import React, { useCallback, useEffect, useState, useRef } from "react";
import PrimaryButton from "../component/button";
import IosRefresh from "react-ionicons/lib/IosRefresh";
import Modal from "react-bootstrap/Modal";

import { Col } from 'react-bootstrap';
import H1 from '../../../../components/h1';
import InputText from '../component/input';
import MdCheckmarkCircle from "react-ionicons/lib/MdCheckmarkCircle";

import { firetoast, notify } from "../../../../components/extras";
import HeaderSignup from "../../../../components/header-signup";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../constants/api";
import {
  isConfirmPasswordValid,
  isEmailValid,
  isPasswordValid,
  isPhoneNumberValid,
} from '../component/extras';
import Stepper from '../component/stepper';
import { greenCheckIcon, InfoIcon2, PasswordIcon } from '../../../../icons';
import UserManagement from '../../../../services/api/resources/user-management';
import { formatPhoneNumber } from '../../../../utils/formatters';
import { toast } from 'react-toastify';
import Onboarding from '../../../../services/api/resources/onboarding';
import { getDeviceInformation } from '../../../../utils/helpers';
import { saveAuthToken } from '../../../../utils/auth';

const SecureAccountScene = ({ setScreen, application }) => {
  const userManagement = new UserManagement();
  const onboarding = new Onboarding();
  const agentOnboardingInfo = JSON.parse(
    localStorage.getItem("agentOnboardingInfo")
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFaceModal, setIsLoadingFaceModal] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [isLoadingOtp, setIsLoadingOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [preferredPhoneNumber, setPreferredPhoneNumber] = useState("");
  const [finalPhoneNumber, setFinalPhoneNumber] = useState("");
  const [isYesSelected, setIsYesSelected] = useState(true);
  const [isOtpIncorrect, setIsOtpIncorrect] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loadingPhoneNumber, setLoadingPhoneNumber] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [emailTimeout, setEmailTimeout] = useState(null);
  const [otpInfo, setOtpInfo] = useState({});
  const [otpString, setOtpString] = useState("");
  const [isEmailValidStatus, setIsEmailValidStatus] = useState(false);
  const [isPhoneNumberValidStatus, setIsPhoneNumberValidStatus] =
    useState(false);
  const [errorMessagePhoneNum, setErrorMessagePhoneNum] = useState(false);
  const [errorMessageEmail, setErrorMessageEmail] = useState(false);
  const [visible, setVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);

  const otpInputsRef = useRef([]);

  const [secureAccountDetailsForm, setSecureAccountDetailsForm] = useState({
    preferredNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    const updatedSecureAccountDetailsForm = JSON.parse(
      localStorage.getItem("secureAccountDetailsForm")
    );

    if (updatedSecureAccountDetailsForm) {
      setSecureAccountDetailsForm({
        preferredNumber:
          application?.secureAccountDetailsForm?.preferredNumber ||
          updatedSecureAccountDetailsForm.preferredNumber ||
          "",
        email:
          application?.secureAccountDetailsForm?.email ||
          updatedSecureAccountDetailsForm.email ||
          "",
        password:
          application?.secureAccountDetailsForm?.password ||
          updatedSecureAccountDetailsForm.password ||
          "",
        confirmPassword:
          application?.secureAccountDetailsForm?.confirmPassword ||
          updatedSecureAccountDetailsForm.confirmPassword ||
          "",
      });
    }
  }, [application]);

  useEffect(() => {
    localStorage.setItem(
      "secureAccountDetailsForm",
      JSON.stringify(secureAccountDetailsForm)
    );
  }, [secureAccountDetailsForm]);

  const updateFormField = (params) => {
    const newForm = {
      ...secureAccountDetailsForm,
      ...params,
    };

    setSecureAccountDetailsForm(newForm);
  };

  useEffect(() => {
    const fetchedBvnNumber =
      agentOnboardingInfo?.kycData?.bvnVerificationResponse?.bvnData
        ?.phoneNumber || "";

    setPreferredPhoneNumber(fetchedBvnNumber);
    setFinalPhoneNumber(fetchedBvnNumber);
  }, []);

  const handleRadioChange = (e) => {
    const value = e.target.value;

    if (value === "yes") {
      const fetchedBvnNumber =
        agentOnboardingInfo?.kycData?.bvnVerificationResponse?.bvnData
          ?.phoneNumber || "";

      setPreferredPhoneNumber(fetchedBvnNumber);
      setFinalPhoneNumber(fetchedBvnNumber);
      setIsYesSelected(true);
    } else {
      setPreferredPhoneNumber("");
      setFinalPhoneNumber("");
      setIsYesSelected(false);
    }
  };

  const handlePhoneNumberChange = (e) => {
    const newPhoneNumber = e.target.value;

    const numericPhoneNumber = newPhoneNumber.replace(/\D/g, "");

    setSecureAccountDetailsForm(prevState => ({
      ...prevState,
      preferredNumber: numericPhoneNumber,
    }));
    setFinalPhoneNumber(numericPhoneNumber);
    setTimeout(() => {
      confirmPhoneNumber(numericPhoneNumber);
    }, 200);
  };

  const confirmPhoneNumber = async (phoneNumber) => {
    if (phoneNumber.length !== 11) {
      return;
    }

    if (phoneNumber) {
      setLoadingPhoneNumber(true);

      const payload = { phoneNumber };

      const kycId = agentOnboardingInfo?.kycData?.kycId;
      const { status, response } = await onboarding.bvnNumberValidate(
        kycId,
        payload
      );

      setLoadingPhoneNumber(false);

      if (status === ERROR_STATUS) {
        setErrorMessagePhoneNum("Phone number already exist.");
        setIsPhoneNumberValidStatus(false)
      } else if (status === SUCCESS_STATUS) {
        if (response?.code === "20000") {
          setIsPhoneNumberValidStatus(true)
          setErrorMessagePhoneNum("");
          setOtpInfo(response);
        } else {
          setIsPhoneNumberValidStatus(true)
        }
      }
    }
  };

  function isPhoneNumberValidAndNew() {
    return isPhoneNumberValid(secureAccountDetailsForm.preferredNumber) && isPhoneNumberValidStatus;
  }

  const validateEmail = async (email) => {
    if (!email) {
      return;
    }
    setLoadingEmail(true);

    try {
      const validateUserObj = await userManagement.validateUserOnPassport(
        email
      );
      const { status, response } = validateUserObj;
      setLoadingEmail(false);

      if (response?.code === "404") {
        setIsEmailValidStatus(true);
        setErrorMessageEmail("");
        return;
      } else if (response?.code === "20000") {
        setIsEmailValidStatus(false);
        setErrorMessageEmail("Email has already been used, try another email.");
      } else if (status === ERROR_STATUS) {
        setIsEmailValidStatus(false);
      } else {
        setIsEmailValidStatus(false);
      }
    } catch (error) {
      setLoadingEmail(false);
      setIsEmailValidStatus(false);
    }

    setIsEmailValidStatus(true);
  };


  function isEmailValidAndNew() {
    return isEmailValid(secureAccountDetailsForm) && isEmailValidStatus;
  }

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    updateFormField({ email: inputEmail });

    if (emailTimeout) {
      clearTimeout(emailTimeout);
    }
    const timeoutId = setTimeout(() => {
      validateEmail(inputEmail);
    }, 2000);

    setEmailTimeout(timeoutId);
  };

  const callSignUp = async () => {
    const payload = {
      mobileNo: finalPhoneNumber,
      firstName:
        agentOnboardingInfo?.kycData?.bvnVerificationResponse?.bvnData
          ?.firstName,
      lastName:
        agentOnboardingInfo?.kycData?.bvnVerificationResponse?.bvnData
          ?.lastName,
      password: secureAccountDetailsForm.password,
      confirmPassword: secureAccountDetailsForm.confirmPassword,
      email: secureAccountDetailsForm.email,
      gender:
        agentOnboardingInfo?.kycData?.bvnVerificationResponse?.bvnData?.gender,
      domainCode: "app",
    };
    try {
      const responseObj = await userManagement.signupUserOnboarding(
        payload,
        false,
        false,
        true
      );
      const { status, response } = responseObj;

      if (status === ERROR_STATUS) {
        notify(`${response.description}`, "error");
      } else if (status === SUCCESS_STATUS) {
      }
      return responseObj;
    } catch (error) {
      return { status: ERROR_STATUS, response: error };
    }
  };

  const callLogin = async (domainTypeId = 5) => {
    const deviceInformation = getDeviceInformation();
    try {
      const loginResponseObj = await userManagement.login(
        finalPhoneNumber,
        secureAccountDetailsForm.password,
        deviceInformation,
        domainTypeId
      );
      const { status, response } = loginResponseObj;

      const authToken = loginResponseObj?.response?.data?.accessToken;

      if (status === ERROR_STATUS) {
        notify(`${response.description}`, "error");
      } else if (status === SUCCESS_STATUS) {
        localStorage.setItem("authToken", authToken);
        saveAuthToken(authToken);

        await callCreateApplication();
      }
      return loginResponseObj;
    } catch (error) {
      return { status: ERROR_STATUS, response: error };
    }
  };

  const callCreateApplication = async () => {
    const authToken = localStorage.getItem("authToken");
    const payload = {
      emailAddress: secureAccountDetailsForm?.email,
      kycId: agentOnboardingInfo?.kycData?.kycId,
    };
    try {
      const responseObj = await onboarding.createApplicationOnboarding(
        payload,
        `Bearer ${authToken}`
      );
      const { status, response } = responseObj;

      if (status === ERROR_STATUS) {
        notify(`${response.description}`, "error");
      } else if (status === SUCCESS_STATUS) {
        localStorage.setItem("createApplication", JSON.stringify(response));
      }
      return responseObj;
    } catch (error) {
      return { status: ERROR_STATUS, response: error };
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const signUpResponse = await callSignUp();
      if (signUpResponse.status === ERROR_STATUS) {
        setIsLoading(false);
        return;
      }

      const loginResponse = await callLogin();
      if (loginResponse.status === ERROR_STATUS) {
        setIsLoading(false);
        return;
      }

      const createApplicationResponse = await callCreateApplication();
      if (createApplicationResponse.status === ERROR_STATUS) {
        setIsLoading(false);
        return;
      }
      handleShowModal();
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const callResendOtp = async () => {
    setIsLoadingOtp(true);

    const responseObj = await userManagement.resendOtp(
      formatPhoneNumber("08161564659")
    );
    const { status, response } = responseObj;
    const newResponse = response.toString().replace(/[, ]+/g, "").trim();
    if (status === ERROR_STATUS) {
      firetoast("error", `${newResponse.description}`, "");
      setIsOtpIncorrect(true);
      setIsLoadingOtp(false);
    } else if (status === SUCCESS_STATUS) {
      notify("OTP successfully sent!", "success");
      setIsOtpIncorrect(false);
      setIsLoadingOtp(false);
    }
  };

  const navigateToFaceVerification = async () => {
    const kycId = agentOnboardingInfo?.kycData?.kycId;
    localStorage.setItem("kycId", kycId);
    setIsLoadingFaceModal(true);

    try {
      const responseObj = await onboarding.submitInitiateImageCapturing(kycId);
      const { response } = responseObj;

      if (response.code === "20000") {
        localStorage.setItem("jobId", response.kycData.jobId);
        setScreen(2);
      } else if (
        response.code === "00" &&
        response.description === "Liveliness check done and validated"
      ) {
        setScreen(3);
      }
    } catch (error) {
      console.error("Error during image capturing initiation:", error);
    } finally {
      setIsLoadingFaceModal(false);
    }
  };

  const textStyle = {
    color: "#1F2937",
    fontWeight: "500",
    fontSize: "0.8rem",
    letterSpacing: "0.3px",
  };

  const spanStyle = {
    display: "inline-flex",
    alignItems: "center",
  };

  const pStyle = {
    marginLeft: "10px",
    marginTop: "12px",
  };

  const iconStyle = {
    width: "20px",
    height: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const instructions = [
    "Ensure you are in a well-lit area",
    "Make sure you are in front of a plain background",
    "Make sure to remove hats, thick glasses, or anything else that might obscure your face",
    "Make sure you keep your expressions neutral",
  ];

  function debounce(fn, delay) {
    let timeoutId;
    return function (...args) {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => fn.apply(this, args), delay);
    };
  }

  const handleOtpSubmit = async () => {
    if (otp.length !== 6) {
      return;
    }

    setLoading(true);

    setTimeout(async () => {
      const otpString = otp.join("");

      const payload = {
        prefix: otpInfo?.prefix,
        tokenId: otpInfo?.tokenId,
        otp: otpString,
      };

      try {
        const responseObj = await onboarding.secureWalletOtpValidate(payload);
        const { status, response } = responseObj;
        setLoading(false);

        if (status === ERROR_STATUS) {
          setIsOtpIncorrect(true);
          setIsPhoneNumberValidStatus(false);
        } else if (status === SUCCESS_STATUS) {
          setIsOtpIncorrect(false);
          setIsPhoneNumberValidStatus(true);
          setTimeout(() => {
            toast.success("Successful");
            setShowModal(true);
          }, 1000);
        }
      } catch (error) {
        setLoading(false);
        setIsPhoneNumberValidStatus(false);
      }
    }, 100);
  };

  const debouncedHandleOtpSubmit = useCallback(
    debounce(handleOtpSubmit, 2000),
    [otp]
  );

  const handleOtpChange = (e, index) => {
    const { value } = e.target;

    if (/^[0-9]$/.test(value)) {
      let newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < 5) {
        otpInputsRef.current[index + 1].focus();
      } else {
        otpInputsRef.current[index].blur();
      }
    } else {
      e.target.value = "";
    }
  };

  useEffect(() => {
    if (otp.join("").length === 6) {
      debouncedHandleOtpSubmit();
    }
  }, [otp]);

  const handleShowModal = () => {
    if (isYesSelected) {
      setShowModal(true);
    } else {
      setShowOtpModal(true);
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setShowOtpModal(false);
  };

  useEffect(() => {
    if (otp.length === 6) {
      setOtpString(otp.join(""));
    }
  }, [otp]);

  const isFormValid = () => {
    if (isYesSelected) {
      return (
        secureAccountDetailsForm.password &&
        secureAccountDetailsForm.confirmPassword &&
        (secureAccountDetailsForm.password === secureAccountDetailsForm.confirmPassword) &&
        isPasswordValid(secureAccountDetailsForm.password) &&
        isConfirmPasswordValid(secureAccountDetailsForm.confirmPassword) &&
        isEmailValid(secureAccountDetailsForm) &&
        isEmailValidStatus &&
        isPhoneNumberValidStatus
      );
    } else {
      return (
        secureAccountDetailsForm.preferredNumber &&
        secureAccountDetailsForm.password &&
        secureAccountDetailsForm.confirmPassword &&
        (secureAccountDetailsForm.password === secureAccountDetailsForm.confirmPassword) &&
        isPhoneNumberValid(secureAccountDetailsForm.preferredNumber) &&
        isPasswordValid(secureAccountDetailsForm.password) &&
        isConfirmPasswordValid(secureAccountDetailsForm.confirmPassword) &&
        isEmailValid(secureAccountDetailsForm) &&
        isEmailValidStatus &&
        isPhoneNumberValidStatus
      );
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          gap: "40px",
        }}
      >
        <HeaderSignup />
      </div>
      <main className="onboarding-container">
        <Col
          style={{
            marginBottom: "20px",
            width: "80%",
            margin: "0 auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              gap: "10px",
              marginBottom: "30px",
            }}
          >
            <Stepper borderColor="#00425F" />
            <Stepper />
            <Stepper />
            <Stepper />
          </div>
          <H1
            style={{
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "10px",
            }}
          >
            Secure your Account
          </H1>
          <p
            style={{
              color: "grey",
              fontWeight: "500",
              fontSize: "12px",
            }}
          >
            Enter the details below to secure your account
          </p>
          <p style={{
            color: "black",
            fontWeight: "700",
            fontSize: "10px",
          }}>Would you like to use the phone number linked to your BVN as your preferred wallet?</p>

          <div className="mb-2">
            <label className="mr-2">
              <input
                type="radio"
                name="usePreferredPhone"
                value="yes"
                checked={isYesSelected}
                onChange={handleRadioChange}
              />
              Yes
            </label>
            <label className="ml-4">
              <input
                type="radio"
                name="usePreferredPhone"
                value="no"
                checked={!isYesSelected}
                onChange={handleRadioChange}
              />
              No
            </label>
          </div>

          <div
            style={{
              alignItems: "center",
              flexWrap: "wrap",
              gap: "14px",
              width: "100%",
              position: "relative",
            }}
          >
            <InputText
              label={"Preferred Phone Number"}
              onChange={handlePhoneNumberChange}
              labelColor={
                !secureAccountDetailsForm.preferredNumber ||
                  isPhoneNumberValid(secureAccountDetailsForm.preferredNumber)
                  ? 'black'
                  : process.env.REACT_APP_RED_COLOUR
              }
              border={`1px solid ${!secureAccountDetailsForm.preferredNumber ||
                isPhoneNumberValid(secureAccountDetailsForm.preferredNumber)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
                }`}
              placeholder={"Enter your Preferred Phone Number"}
              value={
                isYesSelected
                  ? preferredPhoneNumber
                  : secureAccountDetailsForm.preferredNumber
              }
              maxLength={11}
              disabled={isYesSelected ? true : false}
              style={{ paddingRight: "40px" }}
            />
            {loadingPhoneNumber && (
              <div
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "70%",
                  transform: "translateY(-50%)",
                }}
              >
                <IosRefresh fontSize="24px" color="grey" rotate={true} />
              </div>
            )}
            {isPhoneNumberValidAndNew() && !loadingPhoneNumber ? (
              <MdCheckmarkCircle
                style={{
                  marginTop: "-45pt",
                  marginLeft: "90%",
                  width: "20px",
                  position: "absolute",
                }}
                color="green"
                fontSize="40px"
              />
            ) : (
              ""
            )}
            {errorMessagePhoneNum && !loadingPhoneNumber && (
              <div
                style={{
                  color: process.env.REACT_APP_RED_COLOUR,
                  fontSize: "12px",
                  marginBottom: "10px",
                }}
              >
                {errorMessagePhoneNum}
              </div>
            )}
          </div>

          <div
            style={{
              alignItems: "center",
              flexWrap: "wrap",
              gap: "14px",
              width: "100%",
              position: "relative",
            }}
          >
            <InputText
              label={"Enter Email"}
              labelColor={
                !secureAccountDetailsForm.email ||
                  isEmailValid(secureAccountDetailsForm)
                  ? 'black'
                  : process.env.REACT_APP_RED_COLOUR
              }
              border={`1px solid ${!secureAccountDetailsForm.email ||
                isEmailValid(secureAccountDetailsForm)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
                }`}
              placeholder={"Enter your email"}
              onChange={handleEmailChange}
              maxLength={200}
              value={secureAccountDetailsForm.email}
            />
            {loadingEmail && (
              <div
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "70%",
                  transform: "translateY(-50%)",
                }}
              >
                <IosRefresh fontSize="24px" color="grey" rotate={true} />
              </div>
            )}
            {isEmailValidAndNew() && !loadingEmail ? (
              <MdCheckmarkCircle
                style={{
                  marginTop: "-45pt",
                  marginLeft: "90%",
                  width: "20px",
                  position: "absolute",
                }}
                color="green"
                fontSize="40px"
              />
            ) : (
              ""
            )}
            {errorMessageEmail && !loadingEmail && (
              <div
                style={{
                  color: process.env.REACT_APP_RED_COLOUR,
                  fontSize: "12px",
                  marginBottom: "10px",
                }}
              >
                {errorMessageEmail}
              </div>
            )}
          </div>

          <div style={{ position: "relative" }}>
            <InputText
              label={'Enter Password'}
              labelColor={
                !secureAccountDetailsForm.password ||
                  isPasswordValid(secureAccountDetailsForm.password)
                  ? 'black'
                  : process.env.REACT_APP_RED_COLOUR
              }
              border={`1px solid ${!secureAccountDetailsForm.password ||
                isPasswordValid(secureAccountDetailsForm.password)
                ? '#DFE2E6'
                : process.env.REACT_APP_RED_COLOUR
                }`}
              type={visible ? "text" : "password"}
              placeholder={'Enter your password'}
              onChange={(e) => {
                updateFormField({ password: e.target.value });
              }}
              maxLength={200}
              value={secureAccountDetailsForm.password}
            />
            <span
              style={{
                position: "absolute",
                top: "40px",
                right: "4%",
                cursor: "pointer",
              }}
              onClick={() => setVisible(!visible)}
            >
              {PasswordIcon()}
            </span>
          </div>

          <div style={{ position: "relative" }}>
            <InputText
              label={'Confirm Password'}
              labelColor={
                !secureAccountDetailsForm.confirmPassword ||
                  isConfirmPasswordValid(secureAccountDetailsForm.confirmPassword)
                  ? 'black'
                  : process.env.REACT_APP_RED_COLOUR
              }
              border={`1px solid ${!secureAccountDetailsForm.confirmPassword ||
                isConfirmPasswordValid(secureAccountDetailsForm.confirmPassword)
                ? '#DFE2E6'
                : process.env.REACT_APP_RED_COLOUR
                }`}
              type={confirmVisible ? "text" : "password"}
              placeholder={'Enter confirm password'}
              onChange={(e) => {
                updateFormField({ confirmPassword: e.target.value });
              }}
              maxLength={200}
              value={secureAccountDetailsForm.confirmPassword}
            />
            <span
              style={{
                position: "absolute",
                top: "40px",
                right: "4%",
                cursor: "pointer",
              }}
              onClick={() => setConfirmVisible(!confirmVisible)}
            >
              {PasswordIcon()}
            </span>
          </div>
          {secureAccountDetailsForm.password !== secureAccountDetailsForm.confirmPassword ?
            <span style={{ fontSize: "12px", color: "#DC4437" }}>Passwords do not match.</span> : null
          }

          <PrimaryButton
            disabled={!isFormValid() || isLoading}
            onClick={handleSubmit}
          >
            {isLoading ? "Processing..." : "Next"}
          </PrimaryButton>
        </Col>
      </main>

      <Modal
        show={showModal}
        aria-labelledby="contained-modal-title-vcenter"
        style={{
          paddingTop: "6rem",
        }}
      >
        <Modal.Body style={{ padding: "2rem" }}>
          <div style={{ alignContent: "center", padding: "1rem" }}>
            <h1
              style={{
                fontWeight: "bold",
                fontSize: "1.5rem",
              }}
            >
              Face ID Verification
            </h1>
            <p
              style={{
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "20px",
                color: "#1F2937",
              }}
            >
              Ensure your camera is steady and your face fits into the shape.
            </p>
            <div style={textStyle}>
              {instructions.map((instruction, index) => (
                <span key={index} style={spanStyle}>
                  <div style={iconStyle}>{greenCheckIcon()}</div>
                  <p style={pStyle}>{instruction}</p>
                </span>
              ))}
            </div>

            <button
              onClick={navigateToFaceVerification}
              className="signupButton2"
              style={{
                marginTop: "2rem",
                textAlign: "center",
                color: "white",
                backgroundColor: "#00425f",
                width: "100%",
              }}
            >
              {isLoadingFaceModal ? "Processing..." : "Next"}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        closeButton
        show={showOtpModal}
        aria-labelledby="contained-modal-title-vcenter"
        style={{
          backdropFilter: "blur(8px)",
          backgroundColor: "rgba(16, 24, 40, 0.3)",
          paddingTop: "6rem",
        }}
        dialogClassName="custom-modal"
        onHide={handleClose}
      >
        <Modal.Body style={{ padding: "3rem" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <form
              className="otp-container"
              style={{ maxWidth: "500px", width: "100%" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {" "}
                <span
                  style={{
                    fontSize: "24px",
                    fontWeight: 700,
                    marginTop: "12px",
                  }}
                >
                  Enter OTP
                </span>
                <Modal.Header closeButton></Modal.Header>
              </div>
              <p style={{ fontSize: "12px", color: "#5F738C" }}>
                An OTP was sent to your Phone number 08078****54. Kindly enter
                it below.
              </p>

              <div
                style={{
                  justifyContent: "center",
                }}
              >
                <label
                  htmlFor="otp-input"
                  style={{
                    fontSize: "14px",
                    color: "black",
                    fontWeight: 700,
                    display: "block",
                  }}
                >
                  OTP
                </label>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: "14px",
                    marginBottom: "2px",
                    width: "100%",
                  }}
                >
                  <span
                    style={{
                      fontSize: "20px",
                      fontWeight: 700,
                      color: "black",
                    }}
                  >
                    {otpInfo.prefix} -
                  </span>
                  {[...Array(6)].map((_, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength="1"
                      style={{
                        width: "50px",
                        height: "50px",
                        textAlign: "center",
                        fontSize: "20px",
                        borderRadius: "8px",
                        border: `1px solid ${isOtpIncorrect ? "#DC4437" : "#ccc"}`,
                        backgroundColor: " #e0e0e0",
                        flex: "1",
                        minWidth: "40px",
                      }}
                      onChange={(e) => handleOtpChange(e, index)}
                      onFocus={(e) => e.target.select()}
                      ref={(el) => (otpInputsRef.current[index] = el)}
                    />
                  ))}
                  {loading ? (
                    <IosRefresh fontSize="24px" color="grey" rotate={true} />
                  ) : (
                    <span></span>
                  )}
                </div>

                <div style={{ textAlign: "left", marginTop: "1px" }}>
                  {isLoadingOtp ? (
                    <IosRefresh fontSize="24px" color="grey" rotate={true} />
                  ) : (
                    <span
                      style={{
                        cursor: "pointer",
                        color: "#0047ab",
                        fontSize: "12px",
                      }}
                      className="otp-resend"
                      onClick={callResendOtp}
                    >
                      Resend OTP
                    </span>
                  )}
                </div>

                <div style={{ textAlign: "left", marginTop: "10px" }}>
                  {isOtpIncorrect && (
                    <>
                      <InfoIcon2 fontSize="24px" color="#DC4437" />
                      <span
                        style={{
                          marginLeft: "10px",
                          color: "#DC4437",
                        }}
                        className="otp-error"
                      >
                        OTP is incorrect
                      </span>
                    </>
                  )}
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SecureAccountScene;
