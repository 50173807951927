import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { BadgeIcon, cancelBannerIcon, } from '../../../../../icons';

export const AgentBanner = ({ closeBanner }) => {
    const currentApplication = JSON.parse(localStorage.getItem('currentApplication'));
    let currentAgent = JSON.parse(
        localStorage.getItem('currentAgent')
    );
    return (
        <>
            {currentApplication?.approvalStatus === "AWAITING_VALIDATION" || currentApplication?.approvalStatus === "AWAITING_APPROVAL" || currentAgent?.agentClass === 'PRESTIGE' ? '' :
                <div style={{
                    padding: '1rem 1.5rem',
                    borderRadius: '8px',
                    border: `1px solid #A8D6EF`,
                    marginBottom: '20px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    backgroundColor: '#EBF8FE',
                    position: 'absolute',
                    backgroundColor: '#EBF8FE',
                    gap: '10px'
                }}>
                    <div>
                        <p
                            style={{
                                fontSize: '0.875rem',
                                margin: 0,
                                fontWeight: 600,
                                color: '#1F2126',
                                marginBottom: '10px',
                            }}
                        >
                            {currentAgent?.agentClass} Account
                        </p>
                        <p
                            style={{
                                fontSize: '0.775rem',
                                margin: 0,
                                color: '#353F50',
                            }}
                        >
                            You have a {currentAgent?.agentClass} Account. Upgrade to do more on POS Workflow.
                        </p>
                        <Link
                            className=''
                            to={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-profile/upgrade-list`}
                        >
                            <span style={{
                                fontSize: '0.775rem',
                                margin: 0,
                                color: '#0275D8',
                                fontWeight: '600'
                            }}>Upgrade now.</span>
                        </Link>
                    </div>
                    <div>
                        {BadgeIcon()}
                    </div>
                    <div style={{ cursor: 'pointer' }} onClick={() => closeBanner()}>
                        {cancelBannerIcon()}
                    </div>
                </div>
            }
        </>
    );
};

export const PendingApprovalBanner = ({ closeBanner }) => {

    return (
        <div style={{
            padding: '1rem 1.5rem',
            borderRadius: '8px',
            border: `1px solid #A8D6EF`,
            marginBottom: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: '#FEF6CF',
            position: 'absolute',
        }}>
            <div>
                <p
                    style={{
                        fontSize: '0.875rem',
                        margin: 0,
                        fontWeight: 600,
                        color: '#1F2126',
                        marginBottom: '10px',
                    }}
                >
                    Pending Approval
                </p>
                <p
                    style={{
                        fontSize: '0.775rem',
                        margin: 0,
                        color: '#353F50',
                        fontWeight: '400',
                        fontSize: '12px',
                        lineHeight: '16px',
                        color: '#5F738C',
                    }}
                >
                    Your upgrade is pending approval, you should get feedback within 24 - 48 hrs.
                </p>
                <Link
                    className=''
                    to={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-profile/upgrade-status`}
                >
                    <span style={{
                        fontSize: '0.775rem',
                        margin: 0,
                        color: '#AF5F26',
                        fontWeight: '600'
                    }}>View Details.</span>
                </Link>
            </div>
            <div style={{ marginLeft: '8px' }}>
                {BadgeIcon()}
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => closeBanner()}>
                {cancelBannerIcon()}
            </div>
        </div>

    );
};

export const PendingAttestationBanner = ({ closeBanner }) => {
    return (

        <div style={{
            padding: '1rem 1.5rem',
            borderRadius: '8px',
            border: `1px solid #A8D6EF`,
            marginBottom: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: '#FEF6CF',
            position: 'absolute',
        }}>
            <div>
                <p
                    style={{
                        fontSize: '0.875rem',
                        margin: 0,
                        fontWeight: 600,
                        color: '#1F2126',
                        marginBottom: '10px',
                    }}
                >
                    Pending Referee Attestation
                </p>
                <p
                    style={{
                        fontSize: '0.775rem',
                        margin: 0,
                        color: '#353F50',
                        fontWeight: '400',
                        fontSize: '12px',
                        lineHeight: '16px',
                        color: '#5F738C',
                    }}
                >
                    Your upgrade is pending referee attestation, kindly follow <br />up with your referee to speed up the process.
                </p>
                <Link
                    className=''
                    to={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-profile/upgrade-status`}
                >
                    <span style={{
                        fontSize: '0.775rem',
                        margin: 0,
                        color: '#AF5F26',
                        fontWeight: '600'
                    }}>View Details.</span>
                </Link>
            </div>
            <div style={{ marginLeft: '8px' }}>
                {BadgeIcon()}
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => closeBanner()}>
                {cancelBannerIcon()}
            </div>
        </div>

    );
};

export const PendingSubmissionBanner = ({ closeBanner }) => {

    return (
        <div style={{
            padding: '1rem 1.5rem',
            borderRadius: '8px',
            border: `1px solid #A8D6EF`,
            marginBottom: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: '#FEF6CF',
            position: 'absolute',
        }}>
            <div>
                <p
                    style={{
                        fontSize: '0.875rem',
                        margin: 0,
                        fontWeight: 600,
                        color: '#1F2126',
                        marginBottom: '10px',
                    }}
                >
                    Update required
                </p>
                <p
                    style={{
                        fontSize: '0.775rem',
                        margin: 0,
                        color: '#353F50',
                        fontWeight: '400',
                        fontSize: '12px',
                        lineHeight: '16px',
                        color: '#5F738C',
                    }}
                >
                    Your referee has attested to your account, Kindly complete your application to submit.
                </p>
                <Link
                    className=''
                    to={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-profile/upgrade-status`}                >
                    <span style={{
                        fontSize: '0.775rem',
                        margin: 0,
                        color: '#AF5F26',
                        fontWeight: '600'
                    }}>Complete Application.</span>
                </Link>
            </div>
            <div style={{ marginLeft: '8px' }}>
                {BadgeIcon()}
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => closeBanner()}>
                {cancelBannerIcon()}
            </div>
        </div>

    );
};

