import React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Text from './text';

const DOC_FORMATS = ['pdf'];
const IMAGE_FORMATS = ['png', 'jpg', 'jpeg', 'bimp'];

export default class Thumbnail extends React.Component {
  get locationThumbnail() {
    const srcSplitted = this.src_.split('.')

    return <div style={{alignItems: 'center', display: 'flex', flexDirection: 'row', height: '100%', justifyContent: 'center', width: '100%'}}>
      {/* <embed src={this.src_} height="100px" width="100px" /> */}
      <span style={{
        color: 'white',
        padding: '10px'
      }}> 
        {srcSplitted[srcSplitted.length - 1].toUpperCase()}
      </span>
    </div>
  }

  get fileIsImage() {
    return IMAGE_FORMATS.includes(this.fileExtension.toLowerCase());
  }

  get fileIsDocument() {
    return DOC_FORMATS.includes(this.fileExtension.toLowerCase());
  }

  get fileExtension() {
    const srcSplitted = this.props.filename.split('.');
    return srcSplitted[srcSplitted.length - 1].split('-')[0];
  }

  state = {
    showLightbox: false
  }

  shorten(text) {
    return `${text.slice(0, 6)}...${text.slice(text.length-7, text.length)}`
  }

  render() {
    const { location } = this.props;
    let src = this.props.src;

    if (location) {
      src = `http://mufasa-qa.interswitchng.com/p/${location.split('/public/')[1]}`;
      this.src_ = src;
    }

    return <div
      onClick={() => {
        this.setState({
          showLightbox: true
        })
      }}
      style={{
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: '5px',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100px',
        marginRight: '10px',
        width: '100px'
      }}
    >

      <Text 
        style={{
          backgroundColor: '#00425FDD',
          bottom: '0px', 
          color: 'white', 
          marginLeft: this.fileIsImage ? '-5px' : '0px',
          position: 'absolute', 
          textAlign: 'center', 
          width: '100px'
        }}>{this.shorten(this.props.filename.split(this.props.suffix)[0])}</Text>
      
      {/* <Form.Group>
        <Form.Control 
          as="textarea" 
          // value={this.state.form.address} 
          maxLength={MAX_NAME_LENGTH} 
          type="text"
          // onBlur={() => this.setState({
          //   fieldsFocused: [...this.state.fieldsFocused, 'address']
          // })}
          // onChange={(event) => {
          //   const address = this.stripNotAllowedCharactersFromString(
          //     event.target.value
          //   )
          //   this.updateFormField({address})
          // }} 
          style={{
            backgroundColor: '#FFFFFF', 
            borderRadius: '10px', 
            border: `1px solid #DFE2E6`, 
            boxSizing: 'border-box', 
            fontSize: '14px', 
            lineHeight: '17px',
            height: '10px',
            width: '100px'
          }} />
      </Form.Group> */}

      {this.fileIsImage ? <React.Fragment>
        <img 
          {...this.props}
          alt=""
          data-toggle="modal"
          data-target="#kt_modal_1"
          height={100}
          src={this.src_ || src}
          width={100}
        />

        {this.state.showLightbox && <Lightbox
          mainSrc={this.src_ || src}
          onCloseRequest={() => this.setState({ showLightbox: false })}
          onMovePrevRequest={() =>
            this.setState({
            })
          }
          onMoveNextRequest={() =>
            this.setState({
            })
          }
        />}

      </React.Fragment> : <React.Fragment>
        <embed
          style={{
            height: '100px',
            width: '100px'
          }}
          name="plugin"
          src={this.src_ || src} />
      </React.Fragment>}
    </div>
  }
}
