import React, { useState, useEffect } from "react";

const OtpInput = ({ value, onChange, length, onComplete }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [focusedIndex, setFocusedIndex] = useState(null);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (value.length === length && onComplete) {
      onComplete(value);
    }
  }, [value, length, onComplete]);

  const getInputStyle = (index) => ({
    width: "2.5rem",

    height: "3rem",
    fontSize:
      windowWidth <= 480 ? "1rem" : windowWidth <= 768 ? "1.2rem" : "1.5rem",
    margin:
      windowWidth <= 480 ? "0 3px" : windowWidth <= 768 ? "0 4px" : "0 5px",
    backgroundColor: "#F3F5F6",
    border: true
      ? `1px solid ${focusedIndex === index ? "#00425F" : "#F3F5F6"}`
      : `2px solid #DC4437`,
    borderRadius: "5px",
    outline: `2px solid ${focusedIndex === index ? "#00425F" : "transparent"}`,
    boxSizing: "border-box",
    textAlign: "center",
  });

  const handleChange = (e, index) => {
    const newValue = e.target.value;
    if (/^\d?$/.test(newValue)) {
      const newOtp = value.split("");
      newOtp[index] = newValue;
      onChange(newOtp.join(""));

      if (newValue && index < length - 1) {
        document.getElementById(`otp-${index + 1}`).focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (value[index]) {
        const newOtp = value.split("");
        newOtp[index] = "";
        onChange(newOtp.join(""));
      } else if (index > 0) {
        document.getElementById(`otp-${index - 1}`).focus();
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        maxWidth: "400px",
        margin: "0 auto",
      }}
    >
      {Array.from({ length }, (_, index) => (
        <input
          key={index}
          id={`otp-${index}`}
          type="text"
          maxLength="1"
          value={value[index] || ""}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onFocus={() => setFocusedIndex(index)}
          onBlur={() => setFocusedIndex(null)}
          style={getInputStyle(index)}
        />
      ))}
    </div>
  );
};

export default OtpInput;
