import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import { Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import H1 from "../../../../components/h1";
import HeaderSignup from "../../../../components/header-signup";
import PrimaryButton from "../../onboarding-setup/component/button";
import InputText from "../../onboarding-setup/component/input";
import { RegexChecker } from "../../../../utils/regex-checker";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../constants/api";
import Transaction from "../../../../services/api/resources/transaction";
import BackButton from "../../onboarding-setup/component/backButton";
import Lightbox from "react-image-lightbox";
import { HandIcon } from "../../../../icons";
import { notify } from "../../../../components/extras";
import Platform from "../../../../services/api/resources/platform";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const RefereePolicyScene = () => {
  const location = useLocation();
  const data = location.state;
  const refereeInformationForm = JSON.parse(
    localStorage.getItem("refereeInformationForm")
  );
  const signatureImg = localStorage.getItem("signatureImg");
  const passportPhotoImg = localStorage.getItem("passportPhoto");
  const bank = localStorage.getItem("bank");
  const history = useHistory();

  const platform = new Platform();

  const [isLoading, setIsLoading] = useState(false);
  const [banks, setBanks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showLightbox, setShowLightbox] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [showCongratsModal, setShowCongratsModal] = useState(false);
  //   const [signatureImg, setSignatureImg] = useState(null);
  //   const [passportPhotoImg, setpassportPhotoImg] = useState(null);
  const [fileFormatPDF, setFileFormatPDF] = useState(null);
  const [pdfDocument, setPdfDocument] = useState(null);

  const recentDate = new Date().toLocaleDateString();
  const [currentDate, setCurrentDate] = useState(recentDate);

  const handleDocumentClick = (documentName) => {
    setSelectedDocument(documentName);
    setShowLightbox(true);
  };

  const imgStyle = {
    borderRadius: "5px",
    height: "10rem",
    border: "1px solid grey",
    // width: "10rem",
    cursor: "pointer",
    objectFit: "cover",
    // margin: "1rem",
  };

  const pStyle = {
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "20px",
  };

  const getDocumentUrl = (documentLink) => {
    let convertedDocument = documentLink.replace(
      "/data",
      process.env.REACT_APP_ONBOARDING_BASE_URL
    );
    return convertedDocument;
  };

  const transaction = new Transaction();

  const getBanks = async () => {
    setLoading(true);

    const { status, response } = await transaction.getUnifiedBanks();
    setLoading(false);

    if (status === ERROR_STATUS) {
    } else if (status === SUCCESS_STATUS) {
      setBanks(response);
    }
  };

  const prevScreen = () => {
    history.push(`/upgrade/attestation?req_id=${data.req_id}`);
  };

  const congratsModal = () => {
    setShowCongratsModal(true);
  };

  const convertToPDF = () => {
    const targetElement = document.getElementById("pdf-attestation");

    if (targetElement) {
      html2canvas(targetElement, {
        logging: true,
        useCORS: true,
        scrollX: 0,
        scrollY: 0,
        allowTaint: true,
      })
        .then((canvas) => {
          const pdfWidth = 208;
          const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

          const imgData = canvas.toDataURL("image/jpeg", 0.7);

          const pdf = new jsPDF("p", "mm", "a4");
          pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);

          const totalPages = Math.ceil(pdfHeight / 297);
          for (let i = 1; i < totalPages; i++) {
            pdf.addPage();
            pdf.addImage(imgData, "JPEG", 0, -297 * i, pdfWidth, pdfHeight);
          }

          const blob = pdf.output("blob");
          const pdfFile = new File([blob], "file.pdf", {
            type: "application/pdf",
          });
          setPdfDocument(pdfFile);
        })
        .catch((error) => {});
    }
  };

  useEffect(() => {
    convertToPDF();
  }, []);

  const handleSubmit = async () => {
    setIsLoading(true);
    const uploadDoc = await platform.documentUploadAttestation(
      data.req_id,
      pdfDocument
    );
    setIsLoading(false);
    if (uploadDoc.status === ERROR_STATUS) {
      // setError(false);
      notify("An error occured, please try again", "error");
    } else {
      congratsModal();
      localStorage.removeItem("refereeInformationForm");
      localStorage.removeItem("signatureImg");
      localStorage.removeItem("passportPhoto");
      localStorage.removeItem("bank");
    }
  };

  const closeTab = () => {
    setShowCongratsModal(false);
    history.push("/");
  };

  useEffect(() => {
    getBanks();
  }, []);

  return (
    <>
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "40px",
            margin: "20px",
          }}
        >
          <HeaderSignup />
          <BackButton onClick={prevScreen} />
        </div>
        <main className="onboarding-container" id="pdf-attestation">
          <Col
            style={{
              // marginBottom: '20px',
              // width: '80%',
              margin: "0 auto",
            }}
          >
            <H1
              style={{
                textAlign: "left",
                fontWeight: 600,
                marginBottom: "1rem",
              }}
            >
              Attestation Policy
            </H1>

            <p
              style={{
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "18px",
                color: "#353F50",
              }}
            >
              I{" "}
              <span style={pStyle}>
                {refereeInformationForm?.refereeName
                  ? refereeInformationForm?.refereeName
                  : "....."}
              </span>{" "}
              confirm that{" "}
              <span style={pStyle}>
                {data?.data?.agentName ? data?.data?.agentName : "....."}
              </span>{" "}
              who resides at{" "}
              <span style={pStyle}>
                {data?.data?.agentAddress ? data?.data?.agentAddress : "....."}
              </span>{" "}
              has been known to me for{" "}
              <span style={pStyle}>
                {refereeInformationForm?.duration
                  ? refereeInformationForm?.duration
                  : "....."}
              </span>{" "}
              as a/an{" "}
              <span style={pStyle}>
                {refereeInformationForm?.relationship
                  ? refereeInformationForm?.relationship
                  : "....."}
              </span>{" "}
              and is a person of good character.
            </p>

            <p
              style={{
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "18px",
                color: "#353F50",
              }}
            >
              I confirm that the individual’s business and residential addresses
              as provided in this form are correct, and that the other
              information in this form, to the best of my knowledge are valid
              and genuine.
            </p>

            <p
              style={{
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "24px",
                color: "#353F50",
              }}
            >
              I hereby declare and affirm that he/she is fit for consideration
              to be an IFIS Agent and confirm that my details herein provided
              are authentic and in good faith, without prejudice or intention to
              deceive.
            </p>

            <InputText
              disabled={true}
              value={refereeInformationForm?.refPhoneNumber}
              label={"Referee Phone Number"}
            />
            <InputText
              disabled={true}
              value={refereeInformationForm?.businessAddress}
              label={"Referee Home / Business Address"}
            />
            <InputText
              disabled={true}
              value={bank}
              label={"Referee Bank Name"}
            />

            <InputText
              disabled={true}
              value={refereeInformationForm?.accountNumber}
              label={"Account Number"}
            />
            <div
            //   style={{
            //     display: "flex",
            //   }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "1rem",
                }}
              >
                <label
                  style={{
                    marginBottom: "5px",
                    fontWeight: 600,
                    fontSize: "14px",
                  }}
                >
                  Signature
                </label>
                {signatureImg && signatureImg.startsWith("data:image/") ? (
                  <img style={imgStyle} src={signatureImg} alt="Signature" />
                ) : (
                  <embed
                    name="plugin"
                    style={imgStyle}
                    src={signatureImg}
                    title={"Signature"}
                    alt={"Signature"}
                  />
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "1rem",
                }}
              >
                <label
                  style={{
                    marginBottom: "5px",
                    fontWeight: 600,
                    fontSize: "14px",
                  }}
                >
                  Referee Passport Photograph
                </label>
                {passportPhotoImg &&
                passportPhotoImg.startsWith("data:image/") ? (
                  <img style={imgStyle} src={passportPhotoImg} alt="Passport" />
                ) : (
                  <embed
                    name="plugin"
                    style={imgStyle}
                    src={passportPhotoImg}
                    title={"Passport"}
                    alt={"Passport"}
                  />
                )}
              </div>
            </div>
            <InputText
              disabled={true}
              value={data?.data?.businessName}
              label={"Agent Business Name"}
            />
            <InputText
              disabled={true}
              value={data?.data?.agentPhoneNumber}
              label={"Agent Phone Number"}
            />
            <InputText disabled={true} value={currentDate} label={"Date"} />
          </Col>
        </main>
        <div className="onboarding-container">
          <Col>
            <PrimaryButton disabled={isLoading} onClick={handleSubmit}>
              {isLoading ? "Processing..." : "I Attest"}
            </PrimaryButton>
          </Col>
        </div>
      </>
      <>
        <Modal
          show={showCongratsModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body style={{ padding: "1rem" }}>
            <div style={{ padding: "4rem", alignContent: "center" }}>
              <div style={{ display: "grid", placeContent: "center" }}>
                {HandIcon()}
              </div>
              <h1
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  marginBottom: "1rem",
                  fontSize: "1.5rem",
                }}
              >
                Attestation Successful
              </h1>
              <p
                style={{
                  color: "grey",
                  fontWeight: "200",
                  marginLeft: "1.5rem",
                  textAlign: "center",
                }}
              >
                You have successfully attested the details for{" "}
                <span style={pStyle}>{data?.data?.agentName}</span>
              </p>

              <button
                onClick={closeTab}
                className="signupButton2"
                style={{
                  marginTop: "3rem",
                  textAlign: "center",
                  color: "#FFFFFF",
                  backgroundColor: "#00425f",
                }}
              >
                {" "}
                Done{" "}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    </>
  );
};

export default RefereePolicyScene;
