import React, { createRef } from "react";
import { connect } from "react-redux";

import qs from "query-string";
import { Alert, Carousel, Col, Container, Form, Row } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";

import { stopwatch, userManagementServiceClient } from "../../App";
import Button from "../../components/button";
import Footer from "../../components/footer";
import FormControl from "../../components/form-control";
import FormLabel from "../../components/form-label";
import H1 from "../../components/h1";
import Header from "../../components/header";
import HR from "../../components/hr";
import Text from "../../components/text";
import { CURRENT_USER, NIGERIA_PHONE_PREFIX } from "../../constants";
import {
  LOGIN_CLICK,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
} from "../../constants/analytics";
import {
  CHANGE_PASSWORD_CODE,
  DEVICE_IN_USE_CODE,
  ERROR_STATUS,
  EXCEED_DEVICE_LIMIT_CODE,
  GUEST,
  HTTP_CONFLICT_CODE,
  NEW_DEVICE_CODE,
  OTP_CODE,
  RESET_PASSWORD_CODE,
  SELECT_USER_PROFILE,
  SUCCESS_CODE,
  SUCCESS_STATUS,
  VERIFY_PHONE_NUMBER_CODE,
} from "../../constants/api";
import {
  AUTO_LOGIN_FAILED,
  CANNOT_ACCESS_DASHBOARD,
  CONFLICT_USER,
  DEVICE_IN_USE,
  EMAIL_NOT_VERIFIED,
  EMAIL_VERIFIED,
  EXCEED_DEVICE_LIMIT,
  NEW_DEVICE,
} from "../../constants/cases";
import { logEvent } from "../../core/logger";
import ApiGateway from "../../services/api/resources/api-gateway";
import Onboarding from "../../services/api/resources/onboarding";
import Passport from "../../services/api/resources/passport";
import Platform from "../../services/api/resources/platform";
import {
  dismissErrorMessage,
  dismissSuccessMessage,
  showErrorMessage,
  showSuccessMessage,
  updateLoading,
} from "../../services/redux/actions/tunnel";
import {
  onNewSessionBegin,
  saveAuthToken,
  saveRefreshToken,
} from "../../utils/auth";
import {
  formatDomainTypeId,
  formatEmailOrPhone,
  formatPhoneNumber,
  formatPhoneNumberWithCountryCode,
} from "../../utils/formatters";
import { getDeviceInformation } from "../../utils/helpers";
import { saveData } from "../../utils/storage";
import {
  isDeviceNameValid,
  isEmailValid,
  isOtpValid,
  isPasswordValid,
  isPhoneValid,
} from "../../utils/validators/form-validators";
import { RECOVER_PASSWORD_REDIRECT_URL } from "../../utils/api-properties";
import BaseScene from "../base-scene";
import "./styles.scss";
import { notify } from "../../components/extras";

class Login extends BaseScene {
  location = window.location.href;
  path = "/login";
  title = "Login";

  isAuthRequired = false;
  requiresEmptySession = true;

  apiGateway = new ApiGateway();
  onboarding = new Onboarding();
  passport = new Passport();
  platform = new Platform();

  passwordRef = createRef(null);
  setPasswordRef = createRef(null);

  constructor(props) {
    super(props);

    this.callDeviceAuth = this.callDeviceAuth.bind(this);

    this.callLogin = this.callLogin.bind(this);
    this.callNewLogin = this.callNewLogin.bind(this);
    this.callGetUserProfile = this.callGetUserProfile.bind(this);

    this.callResendOtp = this.callResendOtp.bind(this);
    this.callVerifyOtp = this.callVerifyOtp.bind(this);
    this.callResendOtpGuest = this.callResendOtpGuest.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.onSuccessfulLogin = this.onSuccessfulLogin.bind(this);
    this.resetPassword = this.resetPassword.bind(this);

    const queryParams = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });

    this.state = {
      doDeviceAuth: false,
      isUserGuest: false,
      showOtpField: false,
      isUserAgent: false,
      error: false,
      verifyUserMobile: false,
      changePassword: false,
      firstName: queryParams.firstName,
      userProfiles: {},
      fieldsFocused: [],
      form: {
        email: null,
        emailOrPhone: null,
        phone: null,
      },
      hidePassword: true,
      hideConfirmPassword: true,
      hideNewPassword: true,
      otpForm: {
        registerDevice: false,
      },
      guestForm: {
        verifyDetails: false,
        otp: "",
        domainCode: "app",
      },
      changePasswordForm: {},
      resetPasswordForm: {},
      setPasswordForm: {},
      redirectToDashboard: false,
      case: queryParams.case,
    };
  }

  componentDidMount() {
    if (window.location.search) {
      const queryStringObject = new URLSearchParams(window.location.search);

      const message = queryStringObject.get("message");
      const isEmailVerified = queryStringObject.get("isEmailVerified");

      if (message) {
        this.setState({
          message,
        });
      }

      if (isEmailVerified) {
        this.handleEmailVerification(isEmailVerified);
      }

      const currentUrl = window.location.href.split("?");
      const urlObj = { Title: "login", Url: currentUrl[0] };
      window.history.replaceState(urlObj, urlObj.Title, urlObj.Url);
    }
  }

  handleEmailVerification(isEmailVerified) {
    if (isEmailVerified === "true") {
      this.setState({
        case: "EMAIL_VERIFIED",
      });
    } else {
      this.setState({
        case: "EMAIL_NOT_VERIFIED",
      });
    }
  }

  backToLogin() {
    this.setState({
      doDeviceAuth: false,
      isUserGuest: false,
      showUserProfile: false,
    });
  }

  canRegisterDevice(loginResponseBody) {
    return !Boolean(
      loginResponseBody.code === DEVICE_IN_USE_CODE ||
        loginResponseBody.code === EXCEED_DEVICE_LIMIT_CODE
    );
  }

  getLoginCase(loginResponseBody) {
    if (loginResponseBody.code === NEW_DEVICE_CODE) {
      return "NEW_DEVICE";
    }

    if (loginResponseBody.code === DEVICE_IN_USE_CODE) {
      return "DEVICE_IN_USE";
    }

    if (loginResponseBody.code === EXCEED_DEVICE_LIMIT_CODE) {
      return "EXCEED_DEVICE_LIMIT";
    }

    return "";
  }

  isOtpRequired(loginResponseBody) {
    if (
      loginResponseBody.data.migratedUser &&
      !loginResponseBody.data.migrationCompleted
    ) {
      return true;
    }

    return false;
  }

  async callRecoverPassword(username) {
    const redirectUrl = RECOVER_PASSWORD_REDIRECT_URL;
    const recoverPasswordResponse =
      await userManagementServiceClient.recoverPassword(
        username,
        redirectUrl,
        this.state.authenticatedAs
      );

    this.setState({
      uuid: recoverPasswordResponse.response.uuid,
    });
    if (recoverPasswordResponse.status === ERROR_STATUS) {
      notify(recoverPasswordResponse.response.description, "error");
      this.props.updateLoading(false);
    } else {
      notify("Successful", "success");
      this.setState({
        setPassword: true,
        isLoading: false,
      });
    }
  }

  async resetPassword() {
    this.props.updateLoading(67);

    const { resetPasswordForm, username, uuid } = this.state;
    const { status, response } =
      await userManagementServiceClient.resetPassword(
        resetPasswordForm.newPassword,
        resetPasswordForm.confirmPassword,
        uuid,
        username,
        username,
        resetPasswordForm.otp //isOtpRequired ? resetPasswordForm.otp : null
      );
    this.props.updateLoading(false);

    if (status === SUCCESS_STATUS) {
      this.props.showSuccessMessage("Password reset successfully");
      setTimeout(() => {
        this.props.dismissSuccessMessage();
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
      return;
    }

    this.props.showErrorMessage(JSON.stringify(response));
    setTimeout(() => {
      this.props.dismissErrorMessage();
    }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
    return;
  }

  async resetPasswordPhone(e) {
    e.preventDefault();
    const { setPasswordForm, uuid } = this.state;
    this.props.updateLoading(true, 66);
    // const userUuid = localStorage.getItem('uuid');

    const setPasswordResponse =
      await userManagementServiceClient.resetPasswordPhone(
        setPasswordForm.password,
        setPasswordForm.confirmPassword,
        uuid,
        setPasswordForm.otp
      );

    const setPasswordResponseStatus = setPasswordResponse.status;
    this.props.updateLoading(false);

    if (setPasswordResponseStatus === ERROR_STATUS) {
      notify(setPasswordResponse.response.errors[0].message, "error");
    } else {
      notify("Successful", "success");
      this.setState({
        showUserProfile: true,
      });
    }
    return;
  }

  // First call API to get agent login details
  async callGetUserProfile() {
    const { emailOrPhone } = this.state.form;

    logEvent(LOGIN_CLICK);
    this.props.updateLoading(true, 25);

    stopwatch.start();
    try {
      const { response } = await userManagementServiceClient.getUserProfile(
        formatEmailOrPhone(emailOrPhone)
      );
      if (response?.code !== SELECT_USER_PROFILE) {
        notify(response.description, "error");
        this.props.updateLoading(false);
        return;
      }
      if (response?.data?.userDomainTypes[0].shouldResetPassword === false) {
        this.setState({
          userProfiles: response?.data,
        });

        this.setState({
          showUserProfile: true,
          // redirectToDashboard: true
        });
        this.props.updateLoading(false);
      } else {
        this.setState({
          userProfiles: response?.data,
          setPassword: true,
          authenticatedAs: response?.data?.userDomainTypes[0].authenticatedAs,
        });
        this.props.updateLoading(false);

        await this.callRecoverPassword(emailOrPhone);
      }
    } catch (error) {
      notify(error.message, "error");
    }
  }

  getUsername = (userProfiles, selectedProfile) => {
    const { emailOrPhone } = this.state.form;

    if (userProfiles.count === 0) {
      return emailOrPhone;
    }

    if (userProfiles.count < 2) {
      return userProfiles.userDomainTypes[0].username;
    }

    if (!selectedProfile) {
      const filteredUser = userProfiles.userDomainTypes.filter((users) => {
        return !users.fipUser;
      });
      return filteredUser[0].username;
    } else {
      return selectedProfile.username;
    }
  };

  getDomainTypeId = (userProfiles, selectedProfile) => {
    if (userProfiles.count === 0) {
      return null;
    }

    if (userProfiles.count < 2) {
      return userProfiles.userDomainTypes[0].domainTypeId;
    }

    if (!selectedProfile) {
      const filteredUser = userProfiles.userDomainTypes.filter((users) => {
        return !users.fipUser;
      });
      return filteredUser[0].domainTypeId;
    } else {
      return selectedProfile.domainTypeId;
    }
  };

  //Second API call, At the point of getting password of the user on the password screen
  async callLogin() {
    const {
      userProfiles,
      form: { selectedProfile, password },
    } = this.state;

    logEvent(LOGIN_CLICK);
    this.props.updateLoading(true, 25);

    const deviceInformation = getDeviceInformation();
    deviceInformation.deviceOs = this.deviceFingerprint[16].value;

    const username = this.getUsername(userProfiles, selectedProfile);
    const domainTypeId = this.getDomainTypeId(userProfiles, selectedProfile);

    stopwatch.start();

    const loginResponseObj = await userManagementServiceClient.login(
      formatEmailOrPhone(username),
      password,
      deviceInformation,
      domainTypeId
    );

    stopwatch.stop();

    const loginResponseStatus = loginResponseObj.status;
    const loginResponseBody = loginResponseObj.response;
    const loginResponseCode = loginResponseObj.code;

    this.setState({
      description: loginResponseBody.description,
    });

    if (loginResponseStatus === ERROR_STATUS) {
      if (loginResponseCode === HTTP_CONFLICT_CODE) {
        this.setState({
          case: CONFLICT_USER,
        });
        this.props.updateLoading(false);
        return;
      }

      this.props.showErrorMessage(JSON.stringify(loginResponseBody));
      setTimeout(() => {
        this.props.dismissErrorMessage();
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
      this.props.updateLoading(false);

      logEvent(LOGIN_FAILURE, {
        secondsElapsed: stopwatch.secondsElapsed,
        errorCode: loginResponseCode,
        errorSource: loginResponseObj.errorSource,
      });

      return;
    }

    logEvent(LOGIN_SUCCESS, {
      secondsElapsed: stopwatch.secondsElapsed,
    });

    if (loginResponseCode === OTP_CODE) {
      if (loginResponseBody.code === VERIFY_PHONE_NUMBER_CODE) {
        this.setState({
          isUserAgent: true,
          verifyUserMobile: true,
          showUserProfile: false,
          otpForm: {
            ...this.state.otpForm,
            mobileNo: loginResponseBody.data,
          },
        });
        this.props.updateLoading(false);
        return;
      }

      if (
        loginResponseBody.code === NEW_DEVICE_CODE ||
        loginResponseBody.code === DEVICE_IN_USE_CODE ||
        loginResponseBody.code === EXCEED_DEVICE_LIMIT_CODE
      ) {
        this.setState({
          accessToken: loginResponseBody.data.access_token,
          verifyUserMobile: false,
          verifyUserDevice: true,
          showUserProfile: false,
          case: this.getLoginCase(loginResponseBody),
          canRegisterDevice: this.canRegisterDevice(loginResponseBody),
          otpForm: {
            ...this.state.otpForm,
            tokenId: loginResponseBody.data.tokenId,
          },
        });
        this.props.updateLoading(false);
        return;
      }

      if (loginResponseBody.code === CHANGE_PASSWORD_CODE) {
        this.setState({
          accessToken:
            typeof loginResponseBody.data === "string"
              ? loginResponseBody.data
              : loginResponseBody.data.access_token,
          changePassword: true,
          showUserProfile: false,
          isOtpRequired: this.isOtpRequired(loginResponseBody),
          uuid: deviceInformation.deviceUuid,
          username: formatEmailOrPhone(this.state.form.emailOrPhone),
        });
        this.props.updateLoading(false);
        return;
      }

      if (loginResponseBody.code === RESET_PASSWORD_CODE) {
        if (this.isOtpRequired(loginResponseBody)) {
          window.location.href = `/login?message=${loginResponseBody.description}`;
        }
        this.setState({
          accessToken: loginResponseBody.data.access_token,
          resetPassword: true,
          showUserProfile: false,
          isOtpRequired: this.isOtpRequired(loginResponseBody),
          uuid: loginResponseBody.user.uuid,
          username: loginResponseBody.user.username,
        });
        this.props.updateLoading(false);
        return;
      }
    }

    if (loginResponseCode === SUCCESS_CODE) {
      if (loginResponseBody.data.user.roleName === GUEST) {
        this.setState({
          isUserGuest: true,
          showUserProfile: false,
          guestForm: {
            ...this.state.guestForm,
            firstName: loginResponseBody.data.user.firstName,
            lastName: loginResponseBody.data.user.lastName,
            email: loginResponseBody.data.user.email,
            mobileNo: loginResponseBody.data.user.mobileNo,
            password: this.state.form.password,
          },
        });
        this.props.updateLoading(false);
        return;
      }

      this.onSuccessfulLogin(loginResponseBody);
    }
  }

  async callNewLogin() {
    const {
      userProfiles,
      form: { selectedProfile, password },
    } = this.state;

    logEvent(LOGIN_CLICK);
    this.props.updateLoading(true, 25);

    const deviceInformation = getDeviceInformation();
    deviceInformation.deviceOs = this.deviceFingerprint[16].value;

    // const username = this.getUsername(userProfiles, selectedProfile);
    const domainTypeId = this.getDomainTypeId(userProfiles, selectedProfile);

    stopwatch.start();

    const loginResponseObj = await userManagementServiceClient.login(
      formatEmailOrPhone(this.state.form.emailOrPhone),
      password,
      deviceInformation,
      domainTypeId
    );

    stopwatch.stop();

    const loginResponseStatus = loginResponseObj.status;
    const loginResponseBody = loginResponseObj.response;
    const loginResponseCode = loginResponseObj.code;

    this.setState({
      description: loginResponseBody.description,
    });

    if (loginResponseStatus === ERROR_STATUS) {
      if (loginResponseCode === HTTP_CONFLICT_CODE) {
        this.setState({
          case: CONFLICT_USER,
        });
        this.props.updateLoading(false);
        return;
      }

      this.props.showErrorMessage(JSON.stringify(loginResponseBody));
      setTimeout(() => {
        this.props.dismissErrorMessage();
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
      this.props.updateLoading(false);

      logEvent(LOGIN_FAILURE, {
        secondsElapsed: stopwatch.secondsElapsed,
        errorCode: loginResponseCode,
        errorSource: loginResponseObj.errorSource,
      });

      return;
    }

    logEvent(LOGIN_SUCCESS, {
      secondsElapsed: stopwatch.secondsElapsed,
    });

    if (loginResponseCode === OTP_CODE) {
      if (loginResponseBody.code === VERIFY_PHONE_NUMBER_CODE) {
        this.setState({
          isUserAgent: true,
          verifyUserMobile: true,
          showUserProfile: false,
          otpForm: {
            ...this.state.otpForm,
            mobileNo: loginResponseBody.data,
          },
        });
        this.props.updateLoading(false);
        return;
      }

      if (
        loginResponseBody.code === NEW_DEVICE_CODE ||
        loginResponseBody.code === DEVICE_IN_USE_CODE ||
        loginResponseBody.code === EXCEED_DEVICE_LIMIT_CODE
      ) {
        this.setState({
          accessToken: loginResponseBody.data.access_token,
          verifyUserMobile: false,
          verifyUserDevice: true,
          showUserProfile: false,
          case: this.getLoginCase(loginResponseBody),
          canRegisterDevice: this.canRegisterDevice(loginResponseBody),
          otpForm: {
            ...this.state.otpForm,
            tokenId: loginResponseBody.data.tokenId,
          },
        });
        this.props.updateLoading(false);
        return;
      }

      if (loginResponseBody.code === CHANGE_PASSWORD_CODE) {
        this.setState({
          accessToken:
            typeof loginResponseBody.data === "string"
              ? loginResponseBody.data
              : loginResponseBody.data.access_token,
          changePassword: true,
          showUserProfile: false,
          isOtpRequired: this.isOtpRequired(loginResponseBody),
          uuid: deviceInformation.deviceUuid,
          username: formatEmailOrPhone(this.state.form.emailOrPhone),
        });
        this.props.updateLoading(false);
        return;
      }

      if (loginResponseBody.code === RESET_PASSWORD_CODE) {
        if (this.isOtpRequired(loginResponseBody)) {
          window.location.href = `/login?message=${loginResponseBody.description}`;
        }
        this.setState({
          accessToken: loginResponseBody.data.access_token,
          resetPassword: true,
          showUserProfile: false,
          isOtpRequired: this.isOtpRequired(loginResponseBody),
          uuid: loginResponseBody.user.uuid,
          username: loginResponseBody.user.username,
        });
        this.props.updateLoading(false);
        return;
      }
    }

    if (loginResponseCode === SUCCESS_CODE) {
      if (loginResponseBody.data.user.roleName === GUEST) {
        this.setState({
          isUserGuest: true,
          showUserProfile: false,
          guestForm: {
            ...this.state.guestForm,
            firstName: loginResponseBody.data.user.firstName,
            lastName: loginResponseBody.data.user.lastName,
            email: loginResponseBody.data.user.email,
            mobileNo: loginResponseBody.data.user.mobileNo,
            password: this.state.form.password,
          },
        });
        this.props.updateLoading(false);
        return;
      }

      this.onSuccessfulLogin(loginResponseBody);
    }
  }

  //Third call, to authenticate the OTP
  async callDeviceAuth() {
    this.props.updateLoading(true, 50);

    const deviceInformation = getDeviceInformation();
    deviceInformation.deviceOs = this.deviceFingerprint[16].value;
    deviceInformation.deviceName = this.state.otpForm.deviceName;

    let headers = {};

    if (
      process.env.REACT_APP_USER_MANAGEMENT_API_BASE_URL.includes(
        "api-gateway"
      ) &&
      this.state.accessToken
    ) {
      headers.Authorization = `Bearer ${this.state.accessToken}`;
    }

    const loginResponseObj = await userManagementServiceClient.deviceAuth(
      this.state.otpForm.otp,
      this.state.otpForm.tokenId,
      deviceInformation,
      this.state.otpForm.registerDevice,
      headers
    );

    const loginResponseStatus = loginResponseObj.status;
    const loginResponseBody = loginResponseObj.response;

    if (loginResponseStatus === ERROR_STATUS) {
      this.props.showErrorMessage(JSON.stringify(loginResponseBody));
      setTimeout(() => {
        this.props.dismissErrorMessage();
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
      this.props.updateLoading(false);

      return;
    }

    this.onSuccessfulLogin(loginResponseBody);
  }

  async callVerifyOtp() {
    const {
      userProfiles,
      form: { selectedProfile },
    } = this.state;
    this.props.updateLoading(true, 33);

    const username = this.getUsername(userProfiles, selectedProfile);
    const verifyResponseObj = await userManagementServiceClient.verifyOtp(
      username,
      this.state.otpForm.otp
    );
    const { response, status } = verifyResponseObj;

    if (status === SUCCESS_STATUS) {
      this.props.updateLoading(false);
      this.callLogin();
    } else {
      this.props.updateLoading(false);
      this.props.showErrorMessage(JSON.stringify(response));
      setTimeout(() => {
        this.props.dismissErrorMessage();
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
    }
  }

  async callGuestSignUp(sendOtp, verifyOtp) {
    const { guestForm } = this.state;
    this.props.updateLoading(true, 33);

    const deviceInformation = getDeviceInformation();
    deviceInformation.deviceOs = this.deviceFingerprint[16].value;

    const guestSignupResponseObj =
      await userManagementServiceClient.signupGuestUser(
        guestForm,
        sendOtp,
        verifyOtp
      );

    const { status, response, code } = guestSignupResponseObj;

    if (status === ERROR_STATUS) {
      if (code === HTTP_CONFLICT_CODE) {
        this.callLogin();
      }

      this.props.updateLoading(false);

      this.props.showErrorMessage(JSON.stringify(response));
      setTimeout(() => {
        this.props.dismissErrorMessage();
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
    }

    if (status === SUCCESS_STATUS) {
      if (code === OTP_CODE) {
        this.props.updateLoading(false);
        this.setState({
          isUserGuest: true,
          doDeviceAuth: true,
        });
        return;
      }

      if (code === SUCCESS_CODE) {
        let hasLoggedInSuccessfully = false;
        let retryCount = 0;
        this.props.updateLoading(true, 66);

        while (!hasLoggedInSuccessfully && retryCount < 3) {
          this.props.updateLoading(true, 80);
          const responseObj = await userManagementServiceClient.login(
            guestForm.mobileNo,
            guestForm.password,
            deviceInformation,
            parseInt(process.env.REACT_APP_APPLICANT_DOMAIN_TYPE_ID)
          );
          const { status, response } = responseObj;

          this.props.updateLoading(false);
          retryCount += 1;

          if (status === SUCCESS_STATUS) {
            this.props.updateLoading(true, 80);
            const { firstName, lastName, mobileNo, email } = guestForm;
            const createApplicationPayload = {
              applicantDetails: {
                firstName,
                surname: lastName,
                emailAddress: email,
                phoneNumber: formatPhoneNumber(mobileNo),
              },
            };

            hasLoggedInSuccessfully = true;
            saveAuthToken(response.data.access_token);
            saveRefreshToken(response.data.access_token);
            saveData(CURRENT_USER, JSON.stringify(response.data.user));

            onNewSessionBegin();

            let hasCreateApplication = false;

            while (!hasCreateApplication) {
              const onboardingResponseObj =
                await this.onboarding.createApplication(
                  createApplicationPayload
                );
              const onboardingResponseObjStatus = onboardingResponseObj.status;
              const onboardingResponseObjresponse =
                onboardingResponseObj.response;
              const onboardingResponseObjCode = onboardingResponseObj.code;

              if (onboardingResponseObjStatus === ERROR_STATUS) {
                if (onboardingResponseObjCode === HTTP_CONFLICT_CODE) {
                  this.setState({
                    redirectToDashboard: true,
                  });
                  return;
                }
                this.props.updateLoading(false);
                this.props.showErrorMessage(
                  JSON.stringify(onboardingResponseObjresponse)
                );
                setTimeout(() => {
                  this.props.dismissErrorMessage();
                }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
                this.props.updateLoading(false);
                return;
              }

              this.props.updateLoading(false);
              hasCreateApplication = true;
              localStorage.setItem(
                "currentApplication",
                JSON.stringify(onboardingResponseObjresponse)
              );
              this.setState({
                signupSuccessful: true,
              });
            }
          }
        }

        if (retryCount === 3 && !hasLoggedInSuccessfully) {
          this.props.updateLoading(false);
          this.props.showErrorMessage("An error occurred.");
          setTimeout(
            () => this.props.dismissErrorMessage(),
            parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
          );
        }
      }
    }
  }

  async callResendOtpGuest() {
    const resendOtpParameter = this.state.isUserAgent
      ? formatEmailOrPhone(this.state.form.emailOrPhone)
      : formatPhoneNumber(this.state.guestForm.mobileNo);

    const responseObj = await userManagementServiceClient.resendOtp(
      resendOtpParameter
    );
    const { status, response } = responseObj;

    if (status === ERROR_STATUS) {
      this.props.showErrorMessage(JSON.stringify(response));
      setTimeout(() => {
        this.props.dismissErrorMessage();
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
    } else {
      this.props.showSuccessMessage("OTP successfully sent!");
      setTimeout(() => {
        this.props.dismissSuccessMessage();
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
    }
  }

  async callResendOtp() {
    const deviceInformation = getDeviceInformation();
    this.props.updateLoading(true, 50);

    let headers = {};

    if (
      process.env.REACT_APP_USER_MANAGEMENT_API_BASE_URL.includes(
        "api-gateway"
      ) &&
      this.state.accessToken
    ) {
      headers.Authorization = `Bearer ${this.state.accessToken}`;
    }

    const resendOtpResponse = await userManagementServiceClient.regenerateToken(
      deviceInformation.deviceUuid,
      this.state.otpForm.tokenId,
      headers
    );

    this.props.updateLoading(false);

    const resendOtpResponseStatus = resendOtpResponse.status;
    const resendOtpResponseObj = resendOtpResponse.response;

    if (resendOtpResponseStatus === ERROR_STATUS) {
      this.props.showErrorMessage(JSON.stringify(resendOtpResponseObj));
      setTimeout(() => {
        this.props.dismissErrorMessage();
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));

      return;
    }

    this.props.showSuccessMessage("OTP successfully sent!");
    setTimeout(() => {
      this.props.dismissSuccessMessage();
    }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));

    this.setState({
      doDeviceAuth: true,
      otpForm: {
        ...this.state.otpForm,
        tokenId: resendOtpResponseObj.data.tokenId,
        registerDevice: false,
      },
    });
  }

  isEmailOrPhoneValid(newForm) {
    return (
      isPhoneValid(newForm.emailOrPhone, NIGERIA_PHONE_PREFIX) ||
      isEmailValid(newForm.emailOrPhone)
    );
  }

  async onSuccessfulLogin(loginResponseBody) {
    saveAuthToken(loginResponseBody.data.access_token);
    saveRefreshToken(loginResponseBody.data.refresh_token);
    saveData(CURRENT_USER, JSON.stringify(loginResponseBody.data.user));

    onNewSessionBegin();

    this.setState({ redirectToDashboard: true });
  }

  async changePassword() {
    this.props.updateLoading(67);
    const { accessToken, changePasswordForm } = this.state;
    const changePasswordResponse =
      await userManagementServiceClient.changePassword(
        changePasswordForm.oldPassword,
        changePasswordForm.newPassword,
        changePasswordForm.confirmPassword,
        accessToken
      );
    this.props.updateLoading(false);
    const { status, response } = changePasswordResponse;

    if (status === SUCCESS_STATUS) {
      this.setState({
        isLoading: false,
      });
      this.props.showSuccessMessage("Password changed successfully");
      setTimeout(() => {
        this.props.dismissSuccessMessage();
        window.location.reload();
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
      return;
    }

    this.props.showErrorMessage(JSON.stringify(response));
    setTimeout(() => {
      this.props.dismissErrorMessage();
    }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
    return;
  }

  async resetPassword() {
    this.props.updateLoading(67);

    const { resetPasswordForm, username, uuid } = this.state;
    const { status, response } =
      await userManagementServiceClient.resetPassword(
        resetPasswordForm.newPassword,
        resetPasswordForm.confirmPassword,
        uuid,
        username,
        username,
        resetPasswordForm.otp
      );
    this.props.updateLoading(false);

    if (status === SUCCESS_STATUS) {
      this.props.showSuccessMessage("Password reset successfully");
      setTimeout(() => {
        this.props.dismissSuccessMessage();
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
      return;
    }

    this.props.showErrorMessage(JSON.stringify(response));
    setTimeout(() => {
      this.props.dismissErrorMessage();
    }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
    return;
  }

  updateFormField(params) {
    const newFormData = {
      ...this.state.form,
      ...params,
    };

    const allParamsAreValid =
      this.isEmailOrPhoneValid(newFormData) &&
      isPasswordValid(newFormData.password);

    this.setState({
      form: newFormData,
      formIsValid: allParamsAreValid,
    });
  }

  updateNewLoginFormField(params) {
    const newFormData = {
      ...this.state.form,
      ...params,
    };

    const allParamsAreValid = this.isEmailOrPhoneValid(newFormData);
    const allProfileParamsAreValid = isPasswordValid(newFormData.password);

    this.setState({
      form: newFormData,
      usernameIsValid: allParamsAreValid,
      userProfileIsValid: allProfileParamsAreValid,
    });
  }

  updateOtpFormField(params) {
    const newFormData = {
      ...this.state.otpForm,
      ...params,
    };

    const allParamsAreValid = isOtpValid(newFormData);

    this.setState({
      otpForm: newFormData,
      otpFormIsValid: allParamsAreValid,
    });
  }

  updateGuestFormField(params) {
    const newFormData = {
      ...this.state.form,
      ...params,
    };
    this.setState({
      guestForm: newFormData,
    });
  }

  updateChangePasswordFormField(params) {
    const newFormData = {
      ...this.state.changePasswordForm,
      ...params,
    };
    this.setState({
      changePasswordForm: newFormData,
    });
  }

  setPasswordFormField(params) {
    const newFormData = {
      ...this.state.setPasswordForm,
      ...params,
    };
    this.setState({
      setPasswordForm: newFormData,
    });
  }

  updateResetPasswordFormField(params) {
    const newFormData = {
      ...this.state.resetPasswordForm,
      ...params,
    };
    this.setState({
      resetPasswordForm: newFormData,
    });
  }

  isPasswordSame(password) {
    return password === this.state.changePasswordForm.newPassword;
  }

  arePasswordsTheSame(password, confirmPassword) {
    return password === confirmPassword;
  }

  isChangePasswordFormValid() {
    const { changePasswordForm } = this.state;
    return (
      isPasswordValid(changePasswordForm.oldPassword) &&
      isPasswordValid(changePasswordForm.newPassword) &&
      this.isPasswordSame(changePasswordForm.confirmPassword)
    );
  }

  isResetPasswordFormValid() {
    const { resetPasswordForm } = this.state;
    return (
      isPasswordValid(resetPasswordForm.oldPassword) &&
      isPasswordValid(resetPasswordForm.newPassword) &&
      this.arePasswordsTheSame(
        resetPasswordForm.newPassword,
        resetPasswordForm.confirmPassword
      ) &&
      isOtpValid(resetPasswordForm)
    );
  }

  isSetPasswordFormValid() {
    const { setPasswordForm } = this.state;
    return (
      isPasswordValid(setPasswordForm.password) &&
      this.arePasswordsTheSame(
        setPasswordForm.password,
        setPasswordForm.confirmPassword
      ) &&
      isOtpValid(setPasswordForm)
    );
  }

  get message() {
    const _message = this.state.message;

    if (_message) {
      return <Alert variant="primary">{_message}</Alert>;
    }

    return null;
  }

  get caseDescriptionLabel() {
    switch (this.state.case) {
      case AUTO_LOGIN_FAILED:
        return (
          <Alert variant="primary">
            {this.state.firstName}, you already have a{" "}
            <span className="brand-name">Quickteller</span> account! Log-in with
            your existing details.
          </Alert>
        );
      case CANNOT_ACCESS_DASHBOARD:
        return (
          <Alert variant="danger">
            You are not authorized to access this dashboard.
          </Alert>
        );
      case CONFLICT_USER:
        return <Alert variant="danger">{this.state.description}</Alert>;
      case NEW_DEVICE:
        return <Alert variant="success">{this.state.description}</Alert>;
      case EMAIL_VERIFIED:
        return (
          <Alert variant="success">
            Email Verified Successfully, Please Login
          </Alert>
        );
      case EMAIL_NOT_VERIFIED:
        return <Alert variant="danger">Failed to verify email.</Alert>;
      case DEVICE_IN_USE:
        return <Alert variant="danger">{this.state.description}</Alert>;
      case EXCEED_DEVICE_LIMIT:
        return <Alert variant="danger">{this.state.description}</Alert>;
      default:
        return "";
    }
  }

  doOtpVerification() {
    this.state.otpForm.registerDevice || this.state.verifyUserDevice
      ? this.callDeviceAuth()
      : this.callVerifyOtp();
  }

  render() {
    if (this.state.redirectToDashboard) {
      return <Redirect to="/dashboard" />;
    }

    if (this.state.signupSuccessful) {
      return <Redirect to="/application" />;
    }

    const userProfile =
      this.state.userProfiles && this.state.userProfiles.count >= 1
        ? this.state.userProfiles.userDomainTypes.filter((profile) => {
            return !profile.fipUser;
          })
        : [];

    const newLoginForm = (
      <Form>
        <H1
          style={{ textAlign: "center", margin: 0, textTransform: "uppercase" }}
        >
          Login to your account
        </H1>
        <center>
          <HR style={{ marginTop: "10px" }} />
        </center>

        {this.caseDescriptionLabel}
        {this.message}

        <form onSubmit={() => this.callGetUserProfile()}>
          <Row className="regular-row">
            <Col className="regular-col">
              <Form.Group>
                <FormLabel
                  style={{
                    color: `${
                      !this.state.form.emailOrPhone ||
                      this.isEmailOrPhoneValid(this.state.form)
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  Phone or Email:
                </FormLabel>
                <FormControl
                  autoComplete="new-password"
                  defaultValue={this.state.form.email}
                  id="email-or-phone-field"
                  placeholder="Phone or Email"
                  onChange={(event) => {
                    const emailOrPhone = event.target.value.trim();
                    this.updateNewLoginFormField({ emailOrPhone });
                  }}
                  onBlur={() =>
                    this.setState({
                      fieldsFocused: [...this.state.fieldsFocused, "email"],
                    })
                  }
                  style={{
                    border: `1px solid ${
                      !this.state.form.emailOrPhone ||
                      this.isEmailOrPhoneValid(this.state.form)
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                  }}
                  type="text"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <div
                style={{
                  color: process.env.REACT_APP_LINK_COLOUR,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  paddingBottom: "10px",
                }}
              >
                <Link to="/forgotten-password">Forgot Password?</Link>
                <span style={{ float: "right" }}>
                  <a href="mailto:ifiscustomercare@interswitchgroup.com">
                    Contact Support
                  </a>
                </span>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Button
                type="submit"
                disabled={!this.state.usernameIsValid || this.props.isLoading}
                style={{ fontWeight: "normal", width: "100%" }}
                onClick={(e) => {
                  e.preventDefault();
                  this.callGetUserProfile();
                }}
              >
                {this.props.isLoading ? "Loading..." : "Login"}
              </Button>
            </Col>
          </Row>

          <Row>
            <Col>
              <div
                style={{
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  paddingTop: "10px",
                }}
              >
                Are you new on Quickteller Paypoint?
                {process.env.REACT_APP_SHOW_NEW_SIGNUP === "true" ? (
                  <Link to="/signup"> Signup</Link>
                ) : (
                  <Link to="/signupOld"> Signup</Link>
                )}
              </div>
            </Col>
          </Row>
        </form>
      </Form>
    );

    const selectUserProfileForm = (
      <Form>
        <H1
          style={{ textAlign: "center", margin: 0, textTransform: "uppercase" }}
        >
          {userProfile && userProfile.length < 2
            ? "Login to your profile"
            : "Select your profile"}
        </H1>
        <center>
          <HR style={{ marginTop: "10px" }} />
        </center>

        <form>
          {userProfile && userProfile.length === 1 && (
            <div className="regular-row">
              <div className="regular-col">
                <div className="form-group">
                  <label style={{ lineHeight: "19px" }}>
                    <Text
                      style={{
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        fontWeight: "bold",
                      }}
                    >
                      Username:
                    </Text>
                  </label>
                  <Text
                    style={{
                      float: "right",
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    }}
                  >
                    {userProfile[0].username}
                  </Text>
                </div>
              </div>
            </div>
          )}

          {userProfile &&
            userProfile.length > 1 &&
            userProfile.map((profile, index) => {
              return (
                <Row
                  key={index}
                  className="regular-row"
                  style={{ paddingBottom: "10px" }}
                >
                  <Col className="regular-col">
                    <div style={{ padding: "10px" }}>
                      <label>
                        <input
                          type="radio"
                          name="profileRadio"
                          value={JSON.stringify(profile)}
                          onChange={(event) => {
                            const selectedProfile = JSON.parse(
                              event.target.value
                            );
                            this.updateNewLoginFormField({ selectedProfile });
                          }}
                        />
                        <div style={{ float: "right", paddingLeft: "10px" }}>
                          <h6
                            style={{ color: process.env.REACT_APP_BLUE_COLOUR }}
                          >
                            <b>{formatDomainTypeId(profile.domainTypeId)}</b>
                          </h6>
                          <span style={{ fontSize: "12px" }}>
                            {profile.domainName}
                          </span>
                          <br />
                          <span style={{ fontSize: "12px" }}>
                            {profile.roleName}
                          </span>
                          <br />
                          <span style={{ fontSize: "12px" }}>
                            {profile.username}
                          </span>
                        </div>
                      </label>
                    </div>
                  </Col>
                </Row>
              );
            })}

          <Row
            hidden={
              !this.state.form.selectedProfile &&
              userProfile &&
              userProfile.length > 1
            }
            className="regular-row"
          >
            <Col className="regular-col">
              <Form.Group>
                <FormLabel
                  style={{
                    color: `${
                      !this.state.form.password ||
                      isPasswordValid(this.state.form.password)
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  Password or PIN:
                </FormLabel>

                <FormControl
                  autoComplete="new-password"
                  id="password-field"
                  ref={this.passwordRef}
                  type={this.state.hidePassword ? "password" : "text"}
                  placeholder="Password or PIN"
                  onBlur={() =>
                    this.setState({
                      fieldsFocused: [...this.state.fieldsFocused, "email"],
                    })
                  }
                  onChange={(event) => {
                    const password = event.target.value;
                    this.updateNewLoginFormField({ password });
                  }}
                  style={{
                    border: `1px solid ${
                      !this.state.form.password ||
                      isPasswordValid(this.state.form.password)
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                  }}
                />

                <img
                  className="hide-password-toggle"
                  style={{
                    marginLeft: this.state.deviceIsPC ? "265px" : "90%",
                  }}
                  src={
                    this.state.hidePassword
                      ? "/assets/media/icons/eye.svg"
                      : "/assets/media/icons/eye-hidden.svg"
                  }
                  onClick={() =>
                    this.setState({ hidePassword: !this.state.hidePassword })
                  }
                  alt=""
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Button
                type="submit"
                disabled={
                  !this.state.userProfileIsValid || this.props.isLoading
                }
                style={{ fontWeight: "normal", width: "100%" }}
                onClick={(e) => {
                  e.preventDefault();
                  this.callLogin();
                }}
              >
                {this.props.isLoading ? "Loading..." : "Continue"}
              </Button>
            </Col>
          </Row>

          <Row style={{ margin: 0 }}>
            <Col md={12} lg={12} style={{ margin: 0, padding: 0 }}>
              <div
                style={{
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  paddingTop: "10px",
                  cursor: "pointer",
                  color: "blue",
                }}
                onClick={() => this.backToLogin()}
              >
                Login with another account?
              </div>
            </Col>
          </Row>
        </form>
      </Form>
    );

    const otpForm = (
      <Form>
        <H1
          style={{ textAlign: "center", margin: 0, textTransform: "uppercase" }}
        >
          {this.state.verifyUserMobile
            ? "VERIFY YOUR PHONE NUMBER"
            : "ENTER YOUR OTP"}
        </H1>

        <center>
          <HR style={{ marginTop: "10px" }} />
        </center>

        {this.caseDescriptionLabel}

        <Row className="regular-row">
          <Col className="regular-col">
            <div
              style={{
                color: process.env.REACT_APP_BLACK_COLOUR,
                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                marginBottom: "15px",
                textAlign: "center",
              }}
            >
              {this.state.verifyUserMobile
                ? "An OTP has been sent to your phone."
                : "Enter the OTP that was sent to your registered phone number."}
            </div>
          </Col>
        </Row>

        <Row
          className="regular-row"
          style={{
            marginBottom: "0px",
            paddingBottom: "0px",
          }}
        >
          <Col
            className="regular-col"
            style={{
              marginBottom: "0px",
              paddingBottom: "0px",
            }}
          >
            <Form.Group
              style={{
                marginBottom: "0px",
                paddingBottom: "0px",
              }}
            >
              <FormLabel
                style={{
                  color: `${
                    !this.state.otpForm.otp || isOtpValid(this.state.otpForm)
                      ? "#3A3B3B"
                      : process.env.REACT_APP_RED_COLOUR
                  }`,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  lineHeight: "19px",
                }}
              >
                OTP:
              </FormLabel>
              <FormControl
                autoComplete="new-password"
                id="otp-input"
                type="text"
                placeholder="OTP"
                onChange={(event) => {
                  const otp = event.target.value;
                  this.updateGuestFormField({ ...this.state.guestForm, otp });
                  this.updateOtpFormField({ otp });
                }}
                style={{
                  border: `1px solid ${
                    !this.state.otpForm.otp || isOtpValid(this.state.otpForm)
                      ? "#DFE2E6"
                      : process.env.REACT_APP_RED_COLOUR
                  }`,
                }}
              />
            </Form.Group>
          </Col>
        </Row>

        {this.state.canRegisterDevice && (
          <Row
            className="regular-row"
            hidden={this.state.isUserGuest || this.state.verifyUserMobile}
            style={{
              margin: 0,
              padding: 0,
            }}
          >
            <Col
              className="regular-col"
              style={{
                margin: 0,
                padding: 0,
              }}
            >
              <Form.Group
                style={{
                  marginBottom: "10px",
                  padding: 0,
                }}
              >
                <div id="register-device-check-container">
                  <Form.Check
                    id="register-checkbox"
                    type="checkbox"
                    onChange={(event) => {
                      this.updateOtpFormField({
                        registerDevice: !this.state.otpForm.registerDevice,
                      });
                    }}
                  />
                  <Text>Register Device</Text>
                </div>
              </Form.Group>
            </Col>
          </Row>
        )}

        <Row
          className="regular-row"
          hidden={!this.state.otpForm.registerDevice}
        >
          <Col className="regular-col">
            <Form.Group
              style={{
                marginBottom: "5px",
              }}
            >
              <FormLabel
                style={{
                  color: `${
                    !this.state.otpForm.deviceName ||
                    isDeviceNameValid(this.state.otpForm)
                      ? "#3A3B3B"
                      : process.env.REACT_APP_RED_COLOUR
                  }`,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  lineHeight: "19px",
                }}
              >
                Device Name:
              </FormLabel>
              <FormControl
                autoComplete="new-password"
                id="otp-input"
                type="text"
                placeholder="Device Name"
                onChange={(event) => {
                  const deviceName = event.target.value;
                  this.updateOtpFormField({ deviceName });
                }}
                style={{
                  border: `1px solid ${
                    !this.state.otpForm.deviceName ||
                    isDeviceNameValid(this.state.otpForm)
                      ? "#DFE2E6"
                      : process.env.REACT_APP_RED_COLOUR
                  }`,
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="regular-row">
          <Col className="regular-col">
            <div
              id="resend-otp-link"
              style={{
                color: process.env.REACT_APP_LINK_COLOUR,
                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                margin: "10px 10px 10px 10px",
              }}
            >
              <Link
                onClick={
                  this.state.isUserGuest || this.state.isUserAgent
                    ? this.callResendOtpGuest
                    : this.callResendOtp
                }
              >
                Resend OTP
              </Link>
            </div>
          </Col>
        </Row>

        <Row id="continue-button-row" hidden={this.state.isUserGuest}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="regular-col">
            <Button
              type="submit"
              disabled={
                this.state.otpForm.registerDevice
                  ? !this.state.otpFormIsValid ||
                    this.props.isLoading ||
                    !isDeviceNameValid(this.state.otpForm)
                  : !this.state.otpFormIsValid || this.props.isLoading
              }
              style={{ fontWeight: "normal", width: "100%" }}
              onClick={(e) => {
                e.preventDefault();
                this.doOtpVerification();
              }}
            >
              {this.props.isLoading ? "Loading..." : "CONTINUE"}
            </Button>
          </Col>
        </Row>

        <Row id="continue-button-row" hidden={!this.state.isUserGuest}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="regular-col">
            <Button
              type="submit"
              disabled={!this.state.otpFormIsValid || this.props.isLoading}
              style={{ fontWeight: "normal", width: "100%" }}
              onClick={(e) => {
                e.preventDefault();
                this.callGuestSignUp(false, true);
              }}
            >
              {this.props.isLoading ? "Loading..." : "CONTINUE"}
            </Button>
          </Col>
        </Row>
      </Form>
    );

    const guestForm = (
      <form onSubmit={() => this.callGuestSignUp(true, false)}>
        {this.state.error ? (
          <Alert variant="danger">Failed to Send OTP, Try again</Alert>
        ) : (
          ""
        )}

        <Alert variant="primary" style={{ marginBottom: "50px" }}>
          Hi{" "}
          {this.state.guestForm.firstName &&
            `${this.state.guestForm.firstName.toUpperCase()} ${this.state.guestForm.lastName.toUpperCase()}`}
          , you currently exist on our platform. Would you like to register as a
          Quickteller Paypoint Agent with the existing details?
        </Alert>

        <div hidden={!this.state.guestForm.email} className="regular-row">
          <div className="regular-col">
            <div className="form-group">
              <label style={{ lineHeight: "19px" }}>
                <Text
                  style={{
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    fontWeight: "bold",
                  }}
                >
                  Email:
                </Text>
              </label>
              <Text
                style={{
                  float: "right",
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                {this.state.guestForm.email && this.state.guestForm.email}
              </Text>
            </div>
          </div>
        </div>

        <div className="regular-row">
          <div className="regular-col">
            <div className="form-group">
              <label style={{ lineHeight: "19px" }}>
                <Text
                  style={{
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    fontWeight: "bold",
                  }}
                >
                  Phone Number:
                </Text>
              </label>
              <Text
                style={{
                  float: "right",
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                {this.state.guestForm.mobileNo &&
                  formatPhoneNumberWithCountryCode(
                    this.state.guestForm.mobileNo
                  )}
              </Text>
            </div>
          </div>
        </div>

        <div
          id="continue-button-row"
          style={{
            margin: "20px 0px 0px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
          hidden={this.state.showOtpField}
        >
          <div
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="regular-col"
            onClick={() => this.backToLogin()}
            style={{ marginTop: "8px", fontWeight: "bold", cursor: "pointer" }}
          >
            BACK
          </div>
          <div xs={12} sm={12} md={12} lg={12} xl={12} className="regular-col">
            <Button
              type="submit"
              disabled={this.props.isLoading}
              style={{ fontWeight: "normal", width: "100%" }}
              onClick={(e) => {
                e.preventDefault();
                this.callGuestSignUp(true, false);
              }}
            >
              {this.props.isLoading ? "Loading..." : "CONTINUE"}
            </Button>
          </div>
        </div>

        <div
          id="continue-button-row"
          style={{
            margin: "20px 0px 0px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
          hidden={!this.state.showOtpField}
        >
          <div xs={12} sm={12} md={12} lg={12} xl={12} className="regular-col">
            <Button
              type="submit"
              style={{ fontWeight: "normal", width: "100%" }}
              onClick={(e) => {
                e.preventDefault();
                this.backToLogin();
              }}
            >
              LOGIN
            </Button>
          </div>
          <div xs={12} sm={12} md={12} lg={12} xl={12} className="regular-col">
            <Button
              type="submit"
              disabled={
                !isOtpValid(this.state.guestForm) || this.props.isLoading
              }
              style={{ fontWeight: "normal", width: "100%" }}
              onClick={(e) => {
                e.preventDefault();
                this.callGuestSignUp(false, true);
              }}
            >
              {this.props.isLoading ? "Loading..." : "CONTINUE"}
            </Button>
          </div>
        </div>
      </form>
    );

    const changePasswordForm = (
      <Form>
        <H1
          style={{ textAlign: "center", margin: 0, textTransform: "uppercase" }}
        >
          Change Password
        </H1>

        <center>
          <HR style={{ marginTop: "10px" }} />
        </center>
        <form onSubmit={this.changePassword}>
          <Row className="regular-row">
            <Col className="regular-col">
              <Form.Group>
                <FormLabel
                  style={{
                    color: `${
                      !this.state.changePasswordForm.oldPassword ||
                      isPasswordValid(this.state.changePasswordForm.oldPassword)
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  Old Password or PIN:
                </FormLabel>
                <FormControl
                  autoComplete="new-password"
                  id="password-field"
                  ref={this.passwordRef}
                  type={this.state.hidePassword ? "password" : "text"}
                  placeholder="Old Password"
                  onBlur={() =>
                    this.setState({
                      fieldsFocused: [
                        ...this.state.fieldsFocused,
                        "old-password",
                      ],
                    })
                  }
                  onChange={(event) => {
                    const oldPassword = event.target.value;
                    this.updateChangePasswordFormField({ oldPassword });
                  }}
                  style={{
                    border: `1px solid ${
                      !this.state.changePasswordForm.oldPassword ||
                      isPasswordValid(this.state.changePasswordForm.oldPassword)
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                  }}
                />

                <img
                  className="hide-password-toggle"
                  style={{
                    marginLeft: this.state.deviceIsPC ? "265px" : "90%",
                  }}
                  src={
                    this.state.hidePassword
                      ? "/assets/media/icons/eye.svg"
                      : "/assets/media/icons/eye-hidden.svg"
                  }
                  onClick={() =>
                    this.setState({ hidePassword: !this.state.hidePassword })
                  }
                  alt=""
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="regular-row">
            <Col className="regular-col">
              <Form.Group>
                <FormLabel
                  style={{
                    color: `${
                      !this.state.changePasswordForm.newPassword ||
                      isPasswordValid(this.state.changePasswordForm.newPassword)
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  New Password or PIN:
                </FormLabel>
                <FormControl
                  autoComplete="new-password"
                  id="password-field"
                  ref={this.passwordRef}
                  type={this.state.hideNewPassword ? "password" : "text"}
                  placeholder="New Password"
                  onBlur={() =>
                    this.setState({
                      fieldsFocused: [
                        ...this.state.fieldsFocused,
                        "new-password",
                      ],
                    })
                  }
                  onChange={(event) => {
                    const newPassword = event.target.value;
                    this.updateChangePasswordFormField({ newPassword });
                  }}
                  style={{
                    border: `1px solid ${
                      !this.state.changePasswordForm.newPassword ||
                      isPasswordValid(this.state.changePasswordForm.newPassword)
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                  }}
                />

                <img
                  className="hide-password-toggle"
                  style={{
                    marginLeft: this.state.deviceIsPC ? "265px" : "90%",
                  }}
                  src={
                    this.state.hideNewPassword
                      ? "/assets/media/icons/eye.svg"
                      : "/assets/media/icons/eye-hidden.svg"
                  }
                  onClick={() =>
                    this.setState({
                      hideNewPassword: !this.state.hideNewPassword,
                    })
                  }
                  alt=""
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="regular-row">
            <Col className="regular-col">
              <Form.Group>
                <FormLabel
                  style={{
                    color: `${
                      !this.state.changePasswordForm.confirmPassword ||
                      this.isPasswordSame(
                        this.state.changePasswordForm.confirmPassword
                      )
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  Confirm Password or PIN:
                </FormLabel>
                <FormControl
                  autoComplete="new-password"
                  id="password-field"
                  ref={this.passwordRef}
                  type={this.state.hideConfirmPassword ? "password" : "text"}
                  placeholder="Confirm Password"
                  onBlur={() =>
                    this.setState({
                      fieldsFocused: [
                        ...this.state.fieldsFocused,
                        "confirm-password",
                      ],
                    })
                  }
                  onChange={(event) => {
                    const confirmPassword = event.target.value;
                    this.updateChangePasswordFormField({ confirmPassword });
                  }}
                  style={{
                    border: `1px solid ${
                      !this.state.changePasswordForm.confirmPassword ||
                      this.isPasswordSame(
                        this.state.changePasswordForm.confirmPassword
                      )
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                  }}
                />

                <img
                  className="hide-password-toggle"
                  style={{
                    marginLeft: this.state.deviceIsPC ? "265px" : "90%",
                  }}
                  src={
                    this.state.hideConfirmPassword
                      ? "/assets/media/icons/eye.svg"
                      : "/assets/media/icons/eye-hidden.svg"
                  }
                  onClick={() =>
                    this.setState({
                      hideConfirmPassword: !this.state.hideConfirmPassword,
                    })
                  }
                  alt=""
                />
              </Form.Group>
            </Col>
          </Row>

          <div
            id="continue-button-row"
            style={{
              margin: "20px 0px 0px 0px",
              display: "flex",
              justifyContent: "space-between",
            }}
            hidden={this.state.showOtpField}
          >
            <div
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="regular-col"
              onClick={() => this.backToLogin()}
              style={{
                marginTop: "8px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              BACK
            </div>
            <div
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="regular-col"
            >
              <Button
                type="submit"
                disabled={
                  !this.isChangePasswordFormValid() || this.props.isLoading
                }
                style={{ fontWeight: "normal", width: "100%" }}
                onClick={(e) => {
                  e.preventDefault();
                  this.changePassword();
                }}
              >
                {this.props.isLoading ? "Loading..." : "CONTINUE"}
              </Button>
            </div>
          </div>
        </form>
      </Form>
    );

    const resetPasswordForm = (
      <Form>
        <H1
          style={{ textAlign: "center", margin: 0, textTransform: "uppercase" }}
        >
          Reset Password
        </H1>

        <center>
          <HR style={{ marginTop: "10px" }} />
        </center>
        <form onSubmit={this.resetPassword}>
          <Row className="regular-row">
            <Col className="regular-col">
              <Form.Group>
                <FormLabel
                  style={{
                    color: `${
                      !this.state.resetPasswordForm.oldPassword ||
                      isPasswordValid(this.state.resetPasswordForm.oldPassword)
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  Old Password or PIN:
                </FormLabel>
                <FormControl
                  id="password-field"
                  ref={this.passwordRef}
                  type={this.state.hidePassword ? "password" : "text"}
                  placeholder="Old Password"
                  onBlur={() =>
                    this.setState({
                      fieldsFocused: [
                        ...this.state.fieldsFocused,
                        "old-password",
                      ],
                    })
                  }
                  onChange={(event) => {
                    const oldPassword = event.target.value;
                    this.updateResetPasswordFormField({ oldPassword });
                  }}
                  style={{
                    border: `1px solid ${
                      !this.state.resetPasswordForm.oldPassword ||
                      isPasswordValid(this.state.resetPasswordForm.oldPassword)
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                  }}
                />

                <img
                  className="hide-password-toggle"
                  style={{
                    marginLeft: this.state.deviceIsPC ? "265px" : "90%",
                  }}
                  src={
                    this.state.hidePassword
                      ? "/assets/media/icons/eye.svg"
                      : "/assets/media/icons/eye-hidden.svg"
                  }
                  onClick={() =>
                    this.setState({ hidePassword: !this.state.hidePassword })
                  }
                  alt=""
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="regular-row">
            <Col className="regular-col">
              <Form.Group>
                <FormLabel
                  style={{
                    color: `${
                      !this.state.resetPasswordForm.newPassword ||
                      isPasswordValid(this.state.resetPasswordForm.newPassword)
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  New Password or PIN:
                </FormLabel>
                <FormControl
                  autoComplete="new-password"
                  id="password-field"
                  ref={this.passwordRef}
                  type={this.state.hidePassword ? "password" : "text"}
                  placeholder="New Password"
                  onBlur={() =>
                    this.setState({
                      fieldsFocused: [
                        ...this.state.fieldsFocused,
                        "new-password",
                      ],
                    })
                  }
                  onChange={(event) => {
                    const newPassword = event.target.value;
                    this.updateResetPasswordFormField({ newPassword });
                  }}
                  style={{
                    border: `1px solid ${
                      !this.state.resetPasswordForm.newPassword ||
                      isPasswordValid(this.state.resetPasswordForm.newPassword)
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                  }}
                />

                <img
                  className="hide-password-toggle"
                  style={{
                    marginLeft: this.state.deviceIsPC ? "265px" : "90%",
                  }}
                  src={
                    this.state.hidePassword
                      ? "/assets/media/icons/eye.svg"
                      : "/assets/media/icons/eye-hidden.svg"
                  }
                  onClick={() =>
                    this.setState({ hidePassword: !this.state.hidePassword })
                  }
                  alt=""
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="regular-row">
            <Col className="regular-col">
              <Form.Group>
                <FormLabel
                  style={{
                    color: `${
                      !this.state.resetPasswordForm.confirmPassword ||
                      this.arePasswordsTheSame(
                        this.state.resetPasswordForm.newPassword,
                        this.state.resetPasswordForm.confirmPassword
                      )
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  Confirm Password or PIN:
                </FormLabel>
                <FormControl
                  autoComplete="new-password"
                  id="password-field"
                  ref={this.passwordRef}
                  type={this.state.hideConfirmPassword ? "password" : "text"}
                  placeholder="Confirm Password"
                  onBlur={() =>
                    this.setState({
                      fieldsFocused: [
                        ...this.state.fieldsFocused,
                        "confirm-password",
                      ],
                    })
                  }
                  onChange={(event) => {
                    const confirmPassword = event.target.value;
                    this.updateResetPasswordFormField({ confirmPassword });
                  }}
                  style={{
                    border: `1px solid ${
                      !this.state.resetPasswordForm.confirmPassword ||
                      this.arePasswordsTheSame(
                        this.state.resetPasswordForm.newPassword,
                        this.state.resetPasswordForm.confirmPassword
                      )
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                  }}
                />

                <img
                  className="hide-password-toggle"
                  style={{
                    marginLeft: this.state.deviceIsPC ? "265px" : "90%",
                  }}
                  src={
                    this.state.hideConfirmPassword
                      ? "/assets/media/icons/eye.svg"
                      : "/assets/media/icons/eye-hidden.svg"
                  }
                  onClick={() =>
                    this.setState({
                      hideConfirmPassword: !this.state.hideConfirmPassword,
                    })
                  }
                  alt=""
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="regular-row" hidden>
            <Col className="regular-col">
              <Form.Group>
                <FormLabel
                  style={{
                    color: `${
                      !this.state.resetPasswordForm.otp ||
                      isOtpValid(this.state.resetPasswordForm)
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  OTP
                </FormLabel>
                <FormControl
                  id="otp-field"
                  ref={this.passwordRef}
                  placeholder="OTP"
                  onBlur={() =>
                    this.setState({
                      fieldsFocused: [...this.state.fieldsFocused, "otp"],
                    })
                  }
                  onChange={(event) => {
                    const otp = event.target.value;
                    this.updateResetPasswordFormField({ otp });
                  }}
                  style={{
                    background: "none",
                    border: `1px solid ${
                      !this.state.resetPasswordForm.otp ||
                      isOtpValid(this.state.resetPasswordForm)
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                  }}
                />
              </Form.Group>
            </Col>
          </Row>

          <div
            id="continue-button-row"
            style={{
              margin: "20px 0px 0px 0px",
              display: "flex",
              justifyContent: "space-between",
            }}
            hidden={this.state.showOtpField}
          >
            <div
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="regular-col"
              onClick={() => this.backToLogin()}
              style={{
                marginTop: "8px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              BACK
            </div>
            <div
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="regular-col"
            >
              <Button
                type="submit"
                disabled={
                  !this.isResetPasswordFormValid() || this.props.isLoading
                }
                style={{ fontWeight: "normal", width: "100%" }}
                onClick={(e) => {
                  e.preventDefault();
                  this.resetPassword();
                }}
              >
                {this.props.isLoading ? "Loading..." : "CONTINUE"}
              </Button>
            </div>
          </div>
        </form>
      </Form>
    );

    const { emailOrPhone } = this.state.form;
    const setPasswordForm = (
      <Form>
        <H1
          style={{ textAlign: "center", margin: 0, textTransform: "uppercase" }}
        >
          Set Password
        </H1>

        <center>
          <HR style={{ marginTop: "10px" }} />
        </center>
        <form onSubmit={(e) => this.resetPasswordPhone(e)}>
          <Row className="regular-row">
            <Col className="regular-col">
              <Form.Group>
                <FormLabel
                  style={{
                    color: `${
                      !this.state.setPasswordForm.otp ||
                      isOtpValid(this.state.setPasswordForm)
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  OTP
                </FormLabel>
                <FormControl
                  id="otp-field"
                  ref={this.passwordRef}
                  placeholder="OTP"
                  onBlur={() =>
                    this.setState({
                      fieldsFocused: [...this.state.fieldsFocused, "otp"],
                    })
                  }
                  onChange={(event) => {
                    const otp = event.target.value;
                    this.setPasswordFormField({ otp });
                  }}
                  style={{
                    background: "none",
                    border: `1px solid ${
                      !this.state.setPasswordForm.otp ||
                      isOtpValid(this.state.setPasswordForm)
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                  }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="regular-row">
            <Col className="regular-col">
              <Form.Group>
                <FormLabel
                  style={{
                    color: `${
                      !this.state.setPasswordForm.password ||
                      isPasswordValid(this.state.setPasswordForm.password)
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  Password or PIN:
                </FormLabel>
                <FormControl
                  id="password-field"
                  ref={this.passwordRef}
                  type={this.state.hidePassword ? "password" : "text"}
                  placeholder="Enter Password"
                  onBlur={() =>
                    this.setState({
                      fieldsFocused: [...this.state.fieldsFocused, "password"],
                    })
                  }
                  onChange={(event) => {
                    const password = event.target.value;
                    this.setPasswordFormField({ password });
                  }}
                  style={{
                    border: `1px solid ${
                      !this.state.setPasswordForm.password ||
                      isPasswordValid(this.state.setPasswordForm.password)
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                  }}
                />

                <img
                  className="hide-password-toggle"
                  style={{
                    marginLeft: this.state.deviceIsPC ? "265px" : "90%",
                  }}
                  src={
                    this.state.hidePassword
                      ? "/assets/media/icons/eye.svg"
                      : "/assets/media/icons/eye-hidden.svg"
                  }
                  onClick={() =>
                    this.setState({ hidePassword: !this.state.hidePassword })
                  }
                  alt=""
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="regular-row">
            <Col className="regular-col">
              <Form.Group>
                <FormLabel
                  style={{
                    color: `${
                      !this.state.setPasswordForm.confirmPassword ||
                      this.arePasswordsTheSame(
                        this.state.setPasswordForm.password,
                        this.state.setPasswordForm.confirmPassword
                      )
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  Confirm Password or PIN:
                </FormLabel>
                <FormControl
                  autoComplete="new-password"
                  id="password-field"
                  ref={this.passwordRef}
                  type={this.state.hideConfirmPassword ? "password" : "text"}
                  placeholder="Confirm Password"
                  onBlur={() =>
                    this.setState({
                      fieldsFocused: [
                        ...this.state.fieldsFocused,
                        "confirm-password",
                      ],
                    })
                  }
                  onChange={(event) => {
                    const confirmPassword = event.target.value;
                    this.setPasswordFormField({ confirmPassword });
                  }}
                  style={{
                    border: `1px solid ${
                      !this.state.setPasswordForm.confirmPassword ||
                      this.arePasswordsTheSame(
                        this.state.setPasswordForm.password,
                        this.state.setPasswordForm.confirmPassword
                      )
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                  }}
                />

                <img
                  className="hide-password-toggle"
                  style={{
                    marginLeft: this.state.deviceIsPC ? "265px" : "90%",
                  }}
                  src={
                    this.state.hideConfirmPassword
                      ? "/assets/media/icons/eye.svg"
                      : "/assets/media/icons/eye-hidden.svg"
                  }
                  onClick={() =>
                    this.setState({
                      hideConfirmPassword: !this.state.hideConfirmPassword,
                    })
                  }
                  alt=""
                />
              </Form.Group>
            </Col>
          </Row>

          <div
            id="continue-button-row"
            style={{
              margin: "20px 0px 0px 0px",
              display: "flex",
              justifyContent: "space-between",
            }}
            hidden={this.state.showOtpField}
          >
            {/* <div
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="regular-col"
              onClick={() => this.backToLogin()}
              style={{
                marginTop: "8px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              BACK
            </div> */}

            <Row className="regular-row">
              <Col className="regular-col">
                <div
                  id="resend-otp-link"
                  style={{
                    color: process.env.REACT_APP_LINK_COLOUR,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    margin: "10px 10px 10px 10px",
                  }}
                >
                  <Link onClick={() => this.callRecoverPassword(emailOrPhone)}>
                    Resend OTP
                  </Link>
                </div>
              </Col>
            </Row>

            <div
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="regular-col"
            >
              <Button
                type="submit"
                disabled={
                  !this.isSetPasswordFormValid() || this.props.isLoading
                }
                style={{ fontWeight: "normal", width: "100%" }}
              >
                {this.props.isLoading ? "Loading..." : "CONTINUE"}
              </Button>
            </div>
          </div>
        </form>
      </Form>
    );
    const carousel = (
      <Carousel
        // activeIndex={this.state.doDeviceAuth ? 1 : this.state.isUserGuest ? 2 : this.state.verifyUserDevice ? 3 : this.state.verifyUserMobile ? 1 : this.state.changePassword ? 4 : this.state.resetPassword ? 5 : 0}
        activeIndex={
          this.state.showUserProfile
            ? 1
            : this.state.doDeviceAuth
            ? 2
            : this.state.isUserGuest
            ? 3
            : this.state.verifyUserDevice
            ? 4
            : this.state.verifyUserMobile
            ? 2
            : this.state.changePassword
            ? 5
            : this.state.resetPassword
            ? 6
            : this.state.setPassword
            ? 7
            : this.state.selectNewUser
            ? 9
            : 0
        }
        controls={false}
        disabled
        indicators={false}
        interval={null}
        style={{
          transition: "all .2s ease-in-out",
        }}
      >
        <Carousel.Item>{newLoginForm}</Carousel.Item>
        <Carousel.Item>{selectUserProfileForm}</Carousel.Item>
        <Carousel.Item>{otpForm}</Carousel.Item>
        <Carousel.Item>{guestForm}</Carousel.Item>
        <Carousel.Item>{otpForm}</Carousel.Item>
        <Carousel.Item>{changePasswordForm}</Carousel.Item>
        <Carousel.Item>{resetPasswordForm}</Carousel.Item>
        <Carousel.Item>{setPasswordForm}</Carousel.Item>
      </Carousel>
    );

    const desktopContainer = (
      <Container id="desktop-container" fluid>
        <Row style={{ margin: 0, padding: 0 }}>
          <Col
            xs={12}
            md={12}
            lg={6}
            style={{
              margin: 0,
              padding: 0,
              display: "flex",
              justifyContent: this.state.deviceIsPC ? "flex-start" : "center",
              marginLeft: this.state.deviceIsPC ? "10%" : "0%",
            }}
          >
            <div id="carousel-container">{carousel}</div>
          </Col>
        </Row>
      </Container>
    );

    const mobileContainer = (
      <Container id="mobile-container" fluid>
        {carousel}
      </Container>
    );

    return (
      <div
        id="login-main-container"
        style={{
          backgroundImage: this.state.deviceIsPC
            ? "url('https://mufasa.interswitchng.com/p/quickteller/Content/Images/QT_paypoint_bg.png')"
            : "",
          backgroundSize: "contain",
        }}
      >
        <Header
          hideLogin={true}
          transparent={true}
          outerDivStyle={{ backgroundColor: "white" }}
        />

        {this.state.deviceIsMobile ? mobileContainer : desktopContainer}

        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.tunnel.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dismissErrorMessage: () => dispatch(dismissErrorMessage()),
    dismissSuccessMessage: () => dispatch(dismissSuccessMessage()),
    showErrorMessage: (message) => dispatch(showErrorMessage(message)),
    showSuccessMessage: (message) => dispatch(showSuccessMessage(message)),
    updateLoading: (isLoading, percentage = null) =>
      dispatch(updateLoading({ isLoading, percentage })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
