import React from "react";
import MdArrowBack from "react-ionicons/lib/MdArrowBack";

import { Col } from "react-bootstrap";
import ActivityIndicator from "../../../../../components/activity-indicator";
import H1 from "../../../../../components/h1";
import { CASH_IN } from "../../../../../constants";
import { ERROR_STATUS } from "../../../../../constants/api";
import CashInServiceId from "../../../../../fixtures/services_id";
import Catalog from "../../../../../services/api/resources/catalog";
import Quickteller from "../../../../../services/api/resources/quickteller";
import BaseScene from "../../../../base-scene";
import ProductPayment from "../components/product-payment";
import ProductThumbnail from "../components/product-thumbnail";

const serviceId = CashInServiceId[process.env.REACT_APP_ENVIRONMENT];

class ServicesTab extends BaseScene {
  state = {
    isLoading: true,
    services: [],
  };

  quickteller = new Quickteller();
  catalog = new Catalog();

  constructor(props) {
    super(props);

    this.onBackButtonClick = this.onBackButtonClick.bind(this);
  }

  componentDidMount() {
    this.getServices();
  }

  async getServices() {
    const getServicesResponseObj = await this.quickteller.getServices(
      serviceId.id
    );
    const { status, response } = getServicesResponseObj;

    if (status === ERROR_STATUS) {
      this.props.showErrorMessage("Unable to Fetch Service, Try again");
      setTimeout(() => {
        this.props.dismissErrorMessage();
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
      this.setState({
        isLoading: false,
        erroroccurred: true,
      });

      return;
    }
    this.setState({
      isLoading: false,
      services: response.services,
    });
  }

  onBackButtonClick() {
    this.props.onBackButtonClick();
  }

  refresh() {
    this.setState({
      isLoading: true,
      services: [],
    });

    this.getServices();
  }

  render() {
    return (
      <section
        className={`services-tab${this.props.deviceIsMobile ? "-mobile" : ""}`}
      >
        <H1
          style={{
            marginBottom: "10px",
            marginTop: "0px",
            textAlign: "left",
            width: "100vw",
            display: "flex",
            alignItems: "center",
            paddingLeft: "20px",
          }}
        >
          {this.state.deviceIsMobile && (
            <div
              style={{
                alignItems: "center",
                display: "flex",
                marginRight: "10px",
              }}
            >
              <MdArrowBack fontSize="26px" onClick={this.onBackButtonClick} />
            </div>
          )}
          Transfer to MMO
        </H1>
        {this.state.isLoading || !this.state.services ? (
          <ActivityIndicator />
        ) : (
          this.state.services.map((value, index) => (
            <ProductThumbnail
              onSelect={this.props.onSelect}
              product={value}
              key={index}
            />
          ))
        )}
      </section>
    );
  }
}
export default class CashInScene extends React.Component {
  state = {};

  render() {
    return (
      <div>
        {!this.state.selectedService && (
          <Col>
            <ServicesTab
              onSelect={(value) => {
                this.setState({ selectedService: value });
                this.props.onSelect(value);
              }}
              serviceType={this.state.selectedServiceType}
              showErrorMessage={this.props.showErrorMessage}
              dismissErrorMessage={this.props.dismissErrorMessage}
            />
          </Col>
        )}
        {this.state.selectedService && (
          <Col md={12} style={{ marginLeft: "0px", paddingLeft: "0px" }}>
            <ProductPayment
              category={CASH_IN}
              service={this.state.selectedService}
              onCancelProduct={() => this.setState({ selectedService: null })}
            />
          </Col>
        )}
      </div>
    );
  }
}
