import React from "react";
import { AppProvider } from "../../../../../signup/onboarding-setup/context";
import PassengerDetailsScene from "./passengerDetailsScene";
import BookingScene from "./bookingScene";
import AvailableTripsScene from "./availableTripsScene";
import { LandingScene } from "./landingScene";
import RouteScene from "./routeScene";
import { FirstBookingScene } from "./firstBookingScene";
import ReceiptScreen from "./receiptScreen";

class QtTransport extends React.Component {
  constructor(props) {
    super(props);
    this.myElementRef = React.createRef();
    this.state = {
      screen: 1,
      trips: [],
      allPassengers: [],
      passengers: [],
      receiptInfo: [],
      selectedTrip: null,
      isForself: false,
      date: null,
      bookings: null,
      adultCount: 1,
      childCount: 0,
      selectedFromTerminal: null,
      selectedToTerminal: null,
      isLoading: false,
    };
  }

  setScreen = (newScreen) => {
    this.setState({ screen: newScreen });
  };
  setBookings = (newBookings) => {
    this.setState({ bookings: newBookings });
  };
  setTrips = (newTrips) => {
    this.setState({ trips: newTrips });
  };
  setDate = (newDate) => {
    this.setState({ date: newDate });
  };
  setAdultCount = (newAdultCount) => {
    this.setState({ adultCount: newAdultCount });
  };
  setChildCount = (newChildCount) => {
    this.setState({ childCount: newChildCount });
  };
  setSelectedToTerminal = (newSelectedToTerminal) => {
    this.setState({ selectedToTerminal: newSelectedToTerminal });
  };
  setSelectedFromTerminal = (newSelectedFromTerminal) => {
    this.setState({ selectedFromTerminal: newSelectedFromTerminal });
  };
  setSelectedTrip = (newSelectedTrip) => {
    this.setState({ selectedTrip: newSelectedTrip });
  };
  setIsForself = (newIsForself) => {
    this.setState({ isForself: newIsForself });
  };

  setAllPassengers = (newAllPassengers) => {
    this.setState({ allPassengers: newAllPassengers });
  };
  setPassengers = (newPassengers) => {
    this.setState({ passengers: newPassengers });
  };
  setReceiptInfo = (newReceiptInfo) => {
    this.setState({ receiptInfo: newReceiptInfo });
  };

  render() {
    const { screen } = this.state;

    return (
      <AppProvider>
        {screen === 1 && (
          <LandingScene
            setScreen={this.setScreen}
            isLoading={this.state.isLoading}
          />
        )}
        {screen === 2 && (
          <RouteScene
            setIsForself={this.setIsForself}
            setScreen={this.setScreen}
            isLoading={this.state.isLoading}
          />
        )}
        {screen === 3 && (
          <FirstBookingScene
            date={this.state.date}
            setDate={this.setDate}
            setScreen={this.setScreen}
            setTrips={this.setTrips}
            setAdultCount={this.setAdultCount}
            setSelectedToTerminal={this.setSelectedToTerminal}
            setSelectedFromTerminal={this.setSelectedFromTerminal}
            selectedFromTerminal={this.state.selectedFromTerminal}
            selectedToTerminal={this.state.selectedToTerminal}
            setChildCount={this.setChildCount}
            childCount={this.state.childCount}
            adultCount={this.state.adultCount}
            isLoading={this.state.isLoading}
          />
        )}
        {screen === 4 && (
          <AvailableTripsScene
            setSelectedTrip={this.setSelectedTrip}
            childCount={this.state.childCount}
            selectedToTerminal={this.state.selectedToTerminal}
            selectedFromTerminal={this.state.selectedFromTerminal}
            date={this.state.date}
            trips={this.state.trips}
            adultCount={this.state.adultCount}
            setScreen={this.setScreen}
            isLoading={this.state.isLoading}
          />
        )}
        {screen === 5 && (
          <PassengerDetailsScene
            setScreen={this.setScreen}
            setBookings={this.setBookings}
            setAllPassengers={this.setAllPassengers}
            allPassengers={this.state.allPassengers}
            setPassengers={this.setPassengers}
            isForself={this.state.isForself}
            isLoading={this.state.isLoading}
            childCount={this.state.childCount}
            adultCount={this.state.adultCount}
            selectedTrip={this.state.selectedTrip}
          />
        )}

        {screen === 6 && (
          <BookingScene
            setReceiptInfo={this.setReceiptInfo}
            passengers={this.state.passengers}
            selectedTrip={this.state.selectedTrip}
            bookings={this.state.bookings}
            setScreen={this.setScreen}
            allPassengers={this.state.allPassengers}
            childCount={this.state.childCount}
            adultCount={this.state.adultCount}
            isLoading={this.state.isLoading}
          />
        )}
        {screen === 7 && (
          <ReceiptScreen
            bookings={this.state.receiptInfo}
            setScreen={this.setScreen}
          />
        )}
      </AppProvider>
    );
  }
}

export default QtTransport;
