import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import HeaderSignup from "../../../../components/header-signup";
import "../styles.scss";
import { HandRejectIcon } from "../../../../icons";

export default function InsufficientScene({ setScreen }) {
  const history = useHistory();

  return (
    <>
      <HeaderSignup />
      <main className="onboarding-container">
        <div style={{ padding: "2rem", alignContent: "center" }}>
          <div
            style={{
              display: "grid",
              placeContent: "center",
              marginBottom: "2rem",
            }}
          >
            {HandRejectIcon()}
          </div>
          <h1
            style={{
              fontWeight: "bold",
              marginBottom: "1rem",
              fontSize: "1.5rem",
              textAlign: "center",
            }}
          >
            Insufficient Funds
          </h1>
          <p
            style={{
              color: "grey",
              fontWeight: "200",
              textAlign: "center",
              fontSize: "15px",
            }}
          >
            Funds insufficient to process this payment.
          </p>
          <button
            className="signupButton2"
            style={{
              marginTop: "4rem",
              textAlign: "center",
              color: "#fff",
              backgroundColor: "#00425f",
            }}
            onClick={() => {
              history.push("/fund-wallet");
            }}
          >
            {" "}
            Fund Now{" "}
          </button>
          <button
            className="signupButton2"
            style={{
              marginTop: "1rem",
              textAlign: "center",
              color: "#000",
            }}
            onClick={() => setScreen(7)}
          >
            {" "}
            Pay Later{" "}
          </button>
        </div>
      </main>
    </>
  );
}
