import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

import BackButton from "../../onboarding-setup/component/backButton";
import PrimaryButton from "../../onboarding-setup/component/button";
import ProfileUpgradeCTA2 from "../../onboarding-setup/component/profileUpgradeCTA2";
import SkeletonLoading from "../../onboarding-setup/component/skeletonLoading";
import H1 from "../../../../components/h1";
import { BadgeIcon } from "../../../../icons";
import Platform from "../../../../services/api/resources/platform";
import { notify } from "../../../../components/extras";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../constants/api";

const SetupNewAgentScene2 = ({
  setScreen,
  props,
  application,
  fetchApplication,
  isLoading,
}) => {
  const agentDetails = JSON.parse(localStorage.getItem("agentDetails"));
  const currentAgent = JSON.parse(localStorage.getItem("currentAgent"));

  let modifiedAgentDetails = JSON.parse(
    localStorage.getItem("modifiedAgentDetails")
  );
  const history = useHistory();

  const platform = new Platform();

  let personalDetailsForm = JSON.parse(
    localStorage.getItem("personalDetailsFormUpgrade")
  );

  let kycDetailsForm = JSON.parse(
    localStorage.getItem("kycDetailsFormUpgrade")
  );
  let businessDetailsForm = JSON.parse(
    localStorage.getItem("businessDetailsFormUpgrade")
  );
  let refereeDetailsForm = JSON.parse(
    localStorage.getItem("refereeDetailsFormUpgrade")
  );

  const handleKYC = () => {
    if (
      personalDetailsForm?.motherMaidenName &&
      businessDetailsForm.accountNumber
    ) {
      setScreen(4);
    }
  };
  const handleBussinessDetails = () => {
    if (personalDetailsForm?.motherMaidenName) {
      setScreen(3);
    }
  };

  const handlerefereeDetails = () => {
    if (
      personalDetailsForm?.motherMaidenName &&
      businessDetailsForm.accountNumber
      // kycDetailsForm.idNumber
    ) {
      setScreen(5);
    }
  };

  const navigateToOverview = () => {
    history.goBack();
  };
  const goToRef = async () => {
    const agentCode = currentAgent?.agentCode;
    const { status, response } = await platform.postExpireRefereeLink(
      agentCode
    );

    if (status === ERROR_STATUS) {
      notify(response?.description, "error");
    } else if (status === SUCCESS_STATUS) {
      notify(response?.responseMessage, "success");
      setScreen(5);
    }
    // setScreen(5)
  };

  const finalSubmit = async () => {
    const agentCode = currentAgent?.agentCode;
    const { status, response } = await platform.approveAttestation(agentCode);

    if (status === ERROR_STATUS) {
      notify(response?.description, "error");
    } else if (status === SUCCESS_STATUS) {
      notify(
        `Request Submitted, \n Your request has been submitted and is now pending approval.`,
        "success"
      );
      history.push("/agent/dashboard");
    }
  };

  useEffect(() => {
    if (agentDetails && !personalDetailsForm) {
      fetchApplication();
    }
  }, []);

  const conditionRender = () => {
    if (
      modifiedAgentDetails &&
      modifiedAgentDetails?.content[0]?.status === 5
    ) {
      return {
        text: "Pending Attestation",
        button: <PrimaryButton disabled={true}>Submit</PrimaryButton>,
        errMessage:
          "Your attestation is taking longer than usual. You can edit to add another Referee",
        edit: (
          <span style={{ cursor: "pointer" }} onClick={() => goToRef()}>
            Edit
          </span>
        ),
      };
    } else if (modifiedAgentDetails?.content[0]?.status === 6) {
      return {
        text: "",
        button: (
          <PrimaryButton onClick={() => finalSubmit()}>Submit</PrimaryButton>
        ),
      };
    }
    // else if (modifiedAgentDetails?.content[0]?.status === 4) {
    //     return {
    //         text: <span style={{ color: 'green' }}>Approved</span>,
    //         button: <PrimaryButton>Submit</PrimaryButton>,
    //     }
    // }
    else if (modifiedAgentDetails?.content[0]?.status === 2) {
      return {
        text: "Pending Approval",
        button: "",
      };
    }
  };
  const handleBackButton = () => {
    if (application) {
      // props.navigateTo(`/agents/my-agents/upgrade/upgradeClass`);
      props.navigateTo(`/manage-profile/upgrade-list`);

      localStorage.removeItem("application");
      localStorage.removeItem("businessType");
      localStorage.removeItem("personalDetailsFormUpgrade");
      localStorage.removeItem("kycDetailsFormUpgrade");
      localStorage.removeItem("businessDetailsFormUpgrade");
    } else {
      // props.navigateTo(`/agents/my-agents/upgrade/upgradeClass`);
      props.navigateTo(`/manage-profile/upgrade-list`);
    }
  };

  const Loading = () => {
    return (
      <div
        style={{
          marginBottom: "25px",
        }}
      >
        {" "}
        <SkeletonLoading width={"100%"} height={"15px"} />{" "}
        <SkeletonLoading width={"100%"} height={"15px"} />
      </div>
    );
  };

  return (
    <>
      <BackButton onClick={navigateToOverview} />
      <main className="aggregator-container">
        <div>
          <H1
            style={{
              textAlign: "left",
              marginBottom: "1rem",
            }}
          >
            Upgrade to Standard
          </H1>
          <p
            style={{
              color: "#6B7280",
              fontSize: "0.75rem",
              marginBottom: "3rem",
            }}
          >
            The following details below are required to upgrade your account.
          </p>
        </div>

        {/* <div style={{
                    padding: '1rem 1.5rem',
                    borderRadius: '8px',
                    border: `1px solid #A8D6EF`,
                    marginBottom: '20px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    backgroundColor: '#EBF8FE'
                }}>
                    <div>
                        <p
                            style={{
                                fontSize: '0.875rem',
                                margin: 0,
                                fontWeight: 600,
                                color: '#1F2126',
                                marginBottom: '10px',
                            }}
                        >
                            Standard Account
                        </p>
                        <p
                            style={{
                                fontSize: '0.775rem',
                                margin: 0,
                                color: '#353F50',
                            }}
                        >
                            Please fill the information below to upgrade to a standard account.
                        </p>
                    </div>
                    <div>
                        {BadgeIcon()}
                    </div>

                </div> */}

        {isLoading ? (
          <Loading />
        ) : (
          <ProfileUpgradeCTA2
            application={application}
            // onClick={() => setScreen(2)}
            // actionColor={
            //     personalDetailsForm
            //         ? personalDetailsForm?.motherMaidenName
            //         : false
            // }
            title={"Personal Details"}
            checkClickableTile={
              personalDetailsForm ? personalDetailsForm?.motherMaidenName : true
            }
            isLoading={isLoading}
            sub={`Provide your residential address, maiden name, state and LGA`}
            errMessage={``}
            text={
              conditionRender()?.text === "Pending Attestation"
                ? ""
                : conditionRender()?.text
            }
          />
        )}

        {isLoading ? (
          <Loading />
        ) : (
          <ProfileUpgradeCTA2
            application={application}
            actionColor={false}
            onClick={() => handleBussinessDetails()}
            checkClickableTile={null}
            title={"Business Details"}
            isLoading={isLoading}
            sub={`Provide your business type and bank information.`}
            errMessage={``}
            text={
              conditionRender()?.text === "Pending Attestation"
                ? ""
                : conditionRender()?.text
            }
          />
        )}

        {isLoading ? (
          <Loading />
        ) : (
          <ProfileUpgradeCTA2
            application={application}
            onClick={() => handleKYC()}
            // onClick={application ? handleKYCApplicationId : handleKYC}
            actionColor={
              kycDetailsForm ? kycDetailsForm.attachedDocuments : false
            }
            title={"KYC Information"}
            checkClickableTile={
              businessDetailsForm ? businessDetailsForm.accountNumber : null
            }
            isLoading={isLoading}
            sub={`Provide your ID information and utility bill.`}
            errMessage={``}
            text={
              conditionRender()?.text === "Pending Attestation"
                ? ""
                : conditionRender()?.text
            }
          />
        )}

        {isLoading ? (
          <Loading />
        ) : (
          <ProfileUpgradeCTA2
            application={application}
            actionColor={
              refereeDetailsForm ? refereeDetailsForm.phoneNumber : false
            }
            onClick={() => handlerefereeDetails()}
            checkClickableTile={
              businessDetailsForm ? businessDetailsForm.accountNumber : null
            }
            title={"Referee Information"}
            edit={conditionRender()?.edit}
            isLoading={isLoading}
            sub={`Provide your referee details here.`}
            errMessage={
              modifiedAgentDetails?.content[0]?.attestationDelayed === true
                ? conditionRender()?.errMessage
                : ""
            }
            text={conditionRender()?.text}
          />
        )}
        <div
          style={{
            margin: "0 auto",
          }}
        >
          {conditionRender()?.button}
        </div>
      </main>
    </>
  );
};

export default SetupNewAgentScene2;
