import React from 'react'
import { Col, Dropdown, Form, Row } from 'react-bootstrap';
import ReactPhoneInput from 'react-phone-input-2';
import MdArrowDropleft from 'react-ionicons/lib/MdArrowDropleft';
import BaseScene from '../../base-scene'
import Button from '../../../components/button'
import FormControl from '../../../components/form-control'
import H1 from '../../../components/h1'
import phoneValidators from '../../../utils/validators/phone-validators';
import Relationships from '../../../fixtures/relationships'
import Text from '../../../components/text';

const NIGERIA_PHONE_PREFIX = '234';
const NIGERIA_SHORT_CODE = 'ng';

class CustomToggle extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();

    this.props.onClick(e);
  }

  render() {
    return (
      <a href="" onClick={this.handleClick}>
        {this.props.children}
      </a>
    );
  }
}

class CustomMenu extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleChange = this.handleChange.bind(this);

    this.state = { value: '' };
  }

  handleChange(e) {
    this.setState({ value: e.target.value.toLowerCase().trim() });
  }

  stripSpacesFromString(str) {
    return str.replace(/ /g, "");
  }

  render() {
    const {
      children,
      style,
      className,
      'aria-labelledby': labeledBy,
    } = this.props;

    const { value } = this.state;

    return (
      <div style={{
        // display: 'flex',
        // flexDirection: 'column',
        height: '150px',
        overflowY: 'auto',
        ...style
      }} className={className} aria-labelledby={labeledBy}>
        <FormControl
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={this.handleChange}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            child =>
              !value || child.props.children.toLowerCase().startsWith(value),
          )}
        </ul>
      </div>
    );
  }
}

export default class NextOfKinDetailsForm extends BaseScene {
  state = {
    countryShortCode: NIGERIA_SHORT_CODE,
    fieldsFocused: [],
    form: {
      phonePrefix: NIGERIA_PHONE_PREFIX,
      sameAsPersonalAddress: false
    },
    previouslySavedFields: {

    },
    relationships: Relationships
  }

  componentDidMount() {
    const currentApplication = JSON.parse(localStorage.getItem('currentApplication'))

    const applicantDetails = currentApplication.applicantDetails.nextOfKin || {}

    const newForm = {
      address: applicantDetails.address,
      firstName: applicantDetails.firstName,
      lastName: applicantDetails.surname,
      gender: applicantDetails.gender,
      relationship: applicantDetails.relationship,
      phone: applicantDetails.phoneNumber,
      phonePrefix: NIGERIA_PHONE_PREFIX
    }

    this.setState({
      form: newForm,
      previouslySavedFields: newForm
    })

    this.props.onUpdateFormField(newForm)
  }

  isAddressValid(newForm) {
    const form = newForm || this.state.form
    return form.address && form.address.length >= 5
  }

  isFirstNameValid(newForm) {
    const form = newForm || this.state.form
    return form.firstName && form.firstName.length > 2
  }

  isLastNameValid(newForm) {
    const form = newForm || this.state.form
    return form.lastName && form.lastName.length > 2
  }

  isPhoneValid(newForm) {
    const form = newForm || this.state.form
    const countryValidator = phoneValidators[this.state.form.phonePrefix]

    return form.phone && (countryValidator ? countryValidator(form.phone) : true)
  }

  isRelationshipValid(newForm) {
    const form = newForm || this.state.form

    return form.relationship && form.relationship.length >= 3
  }

  stripNonNumbers(str) {
    return str.replace(/\D/g, '')
  }

  stripNumbersFromString(str) {
    return str.replace(/[0-9]/g, '')
  }

  stripSpacesFromString(str) {
    return str.replace(/ /g, '')
  }

  toHideContactInformationHeader() {
    return (!this.props.showPreviouslySavedFields &&
      this.state.previouslySavedFields.firstName &&
      this.state.previouslySavedFields.lastName &&
      this.state.previouslySavedFields.gender &&
      this.state.previouslySavedFields.phone &&
      this.state.previouslySavedFields.address &&
      this.state.previouslySavedFields.relationship
    )
  }

  updateFormField(params) {
    const newForm = {
      ...this.state.form,
      ...params
    }

    this.props.onUpdateFormField(newForm)

    this.setState({
      form: newForm
    })
  }

  render() {
    const screenSensitiveStyle = !this.state.deviceIsMobile ? {
      padding: '0px 25px 10px 25px'
    } : {}

    return <Form style={screenSensitiveStyle}>
      <div>
        <H1 style={{ marginTop: 10 }}>
          <MdArrowDropleft
            color={process.env.REACT_APP_BLUE_COLOUR}
            className="grow"
            onClick={this.props.goBack}
            style={{
              marginRight: '15pt'
            }} />
          Next Of Kin Details
        </H1>

        <H1 hidden={this.toHideContactInformationHeader()} ref={this.props.formHeaderRef} style={{ color: '#3A3B3B', fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY, fontSize: '24px', fontWeight: '400', padding: '35px', paddingLeft: 0, textAlign: 'left' }}>Next of Kin Information</H1>

        <Row>
          <Col xs={12} md={6} hidden={!this.props.showPreviouslySavedFields && this.state.previouslySavedFields.firstName}>
            <Form.Group>
              <Form.Label style={{ color: `${!this.state.form.firstName && !this.state.fieldsFocused.includes('firstName') || this.isFirstNameValid() ? '#3A3B3B' : process.env.REACT_APP_RED_COLOUR}`, fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px' }}>First Name: <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>*</span></Form.Label>
              <FormControl
                value={this.state.form.firstName}
                type="text"
                placeholder="First name"
                onBlur={() => this.setState({
                  fieldsFocused: [...this.state.fieldsFocused, 'firstName']
                })}
                onChange={(event) => {
                  const firstName = this.stripNumbersFromString(event.target.value)
                  this.updateFormField({ firstName })
                }}
                style={{ backgroundColor: '#FFFFFF', borderRadius: '20px', border: `1px solid ${!this.state.form.firstName || this.isFirstNameValid() ? '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`, boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px' }} />
              {!this.state.form.firstName && (this.state.propagateFormErrors || this.state.fieldsFocused.includes('firstName')) && <Form.Label style={{ color: process.env.REACT_APP_RED_COLOUR, fontSize: process.env.REACT_APP_SMALL_FONT_SIZE, lineHeight: '19px', position: 'absolute', width: '100%', left: 25 }}>First name is required.</Form.Label>}
              {(!this.state.form.firstName || this.isFirstNameValid()) ? <React.Fragment /> : <Form.Label style={{ color: process.env.REACT_APP_RED_COLOUR, fontSize: process.env.REACT_APP_SMALL_FONT_SIZE, lineHeight: '19px', position: 'absolute', width: '100%', left: 25 }}>First name is invalid.</Form.Label>}
            </Form.Group>
          </Col>
          <Col xs={12} md={6} hidden={!this.props.showPreviouslySavedFields && this.state.previouslySavedFields.lastName}>
            <Form.Group>
              <Form.Label style={{ color: `${!this.state.form.lastName && !this.state.fieldsFocused.includes('lastName') || this.isLastNameValid() ? '#3A3B3B' : process.env.REACT_APP_RED_COLOUR}`, fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px' }}>Last Name: <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>*</span></Form.Label>
              <FormControl
                value={this.state.form.lastName}
                type="text"
                placeholder="Last name"
                onBlur={() => this.setState({
                  fieldsFocused: [...this.state.fieldsFocused, 'lastName']
                })}
                onChange={(event) => {
                  const lastName = this.stripNumbersFromString(event.target.value)
                  this.updateFormField({ lastName })
                }}
                style={{ backgroundColor: '#FFFFFF', borderRadius: '20px', border: `1px solid ${!this.state.form.lastName || this.isLastNameValid() ? '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`, boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px' }} />
              {!this.state.form.lastName && (this.state.propagateFormErrors || this.state.fieldsFocused.includes('lastName')) && <Form.Label style={{ color: process.env.REACT_APP_RED_COLOUR, fontSize: process.env.REACT_APP_SMALL_FONT_SIZE, lineHeight: '19px', position: 'absolute', width: '100%', left: 25 }}>Last name is required.</Form.Label>}
              {(!this.state.form.lastName || this.isLastNameValid()) ? <React.Fragment /> : <Form.Label style={{ color: process.env.REACT_APP_RED_COLOUR, fontSize: process.env.REACT_APP_SMALL_FONT_SIZE, lineHeight: '19px', position: 'absolute', width: '100%', left: 25 }}>Last name is invalid.</Form.Label>}
            </Form.Group>
          </Col>

          <Col xs={12} md={6} hidden={!this.props.showPreviouslySavedFields && this.state.previouslySavedFields.phone}>
            <Form.Group>
              <Form.Label style={{ color: `${!this.state.form.phone && !this.state.fieldsFocused.includes('phone') || this.isPhoneValid() ? '#3A3B3B' : process.env.REACT_APP_RED_COLOUR}`, fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px' }}>Phone Number: <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>*</span></Form.Label>
              <ReactPhoneInput
                buttonStyle={{ borderTopLeftRadius: process.env.REACT_APP_BORDER_STYLE === 'square' ? '4px' : '20px', borderBottomLeftRadius: process.env.REACT_APP_BORDER_STYLE === 'square' ? '4px' : '20px' }}
                containerStyle={{ margin: 0, padding: 0, width: '100%' }}
                defaultCountry={'ng'}
                disableCountryCode={true}
                inputStyle={{ backgroundColor: '#FFFFFF', borderRadius: process.env.REACT_APP_BORDER_STYLE === 'square' ? '4px' : '20px', border: `1px solid ${!this.state.form.phone || this.isPhoneValid() ? '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`, boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px', width: '100%' }}
                value={this.state.form.phone || ''}
                maxLength={11}
                onlyCountries={['ng', 'ke', 'ug']}
                onBlur={() => this.setState({
                  fieldsFocused: [...this.state.fieldsFocused, 'phone']
                })}
                onChange={(value, data) => {
                  this.updateFormField({ phone: value })
                }}
                placeholder="08012345678" />
              {!this.state.form.phone && (this.state.propagateFormErrors || this.state.fieldsFocused.includes('phone')) && <Form.Label style={{ color: process.env.REACT_APP_RED_COLOUR, fontSize: process.env.REACT_APP_SMALL_FONT_SIZE, lineHeight: '19px', position: 'absolute', width: '100%', left: 25 }}>Phone is required.</Form.Label>}
              {(!this.state.form.phone || this.isPhoneValid()) ? <React.Fragment /> : <Form.Label style={{ color: process.env.REACT_APP_RED_COLOUR, fontSize: process.env.REACT_APP_SMALL_FONT_SIZE, lineHeight: '19px', position: 'absolute', width: '100%', left: 25 }}>Phone is invalid.</Form.Label>}
            </Form.Group>
          </Col>

          <Col xs={12} md={6} hidden={!this.props.showPreviouslySavedFields && this.state.previouslySavedFields.gender}>
            <Form.Group controlId="gender">
              <Form.Label style={{ color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px' }}>Gender: <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>*</span></Form.Label>
              <Row>
                <Col>
                  <Form.Check checked={this.state.form.gender === 'MALE'} inline type='radio' onChange={(event) => {
                    this.updateFormField({ gender: 'MALE' })
                  }} style={{ backgroundColor: '#FFFFFF', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px', width: '100%' }} id="male-gender" label={`Male`} />
                </Col>
                <Col>
                  <Form.Check checked={this.state.form.gender === 'FEMALE'} inline type='radio' onChange={(event) => {
                    this.updateFormField({ gender: 'FEMALE' })
                  }} style={{ backgroundColor: '#FFFFFF', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px', width: '100%' }} id="female-gender" label={`Female`} />
                </Col>
                <Col />
              </Row>
            </Form.Group>
          </Col>
          <Col xs={12} md={6} hidden={!this.props.showPreviouslySavedFields && this.state.previouslySavedFields.relationship}>
            <Form.Group>
              <Form.Label style={{ color: `${!this.state.form.relationship && !this.state.fieldsFocused.includes('relationship') || this.isRelationshipValid() ? '#3A3B3B' : process.env.REACT_APP_RED_COLOUR}`, fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px' }}>Relationship: <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>*</span></Form.Label>

              <Dropdown>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                  <FormControl
                    placeholder="Select Relationship"
                    value={
                      this.state.form.relationship ? (this.state.relationships.find(value => value.name === this.state.form.relationship) || {}).name : ''
                    }
                    readOnly
                    onBlur={() => this.setState({
                      fieldsFocused: [...this.state.fieldsFocused, 'relationship']
                    })}
                    style={{ backgroundColor: '#FFFFFF', borderRadius: '20px', border: '1px solid #DFE2E6', boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px', width: '100%' }} />
                </Dropdown.Toggle>

                <Dropdown.Menu as={CustomMenu}>
                  {this.state.relationships.map(value => <Dropdown.Item onClick={() => this.updateFormField({ relationship: value.name })} eventKey={value.name}>{value.name}</Dropdown.Item>)}
                </Dropdown.Menu>
              </Dropdown>

              {/* <FormControl
                value={this.state.form.relationship}
                type="text"
                placeholder="Relationship"
                onBlur={() => this.setState({
                  fieldsFocused: [...this.state.fieldsFocused, 'relationship']
                })}
                onChange={(event) => {
                  const relationship = this.stripNumbersFromString(event.target.value)
                  this.updateFormField({relationship})
                }}
                style={{backgroundColor: '#FFFFFF', borderRadius: '20px', border: `1px solid ${!this.state.form.relationship || this.isRelationshipValid() ? '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`, boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px'}} />
              {!this.state.form.relationship && (this.state.propagateFormErrors || this.state.fieldsFocused.includes('relationship')) && <Form.Label style={{color: process.env.REACT_APP_RED_COLOUR, fontSize: process.env.REACT_APP_SMALL_FONT_SIZE, lineHeight: '19px'}}>Relationship is required.</Form.Label>}
              {(!this.state.form.relationship || this.isRelationshipValid()) ? <React.Fragment /> : <Form.Label style={{color: process.env.REACT_APP_RED_COLOUR, fontSize: process.env.REACT_APP_SMALL_FONT_SIZE, lineHeight: '19px'}}>Relationship is invalid.</Form.Label>} */}
            </Form.Group>
          </Col>
          <Col xs={12} md={6} hidden={!this.props.showPreviouslySavedFields && this.state.previouslySavedFields.address}>
            <Form.Group>
              <Form.Label style={{ color: `${!this.state.form.address && !this.state.fieldsFocused.includes('address') || this.isAddressValid() ? '#3A3B3B' : process.env.REACT_APP_RED_COLOUR}`, fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px' }}>Address: <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>*</span></Form.Label>
              <Form.Check
                inline
                onChange={(event) => {
                  this.updateFormField({
                    sameAsPersonalAddress: !this.state.form.sameAsPersonalAddress,
                    address: !this.state.form.sameAsPersonalAddress ? this.props.sameAddress : ''
                  })
                }}
              />
              <Text className="tool-tip" style={{ paddingLeft: "10px", paddingBottom: "3px" }}>Same as Personal Address
                <span className="tool-tip-text">Tick this box if your next of kin address is same as personal address</span>
              </Text>
              <FormControl
                value={this.state.form.sameAsPersonalAddress ? this.props.sameAddress : this.state.form.address}
                as="textarea"
                placeholder="Address"
                onBlur={() => this.setState({
                  fieldsFocused: [...this.state.fieldsFocused, 'address']
                })}
                onChange={(event) => {
                  const address = event.target.value
                  this.updateFormField({ address })
                }}
                style={{ backgroundColor: '#FFFFFF', borderRadius: '20px', border: `1px solid ${!this.state.form.address || this.isAddressValid() ? '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`, boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px' }}
                disabled={this.state.form.sameAsPersonalAddress}
              />
              {!this.state.form.address && (this.state.propagateFormErrors || this.state.fieldsFocused.includes('address')) && <Form.Label style={{ color: process.env.REACT_APP_RED_COLOUR, fontSize: process.env.REACT_APP_SMALL_FONT_SIZE, lineHeight: '19px', position: 'absolute', width: '100%', left: 25 }}>Address is required.</Form.Label>}
              {(!this.state.form.address || this.isAddressValid()) ? <React.Fragment /> : <Form.Label style={{ color: process.env.REACT_APP_RED_COLOUR, fontSize: process.env.REACT_APP_SMALL_FONT_SIZE, lineHeight: '19px', position: 'absolute', width: '100%', left: 25 }}>Address is invalid.</Form.Label>}
            </Form.Group>
          </Col>
        </Row>
      </div>

      <div style={{ color: process.env.REACT_APP_GREY_COLOUR, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80px' }} hidden={!(this.toHideContactInformationHeader())}>
        You are done with this!
      </div>

      <center style={{ marginTop: '10px' }}>
        <Button
          outline
          onClick={(e) => {
            e.preventDefault()
            this.props.goBack()
          }}
          style={{
            backgroundColor: 'transparent',
            marginTop: this.props.deviceIsMobile ? '10pt' : null,
            padding: '8pt 37pt',
            marginRight: '10px'
          }}
        >Back</Button>
        <Button
          disabled={this.props.isLoading || !this.props.canSubmit}
          onClick={(e) => {
            e.preventDefault()
            this.props.onSubmit(this.state.form)
          }}
        >{this.props.isLoading ? 'LOADING...' : 'PROCEED'}</Button>
      </center>

    </Form>
  }
}
