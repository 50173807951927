export const DEVICE_AUTHENTICATION_CLICK = 'device_authentication_click';
export const DEVICE_AUTHENTICATION_FAILURE = 'device_authentication_failure';
export const DEVICE_AUTHENTICATION_SUCCESS = 'device_authentication_success';

export const FIRST_LOGIN_AFTER_APPROVAL_CLICK = 'first_login_after_approval';
export const FIRST_LOGIN_AFTER_APPROVAL_FAILURE = 'first_login_after_approval_failure';
export const FIRST_LOGIN_AFTER_APPROVAL_SUCCESS = 'first_login_after_approval_success';

export const FIRST_WALLET_FUNDING_AFTER_APPROVAL_CLICK = 'first_wallet_funding_after_approval_click';
export const FIRST_WALLET_FUNDING_AFTER_APPROVAL_FAILURE = 'first_wallet_funding_after_approval_failure';
export const FIRST_WALLET_FUNDING_AFTER_APPROVAL_SUCCESS = 'first_wallet_funding_after_approval_success';

export const LOGIN_CLICK = 'login_click';
export const LOGIN_FAILURE = 'login_failure';
export const LOGIN_SUCCESS = 'login_success';

export const LOGIN_AFTER_SIGNUP_START = 'login_after_signup_start';
export const LOGIN_AFTER_SIGNUP_FAILURE = 'login_after_signup_failure';
export const LOGIN_AFTER_SIGNUP_SUCCESS = 'login_after_signup_success';

export const SIGNUP_CLICK = 'signup_click';
export const SIGNUP_FAILURE = 'signup_failure';
export const SIGNUP_SUCCESS = 'signup_success';

export const TRANSACTION_INITIATE_CLICK = 'transaction_initiate_click';
export const TRANSACTION_INITIATE_FAILURE = 'transaction_initiate_failure';
export const TRANSACTION_INITIATE_SUCCESS = 'transaction_initiate_success';

export const TRANSACTION_PROCEED_CLICK = 'transaction_proceed_click';
export const TRANSACTION_PROCEED_FAILURE = 'transaction_proceed_failure';
export const TRANSACTION_PROCEED_SUCCESS = 'transaction_proceed_success';

export const GET_PROFILE_FOR_RESET_PASSWORD = 'get_profile_for_reset_password'

export const PAGE_VIEW = 'page_view';
