import React, { useEffect } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import IosRefresh from "react-ionicons/lib/IosRefresh";
import ReactPhoneInput from "react-phone-input-2";
import Select from "react-select";
import Button from "../../../../../../../components/button";
import FormControl from "../../../../../../../components/form-control";
import FormLabel from "../../../../../../../components/form-label";
import { RegexChecker } from "../../../../../../../utils/regex-checker";
import phoneValidators from "../../../../../../../utils/validators/phone-validators";

export const SendMoneyForm = ({
  props,

  customStylesBanks,
  validateAccountDetails,
  state = {
    fieldsFocused: [],
    form: {
      accountNumber: null,
      gender: "male",
    },
    posRequestForm: {},
    requestTypeValues: [],
  },
  setState,
}) => {
  useEffect(() => {
    const resetState = () => {
      setState({
        form: {
          beneficiaryName: "",
          bank: "",
          beneficiaryPhone: "",
          amount: "",
        },
      });
    };
    resetState();
  }, []);

  const updateFormField = (params) => {
    let newForm = {
      ...state.form,
      ...params,
    };

    if (params.accountNumber) {
      newForm = {
        ...newForm,
        beneficiaryName: "",
        beneficiaryPhone: "",
        amount: "",
      };
    }

    setState({
      form: newForm,
    });
  };

  const isBeneficiaryPhoneValid = (newForm) => {
    const form = newForm || state.form;
    const countryValidator = phoneValidators[state.form.phonePrefix];

    return (
      form.beneficiaryPhone &&
      (countryValidator ? countryValidator(form.beneficiaryPhone) : true)
    );
  };

  const isAccountNumberComplete = () => {
    return RegexChecker.accountNumber.test(state.form.accountNumber);
  };
  const isBeneficiaryNameValid = () => {
    return state.form.beneficiaryName && state.form.beneficiaryName.length > 2;
  };
  const isSendMoneyFormValid = () => {
    return (
      // this.isSenderNameValid() &&
      isBeneficiaryNameValid() &&
      isAccountNumberComplete() &&
      state.form.bank &&
      state.form.amount
    );
  };
  return (
    <Form>
      <Row>
        <Col xs={12} md={6}>
          <Form.Group>
            <FormLabel
              className={`form-label-style ${state.form.accountNumber && !isAccountNumberComplete()
                ? "isNotValid"
                : "isValid"
                }`}
            >
              Account Number:
              <span className="asterisk-style">*</span>
            </FormLabel>
            <FormControl
              className="form-control-text-input"
              maxLength={10}
              onBlur={() => {
                setState({
                  fieldsFocused: [...state.fieldsFocused, "accountNumber"],
                });
              }}
              onChange={(event) => {
                const accountNumber = event.target.value;
                updateFormField({ accountNumber });
              }}
              style={{
                borderColor:
                  state.form.accountNumber && !isAccountNumberComplete()
                    ? process.env.REACT_APP_RED_COLOUR
                    : "#DFE2E6",
              }}
            />
          </Form.Group>
        </Col>

        <Col xs={12} md={6} style={{ zIndex: 3 }}>
          <Form.Group>
            <FormLabel
              style={{
                color: "#3A3B3B",
                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                lineHeight: "19px",
              }}
            >
              Bank:{" "}
              <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>*</span>
            </FormLabel>
            <Select
              options={
                state.banks &&
                state.banks.map((bank) => {
                  return { label: bank.bankName, value: bank.cbnCode };
                })
              }
              onChange={(event) => {
                const bank = event;
                updateFormField({ bank });
                state.form.accountNumber && validateAccountDetails(bank);
              }}
              styles={customStylesBanks}
              className="select-input-container-banks"
              classNamePrefix="react-select"
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "rgb(178, 212, 255)",
                },
              })}
            />
          </Form.Group>
        </Col>

        <Col xs={12} md={6} style={{ paddingBottom: "30px" }}>
          <Form.Group>
            <FormLabel
              className={`form-label-style ${state.form.beneficiaryName && !isBeneficiaryNameValid()
                ? "isNotValid"
                : "isValid"
                }`}
            >
              Beneficiary's Name:
              <span className="asterisk-style">*</span>
            </FormLabel>
            <InputGroup style={{ height: "10px", width: "100%" }}>
              <FormControl
                className="form-control-text-input"
                onChange={(event) => {
                  const beneficiaryName = event.target.value;
                  setState({
                    errorDescription: "",
                  });
                  updateFormField({ beneficiaryName });
                }}
                value={state.form.beneficiaryName}
                disabled={state.isDisabled || state.validationLoading}
              />
              {state.validationLoading && (
                <IosRefresh
                  style={{
                    marginTop: "-19pt",
                    marginLeft: "45%",
                    width: "40px",
                    position: "absolute",
                  }}
                  fontSize="100px"
                  color="grey"
                  rotate={true}
                />
              )}
              {!state.isDisabled && !state.form.beneficiaryName && (
                <InputGroup.Append>
                  <Button
                    id="beneficiary-name-retry-button"
                    onClick={() => validateAccountDetails()}
                    disabled={state.validationLoading}
                  >
                    Retry
                  </Button>
                </InputGroup.Append>
              )}
            </InputGroup>
            {
              <span
                style={{
                  color: "red",
                  fontSize: "11px",
                  position: "absolute",
                  bottom: props.deviceIsMobile ? "-15px" : "-5px",
                }}
              >
                {state.errorDescription || !state.form.beneficiaryName
                  ? state.errorDescription
                  : ""}
              </span>
            }
          </Form.Group>
        </Col>

        <Col xs={12} md={6} style={{ zIndex: 2 }}>
          <Form.Group>
            <FormLabel
              style={{
                color:
                  state.form.beneficiaryPhone && !isBeneficiaryPhoneValid()
                    ? process.env.REACT_APP_RED_COLOUR
                    : "#3A3B3B",
                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                lineHeight: "19px",
              }}
            >
              Beneficiary's Number:{" "}
            </FormLabel>
            <ReactPhoneInput
              buttonStyle={{
                borderTopLeftRadius:
                  process.env.REACT_APP_BORDER_STYLE === "square"
                    ? "4px"
                    : "20px",
                borderBottomLeftRadius:
                  process.env.REACT_APP_BORDER_STYLE === "square"
                    ? "4px"
                    : "20px",
              }}
              containerStyle={{ margin: 0, padding: 0, width: "100%" }}
              defaultCountry={"ng"}
              disableCountryCode={true}
              inputStyle={{
                backgroundColor: "#FFFFFF",
                borderRadius:
                  process.env.REACT_APP_BORDER_STYLE === "square"
                    ? "4px"
                    : "20px",
                border: `1px solid ${(!state.form.beneficiaryPhone &&
                  !state.propagateFormErrors) ||
                  isBeneficiaryPhoneValid()
                  ? "#DFE2E6"
                  : process.env.REACT_APP_RED_COLOUR
                  }`,
                boxSizing: "border-box",
                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                lineHeight: "17px",
                width: "100%",
              }}
              maxLength={11}
              value={state.form.beneficiaryPhone || ""}
              onlyCountries={["ng", "ke", "ug"]}
              onBlur={() =>
                setState({
                  fieldsFocused: [...state.fieldsFocused, "beneficiaryPhone"],
                })
              }
              onChange={(value, data) => {
                updateFormField({ beneficiaryPhone: value });
              }}
              placeholder="08012345678"
            />
          </Form.Group>
        </Col>

        <Col md={6}>
          <Form.Group as={Col} md="12" controlId="amount">
            <FormLabel
              style={{
                color: "#3A3B3B",
                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                lineHeight: "19px",
              }}
            >
              Amount:{" "}
              <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>*</span>
            </FormLabel>
            <InputGroup style={{ borderRadius: 10 }}>
              <InputGroup.Prepend>
                <InputGroup.Text
                  id="inputGroupPrepend"
                  style={{
                    borderTopLeftRadius:
                      process.env.REACT_APP_BORDER_STYLE === "square"
                        ? "4px"
                        : 20,
                    borderBottomLeftRadius:
                      process.env.REACT_APP_BORDER_STYLE === "square"
                        ? "4px"
                        : 20,
                  }}
                >
                  NGN
                </InputGroup.Text>
              </InputGroup.Prepend>

              <FormControl
                type="tel"
                value={state.form.amount}
                onChange={(event) => {
                  const amount = event.target.value;
                  updateFormField({ amount });
                }}
                style={{
                  backgroundColor: "#FFFFFF",
                  border: `1px solid #DFE2E6`,
                  borderTopRightRadius: 20,
                  borderBottomRightRadius: 20,
                  boxSizing: "border-box",
                  fontSize: "12px",
                  lineHeight: "17px",
                  padding: "20px",
                }}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col xs={6} md={6}>
          <Form.Group>
            <FormLabel
              style={{
                color: "#3A3B3B",
                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                lineHeight: "19px",
              }}
            >
              Remark:
            </FormLabel>
            <FormControl
              className="form-control-text-input"
              placeholder="Transfer to ABC"
              onChange={(event) => {
                const remark = event.target.value;
                updateFormField({ remark });
              }}
            />
          </Form.Group>
        </Col>
        <Col xs={6} md={6}>
          <Form.Group controlId="gender">
            <FormLabel
              style={{
                color: "#3A3B3B",
                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                lineHeight: "19px",
              }}
            >
              Gender:{" "}
            </FormLabel>
            <Row>
              <Col md={6}>
                <input
                  type="radio"
                  name="gender"
                  id="male"
                  value="Male"
                  onChange={(event) => {
                    const gender = event.target.value;
                    updateFormField({ gender });
                  }}
                />{" "}
                <label for="male">Male</label>
              </Col>
              <Col md={6}>
                <input
                  type="radio"
                  name="gender"
                  id="female"
                  value="Female"
                  onChange={(event) => {
                    const gender = event.target.value;
                    updateFormField({ gender });
                  }}
                />{" "}
                <label for="female">Female</label>
              </Col>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="proceed-button-col">
          <Button
            disabled={
              props.isLoading ||
              !isSendMoneyFormValid() ||
              !state.form.beneficiaryName
            }
            className="services-proceed-button"
            onClick={(e) => {
              e.preventDefault();
              props.onSendMoneyProceed(state.form);
            }}
          >
            {props.isLoading ? "Loading..." : "Continue"}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
