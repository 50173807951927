import { specialDateRangeFormatter_StartDateDate } from "../../utils/formatters"

export default class PersonalDetailsFormSerializer {
  constructor(props) {
    const { firstName, middleName, lastName, email, phone, phonePrefix, state, lga, nationality,
    address, closestLandmark, placeOfBirth, gender, identificationNumber, identificationType, 
    bvn, mothersMaidenName, dateOfBirth, referralCode , bvnVerificationStatus} = props

    this.firstName = firstName
    this.middleName = middleName
    this.surname = lastName
    this.emailAddress = email
    this.phone = phone
    this.state = state
    this.localGovernmentArea = lga
    this.nationality = nationality
    this.address = address
    this.closestLandMark = closestLandmark
    this.placeOfBirth = placeOfBirth
    this.gender = gender
    this.identificationNumber = identificationNumber
    this.identificationType = identificationType
    this.bvn = bvn
    this.dob = dateOfBirth
    this.mothersMaidenName = mothersMaidenName
    this.referralCode = referralCode
    this.bvnVerificationStatus = bvnVerificationStatus

    if (phone) {  
      this.phoneNumber = phone.startsWith(phonePrefix) ? `${phone}` : `${phonePrefix}${phone}`
    }
  }

  formIsValid () {
    return this.firstName || this.surname || this.phone || this.gender
  }

  formIsComplete () {
    return Boolean(this.firstName) && Boolean(this.surname)
    && Boolean(this.phone) && Boolean(this.state) && Boolean(this.localGovernmentArea) && Boolean(this.nationality)
    && Boolean(this.address) && Boolean(this.closestLandMark) && Boolean(this.placeOfBirth) && Boolean(this.gender)
    && Boolean(this.identificationNumber) && Boolean(this.identificationType) && Boolean(this.bvn) && Boolean(this.dob)
    && Boolean(this.mothersMaidenName) 
  }

  asJson () {
    return {
      firstName: this.firstName,
      middleName: this.middleName,
      surname: this.surname,
      emailAddress: this.emailAddress,
      phoneNumber: this.phoneNumber,
      state: this.state,
      localGovernmentArea: this.localGovernmentArea,
      nationality: this.nationality,
      address: this.address,
      closestLandMark: this.closestLandMark,
      placeOfBirth: this.placeOfBirth,
      gender: this.gender,
      identificationNumber: this.identificationNumber,
      identificationType: this.identificationType,
      bvn: this.bvn,
      bvnVerificationStatus:this.bvnVerificationStatus,
      dob: specialDateRangeFormatter_StartDateDate(this.dob),
      mothersMaidenName: this.mothersMaidenName,
      referralCode: this.referralCode
    }
  }
}
