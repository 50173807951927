import React, { useState } from "react";
import BackButton from "../component/backButton";
import Thumbnail from "../component/thumbnail";
import H1 from "../../../../../components/h1";
import { PERSONAL_DOC_SUFFIX, TEN_MB } from "../../../../../constants";
import { notify } from "../../../../../components/extras";
import { ERROR_STATUS } from "../../../../../constants/api";
import Onboarding from "../../../../../services/api/resources/onboarding";

const AuthorizationLetter = ({ application, setScreen, setKycId }) => {
  const onboarding = new Onboarding();

  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState("");
  const [formIsValid, setFormIsValid] = useState(false);
  const [error, setError] = useState("");

  const [attachedDocument, setAttachedDocument] = useState("");

  const updateFormField = (params) => {
    const newForm = {
      ...form,
      ...params,
    };
    setForm(newForm);
    setFormIsValid(false);
  };

  const handleSubmit = () => {
    setScreen(3);
  };

  const onFileSelect = async (event, filename) => {
    const name = event.target.files[0].name;
    const file = event.target.files[0];

    if (file.size > TEN_MB) {
      setError(true);
      return;
    } else {
      setIsLoading(true);
      setError(false);

      const uploadDoc = await onboarding.newDocumentUploadAggregator(file);

      setIsLoading(false);
      if (uploadDoc.status === ERROR_STATUS) {
        notify(uploadDoc?.description, "error");
      } else {
        const newAttachedDocument = {
          name: `${name}${PERSONAL_DOC_SUFFIX}`,
          filename: `${filename}${PERSONAL_DOC_SUFFIX}`,
          file: file,
          url: URL.createObjectURL(file),
        };
        notify("File uploaded successfully", "success");

        setKycId(uploadDoc.response.kycId);
        setAttachedDocument(newAttachedDocument);

        updateFormField({ attachedDocument: newAttachedDocument });
      }
    }
  };

  const onFileUnselect = () => {
    setAttachedDocument("");
    updateFormField({ attachedDocument: {} });
  };

  return (
    <>
      <BackButton
        onClick={() => {
          localStorage.removeItem("businessDetailsForm");
          setScreen(2);
        }}
      />
      <main className="aggregator-container">
        <H1
          style={{
            textAlign: "left",
            fontWeight: 600,
            marginBottom: "2rem",
          }}
        >
          Authorisation Letter
        </H1>
        <label
          style={{
            fontWeight: "bold",
            color: process.env.REACT_APP_BLUE_COLOUR,
          }}
        >
          State Authorisation Letter
        </label>

        <Thumbnail
          attachedDocument={attachedDocument}
          filename={"attachment"}
          onFileSelect={onFileSelect}
          onFileUnselect={onFileUnselect}
          isLoading={isLoading}
        />
        {error && (
          <span
            style={{
              color: "tomato",
              fontSize: "14px",
            }}
          >
            File size must not exceed 10MB.
          </span>
        )}
        <button
          onClick={handleSubmit}
          disabled={!attachedDocument || isLoading}
          className="signupButton2"
          style={{
            marginTop: "3rem",
            textAlign: "center",
            color: "white",
            backgroundColor: "#00425f",
            width: "100%",
          }}
        >
          {"Next"}
        </button>
      </main>
    </>
  );
};

export default AuthorizationLetter;
