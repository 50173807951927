import React, { useState } from "react";
import { Link } from "react-router-dom";
import HeaderSignup from "../../../../components/header-signup";
import "../styles.scss";
import { HandIcon } from "../../../../icons";


export default function CongratsScene({ setScreen }) {


  return (
    <>
      <HeaderSignup />
      <main className="onboarding-container">
        <div style={{ padding: "2rem", alignContent: "center" }}>
          <div style={{ display: "grid", placeContent: "center", marginBottom: "2rem" }}>
            {HandIcon()}
          </div>
          <h1
            style={{
              textAlign: "center",
              fontWeight: "bold",
              marginBottom: "1rem",
              fontSize: "1.5rem",
            }}
          >
            Congratulations!
          </h1>
          <p
            style={{
              color: "grey",
              fontWeight: "200",
              marginLeft: "1.5rem",
              fontSize: "15px",
              textAlign: "center",
            }}
          >
            The payment has been completed successfully.
            Your business registration will be completed within 2-5 business days.
          </p>
          <Link to="/dashboard">
            <button
              className="signupButton2"
              style={{
                marginTop: "3rem",
                textAlign: "center",
                color: "#479FC8",
              }}
            >
              {" "}
              Continue to Dashboard{" "}
            </button>
          </Link>
        </div>
      </main>
    </>
  );
}
