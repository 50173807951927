import React from "react";

import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../constants/api";
import CountriesStatesLga from "../../../../fixtures/countries_states_lgas.json";
import Onboarding from "../../../../services/api/resources/onboarding";
import { AppProvider } from "../context";
import ApplicationReviewScene from "./applicationReviewScene";
import KycDocumentScene from "./kycDocumentScene";
import PersonalDetailsScene from "./personalDetailsScene";
import BvnValidationScene from "./bvnValidationScene";
import FaceVerificationScene from "./faceVerificationScene";
import CongratsScene from './congrat';
import SecureAccountScene from './secureAccountScene';


class SetupAgentOnboardingScene extends React.Component {
  constructor(props) {
    super(props);
    this.myElementRef = React.createRef();
    this.state = {
      screen: 1,
      isLoading: false,
      application: null,
    };

    this.fetchApplication = this.fetchApplication.bind(this);
  }

  setScreen = (newScreen) => {
    this.setState({ screen: newScreen });
  };

  async fetchApplication() {
    const applicationId = localStorage.getItem("applicationId");
    const onboarding = new Onboarding();

    this.setState({ isLoading: true });
    const { status, response } = await onboarding.getApplicationAggregator(
      applicationId
    );

    localStorage.setItem("application", JSON.stringify(response));
    this.setState({ isLoading: false });
    if (status === ERROR_STATUS) {
    } else if (status === SUCCESS_STATUS) {
      this.setState({ isLoading: false });

      const stateArray = CountriesStatesLga[0].states.map((state) => {
        return {
          name: state.name,
          id: state.id,
        };
      });

      localStorage.setItem(
        "state",
        response?.businessDetails?.state
          ? stateArray.find(
            (value) => value.id === parseInt(response?.businessDetails?.state)
          ).name
          : ""
      );
    } else {
      this.setState({ isLoading: false });
    }
  }

  componentDidMount() {
    this.loadApplication();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoading !== this.props.isLoading) {
      this.loadApplication();
    }
  }

  loadApplication() {
    const application = JSON.parse(localStorage.getItem("application"));
    this.setState({ application });
  }

  render() {
    const { screen } = this.state;
    const application = JSON.parse(localStorage.getItem("currentApplication"));

    return (
      <AppProvider>
        <div className="onboarding-layout">
          {screen === 1 && (
            <BvnValidationScene
              application={application}
              setScreen={this.setScreen}
              screen={screen}
            />
          )}
          {screen === 2 && (
            <FaceVerificationScene
              application={application}
              setScreen={this.setScreen}
            />
          )}
          {screen === 3 && (
            <KycDocumentScene
              application={application}
              setScreen={this.setScreen}
            />
          )}
          {screen === 4 && (
            <PersonalDetailsScene
              application={application}
              setScreen={this.setScreen}
              screen={screen}
            />
          )}
          {screen === 5 && (
            <ApplicationReviewScene
              application={this.state.application}
              setScreen={this.setScreen}
              screen={screen}
              isLoading={this.state.isLoading}
              props={this.props}
            />
          )}
          {screen === 6 && (
            <CongratsScene
              application={application}
              setScreen={this.setScreen}
              screen={screen}
            />
          )}
          {screen === 7 && (
            <SecureAccountScene
              application={application}
              setScreen={this.setScreen}
              screen={screen}
            />
          )}
        </div>
      </AppProvider>
    );
  }
}

export default SetupAgentOnboardingScene;
