import React, { useState } from 'react';

import SetupNewAgentScene from './setupNewAgentScene';
import KycDocumentScene from './kycDocumentScene';
import { AppProvider } from '../context';
import PersonalDetailsScene from './personalDetailsScene';
import CountriesStatesLga from '../../../../../fixtures/countries_states_lgas.json';
import BusinessDetailsScene from './businessDetailsScene';
import NextOfKinScene from './nextOfKinScene';
import ApplicationReviewScene from './applicationReviewScene';
import Onboarding from '../../../../../services/api/resources/onboarding';
import { ERROR_STATUS, SUCCESS_STATUS } from '../../../../../constants/api';

class SetupAgent extends React.Component {
  constructor(props) {
    super(props);
    this.myElementRef = React.createRef();
    this.state = {
      screen: 1,
      isLoading: false,
      application: null,
    };

    this.fetchApplication = this.fetchApplication.bind(this);
  }

  setScreen = (newScreen) => {
    this.setState({ screen: newScreen });
  };

  async fetchApplication() {
    const applicationId = localStorage.getItem('applicationId');
    const onboarding = new Onboarding();
    // applicationId

    this.setState({ isLoading: true });
    const { status, response } = await onboarding.getApplicationAggregator(
      applicationId
    );

    localStorage.setItem('application', JSON.stringify(response));
    this.setState({ isLoading: false });
    if (status === ERROR_STATUS) {
    } else if (status === SUCCESS_STATUS) {
      this.setState({ isLoading: false });

      const stateArray = CountriesStatesLga[0].states.map((state) => {
        return {
          name: state.name,
          id: state.id,
        };
      });

      localStorage.setItem(
        'state',
        response?.businessDetails?.state
          ? stateArray.find(
              (value) => value.id === parseInt(response?.businessDetails?.state)
            ).name
          : ''
      );
    } else {
      this.setState({ isLoading: false });
    }
  }

  componentDidMount() {
    this.loadApplication();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoading !== this.props.isLoading) {
      this.loadApplication();
    }
  }

  loadApplication() {
    const application = JSON.parse(localStorage.getItem('application'));
    this.setState({ application });
  }

  render() {
    const { screen } = this.state;
    const application = JSON.parse(localStorage.getItem('application'));
    return (
      <AppProvider>
        <div className='aggregator-layout'>
          {screen === 1 && (
            <SetupNewAgentScene
              application={application}
              setScreen={this.setScreen}
              props={this.props}
              isLoading={this.state.isLoading}
              fetchApplication={this.fetchApplication}
            />
          )}
          {screen === 2 && (
            <PersonalDetailsScene
              application={application}
              setScreen={this.setScreen}
              screen={screen}
            />
          )}
          {screen === 3 && (
            <KycDocumentScene
              application={application}
              setScreen={this.setScreen}
              fetchApplication={this.fetchApplication}
            />
          )}
          {screen === 4 && (
            <BusinessDetailsScene
              application={application}
              setScreen={this.setScreen}
              screen={screen}
              fetchApplication={this.fetchApplication}
            />
          )}
          {screen === 5 && (
            <NextOfKinScene
              application={application}
              setScreen={this.setScreen}
              fetchApplication={this.fetchApplication}
            />
          )}
          {screen === 6 && (
            <ApplicationReviewScene
              application={this.state.application}
              setScreen={this.setScreen}
              screen={screen}
              isLoading={this.state.isLoading}
              props={this.props}
            />
          )}
        </div>
      </AppProvider>
    );
  }
}

export default SetupAgent;
