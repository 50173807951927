import React from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import BaseScene from './base-scene';
import Button from '../components/button';
import Footer from '../components/footer';
import FormControl from '../components/form-control';
import Header from '../components/header';
import H1 from '../components/h1';
import HR from '../components/hr';
import Text from '../components/text';
import UserManagement from '../services/api/resources/user-management';
import { showErrorMessage, dismissErrorMessage, updateLoading } from '../services/redux/actions/tunnel';
import { ERROR_STATUS } from '../constants/api';

class ResetPassword extends BaseScene {
  isAuthRequired = false;
  userManagement = new UserManagement();

  constructor() {
    super()

    this.isEmailValid = this.isEmailValid.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  state = {
    hideConfirmPassword: true,
    hidePassword: true,
    form: {

    }
  };

  componentDidMount() {
    const { passwordResetUid } = this.props.match.params;
    this.passwordResetUid = passwordResetUid;
  }

  isEmailValid (newForm) {
    const form = newForm || this.state.form
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(form.email).toLowerCase());
  }

  isPasswordValid(newForm) {
    const form = newForm || this.state.form
    return form.password && form.password.length > 4
  }

  passwordsMatch(newForm) {
    const form = newForm || this.state.form
    return form.password === form.confirmPassword
  }

  updateFormField(params) {
    const newForm = {
      ...this.state.form,
      ...params
    }

    this.setState({
      form: newForm,
      formIsValid: this.isEmailValid(newForm) && this.isPasswordValid(newForm) && this.passwordsMatch(newForm) && newForm.password
    })
  }
  
  async onSubmit() {
    this.props.updateLoading(true, 0);
    setTimeout(() => this.props.isLoading && this.props.updateLoading(true, 66), 400);

    const pendingEmail = localStorage.getItem('pendingEmail');

    const resetPasswordResponse = await this.userManagement.resetPassword(
      this.state.form.password,
      this.state.form.confirmPassword,
      this.passwordResetUid,
      this.state.form.email
    );

    const resetPasswordResponseStatus = resetPasswordResponse.status;
    const resetPasswordResponseObj = resetPasswordResponse.response;
    
    this.props.updateLoading(false)

    if (resetPasswordResponseStatus === ERROR_STATUS) {
      this.props.showErrorMessage(JSON.stringify(resetPasswordResponseObj));
      setTimeout(() => this.props.dismissErrorMessage(), 3000);
      return
    }

    this.setState({
      redirectToLogin: true
    })

  }
  
  render () {

    if (this.state.redirectToLogin) {
      return <Redirect to="/login" />
    }

    const card = <React.Fragment>
      <H1 style={{textAlign: 'center', textTransform: 'uppercase'}}>Reset Password</H1>

      <center>
        <HR style={{marginTop: '10px'}} />
      </center>

      <Row>
        <Col md={12} lg={12}>
          <Form.Group>
            <Form.Label style={{color: `${!this.state.form.email || this.isEmailValid() ?  '#3A3B3B' : process.env.REACT_APP_RED_COLOUR}`, fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Email:</Form.Label>
            
            <Row>
              <Col style={{}}>
                <FormControl 
                  autoComplete="email-address"
                  type={'email'} 
                  placeholder="Email address" 
                  onChange={(event) => {
                    const email = event.target.value
                    this.updateFormField({email})
                  }}
                  style={{
                    paddingLeft: "50px",
                    backgroundColor: '#FFFFFF', 
                    borderRadius: '20px', 
                    border: `1px solid ${!this.state.form.email || this.isEmailValid() ? '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`, 
                    boxSizing: 'border-box', 
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px'
                  }} 
                />
              </Col>
            </Row>
          </Form.Group>
        </Col>

        <Col md={12} lg={12}>
          <Form.Group>
            <Form.Label style={{color: `${!this.state.form.password || this.isPasswordValid() ?  '#3A3B3B' : process.env.REACT_APP_RED_COLOUR}`, fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>New Password:</Form.Label>
            
            <Row>
              <Col style={{}}>
                <FormControl 
                  autoComplete="new-password"
                  type={this.state.hidePassword ? 'password' : 'text'} 
                  placeholder="Enter new password" 
                  onChange={(event) => {
                    const password = event.target.value
                    this.updateFormField({password})
                  }}
                  style={{
                    background: "url(https://img.icons8.com/material/24/C1C1C2/key.png) no-repeat scroll 8px 8px",
                    paddingLeft: "50px",
                    backgroundColor: '#FFFFFF', 
                    borderRadius: '20px', 
                    border: `1px solid ${!this.state.form.password || this.isPasswordValid() ? '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`, 
                    boxSizing: 'border-box', 
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px'
                  }} 
                />
                
                <img src={this.state.hidePassword ? '/assets/media/icons/eye.svg' : '/assets/media/icons/eye-hidden.svg'} style={{
                  height: '24px', 
                  width: '24px', 
                  opacity: 1,
                  // float: 'right',
                  marginLeft: this.state.deviceIsPC ? '245px' : '85%',
                  marginTop: '-70px',
                  position: 'relative',
                  zIndex: 2
                }} onClick={() => this.setState({hidePassword: !this.state.hidePassword})} alt="" />
              </Col>
            </Row>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={12} lg={12}>
          <Form.Group>
            <Form.Label style={{color: `${!this.state.form.confirmPassword || this.isPasswordValid() && this.passwordsMatch() ?  '#3A3B3B' : process.env.REACT_APP_RED_COLOUR}`, fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Repeat New Password:</Form.Label>
            
            <Row>
              <Col style={{}}>
                <FormControl 
                  autoComplete="new-password"
                  type={this.state.hideConfirmPassword ? 'password' : 'text'} 
                  placeholder="Repeat your new password" 
                  onChange={(event) => {
                    const confirmPassword = event.target.value
                    this.updateFormField({confirmPassword})
                  }} 
                  style={{
                    background: "url(https://img.icons8.com/material/24/C1C1C2/key.png) no-repeat scroll 8px 8px",
                    paddingLeft: "50px",
                    backgroundColor: '#FFFFFF', 
                    borderRadius: '20px', 
                    border: `1px solid ${!this.state.form.confirmPassword || (this.isPasswordValid() && this.passwordsMatch()) ? '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`, 
                    boxSizing: 'border-box', 
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px'
                  }} 
                />
                
                <img src={this.state.hideConfirmPassword ? '/assets/media/icons/eye.svg' : '/assets/media/icons/eye-hidden.svg'} style={{
                  height: '24px', 
                  width: '24px', 
                  opacity: 1,
                  // float: 'right',
                  marginLeft: this.state.deviceIsPC ? '245px' : '85%',
                  marginTop: '-70px',
                  position: 'relative',
                  zIndex: 2
                }} onClick={() => this.setState({hideConfirmPassword: !this.state.hideConfirmPassword})} alt="" />
              </Col>
            </Row>
          </Form.Group>
        </Col>
      </Row>

      <Row style={{margin: '5px 0px 0px 0px'}}>
        <Col style={{margin: 0, padding: 0}}>
          <Button disabled={!this.state.formIsValid || this.props.isLoading} onClick={this.onSubmit} style={{fontWeight: 'bold', width: '100%'}}>{this.props.isLoading ? 'Loading...' : 'RESET PASSWORD AND LOGIN'}</Button>
        </Col>
      </Row>

      {/* <Row style={{marginTop: '20px'}}>
        <Col xs={2} md={2}>
          <Form.Check inline onChange={(event) => {
            const iAgree = event.target.value
            this.updateFormField({iAgree})
          }} style={{backgroundColor: '#FFFFFF', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px', width: '100%'}} /> 
        </Col>
        <Col xs={10} md={10}>
          <Text>Remember me</Text>
        </Col>
      </Row> */}

        <Row style={{margin: 0}}>
          <Col style={{margin: 0, padding: 0}}>
            <div style={{color: process.env.REACT_APP_LINK_COLOUR, marginTop: '15px'}}><Link to="/login">Back to Login</Link></div>
          </Col>
        </Row>

    </React.Fragment>
      
    const desktopContainer = <Container fluid style={{
      margin: 0,
      marginRight: '165px',
      marginTop: '70px',
      paddingLeft: '0px',
      paddingRight: '0px',
      paddingTop: '90px',
      paddingBottom: '100px'
    }}>
      <Row style={{margin: 0, padding: 0}}>
        <Col xs={12} md={12} lg={6} style={{margin: 0, padding: 0, display: 'flex', justifyContent: this.state.deviceIsPC ? 'flex-start' : 'center',
        marginLeft: this.state.deviceIsPC ? '10%' : '0%'}}>
          <div style={{
            border: '2px', 
            borderRadius: '8px',
            boxShadow: '0 1px 5px 4px rgba(0,0,0,0.12)', 
            // margin: '5px',
            padding: '75px',
            paddingTop: '50px',
            paddingBottom: '35px',
            backgroundColor: 'white',
            width: '450px'}}>
            {card}
          </div>
        </Col>
      </Row>
    </Container>
  
    const mobileContainer = <Container fluid style={{padding: '20px', margin: 0}}>
      {card}
    </Container>

    return <div style={{
      backgroundColor: 'white',
      minHeight: '100vh',
      height: '100%',
      width: '100%',
      maxWidth: '1440px',
      overflow: 'hidden',
      display: 'block',
      flexDirection: 'column',
      marginLeft: 'auto',
      marginRight: 'auto',
      position: 'relative',
      paddingBottom: '100px'
    }}>
      <div>
        <img
          hidden={!this.state.deviceIsPC}
          src={'/assets/media/images/StyleGuide_Interswitch_2015_V02.png'}
          style={{position: 'absolute', right: '0px', zIndex: 0}}
          height={'740px'}
          width={'1028px'}
        />
      </div>

      <Header transparent={true} outerDivStyle={{backgroundColor: 'white', maxWidth: '1440px'}} />

      {this.state.deviceIsMobile ? mobileContainer : desktopContainer}
      
      <Footer />

    </div>
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.tunnel.isLoading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dismissErrorMessage: () => dispatch(dismissErrorMessage()),
    showErrorMessage: message => dispatch(showErrorMessage(message)),
    updateLoading: (isLoading, percentage=null) => dispatch(updateLoading({isLoading, percentage}))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
