import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import InputText from "../component/input";

import "react-datepicker/dist/react-datepicker.css";

import H1 from "../../../../components/h1";
import { HTTP_NOT_FOUND_CODE, SUCCESS_CODE } from "../../../../constants/api";
import PrimaryButton from "../component/button";
import {
  isEmailValid,
  isBusinessNameValid,
  isHomeAddressValid,
  isBusinessLgaValid,
} from "../component/extras";

import FormLabel from "../../../../components/form-label";
import HeaderSignup from "../../../../components/header-signup";
import UserManagement from "../../../../services/api/resources/user-management";
import { navigateTo } from "../../../../services/redux/actions/navigation";
import { formatEmailOrPhone } from "../../../../utils/formatters";
import {
  isNumberValid,
  isPhoneValid,
} from "../../../../utils/validators/form-validators";
import Stepper from "../component/stepper";
import SelectInput from "../component/select";
import CountriesStatesLga from "../../../../fixtures/countries_states_lgas.json";
import BackButton from "../component/backButton";
import { SelectInputLga, SelectInputState } from "../component/stateSelect";

const PersonalDetailsScene = ({ setScreen, application }) => {
  const bvnInformation = JSON.parse(localStorage.getItem("bvnInformation"));
  const bvnInfo = JSON.parse(localStorage.getItem("bvnInfo"));

  const [isLoading, setIsLoading] = useState(false);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState(null);

  const [personalDetailsForm, setPersonalDetailsForm] = useState({
    nationality: "",
    state: "",
    city: "",
    lga: "",
    streetNumber: "",
    houseAddress: "",
    gender: "",
    postalCode: "",
  });

  useEffect(() => {
    const updatedpersonalDetailsForm = JSON.parse(
      localStorage.getItem("personalDetailsForm")
    );

    if (updatedpersonalDetailsForm) {
      setPersonalDetailsForm({
        email: bvnInformation?.email || updatedpersonalDetailsForm.email || "",
        lastName:
          application?.personalDetails?.lastName ||
          updatedpersonalDetailsForm.lastName ||
          "",
        firstName:
          application?.personalDetails?.firstName ||
          updatedpersonalDetailsForm.firstName ||
          "",
        phoneNumber:
          application?.personalDetails?.phoneNumber ||
          updatedpersonalDetailsForm.phoneNumber ||
          "",
        nationality:
          application?.personalDetails?.nationality ||
          updatedpersonalDetailsForm.nationality ||
          "",
        state:
          application?.personalDetails?.state ||
          updatedpersonalDetailsForm.state ||
          "",
        city:
          application?.personalDetails?.city ||
          updatedpersonalDetailsForm.city ||
          "",
        lga:
          application?.personalDetails?.lga ||
          updatedpersonalDetailsForm.lga ||
          "",
        streetNumber:
          application?.personalDetails?.streetNumber ||
          updatedpersonalDetailsForm.streetNumber ||
          "",
        houseAddress:
          application?.personalDetails?.houseAddress ||
          updatedpersonalDetailsForm.houseAddress ||
          "",
        gender:
          application?.personalDetails?.gender ||
          updatedpersonalDetailsForm.gender ||
          "",
        postalCode:
          application?.personalDetails?.postalCode ||
          updatedpersonalDetailsForm.postalCode ||
          "",
        dob: application
          ? new Date(2005, 0, 1)
          : updatedpersonalDetailsForm
          ? new Date(updatedpersonalDetailsForm.dob)
          : new Date(2005, 0, 1),
      });
    }
  }, [application]);

  useEffect(() => {
    localStorage.setItem(
      "personalDetailsForm",
      JSON.stringify(personalDetailsForm)
    );
  }, [personalDetailsForm]);

  const updateFormField = (params) => {
    const newForm = {
      ...personalDetailsForm,
      ...params,
    };

    setPersonalDetailsForm(newForm);
  };

  const stripSpacesFromString = (str) => {
    return str.replace(/ /g, "");
  };

  const isFormValid = () => {
    return (
      personalDetailsForm.nationality &&
      personalDetailsForm.state &&
      personalDetailsForm.city &&
      personalDetailsForm.lga &&
      personalDetailsForm.streetNumber &&
      personalDetailsForm.houseAddress &&
      personalDetailsForm.gender &&
      personalDetailsForm.postalCode &&
      isHomeAddressValid(personalDetailsForm.houseAddress)
    );
  };

  const gender = ["Male", "Female"];
  const nationality = ["Nigeria"];
  const NEW_PHONE = "phone is new";
  const NEW_EMAIL = "email is new";
  const USED_PHONE = "phone not new";
  const USED_EMAIL = "email not new";

  const userManagement = new UserManagement();

  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [isEmailExisting, setIsEmailExisting] = useState("");

  const validateEmail = async (email) => {
    if (!email) {
      return;
    }

    setIsEmailLoading(true);
    const validateUserObj = await userManagement.validateUserOnPassport(email);
    const { code } = validateUserObj;
    if (code === SUCCESS_CODE) {
      setIsEmailExisting(USED_EMAIL);
      setIsEmailLoading(false);
      setEmailError(false);
    } else if (code === HTTP_NOT_FOUND_CODE) {
      setIsEmailExisting(NEW_EMAIL);
      setIsEmailLoading(false);
      setEmailError(false);
    } else {
      setIsEmailLoading(false);
      setEmailError(true);
    }
  };

  const fetchAllStates = async (countryId) => {
    const stateArray = CountriesStatesLga[0].states.map((state) => {
      return {
        name: state.name,
        id: state.id,
      };
    });

    setStates(stateArray);
  };

  const selectedStateData = CountriesStatesLga[0].states.find(
    (state) => state.id === Number(personalDetailsForm?.state)
  );

  const [isPhoneLoading, setIsPhoneLoading] = useState(false);
  const [isPhoneExisting, setIsPhoneExisting] = useState("");
  const [phoneError, setPhoneError] = useState(false);

  const validatePhone = async (phone) => {
    if (!phone) {
      return;
    }

    setIsPhoneLoading(true);
    const formattedPhone = formatEmailOrPhone(phone);
    const validateUserObj = await userManagement.validateUserOnPassport(
      formattedPhone
    );
    const { code } = validateUserObj;
    if (code === SUCCESS_CODE) {
      setIsPhoneExisting(USED_PHONE);
      setIsPhoneLoading(false);
      setPhoneError(false);
    } else if (code === HTTP_NOT_FOUND_CODE) {
      setIsPhoneExisting(NEW_PHONE);
      setIsPhoneLoading(false);
      setPhoneError(false);
    } else {
      setIsPhoneLoading(false);
      setPhoneError(true);
    }
  };

  useEffect(() => {
    fetchAllStates();
  }, []);

  useEffect(() => {
    const storedState = localStorage.getItem("state");
    const storedLga = localStorage.getItem("lga");

    if (storedState) {
      const selectedStateData = states.find(
        (state) => state.name === storedState
      );
      if (selectedStateData) {
        setSelectedState(selectedStateData.id);
        setPersonalDetailsForm((prevForm) => ({
          ...prevForm,
          state: selectedStateData.id,
          lga: storedLga || "",
        }));
      }
    }
  }, [states]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          gap: "40px",
        }}
      >
        <HeaderSignup />
        <BackButton onClick={() => setScreen(1)} />
      </div>
      <main className="onboarding-container">
        <Col
          style={{
            marginBottom: "20px",
            margin: "0 auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              gap: "10px",
              marginBottom: "30px",
            }}
          >
            <Stepper borderColor="#00425F" />
            <Stepper />
            <Stepper />
          </div>

          <H1
            style={{
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "1rem",
              fontSize: "2rem",
            }}
          >
            Personal Details
          </H1>
          <p style={{ marginBottom: "20px" }}>
            Please enter your details below
          </p>

          <InputText
            label={"Surname"}
            onChange={(e) => {
              updateFormField({ lastName: e.target.value });
            }}
            labelColor={
              !personalDetailsForm.lastName ||
              isBusinessNameValid(personalDetailsForm)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !personalDetailsForm.lastName ||
              isBusinessNameValid(personalDetailsForm)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            placeholder={"Enter your First Name"}
            value={bvnInfo?.data?.bvnLastName}
            maxLength={200}
            disabled={true}
          />
          <InputText
            label={"First Name"}
            onChange={(e) => {
              updateFormField({ lastName: e.target.value });
            }}
            labelColor={
              !personalDetailsForm.businessName ||
              isBusinessNameValid(personalDetailsForm)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !personalDetailsForm.businessName ||
              isBusinessNameValid(personalDetailsForm)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            placeholder={"Enter your First Name"}
            value={bvnInfo?.data?.bvnFirstName}
            maxLength={200}
            disabled={true}
          />

          <InputText
            onBlur={() => {
              isNumberValid(personalDetailsForm) &&
                validatePhone(personalDetailsForm.phoneNumber);
            }}
            label={"Phone Number"}
            placeholder={"Enter phone number"}
            onChange={(e) => {
              updateFormField({
                phoneNumber: stripSpacesFromString(e.target.value),
              });
            }}
            labelColor={
              !personalDetailsForm.phoneNumber ||
              isNumberValid(personalDetailsForm.phoneNumber)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !personalDetailsForm.phoneNumber ||
              isNumberValid(personalDetailsForm.phoneNumber)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            value={bvnInfo?.data?.bvnPhoneNumber}
            maxLength={11}
            disabled={true}
          />

          <InputText
            onBlur={() => {
              isEmailValid(personalDetailsForm) &&
                validateEmail(personalDetailsForm.email);
            }}
            onChange={(e) => {
              updateFormField({ email: e.target.value.trim() });
            }}
            labelColor={
              !personalDetailsForm.email || isEmailValid(personalDetailsForm)
                ? "#3A3B3B"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !personalDetailsForm.email || isEmailValid(personalDetailsForm)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            type={"email"}
            value={bvnInfo?.data?.businessEmail}
            label={"Email address"}
            placeholder={"Enter  email adress"}
            disabled={true}
          />

          <InputText
            onBlur={() => {
              isNumberValid(personalDetailsForm) &&
                validatePhone(personalDetailsForm.phoneNumber);
            }}
            label={"DOB"}
            placeholder={"Date of birth"}
            onChange={(e) => {
              updateFormField({ dob: stripSpacesFromString(e.target.value) });
            }}
            value={bvnInfo?.data?.bvnDateOfBirth}
            maxLength={11}
            disabled={true}
          />

          <SelectInput
            onChange={(e) => {
              updateFormField({ gender: e.target.value });
            }}
            placeholder={"gender"}
            value={personalDetailsForm.gender}
            option={gender}
            label={"Gender"}
            disabled={false}
          />
          <br />

          <SelectInput
            onChange={(e) => {
              updateFormField({ nationality: e.target.value });
            }}
            placeholder={"nationality"}
            value={personalDetailsForm.nationality}
            option={nationality}
            label={"Nationality"}
            disabled={false}
          />

          <SelectInputState
            label={"State"}
            option={states}
            getState={localStorage.getItem("state")}
            value={personalDetailsForm.state}
            placeholder={"State"}
            onChange={(e) => {
              const selectedState = states.find(
                (value) => value.name === e.target.value
              );
              localStorage.setItem("state", selectedState.name);
              setPersonalDetailsForm({
                ...personalDetailsForm,
                state: selectedState.id,
                lga: "",
              });
              localStorage.removeItem("lga");
              setSelectedState(selectedState.id);
              updateFormField({ state: selectedState.id });
            }}
            default={false}
          />

          <InputText
            label={"City"}
            placeholder={"city"}
            disabled={false}
            onChange={(e) => {
              updateFormField({ city: e.target.value });
            }}
            maxLength={200}
            value={personalDetailsForm?.city}
          />

          <SelectInputLga
            border={`3px solid ${
              !isBusinessLgaValid(personalDetailsForm.lga) &&
              personalDetailsForm.state
                ? process.env.REACT_APP_RED_COLOUR
                : "#DFE2E6"
            }`}
            label={"LGA"}
            option={selectedState ? selectedStateData?.lgas : []}
            value={personalDetailsForm.lga}
            placeholder={"LGA"}
            onChange={(e) => {
              const selectedLga = selectedStateData?.lgas.find(
                (value) => value.name === e.target.value
              );

              if (selectedLga) {
                localStorage.setItem("lga", selectedLga.name);
                setPersonalDetailsForm({
                  ...personalDetailsForm,
                  lga: selectedLga.id,
                });
                updateFormField({
                  lga: selectedLga.id,
                });
              }
            }}
          />
          {!isBusinessLgaValid(localStorage.getItem("lga")) &&
            personalDetailsForm.state && (
              <FormLabel
                style={{
                  color: process.env.REACT_APP_RED_COLOUR,
                  fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                  lineHeight: "19px",
                  position: "relative",
                  top: "-15px",
                  width: "100%",
                }}
              >
                Select Local Government Area
              </FormLabel>
            )}

          <InputText
            label={"Postal Code"}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d{0,11}$/.test(value)) {
                updateFormField({ postalCode: value });
              }
            }}
            labelColor={
              !personalDetailsForm.postalCode ||
              /^\d{0,11}$/.test(personalDetailsForm.postalCode)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !personalDetailsForm.postalCode ||
              /^\d{0,11}$/.test(personalDetailsForm.postalCode)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            placeholder={"Enter your postal code"}
            value={personalDetailsForm.postalCode}
            maxLength={6}
            disabled={false}
          />

          <InputText
            label={"Street Number"}
            onChange={(e) => {
              const value = e.target.value;
              if (/^[a-zA-Z0-9 ]*$/.test(value)) {
                updateFormField({ streetNumber: value });
              }
            }}
            labelColor={
              !personalDetailsForm.streetNumber ||
              /^[a-zA-Z0-9 ]*$/.test(personalDetailsForm.streetNumber)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !personalDetailsForm.streetNumber ||
              /^[a-zA-Z0-9 ]*$/.test(personalDetailsForm.streetNumber)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            placeholder={"Enter your street number"}
            value={personalDetailsForm.streetNumber}
            disabled={false}
          />

          <InputText
            label={"House Address"}
            labelColor={
              !personalDetailsForm.houseAddress ||
              isHomeAddressValid(personalDetailsForm.houseAddress)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !personalDetailsForm.houseAddress ||
              isHomeAddressValid(personalDetailsForm.houseAddress)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            placeholder={"Enter your house address"}
            onChange={(e) => {
              updateFormField({ houseAddress: e.target.value });
            }}
            maxLength={200}
            value={personalDetailsForm.houseAddress}
          />

          <PrimaryButton
            disabled={!isFormValid() || isLoading}
            onClick={() => setScreen(3)}
          >
            {isLoading ? "Processing..." : "Next"}
          </PrimaryButton>
        </Col>
      </main>
    </>
  );
};

function mapStateToProps(state) {
  return {
    // isLoading: state.tunnel.isLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: (pendingUrl) => dispatch(navigateTo(pendingUrl)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalDetailsScene);
