import React from 'react';
import { Card, Form } from 'react-bootstrap';
import H1 from '../../../components/h1';
import { CheckIcon, vectorImgOne, vectorImgTwo } from '../../../icons'
// import Background from "../../../../public/assets/media/images/background-upgrade"

const agentDetails = JSON.parse(localStorage.getItem('currentAgent'));
const AgentTypeCard = ({ onClick, docType, buttonStyle, agentType, backgroundImg, icon, text, active }) => {
  localStorage.removeItem('personalDetailsFormUpgrade');
  const handleDisable = () => {
    if (!buttonStyle?.disabled) {
      onClick();
    }
  };

  return (
    <section
      style={{
        backgroundImage: `url(${backgroundImg})`,
        // position: 'relative',
      }}
      className='agent-card-type' >
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>
            <H1
              style={{
                fontSize: '1.5rem',
                fontWeight: '700',
                textAlign: 'start',
                color: buttonStyle.color,

              }}
            >
              {agentType}
            </H1>
            <p style={{
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '20px',
              color: buttonStyle.color,
            }}>Account tier</p>
          </span>
          <div>
            {icon}
          </div>
        </div>

        <div>{active}</div>

        <div>
          {text}
        </div>

        <div
          style={{
            // height: '5rem',
          }}
        >
          <div
            style={{
              marginLeft: '2rem',
            }}
          >
          </div>
        </div>


        {buttonStyle?.text === 'remove button' ? (
          ''
        ) : (
          <div style={{}}>
            <button
              onClick={() => handleDisable()}
              style={{
                display: 'flex',
                // marginTop: '2rem',
                alignItems: 'center',
                border: buttonStyle?.border,
                outline: 'none',
                width: '100%',
                justifyContent: 'center',
                gap: '10px',
                padding: '1rem',
                borderRadius: '12px',
                backgroundColor: buttonStyle?.backgroundColor,
                cursor: buttonStyle?.text === 'Coming Soon' ? 'not-allowed' : 'pointer',
                marginTop: agentType === 'Basic' ? '2rem' : '4rem'
                // position: 'relative',
                // bottom: '10'
              }}
            >
              <div
                style={{
                  color: buttonStyle?.color,
                  fontSize: '17px',
                }}
              >
                {buttonStyle?.text}
              </div>
            </button>
          </div>
        )}
      </div>
    </section >
  );
};
export default AgentTypeCard;

export const Active = () => {
  return (
    <>
      <button style={{
        border: '2px solid #519E47', color: '#519E47', padding: '5px',
        borderRadius: '25px', fontSize: '12px'
      }}><span style={{
        height: '10px', width: '10px', backgroundColor: '#519E47',
        borderRadius: '50%', display: 'inline-block',
      }}></span>ACTIVE</button>
    </>
  )
}

export const LimitText = () => {

  return (
    <div
      style={{
        marginBottom: '10px',
        alignItems: 'center',
        marginTop: '12rem',
        // color: '#5F738C',
      }}
    >
      <p style={{
        height: '16px', marginTop: '30px', fontWeight: '600', fontSize: '12px', lineHeight: '16px'
      }}> You get access to</p>

      <div>
        {CheckIcon()}
        <span style={{ fontWeight: '400', fontSize: '14px', lineHeight: '20px', marginLeft: '15px' }}>{`50,000 Daily transaction limit`}</span><br />
      </div>
      <div>
        {CheckIcon()}
        <span style={{ fontWeight: '400', fontSize: '14px', lineHeight: '20px', marginLeft: '15px' }}>{`300,000 Cummulative Balance`}</span><br />
      </div>
    </div>


  );
};

export const LimitTextStandard = ({ style }) => {
  return (
    <div
      style={{
        marginBottom: '10px',
        alignItems: 'center',
        color: style.textColor
      }}
    >
      <p style={{
        height: '16px', marginTop: '30px', fontWeight: '600', fontSize: '12px', lineHeight: '16px'
      }}> You get access to</p>

      <div>
        {CheckIcon()}
        <span style={{ fontWeight: '400', fontSize: '14px', lineHeight: '20px', marginLeft: '15px' }}>{`₦500,000 Daily transaction limit`}</span><br />
      </div>
      <div>
        {CheckIcon()}
        <span style={{ fontWeight: '400', fontSize: '13.4px', lineHeight: '20px', marginLeft: '15px' }}>{`₦5,000,000 Cummulative Balance`}</span><br />
      </div>


      <p style={{
        height: '16px', marginTop: '30px', fontWeight: '600', fontSize: '12px', lineHeight: '16px'
      }}> To upgrade to Standard</p>
      <div>
        {CheckIcon()}
        <span style={{ fontWeight: '400', fontSize: '14px', lineHeight: '20px', marginLeft: '15px' }}>{`NIN`}</span><br />
      </div>
      <div>
        {CheckIcon()}
        <span style={{ fontWeight: '400', fontSize: '14px', lineHeight: '20px', marginLeft: '15px' }}>{`Utility Bill`}</span><br />
      </div>
      <div>
        {CheckIcon()}
        <span style={{ fontWeight: '400', fontSize: '14px', lineHeight: '20px', marginLeft: '15px' }}>{`ID Document`}</span><br />
      </div>
      <div>
        {CheckIcon()}
        <span style={{ fontWeight: '400', fontSize: '14px', lineHeight: '20px', marginLeft: '15px' }}>{`Reference`}</span><br />
      </div>
    </div>
  );
};

export const LimitTextPrestige = () => {
  return (
    <div
      style={{
        marginBottom: '10px',
        alignItems: 'center',
        color: agentDetails?.agentClass === 'PRESTIGE' ? '#5F738C' : 'white'
      }}
    >

      <p style={{
        height: '16px', marginTop: '15px', fontWeight: '600', fontSize: '12px', lineHeight: '16px'
      }}> You get access to</p>

      <div>
        {CheckIcon()}
        <span style={{ fontWeight: '400', fontSize: '14px', lineHeight: '20px', marginLeft: '15px' }}>{`₦1,000,000 Daily transaction limit`}</span><br />
      </div>
      <div>
        {CheckIcon()}
        <span style={{ fontWeight: '400', fontSize: '13.4px', lineHeight: '20px', marginLeft: '15px' }}>{`₦10,000,000 Cummulative Balance`}</span><br />
      </div>

      <p style={{
        height: '16px', marginTop: '20px', fontWeight: '600', fontSize: '12px', lineHeight: '16px'
      }}> To upgrade to Prestige</p>
      <div>
        {CheckIcon()}
        <span style={{ fontWeight: '400', fontSize: '14px', lineHeight: '20px', marginLeft: '15px' }}>{`NIN`}</span><br />
      </div>
      <div>
        {CheckIcon()}
        <span style={{ fontWeight: '400', fontSize: '14px', lineHeight: '20px', marginLeft: '15px' }}>{`Utility Bill`}</span><br />
      </div>
      <div>
        {CheckIcon()}
        <span style={{ fontWeight: '400', fontSize: '14px', lineHeight: '20px', marginLeft: '15px' }}>{`ID Document`}</span><br />
      </div>
      <div>
        {CheckIcon()}
        <span style={{ fontWeight: '400', fontSize: '14px', lineHeight: '20px', marginLeft: '15px' }}>{`Reference`}</span><br />
      </div>
      <div>
        {CheckIcon()}
        <span style={{ fontWeight: '400', fontSize: '14px', lineHeight: '20px', marginLeft: '15px' }}>{`Corporate documents`}</span><br />
      </div>
    </div>
  );
};