import React from "react";
import {
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
  Dropdown,
  Modal,
} from "react-bootstrap";
import Button from "../../../components/button";
import FormControl from "../../../components/form-control";
import FormLabel from "../../../components/form-label";
import ReactPhoneInput from "react-phone-input-2";
import IosRefresh from "react-ionicons/lib/IosRefresh";
import MdCheckmarkCircle from "react-ionicons/lib/MdCheckmarkCircle";
import { platformServiceClient } from "../../../App";
import { ERROR_STATUS } from "../../../constants/api";
import { saveData } from "../../../utils/storage";
import {
  MAX_BVN_LENGTH,
  MAX_DESCRIPTION_LENGTH,
  MAX_IDENTIFICATION_NUMBER_LENGTH,
  MAX_MOTHERS_MAIDEN_NAME_LENGTH,
  MAX_NAME_LENGTH,
  MAX_PLACE_OF_BIRTH_LENGTH,
  MIN_DOCUMENTS_REQUIRED,
} from "../../../constants/form-fields";

import {
  dismissSuccessMessage,
  showSuccessMessage,
  dismissErrorMessage,
  showErrorMessage,
} from "../../../services/redux/actions/tunnel";
import DatePicker from "react-datepicker";
import {
  isEmailValid,
  isFirstNameValid,
  isLastNameValid,
  isPasswordValid,
  isPhoneValid,
  isOtpValid,
} from "../../../utils/validators/form-validators";
import {
  NIGERIA,
  NIGERIA_PHONE_PREFIX,
  CURRENT_USER,
} from "../../../constants";
import { StatusCode } from "../../../fixtures/report_filter_options";
import { subtractDays } from "../../../utils/time";
import moment from "moment";
import Onboarding from "../../../services/api/resources/onboarding";
import { formatPhoneNumber } from "../../../utils/formatters";

//import Alert from 'react-bootstrap/Alert'
import MdClose from "react-ionicons/lib/MdClose";
import MdCloseCircle from "react-ionicons/lib/MdCloseCircle";
//import MdCheckmarkCircle from "react-ionicons/lib/MdCheckmarkCircle";
import H1 from "../../../components/h1";

const NEW_PHONE = "phone is new";
const USED_PHONE = "phone not new";

export default class BVNValidationForm extends React.Component {
  onboarding = new Onboarding();
  constructor(props) {
    super(props);

    this.state = {
      form: {
        agentPhoneNumber: "",
        bvnFirstName: "",
        bvnLastName: "",
        bvnNumber: "",
        bvnPhoneNumber: "",
        bvnDateOfBirth: "",
        phonePrefix: NIGERIA_PHONE_PREFIX,
        formIsValid: false,
      },
      faultyFields: [],
      fieldsFocused: [],
      phoneError: false,
      isLoading: false,
      isPhoneExisting: "",
      showSuccessModal: false,
      showFailedModal: false,
    };

    //this.validatePhone = this.validatePhone.bind(this);
    this.updateFormField = this.updateFormField.bind(this);
    //this.onPhoneUpdate = this.onPhoneUpdate.bind(this);
    this.propagateFormErrors = this.propagateFormErrors.bind(this);
    this.isPhoneValidAndNew = this.isPhoneValidAndNew.bind(this);
    //this.isDobValid =this.isDobValid.bind(this)
    this.isbvnNumberValid = this.isbvnNumberValid.bind(this);
    this.isDateOfBirthValid = this.isDateOfBirthValid.bind(this);
  }

  componentDidMount() {
    // this.updateFormField({
    // firstName: queryParams.first_name,
    // lastName: queryParams.last_name,
    // })
  }

  updateFormField(params) {
    const newFormData = {
      ...this.state.form,
      ...params,
    };

    const allParamsAreValid =
      isFirstNameValid(newFormData.bvnFirstName) &&
      isLastNameValid(newFormData.bvnLastName) &&
      this.isbvnNumberValid(newFormData.bvnNumber) &&
      isPhoneValid(
        newFormData.bvnPhoneNumber,
        newFormData.phonePrefix,
        this.state.faultyFields
      ) &&
      this.isDateOfBirthValid(newFormData.bvnDateOfBirth);

    this.setState({
      form: newFormData,
      formIsValid: allParamsAreValid,
    });
  }

  propagateFormErrors() {
    this.setState({
      propagateFormErrors: true,
    });
  }

  isPhoneValidAndNew() {
    const { isPhoneExisting, form } = this.state;
    return (
      isPhoneValid(form.phone, form.phonePrefix) &&
      isPhoneExisting === NEW_PHONE
    );
  }

  isbvnNumberValid(param) {
    return true;
  }

  isDateOfBirthValid(dob) {
    return dob !== "" && dob.length !== 0;
  }

  async submitForm() {
    const formatedPhone = formatPhoneNumber(this.state.form.bvnPhoneNumber);
    this.props.updateLoading(true);

    const currentUser = JSON.parse(localStorage.getItem("currentUser") || "{}");
    const formatedVal = moment(
      this.state.form.bvnDateOfBirth,
      "DD-MM-YYYY",
      true
    ).format("YYYY-MM-DD");

    const bvnValidationForm = {
      agentPhoneNumber: currentUser.mobileNo,
      bvnFirstName: this.state.form.bvnFirstName,
      bvnLastName: this.state.form.bvnLastName,
      bvnDateOfBirth: formatedVal,
      bvnNumber: this.state.form.bvnNumber,
      bvnPhoneNumber: formatedPhone,
    };

    const { status, response } = await platformServiceClient.validateBVNDetails(
      bvnValidationForm
    );

    if (status === ERROR_STATUS) {
      this.setState({
        showFailedModal: true,
      });

      this.props.updateLoading(false);

      if (typeof response === "undefined") {
        this.setState({
          showFailedMessage: "An error occurred. Try again later!",
        });
        return null;
      }

      if ("code" in response && response.code === "40002") {
        const msg = "errors" in response && response.errors[0].message;

        this.setState({ showFailedMessage: msg });
      } else {
        const msg = response.message;
        this.setState({ showFailedMessage: msg });
      }

      this.props.setBVNValidationStatus(response.validationStatus);

      return null;
    }
    // else  {

    this.props.setBVNValidationStatus(response.validationStatus);

    this.setState({
      showSuccessModal: true,
      showSuccessMessage: response?.message,
    });

    setTimeout(() => {
      this.setState({ showSuccessModal: false });
    }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));

    //post to onboarding
    const currentApplication = JSON.parse(
      localStorage.getItem("currentApplication")
    );
    const newApplicationData = {
      ...currentApplication,
    };

    newApplicationData.applicantDetails.bvn = this.state.form.bvnNumber;
    newApplicationData.applicantDetails.dob = formatedVal;
    newApplicationData.applicantDetails.bvnVerificationStatus =
      response?.validationStatus;

    localStorage.setItem(
      "currentApplication",
      JSON.stringify(newApplicationData)
    );

    const responseObj = await this.onboarding.saveAsDraft(newApplicationData);

    //  console.log("newApplication :" + JSON.stringify(responseObj))

    //const responseObj = responseObj;
    // console.log("responseObj :" + JSON.stringify(responseObj))

    // console.log("currentApplication :" +  JSON.stringify( localStorage.getItem('currentApplication')) )

    if (responseObj.status === "SUCCESS") {
      localStorage.setItem(
        "currentApplication",
        JSON.stringify(responseObj.response)
      );
      //window.location.href= '/application'

      this.setState({
        agentPhoneNumber: "",
        bvnFirstName: "",
        bvnLastName: "",
        bvnDateOfBirth: "",
        bvnNumber: "",
        bvnPhoneNumber: "",
      });
    } else {
      //code === 400 ? this.props.showErrorMessage("Something is wrong with the data submitted.") :
      // this.props.showErrorMessage(JSON.stringify(response));
      // setTimeout(() => this.props.dismissErrorMessage(), parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT))
    }

    this.props.updateLoading(false);
    this.props.isBVNValidationSuccesful(true);
    this.props.isSignupSuccessful(true);
    //}
  }

  render() {
    const showSuccessModal = (
      <Modal
        animation="true"
        style={{ borderRadius: "10px", transition: "all .2s ease-in-out" }}
        size="lg"
        show={this.state.showSuccessModal}
        onHide={() => {
          this.setState({ showSuccessModal: false });
        }}
      >
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ margin: "10px", padding: "1px", width: "100%" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <MdCheckmarkCircle
              color={"#00FF00"}
              fontSize="50px"
              style={{ marginLeft: "45%", marginBottom: "10px" }}
            />
            <h4 style={{ textAlign: "center", margin: "0px", padding: "0px" }}>
              Successful
            </h4>
            <span style={{ textAlign: "center" }}>
              {" "}
              {this.state.showSuccessMessage}
            </span>
          </div>
        </Modal.Body>
      </Modal>
    );

    const showFailedModal = (
      <Modal
        animation="true"
        style={{
          borderRadius: "10px",
          transition: "all .2s ease-in-out",
          top: 30,
        }}
        size="lg"
        show={this.state.showFailedModal}
        onHide={() => {
          this.setState({ showFailedModal: false });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ margin: "10px", padding: "15px" }}>
          {/* <div style={{display:'flex', flexDirection:'column'}}> */}
          <MdCloseCircle
            color={"#FF0000"}
            fontSize="40px"
            style={{ marginLeft: "45%", marginBottom: "40px" }}
          />
          <h6 style={{ textAlign: "center", margin: "0px", padding: "0px" }}>
            {/* Ensure you have provided accurate BVN information. Please try again. */}
            {this.state.showFailedMessage}
          </h6>
          {/* </div> */}
        </Modal.Body>

        <Modal.Footer style={{ border: "0px solid #000" }}>
          <Button
            onClick={() => {
              this.setState({ showFailedModal: false });
            }}
            style={{ marginRight: "45%" }}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );

    const { form } = this.state;

    return (
      <React.Fragment>
        <Form>
          <Row>
            <Col xs={12} lg={12}>
              <Form.Group controlId="message" className="form-group">
                <FormLabel style={{}}>
                  Please provide your accurate information as it is on your BVN
                </FormLabel>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6}>
              <Form.Group controlId="first-name" className="form-group">
                <FormLabel
                  style={{
                    color: `${
                      (!form.bvnFirstName &&
                        !this.state.fieldsFocused.includes("bvnFirstName") &&
                        !this.state.propagateFormErrors) ||
                      isFirstNameValid(form.bvnFirstName)
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                  }}
                >
                  First name: <span className="required-field-tick">*</span>
                </FormLabel>
                <FormControl
                  // defaultValue={qs.parse(this.props.location.search, { ignoreQueryPrefix: true })['first_name']}
                  type="text"
                  placeholder="Your first name"
                  onBlur={() =>
                    this.setState({
                      fieldsFocused: [
                        ...this.state.fieldsFocused,
                        "bvnFirstName",
                      ],
                    })
                  }
                  onChange={(event) => {
                    const bvnFirstName = event.target.value;
                    this.updateFormField({ bvnFirstName });
                  }}
                  style={{
                    border: `1px solid ${
                      !form.bvnFirstName || isFirstNameValid(form.bvnFirstName)
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                  }}
                  // disabled={showOtpField}
                />
                {/* {(this.state.propagateFormErrors || this.state.fieldsFocused.includes('firstName')) && !form.firstName && <FormLabel style={{color: process.env.REACT_APP_RED_COLOUR, fontSize: process.env.REACT_APP_SMALL_FONT_SIZE, lineHeight: '19px', position: 'absolute', width: '100%', left: 25}}>First name is required.</FormLabel>} */}
                {!form.bvnFirstName || isFirstNameValid(form.bvnFirstName) ? (
                  <React.Fragment />
                ) : (
                  <FormLabel
                    style={{
                      color: process.env.REACT_APP_RED_COLOUR,
                      fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                      lineHeight: "19px",
                      position: "absolute",
                      width: "100%",
                      left: 25,
                    }}
                  >
                    First name is not valid.
                  </FormLabel>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group controlId="last-name" className="form-group">
                <FormLabel
                  style={{
                    color: `${
                      (!form.bvnLastName &&
                        !this.state.fieldsFocused.includes("bvnLastName") &&
                        !this.state.propagateFormErrors) ||
                      isLastNameValid(form.bvnLastName)
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                  }}
                >
                  Last name: <span className="required-field-tick">*</span>
                </FormLabel>
                <FormControl
                  //  defaultValue={qs.parse(this.props.location.search, { ignoreQueryPrefix: true })['last_name']}
                  type="text"
                  placeholder="Your last name"
                  onBlur={() =>
                    this.setState({
                      fieldsFocused: [
                        ...this.state.fieldsFocused,
                        "bvnLastName",
                      ],
                    })
                  }
                  onChange={(event) => {
                    const bvnLastName = event.target.value;
                    this.updateFormField({ bvnLastName });
                  }}
                  style={{
                    border: `1px solid ${
                      !form.bvnLastName || isLastNameValid(form.bvnLastName)
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                  }}
                  //disabled={showOtpField}
                />
                {!form.bvnLastName || isLastNameValid(form.bvnLastName) ? (
                  <React.Fragment />
                ) : (
                  <FormLabel
                    style={{
                      color: process.env.REACT_APP_RED_COLOUR,
                      fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                      lineHeight: "19px",
                      position: "absolute",
                      width: "100%",
                      left: 25,
                    }}
                  >
                    Last name is not valid.
                  </FormLabel>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={6}>
              <Form.Group controlId="bvn" className="form-group">
                <FormLabel
                  style={{
                    color: `${
                      (!form.bvnNumber &&
                        !this.state.fieldsFocused.includes("bvnNumber") &&
                        !this.state.propagateFormErrors) ||
                      isFirstNameValid(form.bvnNumber)
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                  }}
                >
                  BVN: <span className="required-field-tick">*</span>
                </FormLabel>
                <FormControl
                  //defaultValue={qs.parse(this.props.location.search, { ignoreQueryPrefix: true })['bvn']}
                  type="text"
                  placeholder="BVN"
                  onBlur={() =>
                    this.setState({
                      fieldsFocused: [...this.state.fieldsFocused, "bvnNumber"],
                    })
                  }
                  onChange={(event) => {
                    const bvnNumber = event.target.value;
                    this.updateFormField({ bvnNumber });
                  }}
                  style={{
                    border: `1px solid ${
                      !form.bvnNumber || this.isbvnNumberValid(form.bvnNumber)
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                  }}
                  //disabled={}
                />
                {/* {(this.state.propagateFormErrors || this.state.fieldsFocused.includes('firstName')) && !form.firstName && <FormLabel style={{color: process.env.REACT_APP_RED_COLOUR, fontSize: process.env.REACT_APP_SMALL_FONT_SIZE, lineHeight: '19px', position: 'absolute', width: '100%', left: 25}}>First name is required.</FormLabel>} */}
                {!form.bvnNumber || this.isbvnNumberValid(form.bvnNumber) ? (
                  <React.Fragment />
                ) : (
                  <FormLabel
                    style={{
                      color: process.env.REACT_APP_RED_COLOUR,
                      fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                      lineHeight: "19px",
                      position: "absolute",
                      width: "100%",
                      left: 25,
                    }}
                  >
                    BVN is not valid.
                  </FormLabel>
                )}
              </Form.Group>
            </Col>

            <Col xs={12} lg={6}>
              <Form.Group controlId="bvnPhoneNumber" className="form-group">
                <FormLabel
                  style={{
                    color: `${
                      (!form.bvnPhoneNumber &&
                        !this.state.fieldsFocused.includes("bvnPhoneNumber") &&
                        !this.state.propagateFormErrors) ||
                      isPhoneValid(
                        form.bvnPhoneNumber,
                        form.phonePrefix,
                        this.state.faultyFields
                      )
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                  }}
                >
                  Phone number: <span className="required-field-tick">*</span>
                </FormLabel>
                <ReactPhoneInput
                  buttonStyle={{
                    borderTopLeftRadius:
                      process.env.REACT_APP_BORDER_STYLE === "square"
                        ? "4px"
                        : "20px",
                    borderBottomLeftRadius:
                      process.env.REACT_APP_BORDER_STYLE === "square"
                        ? "4px"
                        : "20px",
                  }}
                  containerStyle={{ margin: 0, padding: 0, width: "100%" }}
                  defaultCountry={"ng"}
                  disableCountryCode={true}
                  inputStyle={{
                    backgroundColor: "#FFFFFF",
                    borderRadius:
                      process.env.REACT_APP_BORDER_STYLE === "square"
                        ? "4px"
                        : "20px",
                    border: `1px solid ${
                      !form.bvnPhoneNumber ||
                      isPhoneValid(
                        form.bvnPhoneNumber,
                        form.phonePrefix,
                        this.state.faultyFields
                      )
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                    width: "100%",
                  }}
                  value={form.bvnPhoneNumber || ""}
                  onlyCountries={["ng", "ke", "ug"]}
                  onBlur={() => {
                    isPhoneValid(form.bvnPhoneNumber, form.phonePrefix); //&& this.props.validatePhone(form.bvnPhoneNumber)
                    this.setState({
                      fieldsFocused: [
                        ...this.state.fieldsFocused,
                        "bvnPhoneNumber",
                      ],
                    });
                  }}
                  onChange={(value, data) => {
                    //this.onPhoneUpdate();
                    this.updateFormField({ bvnPhoneNumber: value });
                  }}
                  placeholder="08012345678"
                  //disabled={showOtpField}
                />
                {/* {this.state.isPhoneLoading && this.state.fieldsFocused.includes('bvnPhoneNumber') && 
                                                 <IosRefresh  style={{marginTop: '-43pt', marginLeft: '93%', width: '20px', position: 'relative'}} 
                                                     fontSize="60px" 
                                                     color="grey" 
                                                     rotate={true} />
                                        }   */}

                {/* {(this.isPhoneValidAndNew() && !this.state.isPhoneLoading) ? 
                                                   <MdCheckmarkCircle style={{marginTop: '-43pt', marginLeft: '93%', width: '20px', position: 'relative'}} 
                                                                      color='green' 
                                                                      fontSize='40px'/> 
                                                    : ''
                                        } */}

                {!form.bvnPhoneNumber ||
                isPhoneValid(
                  form.bvnPhoneNumber,
                  form.phonePrefix,
                  this.state.faultyFields
                ) ? (
                  <React.Fragment />
                ) : (
                  <FormLabel
                    style={{
                      color: process.env.REACT_APP_RED_COLOUR,
                      fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                      lineHeight: "19px",
                      position: "absolute",
                      width: "100%",
                      left: 25,
                    }}
                  >
                    Phone number is not valid.
                  </FormLabel>
                )}

                {/* {(this.state.isPhoneExisting === USED_PHONE  && this.state.fieldsFocused.includes('bvnPhoneNumber') && form.bvnPhoneNumber) &&    <FormLabel style={{color: process.env.REACT_APP_RED_COLOUR, fontSize: process.env.REACT_APP_SMALL_FONT_SIZE, lineHeight: '19px', position: 'absolute', width: '100%', left: 25}}>
                                             Phone number already registered on Quickteller Paypoint. Try another number!
                                        </FormLabel>} */}

                {/* {(this.state.phoneError && this.state.fieldsFocused.includes('bvnPhoneNumber') && form.bvnPhoneNumber) &&
                                         <FormLabel style={{color: process.env.REACT_APP_RED_COLOUR, fontSize: process.env.REACT_APP_SMALL_FONT_SIZE, lineHeight: '19px', position: 'absolute', width: '100%', left: 25}}>Failed to validate Phone Number. Try again
                                        </FormLabel>
                                        } */}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={6}>
              <Form.Group controlId="" className="form-group">
                <FormLabel
                  style={{
                    border: "1px solid #DFE2E6",
                    fontWeight: "bold",
                    fontSize: 12,
                    width: 480,
                    backgroundColor: "rgb(223, 226, 230)",
                    maxWidth: 480,
                  }}
                >
                  Dial <span style={{ fontSize: 15 }}>*565*0#</span> to securely
                  get your BVN from your network provider
                </FormLabel>
                {/* <FormControl
                                            type="text"     
                                            placeholder="Dail *555*0# to securely get your BVN from your network provider"
                                            style={{border: '1px solid #DFE2E6' , width:480}}
                                            disabled={true}

                                       /> */}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6} lg={4}>
              <Form.Group>
                <Form.Label
                  style={{
                    color: `${
                      (!this.state.form.bvnDateOfBirth &&
                        !this.state.fieldsFocused.includes("bvnDateOfBirth") &&
                        !this.state.propagateFormErrors) ||
                      this.isDateOfBirthValid(this.state.form.bvnDateOfBirth)
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    fontSize: "16px",
                    lineHeight: "19px",
                  }}
                >
                  Date of Birth: <span className="required-field-tick">*</span>
                </Form.Label>
                <Col xs={12}>
                  <DatePicker
                    customInput={
                      <FormControl
                        maxLength={MAX_DESCRIPTION_LENGTH}
                        placeholder="DD-MM-YYYY"
                        value={this.state.form.bvnDateOfBirth}
                        onBlur={() =>
                          this.setState({
                            fieldsFocused: [
                              ...this.state.fieldsFocused,
                              "bvnDateOfBirth",
                            ],
                          })
                        }
                        style={{
                          backgroundColor: "#FFFFFF",
                          borderRadius: "20px",
                          border: `1px solid ${
                            !this.state.bvnDateOfBirth
                              ? "#DFE2E6"
                              : process.env.REACT_APP_RED_COLOUR
                          }`,
                          boxSizing: "border-box",
                          fontSize: "14px",
                          lineHeight: "17px",
                        }}
                      />
                    }
                    dateFormat="dd-MM-yyyy"
                    showYearDropdown
                    minDate={subtractDays(new Date(), 365 * 90)}
                    maxDate={subtractDays(new Date(), 365 * 18)}
                    selected={this.state.form.bvnDateOfBirth}
                    onBlur={() =>
                      this.setState({
                        fieldsFocused: [
                          ...this.state.fieldsFocused,
                          "bvnDateOfBirth",
                        ],
                      })
                    }
                    onChange={(value) => {
                      this.updateFormField({ bvnDateOfBirth: value });
                    }}
                    placeholderText="DD-MM-YYYY"
                    className="date-picker"
                    stylea={{
                      border: `1px solid ${
                        (!this.state.form.bvnDateOfBirth &&
                          !this.state.propagateFormErrors) ||
                        this.isDateOfBirthValid(this.state.form.bvnDateOfBirth)
                          ? "#DFE2E6"
                          : process.env.REACT_APP_RED_COLOUR
                      }`,
                      borderRadius: "20px",
                      padding: 10,
                    }}
                    popperPlacement="right-end"
                    /// popperContainer={Form}
                  />
                </Col>

                {(this.state.propagateFormErrors ||
                  this.state.fieldsFocused.includes("bvnDateOfBirth")) &&
                  !this.state.form.bvnDateOfBirth && (
                    <Form.Label
                      style={{
                        color: process.env.REACT_APP_RED_COLOUR,
                        fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                        lineHeight: "19px",
                        position: "absolute",
                        width: "100%",
                        left: 25,
                      }}
                    >
                      Date of Birth is required.
                    </Form.Label>
                  )}

                {!this.state.form.bvnDateOfBirth ||
                this.isDateOfBirthValid(this.state.form.bvnDateOfBirth) ? (
                  <React.Fragment />
                ) : (
                  <Form.Label
                    style={{
                      color: process.env.REACT_APP_RED_COLOUR,
                      fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                      lineHeight: "19px",
                      position: "absolute",
                      width: "100%",
                      left: 25,
                    }}
                  >
                    Date of Birth is invalid.
                  </Form.Label>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row style={{ color: "#8C8D8F" }}>
            <Col xs={12} lg={12} className="col">
              <center>
                <Button
                  disabled={this.props.isLoading || !this.state.formIsValid}
                  onClick={(e) => {
                    e.preventDefault();

                    this.submitForm();
                  }}
                  //hidden={}
                >
                  {
                    "Validate BVN" /* {this.props.isLoading ? 'Loading...' : 'SIGN UP'} */
                  }
                </Button>
              </center>
            </Col>
          </Row>
        </Form>

        {this.state.showSuccessModal && showSuccessModal}
        {this.state.showFailedModal && showFailedModal}
      </React.Fragment>
    );
  }
}
