import React from "react";
import { Carousel, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Select from "react-select";

import FormLabel from "../../../../../components/form-label";
import H1 from "../../../../../components/h1";
import HR from "../../../../../components/hr";
import Text from "../../../../../components/text";
import {
  BILLS,
  BUY_AIRTIME,
  CASH_IN,
  DISTRIBUTE,
  NIP_TRANSFER,
  PAYCODE_CASHOUT,
  PAY_A_BILL,
  REQUEST_POS,
  SELL_AIRTIME,
  SEND_MONEY,
  TRANSFER_CASH_IN,
  TRANSFER_TO_ACCOUNT,
  TRANSFER_TO_AGENT,
  USSD_WITHDRAWAL,
  WITHDRAWAL,
} from "../../../../../constants";
import {
  ERROR_STATUS,
  HTTP_BAD_REQUEST_CODE,
  HTTP_NOT_FOUND_CODE,
  SUCCESS_STATUS,
} from "../../../../../constants/api";
import BaseScene from "../../../../../scenes/base-scene";
import Catalog from "../../../../../services/api/resources/catalog";
import Liquidity from "../../../../../services/api/resources/liquidity";
import NipTransaction from "../../../../../services/api/resources/nip-transaction";
import Platform from "../../../../../services/api/resources/platform";
import PosRequest from "../../../../../services/api/resources/pos-request-automation";
import Quickteller from "../../../../../services/api/resources/quickteller";
import Transaction from "../../../../../services/api/resources/transaction";
import TransactionHistory from "../../../../../services/api/resources/transaction-history";
import {
  dismissErrorMessage,
  dismissSuccessMessage,
  showErrorMessage,
  showSuccessMessage,
  updateLoading,
} from "../../../../../services/redux/actions/tunnel";
import {
  convertNgkToNgn,
  convertNgnToNgk,
} from "../../../../../utils/converters/currencies";
import {
  formatPhoneNumber,
  nameFormatter,
  refineDistributeData,
} from "../../../../../utils/formatters";
import {
  generateChecksum,
  generateMacValue,
} from "../../../../../utils/helpers";
import MockTransaction from "../../../../../utils/mocks/transaction";
import Wallet from "../../../../../utils/mocks/wallet";
import "../styles.scss";

import { stopwatch } from "../../../../../App";
import ReceiptPage from "../../../../../components/receipt";
import {
  TRANSACTION_INITIATE_CLICK,
  TRANSACTION_INITIATE_FAILURE,
  TRANSACTION_INITIATE_SUCCESS,
  TRANSACTION_PROCEED_CLICK,
  TRANSACTION_PROCEED_FAILURE,
  TRANSACTION_PROCEED_SUCCESS,
} from "../../../../../constants/analytics";
import { logEvent } from "../../../../../core/logger";
import { cancelIcon, receiptIcon } from "../../../../../icons";
import AgentSerializer from "../../../../../serializers/resources/agent";
import { navigateTo } from "../../../../../services/redux/actions/navigation";
import {
  CASHIN_PAYMENT_ITEM_CODE,
  DISTRIBUTE_PAYMENT_ITEM_CODE,
  NIP_TRANSFERS_PAYMENT_ITEM_CODE,
  QUICKTELLER_CHANNEL,
  QUICKTELLER_TERMINAL_ID,
  TRANSFERS_PAYMENT_ITEM_CODE,
} from "../../../../../utils/api-properties";
import { CashInConfirmationTab } from "../tabs/CashInConfirmationTab";
import { ConfirmationTab } from "../tabs/ConfirmationTab";
import { DistributeConfirmationTab } from "../tabs/DistributeConfirmationTab";
import { NIPTransferConfirmationTab } from "../tabs/NIPTransferConfirmationTab";
import { PayBillConfirmationTab } from "../tabs/PayBillConfirmationTab";
import { SellAirtimeConfirmationTab } from "../tabs/SellAirtimeConfirmationTab";
import { TransferConfirmationTab } from "../tabs/TransferConfirmationTab";
import { UssdConfirmationTab } from "../tabs/UssdConfirmationTab";
import { WalletToWalletConfirmationTab } from "../tabs/WalletToWalletConfirmationTab";
import { PayCodeConfirmationTab } from "../tabs/payCodeConfirmationTab";
import { PayBillTab } from "../tabs/paybills/pay-bills-tab";
import "./product-payment-styles.scss";

export const getConfirmationPage = (props) => {
  if (props.category === SELL_AIRTIME) {
    return SellAirtimeConfirmationTab(props);
  }
  if (props.category === PAY_A_BILL) {
    return PayBillConfirmationTab(props);
  }
  if (props.category === CASH_IN) {
    return CashInConfirmationTab(props);
  }
  if (props.category === DISTRIBUTE) {
    return DistributeConfirmationTab(props);
  }
  if (props.category === SEND_MONEY && props.service.id === 1) {
    return TransferConfirmationTab(props);
  }
  if (props.category === SEND_MONEY && props.service.id === 7) {
    return TransferConfirmationTab(props);
  }
  if (props.category === SEND_MONEY && props.service.id === 2) {
    return WalletToWalletConfirmationTab(props);
  }
  if (props.category === WITHDRAWAL && props.service.id === 1) {
    return UssdConfirmationTab(props);
  }
  if (props.category === WITHDRAWAL && props.service.id === 2) {
    return PayCodeConfirmationTab(props);
  }
  if (props.category === SEND_MONEY && props.service.id === 4) {
    return NIPTransferConfirmationTab(props);
  }
};

class ProductPayment extends BaseScene {
  quickteller = new Quickteller();
  catalog = new Catalog();
  transaction = new Transaction();
  transactionHistory = new TransactionHistory();
  nipTransaction = new NipTransaction();
  mockTransaction = new MockTransaction({
    wallet: new Wallet(),
  });
  liquidity = new Liquidity();
  platform = new Platform();

  posRequest = new PosRequest();

  constructor(props) {
    super(props);

    this.state = {
      showCongratulationsModal: false,
      form: {
        billerOption: null,
      },
      distributeForm: {},
      receipt: null,
      showGetAmountField: true,
      selectedAgents: [],
      subAccounts: [],
      banks: [],
      ussdCashoutBanks: [],
      currentAgent: {},

      posTypes: [],
      posModels: [],
      posTotalPrice: 0,
      posUnitPrice: 0,
    };

    this.createBillsPaymentRequest = this.createBillsPaymentRequest.bind(this);
    this.onInitiateSuccessful = this.onInitiateSuccessful.bind(this);
    this.onBuyAirtimeProceed = this.onBuyAirtimeProceed.bind(this);
    this.onBuyDataProceed = this.onBuyDataProceed.bind(this);
    this.onPayBillProceed = this.onPayBillProceed.bind(this);
    this.onNipProceed = this.onNipProceed.bind(this);
    this.onPayBillProceedWithSubAccount =
      this.onPayBillProceedWithSubAccount.bind(this);
    this.onSendMoneyProceed = this.onSendMoneyProceed.bind(this);
    this.onTransferCashinProceed = this.onTransferCashinProceed.bind(this);
    this.onSendNIPMoneyProceed = this.onSendNIPMoneyProceed.bind(this);
    this.onCashInProceed = this.onCashInProceed.bind(this);
    this.onWalletTransferProceed = this.onWalletTransferProceed.bind(this);
    this.onPayCodeWithdrawalProceed =
      this.onPayCodeWithdrawalProceed.bind(this);
    this.onUssdWithdrawalProceed = this.onUssdWithdrawalProceed.bind(this);
    this.onPayBillConfirmation = this.onPayBillConfirmation.bind(this);
    this.onSellAirtimeConfirmation = this.onSellAirtimeConfirmation.bind(this);
    this.onCashInConfirmation = this.onCashInConfirmation.bind(this);
    this.onTransferConfirmation = this.onTransferConfirmation.bind(this);
    this.onUssdWithdrawalConfirmation =
      this.onUssdWithdrawalConfirmation.bind(this);
    this.onNIPTransferConfirmation = this.onNIPTransferConfirmation.bind(this);
    this.onPayCodeWithdrawalConfirmation =
      this.onPayCodeWithdrawalConfirmation.bind(this);
    this.onWalletToWalletTransferConfirmation =
      this.onWalletToWalletTransferConfirmation.bind(this);
    this.createWalletToWalletRequest =
      this.createWalletToWalletRequest.bind(this);
    this.onPayBillGetAmount = this.onPayBillGetAmount.bind(this);
    this.getServices = this.getServices.bind(this);
    this.getAgents = this.getAgents.bind(this);
    this.onDistributeProceed = this.onDistributeProceed.bind(this);
    this.onDistributeConfirmation = this.onDistributeConfirmation.bind(this);
    this.setDistributeAgent = this.setDistributeAgent.bind(this);
    this.removeDistributeAgent = this.removeDistributeAgent.bind(this);
    this.updateDistributeFormField = this.updateDistributeFormField.bind(this);
    this.getUssdWithdrawalBanks = this.getUssdWithdrawalBanks.bind(this);
    this.handleUsedModalClose = this.handleUsedModalClose.bind(this);
    this.onCashinShowReceipt = this.onCashinShowReceipt.bind(this);
    this.fetchUssdCashoutBanks = this.fetchUssdCashoutBanks.bind(this);
    this.onPosRequestProceed = this.onPosRequestProceed.bind(this);
    this.getAgentDetails = this.getAgentDetails.bind(this);

    this.getPosTypes = this.getPosTypes.bind(this);
    this.getPosModels = this.getPosModels.bind(this);
    this.getPosUnitPrice = this.getPosUnitPrice.bind(this);
    this.setPosTotalPrice = this.setPosTotalPrice.bind(this);
    this.setPosUnitPrice = this.setPosUnitPrice.bind(this);
  }

  componentDidMount() {
    this.props.category !== SELL_AIRTIME &&
      this.props.category !== REQUEST_POS &&
      this.props.category !== DISTRIBUTE &&
      this.props.category !== WITHDRAWAL &&
      this.props.category !== SEND_MONEY &&
      this.getOptions();
    this.props.category === SELL_AIRTIME && this.getServices();
    this.props.category === DISTRIBUTE && this.getAgents();
    this.props.category === WITHDRAWAL && this.fetchUssdCashoutBanks();
    this.props.category === SEND_MONEY && this.getTransferBanks();
    this.getCurrentUser();
    this.getAgentDetails();
  }

  async getTransferBanks() {
    const transferBanks = await this.transaction.getUnifiedBanks();
    const transferBanksStatus = transferBanks.status;

    if (transferBanksStatus === ERROR_STATUS) {
      return;
    }

    const banksList = transferBanks.response || [];
    banksList.sort((a, b) => {
      if (a.bankName.toLowerCase() > b.bankName.toLowerCase()) {
        return 1;
      }

      if (a.bankName.toLowerCase() < b.bankName.toLowerCase()) {
        return -1;
      }

      return 0;
    });

    this.setState({
      errorDescription: "",
      banks: banksList,
      form: {
        ...this.state.form,
      },
    });
  }

  async fetchUssdCashoutBanks() {
    this.setState({
      isGetUssdCashoutBankLoading: true,
    });

    const { status, response } = await this.liquidity.fetchUssdCashoutBanks();

    if (status === ERROR_STATUS) {
      this.setState({
        errorLoading: false,
        isGetUssdCashoutBankLoading: false,
      });

      return;
    }
    const transformUssdBanks = (ussdBanks) => {
      const newUssdBanks = ussdBanks.map(
        ({ name: label, name: value, name, ...rest }) => ({
          label,
          value,
          name,
          ...rest,
        })
      );
      return newUssdBanks;
    };

    this.setState({
      isGetUssdCashoutBankLoading: false,
      ussdCashoutBanks: transformUssdBanks(response),
    });
  }

  getCurrentUser() {
    const currentUser = JSON.parse(localStorage.getItem("currentUser") || "{}");
    this.setState({ currentUser: currentUser });
  }

  handleGetAgentsEmptyResponse = (code) => {
    this.setState({
      agents: [],
      isLoading: false,
    });
    if (code === HTTP_NOT_FOUND_CODE) {
      this.setState({
        didErrorOccurWhileGettingAgents: false,
      });
    } else {
      this.setState({
        didErrorOccurWhileGettingAgents: true,
      });
    }
  };

  updateDistributeFormField(params) {
    this.setState({
      distributeForm: {
        ...this.state.distributeForm,
        ...params,
      },
    });
  }

  //TODO: look into this
  async getAgents() {
    this.setState({
      isLoading: true,
    });
    //noPaging
    const { status, response, code } = await this.platform.searchAgents(
      null,
      null,
      null,
      true
    );
    const agentSample = response.content;

    if (status === SUCCESS_STATUS) {
      const transformAgent = (agentSample) => {
        const newAgentArray = agentSample.map(
          ({ accountName: label, accountName: value, ...rest }) => ({
            label,
            value,
            ...rest,
          })
        );
        return newAgentArray;
      };
      this.setState({
        agents: transformAgent(agentSample),
        isLoading: false,
        didErrorOccurWhileGettingAgents: false,
      });
    } else {
      this.handleGetAgentsEmptyResponse(code);
    }
  }

  setDistributeAgent(value) {
    const { amount, currentAgent } = value;
    const { selectedAgents } = this.state;
    const modifiedAmount = convertNgnToNgk(amount);

    const selectedAgent = {
      amount: modifiedAmount,
      beneficiaryAccountNo: currentAgent.accountNo,
      beneficiaryAgentCode: currentAgent.agentCode,
      beneficiaryEmail: currentAgent.businessEmail,
      beneficiaryFirstName: currentAgent.agentBankAccount.accountName.replace(
        /\s/g,
        ""
      ),
      beneficiaryLastName: currentAgent.agentBankAccount.accountName.replace(
        /\s/g,
        ""
      ),

      beneficiaryName: currentAgent.agentBankAccount.accountName.replace(
        /\s/g,
        ""
      ),

      beneficiaryPhone: currentAgent.agentMobileNo,
      businessName: currentAgent.businessName,
    };

    const agentCode = currentAgent.agentCode;
    const agents = this.state.agents.filter((agent) => {
      if (agent.agentCode === agentCode) {
        agent.selected = true;
      }
      return agent;
    });
    this.setState({
      agents,
      selectedAgents: selectedAgents.concat(selectedAgent),
    });
  }

  removeDistributeAgent(value) {
    const { selectedAgents, distributeForm } = this.state;

    const agentCode = value.beneficiaryAgentCode;

    const agents = this.state.agents.filter((agent) => {
      if (agent.agentCode === agentCode) {
        agent.selected = false;
      }
      return agent;
    });

    const filteredAgents = selectedAgents.filter((agent) => {
      return agent.beneficiaryAgentCode !== agentCode;
    });

    const modifiedDistributeForm = {
      currentAgent: {},
      amount: distributeForm.amount,
    };

    this.setState({
      agents,
      selectedAgents: filteredAgents,
      distributeForm: modifiedDistributeForm,
    });
  }

  async getOptions() {
    this.setState({
      isLoading: true,
    });
    const { status, response } =
      process.env.REACT_APP_SHOW_QUICKTELLER_SERVICE_URL === "true"
        ? await this.catalog.getOptions2(
            this.props.service.urlName,
            QUICKTELLER_TERMINAL_ID
          )
        : await this.catalog.getOptions(
            this.props.service.urlName,
            QUICKTELLER_TERMINAL_ID
          );

    const serviceOptions = response?.options?.slice(0, 1000);
    localStorage.setItem("services", JSON.stringify(serviceOptions));

    if (status === ERROR_STATUS) {
      this.setState({
        errorLoading: false,
        isLoading: false,
      });

      return;
    }
    const transformServiceOption = (serviceOptions) => {
      const newServiceOptionArray = serviceOptions?.map(
        ({ name: label, name: value, name, ...rest }) => ({
          label,
          value,
          name,
          ...rest,
        })
      );
      return newServiceOptionArray;
    };

    this.setState({
      isLoading: false,
      service: response,
      serviceOptions: transformServiceOption(serviceOptions),
    });
  }

  async getServices() {
    this.setState({
      isLoading: true,
    });

    const { status, response } = await this.quickteller.getServices(
      this.props.serviceTypeId,
      QUICKTELLER_TERMINAL_ID
    );
    const services = response.services;

    if (status === ERROR_STATUS) {
      this.props.showErrorMessage("Unable to Fetch Service, Try again");
      setTimeout(() => {
        this.props.dismissErrorMessage();
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
      this.setState({
        isLoading: false,
        erroroccurred: true,
      });

      return;
    }
    const transformServiceOption = (services) => {
      const newServiceOptionArray = services.map(
        ({ name: label, name: value, name, ...rest }) => ({
          label,
          value,
          name,
          ...rest,
        })
      );
      return newServiceOptionArray;
    };

    this.setState({
      isLoading: false,
      services: transformServiceOption(services),
    });
  }

  async getUssdWithdrawalBanks() {
    this.setState({
      isLoading: true,
    });
    const { status, response } = await this.liquidity.fetchWithdrawalBanks();
    const withdrawalBanks = response;
    this.setState({
      isLoading: false,
    });
  }

  getCurrentCarouselIndex(tabs) {
    return tabs.indexOf(this.state.activeTab);
  }

  onConfirmation() {
    // const { amount } = this.state.form;
    // const serviceName = this.state.service ? this.state.service.name : this.props.service.name
    // this.transaction.create(
    //   amount,
    //   serviceName,
    //   this.props.category
    // );
    // this.setState({
    //   showCongratulationsModal: true
    // })
  }

  get toShowTitleSection() {
    return (
      this.props.category === PAY_A_BILL || this.props.category === CASH_IN
    );
  }

  updateFormField(params) {
    this.setState({ activeTab: "pay-bill" });
    this.setState({
      form: {
        ...this.state.form,
        ...params,
      },
    });
  }

  onBillerOptionSelect(billerOption) {
    if (!billerOption) {
      return;
    }

    this.setState({
      amount: billerOption.amount,
      customerIdField: billerOption.customerIdField,
      isAmountFixed: billerOption.isAmountFixed,
      value: billerOption.value,
    });
  }

  async getPosTypes() {
    const { status, response } = await this.posRequest.getPosTypes();
    if (status === ERROR_STATUS) {
      this.setState({
        errorLoading: false,
        isLoading: false,
      });

      return null;
    }

    const options = response.data.map((d, index) => ({
      value: d.terminalType,
      label: d.terminalType,
    }));

    this.setState({ posTypes: options });
  }

  async getPosModels(posType) {
    const { status, response } = await this.posRequest.getPosModels(posType);
    if (status === ERROR_STATUS) {
      this.setState({
        errorLoading: false,
        isLoading: false,
      });

      return null;
    }

    const options = response.data.map((d) => ({
      value: d.terminalModel,
      label: d.terminalModel,
      unitPrice: d.price,
    }));

    this.setState({ posModels: options });
  }

  async getAgentDetails() {
    const currentAgent = JSON.parse(
      localStorage.getItem("currentAgent") || "{}"
    );
    const agentSerializer = new AgentSerializer(currentAgent);

    if (agentSerializer === {}) {
      const { status, response } = await this.platform.getCurrentAgent();
      if (status === ERROR_STATUS) {
        this.setState({
          errorLoading: false,
          isLoading: false,
        });

        return null;
      }
      this.setState({ currentAgent: response });
    } else {
      this.setState({ currentAgent: agentSerializer });
    }
  }

  getPosUnitPrice(posModel, quantity) {
    const unitPrice = this.state.posModels.find(
      (o) => o.value === posModel
    ).unitPrice;

    this.setState({
      posUnitPrice: unitPrice,
      posTotalPrice: quantity * unitPrice,
    });
  }

  setPosUnitPrice(price) {
    this.setState({
      posUnitPrice: price,
    });
  }

  setPosTotalPrice(amount) {
    this.setState({
      posTotalPrice: amount,
    });
  }

  async createBuyAirtimeRequest(formData) {
    const paymentItemCode = formData.paymentCode;
    const amount = convertNgnToNgk(formData.amount);
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const deviceId = localStorage.getItem("deviceUid");
    const httpMethod = "POST";
    const username = currentUser.username;

    logEvent(TRANSACTION_INITIATE_CLICK, {
      transaction_type: BUY_AIRTIME,
    });

    stopwatch.start();

    return await this.transaction.initiateAirtimePayment(
      {
        amount: amount,
        paymentInstrumentType: "CASH",
        customerMsisdn: formatPhoneNumber(
          formData.phone,
          formData.countryShortCode
        ),
        customerId: formData.phone,
        gender: formData.gender,
        narration: `${formData.network} VTU`,
        paymentItemCode: paymentItemCode,
        customerEmail: username,
        terminalId: QUICKTELLER_TERMINAL_ID,
      },
      generateChecksum(
        `${username}${httpMethod}${amount}${httpMethod}${paymentItemCode}${httpMethod}${deviceId}`
      ),
      deviceId
    );
  }

  async createPOSRequest(formData) {
    // const currentAgent = JSON.parse(localStorage.getItem('currentAgent'));

    if (typeof this.state.currentAgent.businessLocation === "undefined") {
      stopwatch.stop();
      this.props.showErrorMessage(
        "Agent Businesss Address Not Available,Kindly Contact The Administrator"
      );
      setTimeout(
        this.props.dismissErrorMessage,
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );

      this.setState({
        errorLoading: false,
        isGetUssdCashoutBankLoading: false,
      });

      this.props.updateLoading(false);
      return null;
    }

    const agentBusinessAddress =
      this.state.currentAgent.businessLocation[0].addressLine1;
    const agentState = this.state.currentAgent.businessLocation[0].state;
    const { status, response } =
      await this.posRequest.posRequestSignatureUpload(formData.file);

    if (status === ERROR_STATUS) {
      this.setState({
        errorLoading: false,
        isGetUssdCashoutBankLoading: false,
      });

      this.props.showErrorMessage(response.description);
      setTimeout(
        this.props.dismissErrorMessage,
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );
      this.props.updateLoading(false);

      //show error message
      return;
    }

    stopwatch.start();
    return await this.posRequest.initiatePosRequest({
      posModel: formData.posModel,
      posType: formData.posType,
      quantity: formData.quantity,
      requestDeviceChannel: "WEB",
      requestType: formData.requestType.value,
      amount: this.state.posTotalPrice,
      signatoryUploadId: response.data.uploadId,
      termsCondition: true,
      agentBusinessAddress: agentBusinessAddress,
      agentState: agentState,
    });
  }

  async createBillsPaymentRequest(formData, newAmount) {
    const { paymentCode } = this.state.form.billerOption;
    // const amount = formData.amount === 0 ?  newAmount : convertNgnToNgk(formData.amount);
    //TO-DO: INCASE IF BUSIMESS REQUESTED OF THIS REQUIREMENT FOR THE NEW AMOUNT TO ALWAYS OVERRIDE THE OLD AMOUNT
    const amount = newAmount || convertNgnToNgk(formData.amount);
    // const currentAgent = JSON.parse(localStorage.getItem('currentAgent'));
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const deviceId = localStorage.getItem("deviceUid");
    const httpMethod = "POST";
    const username = currentUser.username;
    const billDescription = this.props.service.name;

    logEvent(TRANSACTION_INITIATE_CLICK, {
      transaction_type: BILLS,
    });

    stopwatch.start();

    return await this.transaction.initiateBillPayment(
      {
        amount: amount,
        paymentInstrumentType: "CASH",
        customerMsisdn: formatPhoneNumber(
          formData.phone,
          formData.countryShortCode
        ),
        customerId: formData.customerId,
        parentCustomerId: formData.customerId,
        gender: formData.gender,
        email: formData.email,
        address: formData.address,
        paymentItemCode: paymentCode,
        terminalId: QUICKTELLER_TERMINAL_ID,
        narration: billDescription,
        channel: QUICKTELLER_CHANNEL,
      },
      generateChecksum(
        `${username}${httpMethod}${amount}${httpMethod}${paymentCode}${httpMethod}${deviceId}`
      ),
      QUICKTELLER_CHANNEL,
      deviceId
    );
  }

  async createNipPaymentRequest(formData) {
    const { paymentCode } = this.state.form.billerOption;
    const amount = convertNgnToNgk(formData.amount);
    // const currentAgent = JSON.parse(localStorage.getItem('currentAgent'));
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const deviceId = localStorage.getItem("deviceUid");
    const httpMethod = "POST";
    const username = currentUser.username;
    const billDescription = this.props.service.name;

    logEvent(TRANSACTION_INITIATE_CLICK, {
      transaction_type: BILLS,
    });

    stopwatch.start();

    return await this.nipTransaction.initiateBillPayment(
      {
        amount: amount,
        paymentInstrumentType: "CASH",
        customerMsisdn: username,
        customerId: formData.customerId,
        parentCustomerId: formData.customerId,
        gender: formData.gender,
        email: formData.email,
        paymentItemCode: paymentCode,
        terminalId: QUICKTELLER_TERMINAL_ID,
        narration: billDescription,
        channel: QUICKTELLER_CHANNEL,
      },
      generateChecksum(
        `${username}${httpMethod}${amount}${httpMethod}${paymentCode}${httpMethod}${deviceId}`
      ),
      deviceId
    );
  }

  async createCashInPaymentRequest(formData) {
    const { paymentCode } = this.state.form.billerOption;
    const amount = convertNgnToNgk(formData.amount);
    // const currentAgent = JSON.parse(localStorage.getItem('currentAgent'));
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const deviceId = localStorage.getItem("deviceUid");
    const httpMethod = "POST";
    const username = currentUser.username;
    const billDescription = this.props.service.name;

    logEvent(TRANSACTION_INITIATE_CLICK, {
      transaction_type: CASH_IN,
    });

    stopwatch.start();

    return await this.transaction.initiateCashInPayment(
      {
        amount: amount,
        paymentInstrumentType: "CASH",
        customerMsisdn: formatPhoneNumber(
          formData.phone,
          formData.countryShortCode
        ),
        customerId: formData.customerId,
        gender: formData.gender,
        email: formData.email,
        paymentItemCode: paymentCode,
        terminalId: QUICKTELLER_TERMINAL_ID,
        narration: billDescription,
        channel: QUICKTELLER_CHANNEL,
      },
      generateChecksum(
        `${username}${httpMethod}${amount}${httpMethod}${paymentCode}${httpMethod}${deviceId}`
      ),
      deviceId
    );
  }

  async createWalletToWalletRequest(formData) {
    const paymentItemCode =
      process.env.REACT_APP_WALLET_TO_WALLET_PAYMENT_ITEM_CODE;
    const amount = convertNgnToNgk(formData.amount);
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const deviceId = localStorage.getItem("deviceUid");
    // const httpMethod = 'POST';
    // const username = currentUser.email;
    const customerMsisdn = currentUser.businessMobileNo;

    logEvent(TRANSACTION_INITIATE_CLICK, {
      transaction_type: TRANSFER_TO_AGENT,
    });

    stopwatch.start();

    return await this.liquidity.initiateWalletToWalletTransfer(
      {
        amount: amount,
        beneficiaryPhone: formatPhoneNumber(formData.customerId, "wallet"),
        customerMsisdn: customerMsisdn,
        gender: formData.gender,
        paymentItemCode: paymentItemCode,
        terminalType: "WEB",
        paymentInstrumentType: "CASH",
        terminalId: QUICKTELLER_TERMINAL_ID,
        narration: "Wallet To Wallet Transfer",
        // "channel": QUICKTELLER_CHANNEL
      },
      // generateChecksum(
      //   `${username}${httpMethod}${amount}${httpMethod}${paymentItemCode}${httpMethod}${deviceId}`
      // ),
      deviceId
    );
  }

  async createDistributeRequest(formData) {
    const paymentItemCode = DISTRIBUTE_PAYMENT_ITEM_CODE;
    const deviceId = localStorage.getItem("deviceUid");
    const totalAmount =
      formData && refineDistributeData(formData).reduce((a, b) => a + b, 0);

    logEvent(TRANSACTION_INITIATE_CLICK, {
      transaction_type: DISTRIBUTE,
    });

    stopwatch.start();

    return await this.liquidity.initiateDistribute(
      {
        narration: "Distribute",
        paymentItemCode: paymentItemCode,
        terminalId: QUICKTELLER_TERMINAL_ID,
        totalAmount: totalAmount,
        w2WRequestList: [...formData],
      },
      deviceId
    );
  }

  async createTransfersRequest(formData) {
    //alert( formData.gender)
    const paymentItemCode = TRANSFERS_PAYMENT_ITEM_CODE;
    const amount = convertNgnToNgk(formData.amount);
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const deviceId = localStorage.getItem("deviceUid");
    const httpMethod = "POST";
    const username = currentUser.username;

    const initiationCurrencyCode = "NGN";
    const initiationPaymentCode = "CA";
    const terminationCurrencyCode = "NGN";
    const terminationPaymentMethod = "AC";
    const terminationCountryCode = "NG";
    const terminationAmount = amount;

    logEvent(TRANSACTION_INITIATE_CLICK, {
      transaction_type: TRANSFER_TO_ACCOUNT,
    });

    stopwatch.start();

    return await this.transaction.initiateTransfer(
      {
        unifiedTransferRequest: {
          accountNumber: formData.accountNumber,
          accountType: "00",
          bankCode: formData.bank.value,
          bankName: formData.bank.label,
          shortBankName: formData.shortBankName,
          institutionCode: formData.institutionCode,
          channel: QUICKTELLER_CHANNEL,
          beneficiary: {
            firstName: nameFormatter(formData.beneficiaryName)[0].replace(
              /[^a-zA-Z ]/g,
              ""
            ),
            otherNames: nameFormatter(formData.beneficiaryName)[1]
              ? nameFormatter(formData.beneficiaryName)[1].replace(
                  /[^a-zA-Z ]/g,
                  ""
                )
              : "",
            lastName: nameFormatter(formData.beneficiaryName)[2]
              ? nameFormatter(formData.beneficiaryName)[2].replace(
                  /[^a-zA-Z ]/g,
                  ""
                )
              : "",
            mobileNumber: formData.beneficiaryPhone
              ? formatPhoneNumber(
                  formData.beneficiaryPhone,
                  formData.countryShortCode
                )
              : "",
          },
          gender: formData.gender,
          customerServiceProvider: "IFIS",
          amount: amount,
          paymentItemCode: paymentItemCode,
          terminalType: "MOBILE",
          currencyCode: initiationCurrencyCode,
          mac: generateMacValue(
            `${amount}${initiationCurrencyCode}${initiationPaymentCode}${terminationAmount}${terminationCurrencyCode}${terminationPaymentMethod}${terminationCountryCode}`
          ),
          terminalId: QUICKTELLER_TERMINAL_ID,
          paymentInstrumentType: "CASH",
          transactionType: "FUND_TRANSFER_UNIFIED",
          narration: "Transfer to Account",
        },
      },
      generateChecksum(
        `${username}${httpMethod}${amount}${httpMethod}${paymentItemCode}${httpMethod}${deviceId}`
      ),
      deviceId
    );
  }

  async createCashinRequest(formData) {
    const paymentItemCode = CASHIN_PAYMENT_ITEM_CODE;
    const amount = convertNgnToNgk(formData.amount);
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const deviceId = localStorage.getItem("deviceUid");
    const httpMethod = "POST";
    const username = currentUser.username;

    const initiationCurrencyCode = "566";
    const initiationPaymentCode = "CA";
    const terminationCurrencyCode = "566";
    const terminationPaymentMethod = "AC";
    const terminationCountryCode = "NG";
    const terminationAmount = amount;

    stopwatch.start();

    return await this.transaction.initiateTransfer(
      {
        transferRequest: {
          accountNumber: formData.accountNumber,
          accountType: "00",
          bankCode: formData.bank.value,
          bankName: formData.bank.label,
          channel: QUICKTELLER_CHANNEL,
          beneficiary: {
            firstName: nameFormatter(formData.beneficiaryName)[0].replace(
              /[^a-zA-Z ]/g,
              ""
            ),
            otherNames: nameFormatter(formData.beneficiaryName)[1]
              ? nameFormatter(formData.beneficiaryName)[1].replace(
                  /[^a-zA-Z ]/g,
                  ""
                )
              : "",
            lastName: nameFormatter(formData.beneficiaryName)[2]
              ? nameFormatter(formData.beneficiaryName)[2].replace(
                  /[^a-zA-Z ]/g,
                  ""
                )
              : "",
            mobileNumber: formatPhoneNumber(
              formData.beneficiaryPhone,
              formData.countryShortCode
            ),
          },
          gender: formData.gender,
          customerServiceProvider: "IFIS",
          amount: amount,
          paymentItemCode: paymentItemCode,
          terminalType: "WEB",
          currencyCode: initiationCurrencyCode,
          mac: generateMacValue(
            `${amount}${initiationCurrencyCode}${initiationPaymentCode}${terminationAmount}${terminationCurrencyCode}${terminationPaymentMethod}${terminationCountryCode}`
          ),
          terminalId: QUICKTELLER_TERMINAL_ID,
          paymentInstrumentType: "CASH",
          transactionType: TRANSFER_CASH_IN,
          narration: "Transfer to Account",
        },
      },
      generateChecksum(
        `${username}${httpMethod}${amount}${httpMethod}${paymentItemCode}${httpMethod}${deviceId}`
      ),
      deviceId
    );
  }

  async createNIPTransfersRequest(formData) {
    const paymentItemCode = NIP_TRANSFERS_PAYMENT_ITEM_CODE;
    const amount = convertNgnToNgk(formData.amount);
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const deviceId = localStorage.getItem("deviceUid");
    const httpMethod = "POST";
    const username = currentUser.username;

    const initiationCurrencyCode = "566";
    const initiationPaymentCode = "CA";
    const terminationCurrencyCode = "566";
    const terminationPaymentMethod = "AC";
    const terminationCountryCode = "NG";
    const terminationAmount = amount;

    logEvent(TRANSACTION_INITIATE_CLICK, {
      transaction_type: NIP_TRANSFER,
    });

    stopwatch.start();

    return await this.nipTransaction.initiateNIPTransfer(
      {
        accountNumber: formData.accountNumber,
        accountType: "00",
        amount: amount,
        customerId: username,
        institutionCode: formData.bank.value,
        bankName: formData.bank.label,
        nipBeneficiary: {
          phoneNumber: formatPhoneNumber(
            formData.beneficiaryPhone,
            formData.countryShortCode
          ),
          email: formData.beneficiaryEmail,
        },
        channelCode: "6",
        currencyCode: "NGN",
        narration: formData.remark,
        gender: formData.gender,
        paymentItemCode: paymentItemCode,
        paymentInstrumentType: "CASH",
        terminalId: "Test",
        mac: generateMacValue(
          `${amount}${initiationCurrencyCode}${initiationPaymentCode}${terminationAmount}${terminationCurrencyCode}${terminationPaymentMethod}${terminationCountryCode}`
        ),
      },
      generateChecksum(
        `${username}${httpMethod}${amount}${httpMethod}${paymentItemCode}${httpMethod}${deviceId}`
      ),
      deviceId
    );
  }

  async createBuyDataRequest(formData) {
    const paymentItemCode = formData.paymentCode;
    const amount = Math.round(convertNgnToNgk(formData.buyDataAmount));
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const deviceId = localStorage.getItem("deviceUid");
    const httpMethod = "POST";
    const username = currentUser.username;

    logEvent(TRANSACTION_INITIATE_CLICK, {
      transaction_type: BUY_AIRTIME,
    });

    stopwatch.start();

    return await this.transaction.initiateAirtimePayment(
      {
        amount: amount,
        paymentInstrumentType: "CASH",
        customerMsisdn: formatPhoneNumber(
          formData.buyDataPhone,
          formData.countryShortCode
        ),
        customerId: formData.buyDataPhone,
        gender: formData.buyDataGender,
        narration: `${formData.paymentDescription}`,
        paymentItemCode: paymentItemCode,
        customerEmail: username,
        terminalId: QUICKTELLER_TERMINAL_ID,
      },
      generateChecksum(
        `${username}${httpMethod}${amount}${httpMethod}${paymentItemCode}${httpMethod}${deviceId}`
      ),
      deviceId
    );
  }

  async createEPinRequest(formData) {
    const paymentItemCode = formData.paymentCode;
    const amount = convertNgnToNgk(formData.ePinAmount);
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const deviceId = localStorage.getItem("deviceUid");
    const httpMethod = "POST";
    const username = currentUser.username;

    logEvent(TRANSACTION_INITIATE_CLICK, {
      transaction_type: BUY_AIRTIME,
    });

    stopwatch.start();

    return await this.transaction.initiateAirtimePayment(
      {
        amount: amount,
        paymentInstrumentType: "CASH",
        customerMsisdn: formatPhoneNumber(
          formData.ePinPhone,
          formData.countryShortCode
        ),
        customerId: formData.ePinPhone,
        gender: formData.ePinGender,
        narration: `${formData.paymentDescription}`,
        paymentItemCode: paymentItemCode,
        customerEmail: username,
        terminalId: QUICKTELLER_TERMINAL_ID,
      },
      generateChecksum(
        `${username}${httpMethod}${amount}${httpMethod}${paymentItemCode}${httpMethod}${deviceId}`
      ),
      deviceId
    );
  }

  async createUssdWithdrawalRequest(formData) {
    const paymentItemCode = "CASHOUT_USSD";
    const amount = convertNgnToNgk(formData.amount);
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const deviceId = localStorage.getItem("deviceUid");
    const httpMethod = "POST";
    const username = currentUser.username;

    logEvent(TRANSACTION_INITIATE_CLICK, {
      transaction_type: TRANSFER_TO_AGENT,
    });

    stopwatch.start();
    return await this.liquidity.initiateUssdWithdrawalRequest(
      {
        amount: `${amount}`,
        customerId: formatPhoneNumber(formData.senderPhone, "wallet"),
        paymentItemCode: paymentItemCode,
        bankCode: formData.bank.cbnCode,
        checksum: generateChecksum(
          `${username}${httpMethod}${amount}${httpMethod}${deviceId}`
        ),
      },
      deviceId
    );
  }

  //TODO: likely bug here with username
  async createPayCodeWithdrawalRequest(formData) {
    const amount = convertNgnToNgk(formData.amount);
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const deviceId = localStorage.getItem("deviceUid");
    const httpMethod = "POST";
    const username = currentUser.email;

    logEvent(TRANSACTION_INITIATE_CLICK, {
      transaction_type: TRANSFER_TO_AGENT,
    });

    stopwatch.start();

    return await this.liquidity.initiatePaycodeWithdrawalRequest(
      {
        amount: amount,
        checksum: generateChecksum(
          `${username}${httpMethod}${amount}${httpMethod}${deviceId}`
        ),
        customerPhoneNo: formatPhoneNumber(formData.phoneNumber),
        payCode: formData.payCode,
        subscriberId: formatPhoneNumber(formData.initiatorPhone),
        gender: formData.gender,
      },
      deviceId
    );
  }

  onPayBillProceedWithSubAccount(e, formData) {
    formData.parentCustomerId = formData.customerId;
    e.preventDefault();
    this.props.updateLoading(true, 66);
    this.props.updateLoading(false);
    this.setState({ activeTab: "confirmation" });
    this.onInitiateSuccessful(formData);
  }

  async onNipProceed(formData) {
    this.props.updateLoading(true, 66);

    const billPaymentResponse = await this.createNipPaymentRequest(formData);
    stopwatch.stop();
    const billPaymentResponseStatus = billPaymentResponse.status;
    const billPaymentResponseObj = billPaymentResponse.response;
    this.props.updateLoading(false);
    if (billPaymentResponseStatus === ERROR_STATUS) {
      logEvent(TRANSACTION_INITIATE_FAILURE, {
        transaction_type: BILLS,
        secondsElapsed: stopwatch.secondsElapsed,
        errorCode: billPaymentResponse.code,
        errorSource: billPaymentResponse.errorSource,
      });

      this.props.showErrorMessage(JSON.stringify(billPaymentResponseObj), {
        customerId: formData.customerId,
        customerIdField: this.state.form.billerOption.customerIdField,
      });
      setTimeout(
        this.props.dismissErrorMessage,
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );
      return;
    }
  }

  async onPayBillProceed(formData, newAmount = 0) {
    this.props.updateLoading(true, 66);

    const billPaymentResponse = await this.createBillsPaymentRequest(
      formData,
      newAmount
    );
    stopwatch.stop();
    const billPaymentResponseStatus = billPaymentResponse?.status;
    const billPaymentResponseObj = billPaymentResponse?.response;
    this.props.updateLoading(false);
    if (billPaymentResponseStatus === ERROR_STATUS) {
      if (billPaymentResponse.code === HTTP_BAD_REQUEST_CODE) {
        logEvent(TRANSACTION_INITIATE_FAILURE, {
          transaction_type: BILLS,
          secondsElapsed: stopwatch.secondsElapsed,
          errorCode: billPaymentResponse.code,
          errorSource: billPaymentResponse.errorSource,
        });

        this.props.showErrorMessage(JSON.stringify(billPaymentResponseObj), {
          customerId: formData.customerId,
          customerIdField: this.state.form.billerOption.customerIdField,
        });

        setTimeout(
          this.props.dismissErrorMessage,
          parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
        );
        this.setState({
          showProceedModal: true,
        });
        return;
      }

      logEvent(TRANSACTION_INITIATE_FAILURE, {
        transaction_type: BILLS,
        secondsElapsed: stopwatch.secondsElapsed,
        errorCode: billPaymentResponse.code,
        errorSource: billPaymentResponse.errorSource,
      });

      this.props.showErrorMessage(JSON.stringify(billPaymentResponseObj), {
        customerId: formData.customerId,
        customerIdField: this.state.form.billerOption.customerIdField,
      });
      setTimeout(
        this.props.dismissErrorMessage,
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );
      return;
    }

    logEvent(TRANSACTION_INITIATE_SUCCESS, {
      transaction_type: BILLS,
      secondsElapsed: stopwatch.secondsElapsed,
    });

    const subAccounts =
      billPaymentResponseObj?.billsPaymentRequest &&
      billPaymentResponseObj?.billsPaymentRequest?.subAccounts
        ? billPaymentResponseObj?.billsPaymentRequest?.subAccounts
        : [];

    this.setState({
      showSubAccount: subAccounts?.length ? true : false,
      activeTab: subAccounts?.length ? "" : "confirmation",
      form: {
        ...this.state.form,
        ...formData,
        amount: newAmount == 0 ? formData.amount : convertNgkToNgn(newAmount),
      },
      subAccounts: subAccounts,
    });

    this.onInitiateSuccessful(billPaymentResponseObj);
  }

  async onPayBillGetAmount(formData) {
    this.props.updateLoading(true, 66);
    const { paymentCode, customerIdField } = this.state.form.billerOption;
    // const paymentCode = 10403
    const { customerId } = formData;

    const { response, status } = await this.transaction.customerInquiry(
      customerId,
      paymentCode
    );

    if (status === ERROR_STATUS) {
      this.props.showErrorMessage(JSON.stringify(response), {
        customerId: customerId,
        customerIdField: customerIdField,
      });
      setTimeout(
        this.props.dismissErrorMessage,
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );
      this.props.updateLoading(false);
      return;
    }

    this.setState({
      showGetAmountField: false,
      amount: response.amount,
      customerId: response.customerId,
    });
    this.props.updateLoading(false);
    this.onPayBillProceed(formData, response.amount);
  }

  async onCashInProceed(formData) {
    this.props.updateLoading(true, 66);

    const cashInPaymentResponse = await this.createCashInPaymentRequest(
      formData
    );
    stopwatch.stop();
    const cashInPaymentResponseStatus = cashInPaymentResponse.status;
    const cashInPaymentResponseObj = cashInPaymentResponse.response;
    this.props.updateLoading(false);

    if (cashInPaymentResponseStatus === ERROR_STATUS) {
      logEvent(TRANSACTION_INITIATE_FAILURE, {
        transaction_type: CASH_IN,
        secondsElapsed: stopwatch.secondsElapsed,
        errorCode: cashInPaymentResponse.code,
        errorSource: cashInPaymentResponse.errorSource,
      });

      this.props.showErrorMessage(JSON.stringify(cashInPaymentResponseObj));
      setTimeout(
        this.props.dismissErrorMessage,
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );
      return;
    }

    logEvent(TRANSACTION_INITIATE_SUCCESS, {
      transaction_type: CASH_IN,
      secondsElapsed: stopwatch.secondsElapsed,
    });

    this.setState({
      activeTab: "confirmation",
      form: {
        ...this.state.form,
        ...formData,
      },
    });
    this.showSubAccount = true;
    this.onInitiateSuccessful(cashInPaymentResponseObj);
  }

  async onWalletTransferProceed(formData) {
    this.props.updateLoading(true, 66);

    const walletToWalletResponse = await this.createWalletToWalletRequest(
      formData
    );
    stopwatch.stop();
    const walletTowalletResponseStatus = walletToWalletResponse.status;
    const walletTowalletResponseObj = walletToWalletResponse.response;

    this.props.updateLoading(false);

    if (walletTowalletResponseStatus === ERROR_STATUS) {
      logEvent(TRANSACTION_INITIATE_FAILURE, {
        transaction_type: TRANSFER_TO_AGENT,
        secondsElapsed: stopwatch.secondsElapsed,
        errorCode: walletToWalletResponse.code,
        errorSource: walletToWalletResponse.errorSource,
      });
      this.props.showErrorMessage(JSON.stringify(walletTowalletResponseObj), {
        customerId: formData.walletToWalletbeneficiaryPhone,
        customerIdField: "Phone Number",
      });
      setTimeout(
        this.props.dismissErrorMessage,
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );
      return;
    }

    logEvent(TRANSACTION_INITIATE_SUCCESS, {
      transaction_type: TRANSFER_TO_AGENT,
      secondsElapsed: stopwatch.secondsElapsed,
    });

    this.setState({
      activeTab: "confirmation",
      form: {
        ...this.state.form,
        ...formData,
      },
    });

    this.onInitiateSuccessful(walletTowalletResponseObj);
  }

  async onDistributeProceed(formData) {
    this.props.updateLoading(true, 66);

    const distributeResponse = await this.createDistributeRequest(formData);
    stopwatch.stop();

    const distributeResponseStatus = distributeResponse.status;
    const distributeResponseObj = distributeResponse.response;

    this.props.updateLoading(false);

    if (distributeResponseStatus === ERROR_STATUS) {
      logEvent(TRANSACTION_INITIATE_FAILURE, {
        transaction_type: TRANSFER_TO_AGENT,
        secondsElapsed: stopwatch.secondsElapsed,
        errorCode: distributeResponse.code,
        errorSource: distributeResponse.errorSource,
      });
      this.props.showErrorMessage(JSON.stringify(distributeResponseObj), {
        customerId: formData.walletToWalletbeneficiaryPhone,
        customerIdField: "Phone Number",
      });
      setTimeout(
        this.props.dismissErrorMessage,
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );
      return;
    }

    logEvent(TRANSACTION_INITIATE_SUCCESS, {
      transaction_type: TRANSFER_TO_AGENT,
      secondsElapsed: stopwatch.secondsElapsed,
    });

    this.setState({
      activeTab: "confirmation",
      form: {
        ...this.state.form,
        ...formData,
      },
    });

    this.onInitiateSuccessful(distributeResponseObj);
  }

  async onSendMoneyProceed(formData) {
    this.props.updateLoading(true, 66);

    const transferResponse = await this.createTransfersRequest(formData);
    stopwatch.stop();
    const transferResponseStatus = transferResponse.status;
    const transferResponseObj = transferResponse.response;

    this.props.updateLoading(false);
    if (transferResponseStatus === ERROR_STATUS) {
      logEvent(TRANSACTION_INITIATE_FAILURE, {
        transaction_type: TRANSFER_TO_ACCOUNT,
        secondsElapsed: stopwatch.secondsElapsed,
        errorCode: transferResponse.code,
        errorSource: transferResponse.errorSource,
      });

      this.props.showErrorMessage(JSON.stringify(transferResponseObj));
      setTimeout(
        this.props.dismissErrorMessage,
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );
      return;
    }

    logEvent(TRANSACTION_INITIATE_SUCCESS, {
      transaction_type: TRANSFER_TO_ACCOUNT,
      secondsElapsed: stopwatch.secondsElapsed,
    });

    this.setState({
      activeTab: "confirmation",
      form: {
        ...this.state.form,
        ...formData,
      },
    });

    this.onInitiateSuccessful(transferResponseObj);
  }

  async onTransferCashinProceed(formData) {
    this.props.updateLoading(true, 66);

    const transferResponse = await this.createCashinRequest(formData);
    stopwatch.stop();
    const transferResponseStatus = transferResponse.status;
    const transferResponseObj = transferResponse.response;

    this.props.updateLoading(false);
    if (transferResponseStatus === ERROR_STATUS) {
      logEvent(TRANSACTION_INITIATE_FAILURE, {
        transaction_type: TRANSFER_CASH_IN,
        secondsElapsed: stopwatch.secondsElapsed,
        errorCode: transferResponse.code,
        errorSource: transferResponse.errorSource,
      });

      this.props.showErrorMessage(JSON.stringify(transferResponseObj));
      setTimeout(
        this.props.dismissErrorMessage,
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );
      return;
    }

    logEvent(TRANSACTION_INITIATE_SUCCESS, {
      transaction_type: TRANSFER_CASH_IN,
      secondsElapsed: stopwatch.secondsElapsed,
    });

    this.setState({
      activeTab: "confirmation",
      form: {
        ...this.state.form,
        ...formData,
      },
    });

    this.onInitiateSuccessful(transferResponseObj);
  }

  async onSendNIPMoneyProceed(formData) {
    this.props.updateLoading(true, 66);

    const transferResponse = await this.createNIPTransfersRequest(formData);
    stopwatch.stop();
    const transferResponseStatus = transferResponse.status;
    const transferResponseObj = transferResponse.response;

    this.props.updateLoading(false);

    if (transferResponseStatus === ERROR_STATUS) {
      logEvent(TRANSACTION_INITIATE_FAILURE, {
        transaction_type: TRANSFER_TO_ACCOUNT,
        secondsElapsed: stopwatch.secondsElapsed,
        errorCode: transferResponse.code,
        errorSource: transferResponse.errorSource,
      });

      this.props.showErrorMessage(JSON.stringify(transferResponseObj));
      setTimeout(
        this.props.dismissErrorMessage,
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );
      return;
    }

    logEvent(TRANSACTION_INITIATE_SUCCESS, {
      transaction_type: TRANSFER_TO_ACCOUNT,
      secondsElapsed: stopwatch.secondsElapsed,
    });

    this.setState({
      activeTab: "confirmation",
      form: {
        ...this.state.form,
        ...formData,
      },
    });

    this.onInitiateSuccessful(transferResponseObj);
  }

  async onPosRequestProceed(formData) {
    this.props.updateLoading(true, 66);

    const posRequestResponse = await this.createPOSRequest(formData);

    if (!posRequestResponse) {
      return;
    }

    stopwatch.stop();
    const posRequestResponseStatus = posRequestResponse.status;
    const posRequestResponseObj = posRequestResponse.response;

    this.props.updateLoading(false);

    if (posRequestResponseStatus === ERROR_STATUS) {
      logEvent(TRANSACTION_INITIATE_FAILURE, {
        transaction_type: REQUEST_POS,
        secondsElapsed: stopwatch.secondsElapsed,
        errorCode: posRequestResponse.code,
        errorSource: posRequestResponse.errorSource,
      });

      this.props.showErrorMessage(posRequestResponseObj.description); //description + " !"
      setTimeout(
        this.props.dismissErrorMessage,
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );

      return;
    }

    logEvent(TRANSACTION_INITIATE_SUCCESS, {
      transaction_type: REQUEST_POS,
      secondsElapsed: stopwatch.secondsElapsed,
    });

    this.props.showSuccessMessage(
      "POS request initiated successfully. Request can be viewed on your report's page"
    ); //(posRequestResponseObj.description);

    setTimeout(
      () => this.props.dismissSuccessMessage(),
      parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
    );

    this.onInitiateSuccessful(posRequestResponseObj);

    this.props.navigateTo("/my-reports");
  }

  async onBuyAirtimeProceed(formData) {
    this.props.updateLoading(true, 66);

    const buyAirtimeResponse = await this.createBuyAirtimeRequest(formData);
    stopwatch.stop();
    const buyAirtimeResponseStatus = buyAirtimeResponse.status;
    const buyAirtimeResponseObj = buyAirtimeResponse.response;

    this.props.updateLoading(false);

    if (buyAirtimeResponseStatus === ERROR_STATUS) {
      logEvent(TRANSACTION_INITIATE_FAILURE, {
        transaction_type: BUY_AIRTIME,
        secondsElapsed: stopwatch.secondsElapsed,
        errorCode: buyAirtimeResponse.code,
        errorSource: buyAirtimeResponse.errorSource,
      });
      // this.props.showErrorMessage(JSON.stringify(buyAirtimeResponseObj))
      this.props.showErrorMessage(JSON.stringify(buyAirtimeResponseObj), {
        customerId: formData.phone,
        customerIdField: "phone number",
      });
      setTimeout(
        this.props.dismissErrorMessage,
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );
      return;
    }

    logEvent(TRANSACTION_INITIATE_SUCCESS, {
      transaction_type: BUY_AIRTIME,
      secondsElapsed: stopwatch.secondsElapsed,
    });

    this.setState({
      activeTab: "confirmation",
      form: {
        ...this.state.form,
        ...formData,
      },
    });

    this.onInitiateSuccessful(buyAirtimeResponseObj);
  }

  async onBuyDataProceed(formData) {
    this.props.updateLoading(true, 66);

    const buyAirtimeResponse = await this.createBuyDataRequest(formData);
    stopwatch.stop();
    const buyAirtimeResponseStatus = buyAirtimeResponse.status;
    const buyAirtimeResponseObj = buyAirtimeResponse.response;
    this.props.updateLoading(false);

    if (buyAirtimeResponseStatus === ERROR_STATUS) {
      logEvent(TRANSACTION_INITIATE_FAILURE, {
        transaction_type: BUY_AIRTIME,
        secondsElapsed: stopwatch.secondsElapsed,
        errorCode: buyAirtimeResponse.code,
        errorSource: buyAirtimeResponse.errorSource,
      });
      this.props.showErrorMessage(JSON.stringify(buyAirtimeResponseObj), {
        customerId: formData.buyDataPhone,
        customerIdField: "phone number",
      });
      setTimeout(
        this.props.dismissErrorMessage,
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );
      return;
    }

    logEvent(TRANSACTION_INITIATE_SUCCESS, {
      transaction_type: BUY_AIRTIME,
      secondsElapsed: stopwatch.secondsElapsed,
    });

    this.setState({
      activeTab: "confirmation",
      form: {
        ...this.state.form,
        ...formData,
      },
    });

    this.onInitiateSuccessful(buyAirtimeResponseObj);
  }

  async onUssdWithdrawalProceed(formData, e) {
    e.preventDefault();
    this.props.updateLoading(true, 66);
    const ussdWithdrawalResponse = await this.createUssdWithdrawalRequest(
      formData
    );
    stopwatch.stop();

    const ussdWithdrawalResponseStatus = ussdWithdrawalResponse.status;
    const ussdWithdrawalResponseObj = ussdWithdrawalResponse.response;
    this.props.updateLoading(false);

    if (ussdWithdrawalResponseStatus === ERROR_STATUS) {
      logEvent(TRANSACTION_INITIATE_FAILURE, {
        secondsElapsed: stopwatch.secondsElapsed,
        errorCode: ussdWithdrawalResponseObj.code,
        errorSource: ussdWithdrawalResponseObj.errorSource,
      });

      this.props.showErrorMessage(JSON.stringify(ussdWithdrawalResponseObj), {
        customerId: formData.customerId,
      });
      setTimeout(
        this.props.dismissErrorMessage,
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );
      return;
    }

    logEvent(TRANSACTION_INITIATE_SUCCESS, {
      secondsElapsed: stopwatch.secondsElapsed,
    });

    this.setState({
      activeTab: "confirmation",
      form: {
        ...this.state.form,
        ...formData,
      },
    });
    this.onInitiateSuccessful(ussdWithdrawalResponseObj);
  }

  async onPayCodeWithdrawalProceed(formData, e) {
    e.preventDefault();
    this.props.updateLoading(true, 66);
    const payCodeWithdrawalResponse = await this.createPayCodeWithdrawalRequest(
      formData
    );
    stopwatch.stop();

    const payCodeWithdrawalResponseStatus = payCodeWithdrawalResponse.status;
    const payCodeWithdrawalResponseObj = payCodeWithdrawalResponse.response;
    const payCodeWithdrawalCode = payCodeWithdrawalResponse.code;

    this.props.updateLoading(false);

    if (payCodeWithdrawalResponseStatus === ERROR_STATUS) {
      logEvent(TRANSACTION_INITIATE_FAILURE, {
        transaction_type: PAYCODE_CASHOUT,
        secondsElapsed: stopwatch.secondsElapsed,
        errorCode: payCodeWithdrawalResponseObj.code,
        errorSource: payCodeWithdrawalResponseObj.errorSource,
      });

      if (payCodeWithdrawalCode === 500) {
        this.props.showErrorMessage(
          JSON.stringify(payCodeWithdrawalResponseObj),
          {
            customerId: formData.customerId,
            customerIdField: "User",
          }
        );
        setTimeout(
          this.props.dismissErrorMessage,
          parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
        );
      }

      this.setState({
        showErrorModal: true,
        paycodeError: payCodeWithdrawalResponseObj.description,
      });
      return;
    }

    logEvent(TRANSACTION_INITIATE_SUCCESS, {
      transaction_type: PAYCODE_CASHOUT,
      secondsElapsed: stopwatch.secondsElapsed,
    });

    this.setState({
      payment: payCodeWithdrawalResponseObj,
      form: {
        ...this.state.form,
        ...formData,
      },
      showProceedModal: true,
    });
  }

  async gotoReceiptExtra(extraInfoList) {
    setTimeout(() => {
      this.props.updateLoading(false);
      this.setState({
        activeTab: "payment-receipt",
        form: {
          ...this.state.form,
        },
        payment: {
          ...this.state.payment,
          extraInfoList,
        },
      });
    }, 2000);
  }

  async gotoReceipt() {
    setTimeout(() => {
      this.props.updateLoading(false);
      this.setState({
        activeTab: "payment-receipt",
        form: {
          ...this.state.form,
        },
      });
    }, 2000);
  }

  async onPayBillConfirmation() {
    this.props.updateLoading(true, 66);
    logEvent(TRANSACTION_PROCEED_CLICK, {
      transaction_type: BILLS,
    });

    stopwatch.start();
    const { code, errorSource, response, status } =
      await this.transaction.processBillPayment(
        this.state.payment,
        localStorage.getItem("deviceUid")
      );
    stopwatch.stop();

    if (status === SUCCESS_STATUS) {
      this.setState({
        isProcessed: true,
        pin: response.pin,
        code: response.code,
      });

      let extraInfoList = null;
      if (response.pinExtras) {
        extraInfoList = JSON.parse(response.pinExtras);
      }
      this.gotoReceiptExtra(extraInfoList);

      logEvent(TRANSACTION_PROCEED_SUCCESS, {
        transaction_type: BILLS,
        secondsElapsed: stopwatch.secondsElapsed,
      });

      return;
    }

    if (status === ERROR_STATUS) {
      if (response.code === "01" || response.code === "40006") {
        this.props.showErrorMessage(
          response.description || JSON.stringify(response)
        );

        setTimeout(
          this.props.dismissErrorMessage,
          parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
        );

        logEvent(TRANSACTION_PROCEED_FAILURE, {
          transaction_type: BILLS,
          secondsElapsed: stopwatch.secondsElapsed,
          errorCode: code,
          errorSource: errorSource,
        });

        this.props.onCancelProduct();
        this.props.updateLoading(false);
        return;
      }

      this.setState({
        isProcessed: true,
        pin: response.pin,
        code: response.code,
      });

      this.gotoReceipt();

      logEvent(TRANSACTION_PROCEED_FAILURE, {
        transaction_type: BUY_AIRTIME,
        secondsElapsed: stopwatch.secondsElapsed,
        errorCode: code,
        errorSource: errorSource,
      });

      return;
    }
  }

  async onSellAirtimeConfirmation() {
    this.props.updateLoading(true, 66);
    logEvent(TRANSACTION_PROCEED_CLICK, {
      transaction_type: BUY_AIRTIME,
    });

    stopwatch.start();
    const { code, errorSource, response, status } =
      await this.transaction.processAirtimePayment(
        this.state.payment,
        localStorage.getItem("deviceUid")
      );
    stopwatch.stop();

    if (status === SUCCESS_STATUS) {
      this.setState({
        isProcessed: true,
        pin: response.pin,
        code: response.code,
      });

      this.gotoReceipt();

      logEvent(TRANSACTION_PROCEED_SUCCESS, {
        transaction_type: BUY_AIRTIME,
        secondsElapsed: stopwatch.secondsElapsed,
      });

      return;
    }

    if (status === ERROR_STATUS) {
      if (response.code === "01" || response.code === "40006") {
        logEvent(TRANSACTION_PROCEED_FAILURE, {
          transaction_type: BUY_AIRTIME,
          secondsElapsed: stopwatch.secondsElapsed,
          errorCode: code,
          errorSource: errorSource,
        });

        this.props.showErrorMessage(
          response.description || JSON.stringify(response)
        );

        setTimeout(
          this.props.dismissErrorMessage,
          parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
        );

        this.props.onCancelProduct();
        this.props.updateLoading(false);
        return;
      }
      this.setState({
        isProcessed: true,
        pin: response.pin,
        code: response.code,
      });

      this.gotoReceipt();
      logEvent(TRANSACTION_PROCEED_FAILURE, {
        transaction_type: BUY_AIRTIME,
        secondsElapsed: stopwatch.secondsElapsed,
        errorCode: code,
        errorSource: errorSource,
      });

      return;
    }
  }

  async onCashInConfirmation() {
    this.props.updateLoading(true, 66);
    logEvent(TRANSACTION_PROCEED_CLICK, {
      transaction_type: CASH_IN,
    });

    stopwatch.start();
    const { code, errorSource, status, response } =
      await this.transaction.processBillPayment(
        this.state.payment,
        localStorage.getItem("deviceUid")
      );
    stopwatch.stop();

    if (status === SUCCESS_STATUS) {
      this.setState({
        isProcessed: true,
        pin: response.pin,
        code: response.code,
      });

      this.gotoReceipt();

      logEvent(TRANSACTION_PROCEED_SUCCESS, {
        transaction_type: CASH_IN,
        secondsElapsed: stopwatch.secondsElapsed,
      });

      return;
    }

    if (status === ERROR_STATUS) {
      if (response.code === "01" || response.code === "40006") {
        logEvent(TRANSACTION_PROCEED_FAILURE, {
          transaction_type: CASH_IN,
          secondsElapsed: stopwatch.secondsElapsed,
          errorCode: code,
          errorSource: errorSource,
        });

        this.props.showErrorMessage(
          response.description || JSON.stringify(response)
        );

        setTimeout(
          this.props.dismissErrorMessage,
          parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
        );

        this.props.onCancelProduct();
        this.props.updateLoading(false);
        return;
      }

      this.setState({
        isProcessed: true,
        pin: response.pin,
        code: response.code,
      });

      this.gotoReceipt();

      logEvent(TRANSACTION_PROCEED_FAILURE, {
        transaction_type: BUY_AIRTIME,
        secondsElapsed: stopwatch.secondsElapsed,
        errorCode: code,
        errorSource: errorSource,
      });

      return;
    }
  }

  async onTransferConfirmation() {
    logEvent(TRANSACTION_PROCEED_CLICK, {
      transaction_type: TRANSFER_TO_ACCOUNT,
    });

    this.props.updateLoading(true, 66);
    stopwatch.start();
    const { code, errorSource, response, status } =
      await this.transaction.processTransfer(
        this.state.payment,
        localStorage.getItem("deviceUid")
      );
    stopwatch.stop();
    if (status === SUCCESS_STATUS) {
      this.setState({
        isProcessed: true,
        code: response.code,
      });

      this.gotoReceipt();

      logEvent(TRANSACTION_PROCEED_SUCCESS, {
        transaction_type: TRANSFER_TO_ACCOUNT,
        secondsElapsed: stopwatch.secondsElapsed,
      });

      return;
    }
    if (status === ERROR_STATUS) {
      if (response.code === "01" || response.code.includes("400")) {
        logEvent(TRANSACTION_PROCEED_FAILURE, {
          transaction_type: TRANSFER_TO_ACCOUNT,
          secondsElapsed: stopwatch.secondsElapsed,
          errorCode: code,
          errorSource: errorSource,
        });
        this.props.showErrorMessage(
          response.description || JSON.stringify(response)
        );

        setTimeout(
          this.props.dismissErrorMessage,
          parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
        );

        this.props.onCancelProduct();
        this.props.updateLoading(false);
        return;
      }
      this.setState({
        isProcessed: true,
        pin: response.pin,
        code: response.code,
      });

      this.gotoReceipt();

      logEvent(TRANSACTION_PROCEED_FAILURE, {
        transaction_type: TRANSFER_TO_ACCOUNT,
        secondsElapsed: stopwatch.secondsElapsed,
        errorCode: code,
        errorSource: errorSource,
      });
    }
  }

  async onNIPTransferConfirmation() {
    logEvent(TRANSACTION_PROCEED_CLICK, {
      transaction_type: TRANSFER_TO_ACCOUNT,
    });

    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const username = currentUser.username;

    this.props.updateLoading(true, 66);
    stopwatch.start();
    const { code, errorSource, response, status } =
      await this.nipTransaction.processNIPTransfer(
        this.state.payment,
        localStorage.getItem("deviceUid"),
        username
      );
    stopwatch.stop();

    if (status === SUCCESS_STATUS) {
      this.setState({
        isProcessed: true,
        code: response.code,
      });

      this.setState({
        isProcessed: true,
        pin: response.pin,
        code: response.code,
      });

      this.gotoReceipt();
      logEvent(TRANSACTION_PROCEED_SUCCESS, {
        transaction_type: NIP_TRANSFER,
        secondsElapsed: stopwatch.secondsElapsed,
      });

      return;
    }
    if (status === ERROR_STATUS) {
      if (
        response.code === "01" ||
        response.code === "40006" ||
        response.code === "40000" ||
        response.code === "40901"
      ) {
        logEvent(TRANSACTION_PROCEED_FAILURE, {
          transaction_type: NIP_TRANSFER,
          secondsElapsed: stopwatch.secondsElapsed,
          errorCode: code,
          errorSource: errorSource,
        });

        this.props.showErrorMessage(
          response.description || JSON.stringify(response)
        );

        setTimeout(
          this.props.dismissErrorMessage,
          parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
        );
        this.props.updateLoading(false);

        return;
      }
      this.setState({
        isProcessed: true,
        pin: response.pin,
        code: response.code,
      });

      this.gotoReceipt();
      logEvent(TRANSACTION_PROCEED_FAILURE, {
        transaction_type: NIP_TRANSFER,
        secondsElapsed: stopwatch.secondsElapsed,
        errorCode: code,
        errorSource: errorSource,
      });

      return;
    }
  }

  async onWalletToWalletTransferConfirmation() {
    logEvent(TRANSACTION_PROCEED_CLICK, {
      transaction_type: TRANSFER_TO_AGENT,
    });

    this.props.updateLoading(true, 66);
    stopwatch.start();
    const { code, errorSource, status, response } =
      await this.liquidity.processWalletToWalletTransfer(
        this.state.payment,
        localStorage.getItem("deviceUid")
      );
    stopwatch.stop();

    if (status === SUCCESS_STATUS) {
      this.setState({
        isProcessed: true,
        code: response.code,
      });

      this.gotoReceipt();

      logEvent(TRANSACTION_PROCEED_SUCCESS, {
        transaction_type: TRANSFER_TO_AGENT,
        secondsElapsed: stopwatch.secondsElapsed,
      });

      return;
    }
    if (status === ERROR_STATUS) {
      if (
        response.code === "01" ||
        response.code === "40000" ||
        response.code === "40006"
      ) {
        logEvent(TRANSACTION_PROCEED_FAILURE, {
          transaction_type: TRANSFER_TO_AGENT,
          secondsElapsed: stopwatch.secondsElapsed,
          errorCode: code,
          errorSource: errorSource,
        });

        this.props.showErrorMessage(
          response.description || JSON.stringify(response)
        );

        setTimeout(
          this.props.dismissErrorMessage,
          parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
        );

        this.props.onCancelProduct();
        this.props.updateLoading(false);
        return;
      }

      this.setState({
        isProcessed: true,
        pin: response.pin,
        code: response.code,
      });

      this.gotoReceipt();

      logEvent(TRANSACTION_PROCEED_FAILURE, {
        transaction_type: BUY_AIRTIME,
        secondsElapsed: stopwatch.secondsElapsed,
        errorCode: code,
        errorSource: errorSource,
      });

      return;
    }
  }

  async onDistributeConfirmation() {
    logEvent(TRANSACTION_PROCEED_CLICK, {
      transaction_type: DISTRIBUTE,
    });

    this.props.updateLoading(true, 66);
    stopwatch.start();
    const { code, errorSource, status, response } =
      await this.liquidity.processDistribute(
        this.state.payment,
        localStorage.getItem("deviceUid")
      );
    stopwatch.stop();

    if (status === SUCCESS_STATUS) {
      //this.searchByTransRef(response.requestReference)

      if (response.code === "01" || response.code === "40006") {
        logEvent(TRANSACTION_PROCEED_FAILURE, {
          transaction_type: DISTRIBUTE,
          secondsElapsed: stopwatch.secondsElapsed,
          errorCode: code,
          errorSource: errorSource,
        });

        this.props.showErrorMessage(
          response.description || JSON.stringify(response)
        );

        setTimeout(
          this.props.dismissErrorMessage,
          parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
        );

        this.props.onCancelProduct();
        this.props.updateLoading(false);
        return;
      }

      this.setState({
        isProcessed: true,
        code: response.code,
      });

      this.gotoReceipt();

      logEvent(TRANSACTION_PROCEED_SUCCESS, {
        transaction_type: DISTRIBUTE,
        secondsElapsed: stopwatch.secondsElapsed,
      });

      return;
    }
    if (status === ERROR_STATUS) {
      if (response.code === "01" || response.code === "40006") {
        logEvent(TRANSACTION_PROCEED_FAILURE, {
          transaction_type: DISTRIBUTE,
          secondsElapsed: stopwatch.secondsElapsed,
          errorCode: code,
          errorSource: errorSource,
        });

        this.props.showErrorMessage(
          response.description || JSON.stringify(response)
        );

        setTimeout(
          this.props.dismissErrorMessage,
          parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
        );

        this.props.onCancelProduct();
        this.props.updateLoading(false);
        return;
      }

      this.setState({
        isProcessed: true,
        pin: response.pin,
        code: response.code,
      });

      this.gotoReceipt();

      logEvent(TRANSACTION_PROCEED_FAILURE, {
        transaction_type: DISTRIBUTE,
        secondsElapsed: stopwatch.secondsElapsed,
        errorCode: code,
        errorSource: errorSource,
      });

      return;
    }
  }

  async onUssdWithdrawalConfirmation() {
    logEvent(TRANSACTION_PROCEED_CLICK, {
      transaction_type: USSD_WITHDRAWAL,
    });
    this.props.updateLoading(true, 66);
    stopwatch.start();
    const { code, errorSource, response, status } =
      await this.liquidity.processUssdWithdrawal(
        this.state.payment,
        localStorage.getItem("deviceUid")
      );
    stopwatch.stop();

    if (status === SUCCESS_STATUS) {
      //this.searchByTransRef(response.requestReference)
      this.setState({
        isProcessed: true,
        code: response.code,
      });

      this.gotoReceipt();

      logEvent(TRANSACTION_PROCEED_SUCCESS, {
        // transaction_type: USSD_WITHDRAWAL,
        secondsElapsed: stopwatch.secondsElapsed,
      });

      return;
    }

    if (status === ERROR_STATUS) {
      if (response.code === "01" || response.code === "40006") {
        logEvent(TRANSACTION_PROCEED_FAILURE, {
          // transaction_type: USSD_WITHDRAWAL,
          secondsElapsed: stopwatch.secondsElapsed,
          errorCode: code,
          errorSource: errorSource,
        });

        this.props.showErrorMessage(
          response.description || JSON.stringify(response)
        );

        setTimeout(
          this.props.dismissErrorMessage,
          parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
        );

        this.props.onCancelProduct();
        this.props.updateLoading(false);
        return;
      }
      this.setState({
        isProcessed: true,
        pin: response.pin,
        code: response.code,
      });

      this.gotoReceipt();

      logEvent(TRANSACTION_PROCEED_FAILURE, {
        // transaction_type: USSD_WITHDRAWAL,
        secondsElapsed: stopwatch.secondsElapsed,
        errorCode: code,
        errorSource: errorSource,
      });

      return;
    }
  }

  async onPayCodeWithdrawalConfirmation() {
    logEvent(TRANSACTION_PROCEED_CLICK, {
      transaction_type: PAYCODE_CASHOUT,
    });

    this.props.updateLoading(true, 66);
    stopwatch.start();
    const withdrawalData = {
      pin: this.state.form.cashOutPin,
      ...this.state.payment,
    };
    const { code, errorSource, response, status } =
      await this.liquidity.processPaycodeWithdrawal(
        withdrawalData,
        localStorage.getItem("deviceUid")
      );
    stopwatch.stop();

    this.props.updateLoading(false);
    if (status === SUCCESS_STATUS) {
      if (response.code === "05") {
        logEvent(TRANSACTION_PROCEED_FAILURE, {
          transaction_type: PAYCODE_CASHOUT,
          secondsElapsed: stopwatch.secondsElapsed,
          errorCode: code,
          errorSource: errorSource,
        });

        this.props.showErrorMessage(
          response.description || JSON.stringify(response)
        );

        setTimeout(this.props.dismissErrorMessage, parseInt("40000"));
        this.setState({
          showProceedModal: true,
        });
        return;
      }
      this.setState({
        isProcessed: true,
        code: response.code,
      });

      setTimeout(
        () =>
          this.setState({
            showReceiptModal: true,
            showProceedModal: false,
            form: {
              ...this.state.form,
            },
          }),
        2000
      );

      logEvent(TRANSACTION_PROCEED_SUCCESS, {
        transaction_type: PAYCODE_CASHOUT,
        secondsElapsed: stopwatch.secondsElapsed,
      });

      return;
    }

    if (status === ERROR_STATUS) {
      if (
        code === HTTP_BAD_REQUEST_CODE ||
        response.code === "01" ||
        response.code === "40006"
      ) {
        logEvent(TRANSACTION_PROCEED_FAILURE, {
          transaction_type: PAYCODE_CASHOUT,
          secondsElapsed: stopwatch.secondsElapsed,
          errorCode: code,
          errorSource: errorSource,
        });

        this.props.showErrorMessage(
          response.description || JSON.stringify(response)
        );

        setTimeout(
          this.props.dismissErrorMessage,
          parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
        );
        this.setState({
          showProceedModal: true,
        });
        return;
      }
      this.setState({
        isProcessed: true,
        pin: response.pin,
        code: response.code,
      });

      setTimeout(
        () =>
          this.setState({
            showReceiptModal: true,
            showProceedModal: false,
            form: {
              ...this.state.form,
            },
          }),
        2000
      );

      logEvent(TRANSACTION_PROCEED_FAILURE, {
        transaction_type: PAYCODE_CASHOUT,
        secondsElapsed: stopwatch.secondsElapsed,
        errorCode: code,
        errorSource: errorSource,
      });
      return;
    }
  }

  onInitiateSuccessful(initiateResponseObj) {
    // this.setState({
    //   payment: initiateResponseObj,
    // });
    //REMOVING WHITESPACES FROM THE JSON OBJECTS
    try {
      const cleanedObj = Object.keys(initiateResponseObj).reduce((acc, key) => {
        let value = initiateResponseObj[key];

        if (
          key === "beneficiaryFirstName" ||
          key === "beneficiaryLastName" ||
          key === "beneficiaryPhone"
        ) {
          value = value.replace(/\s/g, "");
        }

        acc[key] = value;
        return acc;
      }, {});

      this.setState({
        payment: cleanedObj,
      });
    } catch (error) {
      console.error("An error occurred while cleaning the JSON object:", error);
      alert("An error occurred while cleaning the JSON object:", error);
      return null;
    }
  }

  async searchByTransRef(transRef) {
    this.setState({
      isLoading: true,
    });
    const { status, response } =
      await this.transactionHistory.getTransactionsByRef(transRef);

    if (status === SUCCESS_STATUS) {
      this.setState({
        //showModal: true,
        payment: {
          ...this.state.payment,
          extraInfoList: response.extraInfoList,
        },
        isLoading: false,
      });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  handleUsedModalClose() {
    this.setState({
      showErrorModal: false,
      showReceiptModal: false,
      showProceedModal: false,
      showCashinSuccessfulModal: false,
      showCashinReceiptModal: false,
      showCashinProceedModal: false,
    });
  }

  onCashinShowReceipt() {
    this.setState({
      showCashinSuccessfulModal: false,
      showCashinReceiptModal: true,
    });
  }

  render() {
    const tabs = {
      "pay-bill": {
        component: (
          <PayBillTab
            {...this.props}
            nipBanks={this.state.banks}
            ussdCashoutBanks={this.state.ussdCashoutBanks}
            ussdBanksLoading={this.state.isGetUssdCashoutBankLoading}
            amount={this.state.amount}
            billerOption={this.state.form.billerOption}
            category={this.props.category}
            customerIdField={
              this.state.service && !this.state.service?.options?.length
                ? this.state.service?.customerIdName
                : this.state.customerIdField
            }
            deviceIsMobile={this.state.deviceIsMobile}
            isAmountFixed={this.state.isAmountFixed}
            onCancelProduct={() => this.props.onCancelProduct()}
            onBuyAirtimeProceed={this.onBuyAirtimeProceed}
            onBuyDataProceed={this.onBuyDataProceed}
            onPayBillProceed={this.onPayBillProceed}
            onNipProceed={this.onNipProceed}
            onUssdWithdrawalProceed={this.onUssdWithdrawalProceed}
            onPayCodeWithdrawalProceed={this.onPayCodeWithdrawalProceed}
            onPayBillProceedWithSubAccount={this.onPayBillProceedWithSubAccount}
            onPayBillGetAmount={this.onPayBillGetAmount}
            onSendMoneyProceed={this.onSendMoneyProceed}
            onTransferCashinProceed={this.onTransferCashinProceed}
            onSendNIPMoneyProceed={this.onSendNIPMoneyProceed}
            onNIPTransferConfirmation={this.onNIPTransferConfirmation}
            onWalletTransferProceed={this.onWalletTransferProceed}
            onCashInProceed={this.onCashInProceed}
            onDistributeProceed={this.onDistributeProceed}
            service={this.props.service}
            transaction={this.transaction}
            nipTransaction={this.nipTransaction}
            showGetAmountField={this.state.showGetAmountField}
            serviceOptions={this.state.services}
            isFetchingData={this.state.isLoading}
            isFetchingPaycodeWithdrawal={this.state.isLoading}
            getServices={this.getServices}
            getAgents={this.getAgents}
            agents={this.state.agents}
            getUssdWithdrawalBanks={this.getUssdWithdrawalBanks}
            currentUser={this.state.currentUser}
            setDistributeAgent={this.setDistributeAgent}
            selectedAgents={this.state.selectedAgents}
            removeDistributeAgent={this.removeDistributeAgent}
            updateDistributeFormField={this.updateDistributeFormField}
            distributeForm={this.state.distributeForm}
            showSubAccount={this.state.showSubAccount}
            subAccounts={this.state.subAccounts}
            payment={this.state.payment}
            form={this.state.form}
            showProceedModal={this.state.showProceedModal}
            showCashinProceedModal={this.state.showCashinProceedModal}
            showCashinSuccessfulModal={this.state.showCashinSuccessfulModal}
            onCashinShowReceipt={this.onCashinShowReceipt}
            onPayCodeWithdrawalConfirmation={
              this.onPayCodeWithdrawalConfirmation
            }
            showReceiptModal={this.state.showReceiptModal}
            showErrorModal={this.state.showErrorModal}
            paycodeError={this.state.paycodeError}
            handleUsedModalClose={this.handleUsedModalClose}
            showCashinReceiptModal={this.state.showCashinReceiptModal}
            code={this.state.code}
            onPosRequestProceed={this.onPosRequestProceed}
            getPosTypes={this.getPosTypes}
            getPosModels={this.getPosModels}
            getPosUnitPrice={this.getPosUnitPrice}
            posTypes={this.state.posTypes}
            posModels={this.state.posModels}
            posUnitPrice={this.state.posUnitPrice}
            posTotalPrice={this.state.posTotalPrice}
            setPosTotalPrice={this.setPosTotalPrice}
            setPosUnitPrice={this.setPosUnitPrice}
          />
        ),
      },

      confirmation: {
        component: (
          <ConfirmationTab
            {...this.props}
            category={this.props.category}
            deviceIsMobile={this.state.deviceIsMobile}
            form={this.state.form}
            isProcessed={this.state.isProcessed}
            onBack={() =>
              this.setState({
                activeTab: "pay-bill",
              })
            }
            onCancelProduct={this.props.onCancelProduct}
            onPayBillConfirmation={this.onPayBillConfirmation}
            onSellAirtimeConfirmation={this.onSellAirtimeConfirmation}
            onCashInConfirmation={this.onCashInConfirmation}
            onTransferConfirmation={this.onTransferConfirmation}
            onNIPTransferConfirmation={this.onNIPTransferConfirmation}
            onWalletToWalletTransferConfirmation={
              this.onWalletToWalletTransferConfirmation
            }
            onDistributeConfirmation={this.onDistributeConfirmation}
            onUssdWithdrawalConfirmation={this.onUssdWithdrawalConfirmation}
            onPayCodeWithdrawalConfirmation={
              this.onPayCodeWithdrawalConfirmation
            }
            payment={this.state.payment}
          />
        ),
      },
      "payment-receipt": {
        component: (
          <ReceiptPage
            {...this.props}
            form={this.state.form}
            payment={this.state.payment}
            payments={this.state.payment}
            currentUser={this.state.currentUser}
            pin={this.state.pin}
            code={this.state.code}
            deviceIsMobile={this.state.deviceIsMobile}
          />
        ),
      },
    };

    const carousel = (
      <Carousel
        activeIndex={this.getCurrentCarouselIndex(Object.keys(tabs))}
        controls={false}
        disabled
        indicators={false}
        interval={null}
        style={{
          opacity:
            !this.toShowTitleSection ||
            (this.state.service && !this.state.service?.options?.length)
              ? 1
              : this.state.form.billerOption &&
                this.state.form.billerOption !== "null"
              ? 1
              : 0.1,
          margin: 0,
          marginTop: "10px",
          padding: 0,
        }}
      >
        {Object.values(tabs).map((value, idx) => {
          return <Carousel.Item key={idx}>{value.component}</Carousel.Item>;
        })}
      </Carousel>
    );

    const congratulationsModal = (
      <Modal
        onHide={() => this.setState({ showAddBillingInformation: false })}
        style={{ borderRadius: "10px", transition: "all .2s ease-in-out" }}
        size="sm"
        show={this.state.showCongratulationsModal}
        centered
      >
        <Modal.Body style={{ margin: "10px", padding: "15px" }}>
          <H1
            style={{
              textAlign: "center",
              textTransform: "uppercase",
              margin: "0px",
              padding: "0px",
            }}
          >
            CONGRATULATIONS!
          </H1>

          {cancelIcon(() =>
            this.setState({ showAddBillingInformation: false })
          )}

          <center>
            <HR style={{ marginTop: "10px", width: "20%" }} />

            {receiptIcon()}
            {JSON.stringify(this.state.payment)}

            <Text
              style={{
                fontSize: process.env.REACT_APP_BIG_FONT_SIZE,
                lineHeight: process.env.REACT_APP_MID_BIG_LINE_HEIGHT,
                margin: "10px",
              }}
            >
              Payment Confirmed.
            </Text>
          </center>
        </Modal.Body>
      </Modal>
    );

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        fontSize: 12,
      }),
      singleValue: (provided, state) => {
        const fontSize = 12;
        return { ...provided, fontSize };
      },
    };

    const titleSection = (
      <Row
        className="regular-row"
        style={{
          backgroundColor: "white",
          borderRadius: "8px",
          height: "100pt",
          margin: 0,
          padding: "10pt",
          width: "100%",
          display: "flex",
        }}
      >
        <Col
          hidden={this.state.deviceIsMobile}
          sm={12}
          md={this.props.category === "cash-in" ? 5 : 4}
          style={{ margin: 0, padding: 0 }}
        >
          <img src={this.props.service.imageUrl} height="70%" alt="" />
        </Col>
        <Col
          className="regular-col"
          sm={12}
          md={this.props.category === "cash-in" ? 7 : 8}
          style={{
            margin: 0,
            fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
            padding: 0,
            display: `${this.state.deviceIsMobile && "flex"}`,
            justifyContent: `${this.state.deviceIsMobile && "center"}`,
          }}
        >
          <div
            style={{
              marginLeft: "0px",
              marginTop: "10px",
              marginBottom: "5px",
              paddingLeft: "0px",
              fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
            }}
          >
            <Row className="regular-row">
              <strong>{this.props.service.name}</strong>
            </Row>
            <Row
              className="regular-row"
              hidden={
                this.state.service && !this.state.service?.options?.length
              }
            >
              {/* <Text style={{color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Select a biller option444:</Text> */}
              <Form.Group className="regular-form-group">
                <FormLabel
                  style={{
                    color: "#3A3B3B",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  Select a biller option:
                </FormLabel>

                  <Select
                    options={
                      this.props.service.code === "KAOC" && process.env.REACT_APP_SHOW_KOGI_COLLECTION === "true"
                        ? [
                            {
                              label: "Pay with Invoice No / Assessment No",
                              value: "invoiceOrAssessmentNo",
                            },
                            { label: "Pay with Payer ID", value: "invoice" },
                            {
                              label: "Pay without Payer ID or Invoice No",
                              value: "payerDetailsNoIdOrInvoice",
                            },
                          ]
                        : this.state.serviceOptions
                    }
                    onChange={(event) => {
                      const billerOption = event || event?.value;
                      this.updateFormField({ billerOption });
                      this.onBillerOptionSelect(billerOption);
                    }}
                    styles={customStyles}
                    className="select-input-container-product"
                    classNamePrefix="react-select"
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "rgb(178, 212, 255)",
                      },
                    })}
                  />
              
              </Form.Group>
            </Row>
          </div>
        </Col>
      </Row>
    );

    const screenSensitiveStyle = this.state.deviceIsMobile
      ? {
          padding: 0,
          margin: 0,
          borderRadius: 0,
        }
      : {};

    return (
      <Container
        fluid
        style={{
          height: "100vh",
          overflowY: "auto",
          paddingTop: !this.state.deviceIsMobile ? "20px" : "20px",
          paddingBottom: "90px",
          marginRight: "10px",
          ...screenSensitiveStyle,
        }}
      >
        {/* {alert("sjdkf s")} */}
        {this.toShowTitleSection && titleSection}
        {congratulationsModal}
        {carousel}
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.tunnel.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dismissErrorMessage: () => dispatch(dismissErrorMessage()),
    dismissSuccessMessage: () => dispatch(dismissSuccessMessage()),
    showErrorMessage: (message, keywords) =>
      dispatch(showErrorMessage(message, keywords)),
    showSuccessMessage: (message) => dispatch(showSuccessMessage(message)),
    updateLoading: (isLoading, percentage = null) =>
      dispatch(updateLoading({ isLoading, percentage })),
    navigateTo: (pendingUrl) => dispatch(navigateTo(pendingUrl)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPayment);
