import React, { Component } from 'react'
import { Button as RbButton } from 'react-bootstrap'


export default class Button extends Component {
  render () {
    const propsStyle = this.props.style ? this.props.style : {};
    let specifiedStyle = {};

    if (this.props.outline) {
      specifiedStyle.backgroundColor = 'transparent';
      specifiedStyle.borderColor = process.env.REACT_APP_RED_COLOUR;
      specifiedStyle.borderRadius = '4px';
      specifiedStyle.borderWidth = '1pt';
      specifiedStyle.color = propsStyle.color || process.env.REACT_APP_RED_COLOUR;
      specifiedStyle.fontSize = '14px';
      specifiedStyle.fontWeight = 'bold';
      specifiedStyle.textTransform = 'uppercase';
    }

    const buttonStyle = {
      ...specifiedStyle,
      ...propsStyle,
    };

    const buttonClass = this.props.outline ? this.props.className : `button ${this.props.outline ? 'button-outline' : ''} ${this.props.className}`

    return <button {...this.props} style={buttonStyle} className={buttonClass}>
      {this.props.children}
    </button>
  }
}
