import React from "react";

import { Col, Row } from "react-bootstrap";
import MdArrowDropright from "react-ionicons/lib/MdArrowDropright";
import { connect } from "react-redux";

import Adboard from "../../../../components/adboard";
import { CASH_IN, PAY_A_BILL } from "../../../../constants";
import { SUCCESS_STATUS } from "../../../../constants/api";
import CashInServiceId from "../../../../fixtures/services_id";
import BaseScene from "../../../../scenes/base-scene";
import UserSerializer from "../../../../serializers/resources/user";
import Catalog from "../../../../services/api/resources/catalog";
import Quickteller from "../../../../services/api/resources/quickteller";
import {
  SHOW_CARD_LINKING,
  SHOW_OPEN_ACCOUNT,
} from "../../../../utils/api-properties";
import AccountOpeningScene from "../../scenes/account-opening";
import LinkDebitCardScene from "../../scenes/link-debit-card";
import ProductPayment from "./components/product-payment";
import ServicesSearchPage from "./components/search";
import SearchServicesTab from "./components/search-services-tab";
import BuyPowerScene from "./scenes/buy-power";
import CashInScene from "./scenes/cash-in";
import DistributeScene from "./scenes/distribute/distribute";
import PayABillScene from "./scenes/pay-a-bill";
import RequestPosScene from "./scenes/request-pos";
import SellAirtimeScene from "./scenes/sell-airtime";
import SendMoneyScene from "./scenes/send-money";
import WithdrawalScene from "./scenes/withdrawal";

const cashInServiceId = CashInServiceId[process.env.REACT_APP_ENVIRONMENT];
const quickLinkSlug = [
  "pay-a-bill",
  "buy-power",
  "setup-new-agent",
  "sell-airtime-and-data",
  "send-money",
  "request-pos",
  "cash-in",
  "distribute",
  "link-debit-card",
  "create-account-form",
  "account-opening-documents",
  "cardless-cashout",
];
class ServicesScene extends BaseScene {
  location = window.location.href;
  path = "/services";
  title = "Services";

  quickteller = new Quickteller();
  catalog = new Catalog();

  categoryScenes = {
    "pay-a-bill": () => (
      <PayABillScene
        hideServiceCategories={() =>
          this.setState({ showServiceCategories: false })
        }
        showServiceCategories={() =>
          this.setState({ showServiceCategories: true })
        }
        onSelect={(value) =>
          this.setState({ showServiceCategories: value === null })
        }
        onClose={() =>
          this.setState({
            showServiceCategories: true,
            selectedServiceCategory: null,
          })
        }
        slug={this.state.slug}
        showErrorMessage={this.props.showErrorMessage}
        dismissErrorMessage={this.props.dismissErrorMessage}
      />
    ),
    "buy-power": () => (
      <BuyPowerScene
        hideServiceCategories={() =>
          this.setState({ showServiceCategories: false })
        }
        showServiceCategories={() =>
          this.setState({ showServiceCategories: true })
        }
        onSelect={(value) =>
          this.setState({ showServiceCategories: value === null })
        }
        onClose={() =>
          this.setState({
            showServiceCategories: true,
            selectedServiceCategory: null,
          })
        }
        slug={this.state.slug}
        showErrorMessage={this.props.showErrorMessage}
        dismissErrorMessage={this.props.dismissErrorMessage}
      />
    ),
    "send-money": () => (
      <SendMoneyScene
        hideServiceCategories={() =>
          this.setState({ showServiceCategories: false })
        }
        showServiceCategories={() =>
          this.setState({ showServiceCategories: true })
        }
        onSelect={(value) =>
          this.setState({ showServiceCategories: value === null })
        }
        onClose={() =>
          this.setState({
            showServiceCategories: true,
            selectedServiceCategory: null,
          })
        }
      />
    ),
    "sell-airtime-and-data": () => (
      <SellAirtimeScene
        hideServiceCategories={() =>
          this.setState({ showServiceCategories: false })
        }
        showServiceCategories={() =>
          this.setState({ showServiceCategories: true })
        }
        onSelect={(value) =>
          this.setState({ showServiceCategories: value === null })
        }
        onClose={() =>
          this.setState({
            showServiceCategories: true,
            selectedServiceCategory: null,
          })
        }
      />
    ),
    "request-pos": () => (
      <RequestPosScene
        hideServiceCategories={() =>
          this.setState({ showServiceCategories: false })
        }
        showServiceCategories={() =>
          this.setState({ showServiceCategories: true })
        }
        onSelect={(value) =>
          this.setState({ showServiceCategories: value === null })
        }
        onClose={() =>
          this.setState({
            showServiceCategories: true,
            selectedServiceCategory: null,
          })
        }
      />
    ),
    "cardless-cashout": () => (
      <WithdrawalScene
        hideServiceCategories={() =>
          this.setState({ showServiceCategories: false })
        }
        showServiceCategories={() =>
          this.setState({ showServiceCategories: true })
        }
        onSelect={(value) =>
          this.setState({ showServiceCategories: value === null })
        }
        onClose={() =>
          this.setState({
            showServiceCategories: true,
            selectedServiceCategory: null,
          })
        }
      />
    ),
    "create-account-form": () => (
      <AccountOpeningScene
        hideServiceCategories={() =>
          this.setState({ showServiceCategories: false })
        }
        showServiceCategories={() =>
          this.setState({ showServiceCategories: true })
        }
        onSelect={(value) =>
          this.setState({ showServiceCategories: value === null })
        }
        onClose={() =>
          this.setState({
            showServiceCategories: true,
            selectedServiceCategory: null,
          })
        }
      />
    ),
    "link-debit-card": () => (
      <LinkDebitCardScene
        hideServiceCategories={() =>
          this.setState({ showServiceCategories: false })
        }
        showServiceCategories={() =>
          this.setState({ showServiceCategories: true })
        }
        onSelect={(value) =>
          this.setState({ showServiceCategories: value === null })
        }
        onClose={() =>
          this.setState({
            showServiceCategories: true,
            selectedServiceCategory: null,
          })
        }
      />
    ),
    // "cash-out": () => <CashOutScene
    //   hideServiceCategories={() => this.setState({showServiceCategories: false})}
    //   showServiceCategories={() => this.setState({showServiceCategories: true})}
    //   onSelect={(value) => this.setState({showServiceCategories: value === null})}
    //   onClose={() => this.setState({
    //     showServiceCategories: true,
    //     selectedServiceCategory: null
    //   })}
    //   showErrorMessage={this.props.showErrorMessage}
    //   dismissErrorMessage={this.props.dismissErrorMessage}
    // />,
    "cash-in": () => (
      <CashInScene
        hideServiceCategories={() =>
          this.setState({ showServiceCategories: false })
        }
        showServiceCategories={() =>
          this.setState({ showServiceCategories: true })
        }
        onSelect={(value) => this.setState({ showServiceCategories: true })}
        onClose={() =>
          this.setState({
            showServiceCategories: true,
            selectedServiceCategory: null,
          })
        }
        showErrorMessage={this.props.showErrorMessage}
        dismissErrorMessage={this.props.dismissErrorMessage}
      />
    ),
    // "cash-in": () => <NipTransferScene
    //   hideServiceCategories={() => this.setState({showServiceCategories: false})}
    //   showServiceCategories={() => this.setState({showServiceCategories: true})}
    //   onSelect={(value) => this.setState({showServiceCategories: value === null})}
    //   onClose={() => this.setState({
    //     showServiceCategories: true,
    //     selectedServiceCategory: null
    //   })}
    // />,
    distribute: () => (
      <DistributeScene
        hideServiceCategories={() =>
          this.setState({ showServiceCategories: false })
        }
        showServiceCategories={() =>
          this.setState({ showServiceCategories: true })
        }
        onSelect={(value) =>
          this.setState({ showServiceCategories: value === null })
        }
        onClose={() =>
          this.setState({
            showServiceCategories: true,
            selectedServiceCategory: null,
          })
        }
        showErrorMessage={this.props.showErrorMessage}
        dismissErrorMessage={this.props.dismissErrorMessage}
      />
    ),

    // "fund-wallet": () => <FundWalletScene
    //   hideServiceCategories={() => this.setState({showServiceCategories: false})}
    //   showServiceCategories={() => this.setState({showServiceCategories: true})}
    //   onSelect={(value) => this.setState({showServiceCategories: value === null})}
    //   onClose={() => this.setState({
    //     showServiceCategories: true,
    //     selectedServiceCategory: null
    //   })}
    // />
    // "open-an-account": () => <OpenAnAccountScene
    //   hideServiceCategories={() => this.setState({showServiceCategories: false})}
    //   showServiceCategories={() => this.setState({showServiceCategories: true})}
    //   onSelect={(value) => this.setState({showServiceCategories: value === null})}
    //   onClose={() => this.setState({
    //     showServiceCategories: true,
    //     selectedServiceCategory: null
    //   })}
    // />,
    // "marketplace": () => <MarketplaceScene
    //   hideServiceCategories={() => this.setState({showServiceCategories: false})}
    //   showServiceCategories={() => this.setState({showServiceCategories: true})}
    //   onSelect={(value) => this.setState({showServiceCategories: value === null})}
    //   onClose={() => this.setState({
    //     showServiceCategories: true,
    //     selectedServiceCategory: null
    //   })}
    // />,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedServiceCategory: null,
      selectedServiceSubCategory: null,
      showAd: true,
      showServiceCategories: true,
      showSearchResult: true,
      isLoading: false,
      services: null,
      form: {},
      ...props,
    };

    this.isSearchTermValid = this.isSearchTermValid.bind(this);
    this.selectServiceCategory = this.selectServiceCategory.bind(this);
    this.updateFormField = this.updateFormField.bind(this);
  }

  componentDidMount() {
    const slug = this.props.match.params.slug;

    if (!slug) {
      this.setState({
        showServiceCategories: true,
      });
    } else if (quickLinkSlug.includes(slug)) {
      this.setState({
        selectedServiceCategory: slug,
      });
    } else {
      this.setState({
        selectedServiceCategory: "pay-a-bill",
        slug: slug,
        showServiceCategories: false,
      });
    }

    const currentUser = JSON.parse(localStorage.getItem("currentUser") || "{}");
    const userSerializer = new UserSerializer(currentUser);
    this.setState({
      currentUser: userSerializer,
    });
  }

  isSearchTermValid() {
    const { form } = this.state;

    return form.searchTerm && form.searchTerm.length > 0;
  }

  onSelectSubCategory(value) {
    this.setState({
      selectedServiceSubCategory: value,
    });
  }

  selectServiceCategory(serviceCategory) {
    this.setState({
      selectedServiceCategory: serviceCategory,
    });
  }

  showServiceCategory(category) {
    return this.categoryScenes[category]();
  }

  updateFormField(params) {
    const newFormData = {
      ...this.state.form,
      ...params,
    };

    this.setState({
      form: newFormData,
    });
  }

  async serviceUniversalSearch(searchTerm) {
    this.setState({
      isLoading: true,
    });
    const universalSearch = await this.quickteller.search(searchTerm);

    if (universalSearch.status === SUCCESS_STATUS) {
      const filteredResponse = universalSearch.response.filter((value) => {
        return (
          value.name.toLowerCase().indexOf("mtn") === -1 &&
          value.name.toLowerCase().indexOf("9mobile") === -1 &&
          value.name.toLowerCase().indexOf("airtel") === -1
        );
      });
      this.setState({
        services: filteredResponse,
        isLoading: false,
      });
    } else {
      this.props.showErrorMessage("Unable to Fetch Service, Try again");
      setTimeout(() => {
        this.props.dismissErrorMessage();
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
      this.setState({
        isLoading: false,
      });
    }
  }

  onSelect = (value) => {
    this.setState({ showServiceCategories: true });
  };

  handleSearch = () => {
    this.serviceUniversalSearch(this.state.form.searchTerm);
    this.setState({
      // showServiceCategories: false
    });
  };

  onBackButtonClick = () => {
    this.setState({
      selectedServiceCategory: null,
      services: null,
    });
  };

  getCategory = () => {
    if (this.state.selectedService.categoryId === cashInServiceId.id) {
      return CASH_IN;
    } else {
      return PAY_A_BILL;
    }
  };

  get serviceCategories() {
    return (
      <Row style={{ paddingLeft: this.state.deviceIsPC ? "30px" : "0px" }}>
        {!(this.state.currentUser && this.state.currentUser.isAggregator) && (
          <Col
            md={12}
            className={`service-category${
              this.state.selectedServiceCategory === "pay-a-bill"
                ? "-active"
                : ""
            }`}
            style={{
              alignItems: "center",
              borderRadius: process.env.REACT_APP_BORDER_STYLE ? "4px" : "24px",
              borderBottom: "0.6px solid #ccc",
              borderBottomLeftRadius: "0px",
              borderBottomRightRadius: "0px",
              display: "flex",
              justifyContent: "space-between",
              fontSize: this.state.deviceIsPC
                ? process.env.REACT_APP_MID_FONT_SIZE
                : process.env.REACT_APP_MID_BIG_FONT_SIZE,
              padding: this.state.deviceIsPC ? "10pt" : "15pt",
              paddingRight: "10pt",
              paddingLeft: "15px",
              margin: "0px",
            }}
            onClick={() => this.selectServiceCategory("pay-a-bill")}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color:
                  this.state.selectedServiceCategory === "pay-a-bill"
                    ? process.env.REACT_APP_BLUE_COLOUR
                    : process.env.REACT_APP_BLACK_COLOUR,
              }}
            >
              <div
                style={{
                  background:
                    "linear-gradient(184.81deg, #9483FA -7.1%, #9F4FF5 93.85%)",
                  borderRadius: "4px",
                  height: "25pt",
                  marginRight: "20px",
                  width: "25pt",
                }}
              ></div>
              Pay a Bill
            </div>
            <MdArrowDropright
              color={
                this.state.selectedServiceCategory === "pay-a-bill"
                  ? process.env.REACT_APP_BLUE_COLOUR
                  : process.env.REACT_APP_LIGHT_GREY_COLOUR
              }
            />
            {/* <img src={require('../../../../assets/media/icons/pay-a-bill-icon-3.png')} height="50%" width="50%" alt="" /> */}
          </Col>
        )}

        {!(this.state.currentUser && this.state.currentUser.isAggregator) && (
          <Col
            md={12}
            className={`service-category${
              this.state.selectedServiceCategory === "sell-airtime-and-data"
                ? "-active"
                : ""
            }`}
            style={{
              alignItems: "center",
              borderRadius: process.env.REACT_APP_BORDER_STYLE ? "4px" : "24px",
              borderBottom: "0.6px solid #ccc",
              borderBottomLeftRadius: "0px",
              borderBottomRightRadius: "0px",
              display: "flex",
              justifyContent: "space-between",
              fontSize: this.state.deviceIsPC
                ? process.env.REACT_APP_MID_FONT_SIZE
                : process.env.REACT_APP_MID_BIG_FONT_SIZE,
              padding: this.state.deviceIsPC ? "10pt" : "15pt",
              paddingRight: "10pt",
              paddingLeft: "15px",
              margin: "0px",
            }}
            onClick={() => this.selectServiceCategory("sell-airtime-and-data")}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color:
                  this.state.selectedServiceCategory === "sell-airtime-and-data"
                    ? process.env.REACT_APP_BLUE_COLOUR
                    : process.env.REACT_APP_BLACK_COLOUR,
              }}
            >
              <div
                style={{
                  background:
                    "linear-gradient(184.81deg, #83F4FA -7.1%, #00B8DE 93.85%)",
                  borderRadius: "4px",
                  height: "25pt",
                  marginRight: "20px",
                  width: "25pt",
                }}
              ></div>
              Sell Airtime and Data
            </div>
            <MdArrowDropright
              color={
                this.state.selectedServiceCategory === "sell-airtime-and-data"
                  ? process.env.REACT_APP_BLUE_COLOUR
                  : process.env.REACT_APP_LIGHT_GREY_COLOUR
              }
            />
          </Col>
        )}
        {this.state.currentUser &&
          !(this.state.currentUser && this.state.currentUser.isAggregator) && (
            <Col
              md={12}
              className={`service-category${
                this.state.selectedServiceCategory === "cardless-cashout"
                  ? "-active"
                  : ""
              }`}
              style={{
                alignItems: "center",
                borderRadius: process.env.REACT_APP_BORDER_STYLE
                  ? "4px"
                  : "24px",
                borderBottom: "0.6px solid #ccc",
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
                display: "flex",
                justifyContent: "space-between",
                fontSize: this.state.deviceIsPC
                  ? process.env.REACT_APP_MID_FONT_SIZE
                  : process.env.REACT_APP_MID_BIG_FONT_SIZE,
                padding: this.state.deviceIsPC ? "10pt" : "15pt",
                paddingRight: "10pt",
                paddingLeft: "15px",
                margin: "0px",
              }}
              onClick={() => this.selectServiceCategory("cardless-cashout")}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color:
                    this.state.selectedServiceCategory === "cardless-cashout"
                      ? process.env.REACT_APP_BLUE_COLOUR
                      : process.env.REACT_APP_BLACK_COLOUR,
                }}
              >
                <div
                  style={{
                    background:
                      "linear-gradient(184.81deg, #bd7874 -7.1%, #A5534F 93.85%)",
                    borderRadius: "4px",
                    height: "25pt",
                    marginRight: "20px",
                    width: "25pt",
                  }}
                ></div>
                Cardless Cashout
              </div>
              <MdArrowDropright
                color={
                  this.state.selectedServiceCategory === "cardless-cashout"
                    ? process.env.REACT_APP_BLUE_COLOUR
                    : process.env.REACT_APP_LIGHT_GREY_COLOUR
                }
              />
            </Col>
          )}

        {!(this.state.currentUser && this.state.currentUser.isAggregator) && (
          <Col
            md={12}
            className={`service-category${
              this.state.selectedServiceCategory === "send-money"
                ? "-active"
                : ""
            }`}
            style={{
              alignItems: "center",
              borderRadius: process.env.REACT_APP_BORDER_STYLE ? "4px" : "24px",
              borderBottom: "0.6px solid #ccc",
              borderBottomLeftRadius: "0px",
              borderBottomRightRadius: "0px",
              display: "flex",
              justifyContent: "space-between",
              fontSize: this.state.deviceIsPC
                ? process.env.REACT_APP_MID_FONT_SIZE
                : process.env.REACT_APP_MID_BIG_FONT_SIZE,
              padding: this.state.deviceIsPC ? "10pt" : "15pt",
              paddingRight: "10pt",
              paddingLeft: "15px",
              margin: "0px",
            }}
            onClick={() => this.selectServiceCategory("send-money")}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color:
                  this.state.selectedServiceCategory === "send-money"
                    ? process.env.REACT_APP_BLUE_COLOUR
                    : process.env.REACT_APP_BLACK_COLOUR,
              }}
            >
              <div
                style={{
                  background:
                    "linear-gradient(184.81deg, #FACB83 -7.1%, #F5834F 93.85%)",
                  borderRadius: "4px",
                  height: "25pt",
                  marginRight: "20px",
                  width: "25pt",
                }}
              ></div>
              Send Money
            </div>
            <MdArrowDropright
              color={
                this.state.selectedServiceCategory === "send-money"
                  ? process.env.REACT_APP_BLUE_COLOUR
                  : process.env.REACT_APP_LIGHT_GREY_COLOUR
              }
            />
          </Col>
        )}

        {!(this.state.currentUser && this.state.currentUser.isAggregator) && (
          <Col
            md={12}
            className={`service-category${
              this.state.selectedServiceCategory === "cash-in" ? "-active" : ""
            }`}
            style={{
              alignItems: "center",
              borderRadius: process.env.REACT_APP_BORDER_STYLE ? "4px" : "24px",
              borderBottom: "0.6px solid #ccc",
              borderBottomLeftRadius: "0px",
              borderBottomRightRadius: "0px",
              display: "flex",
              justifyContent: "space-between",
              fontSize: this.state.deviceIsPC
                ? process.env.REACT_APP_MID_FONT_SIZE
                : process.env.REACT_APP_MID_BIG_FONT_SIZE,
              padding: this.state.deviceIsPC ? "10pt" : "15pt",
              paddingRight: "10pt",
              paddingLeft: "15px",
              margin: "0px",
            }}
            onClick={() => this.selectServiceCategory("cash-in")}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color:
                  this.state.selectedServiceCategory === "cash-in"
                    ? process.env.REACT_APP_BLUE_COLOUR
                    : process.env.REACT_APP_BLACK_COLOUR,
              }}
            >
              <div
                style={{
                  background:
                    "linear-gradient(184.81deg, #FFA69E -7.1%, #861657 93.85%)",
                  borderRadius: "4px",
                  height: "25pt",
                  marginRight: "20px",
                  width: "25pt",
                }}
              ></div>
              Transfer to MMO
            </div>
            <MdArrowDropright
              color={
                this.state.selectedServiceCategory === "send-money"
                  ? process.env.REACT_APP_BLUE_COLOUR
                  : process.env.REACT_APP_LIGHT_GREY_COLOUR
              }
            />
          </Col>
        )}
        {/* {!(this.state.currentUser && this.state.currentUser.isAggregator) && <Col 
        md={12} 
        className={`service-category${this.state.selectedServiceCategory === 'cash-in' ? '-active' : ''}`} 
        style={{
          alignItems: 'center',
          borderRadius: process.env.REACT_APP_BORDER_STYLE ? '4px' : '24px', 
          borderBottom: '0.6px solid #ccc',
          borderBottomLeftRadius: '0px',
          borderBottomRightRadius: '0px',
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: this.state.deviceIsPC ? process.env.REACT_APP_MID_FONT_SIZE : process.env.REACT_APP_MID_BIG_FONT_SIZE, 
          padding: this.state.deviceIsPC ? '10pt' : '15pt',
          paddingRight: '10pt',
          paddingLeft: '15px',
          margin: '0px'
        }}
        onClick={() => this.selectServiceCategory('cash-in')}
      >
        <div style={{display: 'flex', alignItems: 'center', color: this.state.selectedServiceCategory === 'cash-in' ? process.env.REACT_APP_BLUE_COLOUR : process.env.REACT_APP_BLACK_COLOUR}}>
          <div style={{background: 'linear-gradient(184.81deg, #FFF9A0 -7.1%, #EE8A0F 93.85%)', borderRadius: '4px', height: '25pt', marginRight: '20px', width: '25pt'}}></div>
          Cash In
        </div>
        <MdArrowDropright  color={this.state.selectedServiceCategory === 'cash-in' ? process.env.REACT_APP_BLUE_COLOUR : process.env.REACT_APP_LIGHT_GREY_COLOUR} />
      </Col> */}
        {SHOW_OPEN_ACCOUNT && (
          <Col
            md={12}
            className={`service-category${
              this.state.selectedServiceCategory === "create-account-form"
                ? "-active"
                : ""
            }`}
            style={{
              alignItems: "center",
              borderRadius: process.env.REACT_APP_BORDER_STYLE ? "4px" : "24px",
              borderBottom: "0.6px solid #ccc",
              borderBottomLeftRadius: "0px",
              borderBottomRightRadius: "0px",
              display: "flex",
              justifyContent: "space-between",
              fontSize: this.state.deviceIsPC
                ? process.env.REACT_APP_MID_FONT_SIZE
                : process.env.REACT_APP_MID_BIG_FONT_SIZE,
              padding: this.state.deviceIsPC ? "10pt" : "15pt",
              paddingRight: "10pt",
              paddingLeft: "15px",
              margin: "0px",
            }}
            onClick={() => this.selectServiceCategory("create-account-form")}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color:
                  this.state.selectedServiceCategory === "create-account-form"
                    ? process.env.REACT_APP_BLUE_COLOUR
                    : process.env.REACT_APP_BLACK_COLOUR,
              }}
            >
              <div
                style={{
                  background:
                    "linear-gradient(184.81deg, #83F4FA -7.1%, #A5534F 93.85%)",
                  borderRadius: "4px",
                  height: "25pt",
                  marginRight: "20px",
                  width: "25pt",
                }}
              ></div>
              Open Account
            </div>
            <MdArrowDropright
              color={
                this.state.selectedServiceCategory === "create-account-form"
                  ? process.env.REACT_APP_BLUE_COLOUR
                  : process.env.REACT_APP_LIGHT_GREY_COLOUR
              }
            />
          </Col>
        )}
        {SHOW_CARD_LINKING &&
          this.state.currentUser &&
          !this.state.currentUser.isAggregator && (
            <Col
              md={12}
              className={`service-category${
                this.state.selectedServiceCategory === "link-debit-card"
                  ? "-active"
                  : ""
              }`}
              style={{
                alignItems: "center",
                borderRadius: process.env.REACT_APP_BORDER_STYLE
                  ? "4px"
                  : "24px",
                borderBottom: "0.6px solid #ccc",
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
                display: "flex",
                justifyContent: "space-between",
                fontSize: this.state.deviceIsPC
                  ? process.env.REACT_APP_MID_FONT_SIZE
                  : process.env.REACT_APP_MID_BIG_FONT_SIZE,
                padding: this.state.deviceIsPC ? "10pt" : "15pt",
                paddingRight: "10pt",
                paddingLeft: "15px",
                margin: "0px",
              }}
              onClick={() => this.selectServiceCategory("link-debit-card")}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color:
                    this.state.selectedServiceCategory === "link-debit-card"
                      ? process.env.REACT_APP_BLUE_COLOUR
                      : process.env.REACT_APP_BLACK_COLOUR,
                }}
              >
                <div
                  style={{
                    background:
                      "linear-gradient(184.81deg, #83F4FA -7.1%, #53A54F 93.85%)",
                    borderRadius: "4px",
                    height: "25pt",
                    marginRight: "20px",
                    width: "25pt",
                  }}
                ></div>
                Link a Card
              </div>
              <MdArrowDropright
                color={
                  this.state.selectedServiceCategory === "link-debit-card"
                    ? process.env.REACT_APP_BLUE_COLOUR
                    : process.env.REACT_APP_LIGHT_GREY_COLOUR
                }
              />
            </Col>
          )}
        {this.state.currentUser && this.state.currentUser.isAggregator && (
          <>
            <Col
              md={12}
              className={`service-category${
                this.state.selectedServiceCategory === "distribute"
                  ? "-active"
                  : ""
              }`}
              style={{
                alignItems: "center",
                borderRadius: process.env.REACT_APP_BORDER_STYLE
                  ? "4px"
                  : "24px",
                borderBottom: "0.6px solid #ccc",
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
                display: "flex",
                justifyContent: "space-between",
                fontSize: this.state.deviceIsPC
                  ? process.env.REACT_APP_MID_FONT_SIZE
                  : process.env.REACT_APP_MID_BIG_FONT_SIZE,
                padding: this.state.deviceIsPC ? "10pt" : "15pt",
                paddingRight: "10pt",
                paddingLeft: "15px",
                margin: "0px",
              }}
              onClick={() => this.selectServiceCategory("distribute")}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color:
                    this.state.selectedServiceCategory === "distribute"
                      ? process.env.REACT_APP_BLUE_COLOUR
                      : process.env.REACT_APP_BLACK_COLOUR,
                }}
              >
                <div
                  style={{
                    background:
                      "linear-gradient(184.81deg, #F5834F -7.1%, #FACB83 93.85%)",
                    borderRadius: "4px",
                    height: "25pt",
                    marginRight: "20px",
                    width: "25pt",
                  }}
                ></div>
                Distribute
              </div>
              <MdArrowDropright
                color={
                  this.state.selectedServiceCategory === "send-money"
                    ? process.env.REACT_APP_BLUE_COLOUR
                    : process.env.REACT_APP_LIGHT_GREY_COLOUR
                }
              />
            </Col>
          </>
        )}

        {!process.env.REACT_APP_DISABLE_CASH_IN && (
          <Col
            md={12}
            className={`service-category${
              this.state.selectedServiceCategory === "cash-in" ? "-active" : ""
            }`}
            style={{
              alignItems: "center",
              borderRadius: process.env.REACT_APP_BORDER_STYLE ? "4px" : "24px",
              borderBottom: "0.6px solid #ccc",
              borderBottomLeftRadius: "0px",
              borderBottomRightRadius: "0px",
              display: "flex",
              justifyContent: "space-between",
              fontSize: this.state.deviceIsPC
                ? process.env.REACT_APP_MID_FONT_SIZE
                : process.env.REACT_APP_MID_BIG_FONT_SIZE,
              padding: this.state.deviceIsPC ? "10pt" : "15pt",
              paddingRight: "10pt",
              paddingLeft: "15px",
              margin: "0px",
            }}
            onClick={() => this.selectServiceCategory("cash-in")}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color:
                  this.state.selectedServiceCategory === "cash-in"
                    ? process.env.REACT_APP_BLUE_COLOUR
                    : process.env.REACT_APP_BLACK_COLOUR,
              }}
            >
              <div
                style={{
                  background:
                    "linear-gradient(184.81deg, #F9596C -7.1%, #EE312A 93.85%)",
                  borderRadius: "4px",
                  height: "25pt",
                  marginRight: "20px",
                  width: "25pt",
                }}
              ></div>
              Cash In
            </div>
            <MdArrowDropright
              color={
                this.state.selectedServiceCategory === "cash-in"
                  ? process.env.REACT_APP_BLUE_COLOUR
                  : process.env.REACT_APP_LIGHT_GREY_COLOUR
              }
            />
          </Col>
        )}
        {/* <Col 
        md={12} 
        className={`service-category${this.state.selectedServiceCategory === 'fund-wallet' ? '-active' : ''}`} 
        style={{
          alignItems: 'center',
          borderRadius: process.env.REACT_APP_BORDER_STYLE ? '4px' : '24px',
          borderBottom: '0.6px solid #ccc',
          borderBottomLeftRadius: '0px',
          borderBottomRightRadius: '0px',
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: this.state.deviceIsPC ? process.env.REACT_APP_MID_FONT_SIZE : process.env.REACT_APP_MID_BIG_FONT_SIZE, 
          padding: this.state.deviceIsPC ? '10pt' : '15pt',
          paddingRight: '10pt',
          paddingLeft: '15px',
          margin: '0px'
        }}
        onClick={() => this.selectServiceCategory('fund-wallet')}
        >
          <div style={{display: 'flex', alignItems: 'center', color: this.state.selectedServiceCategory === 'sell-airtime' ? process.env.REACT_APP_BLUE_COLOUR : process.env.REACT_APP_BLACK_COLOUR}}>
            <div style={{background: 'linear-gradient(184.81deg, #83F4FA -7.1%, #00B8DE 93.85%)', borderRadius: '4px', height: '25pt', marginRight: '20px', width: '25pt'}}></div>
            Fund Wallet
          </div>
        <MdArrowDropright  color={this.state.selectedServiceCategory === 'fund-wallet' ? process.env.REACT_APP_BLUE_COLOUR : process.env.REACT_APP_LIGHT_GREY_COLOUR} />
      </Col> */}
        {/* <Col 
        md={12} 
        className={`service-category${this.state.selectedServiceCategory === 'open-an-account' ? '-active' : ''}`} 
        style={{
          alignItems: 'center',
          borderRadius: process.env.REACT_APP_BORDER_STYLE ? '4px' : '24px',
          borderBottom: '0.6px solid #ccc',
          borderBottomLeftRadius: '0px',
          borderBottomRightRadius: '0px',
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: this.state.deviceIsPC ? process.env.REACT_APP_MID_FONT_SIZE : process.env.REACT_APP_MID_BIG_FONT_SIZE, 
          padding: this.state.deviceIsPC ? '10pt' : '15pt',
          paddingRight: '10pt',
          paddingLeft: '15px',
          margin: '0px'
        }}
        onClick={() => this.selectServiceCategory('open-an-account')}
      >
        <div style={{display: 'flex', alignItems: 'center', color: this.state.selectedServiceCategory === 'open-an-account' ? process.env.REACT_APP_BLUE_COLOUR : process.env.REACT_APP_BLACK_COLOUR}}>
          <div style={{background: 'linear-gradient(188deg, #5983F9, #2A32EE)', borderRadius: '4px', height: '25pt', marginRight: '20px', width: '25pt'}}></div>
          Open an Account
        </div>
        <MdArrowDropright color={this.state.selectedServiceCategory === 'open-an-account' ? process.env.REACT_APP_BLUE_COLOUR : process.env.REACT_APP_LIGHT_GREY_COLOUR} />
      </Col> */}
        {/* <Col 
        md={12} 
        className={`service-category${this.state.selectedServiceCategory === 'marketplace' ? '-active' : ''}`} 
        style={{
          alignItems: 'center',
          borderRadius: process.env.REACT_APP_BORDER_STYLE ? '4px' : '24px',
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: this.state.deviceIsPC ? process.env.REACT_APP_MID_FONT_SIZE : process.env.REACT_APP_MID_BIG_FONT_SIZE, 
          padding: this.state.deviceIsPC ? '10pt' : '15pt',
          paddingRight: '10pt',
          paddingLeft: '15px',
          margin: '0px'
        }} onClick={() => this.selectServiceCategory('marketplace')}>
        <div style={{display: 'flex', alignItems: 'center', color: this.state.selectedServiceCategory === 'marketplace' ? process.env.REACT_APP_BLUE_COLOUR : process.env.REACT_APP_BLACK_COLOUR}}>
          <div style={{background: 'linear-gradient(188deg, #59F983, #2AEE32)', borderRadius: '4px', height: '25pt', marginRight: '20px', width: '25pt'}}></div>
          Marketplace
        </div>
        <MdArrowDropright color={this.state.selectedServiceCategory === 'marketplace' ? process.env.REACT_APP_BLUE_COLOUR : process.env.REACT_APP_LIGHT_GREY_COLOUR} />
       </Col> */}
        {/* {SHOW_POS &&
          this.state.currentUser &&
          !(this.state.currentUser && this.state.currentUser.isAggregator) && (
            <Col
              md={12}
              className={`service-category${
                this.state.selectedServiceCategory === 'request-pos'
                  ? '-active'
                  : ''
              }`}
              style={{
                alignItems: 'center',
                borderRadius: process.env.REACT_APP_BORDER_STYLE
                  ? '4px'
                  : '24px',
                borderBottom: '0.6px solid #ccc',
                borderBottomLeftRadius: '0px',
                borderBottomRightRadius: '0px',
                display: 'flex',
                justifyContent: 'space-between',
                fontSize: this.state.deviceIsPC
                  ? process.env.REACT_APP_MID_FONT_SIZE
                  : process.env.REACT_APP_MID_BIG_FONT_SIZE,
                padding: this.state.deviceIsPC ? '10pt' : '15pt',
                paddingRight: '10pt',
                paddingLeft: '15px',
                margin: '0px',
              }}
              onClick={() => this.selectServiceCategory('request-pos')}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color:
                    this.state.selectedServiceCategory === 'request-pos'
                      ? process.env.REACT_APP_BLUE_COLOUR
                      : process.env.REACT_APP_BLACK_COLOUR,
                }}
              >
                <div
                  style={{
                    background:
                      'linear-gradient(184.81deg, #F483FA -7.1%, #00B8DE 93.85%)',
                    borderRadius: '4px',
                    height: '25pt',
                    marginRight: '20px',
                    width: '25pt',
                    boxShadow: '0 3px 8.3px 0.7px rgb(163 93 255 / 35%)',
                  }}
                ></div>
                Request POS
              </div>
              <MdArrowDropright
                color={
                  this.state.selectedServiceCategory === 'request-pos'
                    ? process.env.REACT_APP_BLUE_COLOUR
                    : process.env.REACT_APP_LIGHT_GREY_COLOUR
                }
              />
            </Col>
          )} */}
      </Row>
    );
  }

  render() {
    const desktopContainer = (
      <Row style={{ height: "100vh", width: "83vw", margin: 0, padding: 0 }}>
        {this.state.showServiceCategories && (
          <Col
            md={4}
            lg={3}
            style={{
              backgroundColor: "#eef2f4",
              borderRight: "0.6px solid #CCC",
              height: "100%",
              minHeight: "100vh",
              padding: "35px",
              paddingLeft: "0px",
              margin: 0,
            }}
          >
            {this.serviceCategories}
          </Col>
        )}

        {!(this.state.currentUser && this.state.currentUser.isAggregator) &&
          !this.state.selectedServiceCategory &&
          !this.state.services && (
            <Col md={7}>
              <ServicesSearchPage
                onSearch={this.handleSearch}
                isLoading={this.state.isLoading}
                searchTerm={this.state.form.searchTerm}
                updateFormField={this.updateFormField}
                isSearchTermValid={this.isSearchTermValid}
              />
            </Col>
          )}

        {this.state.selectedServiceCategory && (
          <Col
            md={this.state.showServiceCategories ? 7 : 10}
            style={{ margin: "0px", padding: "0px" }}
          >
            {this.showServiceCategory(this.state.selectedServiceCategory)}
          </Col>
        )}

        {!(this.state.currentUser && this.state.currentUser.isAggregator) &&
          this.state.showAd &&
          this.state.deviceIsPC &&
          !this.state.selectedService && (
            <React.Fragment>
              <Col md={7} lg={7}>
                <SearchServicesTab
                  isLoading={this.state.isLoading}
                  services={this.state.services}
                  onSelect={(value) => {
                    this.setState({ selectedService: value });
                    this.onSelect(value);
                  }}
                  onBackButtonClick={this.onBackButtonClick}
                  serviceType={this.state.selectedServiceType}
                />
              </Col>
              <Adboard />
            </React.Fragment>
          )}
        {this.state.selectedService && this.state.showAd && (
          <>
            <Col md={7} style={{ marginLeft: "0px", paddingLeft: "0px" }}>
              <ProductPayment
                category={this.getCategory()}
                service={this.state.selectedService}
                onCancelProduct={() => this.setState({ selectedService: null })}
              />
            </Col>
            <Adboard />
          </>
        )}
        {this.state.currentUser && this.state.currentUser.isAggregator && (
          <Adboard />
        )}
      </Row>
    );

    const mobileContainer = (
      <Row style={{ margin: 0, padding: 0 }}>
        {!this.state.selectedServiceCategory ? (
          <Col
            md={12}
            style={{
              backgroundColor: "#eef2f4",
              height: "100%",
              minHeight: "100vh",
              padding: "35px",
              paddingTop: 0,
              paddingLeft: "20px",
              margin: 0,
            }}
          >
            {this.serviceCategories}
          </Col>
        ) : (
          <Col md={12} lg={9} style={{ margin: "0px", padding: "0px" }}>
            {this.showServiceCategory(this.state.selectedServiceCategory)}
          </Col>
        )}
      </Row>
    );

    return (
      <div
        style={{
          paddingLeft: "0px",
          paddingRight: !this.state.deviceIsMobile ? "0px" : "0px",
        }}
      >
        {this.state.deviceIsPC ? desktopContainer : mobileContainer}
      </div>
    );
  }
}

export default connect(null, null)(ServicesScene);
