import React, { useEffect, useRef, useState } from "react";

import MdBook from "react-ionicons/lib/MdBook";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../../../constants/api";
import TransactionHistory from "../../../../../../services/api/resources/transaction-history";
import {
  formatAmount,
  formatDate,
  formatWalletDate,
  specialDateRangeFormatter_StartDate,
} from "../../../../../../utils/formatters";

import TransactionTable from "./transactionTable";
import CustomError from "../../../../../../components/error-component";
import H1 from "../../../../../../components/h1";
import SkeletonLoading from "../../component/skeletonLoading";
import { Overlay } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getThisWeekDateRange } from "../../../../../../components/extras";
import { convertNgkToNgn } from "../../../../../../utils/converters/currencies";

const Activity = ({ agentDetails }) => {
  const transactionHistory = new TransactionHistory();
  const [inflowOutflow, setInflowOutflow] = useState("");
  const [error, setError] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("This week");
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [dateIntervalType, setDateIntervalType] = useState(2);

  const onChange = (dates) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);
  };

  const filterStyle = (filterName) => {
    return {
      padding: ".5rem 1.5rem",
      backgroundColor:
        selectedFilter === filterName ? "#00425F" : "transparent",
      color:
        selectedFilter === filterName
          ? "white"
          : process.env.REACT_APP_BLUE_COLOUR,
      cursor: "pointer",
      border: "1px solid #EBF8FE",
    };
  };

  const handleFilterClick = (filterName) => {
    setSelectedFilter(filterName);

    if (filterName === "This week") {
      setDateIntervalType(2);
    } else if (filterName === "This Month") {
      setDateIntervalType(3);
    } else if (filterName === "This Year") {
      setDateIntervalType(4);
    } else {
      setDateIntervalType();
    }
  };

  const fetchInflowOutflow = async () => {
    setIsLoading(true);
    // const payload = {
    //   bvn: agentDetails.bvn,
    //   mobile: agentDetails.agentMobileNo,
    //   business: agentDetails.businessName,
    //   from: specialDateRangeFormatter_StartDate(startDate),
    //   to: specialDateRangeFormatter_StartDate(endDate),

    //   domainCode: agentDetails.agentCode,
    // };

    let payload = {};
    if (dateIntervalType) {
      payload = {
        dateIntervalType: dateIntervalType,
        bvn: agentDetails.bvn,
        mobile: agentDetails.agentMobileNo,
        business: agentDetails.businessName,
        domainCode: agentDetails.agentCode,
      };
    } else {
      payload = {
        bvn: agentDetails.bvn,
        mobile: agentDetails.agentMobileNo,
        business: agentDetails.businessName,
        from: specialDateRangeFormatter_StartDate(startDate),
        to: specialDateRangeFormatter_StartDate(endDate),
        domainCode: agentDetails.agentCode,
      };
    }
    const { status, response } = await transactionHistory.getInflowOutflow(
      payload
    );

    setIsLoading(false);
    if (status === ERROR_STATUS) {
      setError(true);
    } else if (status === SUCCESS_STATUS) {
      setError(false);
      setIsLoading(false);

      setInflowOutflow(response);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchInflowOutflow();
  }, [endDate, dateIntervalType]);

  const LoadingComponent = () => {
    return (
      <div
        style={{
          marginBottom: "25px",
        }}
      >
        {" "}
        <SkeletonLoading width={"100%"} height={"20px"} />{" "}
        <SkeletonLoading width={"100%"} height={"20px"} />
        <SkeletonLoading width={"100%"} height={"20px"} />
      </div>
    );
  };

  const BalanceCard = ({
    title,
    balance,
    showTopUp = false,
    onClick,

    buttonText,
  }) => {
    return (
      <>
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <div className="balance-card">
            <H1
              style={{
                fontSize: "1.25rem",
                fontWeight: 400,
                // color: '#4B5563',
                color: process.env.REACT_APP_BLUE_COLOUR,
                textAlign: "start",
              }}
            >
              {title}
            </H1>
            <h3
              style={{
                fontSize: "1.75rem",
                marginTop: "15px",
                fontWeight: "600",
                color: process.env.REACT_APP_BLUE_COLOUR,
              }}
            >
              {balance}
            </h3>
            {showTopUp && (
              <div>
                <button
                  title="Top up wallet"
                  style={{
                    color: "#0275D8",
                    display: "flex",
                    marginTop: "1rem",
                    alignItems: "center",
                    border: "1px solid #E5E7EB",
                    outline: "none",

                    justifyContent: "center",
                    gap: "10px",
                    padding: "0.5rem 1rem",
                    borderRadius: "0.5rem",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                    fontWeight: 600,
                  }}
                  onClick={onClick}
                >
                  <div>{buttonText}</div>
                </button>
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <section
      style={{
        marginTop: "2rem",
      }}
    >
      <article className="filter-tabs-container">
        <div
          className="rounded-left"
          style={filterStyle("This week")}
          onClick={() => handleFilterClick("This week")}
        >
          This week
        </div>
        <div
          style={filterStyle("This Month")}
          onClick={() => handleFilterClick("This Month")}
        >
          This Month
        </div>
        <div
          style={filterStyle("This Year")}
          onClick={() => handleFilterClick("This Year")}
        >
          This Year
        </div>
        {/* <div
          style={filterStyle('All Time')}
          onClick={() => handleFilterClick('All Time')}
        >
          All Time
        </div> */}
        <div
          className="rounded-right"
          style={filterStyle("Custom Range")}
          ref={target}
          onClick={() => {
            handleFilterClick("Custom Range");
            setShow(!show);
          }}
        >
          Custom Range
        </div>
      </article>

      <Overlay
        rootClose={true}
        onHide={() => setShow(false)}
        target={target.current}
        show={show}
        placement="bottom"
      >
        {({
          placement: _placement,
          arrowProps: _arrowProps,
          show: _show,

          popper: _popper,
          hasDoneInitialMeasure: _hasDoneInitialMeasure,
          ...props
        }) => (
          <div
            {...props}
            style={{
              // position: 'absolute',

              backgroundColor: "white",
              padding: "10px",
              borderRadius: "10px",
              boxShadow:
                "0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)",
              border: "1px solid var(#E1E6ED, #E1E6ED)",

              ...props.style,
            }}
          >
            <div
              style={{
                margin: "30px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <div>
                  <span
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    Start Date
                  </span>
                </div>
                <div>
                  <span
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    End Date
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "20px",
                  marginTop: "10px",
                }}
              >
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  inline
                  showYearDropdown
                />
                <DatePicker
                  selected={endDate}
                  onChange={(date) => {
                    setEndDate(date);
                    setShow(false);
                  }}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  inline
                  showYearDropdown
                />
              </div>
            </div>
          </div>
        )}
      </Overlay>

      <H1
        style={{
          fontSize: "0.875rem",
          textAlign: "start",
          fontWeight: 400,
          color: "#6B7280",
          marginBottom: "2rem",
        }}
      >
        {selectedFilter === "Custom Range"
          ? `Showing data between ${formatWalletDate(
              startDate
            )} and ${formatWalletDate(endDate)}`
          : `Showing data for ${selectedFilter.toLowerCase()}`}
      </H1>
      {error ? (
        <CustomError
          //       pageSize={this.state.pageSize}
          // pageNum={this.state.pageNum}
          isLoading={isLoading}
          onRetry={() => fetchInflowOutflow()}
        />
      ) : (
        <article className="agentType-container-card">
          <BalanceCard
            title={"Wallet Balance"}
            balance={"**********"}
            // showTopUp={true}
            loading={isLoading}
            buttonText={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <MdBook color={"#0275D8"} />
                <div>Top-up wallet</div>
              </div>
            }
          />

          <BalanceCard
            title={"Inflow"}
            loading={isLoading}
            balance={`₦${formatAmount(
              convertNgkToNgn(inflowOutflow.inFlowValue)
            )}`}
          />

          <BalanceCard
            title={"Outflow"}
            loading={isLoading}
            balance={`₦${formatAmount(
              convertNgkToNgn(inflowOutflow.outFlowValue)
            )}`}
          />
        </article>
      )}
      <article>
        <H1
          style={{
            textAlign: "start",
            marginTop: "2rem",
            fontSize: "1rem",
            fontWeight: "600",
            color: process.env.REACT_APP_BLUE_COLOUR,
          }}
        >
          Transaction History
        </H1>
        <TransactionTable
          dateIntervalType={dateIntervalType}
          startDate={startDate}
          endDate={endDate}
          agentDetails={agentDetails}
        />
      </article>
    </section>
  );
};

export default Activity;
