import React from 'react'
import { Redirect } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import BaseScene from './base-scene'
import Header from '../components/header'
import '../App.css';
import Button from '../components/button';
import Footer from '../components/footer';
import H1 from '../components/h1';

export default class VerifyEmailOne extends BaseScene {
  isAuthRequired = false
  
  state = {
    redirectToApplication: false
  }

  render () {
    if (this.state.redirectToApplication) {
      return <Redirect to="/application" />
    }

    return <div style={{
      backgroundColor: 'white',
      minHeight: '100vh',
      height: '100%',
      width: '100%',
      maxWidth: '1440px',
      overflow: 'hidden',
      display: 'block',
      flexDirection: 'column',
      marginLeft: 'auto',
      marginRight: 'auto',
      position: 'relative',
      paddingBottom: '100px'
    }}>
      <div>
        <svg hidden style={{position: 'absolute', top: '15%'}} width="100%" height="622" viewBox="0 0 1440 622" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 247.82C0 247.82 104.352 388 272.469 374C449.468 359.26 521.768 230.644 680.33 131.552C842.827 30.0001 1039.49 5.75783 1150.41 0.444883C1217.53 -2.76993 1440 72.6646 1440 72.6646V622H0L0 247.82Z" fill="url(#paint0_linear)"/>
          <defs>
            <linearGradient id="paint0_linear" x1="0" y1="0.313477" x2="0" y2="622" gradientUnits="userSpaceOnUse">
              <stop stop-color="#E2E3E3"/>
              <stop offset="2" stop-color="white"/>
            </linearGradient>
          </defs>
        </svg>

        <svg hidden style={{position: 'absolute', top: '15%'}} width="100%" height="622" version="1.1" xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
          <desc>Created with Lunacy</desc>
          <defs>
            <linearGradient x1="0.5" y1="0.303625673" x2="0.5" y2="0.589102864" id="gradient_1" gradientUnits="userSpaceOnUse">
              <stop offset="0" stop-color="#EAECEF" />
              <stop offset="1" stop-color="#F4F5F7" />
            </linearGradient>
          </defs>
          <path d="M9.99201e-15 213C9.99201e-15 213 243.514 330.377 528 288.297C812.486 246.217 2500 0 2500 0L2500 2500L0 2500L9.99201e-15 213Z" transform="matrix(-1 0 0 1 1440 0)" id="Rectangle" fill="url(#gradient_1)" stroke="none" />
        </svg>

        <svg hidden style={{position: 'absolute', top: '15%'}} width="1440px" height="850px" version="1.1" xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
          <desc>Created with Lunacy</desc>
          <defs>
            <linearGradient x1="0.5" y1="0.303625673" x2="0.5" y2="0.589102864" id="gradient_1">
              <stop offset="0" stop-color="#EAECEF" />
              <stop offset="1" stop-color="#F4F5F7" />
            </linearGradient>
          </defs>
          <path d="M9.99201e-15 213C9.99201e-15 213 243.514 330.377 528 288.297C812.486 246.217 2500 0 2500 0L1440 850L0 850L9.99201e-15 213Z" transform="matrix(-1 0 0 1 1440 0)" id="Rectangle" fill="url(#gradient_1)" stroke="none" />
        </svg>
      </div>
      
      <Header outerDivStyle={{position: 'relative'}} />

      <Row style={{padding: this.state.deviceIsMobile ? '0px 0px 20px 0px' : '0px 50px 0px 50px', margin: 0}}>
        <Col xs={12} md={6} style={{
          fontSize: '26px', 
          fontWeight: 'bold',
          justifyContent: 'center', 
          height: '100%',
          padding: `${this.state.deviceIsMobile ? '25px' : '100px' } 25px ${this.state.deviceIsMobile ? '15px' : '200px'} 25px`}}>
          <center>
            <svg width="113px" height="113px" viewBox="0 0 113 113" version="1.1" xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
              <desc>Created with Lunacy</desc>
              <defs>
                <filter filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB" id="filter_1">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                  <feOffset dx="0" dy="6" />
                  <feGaussianBlur stdDeviation="5.5" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2196078 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect0_dropShadow" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect0_dropShadow" result="shape" />
                </filter>
                <path d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z" id="path_1" />
                <clipPath id="mask_1">
                  <use href="#path_1" />
                </clipPath>
              </defs>
              <g id="Oval-2" transform="translate(11 5)">
                <g filter="url(#filter_1)">
                  <path d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z" id="Oval" fill="#FFFFFF" stroke="none" />
                </g>
                <g clip-path="url(#mask_1)">
                  <g id="icon-3" transform="translate(27 17)">
                    <path d="M22.6588 0L3.34118 0C1.49241 0 0 1.46928 0 3.278L0 41.7253C0 43.5324 1.49241 45 3.34118 45L22.6588 45C24.5042 45 26 43.5324 26 41.7253L26 3.278C26 1.46928 24.5042 0 22.6588 0ZM8.23333 1.99074L17.77 1.99074C18.0109 1.99074 18.2067 2.34331 18.2067 2.7795C18.2067 3.21569 18.0109 3.56989 17.77 3.56989L8.23333 3.56989C7.99073 3.56989 7.79833 3.21569 7.79833 2.7795C7.79833 2.34331 7.99073 1.99074 8.23333 1.99074ZM13.0017 41.7646C11.8255 41.7646 10.8685 40.8267 10.8685 39.6722C10.8685 38.5178 11.8255 37.5831 13.0017 37.5831C14.1745 37.5831 15.1315 38.5178 15.1315 39.6722C15.1315 40.8267 14.1745 41.7646 13.0017 41.7646ZM23.497 34.6003L2.50463 34.6003L2.50463 5.53112L23.497 5.53112L23.497 34.6003Z" id="Shape" fill="#00B8DE" stroke="none" />
                  </g>
                </g>
              </g>
            </svg>

            <H1>
              DOWNLOAD MOBILE APP
            </H1>

            <div style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'normal',
              color: '#A2A3A3',
              marginTop: '20px'}}>
              Please check your email and click on the link provided to verify your address.
            </div>

            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <img style={{width: '170px'}} src={'/assets/media/icons/apple-store.png'} alt="" />
              <img style={{width: '160px'}} src={'/assets/media/icons/play-store.png'} alt="" />
            </div>

          </center>
        </Col>

        <Col xs={12} md={6} style={{
          fontSize: '26px', 
          fontWeight: 'bold',
          justifyContent: 'center', 
          height: '100%',
          borderTop: `${this.state.deviceIsMobile ? '1px solid grey' : ''}`,
          borderLeft: `${!this.state.deviceIsMobile ? '1px solid grey' : ''}`,
          padding: `${this.state.deviceIsMobile ? '25px' : '100px' } 25px ${this.state.deviceIsMobile ? '0px' : '200px'} 25px`}}>
          <center>
            <svg width="113px" height="113px" viewBox="0 0 113 113" version="1.1" xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
              <desc>Created with Lunacy</desc>
              <defs>
                <filter filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB" id="filter_1">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                  <feOffset dx="0" dy="6" />
                  <feGaussianBlur stdDeviation="5.5" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2196078 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect0_dropShadow" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect0_dropShadow" result="shape" />
                </filter>
                <path d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z" id="path_1" />
                <clipPath id="mask_1">
                  <use href="#path_1" />
                </clipPath>
              </defs>
              <g id="Oval-3" transform="translate(11 5)">
                <g filter="url(#filter_1)">
                  <path d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z" id="Oval" fill="#FFFFFF" stroke="none" />
                </g>
                <g clip-path="url(#mask_1)">
                  <g id="world-2" transform="translate(21 21)">
                    <path d="M17.7689 4.51209L17.1979 4.68901L14.1575 4.9538L13.2987 6.29436L12.6756 6.10082L10.2558 3.96826L9.90479 2.85924L9.43469 1.6766L7.91387 0.343156L6.11974 0L6.07844 0.802677L7.83613 2.47927L8.69615 3.46956L7.72923 3.96351L6.9421 3.73672L5.76261 3.25583L5.8027 2.3261L4.25515 1.70391L3.74132 3.8899L2.18163 4.23543L2.3359 5.45488L4.36812 5.83722L4.71917 3.88871L6.39669 4.13094L7.17654 4.5774L8.4277 4.5774L9.28407 6.25399L11.5544 8.50529L11.388 9.3804L9.55738 9.15242L6.39426 10.7138L4.11667 13.3843L3.82028 14.5669L3.00278 14.5669L1.47952 13.8806L0 14.5669L0.368059 16.0927L1.01186 15.3672L2.14397 15.3328L2.06502 16.703L3.00278 16.9714L3.93932 17.9997L5.46865 17.5793L7.21541 17.8489L9.24398 18.382L10.2571 18.4984L11.9747 20.4041L15.2896 22.3099L13.1457 26.3138L10.8826 27.3421L10.0238 29.6302L6.74896 31.7675L6.40034 33C14.7722 31.0289 21 23.6778 21 14.8887C20.9976 11.0499 19.8071 7.47701 17.7689 4.51209Z" transform="translate(17 4)" id="Path" fill="#00B8DE" stroke="none" />
                    <path d="M21.1288 19.2087L19.7485 16.7222L21.0154 14.1571L19.7485 13.789L18.3259 12.4009L15.1742 11.714L14.1281 9.58734L14.1281 10.8499L13.6672 10.8499L10.9511 7.27198L10.9511 4.33294L8.96024 1.18757L5.79894 1.73505L3.66928 1.73505L2.59782 1.05275L3.9649 0L2.60144 0.305979C0.958043 3.03283 0 6.19695 0 9.58617C0 19.7538 8.48363 28 18.9497 28C19.7557 28 20.546 27.9308 21.3279 27.8394L21.13 25.6084C21.13 25.6084 22 22.2954 22 22.1829C21.9988 22.0692 21.1288 19.2087 21.1288 19.2087Z" transform="translate(0 9)" id="Path" fill="#00B8DE" stroke="none" />
                    <path d="M2.62624 6.20819L5.89148 5.7314L7.39653 4.86727L9.09001 5.37839L11.7958 5.2215L12.7227 3.6955L14.0745 3.92838L17.3573 3.60602L18.2619 2.56172L19.5376 1.66941L21.3423 1.95377L22 1.84959C19.623 0.677815 16.9781 0 14.1751 0C8.46973 0 3.36823 2.72352 0 7L0.0093627 7L2.62624 6.20819ZM14.9522 1.91455L16.8294 0.832254L18.0349 1.56155L16.2899 2.95272L14.6234 3.128L13.8732 2.61811L14.9522 1.91455ZM9.39196 2.07267L10.2206 2.43425L11.3055 2.07267L11.8965 3.14516L9.39196 3.834L8.18768 3.09613C8.18651 3.09613 9.36504 2.30187 9.39196 2.07267Z" transform="translate(4 0)" id="Shape" fill="#00B8DE" stroke="none" />
                  </g>
                </g>
              </g>
            </svg>

            <H1>
              PREFER USING YOUR BROWSER?
            </H1>

            <div style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'normal',
              color: '#A2A3A3',
              marginTop: '20px'}}>
              Please check your email and click on the link provided to verify your address.
            </div>

            <Button onClick={() => this.setState({redirectToApplication: true})} style={{
              marginTop: '20px'
            }}>
              GO TO WEB APP
            </Button>

          </center>
        </Col>
      </Row>

      <Footer />
    </div>
  }
}