import qs from "qs";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MdSync from "react-ionicons/lib/MdSync";
import Select from "react-select";
import "react-table/react-table.css";
import ActivityIndicator from "../../components/activity-indicator";
import Text from "../../components/text";
import { HTTP_NOT_FOUND_CODE, SUCCESS_STATUS } from "../../constants/api";
import { SampleTransactions } from "../../fixtures/transactions";
import BaseScene from "../../scenes/base-scene";
import UserSerializer from "../../serializers/resources/user";
import CRMService from "../../services/api/resources/crm-service";
import Platform from "../../services/api/resources/platform";
import TransactionHistory from "../../services/api/resources/transaction-history";
import {
  specialDateRangeFormatter_EndDate,
  specialDateRangeFormatter_StartDate,
} from "../../utils/formatters";
import Transaction from "../../utils/mocks/transaction";
import Wallet from "../../utils/mocks/wallet";
import "./styles.scss";
import TransactionsReportTable from "./tables/transactions-report";
export default class IssueReportScene extends BaseScene {
  location = window.location.href;
  path = "/reports";
  title = "Reports";

  transaction = new Transaction({
    wallet: new Wallet(),
  });

  transactionHistory = new TransactionHistory();
  platform = new Platform();
  crmService = new CRMService();
  TodaysDate = new Date();
  OneMonthsBefore = new Date(
    this.TodaysDate.getFullYear(),
    this.TodaysDate.getMonth() - 1
  );

  constructor(props) {
    super(props);

    const lastWeekDate = new Date();
    lastWeekDate.setDate(lastWeekDate.getDate() - 7);

    const firstDayCurrentMonth = new Date(
      this.TodaysDate.getFullYear(),
      this.TodaysDate.getMonth(),
      1
    );
    const lastDayCurrentMonth = new Date(
      this.TodaysDate.getFullYear(),
      this.TodaysDate.getMonth() + 1,
      0
    );

    const firstDayPrevMonth = new Date(
      this.TodaysDate.getFullYear(),
      this.TodaysDate.getMonth() - 1,
      1
    );
    const lastDayPrevMonth = new Date(
      this.TodaysDate.getFullYear(),
      this.TodaysDate.getMonth(),
      0
    );

    this.state = {
      endDate: this.TodaysDate,
      startDate: this.OneMonthsBefore,
      reportsGraphEndDate: this.TodaysDate,
      reportsGraphStartDate: lastWeekDate,
      firstDayCurrentMonth,
      lastDayCurrentMonth,
      firstDayPrevMonth,
      lastDayPrevMonth,
      showModal: false,
      transactionHistory: this.transaction.history,
      sampleTransactions: [],
      filteredTransactions: [],
      filteredTransactions2: SampleTransactions,
      searchTerm: "",
      selectedOption: { label: "Transaction Type ", value: "" },
      statusOption: { label: "Transaction Status", value: "" },
      transacionRef: "",
      agentList: { label: "Select Agent", value: "0" },
      pageSize: 50,
      pageNum: 1,
      pageIndex: 1,
      isLoading: false,
      didErrorOccurWhileFetchingAllTransactions: false,
      deviceIsMobile:
        qs.parse(window.location.search)["?mobile"] || window.innerWidth <= 576,
      deviceIsPC:
        !qs.parse(window.location.search)["?mobile"] &&
        window.innerWidth >= 992,
      deviceIsTab:
        qs.parse(window.location.search)["?mobile"] || window.innerWidth <= 992,
      showServiceCategories: true,
      transactionTypeFilter: "",
      dateLoggedFilter: "",
      statusFilter: "",
      loggedDate: "",
      alternateData: [],
    };

    this.getAllTransactions = this.getAllTransactions.bind(this);
    this.getAlternateData = this.getAlternateData.bind(this);
  }

  componentDidMount() {
    const currentUser = JSON.parse(localStorage.getItem("currentUser") || "{}");
    const userSerializer = new UserSerializer(currentUser);

    this.setState({
      userSerializer: userSerializer,
    });
  }

  componentWillMount() {
    this.getAllTransactions(
      this.state.startDate,
      this.state.endDate,
      this.state.pageNum,
      this.state.pageSize
    );

    this.getAlternateData();
  }

  handleEmptyResponse = (code) => {
    this.setState({
      sampleTransactions: [],
      filteredTransactions: [],
      pages: 1,
      isLoading: false,
    });
    if (code === HTTP_NOT_FOUND_CODE) {
      this.setState({
        didErrorOccurWhileFetchingAllTransactions: false,
      });
    } else {
      this.setState({
        didErrorOccurWhileFetchingAllTransactions: true,
      });
    }
  };

  async getAllTransactions(
    startDate,
    endDate,
    pageNum,
    pageSize,
    selectedOption,
    statusOption,
    transacionRef,
    ticketNumber
  ) {
    const transactionStartDate = specialDateRangeFormatter_StartDate(startDate);

    if(endDate === null){
      endDate = Date.now();
    }

    const transactionEndDate = specialDateRangeFormatter_EndDate(endDate);
    this.setState({
      isLoading: true,
      pageNum,
      pageSize,
    });

    const { status, response, code, description } =
      await this.crmService.getIssueHistory(
        transactionStartDate,
        transactionEndDate,
        pageNum,
        pageSize,
        selectedOption,
        statusOption,
        transacionRef,
        ticketNumber
      );
    if (status === SUCCESS_STATUS) {
      if (response.data && response.data.content) {
        const { content, count } = response.data;
        const filteredResponse = content;

        this.setState({
          sampleTransactions: filteredResponse,
          filteredTransactions: filteredResponse,
          isLoading: false,
          pages: Math.ceil(count / this.state.pageSize),
          dataCount: count / this.state.pageSize,
          didErrorOccurWhileFetchingAllTransactions: false,
        });
      } else {
        this.setState({
          sampleTransactions: [],
          filteredTransactions: [],
          isLoading: false,
          pages: 0,
          dataCount: 0,
          didErrorOccurWhileFetchingAllTransactions: false,
        });
      }
    } else {
      this.handleEmptyResponse(code);
    }
  }

  handleFilter = async () => {
    const {
      transactionTypeFilter,
      statusFilter,
      dateLoggedFilter,
    } = this.state;
  
    this.handleClose();
     if (transactionTypeFilter && transactionTypeFilter.value === "ALL") {
    this.setState({ transactionTypeFilter: "" });
  }
    await this.getAllTransactions(
      dateLoggedFilter ? dateLoggedFilter : this.state.startDate,
      this.state?.endDate,
      this.state.pageNum,
      this.state.pageSize,
      transactionTypeFilter?.value,
      statusFilter?.value
    );
    this.setState({
      transactionTypeFilter: "",
      statusFilter: "",
      dateLoggedFilter: "",
    });
  };
  

  getAlternateData = async () => {
    const { startDate, endDate, pageNum, pageSize ,} = this.state;
    const transactionStartDate = specialDateRangeFormatter_StartDate(startDate);
    const transactionEndDate = specialDateRangeFormatter_EndDate(endDate);
    try {
      const res = await this.crmService.getIssueHistory(
        transactionStartDate,
        transactionEndDate,
        pageNum,
        pageSize
      );
      const { status, response } = res;
      if (status === SUCCESS_STATUS) {
        if (response.data && response.data.content) {
          const { content, count } = response.data;
          if (content.length > 0 || count > 0) {
            this.setState({ alternateData: content });
          }
        }
      }
    } catch (err) {}
  };

  closeSearch() {
    this.setState({ filteredTransactions: this.state.sampleTransactions });
  }

  handleSearch = (event) => {
    const { alternateData } = this.state;
    const search = event.target.value;
  
    const filterData = alternateData?.filter((item) => {
      if (item.transactionRef && item.ticketNumber) {
        return (
          item.transactionRef.toUpperCase().includes(search.toUpperCase()) ||
          item.ticketNumber.toUpperCase().includes(search.toUpperCase()) ||
          item.transactionRef.toLowerCase().includes(search.toLowerCase()) ||
          item.ticketNumber.toLowerCase().includes(search.toLowerCase())
        );
      }
      return false;
    });
  
    if (!filterData || filterData?.length === 0) {
      this.setState({
        filteredTransactions: [],
      });
      return;
    }
    this.setState({ filteredTransactions: filterData });
  };
  

  didErrorOccurWhileFetchingMonthlyServiceStatistics() {
    const { currentMonthError, previousMonthError } = this.state;
    return currentMonthError || previousMonthError;
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  renderRefreshTransactionMessage() {
    const { startDate, endDate, pageNum, pageSize } = this.state;
    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "top",
          marginTop: "24px",
          paddingBottom: "30px",
        }}
      >
        <Text blue bold small>
          Oops!
        </Text>
        <Text mid>Something went wrong. Please, try again.</Text>
        <div
          style={{
            alignItems: "center",
            border: `1pt solid ${process.env.REACT_APP_BLUE_COLOUR}`,
            borderRadius: "4px",
            color: process.env.REACT_APP_BLUE_COLOUR,
            cursor: "pointer",
            display: "flex",
            fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            fontStyle: process.env.REACT_APP_MID_FONT_SIZE,
            fontWeight: "bold",
            height: "40px",
            justifyContent: "center",
            marginTop: "16px",
            width: "160px",
          }}
          onClick={() =>
            this.getAllTransactions(startDate, endDate, pageNum, pageSize)
          }
        >
          <MdSync
            rotate={this.state.isLoading}
            color={process.env.REACT_APP_BLUE_COLOUR}
            fontSize={"24px"}
            style={{ marginRight: "10px" }}
          />
          {this.state.isLoading ? "Loading..." : "RETRY"}
        </div>
      </div>
    );
  }

  complaintStatus = [
    {
      label: "All",
      value: "",
    },
    {
      label: "Active",
      value: "Active",
    },
    {
      label: "Resolved",
      value: "Resolved",
    },
  ];

  complaintTransactionType = [
    {
      label: "ALL",
      value: "",
    },
    {
      label: "BILLS",
      value: "BILLS",
    },
    {
      label: "TRANSFER",
      value: "TRANSFER",
    },
    {
      label: "NIP",
      value: "NIP",
    },
    {
      label: "POS PURCHASE",
      value: "POS PURCHASE",
    },
    {
      label: "RECHARGE",
      value: "RECHARGE",
    },
    {
      label: "FUND",
      value: "FUND",
    },
    {
      label: "CASH OUT",
      value: "CASH OUT",
    },
    {
      label: "WALLET",
      value: "WALLET",
    },
    {
      label: "CASH IN",
      value: "CASH_IN",
    },
    {
      label: "COMMISSION UNLOAD",
      value: "COMMISSION_UNLOAD",
    },
    {
      label: "PAYPOINT FUND",
      value: "PAYPOINT_FUND",
    },
    {
      label: "REVERSAL",
      value: "REVERSAL",
    },
    {
      label: "REFUND",
      value: "REFUND",
    },
    {
      label: "RETRIEVAL",
      value: "RETRIEVAL",
    },
    {
      label: "ACCOUNT OPENING",
      value: "ACCOUNT_OPENING",
    },
    {
      label: "DISTRIBUTE",
      value: "DISTRIBUTE",
    },
  ];

  render() {
    const {
      searchTerm,
      filteredTransactions,
      statusOption,
      transacionRef,
      selectedOption,
      transactionGraphData,
      currentMonthGraphData,
      previousMonthGraphData,
      userSerializer,
      agentList,
    } = this.state;

    const transactionReportComponent = (
      <div
        className={
          this.state.deviceIsMobile
            ? "container container-fluid reports-scene-container-mobile"
            : "reports-scene-container"
        }
      >
        <div
          className="h1 reports-scene-header"
          style={{ fontSize: 20, fontWeight: "bold" }}
        >
          Issue History
        </div>
        <Container
          fluid
          className="regular-container"
          style={{ padding: "0px", overflow: "scroll", width: "100%" }}
        >
          <Row id="reports-scene-row">
            <Col
              md={12}
              className="card-with-shadow"
              id="transactions-table-container"
            >
              <div className="transactions-table-header">
                <div className="search-container">
                  <input
                    className="search-input"
                    id="search-field"
                    size={38}
                    // value={searchTerm}
                    style={{ fontSize: 12, height: 36 }}
                    onChange={this.handleSearch}
                    placeholder="Enter Ticket ID or Transaction Reference"
                  />
                </div>

                <div className="reports-scene-date-picker-container">
                  <button
                    id="filter-field"
                    onClick={() => this.setState({ showModal: true })}
                  >
                    Filter
                  </button>
                </div>
              </div>
              <div className="transactions-table-container ">
                {this.state.didErrorOccurWhileFetchingAllTransactions ? (
                  this.renderRefreshTransactionMessage()
                ) : filteredTransactions.length > 0 ? (
                  <TransactionsReportTable
                    data={filteredTransactions}
                    dataCount={this.state.dataCount}
                    getAllTransactions={this.getAllTransactions}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    pages={this.state.pages}
                    pageSize={this.state.pageSize}
                    pageNum={this.state.pageNum}
                    loading={this.state.isLoading}
                    selectedOption={this.state.selectedOption}
                    statusOption={this.state.transacionRef}
                    dismissErrorMessage={this.props.dismissErrorMessage}
                    showErrorMessage={this.props.showErrorMessage}
                    domainCode={this.state.domainCode}
                    userSerializer={userSerializer}
                  />
                ) : this.state.isLoading ? (
                  <p
                    style={{
                      textAlign: "center",
                      cursor: "pointer",
                      padding: "20px 0 20px 0",
                    }}
                  >
                    <ActivityIndicator />
                  </p>
                ) : (
                  <p
                    style={{
                      textAlign: "center",
                      color: process.env.REACT_APP_BLUE_COLOUR,
                      fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                      padding: "85px 0 85px 0",
                    }}
                  >
                    {"No record found, change filter"}
                  </p>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );

    const desktopContainer = (
      <Row
        style={{ /*height: '100vh',*/ width: "55vw", margin: 0, padding: 0 }}
      >
        {
          <Col
            md={this.state.showServiceCategories ? 7 : 10}
            style={{ margin: "0px", padding: "0px" }}
          >
            {transactionReportComponent}
          </Col>
        }
      </Row>
    );

    const mobileContainer = (
      <Row style={{ margin: 0, padding: 0 }}>
        {!this.state.selectedServiceCategory ? (
          <Col
            md={12}
            style={{
              backgroundColor: "#eef2f4",
              height: "100%",
              minHeight: "100vh",
              padding: "35px",
              paddingTop: 0,
              paddingLeft: "20px",
              margin: 0,
            }}
          >
            {this.serviceCategories}
          </Col>
        ) : (
          <Col md={12} lg={9} style={{ margin: "0px", padding: "0px" }}>
            {transactionReportComponent}
          </Col>
        )}
      </Row>
    );

    return (
      <>
        <div
          style={{
            paddingLeft: "0px",
            paddingRight: !this.state.deviceIsMobile ? "0px" : "0px",
          }}
        >
          {true ? desktopContainer : mobileContainer}
        </div>
        {this.state.showModal && (
          <div style={{ position: "absolute", right: 10, top: 80, zIndex: 1 }}>
            <div id="print-receipt" style={{ textAlign: "center" }}>
              <Row style={{ marginTop: "15px" }}>
                <Col
                  md={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <div
                    style={{
                      paddingLeft: 50,
                      paddingRight: 50,
                      backgroundColor: "#F3F5F6",
                      borderRadius: 20,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          color: "#353F50",
                          fontSize: 24,
                          fontWeight: 400,
                          lineHeight: "36px",
                          textAlign: "center",
                        }}
                      >
                        Filter
                      </Text>
                      <div
                        onClick={() => {
                          this.handleClose();
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="52"
                          height="52"
                          viewBox="0 0 32 32"
                          fill="none"
                        >
                          <path
                            d="M20 12L12 20"
                            stroke="#353F50"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M20 20L12 12"
                            stroke="#353F50"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                       
                    <div style={{ padding: 5, width: "100%" }}>
                      <Text
                        style={{
                          color: "#353F50",
                          fontSize: 14,
                          fontWeight: 600,
                          textAlign: "start",
                          paddingBottom: 10,
                        }}
                      >
                         Date Logged
                      </Text>
                      <Text
                        style={{
                          color: "#353F50",
                          fontSize: 14,
                          fontWeight: 600,
                          textAlign: "start",
                          paddingBottom: 10,
                        }}
                      >
                        Start Date
                      </Text>
                      <div
                        style={{
                          backgroundColor: "#F3F5F6",
                          display: "flex",
                        }}
                      >
                        <DatePicker
                          selected={this.state.dateLoggedFilter}
                          onChange={(date) => {
                            this.setState({
                              dateLoggedFilter: date,
                            });
                          }}
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                          dateFormat={`dd/MM/yyyy`}
                          showYearDropdown
                          maxDate={new Date()}
                          closeOnScroll={true}
                          isClearable
                          placeholderText="Choose date"
                          style={{ width: "100%" }}
                          portalClassName="portal-content"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "0, 0",
                            },
                            preventOverflow: {
                              enabled: true,
                              boundariesElement: "viewport",
                            },
                          }}
                        />
                      </div>
                    </div>

                    <div style={{ padding: 5, width: "100%" }}>
                      <Text
                        style={{
                          color: "#353F50",
                          fontSize: 14,
                          fontWeight: 600,
                          textAlign: "start",
                          paddingBottom: 10,
                        }}
                      >
                        End Date
                      </Text>
                      <div
                        style={{
                          backgroundColor: "#F3F5F6",
                          display: "flex",
                        }}
                      >
                        <DatePicker
                          selected={this.state.endDate}
                          onChange={(date) => {
                            this.setState({
                              endDate: date,
                            });
                          }}
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                          dateFormat={`dd/MM/yyyy`}
                          showYearDropdown
                          maxDate={new Date()}
                          closeOnScroll={true}
                          isClearable
                          placeholderText="Choose date"
                          style={{ width: "100%" }}
                          portalClassName="portal-content"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "0, 0",
                            },
                            preventOverflow: {
                              enabled: true,
                              boundariesElement: "viewport",
                            },
                          }}
                        />
                      </div>
                    </div>

                    <div style={{ padding: 5, width: "100%" }}>
                      {/* <Row> */}
                      <Text
                        style={{
                          color: "#353F50",
                          fontSize: 14,
                          fontWeight: 600,
                          textAlign: "start",
                          paddingBottom: 10,
                        }}
                      >
                        Transaction Type
                        {/* <br /> */}
                      </Text>
                      {/* </Row> */}
                      <Select
                        options={this.complaintTransactionType}
                        onChange={(value) =>
                          this.setState({
                            transactionTypeFilter: value,
                          })
                        }
                        value={this.state.transactionTypeFilter}
                        classNamePrefix="react-select"
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: "rgb(178, 212, 255)",
                          },
                        })}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: 200,
                          }),
                          menu: (provided) => ({
                            ...provided,
                            width: 200,
                          }),
                        }}
                      />
                    </div>

                    <div style={{ padding: 5, width: "100%" }}>
                      {/* <Row> */}
                      <Text
                        style={{
                          color: "#353F50",
                          fontSize: 14,
                          fontWeight: 600,
                          textAlign: "start",
                          paddingBottom: 10,
                        }}
                      >
                        Ticket Status
                        <br />
                      </Text>
                      {/* </Row> */}

                      <Select
                        options={this.complaintStatus}
                        onChange={(value) =>
                          this.setState({ statusFilter: value })
                        }
                        value={this.state.statusFilter}
                        classNamePrefix="react-select"
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: "rgb(178, 212, 255)",
                          },
                        })}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: 200,
                          }),
                          menu: (provided) => ({
                            ...provided,
                            width: 200,
                          }),
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 20,
                        paddingBottom: 20,
                        paddingTop: 20,
                      }}
                    >
                      <button
                        onClick={() => this.handleFilter()}
                        style={{
                          paddingLeft: "50px",
                          paddingRight: "50px",
                          backgroundColor: "#00425F",
                          color: "white",
                          fontSize: "14px",
                          borderRadius: "8px",
                          padding: 10,
                          border: "none",
                        }}
                        disabled={
                          !this.state.transactionTypeFilter &&
                          !this.state.statusFilter &&
                          !this.state.dateLoggedFilter
                        }
                      >
                        Apply Filter
                      </button>
                      <button
                        onClick={() =>
                          this.setState({
                            transactionTypeFilter: "",
                            statusFilter: "",
                            dateLoggedFilter: "",
                          })
                        }
                        style={{
                          paddingLeft: "50px",
                          paddingRight: "50px",
                          backgroundColor: "#ffffff",
                          fontSize: "14px",
                          borderRadius: "8px",
                          padding: 10,
                          color: "#353F50",
                          border: "2px solid #E1E6ED",
                        }}
                      >
                        Clear Filter
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </>
    );
  }
}
