import React from "react";

import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../constants/api";
import CountriesStatesLga from "../../../../fixtures/countries_states_lgas.json";
import Onboarding from "../../../../services/api/resources/onboarding";
import { AppProvider } from "../context";
import BusinessDetailsScene from "./businessDetailsScene";
import KycDocumentScene from "./kycDocumentScene";
import InsufficientScene from "./insufficient";
import CancelledScene from "./cancelled";
import CongratsScene from "./congrat";
import TransactionFailedScene from "./transactionFailed";
import PersonalDetailsScene from "./personalDetailsScene";
import CACRegistrationScene from "./cacRegistration";
import PersonalDetailsSceneAssisted from "./personalDetailsSceneAssisted";
import KycDocumentSceneAssisted from "./kycDocumentSceneAssisted";
import BusinessDetailsSceneAssisted from "./businessDetailsSceneAssisted";

class SetupAgentOnboardingScene extends React.Component {
  constructor(props) {
    super(props);
    this.myElementRef = React.createRef();
    this.state = {
      screen: 1,
      isLoading: false,
      application: null,
    };

    this.fetchApplication = this.fetchApplication.bind(this);
  }

  setScreen = (newScreen) => {
    this.setState({ screen: newScreen });
  };

  async fetchApplication() {
    const applicationId = localStorage.getItem("applicationId");
    const onboarding = new Onboarding();
    // applicationId

    this.setState({ isLoading: true });
    const { status, response } = await onboarding.getApplicationAggregator(
      applicationId
    );
    localStorage.setItem("application", JSON.stringify(response));
    this.setState({ isLoading: false });
    if (status === ERROR_STATUS) {
    } else if (status === SUCCESS_STATUS) {
      this.setState({ isLoading: false });

      const stateArray = CountriesStatesLga[0].states.map((state) => {
        return {
          name: state.name,
          id: state.id,
        };
      });

      localStorage.setItem(
        "state",
        response?.businessDetails?.state
          ? stateArray.find(
              (value) => value.id === parseInt(response?.businessDetails?.state)
            ).name
          : ""
      );
    } else {
      this.setState({ isLoading: false });
    }
  }

  componentDidMount() {
    this.loadApplication();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoading !== this.props.isLoading) {
      this.loadApplication();
    }
  }

  loadApplication() {
    const application = JSON.parse(localStorage.getItem("application"));
    this.setState({ application });
  }

  render() {
    const { screen } = this.state;
    const application = JSON.parse(localStorage.getItem("currentApplication"));
    const cacAssistedService = localStorage.getItem("cacAssistedService");

    return (
      <AppProvider>
        <div className="onboarding-layout">
          {screen === 1 && (
            <CACRegistrationScene
              application={application}
              setScreen={this.setScreen}
              props={this.props}
              isLoading={this.state.isLoading}
              fetchApplication={this.fetchApplication}
            />
          )}

          {screen === 2 ? (
            cacAssistedService ? (
              <PersonalDetailsSceneAssisted
                application={application}
                setScreen={this.setScreen}
                screen={screen}
              />
            ) : (
              <PersonalDetailsScene
                application={application}
                setScreen={this.setScreen}
                screen={screen}
              />
            )
          ) : null}

          {screen === 3 ? (
            cacAssistedService ? (
              <KycDocumentSceneAssisted
                application={application}
                setScreen={this.setScreen}
              />
            ) : (
              <KycDocumentScene
                application={application}
                setScreen={this.setScreen}
              />
            )
          ) : null}

          {screen === 4 ? (
            cacAssistedService ? (
              <BusinessDetailsSceneAssisted
                application={application}
                setScreen={this.setScreen}
                screen={screen}
              />
            ) : (
              <BusinessDetailsScene
                application={application}
                setScreen={this.setScreen}
                screen={screen}
              />
            )
          ) : null}

          {screen === 5 && (
            <CongratsScene
              application={application}
              setScreen={this.setScreen}
              screen={screen}
            />
          )}
          {screen === 6 && (
            <InsufficientScene
              application={application}
              setScreen={this.setScreen}
              screen={screen}
            />
          )}
          {screen === 7 && (
            <CancelledScene
              application={application}
              setScreen={this.setScreen}
              screen={screen}
            />
          )}
          {screen === 8 && (
            <TransactionFailedScene
              application={application}
              setScreen={this.setScreen}
              screen={screen}
            />
          )}
        </div>
      </AppProvider>
    );
  }
}

export default SetupAgentOnboardingScene;
