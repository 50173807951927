import qs from 'qs';
import React from 'react';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import Select from 'react-select';
import 'react-table/react-table.css';
import FormControl from '../../../../components/form-control';
import { HTTP_NOT_FOUND_CODE, SUCCESS_STATUS } from '../../../../constants/api';
import AccountOpening from '../../../../services/api/resources/account-opening';
import { getDeviceInformation } from '../../../../utils/helpers';
import BaseScene from '../../../base-scene';

const customStylesBanks = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 14,
  }),
  control: (base) => ({
    ...base,
    minHeight: 48,
  }),
  singleValue: (provided, state) => {
    const fontSize = 14;
    return { ...provided, fontSize };
  },
};

const MAX_ACCOUNT_NUMBER_LENGTH = 10;

export default class LinkDebitCardScene extends BaseScene {
  location = window.location.href;
  path = '/link-debit-card';
  title = 'Link Debit Card';

  accountOpening = new AccountOpening();
  constructor(props) {
    super(props);

    this.state = {
      accountOpeningData: [
        { sanefBankCode: 'loading', bankName: 'loading...' },
      ],
      accountNumber: null,
      bankCode: null,
      errorMessage: null,
      checkValidFields: 0,
      isLoading: false,
      isLoadingBanks: false,
      fieldsFocused: [],
      redirectToCompleteCardLink: false,
      didErrorOccurWhileFetchingAllBanks: false,
      deviceIsMobile:
        qs.parse(window.location.search)['?mobile'] || window.innerWidth <= 576,
      deviceIsPC:
        !qs.parse(window.location.search)['?mobile'] &&
        window.innerWidth >= 992,
      deviceIsTab:
        qs.parse(window.location.search)['?mobile'] || window.innerWidth <= 992,
    };
    this.getBankList = this.getBankList.bind(this);
    this.sendOtp = this.sendOtp.bind(this);
  }

  componentWillMount() {
    this.getBankList();
  }

  handleGetAgentsEmptyResponse = (code) => {
    this.setState({
      agents: [],
      isAgentLoading: false,
    });
    if (code === HTTP_NOT_FOUND_CODE) {
      this.setState({
        didErrorOccurWhileGettingAgents: false,
      });
    } else {
      this.setState({
        didErrorOccurWhileGettingAgents: true,
      });
    }
  };

  async getBankList() {
    this.setState({
      isLoadingBanks: true,
    });
    const deviceInformation = getDeviceInformation();
    const { status, response, code } =
      await this.accountOpening.registerCardLinking(
        deviceInformation.deviceUuid
      );

    let accountOpeningResponse = '';
    try {
      accountOpeningResponse = response.data.bankList;
    } catch (e) { }

    if (status === SUCCESS_STATUS) {
      this.setState({
        accountOpeningData: accountOpeningResponse,
        isLoadingBanks: false,
        didErrorOccurWhileFetchingAllBanks: false,
      });
    } else {
      this.handleEmptyResponse(code);
    }
  }

  async sendOtp() {
    this.setState({
      isLoading: true,
    });

    let acctNumber = this.state.accountNumber;
    let bankCode = this.state.bankCode;
    const deviceInformation = getDeviceInformation();

    const { status, response } = await this.accountOpening.sendOtp(
      deviceInformation.deviceUuid,
      { accountNumber: acctNumber, bankCode: bankCode }
    );

    const cardLinkingResponse = response.data;

    if (status === SUCCESS_STATUS) {
      localStorage.setItem('requestId', cardLinkingResponse);
      this.setState({
        accountOpeningData: cardLinkingResponse,
        isLoading: false,
        didErrorOccurWhileFetchingAllBanks: false,
        redirectToCompleteCardLink: true,
      });
    } else {
      this.handleEmptyResponse(response.description);
    }
  }

  handleEmptyResponse = (description) => {
    this.setState({
      pages: 1,
      isLoading: false,
      errorMessage: description,
    });
  };

  updateAccountNumberField(params) {
    this.setState({ accountNumber: params });

    let count = 0;
    if (params.length > 9) {
      count = 1;
    }
    if (this.state.bankCode)
      if (this.state.bankCode.length > 1) {
        count += 1;
      }
    this.setState({ checkValidFields: count });
  }

  updateBankCodeField(params) {
    this.setState({ bankCode: params });

    let count = 0;
    if (params.length > 1) count = 1;
    if (this.state.accountNumber)
      if (this.state.accountNumber.length > 9) {
        count += 1;
      }
    this.setState({ checkValidFields: count });
  }

  render() {
    if (this.state.redirectToCompleteCardLink) {
      return <Redirect to='/agent/complete-debit-card-link' />;
    }

    return (
      <div
        className={
          this.state.deviceIsMobile
            ? 'container container-fluid reports-scene-container-mobile'
            : 'reports-scene-container'
        }
      >
        <div className='h1 reports-scene-header'>Card Linking</div>
        <Container
          fluid
          className='regular-container'
          style={{ padding: '10px' }}
        >
          <Row id='reports-scene-row'>
            <Col
              xs={6}
              md={6}
              xl={6}
              id='services-report-container'
              className='card-with-shadow'
            >
              <Col
                md={12}
                className='card-with-shadow'
                id='transactions-table-container'
              >
                <Form.Group controlId='accountNumber'>
                  {this.state.errorMessage && (
                    <Alert variant='danger'>{this.state.errorMessage}</Alert>
                  )}
                  <Form.Label
                    style={{
                      color: '#3A3B3B',
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: '19px',
                    }}
                  >
                    Account Number:{' '}
                    <span className='required-field-tick'>*</span>
                  </Form.Label>
                  <FormControl
                    autoComplete='off'
                    defaultValue={this.state.accountNumber}
                    maxLength={MAX_ACCOUNT_NUMBER_LENGTH}
                    type='text'
                    placeholder='Your account number'
                    onBlur={() =>
                      this.setState({
                        fieldsFocused: [
                          ...this.state.fieldsFocused,
                          'accountNumber',
                        ],
                      })
                    }
                    onChange={(event) => {
                      const accountNumber = event.target.value;
                      this.updateAccountNumberField(accountNumber);
                    }}
                    style={{
                      backgroundColor: '#FFFFFF',
                      borderRadius: '20px',
                      border: `1px solid #DFE2E6`,
                      boxSizing: 'border-box',
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: '17px',
                    }}
                  />
                </Form.Group>

                <Form.Group controlId='bankCode'>
                  <Form.Label
                    style={{
                      color: '#3A3B3B',
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: '19px',
                    }}
                  >
                    Banks : <span className='required-field-tick'>*</span>
                  </Form.Label>

                  <Select
                    options={this.state.accountOpeningData.map((value) => ({
                      value: value.sanefBankCode,
                      label: value.bankName,
                    }))}
                    onChange={(event) => {
                      const bankCode = event.value;
                      this.updateBankCodeField(bankCode);
                    }}
                    styles={customStylesBanks}
                    className='select-input-container-banks'
                    classNamePrefix='react-select'
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: 'rgb(178, 212, 255)',
                      },
                    })}
                  />
                </Form.Group>

                <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                  <Button
                    className='continue-later-pc-button-right'
                    onClick={this.sendOtp}
                    disabled={
                      this.state.checkValidFields < 2 || this.state.isLoading
                    }
                  >
                    {this.state.isLoading ? 'LOADING...' : 'CONTINUE'}
                  </Button>
                </div>
                <br />
                <br />
              </Col>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
