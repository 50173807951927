import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Col } from "react-bootstrap";
import { debounce } from "lodash";
import { toast } from "react-toastify";

import H1 from "../../../../components/h1";
import HeaderSignup from "../../../../components/header-signup";
import BackButton from "../component/backButton";
import PrimaryButton from "../component/button";
import InputText from "../component/input";
import SelectInput from "../component/select";

import Platform from "../../../../services/api/resources/platform";
import Transaction from "../../../../services/api/resources/transaction";
import { generateChecksum } from "../../../../utils/helpers";

import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../constants/api";
import { QUICKTELLER_TERMINAL_ID } from "../../../../utils/api-properties";
import { isProposedBusinessNameValid } from "../component/extras";
import { CAC_REGISTRATION } from "../../../../constants";
import { TRANSACTION_INITIATE_CLICK } from "../../../../constants/analytics";
import { logEvent } from "../../../../core/logger";

const MAX_BUSINESS_NAME_LENGTH = 50;
const businessLineOptions = [
  "General Merchandise",
  "Trading",
  "Fashion design/tailoring",
  "ICT Service",
  "Data Analysis",
  "Poultry/Livestock Farming",
  "Crop production farming/Agro allied service",
  "Hair stylist/salon",
  "Solar Panel installation",
  "Digital Marketing",
  "Graphic Design",
  "Content Creation",
  "Web Design",
  "POS Agent",
];

const CACRegistrationScene = ({ setScreen, application }) => {
  const cacAssistedService = localStorage.getItem("cacAssistedService");
  const history = useHistory();
  const platform = new Platform();
  const transaction = new Transaction();

  const [isLoading, setIsLoading] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [errorMessageVisible, setErrorMessageVisible] = useState(false);

  const [cacRegistrationForm, setCacRegistrationForm] = useState({
    proposedName:
      application?.applicantDetails?.cacRegistration?.proposedName || "",
    businessLine:
      application?.applicantDetails?.cacRegistration?.businessLine || "",
  });

  useEffect(() => {
    const savedForm = JSON.parse(localStorage.getItem("cacRegistrationForm"));
    if (savedForm) {
      setCacRegistrationForm({
        proposedName:
          savedForm.proposedName || cacRegistrationForm.proposedName,
        businessLine:
          savedForm.businessLine || cacRegistrationForm.businessLine,
      });
    }
  }, [application]);

  useEffect(() => {
    localStorage.setItem(
      "cacRegistrationForm",
      JSON.stringify(cacRegistrationForm)
    );
  }, [cacRegistrationForm]);

  useEffect(() => {
    if (isEdited) {
      validateBusinessName(cacRegistrationForm.proposedName);
    }
  }, [cacRegistrationForm.proposedName]);

  useEffect(() => {
    if (!cacAssistedService) {
      fetchBvnDetails();
    }
  }, []);

  const updateFormField = (params) => {
    setCacRegistrationForm((prevForm) => ({ ...prevForm, ...params }));
    setIsEdited(true);
  };

  const isFormValid = () =>
    cacRegistrationForm.proposedName &&
    cacRegistrationForm.businessLine &&
    isProposedBusinessNameValid(cacRegistrationForm.proposedName);

  const validateBusinessName = debounce((name) => {
    if (isEdited) {
      setErrorMessageVisible(!isProposedBusinessNameValid(name));
    }
  }, 500);

  const fetchBvnDetails = async () => {
    try {
      const { status, response } = await platform.getBvnInfo();
      if (status === ERROR_STATUS) {
      } else {
        localStorage.setItem("bvnInfo", JSON.stringify(response));
      }
    } catch (error) {}
  };

  const createCacPaymentRequest = async () => {
    // if (!isEdited) {
    //   setScreen(2);
    //   return;
    // }

    if (isFormValid()) {
      const transactionAmount = 0;
      const currentUser = JSON.parse(localStorage.getItem("currentUser"));
      const deviceId = localStorage.getItem("deviceUid");
      const username = currentUser.username;

      logEvent(TRANSACTION_INITIATE_CLICK, {
        transaction_type: CAC_REGISTRATION,
      });

      try {
        setIsLoading(true);
        const { status, response } = await transaction.initiateCacRegPayment(
          {
            transactionAmount,
            paymentInstrumentType: "CASH",
            businessName: cacRegistrationForm.proposedName,
            lineOfBusiness: cacRegistrationForm.businessLine,
            paymentItemCode: "IFISCAC",
            terminalId: QUICKTELLER_TERMINAL_ID,
          },
          generateChecksum(
            `${username}POST${transactionAmount}POSTIFISCACPOST${deviceId}`
          ),
          deviceId
        );
        setIsLoading(false);
        handleTransactionResponse(status, response);
      } catch (error) {
        notify("An unexpected error occurred. Please try again.");
      }
    }
  };

  const handleTransactionResponse = (status, response) => {
    if (status === ERROR_STATUS) {
      notifyWithHeading("Error!", response.description, "error");
    } else if (status === SUCCESS_STATUS) {
      localStorage.setItem("cacInitiate", JSON.stringify(response));
      notify("Proposed name registered successfully", "success");
      setScreen(2);
    } else {
      notify("Server error occurred. Please try again later.", "error");
    }
  };

  const notify = (message, type) => {
    const options = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    };

    type === "success"
      ? toast.success(message, options)
      : toast.error(message, options);
  };

  const notifyWithHeading = (heading, body, type) => {
    const options = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    };

    const message = (
      <>
        <strong>{heading}</strong>
        <p>{body}</p>
      </>
    );

    type === "success"
      ? toast.success(message, options)
      : toast.error(message, options);
  };

  return (
    <>
      <div
        style={{ display: "flex", justifyContent: "flex-start", gap: "40px" }}
      >
        <HeaderSignup />
        <BackButton
          marginBottom={0}
          onClick={() =>
            cacAssistedService
              ? history.push("/dashboard")
              : history.push("/business-cbn")
          }
        />
      </div>
      <main className="onboarding-container">
        <Col style={{ marginBottom: "20px" }}>
          <H1
            style={{ textAlign: "left", fontWeight: 600, marginBottom: "1rem" }}
          >
            Complete the details below
          </H1>
          <InputText
            label={"Proposed business name"}
            placeholder={"Enter Business Name"}
            onChange={(e) => updateFormField({ proposedName: e.target.value })}
            labelColor={
              !cacRegistrationForm.proposedName ||
              isProposedBusinessNameValid(cacRegistrationForm.proposedName)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !cacRegistrationForm.proposedName ||
              isProposedBusinessNameValid(cacRegistrationForm.proposedName)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            value={cacRegistrationForm.proposedName}
            maxLength={MAX_BUSINESS_NAME_LENGTH}
          />
          {isEdited && errorMessageVisible && (
            <p
              style={{
                color: process.env.REACT_APP_RED_COLOUR,
                fontSize: "14px",
              }}
            >
              Business name cannot be one word
            </p>
          )}
          <SelectInput
            label={"Line of business"}
            onChange={(e) => updateFormField({ businessLine: e.target.value })}
            placeholder={"business line"}
            value={cacRegistrationForm.businessLine}
            option={businessLineOptions}
          />
          <PrimaryButton
            onClick={createCacPaymentRequest}
            disabled={!isFormValid() || isLoading}
          >
            {isLoading ? "Processing..." : "Next"}
          </PrimaryButton>
        </Col>
      </main>
    </>
  );
};

export default CACRegistrationScene;
