import React from "react";
import { Nav, Tab } from "react-bootstrap";
import MdSync from "react-ionicons/lib/MdSync";
import { platformServiceClient } from "../../../../App";
import ActivityIndicator from "../../../../components/activity-indicator";
import Text from "../../../../components/text";
import {
  BUSINESS_DOC_SUFFIX,
  PERSONAL_DOC_SUFFIX,
} from "../../../../constants";
import { ERROR_STATUS } from "../../../../constants/api";
import IdentificationTypes from "../../../../fixtures/identification_types.json";
import Agent from "../../../../serializers/resources/agent";
import UserSerializer from "../../../../serializers/resources/user";
import UserManagement from "../../../../services/api/resources/user-management";
import { handleCustomerIdFormat } from "../../../../utils/formatters";
import BaseScene from "../../../base-scene";
import BackButton from "./component/backButton";
import BusinessDetailsForm from "./tabs/business-details-form";
import KYCInformationForm from "./tabs/kyc-information-form";
import NextOfKinDetailsForm from "./tabs/next-of-kin-details-form";
import PersonalDetailsForm from "./tabs/personal-details-form";

export default class ManageProfileScene extends BaseScene {
  location = window.location.href;
  path = "manage-AgentProfile";
  title = "Agent Profiles";

  constructor(props) {
    super(props);

    this.state = {
      // activeTab: 'personal-details',
      activeTab: "",
      didErrorOccurWhileFetching: null,
      form: {
        identificationtypes: IdentificationTypes,
      },
      isLoading: true,
    };

    this.userManagement = new UserManagement();

    this.loadData = this.loadData.bind(this);
    this.onLoadData = this.onLoadData.bind(this);
    this.resendEmail = this.resendEmail.bind(this);
  }

  componentDidMount() {
    this.getUrlParameter();

    this.loadData().then((agentData) => this.onLoadData(agentData));
  }

  getUrlParameter = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const theParam = urlParams.get("activeTab");
    this.setState({ activeTab: theParam });
  };
  async loadData() {
    this.setState({
      didErrorOccurWhileFetching: null,
      isLoading: true,
    });

    const { status, response } = await platformServiceClient.getCurrentAgent();

    this.setState({
      isLoading: false,
    });

    if (status === ERROR_STATUS) {
      this.setState({
        didErrorOccurWhileFetching: true,
      });
      return null;
    }
    localStorage.setItem("currentAgent", JSON.stringify(response));

    return response;
  }

  onLoadData(agentData) {
    const currentAgent =
      agentData || JSON.parse(localStorage.getItem("currentAgent") || "{}");
    const currentUser = JSON.parse(localStorage.getItem("currentUser") || "{}");
    const serializedAgent = new Agent(currentAgent);
    const userSerializer = new UserSerializer(currentUser);

    const personalDetailsDocuments = serializedAgent.documents
      ? serializedAgent.documents.filter((value) =>
          value.documentType.endsWith(PERSONAL_DOC_SUFFIX)
        )
      : [];

    const businessDetailsDocuments = serializedAgent.documents
      ? serializedAgent.documents.filter((value) =>
          value.documentType.endsWith(BUSINESS_DOC_SUFFIX)
        )
      : [];

    if ([null, {}].includes(currentAgent)) {
      return;
    }
    this.setState({
      currentUser: userSerializer,
      form: {
        ...this.state.form,
        firstName: serializedAgent?.businessContact
          ? serializedAgent?.businessContact?.firstname
          : "",
        middleName: serializedAgent?.businessContact
          ? serializedAgent?.businessContact?.middlename
          : "",
        lastName: serializedAgent?.businessContact
          ? serializedAgent?.businessContact?.lastname
          : "",
        phoneNumber: serializedAgent?.agentMobileNo
          ? handleCustomerIdFormat(serializedAgent?.agentMobileNo)
          : "",
        personalEmail: serializedAgent?.businessContact
          ? serializedAgent?.businessContact?.emailAddress
          : "",
        address: serializedAgent?.businessContact
          ? serializedAgent?.businessContact?.residentialAddress.addressLine1
          : "",
        town: serializedAgent?.businessContact
          ? serializedAgent?.businessContact?.residentialAddress.lga
          : "",
        closestLandmark: serializedAgent?.businessContact
          ? serializedAgent?.businessContact?.residentialAddress.landmark
          : "",
        gender: serializedAgent?.businessContact
          ? serializedAgent?.businessContact?.gender
          : "",
        state: serializedAgent?.businessContact
          ? serializedAgent?.businessContact?.residentialAddress.state
          : "",
        dob: serializedAgent?.businessContact
          ? serializedAgent?.businessContact?.dob
          : "",
        pob: serializedAgent?.businessContact
          ? serializedAgent?.businessContact?.placeOfBirth
          : "",
        stateOfOrigin: serializedAgent?.businessLocation[0]
          ? serializedAgent?.businessLocation[0]?.state
          : "",
        idType: serializedAgent?.identificationType
          ? serializedAgent?.identificationType
          : "",
        idNumber: serializedAgent?.identificationNumber
          ? serializedAgent?.identificationNumber
          : "",
        bvn: serializedAgent?.agentBankAccount
          ? serializedAgent?.agentBankAccount?.bvn
          : "",
        maidenName: serializedAgent?.businessContact
          ? serializedAgent?.businessContact?.motherMadienName
          : "",
        country: serializedAgent?.businessContact
          ? serializedAgent?.businessContact?.nationality
          : "",
        businessName: serializedAgent?.businessName
          ? serializedAgent?.businessName
          : "",
        companyRegNo: serializedAgent?.companyRegNo
          ? serializedAgent?.companyRegNo
          : "",
        businessMobileNo: serializedAgent?.businessPhoneNo
          ? handleCustomerIdFormat(serializedAgent?.businessPhoneNo)
          : "",
        businessType: serializedAgent?.businessTypeName
          ? serializedAgent?.businessTypeName
          : "",
        bankName: serializedAgent?.agentBankAccount
          ? serializedAgent?.agentBankAccount?.bankName
          : "",
        accountName: serializedAgent?.agentBankAccount
          ? serializedAgent?.agentBankAccount?.accountName
          : "",
        accountNumber: serializedAgent?.agentBankAccount
          ? serializedAgent?.agentBankAccount?.accountNo
          : "",
        businessAddress: serializedAgent?.businessLocation[0]
          ? `${serializedAgent?.businessLocation[0]?.addressLine1}`
          : "",
        businessEmail: serializedAgent?.businessEmail
          ? serializedAgent?.businessEmail
          : "",
        nextOfKinFirstName: serializedAgent?.nextOfKins[0]
          ? serializedAgent?.nextOfKins[0]?.firstname
          : "",
        nextOfKinLastName: serializedAgent?.nextOfKins[0]
          ? serializedAgent?.nextOfKins[0]?.lastname
          : "",
        nextOfKinMobileNo: serializedAgent?.nextOfKins[0]
          ? handleCustomerIdFormat(serializedAgent?.nextOfKins[0]?.phoneNo)
          : "",
        nextOfKinGender: serializedAgent?.nextOfKins[0]
          ? serializedAgent?.nextOfKins[0]?.gender
          : "",
        nextOfKinAddress: serializedAgent?.nextOfKins[0]
          ? serializedAgent?.nextOfKins[0]?.residentialAddress?.addressLine1
          : "",
        nextOfKinRelationship: serializedAgent?.nextOfKins[0]
          ? serializedAgent?.nextOfKins[0]?.relationship
          : "",
        personalDocuments: personalDetailsDocuments || [],
        businessDocuments: businessDetailsDocuments || [],
      },
    });
  }

  async resendEmail() {
    // const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
    // const userSerializer = new UserSerializer(currentUser);

    const resendEmailObj = await this.userManagement.resendEmail(
      this.state.currentUser.mobileNo
    );
    const { status, response } = resendEmailObj;

    if (status === ERROR_STATUS) {
      this.props.showErrorMessage(JSON.stringify(response));
      setTimeout(() => {
        this.props.dismissErrorMessage();
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
    } else {
      this.props.showSuccessMessage(response.description);
      setTimeout(() => {
        this.props.dismissSuccessMessage();
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
    }
  }

  renderRefreshTransactionMessage() {
    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
          marginTop: "24px",
          width: "83vw",
        }}
      >
        <Text blue bold small>
          Oops!
        </Text>
        <Text mid>Something went wrong. Please, try again.</Text>
        <div
          style={{
            alignItems: "center",
            // backgroundColor: this.props.isLoading ? 'white' : process.env.REACT_APP_BLUE_COLOUR,
            border: `1pt solid ${process.env.REACT_APP_BLUE_COLOUR}`,
            borderRadius: "4px",
            color: process.env.REACT_APP_BLUE_COLOUR,
            cursor: "pointer",
            display: "flex",
            fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            fontStyle: process.env.REACT_APP_MID_FONT_SIZE,
            fontWeight: "bold",
            height: "40px",
            justifyContent: "center",
            marginTop: "16px",
            // padding: '7.5px 10px 7.5px 10px',
            width: "160px",
          }}
          // onClick={() => props.disabled ? null : window.open(FUND_WALLET_URL, '_blank')}
          onClick={this.loadData}
        >
          <MdSync
            rotate={this.state.isLoading}
            color={process.env.REACT_APP_BLUE_COLOUR}
            fontSize={"24px"}
            style={{ marginRight: "10px" }}
          />
          {this.state.isLoading ? "Loading..." : "RETRY"}
        </div>
      </div>
    );
  }

  render() {
    //alert(this.props.activeTab)
    const { didErrorOccurWhileFetching, isLoading } = this.state;

    const screenSensitiveStyle = this.state.deviceIsPC
      ? {
          padding: "40px",
        }
      : { paddingTop: "20px" };

    const tabContentScreenSensitiveStyle = this.state.deviceIsPC
      ? {
          width: "80%",
          paddingLeft: "20%",
        }
      : {};

    if (didErrorOccurWhileFetching) {
      return this.renderRefreshTransactionMessage();
    }

    if (isLoading) {
      return (
        <ActivityIndicator style={{ width: this.state.deviceIsPC && "83vw" }} />
      );
    }

    const tabsContainer = (
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey={this.state.activeTab}
      >
        <div className="tab-container-commission">
          <div>
            <Nav variant="pills" className="flex-row">
              <Nav.Item>
                <Nav.Link
                  style={{
                    color: process.env.REACT_APP_BLUE_COLOUR,
                  }}
                  eventKey={
                    this.state.activeTab === "personal"
                      ? "personal"
                      : "personal"
                  }
                >
                  Personal Details
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  style={{ color: process.env.REACT_APP_BLUE_COLOUR }}
                  eventKey={
                    this.state.activeTab === "business"
                      ? "business"
                      : "business"
                  }
                >
                  Business Information
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  style={{
                    color: process.env.REACT_APP_BLUE_COLOUR,
                  }}
                  eventKey={this.state.activeTab === "kyc" ? "kyc" : "kyc"}
                >
                  KYC Information
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  style={{
                    color: process.env.REACT_APP_BLUE_COLOUR,
                  }}
                  eventKey={
                    this.state.activeTab === "nextOfKin"
                      ? "nextOfKin"
                      : "nextOfKin"
                  }
                >
                  Next of Kin
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        <div className="commision-container">
          <Tab.Content>
            <Tab.Pane
              eventKey={
                this.state.activeTab === "personal" ? "personal" : "personal"
              }
            >
              <PersonalDetailsForm
                form={this.state.form}
                onSubmit={() => {}}
                currentUser={this.state.currentUser}
                resendEmail={this.resendEmail}
              />
            </Tab.Pane>
            <Tab.Pane
              eventKey={
                this.state.activeTab === "business" ? "business" : "business"
              }
            >
              <BusinessDetailsForm form={this.state.form} onSubmit={() => {}} />
            </Tab.Pane>
            <Tab.Pane eventKey={this.state.activeTab === "kyc" ? "kyc" : "kyc"}>
              <KYCInformationForm form={this.state.form} onSubmit={() => {}} />
            </Tab.Pane>
            <Tab.Pane
              eventKey={
                this.state.activeTab === "nextOfKin" ? "nextOfKin" : "nextOfKin"
              }
            >
              <NextOfKinDetailsForm
                form={this.state.form}
                onSubmit={() => {}}
              />
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
    );
    const { history } = this.props;

    return (
      <div style={{ ...screenSensitiveStyle }}>
        <BackButton
          onClick={() =>
            history.push(
              `${process.env.REACT_APP_ROUTE_BASENAME}/manage-profile`
            )
          }
        />
        <div
          style={{
            boxSizing: "border-box",
            color: "var(--brand-colors-primary-blue, #00425f)",
            textAlign: "left",
            fontFamily: '"Averta-Semibold", sans-serif',
            fontSize: "30px",
            lineHeight: "24px",
            fontWeight: 600,
            position: "relative",
            marginBottom: "20px",
          }}
        >
          My Account
        </div>
        <div
          style={{
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "8px",
          }}
        >
          {tabsContainer}
        </div>
      </div>
    );
  }
}
