import React from 'react';
import { Badge, Carousel, Col, Form, InputGroup, Row } from 'react-bootstrap';
import MdArrowBack from 'react-ionicons/lib/MdArrowBack';
import MdCopy from 'react-ionicons/lib/MdCopy';
import Lottie from 'react-lottie';
import { connect } from 'react-redux';
import animationData from '../../../../../../animations/checked-done-2';
import Button from '../../../../../../components/button';
import FormControl from '../../../../../../components/form-control';
import FormLabel from '../../../../../../components/form-label';
import H1 from '../../../../../../components/h1';
import HR from '../../../../../../components/hr';
import { SUCCESS_STATUS } from '../../../../../../constants/api';
import Liquidity from '../../../../../../services/api/resources/liquidity';
import Quickteller from '../../../../../../services/api/resources/quickteller';
import {
  dismissErrorMessage,
  showErrorMessage
} from '../../../../../../services/redux/actions/tunnel';
import {
  FUND_WALLET_URL,
  SHOW_FUND_VIA_USSD,
  SHOW_STATIC_ACCOUNT
} from '../../../../../../utils/api-properties';
import { convertNgnToNgk } from '../../../../../../utils/converters/currencies';
import {
  formatAmount,
  processWebpayErrorResponse
} from '../../../../../../utils/formatters';
import {
  checkFieldsValidation,
  generateChecksum,
  setActivePage
} from '../../../../../../utils/helpers';
import BaseScene from '../../../../../base-scene';
import FundViaUSSD from './fund-ussd/FundViaUSSD';
import './fund-wallet.css';
import HeaderSignup from '../../../../../../components/header-signup';
const deviceId = localStorage.getItem('deviceUid');

export const copybox = {
  padding: '2px 27px',
  borderRadius: '3px',
  color: 'black',
  background: 'white',
  position: 'absolute',
  top: '25%',
  right: '25%',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  backdropFilter: 'blur(5px)',
};

class FundWalletScene extends BaseScene {
  quickteller = new Quickteller();
  liquidity = new Liquidity();

  constructor(props) {
    super(props);
    const cacRegistrationFee = localStorage.getItem("cac-registration-fee");

    this.state = {
      form: {},
      show: false,

      isLoading: false,
      agentMobileNo: '',
      isOpen: false,
      activeTab: 'confirmation',

      amount: cacRegistrationFee || '',
      isReadOnly: !!cacRegistrationFee,
    };
    this.myRef = React.createRef();

    this.createHandler = this.createHandler.bind(this);
    this.handleCreateHostedFields = this.handleCreateHostedFields.bind(this);
    this.handleMakePayment = this.handleMakePayment.bind(this);
    this.handlePayResponse = this.handlePayResponse.bind(this);
    this.handlePayment = this.handlePayment.bind(this);
    this.handleContinuePayment = this.handleContinuePayment.bind(this);
    this.handleBinConfigResponse = this.handleBinConfigResponse.bind(this);
    this.handleValidatePayment = this.handleValidatePayment.bind(this);
    this.handleValidateResponse = this.handleValidateResponse.bind(this);
    this.handleCopyAccountNumber = this.handleCopyAccountNumber.bind(this);
    this.goBack = this.goBack.bind(this);
    // this.handleFakeMakePayment = this.handleFakeMakePayment.bind(this);
  }

  handleCopyAccountNumber(agentId) {
    const currentAgent = JSON.parse(localStorage.getItem('currentAgent'));
    const filter = currentAgent.staticAccounts.filter(
      (data) => data.agentId === agentId
    );
    this.setState({
      show: true,
    });
    navigator.clipboard.writeText(filter[0].accountNumber);

    setTimeout(() => {
      this.setState({ show: false });
    }, 1500);
  }
  componentDidMount() {
    this.setupHostedFieldsSdk();
    const cacRegistrationFee = localStorage.getItem("cac-registration-fee");
    if (cacRegistrationFee) {
      this.setState({
        form: {
          amount: cacRegistrationFee,
        },
        isReadOnly: true,
      });
    }
  }

  setupHostedFieldsSdk() {
    const script = document.createElement('script');

    //https://isw-hosted-fields.k8.isw.la/sdk.js
    script.src = process.env.REACT_APP_HOSTED_FIELDS_SDK;
    this.div.appendChild(script);
  }

  handleChange = (event) => {
    const amount = event.target.value;
    if (!this.state.isReadOnly) {
      this.updateFormField({ amount });
    }
  };

  async handleMakePayment() {
    this.setState({
      isLoading: true,
    });
    const { form } = this.state;
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const httpMethod = 'POST';
    const username = currentUser.username;
    const amount = convertNgnToNgk(form.amount);

    const checksum = generateChecksum(
      `${username}${httpMethod}${amount}${httpMethod}${deviceId}`
    );

    const makePaymentResponse = await this.liquidity.webpayPayment(
      amount,
      checksum,
      deviceId
    );
    const { status, code, response } = makePaymentResponse;
   

    if (status === SUCCESS_STATUS) {
      this.handleCreateHostedFields(response.data);
      this.setState({
        payment: response.data,
        isLoading: false
      });
      return;
    }
    this.props.showErrorMessage(JSON.stringify(response), {
      customerId: username,
      customerIdField: 'Agent Number',
    });
    setTimeout(
      this.props.dismissErrorMessage,
      parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
    );
    this.setState({
      isLoading: false,
    });
  }

  updateFormField(params) {
    let { amount } = params;
    if (amount !== null && amount !== undefined) {
      params.amount = JSON.stringify(amount).replace(/[^0-9]/g, '');
    }

    const newForm = {
      ...this.state.form,
      ...params,
    };

    this.setState({
      form: newForm,
    });
  }

  isAmountValid() {
    const cacRegistrationFee = localStorage.getItem("cac-registration-fee");
    return cacRegistrationFee || this.state.form.amount > 0;
  }

  goBackPrev = () => {
    this.props.history.goBack();
  }

  configuration = {
    fields: {
      cardNumber: {
        selector: '#cardNumber-container',
        placeholder: '****  ****  ****  ****',
        styles: {
          fontSize: '16px',
          padding: '0px 0px 0px 10px',
          backgroundColor: 'rgb(247, 247, 247)',
        },
      },
      expirationDate: {
        selector: '#expirationDate-container',
        placeholder: 'MM / YY',
        styles: {
          fontSize: '16px',
          padding: '0px 0px 0px 10px',
          backgroundColor: 'rgb(247, 247, 247)',
        },
      },
      cvv: {
        selector: '#cvv-container',
        placeholder: '***',
        styles: {
          fontSize: '16px',
          padding: '0px 0px 0px 10px',
          backgroundColor: 'rgb(247, 247, 247)',
        },
      },
      pin: {
        selector: '#pin-container',
        placeholder: '* * * *',
        styles: {
          fontSize: '16px',
          padding: '0px 0px 0px 10px',
          backgroundColor: 'rgb(247, 247, 247)',
          textAlign: 'center',
        },
      },
      otp: {
        selector: '#otp-container',
        placeholder: '* * * * * *',
        styles: {
          fontSize: '16px',
          padding: '0px 0px 0px 10px',
          backgroundColor: 'rgb(247, 247, 247)',
          textAlign: 'center',
        },
      },
    },
    cardinal: {
      containerSelector: '.cardinal-container',
      activeClass: 'show',
    },
    paymentParameters: null,
  };

  createHandler(createError, hostedFieldsInstance) {
    this.setState({
      isLoading: false,
    });

    const paymentContainer = window.document.getElementById('paymentContainer');
    const paymentFormContainer = window.document.getElementById(
      'paymentFormContainer'
    );
    const pinBackButton = window.document.getElementById('pin-back-button');
    const otpBackButton = window.document.getElementById('otp-back-button');

    let showFormErrors = false;

    if (createError != null) {
      var errorName = createError.name;
      var errorMessage = createError.message;

      alert(errorName + '\n' + errorMessage);
      return;
    }

    paymentContainer.style.display = 'none';
    paymentFormContainer.style.display = 'block';

    window.instance = hostedFieldsInstance;

    window.instance.on('focus', function (event) {
      const fieldContainerEl = window.document.querySelector(event.selector);
      fieldContainerEl.style.borderBottomColor = '#a0c8e2';

      if (!showFormErrors) {
        return;
      }

      const validationState = window.instance.getFieldsState();
      checkFieldsValidation(
        ['cardNumber', 'expirationDate', 'cvv', 'pin', 'otp'],
        validationState
      );
    });

    window.instance.on('blur', function (event) {
      let fieldName = event.fieldType;

      const fieldContainerEl = window.document.querySelector(event.selector);
      fieldContainerEl.style.borderBottomColor = '#e4e4e4';

      if (showFormErrors) {
        const validationState = window.instance.getFieldsState();

        if (!validationState[fieldName].valid) {
          fieldContainerEl.style.borderBottomColor = 'red';
        }
      }
    });

    window.instance.on('validation', function (validationState) {
      if (!showFormErrors) {
        return;
      }

      checkFieldsValidation(
        ['cardNumber', 'expirationDate', 'cvv', 'pin', 'otp'],
        validationState
      );
    });

    window.instance.on('cardinal-response', async function (err, response) {
      setActivePage('card-details');

      if (err != null && err.validationError === true) {
        this.props.showErrorMessage(`Payment validation failed`);
        setTimeout(
          this.props.dismissErrorMessage,
          parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
        );
        return;
      }

      if (err != null) {
        this.props && this.props.showErrorMessage(`Something went wrong`);
        setTimeout(
          this.props && this.props.dismissErrorMessage,
          parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
        );
        return;
      }

      if (response.responseCode === '00') {
        const proceedPaymentResponse = await this.liquidity.proceesWebPayment(
          this.state.payment,
          deviceId
        );
        const { status } = proceedPaymentResponse;

        if (status === SUCCESS_STATUS) {
          this.setState({
            isLoading: false,
          });
          window.instance.clearField('card-details');
          window.instance.clearField('pin');
          window.instance.clearField('otp');

          this.props.showSuccessMessage('Transaction successful');
          setTimeout(
            this.props.dismissSuccessMessage,
            parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
          );

          setActivePage('card-details');
          this.goBack();
          return;
        }
      }

      this.props.showErrorMessage(
        `Cardinal payment validation failed. ${processWebpayErrorResponse(
          response.responseCode
        )}`
      );
      setTimeout(
        this.props.dismissErrorMessage,
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );
      return;
    });

    pinBackButton.addEventListener('click', function () {
      setActivePage('card-details');
      window.instance.clearField('pin');
    });

    otpBackButton.addEventListener('click', function () {
      setActivePage('pin');
      window.instance.clearField('otp');
    });
  }

  handleCreateHostedFields = (data) => {
    const paymentParameters = {
      amount: data.amount,
      currencyCode: data.currencyCode,
      merchantCode: data.merchantCode,
      payableCode: data.paymentItemCode,
      merchantCustomerName: data.merchantCustomerName,
      transactionReference: data.transactionRef,
      customerId: data.customerMsisdn,
    };

    let createConfiguration = this.configuration;
    createConfiguration.paymentParameters = paymentParameters;

    window.isw.hostedFields.create(createConfiguration, this.createHandler);
  };

  handlePayment() {
    const showFormErrors = true;

    const validationState = window.instance.getFieldsState();

    const fieldsValid = checkFieldsValidation(
      ['cardNumber', 'expirationDate', 'cvv'],
      validationState
    );

    if (!fieldsValid) {
      return;
    }

    this.setState({
      isLoading: true,
    });
    window.instance.getBinConfiguration(this.handleBinConfigResponse);
  }

  handleContinuePayment() {
    const validationState = window.instance.getFieldsState();

    const fieldsValid = checkFieldsValidation(['pin'], validationState);

    if (!fieldsValid) {
      return;
    }

    this.setState({
      isLoading: true,
    });
    window.instance.makePayment(this.handlePayResponse);
  }

  handleValidatePayment() {
    const validationState = window.instance.getFieldsState();

    const fieldsValid = checkFieldsValidation(['otp'], validationState);

    if (!fieldsValid) {
      return;
    }

    this.setState({
      isLoading: true,
    });
    window.instance.validatePayment(this.handleValidateResponse);
  }

  goBack() {
    this.setState({ activeTab: 'confirmation' });
    this.updateFormField({ amount: '' });
    const paymentContainer = window.document.getElementById('paymentContainer');
    const paymentFormContainer = window.document.getElementById(
      'paymentFormContainer'
    );

    paymentContainer.style.display = 'block';
    paymentFormContainer.style.display = 'none';
    // window.location.href = "agent/dashboard?fund-page=true";
  }

  async handlePayResponse(err, response) {
    if (err != null && err.validationError === true) {
      this.setState({
        isLoading: false,
      });
      this.props.showErrorMessage('Validation Error');
      setTimeout(
        this.props.dismissErrorMessage,
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );
      return;
    }

    if (err != null && err.networkError === true) {
      this.setState({
        isLoading: false,
      });
      this.props.showErrorMessage('Network Error');
      setTimeout(
        this.props.dismissErrorMessage,
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );
      return;
    }

    if (err != null) {
      this.setState({
        isLoading: false,
      });
      this.props.showErrorMessage(
        `Payment failed. ${processWebpayErrorResponse(err.responseCode)}`
      );
      setTimeout(
        this.props.dismissErrorMessage,
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );
      return;
    }

    if (response.responseCode === '00') {
      const proceedPaymentResponse = await this.liquidity.proceesWebPayment(
        this.state.payment,
        deviceId
      );
      const { status } = proceedPaymentResponse;

      if (status === SUCCESS_STATUS) {
        this.setState({
          isLoading: false,
        });
        window.instance.clearField('card-details');
        window.instance.clearField('pin');
        window.instance.clearField('otp');

        this.props.showSuccessMessage('Transaction successful');
        setTimeout(
          this.props.dismissSuccessMessage,
          parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
        );

        setActivePage('card-details');
        this.goBack();
        return;
      }

      this.setState({
        isLoading: false,
      });

      this.props.showErrorMessage(`Payment failed`);
      setTimeout(
        this.props.dismissErrorMessage,
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );
      return;
    }

    if (
      response.responseCode === 'T0' &&
      response.requiresCentinelAuthorization === true
    ) {
      this.setState({
        isLoading: false,
      });
      setActivePage('cardinal');
      return;
    }

    if (response.responseCode === 'T0') {
      this.setState({
        isLoading: false,
      });
      setActivePage('otp');
      return;
    }

    this.setState({
      isLoading: false,
    });
    this.props.showErrorMessage(
      `Payment failed. ${processWebpayErrorResponse(response.responseCode)}`
    );
    setTimeout(
      this.props.dismissErrorMessage,
      parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
    );
    return;
  }

  async handleValidateResponse(err, response) {
    if (err != null && err.validationError === true) {
      this.setState({
        isLoading: false,
      });
      this.props.showErrorMessage(`Validation Error`);
      setTimeout(
        this.props.dismissErrorMessage,
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );
      return;
    }

    if (err != null && err.networkError === true) {
      this.setState({
        isLoading: false,
      });
      this.props.showErrorMessage(`Network Error`);
      setTimeout(
        this.props.dismissErrorMessage,
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );
      return;
    }

    if (err != null) {
      const proceedPaymentResponse = await this.liquidity.proceesWebPayment(
        this.state.payment,
        deviceId
      );
      const { status } = proceedPaymentResponse;
      if (status === SUCCESS_STATUS) {
        this.setState({
          isLoading: false,
        });
        this.props.showErrorMessage(
          `Payment validation failed ${processWebpayErrorResponse(
            err.responseCode
          )}`
        );
        setTimeout(
          this.props.dismissErrorMessage,
          parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
        );
        return;
      }

      this.setState({
        isLoading: false,
      });
      this.props.showErrorMessage(
        `Payment validation failed ${processWebpayErrorResponse(
          err.responseCode
        )}`
      );
      setTimeout(
        this.props.dismissErrorMessage,
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );
      return;
    }

    if (response.responseCode === '00') {
      const proceedPaymentResponse = await this.liquidity.proceesWebPayment(
        this.state.payment,
        deviceId
      );
      const { status } = proceedPaymentResponse;

      if (status === SUCCESS_STATUS) {
        this.setState({
          isLoading: false,
        });
        window.instance.clearField('card-details');
        window.instance.clearField('pin');
        window.instance.clearField('otp');

        this.props.showSuccessMessage('Transaction successful');
        setTimeout(
          this.props.dismissSuccessMessage,
          parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
        );

        setActivePage('card-details');
        this.goBack();
        return;
      }

      this.setState({
        isLoading: false,
      });

      this.props.showErrorMessage(`Payment validation pending`);
      setTimeout(
        this.props.dismissErrorMessage,
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );
      return;
    }

    this.setState({
      isLoading: false,
    });

    this.props.showErrorMessage(`Payment validation failed`);
    setTimeout(
      this.props.dismissErrorMessage,
      parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
    );
    return;
  }

  handleBinConfigResponse(err, response) {
    let instance;
    if (err != null && err.validationError === true) {
      this.setState({
        isLoading: false,
      });
      this.props.showErrorMessage(`Validation Error`);
      setTimeout(
        this.props.dismissErrorMessage,
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );
      return;
    }

    if (err != null && err.networkError === true) {
      this.setState({
        isLoading: false,
      });
      this.props.showErrorMessage(`Network Error`);
      setTimeout(
        this.props.dismissErrorMessage,
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );
      return;
    }

    if (err !== null) {
      this.setState({
        isLoading: false,
      });

      this.props.showErrorMessage(
        `Could not process the request ${processWebpayErrorResponse(
          err.responseCode
        )}`
      );
      setTimeout(
        this.props.dismissErrorMessage,
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );
      return;
    }

    if (response.supportsPin) {
      this.setState({
        isLoading: false,
      });
      setActivePage('pin');
      return;
    }

    // this.setState({
    //   isLoading: false
    // });

    window.instance.makePayment(this.handlePayResponse);
  }

  getCurrentCarouselIndex(tabs) {
    return tabs.indexOf(this.state.activeTab);
  }

  onCancelConfirmation() {
    this.props.onCancel();
  }

  render() {
    const currentAgent = JSON.parse(localStorage.getItem('currentAgent')) || [];
    const cacRegistrationFee = localStorage.getItem("cac-registration-fee");
    const containerStyle = cacRegistrationFee
      ? { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }
      : {};

    const confirmationTab = (
      <>
        {cacRegistrationFee ? <HeaderSignup /> : null}
        <H1
          style={{
            textAlign: 'left',
            marginTop: '0px',
            paddingTop: '0px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <MdArrowBack
            // onClick={() => console.log('hhhhhhhhhhhhhhhhhhhh')}
            onClick={this.goBackPrev}
            fontSize='26px'
            style={{
              cursor: 'pointer',
              color: '#00425F',
              marginRight: '10px',
            }}
          />
          <span>Fund Wallet</span>
        </H1>
        <HR style={{ marginTop: '10px', width: '100%' }} />
        <section className='fund-wallet-section' style={containerStyle}>
          <h2 className='fund-wallet-header-text' >Select Funding Option?</h2>
          <div className={cacRegistrationFee ? '' : 'fundwalletcards'}>
            <article className='fund-wallet-article'>
              <div>
                <div className='fund-wallet-tab'>
                  {' '}
                  <a
                    style={{ textDecoration: 'none', color: 'black' }}
                    target='_blank'
                    rel='noopener noreferrer'
                    href={FUND_WALLET_URL}
                    title='Click to visit Quickteller'
                  >
                    Fund via Quickteller
                  </a>
                </div>
                {SHOW_STATIC_ACCOUNT && (
                  <div
                    onClick={() =>
                      this.setState({
                        isOpen: !this.state.isOpen,
                      })
                    }
                    title={`${currentAgent.staticAccounts
                      ? 'Click to copy account details'
                      : 'Account details not available'
                      }`}
                    className='fund-wallet-tab d-flex'
                  >
                    <div
                      style={{
                        color: currentAgent.staticAccounts ? 'black' : 'grey',
                      }}
                    >
                      Fund via Transfer
                    </div>
                    <Badge
                      style={{
                        backgroundColor: 'red',
                        color: 'white',
                        marginLeft: '10px',

                        height: '15px',
                        fontSize: '10px',
                      }}
                      bg='secondary'
                    >
                      New
                    </Badge>
                  </div>
                )}
                <div
                  onClick={() => this.setState({ activeTab: 'details' })}
                  title='Click to fund via debit card'
                  className='fund-wallet-tab'
                >
                  Fund via Debit Card
                </div>

                {SHOW_FUND_VIA_USSD && (
                  <div className='fund-wallet-tab d-flex'>
                    <div
                      onClick={() => this.setState({ activeTab: 'ussd' })}
                      title='Click to fund via USSD'
                    >
                      Fund via USSD
                    </div>
                    <Badge
                      style={{
                        backgroundColor: 'red',
                        color: 'white',
                        marginLeft: '10px',

                        height: '15px',
                        fontSize: '10px',
                      }}
                      bg='secondary'
                    >
                      New
                    </Badge>
                  </div>
                )}
                {!SHOW_FUND_VIA_USSD && (
                  <div className='fund-wallet-tab d-flex'>
                    <div
                      onClick={() => alert("Coming soon")}
                      title='Click to fund via USSD'
                      style={{
                        color: 'grey'
                      }}
                    >
                      Fund via USSD
                    </div>
                    <Badge
                      style={{
                        backgroundColor: '#bB8000',
                        color: 'white',
                        marginLeft: '10px',

                        height: '15px',
                        fontSize: '10px',
                      }}
                      bg='secondary'
                    >
                      Coming Soon
                    </Badge>
                  </div>
                )}
              </div>
            </article>
            {currentAgent.staticAccounts && (
              <>
                {this.state.isOpen && (
                  <div>
                    {currentAgent?.staticAccounts.map((item, index) => {
                      return (
                        <article
                          key={index}
                          className='fundwallet-copy-container'
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              padding: '15px',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <span style={{ color: '#00B8DE' }}>
                                {item.bankName}
                              </span>
                              <span style={{ fontSize: '18px' }}>
                                {item.accountNumber}
                              </span>
                            </div>

                            <MdCopy
                              fontSize='26px'
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                this.handleCopyAccountNumber(item.agentId)
                              }
                            />
                          </div>
                          <div
                            style={{
                              height: '1px',
                              width: '100%',
                              backgroundColor: '#F4F4F4',
                            }}
                          />
                        </article>
                      );
                    })}
                  </div>
                )}
              </>
            )}
            {this.state.show ? <div style={copybox}>Copied!</div> : ''}
          </div>
        </section>
      </>
    );

    const detailsTab = this.state.displayAnimation ? (
      <Animation />
    ) : (
      <>
        <H1
          style={{
            textAlign: 'left',
            marginTop: '0px',
            paddingTop: '0px',
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={() => this.goBack()}
        >
          <MdArrowBack
            fontSize='26px'
            style={{
              cursor: 'pointer',
              color: '#00425F',
              marginRight: '10px',
            }}
          />
          <span>Fund Wallet</span>
        </H1>
        <HR style={{ marginTop: '10px', width: '100%' }} />
        <div ref={(el) => (this.div = el)} style={{ color: 'black' }}>
          <Row style={{ marginTop: '20px', padding: '0px 10px 10px' }}>
            <Col xs={9} md={11} style={{ marginTop: 0, paddingTop: 0 }}>
              {/* <H1 style={{textAlign: 'left'}}>FUND WALLET</H1> */}
            </Col>
            <Col
              xs={3}
              md={1}
              style={{
                alignItems: 'center',
                display: 'flex',
                margin: 0,
                padding: 0,
              }}
            >
              {/* <Button
                outline='true'
                style={{
                  backgroundColor: 'transparent',
                  borderColor: 'transparent',
                  color: process.env.REACT_APP_RED_COLOUR,
                  fontWeight: 'bold',
                  margin: 0,
                  padding: 0,
                  textTransform: 'none',
                }}
                onClick={() => {
                  this.goBack();
                }}
              >
                Go Back
              </Button> */}
            </Col>
          </Row>
          <div
            id='paymentContainer'
            style={{
              position: 'relative',
              backgroundColor: 'white',
              width: this.state.deviceIsPC && '40%',
              margin: 'auto',
              marginTop: '5%',
              padding: '10px',
              borderRadius: '8px',
              paddingRight: '20px',
            }}
            className='create-payment-container'
          >
            <Form>
              <Row>
                <Col md={12}>
                  <Form.Group as={Col} md='12' controlId='amount'>
                    <FormLabel
                      style={{
                        color: '#3A3B3B',
                        fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                        lineHeight: '19px',
                      }}
                    >
                      Amount:
                    </FormLabel>
                    <InputGroup style={{ borderRadius: 10 }}>
                      <InputGroup.Prepend>
                        <InputGroup.Text
                          id='inputGroupPrepend'
                          style={{
                            borderTopLeftRadius: process.env
                              .REACT_APP_BORDER_STYLE
                              ? '4px'
                              : 20,
                            borderBottomLeftRadius: process.env
                              .REACT_APP_BORDER_STYLE
                              ? '4px'
                              : 20,
                          }}
                        >
                          ₦
                        </InputGroup.Text>
                      </InputGroup.Prepend>

                      <FormControl
                        type='tel'
                        value={this.state.form.amount}
                        onChange={this.handleChange}
                        readOnly={this.state.isReadOnly}
                        style={{
                          backgroundColor: '#FFFFFF',
                          border: `1px solid #DFE2E6`,
                          borderTopRightRadius: 20,
                          borderBottomRightRadius: 20,
                          boxSizing: 'border-box',
                          fontSize: '12px',
                          lineHeight: '17px',
                          padding: '20px',
                        }}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={12} className='proceed-button-col'>
                  <Button
                    id='create-fields-button'
                    disabled={!this.isAmountValid() || this.state.isLoading}
                    className='services-proceed-button'
                    onClick={this.handleMakePayment}
                  >
                    {this.state.isLoading ? 'Loading...' : 'Continue'}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>

          <div
            id='paymentFormContainer'
            className='payment-form-container'
            style={{ display: 'none', width: this.state.deviceIsPC && '40%' }}
          >
            <div className='notification-box'>
              <div className='content' />
            </div>

            <div className='form-header'>
              <img
                src={
                  'https://mufasa.interswitchng.com/p/quickteller-paypoint/Assets/header-logo.svg'
                }
                alt=''
              />

              <div className='info'>
                <div className='title'>Quickteller Paypoint Payment</div>
                <div
                  id='amount-placeholder'
                  className='amount'
                >{`₦${formatAmount(this.state.form.amount)}`}</div>
              </div>
            </div>

            <div className='form-page card-details show'>
              {/* <div class="back-control" id="card-back-button" onClick={() => this.goBack()}>
              <img src={'/assets/media/icons/back-icon.png'} alt=""/>
              <label style={{marginTop: '8px'}}>Back</label>
            </div> */}

              <div style={{ fontSize: '0px', marginTop: '0px' }}>
                <label
                  style={{
                    display: 'inline-block',
                    marginBottom: '6px',
                    fontSize: '14px',
                    lineHeight: '19px',
                  }}
                >
                  Card Number
                </label>
                <div id='cardNumber-container' className='payment-field' />
              </div>

              <div style={{ fontSize: '0px' }}>
                <div
                  style={{
                    display: 'inline-block',
                    width: '48%',
                    marginTop: '35px',
                    fontSize: '0px',
                  }}
                >
                  <label
                    style={{
                      display: 'inline-block',
                      marginBottom: '6px',
                      fontSize: '14px',
                      lineHeight: '19px',
                    }}
                  >
                    EXP
                  </label>
                  <div
                    id='expirationDate-container'
                    className='payment-field'
                  />
                </div>

                <div
                  style={{
                    display: 'inline-block',
                    width: '48%',
                    marginTop: '35px',
                    fontSize: '0px',
                    float: 'right',
                  }}
                >
                  <label
                    style={{
                      display: 'inline-block',
                      marginBottom: '6px',
                      fontSize: '14px',
                      lineHeight: '19px',
                    }}
                  >
                    CVV
                  </label>
                  <div id='cvv-container' className='payment-field' />
                </div>
              </div>

              <div className='button-container' style={{ marginTop: '40px' }}>
                <Button
                  id='pay-button'
                  className='services-proceed-button'
                  disabled={this.state.isLoading}
                  onClick={this.handlePayment}
                >
                  {this.state.isLoading ? 'Loading...' : 'Pay'}
                </Button>
              </div>
            </div>

            <div className='form-page pin' style={{ paddingTop: '0px' }}>
              <div className='back-control' id='pin-back-button'>
                <img src={'/assets/media/icons/back-icon.png'} alt='' />
                <label style={{ marginTop: '8px' }}>Back</label>
              </div>

              <div className='form-text'>Please provide your PIN</div>

              <div style={{ marginTop: '0px', fontSize: '0px' }}>
                <label
                  style={{
                    display: 'inline-block',
                    marginBottom: '6px',
                    fontSize: '13px',
                    color: '#848484',
                    fontWeight: 'bold',
                    letterSpacing: '2px',
                  }}
                />
                <div id='pin-container' className='payment-field' />
              </div>

              <div className='button-container' style={{ marginTop: '40px' }}>
                <Button
                  id='continue-button'
                  className='services-proceed-button'
                  disabled={this.state.isLoading}
                  onClick={this.handleContinuePayment}
                >
                  {this.state.isLoading ? 'Loading...' : 'Continue'}
                </Button>
              </div>
            </div>

            <div className='form-page otp' style={{ paddingTop: '0px' }}>
              <div
                className='back-control'
                id='otp-back-button'
                style={{
                  display: 'inline-block',
                  marginBottom: '0px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  verticalAlign: 'middle',
                  cursor: 'pointer',
                }}
              >
                <img src={'/assets/media/icons/back-icon.png'} alt='' />
                <label style={{ marginTop: '8px' }}>Back</label>
              </div>

              <div className='form-text'>Please input OTP</div>

              <div style={{ marginTop: '0px', fontSize: '0px' }}>
                <label
                  style={{
                    display: 'inline-block',
                    marginBottom: '6px',
                    fontSize: '13px',
                    color: '#848484',
                    fontWeight: 'bold',
                    letterSpacing: '2px',
                  }}
                />
                <div id='otp-container' className='payment-field' />
              </div>

              <div className='button-container' style={{ marginTop: '40px' }}>
                <Button
                  id='validate-button'
                  className='services-proceed-button'
                  disabled={this.state.isLoading}
                  onClick={this.handleValidatePayment}
                >
                  {this.state.isLoading ? 'Loading...' : 'Validate'}
                </Button>
              </div>
            </div>

            <div
              className='form-page cardinal'
              style={{
                position: 'relative',
                backgroundColor: 'white',
                width: '92%',
                margin: 'auto',
                marginTop: '5%',
                padding: '10px',
                borderRadius: '8px',
                paddingRight: '20px',
                marginLeft: '30px',
              }}
            >
              <div
                className='cardinal-container'
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  background: 'white',
                  left: '0',
                  top: '0',
                  zIndex: '10',
                }}
              />
            </div>
          </div>
        </div>
      </>
    );

    const tabs = {
      confirmation: {
        component: confirmationTab,
      },
      details: {
        component: detailsTab,
      },
      ussd: {
        component: (
          <FundViaUSSD
            onCancelConfirm={() => this.onCancelConfirm()}
            goBack={this.goBack}
          />
        ),
      },
    };
    return (
      <>
        <div
          id={this.props.id}
          style={{
            color: process.env.REACT_APP_BLACK_COLOUR,
            marginTop: !this.state.deviceIsMobile ? '20px' : '75pt',
            padding: '10px',
            paddingTop: '0px',
          }}
        >
          {/* <H1
            style={{
              textAlign: 'left',
              marginTop: '0px',
              paddingTop: '0px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <MdArrowBack
              onClick={() => this.onCancelConfirmation()}
              fontSize='26px'
              style={{
                cursor: 'pointer',
                color: '#00425F',
                marginRight: '10px',
              }}
            />
            <span>Fund Walletss</span>
          </H1>
          <HR style={{ marginTop: '10px', width: '100%' }} /> */}

          <Carousel
            activeIndex={this.getCurrentCarouselIndex(Object.keys(tabs))}
            controls={false}
            indicators={false}
            interval={null}
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: 0,
              marginTop: '10px',
              padding: 0,
            }}
          >
            {Object.values(tabs).map((value, idx) => {
              return <Carousel.Item key={idx}>{value.component}</Carousel.Item>;
            })}
          </Carousel>
        </div>
      </>
    );
  }
}

class Animation extends React.Component {
  render() {
    const defaultOptions = {
      autoplay: true,
      loop: false,
      animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    return <Lottie height={400} options={defaultOptions} width={400} />;
  }
}

function mapStateToProps(state) { }

function mapDispatchToProps(dispatch) {
  return {
    dismissErrorMessage: () => dispatch(dismissErrorMessage()),
    showErrorMessage: (message, keywords) =>
      dispatch(showErrorMessage(message, keywords)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FundWalletScene);
