import Requester from '../finch-requester';

const API_BASE_URL = process.env.REACT_APP_API_GATEWAY_API_BASE_URL;

export default class ApiGateway {
  constructor(props) {
    this.apiRequester = props ? props.apiRequester || new Requester({
      apiBaseUrl: API_BASE_URL
    }) : new Requester({
      apiBaseUrl: API_BASE_URL
    });
  }

  doClientAuthentication(clientBasicAuth, args) {
    const payload = {
      grant_type: 'client_credentials',
      scope: 'profile'
    };
  
    var formBody = [];
    for (var property in payload) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(payload[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }

    formBody = formBody.join("&")

    return this.apiRequester.post({
      endpoint: 'passport/oauth/token',
      headers: {
        'Authorization': `Basic ${clientBasicAuth}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: formBody,
      encodeBody: true,
      args
    })
  }

}
