import {
  MANAGE_PROFILE_ACTIVE_TAB,
  NAVIGATE_TO,
  SHOW_TRANSACTION_REPORT,
  UPDATE_CURRENT_PATHNAME,
  UPDATE_SCENE,
} from "../../../constants/action-types/navigation";

const initialState = {
  scene: "dashboard",
  pathName: "",
  history: [],
  pendingUrl: null,
  activeTab: "personal-details",
  isTransactionReportComponent: true,
  showSignupForm: 0,
};

export default function navigationReducer(state = initialState, action) {
  switch (action.type) {
    case NAVIGATE_TO:
      return {
        ...state,
        pendingUrl: action.pendingUrl,
      };

    case UPDATE_CURRENT_PATHNAME:
      return {
        ...state,
        pathName: action.pathName,
        history: [...state.history, action.pathName],
      };

    case UPDATE_SCENE:
      return {
        ...state,
        scene: action.scene,
      };
    case MANAGE_PROFILE_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.activeTab,
      };
    case SHOW_TRANSACTION_REPORT:
      return {
        ...state,
        isTransactionReportComponent: action.payload,
      };
    default:
      return state;
  }
}
