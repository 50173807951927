import React, { useState } from "react";
import BackButton from "../component/backButton";
import Thumbnail from "../component/thumbnail";
import H1 from "../../../../../components/h1";
import { PERSONAL_DOC_SUFFIX, TEN_MB } from "../../../../../constants";
import { notify } from "../../../../../components/extras";

const AuthorizationLetter = ({ application, setScreen }) => {
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState("");
  const [formIsValid, setFormIsValid] = useState(false);
  const [error, setError] = useState("");
  const [showLightboxPassport, setShowLightboxPassport] = useState(false);
  const [fileFormatPassport, setFileFormatPassport] = useState(null);

  const [attachedDocument, setAttachedDocument] = useState("");
  const updateFormField = (params) => {
    const newForm = {
      ...form,
      ...params,
    };

    setForm(newForm);
    setFormIsValid(false);
  };
  const handleSubmit = () => {
    setScreen(3);
  };

  const displayUploadedFile = (name, filename, file) => {
    const newAttachedDocument = {
      name: `${name}${PERSONAL_DOC_SUFFIX}`,
      filename: `${filename}${PERSONAL_DOC_SUFFIX}`,
      file: file,
      url: URL.createObjectURL(file),
    };

    setAttachedDocument(newAttachedDocument);
    setError(false);
    setIsLoading(false);
    updateFormField({ attachedDocument: newAttachedDocument });
  };

  const onFileSelect = (event, filename) => {
    const name = event.target.files[0].name;
    const file = event.target.files[0];

    if (file.size > TEN_MB) {
      setError(true);

      return;
    } else {
      setAttachedDocument(file);
      setError(false);
      displayUploadedFile(name, filename, file);
      return;
    }
  };
  const getDocumentForThumbnail = (attachedDocument) => {
    return attachedDocument;
  };
  const onFileUnselect = () => {
    setAttachedDocument("");
    updateFormField({ attachedDocument: {} });
  };

  return (
    <>
      <BackButton
        onClick={() => {
          localStorage.removeItem("businessDetailsForm");
          setScreen(2);
        }}
      />
      <main className="aggregator-container">
        <H1
          style={{
            textAlign: "left",
            fontWeight: 600,
            marginBottom: "2rem",
          }}
        >
          Authorisation Letter
        </H1>
        <label
          style={{
            fontWeight: "bold",
            color: process.env.REACT_APP_BLUE_COLOUR,
          }}
        >
          State Authorisation Letter
        </label>

        <Thumbnail
          attachedDocument={getDocumentForThumbnail(
            form.attachedDocument,
            "attachment"
          )}
          filename={"attachment"}
          onFileSelect={onFileSelect}
          onFileUnselect={onFileUnselect}
          isLoading={isLoading}
        />
        {error && (
          <span
            style={{
              color: "tomato",
              fontSize: "14px",
            }}
          >
            File size must not exceed 3MB.
          </span>
        )}
        <button
          onClick={handleSubmit}
          disabled={!form.attachedDocument || loading}
          className="signupButton2"
          style={{
            marginTop: "3rem",
            textAlign: "center",
            color: "white",
            backgroundColor: "#00425f",
            width: "100%",
          }}
        >
          {loading ? "Processing..." : "Next"}
        </button>
      </main>
    </>
  );
};

export default AuthorizationLetter;
