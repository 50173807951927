import React from 'react';
import BaseScene from './base-scene';
import Footer from '../components/footer';
import Header from '../components/header';
import { Col, Container, Row } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import H1 from '../components/h1';
import Text from '../components/text';
import Button from '../components/button';

export default class ForgottenPasswordTwo extends BaseScene {
  isAuthRequired = false
  
  state = {

  }

  render() {
    if (this.state.redirectToForgottenPassword) {
      return <Redirect to={`/forgotten-password`} />
    }

    const card = <React.Fragment>
      <H1>CHECK YOUR EMAIL TO PROCEED</H1>

      <center>
        <hr style={{ backgroundColor: '#A2A2A3', border: '1px solid #A2A2A3', margin: '30px 0px 20px 0px', width: '150px' }} />
      </center>

      <center>
       <svg style={{ paddingTop: '10px', paddingBottom: '10px' }} width="120px" height="98px" viewBox="0 0 137 112" version="1.1" xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
          <desc>Created with Lunacy</desc>
          <g id="mailing">
            <g id="Group-3">
              <path d="M123.822 0L63.0003 38.814L2.17834 0C0.844019 1.23518 0 2.99749 0 4.95232L0 81.252C0 83.2164 0.852104 84.986 2.19768 86.2217L63 47.4275L125.482 88L126 88L126 4.95232C126.001 2.99749 125.157 1.23518 123.822 0Z" transform="translate(0 2)" id="Path" fill="#D6CED1" stroke="none" />
              <path d="M4.51197 45L123 45L80.6839 0L40.64 0L0 43.218C1.19382 44.32 2.77598 45 4.51197 45Z" transform="translate(2 45)" id="Path" fill="#C2B8B9" stroke="none" />
              <path d="M4.55506 0.00029448C2.80069 0.00029448 1.2024 0.696739 0 1.82401L56.3502 61.9535C58.9077 64.6822 63.092 64.6822 65.6495 61.9535L122 1.82371C120.798 0.696444 119.199 0 117.445 0L4.55506 0L4.55506 0.00029448Z" transform="translate(2 0)" id="Path" fill="#ECE7EA" stroke="none" />
            </g>
            <path d="M21.5 43C33.3741 43 43 33.3741 43 21.5C43 9.62588 33.3741 0 21.5 0C9.62588 0 0 9.62588 0 21.5C0 33.3741 9.62588 43 21.5 43Z" transform="translate(94 69)" id="Oval" fill="#F14742" stroke="none" />
            <path d="M5.59989 22C4.27416 22 3.20005 20.8636 3.20005 19.4616L3.20005 8.31728C2.35584 8.63199 1.3788 8.43275 0.702874 7.7181C-0.234291 6.72685 -0.234291 5.11954 0.702874 4.12829L3.90265 0.743817C4.5893 0.0175352 5.62161 -0.199739 6.51836 0.193507C7.41537 0.586461 8 1.51227 8 2.53873L8 19.4616C7.99972 20.8633 6.92562 22 5.59989 22Z" transform="translate(110 79)" id="Path" fill="#FFFFFF" stroke="none" />
          </g>
        </svg>
        
        <svg hidden style={{ paddingTop: '10px', paddingBottom: '10px' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 31" width="78px" height="100px">
          <defs>
            <clipPath clipPathUnits="userSpaceOnUse" id="cp1">
              <path d="M-124 -114L356 -114L356 296L-124 296Z" />
            </clipPath>
          </defs>
          <g id="Verify_E-mail" clip-path="url(#cp1)">
            <g id="Group 2">
              <g id="Group">
                <g id="mailing">
                  <g id="Group 3">
                    <path id="Path" style={{ fill: '#d6ced1' }} class="shp0" d="M41.27 1L21 13.94L0.73 1C0.28 1.41 0 2 0 2.65L0 28.08C0 28.74 0.28 29.33 0.73 29.74L21 16.81L41.83 30.33L42 30.33L42 2.65C42 2 41.72 1.41 41.27 1Z" />
                    <path id="Path" style={{ fill: '#c2b8b9' }} class="shp1" d="M2.17 30.33L41.67 30.33L27.56 15.33L14.21 15.33L0.67 29.74C1.06 30.11 1.59 30.33 2.17 30.33Z" />
                    <path id="Path" style={{ fill: '#ece7ea' }} class="shp2" d="M2.18 0.33C1.6 0.33 1.07 0.57 0.67 0.94L19.45 20.98C20.3 21.89 21.7 21.89 22.55 20.98L41.33 0.94C40.93 0.57 40.4 0.33 39.81 0.33L2.18 0.33L2.18 0.33Z" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>

        <Text style={{margin: 0, padding: 0, lineHeight: process.env.REACT_APP_MID_LINE_HEIGHT}}>We just sent an email to the address: <strong>{localStorage.getItem('pendingEmail')}</strong></Text>
        <Text style={{lineHeight: process.env.REACT_APP_MID_LINE_HEIGHT}}>Please check your email and click on the link provided to reset your password.</Text>
      </center>

      <Row style={{ margin: '20px 20px 0px 20px', paddingLeft: '0px', paddingRight: '0px' }}>
        {/* <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ margin: 0, padding: 0 }}>
          <Button style={{ fontWeight: 'normal', width: '100%' }}>RESEND</Button>
        </Col> */}

        <Col xl={2}>
        </Col>

        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ margin: 0, padding: 0 }}>
          <Button outline onClick={() => this.setState({redirectToForgottenPassword: true})} style={{ fontWeight: 'normal', marginTop: `${this.state.deviceIsMobile ? '10px' : '10px'}`, width: '100%' }}>CHANGE EMAIL</Button>
        </Col>
      </Row>
    </React.Fragment>

    const desktopContainer = <Container fluid style={{
      margin: 0,
      marginRight: '165px',
      marginTop: '70px',
      paddingLeft: '0px',
      paddingRight: '0px',
      paddingTop: '90px',
      paddingBottom: '100px'
    }}>
      <Row style={{margin: 0, padding: 0}}>
        <Col xs={12} md={12} lg={6} style={{margin: 0, padding: 0, display: 'flex', justifyContent: this.state.deviceIsPC ? 'flex-start' : 'center',
        marginLeft: this.state.deviceIsPC ? '10%' : '0%'}}>
          <div style={{
            border: '2px', 
            borderRadius: '8px',
            boxShadow: '0 1px 5px 4px rgba(0,0,0,0.12)', 
            // margin: '5px',
            padding: '50px',
            paddingTop: '50px',
            paddingBottom: '35px',
            backgroundColor: 'white',
            width: '450px'}}>
            {card}
          </div>
        </Col>
      </Row>
    </Container>

    const mobileContainer = <Container fluid style={{ padding: 0, margin: 0, marginTop: '90px' }}>
      {card}
    </Container>

    return <div style={{
      backgroundColor: 'white',
      minHeight: '100vh',
      height: '100%',
      width: '100%',
      maxWidth: '1440px',
      overflow: 'hidden',
      display: 'block',
      flexDirection: 'column',
      marginLeft: 'auto',
      marginRight: 'auto',
      position: 'relative',
      paddingBottom: '100px'
    }}>
      <div>
        <img
          hidden={!this.state.deviceIsPC}
          src={'/assets/media/images/StyleGuide_Interswitch_2015_V02.png'}
          style={{position: 'absolute', right: '0px', zIndex: 0}}
          height={'740px'}
          width={'1028px'}
          alt=""
        />
      </div>
      <Header transparent={true} outerDivStyle={{backgroundColor: 'white', maxWidth: '1440px'}} />

      {this.state.deviceIsMobile ? mobileContainer : desktopContainer}

      <Footer />

    </div>
  }
}
