import React, { useState } from "react";
import {
  TrainIcon,
  FlightIcon,
  CarIcon,
  TruckIcon,
  BusIcon,
  FerryIcon,
  RightIcon,
} from "../../../../../../icons";
import { arrowBackIcon } from "../../../../../../icons";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";

const transportData = [
  { icon: BusIcon, label: "Bus", disabled: false },
  //   { icon: FerryIcon, label: "Ferry", disabled: true },
  //   { icon: TrainIcon, label: "Train", disabled: true },
  //   { icon: FlightIcon, label: "Flight", disabled: true },
  //   { icon: CarIcon, label: "Car", disabled: true },
  //   { icon: TruckIcon, label: "Truck", disabled: true },
];

const RouteScene = ({ setScreen, setIsForself }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        textAlign: "left",
        gap: "20px",
        padding: "30px",
      }}
    >
      <button
        onClick={() => setScreen(1)}
        style={{
          display: "flex",
          alignItems: "center",
          color: "#666",
          fontSize: "16px",
          fontWeight: "500",
          marginBottom: "16px",
          background: "none",
          border: "none",
          cursor: "pointer",
          alignSelf: "flex-start",
        }}
      >
        {arrowBackIcon()}{" "}
        <span style={{ marginLeft: "10px", color: "#282626" }}>Back</span>
      </button>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <h1
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            marginBottom: "10px",
            textAlign: "left",
          }}
        >
          How would you like to travel?
        </h1>
      </div>

      <h3
        style={{
          fontSize: "18px",
          color: "#555",
          textAlign: "left",
        }}
      >
        Select a means of transportation
      </h3>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "15px",
          marginTop: "20px",
        }}
      >
        {transportData.map(({ icon: Icon, label, disabled }, index) => (
          <button
            key={index}
            disabled={disabled}
            className="transport-option"
            onClick={() => setShowModal(true)}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                e.preventDefault();
                setShowModal(true);
              }
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "200px",
              height: "130px",
              backgroundColor: "#ffffff",
              borderRadius: "10px",
              cursor: disabled ? "not-allowed" : "pointer",
              transition:
                "transform 0.3s ease-in-out, border 0.3s ease-in-out, background-color 0.3s ease-in-out",
              border: "1px solid #E1E6ED",
              padding: 0,
              textAlign: "center",
              outline: "none",
            }}
            onMouseEnter={(e) => {
              if (!disabled) {
                e.currentTarget.style.transform = "scale(1.05)";
                e.currentTarget.style.borderColor = "#D3D3D3";
              }
            }}
            onMouseLeave={(e) => {
              if (!disabled) {
                e.currentTarget.style.transform = "scale(1)";
                e.currentTarget.style.borderColor = "#E1E6ED";
              }
            }}
            aria-label={disabled ? `${label} (unavailable)` : label}
          >
            <Icon
              style={{
                width: "50px",
                height: "50px",
                marginBottom: "10px",
                opacity: disabled ? 0.5 : 1,
              }}
              aria-hidden="true"
            />
            <span
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                color: disabled ? "#848F9F" : "#00425F",
              }}
            >
              {label}
            </span>
          </button>
        ))}
      </div>

      <Modal
        show={showModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowModal(false)}
        backdrop="static"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        dialogClassName="modal-dialog-centered"
      >
        <div
          style={{
            borderRadius: "10px",
            // width: "300px",
            backgroundColor: "#fff",
          }}
        >
          <div
            style={{
              borderBottom: "1px solid #e1e6ed",
              padding: "16px 20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                color: "#353f50",
                margin: 0,
              }}
            >
              I am booking this trip for
            </h1>

            <div
              style={{ cursor: "pointer" }}
              onClick={() => setShowModal(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </div>
          </div>

          <div style={{ padding: "24px" }}>
            <div
              style={{
                backgroundColor: "#EBF8FE",
                borderRadius: "8px",
                padding: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
                transition: "background 0.3s ease-in-out",
                marginBottom: "12px",
              }}
              onClick={() => {
                setIsForself(true);
                setScreen(3);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  setIsForself(true);
                  setScreen(3);
                }
              }}
              tabIndex={0}
              role="button"
              aria-label="Book trip for myself and others"
              onMouseEnter={(e) =>
                (e.currentTarget.style.backgroundColor = "#D1E7FF")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.backgroundColor = "#EBF8FE")
              }
              onFocus={(e) =>
                (e.currentTarget.style.backgroundColor = "#D1E7FF")
              }
              onBlur={(e) =>
                (e.currentTarget.style.backgroundColor = "#EBF8FE")
              }
            >
              <div>
                <span
                  style={{
                    color: "#00425F",
                    fontSize: "16px",
                    fontWeight: "bold",
                    display: "block",
                  }}
                >
                  Myself
                </span>
                <span style={{ color: "#5F738C", fontSize: "14px" }}>
                  Book a trip for yourself and others
                </span>
              </div>
              <RightIcon aria-hidden="true" />
            </div>

            <div
              style={{
                backgroundColor: "#EBF8FE",
                borderRadius: "8px",
                padding: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
                transition: "background 0.3s ease-in-out",
              }}
              onClick={() => {
                setIsForself(false);
                setScreen(3);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  setIsForself(false);
                  setScreen(3);
                }
              }}
              tabIndex={0}
              role="button"
              aria-label="Book trip for someone else"
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "#D1E7FF";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "#EBF8FE";
              }}
              onFocus={(e) => {
                e.currentTarget.style.backgroundColor = "#D1E7FF";
              }}
              onBlur={(e) => {
                e.currentTarget.style.backgroundColor = "#EBF8FE";
              }}
            >
              <div>
                <span
                  style={{
                    color: "#00425F",
                    fontSize: "16px",
                    fontWeight: "bold",
                    display: "block",
                  }}
                >
                  Someone else
                </span>
                <span style={{ color: "#5F738C", fontSize: "14px" }}>
                  Book a trip for others
                </span>
              </div>
              <RightIcon aria-hidden="true" />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

RouteScene.propTypes = {
  setScreen: PropTypes.func.isRequired,
  setIsForself: PropTypes.func.isRequired,
};
export default RouteScene;
