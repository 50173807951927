import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Select from 'react-select';
import { userManagementServiceClient } from '../../../../../App';
import { firetoast, notify } from '../../../../../components/extras';
import { ERROR_STATUS, SUCCESS_STATUS } from '../../../../../constants/api';
import POSIMAGE from '../../../../../icons/pos-image.png';
import { convertNgkToNgn } from '../../../../../utils/converters/currencies';
import { formatAmount } from '../../../../../utils/formatters';
import { usePosRequestContext } from '../context/pos-Request-context';
import { ResponseModal } from '../remap-pos/response';

const PaymentDetails = ({ setScreen }) => {
  const { value, setValue, requestId, amount, setAmount, setReceiptResponse } =
    usePosRequestContext();
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [agentDetails, setAgentDetails] = useState({});

  useEffect(() => {
    const agentDetails = JSON.parse(localStorage.getItem('currentAgent'));
    setAgentDetails(agentDetails);
  }, []);

  const ptag = { color: '#111827', fontWeight: 600 };
  const spanTag = {
    fontSize: '14px',
    fontWeight: 400,
    width: '52%',
    color: '#6B7280',
  };

  const totalAmountIn =
    (convertNgkToNgn(value.price) + parseInt(requestId.fee)) * parseInt(amount);
  // const totalAmountInFree =
  //   convertNgkToNgn(requestId.posDeploymentFee) * parseInt(amount);

  const handleSubmit = async () => {
    setIsLoading(true);
    const payload = {
      posRequestId: requestId.posRequestId,
    };
    const { status, response } =
      await userManagementServiceClient.submitPosRequest(payload);
    if (status === ERROR_STATUS) {
      firetoast('error', 'Request Failed', `${response.description}`);
      setIsLoading(false);
      handleClose();
      setTimeout(() => {
        setValue('');
        setScreen(1);
        setAmount(1);
      }, 3000);
    } else if (status === SUCCESS_STATUS) {
      setReceiptResponse(response);
      handleClose();
      notify('Your request has been received', 'success');
      setScreen(4);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className='pos-request-label'>POS Model: *</div>{' '}
      <Select
        isDisabled={true}
        defaultValue={value}
        className='select-pos-request-component sm:w-100'
        classNamePrefix='react-select'
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: 'rgb(178, 212, 255)',
          },
        })}
      />
      <section className='pos-request-section-container'>
        <article className='pos-request-article1'>
          <img
            style={{
              width: '100%',
              height: '27rem',
              objectFit: 'cover',
              borderRadius: '10px',
            }}
            src={value.terminalImage ? value.terminalImage : POSIMAGE}
            alt='pos terminal'
          />
        </article>
        <div className='page-two-section'>
          <h2
            style={{
              fontSize: '16px',
              fontWeight: '600',
              marginBottom: '15px',
              marginTop: '20px',
            }}
          >
            Payment Details
          </h2>
          <section className='posRequestSection'>
            <div className='posAgent'>
              <div>
                <span style={spanTag}>Terminal Type</span>
                <p style={ptag}>{value.terminalType}</p>
              </div>
              <div>
                <span style={spanTag}>Terminal Model</span>
                <p style={ptag}>{value.label}</p>
              </div>
            </div>
            <div className='posAgent'>
              <div>
                <span style={spanTag}>Quantity</span>
                <p style={ptag}>{amount}</p>
              </div>
            </div>
          </section>
          <hr />

          <h2
            style={{
              fontSize: '16px',
              fontWeight: '600',
              marginBottom: '15px',
              marginTop: '30px',
            }}
          >
            Pricing
          </h2>

          {requestId.fee === 0 ? (
            ''
          ) : (
            <div className='request-pos-payment-price'>
              <span style={spanTag}>Surcharge</span>
              <p style={ptag}>₦{formatAmount(requestId.fee)}</p>
            </div>
          )}
          {requestId.posDeploymentFee ? (
            <>
              <div className='request-pos-payment-price'>
                <div
                  style={{
                    fontWeight: 400,
                    width: '52%',
                    color: '#6B7280',
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '10px',
                  }}
                >
                  {' '}
                  <span style={{ fontSize: '14px' }}>
                    Pos Deployment Fee
                  </span>{' '}
                  <span
                    style={{
                      color: 'red',
                      fontSize: '11px',
                    }}
                  >
                    N.B: This fee is non-refundable
                  </span>
                </div>
                <p style={ptag}>
                  ₦{formatAmount(convertNgkToNgn(requestId.posDeploymentFee))}
                </p>
              </div>
              {/* <div className='request-pos-payment-price'>
                <span style={spanTag}>Total Cost</span>
                <p style={ptag}>₦{formatAmount(totalAmountInFree)}</p>
              </div> */}
            </>
          ) : (
            <>
              <div className='request-pos-payment-price'>
                <span style={spanTag}>POS Price</span>
                <p style={ptag}>
                  ₦{formatAmount(convertNgkToNgn(value.price))}
                </p>
              </div>
              <div className='request-pos-payment-price'>
                <span style={spanTag}>Total Price</span>
                <p style={ptag}>₦{formatAmount(totalAmountIn)}</p>
              </div>
            </>
          )}

          <div>
            <Button
              className='posButton'
              style={{
                backgroundColor: '#00B8DE',
                width: '200px',
                marginTop: '50px',
                alignItems: 'center',
              }}
              onClick={() => handleShow()}
              disabled={isLoading}
            >
              {'Make Payment'}
            </Button>
          </div>
        </div>
      </section>
      <ResponseModal
        title={'Pos Request'}
        paragraph={'You are about to submit a request for POS'}
        handleClose={handleClose}
        show={show}
        loading={isLoading}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default PaymentDetails;
