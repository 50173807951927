import React from "react";
import { Alert, Col, Container, Row, Button, Form } from "react-bootstrap";
import "react-table/react-table.css";
import qs from "qs";
import { Redirect, Link } from "react-router-dom";
import BaseScene from "../../../../base-scene";
import {
  SUCCESS_STATUS,
  HTTP_NOT_FOUND_CODE,
} from "../../../../../constants/api";
import AccountOpening from "../../../../../services/api/resources/account-opening";
import { ReceiptModal } from "../../../../../components/vertically-centered-modal";
import Text from "../../../../../components/text";
import {
  formatDate,
  handleCustomerIdFormat,
} from "../../../../../utils/formatters";

import Adboard from "../../../../../components/adboard";
import FormControl from "../../../../../components/form-control";
import { ACCOUNT_OPENING } from "../../../../../constants";

export default class AccountOpeningDocumentsScene extends BaseScene {
  location = window.location.href;
  path = "/account-opening-documents";
  title = "KYC Documents Upload";

  accountOpening = new AccountOpening();
  constructor(props) {
    super(props);

    const transactionRef = localStorage.getItem("transactionRef");
    this.state = {
      accountOpeningData: {
        base64DataPhoto: null,
        base64DataSignature: null,
      },
      showReceipt: false,
      checkValidFields: 0,
      isLoading: false,
      isLoadingPhoto: false,
      isLoadingPhotoMsg: null,
      isLoadingSignatureMsg: null,
      isLoadingSignature: false,
      transactionRef,
      fieldsFocused: [],
      successMessage: null,
      errorMessage: null,
      goBack: false,
      deviceIsMobile:
        qs.parse(window.location.search)["?mobile"] || window.innerWidth <= 576,
      deviceIsPC:
        !qs.parse(window.location.search)["?mobile"] &&
        window.innerWidth >= 992,
      deviceIsTab:
        qs.parse(window.location.search)["?mobile"] || window.innerWidth <= 992,
    };
    this.uploadImages = this.uploadImages.bind(this);
    this.submitAccountOpeningForm = this.submitAccountOpeningForm.bind(this);
  }

  handleGetAgentsEmptyResponse = (code) => {
    this.setState({
      agents: [],
      isAgentLoading: false,
    });
    if (code === HTTP_NOT_FOUND_CODE) {
      this.setState({
        didErrorOccurWhileGettingAgents: false,
      });
    } else {
      this.setState({
        didErrorOccurWhileGettingAgents: true,
      });
    }
  };

  async uploadImages() {
    this.setState({
      isLoadingPhoto: true,
      successMessage: "Uploading Photo and Signature",
      isLoadingPhotoMsg: " Uploading...",
    });

    const kycDocumentData = { ...this.state.accountOpeningData };
    const ref = this.state.transactionRef;

    const { status, response, code } = await this.accountOpening.uploadImage({
      base64Data: kycDocumentData.base64DataPhoto,
      ref: ref,
      type: "photo",
    });

    const cardLinkingResponse = response;

    if (status === SUCCESS_STATUS) {
      this.setState({
        isLoadingPhoto: false,
        isLoadingSignature: true,
        successMessage: "Photo uploaded! Uploading signature..",
        isLoadingPhotoMsg: " Done",
        isLoadingSignatureMsg: " Uploading...",
      });
      const { status, response, code } = await this.accountOpening.uploadImage({
        base64Data: kycDocumentData.base64DataSignature,
        ref: ref,
        type: "signature",
      });

      const cardLinkingResponse = response;

      if (status === SUCCESS_STATUS) {
        this.setState({
          isLoadingSignature: false,
          successMessage: "Successfully uploaded signature. Creating account..",
          isLoadingSignatureMsg: " Done",
        });
        this.submitAccountOpeningForm(ref);
      } else {
        this.setState({ isLoadingPhotoMsg: null });
        this.handleEmptyResponse(cardLinkingResponse.description);
      }
    } else {
      this.setState({ isLoadingSignatureMsg: null });
      this.handleEmptyResponse(cardLinkingResponse.description);
    }
  }

  async submitAccountOpeningForm(ref) {
    const { status, response, code } =
      await this.accountOpening.submitAccountOpeningForm(ref);

    const createFormResponse = response.data;
    if (status === SUCCESS_STATUS) {
      localStorage.setItem("accountNumber", createFormResponse.accountNumber);
      this.setState({
        accountOpeningData: response,
        isLoading: false,
        didErrorOccurWhileFetchingAllBanks: false,
        redirectToCompleteCardLink: true,
        showReceipt: true,
        transactionDetails: {
          accountNumber: createFormResponse.accountNumber,
          accountName: localStorage.getItem("accountName"),
          transactionType: ACCOUNT_OPENING,
          transactionRef: ref,
        },
        showReceiptLogo: true,
      });
    } else {
      this.handleEmptyResponse(
        "Failed to create account due to (" + response.description + ")"
      );
    }
  }

  handleEmptyResponse = (description) => {
    this.setState({
      pages: 1,
      isLoading: false,
      errorMessage: description,
      successMessage: null,
    });
  };

  handleChangeImagePhoto = (evt) => {
    let self = this;
    let reader = new FileReader();
    let file = evt.target.files[0];
    let checkValidFields = self.state.checkValidFields;
    checkValidFields += 1;

    reader.onload = function (upload) {
      self.setState({
        accountOpeningData: {
          ...self.state.accountOpeningData,
          base64DataPhoto: upload.target.result,
        },
        checkValidFields,
      });
    };
    reader.readAsDataURL(file);
  };

  handleChangeImageSignature = (evt) => {
    let self = this;
    let reader = new FileReader();
    let file = evt.target.files[0];
    let checkValidFields = self.state.checkValidFields;
    checkValidFields += 1;

    reader.onload = function (upload) {
      self.setState({
        accountOpeningData: {
          ...self.state.accountOpeningData,
          base64DataSignature: upload.target.result,
        },
        checkValidFields,
      });
    };
    reader.readAsDataURL(file);
  };

  render() {
    if (this.state.goBack) {
      return <Redirect to="/agent/my-services/create-account-form" />;
    }

    const handleClose = () => {
      this.setState({
        showModal: false,
        showReceipt: false,
      });
    };

    const getReceiptHeader = () => {
      return (
        <div>
          <img
            alt=""
            style={{
              top: "10px",
              left: "-10px",
              opacity: 0.1,
              position: "absolute",
              width: "240px",
            }}
            src={"https://i.ibb.co/Qbsjn5b/isw.png"}
          />

          <img
            alt=""
            style={{
              top: "100px",
              left: "250px",
              opacity: 0.1,
              position: "absolute",
              width: "240px",
            }}
            src={"https://i.ibb.co/Qbsjn5b/isw.png"}
          />

          <img
            alt=""
            style={{
              top: "200px",
              left: "140px",
              opacity: 0.1,
              position: "absolute",
              width: "240px",
            }}
            src={"https://i.ibb.co/Qbsjn5b/isw.png"}
          />

          <img
            alt=""
            style={{
              top: "40px",
              left: "500px",
              width: "100px",
              float: "right",
            }}
            src="https://i.ibb.co/Qbsjn5b/isw.png"
          />
          <p
            style={{
              color: process.env.REACT_APP_BLUE_COLOUR,
              paddingTop: "5px",
            }}
          >
            <h4>
              <b>Thank you for using Quickteller Paypoint!</b>
            </h4>
            <span>See account opening details below.</span>
          </p>
        </div>
      );
    };

    const TransactionModalBody = (props) => {
      return (
        <>
          {props.details.transactionType === ACCOUNT_OPENING && (
            <div id="print-receipt">
              {props.showReceiptLogo && getReceiptHeader()}

              <hr />

              <Row style={{ marginTop: "15px" }}>
                <Col
                  md={12}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Text
                    style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
                  >
                    Account Number:{" "}
                  </Text>
                  <Text
                    style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
                  >
                    {props.details.accountNumber}
                  </Text>
                </Col>
              </Row>

              <Row style={{ marginTop: "15px" }}>
                <Col
                  md={12}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Text
                    style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
                  >
                    Transaction Type:{" "}
                  </Text>
                  <Text
                    style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
                  >
                    Account Opening
                  </Text>
                </Col>
              </Row>

              <Row style={{ marginTop: "15px" }}>
                <Col
                  md={12}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Text
                    style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
                  >
                    Transaction Date:{" "}
                  </Text>
                  <Text
                    style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
                  >
                    {formatDate(new Date())}
                  </Text>
                </Col>
              </Row>

              <hr />
            </div>
          )}
        </>
      );
    };
    return (
      <>
        {this.state.showReceipt && (
          <ReceiptModal
            onHide={handleClose}
            show={this.state.showReceipt}
            title="Transaction Receipt"
            body={
              <TransactionModalBody
                showFee={false}
                showReceiptLogo={true}
                details={this.state.transactionDetails}
              />
            }
          />
        )}
        {!this.state.showReceipt && (
          <div
            className={
              this.state.deviceIsMobile
                ? "container container-fluid reports-scene-container-mobile"
                : "reports-scene-container"
            }
          >
            <Row>
              <Col xs={9} md={9} xl={9}>
                <div className="h1 reports-scene-header">
                  Upload KYC Documents
                </div>
                <Container
                  fluid
                  className="regular-container"
                  style={{ padding: "10px" }}
                >
                  <Row id="reports-scene-row">
                    <Col
                      xs={12}
                      md={12}
                      xl={12}
                      id="services-report-container"
                      className="card-with-shadow"
                    >
                      <Col
                        md={12}
                        className="card-with-shadow"
                        id="transactions-table-container"
                      >
                        <Form.Group controlId="photo">
                          {this.state.errorMessage && (
                            <Alert variant="danger">
                              {this.state.errorMessage}
                            </Alert>
                          )}
                          {this.state.successMessage && (
                            <Alert variant="success">
                              {this.state.successMessage}
                            </Alert>
                          )}
                          <Form.Label
                            style={{
                              color: "#3A3B3B",
                              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                              lineHeight: "19px",
                            }}
                          >
                            {this.state.accountOpeningData.base64DataPhoto ? (
                              <img
                                src={`${this.state.accountOpeningData.base64DataPhoto}`}
                                alt=""
                                style={{
                                  height: "50px",
                                  borderRadius: "2px",
                                  paddingRight: "2px",
                                }}
                              />
                            ) : (
                              ""
                            )}
                            Customer's Passport:{" "}
                            <span className="required-field-tick">*</span>
                            {this.state.isLoadingPhotoMsg && (
                              <Alert variant="info">
                                {this.state.isLoadingPhotoMsg}
                              </Alert>
                            )}
                          </Form.Label>
                          <Col
                            sm={12}
                            style={{ margin: 0, padding: 0, marginTop: "10px" }}
                          >
                            <FormControl
                              type="file"
                              onBlur={() =>
                                this.setState({
                                  fieldsFocused: [
                                    ...this.state.fieldsFocused,
                                    "photo",
                                  ],
                                })
                              }
                              onChange={this.handleChangeImagePhoto}
                              encType="multipart/form-data"
                              style={{
                                backgroundColor: "#FFFFFF",
                                borderRadius: "20px",
                                border: `1px solid '#DFE2E6'`,
                                boxSizing: "border-box",
                                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                                lineHeight: "17px",
                              }}
                            />
                          </Col>
                        </Form.Group>

                        <Form.Group controlId="signature">
                          <Form.Label
                            style={{
                              color: "#3A3B3B",
                              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                              lineHeight: "19px",
                            }}
                          >
                            {this.state.accountOpeningData
                              .base64DataSignature ? (
                              <img
                                src={`${this.state.accountOpeningData.base64DataSignature}`}
                                alt=""
                                style={{
                                  height: "50px",
                                  borderRadius: "2px",
                                  paddingRight: "2px",
                                }}
                              />
                            ) : (
                              ""
                            )}
                            Customer's Signature:{" "}
                            <span className="required-field-tick">*</span>
                            {this.state.isLoadingSignatureMsg && (
                              <Alert variant="info">
                                {this.state.isLoadingSignatureMsg}
                              </Alert>
                            )}
                          </Form.Label>
                          <FormControl
                            type="file"
                            onBlur={() =>
                              this.setState({
                                fieldsFocused: [
                                  ...this.state.fieldsFocused,
                                  "signature",
                                ],
                              })
                            }
                            onChange={this.handleChangeImageSignature}
                            encType="multipart/form-data"
                            style={{
                              backgroundColor: "#FFFFFF",
                              borderRadius: "20px",
                              border: `1px solid '#DFE2E6'`,
                              boxSizing: "border-box",
                              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                              lineHeight: "17px",
                            }}
                          />
                        </Form.Group>

                        <center
                          style={{ marginTop: "10px", marginBottom: "10px" }}
                        >
                          <Button
                            outline
                            onClick={() => {
                              this.setState({ goBack: true });
                            }}
                            className="continue-later-pc-button-right"
                            style={{
                              marginRight: "100px",
                              backgroundColor: "#9999aa",
                            }}
                          >
                            Back
                          </Button>
                          <Button
                            className="continue-later-pc-button-right"
                            onClick={this.uploadImages}
                            disabled={
                              this.state.checkValidFields < 2 ||
                              this.state.isLoading
                            }
                          >
                            {this.state.isLoading ? "LOADING..." : "SUBMIT"}
                          </Button>
                        </center>
                        <br />
                        <br />
                      </Col>
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Adboard />
            </Row>
          </div>
        )}
      </>
    );
  }
}
