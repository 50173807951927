import phoneValidators from './phone-validators';
import { PHONE_NUMBER } from "../../constants/api";

export function isDeviceNameValid (form) {
  return form.deviceName && form.deviceName.length >= 3;
}

export function isEmailValid (email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const isNumberValid = (amount) => {
  var re = /^[0-9]+$/
  return re.test(amount)
}

export function isFirstNameValid (firstName) {
  return firstName && firstName.length > 2
}

export function isLastNameValid (lastName) {
  return lastName && lastName.length > 2
}

export function isOtpValid (newForm) {
  const form = newForm;
  return form.otp && form.otp.length === parseInt(process.env.REACT_APP_OTP_LENGTH);
}

export function isPasswordValid (password) {
  return password && String(password).length >= 4;
}

export function isPhoneValid (phone, phonePrefix, faultyFields) {
  const countryValidator = phoneValidators[phonePrefix]

  if (faultyFields) {
    return !faultyFields.includes(PHONE_NUMBER) && phone && phone.length > 0 && countryValidator ? countryValidator(phone) : false  
  }

  return phone && phone.length > 0 && countryValidator ? countryValidator(phone) : false
}

export function sanitizePhone (phone) {
  return `234${phone.slice(-10)}`;
}