/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";
import IosRefresh from "react-ionicons/lib/IosRefresh";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { firetoast, notify } from "../../../../components/extras";
import H1 from "../../../../components/h1";
import HeaderSignup from "../../../../components/header-signup";
import VerticallyCenteredModal from "../../../../components/vertically-centered-modal";
import VerticallyCenteredModalTerms from "../../../../components/vertically-centered-modal-terms";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../constants/api";
import { InfoIcon2 } from "../../../../icons";
import Onboarding from "../../../../services/api/resources/onboarding";
import UserManagement from "../../../../services/api/resources/user-management";
import { formatPhoneNumber } from "../../../../utils/formatters";
import PrivacyPolicy from "../../../privacy-policy";
import TermsAndConditions from "../../../terms-and-conditions";
import { isBvnValid, stripNonNumbers } from "../component/extras";
import InputText from "../component/input";
import Stepper from "../component/stepper";

const BvnValidationScene = ({ setScreen, application }) => {
  const history = useHistory();
  const onboarding = new Onboarding();
  const userManagement = new UserManagement();
  const [showModal, setShowModal] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [isLoadingOtp, setIsLoadingOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [isOtpIncorrect, setIsOtpIncorrect] = useState(false);
  const [message, setMessage] = useState("");
  const [tokenPrefix, setTokenPrefix] = useState("");
  const [bvnDetailsForm, setBvnDetailsForm] = useState({
    bvn: application ? application?.applicantDetails?.bvnDetails?.bvn : "",
  });

  const otpInputsRef = useRef([]);

  const confirmBVN = async () => {
    setIsLoading(true);

    if (bvnDetailsForm.bvn) {
      const { status, response } = await onboarding.bvnValidate(
        bvnDetailsForm.bvn
      );
      setIsLoading(false);

      if (status === ERROR_STATUS) {
        toast.error(`${response.description}`);
      } else if (status === SUCCESS_STATUS) {
        if (response?.code === "20000") {
          const apiMessage =
            response?.kycData?.bvnVerificationResponse?.message;
          const tokenPrefixFromApi =
            response?.kycData?.bvnVerificationResponse?.tokenPrefix;
          const tokenId = response?.kycData?.bvnVerificationResponse?.tokenId;
          localStorage.setItem("tokenId", JSON.stringify(tokenId));
          localStorage.setItem("prefix", JSON.stringify(tokenPrefixFromApi));
          setMessage(
            apiMessage || "An OTP was sent to your registered BVN number."
          );
          setTokenPrefix(tokenPrefixFromApi || "");

          localStorage.setItem("bvnInformation", JSON.stringify(response));
          setShowOtpModal(true);
        } else {
          toast.error("Unexpected response code");
        }
      }
    }
  };

  const handleOtpChange = (e, index) => {
    const { value } = e.target;

    if (/^[0-9]$/.test(value)) {
      let newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < 5) {
        otpInputsRef.current[index + 1].focus();
      } else {
        otpInputsRef.current[index].blur();
      }
    } else {
      e.target.value = "";
    }
  };

  useEffect(() => {
    if (otp.join("").length === 6) {
      debouncedHandleOtpSubmit();
    }
  }, [otp]);

  useEffect(() => {
    const updatedBvnDetailsForm = JSON.parse(
      localStorage.getItem("bvnDetailsForm")
    );

    if (updatedBvnDetailsForm) {
      setBvnDetailsForm({
        bvn:
          application?.applicantDetails?.bvnDetails?.bvn ||
          updatedBvnDetailsForm.bvn ||
          "",
      });
    }
  }, [application]);

  useEffect(() => {
    localStorage.setItem("bvnDetailsForm", JSON.stringify(bvnDetailsForm));
  }, [bvnDetailsForm]);

  const updateFormField = (params) => {
    const newForm = {
      ...bvnDetailsForm,
      ...params,
    };

    setBvnDetailsForm(newForm);
  };

  function debounce(fn, delay) {
    let timeoutId;
    return function (...args) {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => fn.apply(this, args), delay);
    };
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      setShowOtpModal(true);
    }, 1000);
  };


  const isFormValid = () => {
    return bvnDetailsForm.bvn && isBvnValid(bvnDetailsForm);
  };

  const handleClose = () => setShowOtpModal(false);

  const callResendOtp = async () => {
    setIsLoading(true);
    setIsLoadingOtp(true);

    const responseObj = await await onboarding.bvnValidate(
      bvnDetailsForm.bvn
    );
    const { status, response } = responseObj;
    const newResponse = response.toString().replace(/[, ]+/g, "").trim();
    setIsLoading(false);
    if (status === ERROR_STATUS) {
      firetoast("error", `${newResponse.description}`, "");
      setIsOtpIncorrect(true);
      setIsLoadingOtp(false);
    } else if (status === SUCCESS_STATUS) {
      notify("OTP successfully sent!", "success");
      setIsOtpIncorrect(false);
      setIsLoadingOtp(false);
    }
  };

  const showFaceIdModalWithDelay = () => {
    setTimeout(() => {
      setShowModal(true);
    }, 5000);
  };

  const handleOtpSubmit = async () => {
    setLoading(true);
    const otpString = otp.join("");
    const tokenId = JSON.parse(localStorage.getItem("tokenId"));
    const tokenPrefix = JSON.parse(localStorage.getItem("prefix"));

    const payload = {
      identificationNumber: bvnDetailsForm.bvn,
      otp: otpString,
      otpValidateModel: {
        prefix: tokenPrefix,
        tokenId: tokenId,
      },
      prefix: tokenPrefix,
    };

    try {
      const responseObj = await onboarding.otpValidate(payload);
      const { status, response } = responseObj;
      localStorage.setItem("agentOnboardingInfo", JSON.stringify(response));

      setLoading(false);

      if (status === ERROR_STATUS) {
        setIsOtpIncorrect(true);
      } else if (status === SUCCESS_STATUS) {
        setIsOtpIncorrect(false);
        setScreen(7);
      }
    } catch (error) {
      setLoading(false);
      notify("Something went wrong, please try again", "error");
    }
  };

  const debouncedHandleOtpSubmit = useCallback(
    debounce(handleOtpSubmit, 300),
    [otp]
  );

  const checkAuthentication = () => {
    const isAuthenticated = localStorage.getItem("auth_token") === null;

    if (!isAuthenticated) {
      history.push("/login");
      localStorage.clear();
    }
  };

  useEffect(() => {
    // checkAuthentication();
  }, []);

  return (
    <>
      <HeaderSignup />
      <main className="onboarding-container">
        <Col style={{ marginBottom: "20px", margin: "0 auto" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              gap: "10px",
              marginBottom: "30px",
            }}
          >
            <Stepper borderColor="#00425F" />
            <Stepper />
            <Stepper />
            <Stepper />
          </div>

          <H1
            style={{
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "1rem",
              fontSize: "2rem",
              color: "#1F2937",
            }}
          >
            Enter your BVN
          </H1>
          <p style={{ marginBottom: "20px", color: "#848F9F" }}>
            Dial *565*0# to securely get your BVN from your network provider.
          </p>

          <form onSubmit={handleSubmit}>
            <InputText
              style={{ backgroundColor: "#F3F5F6" }}
              label={"BVN"}
              labelColor={
                !bvnDetailsForm.bvn || isBvnValid(bvnDetailsForm)
                  ? "#353F50"
                  : process.env.REACT_APP_RED_COLOUR
              }
              border={`1px solid ${!bvnDetailsForm.bvn || isBvnValid(bvnDetailsForm)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
                }`}
              placeholder={"Enter BVN"}
              onChange={(e) => {
                updateFormField({
                  bvn: stripNonNumbers(e.target.value.trim()),
                });
              }}
              maxLength={11}
              value={bvnDetailsForm.bvn}
            />

            <p style={{ marginBottom: "20px", color: "#848F9F" }}>
              By signing up, you agree to our{" "}
              <a
                onClick={() => setShowTermsAndConditions(true)}
                target="_blank"
                style={{ color: "rgba(0, 184, 222, 1)", cursor: "pointer" }}
              >
                Terms of Use{" "}
              </a>
              and
              <a
                onClick={() => setShowPrivacyPolicy(true)}
                target="_blank"
                style={{ color: "rgba(0, 184, 222, 1)", cursor: "pointer" }}
              >
                {" Privacy Policy. "}
              </a>
            </p>

            <button
              disabled={!isFormValid() || isLoading}
              onClick={confirmBVN}
              className="signupButton2"
              style={{
                marginTop: "1rem",
                textAlign: "center",
                color: "white",
                backgroundColor: "#00425f",
                width: "100%",
              }}
            >
              {isLoading ? "Processing..." : "Next"}
            </button>

            <p
              style={{
                marginBottom: "10px",
                textAlign: "center",
                paddingTop: "20px",
                color: "#848F9F",
              }}
            >
              Already have an account?{" "}
              <a href="/login" target="_blank">
                {localStorage.getItem("currentApplication") ? (
                  <Link style={{ color: "rgba(0, 184, 222, 1)" }} to="/logout">
                    Logout{" "}
                  </Link>
                ) : (
                  <Link style={{ color: "rgba(0, 184, 222, 1)" }} to="/login">
                    Log In
                  </Link>
                )}
              </a>
            </p>
          </form>
        </Col>
      </main>
      <VerticallyCenteredModalTerms
        onHide={() => setShowTermsAndConditions(false)}
        title="Quickteller Paypoint Terms and Conditions"
        body={<TermsAndConditions />}
        show={showTermsAndConditions}
      />

      <VerticallyCenteredModal
        onHide={() => setShowPrivacyPolicy(false)}
        title="Privacy Policy"
        body={<PrivacyPolicy />}
        show={showPrivacyPolicy}
      />

      <Modal
        closeButton
        show={showOtpModal}
        aria-labelledby="contained-modal-title-vcenter"
        style={{
          backdropFilter: "blur(8px)",
          backgroundColor: "rgba(16, 24, 40, 0.3)",
          paddingTop: "6rem",
        }}
        dialogClassName="custom-modal"
        onHide={handleClose}
      >
        <Modal.Body style={{ padding: "3rem" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <form
              className="otp-container"
              style={{ maxWidth: "500px", width: "100%" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {" "}
                <span
                  style={{
                    fontSize: "24px",
                    fontWeight: 700,
                    marginTop: "12px",
                  }}
                >
                  Enter OTP
                </span>
                <Modal.Header closeButton></Modal.Header>
              </div>
              <p style={{ fontSize: "12px", color: "#5F738C" }}>{message}</p>

              <div
                style={{
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: "14px",
                    marginBottom: "2px",
                    width: "100%",
                  }}
                >
                  <span
                    style={{
                      fontSize: "20px",
                      fontWeight: 700,
                      color: "black",
                    }}
                  >
                    {tokenPrefix} -
                  </span>
                  {[...Array(6)].map((_, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength="1"
                      style={{
                        width: "50px",
                        height: "50px",
                        textAlign: "center",
                        fontSize: "20px",
                        borderRadius: "8px",
                        border: `1px solid ${isOtpIncorrect ? "#DC4437" : "#ccc"
                          }`,
                        backgroundColor: "#e0e0e0",
                        flex: "1",
                        minWidth: "40px",
                      }}
                      onChange={(e) => handleOtpChange(e, index)}
                      onFocus={(e) => e.target.select()}
                      ref={(el) => (otpInputsRef.current[index] = el)}
                    />
                  ))}
                  {loading && (
                    <IosRefresh fontSize="24px" color="grey" rotate={true} />
                  )}
                </div>

                <div style={{ textAlign: "left", marginTop: "1px" }}>
                  {isLoadingOtp ? (
                    <IosRefresh fontSize="24px" color="grey" rotate={true} />
                  ) : (
                    <span
                      style={{
                        cursor: "pointer",
                        color: "#0047ab",
                        fontSize: "12px",
                      }}
                      className="otp-resend"
                      onClick={callResendOtp}
                    >
                      Resend OTP
                    </span>
                  )}
                </div>

                <div style={{ textAlign: "left", marginTop: "10px" }}>
                  {isOtpIncorrect && (
                    <>
                      <InfoIcon2 fontSize="24px" color="#DC4437" />
                      <span
                        style={{
                          marginLeft: "10px",
                          color: "#DC4437",
                        }}
                        className="otp-error"
                      >
                        OTP is incorrect
                      </span>
                    </>
                  )}
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ onboarding, application }) => ({
  onboarding,
  application,
});

const mapDispatchToProps = (dispatch) => ({
  // setScreen: (screen) => dispatch(navigateTo(screen)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BvnValidationScene);
