import { toast } from "react-toastify";
import Swal from "sweetalert2";
import React from 'react';

export const notify = (message, state, position = "top-right") => {
  if (state === "warning") {
    toast.warning(message, {
      position: position,
    });
  } else if (state === "success") {
    toast.success(message, {
      position: position,
    });
  } else if (state === "error") {
    toast.error(message, {
      position: position,
    });
  }
};

export const firetoast = (status, title, desc) => {
  return Swal.fire({
    icon: status,
    title: title,
    html: `<div class="swal-desc">${desc}</div>`,
    showConfirmButton: false,
    timer: 6000,
  });
};
export const firetoastAggregator = (
  status,
  title,
  desc,
  confirmButtonText,
  onClick
) => {
  return Swal.fire({
    icon: status,
    title: title,
    html: `<div class="swal-desc">${desc}</div>`,
    // preConfirm: onClick,
    allowOutsideClick: false,

    confirmButtonText: confirmButtonText,
  }).then(() => onClick());
};
export const firetoastCustomButton = (
  status,
  title,
  desc,
  confirmButtonText,
  onClick
) => {
  return Swal.fire({
    icon: status,
    title: title,
    html: `<div class="swal-desc">${desc}</div>`,
    buttonsStyling: false,
    customClass: {
      confirmButton: "custom-confirm-button-class",
    },

    allowOutsideClick: false,
    confirmButtonText: confirmButtonText,
  });
};
export const firetoastDynamic = (
  status,
  title,
  desc,
  confirmButtonText = "Close",
  onClick
) => {
  return Swal.fire({
    icon: status,
    title: title,
    html: `<div class="swal-desc">${desc}</div>`,
    confirmButtonText: confirmButtonText,
    showCancelButton: status === "error",
  }).then((e) => onClick(e));
};
export const ErrorBanner = ({ queriedFields }) => {
  return (
    <div style={{ display: 'flex', borderRadius: '5px', overflow: 'hidden' }}>
      <div style={{ width: '1%', backgroundColor: '#EC9B40' }}></div>

      <div style={{ width: '99%', backgroundColor: '#FEF6CF', padding: '12px' }}>
        <p style={{ fontSize: '1.1rem', fontWeight: '600', color: '#6B2B0D', paddingBottom: '0', marginBottom: '0' }}>{queriedFields.length} errors occurred</p>
        <ul style={{ listStyleType: 'disc', paddingInlineStart: '20px', color: '#6B2B0D', paddingBottom: '0', marginBottom: '0', marginLeft: '5px' }}>
          {queriedFields.map((field, index) => (
            <li key={index}>{field.comment}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

