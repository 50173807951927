import Requester from "./requester";
import { isSessionActive } from "../../utils/auth";
import { getCurrentTime } from "../../utils/time";
import { deleteData, loadData, saveData } from "../../utils/storage";


export default class FinchRequester extends Requester {
  _handle401Error() {
    if (isSessionActive() && JSON.parse(process.env.REACT_APP_FORCE_LOGOUT_ON_401_RESPONSE)) {
      window.location.href = '/logout?case=APP_SESSION_EXPIRED'
    }
  }

  
  _cacheKey(url, args) {
    const urlEncoded = new URLSearchParams(args);
    return `cache__${url}?${urlEncoded.toString()}`;
  }

  _extractResponseDataFromCachedData(data) {
    return data;
  }

  _fetchDataFromCache(url, args) {
    const cacheKey = this._cacheKey(url, args);
    const cachedData = loadData(cacheKey);
    if (!cachedData) {
      return null;
    }

    const cachedDataJson = JSON.parse(cachedData);
    if (this._hasCachedDataExpired(cachedDataJson)) {
      deleteData(cacheKey);
      return null;
    }

    return this._extractResponseDataFromCachedData(cachedDataJson);
  }

  _hasCachedDataExpired({expiry}) {
    return getCurrentTime() >= expiry;
  }

  _packageResponseDataAsCachedData(data) {
    return JSON.stringify({
      ...data,
      expiry: new Date(new Date().setHours(24, 0, 0, 0)).valueOf(),
    });
  }

  _saveDataToCache(url, args, data) {
    const cacheKey = this._cacheKey(url, args);
    saveData(cacheKey, this._packageResponseDataAsCachedData(data));
  }
}
