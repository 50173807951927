import React from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import IosRefresh from "react-ionicons/lib/IosRefresh";
import MdCloseCircle from "react-ionicons/lib/MdCloseCircle";
import { UploadIcon } from "../../../../../icons";

const DOC_FORMATS = ["pdf"];
const IMAGE_FORMATS = ["png", "jpg", "jpeg", "bimp"];

export default class Thumbnail extends React.Component {
  hasAttachment = false;

  constructor() {
    super();

    this.state = {
      deleted: false,
      showLightbox: false,
    };

    this.onClick = this.onClick.bind(this);
    this.removeAttachment = this.removeAttachment.bind(this);
  }

  get src() {
    if (!this.props.attachedDocument) {
      return null;
    }

    return this.props.attachedDocument.url
      ? this.props.attachedDocument.url
      : this.getDocumentUrl(this.props.attachedDocument.documentName);
  }

  getDocumentUrl(documentName) {
    return `${process.env.REACT_APP_CDN_BASE_URL}/p/finch/onboarding/${documentName}`;
  }

  get fileIsImage() {
    return IMAGE_FORMATS.includes(this.fileExtension.toLowerCase());
  }

  get fileIsDocument() {
    return DOC_FORMATS.includes(this.fileExtension.toLowerCase());
  }

  get fileExtension() {
    if (!this.props.attachedDocument) {
      return "";
    }

    const srcSplitted = this.props.attachedDocument.name
      ? this.props.attachedDocument.name.split(".")
      : this.props.attachedDocument.documentName.split(".");
    return srcSplitted[srcSplitted.length - 1].split("-")[0];
  }

  shorten(text) {
    return `${text.slice(0, 6)}...${text.slice(text.length - 7, text.length)}`;
  }

  get filename() {
    return this.props.filename;
  }

  onClick() {
    if (!this.hasAttachment) {
      this.refs.fileUploader.click();
      return;
    }

    !this.state.deleted &&
      this.setState({
        showLightbox: true,
      });
  }

  removeAttachment(attachedDocument) {
    const { onFileUnselect } = this.props;

    this.setState({
      deleted: true,
    });
    onFileUnselect && onFileUnselect(attachedDocument);
  }

  render() {
    const { attachedDocument } = this.props;
    // let isUploaded;
    let src = this.props.src;

    if (attachedDocument) {
      this.hasAttachment = true;
    }

    if (this.state.deleted) {
      this.hasAttachment = false;
    }

    const { location } = attachedDocument ? attachedDocument : {};

    if (location) {
      src = `${process.env.REACT_APP_CDN_BASE_URL}/p/${
        location.split("/public/")[1]
      }`;
      // isUploaded = true;
      this.src_ = src;
    }

    return (
      <div
        onClick={!this.props.profile && this.onClick}
        style={{
          alignItems: "center",
          backgroundColor: "#F3F5F6",
          borderRadius: "4px",
          border: "1px dashed #D3D3D3",
          cursor: "pointer",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "140px",
        }}
      >
        {!this.hasAttachment && (
          <React.Fragment>
            <input
              type="file"
              id="file"
              ref="fileUploader"
              value={attachedDocument}
              accept=".pdf,.png,.jpg,.jpeg"
              style={{ display: "none" }}
              onChange={(event) => {
                this.setState({
                  deleted: false,
                });
                this.props.onFileSelect(event, this.filename);
              }}
            />
            {!this.props.isLoading ? (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontSize: "14px",
                }}
              >
                {this.props.filename === "Proof of Identity" ? (
                  <p style={{ color: "#111827" }}>
                    <span style={{ color: "#7F97C3" }}>Upload a file </span> or
                    drag and drop <br />{" "}
                    <span style={{ color: "#6B7280" }}>
                      DOCX, DOC, PDF, upto 10MB
                    </span>
                  </p>
                ) : (
                  <span>
                    <div
                      style={{
                        marginBottom: "0.5rem",
                      }}
                    >
                      {UploadIcon()}
                    </div>
                    <span
                      style={{
                        width: "340.8px",
                        gap: "5.775000095367432px",
                        fontSize: "14px",
                        color: "black",

                        lineHeight: "20px",
                      }}
                    >
                      {" "}
                      Tap here to upload your picture{" "}
                    </span>
                    <br />
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "12px",
                        lineHeight: "16px",
                        color: "#848F9F",
                      }}
                    >
                      JPG or PNG. File size, no more than 10MB
                    </span>
                  </span>
                )}
              </div>
            ) : (
              <IosRefresh
                style={{ width: "24px" }}
                fontSize="100px"
                color="grey"
                rotate={true}
              />
            )}
          </React.Fragment>
        )}
        {this.hasAttachment ? (
          this.fileIsImage ? (
            <React.Fragment>
              <img
                {...this.props}
                alt=""
                data-toggle="modal"
                data-target="#kt_modal_1"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                src={this.src}
              />

              {this.state.showLightbox && (
                <Lightbox
                  mainSrc={this.src}
                  onCloseRequest={() => this.setState({ showLightbox: false })}
                  onMovePrevRequest={() => this.setState({})}
                  onMoveNextRequest={() => this.setState({})}
                />
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <embed
                style={{
                  width: "100%",
                  height: "100%",
                }}
                name="plugin"
                src={this.src}
              />
            </React.Fragment>
          )
        ) : (
          <React.Fragment />
        )}

        {!this.props.manageProfile && (
          <div
            hidden={!this.hasAttachment}
            className="remove-attachment-button"
          >
            <MdCloseCircle
              color={"black"}
              fontSize="35px"
              onClick={() => this.removeAttachment(attachedDocument)}
            />
          </div>
        )}
      </div>
    );
  }
}
