import React, { useEffect, useState } from 'react';
import BackButton from '../component/backButton';
import Title from '../component/title';
import H1 from '../../../../../components/h1';
import InputText from '../component/input';
import SelectInput from '../component/select';
import PrimaryButton from '../component/button';
import { Col } from 'react-bootstrap';
import PhoneInput from '../component/phoneInput';
import phoneValidators from '../../../../../utils/validators/phone-validators';
import { isBusinessAddressValidNOK, isNameValid } from '../component/extras';
import { firetoast, notify } from '../../../../../components/extras';
import { ERROR_STATUS, SUCCESS_STATUS } from '../../../../../constants/api';
import Onboarding from '../../../../../services/api/resources/onboarding';
import { formatPhoneNumber } from '../../../../../utils/formatters';
import { NIGERIA_PHONE_PREFIX } from '../../../../../constants';

const MAX_BUSINESS_NAME_LENGTH = 50;
const NextOfKinScene = ({ setScreen, application, fetchApplication }) => {
  const applicationId = localStorage.getItem('applicationId');
  const onboarding = new Onboarding();
  const [propagateFormErrors, setPropagateFormErrors] = useState(false);
  const [fieldsFocused, setFieldsFocused] = useState([]);

  // const updatednextOfKinDetailsForm = JSON.parse(
  //   localStorage.getItem('nextOfKinDetailsForm')
  // );

  // const [nextOfKinDetailsForm, setNextOfKinDetailsForm] = useState({

  //   firstName: application
  //     ? application?.nextOfKin?.firstName
  //     : updatednextOfKinDetailsForm
  //     ? updatednextOfKinDetailsForm.firstName
  //     : '',
  //   gender: application
  //     ? application?.nextOfKin?.gender
  //     : updatednextOfKinDetailsForm
  //     ? updatednextOfKinDetailsForm.gender
  //     : '',

  //   phoneNumber: application
  //     ? application?.nextOfKin?.phoneNumber
  //     : updatednextOfKinDetailsForm
  //     ? updatednextOfKinDetailsForm.phoneNumber
  //     : '',
  //   relationship: application
  //     ? application?.nextOfKin?.relationship
  //     : updatednextOfKinDetailsForm
  //     ? updatednextOfKinDetailsForm.relationship
  //     : '',
  //   phonePrefix: NIGERIA_PHONE_PREFIX,

  //   address: application
  //     ? application?.nextOfKin?.address
  //     : updatednextOfKinDetailsForm
  //     ? updatednextOfKinDetailsForm.address
  //     : '',
  // });

  // localStorage.setItem(
  //   'nextOfKinDetailsForm',
  //   JSON.stringify(nextOfKinDetailsForm)
  // );

  const [nextOfKinDetailsForm, setNextOfKinDetailsForm] = useState({
    firstName: application ? application?.nextOfKin?.firstName : '',
    gender: application ? application?.nextOfKin?.gender : '',
    phoneNumber: application ? application?.nextOfKin?.phoneNumber : '',
    relationship: application ? application?.nextOfKin?.relationship : '',
    address: application ? application?.nextOfKin?.address : '',
    phonePrefix: NIGERIA_PHONE_PREFIX,
  });

  useEffect(() => {
    const updatednextOfKinDetailsForm = JSON.parse(
      localStorage.getItem('nextOfKinDetailsForm')
    );

    if (updatednextOfKinDetailsForm) {
      setNextOfKinDetailsForm({
        firstName:
          application?.nextOfKin?.firstName ||
          updatednextOfKinDetailsForm.firstName ||
          '',
        gender:
          application?.nextOfKin?.gender ||
          updatednextOfKinDetailsForm.gender ||
          '',
        phoneNumber:
          application?.nextOfKin?.phoneNumber ||
          updatednextOfKinDetailsForm.phoneNumber ||
          '',
        relationship:
          application?.nextOfKin?.relationship ||
          updatednextOfKinDetailsForm.relationship ||
          '',
        address:
          application?.nextOfKin?.address ||
          updatednextOfKinDetailsForm.address ||
          '',
        phonePrefix: NIGERIA_PHONE_PREFIX,
      });
    }
  }, [application]);

  useEffect(() => {
    localStorage.setItem(
      'nextOfKinDetailsForm',
      JSON.stringify(nextOfKinDetailsForm)
    );
  }, [nextOfKinDetailsForm]);
  const [isLoading, setIsLoading] = useState(false);

  const updateFormField = (params) => {
    const newForm = {
      ...nextOfKinDetailsForm,
      ...params,
    };

    setNextOfKinDetailsForm(newForm);
  };

  const isSenderPhoneValid = (newForm) => {
    const Form = newForm || nextOfKinDetailsForm;
    const countryValidator = phoneValidators[nextOfKinDetailsForm.phonePrefix];
    return (
      Form.phoneNumber &&
      (countryValidator ? countryValidator(Form.phoneNumber) : true)
    );
  };

  const isBeneficiaryPhoneValid = (newForm) => {
    const Form = newForm || nextOfKinDetailsForm;
    const countryValidator = phoneValidators[nextOfKinDetailsForm.phonePrefix];

    return (
      Form.phoneNumber &&
      (countryValidator ? countryValidator(Form.phoneNumber) : true)
    );
  };

  const isFormValid = () => {
    return (
      nextOfKinDetailsForm.relationship &&
      nextOfKinDetailsForm.address &&
      nextOfKinDetailsForm.phoneNumber &&
      // nextOfKinDetailsForm.emailAddress &&
      nextOfKinDetailsForm.gender &&
      nextOfKinDetailsForm.firstName
      // &&
      // nextOfKinDetailsForm.surname
    );
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const payload = {
      nextOfKin: {
        firstName: nextOfKinDetailsForm.firstName,
        gender: nextOfKinDetailsForm.gender,
        middleName: nextOfKinDetailsForm.middleName,
        phoneNumber: formatPhoneNumber(nextOfKinDetailsForm.phoneNumber),
        relationship: nextOfKinDetailsForm.relationship,
        address: nextOfKinDetailsForm.address,
      },
    };

    const { status, response } = await onboarding.putApplication(
      payload,

      applicationId
    );

    setIsLoading(false);
    if (status === ERROR_STATUS) {
      firetoast('error', `${response.description}`, '');
    } else if (status === SUCCESS_STATUS) {
      notify('Saved', 'success');
      fetchApplication();
      setScreen(6);
    }
  };

  const relationship = [
    'Aunt',
    'Brother',
    'Cousin',
    'Daughter',
    'Father',
    'Guardian',
    'Mother',
    'Nephew/Niece',
    'Sister',
    'Spouse',
    'Son',
    'Uncle',
  ];
  const gender = ['Male', 'Female'];
  return (
    <>
      <BackButton
        onClick={() => {
          // localStorage.removeItem('lga');
          setScreen(4);
        }}
      />
      <main className='aggregator-container'>
        <Col
          style={{
            marginBottom: '20px',
          }}
        >
          <Title pageNum={'4'} />
          <H1
            style={{
              textAlign: 'left',
              fontWeight: 600,
              marginBottom: '1rem',
            }}
          >
            Next of Kin
          </H1>
          {/* <div
            style={{
              display: 'flex',
              gap: '10px',
            }}
          > */}
          <InputText
            // flex={'1'}
            label={'Name'}
            placeholder={'Enter  Name'}
            onChange={(e) => {
              updateFormField({ firstName: e.target.value });
            }}
            labelColor={
              !nextOfKinDetailsForm.firstName ||
                isNameValid(nextOfKinDetailsForm.firstName)
                ? 'black'
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${!nextOfKinDetailsForm.firstName ||
                isNameValid(nextOfKinDetailsForm.firstName)
                ? '#DFE2E6'
                : process.env.REACT_APP_RED_COLOUR
              }`}
            value={nextOfKinDetailsForm.firstName}
            maxLength={MAX_BUSINESS_NAME_LENGTH}
          />
          {/* <InputText
              flex={'1'}
              label={'MiddleName'}
              placeholder={'Enter Middle Name'}
              onChange={(e) => {
                updateFormField({ middleName: e.target.value.trim() });
              }}
              labelColor={
                !nextOfKinDetailsForm.middleName ||
                isNameValid(nextOfKinDetailsForm.middleName)
                  ? 'black'
                  : process.env.REACT_APP_RED_COLOUR
              }
              border={`1px solid ${
                !nextOfKinDetailsForm.middleName ||
                isNameValid(nextOfKinDetailsForm.middleName)
                  ? '#DFE2E6'
                  : process.env.REACT_APP_RED_COLOUR
              }`}
              value={nextOfKinDetailsForm.middleName}
              maxLength={MAX_BUSINESS_NAME_LENGTH}
            /> */}
          {/* </div> */}
          {/* <InputText
            label={'Surname'}
            placeholder={'Enter surname'}
            onChange={(e) => {
              updateFormField({ surname: e.target.value.trim() });
            }}
            labelColor={
              !nextOfKinDetailsForm.surname ||
              isNameValid(nextOfKinDetailsForm.surname)
                ? 'black'
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !nextOfKinDetailsForm.surname ||
              isNameValid(nextOfKinDetailsForm.surname)
                ? '#DFE2E6'
                : process.env.REACT_APP_RED_COLOUR
            }`}
            value={nextOfKinDetailsForm.surname}
            maxLength={MAX_BUSINESS_NAME_LENGTH}
          /> */}
          {/* <div
            style={{
              display: 'flex',
              gap: '10px',
            }}
          > */}
          <SelectInput
            flex={'1'}
            onChange={(e) => {
              updateFormField({ gender: e.target.value });
            }}
            placeholder={'gender'}
            value={nextOfKinDetailsForm.gender}
            option={gender}
            label={'Gender'}
          />
          {/* <InputText
              flex={'1'}
              onChange={(e) => {
                updateFormField({ emailAddress: e.target.value.trim() });
              }}
              labelColor={
                !nextOfKinDetailsForm.emailAddress ||
                isEmailValidNOK(nextOfKinDetailsForm)
                  ? '#3A3B3B'
                  : process.env.REACT_APP_RED_COLOUR
              }
              border={`1px solid ${
                !nextOfKinDetailsForm.emailAddress ||
                isEmailValidNOK(nextOfKinDetailsForm)
                  ? '#DFE2E6'
                  : process.env.REACT_APP_RED_COLOUR
              }`}
              type={'email'}
              value={nextOfKinDetailsForm.emailAddress}
              label={'Email address'}
              placeholder={'Enter  email adress'}
            /> */}
          {/* </div> */}
          <div
            style={{
              marginTop: '15px',
            }}
          >
            <InputText
              label={'Address'}
              labelColor={
                !nextOfKinDetailsForm.address ||
                  isBusinessAddressValidNOK(nextOfKinDetailsForm)
                  ? 'black'
                  : process.env.REACT_APP_RED_COLOUR
              }
              border={`1px solid ${!nextOfKinDetailsForm.address ||
                  isBusinessAddressValidNOK(nextOfKinDetailsForm)
                  ? '#DFE2E6'
                  : process.env.REACT_APP_RED_COLOUR
                }`}
              placeholder={'Enter your address'}
              onChange={(e) => {
                updateFormField({ address: e.target.value });
              }}
              maxLength={200}
              value={nextOfKinDetailsForm.address}
            />
          </div>
          <PhoneInput
            onChange={(value) => {
              updateFormField({ phoneNumber: value });
            }}
            form={nextOfKinDetailsForm.phoneNumber}
            updateFormField={updateFormField}
            isSenderPhoneValid={isSenderPhoneValid}
            isBeneficiaryPhoneValid={isBeneficiaryPhoneValid}
            fieldsFocused={fieldsFocused}
            setFieldsFocused={setFieldsFocused}
            propagateFormErrors={propagateFormErrors}
            title={"Phone Number"}
          />

          <SelectInput
            label={'Relationship'}
            onChange={(e) => {
              updateFormField({ relationship: e.target.value });
            }}
            placeholder={'relationship'}
            value={nextOfKinDetailsForm.relationship}
            option={relationship}
          />

          <PrimaryButton
            disabled={!isFormValid() || isLoading}
            onClick={handleSubmit}
          >
            {isLoading ? 'Processing...' : 'Save & Continue'}
          </PrimaryButton>
        </Col>
      </main>
    </>
  );
};

export default NextOfKinScene;
