import React from "react";
import { Form } from "react-bootstrap";

export default class FormControl extends React.Component {
  render() {
    const borderRadius =
      process.env.REACT_APP_BORDER_STYLE === "square"
        ? "4px"
        : this.props.style.borderRadius;
    const height = "35pt";
    return (
      <Form.Control
        className="form-control"
        {...this.props}
        style={{ ...this.props.style, borderRadius, height }}
        errorlabel={this.props.errorLabel}
        fieldisvalid={this.props.fieldIsValid}
        maxLength={this.props.maxLength}
      >
        {this.props.children}
      </Form.Control>
    );
  }
}
