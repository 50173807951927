export const SampleTransactions = [
  {
    "transactionRef": "ABC80192",
    "narration": "DStv Premium Subscription",
    "transactionType": "Pay Bills",
    "transactionAmount": 7500,
    "statusCode": "Successful",
    "balanceAfter": 75000,
    "dateCreated": "10-23-2019"
  },
  {
    "transactionRef": "DEF80192",
    "narration": "DStv Premium Subscription",
    "transactionType": "Send Money",
    "transactionAmount": 7500,
    "statusCode": "Successful",
    "balanceAfter": 75000,
    "dateCreated": "10-23-2019"
  },
  {
    "transactionRef": "GHI80192",
    "narration": "DStv Premium Subscription",
    "transactionType": "Buy Airtime",
    "transactionAmount": 7500,
    "statusCode": "Successful",
    "balanceAfter": 75000,
    "dateCreated": "10-22-2019"
  },
  {
    "transactionRef": "JKL80192",
    "narration": "DStv Premium Subscription",
    "transactionType": "Cash Out",
    "transactionAmount": 7500,
    "statusCode": "Failed",
    "balanceAfter": 75000,
    "dateCreated": "10-20-2019"
  },
  {
    "transactionRef": "MNO80192",
    "narration": "DStv Premium Subscription",
    "transactionType": "Open an Account",
    "transactionAmount": 7500,
    "statusCode": "Successful",
    "balanceAfter": 75000,
    "dateCreated": "10-24-2019"
  },
  {
    "transactionRef": "PQR80192",
    "narration": "DStv Premium Subscription",
    "transactionType": "Market Place",
    "transactionAmount": 7500,
    "statusCode": "Successful",
    "balanceAfter": 75000,
    "dateCreated": "10-24-2019"
  },
  {
    "transactionRef": "STU80192",
    "narration": "DStv Premium Subscription",
    "transactionType": "Pay Bills",
    "transactionAmount": 7500,
    "statusCode": "Successful",
    "balanceAfter": 75000,
    "dateCreated": "10-24-2019"
  },
  {
    "transactionRef": "VWX80192",
    "narration": "DStv Premium Subscription",
    "transactionType": "Pay Bills",
    "transactionAmount": 7500,
    "statusCode": "Failed",
    "balanceAfter": 75000,
    "dateCreated": "10-24-2019"
  },
  {
    "transactionRef": "YZA80192",
    "narration": "DStv Premium Subscription",
    "transactionType": "Pay Bills",
    "transactionAmount": 7500,
    "statusCode": "Successful",
    "balanceAfter": 75000,
    "dateCreated": "10-24-2019"
  },
  {
    "transactionRef": "BCD80192",
    "narration": "DStv Premium Subscription",
    "transactionType": "Pay Bills",
    "transactionAmount": 7500,
    "statusCode": "Successful",
    "balanceAfter": 75000,
    "dateCreated": "10-24-2019"
  },
  {
    "transactionRef": "EFG80192",
    "narration": "DStv Premium Subscription",
    "transactionType": "Pay Bills",
    "transactionAmount": 7500,
    "statusCode": "Successful",
    "balanceAfter": 75000,
    "dateCreated": "10-25-2019"
  },
  {
    "transactionRef": "HIJ80192",
    "narration": "DStv Premium Subscription",
    "transactionType": "Pay Bills",
    "transactionAmount": 7500,
    "statusCode": "Failed",
    "balanceAfter": 75000,
    "dateCreated": "10-24-2019"
  },
  {
    "transactionRef": "KLM80192",
    "narration": "DStv Premium Subscription",
    "transactionType": "Pay Bills",
    "transactionAmount": 7500,
    "statusCode": "Failed",
    "balanceAfter": 75000,
    "dateCreated": "10-24-2019"
  }
]