import React, { useEffect, useState } from "react";
import { Col, Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import HeaderSignup from "../../../../components/header-signup";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../constants/api";
import Platform from "../../../../services/api/resources/platform";
import PrimaryButton from "../../scenes/aggregator-module/component/button";
import BackButton from "../manage-profile/component/backButton";
import InputText from "../manage-profile/component/input";

const verifyIcon = "/assets/media/icons/verify-icon.png";

const DocumentInfoCard = () => {
  return (
    <div
      style={{
        width:
          window.innerWidth > 768
            ? "467px"
            : window.innerWidth > 576
            ? "467px"
            : window.innerWidth > 326
            ? "300px"
            : "250px",
        borderRadius: "8px",
        backgroundColor: "#f9fbfc",
        border: "1px solid #e1e6ed",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        padding: "12px 16px",
        textAlign: "left",
        fontSize: "12px",
        color: "#5f738c",
        fontFamily: "Averta",
      }}
    >
      <div
        style={{
          alignSelf: "stretch",
          height: "82px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        <div
          style={{
            alignSelf: "stretch",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "8px 0px",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                flex: "1",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "0px 8px",
                }}
              >
                <img
                  alt="verifyIcon"
                  src={verifyIcon}
                  width="24px"
                  height="24px"
                  style={{ zIndex: 1 }}
                />
                <div
                  style={{
                    alignSelf: "stretch",
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "8px 0px",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      height: "64px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        alignSelf: "stretch",
                        position: "relative",
                        lineHeight: "16px",
                      }}
                    >
                      In line with the latest regulatory requirements from the
                      CBN, we will require some information in order to
                      successfully verify your account. Your information is
                      secure
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const NinInformationScene = ({ disableNavBar }) => {
  useEffect(() => {
    disableNavBar(true);
  }, []);
  const platform = new Platform();
  const [nin, setNin] = useState(null);
  const [isNinVerified, setIsNinVerified] = useState(false);
  const [ninError, setNinError] = useState("");
  const [ninData, setNINData] = useState();
  const [loading, setLoading] = useState(false);
  const [ninResponse, setNINResponse] = useState({
    success: "",
    error: "",
  });

  const currentAgent = JSON.parse(localStorage.getItem("currentAgent"));

  const handleProceed = async () => {
    setLoading(true);
    const payload = {
      dateOfBirth: currentAgent?.businessContact?.dob,
      identificationNumber: nin,
      firstName: currentAgent?.businessContact?.firstname,
      agentPhoneNumber: currentAgent?.agentMobileNo,
      lastName: currentAgent?.businessContact?.lastname,
      phoneNumber: currentAgent?.businessContact?.phoneNo,
    };

    const { status, response } = await platform.validateNINDetails(
      payload,
      "NIN"
    );
    setLoading(false);
    if (status === ERROR_STATUS) {
      notify("error");
    } else if (status === SUCCESS_STATUS) {
      if (response.data.validationStatus === "VERIFIED") {
        notify("success");
        history.push("/agent/dashboard");
        window.location.reload();
      }
      setNin("");
      setIsNinVerified(true);
    }
  };

  const notify = (params) => {
    if (params === "success") {
      toast.success("Your NIN details have been successfully updated.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        title: "Account Upgrade",
      });
    } else if (params === "error") {
      toast.error("Oops! An error occurred, try again.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleNinChange = (event) => {
    setIsNinVerified(false);
    const { value } = event.target;

    if (value.length !== 11) {
      setNinError("NIN must be 11 numeric characters");
      setNin(value);
    } else {
      setNinError("");
      setNin(value);
    }
  };

  const history = useHistory();

  const handleGoBack = () => {
    history.push("/agent/dashboard");
    window.location.reload();
  };

  return (
    <Container className="main-container-nin">
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          margin: "20px",
          flexDirection: window.innerWidth < 576 ? "column" : "row",
          alignItems: "flex-start",
          height: window.innerWidth < 576 ? "100px" : "50px",
        }}
      >
        <HeaderSignup />
        <BackButton
          onClick={handleGoBack}
          style={{
            alignSelf: window.innerWidth < 576 ? "flex-start" : "center",
          }}
        />
      </div>

      <div
        style={{
          minWidth: "100%",
        }}
      >
        <div
          style={{
            marginLeft:
              window.innerWidth > 768
                ? "300px"
                : window.innerWidth > 576
                ? "150px"
                : window.innerWidth > 326
                ? "90px"
                : "20px",
          }}
        >
          <Col style={{ marginBottom: "20px" }}>
            <h1
              style={{
                textAlign: "left",
                fontWeight: 600,
                marginBottom: "1rem",
                fontSize: "2rem",
                color: "#1F2937",
              }}
            >
              Enter ID Information
            </h1>

            <div
              style={{
                width:
                  window.innerWidth > 768
                    ? "467px"
                    : window.innerWidth > 576
                    ? "467px"
                    : window.innerWidth > 326
                    ? "300px"
                    : "250px",
              }}
            >
              <InputText
                disabled={false}
                label={"Enter NIN"}
                placeholder={"0093892883"}
                value={nin}
                onChange={handleNinChange}
                maxLength={11}
                style={{
                  borderColor: ninError || !isNinVerified ? "red" : "",
                  marginBottom: "10px",
                }}
              />
              {ninError && (
                <div style={{ color: "red", marginBottom: "10px" }}>
                  {ninError}
                </div>
              )}
              {isNinVerified && (
                <div
                  style={{
                    color: "red",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      borderRadius: "50%",
                      background: "red",
                      marginRight: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ color: "white", fontSize: "14px" }}>!</span>
                  </div>
                  Your NIN does not match with your account details. Kindly
                  Retry.
                </div>
              )}
            </div>

            <DocumentInfoCard />
            <div
              style={{
                width:
                  window.innerWidth > 768
                    ? "467px"
                    : window.innerWidth > 576
                    ? "467px"
                    : window.innerWidth > 326
                    ? "300px"
                    : "250px",
              }}
            >
              <PrimaryButton onClick={handleProceed} disabled={loading || !nin}>
                {loading ? "Loading..." : "Proceed"}
              </PrimaryButton>
            </div>
          </Col>
        </div>
      </div>
    </Container>
  );
};

export default NinInformationScene;
