import React from 'react';


const SignUp = () => {

    return (
        <div style={{
          fontFamily: 'Helvetica',
          fontSize: '30px',
          fontWeight: '700',
          lineHeight: '32px',
          letterSpacing: '0px',
          textAlign: 'center',
          }}>
          Sign up
        </div>
   
    );
};

export default SignUp;


