import React from "react";

import { Col, Row } from "react-bootstrap";
import Button from "../../../../../components/button";
import Text from "../../../../../components/text";
import { convertNgkToNgn } from "../../../../../utils/converters/currencies";
import {
  basicAddition,
  formatAmount,
  handleCustomerIdFormat,
} from "../../../../../utils/formatters";

export const DistributeConfirmationTab = (props) => {
  //const extras = TransactionExtras;
  return (
    <>
      <Row style={{ padding: "15px" }}>
        <Col md={4}>
          <div
            style={{
              color: process.env.REACT_APP_BLUE_COLOUR,
              fontSize: "17px",
            }}
          >
            <b>Agent Name</b>
          </div>
        </Col>
        <Col md={4}>
          <div
            style={{
              color: process.env.REACT_APP_BLUE_COLOUR,
              fontSize: "17px",
            }}
          >
            <b>Phone</b>
          </div>
        </Col>
        <Col md={4}>
          <div
            style={{
              color: process.env.REACT_APP_BLUE_COLOUR,
              fontSize: "17px",
            }}
          >
            <b>Amount (₦)</b>
          </div>
        </Col>
      </Row>

      {props.payment &&
        props.payment.w2WRequestList &&
        props.payment.w2WRequestList.map((agentData) => {
          return (
            <Row style={{ padding: "15px" }}>
              <Col md={4}>
                <div
                  style={{ fontSize: process.env.REACT_APP_SMALL_FONT_SIZE }}
                >
                  {agentData.beneficiaryName}
                </div>
              </Col>
              <Col md={4}>
                <div
                  style={{ fontSize: process.env.REACT_APP_SMALL_FONT_SIZE }}
                >
                  {handleCustomerIdFormat(agentData.beneficiaryPhone)}
                </div>
              </Col>
              <Col md={4}>
                <div
                  style={{ fontSize: process.env.REACT_APP_SMALL_FONT_SIZE }}
                >{`NGN${formatAmount(convertNgkToNgn(agentData.amount))}`}</div>
              </Col>
            </Row>
          );
        })}

      <hr />

      <Row style={{ padding: "15px" }}>
        <Col md={4}>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            <b>Fee</b>
          </Text>
        </Col>
        <Col md={4}></Col>
        <Col md={4}>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            <b>
              {props.payment
                ? `NGN${formatAmount(convertNgkToNgn(props.payment.fee))}`
                : ""}
            </b>
          </Text>
        </Col>
      </Row>

      <Row style={{ padding: "15px" }}>
        <Col md={4}>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            <b>Total amount</b>
          </Text>
        </Col>
        <Col md={4}></Col>
        <Col md={4}>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            <b>
              {props.payment
                ? `NGN${formatAmount(
                    convertNgkToNgn(
                      basicAddition(
                        props.payment.totalAmount,
                        props.payment.fee
                      )
                    )
                  )}`
                : ""}
            </b>
          </Text>
        </Col>
      </Row>

      <Row style={{ display: "flex", justifyContent: "space-between" }}>
        <Col
          xs={12}
          md={5}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            disabled={props.isLoading}
            style={{ width: "100%" }}
            onClick={props.onDistributeConfirmation}
          >
            {props.isLoading ? "Processing..." : "Pay"}
          </Button>
        </Col>
        <Col xs={12} md={5}>
          <Button
            outline
            style={{
              backgroundColor: "transparent",
              marginTop: props.deviceIsMobile ? "10pt" : null,
              width: "100%",
              padding: "9pt 37pt",
            }}
            onClick={props.onCancelProduct}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </>
  );
};
