import React from "react";

import qs from "qs";

import { connect } from "react-redux";
import { navigateTo } from "../../../../../../services/redux/actions/navigation";
import { updateLoading } from "../../../../../../services/redux/actions/tunnel";
import AgentTypeCard from "./agentTypeCard";
import H1 from "../../../../../../components/h1";
import SkeletonLoading from "../../component/skeletonLoading";
import BackButton from "../../component/backButton";

const ListOfclassScreen = (props) => {
  const agentDetails = JSON.parse(localStorage.getItem("agentDetails"));
  const deviceIsPC =
    !qs.parse(window.location.search)["?mobile"] && window.innerWidth >= 992;

  const getInitials = () => {
    if (agentDetails) {
      const initials = agentDetails.accountName
        .split(" ")
        .map((word) => word[0])
        .join("");

      return initials;
    }
  };

  const basicButtonStyle = (agentClass) => {
    if (agentClass === "BASIC") {
      return {
        text: "You are on Basic",
        disabled: true,
        backgroundColor: "transparent",
        border: "1px solid #E1E6ED",
        color: "black",
      };
    } else if (agentClass === "STANDARD") {
      return {
        text: "remove button",
      };
    } else {
      return {
        text: "remove button",
      };
    }
  };
  const standardButtonStyle = (agentClass) => {
    if (agentClass === "STANDARD") {
      return {
        text: "You are on Standard",
        disabled: true,
        backgroundColor: "transparent",
        border: "1px solid #E1E6ED",
        color: "black",
      };
    } else if (agentClass === "BASIC") {
      return {
        text: "Upgrade to Standard",
        disabled: false,
        backgroundColor: "#00425F",
        border: "none",
        color: "white",
      };
    } else {
      return {
        text: "remove button",
      };
    }
  };
  const prestigeButtonStyle = (agentClass) => {
    if (agentClass === "BASIC" || agentClass === "STANDARD") {
      return {
        text: "Coming Soon",
        disabled: false,
        backgroundColor: "#00425F",
        border: "none",
        color: "white",
      };
    } else {
      return {
        text: "remove button",
      };
    }
  };
  const handleBasic = () => {};
  const handleStandard = () => {
    localStorage.setItem("upgradeTo", "Standard");
    props.navigateTo(
      `/agents/my-agents/upgrade/upgrade-class/${agentDetails.id}`
    );
  };
  const handlePrestige = () => {
    localStorage.setItem("upgradeTo", "prestige");
    props.navigateTo(
      `/agents/my-agents/upgrade/upgrade-class/${agentDetails.id}`
    );
  };
  const basicLimit = [
    `Daily transaction limit ${`>`} ₦50,000`,
    `Daily Deposit Limit ${`>`} ₦300,000`,
    `Ledger Balance Limit ${`>`} ₦300,000`,
    `Daily Withdrawal Limit ${`>`} ₦50,000`,
  ];
  const standardLimit = [
    `Daily transaction limit ${`>`} ₦300,000`,
    `Daily Deposit Limit ${`>`} ₦1,000,000`,
    `Ledger Balance Limit ${`>`} ₦1,000,000`,
    `Daily Withdrawal Limit ${`>`} ₦300,000`,
  ];
  const prestigeLimit = [
    `Daily transaction limit ${`>`} ₦1,000,000`,
    `Daily Deposit Limit ${`>`} ₦10,000,000`,
    `Ledger Balance Limit ${`>`} ₦10,000,000`,
    `Daily Withdrawal Limit ${`>`} ₦1,000,000`,
  ];
  return (
    <>
      <div className="aggregator-layout">
        <BackButton onClick={() => props.navigateTo("/agents/my-agents")} />
        <div
          style={{
            textAlign: "left",
            gap: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {deviceIsPC ? (
            <div
              style={{
                width: "65px",
                height: "65px",
                fontSize: "18px",
                fontWeight: 700,
                display: "grid",
                placeContent: "center",
                borderRadius: "100%",
                background: "#EBF8FE",
                color: "#00425F",
                textTransform: "uppercase",
              }}
            >
              {getInitials()}
            </div>
          ) : (
            <>
              {deviceIsPC && <SkeletonLoading width={"40px"} height={"25px"} />}
            </>
          )}

          <div>
            <H1
              style={{
                fontSize: "1.5rem",
                marginBottom: "7px",
              }}
            >
              {agentDetails.accountName}
            </H1>
            <div
              style={{
                color: "#0275D8",
                borderRadius: "5px",
                backgroundColor: "#EBF8FE",
                padding: "5px",
                display: "inline",
              }}
            >
              {agentDetails.agentClass}
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: "2rem",
          }}
        >
          <div>
            <H1
              style={{
                textAlign: "left",
                marginBottom: "1rem",
              }}
            >
              Select Upgrade Type
            </H1>
            <p
              style={{
                color: "#6B7280",
                fontSize: "0.75rem",
                marginBottom: "3rem",
              }}
            >
              You’ll be required to provide us with some information depending
              on your selection. Kindly choose your preferred option below
            </p>
          </div>
        </div>
        <div className="agentType-container-card">
          <AgentTypeCard
            limit={basicLimit}
            agentType={"Basic"}
            onClick={handleBasic}
            docType={["Passport Photograph", "Government Issued ID"]}
            buttonStyle={basicButtonStyle(agentDetails.agentClass)}
          />
          <AgentTypeCard
            limit={standardLimit}
            buttonStyle={standardButtonStyle(agentDetails.agentClass)}
            onClick={handleStandard}
            agentType={"Standard"}
            docType={["Utility Bill", "Character Form"]}
            buttonText={"Upgrade to Standard"}
          />
          <AgentTypeCard
            limit={prestigeLimit}
            disabled={true}
            buttonStyle={prestigeButtonStyle(agentDetails.agentClass)}
            onClick={handlePrestige}
            agentType={"Prestige"}
            docType={[
              "Utility Bill",
              "Character Form",
              "Address Verification Form",
            ]}
            buttonText={"Upgrade to Prestige"}
          />
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    isLoading: state.tunnel.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateLoading: (isLoading) => dispatch(updateLoading(isLoading)),
    navigateTo: (pendingUrl) => dispatch(navigateTo(pendingUrl)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ListOfclassScreen);
