import React, { useEffect, useState } from 'react';

import { Col } from 'react-bootstrap';

import 'react-datepicker/dist/react-datepicker.css';

import IosRefresh from 'react-ionicons/lib/IosRefresh';

import { NIGERIA_PHONE_PREFIX } from '../../../../../../../constants';
import phoneValidators from '../../../../../../../utils/validators/phone-validators';

import {
  isBusinessLgaValid,
  isBvnValid,
  isEmailValid,
  isNameValid,
  stripNonNumbers,
} from '../../../component/extras';

import { SUCCESS_STATUS } from '../../../../../../../constants/api';

import BackButton from '../../../component/backButton';

import PhoneInput from '../../../component/phoneInput';

import InputText from '../../../component/input';
import SelectInput from '../../../component/select';
import { DatePickerComponent } from '../../../component/datePickerComponent';
import { MAX_BVN_LENGTH } from '../../../../../../../constants/form-fields';
import PrimaryButton from '../../../component/button';
import Title from './title';
import H1 from '../../../../../../../components/h1';
import CountriesStatesLga from '../../../../../../../fixtures/countries_states_lgas.json';

import Platform from '../../../../../../../services/api/resources/platform';
import {
  SelectInputLga,
  SelectInputState,
} from '../../../component/stateSelect';

const PersonalDetailsScene = ({ setScreen, application }) => {
  const platform = new Platform();
  const [loadingLgas, setLoadingLgas] = useState(false);
  const [fetchLga, setFetchLga] = useState([]);
  const [propagateFormErrors, setPropagateFormErrors] = useState(false);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [fieldsFocused, setFieldsFocused] = useState([]);

  const updatedPersonaldetailsForm = JSON.parse(
    localStorage.getItem('personalDetailsFormUpgrade')
  );

  const dateComponents = application?.businessContact?.dob.split('-') || '';
  const year = parseInt(dateComponents[0], 10);
  const month = parseInt(dateComponents[1], 10);
  const day = parseInt(dateComponents[2], 10);

  const formattedDate = `${year}, ${month}, ${day}`;

  const convertDate = (date) => {
    const dateComponents = date?.split('-') || '';
    const year = parseInt(dateComponents[0], 10);
    const month = parseInt(dateComponents[1], 10);
    const day = parseInt(dateComponents[2], 10);

    return `${year}, ${month}, ${day + 1}`;
  };

  const [personalDetailsForm, setPersonalDetailsForm] = useState({
    phoneNumber: application
      ? application?.agentMobileNo
      : updatedPersonaldetailsForm
      ? updatedPersonaldetailsForm?.phoneNumber
      : '',
    email: application
      ? application?.businessEmail
      : updatedPersonaldetailsForm
      ? updatedPersonaldetailsForm?.email
      : '',
    gender: application
      ? application?.businessContact?.gender
      : updatedPersonaldetailsForm
      ? updatedPersonaldetailsForm?.gender
      : '',
    bvn: application
      ? application?.bvn
      : updatedPersonaldetailsForm
      ? updatedPersonaldetailsForm.bvn
      : '',
    localGovernmentArea:
      application?.homeAddressLgaId ||
      updatedPersonaldetailsForm?.localGovernmentArea,
    state: application?.homeAddressStateId || updatedPersonaldetailsForm?.state,

    motherMaidenName:
      application?.businessContact?.motherMadienName ||
      updatedPersonaldetailsForm?.motherMaidenName,

    phonePrefix: NIGERIA_PHONE_PREFIX,
    dob: application.businessContact?.dob && new Date(formattedDate),
    // : updatedPersonaldetailsForm
    // ? new Date(convertDate(updatedPersonaldetailsForm?.dob))
    // : new Date(2005, 0, 1),
  });

  localStorage.setItem(
    'personalDetailsFormUpgrade',
    JSON.stringify(personalDetailsForm)
  );
  const updateFormField = (params) => {
    const newForm = {
      ...personalDetailsForm,
      ...params,
    };

    setPersonalDetailsForm(newForm);
  };

  const isSenderPhoneValid = (newForm) => {
    const Form = newForm || personalDetailsForm;
    const countryValidator = phoneValidators[personalDetailsForm.phonePrefix];
    return (
      Form.phoneNumber &&
      (countryValidator ? countryValidator(Form.phoneNumber) : true)
    );
  };

  const isBeneficiaryPhoneValid = (newForm) => {
    const Form = newForm || personalDetailsForm;
    const countryValidator = phoneValidators[personalDetailsForm.phonePrefix];

    return (
      Form.phoneNumber &&
      (countryValidator ? countryValidator(Form.phoneNumber) : true)
    );
  };

  const isFormValid = (type) => {
    if (type === 'prestige') {
      return (
        isNameValid(personalDetailsForm.motherMaidenName) &&
        personalDetailsForm.state &&
        personalDetailsForm.localGovernmentArea
      );
    } else if (type === 'Standard') {
      return isNameValid(personalDetailsForm.motherMaidenName);
    }

    return false;
  };

  const handleSubmit = async () => {
    setScreen(3);
  };

  const fetchAllStates = async (countryId) => {
    const stateArray = CountriesStatesLga[0].states.map((state) => {
      return {
        name: state.name,
        id: state.id,
      };
    });

    setStates(stateArray);
  };

  const getLga = async (stateId) => {
    setLoadingLgas(true);
    const lgasResponseObj = await platform.retrieveLgas(
      personalDetailsForm.state || selectedState
    );
    const lgasResponseStatus = lgasResponseObj.status;
    const lgasResponse = lgasResponseObj.response;
    setLoadingLgas(false);
    if (lgasResponseStatus === SUCCESS_STATUS) {
      setFetchLga(lgasResponse);
    }
  };

  useEffect(() => {
    getLga();
  }, [personalDetailsForm.state, selectedState]);

  const gender = ['Male', 'Female'];
  const upgradeTo = localStorage.getItem('upgradeTo');

  useEffect(() => {
    fetchAllStates();
  }, []);
  return (
    <>
      <BackButton
        onClick={() => {
          localStorage.removeItem('personalDetailsFormUpgrade');
          localStorage.removeItem('state');
          localStorage.removeItem('lga');
          setScreen(1);
        }}
      />
      <main className='aggregator-container'>
        <Col
          style={{
            marginBottom: '20px',
            margin: '0 auto',
          }}
        >
          <Title upgradeTo={upgradeTo} />
          <H1
            style={{
              textAlign: 'left',
              fontWeight: 600,
              marginBottom: '1rem',
            }}
          >
            Personal Information
          </H1>
          <PhoneInput
            onChange={(value) => {
              updateFormField({ phoneNumber: value });
            }}
            disabled={true}
            form={personalDetailsForm.phoneNumber}
            updateFormField={updateFormField}
            isSenderPhoneValid={isSenderPhoneValid}
            isBeneficiaryPhoneValid={isBeneficiaryPhoneValid}
            fieldsFocused={fieldsFocused}
            setFieldsFocused={setFieldsFocused}
            propagateFormErrors={propagateFormErrors}
          />

          <InputText
            disabled={true}
            onChange={(e) => {
              updateFormField({ email: e.target.value.trim() });
            }}
            labelColor={
              !personalDetailsForm.email || isEmailValid(personalDetailsForm)
                ? '#3A3B3B'
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !personalDetailsForm.email || isEmailValid(personalDetailsForm)
                ? '#DFE2E6'
                : process.env.REACT_APP_RED_COLOUR
            }`}
            type={'email'}
            value={personalDetailsForm.email}
            label={'Email address'}
            placeholder={'Enter  email adress'}
          />

          <SelectInput
            onChange={(e) => {
              updateFormField({ gender: e.target.value });
            }}
            placeholder={'gender'}
            value={personalDetailsForm.gender}
            option={gender}
            label={'Gender'}
            disabled={true}
          />

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '1rem 0',
            }}
          >
            <DatePickerComponent
              onChange={(date) => {
                updateFormField({ dob: date });
              }}
              selected={personalDetailsForm.dob}
              label={'Date of Birth'}
              placeholder='DD-MM-YYYY'
              size={`100%`}
              format={`dd-MM-yyyy`}
              isClearable={true}
              showTimeInput={true}
              disabled={true}
              textStyle={'1.0rem'}
              iconStyle={'0.2rem'}
              dateType={'startDate'}
              preloadDate={false}
            />
          </div>

          <InputText
            disabled={true}
            label={'BVN'}
            labelColor={
              !personalDetailsForm.bvn || isBvnValid(personalDetailsForm)
                ? '#3A3B3B'
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !personalDetailsForm.bvn || isBvnValid(personalDetailsForm)
                ? '#DFE2E6'
                : process.env.REACT_APP_RED_COLOUR
            }`}
            placeholder={'BVN'}
            onChange={(e) => {
              updateFormField({ bvn: stripNonNumbers(e.target.value.trim()) });
            }}
            value={personalDetailsForm.bvn}
            maxLength={MAX_BVN_LENGTH}
          />
          {upgradeTo === 'prestige' && (
            <>
              <SelectInputState
                label={'State'}
                option={states}
                border={`1px solid ${
                  !isBusinessLgaValid(localStorage.getItem('state')) &&
                  !personalDetailsForm.state
                    ? process.env.REACT_APP_RED_COLOUR
                    : '#DFE2E6'
                }`}
                getState={localStorage.getItem('state')}
                value={personalDetailsForm.state}
                placeholder={'state'}
                onChange={(e) => {
                  localStorage.setItem(
                    'state',
                    states.find((value) => value.name === e.target.value).name
                  );
                  setPersonalDetailsForm({ ...personalDetailsForm, lga: '' });
                  localStorage.removeItem('lga');
                  setSelectedState(
                    states.find((value) => value.name === e.target.value).id
                  );
                  updateFormField({
                    state: states.find((value) => value.name === e.target.value)
                      .id,
                  });
                }}
              />
              {!isBusinessLgaValid(localStorage.getItem('state')) &&
                !personalDetailsForm.state && (
                  <div
                    style={{
                      color: process.env.REACT_APP_RED_COLOUR,
                      fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,

                      position: 'relative',
                      top: '-10px',
                    }}
                  >
                    Select state
                  </div>
                )}

              <SelectInputLga
                border={`1px solid ${
                  !isBusinessLgaValid(localStorage.getItem('lga')) &&
                  personalDetailsForm.state
                    ? process.env.REACT_APP_RED_COLOUR
                    : '#DFE2E6'
                }`}
                label={'Local Government Area'}
                option={fetchLga}
                value={personalDetailsForm?.localGovernmentArea}
                getLga={localStorage.getItem('lga')}
                placeholder={'LGA'}
                onChange={(e) => {
                  localStorage.setItem(
                    'lga',
                    fetchLga.find((value) => value.name === e.target.value).name
                  );
                  updateFormField({
                    localGovernmentArea: fetchLga.find(
                      (value) => value.name === e.target.value
                    ).id,
                  });
                }}
              />
              {loadingLgas && (
                <IosRefresh
                  style={{
                    marginTop: '-52pt',
                    marginLeft: '85%',
                    width: '20px',
                    position: 'absolute',
                  }}
                  fontSize='60px'
                  color='grey'
                  rotate={true}
                />
              )}

              {!isBusinessLgaValid(localStorage.getItem('lga')) &&
                personalDetailsForm.state && (
                  <div
                    style={{
                      color: process.env.REACT_APP_RED_COLOUR,
                      fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,

                      position: 'relative',
                      top: '-10px',
                    }}
                  >
                    Select Local Government Area
                  </div>
                )}
            </>
          )}
          <InputText
            label={`Mother's Maiden Name`}
            onChange={(e) => {
              const inputValue = e.target.value;
              const onlyLetters = inputValue.replace(/[^A-Za-z]/g, '');
              updateFormField({ motherMaidenName: onlyLetters });
            }}
            labelColor={
              personalDetailsForm.motherMaidenName &&
              isNameValid(personalDetailsForm.motherMaidenName)
                ? 'black'
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              personalDetailsForm.motherMaidenName &&
              isNameValid(personalDetailsForm.motherMaidenName)
                ? '#DFE2E6'
                : process.env.REACT_APP_RED_COLOUR
            }`}
            placeholder={`Enter Mother's Maiden Name`}
            value={personalDetailsForm.motherMaidenName}
            maxLength={200}
          />

          <PrimaryButton
            disabled={!isFormValid(upgradeTo)}
            onClick={handleSubmit}
          >
            Next
          </PrimaryButton>
        </Col>
      </main>
    </>
  );
};

export default PersonalDetailsScene;
