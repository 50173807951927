import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../constants/api";
import Platform from "../../../../services/api/resources/platform";
import BackButton from "../manage-profile/component/backButton";

const DowngradeFrame = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const platform = new Platform();


  const currentAgent = JSON.parse(localStorage.getItem("currentAgent"));

  const notify = (params) => {
    if (params === "success") {
      toast.success("Your account has been successfully downgraded!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        title: "Account Downgrade",
      });
    } else if (params === "error") {
      toast.error("Oops! An error occurred, try again.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        title: "Error",
      });
    }
  };

  const handleProceed = async () => {
    setLoading(true);

    const payload = {
      agentCode: currentAgent?.agentCode,
    };

    const { status, response } = await platform.agentAccountDowngrade(payload);

    setLoading(false);
 
    if (status === ERROR_STATUS) {
      notify("error");
    } else if (status === SUCCESS_STATUS) {
      notify("success");
      history.push("/agent/dashboard");
      window.location.reload();
    }
  };

  const containerStyle = {
    maxWidth: "1407px",
    width: "100%",
    margin: "0 auto",
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    alignItems: "center",
    justifyContent: isMobile ? "center" : "space-between",
    textAlign: "left",
    fontSize: "2rem",
    color: "#00425f",
  };

  const leftColumnStyle = {
    maxWidth: "438px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: isMobile ? "16px" : "0",
    marginRight: isMobile ? "0" : "16px",
  };

  const buttonContainerStyle = {
    display: "flex",
    gap: isMobile ? "16px" : "8px",
    flexDirection: isMobile ? "column" : "row",
    marginTop: isMobile ? "16px" : "0",
  };

  const buttonCommonStyle = {
    borderRadius: "8px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "12px 24px",
    fontSize: "1rem",
    boxSizing: "border-box",
    minWidth: "180px",
    height: "48px",
  };

  const handleGoBack = () => {
    history.push("/agent/dashboard");
    window.location.reload();
  };

  return (
    <div style={containerStyle}>
      <div style={leftColumnStyle}>
        <div
          style={{
            fontWeight: "600",
            lineHeight: "1.5",
            color: "#00425F",
            fontSize: "1.5rem",
          }}
        >
          Downgrade Account
        </div>
        <div
          style={{
            fontWeight: "400",
            fontSize: "1rem",
            lineHeight: "1.5",
            color: "#5F738C",
          }}
        >
          Are you sure you want to accept an Account downgrade to Basic? Your
          transaction limits will be reduced as outlined below
        </div>
      </div>
      <div style={buttonContainerStyle}>
        <button
          style={{
            ...buttonCommonStyle,
            backgroundColor: "#fff",
            color: "#072F40",
            border: "1px solid #00425f",
          }}
          onClick={() => {
            handleGoBack();
          }}
        >
          Maintain my current tier
        </button>
        <button
          style={{
            ...buttonCommonStyle,
            backgroundColor: "#00425f",
            color: "#fff",
          }}
          onClick={handleProceed}
          disabled={loading}
        >
          {loading ? "Loading..." : "Accept Downgrade"}
        </button>
      </div>
    </div>
  );
};

const ResponsiveTable = () => {
  const currentAgent = JSON.parse(localStorage.getItem("currentAgent"));

  return (
    <div style={{ overflowX: "auto" }}>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <TableHeaderCell>LIMITS</TableHeaderCell>
            <TableHeaderCell>{currentAgent.agentClass}</TableHeaderCell>
            <TableHeaderCell />
            <TableHeaderCell>BASIC</TableHeaderCell>
          </tr>
        </thead>
        <tbody>
          <TableRow
            label="Maximum Cummulative Balance"
            prestigeValue={`${
              currentAgent.agentClass === "STANDARD"
                ? "₦5,000,000"
                : "₦10,000,000"
            }`}
            basicValue="₦50,000"
          />
          <TableRow
            label="Daily Transaction Limit"
            prestigeValue={`${
              currentAgent.agentClass === "STANDARD" ? "₦500,000" : "₦1,000,000"
            }`}
            basicValue="₦50,000"
          />
        </tbody>
      </table>
    </div>
  );
};

const TableHeaderCell = ({ children }) => (
  <th style={headerCellStyle}>{children}</th>
);

const TableRow = ({ label, prestigeValue, basicValue }) => (
  <tr>
    <TableCell>{label}</TableCell>
    <PrestigeTableCell>{prestigeValue}</PrestigeTableCell>
    <TableCell>&rarr;</TableCell>
    <TableCell>{basicValue}</TableCell>
  </tr>
);

const TableCell = ({ children }) => <td style={cellStyle}>{children}</td>;

const PrestigeTableCell = ({ children }) => (
  <td style={{ ...cellStyle, color: "green" }}>{children}</td>
);

const headerCellStyle = {
  padding: "8px",
  backgroundColor: "#f2f2f2",
  textAlign: "left",
  borderBottom: "1px solid #ddd",
  height: "60px",
};

const cellStyle = {
  padding: "8px",
  borderBottom: "1px solid #ddd",
  height: "60px",
};

const AccountDowngradeScene = ({ disableNavBar }) => {
  useEffect(() => {
    disableNavBar(true);
  }, []);
  const history = useHistory();

  const handleGoBack = () => {
    history.push("/agent/dashboard");
    window.location.reload();
  };

  return (
    <Container className="main-container-nin">
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          gap: "20px",
          margin: "30px",
          maxHeight: "50px",
        }}
      >
        <BackButton onClick={handleGoBack} />
      </div>

      <div
        style={{
          minWidth: "100%",
          backgroundColor: "#FFFFFF",
          margin: "20px",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          padding:
            window.innerWidth > 768
              ? "50px"
              : window.innerWidth > 576
              ? "20px"
              : "10",
          borderRadius: "10px",
        }}
      >
        <DowngradeFrame />
        <br />
        <br />
        <ResponsiveTable />
      </div>
    </Container>
  );
};

export default AccountDowngradeScene;
