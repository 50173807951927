import jwt_decode from "jwt-decode";

import Swal from "sweetalert2";
import { SUCCESS_STATUS } from "../constants/api";
import { setUserProperties } from "../core/logger";
import AgentSerializer from "../serializers/resources/agent";
import UserManagement from "../services/api/resources/user-management";
import { appSessionTimer, tokenSessionTimer } from "../setup/session-timers";

export const AUTH_TOKEN = "auth_token";
export const AUTH_TOKEN_EXPIRY = "auth_token_expiry";
export const REFRESH_TOKEN = "refresh_token";
const CURRENT_USER = "currentUser";
const AUTH_TOKEN_TIMEOUT_IN_SEC = 60;

function decodeToken(token) {
  const decodedToken = jwt_decode(token);
  return decodedToken;
}

function currentTimestamp() {
  const currentDate = new Date();
  const currentTimestamp = currentDate.valueOf();
  return currentTimestamp;
}

export function calculateTimeToRefreshToken() {
  const authTokenExpiry = parseInt(localStorage.getItem(AUTH_TOKEN_EXPIRY));
  const timeToRefreshToken = parseInt(
    process.env.REACT_APP_TIME_TO_REFRESH_TOKEN_IN_MILLISECONDS
  );

  return authTokenExpiry - timeToRefreshToken - currentTimestamp();
}

export function onNewSessionBegin() {
  appSessionTimer.start();
  tokenSessionTimer.initialize({
    onEnd: () => {
      refreshAuthToken();
      tokenSessionTimer.start();
    },
    timeout: 600000,
  });
  tokenSessionTimer.start();

  const agentSerializer = new AgentSerializer(
    JSON.parse(localStorage.getItem("currentAgent"))
  );

  const currentAgent = agentSerializer.asJson();
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));

  setUserProperties({ ...currentUser, ...currentAgent });
}

export function onSessionEnd() {
  appSessionTimer.stop();
  tokenSessionTimer.stop();
}

export async function refreshAuthToken() {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const userManagement = new UserManagement();
  const refreshToken = retrieveRefreshToken();

  if (currentUser == null) {
    return;
  }

  const { status, response } = await userManagement.refreshAccessToken(
    refreshToken,
    currentUser.username
  );

  if (status === SUCCESS_STATUS) {
    saveAuthToken(response.data);
  } else {
    window.location.href = "/logout?case=APP_SESSION_EXPIRED";
  }
}

export async function logoutUser() {
  const userManagement = new UserManagement();
  await userManagement.logout(retrieveAuthToken());
}

export function deleteAuthToken() {
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(AUTH_TOKEN_EXPIRY);
}

export function isAuthTokenExpired() {
  const authTokenExpiry = parseInt(localStorage.getItem(AUTH_TOKEN_EXPIRY));
  if (
    authTokenExpiry &&
    currentTimestamp() > authTokenExpiry - AUTH_TOKEN_TIMEOUT_IN_SEC
  ) {
    return true;
  }
  return false;
}

export function isSessionActive() {
  return localStorage.getItem(AUTH_TOKEN) !== null;
}

export function retrieveAuthToken() {
  return localStorage.getItem(AUTH_TOKEN);
}

export function saveAuthToken(token) {
  const { exp } = decodeToken(token);

  const expiryTimestamp = new Date(currentTimestamp() + exp / 1000).valueOf();

  localStorage.setItem(AUTH_TOKEN, token);
  localStorage.setItem(AUTH_TOKEN_EXPIRY, expiryTimestamp);
}

export function retrieveRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN);
}

export function saveRefreshToken(refreshToken) {
  localStorage.setItem(REFRESH_TOKEN, refreshToken);
}

export function saveUserObject(user) {
  localStorage.setItem(CURRENT_USER, JSON.stringify(user));
}

export function loadUserObject() {
  return JSON.parse(localStorage.getItem(CURRENT_USER));
}
