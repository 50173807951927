export function transactionExtraSerializer(myData) {
    const refinedData = {};
    if(myData){
      let extraData = myData
      try{
        extraData.map(
            ({key, value}) => {
              if(key){
                refinedData[key] = value;
              }
            },
        );
      }catch(e){}
      
      try{
        extraData.map((obj) => {
          const key = Object.keys(obj)[0];
          const value = obj[key];

          if(key){
            refinedData[key] = value;
          }
        });
      }catch(e){}
    }
  
    return refinedData;
  }

  export function transactionExtraSerializerReport(extraData) {
    const refinedData = {};    
    
    extraData.map((obj) => {
      const key = obj["name"];
      const value = obj["value"];

      if(key){
        refinedData[key] = value;
      }
    });
    
    return refinedData;
  }
  