import React from 'react';

import Lottie from 'react-lottie';

import ErrorAnimation from './animations/error';
import { DEFAULT_ERROR_MESSAGE } from './constants';
import Header from './components/header';


class ErrorBoundary extends React.Component {
  
  state = {
    error: null,
    errorInfo: null,
    isPaused: false,
  };

  componentDidMount() {
    setTimeout(() => this.setState({
      isPaused: true
    }), 1000)
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  };

  render() {
    const { error, errorInfo } = this.state;
    const defaultOptions = {
      autoplay: true, 
      loop: true,
      animationData: ErrorAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    if (errorInfo) {
      return (<>
        <Header />
        <div id="error-boundary-container">
          <h2 id="error-boundary-error-title">{DEFAULT_ERROR_MESSAGE}</h2>
          <Lottie 
            height={300}
            options={defaultOptions}
            isPaused={this.state.isPaused}
            width={300}
          />

          <details style={{ whiteSpace: 'pre-wrap' }}>
            {error && error.toString()}
            <br />
            {errorInfo.componentStack}
          </details>

          <div 
            style={{
              alignItems: 'center',
              border: `1pt solid ${process.env.REACT_APP_BLUE_COLOUR}`,
              borderRadius: '4px',  
              color: process.env.REACT_APP_BLUE_COLOUR, 
              cursor: 'pointer',
              display: 'flex',
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontStyle: process.env.REACT_APP_MID_FONT_SIZE, 
              fontWeight: 'bold', 
              height: '40px',
              justifyContent: 'center',
              marginTop: '16px',
              width: '160px'
            }}
            onClick={() => window.location.reload()}
          >
            {'Refresh'}
          </div>
        </div>
        </>
      );
    }

    return this.props.children
  }
};

export default ErrorBoundary;