const CATALOG_BASE_URL = process.env.REACT_APP_CATALOG_NEXT_API_BASE_URL;
const QUICKTELLER_BASE_URL =
  process.env.REACT_APP_QUICKTELLER_NEXT_API_BASE_URL;
const SHOW_FUND_VIA_USSD_ = process.env.REACT_APP_SHOW_FUND_VIA_USSD;
const NIP_SERVICE_API_BASE_URL =
  process.env.REACT_APP_FINCH_NIP_SERVICE_API_BASE_URL;
const BANK_MONITORING_SERVICE_API_BASE_URL =
  process.env.REACT_APP_FINCH_BANK_MONITORING_SERVICE_API_BASE_URL;
const CRM_SERVICE_API_BASE_URL =
  process.env.REACT_APP_FINCH_CRM_SERVICE_API_BASE_URL;


const DOCUMENT_BASE_URL =
  process.env.REACT_APP_DOCUMENT_BASE_URL
const REACT_APP_AUDIT_TRAIL_SERVICE_API_BASE_URL =
  process.env["REACT_APP_AUDIT_TRAIL_SERVICE_API_BASE_URL"];
const REACT_APP_ONBOARDING_API_BASE_URL =
  process.env["REACT_APP_ONBOARDING_API_BASE_URL"];
const REACT_APP_PLATFORM_API_BASE_URL =
  process.env["REACT_APP_PLATFORM_API_BASE_URL"];
const REACT_APP_SETTLEMENT_API_BASE_URL =
  process.env["REACT_APP_SETTLEMENT_API_BASE_URL"];
const REACT_APP_TRANSACTION_API_BASE_URL =
  process.env["REACT_APP_TRANSACTION_API_BASE_URL"];
const REACT_APP_TRANSACTION_API_BASE_URL_IKEJA =
  process.env["REACT_APP_TRANSACTION_API_BASE_URL_IKEJA"];
const REACT_APP_TRANSACTION_API_BASE_URL_PRIME =
  process.env["REACT_APP_TRANSACTION_API_BASE_URL_PRIME"];
const REACT_APP_USER_MANAGEMENT_API_BASE_URL =
  process.env["REACT_APP_USER_MANAGEMENT_API_BASE_URL"];
const REACT_APP_TRANSACTION_HISTORY_API_BASE_URL =
  process.env["REACT_APP_TRANSACTION_HISTORY_API_BASE_URL"];
const REACT_APP_FINCH_LIQUIDITY_SERVICE_API_BASE_URL =
  process.env["REACT_APP_FINCH_LIQUIDITY_SERVICE_API_BASE_URL"];
const REACT_APP_FINCH_LIQUIDITY_SERVICE_API_BASE_URL_PRIME =
  process.env["REACT_APP_FINCH_LIQUIDITY_SERVICE_API_BASE_URL_PRIME"];
const REACT_APP_HISTORICAL_DATA_SERVICE_API_BASE_URL =
  process.env["REACT_APP_HISTORICAL_DATA_SERVICE_API_BASE_URL"];
const REACT_APP_HOSTED_FIELDS_SDK = process.env["REACT_APP_HOSTED_FIELDS_SDK"];
const REACT_APP_PRIVACY_POLICY_URL =
  process.env["REACT_APP_PRIVACY_POLICY_URL"];
const REACT_APP_HELP_PORTAL = process.env["REACT_APP_HELP_PORTAL"];
const REACT_APP_RECOVER_PASSWORD_REDIRECT_URL =
  process.env["REACT_APP_RECOVER_PASSWORD_REDIRECT_URL"];
const REACT_APP_QUICKTELLER_CHANNEL =
  process.env["REACT_APP_QUICKTELLER_CHANNEL"];
const REACT_APP_QUICKTELLER_TERMINAL_ID =
  process.env["REACT_APP_QUICKTELLER_TERMINAL_ID"];
const REACT_APP_TRANSFERS_PAYMENT_ITEM_CODE =
  process.env["REACT_APP_TRANSFERS_PAYMENT_ITEM_CODE"];
const REACT_APP_CASHIN_PAYMENT_ITEM_CODE =
  process.env["REACT_APP_CASHIN_PAYMENT_ITEM_CODE"];
const REACT_APP_NIP_TRANSFERS_PAYMENT_ITEM_CODE =
  process.env["REACT_APP_NIP_TRANSFERS_PAYMENT_ITEM_CODE"];
const REACT_APP_DISTRIBUTE_PAYMENT_ITEM_CODE =
  process.env["REACT_APP_DISTRIBUTE_PAYMENT_ITEM_CODE"];
const REACT_APP_FUND_WALLET_URL = process.env["REACT_APP_FUND_WALLET_URL"];

const REACT_APP_SHOW_CARDLESS_CASHOUT =
  process.env["REACT_APP_SHOW_CARDLESS_CASHOUT"];
const REACT_APP_SHOW_CASH_IN = process.env["REACT_APP_SHOW_CASH_IN"];
const REACT_APP_SHOW_OPEN_ACCOUNT = process.env["REACT_APP_SHOW_OPEN_ACCOUNT"];
const REACT_APP_SHOW_STATIC_ACCOUNT =
  process.env["REACT_APP_SHOW_STATIC_ACCOUNT"];
const REACT_APP_SHOW_CARD_LINKING = process.env["REACT_APP_SHOW_CARD_LINKING"];

const REACT_APP_SEND_MONEY_TABS_TO_HIDE =
  process.env["REACT_APP_SEND_MONEY_TABS_TO_HIDE"];
const REACT_APP_CARDLESS_CASHOUT_TABS_TO_HIDE =
  process.env["REACT_APP_CARDLESS_CASHOUT_TABS_TO_HIDE"];
const REACT_APP_WHITELISTED_NUMBERS =
  process.env["REACT_APP_WHITELISTED_NUMBERS"];

export const AUDIT_TRAIL_SERVICE_API_BASE_URL =
  REACT_APP_AUDIT_TRAIL_SERVICE_API_BASE_URL;

export const ONBOARDING_API_BASE_URL = REACT_APP_ONBOARDING_API_BASE_URL;

export const PLATFORM_API_BASE_URL = REACT_APP_PLATFORM_API_BASE_URL;

export const SETTLEMENT_API_BASE_URL = REACT_APP_SETTLEMENT_API_BASE_URL;

export const TRANSACTION_API_BASE_URL = REACT_APP_TRANSACTION_API_BASE_URL;

export const TRANSACTION_API_BASE_URL_IKEJA =
  REACT_APP_TRANSACTION_API_BASE_URL_IKEJA;

export const NIP_TRANSACTION_API_BASE_URL = NIP_SERVICE_API_BASE_URL;

export const CRM_SERVICES_API_BASE_URL = CRM_SERVICE_API_BASE_URL;

export const DOCUMENT_UPLOAD_BASE_URL = DOCUMENT_BASE_URL

export const BANK_NETWORK_MONITORING_SERVICE_API_BASE_URL =
  BANK_MONITORING_SERVICE_API_BASE_URL;

export const TRANSACTION_API_BASE_URL_PRIME =
  REACT_APP_TRANSACTION_API_BASE_URL_PRIME;

export const USER_MANAGEMENT_API_BASE_URL =
  REACT_APP_USER_MANAGEMENT_API_BASE_URL;

export const TRANSACTION_HISTORY_API_BASE_URL =
  REACT_APP_TRANSACTION_HISTORY_API_BASE_URL;

export const FINCH_LIQUIDITY_SERVICE_API_BASE_URL =
  REACT_APP_FINCH_LIQUIDITY_SERVICE_API_BASE_URL;

export const FINCH_LIQUIDITY_SERVICE_API_BASE_URL_PRIME =
  REACT_APP_FINCH_LIQUIDITY_SERVICE_API_BASE_URL_PRIME;

export const HISTORICAL_DATA_SERVICE_API_BASE_URL =
  REACT_APP_HISTORICAL_DATA_SERVICE_API_BASE_URL;

export const HOSTED_FIELDS_SDK = REACT_APP_HOSTED_FIELDS_SDK;

export const PRIVACY_POLICY_URL = REACT_APP_PRIVACY_POLICY_URL;

export const HELP_PORTAL = REACT_APP_HELP_PORTAL;

export const QUICKTELLER_NEXT_API_BASE_URL = QUICKTELLER_BASE_URL;
export const CATALOG_NEXT_API_BASE_URL = CATALOG_BASE_URL;

export const RECOVER_PASSWORD_REDIRECT_URL =
  REACT_APP_RECOVER_PASSWORD_REDIRECT_URL;

export const QUICKTELLER_CHANNEL = REACT_APP_QUICKTELLER_CHANNEL;

export const QUICKTELLER_TERMINAL_ID = REACT_APP_QUICKTELLER_TERMINAL_ID;

export const TRANSFERS_PAYMENT_ITEM_CODE =
  REACT_APP_TRANSFERS_PAYMENT_ITEM_CODE;

export const CASHIN_PAYMENT_ITEM_CODE = REACT_APP_CASHIN_PAYMENT_ITEM_CODE;

export const NIP_TRANSFERS_PAYMENT_ITEM_CODE =
  REACT_APP_NIP_TRANSFERS_PAYMENT_ITEM_CODE;

export const DISTRIBUTE_PAYMENT_ITEM_CODE =
  REACT_APP_DISTRIBUTE_PAYMENT_ITEM_CODE;

export const FUND_WALLET_URL = REACT_APP_FUND_WALLET_URL;

export const SHOW_CARDLESS_CASHOUT = JSON.parse(
  REACT_APP_SHOW_CARDLESS_CASHOUT
);

export const SHOW_CASH_IN = JSON.parse(REACT_APP_SHOW_CASH_IN);

export const SHOW_OPEN_ACCOUNT = JSON.parse(REACT_APP_SHOW_OPEN_ACCOUNT);

export const SHOW_FUND_VIA_USSD = JSON.parse(SHOW_FUND_VIA_USSD_);
export const SHOW_STATIC_ACCOUNT = JSON.parse(REACT_APP_SHOW_STATIC_ACCOUNT);

export const SHOW_CARD_LINKING = JSON.parse(REACT_APP_SHOW_CARD_LINKING);

export const SEND_MONEY_TABS_TO_HIDE = REACT_APP_SEND_MONEY_TABS_TO_HIDE;

export const CARDLESS_CASHOUT_TABS_TO_HIDE =
  REACT_APP_CARDLESS_CASHOUT_TABS_TO_HIDE;

export const WHITELISTED_NUMBERS = REACT_APP_WHITELISTED_NUMBERS;
