import React, { useState } from "react";
import { Button, Form, FormControl } from "react-bootstrap";

const RemapRequest = ({
  handleShowTerminal,
  isLoading,
  fetchAgentByPhoneNumber,
  agentToMap,
}) => {
  const ptag = { color: "#111827", fontWeight: 400 };
  const spanTag = {
    fontSize: "14px",
    fontWeight: 400,
    color: "#6B7280",
  };
  const [agentNumber, setAgentNumber] = useState("");

  return (
    <div>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div style={{ display: "flex" }}>
          <div>
            <Form.Group style={{ padding: 0 }} controlId="Terminal-id">
              <Form.Label>Agent to Map </Form.Label>
              <FormControl
                type="tel"
                placeholder="2348032485788"
                onChange={(e) => setAgentNumber(e.target.value)}
                className="posInput"
              />
            </Form.Group>
          </div>
          <div>
            <Button
              className="posButton"
              onClick={() => {
                fetchAgentByPhoneNumber(agentNumber);
              }}
            >
              {isLoading ? "Loading..." : "SUBMIT"}
            </Button>
          </div>
        </div>
      </Form>
      {agentToMap.content && agentToMap.content.length > 0 && (
        <>
          <h2
            style={{
              fontSize: "16px",
              fontWeight: "600",
              marginBottom: "15px",
              marginTop: "20px",
            }}
          >
            Agent Information
          </h2>
          <section className="posSection">
            <div className="posArticle">
              <div>
                <span style={spanTag}>Agent Name</span>
                <p style={ptag}>
                  {agentToMap.content[0].contact.firstname}{" "}
                  {agentToMap.content[0].contact.middlename}{" "}
                  {agentToMap.content[0].contact.lastname}
                </p>
              </div>
              <div>
                <span style={spanTag}>Agent Address</span>
                <p style={ptag}>
                  {agentToMap.content[0].businessLocation.addressLine1}
                </p>
              </div>

              <div>
                <span style={spanTag}>Status</span>
                <p style={ptag}>{agentToMap.content[0].status}</p>
              </div>
            </div>
            <div className="posArticle">
              <div>
                <span style={spanTag}>Agent Phone Number</span>
                <p style={ptag}>{agentToMap.content[0].agentMobileNo}</p>
              </div>
              <div>
                <span style={spanTag}>Email Address</span>
                <p style={ptag}>{agentToMap.content[0].businessEmail}</p>
              </div>
              <div>
                <span style={spanTag}>State</span>
                <p style={ptag}>
                  {agentToMap.content[0].businessLocation.state}
                </p>
              </div>
            </div>
          </section>

          <div
            style={{
              display: "flex",
            }}
          >
            <Button
              style={{
                fontSize: "14px",
                borderRadius: "5px",
                width: "144px",
                height: "48px",
                backgroundColor: "#00B8DE",
                color: "white",
              }}
              onClick={() => handleShowTerminal()}
            >
              SUBMIT
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default RemapRequest;
