import React from 'react';

const Layout = ({ children, title }) => {
  return (
    <div style={{ margin: '30px 0 40px 30px', width: '79vw' }}>
      <h1
        style={{
          marginBottom: '30px',
          color: 'rgb(0,66,94)',
          fontSize: '24px',
          fontWeight: 'bold',
        }}
      >
        {title}
      </h1>
      <div>{children}</div>
    </div>
  );
};

export default Layout;
