import React, { useEffect, useState } from "react";
import { BalanceCard } from "../../components/balanceCard";
import Spinner from "../../../../../../../components/spinner";
import { getPaginationRows } from "../../../../../../../utils/helpers";
import ReactTable from "react-table";
import { Spacer } from "../../components/extras";
import MdMore from "react-ionicons/lib/MdMore";
import { Dropdown, Form, Modal } from "react-bootstrap";
import SelectInput from "../../../component/select";
import PrimaryButton from "../../../component/button";
import InputText from "../../../component/input";
import { useHistory } from "react-router-dom";
import { userManagementServiceClient } from "../../../../../../../App";
import {
  ERROR_STATUS,
  SUCCESS_STATUS,
} from "../../../../../../../constants/api";
import { formatAmount, formatDate } from "../../../../../../../utils/formatters";
import { convertNgkToNgn } from "../../../../../../../utils/converters/currencies";
import { statusBackgroundColorMyRequest, statusBorderColorMyRequest, statusColorMyRequest } from "../../../component/extras";

const MyRequests = () => {
  const history = useHistory();
  
  const [selectedFilter, setSelectedFilter] = useState("Pending");
  
  const [requestStatus, setRequestStatus] = useState([]);
  const [requestTable, setRequestTable] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pages, setPages] = useState(1);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [dataCount, setDataCount] = useState();

  const [isLoadingStatus, setIsLoadingStatus] = useState(true);
  const [showReject, setShowReject] = useState(false);
  const [showAccept, setShowAccept] = useState(false);

  const handleModalStateAccept = () => setShowAccept(!showAccept);
  const handleModalStateReject = () => setShowReject(!showReject);


  const getAggregatorRequestStatus = async () => {
    const { response, status } =
      await userManagementServiceClient.getAggregatorRequest();

    if (status === ERROR_STATUS) {
      setIsLoading(false);
    } else if (status === SUCCESS_STATUS) {
      
      setRequestStatus(response?.data);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAggregatorRequestStatus();
  }, []);

  const MyRequestColumns = [
    {
      Header: (props) => <div style={{ textAlign: "left" }}>Request ID</div>,
      Cell: (props) => (
        <div
          className="wordbreak"
          style={{ textAlign: "left", wordBreak: "break-all" }}
        >
          {props.value}
        </div>
      ),
      accessor: "requestId",
      headerClassName: "table-header",
      className: "table-cell",
      style: { "white-space": "unset" },
      // width: 500,
    },

    {
      Header: (props) => <div style={{ textAlign: "left" }}>No of POS</div>,
      accessor: "quantity",
      Cell: (props) => <div style={{ textAlign: "left" }}>{props.value}</div>,
      headerClassName: "table-header",
      className: "table-cell",
      //   width: 300,

      style: { "white-space": "unset" },
    },
    {
      Header: (props) => <div style={{ textAlign: "left" }}>Amount Paid</div>,
      accessor: "totalPrice",
      Cell: (props) => <div style={{ textAlign: "left" }}>  ₦{`${formatAmount(convertNgkToNgn(props.value))}`}</div>,
      headerClassName: "table-header",
      className: "table-cell",
      //   width: 300,

      style: { "white-space": "unset" },
    },
    {
      Header: (props) => <div style={{ textAlign: "left" }}>Delivery code</div>,
      accessor: "shipmentCode",
      Cell: (props) => <div style={{ textAlign: "left" }}>{props.value}</div>,
      headerClassName: "table-header",
      className: "table-cell",
      //   width: 300,

      style: { "white-space": "unset" },
    },
    {
      Header: (props) => <div style={{ textAlign: "left" }}>Status</div>,
      accessor: "approvalStatus",
      Cell: (props) =>  <div
      style={{
        display: 'flex',
        alignItems: 'start',
      }}
    >
      <div
        style={{
          color: statusColorMyRequest(props.value),
          textAlign: 'left',
          textTransform: 'capitalize',

          display: 'flex',
          justifyContent: 'center',

          border: `1px solid ${statusBorderColorMyRequest(props.value)}`,
          padding: '0 7px',
          borderRadius: '5px',
          backgroundColor: statusBackgroundColorMyRequest(props.value),
        }}
      >
        <div
          style={{
            display: 'grid',
            placeContent: 'center',
            marginRight: '5px',
          }}
        >
          <div
            style={{
              width: '7px',
              height: '7px',
              borderRadius: '100%',

              display: 'grid',
              placeContent: 'center',
              backgroundColor: statusColorMyRequest(props.value),
            }}
          />
        </div>
        <div>{props.value}</div>
      </div>
    </div>,
      headerClassName: "table-header",
      className: "table-cell",
      //   width: 300,

      style: { "white-space": "unset" },
    },

    {
      Header: (props) => <div style={{ textAlign: "left" }}>Request Date</div>,
      accessor: "dateInitiated",
      Cell: (props) => <div style={{ textAlign: "left" }}>{formatDate(props.value)}</div>,
      headerClassName: "table-header",
      className: "table-cell",
      //   width: 300,

      style: { "white-space": "unset" },
    },
    {
      Header: (props) => <div style={{ textAlign: "left" }}></div>,
      accessor: "narration",
      Cell: (props) => (
        <Dropdown>
          <Dropdown.Toggle
            as={"span"}
            style={{
              cursor: "pointer",
            }}
            variant="secondary"
            id="dropdown-variants-secondary"
          >
            <MdMore fontSize="22px" color={process.env.REACT_APP_GREY_COLOUR} />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() =>
                history.push(
                  "/agent/pos-management/aggregator/pos-request/track-shipment"
                )
              }
            >
              View Details
            </Dropdown.Item>
            {/* <Dropdown.Item onClick={() => handleAccept(props)}>
              Accept Shipment
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleReject(props)}>
              Reject Shipment
            </Dropdown.Item> */}
          </Dropdown.Menu>
        </Dropdown>
      ),
      headerClassName: "table-header",
      className: "table-cell",
      //   width: 300,

      style: { "white-space": "unset" },
    },
  ];

  const handleFilterClick = (filterName) => {

    if (filterName === "My Requests") {
     
      setSelectedFilter("Pending");
    } else if (filterName === "Fulfilled") {
     
      setSelectedFilter("Fulfilled");
    } else if (filterName === "Rejected") {
    
      setSelectedFilter("Rejected");
    } else {
      setSelectedFilter("Pending");
     
    }
  };

  const getStatusInfo = async (filterStatus, pageNum_, pageSize_) => {
    setRequestTable([])
    setIsLoadingStatus(true);
    const payload = {
      pageSize: pageSize_,
      pageNumber: pageNum_,
      requestType: filterStatus,
    };

    const { response, status } =
      await userManagementServiceClient.getAggregatorStatus(payload);

    setIsLoadingStatus(false);
    if (status === ERROR_STATUS) {
      setPages(1);
    } else if (status === SUCCESS_STATUS) {
      setDataCount(response.data.count / pageSize);
      setPages(Math.ceil(response.data.count / pageSize));
   
      setRequestTable(response.data);
    }
  };

  useEffect(() => {
    if (requestStatus) {
      getStatusInfo(selectedFilter, pageNum, pageSize);
    }
  }, [selectedFilter, requestStatus]);

  const filterStyle = (filterName) => {
    return {
      cursor: "pointer",
      border:
        selectedFilter === filterName
          ? "1px solid #0275D8"
          : "1px solid #e5e7eb",
      backgroundColor: selectedFilter === filterName ? "#EBF8FE" : "white",
    };
  };
  return (
    <>
      <Spacer height={15} />
      <article className="agentType-container-card-commission-pos">
        <BalanceCard
          dynamicStyle={filterStyle("Pending")}
          onClick={() => handleFilterClick("Pending")}
          loading={isLoading}
          title={"My Requests"}
          balance={requestStatus?.pendingRequests || 0}
        />
        <BalanceCard
          dynamicStyle={filterStyle("Fulfilled")}
          onClick={() => handleFilterClick("Fulfilled")}
          loading={isLoading}
          title={"Fulfilled Requests"}
          balance={requestStatus?.deliveredRequests || 0}
        />
        <BalanceCard
          dynamicStyle={filterStyle("Rejected")}
          onClick={() => handleFilterClick("Rejected")}
          loading={isLoading}
          title={"Rejected"}
          balance={requestStatus?.rejectedRequests || 0}
        />
      </article>
      <Spacer height={15} />
      <article
        style={{
          padding: "20px",
        }}
      >
        <ReactTable
          style={{
            backgroundColor: "white",
          }}
          columns={MyRequestColumns}
          data={requestTable}
          dataCount={dataCount}
          pageSizes={getPaginationRows(dataCount)}
          pages={pages}
          defaultPageSize={10}
          onFetchData={(state, instance) => {
            getStatusInfo(selectedFilter, state.page + 1, state.pageSize);
          }}
          noDataText={isLoadingStatus ? "" : "No records found"}
          loading={isLoadingStatus}
          LoadingComponent={Spinner}
        />
      </article>

      <Modal centered show={showReject} onHide={handleModalStateReject}>
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              fontWeight: 700,
              fontSize: "16px",
            }}
          >
            Reject Shipment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              padding: "10px",
            }}
          >
            <SelectInput
              placeholder=""
              option={[
                "I don't like this POS",
                "Incomplete Order",
                "Faulty POS",
                "Not what i requested",
              ]}
              label="Reason for Rejection"
            />
            <Spacer height={"10px"} />
            <label style={{ fontSize: "14px" }}>Note</label>
            <Form.Control
              as="textarea"
              placeholder="Leave a comment here"
              style={{ height: "100px" }}
            />
            <Spacer height={"20px"} />
            <PrimaryButton backgroundColor={"#DC4437"} color="white" margin="0">
              Reject Shipment
            </PrimaryButton>
            <Spacer height={"10px"} />
            <PrimaryButton
              onClick={handleModalStateReject}
              backgroundColor={"transparent"}
              border={"1px solid #EAECF0"}
              margin="0"
              color="black"
            >
              Close
            </PrimaryButton>
          </div>
        </Modal.Body>
      </Modal>

      <Modal centered show={showAccept} onHide={handleModalStateAccept}>
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              fontWeight: 700,
              fontSize: "16px",
            }}
          >
            Accept Shipment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              padding: "10px",
            }}
          >
            <p
              style={{
                color: "#353F50",
                fontWeight: 400,
              }}
            >
              Please provide the delivery code to this shipment, You can view
              the details of this shipment to get the delivery code.
            </p>
            <Spacer height={"10px"} />
            <InputText
              label="Enter Shipment Code"
              placeholder="Please enter code"
            />
            <Spacer height={"20px"} />
            <PrimaryButton margin="0">Accept Shipment</PrimaryButton>
            <Spacer height={"10px"} />
            <PrimaryButton
              onClick={handleModalStateAccept}
              backgroundColor={"transparent"}
              border={"1px solid #EAECF0"}
              margin="0"
              color="black"
            >
              Close
            </PrimaryButton>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MyRequests;
