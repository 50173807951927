import React, { useState } from "react";

import { connect } from "react-redux";

import "../app.css";

import InventoryLabel from "../components/inventoryLabel";
import { updateLoading } from "../../../../../../services/redux/actions/tunnel";
import { navigateTo } from "../../../../../../services/redux/actions/navigation";
import { BalanceCard } from "../components/balanceCard";
import ReactTable from "react-table";
import { MyRequestColumns, TotalStockColumns } from "../components/data";
import { getPaginationRows } from "../../../../../../utils/helpers";
import Spinner from "../../../../../../components/spinner";
import { Nav, Tab } from "react-bootstrap";
import MyRequests from "./myRequests/myRequests";
import AgentsRequests from "./agentsRequests/agentsRequests";

const MyPosRequestScreen = (props) => {
  //   const fetchEarningBalance = async (dateIntervalType, startDate, endDate) => {
  //     setGetEarningBalance([]);
  //     setLoading(true);
  //     // const payload = {
  //     //   dateIntervalType: dateIntervalType,
  //     // };

  //     let payload = {};
  //     if (dateIntervalType) {
  //       payload = {
  //         dateIntervalType: dateIntervalType,
  //       };
  //     } else {
  //       payload = {
  //         from: specialDateRangeFormatter_StartDate(startDate),
  //         to: specialDateRangeFormatter_StartDate(endDate),
  //       };
  //     }
  //     const { status, response } = await transactionHistory.getEarnings(payload);

  //     setLoading(false);
  //     if (status === ERROR_STATUS) {
  //       // setError(true);
  //     } else if (status === SUCCESS_STATUS) {
  //       setLoading(false);

  //       setGetEarningBalance(response.data);
  //     } else {
  //       setLoading(false);
  //     }
  //   };

  return (
    <main className="main-container">
      <InventoryLabel />
      <Tab.Container id="left-tabs-example" defaultActiveKey={"first"}>
        <div className="tab-container-commission">
          <div>
            <Nav variant="pills" className="flex-row">
              <Nav.Item>
                <Nav.Link
                  style={{
                    color: process.env.REACT_APP_BLUE_COLOUR,
                  }}
                  eventKey="first"
                >
                  My Requests
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  style={{ color: process.env.REACT_APP_BLUE_COLOUR }}
                  eventKey="second"
                >
                  Agents Requests
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        <div>
          <Tab.Content>
            <Tab.Pane eventKey="first">
              <MyRequests />
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <AgentsRequests />
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
    </main>
  );
};

function mapStateToProps(state) {
  return {
    isLoading: state.tunnel.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateLoading: (isLoading) => dispatch(updateLoading(isLoading)),
    navigateTo: (pendingUrl) => dispatch(navigateTo(pendingUrl)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyPosRequestScreen);
