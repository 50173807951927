import React, { useContext, useEffect, useState } from 'react';

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [code, setCode] = useState('');
  const [application, setApplication] = useState();

  const [preview, setPreview] = useState({
    utility: null,
    passport: null,
    proofofIdentity: null,
    characterAttestation: null,
  });

  const [disabledField, setDisabledField] = useState(
    application?.applicantDetails?.phoneNumber || false
  );

  return (
    <AppContext.Provider
      value={{
        preview,
        disabledField,

        setDisabledField,

        setPreview,

        application,
        code,
        setCode,
      }}
    >
      <div>{children}</div>
    </AppContext.Provider>
  );
};
// make sure use
export const useAggregatorContext = () => {
  return useContext(AppContext);
};
export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebounceValue] = useState(value);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebounceValue(value);
    }, delay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [value, delay]);

  return debouncedValue;
};

export { AppContext, AppProvider };

