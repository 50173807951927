import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Stepper from "../../scenes/signup/onboarding-setup/component/stepper";

import BackButton from "../../scenes/signup/onboarding-setup/component/backButton";
import HeaderSignup from "../header-signup";
import { useLocation } from "react-router-dom";
import "./style.css";

import { notify } from "../extras";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../constants/api";
import CustomError from "./error";
import Platform from "../../services/api/resources/platform";
import Onboarding from "../../services/api/resources/onboarding";
import { loadAgent } from "./mockApi";

const FaceVerificationScene = () => {
  const location = useLocation();
  const platform = new Platform();
  const onboarding = new Onboarding();
  const data = location.state;

  const history = useHistory();

  const [error, setError] = useState(false);
  const [failed, setFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "Unable to complete facial verification."
  );

  const [isLoading, setIsLoading] = useState(false);

  const navigateToBvnValidation = () => {
    history.push("/bvn-validation");
  };

  const manualBvnVerification = async () => {
    // fallback bvn endpoint will be called after all retries
    const { status, response } = await onboarding.bvnConfirmation(data.bvn);

    setIsLoading(false);
    if (status === ERROR_STATUS) {
      setError(true);
      setErrorMessage("Verification failed");
    } else if (status === SUCCESS_STATUS) {
      history.push({
        pathname: "/nin-verification",
        state: { disableBackButton: true },
      });
    }
  };

  const handleStatus = async () => {
    setIsLoading(true);

    let success = false;

    while (!success) {
      const { status, response } = await platform.submitStatusImageCapturing(
        data.jobId
      );

      if (status === SUCCESS_STATUS && response.description === "Successful") {
        // await loadAgent();
        success = true;
        notify("Your face ID verification was successful", "success");

        history.push({
          pathname: "/nin-verification",
          state: { disableBackButton: true },
        });
        break;
      } else if (
        status === SUCCESS_STATUS &&
        response.description === "Pending"
      ) {
        await new Promise((resolve) => setTimeout(resolve, 10000));
      } else if (response.description === "Failed") {
        setIsLoading(false);
        setError(true);
        setFailed(true);
        setErrorMessage(
          "We couldn’t validate your BVN this time, kindly try again later"
        );

        break;
      }
    }
  };

  const handleUpload = async (images) => {
    setIsLoading(true);

    const payload = {
      jobId: data.jobId,
      bvnNumber: +data.bvn,
      imageRequest: { images: images },
    };
    const { status, response } = await platform.submitUploadImageCapturing(
      payload
    );
    setIsLoading(false);
    if (status === ERROR_STATUS) {
      setError(true);
    } else if (status === SUCCESS_STATUS) {
      handleStatus();
    }
  };

  useEffect(() => {
    const app = document.querySelector("smart-camera-web");

    const postContent = async (data) => {
      await handleUpload(data.detail.images);
    };

    if (app) {
      app.addEventListener("imagesComputed", postContent);
    }

    return () => {
      if (app) {
        app.removeEventListener("imagesComputed", postContent);
      }
    };
  }, [error]);

  const headerStyle = {
    fontWeight: "bold",
    padding: "0 20px",
    marginBottom: ".5rem",
    marginTop: "3rem",
    fontSize: "1.5rem",
  };

  const checkAuthentication = () => {
    const isAuthenticated = localStorage.getItem("auth_token") === null;

    if (isAuthenticated) {
      history.push("/login");
      localStorage.clear();
    }
  };

  useEffect(() => {
    checkAuthentication();
  }, []);

  // let kycCheckListDetails = JSON.parse(localStorage.getItem("currentAgent"));

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p
            style={{
              textAlign: "center",
              fontSize: "14px",
              color: "white",
              paddingTop: "10px",
              width: "20rem",
            }}
          >
            Please wait for about 3-5 minutes while we validate your details. Do
            not close your browser.
          </p>
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          gap: "40px",
          margin: "2rem 0 0 2rem",
        }}
      >
        <HeaderSignup />

        {/* {kycCheckListDetails?.kycCheckList?.bvnNinMatch === false && (
          <BackButton onClick={() => navigateToBvnValidation()} />
        )} */}
      </div>
      <main className="onboarding-container">
        <div style={{ alignContent: "center" }}>
          {error ? (
            <>
              <CustomError
                isLoading={false}
                checkFailure={failed}
                errorMessage={errorMessage}
                onRetry={() => {
                  failed ? history.push("/dashboard") : setError(false);
                }}
              />
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  gap: "10px",
                  margin: "0 20px 10px 20px",
                }}
              >
                <Stepper borderColor="#00425F" />
                <Stepper borderColor="#00425F" />
                <Stepper />
                <Stepper />
                <Stepper />
              </div>
              <h1 style={headerStyle}>Face ID Verification</h1>
              <p
                style={{
                  color: "#5F738C",
                  fontWeight: "500",
                  fontSize: "0.8rem",
                  letterSpacing: "0.3px",
                  padding: "0 20px",
                }}
              >
                Ensure your camera is steady and your face fits into the box
                below
              </p>
              <div
                style={{
                  margin: "1rem",
                }}
              >
                <smart-camera-web hide-attribution></smart-camera-web>
              </div>
              <button
                className="signupButton2"
                style={{
                  marginTop: "1rem",
                  textAlign: "center",
                  color: "black",
                  alignContent: "center",
                }}
                onClick={() => {
                  history.push("/dashboard");
                }}
              >
                Continue Later
              </button>
            </>
          )}
        </div>
      </main>
    </>
  );
};

export default FaceVerificationScene;
