import React, { useState, useEffect } from "react";
import "./style.css";
import {
  arrowBackIcon,
  bookedIcon,
  passengerIcon,
  SeatBookedIcon,
  seatIcon,
  selectedIcon,
  steeringIcon,
} from "../../../../../../icons";
import { Button, Modal, Spinner } from "react-bootstrap";
import { notify } from "../../../../../../components/extras";
import { SUCCESS_STATUS } from "../../../../../../constants/api";
import Quickteller from "../../../../../../services/api/resources/quickteller";
import { formatPhoneNumberWithCountryCode } from "../../../../../../utils/formatters";
import Transaction from "../../../../../../services/api/resources/transaction";
import { convertNgnToNgk } from "../../../../../../utils/converters/currencies";
import { QUICKTELLER_TERMINAL_ID } from "../../../../../../utils/api-properties";
import { generateChecksum } from "../../../../../../utils/helpers";
import PropTypes from "prop-types";

const PassengerDetails = ({
  setScreen,
  selectedTrip,
  adultCount,
  childCount,
  isForself,
  setBookings,
  allPassengers,
  setAllPassengers,
  setPassengers,
}) => {
  const currentAgent = JSON.parse(localStorage.getItem("currentAgent"));
  const transaction = new Transaction();
  const deviceId = localStorage.getItem("deviceUid");

  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const httpMethod = "POST";
  const username = currentUser.username;
  const quickteller = new Quickteller();
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [showSeatModal, setShowSeatModal] = useState(false);
  const [scheduleId, setScheduleId] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isRevertSeat, setIsRevertSeat] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [currentPassengerIndex, setCurrentPassengerIndex] = useState(0);
  const [isComplete, setIsComplete] = useState(false);
  const [isSearchSeats, setIsSearchSeats] = useState(false);
  const [seats, setSeats] = useState([]);
  const [passengerData, setPassengerData] = useState({
    firstName: "",
    lastName: "",
    gender: "Male",
    phoneNumber: "",
    type: "Adult",
    nextOfKin: {
      usePrevious: false,
      firstName: "",
      lastName: "",
      phoneNumber: "",
    },
  });

  function extractNames(accountName) {
    const names = accountName.trim().split(/\s+/);

    let firstName, lastName;

    if (names.length === 1) {
      firstName = names[0];
      lastName = "";
    } else if (names.length >= 2) {
      firstName = names[0];
      lastName = names[names.length - 1];
    }

    return { firstName, lastName };
  }

  const totalPassengers = adultCount + childCount;

  useEffect(() => {
    if (isForself && currentAgent?.accountName && currentPassengerIndex === 0) {
      const { firstName, lastName } = extractNames(currentAgent.accountName);
      setPassengerData((prev) => ({
        ...prev,
        firstName,
        lastName,
        phoneNumber: currentAgent?.agentMobileNo,
      }));
    }

    setPassengerData((prev) => ({
      ...prev,
      type: currentPassengerIndex < adultCount ? "Adult" : "Child",
    }));

    if (allPassengers.length === totalPassengers && !isEditing) {
      setIsComplete(true);
    } else {
      setIsComplete(false);
    }
  }, [
    currentPassengerIndex,
    adultCount,
    allPassengers.length,
    totalPassengers,
    isEditing,
    isForself,
    currentAgent?.accountName,
    currentAgent?.agentMobileNo,
  ]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPassengerData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleNextOfKinChange = (e) => {
    const { name, value } = e.target;
    setPassengerData((prev) => ({
      ...prev,
      nextOfKin: {
        ...prev.nextOfKin,
        [name]: value,
      },
    }));
  };

  const handleUsePreviousToggle = (e) => {
    const isChecked = e.target?.checked;

    if (allPassengers.length > 0) {
      const lastPassenger = allPassengers[allPassengers.length - 1];

      setPassengerData((prev) => ({
        ...prev,
        nextOfKin: {
          ...prev.nextOfKin,
          usePrevious: isChecked,
          firstName: isChecked ? lastPassenger.nextOfKin.firstName : "",
          lastName: isChecked ? lastPassenger.nextOfKin.lastName : "",
          phoneNumber: isChecked ? lastPassenger.nextOfKin.phoneNumber : "",
        },
      }));
    }
  };

  const handleAddPassenger = (e) => {
    e.preventDefault();

    if (isEditing && editingIndex !== null) {
      const updatedPassengers = [...allPassengers];
      updatedPassengers[editingIndex] = passengerData;

      setAllPassengers(updatedPassengers);
      setIsEditing(false);
      setEditingIndex(null);
    } else {
      const newPassengers = [...allPassengers, passengerData];
      setAllPassengers(newPassengers);

      if (newPassengers.length >= totalPassengers) {
        setIsComplete(true);
      } else {
        setCurrentPassengerIndex((prev) => prev + 1);

        setPassengerData({
          firstName: "",
          lastName: "",
          gender: "Male",
          phoneNumber: "",
          type: currentPassengerIndex + 1 < adultCount ? "Adult" : "Child",
          nextOfKin: {
            usePrevious: false,
            firstName: "",
            lastName: "",
            phoneNumber: "",
          },
        });
      }
    }
  };

  const handleViewPassenger = (index) => {
    const passengerToEdit = allPassengers[index];
    setPassengerData({ ...passengerToEdit });
    setEditingIndex(index);
    setIsEditing(true);
    setIsComplete(false);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditingIndex(null);

    if (allPassengers.length >= totalPassengers) {
      setIsComplete(true);
    } else {
      setPassengerData({
        firstName: "",
        lastName: "",
        gender: "Male",
        phoneNumber: "",
        type: currentPassengerIndex < adultCount ? "Adult" : "Child",
        nextOfKin: {
          usePrevious: false,
          firstName: "",
          lastName: "",
          phoneNumber: "",
        },
      });
    }
  };

  const handleOpenModal = () => {
    setShowSeatModal(true);
  };

  const handleCloseModal = () => {
    setShowSeatModal(false);
  };

  const handleChooseSeats = async () => {
    const payload = {
      tripId: selectedTrip?.id,
      providerId: selectedTrip?.providerId,
      operatorCode: selectedTrip?.operatorCode,
    };

    setIsSearchSeats(true);
    const { status, response } = await quickteller.getSeatsLayout(payload);

    setIsSearchSeats(false);
    if (status === SUCCESS_STATUS) {
      setSeats(response.seats);
      setScheduleId(response.scheduleId);
      handleOpenModal();
    } else {
      notify(response?.responseDescription, "error");
    }
  };

  const handleSeatClick = (seat) => {
    if (seat.available === true) {
      if (selectedSeats.length < totalPassengers) {
        setSeats((prevSeats) =>
          prevSeats.map((s) =>
            s.seatNumber === seat.seatNumber
              ? { ...s, available: "selected" }
              : s
          )
        );
        setSelectedSeats((prevSelected) => [...prevSelected, seat.seatNumber]);
      } else {
        const oldestSelectedSeat = selectedSeats[0];
        setSeats((prevSeats) =>
          prevSeats.map((s) =>
            s.seatNumber === oldestSelectedSeat
              ? { ...s, available: true }
              : s.seatNumber === seat.seatNumber
              ? { ...s, available: "selected" }
              : s
          )
        );
        setSelectedSeats((prevSelected) => [
          ...prevSelected.slice(1),
          seat.seatNumber,
        ]);
      }
    } else if (seat.available === "selected") {
      setSeats((prevSeats) =>
        prevSeats.map((s) =>
          s.seatNumber === seat.seatNumber ? { ...s, available: true } : s
        )
      );
      setSelectedSeats((prevSelected) =>
        prevSelected.filter((id) => id !== seat.seatNumber)
      );
    }
  };

  const showPreviousNextOfKinOption = allPassengers.length > 0;
  const currentPassengerNumber = currentPassengerIndex + 1;

  const totalAmount = allPassengers.reduce((sum, passenger) => {
    const passengerPrice =
      passenger.type === "Adult"
        ? selectedTrip.adultPrice.originalPrice
        : selectedTrip.childPrice.originalPrice;

    return sum + Number(passengerPrice);
  }, 0);

  const handleReserve = async () => {
    const payload = {
      transactionType: "QT_TRANSPORT",
      transportRequest: {
        amount: convertNgnToNgk(totalAmount),
        paymentItemCode: process.env.REACT_APP_QTTRANSPORT_PAYMENT_ITEM_CODE,
        terminalId: QUICKTELLER_TERMINAL_ID,
        paymentInstrumentType: "CASH",
        customerMsisdn: allPassengers[0].phoneNumber,
        customerId: allPassengers[0].phoneNumber,
        bookingRequest: {
          providerId: String(selectedTrip.providerId),
          operatorCode: selectedTrip.operatorCode,
          scheduleId: scheduleId,
          noOfSeats: adultCount + childCount,
          travelDate: selectedTrip.departureDate,
          adultMetaData: selectedTrip.adultMetaData,
          childMetaData: selectedTrip.childMetaData,
          passengers: allPassengers.map((passenger, index) => ({
            seatNumber: String(selectedSeats[index]),
            amount:
              passenger.type === "Adult"
                ? selectedTrip.adultPrice.originalPrice
                : selectedTrip.childPrice.originalPrice,
            title: passenger.gender === "Male" ? "Mr" : "Ms",
            firstName: passenger.firstName,
            lastName: passenger.lastName,
            phoneNumber: formatPhoneNumberWithCountryCode(
              passenger.phoneNumber
            ),
            email: currentAgent?.businessEmail,
            gender: passenger.gender === "Male" ? "M" : "F",
            primary: true,
            passengerType: passenger.type,
            discountedAmount:
              passenger.type === "Adult"
                ? selectedTrip.adultPrice.discountedPrice
                : selectedTrip.childPrice.discountedPrice,
            nok: {
              firstName: passenger.nextOfKin.firstName,
              lastName: passenger.nextOfKin.lastName,
              mobileNumber: passenger.nextOfKin.phoneNumber,
              email: currentAgent?.businessEmail,
            },
          })),
        },
      },
      checksum: generateChecksum(
        `${username}${httpMethod}${convertNgnToNgk(totalAmount)}${httpMethod}${
          process.env.REACT_APP_QTTRANSPORT_PAYMENT_ITEM_CODE
        }${httpMethod}${deviceId}`
      ),
    };

    setIsRevertSeat(true);
    const { status, response } = await transaction.initiateTransportPayment(
      payload,
      deviceId
    );

    setIsRevertSeat(false);
    if (status === SUCCESS_STATUS) {
      setBookings(response);
      notify(
        `Seat number${totalPassengers > 1 ? "s" : ""} successfully reserved`,
        "success"
      );
      setPassengers(payload.passengers);
      handleCloseModal();
      setScreen(6);
    } else {
      notify(response?.description, "error");
    }
  };
  const isFirstPassengerAndSelf = isForself && currentPassengerIndex === 0;

  const getSeatStatus = (seat) => {
    if (seat.available === false) return "booked";
    if (selectedSeats.includes(seat.seatNumber)) return "selected";
    return "available";
  };
  return (
    <div className="booking-container">
      <button
        className="flex items-center text-blue-600 mb-4 font-medium back-button"
        onClick={() => {
          setPassengerData({
            firstName: "",
            lastName: "",
            gender: "Male",
            phoneNumber: "",
            type: "Adult",
            nextOfKin: {
              usePrevious: false,
              firstName: "",
              lastName: "",
              phoneNumber: "",
            },
          });
          setAllPassengers([]);
          setScreen(3);
        }}
      >
        {arrowBackIcon()} Back
      </button>

      <div
        style={{
          marginBottom: "40px",
        }}
      >
        <p
          style={{
            fontSize: "20px",
            fontWeight: 600,
            color: "#353F50",
          }}
        >
          Passenger Details
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <p className="container-header--inner">
            Who will you be traveling with?
          </p>
          <span
            style={{
              fontSize: "14px",
            }}
          >
            Enter passenger details
          </span>
        </div>
      </div>

      <div className="container--inner">
        {allPassengers.length > 0 && (
          <div
            className={
              isComplete ? "passenger-details-completed" : "passenger-details"
            }
          >
            <div>
              {allPassengers.map((passenger, index) => (
                <div key={index}>
                  <div className="passenger-details-info">
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      <span className="text-blue-500 text-xl">
                        {passengerIcon()}
                      </span>
                      <div>
                        <p className="text-gray-500 mb-1  font-weight-bold">
                          Passenger {index + 1}
                        </p>
                        <p className=" m-0">{passenger.type}</p>
                      </div>
                    </div>

                    <div>
                      <p className="font-weight-bold mb-1">
                        {passenger.firstName} {passenger.lastName}
                      </p>
                      <p className="text-gray-500 text-sm m-0">
                        {passenger.phoneNumber}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        style={{
                          fontSize: "12px",
                        }}
                        onClick={() => handleViewPassenger(index)}
                        className="view-button"
                      >
                        View
                      </button>
                    </div>
                  </div>
                  <hr />
                </div>
              ))}
            </div>
          </div>
        )}

        {!isComplete && (
          <div className="passenger-form">
            <p
              style={{
                color: "#353F50",
                fontSize: "16px",
                fontWeight: "600",
                margin: 0,
              }}
            >
              {isEditing ? (
                <>
                  Passenger {editingIndex + 1} of {totalPassengers}
                </>
              ) : (
                <>
                  Passenger ({currentPassengerNumber} of {totalPassengers})
                </>
              )}
            </p>
            <p style={{ fontSize: "14px" }}>{passengerData.type} Passenger</p>
            <form onSubmit={handleAddPassenger}>
              <div className="passenger-form--inner">
                <div className="mb-3">
                  <label className="title">First Name</label>
                  <input
                    type="text"
                    className="input"
                    name="firstName"
                    disabled={isFirstPassengerAndSelf}
                    value={passengerData.firstName}
                    onChange={handleInputChange}
                    placeholder="First Name"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className="title">Last Name</label>
                  <input
                    className="input"
                    type="text"
                    name="lastName"
                    value={passengerData.lastName}
                    onChange={handleInputChange}
                    disabled={isFirstPassengerAndSelf}
                    placeholder="Last Name"
                    required
                  />
                </div>
              </div>
              <div className="gender-container mb-3">
                <label className="title">Gender</label>
                <div className="gender">
                  <label>
                    <input
                      type="radio"
                      name="gender"
                      value="Male"
                      checked={passengerData.gender === "Male"}
                      onChange={handleInputChange}
                    />
                    Male
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="gender"
                      value="Female"
                      checked={passengerData.gender === "Female"}
                      onChange={handleInputChange}
                    />
                    Female
                  </label>
                </div>
              </div>

              <div className="mb-3">
                <label className="title">Phone Number</label>
                <input
                  type="number"
                  className="phone-input"
                  name="phoneNumber"
                  value={passengerData.phoneNumber}
                  onChange={handleInputChange}
                  disabled={isFirstPassengerAndSelf}
                  placeholder="Phone Number"
                  required
                />
              </div>

              {showPreviousNextOfKinOption && !isEditing && (
                <div className="mb-3">
                  <label className="">
                    <input
                      type="checkbox"
                      checked={passengerData.nextOfKin.usePrevious}
                      onChange={handleUsePreviousToggle}
                    />
                    <span className="addNextTxt">
                      Add Next Of Kin from previous info
                    </span>
                  </label>
                </div>
              )}

              <div className="mb-6">
                <div className="passenger-form--inner">
                  <div className="space-y-2">
                    <label className="title">Next of Kin First Name</label>
                    <input
                      type="text"
                      name="firstName"
                      placeholder="Firstname"
                      value={passengerData.nextOfKin.firstName}
                      onChange={handleNextOfKinChange}
                      className="input"
                      disabled={passengerData.nextOfKin.usePrevious}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label className="title">Next of Kin Last Name</label>
                    <input
                      type="text"
                      name="lastName"
                      placeholder="Lastname"
                      value={passengerData.nextOfKin.lastName}
                      onChange={handleNextOfKinChange}
                      className="input"
                      disabled={passengerData.nextOfKin.usePrevious}
                      required
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <label className="title">Next of Kin Phone Number</label>
                  <input
                    type="number"
                    name="phoneNumber"
                    placeholder="Phone Number"
                    value={passengerData.nextOfKin.phoneNumber}
                    onChange={handleNextOfKinChange}
                    className="phone-input"
                    disabled={passengerData.nextOfKin.usePrevious}
                    required
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",

                  gap: "20px",
                }}
              >
                {isEditing && (
                  <button
                    type="button"
                    className="cancel-btn"
                    onClick={handleCancelEdit}
                  >
                    Cancel
                  </button>
                )}
                <button type="submit" className="book-btn">
                  {isEditing ? "Update" : "Add"}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
      {isComplete && (
        <div
          style={{
            display: "flex",
            marginTop: "1rem",
            justifyContent: "flex-end",
          }}
        >
          <Button
            style={{
              fontSize: "14px",
              borderRadius: "8px",
              width: "205px",
              height: "44px",
              border: "none",
              backgroundColor: "#0275D8",
              color: "white",
              cursor: "pointer",
            }}
            onClick={handleChooseSeats}
            disabled={isSearchSeats}
          >
            {isSearchSeats ? (
              <Spinner
                size="sm"
                animation="border"
                variant="light"
                role="status"
                style={{
                  width: "20px",
                  height: "20px",
                }}
              />
            ) : (
              "Choose Seats"
            )}
          </Button>
        </div>
      )}

      <Modal
        show={showSeatModal}
        onHide={handleCloseModal}
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton onClick={handleCloseModal}>
          <Modal.Title
            style={{
              fontSize: "16px",
              fontWeight: 600,
            }}
          >
            Choose Seat
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            padding: "2rem",
          }}
        >
          <div className="seat-tab">
            <div className="flex  gap-2">
              <span>{seatIcon()}</span>
              <span className="text-sm">Available</span>
            </div>
            <div className="flex items-center gap-2">
              <span>{bookedIcon()}</span>
              <span className="text-sm">Booked</span>
            </div>
            <div className="flex items-center gap-2">
              <span>{selectedIcon()}</span>
              <span className="text-sm">Selected</span>
            </div>
          </div>

          <div className="seat-grid">
            <span className="steering">{steeringIcon()}</span>
            {seats?.map((seat) => (
              <button
                key={seat.seatNumber}
                className={`seat seat-${seat.seatNumber} ${seat.available} ${
                  selectedSeats.includes(seat.seatNumber) ? "selected" : ""
                }`}
                onClick={() => handleSeatClick(seat)}
                disabled={seat.available === false}
                aria-label={
                  seat.available === false
                    ? `Seat ${seat.seatNumber} is booked`
                    : selectedSeats.includes(seat.seatNumber)
                    ? `Deselect seat ${seat.seatNumber}`
                    : `Select seat ${seat.seatNumber}`
                }
                style={{
                  cursor: seat.available === false ? "not-allowed" : "pointer",
                  border: "none",
                  padding: "0",
                  background: "transparent",
                }}
              >
                <SeatBookedIcon
                  status={getSeatStatus(seat)}
                  seatId={seat.seatNumber}
                />
              </button>
            ))}
          </div>

          <button
            onClick={handleReserve}
            className={`book-btn mt-3 ${
              selectedSeats.length === totalPassengers ? "enabled" : "disabled"
            }`}
            disabled={selectedSeats.length !== totalPassengers || isRevertSeat}
          >
            {isRevertSeat ? (
              <Spinner
                size="sm"
                animation="border"
                variant="light"
                role="status"
                style={{
                  width: "20px",
                  height: "20px",
                }}
              />
            ) : (
              "Reserve"
            )}
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

PassengerDetails.propTypes = {
  setScreen: PropTypes.func.isRequired,
  selectedTrip: PropTypes.shape({
    id: PropTypes.string,
    providerId: PropTypes.string,
    operatorCode: PropTypes.string,
    departureDate: PropTypes.string,
    adultPrice: PropTypes.shape({
      originalPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      discountedPrice: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
    childPrice: PropTypes.shape({
      originalPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      discountedPrice: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
    adultMetaData: PropTypes.object,
    childMetaData: PropTypes.object,
  }).isRequired,
  adultCount: PropTypes.number.isRequired,
  childCount: PropTypes.number.isRequired,
  isForself: PropTypes.bool.isRequired,
  setBookings: PropTypes.func.isRequired,
  allPassengers: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      gender: PropTypes.oneOf(["Male", "Female"]).isRequired,
      phoneNumber: PropTypes.string.isRequired,
      type: PropTypes.oneOf(["Adult", "Child"]).isRequired,
      nextOfKin: PropTypes.shape({
        usePrevious: PropTypes.bool.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        phoneNumber: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  setAllPassengers: PropTypes.func.isRequired,
  setPassengers: PropTypes.func.isRequired,
};

PassengerDetails.defaultProps = {
  allPassengers: [],
};

export default PassengerDetails;
