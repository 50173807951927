import React from "react";
import { Redirect } from "react-router-dom";

import { PERSISTENT_DATA } from "../constants/persistent_data";
import { logoutUser, onSessionEnd } from "../utils/auth";
import BaseScene from "./base-scene";

export default class Logout extends BaseScene {
  isAuthRequired = false;

  preservePersistentInformation() {
    return PERSISTENT_DATA.map(
      (value) => `${value};${localStorage.getItem(value)}`
    );
  }

  restoreInformation(preservedInformation) {
    preservedInformation.map((value) => {
      const valueSplitted = value.split(";");
      const key = valueSplitted[0];
      const data = valueSplitted[1];
      if (data !== "null") {
        localStorage.setItem(key, data);
      }
    });
  }

  render() {
    logoutUser();

    const preservedInformation = this.preservePersistentInformation();
    localStorage.clear();
    this.restoreInformation(preservedInformation);

    onSessionEnd();

    return <Redirect to={`/login${window.location.search}`} />;
  }
}
