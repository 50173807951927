import React from "react";

import { AppProvider } from "../../../../../signup/onboarding-setup/context";
import PassengerDetailsScene from "./passengerDetailsScene";
import BookingScene from "./bookingScene";
import AvailableTripsScene from "./availableTripsScene";
import AddPassengerScene from "./addPassengerScene";

class QtTransport extends React.Component {
    constructor(props) {
        super(props);
        this.myElementRef = React.createRef();
        this.state = {
            screen: 1,
            isLoading: false,
        };

    }

    setScreen = (newScreen) => {
        this.setState({ screen: newScreen });
    };

    render() {
        const { screen } = this.state;

        return (
            <AppProvider>
                {screen === 1 && (
                    <PassengerDetailsScene
                        setScreen={this.setScreen}
                        isLoading={this.state.isLoading}
                    />
                )}

                {screen === 2 && (
                    <BookingScene
                        setScreen={this.setScreen}
                        isLoading={this.state.isLoading}
                    />
                )}
                {screen === 3 && (
                    <AvailableTripsScene
                        setScreen={this.setScreen}
                        isLoading={this.state.isLoading}
                    />
                )}
                {screen === 4 && (
                    <AddPassengerScene
                        setScreen={this.setScreen}
                        isLoading={this.state.isLoading}
                    />
                )}
            </AppProvider>
        );
    }
}

export default QtTransport;
