import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import InputText from '../component/input';

import 'react-datepicker/dist/react-datepicker.css';

import { navigateTo } from '../../../../../services/redux/actions/navigation';
import H1 from '../../../../../components/h1';
import BackButton from '../component/backButton'
import SelectInput from '../component/select';
import PrimaryButton from '../component/button';


const PersonalDetailsScene = ({ setScreen, application, props }) => {
    const currentAgent = JSON.parse(
        localStorage.getItem("currentAgent")
    );
    const gender = ["Male", "Female"];

    return (
        <>
            <BackButton onClick={() => setScreen(1)} />

            <main className='aggregator-container'>
                <Col
                    style={{
                        marginBottom: '20px',
                        margin: '0 auto',
                    }}
                >
                    <H1
                        style={{
                            textAlign: 'left',
                            fontWeight: 600,
                            marginBottom: '1rem',
                            fontSize: '2rem',
                            // fontWeight: '800',
                        }}
                    >
                        Personal Details
                    </H1>
                    <p>Let's get to know you</p>

                    <InputText
                        disabled={true}
                        value={currentAgent?.businessContact?.phoneNo}
                        label={'Phone Number'}
                    />


                    <InputText
                        disabled={true}
                        type={'email'}
                        value={currentAgent?.businessContact?.emailAddress}
                        label={'Email address'}
                    />

                    <SelectInput
                        placeholder={"gender"}
                        value={currentAgent?.businessContact?.gender}
                        option={gender}
                        label={"Gender"}
                        disabled={true}
                    />

                    <InputText
                        disabled={true}
                        value={currentAgent?.businessContact?.dob}
                        label={'Date of Birth'}
                    />

                    <InputText
                        disabled={true}
                        value={currentAgent?.bvn}
                        label={'BVN'}
                    />
                    <PrimaryButton
                        onClick={() => setScreen(3)}
                    >
                        Next
                    </PrimaryButton>
                </Col>
            </main>
        </>
    );
};

function mapStateToProps(state) {
    return {
        // isLoading: state.tunnel.isLoading
    };
}

function mapDispatchToProps(dispatch) {
    return {
        navigateTo: (pendingUrl) => dispatch(navigateTo(pendingUrl)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PersonalDetailsScene);

