import React, { useState, useEffect } from "react";
import "./style.css";

import { Button, Spinner } from "react-bootstrap";
import moment from "moment";
import Transaction from "../../../../../../services/api/resources/transaction";

import { notify } from "../../../../../../components/extras";
import { formatAmount } from "../../../../../../utils/formatters";
import { convertNgkToNgn } from "../../../../../../utils/converters/currencies";
import IosRefresh from "react-ionicons/lib/IosRefresh";
import {
  busImage,
  bxTripIcon,
  clockIcon,
  passengerIcon,
  timeIcon,
  warningIcon,
} from "../../../../../../icons";
import PropTypes from "prop-types";

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const sec = seconds % 60;
  return `${minutes}:${sec < 10 ? "0" : ""}${sec}`;
};

const PrimaryButton = ({ children, onClick, disabled, loading }) => (
  <Button
    style={{
      fontSize: "14px",
      borderRadius: "8px",
      width: "205px",
      height: "44px",
      border: "none",
      backgroundColor: "#0275D8",
      color: "white",
      cursor: "pointer",
    }}
    onClick={onClick}
    disabled={disabled}
  >
    {loading ? (
      <Spinner
        size="sm"
        animation="border"
        variant="light"
        role="status"
        style={{ width: "20px", height: "20px" }}
      />
    ) : (
      children
    )}
  </Button>
);
PrimaryButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};
PrimaryButton.defaultProps = {
  disabled: false,
  loading: false,
};

const SecondaryButton = ({ children, onClick, icon }) => (
  <Button
    style={{
      fontSize: "14px",
      border: "1px solid #E1E6ED",
      borderRadius: "8px",
      width: "220px",
      height: "44px",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "transparent",
      color: "#353F50",
      display: "flex",
      gap: "5px",
      cursor: "pointer",
    }}
    onClick={onClick}
  >
    {icon}
    <span>{children}</span>
  </Button>
);
SecondaryButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.node,
};

SecondaryButton.defaultProps = {
  icon: null,
};

const CountdownTimer = ({ radius, circumference, progress, timeLeft }) => (
  <div className="timer-container">
    <svg width="85" height="85">
      <circle
        cx="42.5"
        cy="42.5"
        r={radius * 0.75}
        fill="transparent"
        stroke="#E0E0E0"
        strokeWidth="7"
      />
      <circle
        cx="42.5"
        cy="42.5"
        r={radius * 0.75}
        fill="transparent"
        stroke="#007BFF"
        strokeWidth="7"
        strokeDasharray={circumference * 0.75}
        strokeDashoffset={circumference * 0.75 - progress * 0.75}
        strokeLinecap="round"
        transform="rotate(-90 42.5 42.5)"
        style={{ transition: "stroke-dashoffset 1s linear" }}
      />
      <text
        x="42.5"
        y="47"
        textAnchor="middle"
        fontSize="16px"
        fill="#333"
        fontWeight="bold"
      >
        {formatTime(timeLeft)}
      </text>
    </svg>
  </div>
);
CountdownTimer.propTypes = {
  radius: PropTypes.number.isRequired,
  circumference: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  timeLeft: PropTypes.number.isRequired,
};

const ExpiredSeatWarning = ({ onReserveAnother }) => (
  <div className="expired-seat-warning">
    {warningIcon()}
    <p>Please reserve another seat or book new trip!</p>
    <SecondaryButton
      onClick={onReserveAnother}
      icon={
        <IosRefresh fontSize="100px" color="grey" style={{ width: "24px" }} />
      }
    >
      Reserve Another Seat
    </SecondaryButton>
  </div>
);
ExpiredSeatWarning.propTypes = {
  onReserveAnother: PropTypes.func.isRequired,
};
function formatDateTime(timeString) {
  const [hours, minutes] = timeString.split(":");
  const date = new Date();
  date.setHours(parseInt(hours, 10));
  date.setMinutes(parseInt(minutes, 10));
  let hours12 = date.getHours() % 12;
  hours12 = hours12 ? hours12 : 12;
  const minutes12 = date.getMinutes();
  const ampm = date.getHours() >= 12 ? "PM" : "AM";

  return `${hours12}:${minutes12.toString().padStart(2, "0")} ${ampm}`;
}

const TripInfo = ({ trip, bookings, adultCount, childCount }) => (
  <div className="trip-card-trp">
    <div className="trip-header">
      <div className="flex">
        <img
          src={trip.operatorLogo}
          alt={trip.operatorName}
          className="company-logo"
        />
        <div>
          <p className="fare">
            Total Fare:{" "}
            <strong>
              ₦{formatAmount(convertNgkToNgn(bookings.transportRequest.amount))}
            </strong>
          </p>
          <p className="passenger-info-trp">
            {passengerIcon()}
            {adultCount} Adult, {childCount} Children
          </p>
        </div>
      </div>
    </div>

    <div className="trip-time">
      <p className="time">
        {clockIcon()} {formatDateTime(trip.departureTime)}
      </p>
      <p className="date">
        {timeIcon()} {moment(trip.departureDate).format("ddd, D MMM")}
      </p>
    </div>

    <div className="trip-route">
      <p>
        <strong>{trip.origin}</strong>
      </p>
      <p className="arrow">{bxTripIcon()}</p>
      <p>
        <strong>{trip.destination}</strong>
      </p>
    </div>
  </div>
);

TripInfo.propTypes = {
  trip: PropTypes.shape({
    operatorLogo: PropTypes.string.isRequired,
    operatorName: PropTypes.string.isRequired,
    departureTime: PropTypes.string.isRequired,
    departureDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]).isRequired,
    origin: PropTypes.string.isRequired,
    destination: PropTypes.string.isRequired,
  }).isRequired,
  bookings: PropTypes.shape({
    transportRequest: PropTypes.shape({
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }).isRequired,
  }).isRequired,
  adultCount: PropTypes.number.isRequired,
  childCount: PropTypes.number.isRequired,
};

const BookingScene = ({
  setScreen,
  selectedTrip,
  childCount,
  adultCount,
  bookings,
  setReceiptInfo,
}) => {
  const transaction = new Transaction();
  const deviceId = localStorage.getItem("deviceUid");

  const [timeLeft, setTimeLeft] = useState(5 * 60);
  const [isBookTrip, setIsBookTrip] = useState(false);

  const totalTime = 5 * 60;
  const radius = 40;
  const circumference = 2 * Math.PI * radius;
  const [progress, setProgress] = useState(
    (timeLeft / totalTime) * circumference
  );

  useEffect(() => {
    if (timeLeft <= 0) return;

    setProgress((timeLeft / totalTime) * circumference);

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  const handleStatus = async () => {
    setIsBookTrip(true);

    let success = false;

    while (!success) {
      const { response } = await transaction.proceedTransportPayment(
        bookings?.transactionRef
      );

      if (response.code === "00") {
        success = true;
        setReceiptInfo(response);
        notify("Trip booked Successfully", "success");
        setScreen(7);
        break;
      } else if (response.code === "02") {
        await new Promise((resolve) => setTimeout(resolve, 10000));
      } else if (response.code === "01") {
        setIsBookTrip(false);
        notify(response?.description, "error");

        break;
      }
    }
  };

  const handleBooking = async () => {
    setIsBookTrip(true);
    const { response } = await transaction.proceedTransportPayment(
      bookings,
      deviceId
    );
    setIsBookTrip(false);

    if (response.code === "00") {
      setReceiptInfo(response);
      notify("Trip booked Successfully", "success");
      setScreen(7);
    } else if (response.code === "02") {
      handleStatus();
    } else {
      notify(response?.description, "error");
    }
  };

  return (
    <div className="booking-container">
      <div className="header">
        <h2 className="container-header-booking">Book Trip</h2>
      </div>

      <div className="container--booking">
        <div>
          <div>
            <p className="">Seat reserved</p>
            <p className="title mb-5">This seat expires in 5 minutes</p>
          </div>

          <TripInfo
            trip={selectedTrip}
            bookings={bookings}
            adultCount={adultCount}
            childCount={childCount}
          />
        </div>

        <div>
          <CountdownTimer
            radius={radius}
            circumference={circumference}
            progress={progress}
            timeLeft={timeLeft}
          />

          {timeLeft <= 0 && (
            <ExpiredSeatWarning onReserveAnother={() => setScreen(4)} />
          )}
        </div>
      </div>

      <hr />

      <div className="booking-actions">
        {timeLeft > 0 ? (
          <PrimaryButton
            onClick={handleBooking}
            disabled={isBookTrip}
            loading={isBookTrip}
          >
            Book Trip
          </PrimaryButton>
        ) : (
          <SecondaryButton onClick={() => setScreen(3)} icon={busImage()}>
            Book Another Trip
          </SecondaryButton>
        )}
      </div>
    </div>
  );
};
BookingScene.propTypes = {
  setScreen: PropTypes.func.isRequired,
  selectedTrip: PropTypes.shape({
    operatorLogo: PropTypes.string,
    operatorName: PropTypes.string,
    departureTime: PropTypes.string,
    departureDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    origin: PropTypes.string,
    destination: PropTypes.string,
  }).isRequired,
  childCount: PropTypes.number.isRequired,
  adultCount: PropTypes.number.isRequired,
  bookings: PropTypes.shape({
    transactionRef: PropTypes.string,
    transportRequest: PropTypes.shape({
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }).isRequired,
  setReceiptInfo: PropTypes.func.isRequired,
};
export default BookingScene;
