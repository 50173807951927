import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import BaseScene from '../../../../../../base-scene';
import Button from '../../../../../../../components/button';
import Platform from '../../../../../../../services/api/resources/platform';
import UserManagement from '../../../../../../../services/api/resources/user-management';
import { ERROR_STATUS } from '../../../../../../../constants/api';
import { 
  dismissErrorMessage,
  dismissSuccessMessage,
  showErrorMessage,
  showSuccessMessage,
  updateLoading
} from '../../../../../../../services/redux/actions/tunnel';
import { Dropdown } from 'react-bootstrap';
import RolesTable from '../tables/roles';

class ViewAllRoles extends BaseScene {
  constructor(props) {
    super(props)

    this.state = {
      allRoles: []
    }
  }

  componentDidMount() {
    this.fetchAllRoles()
  }

  async fetchAllRoles() {
    this.props.updateLoading(true, 66);

    const rolesResponse = await this.platform.retrieveRoles()
    const rolesResponseStatus = rolesResponse.status
    const rolesResponseObj = rolesResponse.response
    
    this.props.updateLoading(false);

    if (rolesResponseStatus === ERROR_STATUS) {
      this.props.showErrorMessage(JSON.stringify(rolesResponseObj))
      setTimeout(
        () => this.props.dismissErrorMessage(), 
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      )
      return
    }

    this.setState({
      allRoles: rolesResponseObj
    })
  }

  platform = new Platform();
  userManagement = new UserManagement();

  render() {
    return <section style={{
      backgroundColor: 'white', 
      width: this.state.deviceIsPC ? '83vw': '100vw', 
      padding: '20pt', 
      fontSize: '12pt'
    }}>
      <div style={{
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: '10pt'
      }}>
        <Link to={`/roles/new`}>
          <Button>Add Role</Button>
        </Link>
      </div>
      {!this.props.isLoading && <RolesTable
        data={this.state.allRoles}
        style={{fontSize: '12pt'}}
      />}
    </section>
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.tunnel.isLoading,
    loadUserStatus: state.user.loadUserStatus,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dismissErrorMessage: () => dispatch(dismissErrorMessage()),
    dismissSuccessMessage: () => dispatch(dismissSuccessMessage()),
    showErrorMessage: message => dispatch(showErrorMessage(message)),
    showSuccessMessage: message => dispatch(showSuccessMessage(message)),
    updateLoading: (isLoading, percentage=null) => dispatch(updateLoading({isLoading, percentage}))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewAllRoles)
