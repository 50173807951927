import React from "react";
import BaseScene from "../../../../base-scene";
import Quickteller from "../../../../../services/api/resources/quickteller";
import H1 from "../../../../../components/h1";
import MdArrowBack from "react-ionicons/lib/MdArrowBack";
import MdSearch from "react-ionicons/lib/MdSearch";
import ActivityIndicator from "../../../../../components/activity-indicator";
import ProductThumbnail from "./product-thumbnail";

export default class SearchServicesTab extends BaseScene {
  state = {
    isLoading: true,
    services: [],
  };

  quickteller = new Quickteller();

  render() {
    return (
      <section
        className={`services-tab${this.props.deviceIsMobile ? "-mobile" : ""}`}
      >
        <H1
          style={{
            marginBottom: "10px",
            marginTop: "10px",
            textAlign: "left",
            width: "100vw",
            display: "flex",
            alignItems: "center",
            paddingLeft: `${!this.props.deviceIsMobile && "20px"}`,
          }}
        >
          <div
            hidden={this.props.searchType}
            style={{
              alignItems: "center",
              display: "flex",
              marginRight: "10px",
            }}
          >
            <MdArrowBack
              fontSize="26px"
              onClick={this.props.onBackButtonClick}
            />
          </div>
          {this.props.deviceIsMobile && (
            <div
              style={{
                alignItems: "center",
                display: "flex",
                marginRight: "10px",
              }}
            >
              <MdArrowBack
                fontSize="26px"
                onClick={this.props.onBackButtonClick}
              />
            </div>
          )}
          Search
        </H1>
        {!this.props.services ? (
          <ActivityIndicator />
        ) : this.props.services.length === 0 ? (
          <p
            style={{
              height: "60px",
              margin: "auto",
              marginTop: "50px",
              font: process.env.REACT_APP_FONT_FAMILY_BODY,
            }}
          >
            <MdSearch
              fontSize="36px"
              color={process.env.REACT_APP_BLUE_COLOUR}
            />
            No result found{" "}
          </p>
        ) : (
          this.props.services.map((value) => (
            <ProductThumbnail onSelect={this.props.onSelect} product={value} />
          ))
        )}
      </section>
    );
  }
}
