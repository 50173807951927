import React, { useState } from "react";
import HeaderSignup from "../../../../components/header-signup";
import { Link } from "react-router-dom";
import "../styles.scss";
import { cancelImageIcon } from "../../../../icons";

export default function CancelledScene({ setScreen }) {


    return (
        <>
            <HeaderSignup />
            <main className="onboarding-container">
                <div style={{ padding: "2rem", alignContent: "center" }}>
                    <div style={{ display: "grid", placeContent: "center", marginBottom: "2rem" }}>
                        {cancelImageIcon()}
                    </div>
                    <h1
                        style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            marginBottom: "1rem",
                            fontSize: "1.5rem",
                        }}
                    >
                        Cancelled
                    </h1>
                    <p
                        style={{
                            color: "grey",
                            fontWeight: "200",
                            marginLeft: "1.5rem",
                            fontSize: "15px",
                            textAlign: "center",
                        }}
                    >
                        Your payment has been Cancelled.
                    </p>
                    <Link to="/dashboard">
                        <button
                            className="signupButton2"
                            style={{
                                marginTop: "3rem",
                                textAlign: "center",
                                color: "#479FC8",
                            }}
                        >
                            {" "}
                            Continue to Dashboard{" "}
                        </button>
                    </Link>
                </div>
            </main>
        </>
    );
}
