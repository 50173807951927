import React from 'react';
import { Col } from 'react-bootstrap';
import H1 from '../../../../components/h1';

const Title = ({ upgradeTo }) => {
  return (
    <div>
      <H1
        style={{
          textAlign: 'left',
          marginBottom: '1rem',
        }}
      >
        Upgrade Agent to {upgradeTo}
      </H1>
      <p
        style={{
          color: '#6B7280',
          fontSize: '0.75rem',
          marginBottom: '3rem',
        }}
      >
        Kindly complete the required fields
      </p>
    </div>
  );
};

export default Title;
