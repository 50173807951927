import React, { Component } from "react";
import qs from "qs";
import { v4 as uuidv4 } from "uuid";
import Fingerprint2 from "fingerprintjs2";
import {
  isSessionActive,
  isAuthTokenExpired,
  refreshAuthToken,
} from "../utils/auth";
import { logEvent } from "../core/logger";
import { PAGE_VIEW } from "../constants/analytics";
import UserSerializer from "../serializers/resources/user";
import { debounce } from "lodash";

export default class BaseScene extends React.PureComponent {
  componentWillMount() {
    this.title &&
      logEvent(PAGE_VIEW, {
        page_location: this.location,
        page_path: this.path,
        page_title: `${this.title} Page`,
      });

    this.handleResize();
    window.addEventListener("resize", this.handleResize.bind(this));

    this.deviceFingerprint = {};

    Fingerprint2.get(
      function (components) {
        this.deviceFingerprint = components;
      }.bind(this)
    );

    if (this.isAuthRequired && isAuthTokenExpired()) {
      debounce(async () => await refreshAuthToken(), 2000);
      // window.location = `/logout`;
    }

    if (this.requiresEmptySession && isSessionActive()) {
      //window.location = `${process.env.REACT_APP_ROUTE_BASENAME}/dashboard`;
    }

    const deviceUid = localStorage.getItem("deviceUid");
    if (deviceUid === null) {
      localStorage.setItem("deviceUid", uuidv4());
    }

    const currentUser = JSON.parse(localStorage.getItem("currentUser") || "{}");
    const userSerializer = new UserSerializer(currentUser);
    this.setState({
      userFromBaseScene: userSerializer,
    });
  }

  handleResize() {
    const deviceIsPC =
      !qs.parse(window.location.search)["?mobile"] && window.innerWidth >= 992;
    const deviceIsMobile =
      qs.parse(window.location.search)["?mobile"] || window.innerWidth <= 576;
    const deviceIsTab =
      qs.parse(window.location.search)["?mobile"] || window.innerWidth <= 992;

    this.setState({
      deviceIsPC,
      deviceIsMobile,
      deviceIsTab,
    });
  }
}
