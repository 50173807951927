import {
  QUICKTELLER_NEXT_API_BASE_URL,
  QUICKTELLER_NEXT_API_BASE_URL_V2,
  QUICKTELLER_TERMINAL_ID,
} from "../../../utils/api-properties";
import Requester from "../finch-requester";

const API_BASE_URL = QUICKTELLER_NEXT_API_BASE_URL;
const API_BASE_URL_V2 = QUICKTELLER_NEXT_API_BASE_URL_V2;

export default class Quickteller {
  constructor(props) {
    this.apiRequester = props
      ? props.apiRequester
      : new Requester({
          apiBaseUrl: API_BASE_URL,
        });
    this.apiRequesterV2 = props
      ? props.apiRequesterV2
      : new Requester({
          apiBaseUrl: API_BASE_URL_V2,
        });
  }

  accountInquiry(accountNumber, bankCode, terminalId) {
    return this.apiRequester.get({
      endpoint: `services/inquiries/banks/${bankCode}/accounts`,
      headers: {
        accountNumber,
        terminalId,
      },
    });
  }

  authenticate(username, password) {
    return this.apiRequester.post({
      endpoint: `security/authenticate`,
      body: {
        username,
      },
      headers: {
        secret: password,
      },
    });
  }

  getOptions(serviceId, terminalId) {
    return this.apiRequester.get({
      endpoint: `services/${serviceId}`,
      args: {
        withOptions: true,
      },
      headers: {
        terminalId,
      },
      cache: false,
    });
  }

  getServices(categoryId, terminalId) {
    return this.apiRequester.get({
      endpoint: `services/categories/${categoryId}`,
      args: {
        withservices: true,
      },
      headers: {
        terminalId: QUICKTELLER_TERMINAL_ID,
      },
      cache: true,
    });
  }
  getBuyPowerServices(categoryId, terminalId) {
    return this.apiRequester.get({
      endpoint: `services/categories/${categoryId}`,
      args: {
        withservices: true,
      },
      headers: {
        terminalId: QUICKTELLER_TERMINAL_ID,
      },
      cache: true,
    });
  }

  search(searchTerm) {
    return this.apiRequester.get({
      endpoint: `services/search`,
      args: {
        s: searchTerm,
        countryCode: "NG",
      },
      headers: {
        terminalId: QUICKTELLER_TERMINAL_ID,
      },
    });
  }

  getTransferBanks() {
    return this.apiRequester.get({
      endpoint: `services/transfers/banks`,
      headers: {
        terminalId: QUICKTELLER_TERMINAL_ID,
      },
    });
  }

  reserveSeat(
    providerId,
    scheduleId,
    noOfSeats,
    noOfAdults,
    noOfChildren,
    operatorCode,
    email,
    adultMetaData,
    childMetaData,
    passengers
  ) {
    return this.apiRequester.post({
      endpoint: `booking/reservations`,
      body: {
        providerId,
        scheduleId,
        noOfSeats,
        noOfAdults,
        noOfChildren,
        operatorCode,
        email,
        adultMetaData,
        childMetaData,
        passengers,
      },
      headers: {
        terminalId: QUICKTELLER_TERMINAL_ID,
      },
    });
  }
  getTerminals() {
    return this.apiRequesterV2.get({
      endpoint: `booking/terminals`,
      headers: {
        terminalId: QUICKTELLER_TERMINAL_ID,
      },
    });
  }
  getSeatsLayout(payload) {
    return this.apiRequesterV2.post({
      endpoint: `booking/seats`,
      body: payload,
      headers: {
        terminalId: QUICKTELLER_TERMINAL_ID,
      },
    });
  }

  reserveSeats(payload) {
    return this.apiRequesterV2.post({
      endpoint: `booking/reservations`,
      body: payload,
      headers: {
        terminalId: QUICKTELLER_TERMINAL_ID,
      },
    });
  }
  getTrips(payload) {
    return this.apiRequesterV2.post({
      endpoint: `booking/search`,
      body: payload,
      headers: {
        terminalId: QUICKTELLER_TERMINAL_ID,
      },
    });
  }
}
