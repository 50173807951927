import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import MdCheckmarkCircle from 'react-ionicons/lib/MdCheckmarkCircle';

const ProfileUpgradeCTA2 = ({
    title,
    sub,
    onClick,
    actionColor,
    isLoading,
    checkClickableTile,
    application,
    text,
    errMessage,
    edit = '',
}) => {
    const centerStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
    };

    const getCheckmarkColor = () => {
        if (actionColor && !isLoading) {
            return 'green';
        } else if (
            checkClickableTile === null ||
            checkClickableTile === undefined
        ) {
            return '#cacaca';
        }
        return 'grey';
    };
    // #1F2126
    const getColor = () => {
        if (checkClickableTile === null || checkClickableTile === undefined) {
            return '#cacaca';
        } else {
            return '#1F2126';
        }
    };

    return (
        <Col
            style={{
                padding: '1rem 1.5rem',
                borderRadius: '8px',
                border: `1px solid grey`,
                marginBottom: '20px',
            }}
            onClick={onClick}
            data-testid='checkmark-icon'
        >
            <div style={centerStyle}>
                <div>
                    <div>
                        <p
                            style={{
                                fontSize: '0.875rem',
                                margin: 0,
                                fontWeight: 600,
                                // color: getColor(),
                                color: 'grey',
                                marginBottom: '10px',
                            }}
                        >
                            {title}
                        </p>
                        <span style={{ fontWeight: '600', fontSize: '12px', lineHeight: '20px', color: '#0275D8' }}>{edit}</span>
                    </div>
                    <p
                        style={{
                            fontSize: '0.875rem',
                            margin: 0,
                            // color: getColor(),
                            color: 'grey',
                            fontSize: '14px',
                        }}
                    >
                        {sub}
                    </p>
                </div>
                <span style={{ fontWeight: '600', fontSize: '8px', lineHeight: '16px', color: '#EC9B40', whiteSpace: 'nowrap' }}>{text}</span>
            </div>
            <span style={{ fontWeight: '400', fontSize: '8px', lineHeight: '14px', color: '#EC9B40', whiteSpace: 'nowrap' }}>{errMessage}</span>

        </Col>
    );
};

export default ProfileUpgradeCTA2;
