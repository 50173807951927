import { connect } from 'react-redux';
import { navigateTo } from '../../services/redux/actions/navigation'
import { dismissErrorMessage, showErrorMessage, updateLoading } from '../../services/redux/actions/tunnel'
import Application from './scene'

function mapStateToProps(state) {
  return {
    isLoading: state.tunnel.isLoading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dismissErrorMessage: message => dispatch(dismissErrorMessage(message)),
    showErrorMessage: message => dispatch(showErrorMessage(message)),
    navigateTo: pendingUrl => dispatch(navigateTo(pendingUrl)),
    updateLoading: (isLoading, percentage=null) => dispatch(updateLoading({isLoading, percentage}))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Application);
