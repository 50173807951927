import React from "react";
import { connect } from "react-redux";
import BaseScene from "../../base-scene";
import { Col, Container, Form, Row } from "react-bootstrap";
import { updateLoading } from "../../../services/redux/actions/tunnel";
import Button from "../../../components/button";
import FormControl from "../../../components/form-control";
import FormLabel from "../../../components/form-label";
import "./styles.scss";

class ContactUs extends BaseScene {
  constructor(props) {
    super(props);

    this.state = {
      deviceIsMobile: false,

      firstName: null,
      lastName: null,
      phone: null,
      email: null,
      password: null,
      repeatPassword: null,
      howYouHeardAboutUs: null,
      iAgree: null,
      formIsValid: false,
      hidePassword: true,
      hideRepeatPassword: true,
    };
  }

  isEmailValid(newState) {
    const state = newState || this.state;
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(state.email).toLowerCase());
  }

  isFirstNameValid(newState) {
    const state = newState || this.state;
    return state.firstName && state.firstName.length > 2;
  }

  isLastNameValid(newState) {
    const state = newState || this.state;
    return state.lastName && state.lastName.length > 2;
  }

  isPasswordValid(newState) {
    const state = newState || this.state;
    const { password } = state;

    var re = /[a-z]/;
    var re2 = /[A-Z]/;
    var re3 = /[0-9]/;

    return (
      re.test(String(password)) &&
      re2.test(String(password)) &&
      re3.test(String(password)) &&
      String(password).length >= 8
    );
  }

  isPhoneValid(newState) {
    const state = newState || this.state;
    return state.phone && state.phone.length === 11;
  }

  passwordsMatch(newState) {
    const state = newState || this.state;
    return state.password === state.repeatPassword;
  }

  userAgrees(newState) {
    const state = newState || this.state;
    return state.iAgree;
  }

  updateFormField(params) {
    const newState = {
      ...this.state,
      ...params,
    };
    const allParamsAreValid =
      this.isFirstNameValid(newState) &&
      this.isLastNameValid(newState) &&
      this.isPhoneValid(newState) &&
      this.isEmailValid(newState) &&
      this.isPasswordValid(newState) &&
      this.passwordsMatch(newState) &&
      this.userAgrees(newState);

    this.setState({
      ...params,
      formIsValid: allParamsAreValid,
    });
  }

  render() {
    const screenSensitiveStyle = !this.state.deviceIsMobile
      ? {
          maxWidth: "1080px",
          width: "1080px",
          marginTop: "50px",
        }
      : {};

    return (
      <Container
        fluid
        style={{
          fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
          backgroundColor: "#F3F5F9",
          margin: 0,
          padding: 0,
          width: "100%",
        }}
      >
        <div
          style={{
            backgroundColor: process.env.REACT_APP_BLUE_COLOUR,
            color: "white",
            fontSize: "26px",
            fontWeight: "bold",
            lineHeight: "31px",
            padding: "50px",
          }}
        >
          <svg
            hidden
            style={{ position: "absolute", left: "-27.46%", top: "220px" }}
            width="100%"
            height="92"
            viewBox="0 0 214 92"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.103399"
              d="M107 197C156.706 197 197 156.706 197 107C197 57.2944 156.706 17 107 17C57.2944 17 17 57.2944 17 107C17 156.706 57.2944 197 107 197Z"
              stroke="white"
              stroke-width="33"
            />
          </svg>

          <svg
            hidden
            style={{ position: "absolute", left: "950px", top: "173px" }}
            width="80"
            height="82"
            viewBox="0 0 132 82"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.103399"
              d="M66 65C93.062 65 115 43.062 115 16C115 -11.062 93.062 -33 66 -33C38.938 -33 17 -11.062 17 16C17 43.062 38.938 65 66 65Z"
              stroke="white"
              stroke-width="33"
            />
          </svg>

          <center
            style={{ fontFamily: process.env.REACT_APP_FONT_FAMILY_HEADER }}
          >
            GET IN TOUCH WITH US
          </center>
        </div>

        <div className="form-container-flexbox">
          <div className="form-container" style={screenSensitiveStyle}>
            <Form>
              <Row>
                <Col xs={12} lg={6}>
                  <Form.Group
                    controlId="contact-us-first-name"
                    style={{ padding: 10 }}
                  >
                    <FormLabel
                      style={{
                        color: "#3A3B3B",
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        lineHeight: "19px",
                      }}
                    >
                      First Name:
                    </FormLabel>
                    <FormControl
                      type="text"
                      placeholder="Your first name"
                      onChange={(event) => {
                        const firstName = event.target.value;
                        this.updateFormField({ firstName });
                      }}
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "20px",
                        border: `1px solid ${
                          !this.state.firstName || this.isFirstNameValid()
                            ? "#DFE2E6"
                            : process.env.REACT_APP_RED_COLOUR
                        }`,
                        boxSizing: "border-box",
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        lineHeight: "17px",
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} lg={6}>
                  <Form.Group
                    controlId="contact-us-last-name"
                    style={{ padding: 10 }}
                  >
                    <FormLabel
                      style={{
                        color: "#3A3B3B",
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        lineHeight: "19px",
                      }}
                    >
                      Last Name:
                    </FormLabel>
                    <FormControl
                      type="text"
                      placeholder="Your last name"
                      onChange={(event) => {
                        const lastName = event.target.value;
                        this.updateFormField({ lastName });
                      }}
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "20px",
                        border: `1px solid ${
                          !this.state.lastName || this.isLastNameValid()
                            ? "#DFE2E6"
                            : process.env.REACT_APP_RED_COLOUR
                        }`,
                        boxSizing: "border-box",
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        lineHeight: "17px",
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={6}>
                  <Form.Group
                    controlId="contact-us-phone"
                    style={{ padding: 10 }}
                  >
                    <FormLabel
                      style={{
                        color: "#3A3B3B",
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        lineHeight: "19px",
                      }}
                    >
                      Phone Number:
                    </FormLabel>
                    <FormControl
                      type="text"
                      placeholder="08012345678"
                      onChange={(event) => {
                        const phone = event.target.value;
                        this.updateFormField({ phone });
                      }}
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "20px",
                        border: `1px solid ${
                          !this.state.phone || this.isPhoneValid()
                            ? "#DFE2E6"
                            : process.env.REACT_APP_RED_COLOUR
                        }`,
                        boxSizing: "border-box",
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        lineHeight: "17px",
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} lg={6}>
                  <Form.Group
                    controlId="contact-us-email"
                    style={{ padding: 10 }}
                  >
                    <FormLabel
                      style={{
                        color: "#3A3B3B",
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        lineHeight: "19px",
                      }}
                    >
                      Email Address:
                    </FormLabel>
                    <FormControl
                      type="text"
                      placeholder="john@example.com"
                      onChange={(event) => {
                        const email = event.target.value;
                        this.updateFormField({ email });
                      }}
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "20px",
                        border: `1px solid ${
                          !this.state.email || this.isEmailValid()
                            ? "#DFE2E6"
                            : process.env.REACT_APP_RED_COLOUR
                        }`,
                        boxSizing: "border-box",
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        lineHeight: "17px",
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={6}>
                  <Form.Group
                    controlId="formBasicEmail"
                    style={{ padding: 10 }}
                  >
                    <FormLabel
                      style={{
                        color: "#3A3B3B",
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        lineHeight: "19px",
                      }}
                    >
                      What is your Inquiry about?
                    </FormLabel>
                    <FormControl
                      onChange={(event) => {
                        const howYouHeardAboutUs = event.target.value;
                        this.updateFormField({ howYouHeardAboutUs });
                      }}
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "20px",
                        border: "1px solid #DFE2E6",
                        boxSizing: "border-box",
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        lineHeight: "17px",
                        width: "100%",
                      }}
                      as="select"
                    >
                      <option value="0">Select an option</option>
                      <option value="1">Transaction Request</option>
                      <option value="2">Settlement Request</option>
                      <option value="3">Dispute Request</option>
                      <option value="4">Other Request</option>
                    </FormControl>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} style={{ padding: 10 }}>
                  <Form.Group
                    controlId="formBasicEmail"
                    style={{ padding: 10 }}
                  >
                    <FormLabel
                      style={{
                        color: "#3A3B3B",
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        lineHeight: "19px",
                      }}
                    >
                      Describe your inquiry ?
                    </FormLabel>
                    <FormControl
                      onChange={(event) => {
                        const howYouHeardAboutUs = event.target.value;
                        this.updateFormField({ howYouHeardAboutUs });
                      }}
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "10px",
                        border: "1px solid #DFE2E6",
                        boxSizing: "border-box",
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        lineHeight: "17px",
                        padding: "10px 0px 0px 25px",
                        width: "100%",
                      }}
                      as="textarea"
                      rows={8}
                      placeholder="Let us know what you need."
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} style={{ marginTop: "20px" }}>
                  <center>
                    <Button
                      disabled={!this.state.formIsValid || this.props.isLoading}
                      onClick={() => this.props.updateLoading(true)}
                    >
                      {this.props.isLoading ? "Loading..." : "SUBMIT"}
                    </Button>
                  </center>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.tunnel.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateLoading: (isLoading) => dispatch(updateLoading(isLoading)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
