import React, { useContext, useState } from 'react';

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [value, setValue] = useState(null);
  const [requestId, setRequestId] = useState('');
  const [amount, setAmount] = useState(1);
  const [receiptResponse, setReceiptResponse] = useState({});

  return (
    <AppContext.Provider
      value={{
        value,
        setValue,
        requestId,
        setRequestId,
        receiptResponse,
        setReceiptResponse,
        amount,
        setAmount,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
// make sure use
export const usePosRequestContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
