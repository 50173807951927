import React from 'react';
import Text from './text';
import MdSync from 'react-ionicons/lib/MdSync';

function CustomError({ pageSize, pageNum, isLoading, onRetry }) {
  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'top',
        margin: '5% 0',
      }}
    >
      <Text blue bold small>
        Oops!
      </Text>
      <Text mid>Something went wrong. Please, try again.</Text>
      <div
        style={{
          alignItems: 'center',
          border: `1pt solid ${process.env.REACT_APP_BLUE_COLOUR}`,
          borderRadius: '4px',
          color: process.env.REACT_APP_BLUE_COLOUR,
          cursor: 'pointer',
          display: 'flex',
          fontSize: process.env.REACT_APP_MID_FONT_SIZE,
          fontStyle: process.env.REACT_APP_MID_FONT_SIZE,
          fontWeight: 'bold',
          height: '40px',
          justifyContent: 'center',
          marginTop: '16px',
          width: '160px',
        }}
        onClick={onRetry}
      >
        <MdSync
          rotate={isLoading}
          color={process.env.REACT_APP_BLUE_COLOUR}
          fontSize={'24px'}
          style={{ marginRight: '10px' }}
        />
        {isLoading ? 'Loading...' : 'RETRY'}
      </div>
    </div>
  );
}

export default CustomError;
