import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { stopwatch } from '../../../../../../../App';
import Button from '../../../../../../../components/button';
import { notify } from '../../../../../../../components/extras';
import Text from '../../../../../../../components/text';
import {
  ERROR_STATUS,
  SUCCESS_STATUS,
} from '../../../../../../../constants/api';
import Liquidity from '../../../../../../../services/api/resources/liquidity';
import { convertNgkToNgn } from '../../../../../../../utils/converters/currencies';
import { formatAmount } from '../../../../../../../utils/formatters';

import { useFundUssdContext } from './context';

const copybox = {
  padding: '2px 27px',
  borderRadius: '3px',
  background: 'D3D3D3',
  position: 'absolute',
  color: 'grey',
  top: '20%',
  right: '10%',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  backdropFilter: 'blur(5px)',
};

const UssdConfirmationScreen = ({
  setScreen,
  deviceIsMobile,
  onCancelConfirm,
}) => {
  const { responseData, setForm, setCode } = useFundUssdContext();

  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const liquidity = new Liquidity();

  const onUssdWithdrawalConfirmation = async () => {
    // e.preventDefault();
    setIsLoading(true);

    const { response, status } = await liquidity.processFundUssdRequest(
      responseData,
      localStorage.getItem('deviceUid')
    );
    stopwatch.stop();

    if (status === ERROR_STATUS) {
      setIsLoading(false);
      notify(response.description, 'error');
    } else if (status === SUCCESS_STATUS) {
      setScreen(3);
      setCode(response.code);
    }
  };

  const handleCopyAccountNumber = (ussdcode) => {
    setShow(true);
    navigator.clipboard.writeText(ussdcode);

    setTimeout(() => {
      setShow(false);
    }, 1500);
  };
  const onCancelProduct = () => {
    setForm({});
    setScreen(1);
  };

  return (
    <>
      <article className='fund-by-ussd-section'>
        <Row
          onClick={() => {
            setForm({});
            setScreen(1);
          }}
          style={{
            color: '#009FC3',
            position: 'relative',
            bottom: deviceIsMobile ? 0 : '20px',
            float: 'right',
            cursor: 'pointer',
          }}
        >
          Back
        </Row>
        <Row style={{ height: 'auto', margin: '60px 0' }}>
          <Col md={12} style={{}}>
            <Text
              style={{
                textAlign: 'center',
                color: 'grey',

                fontSize: '20px',
              }}
            >
              Commission Due :{' '}
              {responseData
                ? `NGN${formatAmount(
                    convertNgkToNgn(responseData.agentCommissionDue)
                  )}`
                : '0.0'}
            </Text>
            <Text
              style={{
                textAlign: 'center',
                color: 'grey',
                fontWeight: 'bold',
                fontSize: '20px',
                marginBottom: '20px',
              }}
            >
              Customer should dial this code:{' '}
            </Text>

            <Text
              onClick={() => handleCopyAccountNumber(responseData.ussdCode)}
              title='Click to copy ussd code'
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                fontSize: '50px',
                color: 'grey',
                cursor: 'pointer',
              }}
            >
              {responseData ? `${responseData.ussdCode}` : ''}
            </Text>
            {show ? <div style={copybox}>Copied!</div> : ''}
            <Text
              style={{
                marginTop: '2em',
                marginBottom: '15px',
                fontSize: '12px',
                textAlign: 'center',
                color: 'grey',
              }}
            >
              Once your transaction is complete, click on "PROCEED" to complete
              your transaction.
            </Text>
          </Col>
        </Row>

        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Col
            xs={12}
            md={5}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Button
              disabled={isLoading}
              style={{ width: '100%', marginBottom: '10px' }}
              onClick={onUssdWithdrawalConfirmation}
            >
              {isLoading ? 'Processing...' : 'Proceed'}
            </Button>
          </Col>
          <Col xs={12} md={5}>
            <Button
              outline
              style={{
                backgroundColor: 'transparent',
                marginTop: deviceIsMobile ? '10pt' : null,
                width: '100%',
                padding: '9pt 37pt',
                marginBottom: '10px',
              }}
              onClick={onCancelProduct}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </article>
    </>
  );
};

export default UssdConfirmationScreen;
