import React from "react";
import { Col, Row } from "react-bootstrap";
import Button from "../../../../../components/button";
import HR from "../../../../../components/hr";
import Text from "../../../../../components/text";
import { BILLER_BORNE } from "../../../../../constants/transactions";
import { convertNgkToNgn } from "../../../../../utils/converters/currencies";
import { formatAmount } from "../../../../../utils/formatters";

export const TransferConfirmationTab = (props) => {
  const transferRequest =
    props.payment?.transferRequest || props.payment?.nipTransferRequest;
  return (
    <>
      <HR style={{ marginTop: "5px", width: "100%" }} />

      <Row style={{ marginTop: "15px" }}>
        <Col
          md={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            Beneficiary Name:{" "}
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {props.form ? props.form.beneficiaryName : ""}
          </Text>
        </Col>
      </Row>
      {props.payment && props.form.beneficiaryPhone && (
        <Row style={{ marginTop: "15px" }}>
          <Col
            md={12}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
              Beneficiary Number:{" "}
            </Text>
            <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
              {props.form ? props.form.beneficiaryPhone : ""}
            </Text>
          </Col>
        </Row>
      )}

      <Row style={{ marginTop: "15px" }}>
        <Col
          md={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            Bank:{" "}
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {props.form.bank ? props.form.bank.label : ""}
          </Text>
        </Col>
      </Row>

      <Row style={{ marginTop: "15px" }}>
        <Col
          md={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            Account Number:{" "}
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {props.form ? props.form.accountNumber : ""}
          </Text>
        </Col>
      </Row>

      {props.payment && props.form.gender && (
        <Row style={{ marginTop: "15px" }}>
          <Col
            md={12}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
              Gender
            </Text>
            <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
              {props.form.gender}
            </Text>
          </Col>
        </Row>
      )}

      <Row style={{ marginTop: "15px" }}>
        <Col
          md={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            Commission Due
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {props.payment
              ? `NGN${formatAmount(
                  convertNgkToNgn(
                    props.payment?.transferRequest?.agentCommissionDue
                  )
                )}`
              : "0.0"}
          </Text>
        </Col>
      </Row>

      <Row style={{ marginTop: "15px" }}>
        <Col
          md={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
              fontWeight: "bold",
            }}
          >
            Amount
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
              fontWeight: "bold",
            }}
          >{`NGN${formatAmount(props.form.amount)}`}</Text>
        </Col>
      </Row>

      {props.payment &&
        transferRequest &&
        transferRequest.borneBy !== BILLER_BORNE && (
          <Row style={{ marginTop: "15px" }}>
            <Col
              md={12}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Text
                style={{
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  fontWeight: "bold",
                }}
              >
                Fee
              </Text>
              <Text
                style={{
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  fontWeight: "bold",
                }}
              >
                {props.payment
                  ? `NGN${formatAmount(convertNgkToNgn(transferRequest.fee))}`
                  : ""}
              </Text>
            </Col>
          </Row>
        )}

      <hr />

      <Row style={{ display: "flex", justifyContent: "space-between" }}>
        <Col
          xs={12}
          md={5}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            disabled={props.isLoading}
            style={{ width: "100%" }}
            onClick={props.onTransferConfirmation}
          >
            {props.isLoading ? "Processing..." : "Pay"}
          </Button>
        </Col>
        <Col xs={12} md={5}>
          <Button
            outline
            style={{
              backgroundColor: "transparent",
              marginTop: props.deviceIsMobile ? "10pt" : null,
              width: "100%",
              padding: "9pt 37pt",
            }}
            onClick={props.onCancelProduct}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </>
  );
};
