import React, { useRef } from 'react';
import { Form } from 'react-bootstrap';
import MdSync from 'react-ionicons/lib/MdSync';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import FormLabel from '../../../../../../../components/form-label';

const COLOUR = 'grey';
const PhoneInput = ({
  isBeneficiaryPhoneValid,
  form,
  checkConfirmValidError,
  isSenderPhoneValid,
  propagateFormErrors,
  setFieldsFocused,
  fieldsFocused,
  updateFormField,
  handleCopy,
  loading,
  onChange,
  handlePaste,
  disabled,
  onBlur,
  title = 'Phone Number',
}) => {
  const phoneInputRef = useRef(null);
  return (
    <div>
      <Form.Group className='p-0'>
        <FormLabel
          style={{
            color: checkConfirmValidError
              ? 'black'
              : `${!form || isSenderPhoneValid()
                ? 'black'
                : process.env.REACT_APP_RED_COLOUR
              }`,
            fontSize: '14px',
            fontWeight: 'bold',

            lineHeight: '19px',
          }}
        >
          {title}
        </FormLabel>
        <ReactPhoneInput
          specialLabel=''
          buttonStyle={{
            // padding: '23px 0',
            borderTopLeftRadius:
              process.env.REACT_APP_BORDER_STYLE === 'square' ? '4px' : '20px',
            borderBottomLeftRadius:
              process.env.REACT_APP_BORDER_STYLE === 'square' ? '4px' : '20px',
          }}
          autoComplete='off'
          containerStyle={{
            margin: 0,
            padding: 0,
            width: '100%',
          }}
          disabled={disabled}
          country='ng'
          disableCountryCode={true}
          inputStyle={{
            backgroundColor: '#FFFFFF',
            borderRadius:
              process.env.REACT_APP_BORDER_STYLE === 'square' ? '4px' : '20px',
            border: checkConfirmValidError
              ? '1px solid #DFE2E6'
              : `1px solid ${(!form && !propagateFormErrors) || isBeneficiaryPhoneValid()
                ? '#DFE2E6'
                : process.env.REACT_APP_RED_COLOUR
              }`,
            boxSizing: 'border-box',
            fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            lineHeight: '17px',
            width: '100%',
            padding: '23px 0 23px 50px',
          }}
          value={form || ''}
          onlyCountries={['ng', 'ke', 'ug']}
          inputProps={{
            onCopy: handleCopy,
            onPaste: handlePaste,
          }}
          ref={phoneInputRef}
          onBlur={onBlur}
          onChange={onChange}
          placeholder='08012345678'
        />
        {loading && (
          <div
            style={{
              position: 'absolute',
              bottom: '10px',
              right: '10px',
            }}
          >
            <MdSync
              color={COLOUR}
              fontSize='20pt'
              rotate={true}
              // rotate={loading}
              style={{
                marginRight: '5pt',
              }}
            />
          </div>
        )}
      </Form.Group>
    </div>
  );
};

export default PhoneInput;
