import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import InputText from "../component/input";

import "react-datepicker/dist/react-datepicker.css";
import MdCheckmarkCircle from "react-ionicons/lib/MdCheckmarkCircle";

import IosRefresh from "react-ionicons/lib/IosRefresh";
import {
  ERROR_STATUS,
  HTTP_NOT_FOUND_CODE,
  SUCCESS_CODE,
  SUCCESS_STATUS,
} from "../../../../../../../constants/api";

import { MAX_BVN_LENGTH } from "../../../../../../../constants/form-fields";
import PrimaryButton from "../component/button";
import { DatePickerComponent } from "../component/datePickerComponent";
import {
  isBvnValid,
  isEmailValid,
  isPasswordValid,
  isConfirmPasswordValid,
  stripNonNumbers,
} from "../component/extras";
import PhoneInput from "../component/phoneInput";

import { firetoast, notify } from "../../../../../../../components/extras";
import FormLabel from "../../../../../../../components/form-label";
import HeaderSignup from "../../../../../../../components/form-label";
import { NIGERIA_PHONE_PREFIX } from "../../../../../../../constants";
import Onboarding from "../../../../../../../services/api/resources/onboarding";
import UserManagement from "../../../../../../../services/api/resources/user-management";
import { navigateTo } from "../../../../../../../services/redux/actions/navigation";
import {
  formatEmailOrPhone,
  specialDateRangeFormatter_StartDateDate,
} from "../../../../../../../utils/formatters";
import SignUp from "../component/signupText";
import Stepper from "../component/stepper";
import { PasswordIcon } from "../../../../../../../icons";
import SelectInput from "../component/select";
import H1 from "../../../../../../../components/h1";
import { isPhoneValid } from "../../../../../../../utils/validators/form-validators";
import phoneValidators from "../../../../../../../utils/validators/phone-validators";

const PersonalDetailsScene = ({ setScreen, application }) => {
  const onboarding = new Onboarding();

  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bvnData, setBvnData] = useState();
  const [propagateFormErrors, setPropagateFormErrors] = useState(false);
  const [bvnResponse, setBvnResponse] = useState({
    success: "",
    error: "",
  });
  const [fieldsFocused, setFieldsFocused] = useState([]);
  const [visible, setVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);

  const applicationId = localStorage.getItem("applicationId");
  // const application = JSON.parse(localStorage.getItem('application'));
  const updatedPersonaldetailsForm = JSON.parse(
    localStorage.getItem("personalDetailsForm")
  );

  const convertDate = (date) => {
    const dateComponents = date?.split("-") || "";
    const year = parseInt(dateComponents[0], 10);
    const month = parseInt(dateComponents[1], 10);
    const day = parseInt(dateComponents[2], 10);

    return `${year}, ${month}, ${day + 1}`;
  };

  const [personalDetailsForm, setPersonalDetailsForm] = useState({
    phoneNumber: application
      ? application?.applicantDetails?.phoneNumber
      : updatedPersonaldetailsForm
      ? updatedPersonaldetailsForm.phoneNumber
      : "",
    email: application
      ? application?.applicantDetails?.emailAddress
      : updatedPersonaldetailsForm
      ? updatedPersonaldetailsForm.emailAddress
      : "",
    gender: application
      ? application?.applicantDetails?.gender
      : updatedPersonaldetailsForm
      ? updatedPersonaldetailsForm.gender
      : "",
    bvn: application
      ? application?.applicantDetails?.bvn
      : updatedPersonaldetailsForm
      ? updatedPersonaldetailsForm.bvn
      : "",
    // confirmPhoneNumber: application
    //   ? application?.applicantDetails?.phoneNumber
    //   : updatedPersonaldetailsForm
    //     ? updatedPersonaldetailsForm.confirmPhoneNumber
    //     : '',
    password: application
      ? application?.applicantDetails?.password
      : updatedPersonaldetailsForm
      ? updatedPersonaldetailsForm.password
      : "",
    confirmPassword: application
      ? application?.applicantDetails?.confirmPassword
      : updatedPersonaldetailsForm
      ? updatedPersonaldetailsForm.confirmPassword
      : "",
    phonePrefix: NIGERIA_PHONE_PREFIX,
    dob: application
      ? new Date(2005, 0, 1)
      : updatedPersonaldetailsForm
      ? updatedPersonaldetailsForm.dob
      : new Date(2005, 0, 1),
  });

  localStorage.setItem(
    "personalDetailsForm",
    JSON.stringify(personalDetailsForm)
  );
  const updateFormField = (params) => {
    const newForm = {
      ...personalDetailsForm,
      ...params,
    };

    setPersonalDetailsForm(newForm);
  };

  const isSenderPhoneValid = (newForm) => {
    const Form = newForm || personalDetailsForm;
    const countryValidator = phoneValidators[personalDetailsForm.phonePrefix];
    return (
      Form.phoneNumber &&
      (countryValidator ? countryValidator(Form.phoneNumber) : true)
    );
  };

  const isBeneficiaryPhoneValid = (newForm) => {
    const Form = newForm || personalDetailsForm;
    const countryValidator = phoneValidators[personalDetailsForm.phonePrefix];

    return (
      Form.phoneNumber &&
      (countryValidator ? countryValidator(Form.phoneNumber) : true)
    );
  };

  const isFormValid = () => {
    return (
      personalDetailsForm.phoneNumber &&
      personalDetailsForm.email &&
      personalDetailsForm.gender &&
      personalDetailsForm.password &&
      personalDetailsForm.dob &&
      personalDetailsForm.bvn &&
      isSenderPhoneValid() &&
      isEmailValid(personalDetailsForm) &&
      isBvnValid(personalDetailsForm) &&
      // isPhoneValidAndNew() &&
      // isEmailValidAndNew() &&
      personalDetailsForm.confirmPassword &&
      personalDetailsForm.confirmPassword === personalDetailsForm.password &&
      bvnResponse.success &&
      isPasswordValid(personalDetailsForm?.phoneNumber) &&
      isConfirmPasswordValid(personalDetailsForm?.confirmPassword)
    );
  };

  const onSubmitButtonClick = async () => {
    setIsLoading(true);
    const payload = {
      firstName: bvnData?.data?.firstName,
      lastName: bvnData?.data?.surname,
      email: personalDetailsForm.email,
      gender: personalDetailsForm.gender,
      mobileNo: personalDetailsForm.phoneNumber,
      password: personalDetailsForm.password,
      confirmPassword: personalDetailsForm.confirmPassword,
      domainCode: "app",
    };

    const { status, response } = await userManagement.signupUser(
      payload,
      true,
      false
    );

    setIsLoading(false);
    if (status === ERROR_STATUS) {
      firetoast("error", `${response.description}`, "");
    } else if (status === SUCCESS_STATUS) {
      notify("Saved", "success");
      localStorage.setItem("applicationId", response.applicationId);

      setScreen(7);
    }
    // setScreen(7);
  };

  const gender = ["Male", "Female"];

  const validateBVN = async () => {
    const date = personalDetailsForm.dob;

    setBvnResponse({
      success: "",
      error: "",
    });
    if (personalDetailsForm.bvn && personalDetailsForm.dob) {
      setLoading(true);
      const payload = {
        bvnNumber: personalDetailsForm.bvn,
        bvnDateOfBirth: specialDateRangeFormatter_StartDateDate(date),
      };
      const { status, response } = await onboarding.validateBVNDetails(payload);
      setBvnData(response);
      localStorage.setItem("BVNData", JSON.stringify(response));

      setLoading(false);
      if (status === ERROR_STATUS) {
        setBvnResponse({ error: response.description });
      } else if (status === SUCCESS_STATUS) {
        setBvnResponse({ success: response.description });
      }
    }
  };

  const handleCopy = (event) => {
    event.preventDefault();
  };

  const handlePaste = (event) => {
    event.preventDefault();
  };

  const NEW_PHONE = "phone is new";
  const NEW_EMAIL = "email is new";
  const USED_PHONE = "phone not new";
  const USED_EMAIL = "email not new";

  function isEmailValidAndNew() {
    return isEmailValid(personalDetailsForm) && isEmailExisting === NEW_EMAIL;
  }

  const userManagement = new UserManagement();

  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const [isPasswordLoading, setIsPasswordLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [isExisting] = useState(false);
  const [isEmailExisting, setIsEmailExisting] = useState("");

  const validateEmail = async (email) => {
    if (!email) {
      return;
    }

    setIsEmailLoading(true);
    const validateUserObj = await userManagement.validateUserOnPassport(email);

    const { code } = validateUserObj;
    if (code === SUCCESS_CODE) {
      setIsEmailExisting(USED_EMAIL);
      setIsEmailLoading(false);
      setEmailError(false);
    } else if (code === HTTP_NOT_FOUND_CODE) {
      setIsEmailExisting(NEW_EMAIL);
      setIsEmailLoading(false);
      setEmailError(false);
    } else {
      setIsEmailLoading(false);
      setEmailError(true);
    }
  };

  function isPhoneValidAndNew() {
    return (
      isPhoneValid(
        personalDetailsForm.phoneNumber,
        personalDetailsForm.phonePrefix
      ) && isPhoneExisting === NEW_PHONE
    );
  }

  const [isPhoneLoading, setIsPhoneLoading] = useState(false);
  const [isPhoneExisting, setIsPhoneExisting] = useState("");
  const [phoneError, setPhoneError] = useState(false);

  const validatePhone = async (phone) => {
    if (!phone) {
      return;
    }

    setIsPhoneLoading(true);
    const formattedPhone = formatEmailOrPhone(phone);
    const validateUserObj = await userManagement.validateUserOnPassport(
      formattedPhone
    );
    const { code } = validateUserObj;
    if (code === SUCCESS_CODE) {
      setIsPhoneExisting(USED_PHONE);
      setIsPhoneLoading(false);
      setPhoneError(false);
    } else if (code === HTTP_NOT_FOUND_CODE) {
      setIsPhoneExisting(NEW_PHONE);
      setIsPhoneLoading(false);
      setPhoneError(false);
    } else {
      setIsPhoneLoading(false);
      setPhoneError(true);
    }
  };

  useEffect(() => {
    if (personalDetailsForm.bvn.length === 11) {
      validateBVN();
    }
  }, [personalDetailsForm.bvn, personalDetailsForm.dob]);

  //  async validatePhone(phone) {
  //   if (!phone) {
  //     return;
  //   }
  //   this.setState({
  //     isPhoneLoading: true,
  //   });

  //   const formattedPhone = formatEmailOrPhone(phone);
  //   const validateUserObj = await this.userManagement.validateUserOnPassport(
  //     formattedPhone
  //   );

  //   const { code } = validateUserObj;
  //   if (code === SUCCESS_CODE) {
  //     this.setState({
  //       isPhoneExisting: USED_PHONE,
  //       isPhoneLoading: false,
  //       phoneError: false,
  //     });
  //   } else if (code === HTTP_NOT_FOUND_CODE) {
  //     this.setState({
  //       isPhoneExisting: NEW_PHONE,
  //       isPhoneLoading: false,
  //       phoneError: false,
  //     });
  //   } else {
  //     this.setState({
  //       phoneError: true,
  //       isPhoneLoading: false,
  //     });
  //   }
  // }

  // async validateEmail(email) {
  //   if (!email) {
  //     return;
  //   }
  //   this.setState({
  //     isEmailLoading: true,
  //   });
  //   const validateUserObj = await this.userManagement.validateUserOnPassport(
  //     email
  //   );

  //   const { code } = validateUserObj;
  //   if (code === SUCCESS_CODE) {
  //     this.setState({
  //       isEmailExisting: USED_EMAIL,
  //       isEmailLoading: false,
  //       emailError: false,
  //     });
  //   } else if (code === HTTP_NOT_FOUND_CODE) {
  //     this.setState({
  //       isEmailExisting: NEW_EMAIL,
  //       isEmailLoading: false,
  //       emailError: false,
  //     });
  //   } else {
  //     this.setState({
  //       emailError: true,
  //       isEmailLoading: false,
  //     });
  //   }
  // }
  return (
    <>
      {/* <HeaderSignup /> */}
      <main className="onboarding-container">
        <Col
          style={{
            marginBottom: "20px",
            margin: "0 auto",
          }}
        >
          {/* <SignUp /> */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              gap: "10px",
              marginBottom: "30px",
            }}
          >
            <Stepper borderColor="#00425F" />
            <Stepper />
            <Stepper />
            <Stepper />
          </div>
          <H1
            style={{
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "1rem",
              fontSize: "2rem",
              // fontWeight: '800',
            }}
          >
            Personal Details
          </H1>
          <p>Let's get to know you</p>

          <PhoneInput
            onBlur={() => {
              isPhoneValid(
                personalDetailsForm.phoneNumber,
                personalDetailsForm.phonePrefix
              ) && validatePhone(personalDetailsForm.phoneNumber);
            }}
            onChange={(value) => {
              updateFormField({ phoneNumber: value });
            }}
            // disabled={applicationId}
            form={personalDetailsForm.phoneNumber}
            updateFormField={updateFormField}
            isSenderPhoneValid={isSenderPhoneValid}
            isBeneficiaryPhoneValid={isBeneficiaryPhoneValid}
            fieldsFocused={fieldsFocused}
            setFieldsFocused={setFieldsFocused}
            propagateFormErrors={propagateFormErrors}
            title={"Phone Number"}
          />

          {isPhoneLoading && (
            <IosRefresh
              style={{
                marginTop: "-52pt",
                marginLeft: "85%",
                width: "20px",
                position: "absolute",
              }}
              fontSize="60px"
              color="grey"
              rotate={true}
            />
          )}
          {isPhoneValidAndNew() && !isPhoneLoading ? (
            <MdCheckmarkCircle
              style={{
                marginTop: "-45pt",
                marginLeft: "85%",
                width: "20px",
                position: "absolute",
              }}
              color="green"
              fontSize="40px"
            />
          ) : (
            ""
          )}
          {!personalDetailsForm.phoneNumber ||
          isPhoneValid(
            personalDetailsForm.phoneNumber,
            personalDetailsForm.phonePrefix
          ) ||
          !isExisting ? (
            <React.Fragment />
          ) : (
            <FormLabel
              style={{
                color: process.env.REACT_APP_RED_COLOUR,
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                lineHeight: "19px",
                position: "relative",
                top: "-15px",
                width: "100%",
              }}
            >
              Phone number is not valid.
            </FormLabel>
          )}
          {isPhoneExisting === USED_PHONE &&
            personalDetailsForm.phoneNumber && (
              <FormLabel
                style={{
                  color: process.env.REACT_APP_RED_COLOUR,
                  fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                  lineHeight: "19px",
                  position: "relative",
                  top: "-15px",
                  width: "100%",
                }}
              >
                Phone number already registered on Quickteller Paypoint. Try
                another number!
              </FormLabel>
            )}
          {phoneError && personalDetailsForm.phoneNumber && (
            <FormLabel
              style={{
                color: process.env.REACT_APP_RED_COLOUR,
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                lineHeight: "19px",
                position: "relative",
                top: "-15px",
                width: "100%",
              }}
            >
              Failed to validate Phone Number. Try again
            </FormLabel>
          )}
          {/* <PhoneInput
            title='Re-enter Phone Number'
            disabled={applicationId}
            checkConfirmValidError={true}
            form={personalDetailsForm.confirmPhoneNumber}
            handleCopy={handleCopy}
            handlePaste={handlePaste}
            onChange={(value) => {
              updateFormField({ confirmPhoneNumber: value });
            }}
            updateFormField={updateFormField}
            isSenderPhoneValid={isSenderPhoneValid}
            isBeneficiaryPhoneValid={isBeneficiaryPhoneValid}
            fieldsFocused={fieldsFocused}
            setFieldsFocused={setFieldsFocused}
            propagateFormErrors={propagateFormErrors}
          />
          {personalDetailsForm.confirmPhoneNumber &&
            personalDetailsForm.confirmPhoneNumber !==
            personalDetailsForm.phoneNumber && (
              <FormLabel
                style={{
                  color: process.env.REACT_APP_RED_COLOUR,
                  fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                  lineHeight: '19px',
                  position: 'relative',
                  top: '-15px',

                  width: '100%',
                }}
              >
                Phone number does not match
              </FormLabel>
            )} */}

          <InputText
            onBlur={() => {
              isEmailValid(personalDetailsForm) &&
                validateEmail(personalDetailsForm.email);
            }}
            // disabled={applicationId}
            onChange={(e) => {
              updateFormField({ email: e.target.value.trim() });
            }}
            labelColor={
              !personalDetailsForm.email || isEmailValid(personalDetailsForm)
                ? "#3A3B3B"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !personalDetailsForm.email || isEmailValid(personalDetailsForm)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            type={"email"}
            value={personalDetailsForm.email}
            label={"Email address"}
            placeholder={"Enter  email adress"}
          />

          {isEmailLoading && (
            <IosRefresh
              style={{
                marginTop: "-52pt",
                marginLeft: "85%",
                width: "20px",
                position: "absolute",
              }}
              fontSize="60px"
              color="grey"
              rotate={true}
            />
          )}

          {isEmailValidAndNew() && !isEmailLoading ? (
            <MdCheckmarkCircle
              style={{
                marginTop: "-45pt",
                marginLeft: "85%",
                width: "20px",
                position: "absolute",
              }}
              color="green"
              fontSize="40px"
            />
          ) : (
            ""
          )}
          {!personalDetailsForm.email ||
          isEmailValid(personalDetailsForm) ||
          !isExisting ? (
            <React.Fragment />
          ) : (
            <FormLabel
              style={{
                color: process.env.REACT_APP_RED_COLOUR,
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                lineHeight: "19px",
                position: "relative",
                top: "-15px",
                width: "100%",
              }}
            >
              Email is not valid.
            </FormLabel>
          )}
          {isEmailExisting === USED_EMAIL && personalDetailsForm.email && (
            <FormLabel
              style={{
                color: process.env.REACT_APP_RED_COLOUR,
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                lineHeight: "19px",
                position: "relative",
                top: "-15px",

                width: "100%",
              }}
            >
              Email already registered on Quickteller Paypoint. Try another
              email!
            </FormLabel>
          )}
          {emailError && personalDetailsForm.email && (
            <FormLabel
              style={{
                color: process.env.REACT_APP_RED_COLOUR,
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                lineHeight: "19px",
                position: "relative",
                top: "-15px",
                width: "100%",
              }}
            >
              Failed to validate Email. Try again
            </FormLabel>
          )}
          {/* <InputText
            disabled={applicationId}
            onChange={(e) => {
              updateFormField({ confirmEmail: e.target.value.trim() });
            }}
            // labelColor={
            //   !personalDetailsForm.email || isEmailValid(personalDetailsForm)
            //     ? '#3A3B3B'
            //     : process.env.REACT_APP_RED_COLOUR
            // }
            // border={`1px solid ${
            //   !personalDetailsForm.email || isEmailValid(personalDetailsForm)
            //     ? '#DFE2E6'
            //     : process.env.REACT_APP_RED_COLOUR
            // }`}
            type={'email'}
            handleCopy={handleCopy}
            handlePaste={handlePaste}
            value={personalDetailsForm.confirmEmail}
            label={'Re-enter Email address'}
            placeholder={'Re-enter  email adress'}
          />
          {personalDetailsForm.confirmEmail &&
            personalDetailsForm.confirmEmail !== personalDetailsForm.email && (
              <FormLabel
                style={{
                  color: process.env.REACT_APP_RED_COLOUR,
                  fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                  lineHeight: '19px',
                  position: 'relative',
                  top: '-15px',

                  width: '100%',
                }}
              >
                Email address does not match
              </FormLabel>
            )} */}
          <SelectInput
            onChange={(e) => {
              updateFormField({ gender: e.target.value });
            }}
            placeholder={"gender"}
            value={personalDetailsForm.gender}
            option={gender}
            label={"Gender"}
            disabled={applicationId}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "1rem 0",
            }}
          >
            <DatePickerComponent
              onChange={(date) => {
                updateFormField({ dob: date });
              }}
              selected={personalDetailsForm.dob}
              label={"Date of Birth"}
              placeholder="DD-MM-YYYY"
              size={`100%`}
              format={`dd-MM-yyyy`}
              isClearable={true}
              showTimeInput={true}
              // disabled={applicationId}
              // style={'0.4rem'}
              textStyle={"1.0rem"}
              iconStyle={"0.2rem"}
              dateType={"startDate"}
              preloadDate={false}
            />
          </div>
          {bvnResponse?.error === "Invalid Date of Birth" && (
            <div
              style={{
                color: process.env.REACT_APP_RED_COLOUR,
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,

                position: "relative",
                top: "-10px",
              }}
            >
              {bvnResponse.error}
            </div>
          )}
          <InputText
            loading={loading}
            // disabled={applicationId}
            label={"BVN"}
            labelColor={
              !personalDetailsForm.bvn || isBvnValid(personalDetailsForm)
                ? "#3A3B3B"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !personalDetailsForm.bvn || isBvnValid(personalDetailsForm)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            placeholder={"BVN"}
            onChange={(e) => {
              updateFormField({ bvn: stripNonNumbers(e.target.value.trim()) });
            }}
            value={personalDetailsForm.bvn}
            maxLength={MAX_BVN_LENGTH}
          />
          {bvnResponse.error && (
            <FormLabel
              style={{
                color: process.env.REACT_APP_RED_COLOUR,
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                lineHeight: "19px",
                position: "relative",
                top: "-15px",

                width: "100%",
              }}
            >
              {bvnResponse.error}
            </FormLabel>
          )}
          {bvnResponse?.success ? (
            <MdCheckmarkCircle
              style={{
                marginTop: "-45pt",
                marginLeft: "85%",
                width: "20px",
                position: "absolute",
              }}
              color="green"
              fontSize="40px"
            />
          ) : (
            ""
          )}

          {bvnResponse?.success && (
            <div
              style={{
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                color: "#32de84",

                position: "relative",
                top: "-10px",
              }}
            >
              {bvnResponse.success}
            </div>
          )}

          {!bvnResponse?.error === "Invalid Date of Birth" && (
            <div
              style={{
                color: process.env.REACT_APP_RED_COLOUR,
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,

                position: "relative",
                top: "-10px",
              }}
            >
              {bvnResponse.error}
            </div>
          )}
          <div style={{ position: "relative" }}>
            <InputText
              // onBlur={() => {
              //   isPasswordValid(personalDetailsForm) &&
              //     validateEmail(personalDetailsForm.password);
              // }}
              // disabled={applicationId}
              onChange={(e) => {
                updateFormField({ password: e.target.value.trim() });
              }}
              labelColor={
                !personalDetailsForm.password ||
                isPasswordValid(personalDetailsForm)
                  ? "#3A3B3B"
                  : process.env.REACT_APP_RED_COLOUR
              }
              border={`1px solid ${
                !personalDetailsForm.email ||
                isPasswordValid(personalDetailsForm)
                  ? "#DFE2E6"
                  : process.env.REACT_APP_RED_COLOUR
              }`}
              type={visible ? "text" : "password"}
              value={personalDetailsForm.password}
              label={"Password"}
              placeholder={"Enter password"}
            />
            <span
              style={{
                position: "absolute",
                top: "40px",
                right: "4%",
                cursor: "pointer",
              }}
              onClick={() => setVisible(!visible)}
            >
              {PasswordIcon()}
            </span>
          </div>
          {isPasswordLoading && (
            <IosRefresh
              style={{
                marginTop: "-52pt",
                marginLeft: "85%",
                width: "20px",
                position: "absolute",
              }}
              fontSize="60px"
              color="grey"
              rotate={true}
            />
          )}

          {isPasswordValid() && !isPasswordLoading ? (
            <MdCheckmarkCircle
              style={{
                marginTop: "-45pt",
                marginLeft: "85%",
                width: "20px",
                position: "absolute",
              }}
              color="green"
              fontSize="40px"
            />
          ) : (
            ""
          )}
          {!personalDetailsForm.password ||
          isPasswordValid(personalDetailsForm.password) ? (
            <React.Fragment />
          ) : (
            <FormLabel
              style={{
                color: process.env.REACT_APP_RED_COLOUR,
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                lineHeight: "19px",
                position: "relative",
                top: "-15px",
                width: "100%",
              }}
            >
              Password is not valid.
            </FormLabel>
          )}
          {/* { !personalDetailsForm.password && (
              <FormLabel
                style={{
                  color: process.env.REACT_APP_RED_COLOUR,
                  fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                  lineHeight: '19px',
                  position: 'relative',
                  top: '-15px',

                  width: '100%',
                }}
              >
                 Please enter password!
              </FormLabel>
            )} */}
          {passwordError && personalDetailsForm.password && (
            <FormLabel
              style={{
                color: process.env.REACT_APP_RED_COLOUR,
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                lineHeight: "19px",
                position: "relative",
                top: "-15px",
                width: "100%",
              }}
            >
              Failed to validate Password. Try again
            </FormLabel>
          )}

          <div style={{ position: "relative" }}>
            <InputText
              // disabled={applicationId}
              onChange={(e) => {
                updateFormField({ confirmPassword: e.target.value.trim() });
              }}
              labelColor={
                !personalDetailsForm.confirmPassword ||
                isConfirmPasswordValid(personalDetailsForm)
                  ? "#3A3B3B"
                  : process.env.REACT_APP_RED_COLOUR
              }
              border={`1px solid ${
                !personalDetailsForm.confirmPassword ||
                isConfirmPasswordValid(personalDetailsForm)
                  ? "#DFE2E6"
                  : process.env.REACT_APP_RED_COLOUR
              }`}
              type={confirmVisible ? "text" : "password"}
              handleCopy={handleCopy}
              handlePaste={handlePaste}
              value={personalDetailsForm.confirmPassword}
              label={"Confirm Password"}
              placeholder={"confirm password"}
            />
            <span
              style={{
                position: "absolute",
                top: "40px",
                right: "4%",
                cursor: "pointer",
              }}
              onClick={() => setConfirmVisible(!confirmVisible)}
            >
              {PasswordIcon()}
            </span>
          </div>

          {personalDetailsForm.confirmPassword &&
            personalDetailsForm.confirmPassword !==
              personalDetailsForm.password && (
              <FormLabel
                style={{
                  color: process.env.REACT_APP_RED_COLOUR,
                  fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                  lineHeight: "19px",
                  position: "relative",
                  top: "-15px",

                  width: "100%",
                }}
              >
                Password does not match
              </FormLabel>
            )}

          <PrimaryButton
            disabled={!isFormValid() || isLoading}
            onClick={onSubmitButtonClick}
          >
            {" "}
            {isLoading ? "Processing..." : "Next"}
          </PrimaryButton>
        </Col>
      </main>
    </>
  );
};

function mapStateToProps(state) {
  return {
    // isLoading: state.tunnel.isLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: (pendingUrl) => dispatch(navigateTo(pendingUrl)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalDetailsScene);
