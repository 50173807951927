import React from "react";
import "../app.css";
import PrimaryButton from "../../performance/settings/components/customButton";
import { useHistory } from "react-router-dom";

const InventoryLabel = ({ disableButton }) => {
  const history = useHistory();

  return (
    <div className="inventoryLabelContainer">
      <h1 className="inventoryLabelText">Overview</h1>
      {disableButton || (
        <PrimaryButton
          width={"11rem"}
          height={"40px"}
          margin={0}
          fontSize={"14px"}
          onClick={() =>
            history.push("/agent/pos-management/aggregator/request-pos")
          }
        >
          Request POS
        </PrimaryButton>
      )}
    </div>
  );
};

export default InventoryLabel;
