import React from 'react';
import MdArrowBack from 'react-ionicons/lib/MdArrowBack';
import { Link } from 'react-router-dom';

const Layout = ({ children }) => {
  return (
    <>
      <Link
        to={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-devices/pos-management`}
      >
        <div
          style={{
            margin: '30px 0 0 30px',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <MdArrowBack fontSize='26px' style={{ cursor: 'pointer' }} />
          <span
            style={{
              display: 'flex',
              justifyContent: 'start',
              textDecoration: 'none',
              color: 'black',
            }}
          >
            Go Back
          </span>
        </div>
      </Link>
      <div className='pos-layout'>{children}</div>
    </>
  );
};

export default Layout;
