import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { userManagementServiceClient } from '../../../../../App';
import { notify } from '../../../../../components/extras';
import Text from '../../../../../components/text';
import { ERROR_STATUS, SUCCESS_STATUS } from '../../../../../constants/api';
import POSIMAGE from '../../../../../icons/pos-image.png';
import { usePosRequestContext } from '../context/pos-Request-context';

function getPosImage(link) {
  return link && `${process.env.REACT_APP_POS_IMAGE}` + '/' + link.slice(6);
}

const SelectPosModel = ({ setScreen }) => {
  const { value, setValue } = usePosRequestContext();
  const [model, setModel] = useState([]);
  const [error, setError] = useState('');

  const handleSubmit = () => {
    if (value) {
      setScreen(2);
    } else {
      notify('Select POS model', 'warning');
    }
  };

  const fetchPosModel = async () => {
    const { status, response } =
      await userManagementServiceClient.getPosModel();
    if (status === ERROR_STATUS) {
      setError('Failed fetching Pos Model');
    } else if (status === SUCCESS_STATUS) {
      setModel(response.data);
    }
  };
  useEffect(() => {
    fetchPosModel();
  }, []);
  useEffect(() => {}, [value]);
  return (
    <>
      <div className='pos-request-label'>POS Model: *</div>{' '}
      <Select
        options={model.map((model, i) => ({
          label: model.terminalModel,
          value: model.terminalModel,
          fee: model.fee,
          price: model.price,
          terminalType: model.terminalType,
          terminalImage: getPosImage(model.terminalImage),
          terminalDescription: model.terminalDescription,
        }))}
        onChange={(event) => {
          setValue(event);
        }}
        className='select-pos-request-component sm:w-100'
        classNamePrefix='react-select'
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: 'rgb(178, 212, 255)',
          },
        })}
      />
      <Text style={{ color: 'tomato' }}>{error}</Text>
      {value ? (
        <section className='pos-request-section-container'>
          <article className='pos-request-article1'>
            <img
              style={{
                width: '100%',
                height: '27rem',
                objectFit: 'cover',
                borderRadius: '10px',
              }}
              src={value.terminalImage ? value.terminalImage : POSIMAGE}
              alt='pos terminal'
            />
          </article>
          <div className='w-100'>
            <div className='pos-request-article2-content'>
              {value.terminalDescription ? (
                value.terminalDescription
              ) : (
                <span>
                  This Android POS terminal is a portable device that can be
                  used to accept payments from customers for goods and services.
                  It typically consists of a touchscreen display, a card reader,
                  a printer, and a battery. The device runs on the Android
                  operating system, which allows it to run various payment
                  applications.
                  <br /> <br />
                  To use the Android POS terminal, you simply need to connect it
                  to a power source and turn it on. Once the device is on, you
                  can launch the payment application and select the type of
                  transaction you want to process. For example, you can choose
                  to accept a debit or credit card payment, or even process
                  mobile payments.
                </span>
              )}
            </div>
            <button
              onClick={handleSubmit}
              className='request-pos-article2-button'
              disabled={!value}
            >
              Request
            </button>
          </div>
        </section>
      ) : (
        ''
      )}
    </>
  );
};

export default SelectPosModel;
