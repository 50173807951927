import React from 'react';
import { Modal } from 'react-bootstrap';

export const checkScreenSize = () => {
  if (window.innerWidth <= 576) return true;
};
export const ResponseModal = ({
  show,
  handleClose,
  title,
  loading,
  paragraph,
  handleSubmit,
}) => {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div
            style={{
              margin: '30px',
            }}
          >
            <h1
              style={{
                fontSize: '18px',
                fontWeight: '600',
                textAlign: 'center',
                color: '#353F50',
              }}
            >
              {title}
            </h1>
            <p
              style={{
                fontSize: '14px',
                fontWeight: '400',
                color: '#353F50',
                textAlign: 'center',
              }}
            >
              {paragraph}
            </p>
          </div>
        </Modal.Body>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '50%',
            gap: checkScreenSize() ? '15px' : 0,
            paddingBottom: '30px',
            margin: '0 auto',
          }}
        >
          <button
            style={{
              fontSize: '14px',
              borderRadius: '5px',
              width: '105px',
              height: '45px',

              border: '1px solid #00B8DE',
              color: '#00B8DE',
            }}
            className='poscancelbutton'
            onClick={handleClose}
          >
            {'Cancel'}
          </button>
          <button
            style={{
              fontSize: '14px',
              borderRadius: '5px',
              width: '105px',
              height: '45px',
              outline: 'none',
              border: 'none',
              backgroundColor: '#00B8DE',
              color: 'white',
            }}
            disabled={loading}
            onClick={handleSubmit}
          >
            {loading ? 'Loading...' : 'Proceed'}
          </button>
        </div>
      </Modal>
    </>
  );
};
