import moment from 'moment';

const ACTIVE = 'Active'
const DEACTIVATED = 'Deactivated'
const DORMANT = 'Dormant'
const INACTIVE = 'Inactive'
const NEW = 'New'
const RETIRED = 'Retired'
const SUSPENDED = 'Suspended'
const WALLETCREATED = 'WALLET_CREATED'

//TODO handle agent serializer ternary operator here
export default class Agent {
  disabledStatuses = [INACTIVE, RETIRED, SUSPENDED];

  constructor(props) {
    Object.assign(this, props);

    this.agentPhone = props ? props.agentMobileNo : null;
    this.businessPhone = props ? props.businessPhoneNo : null;
  }

  get age() {
    if (!this.businessContact) {
      return null
    }
    
    return parseInt(moment().year()) - parseInt(moment(this.businessContact.dob, 'YYYY-MM-DD').year());
  }

  get gender() {
    return this.businessContact ? this.businessContact.gender : null;
  }

  get isActive() {
    return this.status === ACTIVE;
  }

  get isDeactivated() {
    return this.status === DEACTIVATED;
  }

  get isDisabled() {
    return this.isSetupComplete === false || this.disabledStatuses.includes(this.status);
  }

  get isDormant() {
    return this.status === DORMANT;
  }

  get isInactive() {
    return this.status === INACTIVE;
  }

  get isNew() {
    return this.status === NEW;
  }

  get isRetired() {
    return this.status === RETIRED;
  }

  get isSetupComplete() {
    
    if (this.walletStatus === undefined) {
      return null
    }

    return this.walletStatus === WALLETCREATED;
  }

  get isSuspended() {
    return this.status === SUSPENDED;
  }

  asJson() {
    return {
      ...this.props,
      agentAge: this.age,
      agentGender: this.gender
    }
  }

}
