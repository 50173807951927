import React, { Component } from "react";
import { Alert, Col, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import IosInformation from "react-ionicons/lib/IosInformationCircleOutline";
import IosRefresh from "react-ionicons/lib/IosRefresh";
import MdArrowBack from "react-ionicons/lib/MdArrowBack";
import MdSearch from "react-ionicons/lib/MdSearch";
import BankMonitoring from "../../../../services/api/resources/bank-monitoring";
import BankNetworkTable from "./components/bank-network-table";

export default class BankNetworkScene extends Component {
  constructor(props) {
    super();
    this.state = {
      searchValue: "",
      isLoading: false,
      searchItem: false,
      isAlert: false,
      searchRecord: [],
      bankData: {},
      formattedData: [],
      showInfo: false,
      banks: [],
      noRecord: false,
      loadingData: false,
      showSearchIcon: true,
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.transformSearchItem = this.transformSearchItem.bind(this);
    this.refreshBankData = this.refreshBankData.bind(this);
  }

  sceneName = "bank-network";
  bankMonitoring = new BankMonitoring();

  componentDidMount() {
    // this.props.updateScene(this.sceneName);
    this.fetchBanksData();
  }

  async fetchBanksData() {
    this.setState({ loadingData: true });

    try {
      const res = await this.bankMonitoring.getBanksNetwork();
      if (res.response === "" || res.response?.length === 0 || !res.response) {
        this.setState({ noRecord: true, loadingData: false });
        return;
      }
      this.setState({
        banks: res.response?.sort((a, b) =>
          a.bankName.localeCompare(b.bankName)
        ),
        noRecord: false,
        loadingData: false,
      });
    } catch (err) {
      this.setState({ isAlert: true, noRecord: true, loadingData: false });
      setTimeout(() => {
        this.setState({ isAlert: false });
      }, 3000);
    }
  }

  transformSearchItem(value) {
    const lowercaseSearchValue = value.toLowerCase();
    const text = lowercaseSearchValue;

    const firstSpaceIndex = text.indexOf(" ");

    const firstWord =
      firstSpaceIndex !== -1 ? text.substring(0, firstSpaceIndex) : text;
    return firstWord;
  }

  handleSearch(searchValue) {
    this.setState({ searchValue: searchValue, showSearchIcon: false });
    if (searchValue === "" || !searchValue) {
      this.setState({ searchItem: false, showSearchIcon: true });
      return;
    }

    const { banks } = this.state;

    this.setState({ isLoading: true, searchItem: false });

    const formattedData = Object.keys(banks).map((bankName) => ({
      bank: bankName,
      ...banks[bankName],
    }));
    const searchTerm = searchValue.toLowerCase();

    const res = formattedData.filter((item) =>
      item.bankName.toLowerCase().includes(searchTerm)
    );

    if (res.length === 0) {
      this.setState({ searchItem: true, searchRecord: [], noRecord: true });

      setTimeout(() => {
        this.setState({ isLoading: false });
      }, 1000);
    } else {
      this.setState({
        searchRecord: res,
        searchItem: true,
        noRecord: false,
      });
      setTimeout(() => {
        this.setState({ isLoading: false });
      }, 0);
    }
  }

  async refreshBankData() {
    this.setState({
      isLoading: true,
      searchItem: false,
      searchValue: "",
      showSearchIcon: true,
    });
    try {
      const res = await this.bankMonitoring.getBanksNetwork();

      if (res.response === "" || res.response?.length === 0 || !res.response) {
        this.setState({ noRecord: true, isLoading: false });
        return;
      }
      this.setState({
        banks: res.response?.sort((a, b) =>
          a.bankName.localeCompare(b.bankName)
        ),
        noRecord: false,
        isLoading: false,
      });
    } catch (err) {
      this.setState({ isAlert: true, noRecord: true, isLoading: false });
      setTimeout(() => {
        this.setState({ isAlert: false });
      }, 3000);
    }
  }

  render() {
    const {
      searchRecord,
      searchItem,
      isAlert,
      searchValue,
      showInfo,
      bankData,
      banks,
      noRecord,
      loadingData,
    } = this.state;

    return (
      // <React.Fragment>
      <div
        className={
          this.state.deviceIsMobile
            ? "container container-fluid reports-scene-container-mobile"
            : "reports-scene-container"
        }
      >
        <Container
          fluid
          // className="regular-container"
          style={{ padding: "1px" }}
        >
          <div style={{ marginLeft: 10 }}>
            <div className="h1 reports-scene-header">
              Bank Network{" "}
              <span
                onMouseOver={() => this.setState({ showInfo: true })}
                onMouseOut={() => this.setState({ showInfo: false })}
                style={{ cursor: "pointer" }}
              >
                <IosInformation
                  style={{ width: "15px" }}
                  fontSize="20px"
                  color="grey"
                  rotate={false}
                />
              </span>
            </div>
            <p style={{ fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY }}>
              View the real-time success rate of your recipient bank cards.
            </p>
          </div>
          {showInfo && (
            <div
              style={{
                backgroundColor: "white",
                position: "absolute",
                top: 92,
                zIndex: 1,
                paddingLeft: 12,
                paddingRight: 12,
                paddingTop: 8,
                borderRadius: 8,
                marginLeft: 150,
                boxShadow: "0px 1px 1px grey",
                marginRight: 45,
              }}
            >
              <p
                style={{
                  fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
                  fontSize: 14,
                  lineHeight: 1.6,
                }}
              >
                The % success rate provided below is based on the number of
                successful transactions passed through the Interswitch Kimono
                Application. You are at liberty not to use this information in
                your decision to complete a transaction.
              </p>
            </div>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 20,
              marginLeft: 15,
            }}
          >
            <Col lg={4}>
              {this.state.showSearchIcon && (
                <div
                  style={{
                    position: "absolute",
                    left: 1,
                    backgroundColor: "white",
                    padding: 3,
                    paddingBottom: 4.5,
                    borderRadius: 10,
                  }}
                >
                  <MdSearch color={"#DFE2E6"} fontSize="20px" width="24px" />
                </div>
              )}

              <Form.Control
                onChange={(event) => this.handleSearch(event.target.value)}
                style={{
                  border: "none",
                  height: 40,
                  borderRadius: 10,
                  outline: "none",
                  outlineColor: "none",
                  boxShadow: "none",
                }}
                placeholder="Search"
                value={this.state.searchValue}
              />

              {searchItem && (
                <div
                  onClick={() => {
                    this.setState({
                      searchItem: false,
                      isAlert: false,
                      searchValue: "",
                      showSearchIcon: true,
                    });
                  }}
                  style={{
                    fontSize: 30,
                    cursor: "pointer",
                  }}
                >
                  <MdArrowBack color="grey" />
                </div>
              )}
            </Col>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderStyle: "solid",
                borderWidth: 1,
                height: 20,
                padding: 10,
                borderRadius: 5,
                backgroundColor: "white",
                borderColor: "#BFC2C6",
                cursor: "pointer",
                userSelect: "none",
              }}
              onClick={this.refreshBankData}
            >
              <IosRefresh
                style={{ width: "24px" }}
                fontSize="18px"
                color="#0275D8"
                rotate={false}
              />
              <p
                style={{
                  marginTop: 17,
                  color: "#0275D8",
                  fontSize: 16,
                  fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
                }}
              >
                Refresh
              </p>
            </div>
          </div>
          {isAlert && (
            <div style={{}}>
              <Alert variant="danger">{"Oops! Something went wrong."}</Alert>
            </div>
          )}
          <BankNetworkTable
            data={searchItem ? searchRecord : banks}
            loading={this.state.isLoading}
            noRecord={noRecord}
            loadingData={loadingData}
          />
        </Container>
        {/* </React.Fragment> */}
      </div>
    );
  }
}
