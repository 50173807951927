import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactTable from "react-table";
import MdMore from "react-ionicons/lib/MdMore";
import MdEye from "react-ionicons/lib/MdEye";
import Spinner from "../../../../../components/spinner";
import { Dropdown, Modal, Row } from "react-bootstrap";
import { getPaginationRows } from "../../../../../utils/helpers";

import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../../constants/api";
import { notify } from "../../../../../components/extras";
import CAC from "../../../../../services/api/resources/cac-service";
import { ModalComponent } from "./viewMore";

const ApplicationGrid = ({ searchText }) => {
  const cac = new CAC();
  const currentAgent = JSON.parse(localStorage.getItem("currentAgent"));
  const [loading, setLoading] = useState(true);
  const [application, setApplication] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [pages, setPages] = useState(1);
  const [pageNum, setPageNum] = useState(1);
  const [dataCount, setDataCount] = useState();
  const [pageSize, setPageSize] = useState(20);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const statusColor = (status) => {
    switch (status) {
      case "QUERIED":
        return "#A4251A";
      case "INITIATED":
        return "#808080";
      case "PROCESSING":
        return "#AF5F26";
      case "SUCCESSFUL":
        return "#36743D";
      default:
        return "#000";
    }
  };

  const statusBackgroundColor = (status) => {
    switch (status) {
      case "QUERIED":
        return "#FBE9E9";
      case "INITIATED":
        return "#E5E4E2";
      case "PROCESSING":
        return "#FEF6CF";
      case "SUCCESSFUL":
        return "#F1FEF1";
      default:
        return "#FFF";
    }
  };

  const statusBorderColor = (status) => {
    switch (status) {
      case "QUERIED":
        return "#F4B7B5";
      case "INITIATED":
        return "#808080";
      case "PROCESSING":
        return "#FDED94";
      case "SUCCESSFUL":
        return "#BEF2B9";
      default:
        return "#DDD";
    }
  };

  const getApplication = async (pageSize_, pageNum_) => {
    setLoading(true);

    const { response, status } = await cac.getCacRegistrationReport(
      pageSize_,
      pageNum_,
      currentAgent?.agentCode
    );

    setLoading(false);
    if (status === ERROR_STATUS) {
      setPages(1);
      notify(response?.data?.description, "error");
    } else if (status === SUCCESS_STATUS) {
      setDataCount(response?.data?.count / pageSize_);
      setPages(Math.ceil(response?.data?.count / pageSize_));
      setApplication(response?.data?.content);
    }
  };

  useEffect(() => {
    getApplication(pageSize, pageNum);
  }, [pageSize, pageNum]);

  useEffect(() => {
    const lowercasedSearchText = searchText.toLowerCase();
    const filtered = application.filter(
      (item) =>
        item.businessName.toLowerCase().includes(lowercasedSearchText) ||
        moment(item.dateCreated)
          .format("DD-MM-YYYY HH:mm a")
          .includes(lowercasedSearchText) ||
        item.reference.toLowerCase().includes(lowercasedSearchText)
    );
    setFilteredData(filtered);
  }, [application, searchText]);

  const handleOpenView = (props) => {
    setModalData(props);
    setShowModal(!showModal);
  };

  const reportColumns = [
    {
      Header: "Business Name",
      accessor: "businessName",
      headerClassName: "table-header",
      className: "table-cell",
      style: { "white-space": "unset", textAlign: "left" },
      width: 240,
    },
    {
      Header: "Business Type",
      accessor: "lineOfBusiness",
      headerClassName: "table-header",
      className: "table-cell",
      style: { "white-space": "unset", textAlign: "left" },
      width: 240,
    },
    {
      Header: "Transaction reference",
      accessor: "reference",

      headerClassName: "table-header",
      className: "table-cell",
      style: { "white-space": "unset", textAlign: "left" },
      width: 200,
    },
    {
      Header: "Date Created",
      accessor: "dateCreated",
      Cell: ({ value }) => (
        <div style={{ textAlign: "left" }}>
          {moment(value).format("DD-MM-YYYY HH:mm a")}
        </div>
      ),
      headerClassName: "table-header",
      className: "table-cell",
      style: { "white-space": "unset" },
    },
    {
      Header: "Date Modified",
      accessor: "dateModified",
      Cell: ({ value }) => (
        <div style={{ textAlign: "left" }}>
          {moment(value).format("DD-MM-YYYY HH:mm a")}
        </div>
      ),

      headerClassName: "table-header",
      className: "table-cell",
      style: { "white-space": "unset", textAlign: "left" },
    },

    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => (
        <div
          style={{
            display: "inline-flex",
            alignItems: "center",

            color: statusColor(value),
            textAlign: "left",
            textTransform: "capitalize",
            border: `1px solid ${statusBorderColor(value)}`,
            padding: "0 7px",
            borderRadius: "5px",
            backgroundColor: statusBackgroundColor(value),
          }}
        >
          <div
            style={{
              display: "grid",
              placeContent: "center",
              marginRight: "5px",
            }}
          >
            <div
              style={{
                width: "7px",
                height: "7px",
                borderRadius: "100%",
                backgroundColor: statusColor(value),
              }}
            />
          </div>
          <div>{value}</div>
        </div>
      ),
      width: 120,
      headerClassName: "table-header",
      className: "table-cell",
      style: { "white-space": "unset" },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ original }) => (
        <Dropdown>
          {original.status !== "INITIATED" && (
            <Dropdown.Toggle
              style={{ cursor: "pointer" }}
              as={"span"}
              variant="secondary"
              id="dropdown-variants-secondary"
            >
              <MdMore color={process.env.REACT_APP_GREY_COLOUR} />
            </Dropdown.Toggle>
          )}

          <Dropdown.Menu variant="secondary" id="dropdown-variants-secondary">
            <Dropdown.Item onClick={() => handleOpenView({ original })}>
              <Row
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  fontSize: "14px",
                }}
              >
                <MdEye
                  fontSize="15px"
                  color={process.env.REACT_APP_GREY_COLOUR}
                />
                <div>view more</div>
              </Row>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
      headerClassName: "table-header",
      className: "table-cell",
      width: 60,
    },
  ];

  return (
    <>
      <ReactTable
        columns={reportColumns}
        data={filteredData}
        dataCount={dataCount}
        pageSizes={getPaginationRows(dataCount)}
        pages={pages}
        manual
        minRows={filteredData.length > 0 ? 20 : 10}
        onFetchData={(state, instance) => {
          getApplication(state.pageSize, state.page + 1);
        }}
        loading={loading}
        noDataText={loading ? "" : "No data available"}
        LoadingComponent={Spinner}
        getPaginationProps={(state, rowInfo, column, instance) => ({
          style: {
            overflow: "visible",
            backgroundColor: "#ffffff",
          },
        })}
        getTheadThProps={() => ({
          style: {
            fontWeight: "bold",
            color: process.env.REACT_APP_BLUE_COLOUR,
          },
        })}
        getTdProps={() => ({
          style: {
            borderRight: "1px solid #E5E4E2",
          },
        })}
        getTrProps={() => ({
          style: {
            borderBottom: "1px solid #E5E4E2",
          },
        })}
      />
      {showModal && (
        <Modal
          size="lg"
          onHide={() => handleOpenView()}
          centered
          show={showModal}
        >
          <Modal.Header closeButton>
            <Modal.Title
              style={{
                fontWeight: 700,
                fontSize: "16px",
                textTransform: "capitalize",
              }}
            >
              {modalData?.original.businessName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: "1.5rem" }}>
            <ModalComponent
              getApplication={getApplication}
              modalData={modalData}
              pageSize={pageSize}
              pageNum={pageNum}
              handleOpenView={handleOpenView}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default ApplicationGrid;
