import qs from "qs";
import React, { useState } from "react";
import {
  Col,
  Form,
  FormControl,
  FormLabel,
  InputGroup,
  Row,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import H1 from "../components/h1";
import Thumbnail2 from "../components/thumbnail-2";
import { PERSONAL_DOC_SUFFIX, THREE_MB } from "../constants";
import { ERROR_STATUS, SUCCESS_STATUS } from "../constants/api";
import CRMService from "../services/api/resources/crm-service";

export const ReopenTicket = (props) => {
  const crmService = new CRMService();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comments, setComments] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [attachedDocument, setAttachedDocument] = useState("");
  const [form, setForm] = useState("");
  const [ticketId, setTicketId] = useState("");
  const [formIsValid, setFormIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [commentsArray, setCommentsArray] = useState([]);
  const [ticketDetails, setTicketDetails] = useState({});
  const history = useHistory();

  const deviceIsMobile =
    qs.parse(window.location.search)["?mobile"] || window.innerWidth <= 576;
  const deviceIsPC =
    qs.parse(window.location.search)["?mobile"] && window.innerWidth >= 992;
  const deviceIsTab =
    qs.parse(window.location.search)["?mobile"] || window.innerWidth <= 992;

  React.useEffect(() => {
    getTicketDetails();
  }, []);
  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const notify = (params) => {
    if (params === "success") {
      toast.success("Ticket reactivated successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (params === "error") {
      toast.error("Oops! An error occurred, try again.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (params === "uploading-error") {
      toast.error("File size must not exceed 3MB.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
  };

  const onFileSelect = (event, filename) => {
    const name = event.target.files[0].name;
    const file = event.target.files[0];

    if (file.size > THREE_MB) {
      setError(true);
      notify("uploading-error");
      return;
    } else {
      setAttachedDocument(file);
      setError(false);
      displayUploadedFile(name, filename, file);
      return;
    }
  };

  const onFileUnselect = () => {
    setAttachedDocument("");
    updateFormField({ attachedDocument: {} });
  };

  const handleAddComment = async (e) => {
    e.preventDefault();
    const { comments, attachedDocument } = form;
    setIsLoading(true);

    const payload = {
      description: comments,
      attachment: attachedDocument,
    };

    try {
      const { status, code, data } = await crmService.reOpenComplaint(
        ticketId,
        payload
      );

      if (status === SUCCESS_STATUS) {
        await getComments();
        handleClose();
        notify("success");
        setAttachedDocument("");
        setForm({ attachedDocument: "" });
        setIsLoading(false);
        props.navigateTo("/ticket");
        window.scrollTo(0, 0);
      }

      if (status === ERROR_STATUS) {
        notify("error");
        setIsLoading(false);
        return;
      }
    } catch (error) {
      setIsLoading(false);
      notify("error");
    }
  };

  const handleClose = () => {
    setIsModalOpen(false);
    history.goBack();
  };

  const getDocumentForThumbnail = (attachedDocument) => {
    return attachedDocument;
  };

  const displayUploadedFile = (name, filename, file) => {
    const newAttachedDocument = {
      name: `${name}${PERSONAL_DOC_SUFFIX}`,
      filename: `${filename}${PERSONAL_DOC_SUFFIX}`,
      file: file,
      url: URL.createObjectURL(file),
    };

    setAttachedDocument(newAttachedDocument);
    setError(false);
    setIsLoading(false);
    updateFormField({ attachedDocument: newAttachedDocument });
  };

  const updateFormField = (params) => {
    const newForm = {
      ...form,
      ...params,
    };

    setForm(newForm);
    setFormIsValid(false);
  };

  const getComments = async () => {
    try {
      const res = await crmService.getTicketNumber(ticketId);
      const { data } = res.response;
      setCommentsArray(data?.comments);
    } catch (err) {
      return false;
    }
  };

  async function getTicketDetails() {
    setLoading(true);
    const ticket = localStorage.getItem("ticketDetailsItem");
    const isssueTicket = JSON.parse(ticket);
    setTicketId(isssueTicket?.ticketNumber);

    try {
      const res = await crmService.getTicketNumber(isssueTicket.ticketNumber);
      const { data } = res.response;
      setTicketDetails(data);
      setCommentsArray(data.comments);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          background: "#ffffff",
          justifyContent: "center",
          padding: "16px",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <H1>Reopen Ticket</H1>
          <button
            style={{ border: "none" }}
            onClick={() => setIsModalOpen(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="52"
              height="52"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M20 12L12 20"
                stroke="#353F50"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M20 20L12 12"
                stroke="#353F50"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>

        <Form>
          <div style={{ backgroundColor: "white" }}>
            <Row
              style={{
                justifyContent: "center",
                paddingBottom: "50px",
              }}
            >
              <Col xs={12} md={0} style={{ paddingBottom: "15px" }}>
                <Form.Group style={{ width: "100px" }}>
                  <FormLabel className={`form-label-style`}>
                    Comments:
                    <span className="asterisk-style">*</span>
                  </FormLabel>
                  <InputGroup style={{ height: "10px", width: "300px" }}>
                    <FormControl
                      className="form-control-text-input"
                      as="textArea"
                      value={comments}
                      placeholder="Add comments"
                      maxLength={75}
                      onChange={(event) => {
                        const comments = event.target.value;
                        updateFormField({ comments });
                      }}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>

            <Row style={{ justifyContent: "center" }}>
              <Col xs={12} md={0} style={{ paddingBottom: "15px" }}>
                <span> Add Attachment:</span>
                <div
                  style={{
                    marginBottom: 5,
                    fontSize: 10,
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  Upload file (image or document) PNG, JPEG, JPG, DOC, DOCX, PDF
                  up to 3MB
                </div>
                <Thumbnail2
                  style={{ marginBottom: "5px", width: "100%" }}
                  attachedDocument={getDocumentForThumbnail(
                    form.attachedDocument,
                    "attachment"
                  )}
                  filename={"attachment"}
                  onFileSelect={onFileSelect}
                  onFileUnselect={onFileUnselect}
                  isLoading={isLoading}
                />
              </Col>
            </Row>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
                paddingBottom: 20,
                paddingTop: 15,
              }}
            >
              <button
                disabled={!form?.comments}
                style={{
                  paddingLeft: "50px",
                  paddingRight: "50px",
                  backgroundColor: "#00425F",
                  color: "white",
                  fontSize: "14px",
                  borderRadius: "8px",
                  padding: 10,
                  border: "none",
                }}
                onClick={handleAddComment}
              >
                {isLoading ? "Loading..." : "Reopen Ticket"}
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};
