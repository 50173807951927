// src/components/ImageUploader.js

import React from "react";
import Lightbox from "react-image-lightbox";
import IosRefresh from "react-ionicons/lib/IosRefresh";
import { UploadIcon } from "../../../../../../../icons";

const IMAGE_FORMATS = ["png", "jpg", "jpeg", "bimp"];
const DocumentUploader = ({
    showLightbox,
    state,
    setShowLightbox,
    fileInputRef,
    onChange,
    handleDocument,
    handleCancel,
    fileFormat,
    loading,
    label,
    loadingCancelDoc,
    error,
}) => {
    return (
        <div
            style={{
                color: "black",
            }}
        >
            <label
                style={{
                    fontSize: "14px",
                    fontWeight: 600,
                }}
            >
                {label}
            </label>
            <div
                style={{
                    alignItems: "center",
                    backgroundColor: "#dcdcdc6d",
                    borderRadius: "5px",
                    border: "1px solid   #D3D3D3",
                    cursor: "pointer",
                    display: "flex",

                    flexDirection: "column",
                    justifyContent: "center",
                    height: "auto",
                    marginRight: "10px",
                    //   width: "200px",
                }}
                className="position-relative"
            >
                {loading ? (
                    <IosRefresh
                        style={{ width: "24px" }}
                        fontSize="100px"
                        color="grey"
                        rotate={true}
                    />
                ) : (
                    <>
                        {state ? (
                            <>
                                {fileFormat &&
                                    IMAGE_FORMATS.includes(fileFormat.toLowerCase()) ? (
                                    <img
                                        style={{
                                            height: "100%",
                                            width: "100%",
                                        }}
                                        src={state}
                                        alt="Uploaded"
                                        onClick={() => setShowLightbox(true)}
                                    />
                                ) : (
                                    <embed
                                        style={{
                                            height: "100%",
                                            width: "100%",
                                        }}
                                        onClick={() => setShowLightbox(true)}
                                        name="plugin"
                                        src={state}
                                    />
                                )}
                            </>
                        ) : (
                            <div onClick={handleDocument}>
                                {label === "Upload ID" ? (
                                    <div
                                        style={{
                                            color: "#111827",
                                            padding: "20px",
                                            textAlign: "center",
                                        }}
                                    >
                                        <span style={{ color: "#7F97C3" }}>Upload a file </span> or
                                        drag and drop <br />{" "}
                                        <span style={{ color: "#6B7280" }}>
                                            JPEG, PNG, upto 1MB
                                        </span>
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "1rem",
                                        }}
                                    >
                                        <div>{UploadIcon()}</div>
                                        <p>
                                            <span style={{
                                                width: '340.8px',
                                                gap: '5.775000095367432px',
                                                fontSize: '14px',
                                                color: 'black',
                                                marginTop: '10px',
                                                lineHeight: '20px',
                                                marginLeft: '15px',
                                            }}> Tap here to upload your picture </span><br /><span style={{ fontWeight: '400', fontSize: '12px', lineHeight: '16px', color: '#848F9F' }}>JPG or PNG. File size, no more than 1MB</span></p>

                                    </div>
                                )}
                            </div>
                        )}
                    </>
                )}
                <input
                    ref={fileInputRef}
                    type="file"
                    accept=" .png, .jpg, .jpeg"
                    onChange={onChange}
                    style={{ display: "none" }}
                />
            </div>
            {error && (
                <div style={{ color: "red", marginTop: "5px", fontSize: "12px" }}>
                    Image size has exceeded the 1MB limit. Please reupload the image.
                </div>
            )}
            {showLightbox && (
                <>
                    <Lightbox
                        mainSrc={state}
                        onCloseRequest={() => setShowLightbox(false)}
                    />
                </>
            )}
            {state && !loading && (
                <>
                    {loadingCancelDoc ? (
                        <IosRefresh
                            style={{ width: "24px" }}
                            fontSize="25px"
                            color="grey"
                            rotate={true}
                        />
                    ) : (
                        <span
                            style={{
                                cursor: "pointer",
                                fontSize: "14px",
                            }}
                            className=" text-danger cursor-pointer"
                            onClick={handleCancel}
                        >
                            Remove
                        </span>
                    )}
                </>
            )}
        </div>
    );
};

export default DocumentUploader;
