import Numeral from "numeral";
import qs from "qs";
import React, { useState, useRef, useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import MdAdd from "react-ionicons/lib/MdAdd";
import MdArrowDropdownCircle from "react-ionicons/lib/MdArrowDropdownCircle";
import { copybox } from "../scenes/fund-wallet/fund-wallet";
import { ArrowDownIcon } from "../../../../../icons";
import Platform from "../../../../../services/api/resources/platform";
import Transaction from "../../../../../services/api/resources/platform";
import { convertNgkToNgn } from "./../../../../../utils/converters/currencies";
const UNLOAD_COMMISSION = "unload-commission";
const COLOUR = "white";

export default (props) => {
  const platform = new Platform();
  const transaction = new Transaction();
  const deviceIsTab =
    qs.parse(window.location.search)["?mobile"] || window.innerWidth <= 992;
  const currentAgent = JSON.parse(localStorage.getItem("currentAgent")) || {};
  const [show, setShow] = useState(false);
  const [showAccount, setShowAccount] = useState(false);
  const [showWalletFundingModal, setShowWalletFundingModal] = useState(false);
  const accountRef = useRef(null);
  const viewAccountRef = useRef(null);
  const [staticAccounts, setStaticAccounts] = useState([]);

  const handleCopyAccountNumber = (agentId) => {
    const filter = currentAgent.staticAccounts.filter(
      (data) => data.agentId === agentId
    );
    setShow(true);
    navigator.clipboard.writeText(filter?.[0]?.accountNumber);
    setTimeout(() => {
      setShow(false);
    }, 1500);
  };

  useEffect(() => {
    const getStaticAccount = async () => {
      try {
        const { response } = await platform.getStaticAccount();
        if (response.code === "00") {
          setStaticAccounts(response.data.availableStaticAccounts);
        }
      } catch (error) {
        console.error("Error fetching static account:", error);
      }
    };
    getStaticAccount();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (accountRef.current && !accountRef.current.contains(event.target)) {
        setShowAccount(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const qtFundDepositLimit = localStorage.getItem("qtFundDepositLimit");

  const handleFundWalletClick = () => {
    setShowWalletFundingModal(true);
    if (props.onFundWalletClick) {
      props.onFundWalletClick();
    }
  };

  const handleCloseWalletFundingModal = () => {
    setShowWalletFundingModal(false);
  };

  const Account = ({ staticAccounts }) => {
    const viewAccountRect = viewAccountRef.current?.getBoundingClientRect();

    return (
      <div
        ref={accountRef}
        style={{
          background: "#ffffff",
          borderRadius: "4px",
          padding: "24px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          width: "150px",
          position: "absolute",
          top: viewAccountRect ? viewAccountRect.bottom + 10 : "15%",
          left: viewAccountRect ? viewAccountRect.left : "70px",
          boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
          zIndex: 10,
        }}
      >
        {staticAccounts?.slice(0, 2).map((account, index) => (
          <div
            key={index}
            style={{
              borderBottom: "1px solid #f3f4f6",
              paddingBottom: "10px",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              width: "100%",
            }}
          >
            <div
              style={{
                color: "#00b8de",
                fontFamily: "'Averta-Regular', sans-serif",
                fontSize: "14px",
                width: "100%",
                whiteSpace: "wrap",
              }}
            >
              {account.bankName}
            </div>
            <div
              style={{
                color: "#353f50",
                fontFamily: "'Averta-Semibold', sans-serif",
                fontSize: "16px",
                fontWeight: "600",
                width: "100%",
                letterSpacing: "0.5px",
              }}
            >
              {account.accountNumber}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <Row
        style={{
          color: COLOUR,
          marginBottom: props.deviceIsMobile ? "10px" : "0px",
          width: props.deviceIsMobile ? "90vw" : "78vw",
        }}
      >
        <Col
          style={{
            display: "flex",
            alignItems: "center",
            flex: "nowrap",
            justifyContent: "flex-start",
            overflowX: "auto",
            overflowY: "hidden",
            padding: "15px",
            paddingBottom: props.deviceIsMobile ? "5px" : "5px",
            borderRadius: "4px",
          }}
        >
          {/* LEDGER BALANCE */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "200px",
              height: "110px",
              marginRight: "30pt",
            }}
          >
            <div
              style={{
                fontSize: props.deviceIsMobile
                  ? "13px"
                  : process.env.REACT_APP_MID_FONT_SIZE,
                lineHeight: process.env.REACT_APP_MID_LINE_HEIGHT,
                marginTop: "5px",
              }}
            >
              LEDGER BALANCE
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: props.deviceIsMobile
                  ? "22px"
                  : process.env.REACT_APP_BIG_FONT_SIZE,
                padding: 0,
                marginRight: "0px",
                marginLeft: "0px",
              }}
            >
              ₦{Numeral(props.wallet.ledger_balance).format("0,0.00")}
            </div>
            <div
              className={`grow ${props.suggestFundWallet ? "heart-beat" : ""}`}
              style={{
                alignItems: "center",
                backgroundColor: props.isAgentNew
                  ? process.env.REACT_APP_YELLOW_COLOUR
                  : "white",
                border: `1pt solid ${process.env.REACT_APP_BLUE_COLOUR}`,
                borderRadius: "4px",
                color: props.disabled
                  ? process.env.REACT_APP_LIGHT_GREY_COLOUR
                  : process.env.REACT_APP_BLUE_COLOUR,
                cursor: props.disabled ? "not-allowed" : "pointer",
                display: "flex",
                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                fontStyle: process.env.REACT_APP_MID_FONT_SIZE,
                fontWeight: "bold",
                height: "40px",
                padding: "7.5px 10px 7.5px 10px",
                width: "150px",
              }}
              aria-controls="fund-wallet-tab"
              onClick={handleFundWalletClick}
            >
              <MdAdd
                color={
                  props.disabled
                    ? process.env.REACT_APP_LIGHT_GREY_COLOUR
                    : process.env.REACT_APP_BLUE_COLOUR
                }
                fontSize={process.env.REACT_APP_MID_FONT_SIZE}
                style={{ marginRight: "5px" }}
              />
              FUND WALLET
            </div>
          </div>

          {/* AVAILABLE AMOUNT */}
          <div
            hidden={props.deviceIsMobile}
            style={{
              backgroundColor: "#46768B",
              right: "30px",
              position: "relative",
              height: "90px",
              width: "1pt",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "200px",
              height: "110px",
              marginRight: "30pt",
            }}
          >
            <div
              style={{
                fontSize: props.deviceIsMobile
                  ? "13px"
                  : process.env.REACT_APP_MID_FONT_SIZE,
                lineHeight: process.env.REACT_APP_MID_LINE_HEIGHT,
                marginTop: "5px",
              }}
            >
              AVAILABLE AMOUNT
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: props.deviceIsMobile
                  ? "22px"
                  : process.env.REACT_APP_BIG_FONT_SIZE,
                padding: 0,
                marginRight: "0px",
                marginLeft: "0px",
                width: props.deviceIsMobile && "150px",
              }}
            >
              ₦{Numeral(props.wallet.current_balance).format("0,0.00")}
            </div>
          </div>

          {/* COMMISSIONS EARNED */}
          <div
            hidden={props.deviceIsMobile}
            style={{
              backgroundColor: "#46768B",
              right: "30px",
              position: "relative",
              height: "90px",
              width: "1px",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "200px",
              height: "110px",
              marginRight: "30pt",
            }}
          >
            <div
              style={{
                cursor: !props.disabled ? "pointer" : "not-allowed",
                fontSize: props.deviceIsMobile
                  ? "13px"
                  : process.env.REACT_APP_MID_FONT_SIZE,
                lineHeight: process.env.REACT_APP_MID_LINE_HEIGHT,
                marginTop: "5px",
                textDecoration: "underline",
              }}
              onClick={props.onSettlementHistoryClick}
            >
              COMMISSIONS EARNED
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: props.deviceIsMobile
                  ? "22px"
                  : process.env.REACT_APP_BIG_FONT_SIZE,
                padding: 0,
                marginRight: "0px",
                marginLeft: "0px",
                width: props.deviceIsMobile && "150px",
              }}
            >
              ₦{Numeral(props.wallet.commissions_earned).format("0,0.00")}
            </div>
            <div
              className={`grow`}
              style={{
                alignItems: "center",
                backgroundColor: "white",
                borderRadius: "4px",
                border: `1pt solid ${process.env.REACT_APP_BLUE_COLOUR}`,
                color: props.disabled
                  ? process.env.REACT_APP_LIGHT_GREY_COLOUR
                  : process.env.REACT_APP_BLUE_COLOUR,
                cursor: props.disabled ? "not-allowed" : "pointer",
                display: "flex",
                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                fontStyle: process.env.REACT_APP_MID_FONT_SIZE,
                fontWeight: "bold",
                height: "40px",
                padding: "7.5px 10px 7.5px 10px",
                width: "100px",
              }}
              aria-controls="unload-commission-tab"
              aria-expanded={props.activeTab === UNLOAD_COMMISSION}
              onClick={props.onUnloadCommissionClick}
            >
              <MdArrowDropdownCircle
                color={
                  props.disabled
                    ? process.env.REACT_APP_LIGHT_GREY_COLOUR
                    : process.env.REACT_APP_BLUE_COLOUR
                }
                fontSize={process.env.REACT_APP_MID_FONT_SIZE}
                style={{ marginRight: "5px" }}
              />
              UNLOAD
            </div>
          </div>

          {/* UNSETTLED COMMISSION */}
          <div
            hidden={props.deviceIsMobile}
            style={{
              backgroundColor: "#46768B",
              right: "30px",
              position: "relative",
              height: "90px",
              width: "1pt",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "200px",
              height: "110px",
              marginRight: "30pt",
            }}
          >
            <div
              style={{
                fontSize: props.deviceIsMobile
                  ? "13px"
                  : process.env.REACT_APP_MID_FONT_SIZE,
                lineHeight: process.env.REACT_APP_MID_LINE_HEIGHT,
                marginTop: "5px",
              }}
            >
              UNSETTLED COMMISSION
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: props.deviceIsMobile
                  ? "22px"
                  : process.env.REACT_APP_BIG_FONT_SIZE,
                padding: 0,
                marginRight: "0px",
                marginLeft: "0px",
                width: props.deviceIsMobile && "150px",
              }}
            >
              ₦{Numeral(props.wallet.unsettled_commission).format("0,0.00")}
            </div>
          </div>

          {/* Conditionally render ACCOUNT NUMBER and VIEW ACCOUNT */}
          {staticAccounts.length > 0 && (
            <>
              <div
                hidden={props.deviceIsMobile}
                style={{
                  backgroundColor: "#46768B",
                  right: "30px",
                  position: "relative",
                  height: "90px",
                  width: "1pt",
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "200px",
                  height: "110px",
                  marginRight: "30pt",
                }}
              >
                <div
                  style={{
                    fontSize: props.deviceIsMobile
                      ? "13px"
                      : process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: process.env.REACT_APP_MID_LINE_HEIGHT,
                    marginTop: "5px",
                  }}
                >
                  ACCOUNT NUMBER
                </div>
                {process.env.REACT_APP_SHOW_STATIC_ACCOUNT_V2 === "true" ? (
                  <>
                    <div
                      ref={viewAccountRef}
                      className={`grow ${
                        props.suggestFundWallet ? "heart-beat" : ""
                      }`}
                      style={{
                        alignItems: "center",
                        backgroundColor: props.isAgentNew
                          ? process.env.REACT_APP_YELLOW_COLOUR
                          : "white",
                        border: `1pt solid ${process.env.REACT_APP_BLUE_COLOUR}`,
                        borderRadius: "4px",
                        color: props.disabled
                          ? process.env.REACT_APP_LIGHT_GREY_COLOUR
                          : process.env.REACT_APP_BLUE_COLOUR,
                        cursor: props.disabled ? "not-allowed" : "pointer",
                        display: "flex",
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        fontStyle: process.env.REACT_APP_MID_FONT_SIZE,
                        fontWeight: "bold",
                        height: "40px",
                        padding: "7.5px 10px 7.5px 10px",
                        width: "150px",
                      }}
                      aria-controls="fund-wallet-tab"
                      onClick={() => setShowAccount(!showAccount)}
                    >
                      VIEW ACCOUNT
                      <ArrowDownIcon
                        color={
                          props.disabled
                            ? process.env.REACT_APP_LIGHT_GREY_COLOUR
                            : process.env.REACT_APP_BLUE_COLOUR
                        }
                        fontSize={process.env.REACT_APP_MID_FONT_SIZE}
                        style={{ marginRight: "5px" }}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <article className="fundwallet-copy-container-dashboard">
                        <div
                          style={{
                            display: "flex",
                            fontSize: deviceIsTab ? "10px" : "13px",
                          }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span style={{ color: "#00B8DE" }}>
                              {currentAgent?.staticAccounts?.[0]?.bankName}
                            </span>
                            <span
                              title="Click to copy account number"
                              onClick={() =>
                                handleCopyAccountNumber(
                                  currentAgent?.staticAccounts?.[0]?.agentId
                                )
                              }
                              style={{
                                fontSize: deviceIsTab ? "12px" : "14px",
                                cursor: "pointer",
                              }}
                            >
                              {currentAgent?.staticAccounts?.[0]?.accountNumber}
                            </span>
                          </div>
                        </div>
                      </article>

                      {show && <div style={copybox}>Copied!</div>}
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </Col>
      </Row>

      {process.env.REACT_APP_SHOW_TRANSACTION_LIMIT === "true" &&
        showWalletFundingModal && (
          <Modal
            show={showWalletFundingModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{
              backdropFilter: "blur(10px)",
              backgroundColor: "rgba(0, 0, 0, 0.1)",
            }}
          >
            <Modal.Body style={{ padding: "1rem", alignSelf: "center" }}>
              <div
                style={{
                  padding: "3rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h1
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    marginBottom: "1rem",
                    fontSize: "1.5rem",
                  }}
                >
                  Wallet Funding Limit
                </h1>
                <p
                  style={{
                    color: "grey",
                    fontWeight: "400",
                    textAlign: "center",
                  }}
                >
                  The minimum amount you can fund your wallet is ₦
                  {Numeral(100).format("0,0.00")}
                </p>
                <p
                  style={{
                    color: "grey",
                    fontWeight: "400",
                    textAlign: "center",
                  }}
                >
                  The maximum amount you can fund your wallet is ₦
                  {Numeral(convertNgkToNgn(qtFundDepositLimit)).format(
                    "0,0.00"
                  )}
                </p>

                <button
                  className="signupButton3"
                  style={{
                    marginTop: "1rem",
                    textAlign: "center",
                    color: "black",
                    alignContent: "center",
                  }}
                  onClick={handleCloseWalletFundingModal}
                >
                  Close
                </button>
              </div>
            </Modal.Body>
          </Modal>
        )}

      {showAccount && <Account staticAccounts={staticAccounts} />}
    </>
  );
};
