import moment from "moment";
import React from "react";
import { Carousel, Col, Container, Modal, Row } from "react-bootstrap";
import Confetti from "react-confetti";
import MdSync from "react-ionicons/lib/MdSync";
import ActivityIndicator from "../../../../components/activity-indicator";
import Button from "../../../../components/button";
import H1 from "../../../../components/h1";
import HR from "../../../../components/hr";
import Tabs from "../../../../components/tabs";
import Text from "../../../../components/text";
import VerticallyCenteredModal from "../../../../components/vertically-centered-modal";
import { END_LOAD_USER_DATA } from "../../../../constants/action-types/user";
import {
  ERROR_STATUS,
  HTTP_NOT_FOUND_CODE,
  SUCCESS_STATUS,
} from "../../../../constants/api";
import {
  NOT_VERIFIED,
  SUSPENDED,
  VERIFICATION_FAILED,
  VERIFIED_PARTIALLY,
} from "../../../../constants/bvnVerificationStatus";
import { successfulTransactions } from "../../../../constants/transactions";
import AgentSerializer from "../../../../serializers/resources/agent";
import ApplicationSerializer from "../../../../serializers/resources/application";
import UserSerializer from "../../../../serializers/resources/user";
import WalletSerializer from "../../../../serializers/resources/wallet";
import Onboarding from "../../../../services/api/resources/onboarding";
import Platform from "../../../../services/api/resources/platform";
import Transaction from "../../../../services/api/resources/transaction";
import TransactionHistory from "../../../../services/api/resources/transaction-history";
import UserManagement from "../../../../services/api/resources/user-management";
import {
  listenToNotification,
  setupMessaging,
} from "../../../../setup/firebase";
import {
  calculatePercentage,
  refineGraphData,
  specialDateRangeFormatter_EndDate,
  specialDateRangeFormatter_StartDate,
} from "../../../../utils/formatters";
import { getClientDetails } from "../../../../utils/index";
import MockTransaction from "../../../../utils/mocks/transaction";
import Wallet from "../../../../utils/mocks/wallet";
import BaseScene from "../../../base-scene";
import BVNDetailsForm from "../manage-profile/tabs/bvn-details-form";
import DecorativeDashboardHeader from "./components/decorative-dashboard-header";
import UpdateButton from "./components/update-button";
import CustomCountdownCircleTimer from "./countDownTimer";
import ApplicationReviewMessage from "./fragments/application-review-message";
import ApplicationStatusIndicators from "./fragments/application-status-indicators";
import FallBackPage from "./fragments/fall-back-message";
import LandingMessage from "./fragments/landing-message";
import ServiceCardRow from "./fragments/service-card-row";
import ServicesLabelRow from "./fragments/services-label-row";
import ServicesReportGraph from "./fragments/services-report-graph";
import SetupInProgressMessage from "./fragments/setup-in-progress-message";
import UpgradeMessage from "./fragments/upgrade-message";
import WalletBalances from "./fragments/wallet-balances";
import CommissionsEarnedScene from "./scenes/commissions-earned/scene";
import FundWalletScene from "./scenes/fund-wallet/fund-wallet";
import UnloadCommissionScene from "./scenes/unload-commission/index";
import "./styles.scss";
import RecentTransactionsTable from "./tables/recent-transactions";
import WalletJournalTable from "./tables/wallet-journal";

import BVNValidationForm from "../../../signup/forms/BVNValidationForm";
import SetupAgentNew from "./fragments/onboarding-application-setup/setup-new-agent-new/setupAgent";
import SetupAgent from "./fragments/onboarding-application-setup/setup-new-agent/setupAgent";
import { HandIcon, HandRejectIcon } from "../../../../icons";

const COMMISSIONS_EARNED = "commissions-earned";
const CONFETTI_ANIM_DURATION = 6000;
const DASHBOARD = "dashboard";
const UNLOAD_COMMISSION = "unload-commission";
const FUND_WALLET = "fund-wallet";
const SETTLEMENT_HISTORY = "settlement-history";
// const SETTLEMENT_HISTORY = 'settlement-history';

const currentDate = new Date();

const firstDayCurrentMonth = new Date(
  currentDate.getFullYear(),
  currentDate.getMonth(),
  1
);
const lastDayCurrentMonth = new Date(
  currentDate.getFullYear(),
  currentDate.getMonth() + 1,
  0
);
const { isIOS, isMac } = getClientDetails();

export default class DashboardScene extends BaseScene {
  permissions = [];

  location = window.location.href;
  path = "/dashboard";
  title = "Dashboard";

  transaction = new MockTransaction({
    wallet: new Wallet(),
  });

  state = {
    activeAddBillingInformationScene: "landing",
    activeTab: DASHBOARD,
    didErrorOccurWhileFetchingRecentTransactions: null,
    didErrorOccurWhileFetchingReportsGraph: null,
    formIsValid: true,
    showAddBillingInformation: false,
    showApplicationReviewMessage: false,
    showUpgradeMessage: false,
    showApplicationStatusModal: false,
    showCommissionsEarned: false,
    showContinueApplicationModal: false,
    showIncompleteApplicationMessage: false,
    showSetupInProgressMessage: false,
    showLandingMessage: null,
    shouldShowAddBillingInformation: null,
    shouldShowContinueApplicationModal: null,
    shouldShowApplicationStatusModal: null,
    suggestFundWallet: false,
    currentApplication: {},
    currentAgent: {},
    currentUser: {
      firstName: "",
      lastName: "",
    },
    user: {
      firstName: "",
      lastName: "",
    },
    tableData: null,
    toShowConfetti: false,
    transactionHistory: [],
    firstDayCurrentMonth,
    lastDayCurrentMonth,
    wallet: {},
    isLoading: false,
    showModal: false,
    graphData: null,
    isResendEmailLoading: false,
    listenerValue: false,
    miniStatementType: 1,
    journalTransactionHistory: [],
    posRequestReport: [],
    agentDetails: [],
    active: true,
    showConfirmationModal: false,
    showThankyouMessage: false,
    showSuspendedAgentModal: false,
    showRedirectToBVNValidationOnboardingForm: false,
    bvnFormInModalMode: false,
    showInstantBVNValidationMessageModal: false,
    instantBVNValidationMessage: "",
    showPartiallyVerifiedAgentModal: false,
    showNotVerifiedAgentModal: false,
    showApprovedModal: false,
    showRejectedModal: false,
    navigateToCommission: false,
    // isBVNValidationSuccesful:false,
  };

  constructor(props) {
    super(props);

    this.onboarding = new Onboarding();
    this.transactionHistory = new TransactionHistory();
    this.userManagement = new UserManagement();
    this.platform = new Platform();

    this.doFundWallet = this.doFundWallet.bind(this);
    this.getWallet = this.getWallet.bind(this);
    this.loadDashboard = this.loadDashboard.bind(this);
    this.updateDashboard = this.updateDashboard.bind(this);
    this.toShowAwaitingApproval = this.toShowAwaitingApproval.bind(this);
    this.toShowAwaitingValidation = this.toShowAwaitingValidation.bind(this);
    this.toShowCompleteApplication = this.toShowCompleteApplication.bind(this);
    this.toShowSetupInProgress = this.toShowSetupInProgress.bind(this);
    this.toShowInactive = this.toShowInactive.bind(this);
    this.toShowSuspended = this.toShowSuspended.bind(this);
    this.getLastTransactions = this.getLastTransactions.bind(this);
    this.getMonthlyGraphTransactionsData =
      this.getMonthlyGraphTransactionsData.bind(this);
    this.resendEmail = this.resendEmail.bind(this);
    this.getAgentDetails = this.getAgentDetails.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.notificationListener = this.notificationListener.bind(this);
    this.handleMiniStatementToggle = this.handleMiniStatementToggle.bind(this);

    this.handleYesConfirmationModal =
      this.handleYesConfirmationModal.bind(this);
    this.handleNoConfirmationModal = this.handleNoConfirmationModal.bind(this);

    this.getGracePeriodMessage = this.getGracePeriodMessage.bind(this);
    this.getGracePeriodRemaining = this.getGracePeriodRemaining.bind(this);
    this.setShowThankyouMessage = this.setShowThankyouMessage.bind(this);
    this.setShowSuspendedAgentModal =
      this.setShowSuspendedAgentModal.bind(this);
    this.showInstantBVNValidationMessage =
      this.showInstantBVNValidationMessage.bind(this);
    this.setShowPartiallyVerifiedAgentModal =
      this.setShowPartiallyVerifiedAgentModal.bind(this);
    this.getPosRequestReport = this.getPosRequestReport.bind(this);

    //this.setShowRedirectToBVNValidationOnboardingForm = this.setShowRedirectToBVNValidationOnboardingForm.bind(this);
    //this.isBVNValidationSuccesful = this.isBVNValidationSuccesful.bind(this);
  }

  componentDidMount() {
    const navigationFromCommission = localStorage.getItem(
      "navigationFromCommission"
    );
    this.getAgentDetails();
    if (window.location.search) {
      const queryStringObject = new URLSearchParams(window.location.search);
      const fundPage = JSON.parse(queryStringObject.get("fund-page"));
      if (fundPage) {
        this.setState({
          activeTab: FUND_WALLET,
        });
      }
    }
    this.getPosRequestReport();

    const currentUrl = window.location.href.split("?");
    const urlObj = { Title: "dashboard", Url: currentUrl[0] };
    window.history.replaceState(urlObj, urlObj.Title, urlObj.Url);

    this.setState({
      currentApplication: this.state.currentApplication,
    });

    this.loadDashboard();
    this.setState({
      showConfirmationModal: true,
      showPartiallyVerifiedAgentModal: true,
      showNotVerifiedAgentModal: true,
      showApprovedModal: true,
      showRejectedModal: true,
      navigateToCommission: navigationFromCommission && true,
      activeTab: navigationFromCommission ? SETTLEMENT_HISTORY : DASHBOARD,
    });
    localStorage.removeItem("navigationFromCommission");
  }

  async getPosRequestReport() {
    const { status, response, code } =
      await this.userManagement.getActivePosRequests();
    const responseData = [];
    responseData.push(response.data);

    if (status === SUCCESS_STATUS) {
      this.setState({
        posRequestReport: responseData,
      });
    } else {
      this.setState({
        posRequestReport: [],
      });
    }
  }

  setupMessagingService() {
    if (!isIOS && !isMac) {
      setupMessaging(this.state.userFromBaseScene.username);
    }
  }

  async notificationListener() {
    if (!isIOS && !isMac) {
      const listenerValue = await listenToNotification();
      this.setState({
        listenerValue: listenerValue,
      });
    }
  }

  handleModalOpen() {
    this.setState({
      showModal: true,
    });
  }

  handleModalClose() {
    this.setState({
      showModal: false,
    });
  }

  changeAddBillingPage(newPage) {
    if (!newPage) {
      this.setState({
        showAddBillingInformation: !this.state.showAddBillingInformation,
      });
      return;
    }

    this.setState({
      activeAddBillingInformationScene: newPage,
    });
  }

  getCurrentCarouselIndex(slides) {
    return slides.indexOf(this.state.activeAddBillingInformationScene);
  }

  async getMonthlyGraphTransactionsData(startDate, endDate) {
    const domainCode =
      this.state.userFromBaseScene &&
      this.state.userFromBaseScene.domainTypeId === 3
        ? this.state.userFromBaseScene.domainCode
        : null;
    const graphStartDate = specialDateRangeFormatter_StartDate(startDate);
    const graphEndDate = specialDateRangeFormatter_EndDate(endDate);

    this.setState({
      didErrorOccurWhileFetchingReportsGraph: null,
      isLoadingReportsGraph: true,
    });

    const { response, status, code } =
      await this.transactionHistory.getGraphTransactions(
        graphStartDate,
        graphEndDate,
        null,
        successfulTransactions,
        domainCode
      );

    if (status === ERROR_STATUS) {
      if (code === HTTP_NOT_FOUND_CODE) {
        this.setState({
          isLoadingReportsGraph: false,
          graphData: null,
        });
      } else {
        this.setState({
          didErrorOccurWhileFetchingReportsGraph: true,
          isLoadingReportsGraph: false,
        });
      }
    } else {
      this.setState({
        graphData: response,
        isLoadingReportsGraph: false,
      });
    }
  }

  async getLastTransactions() {
    const domainCode =
      this.state.userFromBaseScene &&
      this.state.userFromBaseScene.domainTypeId === 3
        ? this.state.userFromBaseScene.domainCode
        : null;
    this.setState({
      miniStatementIsLoading: true,
      didErrorOccurWhileFetchingRecentTransactions: null,
    });
    const { status, response, code } =
      await this.transactionHistory.getTransactions(domainCode);

    if (status === SUCCESS_STATUS) {
      const transactionResponse = response.content;
      const filteredResponse = transactionResponse.filter((response) => {
        return response.statusCode !== "INITIATED";
      });
      this.setState({
        transactionHistory: filteredResponse,
        miniStatementIsLoading: false,
      });
    } else {
      if (code === HTTP_NOT_FOUND_CODE) {
        this.setState({
          transactionHistory: [],
          miniStatementIsLoading: false,
        });
      } else {
        this.setState({
          didErrorOccurWhileFetchingRecentTransactions: true,
          miniStatementIsLoading: false,
        });
      }
    }
  }

  async getLastTransactionsJournal() {
    const domainCode =
      this.state.userFromBaseScene &&
      this.state.userFromBaseScene.domainTypeId === 3
        ? this.state.userFromBaseScene.domainCode
        : null;
    this.setState({
      journalMiniStatementIsLoading: true,
      didErrorOccurWhileFetchingRecentJournalTransactions: null,
    });

    const { status, response, code } =
      await this.transactionHistory.getWalletJournal(
        null,
        null,
        1,
        true,
        null,
        null,
        domainCode
      );

    if (status === SUCCESS_STATUS) {
      const transactionResponse = response.content;
      const sortedResponse = transactionResponse.sort(
        (a, b) => new Date(a.journalEntryDate) - new Date(b.journalEntryDate)
      );

      this.setState({
        journalTransactionHistory: sortedResponse,
        journalMiniStatementIsLoading: false,
      });
    } else {
      if (code === HTTP_NOT_FOUND_CODE) {
        this.setState({
          journalTransactionHistory: [],
          journalMiniStatementIsLoading: false,
        });
      } else {
        this.setState({
          didErrorOccurWhileFetchingRecentJournalTransactions: true,
          journalMiniStatementIsLoading: false,
        });
      }
    }
  }

  setWalletState = (wallet) => {
    this.setState({ wallet: wallet });
    if (wallet.current_balance <= 500 && this.state.disabled === false) {
      this.setState({
        suggestFundWallet: true,
      });
    }
  };

  async getWallet() {
    this.props.updateLoading(true, 75);

    const transaction = new Transaction();

    let transactionWalletBalance = await transaction.getWalletBalance();

    if (transactionWalletBalance.status === ERROR_STATUS) {
      const cachedWallet = this.state.wallet;

      if (cachedWallet) {
        this.setWalletState(cachedWallet);
      }
    } else {
      const transactionWalletSerializer = new WalletSerializer({
        ...transactionWalletBalance.response,
        domainName: this.state.user.domainName,
      });

      const transactionWallet = transactionWalletSerializer.asJson();

      localStorage.setItem(
        "transactionWallet",
        JSON.stringify(transactionWallet)
      );

      this.setWalletState(transactionWallet);
    }

    this.props.updateLoading(false);
  }

  async loadDashboard() {
    const currentAgent = JSON.parse(
      localStorage.getItem("currentAgent") || "{}"
    );
    const currentApplication = JSON.parse(
      localStorage.getItem("currentApplication") || "{}"
    );
    const currentUser = JSON.parse(localStorage.getItem("currentUser") || "{}");
    const transactionWallet = JSON.parse(
      localStorage.getItem("transactionWallet") || "{}"
    );
    const agentSerializer = new AgentSerializer(currentAgent);
    const applicationSerializer = new ApplicationSerializer(currentApplication);
    const userSerializer = new UserSerializer(currentUser);
    const currentWallet =
      currentUser.domainName === transactionWallet.domain_name &&
      transactionWallet;
    const newState = {
      currentAgent: agentSerializer,
      currentApplication: applicationSerializer,
      user: currentUser,
      wallet: currentWallet,
      currentUser: userSerializer,
      disabled: userSerializer.isDomainDisabled || agentSerializer.isDisabled,
    };

    if (applicationSerializer.isDraftApplication) {
      newState.showIncompleteApplicationMessage = true;
    }

    if (
      applicationSerializer.isAwaitingApproval ||
      applicationSerializer.isAwaitingValidation
    ) {
      newState.showApplicationReviewMessage = true;
    }

    if (applicationSerializer.isRejected) {
      newState.rejectedApplication = true;
    }

    if (agentSerializer.agentClass === "BASIC") {
      this.setState({ showUpgradeMessage: true });
    }
    if (userSerializer.isDomainNew) {
      this.setupMessagingService();
      this.notificationListener();
      if (this.isSetupComplete()) {
        newState.showLandingMessage = true;
        newState.suggestFundWallet = true;
      } else {
        newState.showSetupInProgressMessage = true;
      }
    }

    this.setState(newState);

    if (
      userSerializer.isAgent ||
      userSerializer.isAggregator ||
      userSerializer.isApplicant
      //  &&
      // !userSerializer.isDomainNew
    ) {
      this.getWallet();
      this.getLastTransactions();
      this.getLastTransactionsJournal();
      this.getMonthlyGraphTransactionsData(
        this.state.firstDayCurrentMonth,
        this.state.lastDayCurrentMonth
      );
      this.setupMessagingService();
      this.notificationListener();
    }
  }

  async isSetupComplete() {
    const { response } = await this.platform.getWalletStatus();
    return response === "WALLET_CREATED";
  }

  doFundWallet() {
    // this.setState({activeTab: FUND_WALLET})
    this.state.activeTab === FUND_WALLET
      ? this.setState({ activeTab: DASHBOARD })
      : this.setState({ activeTab: FUND_WALLET });
  }

  showCommissionsEarned() {
    this.setState({
      showCommissionsEarned: true,
    });
  }

  toShowAwaitingApproval() {
    return this.state.currentApplication.isAwaitingApproval;
  }

  toShowAwaitingValidation() {
    return this.state.currentApplication.isAwaitingValidation;
  }

  toShowCompleteApplication() {
    return this.state.currentApplication.isDraftApplication;
  }

  toShowInactive() {
    return this.state.currentAgent && this.state.currentAgent.isInactive;
  }

  toShowSetupInProgress() {
    return (
      this.state.currentAgent &&
      this.state.currentAgent.isSetupComplete === false
    );
  }

  toShowSuspended() {
    return this.state.currentAgent && this.state.currentAgent.isSuspended;
  }

  get toThrowConfetti() {
    return (
      this.state.currentAgent &&
      this.state.currentAgent.isNew &&
      !localStorage.getItem("hasShownConfetti")
    );
  }

  throwConfetti() {
    this.setState({
      toShowConfetti: true,
    });

    setTimeout(
      () =>
        this.setState({
          toShowConfetti: false,
        }),
      CONFETTI_ANIM_DURATION
    );

    if (!localStorage.getItem("hasShownConfetti")) {
      localStorage.setItem("hasShownConfetti", JSON.stringify(true));
    }
  }

  updateDashboard() {
    this.props.startLoadUserData();
  }

  computeDashboardHeaderHeight() {
    if (!this.state.deviceIsPC) {
      return this.state.activeTab === "dashboard" ? "320px" : "270px";
    }

    return this.state.activeTab === "dashboard" ? "345px" : "230px";
  }

  get toShowTransactionsTable() {
    return (
      !this.state.showLandingMessage &&
      !this.state.showApplicationReviewMessage &&
      !this.state.showIncompleteApplicationMessage &&
      !this.state.showSetupInProgressMessage &&
      !this.state.showFallBackErrorPage
    );
  }

  async resendEmail() {
    this.setState({
      isResendEmailLoading: true,
    });
    const currentUser = JSON.parse(localStorage.getItem("currentUser") || "{}");
    const userSerializer = new UserSerializer(currentUser);

    const resendEmailObj = await this.userManagement.resendEmail(
      userSerializer.mobileNo
    );
    const { status, response } = resendEmailObj;

    if (status === ERROR_STATUS) {
      this.setState({
        isResendEmailLoading: false,
      });
      this.props.showErrorMessage(JSON.stringify(response));
      setTimeout(() => {
        this.props.dismissErrorMessage();
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
    } else {
      this.setState({
        isResendEmailLoading: false,
      });
      this.props.showSuccessMessage(response.description);
      setTimeout(() => {
        this.props.dismissSuccessMessage();
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
    }
  }

  async getAgentDetails() {
    const { status, response } = await this.platform.getModifiedAgentDetails(
      this.state.active
    );

    if (status === SUCCESS_STATUS) {
      localStorage.setItem("modifiedAgentDetails", JSON.stringify(response));
      this.setState({
        agentDetails: response.content,
      });
    } else {
      localStorage.setItem("modifiedAgentDetails", JSON.stringify(null));
      this.setState({
        agentDetails: [],
      });
    }
  }

  renderRefreshTransactionMessage() {
    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "top",
          marginTop: "24px",
        }}
      >
        <Text blue bold small>
          Oops!
        </Text>
        <Text mid>Something went wrong. Please, try again.</Text>
        <div
          style={{
            alignItems: "center",
            // backgroundColor: this.props.isLoading ? 'white' : process.env.REACT_APP_BLUE_COLOUR,
            border: `1pt solid ${process.env.REACT_APP_BLUE_COLOUR}`,
            borderRadius: "4px",
            color: process.env.REACT_APP_BLUE_COLOUR,
            cursor: "pointer",
            display: "flex",
            fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            fontStyle: process.env.REACT_APP_MID_FONT_SIZE,
            fontWeight: "bold",
            height: "40px",
            justifyContent: "center",
            marginTop: "16px",
            // padding: '7.5px 10px 7.5px 10px',
            width: "160px",
          }}
          // onClick={() => props.disabled ? null : window.open(FUND_WALLET_URL, '_blank')}
          onClick={this.getLastTransactions}
        >
          <MdSync
            rotate={this.props.isLoading}
            color={process.env.REACT_APP_BLUE_COLOUR}
            fontSize={"24px"}
            style={{ marginRight: "10px" }}
          />
          {this.props.isLoading ? "Loading..." : "RETRY"}
        </div>
      </div>
    );
  }

  renderRefreshServicesReportMessage() {
    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "top",
          marginTop: "24px",
        }}
      >
        <Text blue bold small>
          Oops!
        </Text>
        <Text mid>Something went wrong. Please, try again.</Text>
        <div
          style={{
            alignItems: "center",
            // backgroundColor: this.props.isLoading ? 'white' : process.env.REACT_APP_BLUE_COLOUR,
            border: `1pt solid ${process.env.REACT_APP_BLUE_COLOUR}`,
            borderRadius: "4px",
            color: process.env.REACT_APP_BLUE_COLOUR,
            cursor: "pointer",
            display: "flex",
            fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            fontStyle: process.env.REACT_APP_MID_FONT_SIZE,
            fontWeight: "bold",
            height: "40px",
            justifyContent: "center",
            marginTop: "16px",
            // padding: '7.5px 10px 7.5px 10px',
            width: "160px",
          }}
          // onClick={() => props.disabled ? null : window.open(FUND_WALLET_URL, '_blank')}
          onClick={() =>
            this.getMonthlyGraphTransactionsData(
              this.state.firstDayCurrentMonth,
              this.state.lastDayCurrentMonth
            )
          }
        >
          <MdSync
            rotate={this.props.isLoading}
            color={process.env.REACT_APP_BLUE_COLOUR}
            fontSize={"24px"}
            style={{ marginRight: "10px" }}
          />
          {this.props.isLoading ? "Loading..." : "RETRY"}
        </div>
      </div>
    );
  }

  renderTransactionsTable() {
    if (this.state.didErrorOccurWhileFetchingRecentTransactions) {
      return this.renderRefreshTransactionMessage();
    } else if (
      this.state.transactionHistory.length === 0 &&
      !this.state.miniStatementIsLoading
    ) {
      return (
        <p
          style={{
            textAlign: "center",
            color: process.env.REACT_APP_BLUE_COLOUR,
            fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
            padding: "85px 0 85px 0",
          }}
        >
          {"No record found"}
        </p>
      );
    } else {
      return (
        <RecentTransactionsTable
          data={this.state.transactionHistory}
          loading={this.state.miniStatementIsLoading}
          navigateTo={this.props.navigateTo}
        />
      );
    }
  }

  renderTransactionsJournalTable() {
    if (this.state.didErrorOccurWhileFetchingRecentTransactions) {
      return this.renderRefreshTransactionMessage();
    } else if (
      this.state.transactionHistory.length === 0 &&
      !this.state.miniStatementIsLoading
    ) {
      return (
        <p
          style={{
            textAlign: "center",
            color: process.env.REACT_APP_BLUE_COLOUR,
            fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
            padding: "85px 0 85px 0",
          }}
        >
          {"No record found"}
        </p>
      );
    } else {
      return (
        <WalletJournalTable
          data={this.state.journalTransactionHistory}
          loading={this.state.journalMiniStatementIsLoading}
        />
      );
    }
  }

  checkEmptyRecords(serviceReportGraphObject) {
    return (
      serviceReportGraphObject.recharge === 0 &&
      serviceReportGraphObject.transfer === 0 &&
      serviceReportGraphObject.paybills === 0 &&
      serviceReportGraphObject.cashout === 0 &&
      serviceReportGraphObject.fund === 0 &&
      serviceReportGraphObject.wallet === 0 &&
      serviceReportGraphObject.cashin === 0
    );
  }

  renderServicesReportGraph(serviceReportGraphObject) {
    if (this.state.didErrorOccurWhileFetchingReportsGraph) {
      return this.renderRefreshServicesReportMessage();
    } else if (
      this.checkEmptyRecords(serviceReportGraphObject) &&
      !this.state.isLoadingReportsGraph
    ) {
      return (
        <p
          style={{
            textAlign: "center",
            color: process.env.REACT_APP_BLUE_COLOUR,
            fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
            padding: "85px 0 85px 0",
          }}
        >
          {"No transactions record this month"}
        </p>
      );
    } else if (this.state.isLoadingReportsGraph) {
      return (
        <div style={{ color: "#3A6D84" }}>
          <ActivityIndicator />
        </div>
      );
    } else {
      return (
        <ServicesReportGraph
          data={serviceReportGraphObject.pieChartData}
          navigateTo={this.props.navigateTo}
        />
      );
    }
  }

  handleMiniStatementToggle(value) {
    this.setState({
      miniStatementType: value,
    });
  }

  renderTransactionsTablesGrid() {
    const { graphData } = this.state;

    const payBillsMonthDataSum =
      graphData && refineGraphData(graphData.bills).reduce((a, b) => a + b, 0);
    const rechargeMonthDataSum =
      graphData &&
      refineGraphData(graphData.recharge).reduce((a, b) => a + b, 0);
    const transferMonthDataSum =
      graphData &&
      refineGraphData(graphData.transfer).reduce((a, b) => a + b, 0);
    const fundMonthDataSum =
      graphData && refineGraphData(graphData.fund).reduce((a, b) => a + b, 0);
    const cashoutMonthDataSum =
      graphData &&
      refineGraphData(graphData.cashOut).reduce((a, b) => a + b, 0);
    const walletToWalletMonthDataSum =
      graphData &&
      refineGraphData(graphData.walletToWallet).reduce((a, b) => a + b, 0);
    const cashinMonthDataSum =
      graphData && refineGraphData(graphData.cashIn).reduce((a, b) => a + b, 0);
    const payPointFundMonthDataSum =
      graphData &&
      refineGraphData(graphData.payPointFund).reduce((a, b) => a + b, 0);

    const totalMonthlySum =
      payBillsMonthDataSum +
      rechargeMonthDataSum +
      transferMonthDataSum +
      fundMonthDataSum +
      cashoutMonthDataSum +
      walletToWalletMonthDataSum +
      cashinMonthDataSum +
      payPointFundMonthDataSum;
    const recharge = calculatePercentage(rechargeMonthDataSum, totalMonthlySum);
    const transfer = calculatePercentage(transferMonthDataSum, totalMonthlySum);
    const paybills = calculatePercentage(payBillsMonthDataSum, totalMonthlySum);
    const cashout = calculatePercentage(cashoutMonthDataSum, totalMonthlySum);
    const fund = calculatePercentage(fundMonthDataSum, totalMonthlySum);
    const wallet = calculatePercentage(
      walletToWalletMonthDataSum,
      totalMonthlySum
    );
    const cashin = calculatePercentage(cashinMonthDataSum, totalMonthlySum);
    const payPoint = calculatePercentage(
      payPointFundMonthDataSum,
      totalMonthlySum
    );

    const pieChartData = {
      labels: [
        "SELL AIRTIME",
        "SEND MONEY",
        "PAY BILLS",
        "CASH OUT",
        "FUND WALLET",
        "WALLET TO WALLET",
        "CASH IN",
        "PAYPOINT FUND",
      ],
      datasets: [
        {
          data: [
            recharge,
            transfer,
            paybills,
            cashout,
            fund,
            wallet,
            cashin,
            payPoint,
          ],
          borderColor: [
            "#01CBF3",
            "#FFAA26",
            "#9F4FF5",
            "#EE312A",
            "#f3bf01",
            "#53ca59",
            "#EE312A",
            "#ff6c36",
          ],
          backgroundColor: [
            "#83F4FA",
            "#FACB83",
            "#9483FA",
            "#F9596C",
            "#f3bf01",
            "#53ca59",
            "#F959BC",
            "#ff6c36",
          ],
          hoverBackgroundColor: [
            "#83F4FA",
            "#FACB83",
            "#9483FA",
            "#F9596C",
            "#f3bf0170",
            "#53ca59",
            "#F959BC",
            "#ff6c36",
          ],
        },
      ],
    };

    const serviceReportGraphObject = {
      recharge,
      transfer,
      paybills,
      cashout,
      fund,
      wallet,
      cashin,
      payPoint,
      pieChartData,
    };

    return (
      <Row style={{ margin: 0, padding: 0 }}>
        <Col
          xs={12}
          md={12}
          xl={8}
          style={{
            backgroundColor: "white",
            borderRadius: "4px",
            padding: "10px 20px 20px 20px",
            marginRight: "0px",
            margin: 0,
          }}
        >
          <Tabs>
            <div style={{ color: "black" }} label="Recent transactions">
              {this.renderTransactionsTable()}
            </div>
            <div style={{ color: "black" }} label="Statement of account">
              {this.renderTransactionsJournalTable()}
            </div>
          </Tabs>
        </Col>

        {/* <Col xs={12} md={12} xl={8} style={{backgroundColor: 'white', borderRadius: '4px', padding: '10px 20px 20px 20px', marginRight: '0px', margin: 0}}>
        <Row style={{padding: '5px 0px 10px 0px', margin: 0, display: 'flex', justifyContent: 'space-between' }}>
          <Col md={10} onClick={() => this.handleMiniStatementToggle(1)} style={{color: '#3A6D84', cursor: 'pointer', fontSize: process.env.REACT_APP_MID_FONT_SIZE, fontWeight: (this.state.miniStatementType === 1) ? 'bold' : '300', lineHeight: process.env.REACT_APP_MID_LINE_HEIGHT, margin: 0, padding: 0}}>Recent transactions</Col>
          <Col md={2} onClick={() => this.handleMiniStatementToggle(2)} style={{color: '#3A6D84', cursor: 'pointer', fontSize: process.env.REACT_APP_MID_FONT_SIZE, fontWeight: (this.state.miniStatementType === 2) ? 'bold' : '300', lineHeight: process.env.REACT_APP_MID_LINE_HEIGHT, margin: 0, padding: 0}}>Statement of account</Col>
        </Row>
        {this.state.miniStatementType === 1 ? this.renderTransactionsTable() : this.renderTransactionsJournalTable()}
      </Col> */}

        <Col xs={12} md={12} xl={4} style={{ margin: 0, padding: 0 }}>
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "4px",
              marginLeft: this.state.deviceIsPC ? "10px" : "0px",
              marginTop: this.state.deviceIsPC ? "0px" : "10px",
              padding: "10px 20px 20px 20px",
            }}
          >
            <Row style={{ padding: "5px 0px 10px 0px", margin: 0 }}>
              <Col
                md={12}
                style={{
                  color: "#3A6D84",
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  fontWeight: "300",
                  lineHeight: process.env.REACT_APP_MID_LINE_HEIGHT,
                  margin: 0,
                  padding: 0,
                }}
              >
                Monthly Services Report
              </Col>
            </Row>

            {this.renderServicesReportGraph(serviceReportGraphObject)}
          </div>
        </Col>
      </Row>
    );
  }

  renderDefaultTab() {
    return (
      <div id="default-tab">
        <ServicesLabelRow
          disabled={this.state.disabled}
          navigateTo={this.props.navigateTo}
        />
        <ServiceCardRow
          deviceIsMobile={this.state.deviceIsMobile}
          disabled={this.state.disabled}
          navigateTo={this.props.navigateTo}
          currentUser={this.state.currentUser}
        />
        {process.env.REACT_APP_UPGRADE_STANDARD === "true" &&
          this.state.agentDetails.length > 0 &&
          this.state.agentDetails?.count === 0 &&
          this.state.agentDetails[0]?.status === 0 &&
          this.state.agentDetails[0]?.status === 1 &&
          this.state.showUpgradeMessage && <UpgradeMessage />}
        {/* {process.env.REACT_APP_SHOW_POS && (
          <React.Fragment>
            {this.state.posRequestReport.length > 0 && (
              <DashboardBanner requestId={this.state.posRequestReport} />
            )}
          </React.Fragment>
        )} */}
        {this.toShowTransactionsTable === true && (
          <H1 normal leftAlign>
            Transactions
          </H1>
        )}
        {this.toShowTransactionsTable === true &&
          this.renderTransactionsTablesGrid()}
        {this.state.showFallBackErrorPage && (
          <FallBackPage
            loadDashboard={this.loadDashboard}
            loading={this.props.isLoading | this.state.isLoading}
            user={this.state.currentUser}
          />
        )}
        {this.state.showApplicationReviewMessage && (
          <ApplicationReviewMessage />
        )}
        {this.state.showIncompleteApplicationMessage && (
          // <IncompleteApplicationMessage
          //   resendEmail={this.resendEmail}
          //   rejectedApplication={this.state.rejectedApplication}
          //   declineReason={this.state.currentApplication.declineReason}
          //   email={this.state.currentApplication.applicantDetails.emailAddress}
          //   deviceIsMobile={this.state.deviceIsMobile}
          //   isLoading={this.state.isResendEmailLoading}
          // />
          <>
            {process.env.REACT_APP_NAV_MENU_SHOW_KYC_UPDATE ? (
              <SetupAgentNew application={this.state.currentApplication} />
            ) : (
              <SetupAgent application={this.state.currentApplication} />
            )}
          </>
        )}
        {this.state.showSetupInProgressMessage && <SetupInProgressMessage />}
        {this.state.showLandingMessage && (
          <LandingMessage fundWallet={this.doFundWallet} />
        )}
      </div>
    );
  }

  renderDashboardTabs() {
    return {
      dashboard: {
        component: this.renderDefaultTab(),
      },
      // 'commissions-earned': {
      //   component: this.toShowTransactionsTable && <CommissionsEarnedScene id="commissions-earned-tab" agentCode={this.state.currentAgent.agentCode} onCancel={() => this.setState({activeTab: "dashboard"})} />
      // },
      "fund-wallet": {
        component: (
          <FundWalletScene
            id="fund-wallet-tab"
            onCancel={() => this.setState({ activeTab: "dashboard" })}
            updateDashboard={this.updateDashboard}
            showErrorMessage={this.props.showErrorMessage}
            dismissErrorMessage={this.props.dismissErrorMessage}
            showSuccessMessage={this.props.showSuccessMessage}
            dismissSuccessMessage={this.props.dismissSuccessMessage}
          />
        ),
      },
      "unload-commission": {
        component: (
          <UnloadCommissionScene
            id="unload-commission-tab"
            onCancel={() => this.setState({ activeTab: "dashboard" })}
            wallet={this.state.wallet}
            currentAgent={this.state.currentAgent}
          />
        ),
      },
      "settlement-history": {
        component: this.toShowTransactionsTable && (
          <CommissionsEarnedScene
            id="settlement-history-tab"
            onCancel={() =>
              this.state.navigateToCommission
                ? (window.location.href = "/agent/commissions")
                : this.setState({ activeTab: "dashboard" })
            }
          />
        ),
      },
    };
  }

  renderDesktopContainer() {
    const currentAgent = this.state.currentAgent || {};

    return (
      <React.Fragment>
        {this.state.showModal && (
          <VerticallyCenteredModal
            onHide={this.handleModalClose}
            show={this.state.showModal}
            title="FUND WALLET"
            hideFooter={true}
            body={
              <FundWalletScene
                onCancel={() => this.setState({ activeTab: "dashboard" })}
                showErrorMessage={this.props.showErrorMessage}
                dismissErrorMessage={this.props.dismissErrorMessage}
                showSuccessMessage={this.props.showSuccessMessage}
                dismissSuccessMessage={this.props.dismissSuccessMessage}
              />
            }
            // body={<FundScene/>}
          />
        )}
        <DecorativeDashboardHeader
          style={{ height: this.computeDashboardHeaderHeight() }}
        />
        <div style={{ color: "white", padding: "20pt" }}>
          {/* {process.env.REACT_APP_NAV_MENU_SHOW_MANAGE_PRO && (
            (this.state.currentAgent?.agentClass === "PRESTIGE" ||
            this.state.currentAgent?.agentClass === "STANDARD") &&
            this.state.currentAgent?.ninVerificationStatus !== "VERIFIED" && */}
          <UpdateButton
            currentAgent={this.state.currentAgent}
            currentUser={this.state.user}
            isLoading={this.props.isLoading | this.state.isLoading}
            onClick={this.updateDashboard}
            wallet={this.state.wallet}
            toShowCompleteApplication={this.toShowCompleteApplication}
            disabled={this.state.disabled}
            currentApplication={this.state.currentApplication || {}}
            navigateTo={this.props.navigateTo}
            toShowSetupInProgress={this.toShowSetupInProgress}
            toShowAwaitingApproval={this.toShowAwaitingApproval}
            toShowAwaitingValidation={this.toShowAwaitingValidation}
            toShowInactive={this.toShowInactive}
            toShowSuspended={this.toShowSuspended}
            agentClass={currentAgent.agentClass}
            rejectedApplication={this.state.rejectedApplication}
            listenerValue={this.state.listenerValue}
          />
          {/* }} */}
          <WalletBalances
            activeTab={this.state.activeTab}
            deviceIsMobile={false}
            disabled={this.state.disabled}
            isAgentNew={this.state.showLandingMessage}
            onCommissionsEarnedClick={() =>
              this.state.disabled
                ? null
                : this.setState({ activeTab: COMMISSIONS_EARNED })
            }
            onFundWalletClick={() =>
              this.state.disabled ? null : this.doFundWallet()
            }
            onUnloadCommissionClick={() =>
              this.state.disabled
                ? null
                : this.setState({ activeTab: UNLOAD_COMMISSION })
            }
            onSettlementHistoryClick={() =>
              this.state.disabled
                ? null
                : this.setState({ activeTab: SETTLEMENT_HISTORY })
            }
            wallet={this.state.wallet}
            suggestFundWallet={this.state.suggestFundWallet}
            handleModalOpen={this.handleModalOpen}
            handleModalClose={this.handleModalClose}
          />
          <Carousel
            activeIndex={this.getDashboardCurrentCarouselIndex(
              Object.keys(this.renderDashboardTabs())
            )}
            controls={false}
            indicators={false}
            interval={null}
            style={{ margin: 0, padding: 0, width: "78vw" }}
          >
            {Object.values(this.renderDashboardTabs()).map((value, idx) => {
              return <Carousel.Item key={idx}>{value.component}</Carousel.Item>;
            })}
          </Carousel>
        </div>
      </React.Fragment>
    );
  }

  renderMobileContainer() {
    const currentAgent = this.state.currentAgent || {};

    return (
      <React.Fragment>
        <DecorativeDashboardHeader
          mobile
          style={{ height: this.computeDashboardHeaderHeight() }}
        />
        {this.state.disabled && (
          <ApplicationStatusIndicators
            currentAgent={this.state.currentAgent}
            currentUser={this.state.currentUser}
            currentApplication={this.state.currentApplication}
            navigateTo={this.props.navigateTo}
            toShowAwaitingApproval={this.toShowAwaitingApproval}
            toShowAwaitingValidation={this.toShowAwaitingValidation}
            toShowCompleteApplication={this.toShowCompleteApplication}
            toShowInactive={this.toShowInactive}
            toShowSetupInProgress={this.toShowSetupInProgress}
            toShowSuspended={this.toShowSuspended}
            rejectedApplication={this.state.rejectedApplication}
            mobile
          />
        )}
        <div style={{ padding: "20px", paddingTop: "10px" }}>
          <UpdateButton
            currentAgent={this.state.currentAgent}
            currentUser={this.state.user}
            isLoading={this.props.isLoading | this.state.isLoading}
            onClick={this.updateDashboard}
            wallet={this.state.wallet}
            toShowCompleteApplication={this.toShowCompleteApplication}
            disabled={this.state.disabled}
            currentApplication={this.state.currentApplication || {}}
            navigateTo={this.props.navigateTo}
            toShowSetupInProgress={this.toShowSetupInProgress}
            toShowAwaitingApproval={this.toShowAwaitingApproval}
            toShowAwaitingValidation={this.toShowAwaitingValidation}
            toShowInactive={this.toShowInactive}
            toShowSuspended={this.toShowSuspended}
            agentClass={currentAgent.agentClass}
            rejectedApplication={this.state.rejectedApplication}
            deviceIsMobile={this.state.deviceIsMobile}
          />
          <WalletBalances
            activeTab={this.state.activeTab}
            deviceIsMobile={true}
            disabled={this.state.disabled}
            onCommissionsEarnedClick={() =>
              this.state.disabled
                ? null
                : this.setState({ activeTab: COMMISSIONS_EARNED })
            }
            onUnloadCommissionClick={() =>
              this.state.disabled
                ? null
                : this.setState({ activeTab: UNLOAD_COMMISSION })
            }
            onFundWalletClick={() =>
              this.state.disabled ? null : this.doFundWallet()
            }
            onSettlementHistoryClick={() =>
              this.state.disabled
                ? null
                : this.setState({ activeTab: SETTLEMENT_HISTORY })
            }
            wallet={this.state.wallet}
            suggestFundWallet={this.state.suggestFundWallet}
          />
          <Carousel
            activeIndex={this.getDashboardCurrentCarouselIndex(
              Object.keys(this.renderDashboardTabs())
            )}
            controls={false}
            indicators={false}
            interval={null}
            style={{ margin: 0, padding: 0, width: "90vw" }}
          >
            {Object.values(this.renderDashboardTabs()).map((value, idx) => {
              return <Carousel.Item key={idx}>{value.component}</Carousel.Item>;
            })}
          </Carousel>
        </div>
      </React.Fragment>
    );
  }

  handleYesConfirmationModal() {
    this.props.setBVNNotificationFlag(false);
    this.props.setActiveTab("bvn-details");
    this.props.navigateTo("manage-profile");
  }
  handleNoConfirmationModal() {
    this.props.setBVNNotificationFlag(false);
  }

  getGracePeriodMessage() {
    const diffDuration = this.getGracePeriodRemaining();
    const timeMessage =
      "Total Duration : " +
      "Days:" +
      diffDuration.days() +
      " Hours:" +
      diffDuration.hours() +
      "Minutes:" +
      diffDuration.minutes() +
      "Seconds:" +
      diffDuration.seconds();
  }

  getGracePeriodRemaining() {
    const startDate = moment();
    const timeEnd = this.props.bvnGracePeriod
      ? moment(this.props.bvnGracePeriod)
      : null;
    const diff = timeEnd.diff(startDate);
    const diffDuration = moment.duration(diff);
    return diffDuration;
  }

  setShowThankyouMessage(flag) {
    // this.props.setBVNNotificationFlag(false)
    this.setState({
      showThankyouMessage: flag,
      //showSuspendedAgentModal:false
    });
  }

  showInstantBVNValidationMessage(bvnResponseMsg) {
    this.setState({
      instantBVNValidationMessage: bvnResponseMsg,
      showInstantBVNValidationMessageModal: true,
    });

    setTimeout(() => {
      this.setState({
        showInstantBVNValidationMessageModal: false,
      });
    }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
  }

  setShowSuspendedAgentModal(flag) {
    this.setState({
      showSuspendedAgentModal: flag,
    });
  }

  setShowPartiallyVerifiedAgentModal(flag) {
    this.setState({
      showPartiallyVerifiedAgentModal: flag,
    });
  }

  redirectToBVNProcessingPage() {
    localStorage.setItem("isBVNUpdated", "true");
    window.location = `agent/processing-bvn`;
  }

  render() {
    const currentUser = JSON.parse(localStorage.getItem("currentUser")); //
    const currentApplication = JSON.parse(
      localStorage.getItem("currentApplication")
    );

    const domainType = currentUser?.domainType + "";

    if (
      domainType.includes("Applicant") &&
      !currentApplication.applicantDetails.hasOwnProperty("bvn")
    ) {
      window.location.href = `/signup?frm=0`;
    }

    if (this.props.loadUserStatus === END_LOAD_USER_DATA) {
      this.loadDashboard();
      this.props.nullifyUserStatus();
    }

    const showRedirectToBVNValidationOnboardingFormModal = (
      <Modal
        animation="true"
        style={{ borderRadius: "10px", transition: "all .2s ease-in-out" }}
        size="lg"
        show={this.state.showRedirectToBVNValidationOnboardingForm}
        onHide={() => {
          window.location.href = "/logout?case=APP_SESSION_EXPIRED";
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ margin: "10px", padding: "15px" }}>
          <BVNValidationForm
          // validatePhone={this.validatePhone}
          // isBVNValidationSuccesful={()=>null}
          // isLoading={this.props.isLoading}
          // updateLoading = {this.props.updateLoading}
          // dismissErrorMessage={this.props.dismissErrorMessage}
          // showErrorMessage = {this.props.showErrorMessage}
          // dismissSuccessMessage = {this.props.dismissSuccessMessage}
          // showSuccessMessage = {this.props.showSuccessMessage}
          //setShowRedirectToBVNValidationOnboardingForm={this.setShowRedirectToBVNValidationOnboardingForm}
          />
        </Modal.Body>
        {/* <Modal.Footer style={{ justifyContent: "center" }}>
          <Button onClick={() => { window.location.href = `/agent/signup`}}>Ok</Button>
        </Modal.Footer> */}
      </Modal>
    );

    const confirmationModal = (
      <Modal
        animation="true"
        style={{ borderRadius: "10px", transition: "all .2s ease-in-out" }}
        size="lg"
        show={this.state.showConfirmationModal}
        onHide={this.handleNoConfirmationModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ margin: "10px", padding: "15px" }}>
          <H1 style={{ textAlign: "center", margin: "0px", padding: "0px" }}>
            {/* Please, Provide these information as it is on your BVN  */}
            Your BVN data is not accurate.
          </H1>
          <div style={{ textAlign: "center", fontSize: "20px" }}>
            {/* Name,BVN,Date of Birth, and Phone Number */}
            Kindly update your BVN information within the next
          </div>

          <CustomCountdownCircleTimer
            endTime={moment(this.props.bvnGracePeriod).valueOf()}
          />

          <div style={{ textAlign: "center", fontSize: "20px" }}>
            to avoid being suspended.
          </div>

          <center>
            <HR style={{ marginTop: "10px", width: "20%" }} />

            <Text
              style={{
                fontSize: "15px",
                lineHeight: process.env.REACT_APP_MID_BIG_LINE_HEIGHT,
                margin: "10px",
              }}
            >
              Click{" "}
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  this.handleYesConfirmationModal();
                }}
              >
                HERE
              </a>{" "}
              To Update
            </Text>
          </center>
        </Modal.Body>
        {/* <Modal.Footer style={{justifyContent:'center'}}>
                                    <Button onClick={this.handleYesConfirmationModal}>Yes</Button><Button onClick={this.handleNoConfirmationModal}>No</Button> 
                                  </Modal.Footer> */}
      </Modal>
    );

    const suspendedAgentMessageModal = (
      <Modal
        animation="true"
        style={{ borderRadius: "10px", transition: "all .2s ease-in-out" }}
        size="lg"
        show={this.props.bvnNotificationFlag && this.props.isSuspendedAgent()}
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <H1>ACCOUNT SUSPENDED</H1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ margin: "10px", padding: "15px", height: "100px" }}
        >
          <div
            style={{
              textAlign: "center",
              margin: "0px",
              padding: "0px",
              fontSize: "20px",
            }}
          >
            {
              // localStorage.getItem("isBVNUpdated") ==="true" ? "Sorry! Your BVN information previously supplied is incorrect. Kindly try again" :  "Your account has been suspended due to invalid BVN information."
            }
          </div>
          <div
            style={{
              textAlign: "center",
              margin: "0px",
              padding: "0px",
              fontSize: "20px",
            }}
          >
            Your account has been suspended as a result
          </div>
          <div style={{ textAlign: "center", fontSize: "20px" }}>
            of inaccurate BVN information. Click
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ showSuspendedAgentModal: true });
              }}
            >
              {" "}
              HERE
            </a>{" "}
            to update your BVN!.
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button color="secondary" onClick={ ()=> (window.location.href = "/logout")} style={{width:'15%',height:'45px' ,padding:'0px'}} >Logout</Button> */}
          <a href="/logout">logout</a>
        </Modal.Footer>
      </Modal>
    );

    const suspendedAgentsModal = (
      <Modal
        animation="true"
        style={{ borderRadius: "10px", transition: "all .2s ease-in-out" }}
        size="xl"
        show={this.state.showSuspendedAgentModal}
        onHide={(e) => this.setShowSuspendedAgentModal(false)}
        centered
      >
        {this.props.bvnVerificationStatus === VERIFIED_PARTIALLY && (
          <Modal.Header closeButton></Modal.Header>
        )}
        <Modal.Body style={{ margin: "10px", padding: "15px" }}>
          <H1
            style={{
              textAlign: "center",
              textTransform: "uppercase",
              margin: "0px",
              padding: "0px",
            }}
          ></H1>
          <BVNDetailsForm
            bvnVerificationStatus={this.props.bvnVerificationStatus}
            showThankyouMessage={this.setShowThankyouMessage}
            showInstantBVNValidationMessage={
              this.showInstantBVNValidationMessage
            }
            setBVNNotificationFlag={this.props.setBVNNotificationFlag}
            setShowSuspendedAgentModal={this.setShowSuspendedAgentModal}
            setShowPartiallyVerifiedAgentModal={
              this.setShowPartiallyVerifiedAgentModal
            }
            bvnFormInModalMode={true}
          />
        </Modal.Body>

        {/* <Modal.Footer style={{justifyContent:'right',cursor: 'pointer'}}>
                                              Next: <MdArrowDroprightCircle fontSize="26px" color="#EE312A" onClick={() => { this.setShowThankyouMessage(true);  this.setState({showSuspendedAgentModal:false}) }  }>Next</MdArrowDroprightCircle>
                                       </Modal.Footer> */}
      </Modal>
    );

    const instantBVNValidationMessageModal = (
      <Modal
        animation="true"
        style={{ borderRadius: "0px", transition: "all .2s ease-in-out" }}
        size="lg"
        show={this.state.showInstantBVNValidationMessageModal}
      >
        <Modal.Body
          style={{
            margin: "0px",
            padding: "0px",
            backgroundColor: `${
              this.state.instantBVNValidationMessage.validationStatus ===
              "VERIFIED"
                ? "#1ade6e"
                : "red"
            }`,
          }}
        >
          <H1
            style={{
              textAlign: "center",
              textTransform: "uppercase",
              margin: "0px",
              padding: "0px",
              fontSize: "15px",
              color: "#fff",
            }}
          >
            {this.state.instantBVNValidationMessage.message}
          </H1>
          <br />
        </Modal.Body>
        {/* <Modal.Footer style={{ justifyContent: "center" }}>
      <Button onClick={() => this.setState({showInstantBVNValidationMessageModal:false})}>Ok</Button>
      </Modal.Footer> */}
      </Modal>
    );

    const partiallyVerifiedAgentModal = (
      <Modal
        animation="true"
        style={{
          borderRadius: "0px",
          transition: "all .2s ease-in-out",
          marginTop: "10px",
        }}
        size="lg"
        show={this.state.showPartiallyVerifiedAgentModal}
      >
        <Modal.Title style={{ marginTop: "10px" }}>
          <H1>ACCOUNT PARTIALLY VERIFIED</H1>
        </Modal.Title>

        <Modal.Body style={{ margin: "0px", padding: "0px" }}>
          {/* <H1
                                style={{
                                  textAlign: "center",
                                  textTransform: "uppercase",
                                  margin: "0px",
                                  padding: "0px",
                                  fontSize: "15px",
                                  color:'#fff'
                                }}
                              >             
                              </H1> */}
          <br />

          <div
            style={{
              textAlign: "center",
              margin: "0px",
              padding: "0px",
              fontSize: "20px",
            }}
          >
            Your account is partially verified
          </div>
          <div style={{ textAlign: "center", fontSize: "20px" }}>
            please update BVN information. Click
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                this.setState({
                  showSuspendedAgentModal: true,
                  showPartiallyVerifiedAgentModal: false,
                });
              }}
            >
              {" "}
              HERE
            </a>{" "}
            to update your BVN!.
          </div>

          <CustomCountdownCircleTimer
            endTime={moment(this.props.bvnGracePeriod).valueOf()}
          />

          <br />
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center", border: "0px" }}>
          <Button
            onClick={() =>
              this.setState({ showPartiallyVerifiedAgentModal: false })
            }
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );

    const notVerifiedAgentModal = (
      <Modal
        animation="true"
        style={{
          borderRadius: "0px",
          transition: "all .2s ease-in-out",
          marginTop: "10px",
        }}
        size="lg"
        show={this.state.showNotVerifiedAgentModal}
      >
        <Modal.Title style={{ marginTop: "10px" }}>
          <H1>ACCOUNT NOT VERIFIED</H1>
        </Modal.Title>

        <Modal.Body style={{ margin: "0px", padding: "0px" }}>
          {/* <H1
                              style={{
                                textAlign: "center",
                                textTransform: "uppercase",
                                margin: "0px",
                                padding: "0px",
                                fontSize: "15px",
                                color:'#fff'
                              }}
                            >             
                            </H1> */}
          <br />

          <div
            style={{
              textAlign: "center",
              margin: "0px",
              padding: "0px",
              fontSize: "20px",
            }}
          >
            Your account is not verified
          </div>
          <div style={{ textAlign: "center", fontSize: "20px" }}>
            please update BVN information. Click
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                this.setState({
                  showSuspendedAgentModal: true,
                  showPartiallyVerifiedAgentModal: false,
                  showNotVerifiedAgentModal: false,
                });
              }}
            >
              {" "}
              HERE
            </a>{" "}
            to update your BVN!.
          </div>
          <br />
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center", border: "0px" }}>
          <Button
            onClick={() => this.setState({ showNotVerifiedAgentModal: false })}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );

    const approvedAgentModal = (
      <Modal
        show={this.state.showApprovedModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body style={{ padding: "2rem", alignSelf: "center" }}>
          <div style={{ alignContent: "center", padding: "3rem" }}>
            <div style={{ display: "grid", placeContent: "center" }}>
              {HandIcon()}
            </div>
            <h1
              style={{
                textAlign: "center",
                fontWeight: "bold",
                marginBottom: "1rem",
                fontSize: "1.5rem",
              }}
            >
              Upgrade Request Approved
            </h1>
            <p
              style={{
                color: "#5F738C",
                fontWeight: "300",
                whiteSpace: "pre",
              }}
            >
              You upgrade request has been successfully approved
            </p>
            <button
              className="signupButton2"
              style={{
                marginTop: "2rem",
                textAlign: "center",
                color: "white",
                backgroundColor: "#00425f",
                marginLeft: "12%",
                width: "80%",
              }}
              onClick={() => this.setState({ showApprovedModal: false })}
            >
              {" "}
              Close{" "}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );

    const rejectedAgentModal = (
      <Modal
        show={this.state.showRejectedModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body style={{ padding: "1rem", alignSelf: "center" }}>
          <div style={{ alignContent: "center", padding: "3rem" }}>
            <div style={{ display: "grid", placeContent: "center" }}>
              {HandRejectIcon()}
            </div>
            <h1
              style={{
                textAlign: "center",
                fontWeight: "bold",
                marginBottom: "1rem",
                fontSize: "1.5rem",
              }}
            >
              Upgrade Request Rejected
            </h1>
            <p
              style={{
                color: "#5F738C",
                fontWeight: "300",
                whiteSpace: "pre",
                textWrap: "wrap",
              }}
            >
              Your upgrade request has been rejected, Please view the rejection
              reason.
            </p>
            <button
              className="signupButton2"
              style={{
                marginTop: "2rem",
                textAlign: "center",
                color: "white",
                backgroundColor: "#00425f",
                // marginLeft: '12%',
                width: "100%",
              }}
              onClick={() => this.setState({ showRejectedModal: false })}
            >
              {" "}
              View Reason{" "}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );

    return (
      <Container className="main-container" fluid>
        {this.state.deviceIsPC
          ? this.renderDesktopContainer()
          : this.renderMobileContainer()}
        {this.state.agentDetails.length > 0 &&
          this.state.agentDetails[0]?.status === 4 &&
          approvedAgentModal}
        {this.state.agentDetails.length > 0 &&
          this.state.agentDetails[0]?.status === 3 &&
          rejectedAgentModal}
        {this.toThrowConfetti && this.throwConfetti()}
        {this.state.toShowConfetti && (
          <Confetti
            gravity={0.15}
            height={window.innerHeight}
            numberOfPieces={250}
            width={window.innerWidth}
            recycle={false}
          />
        )}

        {this.props.bvnNotificationFlag &&
          this.props.bvnVerificationStatus === VERIFICATION_FAILED &&
          this.props.isGracePeriodValid() &&
          confirmationModal}

        {this.props.bvnNotificationFlag &&
          this.props.bvnVerificationStatus === VERIFIED_PARTIALLY &&
          partiallyVerifiedAgentModal}

        {this.props.bvnNotificationFlag &&
          this.props.bvnVerificationStatus === NOT_VERIFIED &&
          notVerifiedAgentModal}

        {/* {this.props.bvnNotificationFlag &&
              this.props.bvnVerificationStatus !== VERIFIED && this.props.isSuspendedAgent() &&   suspendedAgentMessageModal} */}

        {this.props.bvnNotificationFlag &&
          this.props.bvnVerificationStatus === SUSPENDED &&
          this.props.isSuspendedAgent() &&
          suspendedAgentMessageModal}

        {this.state.showSuspendedAgentModal && suspendedAgentsModal}

        {this.state.showInstantBVNValidationMessageModal &&
          instantBVNValidationMessageModal}

        {this.state.showRedirectToBVNValidationOnboardingForm &&
          showRedirectToBVNValidationOnboardingFormModal}
      </Container>
    );
  }

  getDashboardCurrentCarouselIndex(dashboardTabs) {
    return dashboardTabs.indexOf(this.state.activeTab);
  }
}
