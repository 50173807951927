import React from "react";

export default class PrivacyPolicy extends React.Component {
  isAuthRequired = false;

  render() {
    return (
      <div
        style={{
          height: "275px",
          overflowY: "auto",
          textAlign: "justify",
          paddingRight: "10px",
          ...this.props.style,
        }}
      >
        We electronically and manually collect Personal Data and Personal
        Identifiable Information (PII) of customers which includes name, email
        address, phone number, contact address, limited financial information,
        location data, device data etc. We use the collected personal
        information to provide secure & customised user experience, advertising,
        marketing and other purposes. We store and process your personal
        information on our computers in Lagos, Nigeria and other locations where
        our facilities are located. The information is protected using physical,
        technical, electronic and administrative security safeguards like
        firewalls, data encryption, physical access controls to our data
        centres, information access authorisation controls and ensuring our
        system complies with industry information security standards. We may
        share customers’ personal information with members of the Interswitch
        Group, regulators, law enforcement officials and other third parties. By
        clicking on the <b>I Agree</b> checkbox, you are consenting to our
        <a
          href="https://www.interswitchgroup.com/ng/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          full privacy policy
        </a>{" "}
        here. For users below the age of 18, the consent should be provided by
        your lawful custodian/guardian. Please note, in case you choose not to
        provide us with your consent or withdraw your previous consent at any
        given point in time, we shall not be under any obligation to provide any
        services to you.
      </div>
    );
  }
}
