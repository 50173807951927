import React, { useEffect, useState } from "react";
import BackButton from "../component/backButton";
import Title from "../component/title";
import { Col } from "react-bootstrap";
import InputText from "../component/input";
import SelectInput from "../component/select";

import "react-datepicker/dist/react-datepicker.css";
import MdCheckmarkCircle from "react-ionicons/lib/MdCheckmarkCircle";

import IosRefresh from "react-ionicons/lib/IosRefresh";
import PrimaryButton from "../component/button";
import { DatePickerComponent } from "../component/datePickerComponent";
import H1 from "../../../../../components/h1";
import PhoneInput from "../component/phoneInput";
import phoneValidators from "../../../../../utils/validators/phone-validators";
import { MAX_BVN_LENGTH } from "../../../../../constants/form-fields";
import { isBvnValid, isEmailValid, stripNonNumbers } from "../component/extras";
import {
  ERROR_STATUS,
  HTTP_NOT_FOUND_CODE,
  SUCCESS_CODE,
  SUCCESS_STATUS,
} from "../../../../../constants/api";

import {
  formatEmailOrPhone,
  formatPhoneNumber,
  specialDateRangeFormatter_StartDateDate,
} from "../../../../../utils/formatters";
import Onboarding from "../../../../../services/api/resources/onboarding";
import { firetoast, notify } from "../../../../../components/extras";
import UserManagement from "../../../../../services/api/resources/user-management";
import FormLabel from "../../../../../components/form-label";
import { isPhoneValid } from "../../../../../utils/validators/form-validators";
import { NIGERIA_PHONE_PREFIX } from "../../../../../constants";
const PersonalDetailsScene = ({ setScreen }) => {
  const jumbScreen = localStorage.getItem("jumbScreen");
  const onboarding = new Onboarding();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [propagateFormErrors, setPropagateFormErrors] = useState(false);
  const [bvnResponse, setBvnResponse] = useState({
    success: "",
    error: "",
  });
  const [fieldsFocused, setFieldsFocused] = useState([]);

  const applicationId = localStorage.getItem("applicationId");
  const application = JSON.parse(localStorage.getItem("application"));
  const updatedPersonaldetailsForm = JSON.parse(
    localStorage.getItem("personalDetailsForm")
  );

  const dateComponents = application?.applicantDetails?.dob.split("-") || "";
  const year = parseInt(dateComponents[0], 10);
  const month = parseInt(dateComponents[1], 10);
  const day = parseInt(dateComponents[2], 10);

  const formattedDate = `${year}, ${month}, ${day}`;

  const convertDate = (date) => {
    const dateComponents = date?.split("-") || "";
    const year = parseInt(dateComponents[0], 10);
    const month = parseInt(dateComponents[1], 10);
    const day = parseInt(dateComponents[2], 10);

    return `${year}, ${month}, ${day + 1}`;
  };

  const [personalDetailsForm, setPersonalDetailsForm] = useState({
    phoneNumber: application
      ? application?.applicantDetails?.phoneNumber
      : updatedPersonaldetailsForm
      ? updatedPersonaldetailsForm.phoneNumber
      : "",
    email: application
      ? application?.applicantDetails?.emailAddress
      : updatedPersonaldetailsForm
      ? updatedPersonaldetailsForm.email
      : "",
    gender: application
      ? application?.applicantDetails?.gender
      : updatedPersonaldetailsForm
      ? updatedPersonaldetailsForm.gender
      : "",
    bvn: application
      ? application?.applicantDetails?.bvn
      : updatedPersonaldetailsForm
      ? updatedPersonaldetailsForm.bvn
      : "",
    confirmPhoneNumber: application
      ? application?.applicantDetails?.phoneNumber
      : updatedPersonaldetailsForm
      ? updatedPersonaldetailsForm.confirmPhoneNumber
      : "",
    confirmEmail: application
      ? application?.applicantDetails?.emailAddress
      : updatedPersonaldetailsForm
      ? updatedPersonaldetailsForm.confirmEmail
      : "",
    phonePrefix: NIGERIA_PHONE_PREFIX,
    dob: application
      ? new Date(formattedDate)
      : updatedPersonaldetailsForm
      ? new Date(convertDate(updatedPersonaldetailsForm?.dob))
      : new Date(2005, 0, 1),
  });

  localStorage.setItem(
    "personalDetailsForm",
    JSON.stringify(personalDetailsForm)
  );
  const updateFormField = (params) => {
    const newForm = {
      ...personalDetailsForm,
      ...params,
    };

    setPersonalDetailsForm(newForm);
  };

  const isSenderPhoneValid = (newForm) => {
    const Form = newForm || personalDetailsForm;
    const countryValidator = phoneValidators[personalDetailsForm.phonePrefix];
    return (
      Form.phoneNumber &&
      (countryValidator ? countryValidator(Form.phoneNumber) : true)
    );
  };

  const isBeneficiaryPhoneValid = (newForm) => {
    const Form = newForm || personalDetailsForm;
    const countryValidator = phoneValidators[personalDetailsForm.phonePrefix];

    return (
      Form.phoneNumber &&
      (countryValidator ? countryValidator(Form.phoneNumber) : true)
    );
  };

  const isFormValid = () => {
    return (
      personalDetailsForm.phoneNumber &&
      personalDetailsForm.email &&
      personalDetailsForm.gender &&
      personalDetailsForm.dob &&
      personalDetailsForm.bvn &&
      isSenderPhoneValid() &&
      isEmailValid(personalDetailsForm) &&
      isBvnValid(personalDetailsForm) &&
      isPhoneValidAndNew() &&
      isEmailValidAndNew() &&
      personalDetailsForm.confirmPhoneNumber ===
        personalDetailsForm.phoneNumber &&
      personalDetailsForm.confirmPhoneNumber &&
      personalDetailsForm.confirmEmail &&
      personalDetailsForm.confirmEmail === personalDetailsForm.email &&
      bvnResponse.success
    );
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const payload = {
      applicantDetails: {
        emailAddress: personalDetailsForm.email,
        gender: personalDetailsForm.gender,
        phoneNumber: formatPhoneNumber(personalDetailsForm.phoneNumber),
        bvn: personalDetailsForm.bvn,
        dob: specialDateRangeFormatter_StartDateDate(personalDetailsForm.dob),
      },
    };
    const { status, response } =
      await onboarding.createPersonalDetailsAggregator(payload);

    setIsLoading(false);
    if (status === ERROR_STATUS) {
      firetoast("error", `${response.description}`, "");
    } else if (status === SUCCESS_STATUS) {
      notify("Saved", "success");
      localStorage.setItem("applicationId", response.applicationId);

      jumbScreen ? setScreen(6) : setScreen(3);
    }
  };

  const gender = ["Male", "Female"];

  const validateBVN = async () => {
    const date = personalDetailsForm.dob;

    setBvnResponse({
      success: "",
      error: "",
    });
    if (personalDetailsForm.bvn && personalDetailsForm.dob) {
      setLoading(true);
      const payload = {
        bvnNumber: personalDetailsForm.bvn,
        bvnDateOfBirth: specialDateRangeFormatter_StartDateDate(date),
      };
      const { status, response } = await onboarding.validateBVNDetails(payload);
      setLoading(false);
      if (status === ERROR_STATUS) {
        setBvnResponse({ error: response.description });
      } else if (status === SUCCESS_STATUS) {
        setBvnResponse({ success: response.description });
      }
    }
  };

  const handleCopy = (event) => {
    event.preventDefault();
  };

  const handlePaste = (event) => {
    event.preventDefault();
  };

  const NEW_PHONE = "phone is new";
  const NEW_EMAIL = "email is new";
  const USED_PHONE = "phone not new";
  const USED_EMAIL = "email not new";

  function isEmailValidAndNew() {
    return isEmailValid(personalDetailsForm) && isEmailExisting === NEW_EMAIL;
  }

  const userManagement = new UserManagement();

  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [isExisting] = useState(false);
  const [isEmailExisting, setIsEmailExisting] = useState("");

  const validateEmail = async (email) => {
    if (!email) {
      return;
    }

    setIsEmailLoading(true);
    const validateUserObj = await userManagement.validateUserOnPassport(email);
    const { code } = validateUserObj;
    if (code === SUCCESS_CODE) {
      setIsEmailExisting(USED_EMAIL);
      setIsEmailLoading(false);
      setEmailError(false);
    } else if (code === HTTP_NOT_FOUND_CODE) {
      setIsEmailExisting(NEW_EMAIL);
      setIsEmailLoading(false);
      setEmailError(false);
    } else {
      setIsEmailLoading(false);
      setEmailError(true);
    }
  };

  function isPhoneValidAndNew() {
    return (
      isPhoneValid(
        personalDetailsForm.phoneNumber,
        personalDetailsForm.phonePrefix
      ) && isPhoneExisting === NEW_PHONE
    );
  }

  const [isPhoneLoading, setIsPhoneLoading] = useState(false);
  const [isPhoneExisting, setIsPhoneExisting] = useState("");
  const [phoneError, setPhoneError] = useState(false);

  const validatePhone = async (phone) => {
    if (!phone) {
      return;
    }

    setIsPhoneLoading(true);
    const formattedPhone = formatEmailOrPhone(phone);
    const validateUserObj = await userManagement.validateUserOnPassport(
      formattedPhone
    );
    const { code } = validateUserObj;
    if (code === SUCCESS_CODE) {
      setIsPhoneExisting(USED_PHONE);
      setIsPhoneLoading(false);
      setPhoneError(false);
    } else if (code === HTTP_NOT_FOUND_CODE) {
      setIsPhoneExisting(NEW_PHONE);
      setIsPhoneLoading(false);
      setPhoneError(false);
    } else {
      setIsPhoneLoading(false);
      setPhoneError(true);
    }
  };

  useEffect(() => {
    if (personalDetailsForm.bvn.length === 11) {
      validateBVN();
    }
  }, [personalDetailsForm.bvn, personalDetailsForm.dob]);

  return (
    <>
      <BackButton
        onClick={() => {
          localStorage.removeItem("personalDetailsForm");
          setScreen(1);
        }}
      />
      <main className="aggregator-container">
        <Col
          style={{
            marginBottom: "20px",
            margin: "0 auto",
          }}
        >
          <Title pageNum={"1"} />
          <H1
            style={{
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "1rem",
            }}
          >
            Personal Information
          </H1>
          <PhoneInput
            onBlur={() => {
              isPhoneValid(
                personalDetailsForm.phoneNumber,
                personalDetailsForm.phonePrefix
              ) && validatePhone(personalDetailsForm.phoneNumber);
            }}
            onChange={(value) => {
              updateFormField({ phoneNumber: value });
            }}
            disabled={applicationId}
            form={personalDetailsForm.phoneNumber}
            updateFormField={updateFormField}
            isSenderPhoneValid={isSenderPhoneValid}
            isBeneficiaryPhoneValid={isBeneficiaryPhoneValid}
            fieldsFocused={fieldsFocused}
            setFieldsFocused={setFieldsFocused}
            propagateFormErrors={propagateFormErrors}
            title={"Phone Number"}
          />
          {isPhoneLoading && (
            <IosRefresh
              style={{
                marginTop: "-52pt",
                marginLeft: "85%",
                width: "20px",
                position: "absolute",
              }}
              fontSize="60px"
              color="grey"
              rotate={true}
            />
          )}
          {isPhoneValidAndNew() && !isPhoneLoading ? (
            <MdCheckmarkCircle
              style={{
                marginTop: "-45pt",
                marginLeft: "85%",
                width: "20px",
                position: "absolute",
              }}
              color="green"
              fontSize="40px"
            />
          ) : (
            ""
          )}
          {!personalDetailsForm.phoneNumber ||
          isPhoneValid(
            personalDetailsForm.phoneNumber,
            personalDetailsForm.phonePrefix
          ) ||
          !isExisting ? (
            <React.Fragment />
          ) : (
            <FormLabel
              style={{
                color: process.env.REACT_APP_RED_COLOUR,
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                lineHeight: "19px",
                position: "relative",
                top: "-15px",
                width: "100%",
              }}
            >
              Phone number is not valid.
            </FormLabel>
          )}
          {isPhoneExisting === USED_PHONE &&
            personalDetailsForm.phoneNumber && (
              <FormLabel
                style={{
                  color: process.env.REACT_APP_RED_COLOUR,
                  fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                  lineHeight: "19px",
                  position: "relative",
                  top: "-15px",
                  width: "100%",
                }}
              >
                Phone number already registered on Quickteller Paypoint. Try
                another number!
              </FormLabel>
            )}
          {phoneError && personalDetailsForm.phoneNumber && (
            <FormLabel
              style={{
                color: process.env.REACT_APP_RED_COLOUR,
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                lineHeight: "19px",
                position: "relative",
                top: "-15px",
                width: "100%",
              }}
            >
              Failed to validate Phone Number. Try again
            </FormLabel>
          )}
          <PhoneInput
            title="Re-enter Phone Number"
            disabled={applicationId}
            checkConfirmValidError={true}
            form={personalDetailsForm.confirmPhoneNumber}
            handleCopy={handleCopy}
            handlePaste={handlePaste}
            onChange={(value) => {
              updateFormField({ confirmPhoneNumber: value });
            }}
            updateFormField={updateFormField}
            isSenderPhoneValid={isSenderPhoneValid}
            isBeneficiaryPhoneValid={isBeneficiaryPhoneValid}
            fieldsFocused={fieldsFocused}
            setFieldsFocused={setFieldsFocused}
            propagateFormErrors={propagateFormErrors}
          />
          {personalDetailsForm.confirmPhoneNumber &&
            personalDetailsForm.confirmPhoneNumber !==
              personalDetailsForm.phoneNumber && (
              <FormLabel
                style={{
                  color: process.env.REACT_APP_RED_COLOUR,
                  fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                  lineHeight: "19px",
                  position: "relative",
                  top: "-15px",

                  width: "100%",
                }}
              >
                Phone number does not match
              </FormLabel>
            )}

          <InputText
            onBlur={() => {
              isEmailValid(personalDetailsForm) &&
                validateEmail(personalDetailsForm.email);
            }}
            disabled={applicationId}
            onChange={(e) => {
              updateFormField({ email: e.target.value.trim() });
            }}
            labelColor={
              !personalDetailsForm.email || isEmailValid(personalDetailsForm)
                ? "#3A3B3B"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !personalDetailsForm.email || isEmailValid(personalDetailsForm)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            type={"email"}
            value={personalDetailsForm.email}
            label={"Email address"}
            placeholder={"Enter  email adress"}
          />

          {isEmailLoading && (
            <IosRefresh
              style={{
                marginTop: "-52pt",
                marginLeft: "85%",
                width: "20px",
                position: "absolute",
              }}
              fontSize="60px"
              color="grey"
              rotate={true}
            />
          )}

          {isEmailValidAndNew() && !isEmailLoading ? (
            <MdCheckmarkCircle
              style={{
                marginTop: "-45pt",
                marginLeft: "85%",
                width: "20px",
                position: "absolute",
              }}
              color="green"
              fontSize="40px"
            />
          ) : (
            ""
          )}
          {!personalDetailsForm.email ||
          isEmailValid(personalDetailsForm) ||
          !isExisting ? (
            <React.Fragment />
          ) : (
            <FormLabel
              style={{
                color: process.env.REACT_APP_RED_COLOUR,
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                lineHeight: "19px",
                position: "relative",
                top: "-15px",
                width: "100%",
              }}
            >
              Email is not valid.
            </FormLabel>
          )}
          {isEmailExisting === USED_EMAIL && personalDetailsForm.email && (
            <FormLabel
              style={{
                color: process.env.REACT_APP_RED_COLOUR,
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                lineHeight: "19px",
                position: "relative",
                top: "-15px",

                width: "100%",
              }}
            >
              Email already registered on Quickteller Paypoint. Try another
              email!
            </FormLabel>
          )}
          {emailError && personalDetailsForm.email && (
            <FormLabel
              style={{
                color: process.env.REACT_APP_RED_COLOUR,
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                lineHeight: "19px",
                position: "relative",
                top: "-15px",
                width: "100%",
              }}
            >
              Failed to validate Email. Try again
            </FormLabel>
          )}
          <InputText
            disabled={applicationId}
            onChange={(e) => {
              updateFormField({ confirmEmail: e.target.value.trim() });
            }}
            // labelColor={
            //   !personalDetailsForm.email || isEmailValid(personalDetailsForm)
            //     ? '#3A3B3B'
            //     : process.env.REACT_APP_RED_COLOUR
            // }
            // border={`1px solid ${
            //   !personalDetailsForm.email || isEmailValid(personalDetailsForm)
            //     ? '#DFE2E6'
            //     : process.env.REACT_APP_RED_COLOUR
            // }`}
            type={"email"}
            handleCopy={handleCopy}
            handlePaste={handlePaste}
            value={personalDetailsForm.confirmEmail}
            label={"Re-enter Email address"}
            placeholder={"Re-enter  email adress"}
          />
          {personalDetailsForm.confirmEmail &&
            personalDetailsForm.confirmEmail !== personalDetailsForm.email && (
              <FormLabel
                style={{
                  color: process.env.REACT_APP_RED_COLOUR,
                  fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                  lineHeight: "19px",
                  position: "relative",
                  top: "-15px",

                  width: "100%",
                }}
              >
                Email address does not match
              </FormLabel>
            )}
          <SelectInput
            onChange={(e) => {
              updateFormField({ gender: e.target.value });
            }}
            placeholder={"gender"}
            value={personalDetailsForm.gender}
            option={gender}
            label={"Gender"}
            disabled={applicationId}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "1rem 0",
            }}
          >
            <DatePickerComponent
              onChange={(date) => {
                updateFormField({ dob: date });
              }}
              selected={personalDetailsForm.dob}
              label={"Date of Birth"}
              placeholder="DD-MM-YYYY"
              size={`100%`}
              format={`dd-MM-yyyy`}
              isClearable={true}
              showTimeInput={true}
              disabled={applicationId}
              // style={'0.4rem'}
              textStyle={"1.0rem"}
              iconStyle={"0.2rem"}
              dateType={"startDate"}
              preloadDate={false}
            />
          </div>
          {bvnResponse?.error === "Invalid Date of Birth" && (
            <div
              style={{
                color: process.env.REACT_APP_RED_COLOUR,
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,

                position: "relative",
                top: "-10px",
              }}
            >
              {bvnResponse.error}
            </div>
          )}
          <InputText
            loading={loading}
            disabled={applicationId}
            label={"BVN"}
            labelColor={
              !personalDetailsForm.bvn || isBvnValid(personalDetailsForm)
                ? "#3A3B3B"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !personalDetailsForm.bvn || isBvnValid(personalDetailsForm)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            placeholder={"BVN"}
            onChange={(e) => {
              updateFormField({ bvn: stripNonNumbers(e.target.value.trim()) });
            }}
            value={personalDetailsForm.bvn}
            maxLength={MAX_BVN_LENGTH}
          />
          {bvnResponse?.error === "Invalid Date of Birth" ? (
            ""
          ) : (
            <div
              style={{
                color: process.env.REACT_APP_RED_COLOUR,
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,

                position: "relative",
                top: "-10px",
              }}
            >
              {bvnResponse.error}
            </div>
          )}
          {bvnResponse?.success ? (
            <MdCheckmarkCircle
              style={{
                marginTop: "-45pt",
                marginLeft: "85%",
                width: "20px",
                position: "absolute",
              }}
              color="green"
              fontSize="40px"
            />
          ) : (
            ""
          )}
          {/* 
          {bvnResponse?.success && (
            <div
              style={{
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                color: '#32de84',

                position: 'relative',
                top: '-10px',
              }}
            >
              {bvnResponse.success}
            </div>
          )} */}
          {!bvnResponse?.error === "Invalid Date of Birth" && (
            <div
              style={{
                color: process.env.REACT_APP_RED_COLOUR,
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,

                position: "relative",
                top: "-10px",
              }}
            >
              {bvnResponse.error}
            </div>
          )}
          {applicationId ? (
            <PrimaryButton
              onClick={() => (jumbScreen ? setScreen(6) : setScreen(3))}
            >
              Next
            </PrimaryButton>
          ) : (
            <PrimaryButton
              disabled={!isFormValid() || isLoading}
              onClick={handleSubmit}
            >
              {" "}
              {isLoading ? "Processing..." : "Save & Continue"}
            </PrimaryButton>
          )}
        </Col>
      </main>
    </>
  );
};

export default PersonalDetailsScene;
