import React, { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import Title from "./title";
import Select from "react-select";
import Platform from "../../../../services/api/resources/platform";
import IosRefresh from "react-ionicons/lib/IosRefresh";
import BackButton from "../../onboarding-setup/component/backButton";
import PrimaryButton from "../../onboarding-setup/component/button";
import { isBusinessLgaValid } from "../../onboarding-setup/component/extras";
import InputText2 from "../../onboarding-setup/component/input2";
import { firetoast, notify } from "../../../../components/extras";
import FormLabel from "../../../../components/form-label";
import H1 from "../../../../components/h1";
import {
  ERROR_STATUS,
  SUCCESS_CODE,
  SUCCESS_STATUS,
} from "../../../../constants/api";
import Transaction from "../../../../services/api/resources/transaction";
import { RegexChecker } from "../../../../utils/regex-checker";
import { SelectBusinessType2 } from "../../onboarding-setup/component/stateSelect2";
import { asteriks } from "../../../../icons";

const MAX_BUSINESS_NAME_LENGTH = 50;
const BusinessDetailsScene = ({ setScreen, application }) => {
  const moveScreen = localStorage.getItem("moveScreen");
  const platform = new Platform();
  const upgradeTo = localStorage.getItem("upgradeTo");
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoadingBusinessType, setIsLoadingBusinessType] = useState(false);
  const [businessTypesData, setBusinessTypesData] = useState([]);
  const [banks, setBanks] = useState([]);
  const [accountName, setAccountName] = useState([]);
  const [accountNameDetails, setAccountNameDetails] = useState([]);
  const [loadingAccountName, setLoadingAccountName] = useState(false);
  const [loadingAccountBank, setLoadingAccountBank] = useState(false);
  const [enquiryStatus, setEnquiryStatus] = useState();

  const businessType = localStorage.getItem("businessType") || "";

  const currentUser = JSON.parse(localStorage.getItem("currentUser"));

  const businessTypeId = localStorage.getItem("businessTypeId") || "";

  const personalDetailsFormUpgrade = JSON.parse(
    localStorage.getItem("personalDetailsFormUpgrade")
  );

  const updatedbusinessDetailsForm = JSON.parse(
    localStorage.getItem("businessDetailsFormUpgrade")
  );
  const currentAgent = JSON.parse(localStorage.getItem("currentAgent"));

  const updatedbusinessType = localStorage.getItem("businessType");
  const updatedbank = localStorage.getItem("bank");

  const [businessDetailsForm, setBusinessDetailsForm] = useState({
    businessAddress: application
      ? // application?.businessLocation[0]?.addressLine1 ||
        updatedbusinessDetailsForm?.businessAddress
      : "",
    businessName: currentAgent
      ? currentAgent?.businessName || updatedbusinessDetailsForm?.businessName
      : "",
    state: application
      ? // application?.businessLocation[0]?.state ||
        updatedbusinessDetailsForm?.state
      : "",
    localGovernmentArea: application
      ? // application?.businessLocation[0]?.localGovernmentArea ||
        updatedbusinessDetailsForm?.localGovernmentArea
      : "",
    businessType: application ? application?.businessType || businessType : "",
    bank: { label: application?.bankName || updatedbank, value: "" },
    accountNumber: application
      ? application?.accountNo || updatedbusinessDetailsForm?.accountNumber
      : "",
  });

  localStorage.setItem(
    "businessDetailsFormUpgrade",
    JSON.stringify(businessDetailsForm)
  );

  const updateFormField = (params) => {
    if (params.state) {
      const newForm = {
        ...businessDetailsForm,
        localGovernmentArea: "",

        ...params,
      };
      setBusinessDetailsForm(newForm);
    } else {
      const newForm = {
        ...businessDetailsForm,

        ...params,
      };
      setBusinessDetailsForm(newForm);
    }
  };

  const isFormValid = () => {
    return (
      businessDetailsForm.businessType &&
      // businessDetailsForm.bank.label &&
      businessDetailsForm.accountNumber &&
      isBusinessLgaValid(businessDetailsForm) &&
      isAccountNumberComplete(businessDetailsForm)
    );
  };
  const transaction = new Transaction();

  const getBanks = async () => {
    setLoading(true);

    const { status, response } = await transaction.getUnifiedBanks();
    setLoading(false);

    if (status === ERROR_STATUS) {
    } else if (status === SUCCESS_STATUS) {
      setBanks(response);
      setLoadingAccountBank(true);
    }
  };

  useEffect(() => {
    getBanks();
  }, []);

  const validateAccountDetails = async (bank) => {
    setLoadingAccountName(true);
    const bankCode = localStorage.getItem("bankCode");
    const deviceUuid = localStorage.getItem("deviceUid");
    const accountNumber = updatedbusinessDetailsForm?.accountNumber;

    const nameInquiry = await transaction.nameInquiry(
      accountNumber,
      bankCode,
      deviceUuid
    );
    const nameInquiryStatus = nameInquiry.status;
    const nameInquiryResponse = nameInquiry.response;
    const code = nameInquiry.code;
    setEnquiryStatus(code);

    setAccountName(nameInquiryResponse);
    setLoadingAccountName(false);

    if (nameInquiryStatus === ERROR_STATUS) {
      const accountName = "";
      setAccountName(accountName);
      setAccountNameDetails(nameInquiryResponse);
      return;
    }
    const accountName = nameInquiryResponse.accountName;
  };

  useEffect(() => {
    validateAccountDetails();
  }, [banks]);

  const upgradeClass = () => {
    const upgradeToMap = {
      prestige: 3,
      Standard: 2,
    };

    return upgradeToMap[upgradeTo] || "";
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const payload = {
      newClassId: upgradeClass(),

      homeAddressStateId: personalDetailsFormUpgrade.state
        ? personalDetailsFormUpgrade.state
        : "",
      homeAddressLgaId: personalDetailsFormUpgrade.localGovernmentArea
        ? personalDetailsFormUpgrade.localGovernmentArea
        : "",

      motherMaidenName: personalDetailsFormUpgrade.motherMaidenName,
      businessTypeId: application?.businessType || +businessTypeId,
      bank: businessDetailsForm.bank.label,
      accountNumber: businessDetailsForm.accountNumber,
    };

    const accessToken = localStorage.getItem("auth_token");
    const agentCode = currentUser.domainCode;
    const { status, response } = await platform.initializeUpgradeRequest(
      payload,
      agentCode,
      accessToken
    );
    setIsLoading(false);
    if (status === ERROR_STATUS) {
      firetoast("error", `${response.description}`, "");
    } else if (status === SUCCESS_STATUS) {
      notify("Saved", "success");
      moveScreen ? setScreen(6) : setScreen(4);
    }
    moveScreen ? setScreen(6) : setScreen(4);
  };

  const customStylesBanks = {
    option: (provided, state) => ({
      ...provided,
      fontSize: 14,
    }),
    control: (base) => ({
      ...base,
      minHeight: 48,
      backgroundColor: "transparent",
    }),
    singleValue: (provided, state) => {
      const fontSize = 14;
      return { ...provided, fontSize };
    },
  };

  const isAccountNumberComplete = () => {
    return RegexChecker.accountNumber.test(businessDetailsForm?.accountNumber);
  };

  const fetchBusinessTypes = async () => {
    setIsLoadingBusinessType(true);
    const tierId = 2;
    const { status, response } = await platform.fetchStandardBusinessTypes(
      tierId
    );
    setIsLoadingBusinessType(false);

    if (status === ERROR_STATUS) {
    } else if (status === SUCCESS_STATUS) {
      setBusinessTypesData(response);
    }
  };

  useEffect(() => {
    fetchBusinessTypes();
  }, []);

  return (
    <>
      <BackButton
        onClick={() => {
          setScreen(2);
          // localStorage.removeItem('businessDetailsFormUpgrade');
          // localStorage.removeItem('businessType');
        }}
      />
      <main className="aggregator-container">
        <Col
          style={{
            marginBottom: "20px",
            margin: "0 auto",
          }}
        >
          <Title upgradeTo={upgradeTo} />
          <H1
            style={{
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "1rem",
            }}
          >
            Business Details
          </H1>

          <SelectBusinessType2
            border={`1px solid ${
              isBusinessLgaValid(localStorage.getItem("businessType")) &&
              !businessDetailsForm.businessType
                ? process.env.REACT_APP_RED_COLOUR
                : "#DFE2E6"
            }`}
            label={"Business Type"}
            option={businessTypesData}
            value={businessDetailsForm?.businessType}
            getBusinessType={localStorage.getItem("businessType")}
            placeholder={"Business Type"}
            onChange={(e) => {
              localStorage.setItem(
                "businessType",
                businessTypesData.find(
                  (value) => value.business_type === e.target.value
                ).business_type
              );
              localStorage.setItem(
                "businessTypeId",
                businessTypesData.find(
                  (id) => id.business_type === e.target.value
                ).id
              );
              updateFormField({
                businessType: businessTypesData.find(
                  (value) => value.business_type === e.target.value
                ).id,
              });
            }}
          />
          {isLoadingBusinessType && (
            <IosRefresh
              style={{
                marginTop: "-52pt",
                marginLeft: "85%",
                width: "20px",
                position: "absolute",
              }}
              fontSize="60px"
              color="grey"
              rotate={true}
            />
          )}

          <InputText2
            label={"Business Name"}
            onChange={(e) => {
              updateFormField({ businessName: e.target.value });
            }}
            disabled={true}
            placeholder={"Enter Business Name"}
            value={businessDetailsForm.businessName}
            maxLength={MAX_BUSINESS_NAME_LENGTH}
          />

          <InputText2
            label={"Bank Account Number"}
            placeholder={"Enter Account Number"}
            onChange={(e) => {
              const inputValue = e.target.value;
              const numericValue = inputValue.replace(/\D/g, "");

              updateFormField({ accountNumber: numericValue });
            }}
            labelColor={
              !businessDetailsForm.accountNumber ||
              isAccountNumberComplete(businessDetailsForm)
                ? "#3A3B3B"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !businessDetailsForm.accountNumber ||
              isAccountNumberComplete(businessDetailsForm)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            maxLength={10}
            value={businessDetailsForm.accountNumber}
          />

          <Form.Group
            style={{
              padding: 0,
              marginTop: "15px",
            }}
          >
            <FormLabel
              style={{
                color: "#3A3B3B",
                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                lineHeight: "19px",
                fontWeight: "600",
              }}
            >
              Bank Name <span>{asteriks()}</span>
            </FormLabel>
            <Select
              options={banks?.map((bank) => {
                return { label: bank.bankName, value: bank.cbnCode };
              })}
              defaultValue={
                businessDetailsForm?.bank.label && {
                  label: businessDetailsForm?.bank.label,
                }
              }
              isLoading={loading}
              onChange={(event) => {
                const bank = event;
                localStorage.setItem("bank", event.label);
                localStorage.setItem("bankCode", event.value);
                updateFormField({ bank: bank });
                businessDetailsForm.accountNumber &&
                  validateAccountDetails(bank);
              }}
              styles={customStylesBanks}
              className="select-input-container-banks"
              classNamePrefix="react-select"
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "rgb(178, 212, 255)",
                },
              })}
            />
          </Form.Group>

          <InputText2
            label={"Account Name"}
            // placeholder={'Enter Account Number'}
            disabled={true}
            value={accountName?.accountName}
          />
          {loadingAccountName && loadingAccountBank && (
            <IosRefresh
              style={{
                marginTop: "-52pt",
                marginLeft: "85%",
                width: "20px",
                position: "absolute",
              }}
              fontSize="60px"
              color="grey"
              rotate={true}
            />
          )}
          {/* {enquiryStatus === SUCCESS_CODE ? (
            <div
              style={{
                color: "#32de84",
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,

                position: "relative",
                top: "-10px",
              }}
            >
              {accountNameDetails.description}
            </div>
          ) : (
            <div
              style={{
                color: process.env.REACT_APP_RED_COLOUR,
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,

                position: "relative",
                top: "-10px",
              }}
            >
              {accountNameDetails.description}
            </div>
          )
          } */}

          <PrimaryButton
            disabled={!isFormValid() || isLoading}
            onClick={handleSubmit}
          >
            {isLoading ? "Processing..." : "Proceed"}
          </PrimaryButton>
        </Col>
      </main>
    </>
  );
};

export default BusinessDetailsScene;
