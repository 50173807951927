import React, { useState } from "react";
import {
  convertNgkToNgn,
  convertNgnToNgk,
} from "../../../../../../../utils/converters/currencies";
import {
  formatAmount,
  refineDistributeData,
} from "../../../../../../../utils/formatters";
import PrimaryButton from "../../../../aggregator-module/component/button";
import { SUCCESS_STATUS } from "../../../../../../../constants/api";
import Liquidity from "../../../../../../../services/api/resources/liquidity";
import { firetoast } from "../../../../../../../components/extras";

const CompleteDistributeForm = ({
  responseDetails,
  setResponse,
  setActiveScreen,
}) => {
  const liquidity = new Liquidity();
  const [isLoading, setIsLoading] = useState(false);

  const totalAmount = formatAmount(
    convertNgkToNgn(
      +responseDetails?.w2WRequestList[0].amount + +responseDetails?.fee
    )
  );

  const onDistributeConfirmation = async () => {
    setIsLoading(true);
    const { status, response } = await liquidity.processDistribute(
      responseDetails,
      localStorage.getItem("deviceUid")
    );
    setIsLoading(false);
    if (status === SUCCESS_STATUS) {
      setResponse(response);
      setActiveScreen(3);
      localStorage.removeItem("agentDetailsFromPermanceBalance");
    } else {
      firetoast("error", responseDetails.description, "");
    }
  };

  return (
    <section>
      <div className="unloadBalanceCard">
        <div>
          <div className="details-container">
            <p className="text1">Agent</p>
            <p className="text2">
              {responseDetails?.w2WRequestList[0].beneficiaryName}
            </p>
          </div>
          <div className="details-container">
            <p className="text1">Phone Number</p>
            <p className="text2">
              {responseDetails?.w2WRequestList[0].beneficiaryPhone}
            </p>
          </div>
          <div className="details-container">
            <p className="text1">Amount (₦)</p>
            <p className="text2">
              ₦
              {formatAmount(
                convertNgkToNgn(responseDetails?.w2WRequestList[0].amount)
              )}
            </p>
          </div>
        </div>
        <hr />
        <div>
          <div className="details-container">
            <p className="text1">Fee</p>
            <p className="text2">
              ₦{formatAmount(convertNgkToNgn(responseDetails?.fee))}
            </p>
          </div>
          <div className="details-container">
            <p className="text1">Total Amount</p>
            <p className="text2">₦{totalAmount}</p>
          </div>
        </div>
      </div>
      <div
        style={{
          margin: "-30px 0",
        }}
      >
        <PrimaryButton disabled={isLoading} onClick={onDistributeConfirmation}>
          {isLoading ? "Processing..." : "Pay"}
        </PrimaryButton>
      </div>
    </section>
  );
};

export default CompleteDistributeForm;
