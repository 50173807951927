import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import FormControl from "../../../../../components/form-control";
import H1 from "../../../../../components/h1";
import Thumbnail2 from "../../../../../components/thumbnail-2";
import { PASSPORT_PHOTO, PROOF_OF_ID } from "../../../../../constants";
import Countries from "../../../../../fixtures/countries";
import {
  manageProfileDateFormatter
} from "../../../../../utils/formatters";
import BaseScene from "../../../../base-scene";

export default class PersonalDetailsForm extends BaseScene {
  constructor(props) {
    super(props);

    this.state = {
      form: {},
    };
  }

  componentDidMount() {
    const newForm = {};

    this.updateFormField(newForm);
  }

  updateFormField(params) {
    const newForm = {
      ...this.state.form,
      ...params,
    };

    this.setState({
      form: newForm,
    });
  }

  get documentsToAttach() {
    return [PROOF_OF_ID, PASSPORT_PHOTO];
  }

  getDocumentForThumbnail(attachedDocuments, filename) {
    return attachedDocuments.find((value) => {
      const name = value.filename ? value.filename : value.documentType;
      return name.includes(filename);
    });
  }

  render() {
    const { form } = this.props;
    // const screenSensitiveStyle = !this.state.deviceIsMobile ? {
    //   // width: '50%',
    //   // marginLeft: '25%',
    //   // marginTop: '50px'
    // } : { }
    return (
      <Form>
        <div>
          <H1
            ref={this.props.formHeaderRef}
            style={{
              color: "#3A3B3B",
              fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
              fontSize: "24px",
              fontWeight: "400",
              padding: "35px",
              paddingLeft: 0,
              textAlign: "left",
            }}
          >
            Personal Details
          </H1>

          <Row>
            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label
                  style={{
                    color: "#3A3B3B",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  First Name:
                </Form.Label>
                <FormControl
                  type="text"
                  placeholder="Your first name"
                  onChange={(event) => {
                    const firstName = event.target.value;
                    this.updateFormField({ firstName });
                  }}
                  style={{
                    backgroundColor: "#E1E6ED",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.firstName || this.isFirstNameValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                  }}
                  value={form.firstName}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label
                  style={{
                    color: "#3A3B3B",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  Middle Name:
                </Form.Label>
                <FormControl
                  type="text"
                  placeholder="Your middle name"
                  onChange={(event) => {
                    const middleName = event.target.value;
                    this.updateFormField({ middleName });
                  }}
                  style={{
                    backgroundColor: "#E1E6ED",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.lastName || this.isLastNameValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                  }}
                  value={form.middleName}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label
                  style={{
                    color: "#3A3B3B",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  Last Name:
                </Form.Label>
                <FormControl
                  type="text"
                  placeholder="Last Name"
                  onChange={(event) => {
                    const lastName = event.target.value;
                    this.updateFormField({ lastName });
                  }}
                  style={{
                    backgroundColor: "#E1E6ED",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.firstName || this.isFirstNameValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                  }}
                  value={form.lastName}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label
                  style={{
                    color: "#3A3B3B",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  Phone Number:
                </Form.Label>
                <FormControl
                  type="tel"
                  placeholder="08012345678"
                  onChange={(event) => {
                    const phoneNumber = event.target.value;
                    this.updateFormField({ phoneNumber });
                  }}
                  style={{
                    backgroundColor: "#E1E6ED",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.lastName || this.isLastNameValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                  }}
                  value={form.phoneNumber}
                  disabled
                />
                {/* <img
                  style={{
                    height: "24px",
                    width: "24px",
                    opacity: "1",
                    marginLeft: "89%",
                    marginTop: "-54pt",
                    position: "relative",
                    zIndex: "2",
                  }}
                  src={
                    this.props.currentUser &&
                    this.props.currentUser.mobileVerified &&
                    "/assets/media/icons/icons8-ok.svg"
                  }
                  alt=""
                /> */}
              </Form.Group>
            </Col>

            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label
                  style={{
                    color: "#3A3B3B",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  Email Address:
                </Form.Label>
                <FormControl
                  type="email"
                  placeholder="john@example.com"
                  onChange={(event) => {
                    const personalEmail = event.target.value;
                    this.updateFormField({ personalEmail });
                  }}
                  style={{
                    backgroundColor: "#E1E6ED",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.lastName || this.isLastNameValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                  }}
                  value={form.personalEmail}
                  disabled
                />
              </Form.Group>
            </Col>
            
            {/* {form.personalEmail && (
              <Col
                style={{ marginTop: "50px" }}
                xs={12}
                md={4}
                hidden={
                  this.props.currentUser && this.props.currentUser.emailVerified
                }
              >
                <Link onClick={() => this.props.resendEmail()}>
                  Verify Email
                </Link>
              </Col>
            )} */}

<Col xs={12} md={4}>
              <Form.Group>
                <Form.Label
                  style={{
                    color: "#3A3B3B",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  Gender:
                </Form.Label>
                <FormControl
                  type="text"
                  placeholder="Last Name"
                  onChange={(event) => {
                    const lastName = event.target.value;
                    this.updateFormField({ lastName });
                  }}
                  style={{
                    backgroundColor: "#E1E6ED",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.firstName || this.isFirstNameValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                  }}
                  value={form.gender}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label
                  style={{
                    color: "#3A3B3B",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  Date of Birth:
                </Form.Label>
                <FormControl
                  type="text"
                  placeholder="Last Name"
                  onChange={(event) => {
                    const lastName = event.target.value;
                    this.updateFormField({ lastName });
                  }}
                  style={{
                    backgroundColor: "#E1E6ED",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.firstName || this.isFirstNameValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                  }}
                  value={form.dob}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label
                  style={{
                    color: "#3A3B3B",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  Place of Birth:
                </Form.Label>
                <FormControl
                  type="text"
                  placeholder="Place of Birth"
                  onChange={(event) => {
                    const lastName = event.target.value;
                    this.updateFormField({ lastName });
                  }}
                  style={{
                    backgroundColor: "#E1E6ED",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.firstName || this.isFirstNameValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                  }}
                  value={form.pob}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label
                  style={{
                    color: "#3A3B3B",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  State of Origin:
                </Form.Label>
                <FormControl
                  type="text"
                  placeholder="State of Origin"
                  onChange={(event) => {
                    const lastName = event.target.value;
                    this.updateFormField({ lastName });
                  }}
                  style={{
                    backgroundColor: "#E1E6ED",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.firstName || this.isFirstNameValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                  }}
                  value={form.stateOfOrigin}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label
                  style={{
                    color: "#3A3B3B",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  BVN:
                </Form.Label>
                <FormControl
                  type="text"
                  placeholder="BVN"
                  onChange={(event) => {
                    const lastName = event.target.value;
                    this.updateFormField({ lastName });
                  }}
                  style={{
                    backgroundColor: "#E1E6ED",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.firstName || this.isFirstNameValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                  }}
                  value={form.bvn}
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>
        </div>

        <div>
          <H1
            ref={this.props.formHeaderRef}
            style={{
              color: "#3A3B3B",
              fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
              fontSize: "24px",
              fontWeight: "400",
              padding: "35px",
              paddingLeft: 0,
              textAlign: "left",
            }}
          >
            Residential Address
          </H1>

          <Row>
            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label
                  style={{
                    color: "#3A3B3B",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  Country:
                </Form.Label>
                <FormControl
                  // as="select"
                  type="text"
                  placeholder="Country"
                  onChange={(event) => {
                    const country = event.target.value;
                    this.updateFormField({ country });
                  }}
                  style={{
                    backgroundColor: "#E1E6ED",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.lastName || this.isLastNameValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                  }}
                  value={form.country}
                  disabled
                ></FormControl>
              </Form.Group>
            </Col>

            <Col xs={12} md={4}>
              <Form.Group controlId="exampleFormControlSelect2">
                <Form.Label
                  style={{
                    color: "#3A3B3B",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  State
                </Form.Label>
                <FormControl
                type="text"
                placeholder="State"
                  onChange={(event) => {
                    const state = event.target.value;
                    this.updateFormField({ state });
                  }}
                  style={{
                    backgroundColor: "#E1E6ED",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.lastName || this.isLastNameValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                  }}
                  value={form.state}
                  disabled
                ></FormControl>
              </Form.Group>
            </Col>

            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label
                  style={{
                    color: "#3A3B3B",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  LGA:
                </Form.Label>
                <FormControl
                  type="text"
                  placeholder="Town"
                  onChange={(event) => {
                    const town = event.target.value;
                    this.updateFormField({ town });
                  }}
                  style={{
                    backgroundColor: "#E1E6ED",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.firstName || this.isFirstNameValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                  }}
                  value={form.town}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label
                  style={{
                    color: "#3A3B3B",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  Address:
                </Form.Label>
                <FormControl
                  type="text"
                  placeholder="Address"
                  onChange={(event) => {
                    const address = event.target.value;
                    this.updateFormField({ address });
                  }}
                  style={{
                    backgroundColor: "#E1E6ED",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.lastName || this.isLastNameValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                  }}
                  value={form.address}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={4}>
              <Form.Group controlId="exampleFormControlSelect2">
                <Form.Label
                  style={{
                    color: "#3A3B3B",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  Closest Landmark
                </Form.Label>
                <FormControl
                type="text"
                placeholder="Closest Landmark"
                  onChange={(event) => {
                    const closestLandmark = event.target.value;
                    this.updateFormField({ closestLandmark });
                  }}
                  style={{
                    backgroundColor: "#E1E6ED",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.lastName || this.isLastNameValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                  }}
                  value={form.closestLandmark}
                  disabled
                ></FormControl>
              </Form.Group>
            </Col>

            <Col xs={12} md={4}>
              {/* <Form.Group>
              <Form.Label style={{color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Postal code:</Form.Label>
              <FormControl
                type="tel"
                placeholder="Postal code"
                onChange={(event) => {
                const firstName = event.target.value
                this.updateFormField({firstName})}}
                style={{backgroundColor: '#FFFFFF', borderRadius: '20px', border: `1px solid ${!this.state.firstName || this.isFirstNameValid() ? '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`, boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px'}}
                
              />
            </Form.Group> */}
            </Col>
          </Row>
        </div>

        {/* <center style={{marginTop: '10px'}}>
        <Button onClick={() => {
          this.props.onSubmit()
        }}>Next</Button>
      </center> */}
      </Form>
    );
  }
}
