import React from "react";
import HeaderSignup from "../../../../../../components/header-signup";
import BackButton from "../../../../../signup/onboarding-setup/component/backButton";
import PrimaryButton from "../../../../../signup/onboarding-setup/component/button";

const BookingScene = ({ setScreen }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          gap: "40px",
        }}
      >
        <HeaderSignup />
        <BackButton onClick={() => setScreen(1)} />
      </div>
      <p>BookingScene</p>
      <PrimaryButton
        // disabled={!isFormValid() || isLoading}
        // onClick={handleSubmit}
        onClick={() => setScreen(3)}
      >
        {"Next"}
      </PrimaryButton>
    </>
  );
};

export default BookingScene;