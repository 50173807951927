import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { ERROR_STATUS, SUCCESS_STATUS } from '../../../../../../constants/api';
import TransactionHistory from '../../../../../../services/api/resources/transaction-history';
import { getPaginationRows } from '../../../../../../utils/helpers';

import {
  formatAmount,
  formatDateTime,
  handleStatusFormat,
  handleTransactionTypeFormat,
  specialDateRangeFormatter_StartDate,
} from '../../../../../../utils/formatters';
import { convertNgkToNgn } from '../../../../../../utils/converters/currencies';
import Spinner from '../../../../../../components/spinner';
import Pagination from '../../../../../../components/pagination';

const TransactionTable = ({
  agentDetails,
  endDate,
  startDate,
  dateIntervalType,
}) => {
  const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
  const [loading, setLoading] = useState(true);

  const [transactions, setTransaction] = useState([]);
  const transactionHistory = new TransactionHistory();

  const [pages, setPages] = useState(1);
  const [pageNum, setPageNum] = useState(1);
  const [dataCount, setDataCount] = useState();
  const [pageSize, setPageSize] = useState(20);

  let payload = {};
  const getTransactionHistory = async (
    pageSize_,
    pageNum_,
    endDate,
    startDate,
    dateIntervalType
  ) => {
    setTransaction([]);
    setLoading(true);

    if (dateIntervalType) {
      payload = {
        pageSize: pageSize_,
        pageNum: pageNum_,
        mobile: agentDetails.agentMobileNo,
        dateIntervalType: dateIntervalType,
      };
    } else {
      payload = {
        pageSize: pageSize_,
        pageNum: pageNum_,
        mobile: agentDetails.agentMobileNo,
        from: specialDateRangeFormatter_StartDate(startDate),
        to: specialDateRangeFormatter_StartDate(endDate),
      };
    }

    const { response, status } =
      await transactionHistory.getTransactionsForSearch(payload);

    setLoading(false);
    if (status === ERROR_STATUS) {
      setPages(1);
    } else if (status === SUCCESS_STATUS) {
      setDataCount(response.count / pageSize);
      setPages(Math.ceil(response.count / pageSize));
      setTransaction(response.content);
    }
  };

  useEffect(() => {
    getTransactionHistory(
      pageSize,
      pageNum,
      endDate,
      startDate,
      dateIntervalType,
      agentDetails.agentMobileNo
    );
  }, [endDate, dateIntervalType]);

  const transactionHistoryColumns = [
    {
      Header: (props) => <div style={{ textAlign: 'left' }}>Trans Ref</div>,
      Cell: (props) => (
        <div
          className='wordbreak'
          style={{ textAlign: 'left', wordBreak: 'break-all' }}
        >
          {props.value}
        </div>
      ),
      accessor: 'transactionRef',
      headerClassName: 'table-header',
      className: 'table-cell',
      style: { 'white-space': 'unset' },
      width: 200,
    },
    {
      Header: (props) => (
        <div style={{ textAlign: 'left' }}>Transaction Type</div>
      ),
      accessor: 'transactionTypeInt',
      Cell: (props) => (
        <div style={{ textAlign: 'left' }}>
          {handleTransactionTypeFormat(props.value)}
        </div>
      ),
      //   width: 300,
      headerClassName: 'table-header',
      className: 'table-cell',
      style: { 'white-space': 'unset' },
    },

    {
      Header: (props) => <div style={{ textAlign: 'left' }}>Description</div>,
      accessor: 'narration',
      Cell: (props) => <div style={{ textAlign: 'left' }}>{props.value}</div>,
      headerClassName: 'table-header',
      className: 'table-cell',
      width: 300,

      style: { 'white-space': 'unset' },
    },

    {
      Header: (props) => <div style={{ textAlign: 'left' }}>Amount</div>,
      accessor: 'transactionAmount',
      Cell: (props) => (
        <div className='number' style={{ textAlign: 'left' }}>{`₦${formatAmount(
          convertNgkToNgn(props.value)
        )}`}</div>
      ),
      headerClassName: 'table-header',
      className: 'table-cell',
      width: 200,
      style: { 'white-space': 'unset' },
    },
    {
      Header: (props) => <React.Fragment>Date</React.Fragment>,
      accessor: 'dateCreated',
      Cell: (props) => (
        <div style={{ textAlign: 'left' }} className='number'>
          {formatDateTime(props.value)}
        </div>
      ),
      headerClassName: 'table-header',
      className: 'table-cell',
      style: { 'white-space': 'unset' },
      //   width: 250,
    },
    {
      Header: (props) => <div style={{ textAlign: 'left' }}>Status</div>,
      accessor: 'statusCode',
      Cell: (props) => (
        <div
          className={`${props.value.toLowerCase()}-status`}
          style={{ textAlign: 'left' }}
        >
          {handleStatusFormat(props.value)}
        </div>
      ),
      headerClassName: 'table-header',
      className: 'table-cell',
      style: { 'white-space': 'unset' },
      width: 150,
    },
  ];

  return (
    <div>
      <ReactTable
        columns={transactionHistoryColumns}
        data={transactions}
        dataCount={dataCount}
        pageSizes={getPaginationRows(dataCount)}
        PaginationComponent={Pagination}
        pages={pages}
        manual
        minRows={transactions.length > 0 ? 10 : 10}
        onFetchData={(state, instance) => {
          getTransactionHistory(
            state.pageSize,
            state.page + 1,
            endDate,
            startDate,
            dateIntervalType,
            agentDetails.agentMobileNo
          );
        }}
        loading={loading}
        noDataText={
          loading
            ? ''
            : `No transaction(s) found for ${agentDetails.accountName}`
        }
        LoadingComponent={Spinner}
        getPaginationProps={(state, rowInfo, column, instance) => ({
          style: {
            overflow: 'visible',
            backgroundColor: '#ffffff',
          },
        })}
      />
    </div>
  );
};

export default TransactionTable;
