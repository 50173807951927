import React, { createRef } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Alert, Carousel, Col, Container, Form, Row } from "react-bootstrap";
import qs from "query-string";

// Services
import { stopwatch, userManagementServiceClient } from "../../App";
import ApiGateway from "../../services/api/resources/api-gateway";
import Onboarding from "../../services/api/resources/onboarding";
import Passport from "../../services/api/resources/passport";
import Platform from "../../services/api/resources/platform";

// Components
import Button from "../../components/button";
import Footer from "../../components/footer";
import FormControl from "../../components/form-control";
import FormLabel from "../../components/form-label";
import H1 from "../../components/h1";
import Header from "../../components/header";
import HR from "../../components/hr";
import Text from "../../components/text";
import { notify } from "../../components/extras";

// Constants
import { CURRENT_USER, NIGERIA_PHONE_PREFIX } from "../../constants";
import {
  LOGIN_CLICK,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
} from "../../constants/analytics";
import {
  CHANGE_PASSWORD_CODE,
  DEVICE_IN_USE_CODE,
  ERROR_STATUS,
  EXCEED_DEVICE_LIMIT_CODE,
  GUEST,
  HTTP_CONFLICT_CODE,
  NEW_DEVICE_CODE,
  OTP_CODE,
  RESET_PASSWORD_CODE,
  SELECT_USER_PROFILE,
  SUCCESS_CODE,
  SUCCESS_STATUS,
  VERIFY_PHONE_NUMBER_CODE,
} from "../../constants/api";
import {
  AUTO_LOGIN_FAILED,
  CANNOT_ACCESS_DASHBOARD,
  CONFLICT_USER,
  DEVICE_IN_USE,
  EMAIL_NOT_VERIFIED,
  EMAIL_VERIFIED,
  EXCEED_DEVICE_LIMIT,
  NEW_DEVICE,
} from "../../constants/cases";

// Utils
import { logEvent } from "../../core/logger";
import {
  dismissErrorMessage,
  dismissSuccessMessage,
  showErrorMessage,
  showSuccessMessage,
  updateLoading,
} from "../../services/redux/actions/tunnel";
import {
  onNewSessionBegin,
  saveAuthToken,
  saveRefreshToken,
} from "../../utils/auth";
import {
  formatDomainTypeId,
  formatEmailOrPhone,
  formatPhoneNumber,
  formatPhoneNumberWithCountryCode,
} from "../../utils/formatters";
import { getDeviceInformation } from "../../utils/helpers";
import { saveData } from "../../utils/storage";
import { RECOVER_PASSWORD_REDIRECT_URL } from "../../utils/api-properties";
import {
  isDeviceNameValid,
  isEmailValid,
  isOtpValid,
  isPasswordValid,
  isPhoneValid,
} from "../../utils/validators/form-validators";

// Scenes
import BaseScene from "../base-scene";
import "./styles.scss";

class Login extends BaseScene {
  location = window.location.href;
  path = "/login";
  title = "Login";

  isAuthRequired = false;
  requiresEmptySession = true;

  apiGateway = new ApiGateway();
  onboarding = new Onboarding();
  passport = new Passport();
  platform = new Platform();

  passwordRef = createRef(null);
  setPasswordRef = createRef(null);

  constructor(props) {
    super(props);

    this.callDeviceAuth = this.callDeviceAuth.bind(this);
    this.callLogin = this.callLogin.bind(this);
    this.callNewLogin = this.callNewLogin.bind(this);
    this.callGetUserProfile = this.callGetUserProfile.bind(this);
    this.callResendOtp = this.callResendOtp.bind(this);
    this.callVerifyOtp = this.callVerifyOtp.bind(this);
    this.callResendOtpGuest = this.callResendOtpGuest.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.onSuccessfulLogin = this.onSuccessfulLogin.bind(this);
    this.resetPassword = this.resetPassword.bind(this);

    const queryParams = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });

    this.state = {
      doDeviceAuth: false,
      isUserGuest: false,
      showOtpField: false,
      isUserAgent: false,
      error: false,
      verifyUserMobile: false,
      changePassword: false,
      firstName: queryParams.firstName,
      userProfiles: {},
      fieldsFocused: [],
      form: {
        email: null,
        emailOrPhone: null,
        phone: null,
      },
      hidePassword: true,
      hideConfirmPassword: true,
      hideNewPassword: true,
      otpForm: {
        registerDevice: false,
      },
      guestForm: {
        verifyDetails: false,
        otp: "",
        domainCode: "app",
      },
      changePasswordForm: {},
      resetPasswordForm: {},
      setPasswordForm: {},
      redirectToDashboard: false,
      case: queryParams.case,
    };
  }

  componentDidMount() {
    if (window.location.search) {
      const queryStringObject = new URLSearchParams(window.location.search);
      const message = queryStringObject.get("message");
      const isEmailVerified = queryStringObject.get("isEmailVerified");

      if (message) {
        this.setState({ message });
      }

      if (isEmailVerified) {
        this.handleEmailVerification(isEmailVerified);
      }

      const currentUrl = window.location.href.split("?");
      const urlObj = { Title: "login", Url: currentUrl[0] };
      window.history.replaceState(urlObj, urlObj.Title, urlObj.Url);
    }
  }

  handleEmailVerification(isEmailVerified) {
    this.setState({
      case:
        isEmailVerified === "true" ? "EMAIL_VERIFIED" : "EMAIL_NOT_VERIFIED",
    });
  }

  backToLogin() {
    this.setState({
      doDeviceAuth: false,
      isUserGuest: false,
      showUserProfile: false,
    });
  }

  canRegisterDevice(loginResponseBody) {
    return !(
      loginResponseBody.code === DEVICE_IN_USE_CODE ||
      loginResponseBody.code === EXCEED_DEVICE_LIMIT_CODE
    );
  }

  getLoginCase(loginResponseBody) {
    switch (loginResponseBody.code) {
      case NEW_DEVICE_CODE:
        return "NEW_DEVICE";
      case DEVICE_IN_USE_CODE:
        return "DEVICE_IN_USE";
      case EXCEED_DEVICE_LIMIT_CODE:
        return "EXCEED_DEVICE_LIMIT";
      default:
        return "";
    }
  }

  isOtpRequired(loginResponseBody) {
    return (
      loginResponseBody.data.migratedUser &&
      !loginResponseBody.data.migrationCompleted
    );
  }

  async callRecoverPassword(username) {
    const redirectUrl = RECOVER_PASSWORD_REDIRECT_URL;
    const recoverPasswordResponse =
      await userManagementServiceClient.recoverPassword(
        username,
        redirectUrl,
        this.state.authenticatedAs
      );

    this.setState({ uuid: recoverPasswordResponse.response.uuid });

    if (recoverPasswordResponse.status === ERROR_STATUS) {
      notify(recoverPasswordResponse.response.description, "error");
    } else {
      notify("Successful", "success");
      this.setState({ setPassword: true, isLoading: false });
    }

    this.props.updateLoading(false);
  }

  async resetPassword() {
    this.props.updateLoading(67);

    const { resetPasswordForm, username, uuid } = this.state;
    const { status, response } =
      await userManagementServiceClient.resetPassword(
        resetPasswordForm.newPassword,
        resetPasswordForm.confirmPassword,
        uuid,
        username,
        username,
        resetPasswordForm.otp
      );
    this.props.updateLoading(false);

    if (status === SUCCESS_STATUS) {
      this.props.showSuccessMessage("Password reset successfully");
      setTimeout(() => {
        this.props.dismissSuccessMessage();
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
      return;
    }

    this.props.showErrorMessage(JSON.stringify(response));
    setTimeout(() => {
      this.props.dismissErrorMessage();
    }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
  }

  async resetPasswordPhone(e) {
    e.preventDefault();
    const { setPasswordForm, uuid } = this.state;
    this.props.updateLoading(true, 66);

    const setPasswordResponse =
      await userManagementServiceClient.resetPasswordPhone(
        setPasswordForm.password,
        setPasswordForm.confirmPassword,
        uuid,
        setPasswordForm.otp
      );

    const setPasswordResponseStatus = setPasswordResponse.status;
    this.props.updateLoading(false);

    if (setPasswordResponseStatus === ERROR_STATUS) {
      notify(setPasswordResponse.response.errors[0].message, "error");
    } else {
      notify("Successful", "success");
      this.setState({ showUserProfile: true });
    }
  }

  async callGetUserProfile() {
    const { emailOrPhone } = this.state.form;

    logEvent(LOGIN_CLICK);
    this.props.updateLoading(true, 25);

    stopwatch.start();
    try {
      const { response } = await userManagementServiceClient.getUserProfile(
        formatEmailOrPhone(emailOrPhone)
      );

      if (response?.code !== SELECT_USER_PROFILE) {
        notify(response.description, "error");
        this.props.updateLoading(false);
        return;
      }

      if (response?.data?.userDomainTypes[0].shouldResetPassword === false) {
        this.setState({
          userProfiles: response?.data,
          showUserProfile: true,
        });
      } else {
        this.setState({
          userProfiles: response?.data,
          setPassword: true,
          authenticatedAs: response?.data?.userDomainTypes[0].authenticatedAs,
        });
        await this.callRecoverPassword(emailOrPhone);
      }

      this.props.updateLoading(false);
    } catch (error) {
      notify(error.message, "error");
      this.props.updateLoading(false);
    }
  }

  getUsername = (userProfiles, selectedProfile) => {
    const { emailOrPhone } = this.state.form;

    if (userProfiles.count === 0) return emailOrPhone;
    if (userProfiles.count < 2) return userProfiles.userDomainTypes[0].username;

    if (!selectedProfile) {
      const filteredUser = userProfiles.userDomainTypes.filter(
        (users) => !users.fipUser
      );
      return filteredUser[0].username;
    }

    return selectedProfile.username;
  };

  getDomainTypeId = (userProfiles, selectedProfile) => {
    if (userProfiles.count === 0) return null;
    if (userProfiles.count < 2)
      return userProfiles.userDomainTypes[0].domainTypeId;

    if (!selectedProfile) {
      const filteredUser = userProfiles.userDomainTypes.filter(
        (users) => !users.fipUser
      );
      return filteredUser[0].domainTypeId;
    }

    return selectedProfile.domainTypeId;
  };

  async callLogin() {
    return this.handleLoginCall(false);
  }

  async callNewLogin() {
    return this.handleLoginCall(true);
  }

  async handleLoginCall(isNewLogin) {
    const {
      userProfiles,
      form: { selectedProfile, password },
    } = this.state;

    logEvent(LOGIN_CLICK);
    this.props.updateLoading(true, 25);

    const deviceInformation = getDeviceInformation();
    deviceInformation.deviceOs = this.deviceFingerprint[16].value;

    const username = isNewLogin
      ? formatEmailOrPhone(this.state.form.emailOrPhone)
      : this.getUsername(userProfiles, selectedProfile);

    const domainTypeId = this.getDomainTypeId(userProfiles, selectedProfile);

    stopwatch.start();
    const loginResponseObj = await userManagementServiceClient.login(
      formatEmailOrPhone(username),
      password,
      deviceInformation,
      domainTypeId
    );
    stopwatch.stop();

    const loginResponseStatus = loginResponseObj.status;
    const loginResponseBody = loginResponseObj.response;
    const loginResponseCode = loginResponseObj.code;

    this.setState({ description: loginResponseBody.description });

    if (loginResponseStatus === ERROR_STATUS) {
      if (loginResponseCode === HTTP_CONFLICT_CODE) {
        this.setState({ case: CONFLICT_USER });
        this.props.updateLoading(false);
        return;
      }

      this.props.showErrorMessage(JSON.stringify(loginResponseBody));
      setTimeout(() => {
        this.props.dismissErrorMessage();
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
      this.props.updateLoading(false);

      logEvent(LOGIN_FAILURE, {
        secondsElapsed: stopwatch.secondsElapsed,
        errorCode: loginResponseCode,
        errorSource: loginResponseObj.errorSource,
      });
      return;
    }

    logEvent(LOGIN_SUCCESS, {
      secondsElapsed: stopwatch.secondsElapsed,
    });

    if (loginResponseCode === OTP_CODE) {
      this.handleOtpCases(loginResponseBody);
      return;
    }

    if (loginResponseCode === SUCCESS_CODE) {
      if (loginResponseBody.data.user.roleName === GUEST) {
        this.handleGuestCase(loginResponseBody);
        return;
      }

      this.onSuccessfulLogin(loginResponseBody);
    }
  }

  handleOtpCases(loginResponseBody) {
    if (loginResponseBody.code === VERIFY_PHONE_NUMBER_CODE) {
      this.setState({
        isUserAgent: true,
        verifyUserMobile: true,
        showUserProfile: false,
        otpForm: { ...this.state.otpForm, mobileNo: loginResponseBody.data },
      });
      this.props.updateLoading(false);
      return;
    }

    if (
      [NEW_DEVICE_CODE, DEVICE_IN_USE_CODE, EXCEED_DEVICE_LIMIT_CODE].includes(
        loginResponseBody.code
      )
    ) {
      this.setState({
        accessToken: loginResponseBody.data.access_token,
        verifyUserMobile: false,
        verifyUserDevice: true,
        showUserProfile: false,
        case: this.getLoginCase(loginResponseBody),
        canRegisterDevice: this.canRegisterDevice(loginResponseBody),
        otpForm: {
          ...this.state.otpForm,
          tokenId: loginResponseBody.data.tokenId,
        },
      });
      this.props.updateLoading(false);
      return;
    }

    if (loginResponseBody.code === CHANGE_PASSWORD_CODE) {
      this.setState({
        accessToken:
          typeof loginResponseBody.data === "string"
            ? loginResponseBody.data
            : loginResponseBody.data.access_token,
        changePassword: true,
        showUserProfile: false,
        isOtpRequired: this.isOtpRequired(loginResponseBody),
        uuid: getDeviceInformation().deviceUuid,
        username: formatEmailOrPhone(this.state.form.emailOrPhone),
      });
      this.props.updateLoading(false);
      return;
    }

    if (loginResponseBody.code === RESET_PASSWORD_CODE) {
      if (this.isOtpRequired(loginResponseBody)) {
        window.location.href = `/login?message=${loginResponseBody.description}`;
      }
      this.setState({
        accessToken: loginResponseBody.data.access_token,
        resetPassword: true,
        showUserProfile: false,
        isOtpRequired: this.isOtpRequired(loginResponseBody),
        uuid: loginResponseBody.user.uuid,
        username: loginResponseBody.user.username,
      });
      this.props.updateLoading(false);
    }
  }

  handleGuestCase(loginResponseBody) {
    this.setState({
      isUserGuest: true,
      showUserProfile: false,
      guestForm: {
        ...this.state.guestForm,
        firstName: loginResponseBody.data.user.firstName,
        lastName: loginResponseBody.data.user.lastName,
        email: loginResponseBody.data.user.email,
        mobileNo: loginResponseBody.data.user.mobileNo,
        password: this.state.form.password,
      },
    });
    this.props.updateLoading(false);
  }

  async callDeviceAuth() {
    this.props.updateLoading(true, 50);

    const deviceInformation = getDeviceInformation();
    deviceInformation.deviceOs = this.deviceFingerprint[16].value;
    deviceInformation.deviceName = this.state.otpForm.deviceName;

    let headers = {};
    if (
      process.env.REACT_APP_USER_MANAGEMENT_API_BASE_URL.includes(
        "api-gateway"
      ) &&
      this.state.accessToken
    ) {
      headers.Authorization = `Bearer ${this.state.accessToken}`;
    }

    const loginResponseObj = await userManagementServiceClient.deviceAuth(
      this.state.otpForm.otp,
      this.state.otpForm.tokenId,
      deviceInformation,
      this.state.otpForm.registerDevice,
      headers
    );

    const loginResponseStatus = loginResponseObj.status;
    const loginResponseBody = loginResponseObj.response;

    if (loginResponseStatus === ERROR_STATUS) {
      this.props.showErrorMessage(JSON.stringify(loginResponseBody));
      setTimeout(() => {
        this.props.dismissErrorMessage();
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
      this.props.updateLoading(false);
      return;
    }

    this.onSuccessfulLogin(loginResponseBody);
  }

  async callVerifyOtp() {
    const {
      userProfiles,
      form: { selectedProfile },
    } = this.state;
    this.props.updateLoading(true, 33);

    const username = this.getUsername(userProfiles, selectedProfile);
    const verifyResponseObj = await userManagementServiceClient.verifyOtp(
      username,
      this.state.otpForm.otp
    );
    const { response, status } = verifyResponseObj;

    if (status === SUCCESS_STATUS) {
      this.props.updateLoading(false);
      this.callLogin();
    } else {
      this.props.updateLoading(false);
      this.props.showErrorMessage(JSON.stringify(response));
      setTimeout(() => {
        this.props.dismissErrorMessage();
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
    }
  }

  async callGuestSignUp(sendOtp, verifyOtp) {
    const { guestForm } = this.state;
    this.props.updateLoading(true, 33);

    const deviceInformation = getDeviceInformation();
    deviceInformation.deviceOs = this.deviceFingerprint[16].value;

    const guestSignupResponseObj =
      await userManagementServiceClient.signupGuestUser(
        guestForm,
        sendOtp,
        verifyOtp
      );

    const { status, response, code } = guestSignupResponseObj;

    if (status === ERROR_STATUS) {
      if (code === HTTP_CONFLICT_CODE) {
        this.callLogin();
      }

      this.props.updateLoading(false);
      this.props.showErrorMessage(JSON.stringify(response));
      setTimeout(() => {
        this.props.dismissErrorMessage();
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
      return;
    }

    if (status === SUCCESS_STATUS) {
      if (code === OTP_CODE) {
        this.props.updateLoading(false);
        this.setState({
          isUserGuest: true,
          doDeviceAuth: true,
        });
        return;
      }

      if (code === SUCCESS_CODE) {
        await this.handleSuccessfulGuestSignup(guestForm, deviceInformation);
      }
    }
  }

  async handleSuccessfulGuestSignup(guestForm, deviceInformation) {
    let hasLoggedInSuccessfully = false;
    let retryCount = 0;
    this.props.updateLoading(true, 66);

    while (!hasLoggedInSuccessfully && retryCount < 3) {
      this.props.updateLoading(true, 80);
      const responseObj = await userManagementServiceClient.login(
        guestForm.mobileNo,
        guestForm.password,
        deviceInformation,
        parseInt(process.env.REACT_APP_APPLICANT_DOMAIN_TYPE_ID)
      );
      const { status, response } = responseObj;

      this.props.updateLoading(false);
      retryCount += 1;

      if (status === SUCCESS_STATUS) {
        await this.createApplicationAfterLogin(guestForm, response);
        hasLoggedInSuccessfully = true;
      }
    }

    if (retryCount === 3 && !hasLoggedInSuccessfully) {
      this.props.updateLoading(false);
      this.props.showErrorMessage("An error occurred.");
      setTimeout(
        () => this.props.dismissErrorMessage(),
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );
    }
  }

  async createApplicationAfterLogin(guestForm, loginResponse) {
    this.props.updateLoading(true, 80);
    const { firstName, lastName, mobileNo, email } = guestForm;
    const createApplicationPayload = {
      applicantDetails: {
        firstName,
        surname: lastName,
        emailAddress: email,
        phoneNumber: formatPhoneNumber(mobileNo),
      },
    };

    saveAuthToken(loginResponse.data.access_token);
    saveRefreshToken(loginResponse.data.access_token);
    saveData(CURRENT_USER, JSON.stringify(loginResponse.data.user));
    onNewSessionBegin();

    let hasCreateApplication = false;
    while (!hasCreateApplication) {
      const onboardingResponseObj = await this.onboarding.createApplication(
        createApplicationPayload
      );
      const onboardingResponseObjStatus = onboardingResponseObj.status;
      const onboardingResponseObjresponse = onboardingResponseObj.response;
      const onboardingResponseObjCode = onboardingResponseObj.code;

      if (onboardingResponseObjStatus === ERROR_STATUS) {
        if (onboardingResponseObjCode === HTTP_CONFLICT_CODE) {
          this.setState({ redirectToDashboard: true });
          return;
        }
        this.props.updateLoading(false);
        this.props.showErrorMessage(
          JSON.stringify(onboardingResponseObjresponse)
        );
        setTimeout(() => {
          this.props.dismissErrorMessage();
        }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
        this.props.updateLoading(false);
        return;
      }

      this.props.updateLoading(false);
      hasCreateApplication = true;
      localStorage.setItem(
        "currentApplication",
        JSON.stringify(onboardingResponseObjresponse)
      );
      this.setState({ signupSuccessful: true });
    }
  }

  async callResendOtpGuest() {
    const resendOtpParameter = this.state.isUserAgent
      ? formatEmailOrPhone(this.state.form.emailOrPhone)
      : formatPhoneNumber(this.state.guestForm.mobileNo);

    const responseObj = await userManagementServiceClient.resendOtp(
      resendOtpParameter
    );
    this.handleOtpResponse(responseObj);
  }

  async callResendOtp() {
    const deviceInformation = getDeviceInformation();
    this.props.updateLoading(true, 50);

    let headers = {};
    if (
      process.env.REACT_APP_USER_MANAGEMENT_API_BASE_URL.includes(
        "api-gateway"
      ) &&
      this.state.accessToken
    ) {
      headers.Authorization = `Bearer ${this.state.accessToken}`;
    }

    const resendOtpResponse = await userManagementServiceClient.regenerateToken(
      deviceInformation.deviceUuid,
      this.state.otpForm.tokenId,
      headers
    );

    this.props.updateLoading(false);
    this.handleOtpResponse(resendOtpResponse);
  }

  handleOtpResponse(responseObj) {
    const { status, response } = responseObj;

    if (status === ERROR_STATUS) {
      this.props.showErrorMessage(JSON.stringify(response));
      setTimeout(() => {
        this.props.dismissErrorMessage();
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
      return;
    }

    this.props.showSuccessMessage("OTP successfully sent!");
    setTimeout(() => {
      this.props.dismissSuccessMessage();
    }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));

    if (response.data?.tokenId) {
      this.setState({
        doDeviceAuth: true,
        otpForm: {
          ...this.state.otpForm,
          tokenId: response.data.tokenId,
          registerDevice: false,
        },
      });
    }
  }

  isEmailOrPhoneValid(newForm) {
    return (
      isPhoneValid(newForm.emailOrPhone, NIGERIA_PHONE_PREFIX) ||
      isEmailValid(newForm.emailOrPhone)
    );
  }

  async onSuccessfulLogin(loginResponseBody) {
    saveAuthToken(loginResponseBody.data.access_token);
    saveRefreshToken(loginResponseBody.data.refresh_token);
    saveData(CURRENT_USER, JSON.stringify(loginResponseBody.data.user));

    onNewSessionBegin();
    this.setState({ redirectToDashboard: true });
  }

  async changePassword() {
    this.props.updateLoading(67);
    const { accessToken, changePasswordForm } = this.state;
    const changePasswordResponse =
      await userManagementServiceClient.changePassword(
        changePasswordForm.oldPassword,
        changePasswordForm.newPassword,
        changePasswordForm.confirmPassword,
        accessToken
      );
    this.props.updateLoading(false);
    const { status, response } = changePasswordResponse;

    if (status === SUCCESS_STATUS) {
      this.setState({ isLoading: false });
      this.props.showSuccessMessage("Password changed successfully");
      setTimeout(() => {
        this.props.dismissSuccessMessage();
        window.location.reload();
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
      return;
    }

    this.props.showErrorMessage(JSON.stringify(response));
    setTimeout(() => {
      this.props.dismissErrorMessage();
    }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
  }

  updateFormField(params) {
    const newFormData = { ...this.state.form, ...params };
    const allParamsAreValid =
      this.isEmailOrPhoneValid(newFormData) &&
      isPasswordValid(newFormData.password);

    this.setState({
      form: newFormData,
      formIsValid: allParamsAreValid,
    });
  }

  updateNewLoginFormField(params) {
    const newFormData = { ...this.state.form, ...params };
    const allParamsAreValid = this.isEmailOrPhoneValid(newFormData);
    const allProfileParamsAreValid = isPasswordValid(newFormData.password);

    this.setState({
      form: newFormData,
      usernameIsValid: allParamsAreValid,
      userProfileIsValid: allProfileParamsAreValid,
    });
  }

  updateOtpFormField(params) {
    const newFormData = { ...this.state.otpForm, ...params };
    const allParamsAreValid = isOtpValid(newFormData);

    this.setState({
      otpForm: newFormData,
      otpFormIsValid: allParamsAreValid,
    });
  }

  updateGuestFormField(params) {
    this.setState({
      guestForm: { ...this.state.guestForm, ...params },
    });
  }

  updateChangePasswordFormField(params) {
    this.setState({
      changePasswordForm: { ...this.state.changePasswordForm, ...params },
    });
  }

  setPasswordFormField(params) {
    this.setState({
      setPasswordForm: { ...this.state.setPasswordForm, ...params },
    });
  }

  updateResetPasswordFormField(params) {
    this.setState({
      resetPasswordForm: { ...this.state.resetPasswordForm, ...params },
    });
  }

  isPasswordSame(password) {
    return password === this.state.changePasswordForm.newPassword;
  }

  arePasswordsTheSame(password, confirmPassword) {
    return password === confirmPassword;
  }

  isChangePasswordFormValid() {
    const { changePasswordForm } = this.state;
    return (
      isPasswordValid(changePasswordForm.oldPassword) &&
      isPasswordValid(changePasswordForm.newPassword) &&
      this.isPasswordSame(changePasswordForm.confirmPassword)
    );
  }

  isResetPasswordFormValid() {
    const { resetPasswordForm } = this.state;
    return (
      isPasswordValid(resetPasswordForm.oldPassword) &&
      isPasswordValid(resetPasswordForm.newPassword) &&
      this.arePasswordsTheSame(
        resetPasswordForm.newPassword,
        resetPasswordForm.confirmPassword
      ) &&
      isOtpValid(resetPasswordForm)
    );
  }

  isSetPasswordFormValid() {
    const { setPasswordForm } = this.state;
    return (
      isPasswordValid(setPasswordForm.password) &&
      this.arePasswordsTheSame(
        setPasswordForm.password,
        setPasswordForm.confirmPassword
      ) &&
      isOtpValid(setPasswordForm)
    );
  }

  get message() {
    return this.state.message ? (
      <Alert variant="primary">{this.state.message}</Alert>
    ) : null;
  }

  get caseDescriptionLabel() {
    const { case: caseType, description, firstName } = this.state;

    switch (caseType) {
      case AUTO_LOGIN_FAILED:
        return (
          <Alert variant="primary">
            {firstName}, you already have a{" "}
            <span className="brand-name">Quickteller</span> account! Log-in with
            your existing details.
          </Alert>
        );
      case CANNOT_ACCESS_DASHBOARD:
        return (
          <Alert variant="danger">
            You are not authorized to access this dashboard.
          </Alert>
        );
      case CONFLICT_USER:
        return <Alert variant="danger">{description}</Alert>;
      case NEW_DEVICE:
        return <Alert variant="success">{description}</Alert>;
      case EMAIL_VERIFIED:
        return (
          <Alert variant="success">
            Email Verified Successfully, Please Login
          </Alert>
        );
      case EMAIL_NOT_VERIFIED:
        return <Alert variant="danger">Failed to verify email.</Alert>;
      case DEVICE_IN_USE:
      case EXCEED_DEVICE_LIMIT:
        return <Alert variant="danger">{description}</Alert>;
      default:
        return "";
    }
  }

  doOtpVerification() {
    this.state.otpForm.registerDevice || this.state.verifyUserDevice
      ? this.callDeviceAuth()
      : this.callVerifyOtp();
  }

  renderPasswordField(fieldName, label, hideState, onChange, formState) {
    return (
      <Form.Group>
        <FormLabel
          style={{
            color: `${
              !formState[fieldName] || isPasswordValid(formState[fieldName])
                ? "#3A3B3B"
                : process.env.REACT_APP_RED_COLOUR
            }`,
            fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            lineHeight: "19px",
          }}
        >
          {label}:
        </FormLabel>
        <FormControl
          autoComplete="new-password"
          id={`${fieldName}-field`}
          ref={this.passwordRef}
          type={this.state[hideState] ? "password" : "text"}
          placeholder={label}
          onChange={onChange}
          style={{
            border: `1px solid ${
              !formState[fieldName] || isPasswordValid(formState[fieldName])
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`,
          }}
        />
        <img
          className="hide-password-toggle"
          style={{
            marginLeft: this.state.deviceIsPC ? "265px" : "90%",
          }}
          src={
            this.state[hideState]
              ? "/assets/media/icons/eye.svg"
              : "/assets/media/icons/eye-hidden.svg"
          }
          onClick={() => this.setState({ [hideState]: !this.state[hideState] })}
          alt=""
        />
      </Form.Group>
    );
  }

  renderFormRow(children) {
    return (
      <Row className="regular-row">
        <Col className="regular-col">{children}</Col>
      </Row>
    );
  }

  renderFormButton(label, onClick, disabled, isLoading) {
    return (
      <Button
        type="submit"
        disabled={disabled || isLoading}
        style={{ fontWeight: "normal", width: "100%" }}
        onClick={onClick}
      >
        {isLoading ? "Loading..." : label}
      </Button>
    );
  }

  renderNewLoginForm() {
    return (
      <Form>
        <H1
          style={{ textAlign: "center", margin: 0, textTransform: "uppercase" }}
        >
          Login to your account
        </H1>
        <center>
          <HR style={{ marginTop: "10px" }} />
        </center>

        {this.caseDescriptionLabel}
        {this.message}

        <form onSubmit={() => this.callGetUserProfile()}>
          {this.renderFormRow(
            <Form.Group>
              <FormLabel
                style={{
                  color: `${
                    !this.state.form.emailOrPhone ||
                    this.isEmailOrPhoneValid(this.state.form)
                      ? "#3A3B3B"
                      : process.env.REACT_APP_RED_COLOUR
                  }`,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  lineHeight: "19px",
                }}
              >
                Phone or Email:
              </FormLabel>
              <FormControl
                autoComplete="new-password"
                id="email-or-phone-field"
                placeholder="Phone or Email"
                onChange={(event) => {
                  const emailOrPhone = event.target.value.trim();
                  this.updateNewLoginFormField({ emailOrPhone });
                }}
                onBlur={() =>
                  this.setState({
                    fieldsFocused: [...this.state.fieldsFocused, "email"],
                  })
                }
                style={{
                  border: `1px solid ${
                    !this.state.form.emailOrPhone ||
                    this.isEmailOrPhoneValid(this.state.form)
                      ? "#DFE2E6"
                      : process.env.REACT_APP_RED_COLOUR
                  }`,
                }}
                type="text"
              />
            </Form.Group>
          )}

          <Row>
            <Col>
              <div
                style={{
                  color: process.env.REACT_APP_LINK_COLOUR,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  paddingBottom: "10px",
                }}
              >
                <Link to="/forgotten-password">Forgot Password?</Link>
                <span style={{ float: "right" }}>
                  <a href="mailto:ifiscustomercare@interswitchgroup.com">
                    Contact Support
                  </a>
                </span>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              {this.renderFormButton(
                "Login",
                (e) => {
                  e.preventDefault();
                  this.callGetUserProfile();
                },
                !this.state.usernameIsValid,
                this.props.isLoading
              )}
            </Col>
          </Row>

          <Row>
            <Col>
              <div
                style={{
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  paddingTop: "10px",
                }}
              >
                Are you new on Quickteller Paypoint?
                {process.env.REACT_APP_SHOW_NEW_SIGNUP === "true" ? (
                  <Link to="/signup"> Signup</Link>
                ) : (
                  <Link to="/signupOld"> Signup</Link>
                )}
              </div>
            </Col>
          </Row>
        </form>
      </Form>
    );
  }

  renderSelectUserProfileForm() {
    const userProfile =
      this.state.userProfiles && this.state.userProfiles.count >= 1
        ? this.state.userProfiles.userDomainTypes.filter(
            (profile) => !profile.fipUser
          )
        : [];

    return (
      <Form>
        <H1
          style={{ textAlign: "center", margin: 0, textTransform: "uppercase" }}
        >
          {userProfile.length < 2
            ? "Login to your profile"
            : "Select your profile"}
        </H1>
        <center>
          <HR style={{ marginTop: "10px" }} />
        </center>

        <form>
          {userProfile.length === 1 && (
            <div className="regular-row">
              <div className="regular-col">
                <div className="form-group">
                  <label style={{ lineHeight: "19px" }}>
                    <Text
                      style={{
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        fontWeight: "bold",
                      }}
                    >
                      Username:
                    </Text>
                  </label>
                  <Text
                    style={{
                      float: "right",
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    }}
                  >
                    {userProfile[0].username}
                  </Text>
                </div>
              </div>
            </div>
          )}

          {userProfile.length > 1 &&
            userProfile.map((profile, index) => (
              <Row
                key={index}
                className="regular-row"
                style={{ paddingBottom: "10px" }}
              >
                <Col className="regular-col">
                  <div style={{ padding: "10px" }}>
                    <label>
                      <input
                        type="radio"
                        name="profileRadio"
                        value={JSON.stringify(profile)}
                        onChange={(event) => {
                          const selectedProfile = JSON.parse(
                            event.target.value
                          );
                          this.updateNewLoginFormField({ selectedProfile });
                        }}
                      />
                      <div style={{ float: "right", paddingLeft: "10px" }}>
                        <h6
                          style={{ color: process.env.REACT_APP_BLUE_COLOUR }}
                        >
                          <b>{formatDomainTypeId(profile.domainTypeId)}</b>
                        </h6>
                        <span style={{ fontSize: "12px" }}>
                          {profile.domainName}
                        </span>
                        <br />
                        <span style={{ fontSize: "12px" }}>
                          {profile.roleName}
                        </span>
                        <br />
                        <span style={{ fontSize: "12px" }}>
                          {profile.username}
                        </span>
                      </div>
                    </label>
                  </div>
                </Col>
              </Row>
            ))}

          {!(!this.state.form.selectedProfile && userProfile.length > 1) &&
            this.renderFormRow(
              this.renderPasswordField(
                "password",
                "Password or PIN",
                "hidePassword",
                (event) => {
                  const password = event.target.value;
                  this.updateNewLoginFormField({ password });
                },
                this.state.form
              )
            )}

          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              {this.renderFormButton(
                "Continue",
                (e) => {
                  e.preventDefault();
                  this.callLogin();
                },
                !this.state.userProfileIsValid,
                this.props.isLoading
              )}
            </Col>
          </Row>

          <Row style={{ margin: 0 }}>
            <Col md={12} lg={12} style={{ margin: 0, padding: 0 }}>
              <div
                style={{
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  paddingTop: "10px",
                  cursor: "pointer",
                  color: "blue",
                }}
                onClick={() => this.backToLogin()}
              >
                Login with another account?
              </div>
            </Col>
          </Row>
        </form>
      </Form>
    );
  }

  renderOtpForm() {
    return (
      <Form>
        <H1
          style={{ textAlign: "center", margin: 0, textTransform: "uppercase" }}
        >
          {this.state.verifyUserMobile
            ? "VERIFY YOUR PHONE NUMBER"
            : "ENTER YOUR OTP"}
        </H1>
        <center>
          <HR style={{ marginTop: "10px" }} />
        </center>
        {this.caseDescriptionLabel}

        {this.renderFormRow(
          <div
            style={{
              color: process.env.REACT_APP_BLACK_COLOUR,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              marginBottom: "15px",
              textAlign: "center",
            }}
          >
            {this.state.verifyUserMobile
              ? "An OTP has been sent to your phone."
              : "Enter the OTP that was sent to your registered phone number."}
          </div>
        )}

        {this.renderFormRow(
          <Form.Group>
            <FormLabel
              style={{
                color: `${
                  !this.state.otpForm.otp || isOtpValid(this.state.otpForm)
                    ? "#3A3B3B"
                    : process.env.REACT_APP_RED_COLOUR
                }`,
                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                lineHeight: "19px",
              }}
            >
              OTP:
            </FormLabel>
            <FormControl
              autoComplete="new-password"
              id="otp-input"
              type="text"
              placeholder="OTP"
              onChange={(event) => {
                const otp = event.target.value;
                this.updateGuestFormField({ ...this.state.guestForm, otp });
                this.updateOtpFormField({ otp });
              }}
              style={{
                border: `1px solid ${
                  !this.state.otpForm.otp || isOtpValid(this.state.otpForm)
                    ? "#DFE2E6"
                    : process.env.REACT_APP_RED_COLOUR
                }`,
              }}
            />
          </Form.Group>
        )}

        {this.state.canRegisterDevice && (
          <Row
            className="regular-row"
            hidden={this.state.isUserGuest || this.state.verifyUserMobile}
            style={{ margin: 0, padding: 0 }}
          >
            <Col className="regular-col" style={{ margin: 0, padding: 0 }}>
              <Form.Group style={{ marginBottom: "10px", padding: 0 }}>
                <div id="register-device-check-container">
                  <Form.Check
                    id="register-checkbox"
                    type="checkbox"
                    onChange={(event) => {
                      this.updateOtpFormField({
                        registerDevice: !this.state.otpForm.registerDevice,
                      });
                    }}
                  />
                  <Text>Register Device</Text>
                </div>
              </Form.Group>
            </Col>
          </Row>
        )}

        {this.state.otpForm.registerDevice &&
          this.renderFormRow(
            <Form.Group style={{ marginBottom: "5px" }}>
              <FormLabel
                style={{
                  color: `${
                    !this.state.otpForm.deviceName ||
                    isDeviceNameValid(this.state.otpForm)
                      ? "#3A3B3B"
                      : process.env.REACT_APP_RED_COLOUR
                  }`,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  lineHeight: "19px",
                }}
              >
                Device Name:
              </FormLabel>
              <FormControl
                autoComplete="new-password"
                id="otp-input"
                type="text"
                placeholder="Device Name"
                onChange={(event) => {
                  const deviceName = event.target.value;
                  this.updateOtpFormField({ deviceName });
                }}
                style={{
                  border: `1px solid ${
                    !this.state.otpForm.deviceName ||
                    isDeviceNameValid(this.state.otpForm)
                      ? "#DFE2E6"
                      : process.env.REACT_APP_RED_COLOUR
                  }`,
                }}
              />
            </Form.Group>
          )}

        {this.renderFormRow(
          <div
            id="resend-otp-link"
            style={{
              color: process.env.REACT_APP_LINK_COLOUR,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              margin: "10px 10px 10px 10px",
            }}
          >
            <Link
              onClick={
                this.state.isUserGuest || this.state.isUserAgent
                  ? this.callResendOtpGuest
                  : this.callResendOtp
              }
            >
              Resend OTP
            </Link>
          </div>
        )}

        <Row id="continue-button-row" hidden={this.state.isUserGuest}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="regular-col">
            {this.renderFormButton(
              "CONTINUE",
              (e) => {
                e.preventDefault();
                this.doOtpVerification();
              },
              this.state.otpForm.registerDevice
                ? !this.state.otpFormIsValid ||
                    !isDeviceNameValid(this.state.otpForm)
                : !this.state.otpFormIsValid,
              this.props.isLoading
            )}
          </Col>
        </Row>

        <Row id="continue-button-row" hidden={!this.state.isUserGuest}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="regular-col">
            {this.renderFormButton(
              "CONTINUE",
              (e) => {
                e.preventDefault();
                this.callGuestSignUp(false, true);
              },
              !this.state.otpFormIsValid,
              this.props.isLoading
            )}
          </Col>
        </Row>
      </Form>
    );
  }

  renderGuestForm() {
    return (
      <form onSubmit={() => this.callGuestSignUp(true, false)}>
        {this.state.error && (
          <Alert variant="danger">Failed to Send OTP, Try again</Alert>
        )}

        <Alert variant="primary" style={{ marginBottom: "50px" }}>
          Hi{" "}
          {this.state.guestForm.firstName &&
            `${this.state.guestForm.firstName.toUpperCase()} ${this.state.guestForm.lastName.toUpperCase()}`}
          , you currently exist on our platform. Would you like to register as a
          Quickteller Paypoint Agent with the existing details?
        </Alert>

        {this.state.guestForm.email && (
          <div className="regular-row">
            <div className="regular-col">
              <div className="form-group">
                <label style={{ lineHeight: "19px" }}>
                  <Text
                    style={{
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      fontWeight: "bold",
                    }}
                  >
                    Email:
                  </Text>
                </label>
                <Text
                  style={{
                    float: "right",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  }}
                >
                  {this.state.guestForm.email}
                </Text>
              </div>
            </div>
          </div>
        )}

        <div className="regular-row">
          <div className="regular-col">
            <div className="form-group">
              <label style={{ lineHeight: "19px" }}>
                <Text
                  style={{
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    fontWeight: "bold",
                  }}
                >
                  Phone Number:
                </Text>
              </label>
              <Text
                style={{
                  float: "right",
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                {this.state.guestForm.mobileNo &&
                  formatPhoneNumberWithCountryCode(
                    this.state.guestForm.mobileNo
                  )}
              </Text>
            </div>
          </div>
        </div>

        <div
          id="continue-button-row"
          style={{
            margin: "20px 0px 0px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
          hidden={this.state.showOtpField}
        >
          <div
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="regular-col"
            onClick={() => this.backToLogin()}
            style={{ marginTop: "8px", fontWeight: "bold", cursor: "pointer" }}
          >
            BACK
          </div>
          <div xs={12} sm={12} md={12} lg={12} xl={12} className="regular-col">
            {this.renderFormButton(
              "CONTINUE",
              (e) => {
                e.preventDefault();
                this.callGuestSignUp(true, false);
              },
              false,
              this.props.isLoading
            )}
          </div>
        </div>

        <div
          id="continue-button-row"
          style={{
            margin: "20px 0px 0px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
          hidden={!this.state.showOtpField}
        >
          <div xs={12} sm={12} md={12} lg={12} xl={12} className="regular-col">
            {this.renderFormButton(
              "LOGIN",
              (e) => {
                e.preventDefault();
                this.backToLogin();
              },
              false,
              false
            )}
          </div>
          <div xs={12} sm={12} md={12} lg={12} xl={12} className="regular-col">
            {this.renderFormButton(
              "CONTINUE",
              (e) => {
                e.preventDefault();
                this.callGuestSignUp(false, true);
              },
              !isOtpValid(this.state.guestForm),
              this.props.isLoading
            )}
          </div>
        </div>
      </form>
    );
  }

  renderChangePasswordForm() {
    return (
      <Form>
        <H1
          style={{ textAlign: "center", margin: 0, textTransform: "uppercase" }}
        >
          Change Password
        </H1>
        <center>
          <HR style={{ marginTop: "10px" }} />
        </center>

        <form onSubmit={this.changePassword}>
          {this.renderFormRow(
            this.renderPasswordField(
              "oldPassword",
              "Old Password or PIN",
              "hidePassword",
              (event) => {
                const oldPassword = event.target.value;
                this.updateChangePasswordFormField({ oldPassword });
              },
              this.state.changePasswordForm
            )
          )}

          {this.renderFormRow(
            this.renderPasswordField(
              "newPassword",
              "New Password or PIN",
              "hideNewPassword",
              (event) => {
                const newPassword = event.target.value;
                this.updateChangePasswordFormField({ newPassword });
              },
              this.state.changePasswordForm
            )
          )}

          {this.renderFormRow(
            this.renderPasswordField(
              "confirmPassword",
              "Confirm Password or PIN",
              "hideConfirmPassword",
              (event) => {
                const confirmPassword = event.target.value;
                this.updateChangePasswordFormField({ confirmPassword });
              },
              this.state.changePasswordForm
            )
          )}

          <div
            id="continue-button-row"
            style={{
              margin: "20px 0px 0px 0px",
              display: "flex",
              justifyContent: "space-between",
            }}
            hidden={this.state.showOtpField}
          >
            <div
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="regular-col"
              onClick={() => this.backToLogin()}
              style={{
                marginTop: "8px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              BACK
            </div>
            <div
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="regular-col"
            >
              {this.renderFormButton(
                "CONTINUE",
                (e) => {
                  e.preventDefault();
                  this.changePassword();
                },
                !this.isChangePasswordFormValid(),
                this.props.isLoading
              )}
            </div>
          </div>
        </form>
      </Form>
    );
  }

  renderResetPasswordForm() {
    return (
      <Form>
        <H1
          style={{ textAlign: "center", margin: 0, textTransform: "uppercase" }}
        >
          Reset Password
        </H1>
        <center>
          <HR style={{ marginTop: "10px" }} />
        </center>

        <form onSubmit={this.resetPassword}>
          {this.renderFormRow(
            this.renderPasswordField(
              "oldPassword",
              "Old Password or PIN",
              "hidePassword",
              (event) => {
                const oldPassword = event.target.value;
                this.updateResetPasswordFormField({ oldPassword });
              },
              this.state.resetPasswordForm
            )
          )}

          {this.renderFormRow(
            this.renderPasswordField(
              "newPassword",
              "New Password or PIN",
              "hidePassword",
              (event) => {
                const newPassword = event.target.value;
                this.updateResetPasswordFormField({ newPassword });
              },
              this.state.resetPasswordForm
            )
          )}

          {this.renderFormRow(
            this.renderPasswordField(
              "confirmPassword",
              "Confirm Password or PIN",
              "hideConfirmPassword",
              (event) => {
                const confirmPassword = event.target.value;
                this.updateResetPasswordFormField({ confirmPassword });
              },
              this.state.resetPasswordForm
            )
          )}

          <Row className="regular-row" hidden>
            <Col className="regular-col">
              <Form.Group>
                <FormLabel
                  style={{
                    color: `${
                      !this.state.resetPasswordForm.otp ||
                      isOtpValid(this.state.resetPasswordForm)
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  OTP
                </FormLabel>
                <FormControl
                  id="otp-field"
                  ref={this.passwordRef}
                  placeholder="OTP"
                  onBlur={() =>
                    this.setState({
                      fieldsFocused: [...this.state.fieldsFocused, "otp"],
                    })
                  }
                  onChange={(event) => {
                    const otp = event.target.value;
                    this.updateResetPasswordFormField({ otp });
                  }}
                  style={{
                    background: "none",
                    border: `1px solid ${
                      !this.state.resetPasswordForm.otp ||
                      isOtpValid(this.state.resetPasswordForm)
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                  }}
                />
              </Form.Group>
            </Col>
          </Row>

          <div
            id="continue-button-row"
            style={{
              margin: "20px 0px 0px 0px",
              display: "flex",
              justifyContent: "space-between",
            }}
            hidden={this.state.showOtpField}
          >
            <div
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="regular-col"
              onClick={() => this.backToLogin()}
              style={{
                marginTop: "8px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              BACK
            </div>
            <div
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="regular-col"
            >
              {this.renderFormButton(
                "CONTINUE",
                (e) => {
                  e.preventDefault();
                  this.resetPassword();
                },
                !this.isResetPasswordFormValid(),
                this.props.isLoading
              )}
            </div>
          </div>
        </form>
      </Form>
    );
  }

  renderSetPasswordForm() {
    const { emailOrPhone } = this.state.form;

    return (
      <Form>
        <H1
          style={{ textAlign: "center", margin: 0, textTransform: "uppercase" }}
        >
          Set Password
        </H1>
        <center>
          <HR style={{ marginTop: "10px" }} />
        </center>

        <form onSubmit={(e) => this.resetPasswordPhone(e)}>
          {this.renderFormRow(
            <Form.Group>
              <FormLabel
                style={{
                  color: `${
                    !this.state.setPasswordForm.otp ||
                    isOtpValid(this.state.setPasswordForm)
                      ? "#3A3B3B"
                      : process.env.REACT_APP_RED_COLOUR
                  }`,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  lineHeight: "19px",
                }}
              >
                OTP
              </FormLabel>
              <FormControl
                id="otp-field"
                ref={this.passwordRef}
                placeholder="OTP"
                onBlur={() =>
                  this.setState({
                    fieldsFocused: [...this.state.fieldsFocused, "otp"],
                  })
                }
                onChange={(event) => {
                  const otp = event.target.value;
                  this.setPasswordFormField({ otp });
                }}
                style={{
                  background: "none",
                  border: `1px solid ${
                    !this.state.setPasswordForm.otp ||
                    isOtpValid(this.state.setPasswordForm)
                      ? "#DFE2E6"
                      : process.env.REACT_APP_RED_COLOUR
                  }`,
                }}
              />
            </Form.Group>
          )}

          {this.renderFormRow(
            this.renderPasswordField(
              "password",
              "Enter Password",
              "hidePassword",
              (event) => {
                const password = event.target.value;
                this.setPasswordFormField({ password });
              },
              this.state.setPasswordForm
            )
          )}

          {this.renderFormRow(
            this.renderPasswordField(
              "confirmPassword",
              "Confirm Password or PIN",
              "hideConfirmPassword",
              (event) => {
                const confirmPassword = event.target.value;
                this.setPasswordFormField({ confirmPassword });
              },
              this.state.setPasswordForm
            )
          )}

          <div
            id="continue-button-row"
            style={{
              margin: "20px 0px 0px 0px",
              display: "flex",
              justifyContent: "space-between",
            }}
            hidden={this.state.showOtpField}
          >
            <Row className="regular-row">
              <Col className="regular-col">
                <div
                  id="resend-otp-link"
                  style={{
                    color: process.env.REACT_APP_LINK_COLOUR,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    margin: "10px 10px 10px 10px",
                  }}
                >
                  <Link onClick={() => this.callRecoverPassword(emailOrPhone)}>
                    Resend OTP
                  </Link>
                </div>
              </Col>
            </Row>

            <div
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="regular-col"
            >
              {this.renderFormButton(
                "CONTINUE",
                null,
                !this.isSetPasswordFormValid(),
                this.props.isLoading
              )}
            </div>
          </div>
        </form>
      </Form>
    );
  }

  render() {
    if (this.state.redirectToDashboard) {
      return <Redirect to="/dashboard" />;
    }

    if (this.state.signupSuccessful) {
      return <Redirect to="/application" />;
    }

    const carousel = (
      <Carousel
        activeIndex={
          this.state.showUserProfile
            ? 1
            : this.state.doDeviceAuth
            ? 2
            : this.state.isUserGuest
            ? 3
            : this.state.verifyUserDevice
            ? 4
            : this.state.verifyUserMobile
            ? 2
            : this.state.changePassword
            ? 5
            : this.state.resetPassword
            ? 6
            : this.state.setPassword
            ? 7
            : this.state.selectNewUser
            ? 9
            : 0
        }
        controls={false}
        disabled
        indicators={false}
        interval={null}
        style={{ transition: "all .2s ease-in-out" }}
      >
        <Carousel.Item>{this.renderNewLoginForm()}</Carousel.Item>
        <Carousel.Item>{this.renderSelectUserProfileForm()}</Carousel.Item>
        <Carousel.Item>{this.renderOtpForm()}</Carousel.Item>
        <Carousel.Item>{this.renderGuestForm()}</Carousel.Item>
        <Carousel.Item>{this.renderOtpForm()}</Carousel.Item>
        <Carousel.Item>{this.renderChangePasswordForm()}</Carousel.Item>
        <Carousel.Item>{this.renderResetPasswordForm()}</Carousel.Item>
        <Carousel.Item>{this.renderSetPasswordForm()}</Carousel.Item>
      </Carousel>
    );

    const desktopContainer = (
      <Container id="desktop-container" fluid>
        <Row style={{ margin: 0, padding: 0 }}>
          <Col
            xs={12}
            md={12}
            lg={6}
            style={{
              margin: 0,
              padding: 0,
              display: "flex",
              justifyContent: this.state.deviceIsPC ? "flex-start" : "center",
              marginLeft: this.state.deviceIsPC ? "10%" : "0%",
            }}
          >
            <div id="carousel-container">{carousel}</div>
          </Col>
        </Row>
      </Container>
    );

    const mobileContainer = (
      <Container id="mobile-container" fluid>
        {carousel}
      </Container>
    );

    return (
      <div
        id="login-main-container"
        style={{
          backgroundImage: this.state.deviceIsPC
            ? "url('https://mufasa.interswitchng.com/p/quickteller/Content/Images/QT_paypoint_bg.png')"
            : "",
          backgroundSize: "contain",
        }}
      >
        <Header
          hideLogin={true}
          transparent={true}
          outerDivStyle={{ backgroundColor: "white" }}
        />

        {this.state.deviceIsMobile ? mobileContainer : desktopContainer}

        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.tunnel.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dismissErrorMessage: () => dispatch(dismissErrorMessage()),
    dismissSuccessMessage: () => dispatch(dismissSuccessMessage()),
    showErrorMessage: (message) => dispatch(showErrorMessage(message)),
    showSuccessMessage: (message) => dispatch(showSuccessMessage(message)),
    updateLoading: (isLoading, percentage = null) =>
      dispatch(updateLoading({ isLoading, percentage })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
