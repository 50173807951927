import uuid4 from 'uuid/v4';

export default class Transaction {
  constructor(props) {

    this.wallet = props.wallet;
  }

  create (amount, description, serviceType) {
    this.wallet.debit(amount);
    this.wallet.addCommissionsEarned(0.1 * amount);

    const previousTransactions = JSON.parse(localStorage.getItem('transactions') || '[]');
    const date = new Date();

    const transaction = {
      amount,
      balanceAfter: this.wallet.wallet.currentBalance,
      date: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`,
      description,
      serviceType,
      status: 'SUCCESSFUL',
      transactionReference: uuid4().split('-')[0],
    };

    previousTransactions.push(transaction);

    localStorage.setItem('transactions', JSON.stringify(previousTransactions));
  }

  get history () {
    return JSON.parse(localStorage.getItem('transactions') || '[]');
  }

}

export function billPaymentResponse(){
    return {
      "code": "00",
      "responseCode": "00",
      "description": "successful",
      "responseData": {
          "transactionReference": "1321000000000008728",
          "transactionType": "BILLS",
          "walletVersion": 2,
          "billsPaymentRequest": {
              "amount": "400",
              "paymentItemCode": "10803",
              "terminalId": "9PBL0001",
              "paymentInstrumentType": "CASH",
              "fee": "80",
              "borneBy": "Biller",
              "narration": "Airtel Recharge Pins",
              "customerId": "0000000001",
              "customerMsisdn": "+2348168171593",
              "customerName": "",
              "agentCommission":45
          }
      }
      }
  
}

export function rechargeResponse(){
  return {
    "code": "00",
    "responseCode": "00",
    "description": "successful",
    "responseData": {
        "transactionReference": "1321000000000008728",
        "transactionType": "recharge",
        "walletVersion": 2,
        "rechargeRequest": {
            "amount": "400",
            "paymentItemCode": "10803",
            "terminalId": "9PBL0001",
            "paymentInstrumentType": "CASH",
            "fee": "80",
            "borneBy": "Biller",
            "narration": "Airtel Recharge Pins",
            "customerId": "0000000001",
            "customerMsisdn": "+2348168171593",
            "customerName": "",
            "agentCommission":45
        }
    }
    }

}

export function transferResponse(){
  return {
    "code": "00",
    "responseCode": "00",
    "description": "successful",
    "responseData": {
        "transactionReference": "1321000000000008728",
        "transactionType": "transfer",
        "walletVersion": 2,
        "transferRequest": {
            "amount": "400",
            "paymentItemCode": "10803",
            "terminalId": "9PBL0001",
            "paymentInstrumentType": "CASH",
            "fee": "80",
            "borneBy": "Biller",
            "narration": "Airtel Recharge Pins",
            "customerId": "0000000001",
            "customerMsisdn": "+2348168171593",
            "customerName": "",
            "agentCommission":45
        }
    }
    }

}

export function NIPResponse(){
  return {
    "code": "00",
    "responseCode": "00",
    "description": "successful",
    "responseData": {
        "transactionReference": "1321000000000008728",
        "transactionType": "nip",
        "walletVersion": 2,
        "NIPRequest": {
            "amount": "400",
            "paymentItemCode": "10803",
            "terminalId": "9PBL0001",
            "paymentInstrumentType": "CASH",
            "fee": "80",
            "borneBy": "Biller",
            "narration": "Airtel Recharge Pins",
            "customerId": "0000000001",
            "customerMsisdn": "+2348168171593",
            "customerName": "",
            "agentCommission":45
        }
    }
    }

}


export function nameInquiryResponse(){
     return{
             "code": "00",
            "responseCode": "00",
            "description": "successful",
            "responseData":{
                "accountCurrency" : "NIG",
                "accountName":"temi",
                "accountNumber":"3001155245",
                "accountType":"saving",
                "countryCode":"234",
                "firstName":"temi",
                "lastName":"noah",
                "otherNames":"oguns"
                }
    }
}
 

