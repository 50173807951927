import React from 'react';
import { Dropdown } from 'react-bootstrap';
import ReactTable from 'react-table';
import MdMore from 'react-ionicons/lib/MdMore';
import CustomMenu from '../../../../../../../components/custom-menu';
import CustomToggle from '../../../../../../../components/custom-toggle';
import StatusCodes from '../../../../../../../fixtures/status_codes';

const transactionHistoryColumns = [{
  Header: props => <span style={{fontWeight: 'bold', padding: '10pt', width: '10pt'}}>#</span>,
  accessor: 'id',
  Cell: ({row}) => {
    return <div className="table-column-id">
      {row.id}
    </div>
  },
  headerClassName: 'table-header',
  className: 'table-cell',
},{
  Header: 'First Name',
  accessor: 'firstName',
  headerClassName: 'table-header',
  className: 'table-cell',
}, {
  Header: 'Last Name',
  accessor: 'lastName',
  headerClassName: 'table-header',
  className: 'table-cell',
}, {
  Header: 'Email',
  accessor: 'email',
  headerClassName: 'table-header',
  className: 'table-cell',
}, {
  Header: 'Phone',
  accessor: 'mobileNo',
  headerClassName: 'table-header',
  className: 'table-cell',
}, {
  Header: 'Status',
  accessor: 'status',
  Cell: ({row}) => {
    const status = StatusCodes.find(item => item.id === row.status).name;
    return <div style={{color: status === 'Active' ? process.env.REACT_APP_GREEN_COLOUR : process.env.REACT_APP_RED_COLOUR}} className="selectable-table-row">
      {status}
    </div>
  },
  headerClassName: 'table-header',
  className: 'table-cell',
}, {
  Header: 'Actions',
  accessor: 'actions',
  Cell: ({row}) => {
    return <div className="grow link" style={{display: 'flex', justifyContent: 'center'}} onClick={() => window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/users/${row.email}`}>
      Edit
    </div>
  },
  headerClassName: 'table-header',
  className: 'table-cell',
}]

export default props => {
  return <ReactTable
    columns={transactionHistoryColumns}
    data={props.data}
    pageSize={10}
  />
}
