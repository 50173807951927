import React, { useRef } from "react";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import qs from "qs";
import PropTypes from "prop-types";

import Text from "../../../../../../components/text";
import H1 from "../../../../../../components/h1";
import Button from "../../../../../../components/button";
import {
  formatAmount,
  formatDate,
  handleTransactionStatus,
} from "../../../../../../utils/formatters";
import { convertNgkToNgn } from "../../../../../../utils/converters/currencies";

// Reusable Components
const ReceiptItem = ({ label, value, className = "" }) => (
  <>
    <Row style={styles.receiptItem.row}>
      <Col md={12} style={styles.receiptItem.col}>
        <Text style={styles.receiptItem.label}>{label}: </Text>
        <Text style={styles.receiptItem.value} className={className}>
          {value}
        </Text>
      </Col>
    </Row>
    <hr />
  </>
);

ReceiptItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  className: PropTypes.string,
};

const ReceiptHeader = () => (
  <div style={styles.receiptHeader.container}>
    <img
      alt="Interswitch Logo"
      style={styles.receiptHeader.logoLeft}
      src="https://i.ibb.co/Qbsjn5b/isw.png"
    />
    <img
      alt="Interswitch Logo"
      style={styles.receiptHeader.logoRight}
      src="https://i.ibb.co/Qbsjn5b/isw.png"
    />
    <div style={styles.receiptHeader.textContainer}>
      <h6>
        <b>Thank you for using Quickteller Paypoint!</b>
      </h6>
      <span>Here is your transaction receipt.</span>
      <span>See payment details below.</span>
      <p style={styles.receiptHeader.originalCopy}>
        <Text style={styles.receiptHeader.originalCopyText}>
          ***ORIGINAL COPY***
        </Text>
      </p>
    </div>
    <H1 style={styles.receiptHeader.title}>Transaction Receipt</H1>
  </div>
);

const Receipt = ({ data }) => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const today = new Date();

  return (
    <>
      <ReceiptItem label="Phone Number" value={data.customerId} />
      <ReceiptItem label="Ticket Number" value={data?.ticketNumber} />
      <ReceiptItem label="Number of Seat(s)" value={data?.noOfSeats} />
      <ReceiptItem label="Origin" value={data?.origin} />
      <ReceiptItem label="Destination" value={data?.destination} />
      <ReceiptItem label="Bus Type" value={data?.busType} />
      <ReceiptItem
        label="Travel Date"
        value={moment(data?.travelDate).format("ddd, D MMM")}
      />
      <ReceiptItem label="Departure Time" value={data?.departureTime} />
      <ReceiptItem label="Number of Adult" value={data?.noOfAdults} />

      {data?.noOfChildren && (
        <ReceiptItem label="Number of Children" value={data?.noOfChildren} />
      )}

      <ReceiptItem label="Transaction Date" value={formatDate(today)} />
      <ReceiptItem label="Transaction Type" value="QT Transport" />
      <ReceiptItem label="Transaction Reference" value={data?.transactionRef} />
      <ReceiptItem
        label="Transaction Status"
        value={handleTransactionStatus(data.code)}
        className={handleTransactionStatus(data.code)}
      />
      <ReceiptItem
        label="Amount"
        value={
          data ? `NGN${formatAmount(convertNgkToNgn(data.totalFare))}` : ""
        }
      />
      <ReceiptItem label="Business Name" value={currentUser?.domainName} />
    </>
  );
};

Receipt.propTypes = {
  data: PropTypes.shape({
    customerId: PropTypes.string.isRequired,
    ticketNumber: PropTypes.string,
    noOfSeats: PropTypes.number,
    origin: PropTypes.string,
    destination: PropTypes.string,
    busType: PropTypes.string,
    travelDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    departureTime: PropTypes.string,
    noOfAdults: PropTypes.number,
    noOfChildren: PropTypes.number,
    transactionRef: PropTypes.string,
    code: PropTypes.string,
    totalFare: PropTypes.number,
  }).isRequired,
};

const ReceiptActions = ({ onPrint, onDismiss }) => (
  <Row style={styles.actions.row}>
    <Col xs={12} md={4} className="d-flex justify-content-center">
      <Button onClick={onPrint} style={styles.actions.printButton}>
        Print
      </Button>
    </Col>
    <Col xs={12} md={4} className="d-flex justify-content-center">
      <Button outline style={styles.actions.dismissButton} onClick={onDismiss}>
        Dismiss
      </Button>
    </Col>
  </Row>
);

ReceiptActions.propTypes = {
  onPrint: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

// Main Component
const ReceiptScreen = ({ responseData }) => {
  const refTrans = useRef();
  const deviceIsMobile =
    qs.parse(window.location.search)["?mobile"] || window.innerWidth <= 576;

  const printPage = () => {
    const mywindow = window.open("", "PRINT", "height=400,width=600");

    mywindow.document.write(`<html><head><title> </title>`);
    mywindow.document.write(`</head><body >`);
    mywindow.document.write(document.getElementById("print-receipt").innerHTML);
    mywindow.document.write(`</body></html>`);

    mywindow.document.close();
    mywindow.focus();
    mywindow.print();
  };

  const onCancelProduct = () => {
    window.location.href = "/agent/dashboard";
  };

  return (
    <article style={styles.container}>
      <div
        className={`print-receipt airtime-bill-height ${
          deviceIsMobile ? "mobile-width" : "pc-tab-width"
        }`}
      >
        <div className="print-receipt" id="print-receipt" ref={refTrans}>
          <ReceiptHeader />
          <Receipt data={responseData} />
        </div>

        <ReceiptActions onPrint={printPage} onDismiss={onCancelProduct} />
      </div>
    </article>
  );
};

ReceiptScreen.propTypes = {
  responseData: PropTypes.shape({
    customerId: PropTypes.string,
    ticketNumber: PropTypes.string,
    noOfSeats: PropTypes.number,
    origin: PropTypes.string,
    destination: PropTypes.string,
    busType: PropTypes.string,
    travelDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    departureTime: PropTypes.string,
    noOfAdults: PropTypes.number,
    noOfChildren: PropTypes.number,
    transactionRef: PropTypes.string,
    code: PropTypes.string,
    totalFare: PropTypes.number,
  }).isRequired,
};

// Styles
const styles = {
  container: {
    background: "white",
    borderRadius: "10px",
    padding: "30px",
    margin: "0 auto",
  },
  receiptItem: {
    row: { marginTop: "15px" },
    col: { display: "flex" },
    label: {
      paddingLeft: 20,
      minWidth: 200,
      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
      fontWeight: "bold",
    },
    value: {
      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
      fontWeight: "bold",
    },
  },
  receiptHeader: {
    container: {
      color: process.env.REACT_APP_BLUE_COLOUR,
      paddingTop: "5px",
    },
    logoLeft: {
      top: "20px",
      width: "40px",
    },
    logoRight: {
      top: "40px",
      left: "500px",
      width: "100px",
      float: "right",
    },
    textContainer: {
      marginBottom: "20px",
    },
    originalCopy: {
      textAlign: "right",
    },
    originalCopyText: {
      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
      color: process.env.REACT_APP_GREY_COLOUR,
    },
    title: {
      textAlign: "center",
    },
  },
  actions: {
    row: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      marginTop: "20pt",
    },
    printButton: {
      width: "100%",
      marginBottom: "10px",
      padding: "9pt",
      textAlign: "center",
    },
    dismissButton: {
      width: "100%",
      padding: "9pt 10pt",
      marginBottom: "10px",
      textAlign: "center",
    },
  },
};

export default ReceiptScreen;
