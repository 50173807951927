export const successfulTransactions = '3';

export const PAY_BILLS = 1;
export const TRANSFER = 2;
export const RECHARGE = 3;
export const FUND = 4;
export const CASH_OUT = 5;
export const WALLET_TO_WALLET = 6;
export const CASH_IN = 7;

export const BILLER_BORNE = 'Biller';