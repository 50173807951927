export const AGENT_TYPE_ID = 4;
export const BILLS = 'bills';
export const BUY_AIRTIME = 'buy-airtime';
export const CAC_REGISTRATION = 'cac-registration';
export const CASH_IN = 'cash-in';
export const CASH_OUT = 'cash-out';
export const CURRENT_AGENT = 'currentAgent';
export const CURRENT_USER = 'currentUser';
export const DEFAULT_DEVICE_NAME = 'My PC';
export const DEFAULT_ERROR_MESSAGE = 'An error occurred.';
export const FACEBOOK = 'Facebook';
export const FACEBOOK_LOWERCASE = 'facebook';
export const NIGERIA = 'Nigeria';
export const NIGERIA_PHONE_PREFIX = '234';
export const ONE_MB = 1048576;
export const THREE_MB = 3145728;
export const TEN_MB = 10485760;
export const OPEN_AN_ACCOUNT = 'open-an-account';
export const PAY_A_BILL = 'pay-a-bill';
export const BUY_POWER = 'buy-power';
export const SELL_AIRTIME = 'sell-airtime-and-data';
export const REQUEST_POS = 'request-pos';
export const SEND_MONEY = 'send-money';
export const TRANSFER_TO_ACCOUNT = 'transfer-to-account';
export const TRANSFER_CASH_IN = 'TRANSFER_CASH_IN';
export const NIP_TRANSFER = 'NIP';
export const TRANSFER_TO_AGENT = 'transfer-to-agent';
export const DISTRIBUTE = 'distribute';
export const ACCOUNT_OPENING = 'account-opening';
export const WITHDRAWAL = 'cardless-cashout';
export const PAYCODE_CASHOUT = 'paycode-cashout';
export const USSD_WITHDRAWAL = 'ussd-withdrawal';
export const FUND_VIA_USSD = 'fund-via-ussd';

export const BUSINESS_DOC_SUFFIX = '-BD';
export const UTILITY_BILL = 'Utility Bill';
export const CERTIFICATE_OF_INCORPORATION = 'Certificate of Incorporation';
export const ADDRESS_VERIFICATION_FORM = 'Address Verification Form';
export const CHARACTER_ATTESTATION = 'Character Attestation Form';
export const PERSONAL_DOC_SUFFIX = '-PD';
export const PROOF_OF_ID = 'Proof of Identity';
export const PASSPORT_PHOTO = 'Passport Photo';
