import { connect } from 'react-redux';
import { navigateTo } from '../../services/redux/actions/navigation'
import { updateLoading } from '../../services/redux/actions/tunnel'
import HomeScene from './scene'

function mapStateToProps(state) {
  return {
    pendingUrl: state.navigation.pendingUrl,
    isLoading: state.tunnel.isLoading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: pendingUrl => dispatch(navigateTo(pendingUrl)),
    updateLoading: isLoading => dispatch(updateLoading(isLoading))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeScene);
