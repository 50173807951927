import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './bootstrap.min.css';
import './index.css';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import store from './services/redux/store';
import CookieConsent from './components/cookie-consent';


ReactDOM.render(
  <Provider store={store}>
    <App />
    <CookieConsent />
  </Provider>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
