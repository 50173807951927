import React, { Component } from 'react'
import { Toast } from 'react-bootstrap'
import { connect } from 'react-redux'

class ToastFactory extends Component {
  makeErrorToast() {
    return <Toast animation show={Boolean(this.props.errorMessage)} className="toast toast-error">
      <Toast.Body style={{color: 'white'}}>
        {this.props.errorMessage}
      </Toast.Body>
    </Toast>  
  }

  makeSuccessToast() {
    return <Toast animation show={Boolean(this.props.successMessage)} className="toast toast-success">
      <Toast.Body style={{color: 'white'}}>
        {this.props.successMessage}
      </Toast.Body>
    </Toast>  
  }

  render () {
    if (this.props.errorMessage) {
      return this.makeErrorToast()
    } else if (this.props.successMessage) {
      return this.makeSuccessToast()
    } else {
      return <React.Fragment />
    }
  }
}

function mapStateToProps(state) {
  return {
    errorMessage: state.tunnel.errorMessage,
    successMessage: state.tunnel.successMessage,
    isLoading: state.tunnel.isLoading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    // addArticle: article => dispatch(addArticle(article))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ToastFactory);
