import React from "react";
import POSIMAGE from "../../../../../../../icons/pos-image.png";

const PreviewPos = ({ value }) => {
  return (
    <>
      <section className="pos-request-section-container-aggregator">
        <article className="pos-request-article-aggregator">
          <img
            className="preview-pos-image"
            src={value?.terminalImage ? value?.terminalImage : POSIMAGE}
            alt="pos terminal"
          />
        </article>
        <div className="w-100">
          <div className="pos-request-article2-content-aggregator">
            {value?.terminalDescription ? (
              <>
                <p
                  style={{
                    fontWeight: 700,
                  }}
                >
                  {value.label}
                </p>
                <div>{value?.terminalDescription}</div>
              </>
            ) : (
              <>
                <p
                  style={{
                    fontWeight: 700,
                  }}
                >
                  A920 POS Terminal
                </p>
                <div>
                  This Android POS terminal is a portable device that can be
                  used to accept payments from customers for goods and services.
                  It typically consists of a touchscreen display, a card reader,
                  a printer, and a battery. The device runs on the Android
                  operating system, which allows it to run various payment
                  applications.
                  <br /> <br />
                  To use the Android POS terminal, you simply need to connect it
                  to a power source and turn it on. Once the device is on, you
                  can launch the payment application and select the type of
                  transaction you want to process. For example, you can choose
                  to accept a debit or credit card payment, or even process
                  mobile payments.
                </div>
              </>
            )}
          </div>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button
              //   onClick={handleSubmit}
              style={{
                height: "100px",
                width: "100px",
                border: "2px solid #479FC8",
                background: "#EBF8FE",
                cursor: "pointer",
                color: "grey",
                borderRadius: "5px",
                // fontSize: "12px",
              }}
              disabled={!value}
            >
              Previous
            </button>
            <button
              //   onClick={handleSubmit}
              style={{
                height: "100px",
                width: "100px",
                borderRadius: "5px",
                border: "2px solid #479FC8",
                background: "#EBF8FE",
                cursor: "pointer",
                color: "grey",
              }}
              disabled={!value}
            >
              Next
            </button>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default PreviewPos;
