import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import 'react-table/react-table.css';
import MdTrash from 'react-ionicons/lib/MdTrash';
import InfiniteScroll from "react-infinite-scroll-component";


import BaseScene from '../../../base-scene';
import Transaction from '../../../../utils/mocks/transaction';
import Wallet from '../../../../utils/mocks/wallet';
import './styles.scss';


import { SUCCESS_STATUS, HTTP_NOT_FOUND_CODE } from '../../../../constants/api';
import {
  formatDate,
} from '../../../../utils/formatters';

import Spinner from '../../../../components/spinner';
import UserSerializer from '../../../../serializers/resources/user';
import { connect } from 'react-redux';
import { updateLoading, dismissErrorMessage, showErrorMessage } from '../../../../services/redux/actions/tunnel'
import MessagingService from '../../../../services/api/resources/messaging-service';
import VerticallyCenteredModal from '../../../../components/vertically-centered-modal';

const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
const user = new UserSerializer(currentUser);

class NotificationScene extends BaseScene {

  location = window.location.href;
  path = '/reports';
  title = 'Reports';

  transaction = new Transaction({
    wallet: new Wallet(),
  });

  messagingService = new MessagingService();

  TodaysDate = new Date();
  OneMonthsBefore = new Date(this.TodaysDate.getFullYear(), this.TodaysDate.getMonth() - 1);


  constructor(props) {
    super(props);
    
    const lastWeekDate = new Date();
    lastWeekDate.setDate(lastWeekDate.getDate() - 7);

    const firstDayCurrentMonth = new Date(this.TodaysDate.getFullYear(), this.TodaysDate.getMonth(), 1);
    const lastDayCurrentMonth  = new Date(this.TodaysDate.getFullYear(), this.TodaysDate.getMonth() + 1, 0);

    const firstDayPrevMonth = new Date(this.TodaysDate.getFullYear(), this.TodaysDate.getMonth() - 1, 1);
    const lastDayPrevMonth  = new Date(this.TodaysDate.getFullYear(), this.TodaysDate.getMonth(), 0);

    this.state = {
      endDate: this.TodaysDate,
      startDate: this.OneMonthsBefore,
      selectedOption: {label: 'Transaction Type ', value: '0'},
      statusOption: {label: 'Transaction Status', value: '0'},
      agentList: {label: 'Select Agent', value: '0'},
      pageSize: 20,
      pageNum: 1,
      pageIndex: 1,
      hasMore: true,
      messages: []
    };

  }

  componentDidMount() {
    this.getMessages()
  }

  componentWillMount () {
    // this.getTransactionsByDateRange(this.state.startDate, this.state.endDate, this.state.selectedOption.value, this.state.statusOption.value, this.state.pageSize, this.state.pageNum, this.state.domainCode)
    // !process.env.REACT_APP_SHOW_SERVICES_CHARTS && this.getGraphTransactionsData(this.state.reportsGraphStartDate, this.state.reportsGraphEndDate)
    // !process.env.REACT_APP_SHOW_SERVICES_CHARTS && this.getMonthlyGraphTransactionsData(this.state.firstDayCurrentMonth, this.state.lastDayCurrentMonth);
    // !process.env.REACT_APP_SHOW_SERVICES_CHARTS && this.getPrevMonthGraphTransactionsData(this.state.firstDayPrevMonth, this.state.lastDayPrevMonth);
  }



  handleEmptyResponse = (code) => {
    this.setState({
      sampleTransactions: [],
      filteredTransactions: [],
      pages: 1,
      isLoading: false,
      hasMore: false
    })
    if (code === HTTP_NOT_FOUND_CODE) {
      this.setState({
        didErrorOccurWhileFetchingAllTransactions: false
      })
    } else {
      this.setState({
        didErrorOccurWhileFetchingAllTransactions: true
      })
    }
  }

  async getMessages(pageNum, pageSize) {
    // this.setState({
    //   pageNum: pageNum,
    //   pageSize: pageSize
    // })
    const data = {
      pageNumber: pageNum,
      pageSize: pageSize
    }
    const { status, response, code } = await this.messagingService.getMessagesByPhone(data, user.businessMobileNo)
    const messages = response.items
    const count = response.totalCount

    const getMessages = () => {
      if (!this.state.messages) {
        return messages
      } else {
        return this.state.messages.concat(messages)
      }
    }

    const getMore = () => {
      if (count === this.state.messages.length) {
        return false
      } else {
        return true
      }
    }

    if(status === SUCCESS_STATUS) {
      if (messages.length === 0) {
        this.setState({
          hasMore: false
        })
        return
      }
      this.setState({
        messages: getMessages(),
        didErrorOccurWhileGettingAgents: false,
        pages: Math.ceil(count/this.state.pageSize),
        hasMore: getMore()
      })
    } else {
      this.handleEmptyResponse(code);
    }
  }

  

  async readMessage (message) {
    const { messages } = this.state
    const data = [message.id]
    const modifyMessage = (id) => {
      const index = messages.findIndex((msg) => msg.id === id);
      messages[index].read = true
    }
    const { status, response, code } = await this.messagingService.markMessageAsRead(data);
    if (status === SUCCESS_STATUS) {
      modifyMessage(message.id)
      this.setState({
        showMessage: true,
        clickedMessage: message,
      })
    }
    return
  }

  async deleteMessage (id) {
    const data = [id]
    const { status, response, code } = await this.messagingService.deleteMessage(data);
    if(status === SUCCESS_STATUS) {
      this.setState({
        messages: this.state.messages.filter((msg) => id !== msg.id )
      })
    }
    return
  }

 

  render () {
    const { messages } = this.state;

    const handleReadMessage = (message) => {
      this.readMessage(message)
    }

    const handleDeleteMessage = (message) => {
      this.deleteMessage(message.id)
      // this.setState({
      //   messages: messages.filter((msg) => message.id !== msg.id )
      // })
    }
  
    const handleClose = () => {
      this.setState({
        showMessage: false
      })
    }

    return (
      <>
      <InfiniteScroll 
      dataLength={10}
      next={() => this.getMessages(this.state.pageNum + 1, this.state.pageSize)}
      hasMore={this.state.hasMore}
      loader={<h4 style={{ textAlign: "center" }}>Loading...</h4>}
      endMessage={
        <p style={{ textAlign: "center" }}>
          <b>Yay! You have seen it all</b>
        </p>
      }
      >

        {this.state.showMessage && <VerticallyCenteredModal
          onHide={handleClose}
          show={this.state.showMessage}
          title='Message'
          body={<MessageBody details={this.state.clickedMessage}/>}
          hideFooter
        />}
        <div className={this.state.deviceIsMobile ? "container container-fluid reports-scene-container-mobile" : "reports-scene-container"}>
          <div className="h1 reports-scene-header">Notifications</div>
          <Container fluid className="regular-container" style={{ padding: '10px' }}>
            <Row id="reports-scene-row">
              <Col md={12} className="card-with-shadow" id="transactions-table-container">
                <div className="transactions-table-header">
                  <div className='transactions-title'>
                    <div className="reports-scene-statistical-component-heading">Notifications</div>
                  </div>
                  
                  <div className="reports-scene-date-picker-container">
                    
                  </div>
                  <div>
                  </div>
                </div>
                <div className="transactions-table-container">
                {(messages.length > 0) ? messages.map((msg) => (
                  <div className="emailrow">

                    {/* <label class="containerBox">
                      <input type="checkbox"/>
                      <span class="checkmark"></span>
                    </label> */}

                    <div className="emailrow_options">

                    </div>

                    <h3 className={`emailrow_title ${!msg.read ? 'bold' : ''}`} onClick={() => handleReadMessage(msg)}>
                      {msg.title}       
                    </h3>

                    <div className="emailrow_message" onClick={() => handleReadMessage(msg)}>
                      <h6 className={ `${!msg.read ? 'bold' : ''}`}>{(msg.message)}</h6> 
                    </div>

                    <div className='emailrow_time'>
                      <h6 className={`${!msg.read ? 'bold' : ''}`}>{`${formatDate(msg.date)} (${msg.prettyTime})`}</h6>
                    </div>
                    <MdTrash onClick={() => handleDeleteMessage(msg)} color={'grey'} fontSize="26px" style={{marginRight: '10pt', marginBottom: '5pt'}} />

                </div>
                ))
              : <div style={{ padding: '60px 0 20px 0'}}>
                  <Spinner loading/>
                </div>
              }
              </div>
              </Col>
            </Row>
          </Container>
        </div>
        </InfiniteScroll>
      </>
    )
  }
}

const MessageBody = (props) => {
  return <>
    <div>
      <p>{props.details.message}</p> 
    </div>
  </>
}

function mapStateToProps(state) {
  return {
    isLoading: state.tunnel.isLoading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateLoading: isLoading => dispatch(updateLoading(isLoading)),
    dismissErrorMessage: () => dispatch(dismissErrorMessage()),
    showErrorMessage: data => dispatch(showErrorMessage(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationScene);