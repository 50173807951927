export function getClientDetails () {
  const isIOS = navigator.userAgent.includes('iPhone') || navigator.userAgent.includes('iPad');
  const isMac = navigator.userAgent.toLowerCase().includes('mac');

  return {
    isMac,
    isIOS,
    isDesktop: window.innerWidth > 768,
    isMobile: window.innerWidth <= 768,
    isSmallMobile: window.innerWidth <= 360,
  }
}
