export default class NextOfKinDetailsFormSerializer {
  constructor(props) {
    const { firstName, lastName, phone, phonePrefix, gender, relationship, address } = props

    this.firstName = firstName
    this.surname = lastName
    this.phone = phone
    this.gender = gender
    this.relationship = relationship
    this.address = address
    
    if (phone) {  
      this.phoneNumber = phone.startsWith(phonePrefix) ? `${phone}` : `${phonePrefix}${phone}`
    }
  }

  formIsValid () {
    return Boolean(this.firstName) || Boolean(this.surname) || Boolean(this.phone) || Boolean(this.gender) || Boolean(this.relationship) || Boolean(this.address);
  }

  formIsComplete () {
    return Boolean(this.firstName) && Boolean(this.surname) && Boolean(this.phone) && Boolean(this.gender) && Boolean(this.relationship) && Boolean(this.address);
  }

  asJson () {
    return {
      firstName: this.firstName,
      surname: this.surname,
      phoneNumber: this.phoneNumber,
      gender: this.gender,
      relationship: this.relationship,
      address: this.address,
    }
  }
}
