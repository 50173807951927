import React, { useRef } from "react";
import { Col, Row } from "react-bootstrap";
import Text from "../../../../../../../components/text";
import {
  formatAmount,
  formatDate,
  handleTransactionStatus,
} from "../../../../../../../utils/formatters";
import { convertNgkToNgn } from "../../../../../../../utils/converters/currencies";
import H1 from "../../../../../../../components/h1";
import Button from "../../../../../../../components/button";
import { useHistory } from "react-router-dom";

function Receipt(responseData) {
  let today = new Date();

  return (
    <>
      <Row style={{ marginTop: "15px" }}>
        <Col md={12} style={{ display: "flex" }}>
          <Text
            style={{
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: "bold",
            }}
          >
            Phone Number:{" "}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: "bold",
            }}
          >
            {responseData?.w2WRequestList[0].beneficiaryPhone}{" "}
          </Text>
        </Col>
      </Row>
      <hr />
      <Row style={{ marginTop: "15px" }}>
        <Col md={12} style={{ display: "flex" }}>
          <Text
            style={{
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: "bold",
            }}
          >
            Transaction Date:{" "}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: "bold",
            }}
          >
            {formatDate(today)}{" "}
          </Text>
        </Col>
      </Row>
      <hr />
      <Row style={{ marginTop: "15px" }}>
        <Col md={12} style={{ display: "flex" }}>
          <Text
            style={{
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: "bold",
            }}
          >
            Transaction Type:{" "}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: "bold",
            }}
          >
            DISTRIBUTE
          </Text>
        </Col>
      </Row>
      <hr />
      <Row style={{ marginTop: "15px" }}>
        <Col md={12} style={{ display: "flex" }}>
          <Text
            style={{
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: "bold",
            }}
          >
            Transaction Reference:{" "}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: "bold",
            }}
          >
            {responseData && responseData.transactionRef}
          </Text>
        </Col>
      </Row>
      <hr />
      <Row style={{ marginTop: "15px" }}>
        <Col md={12} style={{ display: "flex" }}>
          <Text
            style={{
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: "bold",
            }}
          >
            Transaction Status:{" "}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: "bold",
            }}
            className={handleTransactionStatus(responseData?.code)}
          >
            {handleTransactionStatus(responseData?.code)}
          </Text>
        </Col>
      </Row>
      <hr />
      <Row style={{ marginTop: "15px" }}>
        <Col md={12} style={{ display: "flex" }}>
          <Text
            style={{
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: "bold",
            }}
          >
            Amount:{" "}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: "bold",
            }}
          >
            {responseData
              ? `NGN${formatAmount(
                  convertNgkToNgn(responseData?.w2WRequestList[0].amount)
                )}`
              : ""}
          </Text>
        </Col>
      </Row>
      <hr />
      <Row style={{ marginTop: "15px" }}>
        <Col md={12} style={{ display: "flex" }}>
          <Text
            style={{
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: "bold",
            }}
          >
            Business Name:{" "}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: "bold",
            }}
          >
            {responseData?.w2WRequestList[0].beneficiaryName}
          </Text>
        </Col>
      </Row>
      <hr />
    </>
  );
}

const ReceiptScreen = ({ deviceIsMobile, setScreen, response }) => {
  const refTrans = useRef();
  const history = useHistory();
  const printPage = () => {
    let mywindow = window.open("", "PRINT", "height=400,width=600");

    mywindow.document.write(`<html><head><title> </title>`);
    mywindow.document.write(`</head><body >`);
    mywindow.document.write(document.getElementById("print-receipt").innerHTML);
    mywindow.document.write(`</body></html>`);

    mywindow.document.close();
    mywindow.focus();

    mywindow.print();
    //mywindow.close();

    return true;
  };

  const onCancelProduct = () => {
    history.push("/agent/dashboard");
  };

  return (
    <article className="fund-by-ussd-section-distribute">
      <div className={"print-receipt-distribute airtime-bill-height"}>
        <div
          className="print-receipt-distribute"
          id="print-receipt"
          ref={refTrans}
        >
          <div>
            <img
              alt=""
              style={{
                top: "20px",
                width: "40px",
              }}
              src="https://i.ibb.co/Qbsjn5b/isw.png"
              // src={service ? service.imageUrl : ''}
            />

            <img
              alt=""
              style={{
                top: "40px",
                left: "500px",
                width: "100px",
                float: "right",
              }}
              src="https://i.ibb.co/Qbsjn5b/isw.png"
            />
            <div
              style={{
                color: process.env.REACT_APP_BLUE_COLOUR,
                paddingTop: "5px",
              }}
            >
              <h6>
                <b>Thank you for using Quickteller Paypoint!</b>
              </h6>
              <span>Here is your transaction receipt.</span>
              <span>See payment details below.</span>

              <p style={{ textAlign: "right" }}>
                <Text
                  style={{
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    color: process.env.REACT_APP_GREY_COLOUR,
                  }}
                >
                  ***ORIGINAL COPY***
                </Text>
              </p>
            </div>
            <H1 style={{ textAlign: "center" }}>Transaction Receipt</H1>

            {Receipt(response)}
          </div>
        </div>

        <Row
          style={{ display: "flex", alignItems: "center", marginTop: "20pt" }}
        >
          <Col
            xs={12}
            md={4}
            style={{ marginBottom: `${deviceIsMobile && "5px"}` }}
          >
            <Button
              onClick={printPage}
              style={{
                width: "100%",
                marginBottom: "10px",
              }}
            >
              Print
            </Button>
          </Col>

          <Col xs={12} md={4}>
            <Button
              outline
              style={{
                width: "100%",
                padding: "9pt 37pt",
                marginBottom: "10px",
              }}
              onClick={onCancelProduct}
            >
              Dismiss
            </Button>
          </Col>
        </Row>
      </div>
    </article>
  );
};

export default ReceiptScreen;
