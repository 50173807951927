import React, { useState, useEffect } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import MdMore from "react-ionicons/lib/MdMore";
import ReactTable from "react-table";
import { toast } from "react-toastify";
import Button from "../../../../../components/button";
import Spinner from "../../../../../components/spinner";
import CRMService from "../../../../../services/api/resources/crm-service";
import { convertNgkToNgn } from "../../../../../utils/converters/currencies";
import {
  formatAmount,
  formatDateTime,
  handleStatusFormat,
  handleTransactionTypeFormat,
} from "../../../../../utils/formatters";

export default (props) => {
  const crmService = new CRMService();
  const [showModal, setShowModal] = useState(false);

  const notify = () => {
    toast.error("Oops! Something went wrong, try again.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const handleComplaint = async (transactionDetails) => {
    try {
      const { response } = await crmService.getTransactionTicket(
        transactionDetails.transactionRef
      );
      const { data } = response;

      if (data === undefined) {
        notify();
        return false;
      } else if (data !== null) {
        localStorage.setItem("ticketDetailsItem", JSON.stringify(data));
        props.navigateTo("/ticket");
      } else {
        localStorage.setItem("complaint", JSON.stringify(transactionDetails));
        if (
          transactionDetails.transactionType !== "BILLS" &&
          transactionDetails.transactionType !== "NIP" &&
          transactionDetails.transactionType !== "RECHARGE" &&
          transactionDetails.transactionType !== "CASH_OUT" &&
          transactionDetails.transactionType !== "USSD_CASH_OUT" &&
          transactionDetails.transactionType !== "PAYPOINT_FUND" &&
          transactionDetails.transactionType !== "USSD" &&
          transactionDetails.transactionType !== "TRANSFER"
        ) {
          setShowModal(true);
        } else {
          props.navigateTo("/complaint");
        }
      }
    } catch (err) {
      return false;
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const transactionHistoryColumns = [
    {
      Header: (props) => <React.Fragment>Trans Ref</React.Fragment>,
      accessor: "transactionRef",
      Cell: (props) => (
        <div style={{ textAlign: "left", wordWrap: "break-word" }}>
          {props.value}
        </div>
      ),
      headerClassName: "table-header",
      className: "table-cell",
      width: 250,
      style: { "white-space": "unset" },
    },
    {
      Header: (props) => <React.Fragment>Transaction Type</React.Fragment>,
      accessor: "transactionTypeInt",
      Cell: (props) => (
        <div style={{ textAlign: "left" }}>
          {handleTransactionTypeFormat(props.value)}
        </div>
      ),
      headerClassName: "table-header",
      className: "table-cell",
      style: { "white-space": "unset" },
    },
    {
      Header: (props) => <React.Fragment>Description</React.Fragment>,
      accessor: "narration",
      Cell: (props) => <div style={{ textAlign: "left" }}>{props.value}</div>,
      headerClassName: "table-header",
      className: "table-cell",
      width: 200,
      style: { "white-space": "unset" },
    },
    {
      Header: (props) => <React.Fragment>Amount</React.Fragment>,
      accessor: "transactionAmount",
      Cell: (props) => (
        <div style={{ textAlign: "left" }} className="number">{`₦${formatAmount(
          convertNgkToNgn(props.value)
        )}`}</div>
      ),
      headerClassName: "table-header",
      className: "table-cell",
      style: { "white-space": "unset" },
    },
    {
      Header: (props) => <React.Fragment>Status</React.Fragment>,
      accessor: "statusCode",
      Cell: (props) => (
        <div
          style={{ textAlign: "left" }}
          className={`${props.value.toLowerCase()}-status`}
        >
          {handleStatusFormat(props.value)}
        </div>
      ),
      headerClassName: "table-header",
      className: "table-cell",
      style: { "white-space": "unset" },
      width: 80,
    },
    {
      Header: (props) => <React.Fragment>Date</React.Fragment>,
      accessor: "dateCreated",
      Cell: (props) => (
        <div style={{ textAlign: "left" }} className="number">
          {formatDateTime(props.value)}
        </div>
      ),
      headerClassName: "table-header",
      className: "table-cell",
      style: { "white-space": "unset" },
      width: 150,
    },
    {
      //   Cell: props => <div style={{ textAlign: "left" }} className='number'>{`₦${formatAmount(convertNgkToNgn(props.value))}`}</div>,
      //   headerClassName: 'table-header',
      //   width: 150,
      // width: 150,
      Header: () => <div style={{ textAlign: "left" }}>Action</div>,
      Cell: (props) => (
        <Dropdown>
          <Dropdown.Toggle
            variant="secondary"
            id="dropdown-variants-secondary"
            style={{ backgroundColor: "white" }}
          >
            <MdMore
              fontSize="15px"
              color={process.env.REACT_APP_GREY_COLOUR}
              style={{ backgroundColor: "white" }}
            />
          </Dropdown.Toggle>
          {process.env.REACT_APP_NAV_MENU_SHOW_COMPLAINT == "true" && (
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleComplaint(props.original)}>
                Make a Complaint
              </Dropdown.Item>

              {/* {props.original.transactionTypeInt === REVERSAL_INT && <Dropdown.Item onClick={() => this.searchByTransRef(props.original.narration.replace(/[^\d]/g, ''))}>Show Failed Transaction</Dropdown.Item>} */}
            </Dropdown.Menu>
          )}
        </Dropdown>
      ),
      headerClassName: "table-header",
      className: "table-cell",
      style: { "white-space": "unset" },
      width: 60,
    },
  ];

  return (
    <>
      <ReactTable
        columns={transactionHistoryColumns}
        data={props.data}
        loading={props.loading}
        pageSize={10}
        showPagination={false}
        LoadingComponent={Spinner}
        minRows={props.data.length > 0 ? 0 : 5}
      />

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title className="text-center">Attention!!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This transaction type is currently unavailable to make a complaint.
          For inquiries/complaint, call 07009065000 or send an email to
          support@interswitchgroup.com
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
