import React from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import MdMore from "react-ionicons/lib/MdMore";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Swal from "sweetalert2";
import Pagination from "../../../../../components/pagination";
import { ReceiptModal } from "../../../../../components/vertically-centered-modal";
import { navigateTo } from "../../../../../services/redux/actions/navigation";
import { convertNgkToNgn } from "../../../../../utils/converters/currencies";
import {
  formatAmount,
  formatDate,
  formatDateTime,
  handleCustomerIdFormat,
  handleCustomerIdFormatTwo,
  handleReversalTransactionStatusFormat,
  handleStatusFormat,
  handleTransactionTypeFormat,
  sentenceCapitalization,
} from "../../../../../utils/formatters";
import { getPaginationRows } from "../../../../../utils/helpers";

import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ActivityIndicator from "../../../../../components/activity-indicator";
import Spinner from "../../../../../components/spinner";
import Text from "../../../../../components/text";
import VerticallyCenteredModal from "../../../../../components/vertically-centered-modal";
import { TRANSFER_CASH_IN } from "../../../../../constants";
import { SUCCESS_STATUS } from "../../../../../constants/api";
import { BILLER_BORNE } from "../../../../../constants/transactions";
import { transactionExtraSerializerReport } from "../../../../../serializers/resources/transaction-extras";
import Catalog from "../../../../../services/api/resources/catalog";
import CRMService from "../../../../../services/api/resources/crm-service";
import Quickteller from "../../../../../services/api/resources/quickteller";
import Transaction from "../../../../../services/api/resources/transaction";
import TransactionHistory from "../../../../../services/api/resources/transaction-history";
import "./styles.scss";

const PENDING = "PENDING_VALUE_PROVISIONING";
const minWidth = "200";
const REVERSAL_INT = 10;
const ikedcCodes = "051727101 051722602 04393801 04393701";

class TransactionsReportTable extends React.Component {
  transactionHistory = new TransactionHistory();
  transaction = new Transaction();
  quickteller = new Quickteller();
  catalog = new Catalog();
  crmService = new CRMService();
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      showReceipt: false,
      transactionDetails: null,
      isLoading: false,
      isRequeryLoading: false,
      reversalDetails: [],
      showReversalSection: false,
      isReversalDetailLoading: false,
      loadingComplaint: false,
    };

    this.searchByTransRef = this.searchByTransRef.bind(this);
    this.requeryTransaction = this.requeryTransaction.bind(this);
    this.serviceUniversalSearch = this.serviceUniversalSearch.bind(this);
  }

  async searchByTransRef(transRef) {
    this.setState({
      isLoading: true,
    });
    const { status, code, response } =
      await this.transactionHistory.getTransactionsByRef(transRef);

    if (status === SUCCESS_STATUS) {
      this.setState({
        //showModal: true,
        transactionDetails: {
          ...this.state.transactionDetails,
          extraInfoList: response.extraInfoList,
        },
        isLoading: false,
      });
    } else {
      this.setState({
        isLoading: false,
      });
      this.props.showErrorMessage(JSON.stringify(response));
      setTimeout(() => {
        this.props.dismissErrorMessage();
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
    }
  }

  async getTransactionReversalDetails(transaction) {
    this.setState({
      isReversalDetailLoading: true,
      reversalDetails: [],
      showReversalSection: false,
    });
    const mockReversalDetails = {
      reverseTransRef: "1293000000000011100",
      reverseTransAmount: transaction.transactionAmount,
      statusCode: "CLOSED_AND_SUCCESSFUL",
      dateCreated: transaction.dateCreated,
    };
    const responseObj = await this.transaction.getTransactionReversal(
      transaction.transactionRef
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        reversalDetails:
          response && response.description !== "successful"
            ? response
            : mockReversalDetails,
        isReversalDetailLoading: false,
        showReversalSection:
          response && response.description !== "successful" ? true : true,
      });
    } else {
      this.setState({
        reversalDetails: [],
        isReversalDetailLoading: false,
        showReversalSection: false,
      });
    }
  }

  async requeryTransaction(transRef) {
    this.setState({
      isRequeryLoading: true,
    });
    const { status, code, response } =
      await this.transactionHistory.requeryTransaction(transRef);
    if (status === SUCCESS_STATUS) {
      this.setState({
        transactionDetails: response,
        reversalDetails: response,
        isRequeryLoading: false,
        showModal: true,
      });
    } else {
      this.setState({
        transactionDetails: null,
        isRequeryLoading: false,
      });
      Swal.fire({
        position: "top-center",
        icon: "error",
        title: "Error Requering, Try again.",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }

  async serviceUniversalSearch(searchTerm) {
    const exceptions = "NIP, Transfer to Account";
    this.setState({
      searchServices: null,
    });
    if (exceptions.includes(searchTerm)) {
      return;
    }
    const { status, code, response } = await this.catalog.search(searchTerm);

    try {
      const [searchServices] = response;
      if (status === SUCCESS_STATUS) {
        this.setState({
          searchServices,
        });
      }
    } catch (e) {}
  }

  render() {
    const handleOpen = (props) => {
      this.searchByTransRef(props.original.transactionRef);
      if (handleStatusFormat(props.original.statusCode) === "Failed") {
        this.getTransactionReversalDetails(props.original);
      }
      this.setState({
        showModal: true,
        transactionDetails: props.original,
      });
    };

    const notify = () => {
      toast.error("Oops! Something went wrong, try again.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    };

    const handleComplaint = async (transactionDetails) => {
      this.setState({ loadingComplaint: true });
      try {
        const { response } = await this.crmService.getTransactionTicket(
          transactionDetails.transactionRef
        );
        const { data } = response;
        this.setState({ loadingComplaint: false });
        if (data) {
          localStorage.setItem("ticketDetailsItem", JSON.stringify(data));
          this.props.navigateTo("/ticket");
        } else {
          localStorage.setItem("complaint", JSON.stringify(transactionDetails));
          this.props.navigateTo("/complaint");
        }
      } catch (err) {
        return false;
      } finally {
        this.setState({ loadingComplaint: false });
      }
    };

    const handleReceiptOpen = (props) => {
      this.searchByTransRef(props.original.transactionRef);
      this.serviceUniversalSearch(props.original.service);
      this.setState({
        transactionDetails: props.original,
        showReceipt: true,
      });
    };

    const handleClose = () => {
      this.setState({
        showModal: false,
        showReceipt: false,
      });
    };

    const showReversalSection = (transaction) => {
      return (
        handleStatusFormat(transaction.statusCode || transaction.code) ===
          "Failed" && transaction.transactionTypeInt < 10
      );
    };

    const transactionHistoryColumns = [
      {
        Header: (props) => <div style={{ textAlign: "left" }}>Trans Ref</div>,
        Cell: (props) => (
          <div
            className="wordbreak"
            style={{ textAlign: "left", wordBreak: "break-all" }}
          >
            {props.value}
          </div>
        ),
        accessor: "transactionRef",
        headerClassName: "table-header",
        className: "table-cell",
        style: { "white-space": "unset" },
        width: 200,
      },
      {
        Header: (props) => <div style={{ textAlign: "left" }}>Trans Type</div>,
        accessor: "transactionTypeInt",
        Cell: (props) => (
          <div style={{ textAlign: "left" }}>
            {handleTransactionTypeFormat(props.value)}
          </div>
        ),
        headerClassName: "table-header",
        className: "table-cell",
        style: { "white-space": "unset" },
      },
      {
        Header: (props) => <div style={{ textAlign: "left" }}>Card Pan</div>,
        accessor: "maskedPan",
        Cell: (props) => (
          <div style={{ textAlign: "left" }}>
            {props.value != null ? props.value : "N/A"}
          </div>
        ),
        headerClassName: "table-header",
        className: "table-cell",
        width: 150,
        style: { "white-space": "unset" },
      },
      {
        Header: (props) => <div style={{ textAlign: "left" }}>Description</div>,
        accessor: "narration",
        Cell: (props) => <div style={{ textAlign: "left" }}>{props.value}</div>,
        headerClassName: "table-header",
        className: "table-cell",
        width: 200,
        style: { "white-space": "unset" },
      },
      {
        Header: (props) => <div style={{ textAlign: "left" }}>Customer ID</div>,
        accessor: "customerId",
        Cell: (props) => (
          <div
            className="wordbreak"
            style={{ textAlign: "left", wordBreak: "break-all" }}
          >
            {handleCustomerIdFormatTwo(props.value)}
          </div>
        ),
        headerClassName: "table-header",
        className: "table-cell",
        width: 160,
        style: { "white-space": "unset" },
      },
      {
        Header: (props) => <div style={{ textAlign: "left" }}>Amount</div>,
        accessor: "transactionAmount",
        Cell: (props) => (
          <div
            className="number"
            style={{ textAlign: "left" }}
          >{`₦${formatAmount(convertNgkToNgn(props.value))}`}</div>
        ),
        headerClassName: "table-header",
        className: "table-cell",
        width: 120,
        style: { "white-space": "unset" },
      },
      {
        Header: (props) => <React.Fragment>Date</React.Fragment>,
        accessor: "dateCreated",
        Cell: (props) => (
          <div style={{ textAlign: "left" }} className="number">
            {formatDateTime(props.value)}
          </div>
        ),
        headerClassName: "table-header",
        className: "table-cell",
        style: { "white-space": "unset" },
        width: 150,
      },
      {
        Header: (props) => <div style={{ textAlign: "left" }}>Status</div>,
        accessor: "statusCode",
        Cell: (props) => (
          <div
            className={`${props.value.toLowerCase()}-status`}
            style={{ textAlign: "left" }}
          >
            {handleStatusFormat(props.value)}
          </div>
        ),
        headerClassName: "table-header",
        className: "table-cell",
        style: { "white-space": "unset" },
        width: 100,
      },
      {
        //   Cell: props => <div style={{ textAlign: "left" }} className='number'>{`₦${formatAmount(convertNgkToNgn(props.value))}`}</div>,
        //   headerClassName: 'table-header',
        //   width: 150,
        // width: 150,
        Header: (props) => <div style={{ textAlign: "left" }}>Action</div>,
        Cell: (props) => (
          <Dropdown>
            <Dropdown.Toggle
              variant="secondary"
              id="dropdown-variants-secondary"
            >
              <MdMore
                fontSize="15px"
                color={process.env.REACT_APP_GREY_COLOUR}
              />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {!(props.original.transactionTypeInt === REVERSAL_INT) && (
                <Dropdown.Item onClick={() => handleReceiptOpen(props)}>
                  View Receipt
                </Dropdown.Item>
              )}
              <Dropdown.Item onClick={() => handleOpen(props)}>
                View Details
              </Dropdown.Item>
              {props.original.statusCode === PENDING && (
                <Dropdown.Item
                  onClick={() =>
                    this.requeryTransaction(props.original.transactionRef)
                  }
                >
                  Update Transaction
                </Dropdown.Item>
              )}
              {process.env.REACT_APP_NAV_MENU_SHOW_COMPLAINT == "true" && (
                <Dropdown.Item onClick={() => handleComplaint(props.original)}>
                  Make a Complaint
                </Dropdown.Item>
              )}
              {/* {props.original.transactionTypeInt === REVERSAL_INT && <Dropdown.Item onClick={() => this.searchByTransRef(props.original.narration.replace(/[^\d]/g, ''))}>Show Failed Transaction</Dropdown.Item>} */}
            </Dropdown.Menu>
          </Dropdown>
        ),
        headerClassName: "table-header",
        className: "table-cell",
        style: { "white-space": "unset" },
        width: 60,
      },
    ];

    return (
      <>
        {this.state.showModal && (
          <VerticallyCenteredModal
            onHide={handleClose}
            loadingComplaint={this.state.loadingComplaint}
            handleComplaint={() =>
              handleComplaint(this.state.transactionDetails)
            }
            show={this.state.showModal}
            title="Transaction Details"
            body={
              <TransactionModalBody
                userSerializer={this.props.userSerializer}
                showFee={true}
                showReversalSection={showReversalSection}
                showReceiptLogo={false}
                isReversalDetailLoading={this.state.isReversalDetailLoading}
                reversalDetails={this.state.reversalDetails}
                searchByTransRef={this.searchByTransRef}
                details={this.state.transactionDetails}
              />
            }
          />
        )}
        {this.state.showReceipt && (
          <ReceiptModal
            onHide={handleClose}
            show={this.state.showReceipt}
            title="Transaction Receipt"
            body={
              <TransactionModalBody
                userSerializer={this.props.userSerializer}
                showFee={true}
                showReceiptLogo={true}
                details={this.state.transactionDetails}
                billerLogo={
                  this.state.searchServices &&
                  this.state.searchServices.imageUrl
                }
              />
            }
          />
        )}
        {this.state.isRequeryLoading ? (
          <ActivityIndicator />
        ) : (
          <ReactTable
            PaginationComponent={Pagination}
            columns={transactionHistoryColumns}
            data={this.props.data}
            dataCount={this.props.dataCount}
            pageSizes={getPaginationRows(this.props.dataCount)}
            pages={this.props.pages}
            // defaultPageSize={50}
            manual
            onFetchData={(state, instance) => {
              this.props.getTransactionsByDateRange(
                this.props.startDate,
                this.props.endDate,
                this.props.selectedOption.value,
                this.props.statusOption.value,
                state.pageSize,
                state.page + 1,
                this.props.domainCode
              );
            }}
            loading={this.props.loading}
            minRows={this.props.data.length > 0 ? 3 : 2}
            LoadingComponent={Spinner}
            getPaginationProps={(state, rowInfo, column, instance) => ({
              style: {
                overflow: "visible",
                backgroundColor: "#ffffff",
              },
            })}
          />
        )}
      </>
    );
  }
}

function getReceiptHeader(logo) {
  return (
    <div>
      <img
        alt=""
        style={{
          top: "40px",
          left: "500px",
          width: "100px",
          float: "left",
        }}
        src={logo}
      />
      <img
        alt=""
        style={{
          top: "40px",
          left: "500px",
          width: "100px",
          float: "right",
        }}
        src="https://i.ibb.co/Qbsjn5b/isw.png"
      />
      <p
        style={{
          color: process.env.REACT_APP_BLUE_COLOUR,
          paddingTop: "5px",
          textAlign: "center",
        }}
      >
        <h4>
          <b>Thank you for using Quickteller Paypoint!</b>
        </h4>
        <span>Here is your transaction receipt.</span>
        <span>See payment details below.</span>
      </p>

      <p style={{ textAlign: "right" }}>
        <Text
          style={{
            fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            color: process.env.REACT_APP_GREY_COLOUR,
          }}
        >
          ***RE-PRINT***
        </Text>
      </p>
    </div>
  );
}

function processPins(pins, paymentCode) {
  if (pins?.length > 25) {
    let splitPins = pins.split(";");
    let fields = {
      "Activation Token 1:": splitPins[0],
      "Activation Token 2:": splitPins[1],
      "Credit Token:": splitPins[2],
    };

    return fields;
  } else {
    if (ikedcCodes.includes(paymentCode)) {
      return { Token: pins };
    } else {
      return { PIN: pins };
    }
  }
}

const TransactionModalBody = (props) => {
  const extras = props.details?.extraInfoList
    ? props.details?.extraInfoList
    : [];
  let processedExtra =
    Boolean(extras) && transactionExtraSerializerReport(extras);

  let orderedData = {};

  if (Object.keys(processedExtra).length > 0) {
    Object.keys(processedExtra).map(
      (value) => (orderedData[value] = processedExtra[value])
    );

    if (props.payments?.billsPaymentRequest?.customerType)
      orderedData["Tariff Band"] =
        props.payments.billsPaymentRequest.customerType;
  }

  return (
    <>
      {props.details.description === "Requery in progress. Checkback again" && (
        <p style={{ fontSize: "1.1rem" }}>{props.details.description}</p>
      )}

      {(props.details?.transactionType === "TRANSFER" ||
        props.details?.transactionType === TRANSFER_CASH_IN ||
        props.details?.transactionType === "NIP" ||
        props.details?.transactionType === "W2W" ||
        props.details?.transactionType === "CAC_REGISTRATION" ||
        props.details?.transactionType === "POS_PURCHASE") && (
        <div id="print-receipt">
          {props.showReceiptLogo && getReceiptHeader(props.billerLogo)}

          <Row style={{ marginTop: "15px" }}>
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  fontWeight: "bold",
                }}
              >
                Amount:{" "}
              </Text>
              <Text
                style={{
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  fontWeight: "bold",
                  textAlign: "right",
                }}
              >{`NGN${formatAmount(
                convertNgkToNgn(props.details.transactionAmount)
              )}`}</Text>
            </Col>
          </Row>

          {props.showFee &&
            props.details.borneBy !== BILLER_BORNE &&
            props.details.fee > 0 && (
              <Row style={{ marginTop: "15px" }}>
                <Col md={12} style={{ display: "flex" }}>
                  <Text
                    style={{
                      paddingLeft: 20,
                      width: 200,
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      fontWeight: "bold",
                    }}
                  >
                    Fee:{" "}
                  </Text>
                  <Text
                    style={{
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      fontWeight: "bold",
                    }}
                  >
                    {props.details?.transactionType === "CAC_REGISTRATION"
                      ? `NGN${formatAmount(convertNgkToNgn(0))}`
                      : `NGN${formatAmount(
                          convertNgkToNgn(props.details.fee)
                        )}`}
                  </Text>
                </Col>
              </Row>
            )}

          <hr />
          {props.details?.transactionType === "POS_PURCHASE" || (
            <>
              {props.details?.transactionType === "CAC_REGISTRATION" ? (
                <Row style={{ marginTop: "15px" }}>
                  <Col md={12} style={{ display: "flex" }}>
                    <Text
                      style={{
                        paddingLeft: 20,
                        width: 200,
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      }}
                    >
                      Beneficiary Name:{" "}
                    </Text>
                    <Text
                      style={{
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        textTransform: "capitalize",
                      }}
                    >{`${props.details.customerFirstName}  ${
                      props.details.customerLastName
                        ? props.details.customerLastName
                        : ""
                    }`}</Text>
                  </Col>
                </Row>
              ) : (
                <Row style={{ marginTop: "15px" }}>
                  <Col md={12} style={{ display: "flex" }}>
                    <Text
                      style={{
                        paddingLeft: 20,
                        width: 200,
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      }}
                    >
                      Beneficiary Name:{" "}
                    </Text>
                    <Text
                      style={{
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        textTransform: "capitalize",
                      }}
                    >{`${props.details.beneficiaryFirstName} ${
                      props.details.beneficiaryOtherName
                        ? props.details.beneficiaryOtherName
                        : ""
                    } ${
                      props.details.beneficiaryLastName
                        ? props.details.beneficiaryLastName
                        : ""
                    }`}</Text>
                  </Col>
                </Row>
              )}

              {props.details?.transactionType === "CAC_REGISTRATION" ? (
                <Row style={{ marginTop: "15px" }}>
                  <Col md={12} style={{ display: "flex" }}>
                    <Text
                      style={{
                        paddingLeft: 20,
                        width: 200,
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      }}
                    >
                      Beneficiary Number:{" "}
                    </Text>
                    <Text
                      style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
                    >
                      {handleCustomerIdFormat(props.details.mobileNo)}
                    </Text>
                  </Col>
                </Row>
              ) : (
                <Row style={{ marginTop: "15px" }}>
                  <Col md={12} style={{ display: "flex" }}>
                    <Text
                      style={{
                        paddingLeft: 20,
                        width: 200,
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      }}
                    >
                      Beneficiary Number:{" "}
                    </Text>
                    <Text
                      style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
                    >
                      {handleCustomerIdFormat(
                        props.details.beneficiaryMobileNumber
                      )}
                    </Text>
                  </Col>
                </Row>
              )}

              <Row
                hidden={props.details.transactionType === "W2W"}
                style={{ marginTop: "15px" }}
              >
                <Col md={12} style={{ display: "flex" }}>
                  <Text
                    style={{
                      paddingLeft: 20,
                      width: 200,
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    }}
                  >
                    Bank:{" "}
                  </Text>
                  <Text
                    style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
                  >
                    {props.details.bankName}
                  </Text>
                </Col>
              </Row>

              <Row
                hidden={props.details.transactionType === "W2W"}
                style={{ marginTop: "15px" }}
              >
                <Col md={12} style={{ display: "flex" }}>
                  <Text
                    style={{
                      paddingLeft: 20,
                      width: 200,
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    }}
                  >
                    Account Number:{" "}
                  </Text>
                  <Text
                    style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
                  >
                    {props.details.beneficiaryAccountNumber}
                  </Text>
                </Col>
              </Row>

              <Row style={{ marginTop: "15px" }}>
                <Col md={12} style={{ display: "flex" }}>
                  <Text
                    style={{
                      paddingLeft: 20,
                      width: 200,
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    }}
                  >
                    Sender Name:{" "}
                  </Text>
                  <Text
                    style={{
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      textTransform: "capitalize",
                    }}
                  >{`${props.details.customerFirstName} ${
                    props.details.customerOtherName
                      ? props.details.customerOtherName
                      : ""
                  } ${
                    props.details.customerLastName
                      ? props.details.customerLastName
                      : ""
                  }`}</Text>
                </Col>
              </Row>

              <Row style={{ marginTop: "15px" }}>
                <Col md={12} style={{ display: "flex" }}>
                  <Text
                    style={{
                      paddingLeft: 20,
                      width: 200,
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    }}
                  >
                    Sender Number:{" "}
                  </Text>
                  <Text
                    style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
                  >
                    {handleCustomerIdFormat(props.details.customerMsisdn)}
                  </Text>
                </Col>
              </Row>

              <hr />
            </>
          )}
          {Boolean(extras) &&
            Object.entries(transactionExtraSerializerReport(extras)).map(
              ([key, value]) => (
                <Row style={{ marginTop: "15px" }}>
                  <Col md={12} style={{ display: "flex" }}>
                    <Text
                      style={{
                        paddingLeft: 20,
                        width: 200,
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      }}
                    >
                      {key}:{" "}
                    </Text>
                    <Text
                      style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
                    >
                      {value}
                    </Text>
                  </Col>
                </Row>
              )
            )}
          {Boolean(extras.length) && <hr />}

          <Row style={{ marginTop: "15px" }}>
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                Transaction Type:{" "}
              </Text>
              <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
                {props.details.transactionType === "NIP"
                  ? `${props.details.transactionType} Transfer`
                  : props.details.transactionType === TRANSFER_CASH_IN
                  ? `CASH IN`
                  : props.details.transactionType}
              </Text>
            </Col>
          </Row>
          {props.details.transactionType === "NIP" && (
            <Row style={{ marginTop: "15px" }}>
              <Col md={12} style={{ display: "flex" }}>
                <Text
                  style={{
                    paddingLeft: 20,
                    width: 200,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  }}
                >
                  Service Provider Ref:{" "}
                </Text>
                <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
                  {props.details.serviceProviderTranRef}
                </Text>
              </Col>
            </Row>
          )}

          <Row style={{ marginTop: "15px" }}>
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                Transaction Reference:{" "}
              </Text>
              <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
                {props.details.transactionRef}
              </Text>
            </Col>
          </Row>

          <Row style={{ marginTop: "15px" }}>
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                Transaction Date:{" "}
              </Text>
              <Text
                style={{
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  textAlign: "right",
                }}
              >
                {formatDate(props.details.dateCreated)}
              </Text>
            </Col>
          </Row>

          <Row style={{ marginTop: "15px" }}>
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                Transaction Status:{" "}
              </Text>
              <Text
                style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
                className={handleStatusFormat(props.details.statusCode)}
              >
                {handleStatusFormat(props.details.statusCode)}
              </Text>
            </Col>
          </Row>

          <Row style={{ marginTop: "15px" }}>
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                Description:{" "}
              </Text>
              <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
                {props.details.narration}
              </Text>
            </Col>
          </Row>

          {props.details.remark && (
            <Row style={{ marginTop: "15px" }}>
              <Col md={12} style={{ display: "flex" }}>
                <Text
                  style={{
                    paddingLeft: 20,
                    width: 200,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  }}
                >
                  Remark:{" "}
                </Text>
                <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
                  {props.details.remark}
                </Text>
              </Col>
            </Row>
          )}

          <hr />

          <Row style={{ marginTop: "15px" }}>
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                Agent Business Name:{" "}
              </Text>
              <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
                {sentenceCapitalization(props.details.businessName)}
              </Text>
            </Col>
          </Row>
        </div>
      )}

      {ikedcCodes.includes(props.details?.serviceOption) && (
        <div id="print-receipt">
          {props.showReceiptLogo && getReceiptHeader(props.billerLogo)}

          <hr />

          <Row
            hidden={!props.details.customerFirstName}
            style={{ marginTop: "15px" }}
          >
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                Customer Name:{" "}
              </Text>
              <Text
                style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
              >{`${props.details.customerFirstName} ${props.details.customerLastName}`}</Text>
            </Col>
          </Row>

          {props.details.transactionType === "ACCOUNT_OPENING" && (
            <Row style={{ marginTop: "15px" }}>
              <Col md={12} style={{ display: "flex" }}>
                <Text
                  style={{
                    paddingLeft: 20,
                    width: 200,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  }}
                >
                  Account Name
                </Text>
                <Text
                  style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
                >{`${props.details.customerFirstName} ${props.details.customerLastName}`}</Text>
              </Col>
            </Row>
          )}

          <Row
            hidden={!props.details.accountNumber}
            style={{ marginTop: "15px" }}
          >
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                Account Number:{" "}
              </Text>
              <Text
                style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
              >{`${props.details.accountNumber}`}</Text>
            </Col>
          </Row>

          <Row hidden={!props.details.bankName} style={{ marginTop: "15px" }}>
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                Bank Name:{" "}
              </Text>
              <Text
                style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
              >{`${props.details.bankName}`}</Text>
            </Col>
          </Row>

          {(props.details.transactionType === "DISTRIBUTE" ||
            props.details.transactionType === "FUND") && (
            <Row style={{ marginTop: "15px" }}>
              <Col md={12} style={{ display: "flex" }}>
                <Text
                  style={{
                    paddingLeft: 20,
                    width: 200,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  }}
                >
                  {props.details.transactionType === "DISTRIBUTE"
                    ? `Aggregator Name`
                    : `Agent Name`}
                </Text>
                <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
                  {props.details.agentName}
                </Text>
              </Col>
            </Row>
          )}

          <Row
            hidden={props.details.transactionType === "DISTRIBUTE"}
            style={{ marginTop: "15px" }}
          >
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                Phone Number:{" "}
              </Text>
              <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
                {handleCustomerIdFormat(props.details.customerMsisdn)}
              </Text>
            </Col>
          </Row>

          {props.details.transactionType === "BILLS" &&
            props.details.customerAddress && (
              <Row style={{ marginTop: "15px" }}>
                <Col md={12} style={{ display: "flex" }}>
                  <Text
                    style={{
                      paddingLeft: 20,
                      width: 200,
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      minWidth: minWidth,
                    }}
                  >
                    Address:{" "}
                  </Text>
                  <Text
                    style={{
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      textAlign: "right",
                    }}
                  >
                    {props.details.customerAddress}
                  </Text>
                </Col>
              </Row>
            )}

          <Row
            hidden={
              props.details.transactionType === "RECHARGE" ||
              props.details.transactionType === "FUND" ||
              props.details.transactionType === "PAYPOINT_FUND" ||
              props.details.transactionType === "REVERSAL" ||
              props.details.transactionType === "DISTRIBUTE" ||
              !props.details.customerId
            }
            style={{ marginTop: "15px" }}
          >
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  minWidth: minWidth,
                }}
              >
                {props.details.serviceOption === "051727101" ||
                props.details.serviceOption === "04393801"
                  ? "Account Number"
                  : props.details.serviceOption === "051722602" ||
                    props.details.serviceOption === "04393701"
                  ? "Meter Number"
                  : "Customer ID"}
                :{" "}
              </Text>
              <Text
                style={{
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  textAlign: "right",
                }}
              >
                {handleCustomerIdFormatTwo(props.details.customerId)}
              </Text>
            </Col>
          </Row>

          <hr />

          {props.details.transactionType !== "FUND" &&
            props.details.pin &&
            Object.entries(
              processPins(props.details.pin, props.details.serviceOption)
            ).map(([key, value]) => (
              <Row style={{ marginTop: "15px" }}>
                <Col md={12} style={{ display: "flex" }}>
                  <Text
                    style={{
                      paddingLeft: 20,
                      width: 200,
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    }}
                  >
                    {key}{" "}
                  </Text>
                  <Text
                    style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
                  >
                    {value}
                  </Text>
                </Col>
              </Row>
            ))}

          {props.details.transactionType === "RECHARGE" ? (
            <Row style={{ marginTop: "15px" }}>
              <Col md={12} style={{ display: "flex" }}>
                <Text
                  style={{
                    paddingLeft: 20,
                    width: 200,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  }}
                >
                  Mobile Network:{" "}
                </Text>
                <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
                  {props.details.narration}
                </Text>
              </Col>
            </Row>
          ) : (
            <Row
              hidden={
                props.details.transactionType === "PAYPOINT_FUND" ||
                props.details.transactionType === "DISTRIBUTE"
              }
              style={{ marginTop: "15px" }}
            >
              <Col md={12} style={{ display: "flex" }}>
                <Text
                  style={{
                    paddingLeft: 20,
                    width: 200,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  }}
                >
                  {props.details.transactionType === "REVERSAL"
                    ? "Description"
                    : "Biller Name"}
                  :{" "}
                </Text>
                <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
                  {props.details.narration}
                </Text>
              </Col>
            </Row>
          )}

          <Row
            hidden={!props.details.serviceOptionName}
            style={{ marginTop: "15px" }}
          >
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                {"Service Option"}:{" "}
              </Text>
              <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
                {props.details.serviceOptionName}
              </Text>
            </Col>
          </Row>

          <hr
            hidden={
              props.details.transactionType === "PAYPOINT_FUND" ||
              props.details.transactionType === "REVERSAL" ||
              props.details.transactionType === "DISTRIBUTE"
            }
          />

          <Row style={{ marginTop: "15px" }}>
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  fontWeight: "bold",
                }}
              >
                Amount:{" "}
              </Text>
              <Text
                style={{
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  fontWeight: "bold",
                  textAlign: "right",
                }}
              >{`NGN${formatAmount(
                convertNgkToNgn(props.details.transactionAmount)
              )}`}</Text>
            </Col>
          </Row>

          {props.showFee &&
            props.details.borneBy !== BILLER_BORNE &&
            props.details.fee > 0 && (
              <Row style={{ marginTop: "15px" }}>
                <Col md={12} style={{ display: "flex" }}>
                  <Text
                    style={{
                      paddingLeft: 20,
                      width: 200,
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      fontWeight: "bold",
                    }}
                  >
                    Fee:{" "}
                  </Text>
                  <Text
                    style={{
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      fontWeight: "bold",
                    }}
                  >{`NGN${formatAmount(
                    convertNgkToNgn(props.details.fee)
                  )}`}</Text>
                </Col>
              </Row>
            )}

          {props.showFee &&
            props.details.borneBy !== BILLER_BORNE &&
            props.details.fee > 0 && (
              <Row style={{ marginTop: "15px" }}>
                <Col md={12} style={{ display: "flex" }}>
                  <Text
                    style={{
                      paddingLeft: 20,
                      width: 200,
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      fontWeight: "bold",
                    }}
                  >
                    Fee:{" "}
                  </Text>
                  <Text
                    style={{
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      fontWeight: "bold",
                    }}
                  >{`NGN${formatAmount(
                    convertNgkToNgn(props.details.fee)
                  )}`}</Text>
                </Col>
              </Row>
            )}

          {Boolean(extras) &&
            Object.entries(orderedData).map(
              ([key, value]) =>
                value && (
                  <Row style={{ marginTop: "15px" }}>
                    <Col md={12} style={{ display: "flex" }}>
                      <Text
                        style={{
                          paddingLeft: 20,
                          width: 200,
                          fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        }}
                      >
                        {key}:{" "}
                      </Text>
                      <Text
                        style={{
                          fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        }}
                      >
                        {value}
                      </Text>
                    </Col>
                  </Row>
                )
            )}
          <hr />

          <Row style={{ marginTop: "15px" }}>
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                Transaction Type:{" "}
              </Text>
              <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
                {handleTransactionTypeFormat(props.details.transactionType)}
              </Text>
            </Col>
          </Row>

          <Row style={{ marginTop: "15px" }}>
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                Transaction Reference:{" "}
              </Text>
              <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
                {props.details.transactionRef}
              </Text>
            </Col>
          </Row>

          <Row style={{ marginTop: "15px" }}>
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                Transaction Date:{" "}
              </Text>
              <Text
                style={{
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  textAlign: "right",
                }}
              >
                {formatDate(props.details.dateCreated)}
              </Text>
            </Col>
          </Row>

          <Row style={{ marginTop: "15px" }}>
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                Transaction Status:{" "}
              </Text>
              <Text
                style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
                className={handleStatusFormat(props.details.statusCode)}
              >
                {handleStatusFormat(props.details.statusCode)}
              </Text>
            </Col>
          </Row>

          {props.details.parentReference && (
            <Row style={{ marginTop: "15px" }}>
              <Col md={12} style={{ display: "flex" }}>
                <Text
                  style={{
                    paddingLeft: 20,
                    width: 200,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    fontWeight: "bold",
                  }}
                >
                  Parent Reference:{" "}
                </Text>
                <Text
                  style={{
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    fontWeight: "bold",
                  }}
                >
                  {props.details.parentReference}
                </Text>
              </Col>
            </Row>
          )}

          <hr />
          <Row style={{ marginTop: "15px" }}>
            <Col md={12} style={{ display: "flex" }}>
              <Text style={{ paddingLeft: 20, width: 200, fontSize: "15px" }}>
                Business Name:{" "}
              </Text>
              <Text style={{ fontSize: "15px" }}>
                {sentenceCapitalization(props.details.businessName)}
              </Text>
            </Col>
          </Row>
        </div>
      )}

      {(props.details?.transactionType === "BILLS" ||
        props.details.transactionType === "CASH_IN" ||
        props.details.transactionType === "RECHARGE" ||
        props.details.transactionType === "CASH_OUT" ||
        props.details.transactionType === "FUND" ||
        props.details.transactionType === "PAYPOINT_FUND" ||
        props.details.transactionType === "COMMISSION_UNLOAD" ||
        props.details.transactionType === "REFUND" ||
        props.details.transactionType === "RETRIEVAL" ||
        props.details.transactionType === "DISTRIBUTE" ||
        props.details?.transactionType === "ACCOUNT_OPENING") &&
        !ikedcCodes.includes(props.details?.serviceOption) && (
          <div id="print-receipt">
            {props.showReceiptLogo && getReceiptHeader(props.billerLogo)}

            <Row style={{ marginTop: "15px" }}>
              <Col md={12} style={{ display: "flex" }}>
                <Text
                  style={{
                    paddingLeft: 20,
                    width: 200,
                    fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                    fontWeight: "bold",
                  }}
                >
                  Amount:{" "}
                </Text>
                <Text
                  style={{
                    fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                    fontWeight: "bold",
                    textAlign: "right",
                  }}
                >{`NGN${formatAmount(
                  convertNgkToNgn(props.details.transactionAmount)
                )}`}</Text>
              </Col>
            </Row>

            {props.showFee &&
              props.details.borneBy !== BILLER_BORNE &&
              props.details.fee > 0 && (
                <Row style={{ marginTop: "15px" }}>
                  <Col md={12} style={{ display: "flex" }}>
                    <Text
                      style={{
                        paddingLeft: 20,
                        width: 200,
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        fontWeight: "bold",
                      }}
                    >
                      Fee:{" "}
                    </Text>
                    <Text
                      style={{
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        fontWeight: "bold",
                      }}
                    >{`NGN${formatAmount(
                      convertNgkToNgn(props.details.fee)
                    )}`}</Text>
                  </Col>
                </Row>
              )}

            <hr />

            <Row
              hidden={!props.details.customerFirstName}
              style={{ marginTop: "15px" }}
            >
              <Col md={12} style={{ display: "flex" }}>
                <Text
                  style={{
                    paddingLeft: 20,
                    width: 200,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  }}
                >
                  Customer Name:{" "}
                </Text>
                <Text
                  style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
                >{`${props.details.customerFirstName} ${props.details.customerLastName}`}</Text>
              </Col>
            </Row>

            {props.details.transactionType === "ACCOUNT_OPENING" && (
              <Row style={{ marginTop: "15px" }}>
                <Col md={12} style={{ display: "flex" }}>
                  <Text
                    style={{
                      paddingLeft: 20,
                      width: 200,
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    }}
                  >
                    Account Name
                  </Text>
                  <Text
                    style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
                  >{`${props.details.customerFirstName} ${props.details.customerLastName}`}</Text>
                </Col>
              </Row>
            )}

            <Row
              hidden={!props.details.accountNumber}
              style={{ marginTop: "15px" }}
            >
              <Col md={12} style={{ display: "flex" }}>
                <Text
                  style={{
                    paddingLeft: 20,
                    width: 200,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  }}
                >
                  Account Number:{" "}
                </Text>
                <Text
                  style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
                >{`${props.details.accountNumber}`}</Text>
              </Col>
            </Row>

            <Row hidden={!props.details.bankName} style={{ marginTop: "15px" }}>
              <Col md={12} style={{ display: "flex" }}>
                <Text
                  style={{
                    paddingLeft: 20,
                    width: 200,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  }}
                >
                  Bank Name:{" "}
                </Text>
                <Text
                  style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
                >{`${props.details.bankName}`}</Text>
              </Col>
            </Row>

            {(props.details.transactionType === "DISTRIBUTE" ||
              props.details.transactionType === "FUND") && (
              <Row style={{ marginTop: "15px" }}>
                <Col md={12} style={{ display: "flex" }}>
                  <Text
                    style={{
                      paddingLeft: 20,
                      width: 200,
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    }}
                  >
                    {props.details.transactionType === "DISTRIBUTE"
                      ? `Aggregator Name`
                      : `Agent Name`}
                  </Text>
                  <Text
                    style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
                  >
                    {props.details.agentName}
                  </Text>
                </Col>
              </Row>
            )}

            <Row
              hidden={props.details.transactionType === "DISTRIBUTE"}
              style={{ marginTop: "15px" }}
            >
              <Col md={12} style={{ display: "flex" }}>
                <Text
                  style={{
                    paddingLeft: 20,
                    width: 200,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  }}
                >
                  Phone Number:{" "}
                </Text>
                <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
                  {handleCustomerIdFormat(props.details.customerMsisdn)}
                </Text>
              </Col>
            </Row>

            {props.details.transactionType === "DISTRIBUTE" && (
              <Row style={{ marginTop: "15px" }}>
                <Col md={12} style={{ display: "flex" }}>
                  <Text
                    style={{
                      paddingLeft: 20,
                      width: 200,
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    }}
                  >
                    Phone Number:{" "}
                  </Text>
                  <Text
                    style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
                  >
                    {handleCustomerIdFormat(props.details.username)}
                  </Text>
                </Col>
              </Row>
            )}

            {(props.details.transactionType === "DISTRIBUTE" ||
              props.details.transactionType === "FUND") && (
              <Row style={{ marginTop: "15px" }}>
                <Col md={12} style={{ display: "flex" }}>
                  <Text
                    style={{
                      paddingLeft: 20,
                      width: 200,
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      minWidth: minWidth,
                    }}
                  >
                    Email:{" "}
                  </Text>
                  <Text
                    style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
                  >
                    {handleCustomerIdFormat(props.details.customerEmail)}
                  </Text>
                </Col>
              </Row>
            )}

            {props.details.transactionType === "BILLS" &&
              props.details.customerAddress && (
                <Row style={{ marginTop: "15px" }}>
                  <Col md={12} style={{ display: "flex" }}>
                    <Text
                      style={{
                        paddingLeft: 20,
                        width: 200,
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        minWidth: minWidth,
                      }}
                    >
                      Address:{" "}
                    </Text>
                    <Text
                      style={{
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        textAlign: "right",
                      }}
                    >
                      {props.details.customerAddress}
                    </Text>
                  </Col>
                </Row>
              )}

            <Row
              hidden={
                props.details.transactionType === "RECHARGE" ||
                props.details.transactionType === "FUND" ||
                props.details.transactionType === "PAYPOINT_FUND" ||
                props.details.transactionType === "REVERSAL" ||
                props.details.transactionType === "DISTRIBUTE" ||
                !props.details.customerId
              }
              style={{ marginTop: "15px" }}
            >
              <Col md={12} style={{ display: "flex" }}>
                <Text
                  style={{
                    paddingLeft: 20,
                    width: 200,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    minWidth: minWidth,
                  }}
                >
                  Customer ID:{" "}
                </Text>
                <Text
                  style={{
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    textAlign: "right",
                  }}
                >
                  {handleCustomerIdFormatTwo(props.details.customerId)}
                </Text>
              </Col>
            </Row>

            <hr />

            {props.details.transactionType !== "FUND" &&
              props.details.pin &&
              Object.entries(
                processPins(props.details.pin, props.details.serviceOption)
              ).map(([key, value]) => (
                <Row style={{ marginTop: "15px" }}>
                  <Col md={12} style={{ display: "flex" }}>
                    <Text
                      style={{
                        paddingLeft: 20,
                        width: 200,
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      }}
                    >
                      {key}{" "}
                    </Text>
                    <Text
                      style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
                    >
                      {value}
                    </Text>
                  </Col>
                </Row>
              ))}

            {props.details.transactionType === "RECHARGE" ? (
              <Row style={{ marginTop: "15px" }}>
                <Col md={12} style={{ display: "flex" }}>
                  <Text
                    style={{
                      paddingLeft: 20,
                      width: 200,
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    }}
                  >
                    Mobile Network:{" "}
                  </Text>
                  <Text
                    style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
                  >
                    {props.details.narration}
                  </Text>
                </Col>
              </Row>
            ) : (
              <Row
                hidden={
                  props.details.transactionType === "PAYPOINT_FUND" ||
                  props.details.transactionType === "DISTRIBUTE"
                }
                style={{ marginTop: "15px" }}
              >
                <Col md={12} style={{ display: "flex" }}>
                  <Text
                    style={{
                      paddingLeft: 20,
                      width: 200,
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    }}
                  >
                    {props.details.transactionType === "REVERSAL"
                      ? "Description"
                      : "Biller Name"}
                    :{" "}
                  </Text>
                  <Text
                    style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
                  >
                    {props.details.narration}
                  </Text>
                </Col>
              </Row>
            )}

            <Row
              hidden={!props.details.serviceOptionName}
              style={{ marginTop: "15px" }}
            >
              <Col md={12} style={{ display: "flex" }}>
                <Text
                  style={{
                    paddingLeft: 20,
                    width: 200,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  }}
                >
                  {"Service Option"}:{" "}
                </Text>
                <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
                  {props.details.serviceOptionName}
                </Text>
              </Col>
            </Row>

            <hr
              hidden={
                props.details.transactionType === "PAYPOINT_FUND" ||
                props.details.transactionType === "REVERSAL" ||
                props.details.transactionType === "DISTRIBUTE"
              }
            />

            {Boolean(extras) &&
              Object.entries(transactionExtraSerializerReport(extras)).map(
                ([key, value]) => (
                  <Row style={{ marginTop: "15px" }}>
                    <Col md={12} style={{ display: "flex" }}>
                      <Text
                        style={{
                          paddingLeft: 20,
                          width: 200,
                          fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        }}
                      >
                        {key}:{" "}
                      </Text>
                      <Text
                        style={{
                          fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        }}
                      >
                        {value}
                      </Text>
                    </Col>
                  </Row>
                )
              )}
            {Boolean(extras.length) && <hr />}

            <Row style={{ marginTop: "15px" }}>
              <Col md={12} style={{ display: "flex" }}>
                <Text
                  style={{
                    paddingLeft: 20,
                    width: 200,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  }}
                >
                  Transaction Type:{" "}
                </Text>
                <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
                  {handleTransactionTypeFormat(props.details.transactionType)}
                </Text>
              </Col>
            </Row>

            <Row style={{ marginTop: "15px" }}>
              <Col md={12} style={{ display: "flex" }}>
                <Text
                  style={{
                    paddingLeft: 20,
                    width: 200,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  }}
                >
                  Transaction Reference:{" "}
                </Text>
                <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
                  {props.details.transactionRef}
                </Text>
              </Col>
            </Row>

            <Row style={{ marginTop: "15px" }}>
              <Col md={12} style={{ display: "flex" }}>
                <Text
                  style={{
                    paddingLeft: 20,
                    width: 200,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  }}
                >
                  Transaction Date:{" "}
                </Text>
                <Text
                  style={{
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    textAlign: "right",
                  }}
                >
                  {formatDate(props.details.dateCreated)}
                </Text>
              </Col>
            </Row>

            <Row style={{ marginTop: "15px" }}>
              <Col md={12} style={{ display: "flex" }}>
                <Text
                  style={{
                    paddingLeft: 20,
                    width: 200,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  }}
                >
                  Transaction Status:{" "}
                </Text>
                <Text
                  style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
                  className={handleStatusFormat(props.details.statusCode)}
                >
                  {handleStatusFormat(props.details.statusCode)}
                </Text>
              </Col>
            </Row>

            {props.details.parentReference && (
              <Row style={{ marginTop: "15px" }}>
                <Col md={12} style={{ display: "flex" }}>
                  <Text
                    style={{
                      paddingLeft: 20,
                      width: 200,
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      fontWeight: "bold",
                    }}
                  >
                    Parent Reference:{" "}
                  </Text>
                  <Text
                    style={{
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      fontWeight: "bold",
                    }}
                  >
                    {props.details.parentReference}
                  </Text>
                </Col>
              </Row>
            )}

            <hr />

            <Row style={{ marginTop: "15px" }}>
              <Col md={12} style={{ display: "flex" }}>
                <Text style={{ paddingLeft: 20, width: 200, fontSize: "15px" }}>
                  Business Name:{" "}
                </Text>
                <Text style={{ fontSize: "15px" }}>
                  {sentenceCapitalization(props.details.businessName)}
                </Text>
              </Col>
            </Row>
          </div>
        )}

      {props.details?.transactionType === "REVERSAL" && (
        <div id="print-receipt">
          {props.showReceiptLogo && getReceiptHeader()}

          <Row style={{ marginTop: "15px" }}>
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                  fontWeight: "bold",
                }}
              >
                Amount:{" "}
              </Text>
              <Text
                style={{
                  fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                  fontWeight: "bold",
                  textAlign: "right",
                }}
              >{`NGN${formatAmount(
                convertNgkToNgn(props.details.transactionAmount)
              )}`}</Text>
            </Col>
          </Row>

          {props.showFee &&
            props.details.borneBy !== BILLER_BORNE &&
            props.details.fee > 0 && (
              <Row style={{ marginTop: "15px" }}>
                <Col md={12} style={{ display: "flex" }}>
                  <Text
                    style={{
                      paddingLeft: 20,
                      width: 200,
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      fontWeight: "bold",
                    }}
                  >
                    Fee:{" "}
                  </Text>
                  <Text
                    style={{
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      fontWeight: "bold",
                    }}
                  >{`NGN${formatAmount(
                    convertNgkToNgn(props.details.fee)
                  )}`}</Text>
                </Col>
              </Row>
            )}

          <hr />

          <Row style={{ marginTop: "15px" }}>
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                Phone Number:{" "}
              </Text>
              <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
                {handleCustomerIdFormat(props.details.customerMsisdn)}
              </Text>
            </Col>
          </Row>

          <hr />

          {Boolean(extras) &&
            Object.entries(transactionExtraSerializerReport(extras)).map(
              ([key, value]) => (
                <Row style={{ marginTop: "15px" }}>
                  <Col md={12} style={{ display: "flex" }}>
                    <Text
                      style={{
                        paddingLeft: 20,
                        width: 200,
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      }}
                    >
                      {key}:{" "}
                    </Text>
                    <Text
                      style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
                    >
                      {value}
                    </Text>
                  </Col>
                </Row>
              )
            )}
          {Boolean(extras.length) && <hr />}

          <Row style={{ marginTop: "15px" }}>
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                {props.details.transactionType === "REVERSAL"
                  ? "Description"
                  : "Biller Name"}
                :{" "}
              </Text>
              <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
                {props.details.narration}
              </Text>
            </Col>
          </Row>

          <Row
            hidden={!props.details.serviceOptionName}
            style={{ marginTop: "15px" }}
          >
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                {"Service Option"}:{" "}
              </Text>
              <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
                {props.details.serviceOptionName}
              </Text>
            </Col>
          </Row>

          <Row style={{ marginTop: "15px" }}>
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                Transaction Type:{" "}
              </Text>
              <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
                {handleTransactionTypeFormat(props.details.transactionType)}
              </Text>
            </Col>
          </Row>

          <Row style={{ marginTop: "15px" }}>
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                Transaction Reference:{" "}
              </Text>
              <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
                {props.details.transactionRef}
              </Text>
            </Col>
          </Row>

          <Row style={{ marginTop: "15px" }}>
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                Transaction Date:{" "}
              </Text>
              <Text
                style={{
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  textAlign: "right",
                }}
              >
                {formatDate(props.details.dateCreated)}
              </Text>
            </Col>
          </Row>

          <Row style={{ marginTop: "15px" }}>
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                Transaction Status:{" "}
              </Text>
              <Text
                style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
                className={handleStatusFormat(props.details.statusCode)}
              >
                {handleStatusFormat(props.details.statusCode)}
              </Text>
            </Col>
          </Row>

          <hr />

          <Row style={{ marginTop: "15px" }}>
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                Agent Business Name:{" "}
              </Text>
              <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
                {sentenceCapitalization(props.details.businessName)}
              </Text>
            </Col>
          </Row>

          <Row style={{ marginTop: "15px" }}>
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                Check original transaction details{" "}
                <Link
                  onClick={() =>
                    props.searchByTransRef(
                      props.details.narration.replace(/[^\d]/g, "")
                    )
                  }
                >
                  {props.details.narration}
                </Link>{" "}
              </Text>
            </Col>
          </Row>
        </div>
      )}

      {!props.showReceiptLogo && props.showReversalSection(props.details) && (
        <div>
          <hr />
          <Row style={{ marginTop: "15px" }}>
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  fontWeight: "bold",
                }}
              >
                Reversal Details:{" "}
              </Text>
              {/* <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE, fontWeight: 'bold'}}>{`NGN${formatAmount(convertNgkToNgn(props.details.transactionAmount))}`}</Text> */}
            </Col>
          </Row>
          {props.isReversalDetailLoading && (
            <Spinner loading={props.isReversalDetailLoading} />
          )}
          {!props.isReversalDetailLoading && (
            <div>
              <Row style={{ marginTop: "15px" }}>
                <Col md={12} style={{ display: "flex" }}>
                  <Text
                    style={{
                      paddingLeft: 20,
                      width: 200,
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    }}
                  >
                    Amount:{" "}
                  </Text>
                  <Text
                    style={{
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      textAlign: "right",
                    }}
                  >{`NGN${formatAmount(
                    convertNgkToNgn(props.reversalDetails?.reverseTransAmount)
                  )}`}</Text>
                </Col>
              </Row>
              <Row style={{ marginTop: "15px" }}>
                <Col md={12} style={{ display: "flex" }}>
                  <Text
                    style={{
                      paddingLeft: 20,
                      width: 200,
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    }}
                  >
                    Reference:{" "}
                  </Text>
                  <Text
                    style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
                  >
                    {props.reversalDetails?.reverseTransRef}
                  </Text>
                </Col>
              </Row>
              <Row style={{ marginTop: "15px" }}>
                <Col md={12} style={{ display: "flex" }}>
                  <Text
                    style={{
                      paddingLeft: 20,
                      width: 200,
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    }}
                  >
                    Status:{" "}
                  </Text>
                  <Text
                    style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
                    className={handleReversalTransactionStatusFormat(
                      props.details?.statusCode
                    )}
                  >
                    {handleReversalTransactionStatusFormat(
                      props.reversalDetails?.statusCode
                    )}
                  </Text>
                </Col>
              </Row>
              <Row style={{ marginTop: "15px" }}>
                <Col md={12} style={{ display: "flex" }}>
                  <Text
                    style={{
                      paddingLeft: 20,
                      width: 200,
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    }}
                  >
                    Date:{" "}
                  </Text>
                  <Text
                    style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
                  >
                    {formatDate(props.reversalDetails?.dateCreated)}
                  </Text>
                </Col>
              </Row>
            </div>
          )}
        </div>
      )}
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: (pendingUrl) => dispatch(navigateTo(pendingUrl)),
  };
}
export default connect(null, mapDispatchToProps)(TransactionsReportTable);
