import { connect } from 'react-redux';
import { updateLoading, dismissErrorMessage, showErrorMessage, dismissSuccessMessage, showSuccessMessage } from '../../../../services/redux/actions/tunnel'
import SecuritySettingsScene from './scene'

function mapStateToProps(state) {
  return {
    isLoading: state.tunnel.isLoading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateLoading: isLoading => dispatch(updateLoading(isLoading)),
    dismissErrorMessage: message => dispatch(dismissErrorMessage(message)),
    showErrorMessage: message => dispatch(showErrorMessage(message)),
    dismissSuccessMessage: message => dispatch(dismissSuccessMessage(message)),
    showSuccessMessage: message => dispatch(showSuccessMessage(message)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SecuritySettingsScene);
