import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import Platform from "../../../../../services/api/resources/platform";

import IosRefresh from "react-ionicons/lib/IosRefresh";

import UserManagement from "../../../../../services/api/resources/user-management";
import PrimaryButton from "../component/button";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../../constants/api";
import { notify } from "../../../../../components/extras";
import { greenCheckIcon } from "../../../../../icons";
import MdClose from "react-ionicons/lib/MdClose";
import InputText from "../component/input";
import { stripNonNumbers } from "../component/extras";
import BackButton from "../component/backButton";
import H1 from "../../../../../components/h1";
import Title from "../component/titleRevamp.js";
import OtpInput from "../component/otpInput";
import Onboarding from "../../../../../services/api/resources/onboarding.js";

const BvnValidationScene = ({ application, setScreen, kyc_id }) => {
  const history = useHistory();

  const onboarding = new Onboarding();
  const [showModal, setShowModal] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoadingOtp, setIsLoadingOtp] = useState(false);
  const [bvnNumber, setBvnNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [responseObj, setResponseObj] = useState(null);
  const [bvnValidationForm, setBvnValidationForm] = useState({
    bvn: application ? application?.applicantDetails?.bvnValidation?.bvn : "",
  });

  useEffect(() => {
    const updatedBvnValidationForm = JSON.parse(
      localStorage.getItem("bvnValidationForm")
    );

    if (updatedBvnValidationForm) {
      setBvnValidationForm({
        bvn:
          application?.applicantDetails?.bvnValidation?.bvn ||
          updatedBvnValidationForm.bvn ||
          "",
      });
    }
  }, [application]);

  useEffect(() => {
    localStorage.setItem(
      "bvnValidationForm",
      JSON.stringify(bvnValidationForm)
    );
  }, [bvnValidationForm]);

  const [isLoading, setIsLoading] = useState(false);

  const isBvnValid = (newForm) => {
    const form = newForm;

    return form.bvn && form.bvn.length >= 11 && !form.bvn.includes(" ");
  };

  const updateFormField = (params) => {
    const newForm = {
      ...bvnValidationForm,
      ...params,
    };

    setBvnValidationForm(newForm);
  };

  const congratsModal = () => {
    setShowModal(true);
  };
  const otpModal = () => {
    setShowOtpModal(!showOtpModal);
  };
  const navigateToNinVerification = () => {
    setScreen(4);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const payload = {
      kycId: kyc_id,
      identificationNumber: bvnValidationForm.bvn,
    };

    const { status, response, code } =
      await onboarding.submitInitiateImageCapturing(payload);
    setIsLoading(false);

    if (code === 400) {
      notify(response.description, "error");
    } else if (code === 200) {
      otpModal();
      setBvnNumber(response.kycData.bvnVerificationResponse?.bvnPhoneNumber);
      setResponseObj(response.kycData);
    } else if (code === 409 && response.kycData) {
      setResponseObj(response.kycData);
      setBvnNumber(response.kycData.bvnVerificationResponse?.bvnPhoneNumber);
      otpModal();
    } else if (code === 409 && !response.kycData) {
      notify(response.description, "error");
    } else if (code === 201) {
      notify(response.description, "success");
      navigateToNinVerification();
    }
  };
  const navigateToDashboard = () => {
    history.push({
      pathname: "/dashboard",
    });
  };
  const navigateToFaceVerification = () => {
    setScreen(10);
    // history.push({
    //   pathname: `${process.env.REACT_APP_ROUTE_BASENAME}/agents/setup-new-agent/face-verification`,
    //   state: { jobId: response?.jobId, bvn: bvnValidationForm?.bvn },
    // });
  };

  const isFormValid = () => {
    return bvnValidationForm.bvn && isBvnValid(bvnValidationForm);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const textStyle = {
    color: "#1F2937",
    fontWeight: "500",
    fontSize: "0.8rem",
    letterSpacing: "0.3px",
  };

  const spanStyle = {
    display: "inline-flex",
    alignItems: "center",
  };

  const pStyle = {
    marginLeft: "10px",
    marginTop: "12px",
  };

  const iconStyle = {
    width: "20px",
    height: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const instructions = [
    "Ensure you are in a well-lit area",
    "Make sure you are in front of a plain background",
    "Make sure to remove hats, thick glasses, or anything else that might obscure your face",
    "Make sure you keep your expressions neutral",
  ];

  const callResendOtp = async () => {
    setIsLoadingOtp(true);

    const data = await onboarding.onboardingResendOtp(
      responseObj?.bvnVerificationResponse?.tokenId || responseObj?.tokenId
    );
    const { status, response } = data;

    setIsLoadingOtp(false);
    if (status === ERROR_STATUS) {
      notify(`${response.description}`, "error");
    } else if (status === SUCCESS_STATUS) {
      setResponseObj(response);
      notify("OTP successfully sent!", "success");
    }
  };

  const onSubmitButtonClick = async () => {
    setLoading(true);

    const tokenId =
      responseObj?.bvnVerificationResponse?.tokenId || responseObj?.tokenId;
    const prefix =
      responseObj?.bvnVerificationResponse?.tokenPrefix || responseObj?.prefix;

    const data = await onboarding.onboardingValidateOtp(otp, tokenId, prefix);
    const { status, response } = data;
    setLoading(false);
    if (status === ERROR_STATUS) {
      notify(`${response.description}`, "error");
    } else if (status === SUCCESS_STATUS) {
      otpModal();
      congratsModal();
    }
  };

  const handleOtpChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 6) {
      setOtp(value);
    }
  };

  return (
    <>
      <BackButton
        onClick={() => {
          setScreen(2);
        }}
      />
      <main className="onboarding-container">
        <div
          style={{
            marginBottom: "20px",
            margin: "0 auto",
          }}
        >
          <Title pageNum={"2"} />
          <H1
            style={{
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "1rem",
            }}
          >
            Enter your BVN
          </H1>
          <p
            style={{
              color: "#5F738C",
              fontWeight: "500",
              fontSize: "0.8rem",
              letterSpacing: "0.3px",
            }}
          >
            Dail *565*0# to securely get your BVN from your network provider.
          </p>
          <form onSubmit={handleSubmit}>
            <InputText
              label={"BVN"}
              labelColor={
                !bvnValidationForm.bvn || isBvnValid(bvnValidationForm)
                  ? "black"
                  : process.env.REACT_APP_RED_COLOUR
              }
              border={`1px solid ${
                !bvnValidationForm.bvn || isBvnValid(bvnValidationForm)
                  ? "#DFE2E6"
                  : process.env.REACT_APP_RED_COLOUR
              }`}
              placeholder={"Enter BVN"}
              onChange={(e) => {
                updateFormField({
                  bvn: stripNonNumbers(e.target.value.trim()),
                });
              }}
              maxLength={11}
              value={bvnValidationForm.bvn}
            />

            <button
              disabled={!isFormValid() || isLoading}
              className="signupButton2"
              style={{
                marginTop: "1rem",
                textAlign: "center",
                color: "white",
                backgroundColor: "#00425f",
                width: "100%",
              }}
            >
              {" "}
              {isLoading ? "Processing..." : "Next"}{" "}
            </button>
          </form>
        </div>
      </main>
      <Modal
        show={showModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body style={{ padding: "2rem" }}>
          <div style={{ alignContent: "center", padding: "1rem" }}>
            <h1
              style={{
                fontWeight: "bold",
                fontSize: "1.5rem",
              }}
            >
              Face ID Verification
            </h1>
            <p
              style={{
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "20px",
                color: "#1F2937",
              }}
            >
              Ensure your camera is steady and your face fits into the shape.
            </p>
            <div style={textStyle}>
              {instructions.map((instruction, index) => (
                <span key={index} style={spanStyle}>
                  <div style={iconStyle}>{greenCheckIcon()}</div>
                  <p style={pStyle}>{instruction}</p>
                </span>
              ))}
            </div>

            <button
              onClick={navigateToFaceVerification}
              className="signupButton2"
              style={{
                marginTop: "2rem",
                textAlign: "center",
                color: "white",
                backgroundColor: "#00425f",
                width: "100%",
              }}
            >
              {" "}
              {isLoading ? "Processing..." : "Next"}{" "}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showOtpModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body style={{ padding: windowWidth <= 768 ? "2rem" : "3rem" }}>
          <div style={{ alignContent: "center" }}>
            <form className="otp-conatiner">
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: 700,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span>Enter OTP</span>
                <MdClose
                  onClick={() => otpModal()}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <p style={{ fontSize: "14px", color: "grey" }}>
                An OTP was sent to your BVN phone number {bvnNumber}, kindly
                enter it below
              </p>

              <div
                style={{
                  display: "flex",
                  gap: "7px",
                  alignItems: "center",
                  marginBottom: "-15px",
                }}
              >
                <div
                  style={{
                    width: "5rem",
                    marginBottom: "5px",
                  }}
                >
                  <InputText
                    label={""}
                    placeholder={""}
                    value={`${
                      responseObj?.bvnVerificationResponse?.tokenPrefix ||
                      responseObj?.prefix
                    }`}
                    disabled={true}
                  />
                </div>
                <span
                  style={{
                    borderRadius: "5px",
                    marginTop: "7px",
                    borderBottom: "2px solid grey",
                    width: "10px",
                  }}
                />

                <OtpInput
                  id="otp-input"
                  value={otp}
                  onChange={setOtp}
                  length={6}
                  // onComplete={debouncedHandleOtpSubmit}
                />
              </div>

              <div>
                {isLoadingOtp ? (
                  <IosRefresh fontSize="20px" color="grey" rotate={true} />
                ) : (
                  <span
                    style={{ cursor: "pointer" }}
                    className="otp-resend"
                    onClick={callResendOtp}
                  >
                    Resend
                  </span>
                )}
              </div>

              <PrimaryButton
                onClick={onSubmitButtonClick}
                margin={"20px 0 0 0"}
                disabled={otp.length < 6 || loading}
              >
                {" "}
                {loading ? "Processing..." : "Submit"}
              </PrimaryButton>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BvnValidationScene;
