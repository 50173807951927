import React from "react";
import Lottie from "react-lottie";
import H1 from "../../../../../components/h1";
import Text from "../../../../../components/text";
import animationData from "../../../../../animations/animation-gear";

export default (props) => {
  const defaultOptions = {
    autoplay: true,
    loop: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        marginTop: "25px",
        padding: "5px",
      }}
    >
      <Lottie height={300} options={defaultOptions} width={300} />

      <H1
        style={{
          fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
          fontSize: process.env.REACT_APP_BIG_FONT_SIZE,
          fontWeight: "600",
          marginBottom: "15px",
          marginTop: "0pt",
        }}
      >
        Setup In Progress...
      </H1>
      <Text
        style={{
          color: "#808593",
          fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
          lineHeight: process.env.REACT_APP_MID_BIG_LINE_HEIGHT,
          textAlign: "center",
          maxWidth: "80%",
          width: "450pt",
        }}
      >
        We are currently setting up your {process.env.REACT_APP_NAME} account.
        Please check back in 24 hours.
      </Text>
      <ul
        style={{
          listStyleType: "square",
          color: "#808593",
          fontSize: process.env.REACT_APP_MID_FONT_SIZE,
          lineHeight: process.env.REACT_APP_BIG_LINE_HEIGHT,
          textAlign: "left",
          maxWidth: "55%",
          width: "395pt",
        }}
      >
        For Questions or Feedback, reach out to us on any of the channels below
        <li>
          Email:{" "}
          <a href="mailto:ifiscustomercare@interswitchgroup.com">
            ifiscustomercare@interswitchgroup.com
          </a>
        </li>
        <li>
          Help Portal:{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://help.interswitchgroup.com/"
          >
            Help Portal
          </a>
        </li>
        <li>
          Contact Center: <a href="tel:07009065000">07009065000</a>{" "}
        </li>
      </ul>
    </div>
  );
};
