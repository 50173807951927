import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import BaseScene from '../../../../base-scene'
import Button from '../../../../../components/button'
import FormControl from '../../../../../components/form-control'
import { isPasswordValid } from '../../../../../utils/validators/form-validators'


export default class UpdatePasswordTab extends BaseScene {
  state = {
    form: {

    },
    hidePassword: true,
    hideNewPassword: true,
    hideRepeatNewPassword: true
  }

  isPasswordSame(password) {
    return password === this.state.form.newPassword
  }

  updateFormField(params) {
    this.setState({
      form: {
        ...this.state.form,
        ...params
      }
    })
  }

  isFormValid () {
    const {form} = this.state
    return isPasswordValid(form.password) && isPasswordValid(form.newPassword) && this.isPasswordSame(form.repeatNewPassword)
  }

  render () {
    return <div style={{
      borderRadius: '6px', 
      display: 'flex', 
      flexWrap: 'wrap', 
      justifyContent: 'flex-start', 
      paddingTop: '20px'}}>
      
      <Form>
        <Row style={{margin: 0, padding: 0}}>
          <Col md={12} style={{margin: 0, padding: 0}}>
            <Form.Group>
              <Form.Label style={{color: `${!this.state.form.password || isPasswordValid(this.state.form.password) ?  '#3A3B3B' : process.env.REACT_APP_RED_COLOUR}`, fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Enter current password:</Form.Label>
              
                <Col xs={8} md={4} style={{margin: 0, padding: 0}}>
                  <FormControl 
                    autoComplete="off"
                    type={this.state.hidePassword ? 'password' : 'text'} 
                    placeholder="Password" 
                    onChange={(event) => {
                      const password = event.target.value
                      this.updateFormField({password})
                    }} 
                    style={{
                      backgroundColor: '#FFFFFF', borderRadius: '20px', border: `1px solid ${!this.state.form.password || isPasswordValid(this.state.form.password) ? '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`, boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px'
                    }} />

                  <img 
                    className="hide-password-toggle"
                    src={this.state.hidePassword ? '/assets/media/icons/eye.svg' : '/assets/media/icons/eye-hidden.svg'}
                    style={{marginLeft: this.state.deviceIsPC ? '90%' : '85%'}}
                    onClick={() => this.setState({hidePassword: !this.state.hidePassword})}
                    alt=""
                  />

                  </Col>

            </Form.Group>
          </Col>

          <Col md={12} style={{margin: 0, padding: 0}}>
            <Form.Group>
              <Form.Label style={{color: `${!this.state.form.newPassword || isPasswordValid(this.state.form.newPassword) ?  '#3A3B3B' : process.env.REACT_APP_RED_COLOUR}`, fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Enter a new password:</Form.Label>
              
                <Col xs={8} md={4} style={{margin: 0, padding: 0}}>
                  <FormControl
                    autoComplete="off"
                    type={this.state.hideNewPassword ? 'password' : 'text'}
                    placeholder="New password"
                    onChange={(event) => {
                      const newPassword = event.target.value
                      this.updateFormField({newPassword})
                    }}
                    style={{backgroundColor: '#FFFFFF', borderRadius: '20px', border: `1px solid ${!this.state.form.newPassword || isPasswordValid(this.state.form.newPassword) ? '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`, boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px'}} 
                  />
                  
                  <img 
                    className="hide-password-toggle"
                    src={this.state.hideNewPassword ? '/assets/media/icons/eye.svg' : '/assets/media/icons/eye-hidden.svg'} 
                    style={{marginLeft: this.state.deviceIsPC ? '90%' : '85%'}}
                    onClick={() => this.setState({hideNewPassword: !this.state.hideNewPassword})}
                    alt=""
                    
                  />

                  </Col>
              
            </Form.Group>
          </Col>

          <Col md={12} style={{margin: 0, padding: 0}}>
            <Form.Group>
              <Form.Label style={{color: `${!this.state.form.repeatNewPassword || this.isPasswordSame(this.state.form.repeatNewPassword) ?  '#3A3B3B' : process.env.REACT_APP_RED_COLOUR}`, fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Repeat new password:</Form.Label>
              
                <Col xs={8} md={4} style={{margin: 0, padding: 0}}>
                  <FormControl
                    autoComplete="off"
                    type={this.state.hideRepeatNewPassword ? 'password' : 'text'} 
                    placeholder="Repeat new password" 
                    onChange={(event) => {
                      const repeatNewPassword = event.target.value
                      this.updateFormField({repeatNewPassword})
                    }} 
                    style={{
                      backgroundColor: '#FFFFFF', borderRadius: '20px', border: `1px solid ${!this.state.form.repeatNewPassword || this.isPasswordSame(this.state.form.repeatNewPassword) ? '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`, boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px'
                    }} />

                  <img
                    className="hide-password-toggle"
                    src={this.state.hideRepeatNewPassword ? '/assets/media/icons/eye.svg' : '/assets/media/icons/eye-hidden.svg'}
                    style={{marginLeft: this.state.deviceIsPC ? '90%' : '85%'}}
                    onClick={() => this.setState({hideRepeatNewPassword: !this.state.hideRepeatNewPassword})}
                    alt=""
                  />

                </Col>
              
                <Col xs={4} md={2} style={{display: 'flex', alignItems: 'center'}}>
                </Col>
            </Form.Group>
          </Col>

          <Col xs={8} md={12} style={{margin: 0, padding: 0}}>    
            <Button disabled={!this.isFormValid() || this.props.isLoading} style={{marginTop: '5px', marginLeft: '10px'}} onClick={() => this.props.onSubmit(this.state.form)}>{this.props.isLoading ? 'Loading...' : 'Save Changes'}</Button>
          </Col>

        </Row>
      </Form>

    </div>
  }
}