import React from 'react'
import { Button, FormControl, InputGroup, } from 'react-bootstrap'

export default class SearchPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      form: {

      }
    }

    this.isSearchTermValid = this.isSearchTermValid.bind(this)
    this.updateFormField = this.updateFormField.bind(this)
  }

  isSearchTermValid () {
    const { form } = this.state
    
    return form.searchTerm && form.searchTerm.length > 0
  }

  updateFormField (params) {
    const newFormData = {
      ...this.state.form,
      ...params
    }

    this.setState({
      form: newFormData
    })
  }

  handleSearch = () => {

  }

  render () {
    return <section style={{alignItems: 'center', backgroundColor: '#eef2f4', display: 'flex', justifyContent: 'center', height: '5vh'}}>
      <InputGroup style={{height: '10px', width: '70%'}}> 
        <FormControl defaultValue={this.props.searchTerm} style={{borderBottomLeftRadius: process.env.REACT_APP_BORDER_STYLE === 'square' ? '4px' : '25px', borderTopLeftRadius: process.env.REACT_APP_BORDER_STYLE === 'square' ? '4px' : '25px', padding: '27px'}} type="text" placeholder="Find a biller (eg. DStv)" onChange={(event) => {
          const searchTerm = event.target.value
          this.props.updateFormField({searchTerm})
        }} />
        <InputGroup.Append>
          <Button 
            style={this.props.isSearchTermValid() ? {
              backgroundColor: process.env.REACT_APP_BLUE_COLOUR, 
              borderBottomRightRadius: '25px', 
              borderTopRightRadius: '25px', 
              borderColor: process.env.REACT_APP_BLUE_COLOUR, 
              color: 'white', 
              fontSize: process.env.REACT_APP_MID_FONT_SIZE
            } : {
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              border: '1px solid #ccc', 
              borderBottomRightRadius: process.env.REACT_APP_BORDER_STYLE === 'square' ? '4px' : '25px', 
              borderTopRightRadius: process.env.REACT_APP_BORDER_STYLE === 'square' ? '4px' : '25px'
          }}
          variant="outline-secondary"
          disabled={!this.props.searchTerm}
          onClick={this.props.onSearch}>{this.props.isLoading ? 'Search' : 'Search'}</Button>
        </InputGroup.Append>
      </InputGroup>
    </section>
  }
}
