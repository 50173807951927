import { connect } from 'react-redux';

import SetupAgent from './setupAgent';

import { updateLoading } from '../../../../services/redux/actions/tunnel';
import { navigateTo } from '../../../../services/redux/actions/navigation';

function mapStateToProps(state) {
    return {
        isLoading: state.tunnel.isLoading,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateLoading: (isLoading) => dispatch(updateLoading(isLoading)),
        navigateTo: (pendingUrl) => dispatch(navigateTo(pendingUrl)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SetupAgent);
