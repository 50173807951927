import Requester from '../finch-requester'

const API_BASE_URL = process.env.REACT_APP_ACCOUNT_OPENING_SERVICE_API_BASE_URL

export default class AccountOpening {
  constructor (props) {
    this.apiRequester = props ? props.apiRequester || new Requester({
      apiBaseUrl: API_BASE_URL
    }) : new Requester({
      apiBaseUrl: API_BASE_URL
    })
  }

  sendOtp (deviceUuid, data) {
    return this.apiRequester.post({
      endpoint: 'card-request/send-otp',
      body: data,
      headers: {
        deviceUuid
      }
    })
  }

  resendOtp (requestId) {
    return this.apiRequester.post({
      endpoint: 'card-request/resend-otp/'+requestId
    })
  }

  linkCard (data) {
    return this.apiRequester.post({
      endpoint: 'card-request/link-card',
      body: data
    })
  }

  registerAgent (deviceUuid) {
    return this.apiRequester.post({
      endpoint: 'agent',
      headers: {
        deviceUuid
      }
    })
  }

  registerCardLinking (deviceUuid) {
    return this.apiRequester.post({
      endpoint: 'card-linking-agent',
      headers: {
        deviceUuid
      }
    })
  }

  createForm (deviceUuid, data) {
    return this.apiRequester.post({
      endpoint: 'account/create-form',
      body: data,
      headers: {
        deviceUuid
      }
    })
  }
  
  uploadImage (data) {
    return this.apiRequester.post({
      endpoint: 'account/upload-image',
      body: data
    })
  }
  
  submitAccountOpeningForm (transactionRef) {
    return this.apiRequester.get({
      endpoint: 'account/submit-form/'+transactionRef
    })
  }
  
}