import React from 'react';
import Button from '../../../../../components/button';
import H1 from '../../../../../components/h1';
import Text from '../../../../../components/text';
import MdSync from 'react-ionicons/lib/MdSync';

export default props => {
  return <div 
    style={{
      alignItems: 'center', 
      display: 'flex', 
      flexDirection: 'column', 
      justifyContent: 'space-evenly', 
      marginTop: '0px', 
      padding: '5px'
    }}
  >
    <H1 
      style={{
        color: process.env.REACT_APP_RED_COLOUR,
        fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY, 
        fontSize: process.env.REACT_APP_BIG_FONT_SIZE, 
        fontWeight: '600', 
        marginBottom: '15px', 
        marginTop: '50pt',
      }}
    >
      Something Went Wrong
    </H1>
    <h3 style={{color: 'black'}}>
      Refresh
    </h3>
  </div>
}