import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import Pagination from "../../../../../../components/pagination";
import Spinner from "../../../../../../components/spinner";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../../../constants/api";
import AnalyticsService from "../../../../../../services/api/resources/analytics-service";
import { navigateTo } from "../../../../../../services/redux/actions/navigation";
import { getPaginationRows } from "../../../../../../utils/helpers";
import { BalanceCard } from "./component/balanceCard";

const Balance = (props) => {
  const analyticsService = new AnalyticsService();
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [isLoadingBalance, setIsLoadingBalance] = useState(true);
  const [isLoadingAgent, setIsLoadingAgent] = useState(false);
  const [intervalType, setIntervalType] = useState("");
  const [balance, setBalance] = useState(null);
  const [balanceGrid, setBalanceGrid] = useState([]);
  const [pages, setPages] = useState(1);
  const [pageNum, setPageNum] = useState(1);
  const [dataCount, setDataCount] = useState();

  const [pageSize, setPageSize] = useState(20);
  const filterStyle = (filterName) => {
    return {
      padding: ".5rem 1.5rem",
      backgroundColor: selectedFilter === filterName ? "#00425F" : "white",
      color:
        selectedFilter === filterName
          ? "white"
          : process.env.REACT_APP_BLUE_COLOUR,
      cursor: "pointer",
      border: "1px solid #E1E6ED",
    };
  };
  const handleFilterClick = (filterName) => {
    setSelectedFilter(filterName);
    if (filterName === "All") {
      setIntervalType("");
    } else if (filterName === "Out") {
      setIntervalType("Out");
    } else if (filterName === "Low") {
      setIntervalType("Low");
    } else if (filterName === "Enough") {
      setIntervalType("Enough");
    } else {
      setIntervalType();
    }
  };
  const getBalance = async () => {
    const { response, status } = await analyticsService.getBalance();

    if (status === ERROR_STATUS) {
      setIsLoadingBalance(false);
    } else if (status === SUCCESS_STATUS) {
      const out = response?.data.find((data) => data.status === "Out")?.count;
      const low = response?.data.find((data) => data.status === "Low")?.count;
      const enough = response?.data.find(
        (data) => data.status === "Enough"
      )?.count;

      setBalance({
        out,
        low,
        enough,
      });
      setIsLoadingBalance(false);
    }
  };
  const getBalanceGrid = async (filterStatus, pageNum_, pageSize_) => {
    setIsLoadingAgent(true);

    const { response, status } = await analyticsService.getBalanceGrid(
      filterStatus,
      pageNum_,
      pageSize_
    );

    setIsLoadingAgent(false);
    if (status === ERROR_STATUS) {
      setPages(1);
    } else if (status === SUCCESS_STATUS) {
      setDataCount(response.data.count / pageSize);
      setPages(Math.ceil(response.data.count / pageSize));
      setBalanceGrid(response.data.content);
    }
  };

  const handleNavigation = ({ original }) => {
    localStorage.setItem(
      "agentDetailsFromPermanceBalance",
      JSON.stringify(original)
    );
    props.navigateTo("/distribute");
  };
  const agentColumns = [
    {
      Header: (props) => <React.Fragment>Agent</React.Fragment>,
      Cell: (props) => <div style={{ textAlign: "left" }}>{props.value}</div>,
      accessor: "agentName",
      headerClassName: "table-header",
      className: "table-cell",
      style: { "white-space": "unset" },
    },
    {
      Header: (props) => <React.Fragment>Balance Health</React.Fragment>,
      Cell: (props) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "start",
            }}
          >
            <div
              style={{
                color: statusColor(props.value),
                textAlign: "left",
                textTransform: "capitalize",

                display: "flex",
                justifyContent: "center",

                border: `1px solid ${statusBorderColor(props.value)}`,
                padding: "0 7px",
                borderRadius: "5px",
                backgroundColor: statusBackgroundColor(props.value),
              }}
            >
              <div
                style={{
                  display: "grid",
                  placeContent: "center",
                  marginRight: "5px",
                }}
              >
                <div
                  style={{
                    width: "7px",
                    height: "7px",
                    borderRadius: "100%",

                    display: "grid",
                    placeContent: "center",
                    backgroundColor: statusColor(props.value),
                  }}
                />
              </div>
              <div>{props.value}</div>
            </div>
          </div>
        );
      },
      accessor: "status",
      headerClassName: "table-header",
      className: "table-cell",
      style: { "white-space": "unset" },
    },
    {
      Header: (props) => <React.Fragment>Agent Class</React.Fragment>,
      Cell: (props) => <div style={{ textAlign: "left" }}>{props.value}</div>,
      accessor: "agentClass",
      headerClassName: "table-header",
      className: "table-cell",
      style: { "white-space": "unset" },
    },

    {
      Header: (props) => <React.Fragment>Action</React.Fragment>,
      Cell: (props, original) => (
        <div
          style={{
            color: "#0275D8",
            fontWeight: 600,
            cursor: "pointer",
            textAlign: "left",
          }}
          onClick={() => handleNavigation(props)}
        >
          Top-up Agent
        </div>
      ),
      accessor: "action",
      headerClassName: "table-header",
      className: "table-cell",
      style: { "white-space": "unset" },
    },
  ];

  const statusColor = (ennum) => {
    if (ennum === "Low") {
      return "#AF5F26";
    } else if (ennum === "Out") {
      return "#A4251A";
    } else if (ennum === "Enough") {
      return "#36743D";
    }
  };

  const statusBackgroundColor = (ennum) => {
    if (ennum === "Low") {
      return "#FEF6CF";
    } else if (ennum === "Out") {
      return "#FBE9E9";
    } else if (ennum === "Enough") {
      return "#F1FEF1";
    }
  };
  const statusBorderColor = (ennum) => {
    if (ennum === "Low") {
      return "#FDED94";
    } else if (ennum === "Out") {
      return "#F4B7B5";
    } else if (ennum === "Enough") {
      return "#BEF2B9";
    }
  };

  useEffect(() => {
    getBalanceGrid(intervalType, pageNum, pageSize);
  }, [intervalType]);

  useEffect(() => {
    getBalance();
  }, []);
  return (
    <section>
      <article className="agentType-container-card-commission">
        <BalanceCard
          titleColor={"#DC4437"}
          loading={isLoadingBalance}
          title={"Balance Out"}
          balance={balance?.out || 0}
        />
        <BalanceCard
          titleColor={"#AF5F26"}
          loading={isLoadingBalance}
          title={"Balance Low"}
          balance={balance?.low || 0}
        />
        <BalanceCard
          titleColor={"#36743D"}
          loading={isLoadingBalance}
          title={"Balance Enough"}
          balance={balance?.enough || 0}
        />
      </article>
      <br />
      <article className="filter-tabs-container">
        <div
          className="rounded-left"
          style={filterStyle("All")}
          onClick={() => handleFilterClick("All")}
        >
          All
        </div>
        <div
          style={filterStyle("Out")}
          onClick={() => handleFilterClick("Out")}
        >
          Out
        </div>
        <div
          style={filterStyle("Low")}
          onClick={() => handleFilterClick("Low")}
        >
          Low
        </div>
        <div
          className="rounded-right"
          style={filterStyle("Enough")}
          onClick={() => handleFilterClick("Enough")}
        >
          Enough
        </div>
      </article>
      <br />
      <article
        style={{
          marginTop: "-20px",
        }}
        className="commission-section-container-target"
      >
        <ReactTable
          columns={agentColumns}
          data={balanceGrid}
          dataCount={dataCount}
          pageSizes={getPaginationRows(dataCount)}
          PaginationComponent={Pagination}
          pages={pages}
          manual
          minRows={10}
          onFetchData={(state, instance) => {
            getBalanceGrid(intervalType, state.page + 1, state.pageSize);
          }}
          noDataText={isLoadingAgent ? "" : "No records found"}
          loading={isLoadingAgent}
          LoadingComponent={Spinner}
          getPaginationProps={(state, rowInfo, column, instance) => ({
            style: {
              overflow: "visible",
              backgroundColor: "#ffffff",
            },
          })}
        />
      </article>
    </section>
  );
};

function mapStateToProps(state) {
  return {
    isLoading: state.tunnel.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: (pendingUrl) => dispatch(navigateTo(pendingUrl)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Balance);
