import React from "react";
import { Table, Row, Col, Button as ReactButton, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import MdSync from "react-ionicons/lib/MdSync";
import Select from "react-select";
import { CSVLink } from "react-csv";
import "react-datepicker/dist/react-datepicker.css";

import H1 from "../../../../../../components/h1";
import HR from "../../../../../../components/hr";
import BaseScene from "../../../../../../scenes/base-scene";
import Button from "../../../../../../components/button";
import DateInputButton from "../../../../../../components/date-inputs/button";
import CommissionsEarnedTable from "../../tables/commissions-earned";
import Settlement from "../../../../../../services/api/resources/settlement";
import {
  SUCCESS_STATUS,
  HTTP_NOT_FOUND_CODE,
} from "../../../../../../constants/api";
import TransactionHistory from "../../../../../../services/api/resources/transaction-history";
import Platform from "../../../../../../services/api/resources/platform";
import {
  specialDateRangeFormatter_EndDate,
  specialDateRangeFormatter_StartDate,
  formatDateForDateRange,
  handleTransactionTypeFormat,
  formatAmount,
  formatDate,
  handleLongNumber,
} from "../../../../../../utils/formatters";
import Text from "../../../../../../components/text";
import Spinner from "../../../../../../components/spinner";
import ActivityIndicator from "../../../../../../components/activity-indicator";
import {
  ServiceType,
  Event,
} from "../../../../../../fixtures/report_filter_options";
import { convertNgkToNgn } from "../../../../../../utils/converters/currencies";
import UserSerializer from "../../../../../../serializers/resources/user";
import FormControl from "../../../../../../components/form-control";
import { useHistory } from "react-router-dom";

const endDate = new Date();
const startDate = endDate;

export default class CommissionsEarned extends BaseScene {
  transactionHistory = new TransactionHistory();
  platform = new Platform();

  constructor(props) {
    super(props);

    this.state = {
      commissionsEarnedHistory: [],
      filteredTransactions: [],
      count: 0,
      pages: 1,
      pageNum: 1,
      pageSize: parseInt(process.env.REACT_APP_PAGE_SIZE),
      isLoading: false,
      startDate,
      endDate,
      didErrorOccurWhileFetchingCommissionsEarned: false,
      selectedOption: { label: "All", value: null },
      selectedEventOption: { label: "All", value: null },
    };

    this.getCommissionEarnedHistory =
      this.getCommissionEarnedHistory.bind(this);
    this.filterTransactionsByEvent = this.filterTransactionsByEvent.bind(this);
    this.filterTransactionsByType = this.filterTransactionsByType.bind(this);
    this.filterTransactionsByEvent = this.filterTransactionsByEvent.bind(this);
  }

  componentDidMount() {
    const currentUser = JSON.parse(localStorage.getItem("currentUser") || "{}");
    const userSerializer = new UserSerializer(currentUser);

    userSerializer && userSerializer.isAggregator && this.getAgents();

    this.setState({
      userSerializer: userSerializer,
    });

    this.getCommissionEarnedHistory(
      this.state.pageNum,
      this.state.pageSize,
      this.state.startDate,
      this.state.endDate,
      this.state.transactionType,
      this.state.transactionEvent,
      this.state.domainCode
    );
  }

  // componentWillMount() {
  //   this.getCommissionEarnedHistory();
  // }

  //TODO: look into this
  async getAgents() {
    const { status, response, code } = await this.platform.searchAgents();
    if (status === SUCCESS_STATUS) {
      this.setState({
        agents: response.content,
        isLoading: false,
        didErrorOccurWhileGettingAgents: false,
      });
    } else {
      this.handleGetAgentsEmptyResponse(code);
    }
  }

  handleGetAgentsEmptyResponse = (code) => {
    this.setState({
      agents: [],
      isLoading: false,
    });
    if (code === HTTP_NOT_FOUND_CODE) {
      this.setState({
        didErrorOccurWhileGettingAgents: false,
      });
    } else {
      this.setState({
        didErrorOccurWhileGettingAgents: true,
      });
    }
  };

  async getCommissionEarnedHistory(
    pageNum,
    pageSize,
    startDate,
    endDate,
    transactionType,
    transactionEvent,
    domainCode
  ) {
    const commissionsStartDate =
      startDate && specialDateRangeFormatter_StartDate(startDate);
    const commissionsEndDate =
      endDate && specialDateRangeFormatter_EndDate(endDate);

    this.setState({
      isLoading: true,
      pageSize: pageSize,
      pageNum: pageNum,
    });

    domainCode =
      this.state.userSerializer &&
      this.state.userSerializer.isAggregator &&
      domainCode !== "0"
        ? domainCode
        : null;

    const { status, response, code } =
      await this.transactionHistory.getCommissionHistory(
        pageNum,
        pageSize,
        commissionsStartDate,
        commissionsEndDate,
        transactionType,
        transactionEvent,
        domainCode
      );

    if (status === SUCCESS_STATUS) {
      this.setState({
        commissionsEarnedHistory: response.content,
        filteredTransactions: response.content,
        pages: Math.ceil(response.count / pageSize),
        pageSize: pageSize,
        isLoading: false,
        didErrorOccurWhileFetchingCommissionsEarned: false,
      });
    } else {
      if (code === HTTP_NOT_FOUND_CODE) {
        this.setState({
          isLoading: false,
          commissionsEarnedHistory: [],
          filteredTransactions: [],
        });
      } else {
        this.setState({
          didErrorOccurWhileFetchingCommissionsEarned: true,
          isLoading: false,
        });
      }
    }
  }

  setStartDate(value) {
    const { pageNum, pageSize, selectedOption, endDate, selectedEventOption } =
      this.state;
    this.setState({
      startDate: value,
    });

    const domainCode =
      this.state.userSerializer &&
      this.state.userSerializer.isAggregator &&
      this.state.domainCode !== "0"
        ? this.state.domainCode
        : null;

    this.getCommissionEarnedHistory(
      pageNum,
      pageSize,
      value,
      endDate,
      selectedOption.value,
      selectedEventOption.value,
      domainCode
    );
  }

  setEndDate(value) {
    const {
      pageNum,
      pageSize,
      selectedOption,
      startDate,
      selectedEventOption,
    } = this.state;
    this.setState({
      endDate: value,
    });

    const domainCode =
      this.state.userSerializer &&
      this.state.userSerializer.isAggregator &&
      this.state.domainCode !== "0"
        ? this.state.domainCode
        : null;

    this.getCommissionEarnedHistory(
      pageNum,
      pageSize,
      startDate,
      value,
      selectedOption.value,
      selectedEventOption.value,
      domainCode
    );
  }

  filterTransactionsByAgent(domainCode) {
    const {
      pageNum,
      pageSize,
      startDate,
      endDate,
      selectedOption,
      selectedEventOption,
    } = this.state;
    this.setState({
      domainCode,
    });
    this.getCommissionEarnedHistory(
      pageNum,
      pageSize,
      startDate,
      endDate,
      selectedOption.value,
      selectedEventOption.value,
      domainCode
    );
  }

  filterTransactionsByEvent(selectedEventOption) {
    const { pageNum, pageSize, startDate, endDate, selectedOption } =
      this.state;
    this.setState({
      selectedEventOption,
    });

    const domainCode =
      this.state.userSerializer &&
      this.state.userSerializer.isAggregator &&
      this.state.domainCode !== "0"
        ? this.state.domainCode
        : null;

    this.getCommissionEarnedHistory(
      pageNum,
      pageSize,
      startDate,
      endDate,
      selectedOption.value,
      selectedEventOption.value,
      domainCode
    );
  }

  filterTransactionsByType(selectedOption) {
    const { pageNum, pageSize, startDate, endDate, selectedEventOption } =
      this.state;
    this.setState(() => ({
      selectedOption,
    }));

    const domainCode =
      this.state.userSerializer &&
      this.state.userSerializer.isAggregator &&
      this.state.domainCode !== "0"
        ? this.state.domainCode
        : null;

    this.getCommissionEarnedHistory(
      pageNum,
      pageSize,
      startDate,
      endDate,
      selectedOption.value,
      selectedEventOption.value,
      domainCode
    );
  }

  handleSearch = (event) => {
    const { commissionsEarnedHistory } = this.state;

    let search = event.target.value;
    let initialTransactions = commissionsEarnedHistory;

    if (search !== "") {
      initialTransactions = initialTransactions.filter((transactions) => {
        return (
          transactions.transactionRef &&
          transactions.transactionRef.toString().match(search)
        );
      });
    } else {
      initialTransactions = commissionsEarnedHistory;
    }

    this.setState({
      searchTerm: search,
      filteredTransactions: initialTransactions,
    });
  };

  renderRefreshCommissionsEarnedMessage() {
    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "top",
          paddingTop: "100px",
          paddingBottom: "100px",
        }}
      >
        <Text blue bold small>
          Oops!
        </Text>
        <Text mid>Something went wrong. Please, try again.</Text>
        <div
          style={{
            alignItems: "center",
            border: `1pt solid ${process.env.REACT_APP_BLUE_COLOUR}`,
            borderRadius: "4px",
            color: process.env.REACT_APP_BLUE_COLOUR,
            cursor: "pointer",
            display: "flex",
            fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            fontStyle: process.env.REACT_APP_MID_FONT_SIZE,
            fontWeight: "bold",
            height: "40px",
            justifyContent: "center",
            marginTop: "16px",
            width: "160px",
          }}
          onClick={() => this.getCommissionEarnedHistory()}
        >
          <MdSync
            rotate={this.state.isLoading}
            color={process.env.REACT_APP_BLUE_COLOUR}
            fontSize={"24px"}
            style={{ marginRight: "10px" }}
          />
          {this.state.isLoading ? "Loading..." : "RETRY"}
        </div>
      </div>
    );
  }

  render() {
    const { userSerializer } = this.state;

    const csvFilteredTransaction = this.state.commissionsEarnedHistory.map(
      (transaction) => ({
        transactionRef:
          transaction.transactionRef &&
          handleLongNumber(transaction.transactionRef),
        transactionType:
          transaction.transactionTypeInt &&
          handleTransactionTypeFormat(transaction.transactionTypeInt),
        fee:
          transaction.fee &&
          `₦${formatAmount(convertNgkToNgn(transaction.fee))}`,
        amount:
          transaction.amount &&
          `₦${formatAmount(convertNgkToNgn(transaction.amount))}`,
        availableBalance:
          transaction.availableBalance &&
          `₦${formatAmount(convertNgkToNgn(transaction.availableBalance))}`,
        event: transaction.event && transaction.event,
        status: transaction.status && transaction.status,
        dateCreated:
          transaction.dateCreated && formatDate(transaction.dateCreated),
      })
    );

    const csvHeader = [
      { label: "Transaction Reference", key: "transactionRef" },
      { label: "Transaction Type", key: "transactionType" },
      { label: "Transaction Amount", key: "amount" },
      { label: "Commission Earned", key: "fee" },
      { label: "Status", key: "status" },
      { label: "Event", key: "event" },
      { label: "Commission Balance After", key: "availableBalance" },
      { label: "Date", key: "dateCreated" },
    ];

    return (
      <div
        style={{ marginTop: this.state.deviceIsPC ? "50px" : "75pt" }}
        id={this.props.id}
      >
        <Row style={{ margin: 0, padding: 0 }}>
          <Col xs={9} md={11} style={{ margin: 0, padding: 0 }}>
            <H1 style={{ textAlign: "left" }}>COMMISSIONS EARNED</H1>
          </Col>
          <Col
            xs={3}
            md={1}
            style={{
              display: "flex",
              alignItems: "center",
              margin: 0,
              padding: 0,
            }}
          >
            <Button
              outline
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
                color: process.env.REACT_APP_RED_COLOUR,
                fontWeight: "bold",
                margin: 0,
                padding: 0,
                textTransform: "none",
              }}
              onClick={() => this.props.onCancel()}
            >
              Go Back
            </Button>
          </Col>
        </Row>

        <HR style={{ marginTop: "10px" }} />

        <Col
          md={12}
          className="card-with-shadow"
          id="transactions-table-container"
        >
          <div
            className="transactions-table-header"
            style={{ paddingTop: "10px", paddingBottom: "0px" }}
          >
            {/* <div className='transactions-title'>
            <div className="reports-scene-statistical-component-heading">Commissions Earned</div>
          </div> */}
            {userSerializer && userSerializer.isAggregator && (
              <Form.Group controlId="formBasicEmail" style={{ padding: "0px" }}>
                <FormControl
                  onChange={(event) => {
                    const domainCode = event.target.value;
                    this.filterTransactionsByAgent(domainCode);
                  }}
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "20px",
                    border: "1px solid #DFE2E6",
                    boxSizing: "border-box",
                    fontSize: "12px",
                    lineHeight: "17px",
                    width: "200px",
                  }}
                  as="select"
                >
                  <option value={0}>Select Agent</option>
                  <option value={userSerializer.domainCode}>Self</option>
                  {this.state.agents &&
                    this.state.agents.map(({ businessName, agentCode }) => (
                      <option value={agentCode}>{businessName}</option>
                    ))}
                </FormControl>
              </Form.Group>
            )}

            <Select
              options={Event}
              onChange={this.filterTransactionsByEvent}
              value={this.state.selectedEventOption}
              className="select-input-container"
              classNamePrefix="react-select"
            />

            <Select
              options={ServiceType}
              onChange={this.filterTransactionsByType}
              value={this.state.selectedOption}
              className="select-input-container"
              classNamePrefix="react-select"
            />

            {!(userSerializer && userSerializer.isAggregator) && (
              <div className="search-container">
                <input
                  className="search-input"
                  onChange={this.handleSearch}
                  placeholder="Search by TransRef..."
                />
              </div>
            )}

            <div className="reports-scene-date-picker-container">
              <DatePicker
                selected={this.state.startDate}
                onChange={(date) => this.setStartDate(date)}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                dateFormat={`dd/MM/yyyy`}
                customInput={<DateInputButton />}
                maxDate={this.state.endDate}
              />
              <Text
                className="date-input-separator"
                style={{ fontSize: "24px" }}
              >
                -
              </Text>
              <DatePicker
                selected={this.state.endDate}
                onChange={(date) => this.setEndDate(date)}
                selectsEnd
                endDate={this.state.endDate}
                minDate={this.state.startDate}
                maxDate={endDate}
                dateFormat={`dd/MM/yyyy`}
                customInput={<DateInputButton />}
              />
            </div>
            <div>
              <ReactButton className="download-link-button">
                <CSVLink
                  data={csvFilteredTransaction}
                  filename="QuickTellerPaypoint Commissions History.csv"
                  className="download-text"
                  headers={csvHeader}
                >
                  Download
                </CSVLink>
              </ReactButton>
            </div>
          </div>
          <div
            className="commissions-table-container"
            style={{ marginTop: "20px" }}
          >
            {this.state.didErrorOccurWhileFetchingCommissionsEarned ? (
              this.renderRefreshCommissionsEarnedMessage()
            ) : this.state.filteredTransactions.length > 0 ? (
              <CommissionsEarnedTable
                data={this.state.filteredTransactions}
                pageSize={this.state.pageSize}
                getCommissionEarnedHistory={this.getCommissionEarnedHistory}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                loading={this.state.isLoading}
                transactionType={this.state.selectedOption.value}
                transactionEvent={this.state.selectedEventOption.value}
                pages={this.state.pages}
                domainCode={this.state.domainCode}
              />
            ) : (
              <p
                style={{
                  textAlign: "center",
                  color: process.env.REACT_APP_BLUE_COLOUR,
                  fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                  padding: "130px 0 130px 0",
                }}
              >
                {this.state.isLoading ? (
                  <ActivityIndicator />
                ) : (
                  "No records found for the parameters specified. Please, adjust the filters."
                )}
              </p>
            )}
          </div>
        </Col>
      </div>
    );
  }
}
