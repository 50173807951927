import React, { useState } from "react";

import SetupNewAgentScene from "./setupNewAgentScene";
import { AppProvider } from "../context";
import PersonalDetailsScene from "./personalDetailsScene";
import CountriesStatesLga from "../../../../../fixtures/countries_states_lgas.json";
import BusinessDetailsScene from "./businessDetailsScene";

import ApplicationReviewScene from "./applicationReviewScene";
import Onboarding from "../../../../../services/api/resources/onboarding";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../../constants/api";
import BusinessDetailsVerfication from "./businessDetailsVerfication";
import AuthorizationLetter from "./authorizationLetter";
import BvnValidationScene from "./bvnValidationScene";
import FaceVerificationSceneAggregator from "./faceVerificationScene";
import NINVerificationScene from "./verifyNINScene";
import ResidentialAddressScene from "./residentialAddressScene";
import NextOfKinScene from "./nextOfKinScene";

class SetupAgent extends React.Component {
  constructor(props) {
    super(props);
    this.myElementRef = React.createRef();
    this.state = {
      screen: 1,
      kyc_id: "",
      isLoading: false,
      application: null,
    };

    this.fetchApplication = this.fetchApplication.bind(this);
  }

  setScreen = (newScreen) => {
    this.setState({ screen: newScreen });
  };
  setKyc_id = (newKycId) => {
    this.setState({ kyc_id: newKycId });
  };

  async fetchApplication() {
    const applicationId = localStorage.getItem("applicationId");
    const onboarding = new Onboarding();
    // applicationId

    this.setState({ isLoading: true });
    const { status, response } = await onboarding.getApplicationAggregator(
      applicationId
    );

    localStorage.setItem("application", JSON.stringify(response));
    this.setState({ isLoading: false });
    if (status === ERROR_STATUS) {
    } else if (status === SUCCESS_STATUS) {
      this.setState({ isLoading: false });

      const stateArray = CountriesStatesLga[0].states.map((state) => {
        return {
          name: state.name,
          id: state.id,
        };
      });

      localStorage.setItem(
        "state",
        response?.businessDetails?.state
          ? stateArray.find(
              (value) => value.id === parseInt(response?.businessDetails?.state)
            ).name
          : ""
      );
    } else {
      this.setState({ isLoading: false });
    }
  }

  componentDidMount() {
    this.loadApplication();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoading !== this.props.isLoading) {
      this.loadApplication();
    }
  }

  loadApplication() {
    const application = JSON.parse(localStorage.getItem("application"));
    this.setState({ application });
  }

  render() {
    const { screen, kyc_id } = this.state;
    const application = JSON.parse(localStorage.getItem("application"));
    return (
      <AppProvider>
        <div className="aggregator-layout">
          {screen === 1 && (
            <SetupNewAgentScene
              application={application}
              setScreen={this.setScreen}
              props={this.props}
              isLoading={this.state.isLoading}
              fetchApplication={this.fetchApplication}
            />
          )}
          {screen === 2 && (
            <BusinessDetailsVerfication
              application={application}
              setKycId={this.setKyc_id}
              setScreen={this.setScreen}
              fetchApplication={this.fetchApplication}
            />
          )}
          {screen === 3 && (
            <BvnValidationScene
              kyc_id={kyc_id}
              application={application}
              setScreen={this.setScreen}
              screen={screen}
            />
          )}
          {screen === 4 && (
            <NINVerificationScene
              kyc_id={kyc_id}
              application={application}
              setScreen={this.setScreen}
              fetchApplication={this.fetchApplication}
            />
          )}

          {screen === 5 && (
            <PersonalDetailsScene
              application={application}
              setScreen={this.setScreen}
              screen={screen}
            />
          )}
          {screen === 6 && (
            <BusinessDetailsScene
              application={application}
              setScreen={this.setScreen}
              screen={screen}
              fetchApplication={this.fetchApplication}
            />
          )}
          {screen === 7 && (
            <ResidentialAddressScene
              application={application}
              setScreen={this.setScreen}
              screen={screen}
              fetchApplication={this.fetchApplication}
            />
          )}

          {screen === 8 && (
            <NextOfKinScene
              application={application}
              setScreen={this.setScreen}
              screen={screen}
              fetchApplication={this.fetchApplication}
            />
          )}

          {screen === 9 && (
            <AuthorizationLetter
              application={application}
              setScreen={this.setScreen}
              setKycId={this.setKyc_id}
              fetchApplication={this.fetchApplication}
            />
          )}
          {screen === 10 && (
            <FaceVerificationSceneAggregator
              application={application}
              setScreen={this.setScreen}
              kyc_id={kyc_id}
              fetchApplication={this.fetchApplication}
            />
          )}
          {screen === 11 && (
            <ApplicationReviewScene
              application={this.state.application}
              setScreen={this.setScreen}
              screen={screen}
              isLoading={this.state.isLoading}
              props={this.props}
            />
          )}
        </div>
      </AppProvider>
    );
  }
}

export default SetupAgent;
