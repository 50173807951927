import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

export const SetupAgentCard = ({
  background,
  color,
  icon,
  size,
  slug,
  style,
  text,
  navigateTo,
  deviceIsMobile,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const history = useHistory();

  return (
    <div
      id='serviceCard'
      className='grow'
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        display: 'flex',
        height: size === 'sm' ? '140px' : '140px',
        minWidth: size === 'sm' ? 'none' : '200px',
        background: isHovered ? color : background,
        backgroundImage: isHovered
          ? null
          : 'linear-gradient(75deg, #eaeaea, transparent)',
        borderRadius: '5px',
        marginLeft: deviceIsMobile ? '10px' : '0px',
        marginRight: deviceIsMobile ? '10px' : '30pt',
        padding: deviceIsMobile ? '10px' : '20px',
        marginTop: deviceIsMobile ? '10px' : '0',
        flexDirection: 'column',
        justifyContent: 'space-between',
        elevation: '60deg',
        boxShadow: '5px 5px 10px rgba(0,0,0,0.12)',
        ...style,
      }}
      onClick={() => navigateTo(`/agents/setup-new-agent`)}
    >
      <div>
        {icon({
          color: isHovered ? 'white' : color,
        })}
      </div>
      <div
        md={12}
        style={{
          color: isHovered ? 'white' : color,
          fontSize: '20px',
        }}
      >
        {text}
      </div>
    </div>
  );
};
