import { formatAmount } from "../formatters"

export default class Wallet {
  constructor() {

    this.wallet = JSON.parse(localStorage.getItem('wallet') || '{}')
    
    if (!this.wallet.currentBalance) {
      this.wallet.commissionsEarned = 0
      this.wallet.currentBalance = 0
      this.wallet.floatBalance = 0
      this.wallet.unsettledBalance = 0
    }

  }

  credit (amount) {
    this.wallet.currentBalance += amount
    localStorage.setItem('wallet', JSON.stringify(this.wallet))
  }

  debit (amount) {
    this.wallet.currentBalance -= amount
    localStorage.setItem('wallet', JSON.stringify(this.wallet))
  }

  addCommissionsEarned (amount) {
    this.wallet.commissionsEarned += amount
    localStorage.setItem('wallet', JSON.stringify(this.wallet))
  }

  deductCommissionsEarned (amount) {
    this.wallet.commissionsEarned -= amount
    localStorage.setItem('wallet', JSON.stringify(this.wallet))
  }

  get commissions_earned () {
    return this.wallet.commissionsEarned
  }

  get current_balance () {
    return this.wallet.currentBalance
  }

  get float_balance () {
    return this.wallet.floatBalance
  }

  get unsettled_balance () {
    return this.wallet.unsettledBalance
  }

}
