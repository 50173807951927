import {
  MANAGE_PROFILE_ACTIVE_TAB,
  NAVIGATE_TO,
  SHOW_TRANSACTION_REPORT,
  UPDATE_CURRENT_PATHNAME,
  UPDATE_SCENE,
} from "../../../constants/action-types/navigation";

export function navigateTo(pendingUrl) {
  return {
    type: NAVIGATE_TO,
    pendingUrl,
  };
}

export function updateCurrentPathName(pathName) {
  return {
    type: UPDATE_CURRENT_PATHNAME,
    pathName,
  };
}

export function updateScene(scene) {
  return {
    type: UPDATE_SCENE,
    scene,
  };
}

export function setActiveTab(activeTab) {
  return {
    type: MANAGE_PROFILE_ACTIVE_TAB,
    activeTab,
  };
}

export function showTransactionReportComponent(payload) {
  return {
    type: SHOW_TRANSACTION_REPORT,
    payload,
  };
}
