import React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import IosRefresh from 'react-ionicons/lib/IosRefresh';
import MdCloseCircle from 'react-ionicons/lib/MdCloseCircle';
import Text from './text';

const DOC_FORMATS = ['pdf'];
const IMAGE_FORMATS = ['png', 'jpg', 'jpeg', 'bimp'];

export default class Thumbnail2 extends React.Component {
  hasAttachment = false;

  constructor() {
    super();

    this.state = {
      deleted: false,
      showLightbox: false,
    };

    this.onClick = this.onClick.bind(this);
    this.removeAttachment = this.removeAttachment.bind(this);
  }

  get src() {
    if (!this.props.attachedDocument) {
      return null;
    }

    return this.props.attachedDocument.url
      ? this.props.attachedDocument.url
      : this.getDocumentUrl(this.props.attachedDocument.documentName);
  }

  getDocumentUrl(documentName) {
    return `${process.env.REACT_APP_CDN_BASE_URL}/p/finch/onboarding/${documentName}`;
  }

  get fileIsImage() {
    return IMAGE_FORMATS.includes(this.fileExtension.toLowerCase());
  }

  get fileIsDocument() {
    return DOC_FORMATS.includes(this.fileExtension.toLowerCase());
  }

  get fileExtension() {
    if (!this.props.attachedDocument) {
      return '';
    }

    const srcSplitted = this.props.attachedDocument.name
      ? this.props.attachedDocument.name.split('.')
      : this.props.attachedDocument.documentName.split('.');
    return srcSplitted[srcSplitted.length - 1].split('-')[0];
  }

  shorten(text) {
    return `${text.slice(0, 6)}...${text.slice(text.length - 7, text.length)}`;
  }

  get filename() {
    return this.props.filename;
  }

  onClick() {
    if (!this.hasAttachment) {
      this.refs.fileUploader.click();
      return;
    }

    !this.state.deleted &&
      this.setState({
        showLightbox: true,
      });
  }

  removeAttachment(attachedDocument) {
    const { onFileUnselect } = this.props;

    this.setState({
      deleted: true,
    });

    onFileUnselect && onFileUnselect(attachedDocument);
  }

  render() {
    const { attachedDocument } = this.props;
    // let isUploaded;
    let src = this.props.src;

    if (attachedDocument) {
      this.hasAttachment = true;
    }

    if (this.state.deleted) {
      this.hasAttachment = false;
    }

    const { location } = attachedDocument ? attachedDocument : {};

    if (location) {
      src = `${process.env.REACT_APP_CDN_BASE_URL}/p/${
        location.split('/public/')[1]
      }`;
      // isUploaded = true;
      this.src_ = src;
    }

    return (
      <div
        onClick={!this.props.profile && this.onClick}
        style={{
          alignItems: 'center',
          backgroundColor: 'white',
          borderRadius: '4px',
          border: '1px solid grey',
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100px',
          marginRight: '10px',
          width: '100px',
        }}
      >
        <Text
          style={{
            backgroundColor: '#00425FDD',
            bottom: '0px',
            color: 'white',
            fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
            marginLeft: '0px',
            position: 'absolute',
            textAlign: 'center',
            width: '100px',
          }}
        >
          {this.filename}
        </Text>

        {!this.hasAttachment && (
          <React.Fragment>
            <input
              type='file'
              id='file'
              ref='fileUploader'
              accept='.pdf,.png,.jpg,.jpeg'
              style={{ display: 'none' }}
              onChange={(event) => {
                this.setState({
                  deleted: false,
                });
                this.props.onFileSelect(event, this.filename);
              }}
            />
            {!this.props.isLoading ? (
              <Text big>+</Text>
            ) : (
              <IosRefresh
                style={{ width: '24px' }}
                fontSize='60px'
                color='grey'
                rotate={true}
              />
            )}
          </React.Fragment>
        )}
        {this.hasAttachment ? (
          this.fileIsImage ? (
            <React.Fragment>
              <img
                {...this.props}
                alt=''
                data-toggle='modal'
                data-target='#kt_modal_1'
                height={100}
                src={this.src}
                width={100}
              />

              {this.state.showLightbox && (
                <Lightbox
                  mainSrc={this.src}
                  onCloseRequest={() => this.setState({ showLightbox: false })}
                  onMovePrevRequest={() => this.setState({})}
                  onMoveNextRequest={() => this.setState({})}
                />
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <embed
                style={{
                  height: '100px',
                  width: '100px',
                }}
                name='plugin'
                src={this.src}
              />
            </React.Fragment>
          )
        ) : (
          <React.Fragment />
        )}

        {!this.props.manageProfile && (
          <div
            hidden={!this.hasAttachment}
            className='remove-attachment-button'
          >
            <MdCloseCircle
              color={'black'}
              fontSize='35px'
              onClick={() => this.removeAttachment(attachedDocument)}
            />
          </div>
        )}
      </div>
    );
  }
}
