import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";

export default class BankNetworkTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      showReceipt: false,
      transactionDetails: null,
      isLoading: false,
      isRequeryLoading: false,
      reversalDetails: [],
      showReversalSection: false,
      isReversalDetailLoading: false,
      pageSize: 300,
      page: 0,
    };
  }

  getColorByPercentage(percentage) {
    const percentageString = percentage.toString();

    if (percentageString.toLowerCase() === "n/a") {
      return "#808593";
    } else {
      const percentageValue = parseFloat(percentageString.replace("%", ""));

      if (isNaN(percentageValue)) {
        return "#808593";
      } else if (percentageValue >= 71) {
        return "#519E47";
      } else if (percentageValue >= 31) {
        return "#EC9B40";
      } else {
        return "#DC4437";
      }
    }
  }

  filterEmptyRows(data) {
    return data.filter((row) => {
      return (
        row.bankName !== "" ||
        row.mastercard !== "" ||
        row.verve !== "" ||
        row.visa !== ""
      );
    });
  }

  render() {
    const data = this.props.data;
    const totalRows = data.length;
    const canShowPagination = totalRows > 0;

    const bankCardTypeColumns = [
      {
        Header: (props) => <React.Fragment>Bank</React.Fragment>,
        Cell: (props) => <div>{props.value}</div>,
        accessor: "bankName",
        headerClassName: "table-header",
        className: "table-cell",
        style: { "white-space": "unset", "text-align": "left" },
        width: 300,
      },

      {
        Header: (props) => <React.Fragment>Master Card</React.Fragment>,
        Cell: (props) => {
          const percentageValue = parseFloat(props.value.replace("%", ""));
          const cellColor = this.getColorByPercentage(percentageValue);

          return <div style={{ color: cellColor }}>{props.value}</div>;
        },
        accessor: "mastercard",
        headerClassName: "table-header",
        style: { "white-space": "unset", "text-align": "left" },
        className: "table-cell",
        width: 300,
      },
      {
        Header: (props) => <React.Fragment>Verve</React.Fragment>,
        Cell: (props) => {
          const percentageValue = parseFloat(props.value.replace("%", ""));
          const cellColor = this.getColorByPercentage(percentageValue);

          return <div style={{ color: cellColor }}>{props.value}</div>;
        },
        accessor: "verve",
        headerClassName: "table-header",
        className: "table-cell",
        width: 300,
        style: { "white-space": "unset", "text-align": "left" },
      },
      {
        Header: (props) => <React.Fragment>Visa</React.Fragment>,
        Cell: (props) => {
          const percentageValue = parseFloat(props.value.replace("%", ""));
          const cellColor = this.getColorByPercentage(percentageValue);

          return <div style={{ color: cellColor }}>{props.value}</div>;
        },
        accessor: "visa",
        headerClassName: "table-header",
        className: "table-cell",
        style: {
          "white-space": "unset",
          "text-align": "left",
        },
        width: 300,
      },
    ];

    return (
      <div
        style={{
          alignSelf: "center",
          marginLeft: 20,
        }}
      >
        {canShowPagination && (
          <ReactTable
            columns={bankCardTypeColumns}
            data={this.props.data}
            minRows={0}
            showPagination={false}
            loading={this.props.loading}
            pages={Math.ceil(this.props.data.length / this.state.pageSize)}
            pageSize={this.state.pageSize}
            onPageChange={(pageIndex) => this.setState({ page: pageIndex })}
            getPaginationProps={(state, rowInfo, column, instance) => ({
              style: {
                overflow: "visible",
                backgroundColor: "#ffffff",
              },
            })}
          />
        )}
        {this.props.noRecord && (
          <p
            style={{
              textAlign: "center",
              color: process.env.REACT_APP_BLUE_COLOUR,
              fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
              padding: "85px 0 85px 0",
            }}
          >
            {"No information available."}
          </p>
        )}
        {this.props.loadingData && (
          <p
            style={{
              textAlign: "center",
              color: process.env.REACT_APP_BLUE_COLOUR,
              fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
              padding: "85px 0 85px 0",
            }}
          >
            {"Loading..."}
          </p>
        )}
      </div>
    );
  }
}
