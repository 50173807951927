import React, { useState } from 'react';
import { Button, Col, Form, FormControl, Modal, Row } from 'react-bootstrap';
import IosCart from 'react-ionicons/lib/IosCart';
import { userManagementServiceClient } from '../../../../../App';
import { notify } from '../../../../../components/extras';
import { ERROR_STATUS, SUCCESS_STATUS } from '../../../../../constants/api';
import Layout from '../layout';
import TrackPosDelivery from './track-delivery';

export const statusColor = (status) => {
  if (status === 'declined') return '#EE312A';
  if (status === 'awaiting_approval') return '#faa383';
  if (status === 'approved') return '#37BF6D';
};

const TrackPosOrder = () => {
  const [requestId, setRequestId] = useState('');
  const [stateId, setStateId] = useState('');
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requestDetails, setRequestDetails] = useState([]);
  const handleOpen = (id) => {
    setStateId(id);
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  const ptag = {
    color: '#111827',
    fontWeight: 400,
    textTransform: 'capitalize',
  };

  const spanTag = {
    fontSize: '14px',
    fontWeight: 400,
    width: '50%',
    color: '#6B7280',
  };
  const getPOSWorkflowDetailsByRequestId = async (requestId) => {
    if (requestId) {
      setLoading(true);
      const { status, response } =
        await userManagementServiceClient.getPosWorkflowDetails(requestId);

      setLoading(false);
      if (status === ERROR_STATUS) {
        notify(response.description, 'error');
      } else if (status === SUCCESS_STATUS) {
        setRequestDetails(response.data);
      }
    } else {
      notify('Please Enter a Request ID', 'warning');
    }
  };

  return (
    <Layout>
      <h3
        className='Dance'
        style={{
          color: '#111827',

          fontSize: '22px',
          fontWeight: 'bold',
          marginBottom: '25px',
        }}
      >
        Track Order
      </h3>

      <Form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div style={{ display: 'flex' }}>
          <div>
            <Form.Group style={{ padding: 0 }} controlId='Request-id'>
              <Form.Label>POS Request ID</Form.Label>
              <FormControl
                type='text'
                placeholder='R235545'
                onChange={(e) => setRequestId(e.target.value.trim())}
                className='posInput'
              />
            </Form.Group>
          </div>
          <div>
            <Button
              className='posButton'
              onClick={() => getPOSWorkflowDetailsByRequestId(requestId)}
            >
              {loading ? 'Loading...' : 'Search'}
            </Button>
          </div>
        </div>
      </Form>
      {requestDetails && requestDetails.length > 0 && (
        <>
          <div className='page-two-section'>
            <h2
              style={{
                fontSize: '16px',
                fontWeight: '600',
                marginBottom: '9px',
                marginTop: '10px',
              }}
            >
              Terminal Information
            </h2>
            {requestDetails.map((item, index) => {
              const { agentUsername, terminalModel, initiatorUsername } = item;

              return (
                <>
                  <section key={index} className='posTrackOrderSection'>
                    <div className='posAgent'>
                      <div>
                        <span style={spanTag}>Agent Username</span>
                        <p style={ptag}>{agentUsername}</p>
                      </div>
                      <div>
                        <span style={spanTag}>Terminal Model</span>

                        <p style={ptag}>{terminalModel}</p>
                      </div>
                    </div>

                    <div className='posAgent'>
                      <div>
                        <span style={spanTag}>Initiator Number</span>
                        <p style={ptag}>{initiatorUsername}</p>
                      </div>

                      <div
                        onClick={() =>
                          handleOpen(item.posRequestStockRequestId)
                        }
                        style={{
                          display: 'inline',
                          backgroundColor: '#EEF2F4',
                          padding: '8px',
                          borderRadius: '5px',
                          cursor: 'pointer',
                        }}
                        title='Track Order'
                      >
                        <IosCart color={'green'} fontSize='20pt' />
                      </div>
                    </div>
                  </section>
                  <hr />
                  {item && item.posRequestStockRequestId === stateId && (
                    <>
                      <Modal
                        show={show}
                        onHide={() => handleClose()}
                        backdrop='static'
                        keyboard={false}
                        centered
                      >
                        <Modal.Header closeButton>
                          <Modal.Title style={{ fontSize: '18px' }}>
                            <span style={{ color: 'grey' }}>Tracking:</span>{' '}
                            {requestId}{' '}
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Row style={{ width: '100%', marginLeft: '22px' }}>
                            <Col>
                              <TrackPosDelivery requestDetails={item} />
                            </Col>
                          </Row>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            style={{
                              backgroundColor: 'red',
                              borderRadius: '5px',
                              color: 'white',
                              fontSize: '16px',
                            }}
                            onClick={() => handleClose()}
                          >
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </>
                  )}
                </>
              );
            })}
          </div>
        </>
      )}
    </Layout>
  );
};

export default TrackPosOrder;
