import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import InputText from "../../scenes/signup/onboarding-setup/component/input";
import InputWithSelectText from "../../scenes/signup/onboarding-setup/component/inputWithSelect";
import Stepper from "../../scenes/signup/onboarding-setup/component/stepper";

import MdCheckmarkCircle from "react-ionicons/lib/MdCheckmarkCircle";
import { toast } from "react-toastify";
import { HandIcon } from "../../icons";
import BackButton from "../../scenes/signup/onboarding-setup/component/backButton";
import Platform from "../../services/api/resources/platform.js";
import HeaderSignup from "../header-signup";
import { loadAgent } from "./mockApi.js";

const BusinessCBNScene = ({ application }) => {
  const history = useHistory();
  const platform = new Platform();

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tinValidated, setTinValidated] = useState(false);
  const [businessType, setBusinessType] = useState("BN");
  const [tinSubmitted, setTinSubmitted] = useState(false);
  const [businessSceneForm, setBusinessSceneForm] = useState({
    tin: application?.applicantDetails?.businessScene?.tin || "",
    cacNumber: application?.applicantDetails?.businessScene?.cacNumber || "",
  });
  const [selectedValue, setSelectedValue] = useState("BN");

  useEffect(() => {
    const updateBusinessForm = JSON.parse(localStorage.getItem("businessForm"));
    if (updateBusinessForm) {
      setBusinessSceneForm({
        tin: updateBusinessForm.tin || businessSceneForm.tin,
        cacNumber: updateBusinessForm.cacNumber || businessSceneForm.cacNumber,
      });
    }
  }, [application]);

  useEffect(() => {
    localStorage.setItem(
      "businessSceneForm",
      JSON.stringify(businessSceneForm)
    );
  }, [businessSceneForm]);

  const updateFormField = (params) => {
    setBusinessSceneForm((prevState) => ({
      ...prevState,
      ...params,
    }));
  };

  const notify = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const congratsModal = () => {
    setShowModal(true);
  };

  const handleSubmit = async () => {
    setLoading(true);

    const payload = tinValidated
      ? {
          identificationNumber: businessSceneForm.cacNumber,
          prefix: selectedValue,
        }
      : { identificationNumber: businessSceneForm.tin };

    const validationType = tinValidated ? "CAC" : "TIN";

    try {
      const { status, response } = await platform.validateBusinessDetails(
        payload,
        validationType
      );

      if (response.code !== "00") {
        notify(response.description, "error");
      } else if (response.data.validationStatus === "VERIFIED") {
        // await loadAgent();
        notify(response.data.message, "success");
        if (!tinValidated) {
          setTinValidated(true);
          setTinSubmitted(true);
          updateFormField({ cacNumber: response.data.cacRegNo });
        } else {
          congratsModal();
        }
      } else {
        notify(response.data.message, "error");
      }
    } catch (error) {
      notify(
        "An error occurred while validating your details, please try again.",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const navigateToNINVerification = () => {
    history.push("/nin-verification");
  };

  const isTinValid = (tin) => {
    const tinPattern = /^[A-Za-z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{9,14}$/;
    return tinPattern.test(tin);
  };

  const isCacNumberValid = (cacNumber) => {
    const cacNumberPattern =
      /^[A-Za-z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8}$/;
    return cacNumberPattern.test(cacNumber);
  };

  const isFormValid = () => {
    return isTinValid(businessSceneForm.tin);
    //  &&
    // (tinValidated ? isCacNumberValid(businessSceneForm.cacNumber) : true)
  };

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const checkAuthentication = () => {
    const isAuthenticated = localStorage.getItem("auth_token") === null;

    if (isAuthenticated) {
      history.push("/login");
      localStorage.clear();
    }
  };

  useEffect(() => {
    checkAuthentication();
  }, []);
  // let kycCheckListDetails = JSON.parse(localStorage.getItem("currentAgent"));
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          gap: "40px",
          margin: "2rem 0 0 2rem",
        }}
      >
        <HeaderSignup />
        {/* {kycCheckListDetails?.kycCheckList?.tinNinMatch === false && (
          <BackButton onClick={navigateToNINVerification} />
        )} */}
      </div>
      <main className="onboarding-container">
        <div style={{ alignContent: "center", padding: "2rem" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              gap: "10px",
              marginBottom: "30px",
            }}
          >
            <Stepper borderColor="#00425F" />
            <Stepper borderColor="#00425F" />
            <Stepper borderColor="#00425F" />
            <Stepper borderColor="#00425F" />
            <Stepper />
          </div>
          <h1
            style={{
              fontWeight: "bold",
              marginBottom: "2rem",
              marginTop: "3rem",
              fontSize: "1.5rem",
            }}
          >
            Enter your business details
          </h1>

          <div style={{ position: "relative" }}>
            <InputText
              label={"Enter your Tax Identification Number (TIN)"}
              labelColor={
                !businessSceneForm.tin || isTinValid(businessSceneForm.tin)
                  ? "black"
                  : process.env.REACT_APP_RED_COLOUR
              }
              border={`1px solid ${
                !businessSceneForm.tin || isTinValid(businessSceneForm.tin)
                  ? "#DFE2E6"
                  : process.env.REACT_APP_RED_COLOUR
              }`}
              placeholder={"Enter TIN"}
              onChange={(e) => {
                updateFormField({ tin: e.target.value.trim() });
              }}
              maxLength={14}
              value={businessSceneForm.tin}
              disabled={tinValidated}
            />
            {tinValidated && (
              <MdCheckmarkCircle
                style={{
                  position: "absolute",
                  top: "70%",
                  right: "10px",
                  transform: "translateY(-50%)",
                  width: "30px",
                  height: "30px",
                }}
                color="green"
                fontSize="40px"
              />
            )}
          </div>

          {tinValidated && (
            <div style={{}}>
              <InputWithSelectText
                label={"Select your CAC Registration Prefix"}
                // labelColor={
                //   !businessSceneForm.cacNumber ||
                //   isCacNumberValid(businessSceneForm.cacNumber)
                //     ? "black"
                //     : process.env.REACT_APP_RED_COLOUR
                // }
                placeholder={"Enter CAC Number"}
                value={businessSceneForm.cacNumber}
                maxLength={8}
                // border={`1px solid ${
                //   !businessSceneForm.cacNumber ||
                //   isCacNumberValid(businessSceneForm.cacNumber)
                //     ? "#DFE2E6"
                //     : process.env.REACT_APP_RED_COLOUR
                // }`}
                // loading={loading}
                selectOptions={[
                  { label: "BN", value: "BN" },
                  { label: "LP", value: "LP" },
                  { label: "RC", value: "RC" },
                  { label: "LLP", value: "LLP" },
                  { label: "IT", value: "IT" },
                ]}
                selectValue={selectedValue}
                onSelectChange={handleSelectChange}
                disabled={true}
              />
            </div>
          )}

          <button
            onClick={handleSubmit}
            className="signupButton2"
            style={{
              marginTop: "1rem",
              textAlign: "center",
              color: "white",
              backgroundColor: "#00425f",
              width: "100%",
            }}
            disabled={!isFormValid() || loading}
          >
            {loading ? "Processing..." : tinSubmitted ? "Submit" : "Next"}
          </button>
          <button
            className="signupButton2"
            style={{
              marginTop: "1rem",
              textAlign: "center",
              color: "black",
              alignContent: "center",
            }}
            onClick={() => {
              history.push("/dashboard");
            }}
          >
            Continue Later
          </button>

          {process.env.REACT_APP_CAC_REGISTRATION === "true" && (
            <center
              style={{
                fontWeight: "bold",
                marginBottom: "3rem",
                marginTop: "1.5rem",
                fontSize: "0.9rem",
              }}
            >
              Don't have CAC?
              <br />
              <span
                style={{
                  color: process.env.REACT_APP_LINK_COLOUR,
                  cursor: "pointer",
                }}
                onClick={() => {
                  localStorage.removeItem("cacAssistedService");
                  localStorage.removeItem("personalDetailsForm");
                  localStorage.removeItem("businessSceneForm");
                  localStorage.removeItem("businessDetailsForm");
                  localStorage.removeItem("bvnInfo");
                  localStorage.removeItem("companyState");
                  localStorage.removeItem("cacRegistrationForm");
                  localStorage.removeItem("kycDetailsForm");
                  localStorage.removeItem("documentPassportPhoto");
                  localStorage.removeItem("passportPhotoBase64");
                  localStorage.removeItem("state");
                  localStorage.removeItem("lga");
                  history.push("/cac-registration");
                }}
              >
                Click here
              </span>
            </center>
          )}
        </div>
      </main>
      <Modal
        show={showModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body style={{ padding: "1rem" }}>
          <div style={{ padding: "4rem", alignContent: "center" }}>
            <div style={{ display: "grid", placeContent: "center" }}>
              {HandIcon()}
            </div>
            <h1
              style={{
                textAlign: "center",
                fontWeight: "bold",
                marginBottom: "1rem",
                fontSize: "1.5rem",
              }}
            >
              Congratulations!!!
            </h1>
            <p
              style={{
                color: "grey",
                fontWeight: "200",
                textAlign: "center",
              }}
            >
              Your details have been validated successfully.
            </p>
            <button
              className="signupButton2"
              style={{
                marginTop: "3rem",
                textAlign: "center",
                color: "white",
                backgroundColor: "#00425f",
                width: "100%",
              }}
              onClick={() => {
                setShowModal(false);
                history.push("/dashboard");
              }}
            >
              Okay
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BusinessCBNScene;
