import React from "react";

import { Button } from "react-bootstrap";
const PrimaryButton = ({
  children,
  onClick,
  disabled,
  backgroundColor = "#00425f",
  color = "white",
  height = "56px",
  margin = "30px 0",
  width = "100%",
  fontSize = "1rem",
}) => {
  return (
    <Button
      type="submit"
      disabled={disabled}
      style={{
        fontSize: fontSize,
        borderRadius: "8px",
        width: width,
        height: height,
        margin: margin,
        backgroundColor: backgroundColor,
        color: color,
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default PrimaryButton;
