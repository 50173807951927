import React, { useState } from 'react';
import { AppProvider } from '../context/pos-Request-context';

import Layout from '../layout';
import BackArrow from './backArrow';
import PaymentDetails from './paymentDetails';
import ReceiptScreen from './receipt-screen';
import SelectPosModel from './SelectPosModel';
import SelectRequestType from './SelectRequestType';

const RequestPos = () => {
  const [screen, setScreen] = useState(1);

  return (
    <AppProvider>
      <Layout>
        <div className='request-container'>
          <BackArrow screen={screen} setScreen={setScreen} />

          <article className='pos-request-article2'>
            {screen === 1 && <SelectPosModel setScreen={setScreen} />}
            {screen === 2 && <SelectRequestType setScreen={setScreen} />}
            {screen === 3 && <PaymentDetails setScreen={setScreen} />}
            {screen === 4 && <ReceiptScreen setScreen={setScreen} />}
          </article>
        </div>
      </Layout>
    </AppProvider>
  );
};

export default RequestPos;
