import React, { useEffect, useState } from 'react';
import {
  convertNgkToNgn,
  convertNgnToNgk,
} from '../../../../../../utils/converters/currencies';
import {
  formatAmount,
  specialDateRangeFormatter_StartDate,
} from '../../../../../../utils/formatters';
import ReactTable from 'react-table';
import Spinner from '../../../../../../components/spinner';
import { notify } from '../../../../../../components/extras';
import { ERROR_STATUS, SUCCESS_STATUS } from '../../../../../../constants/api';
import TransactionHistory from '../../../../../../services/api/resources/transaction-history';
import ActivityIndicator from '../../../../../../components/activity-indicator';

const TopAgents = ({ startDate, endDate, dateIntervalType }) => {
  const transactionHistory = new TransactionHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [getTopAgentList, setGetTopAgentList] = useState([]);

  const getTopAgent = async () => {
    setIsLoading(true);
    setGetTopAgentList([]);
    setError(false);
    // const payload = {
    //   from: specialDateRangeFormatter_StartDate(startDate),
    //   to: specialDateRangeFormatter_StartDate(endDate),
    //   dateIntervalType: dateIntervalType,
    // };
    let payload = {};
    if (dateIntervalType) {
      payload = {
        dateIntervalType: dateIntervalType,
      };
    } else {
      payload = {
        from: specialDateRangeFormatter_StartDate(startDate),
        to: specialDateRangeFormatter_StartDate(endDate),
      };
    }

    const { response, status } = await transactionHistory.getTopPerformingAgent(
      payload
    );

    setIsLoading(false);
    if (status === ERROR_STATUS) {
      setError(true);
      // notify(response.description, 'error');
    } else if (status === SUCCESS_STATUS) {
      setGetTopAgentList(response.data);
    }
  };

  useEffect(() => {
    getTopAgent();
  }, [dateIntervalType, endDate]);
  const TopAgentsColumns = [
    {
      Header: (props) => <div style={{ textAlign: 'left' }}>Agent ID</div>,
      Cell: (props) => (
        <div
          className='wordbreak'
          style={{ textAlign: 'left', wordBreak: 'break-all' }}
        >
          {props.value}
        </div>
      ),
      accessor: 'agentId',
      headerClassName: 'table-header',
      className: 'table-cell',
      style: { 'white-space': 'unset' },
      //   width: 200,
    },

    {
      Header: (props) => <div style={{ textAlign: 'left' }}>Agent Name</div>,
      accessor: 'businessName',
      Cell: (props) => <div style={{ textAlign: 'left' }}>{props.value}</div>,
      headerClassName: 'table-header',
      className: 'table-cell',
      //   width: 300,

      style: { 'white-space': 'unset' },
    },
    {
      Header: (props) => <div style={{ textAlign: 'left' }}>Phone Number</div>,
      accessor: 'businessPhoneNo',
      Cell: (props) => <div style={{ textAlign: 'left' }}>{props.value}</div>,
      headerClassName: 'table-header',
      className: 'table-cell',
      //   width: 300,

      style: { 'white-space': 'unset' },
    },

    {
      Header: (props) => <div style={{ textAlign: 'left' }}>Amount Earned</div>,
      accessor: 'commission',
      Cell: (props) => (
        <div className='number' style={{ textAlign: 'left' }}>{`₦${formatAmount(
          convertNgkToNgn(props.value)
        )}`}</div>
      ),
      headerClassName: 'table-header',
      className: 'table-cell',
      //   width: 200,
      style: { 'white-space': 'unset' },
    },
  ];
  return (
    <div className='mt-4'>
      {error && (
        <p
          style={{
            textAlign: 'center',
            color: process.env.REACT_APP_BLUE_COLOUR,
            fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
            padding: '85px 0 85px 0',
          }}
        >
          {'No records found'}
        </p>
      )}

      {isLoading && (
        <div
          style={{
            padding: '85px 0 85px 0',
          }}
        >
          <ActivityIndicator
            style={{
              height: '85px',
            }}
          />
        </div>
      )}

      {getTopAgentList.length > 0 && (
        <ReactTable
          columns={TopAgentsColumns}
          data={getTopAgentList}
          showPagination={false}
          defaultPageSize={5}
          onFetchData={(state, instance) => {
            //   getTransactionHistory(state.pageSize, state.page + 1);
          }}
          loading={isLoading}
          LoadingComponent={Spinner}
        />
      )}
    </div>
  );
};

export default TopAgents;
