import { connect } from 'react-redux';
import {
  dismissErrorMessage,
  showErrorMessage,
  updateLoading,
} from '../../../../services/redux/actions/tunnel';
import ManageDevicesTab from './manage-devices';

function mapStateToProps(state) {
  return {
    isLoading: state.tunnel.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dismissErrorMessage: () => dispatch(dismissErrorMessage()),
    showErrorMessage: (data) => dispatch(showErrorMessage(data)),
    updateLoading: (isLoading) => dispatch(updateLoading(isLoading)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageDevicesTab);
