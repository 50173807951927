import React from 'react';
import { Form } from 'react-bootstrap';
import MdSync from 'react-ionicons/lib/MdSync';
import { asteriks } from '../../../../icons';

const COLOUR = 'grey';
const InputText2 = ({
    label,
    placeholder,
    value,
    maxLength,
    onChange,
    labelColor,
    disabled = false,
    type = 'text',
    border,
    loading,
    handlePaste,
    handleCopy,
    onBlur,
    flex,
}) => {
    return (
        <div
            style={{
                flex: flex,
            }}
        >
            <Form.Group
                style={{
                    position: 'relative',
                }}
                className='p-0'
                controlId='exampleForm.ControlInput1'
            >
                <div style={{ display: 'flex' }}>
                    <Form.Label
                        style={{
                            color: labelColor,

                            fontSize: '14px',
                            fontWeight: 'bold',
                        }}
                    >
                        {label}{' '}
                        <span>{asteriks()}</span>
                    </Form.Label>
                </div>
                <Form.Control
                    style={{
                        border: border,
                    }}
                    onBlur={onBlur}
                    onCopy={handleCopy}
                    onPaste={handlePaste}
                    onChange={onChange}
                    maxLength={maxLength}
                    disabled={disabled}
                    value={value}
                    required
                    autoComplete='off'
                    type={type}
                    placeholder={placeholder}
                />
                {loading && (
                    <div
                        style={{
                            position: 'absolute',
                            bottom: '10px',
                            right: '10px',
                        }}
                    >
                        <MdSync
                            backgroundColor={COLOUR}
                            fontSize='20pt'
                            rotate={true}
                            // rotate={loading}
                            style={{
                                marginRight: '5pt',
                            }}
                        />
                    </div>
                )}
            </Form.Group>
        </div>
    );
};

export default InputText2;
