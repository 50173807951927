import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { navigateTo } from "../../../../../services/redux/actions/navigation";
import { updateLoading } from "../../../../../services/redux/actions/tunnel";
import { Nav, Tab, Tabs } from "react-bootstrap";
import "./app.css";

import Settings from "./settings/settings";
import Balance from "./balance/balance";
import Target from "./target/target";

const PerformanceSceen = (props) => {
  const [activeKey, setActiveKey] = useState("Balance");

  const style = {
    color: process.env.REACT_APP_BLUE_COLOUR,
  };

  return (
    <main>
      <div className="commissionLabel">My Performance</div>
      <Tabs
        unmountOnExit={true}
        id="controlled-tab-example"
        activeKey={activeKey}
        onSelect={(k) => setActiveKey(k)}
        className="commision-container"
        variant="pills"
        style={{
          backgroundColor: "white",
          margin: 0,
          width: "100%",
          padding: "0 20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Tab
          style={style}
          className="commision-container"
          eventKey="Balance"
          title="Balance"
        >
          <Balance />
        </Tab>
        <Tab
          style={style}
          className="commision-container"
          eventKey="Target"
          title="Target"
        >
          <Target setActiveKey={setActiveKey} />
        </Tab>
        <Tab
          style={style}
          className="commision-container"
          eventKey="Settings"
          title="Settings"
        >
          <Settings />
        </Tab>
      </Tabs>
    </main>
  );
};

function mapStateToProps(state) {
  return {
    isLoading: state.tunnel.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateLoading: (isLoading) => dispatch(updateLoading(isLoading)),
    navigateTo: (pendingUrl) => dispatch(navigateTo(pendingUrl)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceSceen);
