import Requester from '../requester';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


export default class Notifications {
  constructor(props) {
    this.apiRequester = props ? props.apiRequester || new Requester({
      apiBaseUrl: API_BASE_URL,
    }) : new Requester({
      apiBaseUrl: API_BASE_URL,
    });
  }

  getCountOfUnreadNotifications() {
    return this.apiRequester.get({
      endpoint: 'notifications/unread/count',
    })
  }

  retrieveNotifications(pageNum, pageSize) {
    return this.apiRequester.get({
      endpoint: 'notifications',
      args: {
        page_num: pageNum,
        page_size: pageSize
      }
    });
  }

  registerNotificationClient(clientId) {
    return this.apiRequester.post({
      endpoint: 'notifications',
      body: {
        messaging_service_client_token: clientId
      }
    });
  }

}
