import React from "react";

import MdSync from "react-ionicons/lib/MdSync";
import Text from "../text";

function CustomError({
  pageSize,
  pageNum,
  isLoading,
  onRetry,
  errorMessage,
  checkFailure,
}) {
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        border: "1px solid #a9a9a9",
        borderRadius: "10px",
        padding: "60px 20px",
        flexDirection: "column",
        height: "100%",
        justifyContent: "top",
        margin: "5% 10px",
        textAlign: "center",
      }}
    >
      <Text
        style={{ fontSize: "20px", marginBottom: "1rem", color: "tomato" }}
        bold
      >
        Oops!
      </Text>
      <Text
        style={{
          color: process.env.REACT_APP_BLUE_COLOUR,
        }}
        mid
      >
        {errorMessage}
        {checkFailure || (
          <>
            <br />
            Will you like to retry?
          </>
        )}
      </Text>
      <div
        style={{
          alignItems: "center",
          border: `1pt solid ${process.env.REACT_APP_BLUE_COLOUR}`,
          borderRadius: "50px",
          color: process.env.REACT_APP_BLUE_COLOUR,
          cursor: "pointer",
          display: "flex",
          fontSize: process.env.REACT_APP_MID_FONT_SIZE,
          fontStyle: process.env.REACT_APP_MID_FONT_SIZE,
          fontWeight: "bold",
          height: "40px",
          justifyContent: "center",
          marginTop: "16px",
          width: "130px",
        }}
        id="error"
        onClick={onRetry}
      >
        {checkFailure ? "Ok" : <>{isLoading ? "Loading..." : "Recapture"}</>}
      </div>
    </div>
  );
}

export default CustomError;
