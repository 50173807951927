import React from "react";
import { Spacer } from "../../components/extras";
import BackButton from "../../../component/backButton";
import { useHistory } from "react-router-dom";
import InputText from "../../../component/input";
import PrimaryButton from "../../../component/button";
import POSIMAGE from "../../../../../../../icons/pos-image.png";

const ApprovePosScreen = () => {
  const { location } = useHistory();
  const history = useHistory();

  const data = location.state;

  const Terminal = ({ refNo, content }) => (
    <div className="terminal">
      <img
        className="pos-request-details-image"
        src={data?.terminalImage ? data?.terminalImage : POSIMAGE}
        alt="pos terminal"
      />
      <div>
        <p className="terminal-ref">{content}</p>
        <div className="terminal-content">
          <span style={{ fontSize: "14px" }}>Ref no</span>
          <br />
          <span>{refNo}</span>
        </div>
      </div>
    </div>
  );
  return (
    <main className="main-container">
      <div className="inventoryLabelContainer">
        <div>
          <h1 className="inventoryLabelText">Approve POS</h1>
          <Spacer height={"20px"} />
          <BackButton marginBottom={0} onClick={() => history.goBack()} />
        </div>
      </div>
      <section className="shipment-section2-container">
        <article className="approvePos-article1-container">
          <InputText
            disabled={true}
            value={data.requestId}
            label="Request ID"
            placeholder="43397744"
          />
          <Spacer height={"5px"} />
          <InputText
            disabled={true}
            value={data.agentName}
            label="Agent Name"
            placeholder="Tofunmi Akinlade"
          />
          <Spacer height={"5px"} />
          <InputText
            disabled={true}
            value={data.noOfPos}
            label="Quantity"
            placeholder="8"
          />
          <Spacer height={"5px"} />
          <InputText
            disabled={true}
            value={data.amount}
            label="Amount Paid"
            placeholder="N21,454.54"
          />
          <Spacer height={"5px"} />
          <InputText
            disabled={true}
            value={data?.address}
            label="Shipping Address"
            placeholder="16B Chrissy Estate, Lekki"
          />
          <Spacer height={"20px"} />

          <PrimaryButton margin="0">Approve Request</PrimaryButton>
        </article>
        <article className="shipment-article2-container">
          <div className="terminal-details-container">
            {[1, 2, 3, 4, 5].map((item) => (
              <Terminal
                refNo="230439784787843753734783"
                content="A920 POS Terminal"
              />
            ))}
          </div>
        </article>
      </section>
    </main>
  );
};

export default ApprovePosScreen;
