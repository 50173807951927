import React, { useEffect, useState } from "react";
import BackButton from "../component/backButton";
import Title from "../component/titleRevamp.js";
import H1 from "../../../../../components/h1";
import InputText from "../component/input";
import SelectInput from "../component/select";
import PrimaryButton from "../component/button";
import { Col } from "react-bootstrap";
import PhoneInput from "../component/phoneInput";
import phoneValidators from "../../../../../utils/validators/phone-validators";
import { isBusinessAddressValidNOK, isNameValid } from "../component/extras";
import { firetoast, notify } from "../../../../../components/extras";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../../constants/api";
import Onboarding from "../../../../../services/api/resources/onboarding";
import { formatPhoneNumber } from "../../../../../utils/formatters";
import { NIGERIA_PHONE_PREFIX } from "../../../../../constants";

const MAX_BUSINESS_NAME_LENGTH = 50;
const NextOfKinScene = ({ setScreen, application, fetchApplication }) => {
  const applicationId = localStorage.getItem("applicationId");
  const onboarding = new Onboarding();
  const [propagateFormErrors, setPropagateFormErrors] = useState(false);
  const [fieldsFocused, setFieldsFocused] = useState([]);

  // const updatednextOfKinDetailsForm = JSON.parse(
  //   localStorage.getItem('nextOfKinDetailsForm')
  // );

  // const [nextOfKinDetailsForm, setNextOfKinDetailsForm] = useState({

  //   firstName: application
  //     ? application?.nextOfKin?.firstName
  //     : updatednextOfKinDetailsForm
  //     ? updatednextOfKinDetailsForm.firstName
  //     : '',
  //   gender: application
  //     ? application?.nextOfKin?.gender
  //     : updatednextOfKinDetailsForm
  //     ? updatednextOfKinDetailsForm.gender
  //     : '',

  //   phoneNumber: application
  //     ? application?.nextOfKin?.phoneNumber
  //     : updatednextOfKinDetailsForm
  //     ? updatednextOfKinDetailsForm.phoneNumber
  //     : '',
  //   relationship: application
  //     ? application?.nextOfKin?.relationship
  //     : updatednextOfKinDetailsForm
  //     ? updatednextOfKinDetailsForm.relationship
  //     : '',
  //   phonePrefix: NIGERIA_PHONE_PREFIX,

  //   address: application
  //     ? application?.nextOfKin?.address
  //     : updatednextOfKinDetailsForm
  //     ? updatednextOfKinDetailsForm.address
  //     : '',
  // });

  // localStorage.setItem(
  //   'nextOfKinDetailsForm',
  //   JSON.stringify(nextOfKinDetailsForm)
  // );

  const [nextOfKinDetailsForm, setNextOfKinDetailsForm] = useState({
    firstName: application ? application?.nextOfKin?.firstName : "",
    gender: application ? application?.nextOfKin?.gender : "",
    phoneNumber: application ? application?.nextOfKin?.phoneNumber : "",
    relationship: application ? application?.nextOfKin?.relationship : "",
    address: application ? application?.nextOfKin?.address : "",
    phonePrefix: NIGERIA_PHONE_PREFIX,
  });

  useEffect(() => {
    const updatednextOfKinDetailsForm = JSON.parse(
      localStorage.getItem("nextOfKinDetailsForm")
    );

    if (updatednextOfKinDetailsForm) {
      setNextOfKinDetailsForm({
        firstName:
          application?.nextOfKin?.firstName ||
          updatednextOfKinDetailsForm.firstName ||
          "",
        gender:
          application?.nextOfKin?.gender ||
          updatednextOfKinDetailsForm.gender ||
          "",
        phoneNumber:
          application?.nextOfKin?.phoneNumber ||
          updatednextOfKinDetailsForm.phoneNumber ||
          "",
        relationship:
          application?.nextOfKin?.relationship ||
          updatednextOfKinDetailsForm.relationship ||
          "",
        address:
          application?.nextOfKin?.address ||
          updatednextOfKinDetailsForm.address ||
          "",
        phonePrefix: NIGERIA_PHONE_PREFIX,
      });
    }
  }, [application]);

  useEffect(() => {
    localStorage.setItem(
      "nextOfKinDetailsForm",
      JSON.stringify(nextOfKinDetailsForm)
    );
  }, [nextOfKinDetailsForm]);
  const [isLoading, setIsLoading] = useState(false);

  const updateFormField = (params) => {
    const newForm = {
      ...nextOfKinDetailsForm,
      ...params,
    };

    setNextOfKinDetailsForm(newForm);
  };

  const isSenderPhoneValid = (newForm) => {
    const Form = newForm || nextOfKinDetailsForm;
    const countryValidator = phoneValidators[nextOfKinDetailsForm.phonePrefix];
    return (
      Form.phoneNumber &&
      (countryValidator ? countryValidator(Form.phoneNumber) : true)
    );
  };

  const isBeneficiaryPhoneValid = (newForm) => {
    const Form = newForm || nextOfKinDetailsForm;
    const countryValidator = phoneValidators[nextOfKinDetailsForm.phonePrefix];

    return (
      Form.phoneNumber &&
      (countryValidator ? countryValidator(Form.phoneNumber) : true)
    );
  };

  const isFormValid = () => {
    return (
      nextOfKinDetailsForm.relationship &&
      nextOfKinDetailsForm.address &&
      nextOfKinDetailsForm.phoneNumber &&
      // nextOfKinDetailsForm.emailAddress &&
      nextOfKinDetailsForm.gender &&
      nextOfKinDetailsForm.firstName
      // &&
      // nextOfKinDetailsForm.surname
    );
  };

  const handleSubmit = async () => {
    // setIsLoading(true);
    // const payload = {
    //   nextOfKin: {
    //     firstName: nextOfKinDetailsForm.firstName,
    //     gender: nextOfKinDetailsForm.gender,
    //     middleName: nextOfKinDetailsForm.middleName,
    //     phoneNumber: formatPhoneNumber(nextOfKinDetailsForm.phoneNumber),
    //     relationship: nextOfKinDetailsForm.relationship,
    //     address: nextOfKinDetailsForm.address,
    //   },
    // };

    // const { status, response } = await onboarding.putApplication(
    //   payload,

    //   applicationId
    // );

    // setIsLoading(false);
    // if (status === ERROR_STATUS) {
    //   firetoast("error", `${response.description}`, "");
    // } else if (status === SUCCESS_STATUS) {
    //   notify("Saved", "success");
    //   fetchApplication();
    //   setScreen(6);
    // }
    setScreen(11);
  };

  const relationship = [
    "Aunt",
    "Brother",
    "Cousin",
    "Daughter",
    "Father",
    "Guardian",
    "Mother",
    "Nephew/Niece",
    "Sister",
    "Spouse",
    "Son",
    "Uncle",
  ];
  const gender = ["Male", "Female"];
  return (
    <>
      <BackButton
        onClick={() => {
          // localStorage.removeItem('lga');
          setScreen(7);
        }}
      />
      <main className="aggregator-container">
        <Col
          style={{
            marginBottom: "20px",
          }}
        >
          <Title pageNum={"7"} />
          <H1
            style={{
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "1rem",
            }}
          >
            Next of Kin
          </H1>
          {/* <div
            style={{
              display: 'flex',
              gap: '10px',
            }}
          > */}
          <InputText
            // flex={'1'}
            label={"First Name"}
            placeholder={"Enter your first name"}
            onChange={(e) => {
              updateFormField({ firstName: e.target.value });
            }}
            labelColor={
              !nextOfKinDetailsForm.firstName ||
              isNameValid(nextOfKinDetailsForm.firstName)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !nextOfKinDetailsForm.firstName ||
              isNameValid(nextOfKinDetailsForm.firstName)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            value={nextOfKinDetailsForm.firstName}
            maxLength={MAX_BUSINESS_NAME_LENGTH}
          />
          <InputText
            // flex={'1'}
            label={"Last Name"}
            placeholder={"Enter your last  name"}
            onChange={(e) => {
              updateFormField({ firstName: e.target.value });
            }}
            labelColor={
              !nextOfKinDetailsForm.firstName ||
              isNameValid(nextOfKinDetailsForm.firstName)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !nextOfKinDetailsForm.firstName ||
              isNameValid(nextOfKinDetailsForm.firstName)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            value={nextOfKinDetailsForm.firstName}
            maxLength={MAX_BUSINESS_NAME_LENGTH}
          />

          <PhoneInput
            onChange={(value) => {
              updateFormField({ phoneNumber: value });
            }}
            form={nextOfKinDetailsForm.phoneNumber}
            updateFormField={updateFormField}
            isSenderPhoneValid={isSenderPhoneValid}
            isBeneficiaryPhoneValid={isBeneficiaryPhoneValid}
            fieldsFocused={fieldsFocused}
            setFieldsFocused={setFieldsFocused}
            propagateFormErrors={propagateFormErrors}
            title={"Phone Number"}
          />

          <SelectInput
            flex={"1"}
            onChange={(e) => {
              updateFormField({ gender: e.target.value });
            }}
            placeholder={"gender"}
            value={nextOfKinDetailsForm.gender}
            option={gender}
            label={"Gender"}
          />
          <div style={{ height: "12px" }} />
          <SelectInput
            label={"Relationship"}
            onChange={(e) => {
              updateFormField({ relationship: e.target.value });
            }}
            placeholder={"relationship"}
            value={nextOfKinDetailsForm.relationship}
            option={relationship}
          />
          <div
            style={{
              marginTop: "15px",
            }}
          >
            <InputText
              label={"Address"}
              labelColor={
                !nextOfKinDetailsForm.address ||
                isBusinessAddressValidNOK(nextOfKinDetailsForm)
                  ? "black"
                  : process.env.REACT_APP_RED_COLOUR
              }
              border={`1px solid ${
                !nextOfKinDetailsForm.address ||
                isBusinessAddressValidNOK(nextOfKinDetailsForm)
                  ? "#DFE2E6"
                  : process.env.REACT_APP_RED_COLOUR
              }`}
              placeholder={"Enter your address"}
              onChange={(e) => {
                updateFormField({ address: e.target.value });
              }}
              maxLength={200}
              value={nextOfKinDetailsForm.address}
            />
          </div>

          <PrimaryButton
            // disabled={!isFormValid() || isLoading}
            onClick={handleSubmit}
          >
            {isLoading ? "Processing..." : "Save & Continue"}
          </PrimaryButton>
        </Col>
      </main>
    </>
  );
};

export default NextOfKinScene;
