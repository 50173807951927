import React from "react";
import { Form } from "react-bootstrap";
import { asteriks } from "../../../../icons";

export const SelectInputState2 = ({
  label,
  disabled = false,
  option,
  onChange,
  placeholder,
  value,

  getState,
}) => {
  return (
    <div
      style={{
        marginTop: "1rem",
        marginBottom: "1rem",
      }}
    >
      <Form.Label
        style={{
          color: "black",
          fontSize: "14px",
          fontWeight: "bold",
        }}
      >
        {label}
        <span style={{ marginBottom: "2rem" }}>{asteriks()}</span>
      </Form.Label>
      <Form.Control onChange={onChange} disabled={disabled} as="select">
        <option value={getState ? getState : value} disabled selected>
          {getState ? getState : ` Select ${placeholder}...`}
        </option>
        {option.map((item) => {
          const { id, name } = item;
          return <option key={id}>{name}</option>;
        })}
      </Form.Control>
    </div>
  );
};
export const SelectInputLga2 = ({
  label,
  disabled = false,
  option,
  onChange,
  placeholder,
  value,
  // border,
  getLga,
}) => {
  return (
    <div
      style={{
        marginTop: "1rem",
        marginBottom: "1rem",
      }}
    >
      <Form.Label
        style={{
          color: "black",
          fontSize: "14px",
          fontWeight: "bold",
        }}
      >
        {label}
        <span>{asteriks()}</span>
      </Form.Label>
      <Form.Control
        style={
          {
            // border: none,
          }
        }
        onChange={onChange}
        disabled={disabled}
        as="select"
      >
        <option value={getLga ? getLga : value} selected>
          {getLga ? getLga : ` Select ${placeholder}...`}
        </option>
        {option.map((item) => {
          const { id, name } = item;

          return <option key={id}>{name}</option>;
        })}
      </Form.Control>
    </div>
  );
};

export const SelectBusinessType2 = ({
  label,
  disabled = false,
  option,
  onChange,
  placeholder,
  value,
  border,
  getBusinessType,
}) => {
  return (
    <div
      style={{
        marginTop: "1rem",
        marginBottom: "1rem",
      }}
    >
      <Form.Label
        style={{
          color: "black",
          fontSize: "14px",
          fontWeight: "600",
        }}
      >
        {label}
        <span>{asteriks()}</span>
      </Form.Label>
      <Form.Control
        style={{
          border: border,
        }}
        onChange={onChange}
        disabled={disabled}
        as="select"
      >
        <option
          disabled
          value={getBusinessType ? getBusinessType : value}
          selected
        >
          {getBusinessType ? getBusinessType : ` Select ${placeholder}...`}
        </option>
        {option.map((item) => {
          const { id, business_type } = item;

          return <option key={id}>{business_type}</option>;
        })}
      </Form.Control>
    </div>
  );
};
