import Requester from '../finch-requester';

const API_BASE_URL = process.env.REACT_APP_SETTLEMENT_API_BASE_URL;

export default class Settlement {
  constructor(props) {
    this.apiRequester = props ? props.apiRequester || new Requester({
      apiBaseUrl: API_BASE_URL
    }) : new Requester({
      apiBaseUrl: API_BASE_URL
    })
  }

  getWalletUnsettledBalance(agentPhoneNo) {
    return this.apiRequester.get({
      endpoint: "commissions/unsettled",
      args: {
        agentPhoneNo
      }
    })
  }

  getUnsettledBalance() {
    return this.apiRequester.get({
      endpoint: "commissions/unsettled",
      args: {
        agentPhoneNo: ''
      }
    })
  }

  getAgentCommission(agentCode, pageNum, pageSize) {
    return this.apiRequester.get({
      endpoint: 'commissions/getReport',
      args: {
        agentId: agentCode,
        fromPage: pageNum,
        pageSize
      }
    })
  }

  getAgentCommissionCount(agentCode) {
    return this.apiRequester.get({
      endpoint: 'commissions/getCount',
      args: {
        agentId: agentCode
      }
    })
  }
}