import React from 'react';
import { Nav, Row, Tab } from 'react-bootstrap';
import Footer from '../../components/footer';
import Header from '../../components/header';
import BaseScene from '../base-scene';
import ContactUsForm from './scenes/contact-us-form';
import FAQScene from './scenes/faq';
import SignupForm from './scenes/signup';
// import SignupAgent from './scenes/signupagent';

export default class Signup extends BaseScene {

  location = window.location.href;
  path = '/signup';
  title = 'Signup';

  state = {
    activeTab: 'signup'
  }

  requiresEmptySession = true;

  moveToTab(tab) {
    this.setState({ 'activeTab': tab })
    window.scrollTo(0, 0)
  }

  render() {
    return <div style={{
      width: '100%',
      // maxWidth: '1440px',
      marginLeft: 'auto',
      marginRight: 'auto',
      minHeight: '100vh',
      height: '100%',
      overflow: 'hidden',
      display: 'block',
      position: 'relative',
      paddingBottom: '100px',
      backgroundColor: 'white'
    }}>

      <Header />

      <Tab.Container id="left-tabs-example" activeKey={this.state.activeTab} onSelect={() => { }}>
        <Nav style={{ backgroundColor: '#828484', paddingLeft: this.state.deviceIsMobile ? '10%' : '10%', marginTop: this.state.deviceIsMobile ? '70px' : '60px' }}>
          {/* <Nav.Item style={{backgroundColor: this.state.activeTab === 'faq' ? '#929293' : 'transparent', padding: '3px'}}>
            <Nav.Link eventKey="faq" onSelect={() => this.setState({'activeTab': 'faq'})} style={{color: 'white', fontSize: process.env.REACT_APP_MID_FONT_SIZE, textAlign: 'center'}}>FAQs</Nav.Link>
          </Nav.Item>
          <Nav.Item style={{backgroundColor: this.state.activeTab === 'contact-us' ? '#929293' : 'transparent', padding: '3px', paddingTop: '10px'}}>
            <a href='https://help.interswitchgroup.com/' target='_blank' rel="noopener noreferrer" style={{color: 'white', fontSize: process.env.REACT_APP_MID_FONT_SIZE, textAlign: 'center'}}>Contact Us</a>
          </Nav.Item>
          <Nav.Item style={{backgroundColor: this.state.activeTab === 'signup' ? '#929293' : 'transparent', padding: '3px'}}>
            <Nav.Link eventKey="signup" onSelect={() => this.setState({'activeTab': 'signup'})} style={{color: 'white', fontSize: process.env.REACT_APP_MID_FONT_SIZE, textAlign: 'center'}}>Registration</Nav.Link>
          </Nav.Item> */}
        </Nav>

        <Row style={{ margin: 0, padding: 0 }}>
          <Tab.Content style={{ width: '100%' }}>
            <Tab.Pane eventKey='faq'>
              <FAQScene moveToTab={(tab) => this.moveToTab(tab)} />
            </Tab.Pane>
            <Tab.Pane eventKey='contact-us'>
              <ContactUsForm moveToTab={(tab) => this.moveToTab(tab)} />
            </Tab.Pane>
            <Tab.Pane eventKey='signup'>
              <SignupForm moveToTab={(tab) => this.moveToTab(tab)} location={this.props.location} />
            </Tab.Pane>
            {/* <Tab.Pane eventKey='signupagent'>
              <SignupAgent moveToTab={(tab) => this.moveToTab(tab)} location={this.props.location} />
            </Tab.Pane> */}
          </Tab.Content>
        </Row>
      </Tab.Container>

      <Footer />
    </div>
  }
}
