import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import InputText from "../component/input";
import "react-datepicker/dist/react-datepicker.css";

import H1 from "../../../../components/h1";
import PrimaryButton from "../component/button";
import HeaderSignup from "../../../../components/header-signup";
import { navigateTo } from "../../../../services/redux/actions/navigation";
import SignUp from "../component/signupText";
import Stepper from "../component/stepper";
import { isNameValid } from "../component/extras";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../constants/api";
import Onboarding from "../../../../services/api/resources/onboarding";
import { notify } from "../../../../components/extras";

const PersonalDetailsScene = ({ setScreen, application }) => {
  const onboarding = new Onboarding();

  const agentOnboardingInfo = JSON.parse(localStorage.getItem('agentOnboardingInfo'));
  const createApplication = JSON.parse(localStorage.getItem('createApplication'));
  const bvnApplicationInfo = JSON.parse(localStorage.getItem('bvnApplicationInfo'));
  const secureAccountDetailsForm = JSON.parse(localStorage.getItem('secureAccountDetailsForm'));
  const [isLoading, setIsLoading] = useState(false);

  const [personalDetailsForm, setPersonalDetailsForm] = useState({
    birthPlace: '',
    maidenName: '',
  });

  useEffect(() => {
    const updatedPersonalDetailsForm = JSON.parse(
      localStorage.getItem("personalDetailsForm")
    );

    if (updatedPersonalDetailsForm) {
      setPersonalDetailsForm({
        birthPlace:
          application?.personalDetailsForm?.birthPlace ||
          updatedPersonalDetailsForm.birthPlace ||
          "",
        maidenName:
          application?.personalDetailsForm?.maidenName ||
          updatedPersonalDetailsForm.maidenName ||
          "",
      });
    }
  }, [application]);

  useEffect(() => {
    localStorage.setItem(
      "personalDetailsForm",
      JSON.stringify(personalDetailsForm)
    );
  }, [personalDetailsForm]);

  const updateFormField = (params) => {
    const newForm = {
      ...personalDetailsForm,
      ...params,
    };

    setPersonalDetailsForm(newForm);
  };

  const isFormValid = () => {
    return (personalDetailsForm.birthPlace &&
      personalDetailsForm.maidenName &&
      isNameValid(personalDetailsForm.birthPlace) &&
      isNameValid(personalDetailsForm.maidenName))
  };

  const handleSubmit = async () => {
    const payload = {
      applicationId: createApplication?.applicationId,
      kycId: agentOnboardingInfo?.kycData?.kycId,
      placeOfBirth: personalDetailsForm?.birthPlace,
      mothersMaidenName: personalDetailsForm?.maidenName,
    };

    try {
      setIsLoading(true);

      const responseObj = await onboarding.personalDetailsSubmit(payload);
      const { status, response } = responseObj;
      console.log("response", responseObj)

      setIsLoading(false);

      if (status === ERROR_STATUS) {
        notify(`${response.description}`, "error");
      } else if (status === SUCCESS_STATUS) {
        setScreen(5);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Validation failed:", error);
      notify("Something went wrong, please try again", "error");
    }
  };

  useEffect(() => {
    const bvnApplicationInfo = async () => {
      const authToken = localStorage.getItem('authToken');

      try {
        const responseObj = await onboarding.getApplicationDetails(`Bearer ${authToken}`);
        const { status, response } = responseObj;
        localStorage.setItem(
          "bvnApplicationInfo",
          JSON.stringify(response)
        );
      } catch (error) {
        setIsLoading(false);
      }
    };

    bvnApplicationInfo();
  }, []);

  return (
    <>
      <HeaderSignup />
      <main className="onboarding-container">
        <Col
          style={{
            marginBottom: "20px",
            margin: "0 auto",
          }}
        >
          <SignUp />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              gap: "10px",
              marginBottom: "30px",
            }}
          >
            <Stepper borderColor="#00425F" />
            <Stepper borderColor="#00425F" />
            <Stepper borderColor="#00425F" />
            <Stepper borderColor="#00425F" />
          </div>

          <H1
            style={{
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "1rem",
              fontSize: "2rem",
            }}
          >
            Personal Details
          </H1>
          <p style={{ marginBottom: "20px" }}>
            Please confirm your details below and set up password
          </p>

          <InputText
            disabled={true}
            value={bvnApplicationInfo?.firstName}
            label={"First Name"}
          />
          <InputText
            disabled={true}
            value={bvnApplicationInfo?.middleName}
            label={"Middle Name"}
          />
          <InputText
            disabled={true}
            value={bvnApplicationInfo?.surname}
            label={"Last Name"}
          />
          <InputText
            disabled={true}
            value={bvnApplicationInfo?.phoneNumber}
            label={"BVN Phone Number"}
          />
          <InputText
            disabled={true}
            value={secureAccountDetailsForm?.email}
            label={"Email Address"}
          />
          <InputText
            disabled={true}
            value={bvnApplicationInfo?.dob}
            label={"Date of Birth"}
          />
          <InputText
            disabled={true}
            value={bvnApplicationInfo?.walletPhoneNumber}
            label={"Proposed Phone Number"}
          />
          <InputText
            label={'Place of Birth'}
            labelColor={
              !personalDetailsForm.birthPlace ||
                isNameValid(personalDetailsForm.birthPlace)
                ? 'black'
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${!personalDetailsForm.birthPlace ||
              isNameValid(personalDetailsForm.birthPlace)
              ? '#DFE2E6'
              : process.env.REACT_APP_RED_COLOUR
              }`}
            placeholder={'Enter confirm password'}
            onChange={(e) => {
              updateFormField({ birthPlace: e.target.value });
            }}
            maxLength={200}
            value={personalDetailsForm.birthPlace}
          />

          <InputText
            label={`Mother's Maiden Name`}
            labelColor={
              !personalDetailsForm.maidenName ||
                isNameValid(personalDetailsForm.maidenName)
                ? 'black'
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${!personalDetailsForm.maidenName ||
              isNameValid(personalDetailsForm.maidenName)
              ? '#DFE2E6'
              : process.env.REACT_APP_RED_COLOUR
              }`}
            placeholder={'Enter confirm password'}
            onChange={(e) => {
              updateFormField({ maidenName: e.target.value });
            }}
            maxLength={200}
            value={personalDetailsForm.maidenName}
          />

          <PrimaryButton
            disabled={!isFormValid() || isLoading}
            // onClick={() => setScreen(5)}
            onClick={handleSubmit}
          >
            {" "}
            {isLoading ? "Processing..." : "Preview"}
          </PrimaryButton>
        </Col>
      </main>
    </>
  );
};

function mapStateToProps(state) {
  return {
    // isLoading: state.tunnel.isLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: (pendingUrl) => dispatch(navigateTo(pendingUrl)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalDetailsScene);
