export default class WalletSerializer {
  constructor(props) {
    this.current_balance = props.transactionWalletBalance / 100;
    this.commissions_earned = props.commissionWalletBalance / 100;
    this.float_balance = props.float_balance;
    this.agent_id = props.agent_id;
    this.unsettled_commission = props.unsettledWalletBalance / 100;
    this.ledger_balance = props.ledgerBalance / 100;
    this.wallet_date = new Date()
    this.domain_name = props.domainName
  }

  asJson() {
    return Object.assign({}, this)
  }
}
