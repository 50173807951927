import React from 'react';
import {
  statusBackgroundColor,
  statusBorderColor,
  statusColor,
} from '../scenes/home/scenes/aggregator-module/component/extras';

export const StatusComponent = ({ value }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'start',
      }}
    >
      <div
        style={{
          color: statusColor(value),
          textAlign: 'left',
          textTransform: 'capitalize',

          display: 'flex',
          justifyContent: 'center',

          border: `1px solid ${statusBorderColor(value)}`,
          padding: '0 7px',
          borderRadius: '5px',
          backgroundColor: statusBackgroundColor(value),
        }}
      >
        <div
          style={{
            display: 'grid',
            placeContent: 'center',
            marginRight: '5px',
          }}
        >
          <div
            style={{
              width: '7px',
              height: '7px',
              borderRadius: '100%',

              display: 'grid',
              placeContent: 'center',
              backgroundColor: statusColor(value),
            }}
          />
        </div>
        <div>{value}</div>
      </div>
    </div>
  );
};
