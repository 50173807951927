import SessionTimer from '../utils/session-timer';


export const appSessionTimer = new SessionTimer({
  onEnd: () => {
    window.location.href = '/logout';
  },
  timeout: parseInt(process.env.REACT_APP_IDLE_TIMEOUT),
});
export const tokenSessionTimer = new SessionTimer();

document.addEventListener('mousemove', appSessionTimer.restart, false);
document.addEventListener('mousedown', appSessionTimer.restart, false);
document.addEventListener('keypress', appSessionTimer.restart, false);
document.addEventListener('scroll', appSessionTimer.restart, false);
document.addEventListener('touchmove', appSessionTimer.restart, false);
