import moment from 'moment';

import React, { useEffect, useState } from 'react';

import ReactTable from 'react-table';
import MdMore from 'react-ionicons/lib/MdMore';
import MdEye from 'react-ionicons/lib/MdEye';
import MdColorWand from 'react-ionicons/lib/MdColorWand';

import Onboarding from '../../../../../services/api/resources/onboarding';
import Spinner from '../../../../../components/spinner';
import { ERROR_STATUS, SUCCESS_STATUS } from '../../../../../constants/api';
import { notify } from '../../../../../components/extras';
import { Dropdown, Row } from 'react-bootstrap';
import { getPaginationRows } from '../../../../../utils/helpers';
import { useDebounce } from '../context';

const ApplicationGrid = ({ filterStatus, searchText, navigateTo }) => {
  const onboarding = new Onboarding();
  const [loading, setLoading] = useState(true);
  const [application, setApplication] = useState([]);

  const debouncedValue = useDebounce(searchText, 1500);

  const [pages, setPages] = useState(1);
  const [pageNum, setPageNum] = useState(1);
  const [dataCount, setDataCount] = useState();

  const [pageSize, setPageSize] = useState(20);

  const convertStatusEnnum = (filterStatus) => {
    if (filterStatus === 'Cancelled') {
      return '6';
    } else if (filterStatus === 'Rejected') {
      return '5';
    } else if (filterStatus === 'Approved') {
      return '4';
    } else if (filterStatus === 'Awaiting approval') {
      return '3';
    } else if (filterStatus === 'Awaiting validation') {
      return '2';
    } else if (filterStatus === 'Draft') {
      return '1';
    } else if (filterStatus === 'All') {
      return '';
    }
  };
  const statusColor = (ennum) => {
    if (ennum === 6) {
      return '#A4251A';
    } else if (ennum === 5) {
      return '#A4251A';
    } else if (ennum === 4) {
      return '#36743D';
    } else if (ennum === 3) {
      return '#AF5F26';
    } else if (ennum === 2) {
      return '#AF5F26';
    } else if (ennum === 1) {
      return '#1A4983';
    }
  };
  const statusBackgroundColor = (ennum) => {
    if (ennum === 6) {
      return '#FBE9E9';
    } else if (ennum === 5) {
      return '#FBE9E9';
    } else if (ennum === 4) {
      return '#F1FEF1';
    } else if (ennum === 3) {
      return '#FEF6CF';
    } else if (ennum === 2) {
      return '#FEF6CF';
    } else if (ennum === 1) {
      return '#EBF8FE';
    }
  };
  const statusBorderColor = (ennum) => {
    if (ennum === 6) {
      return '#F4B7B5';
    } else if (ennum === 5) {
      return '#F4B7B5';
    } else if (ennum === 4) {
      return '#BEF2B9';
    } else if (ennum === 3) {
      return '#FDED94';
    } else if (ennum === 2) {
      return '#FDED94';
    } else if (ennum === 1) {
      return '#A8D6EF';
    }
  };

  const statusEnnum = (ennum) => {
    if (ennum === 6) {
      return 'cancelled';
    } else if (ennum === 5) {
      return 'rejected';
    } else if (ennum === 4) {
      return 'approved';
    } else if (ennum === 3) {
      return 'awaiting approval';
    } else if (ennum === 2) {
      return 'awaiting validation';
    } else if (ennum === 1) {
      return 'draft';
    }
  };
  const getApplication = async (
    pageSize_,
    pageNum_,
    filterStatus,
    searchText
  ) => {
    setLoading(true);

    const { response, status } = await onboarding.getAggregatorApplication(
      convertStatusEnnum(filterStatus),
      searchText,
      pageSize_,
      pageNum_
    );

    setLoading(false);
    if (status === ERROR_STATUS) {
      setPages(1);
      notify(response.description, 'error');
    } else if (status === SUCCESS_STATUS) {
      setDataCount(response.count / pageSize);
      setPages(Math.ceil(response.count / pageSize));
      setApplication(response.content);
    }
  };

  useEffect(() => {
    getApplication(pageSize, pageNum, filterStatus, searchText);
  }, [filterStatus, debouncedValue]);

  const handleOpenResume = (props) => {
    localStorage.setItem('applicationId', props.original.applicationId);
    navigateTo('/agents/setup-new-agent');
  };
  const handleOpenView = (props) => {
    localStorage.setItem('applicationId', props.original.applicationId);
    navigateTo(`/agents/applications/${props.original.applicationId}`);
  };

  const reportColumns = [
    {
      id: '1',
      Header: (props) => <React.Fragment>Agent Name</React.Fragment>,
      Cell: (props) => <div style={{ textAlign: 'left' }}>{props.value}</div>,
      accessor: (props) => (
        <div>
          <span>{props.firstName}</span>
          <span className='pl-2'>{props.surname}</span>
        </div>
      ),
      headerClassName: 'table-header',
      className: 'table-cell',
      style: { 'white-space': 'unset' },
    },
    {
      Header: (props) => <React.Fragment>Email Address</React.Fragment>,
      Cell: (props) => <div style={{ textAlign: 'left' }}>{props.value}</div>,
      accessor: 'emailAddress',
      headerClassName: 'table-header',
      className: 'table-cell',
      style: { 'white-space': 'unset' },
      width: 240,
    },
    {
      Header: (props) => <React.Fragment>Phone Number</React.Fragment>,
      Cell: (props) => {
        return <div style={{ textAlign: 'left' }}>{props.value}</div>;
      },
      accessor: 'phoneNumber',
      headerClassName: 'table-header',
      className: 'table-cell',
      style: { 'white-space': 'unset' },
    },
    {
      Header: (props) => <React.Fragment>Date Created</React.Fragment>,
      Cell: (props) => (
        <div style={{ textAlign: 'left' }}>
          {moment(props.value).format('DD-MM-YYYY HH:mm a')}
        </div>
      ),
      accessor: 'dateCreated',
      headerClassName: 'table-header',
      className: 'table-cell',
      style: { 'white-space': 'unset' },
    },
    {
      Header: (props) => <React.Fragment>Status</React.Fragment>,
      Cell: (props) => {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'start',
            }}
          >
            <div
              style={{
                color: statusColor(props.value),
                textAlign: 'left',
                textTransform: 'capitalize',

                display: 'flex',
                justifyContent: 'center',

                border: `1px solid ${statusBorderColor(props.value)}`,
                padding: '0 7px',
                borderRadius: '5px',
                backgroundColor: statusBackgroundColor(props.value),
              }}
            >
              <div
                style={{
                  display: 'grid',
                  placeContent: 'center',
                  marginRight: '5px',
                }}
              >
                <div
                  style={{
                    width: '7px',
                    height: '7px',
                    borderRadius: '100%',

                    display: 'grid',
                    placeContent: 'center',
                    backgroundColor: statusColor(props.value),
                  }}
                />
              </div>
              <div>{statusEnnum(props.value)}</div>
            </div>
          </div>
        );
      },

      accessor: 'status',
      headerClassName: 'table-header',
      className: 'table-cell',
      style: { 'white-space': 'unset' },
    },
    {
      Header: (props) => <React.Fragment>Action</React.Fragment>,
      Cell: (props, original) => (
        <Dropdown>
          <Dropdown.Toggle
            style={{
              cursor: 'pointer',
            }}
            as={'span'}
            variant='secondary'
            id='dropdown-variants-secondary'
          >
            <MdMore color={process.env.REACT_APP_GREY_COLOUR} />
          </Dropdown.Toggle>

          <Dropdown.Menu variant='secondary' id='dropdown-variants-secondary'>
            <Dropdown.Item onClick={() => handleOpenView(props)}>
              <Row
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <MdEye
                  fontSize='15px'
                  color={process.env.REACT_APP_GREY_COLOUR}
                />

                <div>View</div>
              </Row>
            </Dropdown.Item>
            {(props.original.status === 1 || props.original.status === 5) && (
              <Dropdown.Item onClick={() => handleOpenResume(props)}>
                <Row
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <MdColorWand
                    fontSize='15px'
                    color={process.env.REACT_APP_GREY_COLOUR}
                  />
                  <div>Resume</div>
                </Row>
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      ),
      accessor: 'action',
      headerClassName: 'table-header',
      className: 'table-cell',
      width: 60,
    },
  ];

  return (
    <>
      <ReactTable
        columns={reportColumns}
        data={application}
        dataCount={dataCount}
        pageSizes={getPaginationRows(dataCount)}
        pages={pages}
        manual
        minRows={application.length > 0 ? 10 : 10}
        onFetchData={(state, instance) => {
          getApplication(
            state.pageSize,
            state.page + 1,
            filterStatus,
            searchText
          );
        }}
        loading={loading}
        noDataText={loading ? '' : 'You are yet to onboard an agent'}
        LoadingComponent={Spinner}
        getPaginationProps={(state, rowInfo, column, instance) => ({
          style: {
            overflow: 'visible',
            backgroundColor: '#ffffff',
          },
        })}
      />
    </>
  );
};

export default ApplicationGrid;
