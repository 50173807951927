import React from 'react';
import ReactTable from 'react-table';
import "react-table/react-table.css";
import { Row, Col, Dropdown } from 'react-bootstrap';
import MdMore from 'react-ionicons/lib/MdMore';

import { formatAmount, formatDate, handleStatusFormat, handleTransactionTypeFormat, handleCustomerIdFormat,handleCustomerIdFormatTwo, formatDateTime, sentenceCapitalization } from '../../../../../utils/formatters';
import { convertNgkToNgn } from '../../../../../utils/converters/currencies';
import VerticallyCenteredModal, { ReceiptModal } from '../../../../../components/vertically-centered-modal';

import './styles.scss';
import Text from '../../../../../components/text';
import Spinner from '../../../../../components/spinner';
import TransactionHistory from '../../../../../services/api/resources/transaction-history';
import { SUCCESS_STATUS } from '../../../../../constants/api';
import ActivityIndicator from '../../../../../components/activity-indicator';
import { Link } from 'react-router-dom';

const PENDING = 'PENDING_VALUE_PROVISIONING';
const REVERSAL_INT = 10;
const FAILED = 'CLOSED_BUT_FAILED';
export default class HistoricalTransactionsTable extends React.Component {

  transactionHistory = new TransactionHistory()

  constructor(props) {
    super(props)

    this.state = {
      showModal: false,
      showReceipt: false,
      transactionDetails: null,
      isLoading: false,
      isRequeryLoading: false
    }

    this.searchByTransRef = this.searchByTransRef.bind(this);
    this.requeryTransaction = this.requeryTransaction.bind(this);
  }
  

  async searchByTransRef(transRef) {
    this.setState({
      isLoading: true
    })
    const { status, code, response } = await this.transactionHistory.getTransactionsByRef(transRef);

    if (status === SUCCESS_STATUS) {
      this.setState({
        showModal: true,
        transactionDetails: response,
        isLoading: false
      })
    } else {
      this.setState({
        isLoading: false
      })
      this.props.showErrorMessage(JSON.stringify(response));
      setTimeout(() => {
        this.props.dismissErrorMessage()
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT))
    } 
  }

  async requeryTransaction(transRef) {
    this.setState({
      isRequeryLoading: true
    })
    const { status, code, response } = await this.transactionHistory.requeryTransaction(transRef)
    if (status === SUCCESS_STATUS) {
      this.setState({
        showModal: true,
        transactionDetails: response,
        isRequeryLoading: false
      })
    } else {
      this.setState({
        isRequeryLoading: false
      })
    }
  }

  render() {
    const handleOpen = (props) => {
      this.setState({
        showModal: true,
        transactionDetails: props.original,
      })
    }

    const handleReceiptOpen = (props) => {
      this.setState({
        transactionDetails: props.original,
        showReceipt: true,
      })
    }

    const handleClose = () => {
      this.setState({
        showModal: false,
        showReceipt: false,
      })
    }

    const transactionHistoryColumns = [{
      Header: props => <React.Fragment></React.Fragment>,
      Cell: (props) => (
        <div>{props.value}</div>
      ),
      accessor: 'serialNumber',
      headerClassName: 'table-header',
      className: 'table-cell',
      style: {'white-space': 'unset'},
      width: 50
    }, {
      Header: props => <React.Fragment>Reference ID</React.Fragment>,
      accessor: 'referenceId',
      headerClassName: 'table-header',
      style: {'white-space': 'unset'},
      className: 'table-cell',
      width: 120,
    }, {
      Header: props => <React.Fragment>Last modification date</React.Fragment>,
      accessor: 'dateModified',
      headerClassName: 'table-header',
      Cell: props => <div className='number'>{formatDateTime(props.value)}</div>,
      className: 'table-cell',
      width: 150,
      style: { 'white-space': 'unset' }
    }, {
      Header: props => <React.Fragment>Status</React.Fragment>,
      accessor: 'status',
      headerClassName: 'table-header',
      className: 'table-cell',
      style: {'white-space': 'unset'},
      width: 80
    }, {
      Header: props => <React.Fragment>Transaction type</React.Fragment>,
      accessor: 'transactionType',
      headerClassName: 'table-header',
      className: 'table-cell',
      resizable: false,
      style: {'white-space': 'unset'},
    }, {
      Header: props => <React.Fragment>Source</React.Fragment>,
      accessor: 'source',
      headerClassName: 'table-header',
      className: 'table-cell',
      style: {'white-space': 'unset'},
      width: 120
    }, {
      Header: props => <React.Fragment>Destination</React.Fragment>,
      accessor: 'destination',
      headerClassName: 'table-header',
      className: 'table-cell',
      style: {'white-space': 'unset'},
      width: 115,
    }, {
      Header: props => <React.Fragment>Debited amount</React.Fragment>,
      accessor: 'debitedAmount',
      headerClassName: 'table-header',
      Cell: props => <div className='number'>{`₦${formatAmount(props.value)}`}</div>,
      className: 'table-cell',
      style: {'white-space': 'unset'},
      width: 110
    }, {
      Header: props => <React.Fragment>Credited amount</React.Fragment>,
      accessor: 'creditedAmount',
      headerClassName: 'table-header',
      Cell: props => <div className='number'>{`₦${formatAmount(props.value)}`}</div>,
      className: 'table-cell',
      style: {'white-space': 'unset'},
      width: 150
    }, 
    {
      Header: props => <React.Fragment>Cash amount</React.Fragment>,
      accessor: 'cashAmount',
      headerClassName: 'table-header',
      Cell: props => <div className='number'>{`₦${formatAmount(props.value)}`}</div>,
      className: 'table-cell',
      style: {'white-space': 'unset'},
      width: 150
    },
    {
      Header: props => <React.Fragment>Consumer SP name</React.Fragment>,
      accessor: 'consumerSP',
      headerClassName: 'table-header',
      className: 'table-cell',
      style: {'white-space': 'unset'},
      width: 150
    }, 
    {
      Header: props => <React.Fragment>Original ESB reference</React.Fragment>,
      accessor: 'esbRef',
      headerClassName: 'table-header',
      className: 'table-cell',
      style: {'white-space': 'unset'},
      width: 200
    },
    {
      Header: props => <React.Fragment>External Reference</React.Fragment>,
      accessor: 'externalRef',
      headerClassName: 'table-header',
      className: 'table-cell',
      style: {'white-space': 'unset'},
      width: 150
    },
    {
      Header: props => <React.Fragment>Token type</React.Fragment>,
      accessor: 'tokenType',
      headerClassName: 'table-header',
      className: 'table-cell',
      style: {'white-space': 'unset'},
      width: 150
    },
    {
      Header: props => <React.Fragment>Consumer name</React.Fragment>,
      accessor: 'consumer',
      headerClassName: 'table-header',
      className: 'table-cell',
      style: {'white-space': 'unset'},
      width: 150
    },
    ];
    
    return (
      <>
        {this.state.showModal && <VerticallyCenteredModal
          onHide={handleClose}
          show={this.state.showModal}
          title='Transaction Details'
          body={<TransactionModalBody showFee={true} showReceiptLogo={false} searchByTransRef={this.searchByTransRef} details={this.state.transactionDetails}/>}
        />}
        {this.state.showReceipt && <ReceiptModal
          onHide={handleClose}
          show={this.state.showReceipt}
          title='Transaction Receipt'
          body={<TransactionModalBody showFee={false} showReceiptLogo={true} details={this.state.transactionDetails}/>}
        />}
        {this.state.isRequeryLoading 
          ? <ActivityIndicator />
          : <ReactTable
              columns={transactionHistoryColumns}
              data={this.props.data}
              pages={this.props.pages}
              defaultPageSize={50}
              manual
              onFetchData={(state, instance) => (
                this.props.getAllTransactions(state.page + 1, state.pageSize, this.props.selectedOption.value)
              )}
              loading={this.props.loading}
              minRows={(this.props.data).length > 0 ? 4 : 3}
              LoadingComponent={Spinner}
              getTdProps={(state, rowInfo, column, instance) => ({
                style: {
                  overflow: 'visible',
                  lineHeight: '20px',
                  textAlign: 'left',
                },
              })}
              getTheadThProps={(state, rowInfo, column, instance) => ({
                style: {
                  textAlign: 'left'
                },
              })}
            />
        }
    </>
  )
  }
}

function getReceiptHeader() {
  return (
    <div
      >
        <img 
          alt=""
          style={{
            top: '10px',
            left: '-10px',
            opacity: .1,
            position: 'absolute',
            width: '240px',
          }}
          src={'https://i.ibb.co/Qbsjn5b/isw.png'}
        />

        <img 
          alt=""
          style={{
            top: '100px',
            left: '250px',
            opacity: .1,
            position: 'absolute',
            width: '240px',
          }}
          src={'https://i.ibb.co/Qbsjn5b/isw.png'}
        />

        <img 
          alt=""
          style={{
            top: '200px',
            left: '140px',
            opacity: .1,
            position: 'absolute',
            width: '240px',
          }}
          src={'https://i.ibb.co/Qbsjn5b/isw.png'}
        />

        {/* <img
          alt=''
          style={{
            top: '20px',
            width: '40px'
          }}
          src={props.service ? props.service.imageUrl : ''}
        />   */}

        <img
          alt=''
          style={{
            top: '40px',
            left: '500px',
            width: '100px',
            float: 'right'
          }}
          src='https://i.ibb.co/Qbsjn5b/isw.png'
        />
        <p style={{color: process.env.REACT_APP_BLUE_COLOUR, paddingTop: '5px'}}>
          <h4><b>Thank you for using Quickteller Paypoint!</b></h4>
          <span>Here is your transaction receipt.</span>
          <span>See payment details below.</span>
        </p>
    </div>
  )
}

const TransactionModalBody = (props) => {
  return <>
    {(props.details.transactionType === 'TRANSFER' || props.details.transactionType === 'W2W') && (
      <div id='print-receipt'>

        {props.showReceiptLogo && getReceiptHeader()}

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE, fontWeight: 'bold'}}>Transaction Amount: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE, fontWeight: 'bold'}}>{`NGN${formatAmount(convertNgkToNgn(props.details.transactionAmount))}`}</Text>
          </Col>
        </Row>

        {props.showFee && <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE, fontWeight: 'bold'}}>Fee: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE, fontWeight: 'bold'}}>{`NGN${formatAmount(convertNgkToNgn(props.details.fee))}`}</Text>
          </Col>
        </Row>}

        <hr/>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Beneficiary Name: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE, textTransform: 'capitalize'}}>{`${props.details.beneficiaryFirstName} ${props.details.beneficiaryOtherName ? props.details.beneficiaryOtherName : ''} ${props.details.beneficiaryLastName ? props.details.beneficiaryLastName : ''}` }</Text>
          </Col>
        </Row>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Beneficiary Number: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{handleCustomerIdFormat(props.details.beneficiaryMobileNumber)}</Text>
          </Col>
        </Row>

        <Row hidden={props.details.transactionType === 'W2W'} style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Bank: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{props.details.bankName}</Text>
          </Col>
        </Row>

        <Row hidden={props.details.transactionType === 'W2W'} style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Account Number: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{props.details.beneficiaryAccountNumber}</Text>
          </Col>
        </Row>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Sender Name: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE, textTransform: 'capitalize'}}>{`${props.details.customerFirstName} ${props.details.customerOtherName ? props.details.customerOtherName : ''} ${props.details.customerLastName ? props.details.customerLastName : ''}`}</Text>
          </Col>
        </Row>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Sender Number: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{handleCustomerIdFormat(props.details.customerMsisdn)}</Text>
          </Col>
        </Row>

        <hr/>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Transaction Type: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{props.details.transactionType}</Text>
          </Col>
        </Row>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Transaction Reference: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{props.details.transactionRef}</Text>
          </Col>
        </Row>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Transaction Date: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{formatDate(props.details.dateCreated)}</Text>
          </Col>
        </Row>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Transaction Status: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{handleStatusFormat(props.details.statusCode)}</Text>
          </Col>
        </Row>

        {(props.details.responseDescription && props.details.responseDescription !== 'Successful') && <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Status: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{(props.details.responseDescription)}</Text>
          </Col>
        </Row>}

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Description: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{props.details.narration}</Text>
          </Col>
        </Row>

        {props.details.remark && <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Remark: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{props.details.remark}</Text>
          </Col>
        </Row>}

        <hr/>

        {/* <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Agent: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{props.details.agentName}</Text>
          </Col>
        </Row> */}

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Agent Business Name: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{sentenceCapitalization(props.details.businessName)}</Text>
          </Col>
        </Row>
      </div>
    )}
    {(props.details.transactionType === 'BILLS' ||
        props.details.transactionType === 'CASH_IN' ||
        props.details.transactionType === 'RECHARGE' ||
        props.details.transactionType === 'CASH_OUT' ||
        props.details.transactionType === 'FUND' ||
        props.details.transactionType === 'PAYPOINT_FUND' || 
        props.details.transactionType === 'COMMISSION_UNLOAD'
      ) && (
      <div id='print-receipt'>
        
        {props.showReceiptLogo && getReceiptHeader()}

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE, fontWeight: 'bold'}}>Transaction Amount: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE, fontWeight: 'bold'}}>{`NGN${formatAmount(convertNgkToNgn(props.details.transactionAmount))}`}</Text>
          </Col>
        </Row>

        {props.showFee && <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE, fontWeight: 'bold'}}>Fee: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE, fontWeight: 'bold'}}>{`NGN${formatAmount(convertNgkToNgn(props.details.fee))}`}</Text>
          </Col>
        </Row>}

        <hr/>

        <Row hidden={props.details.transactionType === 'RECHARGE' || props.details.transactionType === 'FUND' || props.details.transactionType === 'REVERSAL'} style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Customer Name: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{`${props.details.customerFirstName} ${props.details.customerLastName}`}</Text>
          </Col>
        </Row>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Phone Number: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{handleCustomerIdFormat(props.details.customerMsisdn)}</Text>
          </Col>
        </Row>

        {(props.details.transactionType === 'BILLS' && props.details.customerAddress) && <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Address: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{props.details.customerAddress}</Text>
          </Col>
        </Row>}

        <Row hidden={props.details.transactionType === 'RECHARGE' || props.details.transactionType === 'FUND' || props.details.transactionType === 'PAYPOINT_FUND' || props.details.transactionType === 'REVERSAL'} style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Customer ID: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{handleCustomerIdFormatTwo(props.details.customerId)}</Text>
          </Col>
        </Row>

        <hr/>

        <Row hidden={props.details.transactionType === 'FUND' || !props.details.pin} style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Pin: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{props.details?.pin}</Text>
          </Col>
        </Row>
        
        {props.details.transactionType === 'RECHARGE' ? <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Mobile Network: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{props.details.narration}</Text>
          </Col>
        </Row> : <Row hidden={props.details.transactionType === 'PAYPOINT_FUND'} style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{props.details.transactionType === 'REVERSAL' ? 'Description' : 'Biller Name'}: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{props.details.narration}</Text>
          </Col>
        </Row>}

        <hr hidden={props.details.transactionType === 'PAYPOINT_FUND' || props.details.transactionType === 'REVERSAL'}/>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Transaction Type: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{handleTransactionTypeFormat(props.details.transactionType)}</Text>
          </Col>
        </Row>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Transaction Reference: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{props.details.transactionRef}</Text>
          </Col>
        </Row>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Transaction Date: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{formatDate(props.details.dateCreated)}</Text>
          </Col>
        </Row>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Transaction Status: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{handleStatusFormat(props.details.statusCode)}</Text>
          </Col>
        </Row>

        {(props.details.responseDescription && props.details.responseDescription !== 'Successful') && <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Status: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{(props.details.responseDescription)}</Text>
          </Col>
        </Row>}

        <hr/>
        
        {/* <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Agent: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{props.details.agentName}</Text>
          </Col>
        </Row> */}

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Agent Business Name: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{sentenceCapitalization(props.details.businessName)}</Text>
          </Col>
        </Row>
      </div>
    )}

    {(props.details.transactionType === 'REVERSAL' 
      ) && (
      <div id='print-receipt'>
        
        {props.showReceiptLogo && getReceiptHeader()}

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE, fontWeight: 'bold'}}>Transaction Amount: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE, fontWeight: 'bold'}}>{`NGN${formatAmount(convertNgkToNgn(props.details.transactionAmount))}`}</Text>
          </Col>
        </Row>

        {props.showFee && <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE, fontWeight: 'bold'}}>Fee: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE, fontWeight: 'bold'}}>{`NGN${formatAmount(convertNgkToNgn(props.details.fee))}`}</Text>
          </Col>
        </Row>}

        <hr/>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Phone Number: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{handleCustomerIdFormat(props.details.customerMsisdn)}</Text>
          </Col>
        </Row>

        <hr/>
        
        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{props.details.transactionType === 'REVERSAL' ? 'Description' : 'Biller Name'}: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{props.details.narration}</Text>
          </Col>
        </Row>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Transaction Type: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{handleTransactionTypeFormat(props.details.transactionType)}</Text>
          </Col>
        </Row>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Transaction Reference: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{props.details.transactionRef}</Text>
          </Col>
        </Row>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Transaction Date: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{formatDate(props.details.dateCreated)}</Text>
          </Col>
        </Row>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Transaction Status: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{handleStatusFormat(props.details.statusCode)}</Text>
          </Col>
        </Row>

        <hr/>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Agent Business Name: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{sentenceCapitalization(props.details.businessName)}</Text>
          </Col>
        </Row>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Check original transaction details <Link onClick={() => props.searchByTransRef(props.details.narration.replace(/[^\d]/g, ''))}>{props.details.narration}</Link> </Text>
          </Col>
        </Row>
      </div>
    )}
  </>
}