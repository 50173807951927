import React from 'react';
import ReactTable from 'react-table';
import { formatAmount, handleStatusFormat, handleTransactionTypeFormat, formatDateTime } from '../../../../../utils/formatters';
import { convertNgkToNgn } from '../../../../../utils/converters/currencies';
import Spinner from '../../../../../components/spinner';

const transactionHistoryColumns = [
  {
    Header: props => <React.Fragment>Transaction Reference</React.Fragment>,
    accessor: 'uniqueReference',
    headerClassName: 'table-header',
    Cell: props => <div style={{ textAlign: "left" }}>{props.value}</div>,
    style: {'white-space': 'unset'},
    className: 'table-cell',
    width: 250,
  }, {
    Header: props => <React.Fragment>Description</React.Fragment>,
    accessor: 'narration',
    headerClassName: 'table-header',
    Cell: props => <div style={{ textAlign: "left" }}>{props.value}</div>,
    className: 'table-cell',
    style: {'white-space': 'unset'},
    width: 350
  }, {
    Header: props => <React.Fragment>Debited amount</React.Fragment>,
    accessor: 'debitAmount',
    headerClassName: 'table-header',
    Cell: props => <div style={{ textAlign: "left" }} className={`number ${props.value !== 0 && 'closed_but_failed-status'}`}>{`₦${formatAmount(convertNgkToNgn(props.value))}`}</div>,
    className: 'table-cell',
    style: {'white-space': 'unset'},
    width: 200
  }, {
    Header: props => <React.Fragment>Credited amount</React.Fragment>,
    accessor: 'creditAmount',
    headerClassName: 'table-header',
    Cell: props => <div style={{ textAlign: "left" }} className={`number ${props.value !== 0 && 'closed_and_successful-status'}`}>{`₦${formatAmount(convertNgkToNgn(props.value))}`}</div>,
    className: 'table-cell',
    style: {'white-space': 'unset'},
    width: 200
  }, 
  {
    Header: props => <React.Fragment>Balance</React.Fragment>,
    accessor: 'balance',
    headerClassName: 'table-header',
    Cell: props => <div style={{ textAlign: "left" }} className='number'>{`₦${formatAmount(convertNgkToNgn(props.value))}`}</div>,
    className: 'table-cell',
    style: {'white-space': 'unset'},
    width: 200
  },
  {
    Header: props => <React.Fragment>Ledger Balance</React.Fragment>,
    accessor: 'remainingBalance',
    headerClassName: 'table-header',
    Cell: props => <div style={{ textAlign: "left" }} className='number'>{`₦${formatAmount(convertNgkToNgn(props.value))}`}</div>,
    className: 'table-cell',
    style: {'white-space': 'unset'},
    width: 200
  },
  {
    Header: props => <React.Fragment>Entry Date</React.Fragment>,
    accessor: 'journalEntryDate',
    headerClassName: 'table-header',
    Cell: props => <div style={{ textAlign: "left" }} className='number'>{props.value ? formatDateTime(props.value) : ''}</div>,
    className: 'table-cell',
    style: { 'white-space': 'unset' },
    width: 200
  },
  {
    Header: props => <React.Fragment>Last Updated on</React.Fragment>,
    accessor: 'lastUpdatedOn',
    headerClassName: 'table-header',
    Cell: props => <div style={{ textAlign: "left" }} className='number'>{props.value ? formatDateTime(props.value) : ''}</div>,
    className: 'table-cell',
    style: { 'white-space': 'unset' }
  },
  ];

export default props => {
  return <ReactTable
    columns={transactionHistoryColumns}
    data={props.data}
    loading={props.loading}
    pageSize={10}
    showPagination={false}
    LoadingComponent={Spinner}
    minRows={props.data.length > 0 ? 0 : 5}
  />
}

