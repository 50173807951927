import React, { useState } from 'react';
import { Link } from "react-router-dom";
// import "../../scenes/styles.scss";
import { BadgeIcon } from '../../../../../icons';


export const AgentBanner = () => {
    let application = JSON.parse(
        localStorage.getItem('currentAgent')
    );

    return (
        <div style={{
            padding: '1rem 1.5rem',
            borderRadius: '8px',
            border: `1px solid #A8D6EF`,
            marginBottom: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: '#EBF8FE'
        }}>
            <div>
                <p
                    style={{
                        fontSize: '0.875rem',
                        margin: 0,
                        fontWeight: 600,
                        color: '#1F2126',
                        marginBottom: '10px',
                    }}
                >
                    {application?.agentClass} Account
                </p>
                <p
                    style={{
                        fontSize: '0.775rem',
                        margin: 0,
                        color: '#353F50',
                    }}
                >
                    You have a {application?.agentClass} Account. Upgrade to do more on POS Workflow.
                </p>
                <Link
                    className=''
                    to={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-profile/upgrade-list`}
                >
                    <span style={{
                        fontSize: '0.775rem',
                        margin: 0,
                        color: '#0275D8',
                        fontWeight: '600'
                    }}>Upgrade now.</span>
                </Link>
            </div>
            <div>
                {BadgeIcon()}
            </div>

        </div>

    );
};

export const PendingApprovalBanner = () => {

    return (
        <div style={{
            padding: '1rem 1.5rem',
            borderRadius: '8px',
            border: `1px solid #A8D6EF`,
            marginBottom: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: '#FEF6CF'
        }}>
            <div>
                <p
                    style={{
                        fontSize: '0.875rem',
                        margin: 0,
                        fontWeight: 600,
                        color: '#1F2126',
                        marginBottom: '10px',
                    }}
                >
                    Pending Approval
                </p>
                <p
                    style={{
                        fontSize: '0.775rem',
                        margin: 0,
                        color: '#353F50',
                        fontWeight: '400',
                        fontSize: '12px',
                        lineHeight: '16px',
                        color: '#5F738C',
                    }}
                >
                    Your upgrade is pending approval, you should get feedback within 24 - 48 hrs.
                </p>
                <Link
                    className=''
                    to={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-profile/upgrade-status`}
                >
                    <span style={{
                        fontSize: '0.775rem',
                        margin: 0,
                        color: '#AF5F26',
                        fontWeight: '600'
                    }}>View Details.</span>
                </Link>
            </div>
            <div>
                {BadgeIcon()}
            </div>

        </div>

    );
};

export const PendingAttestationBanner = () => {

    return (
        <div style={{
            padding: '1rem 1.5rem',
            borderRadius: '8px',
            border: `1px solid #A8D6EF`,
            marginBottom: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: '#FEF6CF'
        }}>
            <div>
                <p
                    style={{
                        fontSize: '0.875rem',
                        margin: 0,
                        fontWeight: 600,
                        color: '#1F2126',
                        marginBottom: '10px',
                    }}
                >
                    Pending Referee Attestation
                </p>
                <p
                    style={{
                        fontSize: '0.775rem',
                        margin: 0,
                        color: '#353F50',
                        fontWeight: '400',
                        fontSize: '12px',
                        lineHeight: '16px',
                        color: '#5F738C',
                    }}
                >
                    Your upgrade is pending with your referee, kindly follow up with your referee to speed up the process.                </p>
                <Link
                    className=''
                    to={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-profile/upgrade-status`}
                >
                    <span style={{
                        fontSize: '0.775rem',
                        margin: 0,
                        color: '#AF5F26',
                        fontWeight: '600'
                    }}>View Details.</span>
                </Link>
            </div>
            <div>
                {BadgeIcon()}
            </div>

        </div>

    );
};


export const PendingSubmissionBanner = () => {

    return (
        <div style={{
            padding: '1rem 1.5rem',
            borderRadius: '8px',
            border: `1px solid #A8D6EF`,
            marginBottom: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: '#FEF6CF',
        }}>
            <div>
                <p
                    style={{
                        fontSize: '0.875rem',
                        margin: 0,
                        fontWeight: 600,
                        color: '#1F2126',
                        marginBottom: '10px',
                    }}
                >
                    Update required
                </p>
                <p
                    style={{
                        fontSize: '0.775rem',
                        margin: 0,
                        color: '#353F50',
                        fontWeight: '400',
                        fontSize: '12px',
                        lineHeight: '16px',
                        color: '#5F738C',
                    }}
                >
                    Your referee has attested to your account, Kindly complete your application to submit.
                </p>
                <Link
                    className=''
                    to={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-profile/upgrade-status`}
                >
                    <span style={{
                        fontSize: '0.775rem',
                        margin: 0,
                        color: '#AF5F26',
                        fontWeight: '600'
                    }}>Complete Application.</span>
                </Link>
            </div>
            <div>
                {BadgeIcon()}
            </div>
        </div>

    );
};
