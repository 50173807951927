import { QUICKTELLER_NEXT_API_BASE_URL, QUICKTELLER_TERMINAL_ID } from '../../../utils/api-properties'
import Requester from '../finch-requester'

const API_BASE_URL = QUICKTELLER_NEXT_API_BASE_URL

export default class Quickteller {
  constructor (props) {
    this.apiRequester = props ? props.apiRequester : new Requester({
      apiBaseUrl: API_BASE_URL
    })
  }

  accountInquiry(accountNumber, bankCode, terminalId) {
    return this.apiRequester.get({
      endpoint: `services/inquiries/banks/${bankCode}/accounts`,
      headers: {
        accountNumber,
        terminalId
      }
    })
  }

  authenticate(username, password) {
    return this.apiRequester.post({
      endpoint: `security/authenticate`,
      body: {
        username,
      },
      headers: {
        secret: password
      }
    })
  }

  getOptions (serviceId, terminalId) {
    return this.apiRequester.get({
      endpoint: `services/${serviceId}`,
      args: {
        'withOptions': true,
      },
      headers: {
        terminalId,
      },
      cache: false,
    })
  }

  getServices (categoryId, terminalId) {
    return this.apiRequester.get({
      endpoint: `services/categories/${categoryId}`,
      args: {
        'withservices': true
      },
      headers: {
        terminalId: QUICKTELLER_TERMINAL_ID
      },
      cache: true,
    })
  }
  getBuyPowerServices (categoryId, terminalId) {
    return this.apiRequester.get({
      endpoint: `services/categories/${categoryId}`,
      args: {
        'withservices': true
      },
      headers: {
        terminalId: QUICKTELLER_TERMINAL_ID
      },
      cache: true,
    })
  }

  search (searchTerm) {
    return this.apiRequester.get({
      endpoint: `services/search`,
      args: {
        s: searchTerm,
        countryCode: 'NG'
      },
      headers: { 
        terminalId: QUICKTELLER_TERMINAL_ID
      }
    })
  }

  getTransferBanks () {
    return this.apiRequester.get({
      endpoint: `services/transfers/banks`,
      headers: {
        terminalId: QUICKTELLER_TERMINAL_ID
      }
    })
  }

}
