import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import { Col } from "react-bootstrap";
import Select from "react-select";
import H1 from "../../../../components/h1";
import HeaderSignup from "../../../../components/header-signup";
import PrimaryButton from "../../onboarding-setup/component/button";
import InputText from "../../onboarding-setup/component/input";
import { Link } from "react-router-dom";
import { isNumberValid } from "../../../../utils/validators/form-validators";
import { RegexChecker } from "../../../../utils/regex-checker";
import SelectInput from "../../onboarding-setup/component/select";
import FormLabel from "../../../../components/form-label";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../constants/api";
import Transaction from "../../../../services/api/resources/quickteller";
import DocumentUploader from "../../../home/scenes/dashboard/fragments/onboarding-application-setup/component/customeImage2";
import { TEN_MB } from "../../../../constants";
import Platform from "../../../../services/api/resources/platform";
import { notify } from "../../../../components/extras";
import Quickteller from "../../../../services/api/resources/quickteller";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Text from "../../../../components/text";
import { Error, ExpiredLinkScene, Loading } from "./extras";
import {
  isNameValid,
  isPhoneNumberValid,
} from "../../onboarding-setup/component/extras";

const MAX_BUSINESS_NAME_LENGTH = 50;
const headerStyle = {
  display: "flex",
  justifyContent: "flex-start",
  gap: "40px",
  margin: "20px",
};
const RefereeInformationScene = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [agentInformation, setAgentInformation] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [banks, setBanks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPassportCancel, setLoadingPassportCancel] = useState(false);
  const [loadingSignatureImgCancel, setLoadingSignatureImgCancel] =
    useState(false);
  const [isLoadingDoc, setIsLoadingDoc] = useState(false);
  const [isLoadingPassport, setIsLoadingPassport] = useState(false);
  const [isLoadingIdCard, setIsLoadingIdCard] = useState(false);
  const [fileFormatPassport, setFileFormatPassport] = useState(null);
  const [fileFormatIdCard, setFileFormatIdCard] = useState(null);

  const [showLightboxPassport, setShowLightboxPassport] = useState(false);
  const [showLightboxId, setShowLightboxId] = useState(false);
  const [passportPhoto, setPassportPhoto] = useState(null);
  const [signatureImg, setSignatureImg] = useState(null);
  const [uploadError, setUploadError] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoadingInformation, setIsLoadingInformation] = useState(false);

  const recentDate = new Date().toLocaleDateString();
  const [currentDate, setCurrentDate] = useState(recentDate);

  const [refereeInformationForm, setRefereeInformationForm] = useState({
    refereeName: "",
    businessAddress: "",
    phoneNumber: "",
    bank: { label: "", value: "" },
    accountNumber: "",
    duration: "",
    relationship: "",
    refPhoneNumber: "",
  });

  useEffect(() => {
    const updatedrefereeInformationForm = JSON.parse(
      localStorage.getItem("refereeInformationForm")
    );

    if (updatedrefereeInformationForm) {
      setRefereeInformationForm({
        refereeName: updatedrefereeInformationForm.refereeName || "",
        businessAddress: updatedrefereeInformationForm.businessAddress || "",
        phoneNumber: updatedrefereeInformationForm.phoneNumber || "",
        bank: updatedrefereeInformationForm.bank || "",
        accountNumber: updatedrefereeInformationForm.accountNumber || "",
        duration: updatedrefereeInformationForm.duration || "",
        relationship: updatedrefereeInformationForm.relationship || "",
        refPhoneNumber: updatedrefereeInformationForm.refPhoneNumber || "",
      });
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "refereeInformationForm",
      JSON.stringify(refereeInformationForm)
    );
  }, [refereeInformationForm]);

  const updateFormField = (params) => {
    if (params.state) {
      const newForm = {
        ...refereeInformationForm,
        localGovernmentArea: "",

        ...params,
      };
      setRefereeInformationForm(newForm);
    } else {
      const newForm = {
        ...refereeInformationForm,

        ...params,
      };
      setRefereeInformationForm(newForm);
    }
  };

  const bank = localStorage.getItem("bank");

  const stripSpacesFromString = (str) => {
    return str.replace(/ /g, "");
  };

  const isAccountNumberComplete = () => {
    return RegexChecker.accountNumber.test(
      refereeInformationForm?.accountNumber
    );
  };

  const customStylesBanks = {
    option: (provided, state) => ({
      ...provided,
      fontSize: 14,
    }),
    control: (base) => ({
      ...base,
      minHeight: 48,
      backgroundColor: "transparent",
    }),
    singleValue: (provided, state) => {
      const fontSize = 14;
      return { ...provided, fontSize };
    },
  };
  const quickteller = new Quickteller();

  const getBanks = async () => {
    setLoading(true);

    const { status, response } = await quickteller.getTransferBanks();
    setLoading(false);

    if (status === ERROR_STATUS) {
    } else if (status === SUCCESS_STATUS) {
      setBanks(response);
    }
  };

  const platform = new Platform();

  const fetchAgentInformation = async () => {
    setIsLoadingInformation(true);
    const { status, response } = await platform.getAgentInformationAttest(
      queryParams.get("req_id")
    );
    setIsLoadingInformation(false);

    if (status === ERROR_STATUS) {
      setErrorMessage(response);
    } else if (status === SUCCESS_STATUS) {
      setAgentInformation(response?.data);
    }
  };

  const fileInputRefPassportPhoto = useRef(null);
  const fileInputRefIdCard = useRef(null);

  const handleClickPassportPhoto = () => {
    fileInputRefPassportPhoto.current.click();
  };
  const handleClickIdCardPhoto = () => {
    fileInputRefIdCard.current.click();
  };

  const getDocumentTypePassport = (file) => {
    const srcSplitted = file?.name
      ? file?.name?.split(".")
      : file?.documentName?.split(".");

    const slittedType = file
      ? srcSplitted[srcSplitted.length - 1].split("-")[0]
      : null;
    setFileFormatPassport(slittedType);
  };

  const handleSignatureChange = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    if (file.size > TEN_MB) {
      setError(true);

      return;
    } else {
      setError(false);
      getDocumentTypePassport(file);
      reader.onload = (e) => {
        const imageDataUrl = e.target.result;
        setSignatureImg(imageDataUrl);

        localStorage.setItem("signatureImg", imageDataUrl);
      };
      reader.readAsDataURL(file);
      notify("Document uploaded", "success");
    }
  };

  const handleCancelSignatureImg = async () => {
    setLoadingSignatureImgCancel(true);
    // fetchApplicationDocument(false);
    notify("Document removed", "success");
    setSignatureImg(null);
    localStorage.removeItem("signatureImg");
    setLoadingSignatureImgCancel(false);
  };

  const handleCancelSignatureImg2 = async () => {
    setLoadingSignatureImgCancel(true);
    // fetchApplicationDocument(false);
    notify("Document removed", "success");
    setSignatureImg(null);
    localStorage.removeItem("signatureImage");
    setLoadingSignatureImgCancel(false);
  };

  const handlePassportChange = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    if (file.size > TEN_MB) {
      setError(true);

      return;
    } else {
      setError(false);
      getDocumentTypePassport(file);
      reader.onload = (e) => {
        const imageDataUrl = e.target.result;
        setPassportPhoto(imageDataUrl);

        localStorage.setItem("passportPhoto", imageDataUrl);
      };
      reader.readAsDataURL(file);
      notify("Document uploaded", "success");
    }
  };

  const handleCancelPassport = async () => {
    setLoadingPassportCancel(true);
    // fetchApplicationDocument(false);
    notify("Document removed", "success");
    setPassportPhoto(null);
    localStorage.removeItem("passportPhoto");
    setLoadingPassportCancel(false);
  };
  const handleCancelPassport2 = async () => {
    setLoadingPassportCancel(true);
    notify("Document removed", "success");
    setPassportPhoto(null);
    localStorage.removeItem("setpassportPhotoImage");
    setLoadingPassportCancel(false);
  };

  const isFormValid = () => {
    return (
      refereeInformationForm.refereeName &&
      refereeInformationForm.businessAddress &&
      refereeInformationForm.refPhoneNumber &&
      refereeInformationForm.bank.value &&
      refereeInformationForm.accountNumber &&
      refereeInformationForm.duration &&
      refereeInformationForm.relationship &&
      signatureImg &&
      passportPhoto &&
      isNameValid(refereeInformationForm.refereeName) &&
      isNumberValid(refereeInformationForm.refPhoneNumber) &&
      isPhoneNumberValid(refereeInformationForm.refPhoneNumber) &&
      isAccountNumberComplete(refereeInformationForm)
    );
  };

  const relationship = [
    "Aunt",
    "Brother",
    "Cousin",
    "Daughter",
    "Father",
    "Guardian",
    "Mother",
    "Nephew/Niece",
    "Sister",
    "Spouse",
    "Son",
    "Uncle",
  ];

  const duration = [
    "Less than 1 year",
    "1 year",
    "2 years",
    "3 years",
    "4 years",
    "5 years",
    "6 years",
    "7+ years",
  ];

  useEffect(() => {
    fetchAgentInformation();
  }, []);

  useEffect(() => {
    getBanks();
  }, []);

  useEffect(() => {
    const savedImageData = localStorage.getItem("signatureImg");

    if (savedImageData) {
      setSignatureImg(savedImageData);
    }

    const savedPassportImage = localStorage.getItem("passportPhoto");

    if (savedPassportImage) {
      setPassportPhoto(savedPassportImage);
    }
  }, []);

  if (isLoadingInformation) {
    return <Loading isLoadingInformation={isLoadingInformation} />;
  }
  if (errorMessage) {
    return <ExpiredLinkScene />;
  }
  const handleRefereeNameChange = (e) => {
    const inputValue = e.target.value;
    const lettersOnly = /^[A-Za-z\s]+$/;

    if (inputValue === "" || lettersOnly.test(inputValue)) {
      updateFormField({
        refereeName: inputValue,
      });
    }
  };

  return (
    <>
      <div style={headerStyle}>
        <HeaderSignup />
      </div>
      <main className="onboarding-container">
        <Col
          style={{
            margin: "0 auto",
          }}
        >
          <H1
            style={{
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "1rem",
            }}
          >
            Referee Attestation Form
          </H1>

          <p
            style={{
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "24px",
              color: "#353F50",
            }}
          >
            Hello, You have been added as a Referee. Please review the
            information below to confirm if you know the person and proceed.
          </p>
          <div
            style={{
              backgroundColor: "#E1E6ED",
              padding: "10px",
              marginBottom: "5px",
            }}
          >
            <p
              style={{
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "18px",
              }}
            >
              Agent Details
            </p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>Agent Name</p>
              <p>{agentInformation?.agentName}</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>Email Address</p>
              <p>{agentInformation?.agentEmail}</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>Phone Number</p>
              <p>{agentInformation?.agentPhoneNumber}</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>Business Address</p>
              <p>{agentInformation?.agentAddress}</p>
            </div>
          </div>

          <InputText
            label={"Referee Name"}
            onChange={handleRefereeNameChange}
            labelColor={
              !refereeInformationForm.refereeName ||
              isNameValid(refereeInformationForm.refereeName)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !refereeInformationForm.refereeName ||
              isNameValid(refereeInformationForm.refereeName)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            // disabled={true}
            placeholder={"Enter Referee Name"}
            value={refereeInformationForm.refereeName}
            maxLength={MAX_BUSINESS_NAME_LENGTH}
          />
          <InputText
            label={"Referee Home / Business Address"}
            onChange={(e) => {
              updateFormField({ businessAddress: e.target.value });
            }}
            placeholder={"Enter Referee Name"}
            value={refereeInformationForm.businessAddress}
            maxLength={MAX_BUSINESS_NAME_LENGTH}
          />
          <InputText
            type="number"
            label={"Referee Phone Number"}
            placeholder={"Enter phone number"}
            onChange={(e) => {
              updateFormField({
                refPhoneNumber: stripSpacesFromString(
                  e.target.value.slice(0, 11).replace(/\s/g, "")
                ),
              });
            }}
            labelColor={
              !refereeInformationForm.refPhoneNumber ||
              isPhoneNumberValid(refereeInformationForm.refPhoneNumber)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !refereeInformationForm.refPhoneNumber ||
              isPhoneNumberValid(refereeInformationForm.refPhoneNumber)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            value={refereeInformationForm.refPhoneNumber}
            maxLength={13}
          />
          <FormLabel
            style={{
              color: "#3A3B3B",
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              lineHeight: "19px",
              fontWeight: "600",
            }}
          >
            Referee Bank Name{" "}
          </FormLabel>
          {/* {!bank ? ( */}
          <Select
            options={banks?.map((bank) => {
              return { label: bank.name, value: bank.cbnCode };
            })}
            value={
              refereeInformationForm?.bank?.label && {
                label: refereeInformationForm?.bank.label,
              }
            }
            isLoading={loading}
            onChange={(event) => {
              const bank = event;
              localStorage.setItem("bank", event.label);
              updateFormField({ bank: bank });
            }}
            styles={customStylesBanks}
            className="select-input-container-banks"
            classNamePrefix="react-select"
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "rgb(178, 212, 255)",
              },
            })}
          />
          <div style={{ marginBottom: "1rem" }} />
          {/* ) : (
            <InputText
              disabled={true}
              value={bank}
              label={'Referee Bank Name'}
            />
          )} */}
          <InputText
            label={"Bank Account Number"}
            placeholder={"Enter Account Number"}
            onChange={(e) => {
              const inputValue = e.target.value;
              const numericValue = inputValue.replace(/\D/g, "");

              updateFormField({ accountNumber: numericValue });
            }}
            labelColor={
              !refereeInformationForm.accountNumber ||
              isAccountNumberComplete(refereeInformationForm)
                ? "#3A3B3B"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !refereeInformationForm.accountNumber ||
              isAccountNumberComplete(refereeInformationForm)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            maxLength={10}
            value={refereeInformationForm.accountNumber}
          />
          <SelectInput
            label={"I've know this person for"}
            onChange={(e) => {
              updateFormField({ duration: e.target.value });
            }}
            placeholder={"duration"}
            value={refereeInformationForm.duration}
            option={duration}
          />
          <div style={{ marginBottom: "1rem" }} />
          <SelectInput
            label={"State Relationship"}
            onChange={(e) => {
              updateFormField({ relationship: e.target.value });
            }}
            placeholder={"relationship"}
            value={refereeInformationForm.relationship}
            option={relationship}
          />
          <div style={{ marginBottom: "1rem" }} />
          {signatureImg ? (
            <DocumentUploader
              label={"Upload Signature"}
              loading={isLoadingDoc || isLoadingIdCard}
              loadingCancelDoc={loadingSignatureImgCancel}
              showLightbox={showLightboxId}
              fileFormat={fileFormatIdCard}
              state={signatureImg}
              setShowLightbox={setShowLightboxId}
              onChange={handleSignatureChange}
              handleDocument={handleClickIdCardPhoto}
              fileInputRef={fileInputRefIdCard}
              handleCancel={handleCancelSignatureImg2}
            />
          ) : (
            <DocumentUploader
              label={"Upload Signature"}
              loading={isLoadingDoc || isLoadingIdCard}
              loadingCancelDoc={loadingSignatureImgCancel}
              showLightbox={showLightboxId}
              fileFormat={fileFormatIdCard}
              state={
                signatureImg?.documentLink
                  ? signatureImg?.documentLink
                  : signatureImg
              }
              setShowLightbox={setShowLightboxId}
              onChange={handleSignatureChange}
              handleDocument={handleClickIdCardPhoto}
              fileInputRef={fileInputRefIdCard}
              handleCancel={handleCancelSignatureImg}
            />
          )}
          <div style={{ marginBottom: "1rem" }} />
          {passportPhoto ? (
            <DocumentUploader
              label={"Referee Passport Photograph"}
              loading={isLoadingDoc || isLoadingPassport}
              loadingCancelDoc={loadingPassportCancel}
              showLightbox={showLightboxPassport}
              fileFormat={fileFormatPassport}
              state={passportPhoto}
              setShowLightbox={setShowLightboxPassport}
              onChange={handlePassportChange}
              handleDocument={handleClickPassportPhoto}
              fileInputRef={fileInputRefPassportPhoto}
              handleCancel={handleCancelPassport2}
            />
          ) : (
            <DocumentUploader
              label={"Referee Passport Photograph"}
              loading={isLoadingDoc || isLoadingPassport}
              loadingCancelDoc={loadingPassportCancel}
              showLightbox={showLightboxPassport}
              fileFormat={fileFormatPassport}
              state={
                passportPhoto?.documentLink
                  ? passportPhoto?.documentLink
                  : passportPhoto
              }
              setShowLightbox={setShowLightboxPassport}
              onChange={handlePassportChange}
              handleDocument={handleClickPassportPhoto}
              fileInputRef={fileInputRefPassportPhoto}
              handleCancel={handleCancelPassport}
            />
          )}

          <br />
          {error && (
            <Col lg={12}>
              <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                File size is above 10MB
              </span>
            </Col>
          )}
          {uploadError && (
            <Col lg={12}>
              <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                Failed to Upload.Try again
              </span>
            </Col>
          )}
          <InputText
            disabled={true}
            value={agentInformation?.businessName}
            label={"Agent Business Name"}
          />
          <InputText
            disabled={true}
            value={agentInformation?.agentPhoneNumber}
            label={"Agent Phone Number"}
          />
          <InputText disabled={true} value={currentDate} label={"Date"} />
          <Link
            to={{
              pathname: "/referee-attestation-policy",
              state: {
                req_id: queryParams.get("req_id"),
                data: agentInformation,
              },
            }}
          >
            <PrimaryButton disabled={!isFormValid()}>
              {isLoading ? "Processing..." : "Proceed"}
            </PrimaryButton>
          </Link>
        </Col>
      </main>
    </>
  );
};

export default RefereeInformationScene;
