import React from 'react';
import ReactTable from 'react-table';
import MdMore from 'react-ionicons/lib/MdMore';

const transactionHistoryColumns = [{
  Header: '#',
  accessor: 'id',
  Cell: ({row}) => {
    return <div 
      className="selectable-table-row" 
      onClick={() => window.location.href = `/roles/${row.name}`}
    >
      {row.id}
    </div>
  },
  headerClassName: 'table-header',
  className: 'table-cell',
}, {
  Header: 'Name',
  accessor: 'name',
  Cell: ({row}) => {
    return <div 
      className="selectable-table-row" 
      onClick={() => window.location.href = `/roles/${row.name}`}
    >
      {row.name}
    </div>
  },
  headerClassName: 'table-header',
  className: 'table-cell',
}, {
  Header: 'Actions',
  accessor: 'actions',
  Cell: ({row}) => {
    return <div 
      style={{
        display: 'flex', 
        justifyContent: 'center'
      }}>
      <MdMore color={process.env.REACT_APP_GREY_COLOUR} />
    </div>
  },
  headerClassName: 'table-header',
  className: 'table-cell',
}];


export default props => {
  return <ReactTable
    columns={transactionHistoryColumns}
    data={props.data}
    pageSize={10}
    {...props}
    style={{
      border: 0
    }}
  />
}
