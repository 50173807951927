import React from "react";
import { Col, Form, FormLabel, InputGroup, Row } from "react-bootstrap";
import Button from "../components/button";
import { firetoastDynamic } from "../components/extras";
import FormControl from "../components/form-control";
import Thumbnail2 from "../components/thumbnail-2";
import {
  PASSPORT_PHOTO,
  PERSONAL_DOC_SUFFIX,
  PROOF_OF_ID,
  THREE_MB,
} from "../constants";
import { ERROR_STATUS } from "../constants/api";
import BaseScene from "../scenes/base-scene";
import CRMService from "../services/api/resources/crm-service";
import { convertNgkToNgn } from "../utils/converters/currencies";
import { formatAmount, formatDateTime3 } from "../utils/formatters";

export default class ComplaintForm extends BaseScene {
  constructor(props) {
    super(props);

    this.crmService = new CRMService();

    this.state = {
      fieldsFocused: [],
      form: {
        comments: "",
        commentLength: 0,
      },
      formIsValid: null,
      previouslySavedFields: {},
      states: [],
      superAgents: [],
      updatesHaveBeenMade: false,
      propagateFormErrors: false,
    };

    this.onFileSelect = this.onFileSelect.bind(this);
    this.onFileUnselect = this.onFileUnselect.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    this.retrieveTransactionDetails();
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        commentLength: 0,
      },
    }));
  }

  goBackToHome = () => {
    this.props.history.push("/agent/view-issues");
  };

  goBack = () => {
    this.props.history.push("/agent/dashboard");
  };

  retrieveTransactionDetails = () => {
    const transactionDetails = JSON.parse(localStorage.getItem("complaint"));
    transactionDetails.dateCreated = transactionDetails.dateCreated.replace(
      "T",
      " "
    );
    this.setState({ form: transactionDetails });
  };

  async onFileSelect(event, filename) {
    if (event.target.files && event.target.files.length > 0) {
      const name = event.target.files[0].name;
      const file = event.target.files[0];

      if (file.size > THREE_MB) {
        this.setState({ error: true });
        firetoastDynamic(
          "error",
          "Oops..",
          "File is more than 3MB, try again",
          "Okay",
          () => {
            this.setState({ error: false });
          }
        );
        this.setState({
          error: true,
        });
        return;
      } else {
        this.setState({
          isLoading: true,
        });
        this.displayUploadedFile(name, filename, file);
      }
    } else {
      console.error("No files selected.");
    }
  }

  retryComplaint = async () => {
    this.setState({
      error: false,
      uploadError: true,
      isLoading: true,
    });

    const uploadDoc = await this.crmService.submitComplaint(this.state.form);

    this.setState({
      isLoading: false,
    });
    if (uploadDoc.status === ERROR_STATUS) {
      this.setState({
        error: false,
        uploadError: true,
      });
      firetoastDynamic(
        "error",
        "Oops..",
        "Something went wrong, we couldn't log your request. Please try again",
        "Try Again",
        (e) => {
          if (e.isConfirmed) this.submitComplaint();
        }
      );
    } else {
      firetoastDynamic(
        "success",
        "Request Submitted",
        "Your request has been received. Please check under 'Issue History' to track your request status.",
        "Close",
        () => {
          this.goBackToHome();
        }
      );
    }
  };

  submitComplaint = async () => {
    this.setState({
      error: false,
      uploadError: true,
      isLoading: true,
    });

    const {
      transactionAmount,
      statusCode,
      dateCreated,
      transactionRef,
      transactionType,
      comments,
      attachedDocument,
      beneficiaryAccountNumber,
      mobileNo,
      rrn,
      maskedPan,
      paymentMethod,
      terminalId,
      agentPhoneNo,
      customerId,
      beneficiaryMobileNumber,
      customerMsisdn,
    } = this.state.form;

    const theTransactionType =
      transactionType === "CASH_OUT" && paymentMethod === "USSD"
        ? "USSD_CASHOUT"
        : transactionType === "NIP"
        ? "TRANSFER"
        : transactionType;
    const payload = {
      amount: convertNgkToNgn(transactionAmount),
      transactionRef: transactionRef,
      transactionType: theTransactionType,
      transactionStatus: statusCode,
      transactionDate: formatDateTime3(dateCreated),
      description: comments,
      attachment: attachedDocument,
      beneficiaryAccountNo: beneficiaryAccountNumber ?? null,
      agentPhoneNo: mobileNo ?? null,
      rrn: rrn ?? null,
      maskedPan: maskedPan ?? null,
      beneficiaryPhoneNo: beneficiaryMobileNumber
        ? beneficiaryMobileNumber
        : customerMsisdn
        ? customerMsisdn
        : mobileNo,
      terminalId: terminalId ?? null,
      customerId: customerId ?? null,
      customerPhoneNo: customerMsisdn ?? null,
    };

    const uploadDoc = await this.crmService.submitComplaint(payload);
    this.setState({
      isLoading: false,
    });

    if (uploadDoc.status === ERROR_STATUS) {
      this.setState({
        error: false,
        uploadError: true,
        isLoading: false,
      });

      if (uploadDoc?.response?.description === "Please update your email.") {
        firetoastDynamic(
          "error",
          "Oops..",
          "Please update your email to continue this operation",
          "Okay",
          (e) => {
            if (e.isConfirmed) this.goBack();
          }
        );
        return;
      }
      firetoastDynamic(
        "error",
        "Oops..",
        "Something went wrong, we couldn't log your request. Please try again",
        "Try Again",
        (e) => {
          if (e.isConfirmed) this.retryComplaint();
        }
      );
    } else {
      firetoastDynamic(
        "success",
        "Request Submitted",
        `Your request has been received,your case ID is ${uploadDoc?.response?.data}. Please check under 'Issue History' to track your request status.`,
        "Close",
        () => {
          this.goBackToHome();
        }
      );
    }
  };

  displayUploadedFile(name, filename, file) {
    const newAttachedDocument = {
      name: `${name}${PERSONAL_DOC_SUFFIX}`,
      filename: `${filename}${PERSONAL_DOC_SUFFIX}`,
      file: file,
      url: URL.createObjectURL(file),
    };

    this.setState({
      attachedDocument: newAttachedDocument,
      error: false,
      uploadError: false,
      isLoading: false,
    });

    this.updateFormField({ attachedDocument: newAttachedDocument });
  }

  onFileUnselect() {
    this.setState({
      attachedDocument: {},
    });

    this.updateFormField({ attachedDocument: {} });
  }

  propagateFormErrors() {
    this.setState({
      propagateFormErrors: true,
    });
  }

  updateFormField(params) {
    if (!this.state.updatesHaveBeenMade) {
      this.setState({
        updatesHaveBeenMade: !this.state.updatesHaveBeenMade,
      });
    }

    const newForm = {
      ...this.state.form,
      ...params,
    };

    this.setState({
      form: newForm,
      formIsValid: false,
    });
  }

  get documentsToAttach() {
    return [PROOF_OF_ID, PASSPORT_PHOTO];
  }

  documents = [{ filename: "evidence", documentType: "jpg", name: "evidence" }];

  getDocumentForThumbnail(attachedDocument) {
    return attachedDocument;
  }

  render() {
    const screenSensitiveStyle = !this.state.deviceIsMobile
      ? {
          padding: "25px 25px 10px 25px",
          backgroundColor: "white",
          borderRadius: "8px",
          margin: "20px",
          paddingTop: "10px",
          width: "80vw",
        }
      : {
          backgroundColor: "white",
        };

    const { comments, commentLength } = this.state.form;

    const getStatusDisplayValue = (statusCode) => {
      switch (statusCode) {
        case "PENDING_VALUE_PROVISIONING":
          return "PENDING";
        case "CLOSED_AND_SUCCESSFUL":
          return "SUCCESSFUL";
        case "CLOSED_BUT_FAILED":
          return "FAILED";
        default:
          return statusCode;
      }
    };

    return (
      <Form style={screenSensitiveStyle}>
        <div>
          <div
            style={{
              marginTop: 10,
              marginBottom: 10,
              fontWeight: "bold",
              fontSize: 18,
              paddingLeft: 7,
            }}
          >
            Make a Complaint
          </div>
          {/* for each item in this.state.transactionFields iterate and check if the items are not in the ignore section */}
          <Row>
            <Col xs={12} md={4} style={{ paddingBottom: "15px" }}>
              <Form.Group>
                <FormLabel className={`form-label-style`}>
                  Transaction Reference:
                </FormLabel>
                <InputGroup style={{ height: "10px", width: "100%" }}>
                  <FormControl
                    className="form-control-text-input"
                    value={this.state.form.transactionRef}
                    disabled
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={4} style={{ paddingBottom: "15px" }}>
              <Form.Group>
                <FormLabel className={`form-label-style`}>
                  Transaction Amount:
                </FormLabel>
                <InputGroup style={{ height: "10px", width: "100%" }}>
                  <FormControl
                    className="form-control-text-input"
                    value={`NGN${formatAmount(
                      convertNgkToNgn(this.state.form.transactionAmount)
                    )}`}
                    disabled
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={4} style={{ paddingBottom: "15px" }}>
              <Form.Group>
                <FormLabel className={`form-label-style`}>
                  Transaction Date:
                </FormLabel>
                <InputGroup style={{ height: "10px", width: "100%" }}>
                  <FormControl
                    className="form-control-text-input"
                    value={this.state.form.dateCreated}
                    disabled
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={4} style={{ paddingBottom: "15px" }}>
              <Form.Group>
                <FormLabel className={`form-label-style`}>
                  Transaction Type:
                </FormLabel>
                <InputGroup style={{ height: "10px", width: "100%" }}>
                  <FormControl
                    className="form-control-text-input"
                    value={this.state.form.transactionType}
                    disabled
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>

          {(this.state.form.transactionType === "TRANSFER" ||
            this.state.form.transactionType === "NIP") && (
            <>
              <Row>
                <Col xs={12} md={4} style={{ paddingBottom: "15px" }}>
                  <Form.Group>
                    <FormLabel className={`form-label-style`}>
                      Masked Pan:
                    </FormLabel>
                    <InputGroup style={{ height: "10px", width: "100%" }}>
                      <FormControl
                        className="form-control-text-input"
                        value={
                          this.state.form.maskedPan
                            ? this.state.form.maskedPan
                            : "N/A"
                        }
                        disabled
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={4} style={{ paddingBottom: "15px" }}>
                  <Form.Group>
                    <FormLabel className={`form-label-style`}>
                      Card RRN:
                    </FormLabel>
                    <InputGroup style={{ height: "10px", width: "100%" }}>
                      <FormControl
                        className="form-control-text-input"
                        value={
                          this.state.form.rrn ? this.state.form.rrn : "N/A"
                        }
                        disabled
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={4} style={{ paddingBottom: "15px" }}>
                  <Form.Group>
                    <FormLabel className={`form-label-style`}>
                      Transaction Status:
                    </FormLabel>
                    <InputGroup style={{ height: "10px", width: "100%" }}>
                      <FormControl
                        className="form-control-text-input"
                        value={getStatusDisplayValue(
                          this.state.form.statusCode
                        )}
                        disabled
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={4} style={{ paddingBottom: "15px" }}>
                  <Form.Group>
                    <FormLabel className={`form-label-style`}>
                      Beneficiary Account Number:
                    </FormLabel>
                    <InputGroup style={{ height: "10px", width: "100%" }}>
                      <FormControl
                        className="form-control-text-input"
                        value={this.state.form.beneficiaryAccountNumber}
                        disabled
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={4} style={{ paddingBottom: "15px" }}>
                  <Form.Group>
                    <FormLabel className={`form-label-style`}>
                      Agent Phone Number:
                    </FormLabel>
                    <InputGroup style={{ height: "10px", width: "100%" }}>
                      <FormControl
                        className="form-control-text-input"
                        value={
                          this.state.form.mobileNo
                            ? this.state.form.mobileNo
                            : "N/A"
                        }
                        disabled
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
            </>
          )}

          {this.state.form.transactionType === "CASH_OUT" &&
            this.state.form.paymentMethod === "USSD" && <></>}

          {this.state.form.transactionType === "CASH_OUT" &&
            this.state.form.paymentMethod !== "USSD" &&
            this.state.form.paymentMethod === "CARD" && (
              <>
                <Row>
                  <Col xs={12} md={4} style={{ paddingBottom: "15px" }}>
                    <Form.Group>
                      <FormLabel className={`form-label-style`}>
                        Transaction Status:
                      </FormLabel>
                      <InputGroup style={{ height: "10px", width: "100%" }}>
                        <FormControl
                          className="form-control-text-input"
                          value={getStatusDisplayValue(
                            this.state.form.statusCode
                          )}
                          disabled
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md={4} style={{ paddingBottom: "15px" }}>
                    <Form.Group>
                      <FormLabel className={`form-label-style`}>
                        Card RRN:
                      </FormLabel>
                      <InputGroup style={{ height: "10px", width: "100%" }}>
                        <FormControl
                          className="form-control-text-input"
                          value={
                            this.state.form.rrn ? this.state.form.rrn : "N/A"
                          }
                          disabled
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md={4} style={{ paddingBottom: "15px" }}>
                    <Form.Group>
                      <FormLabel className={`form-label-style`}>
                        Masked Pan:
                      </FormLabel>
                      <InputGroup style={{ height: "10px", width: "100%" }}>
                        <FormControl
                          className="form-control-text-input"
                          value={
                            this.state.form.maskedPan
                              ? this.state.form.maskedPan
                              : "N/A"
                          }
                          disabled
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md={4} style={{ paddingBottom: "15px" }}>
                    <Form.Group>
                      <FormLabel className={`form-label-style`}>
                        Agent Phone Number:
                      </FormLabel>
                      <InputGroup style={{ height: "10px", width: "100%" }}>
                        <FormControl
                          className="form-control-text-input"
                          value={this.state.form.mobileNo}
                          disabled
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}

          {this.state.form.transactionType === "PAYPOINT_FUND" &&
            this.state.form.paymentMethod === "USSD" && (
              <>
                <Row>
                  <Col xs={12} md={4} style={{ paddingBottom: "15px" }}>
                    <Form.Group>
                      <FormLabel className={`form-label-style`}>
                        Transaction Status:
                      </FormLabel>
                      <InputGroup style={{ height: "10px", width: "100%" }}>
                        <FormControl
                          className="form-control-text-input"
                          value={getStatusDisplayValue(
                            this.state.form.statusCode
                          )}
                          disabled
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={4} style={{ paddingBottom: "15px" }}>
                    <Form.Group>
                      <FormLabel className={`form-label-style`}>
                        Masked Pan:
                      </FormLabel>
                      <InputGroup style={{ height: "10px", width: "100%" }}>
                        <FormControl
                          className="form-control-text-input"
                          value={
                            this.state.form.maskedPan
                              ? this.state.form.maskedPan
                              : "N/A"
                          }
                          disabled
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}

          {this.state.form.transactionType !== "PAYPOINT_FUND" &&
            this.state.form.paymentMethod === "USSD" && (
              <>
                <Row>
                  <Col xs={12} md={4} style={{ paddingBottom: "15px" }}>
                    <Form.Group>
                      <FormLabel className={`form-label-style`}>
                        Transaction Status:
                      </FormLabel>
                      <InputGroup style={{ height: "10px", width: "100%" }}>
                        <FormControl
                          className="form-control-text-input"
                          value={getStatusDisplayValue(
                            this.state.form.statusCode
                          )}
                          disabled
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md={4} style={{ paddingBottom: "15px" }}>
                    <Form.Group>
                      <FormLabel className={`form-label-style`}>
                        Beneficiary Phone Number:
                      </FormLabel>
                      <InputGroup style={{ height: "10px", width: "100%" }}>
                        <FormControl
                          className="form-control-text-input"
                          value={
                            this.state.form.beneficiaryMobileNumber
                              ? this.state.form.beneficiaryMobileNumber
                              : "N/A"
                          }
                          disabled
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}

          {this.state.form.transactionType === "BILLS" && (
            <>
              <Row>
                <Col xs={12} md={4} style={{ paddingBottom: "15px" }}>
                  <Form.Group>
                    <FormLabel className={`form-label-style`}>
                      Transaction Status:
                    </FormLabel>
                    <InputGroup style={{ height: "10px", width: "100%" }}>
                      <FormControl
                        className="form-control-text-input"
                        value={getStatusDisplayValue(
                          this.state.form.statusCode
                        )}
                        disabled
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={4} style={{ paddingBottom: "15px" }}>
                  <Form.Group>
                    <FormLabel className={`form-label-style`}>
                      Masked Pan:
                    </FormLabel>
                    <InputGroup style={{ height: "10px", width: "100%" }}>
                      <FormControl
                        className="form-control-text-input"
                        value={
                          this.state.form.maskedPan
                            ? this.state.form.maskedPan
                            : "N/A"
                        }
                        disabled
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={4} style={{ paddingBottom: "15px" }}>
                  <Form.Group>
                    <FormLabel className={`form-label-style`}>
                      Card RRN:
                    </FormLabel>
                    <InputGroup style={{ height: "10px", width: "100%" }}>
                      <FormControl
                        className="form-control-text-input"
                        value={
                          this.state.form.rrn ? this.state.form.rrn : "N/A"
                        }
                        disabled
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={4} style={{ paddingBottom: "15px" }}>
                  <Form.Group>
                    <FormLabel className={`form-label-style`}>
                      Customer Phone Number:
                    </FormLabel>
                    <InputGroup style={{ height: "10px", width: "100%" }}>
                      <FormControl
                        className="form-control-text-input"
                        value={
                          this.state.form.customerMsisdn
                            ? this.state.form.customerMsisdn
                            : "N/A"
                        }
                        disabled
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
            </>
          )}
          {this.state.form.transactionType === "RECHARGE" && (
            <>
              <Row>
                <Col xs={12} md={4} style={{ paddingBottom: "15px" }}>
                  <Form.Group>
                    <FormLabel className={`form-label-style`}>
                      Transaction Status:
                    </FormLabel>
                    <InputGroup style={{ height: "10px", width: "100%" }}>
                      <FormControl
                        className="form-control-text-input"
                        value={getStatusDisplayValue(
                          this.state.form.statusCode
                        )}
                        disabled
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={4} style={{ paddingBottom: "15px" }}>
                  <Form.Group>
                    <FormLabel className={`form-label-style`}>
                      Masked Pan:
                    </FormLabel>
                    <InputGroup style={{ height: "10px", width: "100%" }}>
                      <FormControl
                        className="form-control-text-input"
                        value={
                          this.state.form.maskedPan
                            ? this.state.form.maskedPan
                            : "N/A"
                        }
                        disabled
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={4} style={{ paddingBottom: "15px" }}>
                  <Form.Group>
                    <FormLabel className={`form-label-style`}>
                      Card RRN:
                    </FormLabel>
                    <InputGroup style={{ height: "10px", width: "100%" }}>
                      <FormControl
                        className="form-control-text-input"
                        value={
                          this.state.form.rrn ? this.state.form.rrn : "N/A"
                        }
                        disabled
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={4} style={{ paddingBottom: "15px" }}>
                  <Form.Group>
                    <FormLabel className={`form-label-style`}>
                      Customer Phone Number:
                    </FormLabel>
                    <InputGroup style={{ height: "10px", width: "100%" }}>
                      <FormControl
                        className="form-control-text-input"
                        value={
                          this.state.form.customerMsisdn
                            ? this.state.form.customerMsisdn
                            : "N/A"
                        }
                        disabled
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
            </>
          )}

          <Row>
            <Col
              xs={12}
              md={4}
              style={{ paddingBottom: "15px", position: "relative" }}
            >
              <Form.Group>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <FormLabel className={`form-label-style`}>
                    Comments:
                    <span className="asterisk-style">*</span>
                  </FormLabel>
                  <div
                    style={{
                      marginLeft: "10px",
                      color:
                        this.state.form.commentLength > 75 ? "red" : "inherit",
                      fontSize: "14px",
                    }}
                  >
                    {this.state.form.commentLength}/75
                  </div>
                </div>
                <InputGroup style={{ height: "10px", width: "100%" }}>
                  <FormControl
                    className="form-control-text-input"
                    value={this.state.form.comments}
                    as="textarea"
                    placeholder="Add comments"
                    onChange={(event) => {
                      let comments = event.target.value;
                      if (comments.length > 75) {
                        comments = comments.slice(0, 75);
                      }

                      const commentLength = comments.length;
                      this.updateFormField({ comments, commentLength });
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={4} style={{ paddingBottom: "15px" }}>
              <Form.Group>
                <FormLabel className={`form-label-style`}>
                  Add Attachment:
                  <div style={{ marginBottom: 5, fontSize: 10 }}>
                    Upload file (image or document) PNG, JPEG, JPG, DOC, DOCX,
                    PDF up to 3MB
                  </div>
                </FormLabel>
                <InputGroup style={{ height: "10px", width: "100%" }}>
                  <Thumbnail2
                    style={{ marginBottom: "5px" }}
                    attachedDocument={this.getDocumentForThumbnail(
                      this.state.attachedDocument
                    )}
                    filename={"attachment"}
                    onFileSelect={this.onFileSelect}
                    onFileUnselect={this.onFileUnselect}
                    isLoading={this.state.isLoading}
                  />
                </InputGroup>
                <br />
                <br />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4} style={{ marginTop: "25px" }}>
              <Button
                style={{ width: "98%" }}
                disabled={
                  this.state.isLoading || !this.state.form.comments
                  // !this.state.attachedDocument?.file
                }
                onClick={(e) => {
                  e.preventDefault();
                  this.submitComplaint();
                  this.propagateFormErrors();
                  return;
                }}
              >
                {this.state.isLoading ? "Loading..." : "Submit"}
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    );
  }
}
