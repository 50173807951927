import { connect } from "react-redux";
import {
  updateLoading,
  dismissErrorMessage,
  showErrorMessage,
} from "../../../../services/redux/actions/tunnel";
import { showTransactionReportComponent } from "../../../../services/redux/actions/navigation";
import ReportScene from "./scene";

function mapStateToProps(state) {
  return {
    isLoading: state.tunnel.isLoading,
    isTransactionReportComponent: state.navigation.isTransactionReportComponent,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateLoading: (isLoading) => dispatch(updateLoading(isLoading)),
    dismissErrorMessage: () => dispatch(dismissErrorMessage()),
    showErrorMessage: (data) => dispatch(showErrorMessage(data)),
    showTransactionReportComponent: (data) =>
      dispatch(showTransactionReportComponent(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportScene);
