import axios from 'axios';
import { debounce } from 'lodash';
import Cookies from 'universal-cookie';
import {
  DELETE_METHOD,
  GET_METHOD,
  POST_METHOD,
  PUT_METHOD,
} from '../../constants/api';
import {
  isAuthTokenExpired,
  refreshAuthToken,
  retrieveAuthToken,
} from '../../utils/auth';

const CLIENT = 'CLIENT';
const SERVER = 'SERVER';

export default class Requester {
  cookies = new Cookies();

  constructor(props) {
    const { apiBaseUrl } = props;
    this.apiBaseUrl = apiBaseUrl;
  }

  _getErrorSource(error) {
    if (error.response) {
      if (error.response.status >= 400 && error.response.status <= 499) {
        return CLIENT;
      } else if (error.response.status >= 500 && error.response.status <= 599) {
        return SERVER;
      }
    }

    return null;
  }

  _getFullUrl(endpoint) {
    if (endpoint.length === 0) {
      return this.apiBaseUrl;
    }

    return `${this.apiBaseUrl}/${endpoint}`;
  }

  _handleErrorIgnore401(error) {
    if (this._handleNetworkError(error)) {
      return {
        code: '02',
        errorSource: SERVER,
        description: 'Network Time out',
      };
    }
    return error.response ? error.response.data : {};
  }

  _handleNetworkError(error) {
    const err = JSON.stringify(error);
    return err.includes('ERR_NETWORK');
  }

  _handleError(error) {
    if (this._handleNetworkError(error)) {
      return {
        code: '02',
        errorSource: SERVER,
        description: 'Network Time out',
      };
    }
    if (error.response && error.response.status === 401) {
      this._handle401Error();
    }

    return error.response ? error.response.data : {};
  }

  _handle401Error() {
    return;
  }

  _handleResponse(response) {
    return response.data.responseData || response.data;
  }

  async _makeHttpRequestWithoutToken(params) {
    let { url, method, headers, args, body } = params;
    return axios({
      url,
      method,
      headers,
      responseType: headers['download'] ? 'arraybuffer' : '',
      params: args,
      data: body,
      mode: 'no-cors',
      timeout: 120000,
    });
  }

  async _makeHttpRequest(params) {
    // check if auth token has expired, refresh token if necessary
    let { url, method, headers, args, body, auth } = params;
    const authToken = retrieveAuthToken();

    if (authToken && isAuthTokenExpired()) {
      debounce(async () => await refreshAuthToken(), 2000);
    }

    if (headers && !headers['Content-Type']) {
      headers['Content-Type'] = 'application/json';
    } else if (!headers) {
      headers = { 'Content-Type': 'application/json' };
    }

    if (authToken && !headers['Authorization']) {
      headers['Authorization'] = `Bearer ${authToken}`;
    }

    if (auth === false) {
      delete headers['Authorization'];
    }

    return axios({
      url,
      method,
      headers,
      responseType: headers['download'] ? 'arraybuffer' : '',
      params: args,
      data: body,
      mode: 'no-cors',
      timeout: 120000,
    });
  }

  async post(params) {
    const { endpoint, headers, body, args } = params;

    try {
      const response = await this._makeHttpRequest({
        url: this._getFullUrl(endpoint),
        method: POST_METHOD,
        headers,
        body: body,
        args: args,
      });

      let cleanResponse = this._handleResponse(response);
      if (!cleanResponse.code) {
        cleanResponse.code = response.data.code;
      }
      return {
        status: 'SUCCESS',
        response: cleanResponse,
        code: response.status,
      };
    } catch (error) {
      return {
        status: 'ERROR',
        response: this._handleError(error),
        code: error.response ? error.response.status : null,
        errorSource: this._getErrorSource(error),
      };
    }
  }

  async postIgnore401(params) {
    const { endpoint, headers, body, args } = params;

    try {
      const response = await this._makeHttpRequest({
        url: this._getFullUrl(endpoint),
        method: POST_METHOD,
        headers,
        body: body,
        args: args,
      });

      return {
        status: 'SUCCESS',
        response: this._handleResponse(response),
        code: response.status,
      };
    } catch (error) {
      return {
        status: 'ERROR',
        response: this._handleErrorIgnore401(error),
        code: error.response ? error.response.status : null,
        errorSource: this._getErrorSource(error),
      };
    }
  }

  async get(params) {
    const { endpoint, headers, args, auth, cache } = params;
    const url = this._getFullUrl(endpoint);

    if (cache) {
      const cachedData = await this._fetchDataFromCache(url, args);
      if (cachedData) {
        return cachedData;
      }
    }

    try {
      const response = await this._makeHttpRequest({
        url,
        method: GET_METHOD,
        headers: headers,
        args: args,
        body: null,
        auth,
      });

      const result = {
        status: 'SUCCESS',
        response: this._handleResponse(response),
        code: response.status,
      };

      if (cache) {
        await this._saveDataToCache(url, args, result);
      }

      return result;
    } catch (error) {
      return {
        status: 'ERROR',
        response: this._handleError(error),
        code: error.response ? error.response.status : null,
        errorSource: this._getErrorSource(error),
      };
    }
  }

  async getIgnore401(params) {
    const { endpoint, headers, args, auth, cache } = params;
    const url = this._getFullUrl(endpoint);

    if (cache) {
      const cachedData = await this._fetchDataFromCache(url, args);
      if (cachedData) {
        return cachedData;
      }
    }

    try {
      const response = await this._makeHttpRequest({
        url,
        method: GET_METHOD,
        headers: headers,
        args: args,
        body: null,
        auth,
      });

      const result = {
        status: 'SUCCESS',
        response: this._handleResponse(response),
        code: response.status,
      };

      if (cache) {
        await this._saveDataToCache(url, args, result);
      }

      return result;
    } catch (error) {
      return {
        status: 'ERROR',
        response: this._handleErrorIgnore401(error),
        code: error.response ? error.response.status : null,
        errorSource: this._getErrorSource(error),
      };
    }
  }

  async getWithoutToken(params) {
    const { endpoint, headers, args } = params;

    try {
      const response = await this._makeHttpRequestWithoutToken({
        url: this._getFullUrl(endpoint),
        method: GET_METHOD,
        headers: headers,
        args: args,
        body: null,
      });

      return {
        status: 'SUCCESS',
        response: this._handleResponse(response),
        code: response.status,
      };
    } catch (error) {
      return {
        status: 'ERROR',
        response: this._handleError(error),
        code: error.response ? error.response.status : null,
        errorSource: this._getErrorSource(error),
      };
    }
  }

  async put(params) {
    const { endpoint, headers, body, args, auth } = params;

    try {
      const response = await this._makeHttpRequest({
        url: this._getFullUrl(endpoint),
        method: PUT_METHOD,
        headers: headers,
        args: args,
        body: body,
        auth,
      });

      return {
        status: 'SUCCESS',
        response: this._handleResponse(response),
        code: response.status,
      };
    } catch (error) {
      return {
        status: 'ERROR',
        response: this._handleError(error),
        code: error.response ? error.response.status : null,
        errorSource: this._getErrorSource(error),
      };
    }
  }

  async delete(params) {
    const { endpoint, headers, body, args, auth } = params;

    try {
      const response = await this._makeHttpRequest({
        url: this._getFullUrl(endpoint),
        method: DELETE_METHOD,
        headers: headers,
        args: args,
        body: body,
        auth,
      });

      return {
        status: 'SUCCESS',
        response: this._handleResponse(response),
        code: response.status,
      };
    } catch (error) {
      return {
        status: 'ERROR',
        response: this._handleError(error),
        code: error.response ? error.response.status : null,
        errorSource: this._getErrorSource(error),
      };
    }
  }
}
