import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import "react-table/react-table.css";

import ActivityIndicator from '../../../../../components/activity-indicator';
import Spinner from '../../../../../components/spinner';
import Text from '../../../../../components/text';
import VerticallyCenteredModal from '../../../../../components/vertically-centered-modal';
import { SUCCESS_STATUS } from '../../../../../constants/api';
import { BILLER_BORNE } from '../../../../../constants/transactions';
import TransactionHistory from '../../../../../services/api/resources/transaction-history';
import { dismissErrorMessage, showErrorMessage, updateLoading } from '../../../../../services/redux/actions/tunnel';
import { convertNgkToNgn } from '../../../../../utils/converters/currencies';
import { formatAmount, formatDate, formatDateTime, handleCustomerIdFormat, handleCustomerIdFormatTwo, handleStatusFormat, handleTransactionTypeFormat, sentenceCapitalization } from '../../../../../utils/formatters';


class WalletJournalTable extends React.Component {

  transactionHistory = new TransactionHistory()

  constructor(props) {
    super(props)

    this.state = {
      showModal: false,
      showReceipt: false,
      transactionDetails: null,
      isLoading: false,
    }
    this.searchByTransRef = this.searchByTransRef.bind(this);
  }

  async searchByTransRef(transRef) {
    this.setState({
      isSearchTransactionLoading: true
    })
    const { status, code, response } = await this.transactionHistory.getTransactionsByRef(transRef);

    if (status === SUCCESS_STATUS) {
      this.setState({
        showModal: true,
        transactionDetails: response,
        isSearchTransactionLoading: false
      })
    } else {
      this.setState({
        isSearchTransactionLoading: false
      })
      this.props.showErrorMessage(JSON.stringify(response));
      setTimeout(() => {
        this.props.dismissErrorMessage()
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT))
    } 
  }
  
  render() {

    const handleClose = () => {
      this.setState({
        showModal: false,
        showReceipt: false,
      })
    }

    const transactionHistoryColumns = [
    {
      Header: props => <React.Fragment>Transaction Reference</React.Fragment>,
      accessor: 'uniqueReference',
      headerClassName: 'table-header',
      Cell: props => <div>{props.value}</div>,
      style: {'white-space': 'unset'},
      className: 'table-cell',
      width: 300,
    },
     {
      Header: props => <React.Fragment>Description</React.Fragment>,
      accessor: 'narration',
      headerClassName: 'table-header',
      className: 'table-cell',
      style: {'white-space': 'unset'},
      width: 300
    }, 
    {
      Header: props => <React.Fragment>Debited amount</React.Fragment>,
      accessor: 'debitAmount',
      headerClassName: 'table-header',
      Cell: props => <div className={`number ${props.value !== 0 && 'closed_but_failed-status'}`}>{`₦${formatAmount(convertNgkToNgn(props.value))}`}</div>,
      className: 'table-cell',
      style: {'white-space': 'unset'},
      width: 200
    }, 
    {
      Header: props => <React.Fragment>Credited amount</React.Fragment>,
      accessor: 'creditAmount',
      headerClassName: 'table-header',
      Cell: props => <div className={`number ${props.value !== 0 && 'closed_and_successful-status'}`}>{`₦${formatAmount(convertNgkToNgn(props.value))}`}</div>,
      className: 'table-cell',
      style: {'white-space': 'unset'},
      width: 200
    }, 
    {
      Header: props => <React.Fragment>Balance</React.Fragment>,
      accessor: 'balance',
      headerClassName: 'table-header',
      Cell: props => <div className='number'>{`₦${formatAmount(convertNgkToNgn(props.value))}`}</div>,
      className: 'table-cell',
      style: {'white-space': 'unset'},
      width: 200
    },
    {
      Header: props => <React.Fragment>Ledger Balance</React.Fragment>,
      accessor: 'remainingBalance',
      headerClassName: 'table-header',
      Cell: props => <div className='number'>{`₦${formatAmount(convertNgkToNgn(props.value))}`}</div>,
      className: 'table-cell',
      style: {'white-space': 'unset'},
      width: 200
    },
    {
      Header: props => <React.Fragment>Entry Date</React.Fragment>,
      accessor: 'journalEntryDate',
      headerClassName: 'table-header',
      Cell: props => <div className='number'>{props.value ? formatDateTime(props.value) : ''}</div>,
      className: 'table-cell',
      style: { 'white-space': 'unset' },
      width: 200
    },
    {
      Header: props => <React.Fragment>Last Updated on</React.Fragment>,
      accessor: 'lastUpdatedOn',
      headerClassName: 'table-header',
      Cell: props => <div className='number'>{props.value ? formatDateTime(props.value) : ''}</div>,
      className: 'table-cell',
      style: { 'white-space': 'unset' }
    },
    ];
    
    return (
      <>
        {this.state.showModal && <VerticallyCenteredModal
          onHide={handleClose}
          show={this.state.showModal}
          title='Transaction Details'
          body={<TransactionModalBody showFee={true} showReceiptLogo={false} searchByTransRef={this.searchByTransRef} details={this.state.transactionDetails}/>}
        />}
        {this.state.isSearchTransactionLoading 
          ? <ActivityIndicator />
          : <ReactTable
              columns={transactionHistoryColumns}
              data={this.props.data}
              pages={this.props.pages}
              defaultPageSize={50}
              manual
              onFetchData={(state, instance) => (
                this.props.getAllTransactions(state.page + 1, state.pageSize, this.props.selectedOption.value, this.props.sortDesc, this.props.startDate, this.props.endDate, this.props.domainCode)
              )}
              loading={this.props.loading}
              minRows={(this.props.data).length > 0 ? 4 : 3}
              LoadingComponent={Spinner}
              getTdProps={(state, rowInfo, column, instance) => ({
                style: {
                  overflow: 'visible',
                  lineHeight: '20px',
                  textAlign: 'left',
                },
              })}
              getTheadThProps={(state, rowInfo, column, instance) => ({
                style: {
                  textAlign: 'left'
                },
              })}
            />}
    </>
  )
  }
}

const TransactionModalBody = (props) => {
  return <>
    {(props.details.transactionType === 'TRANSFER' || props.details.transactionType === 'W2W') && (
      <div id='print-receipt'>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE, fontWeight: 'bold'}}>Transaction Amount: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE, fontWeight: 'bold'}}>{`NGN${formatAmount(convertNgkToNgn(props.details.transactionAmount))}`}</Text>
          </Col>
        </Row>

        {(props.showFee && props.details.borneBy !== BILLER_BORNE) && <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE, fontWeight: 'bold'}}>Fee: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE, fontWeight: 'bold'}}>{`NGN${formatAmount(convertNgkToNgn(props.details.fee))}`}</Text>
          </Col>
        </Row>}

        <hr/>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Beneficiary Name: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE, textTransform: 'capitalize'}}>{`${props.details.beneficiaryFirstName} ${props.details.beneficiaryOtherName ? props.details.beneficiaryOtherName : ''} ${props.details.beneficiaryLastName ? props.details.beneficiaryLastName : ''}` }</Text>
          </Col>
        </Row>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Beneficiary Number: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{handleCustomerIdFormat(props.details.beneficiaryMobileNumber)}</Text>
          </Col>
        </Row>

        <Row hidden={props.details.transactionType === 'W2W'} style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Bank: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{props.details.bankName}</Text>
          </Col>
        </Row>

        <Row hidden={props.details.transactionType === 'W2W'} style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Account Number: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{props.details.beneficiaryAccountNumber}</Text>
          </Col>
        </Row>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Sender Name: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE, textTransform: 'capitalize'}}>{`${props.details.customerFirstName} ${props.details.customerOtherName ? props.details.customerOtherName : ''} ${props.details.customerLastName ? props.details.customerLastName : ''}`}</Text>
          </Col>
        </Row>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Sender Number: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{handleCustomerIdFormat(props.details.customerMsisdn)}</Text>
          </Col>
        </Row>

        <hr/>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Transaction Type: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{props.details.transactionType}</Text>
          </Col>
        </Row>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Transaction Reference: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{props.details.transactionRef}</Text>
          </Col>
        </Row>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Transaction Date: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{formatDate(props.details.dateCreated)}</Text>
          </Col>
        </Row>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Transaction Status: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{handleStatusFormat(props.details.statusCode)}</Text>
          </Col>
        </Row>

        {(props.details.responseDescription && props.details.responseDescription !== 'Successful') && <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Status: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{(props.details.responseDescription)}</Text>
          </Col>
        </Row>}

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Description: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{props.details.narration}</Text>
          </Col>
        </Row>

        {props.details.remark && <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Remark: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{props.details.remark}</Text>
          </Col>
        </Row>}

        <hr/>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Agent Business Name: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{sentenceCapitalization(props.details.businessName)}</Text>
          </Col>
        </Row>
      </div>
    )}
    {(props.details.transactionType === 'BILLS' ||
        props.details.transactionType === 'CASH_IN' ||
        props.details.transactionType === 'RECHARGE' ||
        props.details.transactionType === 'CASH_OUT' ||
        props.details.transactionType === 'FUND' ||
        props.details.transactionType === 'PAYPOINT_FUND' || 
        props.details.transactionType === 'COMMISSION_UNLOAD' ||
        props.details.transactionType === 'REFUND' ||
        props.details.transactionType === 'RETRIEVAL'
      ) && (
      <div id='print-receipt'>
        
        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE, fontWeight: 'bold'}}>Transaction Amount: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE, fontWeight: 'bold'}}>{`NGN${formatAmount(convertNgkToNgn(props.details.transactionAmount))}`}</Text>
          </Col>
        </Row>

        {(props.showFee && props.details.borneBy !== BILLER_BORNE) && <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE, fontWeight: 'bold'}}>Fee: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE, fontWeight: 'bold'}}>{`NGN${formatAmount(convertNgkToNgn(props.details.fee))}`}</Text>
          </Col>
        </Row>}

        <hr/>

        <Row hidden={props.details.transactionType === 'RECHARGE' || props.details.transactionType === 'FUND' || props.details.transactionType === 'REVERSAL' || props.details.transactionType === 'REFUND'} style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Customer Name: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{`${props.details.customerFirstName} ${props.details.customerLastName}`}</Text>
          </Col>
        </Row>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Phone Number: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{handleCustomerIdFormat(props.details.customerMsisdn)}</Text>
          </Col>
        </Row>

        {(props.details.transactionType === 'BILLS' && props.details.customerAddress) && <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Address: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{props.details.customerAddress}</Text>
          </Col>
        </Row>}

        <Row hidden={props.details.transactionType === 'RECHARGE' || props.details.transactionType === 'FUND' || props.details.transactionType === 'PAYPOINT_FUND' || props.details.transactionType === 'REVERSAL'} style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Customer ID: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{handleCustomerIdFormatTwo(props.details.customerId)}</Text>
          </Col>
        </Row>

        <hr/>

        <Row hidden={props.details.transactionType === 'FUND' || !props.details.pin} style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Pin: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{props.details.pin}</Text>
          </Col>
        </Row>
        
        {props.details.transactionType === 'RECHARGE' ? <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Mobile Network: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{props.details.narration}</Text>
          </Col>
        </Row> : <Row hidden={props.details.transactionType === 'PAYPOINT_FUND'} style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{props.details.transactionType === 'REVERSAL' ? 'Description' : 'Biller Name'}: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{props.details.narration}</Text>
          </Col>
        </Row>}

        <hr hidden={props.details.transactionType === 'PAYPOINT_FUND' || props.details.transactionType === 'REVERSAL'}/>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Transaction Type: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{handleTransactionTypeFormat(props.details.transactionType)}</Text>
          </Col>
        </Row>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Transaction Reference: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{props.details.transactionRef}</Text>
          </Col>
        </Row>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Transaction Date: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{formatDate(props.details.dateCreated)}</Text>
          </Col>
        </Row>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Transaction Status: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{handleStatusFormat(props.details.statusCode)}</Text>
          </Col>
        </Row>

        {(props.details.responseDescription && props.details.responseDescription !== 'Successful') && <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Status: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{(props.details.responseDescription)}</Text>
          </Col>
        </Row>}

        <hr/>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Agent Business Name: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{sentenceCapitalization(props.details.businessName)}</Text>
          </Col>
        </Row>
      </div>
    )}

    {(props.details.transactionType === 'REVERSAL' 
      ) && (
      <div id='print-receipt'>
        
        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE, fontWeight: 'bold'}}>Transaction Amount: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE, fontWeight: 'bold'}}>{`NGN${formatAmount(convertNgkToNgn(props.details.transactionAmount))}`}</Text>
          </Col>
        </Row>

        {(props.showFee && props.details.borneBy !== BILLER_BORNE) && <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE, fontWeight: 'bold'}}>Fee: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE, fontWeight: 'bold'}}>{`NGN${formatAmount(convertNgkToNgn(props.details.fee))}`}</Text>
          </Col>
        </Row>}

        <hr/>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Phone Number: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{handleCustomerIdFormat(props.details.customerMsisdn)}</Text>
          </Col>
        </Row>

        <hr/>
        
        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{props.details.transactionType === 'REVERSAL' ? 'Description' : 'Biller Name'}: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{props.details.narration}</Text>
          </Col>
        </Row>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Transaction Type: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{handleTransactionTypeFormat(props.details.transactionType)}</Text>
          </Col>
        </Row>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Transaction Reference: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{props.details.transactionRef}</Text>
          </Col>
        </Row>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Transaction Date: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{formatDate(props.details.dateCreated)}</Text>
          </Col>
        </Row>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Transaction Status: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{handleStatusFormat(props.details.statusCode)}</Text>
          </Col>
        </Row>

        <hr/>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Agent Business Name: </Text>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{sentenceCapitalization(props.details.businessName)}</Text>
          </Col>
        </Row>

        <Row style={{marginTop: '15px'}}>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>Check original transaction details <Link onClick={() => props.searchByTransRef(props.details.narration.replace(/[^\d]/g, ''))}>{props.details.narration}</Link> </Text>
          </Col>
        </Row>
      </div>
    )}
  </>
}

function mapStateToProps(state) {
  return {
    isLoading: state.tunnel.isLoading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateLoading: isLoading => dispatch(updateLoading(isLoading)),
    dismissErrorMessage: () => dispatch(dismissErrorMessage()),
    showErrorMessage: data => dispatch(showErrorMessage(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WalletJournalTable)