import React, { useEffect, useState } from "react";

import "../app.css";
import H1 from "../../../../../../../components/h1";

import DistributeForm from "./distributeForm";
import Platform from "../../../../../../../services/api/resources/platform";
import Liquidity from "../../../../../../../services/api/resources/liquidity";
import { ERROR_STATUS } from "../../../../../../../constants/api";
import { convertNgnToNgk } from "../../../../../../../utils/converters/currencies";
import { refineDistributeData } from "../../../../../../../utils/formatters";
import {
  DISTRIBUTE_PAYMENT_ITEM_CODE,
  QUICKTELLER_TERMINAL_ID,
} from "../../../../../../../utils/api-properties";
import { firetoast } from "../../../../../../../components/extras";
import CompleteDistributeForm from "./completeDistribute";
import qs from "qs";
import ReceiptScreen from "./receiptPage";
import { Container } from "react-bootstrap";

const Spacer = ({ height }) => {
  return (
    <div
      style={{
        height: height,
      }}
    />
  );
};
const EntryPoint = () => {
  const platform = new Platform();
  const liquidity = new Liquidity();
  const [agent, setAgent] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [amount, setAmount] = useState("");
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeScreen, setActiveScreen] = useState(1);

  const deviceIsMobile =
    qs.parse(window.location.search)["?mobile"] || window.innerWidth <= 576;

  const getAgents = async () => {
    setIsLoading(true);
    //noPaging
    const { status, response } = await platform.searchAgents(
      null,
      null,
      null,
      true
    );
    setIsLoading(false);
    const agentSample = response.content;
    if (status === ERROR_STATUS) {
      return;
    } else {
      const transformAgent = (agentSample) => {
        const newAgentArray = agentSample?.map(
          ({ accountName: label, accountName: value, ...rest }) => ({
            label,
            value,
            ...rest,
          })
        );
        return newAgentArray;
      };
      const getAgentToFundDetails =
        JSON.parse(localStorage.getItem("agentDetailsFromPermanceBalance")) ||
        {};
      const agentToFund = transformAgent(agentSample).filter(
        (item) => item.agentCode === getAgentToFundDetails?.agentId
      );
      if (getAgentToFundDetails?.agentId) {
        const defaultValue = {
          label: agentToFund[0]?.label,
          value: agentToFund[0]?.value,
          beneficiaryAccountNo: agentToFund[0]?.accountNo,
          beneficiaryAgentCode: agentToFund[0]?.agentCode,
          beneficiaryEmail: agentToFund[0]?.businessEmail,
          beneficiaryFirstName: agentToFund[0]?.agentBankAccount.accountName,
          beneficiaryLastName: agentToFund[0]?.agentBankAccount.accountName,
          beneficiaryName: agentToFund[0]?.agentBankAccount.accountName,
          beneficiaryPhone: agentToFund[0]?.agentMobileNo,
          businessName: agentToFund[0]?.businessName,
        };

        setAgent(transformAgent(agentToFund));
        setSelectedAgent(defaultValue);
        localStorage.removeItem("agentDetailsFromPermanceBalance");
      } else {
        setSelectedAgent({
          label: "",
          value: "",
        });
        setAgent(transformAgent(agentSample));
      }
    }
  };
  useEffect(() => {
    getAgents();
  }, []);

  const createDistributeRequest = async () => {
    const { label, value, beneficiaryAccountNo, ...rest } = selectedAgent;
    const payload = [{ ...rest, amount: convertNgnToNgk(amount) }];

    const paymentItemCode = DISTRIBUTE_PAYMENT_ITEM_CODE;
    const deviceId = localStorage.getItem("deviceUid");
    const totalAmount =
      payload && refineDistributeData(payload).reduce((a, b) => a + b, 0);
    setLoading(true);
    const { status, response } = await liquidity.initiateDistribute(
      {
        narration: "Distribute",
        paymentItemCode: paymentItemCode,
        terminalId: QUICKTELLER_TERMINAL_ID,
        totalAmount: totalAmount,
        w2WRequestList: [...payload],
      },
      deviceId
    );
    setResponse(response);
    setLoading(false);
    if (status === ERROR_STATUS) {
      firetoast("error", response.description, "");
    } else {
      setAmount("");
      setSelectedAgent(null);
      setActiveScreen(2);
    }
  };

  const screenSensitiveStyle = deviceIsMobile
    ? {
        padding: 0,
        margin: 0,
        borderRadius: 0,
      }
    : {};

  return (
    <Container
      fluid
      style={{
        height: "100vh",
        overflowY: "auto",

        paddingTop: deviceIsMobile ? "20px" : "20px",
        paddingBottom: "90px",
        marginRight: "10px",
        ...screenSensitiveStyle,
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {activeScreen === 3 || (
            <H1
              style={{
                textAlign: "start",
              }}
            >
              Distribute
            </H1>
          )}
          {activeScreen === 2 && (
            <p
              style={{
                cursor: "pointer",
                color: process.env.REACT_APP_BLUE_COLOUR,
              }}
              onClick={() => setActiveScreen(1)}
            >
              Back
            </p>
          )}
        </div>
        <Spacer height={20} />
        {activeScreen === 1 && (
          <DistributeForm
            createDistributeRequest={createDistributeRequest}
            isLoading={isLoading}
            loading={loading}
            agent={agent}
            selectedAgent={selectedAgent}
            setSelectedAgent={setSelectedAgent}
            amount={amount}
            setAmount={setAmount}
          />
        )}
        {activeScreen === 2 && (
          <CompleteDistributeForm
            responseDetails={response}
            setResponse={setResponse}
            setActiveScreen={setActiveScreen}
          />
        )}
        {activeScreen === 3 && (
          <ReceiptScreen
            deviceIsMobile={deviceIsMobile}
            setScreen={setActiveScreen}
            response={response}
          />
        )}
      </div>
    </Container>
  );
};

export default EntryPoint;
