import React, { useEffect, useState } from "react";
import BusLogo from "../../../../../../icons/logo-bus.png";
import { arrowBackIcon } from "../../../../../../icons";
import { Button, Spinner } from "react-bootstrap";

import { DatePickerComponent } from "../../../../../../components/date-picker.js";
import Select from "react-select";
import moment from "moment";
import {
  ERROR_STATUS,
  SUCCESS_STATUS,
} from "../../../../../../constants/api.js";
import Quickteller from "../../../../../../services/api/resources/quickteller.js";
import { notify } from "../../../../../../components/extras.js";

export const FirstBookingScene = ({
  className,
  setScreen,
  style,
  setTrips,
  setDate,
  date,
  adultCount,
  setAdultCount,
  childCount,
  setChildCount,
  selectedFromTerminal,
  setSelectedToTerminal,
  setSelectedFromTerminal,
  selectedToTerminal,
  ...props
}) => {
  const quickteller = new Quickteller();
  const [terminals, setTerminals] = useState([]);
  const [isLoadingTerminals, setIsLoadingTerminals] = useState(false);
  const [isSearchTrips, setIsSearchTrips] = useState(false);

  const [tripType, setTripType] = useState("one-way");

  const isFormValid = () => {
    return (
      selectedFromTerminal &&
      selectedToTerminal &&
      selectedFromTerminal.value !== selectedToTerminal.value &&
      date &&
      adultCount >= 1 &&
      !isSearchTrips
    );
  };

  const fetchTerminal = async () => {
    setIsLoadingTerminals(true);
    const { status, response } = await quickteller.getTerminals();

    if (status === SUCCESS_STATUS) {
      setTerminals(response);
    }
    setIsLoadingTerminals(false);
  };

  useEffect(() => {
    fetchTerminal();
  }, []);

  const findTrips = async () => {
    const payload = {
      origin: selectedFromTerminal.value,
      destination: selectedToTerminal.value,
      departureDate: moment(date).format("YYYY-MM-DD"),
      returnDate: "",
      noOfAdults: adultCount,
      noOfChildren: childCount,
    };
    setIsSearchTrips(true);
    const { status, response } = await quickteller.getTrips(payload);

    setIsSearchTrips(false);
    if (status === SUCCESS_STATUS) {
      if (response.oneWay.content.length > 0) {
        setTrips(response);
        setScreen(4);
      } else {
        notify("No available trips for this route", "error");
      }
    } else {
      notify(response?.responseDescription, "error");
    }
  };

  const handleIncrement = (type) => {
    if (type === "adult") {
      setAdultCount(adultCount + 1);
    } else if (type === "child") {
      setChildCount(childCount + 1);
    }
  };

  const handleDecrement = (type) => {
    if (type === "adult" && adultCount > 1) {
      setAdultCount(adultCount - 1);
    } else if (type === "child" && childCount > 0) {
      setChildCount(childCount - 1);
    }
  };

  const customStylesBanks = {
    option: (provided, state) => ({
      ...provided,
      fontSize: 14,
    }),
    control: (base) => ({
      ...base,
      minHeight: 48,
      border: "none",
      outline: "none",
      backgroundColor: "#F3F5F6",
      boxShadow: "none",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: 14,
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),

    container: (provided) => ({
      ...provided,
      fontSize: "14px",
      "&:hover": {
        border: "none",
        cursor: "pointer",
        outline: "none",
      },
    }),
  };

  return (
    <div style={style}>
      <div className="logo-container">
        <img src={BusLogo} alt="Transport Icon" className="bus-logo" />
      </div>
      <div className="booking-container">
        <button
          className="back-button"
          onClick={() => {
            setSelectedFromTerminal(null);
            setSelectedToTerminal(null);
            setChildCount(0);
            setAdultCount(1);
            setScreen(2);
            setDate(null);
          }}
        >
          {arrowBackIcon()}{" "}
          <span style={{ marginLeft: "10px", color: "#282626" }}>Back</span>
        </button>

        <div className="booking-header">
          <h1 className="heading">Where are you going?</h1>
          <p className="subheading">Enter your trip details</p>
        </div>

        <div className="trip-type">
          <label>
            <input
              type="radio"
              name="trip"
              checked={tripType === "one-way"}
              onChange={() => setTripType("one-way")}
            />
            One-way Trip
          </label>
          {/* <label>
            <input
              type="radio"
              name="trip"
              disabled={true}
              checked={tripType === "return"}
              onChange={() => setTripType("return")}
            />
            Return Trip
          </label> */}
        </div>

        <div className="divider"></div>

        <div
          style={{
            display: "flex",
            gap: "16px",
            alignItems: "flex-end",
          }}
        >
          <div style={{ flex: 1 }}>
            <span style={{ fontSize: "14px", paddingBottom: "1px" }}>From</span>
            <Select
              options={terminals?.map((terminal) => ({
                label: terminal.name,
                value: terminal.code,
              }))}
              isLoading={isLoadingTerminals}
              isDisabled={isLoadingTerminals || isSearchTrips}
              styles={customStylesBanks}
              className="select-input-container-banks"
              classNamePrefix="react-select"
              value={selectedFromTerminal}
              onChange={setSelectedFromTerminal}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "rgb(178, 212, 255)",
                },
              })}
            />
          </div>
          <div style={{ flex: 1 }}>
            <span style={{ fontSize: "14px", paddingBottom: "1px" }}>To</span>
            <Select
              options={terminals?.map((terminal) => ({
                label: terminal.name,
                value: terminal.code,
              }))}
              isLoading={isLoadingTerminals}
              isDisabled={isLoadingTerminals || isSearchTrips}
              styles={customStylesBanks}
              className="select-input-container-banks"
              classNamePrefix="react-select"
              value={selectedToTerminal}
              onChange={setSelectedToTerminal}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "rgb(178, 212, 255)",
                },
              })}
            />
          </div>
          <div
            style={{
              flex: 1,
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span style={{ fontSize: "14px", paddingBottom: "1px" }}>
              Departure Date
            </span>
            <DatePickerComponent
              height={48}
              border="none"
              backgroundColor="#F3F5F6"
              onChange={(date) => setDate(date)}
              selected={date}
              size={"100%"}
              placeholder="Choose Date"
              format={`dd-MM-yyyy`}
              isClearable={true}
              showTimeInput={true}
              disabled={isSearchTrips}
              textStyle={"1.0rem"}
              iconStyle={"0.2rem"}
              dateType={"startDate"}
              preloadDate={false}
              minDate={new Date()}
            />
          </div>
        </div>

        <div className="divider"></div>

        <p
          style={{
            fontWeight: 600,
            marginBottom: "15px",
            color: "#072F40",
          }}
        >
          How many tickets do you need?
        </p>

        <div
          style={{
            display: "flex",
            gap: "16px",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <span
              style={{
                fontSize: "14px",
                color: "#072F40",
              }}
            >
              Adult passengers
            </span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <button
                disabled={isSearchTrips}
                onClick={() => handleDecrement("adult")}
                style={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  border: "1px solid #5F738C",
                  cursor: adultCount > 1 ? "pointer" : "not-allowed",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#f5f5f5",
                  opacity: adultCount > 1 ? 1 : 0.5,
                }}
              >
                -
              </button>
              <span style={{ color: "#353F50", font: "14px" }}>
                {adultCount}
              </span>
              <button
                disabled={isSearchTrips}
                onClick={() => handleIncrement("adult")}
                style={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  border: "1px solid #5F738C",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#f5f5f5",
                }}
              >
                +
              </button>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <span style={{ fontSize: "14px", color: "#072F40" }}>
                Children passengers
              </span>
              <span style={{ fontSize: "12px", color: "#666" }}>
                Between ages 2 - 10
              </span>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <button
                disabled={isSearchTrips}
                onClick={() => handleDecrement("child")}
                style={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  border: "1px solid #5F738C",
                  cursor: childCount > 0 ? "pointer" : "not-allowed",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#f5f5f5",
                  opacity: childCount > 0 ? 1 : 0.5,
                }}
              >
                -
              </button>
              <span style={{ color: "#353F50", font: "14px" }}>
                {childCount}
              </span>
              <button
                disabled={isSearchTrips}
                onClick={() => handleIncrement("child")}
                style={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  border: "1px solid #5F738C",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#f5f5f5",
                }}
              >
                +
              </button>
            </div>
          </div>
        </div>

        <div className="divider"></div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            style={{
              fontSize: "14px",
              borderRadius: "8px",
              width: "205px",
              height: "44px",
              border: "none",
              backgroundColor: isFormValid() ? "#0275D8" : "#A8D6EF",
              color: "white",
              cursor: isFormValid() ? "pointer" : "not-allowed",
            }}
            onClick={findTrips}
            disabled={!isFormValid()}
          >
            {isSearchTrips ? (
              <Spinner
                size="sm"
                animation="border"
                variant="light"
                role="status"
                style={{
                  width: "20px",
                  height: "20px",
                }}
              />
            ) : (
              "  Find Available Trips"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};
