import React, { useEffect, useState } from "react";
import MdArrowBack from "react-ionicons/lib/MdArrowBack";
import { useLocation } from "react-router-dom";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../constants/api";
import Stepper from "../../scenes/signup/onboarding-setup/component/stepper";
import Onboarding from "../../services/api/resources/onboarding";
import Platform from "../../services/api/resources/platform";
import CustomError from "./error";
import "./style.css";

const FaceVerificationSceneFmpa = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const platform = new Platform();
  const onboarding = new Onboarding();

  const bvn = queryParams.get("bvn");
  const token = queryParams.get("token");
  const jobId = queryParams.get("jobId");
  const kycId = queryParams.get("kycId");

  localStorage.setItem("auth_token", token);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "Your identity verification was unsuccessful."
  );

  const [isLoading, setIsLoading] = useState(false);

  const handleUpload = async (images) => {
    setIsLoading(true);

    const payload = {
      kycId: kycId,
      jobId: jobId,
      bvnNumber: bvn,
      imageRequest: { images: images },
    };

    const { status, response } = await onboarding.submitUploadImageCapturing(
      payload
    );

    setIsLoading(false);
    if (status === ERROR_STATUS) {
      setError(true);
      window["ReactNativeWebView"] &&
        window["ReactNativeWebView"].postMessage(JSON.stringify({ response }));
    } else if (status === SUCCESS_STATUS) {
      setIsLoading(true);
      window["ReactNativeWebView"] &&
        window["ReactNativeWebView"].postMessage(
          JSON.stringify({ key: "value" })
        );
    }
  };

  useEffect(() => {
    const app = document.querySelector("smart-camera-web");

    const postContent = async (data) => {
      await handleUpload(data.detail.images);
    };

    if (app) {
      app.addEventListener("imagesComputed", postContent);
    }

    return () => {
      if (app) {
        app.removeEventListener("imagesComputed", postContent);
      }
    };
  }, [error]);

  const isMobile = window.innerWidth <= 768;

  const headerBackButtonContainerStyle = {
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    alignItems: isMobile ? "center" : "flex-start",
    justifyContent: "flex-start",
    gap: isMobile ? "20px" : "40px",
    margin: isMobile ? "1rem 0" : "2rem 0 0 2rem",
  };

  const stepperContainerStyle = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    gap: "10px",
    margin: isMobile ? "0 10px 10px 10px" : "0 20px 10px 20px",
    justifyContent: isMobile ? "center" : "flex-start",
  };

  const instructionsStyle = {
    color: "#5F738C",
    fontWeight: "500",
    fontSize: "0.8rem",
    letterSpacing: "0.3px",
    padding: "0 20px",
    textAlign: "left",
  };

  const cameraContainerStyle = {
    width: isMobile ? "100%" : "auto",
    maxWidth: isMobile ? "350px" : "none",
    margin: "1rem auto",
  };

  const loadingOverlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backdropFilter: "blur(10px)",
  };

  const loadingSpinnerStyle = {
    border: "14px solid #f3f3f3",
    borderRadius: "50%",
    borderTop: "14px solid #00425f",
    width: "100px",
    height: "100px",
    animation: "spin 2s linear infinite",
  };

  return (
    <>
      {isLoading && (
        <div style={loadingOverlayStyle}>
          <div style={loadingSpinnerStyle}></div>
          <p
            style={{
              textAlign: "center",
              fontSize: "14px",
              color: "white",
              paddingTop: "10px",
              width: "20rem",
            }}
          >
            Please wait for about 3-5 minutes while we validate your details. Do
            not close your browser.
          </p>
        </div>
      )}

      <div
        style={{
          display: "flex",
          width: "100%",
          backgroundColor: "#00425F",
          padding: "28px 15px 15px",
          alignContent: "center",
        }}
      >
        <MdArrowBack
          color="#fff"
          fontSize="26px"
          onClick={(e) => {
            e.stopPropagation();
            window["ReactNativeWebView"] &&
              window["ReactNativeWebView"].postMessage(
                JSON.stringify({ key: "Back" })
              );
          }}
        />
      </div>

      <main
        className="onboarding-container"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "1rem",
        }}
      >
        <div
          style={{
            width: "100%",
            maxWidth: "500px",
            margin: "0 auto",
            alignContent: "center",
          }}
        >
          {error ? (
            <>
              <CustomError
                isLoading={false}
                errorMessage={errorMessage}
                onRetry={() => {
                  setError(false);
                }}
              />
            </>
          ) : (
            <>
              <div style={stepperContainerStyle}>
                <Stepper borderColor="#00425F" />
                <Stepper borderColor="#00425F" />
                <Stepper />
                <Stepper />
              </div>
              <h1
                style={{
                  fontWeight: "bold",
                  padding: "0 20px",
                  marginBottom: ".5rem",
                  marginTop: "3rem",
                  fontSize: "1.5rem",
                }}
              >
                Face ID Verification
              </h1>
              <p style={instructionsStyle}>
                Ensure your camera is steady and your face fits into the box
                below
              </p>
              <div style={cameraContainerStyle}>
                <smart-camera-web hide-attribution></smart-camera-web>
              </div>
            </>
          )}
        </div>
      </main>
    </>
  );
};

export default FaceVerificationSceneFmpa;
