import React from 'react';
import qs from 'qs';

const POSIMAGE = '/assets/media/images/pos-banner-image.png';
const BANNERDESIGN1 = '/assets/media/images/pos-banner-design1.png';
const BANNERDESIGN2 = '/assets/media/images/pos-banner-design2.png';
function DashboardBanner({ requestId }) {
  const deviceIsPC =
    !qs.parse(window.location.search)['?mobile'] && window.innerWidth >= 992;

  return (
    <section
      style={{
        display: 'flex',
        padding: '15px',
        backgroundColor: '#EBF8FE',
        borderRadius: '4px',
        marginBottom: '10px',
        position: 'relative',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: deviceIsPC ? '20rem' : '10rem',
        }}
      >
        {deviceIsPC && (
          <img
            style={{ position: 'absolute', top: '.7rem', left: '3px' }}
            src={BANNERDESIGN1}
            alt='banner designs'
          />
        )}
        <img
          style={{ position: 'absolute', top: '3rem' }}
          src={BANNERDESIGN2}
          alt='banner designs'
        />
        <img
          style={{
            zIndex: 2,
          }}
          src={POSIMAGE}
          alt='pos'
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
        className='flex-grow-1'
      >
        <h3
          style={{
            fontWeight: 600,
            fontSize: '1.5rem',
            color: '#072F40',
            margin: 0,
          }}
        >
          Received your POS?
        </h3>
        <p
          style={{
            fontSize: deviceIsPC ? '1.25rem' : '1rem',
            marginBottom: '15px',
            color: '#072F40',
            fontWeight: 400,
          }}
        >
          Enter your terminal ID to activate your POS
        </p>
        <span
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            color: '#00B8DE',
            fontSize: deviceIsPC ? '1.25rem' : '1rem',
            fontWeight: 400,
          }}
          className='text-animation-banner'
          onClick={() => {
            localStorage.setItem('requestId', requestId[0].requestId);
            window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/manage-devices/pos-management/pos-receipt-confirmation`;
          }}
        >
          Activate here
        </span>
      </div>
    </section>
  );
}

export default DashboardBanner;
