import React from "react";
import { RightIcon } from "../../../../../../icons/index.js";
import "./style.css";
import QtpTransport from "../../../../../../icons/bridge-landing.jpg";

export const LandingScene = ({ className, setScreen, style, ...props }) => {
  return (
    <div className="group-container" style={style}>
      <div
        style={{
          position: "relative",
          textAlign: "center",
          width: "100%",
          marginBottom: "20px",
        }}
      >
        <img
          src={QtpTransport}
          alt="Transport Icon"
          style={{
            width: "100%",
            height: "auto",
            display: "block",
            margin: "0 auto",
          }}
        />
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "white",
            fontSize: "38px",
            fontWeight: "bold",
            padding: "10px 20px",
            borderRadius: "5px",
          }}
        >
          TRANSPORT
        </div>
      </div>

      <div style={{ padding: "20px" }}>
        <h1 className="heading">
          What type of journey would you like to take today?
        </h1>
        <p className="subheading">Select a transportation type</p>

        <div className="transport-options">
          <div
            className="transport-card intra-state"
            onClick={() => setScreen(2)}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                setScreen(2);
              }
            }}
            tabIndex={0}
            role="button"
            aria-label="Select Inter State transportation"
          >
            <div className="card-content">
              <div className="card-text">
                <h3 className="card-title">Inter State</h3>
                <p className="card-description">
                  Book a trip between states. eg Lagos to Calabar
                </p>
              </div>
              <RightIcon />
            </div>
          </div>
          <div className="transport-card  inter-state-disabled">
            <div className="card-content">
              <div className="card-text">
                <h3 className="card-title">Intra State</h3>
                <p className="card-description">
                  Book a trip within your state of residence
                </p>
              </div>
              <RightIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
