import React, { useState } from "react";
import { BalanceCard } from "../../components/balanceCard";
import Spinner from "../../../../../../../components/spinner";
import { getPaginationRows } from "../../../../../../../utils/helpers";
import { AgentsRequestColumns, MyRequestColumns } from "../../components/data";
import ReactTable from "react-table";
import { Spacer } from "../../components/extras";
import { useHistory } from "react-router-dom";

const AgentsRequests = () => {
  const history = useHistory();
  const [getEarningBalance, setGetEarningBalance] = useState();
  const [selectedFilter, setSelectedFilter] = useState("Pending Requests");
  const [dateIntervalType, setDateIntervalType] = useState(2);
  const [inventories, setInventories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState(1);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [dataCount, setDataCount] = useState();
  // const transactionHistory = new TransactionHistory();
  const [loading, setLoading] = useState(true);

  const handleFilterClick = (filterName) => {
    setSelectedFilter(filterName);
    if (filterName === "Pending Requests") {
      setDateIntervalType(1);
    } else if (filterName === "Delivered Requests") {
      setDateIntervalType(2);
    } else {
      setDateIntervalType();
    }
  };
  const filterStyle = (filterName) => {
    return {
      cursor: "pointer",
      border:
        selectedFilter === filterName
          ? "1px solid #0275D8"
          : "1px solid #e5e7eb",
      backgroundColor: selectedFilter === filterName ? "#EBF8FE" : "white",
    };
  };

  const sampleData = [
    {
      requestId: "12137624",
      agentName: "John Doe",
      noOfPos: "7",
      amount: "5000",
      phoneNumber: "080366647784",
      status: "pending",
    },
  ];

  const AgentsRequestColumns = [
    {
      Header: (props) => <div style={{ textAlign: "left" }}>Request ID</div>,
      Cell: (props) => (
        <div
          className="wordbreak"
          style={{ textAlign: "left", wordBreak: "break-all" }}
        >
          {props.value}
        </div>
      ),
      accessor: "requestId",
      headerClassName: "table-header",
      className: "table-cell",
      style: { "white-space": "unset" },
      // width: 500,
    },

    {
      Header: (props) => <div style={{ textAlign: "left" }}>Agent Name</div>,
      accessor: "agentName",
      Cell: (props) => <div style={{ textAlign: "left" }}>{props.value}</div>,
      headerClassName: "table-header",
      className: "table-cell",
      //   width: 300,

      style: { "white-space": "unset" },
    },
    {
      Header: (props) => <div style={{ textAlign: "left" }}>No of POS</div>,
      accessor: "noOfPos",
      Cell: (props) => <div style={{ textAlign: "left" }}>{props.value}</div>,
      headerClassName: "table-header",
      className: "table-cell",
      //   width: 300,

      style: { "white-space": "unset" },
    },
    {
      Header: (props) => <div style={{ textAlign: "left" }}>Amount Paid</div>,
      accessor: "amount",
      Cell: (props) => <div style={{ textAlign: "left" }}>{props.value}</div>,
      headerClassName: "table-header",
      className: "table-cell",
      //   width: 300,

      style: { "white-space": "unset" },
    },
    {
      Header: (props) => <div style={{ textAlign: "left" }}>Phone Number</div>,
      accessor: "phoneNumber",
      Cell: (props) => <div style={{ textAlign: "left" }}>{props.value}</div>,
      headerClassName: "table-header",
      className: "table-cell",
      //   width: 300,

      style: { "white-space": "unset" },
    },
    {
      Header: (props) => (
        <div style={{ textAlign: "left" }}>Approval Status</div>
      ),
      accessor: "status",
      Cell: (props) => <div style={{ textAlign: "left" }}>{props.value}</div>,
      headerClassName: "table-header",
      className: "table-cell",
      //   width: 300,

      style: { "white-space": "unset" },
    },

    {
      Header: (props) => <div style={{ textAlign: "left" }}></div>,
      accessor: "",
      Cell: (props) => (
        <div
          onClick={() =>
            history.push({
              pathname:
                "/agent/pos-management/aggregator/pos-request/approve-pos",
              state: props.value,
            })
          }
          style={{
            textAlign: "left",
            cursor: "pointer",
            color: "#0275D8",
            fontWeight: 600,
          }}
        >
          Approved
        </div>
      ),
      headerClassName: "table-header",
      className: "table-cell",
      //   width: 300,

      style: { "white-space": "unset" },
    },
  ];
  return (
    <>
      <Spacer height={15} />
      <article className="agentType-container-card-commission-pos">
        <BalanceCard
          dynamicStyle={filterStyle("Pending Requests")}
          onClick={() => handleFilterClick("Pending Requests")}
          //   loading={loading}
          title={"Pending Requests"}
          balance={"21"}
        />
        <BalanceCard
          dynamicStyle={filterStyle("Delivered Requests")}
          onClick={() => handleFilterClick("Delivered Requests")}
          //   loading={isLoading}
          title={"Delivered Requests"}
          balance={"72"}
        />
      </article>
      <Spacer height={15} />
      <article>
        <ReactTable
          style={{
            margin: "20px",
            marginTop: "30px",
            backgroundColor: "white",
          }}
          columns={AgentsRequestColumns}
          data={sampleData}
          dataCount={dataCount}
          pageSizes={getPaginationRows(dataCount)}
          pages={pages}
          defaultPageSize={10}
          // onFetchData={(state, instance) => {
          //   getWithdrawalReport(state.pageSize, state.page + 1);
          // }}
          noDataText={isLoading ? "" : "No records found"}
          loading={isLoading}
          LoadingComponent={Spinner}
        />
      </article>
    </>
  );
};

export default AgentsRequests;
