import React from "react";
import MdArrowForward from "react-ionicons/lib/MdArrowForward";
import H1 from "../../../../../components/h1";
import "../styles.scss";

const COLOUR = "white";

export default (props) => {
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "0px",
        marginRight: "0px",
      }}
    >
      <H1 normal="true" leftAlign style={{ color: COLOUR }}>
        Services
      </H1>
      <div
        xs={6}
        md={3}
        className="grow"
        style={{
          alignItems: "center",
          color: COLOUR,
          cursor: props.disabled ? "not-allowed" : "pointer",
          display: "flex",
          fontSize: process.env.REACT_APP_MID_FONT_SIZE,
          textAlign: "right",
        }}
        onClick={() =>
          props.disabled ? null : props.navigateTo("/my-services")
        }
      >
        VIEW ALL
        <MdArrowForward color={COLOUR} className="view-more-arrow" />
      </div>
    </div>
  );
};
