import * as firebase from 'firebase/app';
import 'firebase/analytics';
import moment from 'moment';


const isAnalyticsEnabled = JSON.parse(process.env.REACT_APP_ENABLE_ANALYTICS);


export function logEvent(eventName, params={}) {
  isAnalyticsEnabled && firebase.analytics().logEvent(
    eventName, 
    {
      ...params,
      day: moment().format('Do'),
      hour: moment().format('h a'),
      week: moment().week(),
      weekDay: moment().format('dddd'),
      month: moment().format('MMMM'),
      year: moment().year()
    }
  );
}

export function setUserProperties(currentUser) {
  const trackedUserProperties = [
    'domainName', 'firstName', 'agentGender', 
    'lastName', 'roleName', 'agentAge', 
    'username'
  ];
  const userPropertiesToTrack = {}
  trackedUserProperties.map(
    key => userPropertiesToTrack[key] = currentUser[key]
  );

  isAnalyticsEnabled && firebase.analytics().setUserProperties(userPropertiesToTrack);
}
