import React, { useState, } from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";

const CbnLogo = "/assets/media/images/cbn.png";

const PopUpModal = ({ onClose }) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(true);
  const [showSecondModal, setShowSecondModal] = useState(false);

  const containerStyles = {
    // Styles for the outer container
    background: "#ebf8fe",
    borderRadius: "8px",
    border: "1px solid #a8d6ef",
    padding: "16px",
    display: "flex",
    gap: "16px",
    alignItems: "center",
    justifyContent: "flex-start",
    position: "relative",
    zIndex: 9999,
    width: "336px",
    transition: "opacity 0.5s ease-out",
    opacity: showModal ? 1 : 0,
  };

  const innerContainerStyles = {
    // Styles for the inner container
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flex: "1",
    position: "relative",
  };

  const textStyles = {
    enterYourNin: {
      color: "#353f50",
      textAlign: "left",
      fontFamily: '"Averta-Semibold", sans-serif',
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: "600",
    },
    yourAccountWillBeRestricted: {
      color: "#5f738c",
      textAlign: "left",
      fontFamily: '"Averta-Regular", sans-serif',
      fontSize: "12px",
      lineHeight: "16px",
      fontWeight: "400",
    },
    updateNow: {
      color: "#0275d8",
      textAlign: "left",
      fontFamily: '"Averta-Semibold", sans-serif',
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: "600",
      cursor: "pointer",
    },
  };

  const handleUpdateNowClick = () => {
    setShowSecondModal(true);
  };

  const handleCloseSecondModal = () => {
    setShowSecondModal(false);
    history.push("/agent/nin-information");
  };

  return (
    <div style={containerStyles}>
      <div style={innerContainerStyles}>
        <div style={textStyles.enterYourNin}>Enter your NIN</div>
        <div style={textStyles.yourAccountWillBeRestricted}>
          Your account will be restricted if you do not update your info by
          March 1st, 2024
        </div>
        <div style={textStyles.updateNow} onClick={handleUpdateNowClick}>
          Update Now
        </div>
        <Modal
          show={showSecondModal}
          onHide={handleCloseSecondModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body style={{ padding: "1rem", alignSelf: "center" }}>
            <div style={{ padding: "3rem", textAlign: "center" }}>
              <div style={{ marginBottom: "10px" }}>
                <img
                  alt="cbn-logo"
                  src={CbnLogo}
                  style={{ zIndex: 1 }}
                  width="40px"
                  height="53px"
                />
              </div>
              <h1
                style={{
                  fontWeight: "bold",
                  marginBottom: "1rem",
                  fontFamily: '"Averta-Regular", sans-serif',
                  fontSize: "1.5rem",
                }}
              >
                Account Update
              </h1>
              <p
                style={{
                  color: "grey",
                  fontWeight: "400",
                  fontFamily: '"Averta-Regular", sans-serif',
                  marginBottom: "1.5rem",
                }}
              >
                Your account will be restricted if you do not update your info
                by March 31st <br />
              </p>
              <button
                className="signupButton3"
                style={{
                  backgroundColor: "#00425F",
                  color: "#FFFFFF",
                  textAlign:'center'
                }}
                onClick={handleCloseSecondModal}
              >
                Update Now{" "}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default PopUpModal;
