import React from "react";
import BaseScene from "../../../../base-scene";
import { Col, Row } from "react-bootstrap";
import MdSync from "react-ionicons/lib/MdSync";
import Text from "../../../../../components/text";

export default class Skeleton extends BaseScene {
  render() {
    const { props, state } = this;

    const updateButton = () => {
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            fontSize: "12px",
          }}
        >
          <div
            className="dashboard-skeleton-block"
            normal="true"
            leftalign="true"
            onClick={props.onClick}
            style={{ backgroundColor: "white", height: "28px", width: "128px" }}
          ></div>

          <div
            className="dashboard-skeleton-block"
            xs={6}
            md={3}
            style={{ backgroundColor: "white", height: "20px", width: "100px" }}
          >
            {/* <b>{props.currentUser.firstName ? props.currentUser.firstName.toUpperCase() : ''} {props.currentUser.lastName ? props.currentUser.lastName.toUpperCase() : ''}</b> */}
          </div>
        </div>
      );
    };

    const walletBalances = () => {
      return (
        <Row
          style={{
            marginBottom: "0px",
            width: state.deviceIsMobile ? "90vw" : "78vw",
            marginTop: "10px",
          }}
        >
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              flex: "nowrap",
              justifyContent: "flex-start",
              overflowX: "auto",
              padding: "15px",
              paddingBottom: "30px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "200px",
                height: "130px",
                marginRight: "30pt",
              }}
            >
              <div
                className="dashboard-skeleton-block"
                style={{
                  backgroundColor: "white",
                  fontWeight: "bold",
                  fontSize: state.deviceIsMobile
                    ? "22px"
                    : process.env.REACT_APP_BIG_FONT_SIZE,
                  height: "42px",
                  padding: 0,
                  marginRight: "0px",
                  marginLeft: "0px",
                  width: "160px",
                }}
              >
                {/* ₦{Num(props.wallet.current_balance).format('0,0.00')}
              <p style={{fontSize: '10px', opacity: !props.wallet.wallet_date ? 0.01 : 1}}>{props.wallet.wallet_date ? `@ ${formatWalletDate(props.wallet.wallet_date)}` : '@'} </p> */}
              </div>
              <div
                className={`dashboard-skeleton-block grow ${
                  props.suggestFundWallet ? "heart-beat" : ""
                }`}
                style={{
                  alignItems: "center",
                  backgroundColor: "white",
                  color: props.disabled
                    ? process.env.REACT_APP_LIGHT_GREY_COLOUR
                    : process.env.REACT_APP_BLUE_COLOUR,
                  cursor: props.disabled ? "not-allowed" : "pointer",
                  display: "flex",
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  fontStyle: process.env.REACT_APP_MID_FONT_SIZE,
                  fontWeight: "bold",
                  height: "40px",
                  marginTop: "20px",
                  padding: "7.5px 10px 7.5px 10px",
                  width: "170px",
                }}
                aria-controls="fund-wallet-tab"
                // aria-expanded={props.activeTab === FUND_WALLET}
                // onClick={() => props.disabled ? null : window.open(FUND_WALLET_URL, '_blank')}
                // onClick={props.onFundWalletClick}>
              >
                {/* <MdAdd color={props.disabled ? process.env.REACT_APP_LIGHT_GREY_COLOUR : process.env.REACT_APP_BLUE_COLOUR} fontSize={process.env.REACT_APP_MID_FONT_SIZE} style={{marginRight: '5px'}} /> */}
                {/* FUND WALLET */}
              </div>
              <div
                className="dashboard-skeleton-block"
                style={{
                  backgroundColor: "white",
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  height: process.env.REACT_APP_MID_LINE_HEIGHT,
                  marginTop: "8px",
                  width: "160px",
                }}
              >
                {/* CURRENT BALANCE */}
              </div>
            </div>

            <div
              hidden={state.deviceIsMobile}
              style={{
                backgroundColor: "#46768B",
                left: "234px",
                position: "absolute",
                height: "90px",
                width: "1pt",
              }}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "200px",
                height: "130px",
                marginRight: "30pt",
              }}
            >
              <div
                className="dashboard-skeleton-block"
                style={{
                  backgroundColor: "white",
                  fontWeight: "bold",
                  fontSize: state.deviceIsMobile
                    ? "22px"
                    : process.env.REACT_APP_BIG_FONT_SIZE,
                  height: "42px",
                  padding: 0,
                  marginRight: "0px",
                  marginLeft: "0px",
                  width: "160px",
                }}
              >
                {/* ₦{Num(props.wallet.current_balance).format('0,0.00')}
              <p style={{fontSize: '10px', opacity: !props.wallet.wallet_date ? 0.01 : 1}}>{props.wallet.wallet_date ? `@ ${formatWalletDate(props.wallet.wallet_date)}` : '@'} </p> */}
              </div>
              <div
                className={`dashboard-skeleton-block grow ${
                  props.suggestFundWallet ? "heart-beat" : ""
                }`}
                style={{
                  alignItems: "center",
                  backgroundColor: "white",
                  color: props.disabled
                    ? process.env.REACT_APP_LIGHT_GREY_COLOUR
                    : process.env.REACT_APP_BLUE_COLOUR,
                  cursor: props.disabled ? "not-allowed" : "pointer",
                  display: "flex",
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  fontStyle: process.env.REACT_APP_MID_FONT_SIZE,
                  fontWeight: "bold",
                  height: "40px",
                  marginTop: "20px",
                  padding: "7.5px 10px 7.5px 10px",
                  width: "170px",
                }}
                aria-controls="fund-wallet-tab"
                // aria-expanded={props.activeTab === FUND_WALLET}
                // onClick={() => props.disabled ? null : window.open(FUND_WALLET_URL, '_blank')}
                // onClick={props.onFundWalletClick}>
              >
                {/* <MdArrowDropdownCircle color={props.disabled ? process.env.REACT_APP_LIGHT_GREY_COLOUR : process.env.REACT_APP_BLUE_COLOUR} fontSize={process.env.REACT_APP_MID_FONT_SIZE} style={{marginRight: '5px'}} />
              UNLOAD */}
              </div>
              <div
                className="dashboard-skeleton-block"
                style={{
                  backgroundColor: "white",
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  height: process.env.REACT_APP_MID_LINE_HEIGHT,
                  marginTop: "8px",
                  width: "160px",
                }}
              >
                {/* CURRENT BALANCE */}
              </div>
            </div>

            <div
              hidden={state.deviceIsMobile}
              style={{
                backgroundColor: "#46768B",
                left: "234px",
                position: "absolute",
                height: "90px",
                width: "1pt",
              }}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "200px",
                height: "130px",
                marginRight: "30pt",
              }}
            >
              <div
                className="dashboard-skeleton-block"
                style={{
                  backgroundColor: "white",
                  fontWeight: "bold",
                  fontSize: state.deviceIsMobile
                    ? "22px"
                    : process.env.REACT_APP_BIG_FONT_SIZE,
                  height: "42px",
                  padding: 0,
                  marginRight: "0px",
                  marginLeft: "0px",
                  width: "160px",
                }}
              >
                {/* ₦{Num(props.wallet.current_balance).format('0,0.00')}
              <p style={{fontSize: '10px', opacity: !props.wallet.wallet_date ? 0.01 : 1}}>{props.wallet.wallet_date ? `@ ${formatWalletDate(props.wallet.wallet_date)}` : '@'} </p> */}
              </div>
              <div
                className={`dashboard-skeleton-block grow ${
                  props.suggestFundWallet ? "heart-beat" : ""
                }`}
                style={{
                  alignItems: "center",
                  backgroundColor: "white",
                  color: props.disabled
                    ? process.env.REACT_APP_LIGHT_GREY_COLOUR
                    : process.env.REACT_APP_BLUE_COLOUR,
                  cursor: props.disabled ? "not-allowed" : "pointer",
                  display: "flex",
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  fontStyle: process.env.REACT_APP_MID_FONT_SIZE,
                  fontWeight: "bold",
                  height: "40px",
                  marginTop: "20px",
                  padding: "7.5px 10px 7.5px 10px",
                  width: "170px",
                }}
                aria-controls="fund-wallet-tab"
                // aria-expanded={props.activeTab === FUND_WALLET}
                // onClick={() => props.disabled ? null : window.open(FUND_WALLET_URL, '_blank')}
                // onClick={props.onFundWalletClick}>
              >
                {/* <MdArrowDropdownCircle color={props.disabled ? process.env.REACT_APP_LIGHT_GREY_COLOUR : process.env.REACT_APP_BLUE_COLOUR} fontSize={process.env.REACT_APP_MID_FONT_SIZE} style={{marginRight: '5px'}} />
              UNLOAD */}
              </div>
              <div
                className="dashboard-skeleton-block"
                style={{
                  backgroundColor: "white",
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  height: process.env.REACT_APP_MID_LINE_HEIGHT,
                  marginTop: "8px",
                  width: "160px",
                }}
              >
                {/* CURRENT BALANCE */}
              </div>
            </div>

            <div
              hidden={state.deviceIsMobile}
              style={{
                backgroundColor: "#46768B",
                left: "475px",
                position: "absolute",
                height: "90px",
                width: "1px",
              }}
            />
          </Col>
        </Row>
      );
    };

    const desktopServicesBlock = () => {
      return (
        <Row>
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              minHeight: "135pt",
              flex: "nowrap",
              justifyContent: "flex-start",
              overflowX: "auto",
              padding: "15px",
              paddingLeft: "15px",
              paddingTop: state.deviceIsMobile ? "15px" : "5px",
            }}
          >
            <div
              className="dashboard-skeleton-block-solid"
              style={{
                backgroundColor: "white",
                height: "140px",
                marginRight: "30pt",
                width: "200px",
              }}
            />
            <div
              className="dashboard-skeleton-block-solid"
              style={{
                backgroundColor: "white",
                height: "140px",
                marginRight: "30pt",
                width: "200px",
              }}
            />
            <div
              className="dashboard-skeleton-block-solid"
              style={{
                backgroundColor: "white",
                height: "140px",
                marginRight: "30pt",
                width: "200px",
              }}
            />
            <div
              className="dashboard-skeleton-block-solid"
              style={{
                backgroundColor: "white",
                height: "140px",
                marginRight: "30pt",
                width: "200px",
              }}
            />
          </Col>
        </Row>
      );
    };

    const mobileServicesBlock = () => {
      return (
        <Row style={{ margin: 0, padding: 0 }}>
          <Col xs={6} sm={6} style={{ margin: 0, padding: 10 }}>
            <div
              className="dashboard-skeleton-block-solid"
              style={{
                backgroundColor: "white",
                height: "90px",
                width: "100%",
              }}
            />
          </Col>
          <Col xs={6} sm={6} style={{ margin: 0, padding: 10 }}>
            <div
              className="dashboard-skeleton-block-solid"
              style={{
                backgroundColor: "white",
                height: "90px",
                width: "100%",
              }}
            />
          </Col>
          <Col xs={6} sm={6} style={{ margin: 0, padding: 10 }}>
            <div
              className="dashboard-skeleton-block-solid"
              style={{
                backgroundColor: "white",
                height: "90px",
                width: "100%",
              }}
            />
          </Col>
          <Col xs={6} sm={6} style={{ margin: 0, padding: 10 }}>
            <div
              className="dashboard-skeleton-block-solid"
              style={{
                backgroundColor: "white",
                height: "90px",
                width: "100%",
              }}
            />
          </Col>
        </Row>
      );
    };

    const servicesLabel = () => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            className="dashboard-skeleton-block-solid"
            style={{
              backgroundColor: "white",
              height: "20px",
              width: "90px",
            }}
          />
        </div>
      );
    };

    const errorBlock = () => (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: "8px",
          padding: "20px",
        }}
      >
        <Text blue bold center small>
          Oops!
        </Text>
        <Text big center>
          Something went wrong.
        </Text>
        <Text mid>Please try again.</Text>
        <div
          className={`grow ${props.suggestFundWallet ? "heart-beat" : ""}`}
          style={{
            alignItems: "center",
            backgroundColor: this.props.isLoading
              ? "white"
              : process.env.REACT_APP_BLUE_COLOUR,
            border: `1pt solid ${process.env.REACT_APP_BLUE_COLOUR}`,
            borderRadius: "4px",
            color: this.props.isLoading
              ? process.env.REACT_APP_BLUE_COLOUR
              : "white",
            cursor: props.disabled ? "not-allowed" : "pointer",
            display: "flex",
            fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            fontStyle: process.env.REACT_APP_MID_FONT_SIZE,
            fontWeight: "bold",
            height: "40px",
            justifyContent: "center",
            marginTop: "16px",
            // padding: '7.5px 10px 7.5px 10px',
            width: "160px",
          }}
          // onClick={() => props.disabled ? null : window.open(FUND_WALLET_URL, '_blank')}
          onClick={props.loadData}
        >
          <MdSync
            rotate={this.props.isLoading}
            color={
              this.props.isLoading ? process.env.REACT_APP_BLUE_COLOUR : "white"
            }
            fontSize={"24px"}
            style={{ marginRight: "10px" }}
          />
          {this.props.isLoading ? "Loading..." : "RETRY"}
        </div>
      </div>
    );

    return (
      <div
        className="dashboard-skeleton"
        style={{
          fontSize: "32px",
          height: "100vh",
          width: "100vw",
        }}
      >
        <div
          className="dashboard-page-header"
          style={{
            backgroundImage: "linear-gradient(120deg, #002636, #003646)",
            borderBottomLeftRadius: state.deviceIsMobile ? "50px" : "75px",
            height: "295px",
            position: "absolute",
            right: "0px",
            zIndex: "0",
            width: "83vw",
            ...props.style,
          }}
        />

        <div className="dashboard-page-inner-container">
          {updateButton()}
          {walletBalances()}
          {servicesLabel()}
          {state.deviceIsMobile
            ? mobileServicesBlock()
            : desktopServicesBlock()}
          {props.didErrorOccurWhileFetching && errorBlock()}
        </div>
      </div>
    );
  }
}
