import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import FormControl from "../../../../../components/form-control";
import H1 from "../../../../../components/h1";
import { CHARACTER_ATTESTATION, UTILITY_BILL } from "../../../../../constants";
import BaseScene from "../../../../base-scene";

import AttachmentComponent from "../../../../../components/thumbnail-6";

export default class KYCInformationForm extends BaseScene {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        idType: '',
        idNumber: '',
        idIssueDate: '',
        idExpiryDate: '',
        nin: ''
      },
      application: {}
    };
  }

  updateFormField() {}

  get documentsToAttach() {
    return [UTILITY_BILL, CHARACTER_ATTESTATION];
  }

  getDocumentUrl(documentName) {
    return `${process.env.REACT_APP_CDN_BASE_URL}/p/finch/onboarding/${documentName}`;
  }

  getDocumentForThumbnail(attachedDocuments, filename) {
    return attachedDocuments.find((value) => {
      const name = value.filename ? value.filename : value.documentType;
      return name.includes(filename);
    });
  }

  componentDidMount() {
    const application = JSON.parse(localStorage.getItem("currentAgent"));
    this.setState({ application }, () => {
      const { identificationType, identificationNumber, identificationIssueDate, identificationExpiryDate, nin } = this.state.application;
      this.setState({
        form: {
          idType: identificationType || '',
          idNumber: identificationNumber || '',
          idIssueDate: identificationIssueDate || '',
          idExpiryDate: identificationExpiryDate || '',
          nin: nin || ''
        }
      });
    });
  }
  

  render() {
    const { form, application } = this.state;

    return (
      <Form>
        <div>
          <H1
            ref={this.props.formHeaderRef}
            style={{
              color: "#3A3B3B",
              fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
              fontSize: "24px",
              fontWeight: "400",
              padding: "35px",
              paddingLeft: 0,
              textAlign: "left",
            }}
          >
            KYC Information
          </H1>

          <Row>
            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label
                  style={{
                    color: "#3A3B3B",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  ID Type:
                </Form.Label>
                <FormControl
                  type="text"
                  placeholder="Your ID type here"
                  onChange={(event) => {
                    const businessName = event.target.value;
                    this.updateFormField({ businessName });
                  }}
                  style={{
                    backgroundColor: "#E1E6ED",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.firstName || this.isFirstNameValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                  }}
                  value={form.idType}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label
                  style={{
                    color: "#3A3B3B",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  ID Number:
                </Form.Label>
                <FormControl
                  type="text"
                  placeholder="Your ID Number"
                  onChange={(event) => {
                    const businessAddress = event.target.value;
                    this.updateFormField({ businessAddress });
                  }}
                  style={{
                    backgroundColor: "#E1E6ED",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.lastName || this.isLastNameValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                  }}
                  value={form.idNumber}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label
                  style={{
                    color: "#3A3B3B",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  ID Issue Date:
                </Form.Label>
                <FormControl
                  type="text"
                  placeholder="Your ID Issue Date"
                  onChange={(event) => {
                    const companyRegNo = event.target.value;
                    this.updateFormField({ companyRegNo });
                  }}
                  style={{
                    backgroundColor: "#E1E6ED",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.firstName || this.isFirstNameValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                  }}
                  value={form.idIssueDate}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label
                  style={{
                    color: "#3A3B3B",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  ID Expiry Date:
                </Form.Label>
                <FormControl
                  type="text"
                  placeholder="Your ID Expiry Date"
                  onChange={(event) => {
                    const businessMobileNo = event.target.value;
                    this.updateFormField({ businessMobileNo });
                  }}
                  style={{
                    backgroundColor: "#E1E6ED",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.firstName || this.isFirstNameValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                  }}
                  value={form.idExpiryDate}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label
                  style={{
                    color: "#3A3B3B",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  NIN:
                </Form.Label>
                <FormControl
                  type="text"
                  placeholder="Your Nin"
                  onChange={(event) => {
                    const businessType = event.target.value;
                    this.updateFormField({ businessType });
                  }}
                  style={{
                    backgroundColor: "#E1E6ED",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.lastName || this.isLastNameValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                  }}
                  value={form.nin}
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>
        </div>

        <div>
          <H1
            ref={this.props.formHeaderRef}
            style={{
              color: "#3A3B3B",
              fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
              fontSize: "24px",
              fontWeight: "400",
              padding: "35px",
              paddingLeft: 0,
              textAlign: "left",
            }}
          >
            Attachments
          </H1>
          {application && application.documents &&
           (
              <Col
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "10px",
                  gap: "20px",
                }}
              >
                {application.documents.map(
                  (value) => {
                    let filename = value.documentType;
                    let link = value.documentLink;
                    let extention = value.documentExtention;

                    return (
                      <AttachmentComponent
                        key={value.documentId}
                        manageProfile
                        onFileSelect={this.onFileSelect}
                        profile={true}
                        documentExtention={extention}
                        documentLink={link}
                        documentName={filename}
                        application={application}
                      />
                    );
                  }
                )}
              </Col>
            )}
        </div>

        {/* <center style={{marginTop: '10px'}}>
        <Button onClick={() => {
          this.props.onSubmit()
        }}>Next</Button>
      </center> */}
      </Form>
    );
  }
}
