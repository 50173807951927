import React, { useState } from "react";

import qs from "qs";

import { connect } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { navigateTo } from "../../../services/redux/actions/navigation";
import { updateLoading } from "../../../services/redux/actions/tunnel";
import AgentTypeCard, {
  Active,
  LimitText,
  LimitTextStandard,
  LimitTextPrestige,
} from "./agentTypeCard";
import BackButton from "../onboarding-setup/component/backButton";
import HeaderSignup from "../../../components/header-signup";
import {
  BadgeIcon,
  BadgeStandardIcon,
  BadgePrestigeIcon,
  vectorImgOne,
} from "../../../icons";
import Modal from "react-bootstrap/Modal";

const ListOfUpgradeType = (props, setScreen) => {
  const agentDetails = JSON.parse(localStorage.getItem("currentAgent"));
  const deviceIsPC =
    !qs.parse(window.location.search)["?mobile"] && window.innerWidth >= 992;

  const [showModal, setShowModal] = useState(false);

  const basicButtonStyle = (agentClass) => {
    if (agentClass === "BASIC") {
      return {
        text: "Active",
        disabled: true,
        backgroundColor: "transparent",
        border: "1px solid #E1E6ED",
        color: "black",
      };
    } else if (agentClass === "STANDARD") {
      return {
        text: "remove button",
      };
    } else {
      return {
        text: "remove button",
      };
    }
  };
  const standardButtonStyle = (agentClass) => {
    if (agentClass === "STANDARD") {
      return {
        text: "You are on Standard",
        disabled: true,
        backgroundColor: "transparent",
        border: "1px solid #E1E6ED",
        color: "black",
        textColor: "#5F738C",
      };
    } else if (agentClass === "BASIC") {
      return {
        text: "Upgrade to Standard",
        disabled: false,
        backgroundColor: "transparent",
        border: "1px solid #E1E6ED",
        color: "white",
        textColor: "white",
      };
    } else {
      return {
        text: "remove button",
      };
    }
  };
  const prestigeButtonStyle = (agentClass) => {
    if (agentClass === "BASIC" || agentClass === "STANDARD") {
      return {
        text: "Coming Soon",
        disabled: false,
        backgroundColor: "transparent",
        border: "1px solid #E1E6ED",
        color: "white",
      };
    } else {
      return {
        text: "remove button",
      };
    }
  };

  const handleBasic = () => {};
  const handleStandard = () => {
    localStorage.setItem("upgradeTo", "Standard");
    props.navigateTo(`/manage-profile/upgrade-list/${agentDetails?.id}`);
  };
  const handlePrestige = () => {
    localStorage.setItem("upgradeTo", "prestige");
    congratsModal();
    // props.navigateTo(
    //   `/manage-profile/upgrade-list/${agentDetails?.id}`
    // );
  };

  const handlePrestigeIndividual = () => {
    localStorage.setItem("upgradeTo", "prestige");
    props.navigateTo(`/manage-profile/upgrade-list/${agentDetails?.id}`);
  };

  const handlePrestigeCorporate = () => {
    localStorage.setItem("upgradeTo", "prestige");
    props.navigateTo(
      `/manage-profile/upgrade-list/prestige/${agentDetails?.id}`
    );
  };

  const congratsModal = () => {
    setShowModal(true);
  };

  const BackgroundUpgrade1 = "/assets/media/images/upgrade-background.png";
  const BackgroundUpgrade2 = "/assets/media/images/upgrade-background2.png";
  return (
    <>
      <div className="aggregator-layout">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "40px",
          }}
        >
          <HeaderSignup />
          <BackButton onClick={() => props.navigateTo("/manage-profile")} />
        </div>
        <div>
          <div
            style={{
              fontFamily: "Helvetica",
              fontSize: "24px",
              fontWeight: "700",
              lineHeight: "36px",
              letterSpacing: "0px",
            }}
          >
            Upgrade Agent Account
          </div>
          <p
            style={{
              color: "#6B7280",
              fontSize: "14px",
              marginBottom: "3rem",
              fontWeight: "400",
              lineHeight: "20px",
            }}
          >
            You have a Tier 1 Agent Profile. Upgrade to do more with your
            account.
          </p>
        </div>

        <div className="agentType-container-card">
          <AgentTypeCard
            agentType={"Basic"}
            icon={BadgeIcon()}
            text={<LimitText />}
            active={agentDetails?.agentClass === "BASIC" ? <Active /> : ""}
            onClick={handleBasic}
            docType={[" "]}
            buttonStyle={basicButtonStyle(agentDetails?.agentClass)}
            backgroundImg={
              agentDetails?.agentClass === "BASIC"
                ? BackgroundUpgrade2
                : BackgroundUpgrade2
            }
          />
          <AgentTypeCard
            buttonStyle={standardButtonStyle(agentDetails?.agentClass)}
            onClick={handleStandard}
            agentType={"Standard"}
            icon={BadgeStandardIcon()}
            text={
              <LimitTextStandard
                style={standardButtonStyle(agentDetails?.agentClass)}
              />
            }
            active={agentDetails?.agentClass === "STANDARD" ? <Active /> : ""}
            // docType={['Utility Bill', 'Character Form']}
            buttonText={"Upgrade to Standard"}
            backgroundImg={
              agentDetails?.agentClass === "STANDARD"
                ? BackgroundUpgrade2
                : BackgroundUpgrade1
            }
          />
          <AgentTypeCard
            buttonStyle={prestigeButtonStyle(agentDetails?.agentClass)}
            // onClick={handlePrestige}
            // onClick={() => { console.log('Upgrade to Prestige') }}
            agentType={"Prestige"}
            icon={BadgePrestigeIcon()}
            text={<LimitTextPrestige />}
            active={agentDetails?.agentClass === "PRESTIGE" ? <Active /> : ""}
            docType={[
              "Utility Bill",
              "Character Form",
              "Address Verification Form",
            ]}
            buttonText={"Coming Soon"}
            backgroundImg={
              agentDetails?.agentClass === "PRESTIGE"
                ? BackgroundUpgrade2
                : BackgroundUpgrade1
            }
          />
        </div>
      </div>
      <>
        <Modal
          show={showModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body style={{ maxHeight: "200px" }}>
            <div style={{ alignContent: "center" }}>
              <h1
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                Select Account Type
              </h1>
              <p
                style={{
                  color: "grey",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
              >
                Please select account type to proceed
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  gap: "10px",
                  marginBottom: "10px",
                }}
              >
                {/* <Link to="/login"> */}
                <button
                  onClick={handlePrestigeIndividual}
                  className="upgradePrestigeBtn"
                  style={{
                    marginTop: "1rem",
                    textAlign: "center",
                    fontWeight: "600",
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                >
                  I am a Registered Business
                </button>
                {/* </Link> */}
                {/* <Link to="/login"> */}
                <button
                  onClick={handlePrestigeCorporate}
                  className="upgradePrestigeBtn"
                  style={{
                    marginTop: "1rem",
                    textAlign: "center",
                    fontWeight: "600",
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                >
                  I am an Unregistered Business
                </button>
                {/* </Link> */}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    </>
  );
};

function mapStateToProps(state) {
  return {
    isLoading: state.tunnel.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateLoading: (isLoading) => dispatch(updateLoading(isLoading)),
    navigateTo: (pendingUrl) => dispatch(navigateTo(pendingUrl)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ListOfUpgradeType);
