import React from "react";
import H1 from "../../../../../../components/h1";
import BalanceThreshold from "./components/balanceThreshold";
import TransactionTarget from "./components/transactionTarget";

const Settings = () => {
  return (
    <>
      <BalanceThreshold />
      <TransactionTarget />
    </>
  );
};

export default Settings;
