import React from 'react'
import Button from './button'

export default class TourCard extends React.Component {
  onSkipTourClick () {
    localStorage.setItem('skip_tour_guide', true)
  }

  render () {
    return <section style={{
      alignItems: 'center',
      justifyContent: 'space-evenly',
      backgroundColor: '#2EBBE8',
      borderRadius: '6px',
      color: 'white',
      display: 'flex',
      flexDirection: 'column',
      height: '220px',
      padding: '5px',
      width: '335px',
      elevation: '60deg',
      boxShadow: '0 1px 4px rgba(0,0,0,0.12), 0 1px 3px rgba(0,0,0,0.24)',
      ...this.props.style
    }}>
      <svg width="25px" height="25px" viewBox="0 0 25 25" style={{cursor: 'pointer', position: 'absolute', right: '10px', top: '10px'}} onClick={this.props.onCancelClick} version="1.1" xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
        <desc>Created with Lunacy</desc>
        <g id="cancel">
          {/* <path d="M21.3384 3.66162C18.9762 1.29945 15.8407 0 12.5 0C9.1593 0 6.02378 1.29945 3.66162 3.66162C1.29945 6.02378 0 9.1593 0 12.5C0 15.8407 1.29945 18.9762 3.66162 21.3384C6.02378 23.7005 9.1593 25 12.5 25C15.8407 25 18.9762 23.7005 21.3384 21.3384C23.7005 18.9762 25 15.8407 25 12.5C25 9.1593 23.7005 6.02378 21.3384 3.66162ZM20.3335 20.3335C18.2397 22.4274 15.4566 23.5795 12.5 23.5795C9.54335 23.5795 6.76031 22.4274 4.66646 20.3335C0.347222 16.0143 0.347222 8.98569 4.66646 4.66646C6.76031 2.5726 9.54335 1.42045 12.5 1.42045C15.4566 1.42045 18.2397 2.5726 20.3335 4.66646C24.6528 8.98569 24.6528 16.0143 20.3335 20.3335Z" id="Shape" fill="#FFFFFF" stroke="none" /> */}
          <path d="M11.7805 0.219436C11.4879 -0.0731455 11.0186 -0.0731455 10.726 0.219436L6 4.94491L1.27398 0.219436C0.981366 -0.0731455 0.512077 -0.0731455 0.219462 0.219436C-0.0731539 0.512018 -0.0731539 0.981254 0.219462 1.27384L4.94548 5.99931L0.219462 10.7248C-0.0731539 11.0174 -0.0731539 11.4866 0.219462 11.7792C0.363009 11.9227 0.556246 12 0.743961 12C0.931677 12 1.12491 11.9282 1.26846 11.7792L5.99448 7.05371L10.7205 11.7792C10.864 11.9227 11.0573 12 11.245 12C11.4382 12 11.6259 11.9282 11.7695 11.7792C12.0621 11.4866 12.0621 11.0174 11.7695 10.7248L7.05452 5.99931L11.7805 1.27384C12.0732 0.981254 12.0732 0.512018 11.7805 0.219436Z" transform="translate(8 7)" id="Path" fill="#FFFFFF" stroke="none" />
        </g>
      </svg>

      <div>
        {this.props.centerComponent}
      </div>
    
      <div style={{
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        {this.props.leftComponent || <Button outline style={{
          backgroundColor: 'transparent',
          borderWidth: '0px',
          color: 'white',
          fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
          fontWeight: 'normal',
          paddingLeft: '5px',
          paddingRight: '25px'
        }} onClick={this.props.onSkipTourClick}>
          SKIP TOUR
        </Button>}

        <Button 
          onClick={this.props.onNextClick}
          id="tour-guide-next-button"
        >
          {this.props.nextButtonText}
        </Button>
      </div>
    </section>
  }
}
