import React, { Component, useState } from "react";
import H1 from "../../../../../components/h1";
import BackButton from "../component/backButton";
import { Button, Form } from "react-bootstrap";
import PreviewAccordion from "../component/accordion";

import Text from "../../../../../components/text";
import Onboarding from "../../../../../services/api/resources/onboarding";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../../constants/api";
import CountriesStatesLga from "../../../../../fixtures/countries_states_lgas.json";
import MdColorWand from "react-ionicons/lib/MdColorWand";

import qs from "qs";

import { formatDateApplication } from "../component/extras";
import SkeletonLoading from "../component/skeletonLoading";
import Lightbox from "react-image-lightbox";

const imgStyle = {
  borderRadius: "5px",
  height: "9rem",
  border: "1px solid grey",
  width: "8rem",
  cursor: "pointer",
  objectFit: "cover",
};
const fontSize = {
  fontSize: "16px",
};

const PersonalDetails = ({
  setScreen,
  application,
  isLoading,
  documentsList,
}) => {
  const deviceIsPC =
    !qs.parse(window.location.search)["?mobile"] && window.innerWidth >= 992;

  return (
    <section
      style={{
        margin: "0 auto",
        width: deviceIsPC ? "60%" : "100%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div>
        <div style={{ marginBottom: "20px" }}>
          <Text style={{ color: "grey", fontSize: "16px" }}>Phone Number:</Text>
          <Text style={fontSize} bold mid>
            {isLoading ? "Loading..." : application?.phoneNumber}
          </Text>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <Text style={{ color: "grey", fontSize: "16px" }}>
            Email Address:
          </Text>
          <Text style={fontSize} bold mid>
            {isLoading ? "Loading..." : application?.emailAddress}
          </Text>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <Text style={{ color: "grey", fontSize: "16px" }}>Gender:</Text>
          <Text style={fontSize} bold mid>
            {isLoading ? "Loading..." : application?.gender}
          </Text>
        </div>
      </div>
      <div>
        <div style={{ marginBottom: "20px" }}>
          <Text style={{ color: "grey", fontSize: "16px" }}>BVN:</Text>
          <Text style={fontSize} bold mid>
            {isLoading ? "Loading..." : application?.bvn}
          </Text>
        </div>
        <div>
          <Text style={{ color: "grey", fontSize: "16px" }}>
            Date of Birth:
          </Text>
          <Text style={fontSize} bold mid>
            {isLoading ? "Loading..." : formatDateApplication(application?.dob)}
          </Text>
        </div>
      </div>
    </section>
  );
};
const KycInformation = ({
  setScreen,
  application,
  isLoading,
  documentsList,
}) => {
  const deviceIsPC =
    !qs.parse(window.location.search)["?mobile"] && window.innerWidth >= 992;
  const getDocumentUrl = (documentLink) => {
    let convertedDocument = documentLink.replace(
      "/data",
      process.env.REACT_APP_ONBOARDING_BASE_URL
    );
    return convertedDocument;
  };

  const [showLightbox, setShowLightbox] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const handleDocumentClick = (documentName) => {
    setSelectedDocument(documentName);
    setShowLightbox(true);
  };

  const convertFileName = (fileName) => {
    if (fileName === "UTILITY_BILL") {
      return "Utility Bill";
    } else if (fileName === "PASSPORT_PHOTO") {
      return "Passport Photograph";
    } else if (fileName === "ID_CARD") {
      return "Government Issued ID";
    }
  };
  return (
    <section
      style={{
        margin: "0 auto",
        width: deviceIsPC ? "60%" : "100%",
      }}
    >
      <div>
        <div style={{ marginBottom: "20px" }}>
          <Text
            mid
            style={{
              color: "grey",
              fontSize: "16px",
            }}
          >
            Means of Identification:
          </Text>
          <Text style={fontSize} bold mid>
            {isLoading ? "Loading..." : application?.identificationType}
          </Text>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <Text
            mid
            style={{
              color: "grey",
              fontSize: "16px",
            }}
          >
            ID number:
          </Text>
          <Text style={fontSize} bold mid>
            {isLoading ? "Loading..." : application?.identificationNumber}
          </Text>
        </div>
      </div>
      {isLoading ? (
        ""
      ) : (
        <div
          style={{
            display: "flex",
            gap: "10px",

            flexWrap: "wrap",
          }}
        >
          {documentsList?.map((item, index) => {
            const {
              documentType,

              documentExtention,
              documentLink,
              documentName,
            } = item;

            return (
              <>
                <div onClick={() => handleDocumentClick(documentLink)}>
                  <Form.Label
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    {convertFileName(documentType)}
                  </Form.Label>
                  <div style={imgStyle} key={index}>
                    {documentExtention === "png" ||
                    documentExtention === "jpeg" ||
                    documentExtention === "jpg" ||
                    documentExtention === "svg" ? (
                      <img
                        style={imgStyle}
                        src={getDocumentUrl(documentLink)}
                        title={convertFileName(documentType)}
                        alt={convertFileName(documentType)}
                      />
                    ) : (
                      <embed
                        style={imgStyle}
                        name="plugin"
                        src={getDocumentUrl(documentLink)}
                        title={convertFileName(documentType)}
                        alt={convertFileName(documentType)}
                      />
                    )}
                  </div>
                </div>
              </>
            );
          })}
        </div>
      )}
      {showLightbox && selectedDocument && (
        <>
          <Lightbox
            mainSrc={getDocumentUrl(selectedDocument)}
            onCloseRequest={() => setShowLightbox(false)}
          />
        </>
      )}
    </section>
  );
};
const BusinessDetails = ({ setScreen, application, isLoading, state, lga }) => {
  return (
    <section
      style={{
        margin: "0 auto",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div>
        {/* <div style={{ marginBottom: '10px' }}>
          <Text
            mid
            style={{
              color: 'grey',
            }}
          >
            Phone number:
          </Text>
          <Text bold mid>
            {isLoading ? 'Loading...' : application?.phoneNumber}
          </Text>
        </div> */}
        <div style={{ marginBottom: "20px" }}>
          <Text style={{ color: "grey", fontSize: "16px" }} mid>
            State:
          </Text>
          <Text style={fontSize} bold mid>
            {isLoading ? "Loading..." : state}
          </Text>
        </div>
        <div>
          <Text style={{ color: "grey", fontSize: "16px" }} mid>
            Business Name:
          </Text>
          <Text style={fontSize} bold mid>
            {isLoading ? "Loading..." : application?.businessName}
          </Text>
        </div>
      </div>
      <div>
        <div style={{ marginBottom: "20px" }}>
          <Text style={{ color: "grey", fontSize: "16px" }} mid>
            Business Address:
          </Text>
          <Text style={fontSize} bold color="grey" mid>
            {isLoading ? "Loading..." : application?.address}
          </Text>
        </div>

        <div>
          <Text style={{ color: "grey", fontSize: "16px" }} mid>
            Local Government Area:
          </Text>
          <Text style={fontSize} bold color="grey" mid>
            {isLoading ? "Loading..." : lga}
          </Text>
        </div>
      </div>
    </section>
  );
};
const NextOfKinDetails = ({ setScreen, application, isLoading }) => {
  return (
    <section
      style={{
        margin: "0 auto",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div>
        <div style={{ marginBottom: "20px" }}>
          <Text style={{ color: "grey", fontSize: "16px" }}>Name:</Text>
          <Text style={fontSize} bold>
            {isLoading ? "Loading..." : application?.firstName}
          </Text>
        </div>
        {/* <div style={{ marginBottom: '20px' }}>
          <Text style={{ color: 'grey', fontSize: '16px' }} mid>
            Middle Name:
          </Text>
          <Text style={fontSize} bold mid>
            {isLoading ? 'Loading...' : application?.middleName}
          </Text>
        </div> */}
        {/* <div style={{ marginBottom: '20px' }}>
          <Text style={{ color: 'grey', fontSize: '16px' }} mid>
            Surname
          </Text>
          <Text style={fontSize} bold mid>
            {isLoading ? 'Loading...' : application?.surname}
          </Text>
        </div> */}
        <div style={{ marginBottom: "20px" }}>
          <Text style={{ color: "grey", fontSize: "16px" }} mid>
            Home Address:
          </Text>
          <Text style={fontSize} bold mid>
            {isLoading ? "Loading..." : application?.address}
          </Text>
        </div>
        <div>
          <Text style={{ color: "grey", fontSize: "16px" }} mid>
            Gender:
          </Text>
          <Text style={fontSize} bold mid>
            {isLoading ? "Loading..." : application?.gender}
          </Text>
        </div>
      </div>
      <div>
        <div style={{ marginBottom: "20px" }}>
          <Text style={{ color: "grey", fontSize: "16px" }} mid>
            Phone Number:
          </Text>
          <Text style={fontSize} bold mid>
            {isLoading ? "Loading..." : application?.phoneNumber}
          </Text>
        </div>

        <div>
          <Text style={{ color: "grey", fontSize: "16px" }} mid>
            Relationship:
          </Text>
          <Text style={fontSize} bold mid>
            {isLoading ? "Loading..." : application?.relationship}
          </Text>
        </div>
      </div>
    </section>
  );
};

class ApplicationDetailsScene extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      loading: false,
      application: false,
      state: "",
      localGovernmentArea: "",
    };
    this.onboarding = new Onboarding();
  }

  componentDidMount() {
    this.fetchApplication();
  }

  fetchApplication = async () => {
    this.setState({ isLoading: true });
    const applicationId = localStorage.getItem("applicationId");
    const { status, response } = await this.onboarding.getApplicationAggregator(
      applicationId
    );

    this.setState({ isLoading: false });
    if (status === ERROR_STATUS) {
    } else if (status === SUCCESS_STATUS) {
      const stateId = parseInt(response.businessDetails.state);
      this.setState({ isLoading: false });

      let stateData = CountriesStatesLga[0].states.map((item) => {
        return {
          id: item.id,
          name: item.name,
        };
      });
      const getActualStateObj = stateData.find((item) => item.id === stateId);
      const lga = CountriesStatesLga[0].states.find(
        (state) => state.id === getActualStateObj?.id
      );
      const lgaId = parseInt(response.businessDetails.localGovernmentArea);
      this.setState({
        state: getActualStateObj?.name,
        application: response,
        localGovernmentArea: lga?.lgas?.find((item) => item.id === lgaId)?.name,
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  navigateToOverview = () => {
    this.props.navigateTo("/agents/applications");
  };

  handleEdit = () => {
    this.props.navigateTo("/agents/setup-new-agent");
  };

  render() {
    const { isLoading, application, state, localGovernmentArea } = this.state;
    const deviceIsPC =
      !qs.parse(window.location.search)["?mobile"] && window.innerWidth >= 992;

    const isDraft = application.applicationType === "DRAFT";
    const isRejected = application.applicationType === "REJECTED";
    const showButton = isDraft || isRejected;

    return (
      <div className="aggregator-layout">
        <BackButton onClick={this.navigateToOverview} />
        <main
          style={{
            margin: " 0 auto",
            width: deviceIsPC ? "70%" : "100%",
          }}
        >
          {application.approvalStatus === "REJECTED" && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "1rem",
                gap: "10px",
                padding: "0 15px",
              }}
            >
              <div
                style={{
                  color: "black",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                Decline Reason:
              </div>
              <div
                style={{
                  color: process.env.REACT_APP_RED_COLOUR,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  lineHeight: "19px",
                }}
              >
                {application.declineReason}
              </div>
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <div
              style={{
                textAlign: "left",
                gap: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {deviceIsPC && !isLoading ? (
                <div
                  style={{
                    width: "50px",
                    height: "50px",
                    fontWeight: 700,
                    display: "grid",
                    placeContent: "center",
                    borderRadius: "100%",
                    background: "#EBF8FE",
                    color: "#00425F",
                  }}
                >
                  {application?.applicantDetails?.firstName.charAt(0)}{" "}
                  {application?.applicantDetails?.surname.charAt(0)}
                </div>
              ) : (
                <SkeletonLoading width={"200px"} height={"15px"} />
              )}
              <H1
                style={{
                  fontSize: "1.5rem",
                }}
              >
                {application?.applicantDetails?.firstName}{" "}
                {application?.applicantDetails?.surname}
              </H1>
            </div>
            {isLoading ? (
              <SkeletonLoading width={"200px"} height={"15px"} />
            ) : (
              <div>
                {showButton && (
                  <Button
                    style={{
                      background: "white",

                      color: "#00425F",
                      padding: deviceIsPC ? "10px 35px" : "10px 20px",
                      cursor: "pointer",
                      fontSize: "16px",
                      display: "flex",
                      gap: "15px",
                      alignItems: "center",
                    }}
                    onClick={this.handleEdit}
                  >
                    <div>
                      <MdColorWand
                        fontSize="15px"
                        color={process.env.REACT_APP_GREY_COLOUR}
                      />
                    </div>
                    <div>Edit Profile</div>
                  </Button>
                )}
              </div>
            )}
          </div>
          <PreviewAccordion
            application={true}
            index="1"
            title={"Personal Details"}
          >
            <PersonalDetails
              application={application.applicantDetails}
              isLoading={isLoading}
              setScreen={this.props.setScreen}
              documentsList={application.documentsList}
            />
          </PreviewAccordion>
          {application?.documentsList?.length > 1 && (
            <PreviewAccordion
              application={true}
              index={"0"}
              title={"KYC Information"}
            >
              <KycInformation
                setScreen={this.props.setScreen}
                application={application.applicantDetails}
                documentsList={application.documentsList}
              />
            </PreviewAccordion>
          )}
          {application?.businessDetails?.phoneNumber && (
            <PreviewAccordion
              application={true}
              index={"0"}
              title={"Business Details"}
            >
              <BusinessDetails
                application={application.businessDetails}
                isLoading={isLoading}
                setScreen={this.props.setScreen}
                state={state}
                lga={localGovernmentArea}
              />
            </PreviewAccordion>
          )}
          {application?.nextOfKin?.phoneNumber && (
            <PreviewAccordion
              application={true}
              index={"0"}
              title={"Next of kin details"}
            >
              <NextOfKinDetails
                setScreen={this.props.setScreen}
                application={application.nextOfKin}
              />
            </PreviewAccordion>
          )}
        </main>
      </div>
    );
  }
}

export default ApplicationDetailsScene;
