import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import moment from 'moment';
import { Col, Container, Form, Row } from 'react-bootstrap';
import MdSync from 'react-ionicons/lib/MdSync';
import ReactTable from 'react-table';

import {
  auditTrailServiceClient,
  platformServiceClient,
  userManagementServiceClient,
} from '../../../../App';
import DateInputButton from '../../../../components/date-inputs/button';
import FormControl from '../../../../components/form-control';
import Spinner from '../../../../components/spinner';
import Text from '../../../../components/text';
import { CURRENT_AGENT, CURRENT_USER } from '../../../../constants';
import { ERROR_STATUS, HTTP_NOT_FOUND_CODE } from '../../../../constants/api';
import { AuditActions } from '../../../../fixtures/report_filter_options';
import { formatPhoneNumberWithCountryCode } from '../../../../utils/formatters';
import { loadData, saveData } from '../../../../utils/storage';
import BaseScene from '../../../base-scene';
import Layout from './layout';

// const defaultStartDate = moment().subtract(6, 'months').format('YYYY-MM-DD[ 00:00:00]');
// const defaultEndDate = moment().format('YYYY-MM-DD[ ]HH:mm:ss');
const TodaysDate = new Date();
const defaultStartDate = new Date(
  TodaysDate.getFullYear(),
  TodaysDate.getMonth() - 1
);
const defaultEndDate = TodaysDate;

const auditTrailColumns = [
  {
    Header: (props) => <React.Fragment>User ID</React.Fragment>,
    accessor: 'username',
    Cell: (props) => (
      <div style={{ textAlign: 'left' }}>
        {formatPhoneNumberWithCountryCode(props.value)}
      </div>
    ),
    headerClassName: 'table-header',
    className: 'table-cell',
  },
  {
    Header: (props) => <React.Fragment>Audit Action</React.Fragment>,
    accessor: 'auditAction',
    Cell: (props) => <div style={{ textAlign: 'left' }}>{props.value}</div>,
    headerClassName: 'table-header',
    className: 'table-cell',
  },
  // {
  //   Header: props => <React.Fragment>Audit Entity</React.Fragment>,
  //   accessor: 'auditEntity',
  //   Cell: props => <div style={{ textAlign: "left" }}>{props.value}</div>,
  //   headerClassName: 'table-header',
  //   className: 'table-cell',
  // },
  {
    Header: (props) => <React.Fragment>IP</React.Fragment>,
    accessor: 'ip',
    Cell: (props) => <div style={{ textAlign: 'left' }}>{props.value}</div>,
    headerClassName: 'table-header',
    className: 'table-cell',
  },
  {
    Header: (props) => <React.Fragment>Date</React.Fragment>,
    accessor: 'entryDate',
    Cell: (props) => (
      <div style={{ textAlign: 'left' }}>
        {moment(props.value).format('DD-MM-YYYY HH:mm:ss')}
      </div>
    ),
    headerClassName: 'table-header',
    className: 'table-cell',
  },
  // {
  //   Header: props => <React.Fragment>Action</React.Fragment>,
  //   accessor: 'entryDate',
  //   Cell: (props) => (
  //     <div
  //       className="selectable"
  //       style={{
  //         textDecoration: 'underline',
  //         textAlign: 'left'
  //       }}
  //     >
  //       <MdMore color={process.env.REACT_APP_GREY_COLOUR} />
  //     </div>
  //   ),
  //   headerClassName: 'table-header',
  //   className: 'table-cell',
  //   width: 80
  // }
];

const AuditTrailTable = (props) => {
  return (
    <ReactTable
      columns={auditTrailColumns}
      data={props.data}
      loading={props.loading}
      manual
      minRows={3}
      onFetchData={(state) =>
        props.deviceId === '0'
          ? props.getAuditTrail(state.pageSize, state.page + 1)
          : props.getAuditTrail(
              state.pageSize,
              state.page + 1,
              props.deviceId,
              props.actions
            )
      }
      // pages={props.pageCount}
      defaultPageSize={50}
      LoadingComponent={Spinner}
    />
  );
};

export default class ManageDevicesAuditTrailScene extends BaseScene {
  constructor(props) {
    super(props);

    this.state = {
      // auditTrail: props.auditTrail,
      endDate: defaultEndDate,
      isLoading: false,
      devices: [],
      pageNumber: 0,
      pageSize: parseInt(process.env.REACT_APP_PAGE_SIZE),
      user: JSON.parse(loadData(CURRENT_USER)),
      startDate: defaultStartDate,
      didErrorOccurWhileFetchingAllTransactions: false,
      deviceId: '0',
      actions: '0',
    };

    this.getAuditTrail = this.getAuditTrail.bind(this);
    this.showDeviceAuditTrail = this.showDeviceAuditTrail.bind(this);
    this.showActionsAuditTrail = this.showActionsAuditTrail.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  handleEmptyResponse = (code) => {
    this.setState({
      auditTrail: [],
      pages: 1,
      isLoading: false,
    });
    if (code === HTTP_NOT_FOUND_CODE) {
      this.setState({
        didErrorOccurWhileFetchingAllTransactions: false,
      });
    } else {
      this.setState({
        didErrorOccurWhileFetchingAllTransactions: true,
      });
    }
  };

  async loadDevices() {
    const { response, status } =
      await userManagementServiceClient.getDeviceList();
    if (status === ERROR_STATUS) {
      this.onErrorLoadingData();
      return;
    }

    this.setState({
      devices: response.data,
    });
  }

  async loadAgentData() {
    const { response, status } = await platformServiceClient.getCurrentAgent();
    if (status === ERROR_STATUS) {
      this.onErrorLoadingData();
      return;
    }

    const currentAgent = response;
    saveData(CURRENT_AGENT, JSON.stringify(currentAgent));

    this.setState({
      currentAgent: currentAgent,
    });
  }

  onErrorLoadingData() {
    return;
  }
  async showDeviceAuditTrail(deviceId) {
    this.setState({
      deviceId,
    });
    deviceId === '0'
      ? this.getAuditTrail(null, null)
      : this.getAuditTrail(null, null, deviceId, this.state.actions);
  }

  async showActionsAuditTrail(actions) {
    this.setState({
      actions,
    });
    actions === '0'
      ? this.getAuditTrail(null, null)
      : this.getAuditTrail(null, null, this.state.deviceId, actions);
  }

  async getAuditTrail(
    pageSize_,
    pageNumber_,
    deviceId_,
    actions_,
    startDate_,
    endDate_
  ) {
    this.setState({
      isLoading: true,
    });
    // const { devices, user } = this.props;
    // const { endDate, startDate } = this.state;
    const pageSize = pageSize_ || this.state.pageSize;
    const pageNumber = pageNumber_ || this.state.pageNumber;
    const deviceId = deviceId_ !== '0' ? deviceId_ : undefined;
    const auditAction = actions_ !== '0' ? actions_ : undefined;
    const startDate = startDate_ || this.state.startDate;
    const endDate = endDate_ || this.state.endDate;
    const businessName = this.state.user.domainName;

    const formattedPageNumber = pageNumber - 1;

    const formattedStartDate = moment(startDate).format('YYYY-MM-DD[ ]H:mm:ss');
    const formattedEndDate = moment(endDate).format('YYYY-MM-DD[ ]H:mm:ss');

    const { response, status, code } =
      await auditTrailServiceClient.searchAuditTrail(
        formattedStartDate,
        formattedPageNumber,
        pageSize,
        formattedEndDate,
        businessName,
        deviceId,
        auditAction
      );

    if (status === ERROR_STATUS) {
      this.setState({
        isLoading: false,
      });
      this.handleEmptyResponse(code);
      return;
    }

    this.setState({
      isLoading: false,
    });

    const auditTrail = response.data.lgs;
    auditTrail.map((value) => {
      const deviceData = this.state.devices.find(
        (device) => device.deviceid === value.deviceid
      );
      value.deviceName = deviceData ? deviceData.name : null;
      return value;
    });

    const pageCount = Math.ceil(response.data.count / pageSize);

    this.setState({
      auditTrail,
      pageCount,
      pageNumber,
      pageSize,
      isLoading: false,
      didErrorOccurWhileFetchingAllTransactions: false,
    });
  }

  setStartDate(value) {
    this.setState(() => ({
      startDate: value,
    }));
    this.getAuditTrail(null, null, this.state.deviceId, null, value);
  }

  setEndDate(value) {
    this.setState(() => ({ endDate: value }));
    this.getAuditTrail(null, null, this.state.deviceId, null, null, value);
  }

  renderRefreshTransactionMessage() {
    const { startDate, endDate, pageNum, pageSize, deviceId } = this.state;
    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'top',
          marginTop: '24px',
          paddingBottom: '30px',
        }}
      >
        <Text blue bold small>
          Oops!
        </Text>
        <Text mid>Something went wrong. Please, try again.</Text>
        <div
          style={{
            alignItems: 'center',
            border: `1pt solid ${process.env.REACT_APP_BLUE_COLOUR}`,
            borderRadius: '4px',
            color: process.env.REACT_APP_BLUE_COLOUR,
            cursor: 'pointer',
            display: 'flex',
            fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            fontStyle: process.env.REACT_APP_MID_FONT_SIZE,
            fontWeight: 'bold',
            height: '40px',
            justifyContent: 'center',
            marginTop: '16px',
            width: '160px',
          }}
          onClick={() =>
            this.getAuditTrail(pageSize, pageNum, deviceId, startDate, endDate)
          }
        >
          <MdSync
            rotate={this.state.isLoading}
            color={process.env.REACT_APP_BLUE_COLOUR}
            fontSize={'24px'}
            style={{ marginRight: '10px' }}
          />
          {this.state.isLoading ? 'Loading...' : 'RETRY'}
        </div>
      </div>
    );
  }
  async loadData() {
    await this.loadAgentData();
    await this.loadDevices();
    // await this.loadAuditTrail();

    this.setState({
      isLoading: false,
    });
  }
  render() {
    // const { devices } = this.props;

    const { auditTrail, devices, deviceIsPC, isLoading, pageCount, pageSize } =
      this.state;

    const screenSensitiveStyle = deviceIsPC
      ? {
          // padding: '10px'
        }
      : {
          // paddingTop: '20px'
        };

    const deviceSelect = (
      <Form.Group controlId='formBasicEmail'>
        <FormControl
          onChange={(event) => {
            const device = event.target.value;
            this.showDeviceAuditTrail(device);
          }}
          style={{
            backgroundColor: '#FFFFFF',
            borderRadius: '20px',
            border: '1px solid #DFE2E6',
            boxSizing: 'border-box',
            fontSize: '12px',
            lineHeight: '17px',
            width: '200px',
          }}
          as='select'
        >
          <option value={0}>Select Device</option>
          {devices.map(({ deviceid, deviceName }) => (
            <option value={deviceid}>{deviceName}</option>
          ))}
        </FormControl>
      </Form.Group>
    );

    const actionSelect = (
      <Form.Group controlId='formBasicEmail'>
        <FormControl
          onChange={(event) => {
            const action = event.target.value;
            this.showActionsAuditTrail(action);
          }}
          style={{
            backgroundColor: '#FFFFFF',
            borderRadius: '20px',
            border: '1px solid #DFE2E6',
            boxSizing: 'border-box',
            fontSize: '12px',
            lineHeight: '17px',
            width: '200px',
          }}
          as='select'
        >
          <option value={0}>Select Action</option>
          {AuditActions.map(({ label, value }) => (
            <option value={value}>{label}</option>
          ))}
        </FormControl>
      </Form.Group>
    );

    return (
      <Layout title={'Device Audit Trail'}>
        <Container
          fluid
          style={{
            backgroundColor: 'white',
            borderRadius: '4px',
            margin: '0px',
            paddingTop: 0,
            marginBottom: '40px',
            ...screenSensitiveStyle,
          }}
        >
          <Row>
            <Col hidden={this.state.deviceIsMobile} xs={4} md={4}>
              {deviceSelect}
            </Col>
            <Col hidden={this.state.deviceIsMobile} xs={2} md={4}>
              {actionSelect}
            </Col>
            {/* <Col xs={8} md={3} style={{paddingTop: '12px'}}> */}
            <div
              className='reports-scene-date-picker-container'
              style={{
                paddingTop: '15px',
                marginBottom: this.state.deviceIsMobile && '15px',
                marginLeft: this.state.deviceIsMobile && '15px',
              }}
            >
              <DatePicker
                selected={this.state.startDate}
                onChange={(date) => this.setStartDate(date)}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                dateFormat={`dd/MM/yyyy`}
                showYearDropdown
                customInput={<DateInputButton />}
                maxDate={TodaysDate}
              />
              <Text
                className='date-input-separator'
                style={{ fontSize: '24px' }}
              >
                -
              </Text>
              <DatePicker
                selected={this.state.endDate}
                onChange={(date) => this.setEndDate(date)}
                selectsEnd
                endDate={this.state.endDate}
                minDate={this.state.startDate}
                maxDate={TodaysDate}
                dateFormat={`dd/MM/yyyy`}
                showYearDropdown
                customInput={<DateInputButton />}
              />
            </div>
            {/* </Col> */}
            <Col md={12}>
              {this.state.didErrorOccurWhileFetchingAllTransactions ? (
                this.renderRefreshTransactionMessage()
              ) : (
                <AuditTrailTable
                  data={auditTrail}
                  getAuditTrail={this.getAuditTrail}
                  pageCount={pageCount}
                  pageSize={pageSize}
                  loading={isLoading}
                  deviceId={this.state.deviceId}
                  actions={this.state.actions}
                />
              )}
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}
