import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/messaging";
// import { notifications } from './api';
// import { saveData } from "../utils/storage";
import MessagingService from "../services/api/resources/messaging-service";
import { getClientDetails } from "../utils";

const { isIOS, isMac } = getClientDetails();

var firebaseConfig = {
  apiKey: "AIzaSyB78KDChCJh4y1wsrAyPOqrej7OKeXVb64",
  appId: "1:528876495002:web:edd9664884dd1fe3e17f23",
  measurementId: "G-C0D1C2CYKJ",
  messagingSenderId: "528876495002",
  projectId: "quickteller-paypoint-test",
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);
export let firebaseMessaging = undefined;

if (isIOS || isMac) {
  firebaseMessaging = class {};
} else {
  firebaseMessaging = firebase.messaging.isSupported()
    ? firebase.messaging()
    : null;
}

export async function setupMessaging(phone) {
  const messagingService = new MessagingService();
  try {
    firebaseMessaging
      .requestPermission()
      .then(() => {
        console.log("Notification permission granted");
      })
      .catch((err) => {
        console.log("Unable to get permission to notify.", err);
      });

    const firebaseToken = await firebaseMessaging.getToken({
      vapidKey: process.env.REACT_APP_FIREBASE_MESSAGING_PUBLIC_VAPID_KEY,
    });

    // notifications.registerNotificationClient(refreshedToken);

    firebaseMessaging.onTokenRefresh(() => {
      firebaseMessaging
        .getToken({
          vapidKey: process.env.REACT_APP_FIREBASE_MESSAGING_PUBLIC_VAPID_KEY,
        })
        .then((refreshedToken) => {
          console.log("FIREBASE TOKEN", refreshedToken);

          // notifications.registerNotificationClient(refreshedToken);
          // saveData('FIREBASE_MESSAGING_CLIENT_ID', refreshedToken);

          // callback && callback();
        })
        .catch((err) => {});
    });

    await messagingService.subscribeToPushNotification(phone, firebaseToken);
  } catch (e) {}
}

export async function listenToNotification() {
  firebaseMessaging.onMessage((payload) => {
    return true;
  });
  return false;
}
