import React, { useState, useEffect } from 'react';
import SelectInput from '../component/select';
import InputText from '../component/input';
import H1 from '../../../../../components/h1';
import { Col } from 'react-bootstrap';
import BackButton from '../component/backButton';
import PrimaryButton from '../component/button';

const NextOfKinScene = ({ setScreen, application, props }) => {
    const relationship = [
        'Aunt',
        'Brother',
        'Cousin',
        'Daughter',
        'Father',
        'Guardian',
        'Mother',
        'Nephew/Niece',
        'Sister',
        'Spouse',
        'Son',
        'Uncle',
    ];
    const currentAgent = JSON.parse(
        localStorage.getItem("currentAgent")
    );
    const handleUpgrade = () => {
        this.props.navigateTo('/manage-profile')
    };

    return (
        <>
            <BackButton onClick={() => setScreen(4)} />
            <main className='aggregator-container'>
                <Col
                    style={{
                        marginBottom: '20px',
                    }}
                >

                    <H1
                        style={{
                            textAlign: 'left',
                            fontWeight: 600,
                            marginBottom: '1rem',
                            fontSize: '2rem',
                            color: '#1F2937'
                        }}
                    >
                        Next of Kin Details
                    </H1>

                    <InputText
                        disabled={true}
                        value={currentAgent?.nextOfKins[0]?.firstname}
                        label={'Full Name'}
                    />
                    <InputText
                        disabled={true}
                        value={currentAgent?.nextOfKins[0]?.phoneNo}
                        label={'Phone Number'}
                    />
                    <InputText
                        disabled={true}
                        value={currentAgent?.nextOfKins[0]?.emailAddress}
                        label={'Email Address'}
                    />
                    <InputText
                        disabled={true}
                        value={currentAgent?.nextOfKins[0]?.residentialAddress?.addressLine1}
                        label={'Home Address'}
                    />
                    <SelectInput
                        value={currentAgent?.nextOfKins[0]?.relationship}
                        option={relationship}
                        label={"Relationship"}
                        disabled={true}
                    />
                </Col>
                {/* <Link to='/agent/manage-profile'> */}
                <PrimaryButton
                    onClick={() => setScreen(1)}
                >
                    Next
                </PrimaryButton>
            </main>
        </>
    );
};

export default NextOfKinScene;
