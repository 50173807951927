import React, { useState } from "react";

import { connect } from "react-redux";

import "../app.css";

import InventoryLabel from "../components/inventoryLabel";
import { updateLoading } from "../../../../../../services/redux/actions/tunnel";
import { navigateTo } from "../../../../../../services/redux/actions/navigation";
import { BalanceCard } from "../components/balanceCard";
import ReactTable from "react-table";
import { TotalStockColumns } from "../components/data";
import { getPaginationRows } from "../../../../../../utils/helpers";
import Spinner from "../../../../../../components/spinner";
import { Spacer } from "../components/extras";

const InventoryScreen = (props) => {
  const [getEarningBalance, setGetEarningBalance] = useState();
  const [selectedFilter, setSelectedFilter] = useState("Total Stock in Store");
  const [dateIntervalType, setDateIntervalType] = useState(2);
  const [inventories, setInventories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState(1);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [dataCount, setDataCount] = useState();
  // const transactionHistory = new TransactionHistory();
  const [loading, setLoading] = useState(true);

  const handleFilterClick = (filterName) => {
    setSelectedFilter(filterName);
    if (filterName === "Total Stock in Store") {
      setDateIntervalType(1);
    } else if (filterName === "Stock in Transit") {
      setDateIntervalType(2);
    } else if (filterName === "Stock in Transit") {
      setDateIntervalType(3);
    } else {
      setDateIntervalType();
    }
  };
  const filterStyle = (filterName) => {
    return {
      cursor: "pointer",
      border:
        selectedFilter === filterName
          ? "1px solid #0275D8"
          : "1px solid #e5e7eb",
      backgroundColor: selectedFilter === filterName ? "#EBF8FE" : "white",
    };
  };

  //   const fetchEarningBalance = async (dateIntervalType, startDate, endDate) => {
  //     setGetEarningBalance([]);
  //     setLoading(true);
  //     // const payload = {
  //     //   dateIntervalType: dateIntervalType,
  //     // };

  //     let payload = {};
  //     if (dateIntervalType) {
  //       payload = {
  //         dateIntervalType: dateIntervalType,
  //       };
  //     } else {
  //       payload = {
  //         from: specialDateRangeFormatter_StartDate(startDate),
  //         to: specialDateRangeFormatter_StartDate(endDate),
  //       };
  //     }
  //     const { status, response } = await transactionHistory.getEarnings(payload);

  //     setLoading(false);
  //     if (status === ERROR_STATUS) {
  //       // setError(true);
  //     } else if (status === SUCCESS_STATUS) {
  //       setLoading(false);

  //       setGetEarningBalance(response.data);
  //     } else {
  //       setLoading(false);
  //     }
  //   };

  return (
    <main className="main-container">
      <InventoryLabel />
      <Spacer height={15} />
      <article className="agentType-container-card-commission-pos">
        <BalanceCard
          dynamicStyle={filterStyle("Total Stock in Store")}
          onClick={() => handleFilterClick("Total Stock in Store")}
          //   loading={loading}
          title={"Total Stock in Store"}
          balance={"21"}
        />
        <BalanceCard
          dynamicStyle={filterStyle("Stock in transit")}
          onClick={() => handleFilterClick("Stock in transit")}
          //   loading={isLoading}
          title={"Stock in transit"}
          balance={"52"}
        />
        <BalanceCard
          dynamicStyle={filterStyle("Total Deployed POS")}
          onClick={() => handleFilterClick("Total Deployed POS")}
          //   loading={isLoading}
          title={"Total Deployed POS"}
          balance={"72"}
        />
      </article>
      <Spacer height={15} />
      <article
        style={{
          padding: "20px",
        }}
      >
        <ReactTable
          style={{
            backgroundColor: "white",
          }}
          columns={TotalStockColumns}
          data={inventories}
          dataCount={dataCount}
          pageSizes={getPaginationRows(dataCount)}
          pages={pages}
          defaultPageSize={10}
          // onFetchData={(state, instance) => {
          //   getWithdrawalReport(state.pageSize, state.page + 1);
          // }}
          noDataText={isLoading ? "" : "No records found"}
          loading={isLoading}
          LoadingComponent={Spinner}
        />
      </article>
    </main>
  );
};

function mapStateToProps(state) {
  return {
    isLoading: state.tunnel.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateLoading: (isLoading) => dispatch(updateLoading(isLoading)),
    navigateTo: (pendingUrl) => dispatch(navigateTo(pendingUrl)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryScreen);
