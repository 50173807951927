import React from 'react';
import { Col, Container, Row, Button, Form, InputGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-table/react-table.css';
import Select from 'react-select';
import { CSVLink } from 'react-csv';
import MdSync from 'react-ionicons/lib/MdSync';
import qs from 'qs';

import BaseScene from '../../../../base-scene';
import DateInputButton from '../../../../../components/date-inputs/button';
import Text from '../../../../../components/text';
import { AgentStatuses } from '../../../../../fixtures/report_filter_options';
import {
  SUCCESS_STATUS,
  HTTP_NOT_FOUND_CODE,
} from '../../../../../constants/api';
import AgentTable from './tables/agent-table';
import { handleLongNumber, formatDate2 } from '../../../../../utils/formatters';

import { convertNgkToNgn } from '../../../../../utils/converters/currencies';
import MdSearch from 'react-ionicons/lib/MdSearch';
import Platform from '../../../../../services/api/resources/platform';
import H1 from '../../../../../components/h1';
import ActivityIndicator from '../../../../../components/activity-indicator';
// import SampleAgentsResponse  from '../../../../fixtures/agents.json'

export default class AgentScene extends BaseScene {
  location = window.location.href;
  path = '/my-agents';
  title = 'My Agents';

  platform = new Platform();

  TodaysDate = new Date();
  OneMonthsBefore = new Date(
    this.TodaysDate.getFullYear(),
    this.TodaysDate.getMonth() - 1
  );

  constructor(props) {
    super(props);

    this.state = {
      filteredAgents: [],
      sampleAgents: [],
      endDate: this.TodaysDate,
      startDate: this.OneMonthsBefore,
      didErrorOccurWhileFetchingAllTransactions: false,
      pageNum: 1,
      pageSize: 50,
      agentStatus: { label: 'Agent Status', value: '0' },
      deviceIsMobile:
        qs.parse(window.location.search)['?mobile'] || window.innerWidth <= 576,
      deviceIsPC:
        !qs.parse(window.location.search)['?mobile'] &&
        window.innerWidth >= 992,
      deviceIsTab:
        qs.parse(window.location.search)['?mobile'] || window.innerWidth <= 992,
    };

    this.getAgents = this.getAgents.bind(this);
    this.filterByAgentStatus = this.filterByAgentStatus.bind(this);
  }

  componentDidMount() {}

  componentWillMount() {
    this.getAgents(
      this.state.pageNum,
      this.state.pageSize,
      this.state.agentStatus.value
    );
    this.getAgentsCount();
  }

  async getAgents(pageNum, pageSize, agentStatus) {
    this.setState({
      isLoading: true,
      pageNum,
      pageSize,
    });

    agentStatus = agentStatus !== '0' ? agentStatus : null;

    const { status, response, code } = await this.platform.searchAgents(
      pageNum,
      pageSize,
      agentStatus
    );

    if (status === SUCCESS_STATUS) {
      this.setState({
        sampleAgents: response.content,
        filteredAgents: response.content,
        dataCount: response.count / this.state.pageSize,
        isLoading: false,
        pages: Math.ceil(response.count / pageSize),
        pageSize: pageSize,
        didErrorOccurWhileGettingAgents: false,
      });
    } else {
      this.handleGetAgentsEmptyResponse(code);
    }
  }

  async getAgentsCount() {
    const { status, response } = await this.platform.agentCount();

    if (status === SUCCESS_STATUS) {
      this.setState({
        agentCount: response,
      });
    } else {
      this.setState({
        agentCount: [],
      });
    }
  }

  handleGetAgentsEmptyResponse = (code) => {
    this.setState({
      sampleAgents: [],
      filteredAgents: [],
      isLoading: false,
    });
    if (code === HTTP_NOT_FOUND_CODE) {
      this.setState({
        didErrorOccurWhileGettingAgents: false,
      });
    } else {
      this.setState({
        didErrorOccurWhileGettingAgents: true,
      });
    }
  };

  filterByAgentStatus(agentStatus) {
    const { pageNum, pageSize } = this.state;
    this.setState({
      agentStatus,
    });
    this.getAgents(pageNum, pageSize, agentStatus.value);
  }

  filterTransactionsByType(selectedOption) {
    this.setState({
      selectedOption,
    });
    selectedOption.value === '0'
      ? this.getWalletJournal(this.state.pageNum, this.state.pageSize)
      : this.getWalletJournal(
          this.state.pageNum,
          this.state.pageSize,
          selectedOption.value,
          null,
          null
        );
  }

  renderRefreshAgentsMessage() {
    const { pageNum, pageSize } = this.state;
    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'top',
          marginTop: '24px',
          paddingBottom: '30px',
        }}
      >
        <Text blue bold small>
          Oops!
        </Text>
        <Text mid>Something went wrong. Please, try again.</Text>
        <div
          style={{
            alignItems: 'center',
            border: `1pt solid ${process.env.REACT_APP_BLUE_COLOUR}`,
            borderRadius: '4px',
            color: process.env.REACT_APP_BLUE_COLOUR,
            cursor: 'pointer',
            display: 'flex',
            fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            fontStyle: process.env.REACT_APP_MID_FONT_SIZE,
            fontWeight: 'bold',
            height: '40px',
            justifyContent: 'center',
            marginTop: '16px',
            width: '160px',
          }}
          onClick={() => this.getAgents(pageNum, pageSize)}
        >
          <MdSync
            rotate={this.state.isLoading}
            color={process.env.REACT_APP_BLUE_COLOUR}
            fontSize={'24px'}
            style={{ marginRight: '10px' }}
          />
          {this.state.isLoading ? 'Loading...' : 'RETRY'}
        </div>
      </div>
    );
  }

  searchByBusinessName = (event) => {
    const { sampleAgents } = this.state;

    let search = event.target.value;
    let initialAgents = sampleAgents;

    if (search !== '') {
      const searchTerm = search.toLowerCase();
      initialAgents = initialAgents.filter((agent) => {
        return agent.businessName.toLowerCase().match(searchTerm);
      });
    } else {
      initialAgents = sampleAgents;
    }

    this.setState({
      businessName: search,
      filteredAgents: initialAgents,
    });
  };

  searchByBusinessPhone = (event) => {
    const { sampleAgents } = this.state;

    let search = event.target.value;
    let initialAgents = sampleAgents;

    if (search !== '') {
      const searchTerm = search.startsWith('0') ? search.slice(1) : search;
      initialAgents = initialAgents.filter((agent) => {
        return agent.agentMobileNo.toString().match(searchTerm);
      });
    } else {
      initialAgents = sampleAgents;
    }

    this.setState({
      businessPhone: search,
      filteredAgents: initialAgents,
    });
  };

  render() {
    const {
      businessName,
      businessPhone,
      agentStatus,
      startDate,
      endDate,
      filteredAgents,
      didErrorOccurWhileGettingAgents,
      pages,
      pageSize,
      pageNum,
      isLoading,
      deviceIsMobile,
    } = this.state;

    const csvFilteredTransaction = filteredAgents.map((agent) => ({
      agentCode: agent.agentCode,
      agentMobileNo:
        agent.agentMobileNo && handleLongNumber(agent.agentMobileNo),
      businessName: agent.businessName,
      agentClass: agent.agentClass,
      dateApplied: agent.dateApplied && formatDate2(agent.dateApplied),
      dateLastUpdated:
        agent.dateLastUpdated && formatDate2(agent.dateLastUpdated),
      status: agent.status,
    }));

    const csvHeader = [
      { label: 'Agent ID', key: 'agentCode' },
      { label: 'Phone Number', key: 'agentMobileNo' },
      { label: 'Business Name', key: 'businessName' },
      { label: 'Agent Class', key: 'agentClass' },
      { label: 'Date Created', key: 'dateApplied' },
      { label: 'Last Modified', key: 'dateLastUpdated' },
      { label: 'Status', key: 'status' },
    ];

    return (
      <div className='aggregator-layout'>
        <H1
          style={{
            textAlign: 'start',
            marginBottom: '35px',
          }}
        >
          My Agents
        </H1>
        {/* <div className="h1 reports-scene-header">My Agents</div> */}
        <section>
          {/* <Row id="reports-scene-row">
                <Col xs={12} md={12} xl={12} id="services-report-container" >
                    <Col md={12} className="card-with-shadow" id="transactions-table-container"> */}
          <div className='agent-table-header'>
            {/* <div className="search-container">
                              <input className="search-input" value={businessName} onChange={this.searchByBusinessName} placeholder="Search by business name..."/>
                            </div> */}
            {this.state.deviceIsPC && (
              <>
                <InputGroup
                  col
                  lg={3}
                  style={{
                    background: '#F3F5F6',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    borderRadius: 5,
                    width: '15vw',
                    // width: this.state.deviceIsPC ? '25vw' : '40vw',
                    padding: '0 10px',
                  }}
                >
                  <div
                    style={{
                      fontSize: 30,
                      textAlign: 'center',
                      color: '#848F9F',
                      cursor: 'pointer',
                    }}
                    // onClick={() => this.handleSearch()}
                  >
                    <MdSearch color='grey' />
                  </div>
                  <Form.Control
                    onChange={this.searchByBusinessName}
                    value={businessName}
                    title='Search by business name...'
                    style={{
                      border: 'none',
                      background: '#F3F5F6',
                      height: 40,
                      borderRadius: 5,

                      boxShadow: 'none',
                    }}
                    placeholder='Search by business name...'
                  />
                </InputGroup>
                <InputGroup
                  col
                  lg={3}
                  style={{
                    background: '#F3F5F6',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    borderRadius: 5,
                    width: '15vw',
                    // width: this.state.deviceIsPC ? '25vw' : '40vw',
                    padding: '0 10px',
                  }}
                >
                  <div
                    style={{
                      fontSize: 30,
                      textAlign: 'center',
                      color: '#848F9F',
                      cursor: 'pointer',
                    }}
                    // onClick={() => this.handleSearch()}
                  >
                    <MdSearch color='grey' />
                  </div>
                  <Form.Control
                    value={businessPhone}
                    onChange={this.searchByBusinessPhone}
                    title='Search by business phone number...'
                    style={{
                      border: 'none',
                      background: '#F3F5F6',
                      height: 40,
                      borderRadius: 5,

                      boxShadow: 'none',
                    }}
                    placeholder='Search by business phone number...'
                  />
                </InputGroup>
                {/* <div className='search-container'>
              <input
                className='search-input'
                value={businessPhone}
                onChange={this.searchByBusinessPhone}
                placeholder='Search by business phone number...'
              />
            </div> */}
              </>
            )}

            <Select
              options={AgentStatuses}
              onChange={this.filterByAgentStatus}
              value={agentStatus}
              className='select-input-container'
              classNamePrefix='react-select'
            />
            {/* <div className="reports-scene-date-picker-container">
                              <DatePicker
                                selected={startDate}
                                onChange={date => this.setStartDate(date)}
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat={`dd/MM/yyyy`}
                                customInput={<DateInputButton />}
                                maxDate={this.TodaysDate}
                              />
                              <Text className="date-input-separator" style={{fontSize: '24px'}}>-</Text> 
                              <DatePicker
                                selected={endDate}
                                onChange={date => this.setEndDate(date)}
                                selectsEnd
                                endDate={endDate}
                                minDate={startDate}
                                maxDate={this.TodaysDate}
                                dateFormat={`dd/MM/yyyy`}
                                customInput={<DateInputButton/>}
                              />
                            </div> */}
            <div>
              <Button className='download-link-button'>
                <CSVLink
                  data={csvFilteredTransaction}
                  filename='QuickTellerPaypoint Agents List.csv'
                  className='download-text'
                  headers={csvHeader}
                >
                  Download
                </CSVLink>
              </Button>
            </div>
          </div>
          {this.state.agentCount && (
            <div className='agent-table-header'>
              <div>
                <p className='active-status'>
                  Active Agents:{' '}
                  <span>
                    {this.state.agentCount && this.state.agentCount.activeAgent}
                  </span>{' '}
                </p>
              </div>
              <div>
                <p className='new-status'>
                  New Agents:{' '}
                  <span>
                    {this.state.agentCount && this.state.agentCount.newAgent}
                  </span>
                </p>
              </div>
              <div>
                <p className='suspended-status'>
                  Suspended Agents:{' '}
                  <span>
                    {this.state.agentCount &&
                      this.state.agentCount.suspendedAgent}
                  </span>{' '}
                </p>
              </div>
              <div>
                <p className='dormant-status'>
                  Dormant Agents:{' '}
                  <span>
                    {this.state.agentCount &&
                      this.state.agentCount.dormantAgent}
                  </span>{' '}
                </p>
              </div>
              <div>
                <p className='retire-status'>
                  Retired Agents:{' '}
                  <span>
                    {this.state.agentCount && this.state.agentCount.retireAgent}
                  </span>{' '}
                </p>
              </div>
            </div>
          )}
          <div>
            {didErrorOccurWhileGettingAgents ? (
              this.renderRefreshAgentsMessage()
            ) : filteredAgents.length > 0 ? (
              <AgentTable
                data={filteredAgents}
                dataCount={this.state.dataCount}
                getAllTransactions={this.getAgents}
                startDate={startDate}
                endDate={endDate}
                pages={pages}
                pageSize={pageSize}
                pageNum={pageNum}
                loading={isLoading}
                agentStatus={agentStatus}
                dismissErrorMessage={this.props.dismissErrorMessage}
                showErrorMessage={this.props.showErrorMessage}
              />
            ) : isLoading ? (
              <p
                style={{
                  textAlign: 'center',
                  cursor: 'pointer',
                  padding: '20px 0 20px 0',
                }}
              >
                <ActivityIndicator />
              </p>
            ) : (
              <p
                style={{
                  textAlign: 'center',
                  color: process.env.REACT_APP_BLUE_COLOUR,
                  fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                  padding: '85px 0 85px 0',
                }}
              >
                {'No record found, change filter'}
              </p>
            )}
          </div>
          {/* </Col>
                </Col>
            </Row> */}
        </section>
      </div>
    );
  }
}
