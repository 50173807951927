import React from 'react';
import {
  Route, 
  Switch 
} from 'react-router-dom';
import ViewAllUsersScene from './scenes/view-all-users';
import CreateUserScene from './scenes/create-user';
import ViewAndEditUserScene from './scenes/view-and-edit-user';

export default class RoleScene extends React.Component {
  render() {

    const switchComponent = <Switch>
      {/* <Route path={`${process.env.REACT_APP_ROUTE_BASENAME}/dashboard`} component={() => <DashboardScene currentApplication={this.state.currentApplication} currentAgent={this.state.currentAgent} disabled={this.state.disabled} loadUser={this.loadUser} getWallet={this.getWallet} />} /> */}
      <Route exact path={`${process.env.REACT_APP_ROUTE_BASENAME}/users`} component={ViewAllUsersScene} />
      <Route exact path={`${process.env.REACT_APP_ROUTE_BASENAME}/users/new`} component={CreateUserScene} />
      <Route exact path={`${process.env.REACT_APP_ROUTE_BASENAME}/users/:username`} component={ViewAndEditUserScene} />
    </Switch>

    return switchComponent
  }
}
