import React, { useEffect, useRef, useState } from "react";
import { Col, Form } from "react-bootstrap";
import H1 from "../../../../components/h1";
import PrimaryButton from "../component/button";
import InputText from "../component/input";
import { isHomeAddressValid, isPhoneNumValid, isSingleNameValid } from "../component/extras";
import SelectInput from "../component/select";
import BackButton from "../component/backButton";
import Platform from "../../../../services/api/resources/platform";
import { notify } from "../../../../components/extras";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../constants/api";


const MAX_BUSINESS_NAME_LENGTH = 50;
const NextOfKinScene = ({ setScreen, application }) => {
    const platform = new Platform();

    const [isLoading, setIsLoading] = useState(false);
    const [nextOfKinDetailsForm, setNextOfKinDetailsForm] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        gender: '',
        relationship: '',
        homeAddress: '',
    });

    const handleSubmit = async () => {
        setIsLoading(true);
        const payload = {
            firstName: nextOfKinDetailsForm.firstName,
            lastName: nextOfKinDetailsForm.lastName,
            phoneNumber: nextOfKinDetailsForm.phoneNumber,
            gender: nextOfKinDetailsForm.gender,
            relationship: nextOfKinDetailsForm.relationship,
            homeAddress: nextOfKinDetailsForm.homeAddress,
        };
        const currentAgent = JSON.parse(localStorage.getItem("currentAgent"));
        const authToken = JSON.parse(localStorage.getItem("auth_token"));

        const agentCode = currentAgent.domainCode;
        const { status, response } = await platform.postNextOfKinUpdate(
            payload,
            agentCode,
            `Bearer ${authToken}`,
        );
        setIsLoading(false);
        if (status === ERROR_STATUS) {
            notify(response.description, "error");
        } else if (status === SUCCESS_STATUS) {
            notify("Saved", "success");
            setScreen(4);
        }
    };

    useEffect(() => {
        const updatedNextOfKinDetailsForm = JSON.parse(
            localStorage.getItem("nextOfKinDetailsForm")
        );

        if (updatedNextOfKinDetailsForm) {
            setNextOfKinDetailsForm({
                firstName:
                    application?.nextOfKinDetailsForm?.firstName ||
                    updatedNextOfKinDetailsForm.firstName ||
                    "",
                lastName:
                    application?.nextOfKinDetailsForm?.lastName ||
                    updatedNextOfKinDetailsForm.lastName ||
                    "",
                phoneNumber:
                    application?.nextOfKinDetailsForm?.phoneNumber ||
                    updatedNextOfKinDetailsForm.phoneNumber ||
                    "",
                gender:
                    application?.nextOfKinDetailsForm?.gender ||
                    updatedNextOfKinDetailsForm.gender ||
                    "",
                relationship:
                    application?.nextOfKinDetailsForm?.relationship ||
                    updatedNextOfKinDetailsForm.relationship ||
                    "",
                homeAddress:
                    application?.nextOfKinDetailsForm?.homeAddress ||
                    updatedNextOfKinDetailsForm.homeAddress ||
                    "",
            });
        }
    }, [application]);

    useEffect(() => {
        localStorage.setItem(
            "nextOfKinDetailsForm",
            JSON.stringify(nextOfKinDetailsForm)
        );
    }, [nextOfKinDetailsForm]);

    const updateFormField = (params) => {
        const newForm = {
            ...nextOfKinDetailsForm,
            ...params,
        };

        setNextOfKinDetailsForm(newForm);
    };

    const stripSpacesAndNonNumbers = (str) => {
        return str.replace(/[^0-9]/g, "");
    };

    const gender = ['Male', 'Female'];

    const relationship = [
        "Aunt",
        "Brother",
        "Cousin",
        "Daughter",
        "Father",
        "Guardian",
        "Mother",
        "Nephew/Niece",
        "Sister",
        "Spouse",
        "Son",
        "Uncle",
    ];

    const isFormValid = () => {
        return (
            nextOfKinDetailsForm.firstName &&
            nextOfKinDetailsForm.lastName &&
            nextOfKinDetailsForm.phoneNumber &&
            nextOfKinDetailsForm.gender &&
            nextOfKinDetailsForm.relationship &&
            nextOfKinDetailsForm.homeAddress &&
            isSingleNameValid(nextOfKinDetailsForm.firstName) &&
            isSingleNameValid(nextOfKinDetailsForm.lastName) &&
            isPhoneNumValid(nextOfKinDetailsForm.phoneNumber) &&
            isHomeAddressValid(nextOfKinDetailsForm.homeAddress)
        );
    };


    return (
        <>
            <BackButton onClick={() => setScreen(2)} />

            <main className="onboarding-container">
                <Col
                    style={{
                        margin: "20px auto",
                    }}
                >
                    <H1
                        style={{
                            textAlign: "left",
                            fontWeight: 600,
                            marginBottom: "2rem",
                        }}
                    >
                        Next of Kin Details
                    </H1>

                    <InputText
                        label={'First Name'}
                        labelColor={
                            !nextOfKinDetailsForm.firstName ||
                                isSingleNameValid(nextOfKinDetailsForm.firstName)
                                ? 'black'
                                : process.env.REACT_APP_RED_COLOUR
                        }
                        border={`1px solid ${!nextOfKinDetailsForm.firstName ||
                            isSingleNameValid(nextOfKinDetailsForm.firstName)
                            ? '#DFE2E6'
                            : process.env.REACT_APP_RED_COLOUR
                            }`}
                        placeholder={'Enter first name'}
                        onChange={(e) => {
                            const filteredValue = e.target.value.replace(/[^A-Za-z]/g, "");
                            updateFormField({ firstName: filteredValue });
                        }}
                        maxLength={MAX_BUSINESS_NAME_LENGTH}
                        value={nextOfKinDetailsForm.firstName}
                    />
                    <InputText
                        label={'Last Name'}
                        labelColor={
                            !nextOfKinDetailsForm.lastName ||
                                isSingleNameValid(nextOfKinDetailsForm.lastName)
                                ? 'black'
                                : process.env.REACT_APP_RED_COLOUR
                        }
                        border={`1px solid ${!nextOfKinDetailsForm.lastName ||
                            isSingleNameValid(nextOfKinDetailsForm.lastName)
                            ? '#DFE2E6'
                            : process.env.REACT_APP_RED_COLOUR
                            }`}
                        placeholder={'Enter last name'}
                        onChange={(e) => {
                            const filteredValue = e.target.value.replace(/[^A-Za-z]/g, "");
                            updateFormField({ lastName: filteredValue });
                        }}
                        maxLength={MAX_BUSINESS_NAME_LENGTH}
                        value={nextOfKinDetailsForm.lastName}
                    />
                    <InputText
                        label={'Phone Number'}
                        placeholder={'Enter phone number'}
                        onChange={(e) => {
                            updateFormField({ phoneNumber: stripSpacesAndNonNumbers(e.target.value) });
                        }}
                        labelColor={
                            !nextOfKinDetailsForm.phoneNumber ||
                                isPhoneNumValid(nextOfKinDetailsForm.phoneNumber)
                                ? 'black'
                                : process.env.REACT_APP_RED_COLOUR
                        }
                        border={`1px solid ${!nextOfKinDetailsForm.phoneNumber ||
                            isPhoneNumValid(nextOfKinDetailsForm.phoneNumber)
                            ? '#DFE2E6'
                            : process.env.REACT_APP_RED_COLOUR
                            }`}
                        value={nextOfKinDetailsForm.phoneNumber}
                        maxLength={11}
                    />

                    <div
                        style={{
                            display: 'flex',
                            gap: '10px',
                        }}
                    >
                        <SelectInput
                            flex={'1'}
                            onChange={(e) => {
                                updateFormField({ gender: e.target.value });
                            }}
                            placeholder={'gender'}
                            value={nextOfKinDetailsForm.gender}
                            option={gender}
                            label={'Gender'}
                        />
                    </div>
                    <br />

                    <SelectInput
                        label={"Relationship"}
                        onChange={(e) => {
                            updateFormField({ relationship: e.target.value });
                        }}
                        placeholder={"relationship"}
                        value={nextOfKinDetailsForm.relationship}
                        option={relationship}
                    />
                    <br />

                    <InputText
                        label={'Home Address'}
                        labelColor={
                            nextOfKinDetailsForm.homeAddress &&
                                !isHomeAddressValid(nextOfKinDetailsForm.homeAddress)
                                ? process.env.REACT_APP_RED_COLOUR
                                : 'black'
                        }
                        border={`1px solid ${nextOfKinDetailsForm.homeAddress &&
                            !isHomeAddressValid(nextOfKinDetailsForm.homeAddress)
                            ? process.env.REACT_APP_RED_COLOUR
                            : '#DFE2E6'
                            }`}
                        placeholder={'Enter home address'}
                        onChange={(e) => {
                            updateFormField({ homeAddress: e.target.value });
                        }}
                        maxLength={MAX_BUSINESS_NAME_LENGTH}
                        value={nextOfKinDetailsForm.homeAddress}
                    />

                    <PrimaryButton
                        disabled={!isFormValid() || isLoading}
                        onClick={handleSubmit}
                    >
                        {isLoading ? "Processing..." : "Next"}
                    </PrimaryButton>
                </Col>
            </main>
        </>
    );
};

export default NextOfKinScene;
