import React from 'react';
import MdArrowDropright from 'react-ionicons/lib/MdArrowDropright';
import { Link } from 'react-router-dom';

import Layout from '../manage-devices/layout';

const PosManagement = () => {
  const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');

  return (
    <Layout title={'POS MANAGEMENT'}>
      <div className='pos-link-container'>
        <div>
        
          {currentUser.domainTypeId === 3 && (
            <>
              <Link
                className='posManagement-link'
                to={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-devices/pos-management/remap-pos`}
              >
                <span>Remap POS</span>
                <MdArrowDropright
                  color={'#00000'}
                  height='250px'
                  width='250px'
                />
              </Link>
              <Link
            className='posManagement-link'
            to={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-devices/pos-management/request-pos`}
          >
            <span>Request POS</span>
            <MdArrowDropright color={'#00000'} height='250px' width='250px' />
          </Link>
          <Link
            className='posManagement-link'
            to={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-devices/pos-management/pos-report`}
          >
            <span>POS Remap Report</span>
            <MdArrowDropright color={'#00000'} height='250px' width='250px' />
          </Link>
          <Link
            className='posManagement-link'
            to={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-devices/pos-management/track-pos-order`}
          >
            <span>Track POS Order</span>
            <MdArrowDropright color={'#00000'} height='250px' width='250px' />
          </Link>
            </>
          )}
          {currentUser.domainTypeId === 4 && currentUser.fipUser === true && (
            <>
             <Link
                className='posManagement-link'
                to={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-devices/pos-management/remap-pos`}
              >
                <span>Remap POS</span>
                <MdArrowDropright
                  color={'#00000'}
                  height='250px'
                  width='250px'
                />
              </Link>
              <Link
            className='posManagement-link'
            to={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-devices/pos-management/request-pos`}
          >
            <span>Request POS</span>
            <MdArrowDropright color={'#00000'} height='250px' width='250px' />
          </Link>
          <Link
            className='posManagement-link'
            to={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-devices/pos-management/pos-report`}
          >
            <span>POS Remap Report</span>
            <MdArrowDropright color={'#00000'} height='250px' width='250px' />
          </Link>
          <Link
            className='posManagement-link'
            to={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-devices/pos-management/track-pos-order`}
          >
            <span>Track POS Order</span>
            <MdArrowDropright color={'#00000'} height='250px' width='250px' />
          </Link>
            </>
          )}
          {currentUser.domainTypeId === 4 && currentUser.fipUser === false && (
            <>
              <Link
            className='posManagement-link'
            to={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-devices/pos-management/request-pos`}
          >
            <span>Request POS</span>
            <MdArrowDropright color={'#00000'} height='250px' width='250px' />
          </Link>
          <Link
            className='posManagement-link'
            to={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-devices/pos-management/pos-report`}
          >
            <span>POS Remap Report</span>
            <MdArrowDropright color={'#00000'} height='250px' width='250px' />
          </Link>
          <Link
            className='posManagement-link'
            to={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-devices/pos-management/pos-remap-confirmation`}
          >
            <span>POS Remap Confirmation</span>
            <MdArrowDropright color={'#00000'} height='250px' width='250px' />
          </Link>
          {/* <Link
            className='posManagement-link'
            to={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-devices/pos-management/pos-receipt-confirmation`}
          >
            <span>POS Receipt Confirmation</span>
            <MdArrowDropright color={'#00000'} height='250px' width='250px' />
          </Link> */}
          <Link
            className='posManagement-link'
            to={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-devices/pos-management/track-pos-order`}
          >
            <span>Track POS Order</span>
            <MdArrowDropright color={'#00000'} height='250px' width='250px' />
          </Link>
          </>)}
        </div>
      </div>
    </Layout>
  );
};

export default PosManagement;
