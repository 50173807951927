import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getDateByType } from "../utils/helpers";

export const DatePickerComponent = (props) => {
  const {
    selected,
    onChange,
    disabled,
    format,
    minDate,
    maxDate,
    showTimeInput,
    dateType,
    preloadDate,
    isClearable,
    size,
    textStyle,
    style,
    iconStyle,
    placeholder,
    height
  } = props;
  const defaultDate = preloadDate ? getDateByType(dateType) : null;
  const [date, setDate] = useState(defaultDate);

  const DatePickerCustomInput = ({ value, onClick }) => {
    return (
      <div class="searchContainer" style={{ padding: style }}>
        <input
          autocomplete="off"
          class="searchBoxCol1"
          type="text"
          name="search"
          style={{ width: size, height: height, paddingLeft: textStyle ,border:'1px solid rgb(230 223 226)',fontSize:14}}
          value={value}
          onClick={onClick}
          placeholder={placeholder}
        />
        <i
          class="fa fa-calendar searchIconLeft"
          style={{ padding: iconStyle }}
          aria-hidden="true"
        ></i>
        {isClearable && value && (
          <i
            class="fa fa-times-circle searchIconRight"
            style={{ color: "#00425f", cursor: "pointer" }}
            onClick={() => {
              setDate(null);
              onChange(null);
            }}
          ></i>
        )}
      </div>
    );
  };

  return (
    <React.Fragment>
      <DatePicker
        selected={selected ? new Date(selected) : date}
        onChange={(event) => {
          setDate(event);
          onChange(event);
        }}
        disabled={disabled}
        //fixedHeight
        customInput={<DatePickerCustomInput />}
        dateFormat={format}
        maxDate={maxDate || new Date()}
        minDate={minDate}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        showTimeInput={showTimeInput}
        closeOnScroll={true}
        placeholder={placeholder}
        // showTimeSelect
        // timeFormat="HH:mm"
        //showTimeSelect
        //dropdownMode="select"
      />
    </React.Fragment>
  );
};
