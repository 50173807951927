import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Stepper from "../../scenes/signup/onboarding-setup/component/stepper";
import {
  isOTPValid,
  stripNonNumbers,
} from "../../scenes/signup/onboarding-setup/component/extras";
import InputText from "../../scenes/signup/onboarding-setup/component/input";

import { ReloadIcon, greenCheckIcon } from "../../icons";
import HeaderSignup from "../header-signup";
import { firetoast, notify } from "../extras";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../constants/api";
import Platform from "../../services/api/resources/platform";

import BackButton from "../../scenes/signup/onboarding-setup/component/backButton";
import UserManagement from "../../services/api/resources/user-management";
import PrimaryButton from "../../scenes/signup/onboarding-setup/component/button";

import IosRefresh from "react-ionicons/lib/IosRefresh";
import { loadAgent } from "./mockApi";

const BvnValidationScene = ({ application }) => {
  const history = useHistory();
  const userManagement = new UserManagement();
  const platform = new Platform();
  const [showModal, setShowModal] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoadingOtp, setIsLoadingOtp] = useState(false);
  const [tokenId, setTokenId] = useState("");
  const [otp, setOtp] = useState("");
  const [response, setResponse] = useState(null);
  const [bvnValidationForm, setBvnValidationForm] = useState({
    bvn: application ? application?.applicantDetails?.bvnValidation?.bvn : "",
  });

  useEffect(() => {
    const updatedBvnValidationForm = JSON.parse(
      localStorage.getItem("bvnValidationForm")
    );

    if (updatedBvnValidationForm) {
      setBvnValidationForm({
        bvn:
          application?.applicantDetails?.bvnValidation?.bvn ||
          updatedBvnValidationForm.bvn ||
          "",
      });
    }
  }, [application]);

  useEffect(() => {
    localStorage.setItem(
      "bvnValidationForm",
      JSON.stringify(bvnValidationForm)
    );
  }, [bvnValidationForm]);

  const [isLoading, setIsLoading] = useState(false);

  const isBvnValid = (newForm) => {
    const form = newForm;

    return form.bvn && form.bvn.length >= 11 && !form.bvn.includes(" ");
  };

  const updateFormField = (params) => {
    const newForm = {
      ...bvnValidationForm,
      ...params,
    };

    setBvnValidationForm(newForm);
  };

  const congratsModal = () => {
    setShowModal(true);
  };
  const otpModal = () => {
    setShowOtpModal(true);
  };
  const navigateToNinVerification = () => {
    history.push({
      pathname: "/nin-verification",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const payload = {
      bvn: bvnValidationForm.bvn,
    };
    const { status, response, code } =
      await platform.submitInitiateImageCapturing(payload);

    if (code === 400) {
      setIsLoading(false);
      notify(response.description, "error");
    } else if (code === 200) {
      // await loadAgent();
      setIsLoading(false);
      congratsModal();
      setResponse(response.data);
    } else if (code === 409) {
      setIsLoading(false);
      setTokenId(response.data);
      otpModal();
    } else if (code === 201) {
      // await loadAgent();
      setIsLoading(false);
      notify(response.description, "success");
      navigateToNinVerification();
    }
  };
  const navigateToDashboard = () => {
    history.push({
      pathname: "/dashboard",
    });
  };
  const navigateToFaceVerification = () => {
    history.push({
      pathname: "/face-verification",
      state: { jobId: response.jobId, bvn: bvnValidationForm.bvn },
    });
  };

  const isFormValid = () => {
    return bvnValidationForm.bvn && isBvnValid(bvnValidationForm);
  };

  const textStyle = {
    color: "#1F2937",
    fontWeight: "500",
    fontSize: "0.8rem",
    letterSpacing: "0.3px",
  };

  const spanStyle = {
    display: "inline-flex",
    alignItems: "center",
  };

  const pStyle = {
    marginLeft: "10px",
    marginTop: "12px",
  };

  const iconStyle = {
    width: "20px",
    height: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const instructions = [
    "Ensure you are in a well-lit area",
    "Make sure you are in front of a plain background",
    "Make sure to remove hats, thick glasses, or anything else that might obscure your face",
    "Make sure you keep your expressions neutral",
  ];

  const callResendOtp = async () => {
    setIsLoadingOtp(true);

    const responseObj = await platform.cbnComplianceOtp(tokenId.tokenId);
    const { status, response } = responseObj;

    setIsLoadingOtp(false);
    if (status === ERROR_STATUS) {
      notify(`${response.description}`, "error");
    } else if (status === SUCCESS_STATUS) {
      setTokenId(response);
      notify("OTP successfully sent!", "success");
    }
  };

  const onSubmitButtonClick = async () => {
    setLoading(true);
    const responseObj = await platform.cbnComplianceValidateOtp(
      otp,
      tokenId.tokenId,
      tokenId.prefix
    );
    const { status, response } = responseObj;
    setLoading(false);
    if (status === ERROR_STATUS) {
      notify(`${response.description}`, "error");
    } else if (status === SUCCESS_STATUS) {
      navigateToNinVerification();
    }
  };

  const handleOtpChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 6) {
      setOtp(value);
    }
  };

  const checkAuthentication = () => {
    const isAuthenticated = localStorage.getItem("auth_token") === null;

    if (isAuthenticated) {
      history.push("/login");
      localStorage.clear();
    }
  };

  useEffect(() => {
    checkAuthentication();
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          gap: "40px",
          margin: "2rem 0 0 2rem",
        }}
      >
        <HeaderSignup />
      </div>
      <main className="onboarding-container">
        <div style={{ alignContent: "center", padding: "1rem" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              gap: "10px",
              marginBottom: "30px",
            }}
          >
            <Stepper borderColor="#00425F" />
            <Stepper />
            <Stepper />
            <Stepper />
            <Stepper />
          </div>
          <h1
            style={{
              fontWeight: "bold",
              marginBottom: "1rem",
              marginTop: "3rem",
              fontSize: "1.5rem",
            }}
          >
            Enter your BVN
          </h1>
          <p
            style={{
              color: "#5F738C",
              fontWeight: "500",
              fontSize: "0.8rem",
              letterSpacing: "0.3px",
            }}
          >
            Dail *565*0# to securely get your BVN from your network provider.
          </p>
          <form onSubmit={handleSubmit}>
            <InputText
              label={"BVN"}
              labelColor={
                !bvnValidationForm.bvn || isBvnValid(bvnValidationForm)
                  ? "black"
                  : process.env.REACT_APP_RED_COLOUR
              }
              border={`1px solid ${
                !bvnValidationForm.bvn || isBvnValid(bvnValidationForm)
                  ? "#DFE2E6"
                  : process.env.REACT_APP_RED_COLOUR
              }`}
              placeholder={"Enter BVN"}
              onChange={(e) => {
                updateFormField({
                  bvn: stripNonNumbers(e.target.value.trim()),
                });
              }}
              maxLength={11}
              value={bvnValidationForm.bvn}
            />

            <button
              disabled={!isFormValid() || isLoading}
              className="signupButton2"
              style={{
                marginTop: "1rem",
                textAlign: "center",
                color: "white",
                backgroundColor: "#00425f",
                width: "100%",
              }}
            >
              {" "}
              {isLoading ? "Processing..." : "Next"}{" "}
            </button>
            <button
              className="signupButton2"
              style={{
                marginTop: "1rem",
                textAlign: "center",
                color: "black",
                alignContent: "center",
              }}
              onClick={() => {
                history.push("/dashboard");
              }}
            >
              Continue Later
            </button>
          </form>
        </div>
      </main>
      <Modal
        show={showModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body style={{ padding: "2rem" }}>
          <div style={{ alignContent: "center", padding: "1rem" }}>
            <h1
              style={{
                fontWeight: "bold",
                fontSize: "1.5rem",
              }}
            >
              Face ID Verification
            </h1>
            <p
              style={{
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "20px",
                color: "#1F2937",
              }}
            >
              Ensure your camera is steady and your face fits into the shape.
            </p>
            <div style={textStyle}>
              {instructions.map((instruction, index) => (
                <span key={index} style={spanStyle}>
                  <div style={iconStyle}>{greenCheckIcon()}</div>
                  <p style={pStyle}>{instruction}</p>
                </span>
              ))}
            </div>

            <button
              onClick={navigateToFaceVerification}
              className="signupButton2"
              style={{
                marginTop: "2rem",
                textAlign: "center",
                color: "white",
                backgroundColor: "#00425f",
                width: "100%",
              }}
            >
              {" "}
              {isLoading ? "Processing..." : "Next"}{" "}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        closeButton
        show={showOtpModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body style={{ padding: "3rem" }}>
          <div style={{ alignContent: "center" }}>
            <form className="otp-conatiner">
              <div style={{ fontSize: "20px", fontWeight: 700 }}>Enter OTP</div>
              <p style={{ fontSize: "14px", color: "grey" }}>
                An OTP was sent to your BVN phone number, kindly enter it below
              </p>

              <div
                style={{
                  display: "flex",
                  gap: "7px",
                  alignItems: "center",
                  marginBottom: "-15px",
                }}
              >
                <div
                  style={{
                    width: "5rem",
                  }}
                >
                  <InputText
                    label={""}
                    placeholder={""}
                    value={`${tokenId?.prefix}`}
                    disabled={true}
                  />
                </div>
                <span
                  style={{
                    borderRadius: "5px",
                    marginTop: "7px",
                    borderBottom: "2px solid grey",
                    width: "10px",
                  }}
                />
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <InputText
                    label={""}
                    type="text"
                    maxLength={6}
                    placeholder={""}
                    onChange={handleOtpChange}
                    value={otp}
                  />
                </div>
              </div>

              <div>
                {isLoadingOtp ? (
                  <IosRefresh fontSize="20px" color="grey" rotate={true} />
                ) : (
                  <span
                    style={{ cursor: "pointer" }}
                    className="otp-resend"
                    onClick={callResendOtp}
                  >
                    Resend
                  </span>
                )}
              </div>

              <PrimaryButton
                margin={"20px 0 0 0"}
                disabled={otp.length < 6 || loading}
                onClick={onSubmitButtonClick}
              >
                {" "}
                {loading ? "Processing..." : "Submit"}
              </PrimaryButton>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BvnValidationScene;
