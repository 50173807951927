import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import MdCheckmarkCircle from 'react-ionicons/lib/MdCheckmarkCircle';
import { UpgradeArrowIcon } from "../../../../../icons";

const ProfileSetupCTA = ({
  title,
  sub,
  onClick,
  actionColor,
  isLoading,
  checkClickableTile,
  application,
}) => {
  const centerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  };

  const getCheckmarkColor = () => {
    if (actionColor && !isLoading) {
      return 'green';
    } else if (
      checkClickableTile === null ||
      checkClickableTile === undefined
    ) {
      return '#cacaca';
    }
    return 'grey';
  };
  // #1F2126
  const getColor = () => {
    if (checkClickableTile === null || checkClickableTile === undefined) {
      return '#cacaca';
    } else {
      return '#1F2126';
    }
  };

  return (
    <Col
      style={{
        padding: '1rem 1.5rem',
        borderRadius: '8px',
        border: `1px solid #D3D3D3`,
        marginBottom: '20px',
      }}
      onClick={onClick}
      data-testid='checkmark-icon'
    >
      <div style={centerStyle}>
        <div>
          <p
            style={{
              fontSize: '0.875rem',
              margin: 0,
              fontWeight: 600,
              color: '#1F2126',
              marginBottom: '10px',
            }}
          >
            {title}
          </p>
          <p
            style={{
              fontSize: '0.775rem',
              margin: 0,
              // color: getColor(),
              color: '#353F50',
            }}
          >
            {sub}
          </p>
        </div>
        {UpgradeArrowIcon()}
        {/* <div datat-testid='checkmark-icon'>
                    <MdCheckmarkCircle
            checkmark-icon
            color={getCheckmarkColor()}
            fontSize='20px'
          />
                </div> */}
      </div>
    </Col>
  );
};

export default ProfileSetupCTA;
