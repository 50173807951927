import React from "react";
import { Col, Row } from "react-bootstrap";
import Button from "../../../../../components/button";
import HR from "../../../../../components/hr";
import Text from "../../../../../components/text";
import { convertNgkToNgn } from "../../../../../utils/converters/currencies";
import { formatAmount } from "../../../../../utils/formatters";

export const SellAirtimeConfirmationTab = (props) => {
  //const extras = TransactionExtras;
  return (
    <>
      <HR style={{ marginTop: "5px", width: "100%" }} />

      <Row style={{ marginTop: "15px" }}>
        <Col
          md={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            Service
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {props.payment ? props.service.name : ""}
          </Text>
        </Col>
      </Row>

      <Row style={{ marginTop: "15px" }}>
        <Col
          md={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            Phone Number
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {props.payment ? props.payment.rechargeRequest.customerId : ""}
          </Text>
        </Col>
      </Row>

      {props.payment && props.payment.rechargeRequest.gender?.length > 0 && (
        <Row style={{ marginTop: "15px" }}>
          <Col
            md={12}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
              Gender
            </Text>
            <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
              {props.payment ? props.payment.rechargeRequest.gender : ""}
            </Text>
          </Col>
        </Row>
      )}

      <Row style={{ marginTop: "15px" }}>
        <Col
          md={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            Mobile Network
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {props.payment ? props.payment.rechargeRequest.narration : ""}
          </Text>
        </Col>
      </Row>

      <Row style={{ marginTop: "15px" }}>
        <Col
          md={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            }}
          >
            Commission Due
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              //fontWeight: "bold",
            }}
          >
            {props.payment
              ? `NGN${formatAmount(
                  convertNgkToNgn(
                    props.payment.rechargeRequest.agentCommissionDue
                  )
                )}`
              : "0.0"}
          </Text>
        </Col>
      </Row>

      <Row style={{ marginTop: "15px" }}>
        <Col
          md={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
              fontWeight: "bold",
            }}
          >
            Amount
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
              fontWeight: "bold",
            }}
          >
            {props.payment
              ? `NGN${formatAmount(
                  convertNgkToNgn(props.payment.rechargeRequest.amount)
                )}`
              : ""}
          </Text>
        </Col>
      </Row>

      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "30pt",
        }}
      >
        <Col
          xs={12}
          md={5}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            disabled={props.isLoading}
            style={{ width: "100%" }}
            onClick={props.onSellAirtimeConfirmation}
          >
            {props.isLoading ? "Processing..." : "Pay"}
          </Button>
        </Col>
        <Col xs={12} md={5}>
          <Button
            outline
            style={{
              backgroundColor: "transparent",
              marginTop: props.deviceIsMobile ? "10pt" : null,
              width: "100%",
              padding: "9pt 37pt",
            }}
            onClick={props.onCancelProduct}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </>
  );
};
