import React from 'react';
import { Card, Form } from 'react-bootstrap';
import H1 from '../../../../../../components/h1';

const AgentTypeCard = ({
  onClick,
  docType,
  buttonStyle,
  agentType,
  disabled,
  limit,
}) => {
  const handleDisable = () => {
    if (!buttonStyle?.disabled) {
      onClick();
    }
  };
  return (
    <section className='agent-type-card'>
      <div>
        <H1
          style={{
            fontSize: '1.5rem',
            fontWeight: '700',
            marginBottom: '15px',
            textAlign: 'start',
          }}
        >
          {agentType}
        </H1>
        <div
          style={{
            height: '5rem',
          }}
        >
          <div
            style={{
              marginLeft: '2rem',
            }}
          >
            <ul>
              {docType.map((item, index) => {
                return (
                  <li
                    style={{
                      marginBottom: '5px',
                    }}
                    key={index}
                  >
                    {item}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        <hr
          style={{
            margin: '20px 0',
          }}
        />
        {limit?.map((text) => {
          return (
            <div
              style={{
                marginBottom: '10px',
                display: 'flex',
                alignItems: 'center',
                gap: '20px',
              }}
            >
              <Form.Check
                type={'checkbox'}
                style={{
                  accentColor: '#00425F',
                }}
                checked
                id={`default-checkbox`}
              />
              <span>{text}</span>
            </div>
          );
        })}

        {buttonStyle?.text === 'remove button' ? (
          ''
        ) : (
          <button
            disabled={disabled}
            title={buttonStyle?.text}
            onClick={disabled ? undefined : () => handleDisable()}
            style={{
              display: 'flex',
              marginTop: '2rem',
              alignItems: 'center',
              border: buttonStyle?.border,
              outline: 'none',
              width: '100%',
              justifyContent: 'center',
              gap: '10px',
              padding: '1rem',
              borderRadius: '12px',
              backgroundColor: buttonStyle?.backgroundColor,
              cursor: 'pointer',
            }}
          >
            <div
              style={{
                color: buttonStyle?.color,
                fontSize: '17px',
              }}
            >
              {buttonStyle?.text}
            </div>
          </button>
        )}
      </div>
    </section>
  );
};

export default AgentTypeCard;
