import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getDateByType } from "../utils";
import { Form } from "react-bootstrap";
import { MAX_DESCRIPTION_LENGTH } from "../../../../../constants/form-fields";
import { subtractDays } from "../../../../../utils/time";

export const DatePickerComponent = (props) => {
  const {
    selected,
    onChange,
    disabled,
    format,

    showTimeInput,
    dateType,
    preloadDate,
    isClearable,
    size,
    fontWeight,
    textStyle,
    style,
    label,
    iconStyle,
    placeholder,
  } = props;
  const defaultDate = preloadDate ? getDateByType(dateType) : null;
  const [date, setDate] = useState(defaultDate);

  const DatePickerCustomInput = ({ value, onClick }) => {
    return (
      <div class="searchContainer" style={{ padding: style }}>
        <Form.Label
          style={{
            color: "black",
            fontWeight: fontWeight,
            fontSize: "14px",
          }}
        >
          {label}
        </Form.Label>
        <Form.Control
          required
          maxLength={MAX_DESCRIPTION_LENGTH}
          value={value}
          onClick={onClick}
          autoComplete="off"
          type="text"
          name="search"
          style={{
            width: size,
            paddingLeft: textStyle,
            backgroundColor: disabled ? "#E9ECEF" : "transparent",
          }}
          placeholder={placeholder}
        />
      </div>
    );
  };

  const MyContainer = ({ className, children }) => {
    return (
      <div
        style={{
          background: "#F0F0F0",
          fontSize: "14px",
          overflow: "auto",
          borderRadius: "10px",
          height: "100%",
          border: "1px solid grey",
        }}
      >
        <div style={{ position: "relative" }}>{children}</div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <DatePicker
        selected={selected}
        onChange={(event) => {
          setDate(event);
          onChange(event);
        }}
        disabled={disabled}
        calendarContainer={MyContainer}
        //fixedHeight
        customInput={<DatePickerCustomInput />}
        dateFormat={format}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        minDate={subtractDays(new Date(), 365 * 70)}
        maxDate={subtractDays(new Date(), 365 * 18)}
        // showTimeInput={showTimeInput}
        closeOnScroll={true}
        placeholder={placeholder}
        // showTimeSelect
        // timeFormat="HH:mm"
        //showTimeSelect
        //dropdownMode="select"
      />
    </React.Fragment>
  );
};
