import Requester from '../finch-requester';


const API_BASE_URL = process.env.REACT_APP_AUDIT_TRAIL_SERVICE_API_BASE_URL;


export default class Audit {
  
  constructor(props) {
    this.apiRequester = props ? props.apiRequester || new Requester({
      apiBaseUrl: API_BASE_URL
    }) : new Requester({
      apiBaseUrl: API_BASE_URL
    });
  }

  getAuditTrailById(id) {
    return this.apiRequester.get({
      endpoint: 'getById',
      headers: {
        id
      },
      // auth: false
    })
  }

  searchAuditTrail(startDate, pageNumber, pageSize, endDate, businessName, deviceId, auditAction) {
    return this.apiRequester.get({
      endpoint: '',
      headers: {
        pageStart: pageNumber,
        pageSize: pageSize,
        queryData: JSON.stringify({
          businessName,
          deviceId: deviceId,
          fromDate: startDate,
          toDate: endDate,
          auditAction
        })
      },
    })
  }

}