import React from 'react';

import { v4 as uuidv4 } from 'uuid';

import Button from './button';
import BaseScene from '../scenes/base-scene';


const acceptCookiesKey = 'qtpp-cookies';


export default class CookieConsent extends BaseScene {

  constructor(props) {
    super(props);

    this.state = {
      showCookiesNotification: false
    }

    this.acceptCookieHandler = this.acceptCookieHandler.bind(this);
    this.policyHandler = this.policyHandler.bind(this);
  }

  componentDidMount () {
    const value = localStorage.getItem(acceptCookiesKey);

    if ([null, 'null'].includes(value)) {
      this.setState({
        showCookiesNotification: true
      });
    }
    
  }

  policyHandler() {
    window.open(process.env.REACT_APP_PRIVACY_POLICY_URL);
  }

  acceptCookieHandler() {
    const uuid = `qtpp-${uuidv4()}`;
    this.setState({
      showCookiesNotification: false
    });
    localStorage.setItem(acceptCookiesKey, uuid)
  }

  render() {

    if (!this.state.showCookiesNotification) return null

    return (
      <div>
        <div 
          style={{ 
            position: 'fixed', 
            right: this.state.deviceIsPC && '36px', 
            bottom: '40px', 
            width: this.state.deviceIsPC && '450px', 
            margin: this.state.deviceIsMobile && '10px', 
            background: 'white', 
            boxShadow: '0 -2px 12px 7px rgba(0, 0, 0, 0.06)', 
            padding: '20px 10px', 
            boxSizing: 'border-box', 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            zIndex: '1000', 
            borderRadius: '8px' 
          }}
        >
          <div style={{ marginBottom: '10px' }}>
            <p 
              style={{ 
                fontSize: '12px', 
                margin: '0px', 
                textAlign: 'center'
              }}
            >
              {process.env.REACT_APP_COOKIE_POLICY_MESSAGE}
            </p>
          </div>
          <div 
            style={{
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <Button 
              style={{ 
                marginRight: '20px', 
                fontSize: '10px'
              }} 
              outline 
              onClick={this.policyHandler}
            >
              Read the privacy policy
            </Button>
            <Button 
              className='consent-button' 
              onClick={this.acceptCookieHandler}
            >
              I agree
            </Button>
          </div>
        </div>
      </div>
    )
  }
}
