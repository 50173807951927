import React from "react";
import { Col, Container, Row, Table, Button, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-table/react-table.css";
import Select from "react-select";
import { CSVLink } from "react-csv";
import MdSync from "react-ionicons/lib/MdSync";
import qs from "qs";

import BaseScene from "../../../base-scene";
import DateInputButton from "../../../../components/date-inputs/button";
import Text from "../../../../components/text";
import { FinchOneServiceType } from "../../../../fixtures/report_filter_options";
import HistoricalData from "../../../../services/api/resources/historical-data";
import {
  SUCCESS_STATUS,
  HTTP_NOT_FOUND_CODE,
  ERROR_STATUS,
} from "../../../../constants/api";
import HistoricalTransactionsTable from "./tables/historical-transactions-table";
import "./styles.scss";
import {
  specialDateRangeFormatter_StartDate,
  specialDateRangeFormatter_EndDate,
  formatDate,
  handleCustomerIdFormat,
  formatAmount,
  formatDateForDateRange,
  handleLongNumber,
} from "../../../../utils/formatters";
import ActivityIndicator from "../../../../components/activity-indicator";
import { convertNgkToNgn } from "../../../../utils/converters/currencies";

export default class HistoricalTransacctionsScene extends BaseScene {
  location = window.location.href;
  path = "/historical-transactions";
  title = "Historical Transactions";

  historicalData = new HistoricalData();

  TodaysDate = new Date();
  ThreeMonthsBefore = new Date(
    this.TodaysDate.getFullYear(),
    this.TodaysDate.getMonth() - 3
  );

  constructor(props) {
    super(props);

    this.state = {
      historicalData: [],
      filteredHistoricalData: [],
      endDate: this.TodaysDate,
      startDate: this.ThreeMonthsBefore,
      selectedOption: { label: "Select Transaction Type ", value: "0" },
      didErrorOccurWhileFetchingAllTransactions: false,
      pageNum: 1,
      pageSize: 50,
      deviceIsMobile:
        qs.parse(window.location.search)["?mobile"] || window.innerWidth <= 576,
      deviceIsPC:
        !qs.parse(window.location.search)["?mobile"] &&
        window.innerWidth >= 992,
      deviceIsTab:
        qs.parse(window.location.search)["?mobile"] || window.innerWidth <= 992,
    };
    this.getHistoricalTransactions = this.getHistoricalTransactions.bind(this);
    this.filterTransactionsByType = this.filterTransactionsByType.bind(this);
  }

  componentDidMount() {
    // this.getHistoricalTransactions(this.state.pageNum, this.state.pageSize, this.state.selectedOption.value, this.state.startDate, this.state.endDate)
  }

  componentWillMount() {
    this.getHistoricalTransactions(
      this.state.pageNum,
      this.state.pageSize,
      this.state.selectedOption.value,
      this.state.startDate,
      this.state.endDate
    );
  }

  async getHistoricalTransactions(
    pageNum,
    pageSize,
    serviceType_,
    startDate_,
    endDate_
  ) {
    this.setState({
      isLoading: true,
      pageNum,
      pageSize,
    });

    const serviceType = serviceType_;
    const startDate = startDate_ || this.state.startDate;
    const endDate = endDate_ || this.state.endDate;

    const formattedStartDate = formatDateForDateRange(startDate);
    const formattedEndDate = formatDateForDateRange(endDate);

    const sampleResponse = [
      {
        id: "134686577.0000000000",
        referenceId: "707MYHFDDXVK",
        dateCreated: "2018-05-23T07:59:56.681",
        status: "Successful",
        source: "707MYHFDDXVK",
        destination: "2348022074343",
        debitedAmount: "10100.0",
        creditedAmount: "10100.0",
        cashAmount: "10000.0",
        consumerSP: "IFIS",
        agentSA: "",
        agentSP: "",
        esbRef: "12931431527058795",
        externalRef: null,
        consumer: "",
        tokenType: "CASH",
        transactionType: "Money Transfer Inter SP",
        creator: "okechimso",
      },
    ];

    const { status, response, code } =
      await this.historicalData.getHistoricalTransactions(
        pageNum,
        pageSize,
        serviceType,
        formattedStartDate,
        formattedEndDate
      );

    if (status === SUCCESS_STATUS) {
      this.setState({
        historicalData: response.content,
        filteredHistoricalData: response.content,
        isLoading: false,
        pages: Math.ceil(response.count / pageSize),
        pageSize: pageSize,
        didErrorOccurWhileFetchingAllTransactions: false,
      });
    } else {
      this.setState({
        isLoading: false,
      });
      this.handleEmptyResponse(code);
    }
  }

  handleEmptyResponse = (code) => {
    this.setState({
      historicalData: [],
      filteredHistoricalData: [],
      pages: 1,
      isLoading: false,
    });
    if (code === HTTP_NOT_FOUND_CODE) {
      this.setState({
        didErrorOccurWhileFetchingAllTransactions: false,
      });
    } else {
      this.setState({
        didErrorOccurWhileFetchingAllTransactions: true,
      });
    }
  };

  handleSearch = (event) => {
    const { historicalData } = this.state;
    let search = event.target.value;

    let initialTransactions = historicalData;
    if (search !== "") {
      initialTransactions = initialTransactions.filter((transactions) => {
        return transactions.referenceId.toString().match(search);
      });
    } else {
      initialTransactions = historicalData;
    }

    this.setState({
      searchTerm: search,
      filteredHistoricalData: initialTransactions,
    });
  };

  setStartDate(value) {
    this.setState({
      startDate: value,
    });
    this.getHistoricalTransactions(
      this.state.pageNum,
      this.state.pageSize,
      null,
      value
    );
  }

  setEndDate(value) {
    this.setState({
      endDate: value,
    });
    this.getHistoricalTransactions(
      this.state.pageNum,
      this.state.pageSize,
      null,
      null,
      value
    );
  }

  filterTransactionsByType(selectedOption) {
    this.setState({
      selectedOption,
    });
    selectedOption.value === "0"
      ? this.getHistoricalTransactions(this.state.pageNum, this.state.pageSize)
      : this.getHistoricalTransactions(
          this.state.pageNum,
          this.state.pageSize,
          selectedOption.value,
          null,
          null
        );
  }

  renderRefreshTransactionMessage() {
    const { startDate, endDate, selectedOption, pageNum, pageSize } =
      this.state;
    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "top",
          marginTop: "24px",
          paddingBottom: "30px",
        }}
      >
        <Text blue bold small>
          Oops!
        </Text>
        <Text mid>Something went wrong. Please, try again.</Text>
        <div
          style={{
            alignItems: "center",
            border: `1pt solid ${process.env.REACT_APP_BLUE_COLOUR}`,
            borderRadius: "4px",
            color: process.env.REACT_APP_BLUE_COLOUR,
            cursor: "pointer",
            display: "flex",
            fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            fontStyle: process.env.REACT_APP_MID_FONT_SIZE,
            fontWeight: "bold",
            height: "40px",
            justifyContent: "center",
            marginTop: "16px",
            width: "160px",
          }}
          onClick={() =>
            this.getHistoricalTransactions(
              pageNum,
              pageSize,
              selectedOption.value,
              startDate,
              endDate
            )
          }
        >
          <MdSync
            rotate={this.state.isLoading}
            color={process.env.REACT_APP_BLUE_COLOUR}
            fontSize={"24px"}
            style={{ marginRight: "10px" }}
          />
          {this.state.isLoading ? "Loading..." : "RETRY"}
        </div>
      </div>
    );
  }

  render() {
    const csvFilteredTransaction = this.state.historicalData.map((trans) => ({
      id: trans.id,
      referenceId: trans.referenceId && handleLongNumber(trans.referenceId),
      dateModified: trans.dateModified && formatDate(trans.dateModified),
      status: trans.status && trans.status,
      source: trans.source && handleLongNumber(trans.source),
      destination: trans.destination && trans.destination,
      debitedAmount:
        trans.debitedAmount && `₦${formatAmount(trans.debitedAmount)}`,
      creditedAmount:
        trans.creditedAmount && `₦${formatAmount(trans.creditedAmount)}`,
      cashAmount: trans.cashAmount && `₦${formatAmount(trans.cashAmount)}`,
      consumerSP: trans.consumerSP && trans.consumerSP,
      // agentSA: trans.agentSA,
      // agentSP: trans.agentSP,
      esbRef: trans.esbRef && handleLongNumber(trans.esbRef),
      externalRef: trans.externalRef && trans.externalRef,
      consumer: trans.consummer && trans.consummer,
      tokenType: trans.tokenType && trans.tokenType,
      transactionType: trans.transactionType && trans.transactionType,
      // creator: trans.creator
    }));

    const csvHeader = [
      { label: "ID", key: "id" },
      { label: "Reference ID", key: "referenceId" },
      { label: "Last modification date", key: "dateModified" },
      { label: "Status", key: "status" },
      { label: "Transaction Type", key: "transactionType" },
      { label: "Source", key: "source" },
      { label: "Destination", key: "destination" },
      { label: "Debited Amount", key: "debitedAmount" },
      { label: "Credited Amount", key: "creditedAmount" },
      { label: "Cash Amount", key: "cashAmount" },
      { label: "Consumer SP name", key: "consumerSP" },
      { label: "Original ESB reference", key: "esbRef" },
      { label: "External Reference", key: "externalRef" },
      { label: "Token type", key: "tokenType" },
      { label: "Consumer name", key: "consumer" },
      // { label: 'Bank', key: 'tokenType' },
      // { label: 'Beneficiary Name', key: 'transactionType' },
      // { label: 'Sender Name', key: 'creator' },
    ];

    return (
      <div
        className={
          this.state.deviceIsMobile
            ? "container container-fluid reports-scene-container-mobile"
            : "reports-scene-container"
        }
      >
        <div className="h1 reports-scene-header">Historical Transactions</div>
        <Container
          fluid
          className="regular-container"
          style={{ padding: "10px" }}
        >
          <Row id="reports-scene-row">
            <Col
              xs={12}
              md={12}
              xl={12}
              id="services-report-container"
              className="card-with-shadow"
            >
              <Col
                md={12}
                className="card-with-shadow"
                id="transactions-table-container"
              >
                <div className="transactions-table-header">
                  <div className="search-container">
                    <input
                      className="search-input"
                      value={this.state.searchTerm}
                      onChange={this.handleSearch}
                      placeholder="GMPP Ref 7XO0973"
                    />
                  </div>
                  <Select
                    options={FinchOneServiceType}
                    onChange={this.filterTransactionsByType}
                    value={this.state.selectedOption}
                    className="select-input-container"
                    classNamePrefix="react-select"
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "rgb(178, 212, 255)",
                      },
                    })}
                  />
                  <div className="reports-scene-date-picker-container">
                    <DatePicker
                      selected={this.state.startDate}
                      onChange={(date) => this.setStartDate(date)}
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      dateFormat={`dd/MM/yyyy`}
                      showYearDropdown
                      customInput={<DateInputButton />}
                      maxDate={this.TodaysDate}
                      showTimeSelect
                    />
                    <Text
                      className="date-input-separator"
                      style={{ fontSize: "24px" }}
                    >
                      -
                    </Text>
                    <DatePicker
                      selected={this.state.endDate}
                      onChange={(date) => this.setEndDate(date)}
                      selectsEnd
                      endDate={this.state.endDate}
                      minDate={this.state.startDate}
                      maxDate={this.TodaysDate}
                      dateFormat={`dd/MM/yyyy`}
                      showYearDropdown
                      customInput={<DateInputButton />}
                      showTimeSelect
                    />
                  </div>
                  <div>
                    <Button className="download-link-button">
                      <CSVLink
                        data={csvFilteredTransaction}
                        filename="QuickTellerPaypoint Historical Transactions.csv"
                        className="download-text"
                        headers={csvHeader}
                      >
                        Download
                      </CSVLink>
                    </Button>
                  </div>
                </div>
                <div className="transactions-table-container">
                  {this.state.didErrorOccurWhileFetchingAllTransactions ? (
                    this.renderRefreshTransactionMessage()
                  ) : this.state.filteredHistoricalData.length > 0 ? (
                    <HistoricalTransactionsTable
                      data={this.state.filteredHistoricalData}
                      getAllTransactions={this.getHistoricalTransactions}
                      // getTransactionsByDateRange={this.getTransactionsByDateRange}
                      // filterTransactionsByType={this.filterTransactionsByType}
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      pages={this.state.pages}
                      pageSize={this.state.pageSize}
                      pageNum={this.state.pageNum}
                      loading={this.state.isLoading}
                      selectedOption={this.state.selectedOption}
                      // statusOption={this.state.statusOption}
                      dismissErrorMessage={this.props.dismissErrorMessage}
                      showErrorMessage={this.props.showErrorMessage}
                    />
                  ) : this.state.isLoading ? (
                    <p
                      style={{
                        textAlign: "center",
                        cursor: "pointer",
                        padding: "20px 0 20px 0",
                      }}
                    >
                      <ActivityIndicator />
                    </p>
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        color: process.env.REACT_APP_BLUE_COLOUR,
                        fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                        padding: "85px 0 85px 0",
                      }}
                    >
                      {"No record found, change filter"}
                    </p>
                  )}
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
