import Requester from "../finch-requester";

const API_BASE_URL = process.env.REACT_APP_USER_MANAGEMENT_API_BASE_URL;

export default class PosRequest {
  constructor(props) {
    this.apiRequester = props
      ? props.apiRequester ||
        new Requester({
          apiBaseUrl: API_BASE_URL,
        })
      : new Requester({
          apiBaseUrl: API_BASE_URL,
        });
  }

  initiatePosRequest(data) {
    return this.apiRequester.post({
      endpoint: "v1/finch/user-mgmt/pos-request",
      body: data,
    });
  }

  getPosTypes() {
    return this.apiRequester.get({
      endpoint: "v1/finch/user-mgmt/pos-stocks/pos-type",
    });
  }

  getPosModels(posType) {
    return this.apiRequester.get({
      endpoint: `v1/finch/user-mgmt/pos-stocks/pos-model/${posType}`,
    });
  }

  getPosUnitPrice(posModel) {
    return this.apiRequester.get({
      endpoint: `v1/finch/user-mgmt/pos-stocks/price/${posModel}`,
    });
  }

  posRequestSignatureUpload(file) {
    const formData = new FormData();
    formData.append("file", file);

    return this.apiRequester.post({
      endpoint: "v1/finch/user-mgmt/pos-request/upload",
      headers: {
        "content-type": "multipart/form-data",
      },
      body: formData,
    });
  }

  getPosRequests(pageSize, pageNum) {
    return this.apiRequester.get({
      endpoint: `v1/finch/user-mgmt/pos-request/search?pageSize=${pageSize}&pageNum=${pageNum}`,
    });
  }

  getPosRequestByRef(id) {
    return this.apiRequester.get({
      endpoint: `v1/finch/user-mgmt/pos-request/${id}`,
    });
  }

  getPosRemaps(pageSize, pageNum) {
    return this.apiRequester.get({
      endpoint: `v1/finch/user-mgmt/pos-remap-request/search?pageSize=${pageSize}&pageNum=${pageNum}`,
    });
  }

  declineApplication(application) {
    return this.apiRequester.put({
      endpoint: "approval",
      body: {
        applicationID: application.applicationId,
        approvalStatus: "3",
      },
    });
  }

  confirmPosDelivered(id) {
    return this.apiRequester.put({
      endpoint: `v1/finch/user-mgmt/pos-request/delivery/${id}`,
    });
  }
}
