import { NIP_TRANSACTION_API_BASE_URL } from "../../../utils/api-properties";
import Requester from "../finch-requester";

const API_BASE_URL = NIP_TRANSACTION_API_BASE_URL;

export default class NipTransaction {
  constructor(props) {
    this.apiRequester = props
      ? props.apiRequester ||
        new Requester({
          apiBaseUrl: API_BASE_URL,
        })
      : new Requester({
          apiBaseUrl: API_BASE_URL,
        });
  }

  ping() {
    return this.apiRequester.get({
      endpoint: "v2/finch-nip/actuator/health",
    });
  }

  validateAccountNumber(payload, deviceUuid) {
    return this.apiRequester.post({
      endpoint: `v3/finch-nip/payments/validate`,
      body: payload,
      headers: {
        deviceUuid,
      },
    });
  }

  initiateNIPTransfer(nipTransferRequest, checksum, deviceUuid) {
    return this.apiRequester.post({
      endpoint: "v2/finch-nip/payments/initialize",
      body: {
        checksum,
        transactionType: "NIP",
        nipTransferRequest,
      },
      headers: {
        deviceUuid,
      },
    });
  }

  processNIPTransfer(nipTransferRequest, deviceUuid, username) {
    return this.apiRequester.post({
      endpoint: "v2/finch-nip/payments/proceed",
      body: nipTransferRequest,
      headers: {
        deviceUuid,
      },
    });
  }

  getNipBanks(deviceUuid) {
    return this.apiRequester.get({
      endpoint: `v2/finch-nip/payments/v2/alias`,
      headers: {
        deviceUuid,
      },
    });
  }
}
