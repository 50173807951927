import React from "react";
export const Spacer = ({ height }) => {
  return (
    <div
      style={{
        height: height,
      }}
    />
  );
};

export const statusColor = (ennum) => {
  if (ennum === "processing") {
    return "#AF5F26";
  } else if (ennum === "delivered") {
    return "#36743D";
  } else if (ennum === "rejected") {
    return "#A4251A";
  }
};
export const statusBackgroundColor = (ennum) => {
  if (ennum === "processing") {
    return "#FEF6CF";
  } else if (ennum === "delivered") {
    return "#F1FEF1";
  } else if (ennum === "rejected") {
    return "#FBE9E9";
  }
};

export const statusBorderColor = (ennum) => {
  if (ennum === "processing") {
    return "#FDED94";
  } else if (ennum === "delivered") {
    return "#BEF2B9";
  } else if (ennum === "rejected") {
    return "#F4B7B5";
  }
};
export const Status = ({ value }) => {
  return (
    <div
      style={{
        color: statusColor(value),
        textAlign: "left",
        textTransform: "capitalize",

        display: "inline-flex",
        justifyContent: "center",

        border: `1px solid ${statusBorderColor(value)}`,
        padding: "0 7px",
        borderRadius: "5px",
        backgroundColor: statusBackgroundColor(value),
      }}
    >
      <div
        style={{
          display: "grid",
          placeContent: "center",
          marginRight: "5px",
        }}
      >
        <div
          style={{
            width: "7px",
            height: "7px",
            borderRadius: "100%",

            display: "grid",
            placeContent: "center",
            backgroundColor: statusColor(value),
          }}
        />
      </div>
      <div>{value}</div>
    </div>
  );
};
