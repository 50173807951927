import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "./button";
import { savePDF } from "@progress/kendo-react-pdf";
import "./vertically-centered-modal.scss";

const ref = React.createRef();

export default function VerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      style={{
        backdropFilter: "blur(10px)",
        backgroundColor: "rgba(0, 0, 0, 0.1)",
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={
        props.payment && !props.deviceIsMobile
          ? "web-custom-modal"
          : "mobile-custom-modal"
      }
    >
      {!props.receipt && (
        <Modal.Header closeButton className={props.payment && "custom-header"}>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.title}
          </Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>{props.body}</Modal.Body>
      {props.hideFooter ? (
        ""
      ) : (
        <Modal.Footer>
          <a
            href={`${process.env.REACT_APP_TERMS_AND_CONDITION}`}
            style={{
              fontSize: "8pt",
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button transparent className="mobile-header-signup-button">
              Click to download the detailed terms and conditions document here
            </Button>
          </a>

          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}

export function ReceiptModal(props) {
  const [canDownload, setCanDownload] = useState(false);
  useEffect(() => {
    if (canDownload) {
      savePage();
    }
  }, [canDownload]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.body}</Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            setCanDownload(true);
          }}
        >
          Save
        </Button>
        <Button onClick={printPage}>Print</Button>
        {/* <Button onClick="javascript:demoFromHTML()">Save</Button> */}
      </Modal.Footer>
    </Modal>
  );
}

const printPage = () => {
  let mywindow = window.open("", "PRINT", "height=400,width=600");

  mywindow.document.write(`<html><head><title> </title>`);
  mywindow.document.write(`</head><body >`);
  mywindow.document.write(document.getElementById("print-receipt").innerHTML);
  mywindow.document.write(`</body></html>`);

  mywindow.document.close();
  mywindow.focus();

  mywindow.print();
  //mywindow.download();

  return true;
};

const savePage = () => {
  savePDF(ref.current, {
    paperSize: "A4",
    fileName: "transaction-receipt",
    margin: 20,
    author: "Interswitch",
  });
};
