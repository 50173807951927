import React, { useEffect, useState } from "react";
import H1 from "../../../../../../../components/h1";
import qs from "qs";
import PrimaryButton from "./customButton";
import InputText from "./inputText";
import { Form, Modal } from "react-bootstrap";
import {
  firetoastCustomButton,
  notify,
} from "../../../../../../../components/extras";
import { formatAmount } from "../../../../../../../utils/formatters";
import AnalyticsService from "../../../../../../../services/api/resources/analytics-service";
import {
  convertNgkToNgn,
  convertNgnToNgk,
} from "../../../../../../../utils/converters/currencies";
import ActivityIndicator from "../../../../../../../components/activity-indicator";
import {
  ERROR_STATUS,
  SUCCESS_STATUS,
} from "../../../../../../../constants/api";
import { stripNonNumbers } from "../../../component/extras";
const Xcircle = "/assets/media/images/XCircle.png";

const Limits = ({
  value,
  valueAmount,
  count,
  countAmount,
  TransactionType,
  onClick,
}) => {
  const deviceIsMobile =
    qs.parse(window.location.search)["?mobile"] || window.innerWidth <= 576;
  return (
    <>
      <div
        style={{
          marginBottom: "1rem",
          padding: deviceIsMobile ? 0 : "0 1rem",
        }}
      >
        <>
          {deviceIsMobile && (
            <p
              style={{
                fontWeight: 400,
              }}
            >
              {TransactionType}
            </p>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {!deviceIsMobile && (
              <p
                style={{
                  fontWeight: 400,
                  width: "100px",
                }}
              >
                {TransactionType}
              </p>
            )}
            <div
              style={{
                marginLeft: deviceIsMobile ? "0" : "7rem",
              }}
            >
              <div
                style={{
                  fontWeight: 400,
                }}
              >
                <span
                  style={{
                    color: "#9CA3AF",
                  }}
                >
                  {value}
                </span>
                <p>{valueAmount}</p>
              </div>
              <div
                style={{
                  fontWeight: 400,
                }}
              >
                <span
                  style={{
                    color: "#9CA3AF",
                  }}
                >
                  {count}
                </span>
                <p>{countAmount}</p>
              </div>
            </div>
            <div
              style={{
                color: "#479FC8",
                cursor: "pointer",
              }}
              onClick={onClick}
            >
              Edit
            </div>
          </div>
        </>
      </div>
    </>
  );
};

const ModalComponent = ({
  toggleModal,
  showModal,
  handleSubmit,
  title,
  value,
  isLoading,
  count,
  onChangeAppylyAllService,
  onChangeValue,
  onChangeCount,
  applyToAll,
}) => {
  return (
    <Modal centered show={showModal}>
      <Modal.Body style={{ padding: "1.5rem" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "2rem",
          }}
        >
          <H1>{title}</H1>
          <img
            style={{
              cursor: "pointer",
            }}
            onClick={() => toggleModal()}
            src={Xcircle}
            alt="cancel"
          />
        </div>
        <div
          style={{
            marginBottom: "1rem",
          }}
        >
          <InputText
            onChange={onChangeValue}
            label={"Transaction Value"}
            value={`${value}`}
          />
        </div>

        <div
          style={{
            marginBottom: "1rem",
          }}
        >
          <InputText
            onChange={onChangeCount}
            type="number"
            label={"Transaction Count"}
            value={count}
          />
        </div>
        <Form.Check
          defaultChecked={applyToAll}
          type="checkbox"
          onChange={onChangeAppylyAllService}
          style={{
            backgroundColor: "#FFFFFF",
            //fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            fontSize: ".9rem",
            color: "#072F40",
            fontWeight: 400,

            margin: 0,
          }}
          label="Apply to other services"
        />
        <PrimaryButton
          disabled={isLoading}
          children={isLoading ? "Processing..." : "Apply Target"}
          onClick={handleSubmit}
        />
      </Modal.Body>
    </Modal>
  );
};

const TransactionTarget = () => {
  const deviceIsMobile =
    qs.parse(window.location.search)["?mobile"] || window.innerWidth <= 576;
  const analyticsService = new AnalyticsService();
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [isLoadingGet, setIsLoadingGet] = useState(false);
  const [isLoadingPost, setIsLoadingPost] = useState(false);
  const [transactionTarget, setTransactionTarget] = useState({
    airtime: null,
    bills: null,
    transfer: null,
    cashout: null,
  });

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const convertTransactionTypeToInteger = () => {
    const titleMapping = {
      "Airtime Target": 3,
      "Bills Payment Target": 1,
      "Transfer Target": 2,
      "Cash Out Target": 5,
    };

    return titleMapping[modalData?.title] || null;
  };

  const getTransactionTarget = async (showloading = true) => {
    showloading && setIsLoadingGet(true);
    const { response, status } = await analyticsService.getTransactionTarget();

    setIsLoadingGet(false);
    if (status === ERROR_STATUS) {
    } else if (status === SUCCESS_STATUS) {
      const airtime = response?.data.filter(
        (target) => target?.transactionType === 3
      );
      const bills = response?.data.filter(
        (target) => target?.transactionType === 1
      );
      const transfer = response?.data.filter(
        (target) => target?.transactionType === 2
      );
      const cashout = response?.data.filter(
        (target) => target?.transactionType === 5
      );

      setTransactionTarget({
        airtime: airtime?.[0],
        bills: bills?.[0],
        transfer: transfer?.[0],
        cashout: cashout?.[0],
      });
    }
  };

  const handleSubmit = async () => {
    const payload = {
      transactionType: convertTransactionTypeToInteger(),
      transactionCount: modalData?.count,
      transactionValue: convertNgnToNgk(modalData?.value),
      applyToAll: modalData?.applyToAll,
    };

    setIsLoadingPost(true);
    const { response, status } = await analyticsService.updateTransactionKpi(
      payload
    );
    setIsLoadingPost(false);
    if (status === ERROR_STATUS) {
      firetoastCustomButton("error", "", response.description, "Close");
      setShowModal(!showModal);
    } else if (status === SUCCESS_STATUS) {
      firetoastCustomButton(
        "success",
        modalData?.applyToAll ? "Transaction Target" : modalData?.title,

        modalData?.applyToAll
          ? `Your transaction target for all service types has been updated to ₦${formatAmount(
              modalData?.value
            )} and count to ${modalData?.count} for all your agents`
          : `Your ${modalData?.title} is been set to ₦${formatAmount(
              modalData?.value
            )} and count to ${modalData?.count} for your agents`,

        "Close"
      );
      getTransactionTarget(false);
      setShowModal(!showModal);
    }
  };

  useEffect(() => {
    getTransactionTarget();
  }, []);

  return (
    <article className="commission-section-container">
      <H1
        style={{
          marginBottom: "1.5rem",
          textAlign: "start",
        }}
      >
        Transaction Target
      </H1>
      {isLoadingGet ? (
        <div
          style={{
            padding: "90px 0px 90px 0",
            position: "relative",
          }}
        >
          <ActivityIndicator
            style={{
              height: "85px",
            }}
          />
        </div>
      ) : (
        <>
          <Limits
            onClick={() => {
              setModalData({
                title: "Airtime Target",
                value: convertNgkToNgn(
                  transactionTarget.airtime?.transactionValue
                ),
                count: transactionTarget.airtime?.transactionCount,
                applyToAll: false,
              });
              toggleModal();
            }}
            TransactionType={"Airtime"}
            value={"Transaction value"}
            valueAmount={
              transactionTarget.airtime
                ? `₦${formatAmount(
                    convertNgkToNgn(transactionTarget.airtime?.transactionValue)
                  )}`
                : formatAmount(0)
            }
            count={"Transaction count"}
            countAmount={transactionTarget.airtime?.transactionCount}
          />
          <Limits
            onClick={() => {
              setModalData({
                title: "Bills Payment Target",
                value: convertNgkToNgn(
                  transactionTarget.bills?.transactionValue
                ),
                count: transactionTarget.bills?.transactionCount,
                applyToAll: false,
              });
              toggleModal();
            }}
            TransactionType={"Bills Payment"}
            value={"Transaction value"}
            valueAmount={
              transactionTarget.bills
                ? `₦${formatAmount(
                    convertNgkToNgn(transactionTarget.bills?.transactionValue)
                  )}`
                : formatAmount(0)
            }
            count={"Transaction count"}
            countAmount={transactionTarget.bills?.transactionCount}
          />
          <Limits
            onClick={() => {
              setModalData({
                title: "Transfer Target",
                value: convertNgkToNgn(
                  transactionTarget.transfer?.transactionValue
                ),
                count: transactionTarget.transfer?.transactionCount,
                applyToAll: false,
              });
              toggleModal();
            }}
            TransactionType={"Transfer"}
            value={"Transaction value"}
            valueAmount={
              transactionTarget.transfer
                ? `₦${formatAmount(
                    convertNgkToNgn(
                      transactionTarget.transfer?.transactionValue
                    )
                  )}`
                : formatAmount(0)
            }
            count={"Transaction count"}
            countAmount={transactionTarget.transfer?.transactionCount}
          />
          <Limits
            onClick={() => {
              setModalData({
                title: "Cash Out Target",
                value: convertNgkToNgn(
                  transactionTarget.cashout?.transactionValue
                ),
                count: transactionTarget.cashout?.transactionCount,
                applyToAll: false,
              });
              toggleModal();
            }}
            TransactionType={"Cash Out"}
            value={"Transaction value"}
            valueAmount={
              transactionTarget.cashout
                ? `₦${formatAmount(
                    convertNgkToNgn(transactionTarget.cashout?.transactionValue)
                  )}`
                : formatAmount(0)
            }
            count={"Transaction count"}
            countAmount={transactionTarget.cashout?.transactionCount}
          />
        </>
      )}
      <ModalComponent
        title={`Set ${modalData?.title}`}
        isLoading={isLoadingPost}
        value={modalData?.value}
        count={modalData?.count}
        applyToAll={modalData?.applyToAll}
        toggleModal={toggleModal}
        showModal={showModal}
        onChangeValue={(e) =>
          setModalData({
            ...modalData,
            value: stripNonNumbers(e.target.value),
          })
        }
        onChangeCount={(e) =>
          setModalData({
            ...modalData,
            count: e.target.value,
          })
        }
        onChangeAppylyAllService={(e) =>
          setModalData({
            ...modalData,
            applyToAll: e.target.checked,
          })
        }
        handleSubmit={handleSubmit}
      />
    </article>
  );
};

export default TransactionTarget;
