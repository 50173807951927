import Requester from "../finch-requester";

const API_BASE_URL = process.env.REACT_APP_FINCH_LIQUIDITY_SERVICE_API_BASE_URL;

export default class Liquidity {
  constructor(props) {
    this.apiRequester = props
      ? props.apiRequester ||
        new Requester({
          apiBaseUrl: API_BASE_URL,
        })
      : new Requester({
          apiBaseUrl: API_BASE_URL,
        });
  }

  webpayPayment(amount, checksum, deviceUuid) {
    return this.apiRequester.post({
      endpoint: "v1/webpay/initialize",
      body: {
        amount,
        checksum,
      },
      headers: {
        deviceUuid,
      },
    });
  }

  proceesWebPayment(paymentRequest, deviceUuid) {
    return this.apiRequester.post({
      endpoint: "v1/webpay/proceed",
      body: paymentRequest,
      headers: {
        deviceUuid,
      },
    });
  }

  initiateWalletToWalletTransfer(transferRequest, deviceUuid) {
    return this.apiRequester.post({
      endpoint: "v1/wallet/funds/transfer",
      body: {
        ...transferRequest,
      },
      headers: {
        deviceUuid,
      },
    });
  }

  processWalletToWalletTransfer(transferRequest, deviceUuid) {
    return this.apiRequester.post({
      endpoint: "v1/wallet/funds/transfer",
      body: {
        ...transferRequest,
      },
      headers: {
        deviceUuid,
      },
    });
  }

  initiateDistribute(distributeFundRequest, deviceUuid) {
    return this.apiRequester.post({
      endpoint: "v1/wallet/funds/distribute",
      body: {
        ...distributeFundRequest,
      },
      headers: {
        deviceUuid,
      },
    });
  }

  processDistribute(distributeFundRequest, deviceUuid) {
    return this.apiRequester.post({
      endpoint: "v1/wallet/funds/distribute",
      body: {
        ...distributeFundRequest,
      },
      headers: {
        deviceUuid,
      },
    });
  }

  // fetchWithdrawalBanks() {
  //   return this.apiRequester.get({
  //     endpoint: 'cash-out/banks'
  //   })
  // }
  fetchWithdrawalBanks() {
    return this.apiRequester.get({
      endpoint: "",
    });
  }

  fetchUssdCashoutBanks() {
    return this.apiRequester.get({
      endpoint: "v1/cash-out/banks",
    });
  }

  initiateUssdWithdrawalRequest(withdrawalRequest, deviceUuid) {
    return this.apiRequester.post({
      endpoint: "v1/cash-out/initialize",
      body: {
        ...withdrawalRequest,
      },
      headers: {
        deviceUuid,
      },
    });
  }
  initiateFundWithUssdRequest(payload, deviceUuid) {
    return this.apiRequester.post({
      endpoint: "v2/webpay/ussd/initialize",
      body: {
        ...payload,
      },
      headers: {
        deviceUuid,
      },
    });
  }

  processUssdWithdrawal(withdrawalData, deviceUuid) {
    return this.apiRequester.post({
      endpoint: "v1/cash-out/proceed",
      body: {
        ...withdrawalData,
      },
      headers: {
        deviceUuid,
      },
    });
  }
  processFundUssdRequest(payload, deviceUuid) {
    return this.apiRequester.post({
      endpoint: "v2/webpay/ussd/proceed",
      body: {
        ...payload,
      },
      headers: {
        deviceUuid,
      },
    });
  }

  initiatePaycodeWithdrawalRequest(withdrawalRequest, deviceUuid) {
    return this.apiRequester.post({
      endpoint: "v1/cash-out/pay-code/initialize",
      body: {
        ...withdrawalRequest,
      },
      headers: {
        deviceUuid,
      },
    });
  }

  processPaycodeWithdrawal(withdrawalData, deviceUuid) {
    return this.apiRequester.post({
      endpoint: "v1/cash-out/pay-code/proceed",
      body: {
        ...withdrawalData,
      },
      headers: {
        deviceUuid,
      },
    });
  }
}
