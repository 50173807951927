import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Row, Col, Dropdown } from 'react-bootstrap';
//import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import MdMore from 'react-ionicons/lib/MdMore';
import MdEye from 'react-ionicons/lib/MdEye';
import Text from '../../../../../../components/text';
//import { convertNgkToNgn } from '../../../../../utils/converters/currencies';
import Spinner from '../../../../../../components/spinner';
import TransactionHistory from '../../../../../../services/api/resources/transaction-history';
import { SUCCESS_STATUS } from '../../../../../../constants/api';
import VerticallyCenteredModal from '../../../../../../components/vertically-centered-modal';
import { BILLER_BORNE } from '../../../../../../constants/transactions';
import {
  formatAmount,
  formatDate,
  formatDate2,
  handleStatusFormat,
  handleTransactionTypeFormat,
  handleCustomerIdFormat,
  formatDateTime,
  sentenceCapitalization,
} from '../../../../../../utils/formatters';
import ActivityIndicator from '../../../../../../components/activity-indicator';
import {
  updateLoading,
  dismissErrorMessage,
  showErrorMessage,
} from '../../../../../../services/redux/actions/tunnel';
import MdColorWand from 'react-ionicons/lib/MdColorWand';

import './styles.scss';
import { navigateTo } from '../../../../../../services/redux/actions/navigation';
import { getPaginationRows } from '../../../../../../utils/helpers';
class AgentTable extends React.Component {
  transactionHistory = new TransactionHistory();

  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      showReceipt: false,
      transactionDetails: null,
      isLoading: false,
    };
    this.searchByTransRef = this.searchByTransRef.bind(this);
  }

  componentDidMount() {
    localStorage.removeItem('lga');
    localStorage.removeItem('application');
    localStorage.removeItem('businessType');
    localStorage.removeItem('state');
    localStorage.removeItem('personalDetailsFormUpgrade');
    localStorage.removeItem('kycDetailsFormUpgrade');
    localStorage.removeItem('businessDetailsFormUpgrade');
  }

  async searchByTransRef(transRef) {
    this.setState({
      isSearchTransactionLoading: true,
    });
    const { status, code, response } =
      await this.transactionHistory.getTransactionsByRef(transRef);

    if (status === SUCCESS_STATUS) {
      this.setState({
        showModal: true,
        transactionDetails: response,
        isSearchTransactionLoading: false,
      });
    } else {
      this.setState({
        isSearchTransactionLoading: false,
      });
      this.props.showErrorMessage(JSON.stringify(response));
      setTimeout(() => {
        this.props.dismissErrorMessage();
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
    }
  }

  render() {
    const handleOpen = (props) => {
      // if(handleStatusFormat(props.original.statusCode) == "Failed") {
      //   this.getTransactionReversalDetails(props.original)
      // }
      this.setState({
        showModal: true,
        transactionDetails: props.original,
      });
    };

    const handleClose = () => {
      this.setState({
        showModal: false,
        showReceipt: false,
      });
    };

    const statusColor = (ennum) => {
      if (ennum === 'New') {
        return '#5F738C';
      } else if (ennum === 'Retire') {
        return '#A4251A';
      } else if (ennum === 'Suspend') {
        return '#AF5F26';
      } else if (ennum === 'Active') {
        return '#36743D';
      } else if (ennum === 'Format') {
        return '#AF5F26';
      }
    };
    const statusBackgroundColor = (ennum) => {
      if (ennum === 'New') {
        return '#F3F5F6';
      } else if (ennum === 'Retire') {
        return '#FBE9E9';
      } else if (ennum === 'Suspend') {
        return '#FEF6CF ';
      } else if (ennum === 'Active') {
        return '#F1FEF1';
      } else if (ennum === 'Dormat') {
        return '#FEF6CF';
      }
    };
    const statusBorderColor = (ennum) => {
      if (ennum === 'New') {
        return '#E1E6ED';
      } else if (ennum === 'Retire') {
        return '#F4B7B5';
      } else if (ennum === 'Suspend') {
        return ' #FDED94';
      } else if (ennum === 'Active') {
        return '#BEF2B9';
      } else if (ennum === 'Dormat') {
        return '#FDED94';
      }
    };
    const statusClass = (ennum) => {
      return '#353F50';
      // if (ennum === 'PRESTIGE') {
      //   return '#353F50';
      // } else {
      //   return '#AAB7C6';
      // }
    };
    const statusBackgroundClass = (ennum) => {
      return '#F3F5F6';
      // if (ennum === 'PRESTIGE') {
      //   return '#F3F5F6';
      // } else {
      //   return '#F3F5F6';
      // }
    };
    const statusBorderClass = (ennum) => {
      return '#E1E6ED';
      // if (ennum === 'PRESTIGE') {
      //   return '#E1E6ED';
      // } else {
      //   return '#F3F5F6';
      // }
    };

    const handleOpenManage = (props) => {
      localStorage.setItem('agentDetails', JSON.stringify(props.original));

      this.props.navigateTo(`/agents/my-agents/manage/${props.original.id}`);
    };
    const handleOpenUpgrade = (props) => {
      localStorage.setItem('agentDetails', JSON.stringify(props.original));

      this.props.navigateTo(`/agents/my-agents/upgrade/${props.original.id}`);
    };

    const transactionHistoryColumns = [
      {
        Header: (props) => <React.Fragment>AgentId</React.Fragment>,
        accessor: 'agentCode',
        headerClassName: 'table-header',
        Cell: (props) => <div>{props.value}</div>,
        style: { 'white-space': 'unset' },
        className: 'table-cell',
      },
      {
        Header: (props) => <React.Fragment>Phone Number</React.Fragment>,
        accessor: 'agentMobileNo',
        headerClassName: 'table-header',
        className: 'table-cell',
        Cell: (props) => (
          <div style={{ textAlign: 'left' }} className='number'>
            {handleCustomerIdFormat(props.value)}
          </div>
        ),
      },
      {
        Header: (props) => <React.Fragment>Business Name</React.Fragment>,
        accessor: 'businessName',
        headerClassName: 'table-header',
        className: 'table-cell',
        width: 350,
      },
      {
        Header: (props) => <React.Fragment>Agent Class</React.Fragment>,
        accessor: 'agentClass',
        headerClassName: 'table-header',
        className: 'table-cell',
        width: 170,
        Cell: (props) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'start',
            }}
          >
            <div
              style={{
                color: statusClass(props.value),
                textAlign: 'left',
                textTransform: 'capitalize',

                display: 'flex',
                justifyContent: 'center',

                border: `1px solid ${statusBorderClass(props.value)}`,
                padding: '0 7px',
                borderRadius: '5px',
                backgroundColor: statusBackgroundClass(props.value),
              }}
            >
              <div
                style={{
                  display: 'grid',
                  placeContent: 'center',
                  marginRight: '5px',
                }}
              >
                <div
                  style={{
                    width: '7px',
                    height: '7px',
                    borderRadius: '100%',

                    display: 'grid',
                    placeContent: 'center',
                    backgroundColor: statusClass(props.value),
                  }}
                />
              </div>
              <div>{props.value}</div>
            </div>
          </div>
        ),
      },
      {
        Header: (props) => <React.Fragment>Date Created</React.Fragment>,
        accessor: 'dateApplied',
        headerClassName: 'table-header',
        className: 'table-cell',
        Cell: (props) => (
          <div style={{ textAlign: 'left' }} className='number'>
            {formatDate2(props.value)}
          </div>
        ),
      },
      {
        Header: (props) => <React.Fragment>Last Modified</React.Fragment>,
        accessor: 'dateLastUpdated',
        headerClassName: 'table-header',
        className: 'table-cell',
        Cell: (props) => (
          <div style={{ textAlign: 'left' }} className='number'>
            {formatDate2(props.value)}
          </div>
        ),
      },
      {
        Header: (props) => <React.Fragment>Status</React.Fragment>,
        accessor: 'status',
        headerClassName: 'table-header',
        className: 'table-cell',
        Cell: (props) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'start',
            }}
          >
            <div
              style={{
                color: statusColor(props.value),
                textAlign: 'left',
                textTransform: 'capitalize',

                display: 'flex',
                justifyContent: 'center',

                border: `1px solid ${statusBorderColor(props.value)}`,
                padding: '0 7px',
                borderRadius: '5px',
                backgroundColor: statusBackgroundColor(props.value),
              }}
            >
              <div
                style={{
                  display: 'grid',
                  placeContent: 'center',
                  marginRight: '5px',
                }}
              >
                <div
                  style={{
                    width: '7px',
                    height: '7px',
                    borderRadius: '100%',

                    display: 'grid',
                    placeContent: 'center',
                    backgroundColor: statusColor(props.value),
                  }}
                />
              </div>
              <div>{props.value}</div>
            </div>
          </div>
        ),
      },
      {
        Header: (props) => <div style={{ textAlign: 'left' }}>Action</div>,
        Cell: (props) => (
          <>
            {process.env.REACT_APP_SHOW_AGGREGATOR && (
              <Dropdown>
                <Dropdown.Toggle
                  style={{
                    cursor: 'pointer',
                  }}
                  as={'span'}
                  variant='secondary'
                  id='dropdown-variants-secondary'
                >
                  <MdMore color={process.env.REACT_APP_GREY_COLOUR} />
                </Dropdown.Toggle>

                <Dropdown.Menu
                  variant='secondary'
                  id='dropdown-variants-secondary'
                >
                  <Dropdown.Item
                    style={{
                      padding: '5px',
                    }}
                    onClick={() => handleOpenManage(props)}
                  >
                    <Row
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <MdEye
                        fontSize='15px'
                        className='ml-4'
                        color={process.env.REACT_APP_GREY_COLOUR}
                      />

                      <div>Manage</div>
                    </Row>
                  </Dropdown.Item>
                  {props.original.agentClass !== 'PRESTIGE' &&
                    props.original.agentClass !== 'STANDARD' &&
                    props.original.status !== 'Retire' &&
                    props.original.status !== 'Suspend' && (
                      <Dropdown.Item
                        style={{
                          padding: '5px',
                        }}
                        onClick={() => handleOpenUpgrade(props)}
                      >
                        <Row
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                          }}
                        >
                          <MdColorWand
                            fontSize='15px'
                            className='ml-4'
                            color={process.env.REACT_APP_GREY_COLOUR}
                          />
                          <div>Upgrade</div>
                        </Row>
                      </Dropdown.Item>
                    )}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </>
        ),
        headerClassName: 'table-header',
        className: 'table-cell',
        width: 60,
      },
    ];

    return (
      <>
        {this.state.showModal && (
          <VerticallyCenteredModal
            onHide={handleClose}
            show={this.state.showModal}
            title='Agent Details'
            body={
              <TransactionModalBody
                showFee={true}
                showReceiptLogo={false}
                details={this.state.transactionDetails}
              />
            }
          />
        )}
        {this.state.isSearchTransactionLoading ? (
          <ActivityIndicator />
        ) : (
          <ReactTable
            columns={transactionHistoryColumns}
            data={this.props.data}
            pages={this.props.pages}
            dataCount={this.props.dataCount}
            pageSizes={getPaginationRows(this.props.dataCount)}
            defaultPageSize={50}
            manual
            onFetchData={(state, instance) =>
              this.props.getAllTransactions(
                state.page + 1,
                state.pageSize,
                this.props.agentStatus.value
              )
            }
            loading={this.props.loading}
            minRows={this.props.data.length > 0 ? 4 : 3}
            LoadingComponent={Spinner}
            getTdProps={(state, rowInfo, column, instance) => ({
              style: {
                overflow: 'visible',
                lineHeight: '20px',
                textAlign: 'left',
              },
            })}
            getTheadThProps={(state, rowInfo, column, instance) => ({
              style: {
                textAlign: 'left',
              },
            })}
          />
        )}
      </>
    );
  }
}

const TransactionModalBody = (props) => {
  return (
    <>
      <Row style={{ marginTop: '15px' }}>
        <Col
          md={12}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            AgentId:{' '}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            {props.details.agentCode}
          </Text>
        </Col>
      </Row>

      <Row style={{ marginTop: '15px' }}>
        <Col
          md={12}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            Phone Number:{' '}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            {handleCustomerIdFormat(props.details.agentMobileNo)}
          </Text>
        </Col>
      </Row>

      <hr />

      <Row style={{ marginTop: '15px' }}>
        <Col
          md={12}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            Business Name:{' '}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              textTransform: 'capitalize',
            }}
          >
            {props.details.businessName}
          </Text>
        </Col>
      </Row>

      <Row style={{ marginTop: '15px' }}>
        <Col
          md={12}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            Agent Class:{' '}
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {props.details.agentClass}
          </Text>
        </Col>
      </Row>

      <Row
        hidden={props.details.transactionType === 'W2W'}
        style={{ marginTop: '15px' }}
      >
        <Col
          md={12}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            Date Created:{' '}
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {formatDate2(props.details.dateApplied)}
          </Text>
        </Col>
      </Row>

      <Row
        hidden={props.details.transactionType === 'W2W'}
        style={{ marginTop: '15px' }}
      >
        <Col
          md={12}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            Last Modified:{' '}
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {formatDate2(props.details.dateLastUpdated)}
          </Text>
        </Col>
      </Row>

      <Row style={{ marginTop: '15px' }}>
        <Col
          md={12}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            Status:{' '}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              textTransform: 'capitalize',
            }}
            className={`${props.details.status.toLowerCase()}-status`}
          >
            {props.details.status}
          </Text>
        </Col>
      </Row>

      <Row style={{ marginTop: '15px' }}>
        <Col
          md={12}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            Company Registration Number:{' '}
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {props.details.companyRegNo}
          </Text>
        </Col>
      </Row>

      <Row style={{ marginTop: '15px' }}>
        <Col
          md={12}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            Creator:{' '}
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {props.details.createdBy}
          </Text>
        </Col>
      </Row>

      <Row style={{ marginTop: '15px' }}>
        <Col
          md={12}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            Account Number:{' '}
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {props.details.accountNo}
          </Text>
        </Col>
      </Row>

      <Row style={{ marginTop: '15px' }}>
        <Col
          md={12}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            Bank Name:{' '}
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {props.details.bankName}
          </Text>
        </Col>
      </Row>
    </>
  );
};

function mapStateToProps(state) {
  return {
    isLoading: state.tunnel.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateLoading: (isLoading) => dispatch(updateLoading(isLoading)),
    dismissErrorMessage: () => dispatch(dismissErrorMessage()),
    showErrorMessage: (data) => dispatch(showErrorMessage(data)),
    navigateTo: (pendingUrl) => dispatch(navigateTo(pendingUrl)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentTable);
