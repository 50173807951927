import React, { useEffect, useRef, useState } from "react";
import { Col, Form } from "react-bootstrap";
import H1 from "../../../../../../../components/form-label";
import PrimaryButton from "../component/button";
import InputText from "../component/input";
import SelectInput from "../component/select";
import BackButton from "../component/backButton";
import MdCloseCircle from 'react-ionicons/lib/MdCloseCircle';


import { firetoast, notify } from "../../../../../../../components/extras";
import {
  BUSINESS_DOC_SUFFIX,
  PASSPORT_PHOTO,
  PROOF_OF_ID,
  TEN_MB,
} from "../../../../../../../constants";
import {
  ERROR_STATUS,
  SUCCESS_STATUS,
} from "../../../../../../../constants/api";
import { MAX_IDENTIFICATION_NUMBER_LENGTH } from "../../../../../../../constants/form-fields";
import Onboarding from "../../../../../../../services/api/resources/onboarding";

import Lightbox from "react-image-lightbox";
import HeaderSignup from "../../../../../../../components/form-label";
import SignUp from "../component/signupText";
import Stepper from "../component/stepper";
import { isIdentificationNumberValid } from "../component/extras";
import Thumbnail4 from "../../../../../../../components/thumbnail-4";
import DocumentUploader from "../component/customeImage";

const meansOfId = [
  "Passport",
  "Drivers License",
  "International Passport",
  "Voter's Card",
  "National Identification Number",
];
const CANCELIMAGE = '/assets/media/images/cancel.png';
const KycDocumentScene = ({ setScreen, application }) => {
  const onboarding = new Onboarding();
  let applicationId = JSON.parse(localStorage.getItem('applicationId'));
  const [showLightboxPassport, setShowLightboxPassport] = useState(false);
  const [showLightboxId, setShowLightboxId] = useState(false);
  const [passportPhoto, setPassportPhoto] = useState(null);
  const [idCard, setIdCard] = useState(null);

  // const [kycDetailsForm, setKycDetailsForm] = useState({
    // meansOfId: application
    //   ? application?.applicantDetails?.identificationType
    //   : "",
    // idNumber: application
    //   ? application?.applicantDetails?.identificationNumber
    //   : "",
  // });

  // useEffect(() => {
  //   const updatedkycDetailsForm = JSON.parse(
  //     localStorage.getItem("kycDetailsForm")
  //   );

  //   if (updatedkycDetailsForm) {
  //     setKycDetailsForm({
  //       meansOfId:
  //         application?.applicantDetails?.identificationType ||
  //         updatedkycDetailsForm.meansOfId ||
  //         "",
  //       idNumber:
  //         application?.applicantDetails?.identificationNumber ||
  //         updatedkycDetailsForm.idNumber ||
  //         "",
  //     });
  //   }
  // }, [application]);

  // useEffect(() => {
  //   localStorage.setItem("kycDetailsForm", JSON.stringify(kycDetailsForm));
  // }, [kycDetailsForm]);

  const [isLoading, setIsLoading] = useState(false);

  // const updateFormField = (params) => {
  //   const newForm = {
  //     ...kycDetailsForm,
  //     ...params,
  //   };

  //   setKycDetailsForm(newForm);
  // };

  const handleSubmit = async () => {
    setIsLoading(true);
    const payload = {
      applicantDetails: {
        // identificationNumber: kycDetailsForm.idNumber,
        //identificationType: kycDetailsForm.meansOfId,
        identificationType:"Passport"
      },
      documents: [
        {
          documentType: PASSPORT_PHOTO,
          documentLink: passportPhoto,
        },
        ]
    };
    const { status, response } = await onboarding.putApplication(
      
      payload,
      application.applicationId,
      applicationId
    );


    setIsLoading(false);
    if (status === ERROR_STATUS) {
      firetoast("error", `${response.description}`, "");
    } else if (status === SUCCESS_STATUS) {
      notify("Saved", "success");
      setScreen(4);
    }
  };

  const [uploadError, setUploadError] = useState(false);
  const [error, setError] = useState(false);

  const getDocumentUrl = (document) => {
    let convertedDocument = document?.documentLink?.replace(
      "/data",
      process.env.REACT_APP_ONBOARDING_BASE_URL
    );
    return convertedDocument;
  };

  const [loadingPassportCancel, setLoadingPassportCancel] = useState(false);
  const [loadingIdCardCancel, setLoadingIdCardCancel] = useState(false);
  const [isLoadingDoc, setIsLoadingDoc] = useState(false);
  const [isLoadingPassport, setIsLoadingPassport] = useState(false);
  const [isLoadingIdCard, setIsLoadingIdCard] = useState(false);
  const [fileFormatPassport, setFileFormatPassport] = useState(null);
  const [fileFormatIdCard, setFileFormatIdCard] = useState(null);
  const getDocumentTypePassport = (file) => {
    const srcSplitted = file?.name
      ? file?.name?.split(".")
      : file?.documentName?.split(".");

    const slittedType = file
      ? srcSplitted[srcSplitted.length - 1].split("-")[0]
      : null;
    setFileFormatPassport(slittedType);
  };
  const getDocumentTypeIdCard = (file) => {
    const srcSplitted = file?.name
      ? file?.name?.split(".")
      : file?.documentName?.split(".");

    const slittedType = file
      ? srcSplitted[srcSplitted.length - 1].split("-")[0]
      : null;
    setFileFormatIdCard(slittedType);
  };
  const fetchApplicationDocument = async (showLoading = true) => {
    showLoading && setIsLoadingDoc(true);
    // applicationId
    const { status, response } =
      await onboarding.getDocumentsByApplicationAggregator(
        application.applicationId
      );
    setIsLoadingDoc(false);
    if (status === ERROR_STATUS) {
    } else if (status === SUCCESS_STATUS) {
      const passportPhotoDocuments = await response?.filter(
        (doc) => doc.documentType === "PASSPORT_PHOTO"
      );
      const idCardDocuments = await response?.filter(
        (doc) => doc.documentType === "ID_CARD"
      );

      getDocumentTypePassport(passportPhotoDocuments[0]);
      getDocumentTypeIdCard(idCardDocuments[0]);
      // setPassportPhotoId(passportPhotoDocuments[0]?.documentId);
      // setIdCardId(idCardDocuments[0]?.documentId);
      localStorage.setItem("idCardId", idCardDocuments[0]?.documentId);
      localStorage.setItem(
        "passportPhotoId",
        passportPhotoDocuments[0]?.documentId
      );
      setPassportPhoto(getDocumentUrl(passportPhotoDocuments[0]));
      setIdCard(getDocumentUrl(idCardDocuments[0]));
      localStorage.setItem(
        "passportPhoto",
        getDocumentUrl(passportPhotoDocuments[0])
      );
      localStorage.setItem("idCard", getDocumentUrl(idCardDocuments[0]));
    }
  };

  useEffect(() => {
    const storedImage = localStorage.getItem("passportPhoto");
    const storedID = localStorage.getItem("idCard");
    if (storedImage) {
      setPassportPhoto(storedImage);
    }
    if (storedID) {
      setIdCard(storedID);
    }

    fetchApplicationDocument();
  }, []);
  const fileInputRefPassportPhoto = useRef(null);
  const fileInputRefIdCard = useRef(null);

  const handleCancelPassport = async () => {
    setLoadingPassportCancel(true);
    await fetchApplicationDocument(false);
    const passportPhotoId = localStorage.getItem("passportPhotoId");
    const deleteDoc = await onboarding.deleteDocumentsByApplicationAggregator(
      passportPhotoId
    );
    setLoadingPassportCancel(false);
    if (deleteDoc.status === ERROR_STATUS) {
      notify(" Failed to remove.Try again", "error");
    } else {
      notify("Document removed", "success");
      setPassportPhoto(null);

      localStorage.removeItem("passportPhoto");
    }
  };

  // const handleCancelIdCard = async () => {
  //   setLoadingIdCardCancel(true);
  //   await fetchApplicationDocument(false);
  //   const idCardId = localStorage.getItem("idCardId");
  //   const deleteDoc = await onboarding.deleteDocumentsByApplicationAggregator(
  //     idCardId
  //   );
  //   setLoadingIdCardCancel(false);
  //   if (deleteDoc.status === ERROR_STATUS) {
  //     notify(" Failed to remove.Try again", "error");
  //   } else {
  //     notify("Document removed", "success");
  //     setIdCard(null);
  //     localStorage.removeItem("idCard");
  //   }
  // };

  const handleClickPassportPhoto = () => {
    fileInputRefPassportPhoto.current.click();
  };

  const handleClickIdCardPhoto = () => {
    fileInputRefIdCard.current.click();
  };

  const handlePassportChange = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    if (file.size > TEN_MB) {
      setError(true);

      return;
    } else {
      setIsLoadingPassport(true);
      const uploadDoc = await onboarding.documentUploadAggregator(
        application.applicationId,
        "PASSPORT_PHOTO",
        file
      );
      setIsLoadingPassport(false);
      if (uploadDoc.status === ERROR_STATUS) {
        setError(false);
        notify(" Failed to Upload.Try again", "error");
      } else {
        setError(false);

        getDocumentTypePassport(file);
        reader.onload = (e) => {
          const imageDataUrl = e.target.result;
          setPassportPhoto(imageDataUrl);

          localStorage.setItem("passportPhoto", imageDataUrl);
        };
        reader.readAsDataURL(file);
        notify("Document uploaded", "success");
        // fetchApplicationDocument(false);
      }
    }
  };

  // const handleIdCardChange = async (event) => {
  //   const file = event.target.files[0];
  //   const reader = new FileReader();

  //   if (file.size > TEN_MB) {
  //     setError(true);

  //     return;
  //   } else {
  //     setIsLoadingIdCard(true);
  //     const uploadDoc = await onboarding.documentUploadAggregator(
  //       application.applicationId,
  //       "ID_CARD",
  //       file
  //     );
  //     setIsLoadingIdCard(false);
  //     if (uploadDoc.status === ERROR_STATUS) {
  //       setError(false);
  //       notify(" Failed to Upload.Try again", "error");
  //     } else {
  //       setError(false);

  //       getDocumentTypeIdCard(file);
  //       reader.onload = (e) => {
  //         const imageDataUrl = e.target.result;
  //         setIdCard(imageDataUrl);

  //         localStorage.setItem("idCard", imageDataUrl);
  //       };
  //       reader.readAsDataURL(file);
  //       notify("Document uploaded", "success");
  //       // fetchApplicationDocument(false);
  //     }
  //   }
  // };

  const isFormValid = () => {
    return (
      // kycDetailsForm.meansOfId &&
      // kycDetailsForm.idNumber &&
      // isIdentificationNumberValid(kycDetailsForm) &&
      passportPhoto
//  idCard
    );
  };

  return (
    <>
      <BackButton
        onClick={() => {
          localStorage.removeItem("passportPhoto");
          localStorage.removeItem("idCard");
          setScreen(1);
        }}
      />
      <main className="onboarding-container">
        <Col
          style={{
            margin: "20px auto",
          }}
        >
          <SignUp />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              gap: "10px",
              marginBottom: "30px",
            }}
          >
            <Stepper borderColor="#00425F" />
            <Stepper borderColor="#00425F" />
            <Stepper />
            <Stepper />
          </div>
          <H1
            style={{
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "1rem",
              fontSize: "2rem",
              color: "#1F2937",
              // fontWeight: '800',
            }}
          >
            KYC Details
          </H1>
          {/* <SelectInput
            label={"Means of Identification"}
            onChange={(e) => {
              updateFormField({ meansOfId: e.target.value });
            }}
            placeholder={"means of identification"}
            value={kycDetailsForm.meansOfId}
            option={meansOfId}
          // disabled={application.applicationId}
          /> */}

          <br />

          {/* <InputText
            labelColor={
              !kycDetailsForm.idNumber ||
                isIdentificationNumberValid(kycDetailsForm)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${!kycDetailsForm.idNumber ||
                isIdentificationNumberValid(kycDetailsForm)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
              }`}
            onChange={(e) => {
              updateFormField({
                idNumber: e.target.value.trim().replace(/[^a-zA-Z0-9]/g, ""),
              });
            }}
            value={kycDetailsForm.idNumber}
            maxLength={MAX_IDENTIFICATION_NUMBER_LENGTH}
            label={"Enter ID Number"}
            placeholder={"Enter  Id number"}
          // disabled={application.applicationId}
          /> */}

          {/* <DocumentUploader
            label={"Upload ID"}
            loading={isLoadingDoc || isLoadingIdCard}
            loadingCancelDoc={loadingIdCardCancel}
            showLightbox={showLightboxId}
            fileFormat={fileFormatIdCard}
            state={idCard?.documentLink ? idCard?.documentLink : idCard}
            setShowLightbox={setShowLightboxId}
            onChange={handleIdCardChange}
            handleDocument={handleClickIdCardPhoto}
            fileInputRef={fileInputRefIdCard}
            handleCancel={handleCancelIdCard}
          /> */}

          <br />
          <DocumentUploader
            label={"Upload Passport Photograph"}
            loading={isLoadingDoc || isLoadingPassport}
            loadingCancelDoc={loadingPassportCancel}
            showLightbox={showLightboxPassport}
            fileFormat={fileFormatPassport}
            state={
              passportPhoto?.documentLink
                ? passportPhoto?.documentLink
                : passportPhoto
            }
            setShowLightbox={setShowLightboxPassport}
            onChange={handlePassportChange}
            handleDocument={handleClickPassportPhoto}
            fileInputRef={fileInputRefPassportPhoto}
            handleCancel={handleCancelPassport}
          />

          <br />
          {error && (
            <Col lg={12}>
              <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                File size is above 10MB
              </span>
            </Col>
          )}
          {uploadError && (
            <Col lg={12}>
              <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                Failed to Upload.Try again
              </span>
            </Col>
          )}
          {/* </Row> */}

          <PrimaryButton
            disabled={!isFormValid() || isLoading || isLoadingDoc}
            onClick={handleSubmit}
          >
            {isLoading ? "Processing..." : "Next"}
          </PrimaryButton>
        </Col>
      </main>
    </>
  );
};

export default KycDocumentScene;
