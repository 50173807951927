import React from "react";
import { Col, Collapse, Nav, Row } from "react-bootstrap";
import IosRadioOutline from "react-ionicons/lib/IosRadioOutline";
import MdBook from "react-ionicons/lib/MdBook";
import MdCard from "react-ionicons/lib/MdCard";
import MdDocument from "react-ionicons/lib/MdDocument";
import MdGrid from "react-ionicons/lib/MdGrid";
import MdHelp from "react-ionicons/lib/MdHelpCircle";
import MdListBox from "react-ionicons/lib/MdListBox";
import MdNotifications from "react-ionicons/lib/MdNotifications";
import MdPeople from "react-ionicons/lib/MdPeople";
import IosBarcodeOutline from "react-ionicons/lib/IosBarcodeOutline";
import MdPower from "react-ionicons/lib/MdPower";
import MdSettings from "react-ionicons/lib/MdSettings";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DownloadUp from "../icons/downloadUp.png";
import BaseScene from "../scenes/base-scene";
import { navigateTo } from "../services/redux/actions/navigation";
import VerticallyCenteredModal from "./vertically-centered-modal";

const CbnLogo = "/assets/media/images/cbn.png";

class NavBar extends BaseScene {
  state = {
    activeTab: "dashboard",
    openMyAccount: false,
    openManageDevice: false,
    openAgent: false,
    openPosMangementAggregator: false,
    openHelp: false,
    showModal: false,
    user: {},
  };

  tabs = [
    "dashboard",
    "commissions",
    "pos-management",
    "my-services",
    "services",
    "my-agents",
    "pos-management-aggregrator",
    "my-reports",
    "view-issues",
    "bank-network",
    "notifications",
    "wallet-journal",
    "historical-transactions",
    "manage-profile",
    "manage-devices",
    "applications",
    "setup-new-agent",
    "inventory",
    "my-pos-requests",
    "security-settings",
    "contact-us",
    "help-portal",
    "faq",
  ];
  myAccountTabs = ["manage-profile", "manage-devices", "security-settings"];
  agentTabs = ["applications", "setup-new-agent", "agent", "performance"];
  posManagementAggregatorTabs = ["inventory", "my-pos-requests"];
  manageDeviceTabs = ["my-devices", "device-audit-trail", "pos-management"];
  myHelpTabs = ["contact-us", "help-portal", "faq"];

  componentDidMount() {
    const splitted = window.location.pathname.split("/");

    const currentTab = this.tabs.find((value) => splitted.includes(value));
    const currentUser = JSON.parse(localStorage.getItem("currentUser") || "{}");

    this.setState({
      activeTab: currentTab,
      openMyAccount: this.myAccountTabs.includes(currentTab),
      openAgent: this.agentTabs.includes(currentTab),
      openPosMangementAggregator:
        this.posManagementAggregatorTabs.includes(currentTab),
      openManageDevice: this.manageDeviceTabs.includes(currentTab),
      openHelp: this.myHelpTabs.includes(currentTab),
      user: currentUser,
    });
  }

  render() {
    const handleModalOpen = () => {
      this.setState({
        showModal: true,
      });
    };

    const handleClose = () => {
      this.setState({
        showModal: false,
      });
    };

    const ContactUsBody = () => {
      return (
        <div>
          <ul style={{ listStyleType: "none" }}>
            <li>
              <img
                style={{ width: "20px", marginRight: "5px" }}
                src={"/assets/media/icons/land-phone.svg"}
                alt=""
              />
              <a href="tel:0700-9065000">0700-9065000</a>
            </li>
            <li>
              <img
                style={{ width: "20px", marginRight: "5px" }}
                src={"/assets/media/icons/mail.svg"}
                alt=""
              />
              <a href="mailto:ifiscustomercare@interswitchgroup.com">
                ifiscustomercare@interswitchgroup.com
              </a>
            </li>
          </ul>
        </div>
      );
    };

    const { disabled } = this.props;
    const myAccountPages = [
      "manage-devices",
      "manage-profile",
      "security-settings",
    ];
    const agentPages = ["applications", "setup-new-agent", "agent"];
    const posManagementAggregatorPages = ["inventory", "my-pos-prequest"];
    const manageDevicePages = [
      "my-devices",
      "device-audit-trail",
      "pos-management",
    ];
    const staffManagementPages = ["roles", "users"];

    const helpPages = ["contact-us", "help-portal", "faq"];
    return (
      <aside
        className="nav-bar"
        style={{
          alignItems: "space-between",
          display: "flex",
          flexDirection: "column",
          position: "fixed",
          height: "100vh",
          overflowX: "hidden",

          ...this.props.style,
        }}
      >
        <center>
          <img
            src={"/assets/media/logos/logo-white-red.svg"}
            style={{
              padding: "20px",
              paddingTop: "0px",
              paddingLeft: "13px",
              cursor: "pointer",
            }}
            height={"80pt"}
            alt=""
            onClick={() => this.props.navigateTo("/dashboard")}
          />
        </center>

        <Nav
          defaultActiveKey={`${process.env.REACT_APP_ROUTE_BASENAME}/dashboard`}
          className="flex-column"
          style={{
            paddingLeft: "7.5px",
            paddingRight: "7.5px",
          }}
        >
          <Link
            to={
              disabled
                ? null
                : `${process.env.REACT_APP_ROUTE_BASENAME}/dashboard`
            }
            className="nav-bar-link"
            onClick={() =>
              disabled
                ? null
                : this.setState({
                    activeTab: "dashboard",
                    openManageDevice: false,
                    openMyAccount: false,
                  })
            }
            onClickCapture={() => this.props.onClick()}
            style={{
              padding: "10px",
              paddingLeft: "15pt",
              fontSize: this.state.deviceIsPC
                ? process.env.REACT_APP_MID_FONT_SIZE
                : process.env.REACT_APP_MID_BIG_FONT_SIZE,
              fontWeight: this.state.deviceIsPC
                ? "bold"
                : this.state.activeTab === "dashboard"
                ? "bold"
                : "normal",
              marginTop: "5px",
              marginBottom: "5px",
              textDecoration: "none",
              cursor: disabled ? "not-allowed" : "pointer",
              borderRadius: "8px",
              backgroundColor:
                this.state.activeTab === "dashboard"
                  ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_BACKGROUND_COLOUR
                  : process.env
                      .REACT_APP_NAV_BAR_INACTIVE_ITEM_BACKGROUND_COLOUR,
              color:
                this.state.activeTab === "dashboard"
                  ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_COLOUR
                  : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR,
            }}
            href={`${process.env.REACT_APP_ROUTE_BASENAME}/dashboard`}
          >
            <MdGrid
              color={
                this.state.activeTab === "dashboard"
                  ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_COLOUR
                  : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR
              }
              fontSize="26px"
              style={{ marginRight: "10pt" }}
            />
            Dashboard
          </Link>

          <Link
            to={
              disabled
                ? null
                : `${process.env.REACT_APP_ROUTE_BASENAME}/my-services`
            }
            className="nav-bar-link"
            onClick={() =>
              disabled
                ? null
                : this.setState({
                    activeTab: "my-services",
                    openManageDevice: false,
                    openMyAccount: false,
                  })
            }
            onClickCapture={() => this.props.onClick()}
            style={{
              padding: "10px",
              paddingLeft: "15pt",
              fontSize: this.state.deviceIsPC
                ? process.env.REACT_APP_MID_FONT_SIZE
                : process.env.REACT_APP_MID_BIG_FONT_SIZE,
              fontWeight: this.state.deviceIsPC
                ? "bold"
                : this.state.activeTab === "my-services" ||
                  this.state.activeTab === "services"
                ? "bold"
                : "normal",
              marginTop: "5px",
              marginBottom: "5px",
              textDecoration: "none",
              cursor: disabled ? "not-allowed" : "pointer",
              borderRadius: "8px",
              backgroundColor:
                this.state.activeTab === "my-services" ||
                this.state.activeTab === "services"
                  ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_BACKGROUND_COLOUR
                  : process.env
                      .REACT_APP_NAV_BAR_INACTIVE_ITEM_BACKGROUND_COLOUR,
              color:
                this.state.activeTab === "my-services" ||
                this.state.activeTab === "services"
                  ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_COLOUR
                  : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR,
            }}
            href="/home"
            eventkey="link-1"
          >
            <MdCard
              color={
                this.state.activeTab === "my-services" ||
                this.state.activeTab === "services"
                  ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_COLOUR
                  : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR
              }
              fontSize="26px"
              style={{ marginRight: "10pt" }}
            />
            My Services
          </Link>

          {this.state.user && this.state.user.domainTypeId === 3 && (
            <>
              {process.env.REACT_APP_SHOW_COMMISSION === "true" && (
                <Link
                  to={
                    disabled
                      ? null
                      : `${process.env.REACT_APP_ROUTE_BASENAME}/commissions`
                  }
                  className="nav-bar-link"
                  onClick={() =>
                    disabled
                      ? null
                      : this.setState({
                          activeTab: "commissions",
                          openManageDevice: false,
                        })
                  }
                  onClickCapture={() => this.props.onClick()}
                  style={{
                    padding: "10px",
                    paddingLeft: "15pt",
                    fontSize: this.state.deviceIsPC
                      ? process.env.REACT_APP_MID_FONT_SIZE
                      : process.env.REACT_APP_MID_BIG_FONT_SIZE,
                    fontWeight: this.state.deviceIsPC
                      ? "bold"
                      : this.state.activeTab === "commissions"
                      ? "bold"
                      : "normal",
                    marginTop: "5px",
                    marginBottom: "5px",
                    textDecoration: "none",
                    cursor: disabled ? "not-allowed" : "pointer",
                    borderRadius: "8px",
                    backgroundColor:
                      this.state.activeTab === "commissions"
                        ? process.env
                            .REACT_APP_NAV_BAR_ACTIVE_ITEM_BACKGROUND_COLOUR
                        : process.env
                            .REACT_APP_NAV_BAR_INACTIVE_ITEM_BACKGROUND_COLOUR,
                    color:
                      this.state.activeTab === "commissions"
                        ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_COLOUR
                        : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR,
                  }}
                  href="/home"
                >
                  <img
                    alt="Commision icon"
                    src={DownloadUp}
                    style={{
                      marginRight: "10pt",
                      color:
                        this.state.activeTab === "commissions"
                          ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_COLOUR
                          : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR,
                    }}
                  />
                  Commission
                </Link>
              )}
              <Link
                // to={
                //   disabled
                //     ? null
                //     : `${process.env.REACT_APP_ROUTE_BASENAME}/my-agents`
                // }
                className="nav-bar-link"
                onClick={() =>
                  disabled
                    ? null
                    : this.setState({
                        openStaffManagement: false,
                        openHelp: false,
                        openMyAccount: false,
                        openAgent: !this.state.openAgent,
                      })
                }
                onClickCapture={() => this.props.onClick()}
                style={{
                  padding: "10px",
                  paddingLeft: "15pt",
                  fontSize: this.state.deviceIsPC
                    ? process.env.REACT_APP_MID_FONT_SIZE
                    : process.env.REACT_APP_MID_BIG_FONT_SIZE,
                  fontWeight: this.state.deviceIsPC
                    ? "bold"
                    : agentPages.includes(this.state.activeTab)
                    ? "bold"
                    : "normal",
                  marginTop: "5px",
                  marginBottom: "5px",
                  textDecoration: "none",
                  cursor: disabled ? "not-allowed" : "pointer",
                  borderRadius: "8px",
                  backgroundColor: agentPages.includes(this.state.activeTab)
                    ? process.env
                        .REACT_APP_NAV_BAR_ACTIVE_ITEM_BACKGROUND_COLOUR
                    : process.env
                        .REACT_APP_NAV_BAR_INACTIVE_ITEM_BACKGROUND_COLOUR,
                  color: agentPages.includes(this.state.activeTab)
                    ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_COLOUR
                    : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR,
                }}
                href="/home"
              >
                <MdPeople
                  color={
                    this.state.activeTab === "my-agents"
                      ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_COLOUR
                      : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR
                  }
                  fontSize="26px"
                  style={{ marginRight: "10pt" }}
                />
                Agents
              </Link>
              <Collapse in={this.state.openAgent} style={{ width: "100%" }}>
                <Row style={{ margin: 0 }}>
                  {process.env.REACT_APP_SHOW_AGGREGATOR && (
                    <>
                      <Col
                        md={12}
                        style={{ padding: "10px" }}
                        className="nav-bar-link"
                      >
                        <Link
                          className="nav-bar-link"
                          style={{
                            color:
                              this.state.activeTab === "setup-new-agent"
                                ? process.env
                                    .REACT_APP_NAV_BAR_ACTIVE_ITEM_BACKGROUND_COLOUR
                                : process.env
                                    .REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR,
                            fontSize: this.state.deviceIsPC
                              ? process.env.REACT_APP_MID_FONT_SIZE
                              : process.env.REACT_APP_MID_BIG_FONT_SIZE,
                            fontWeight:
                              this.state.activeTab === "setup-new-agent"
                                ? "bold"
                                : "normal",
                            paddingLeft: "30%",
                            textDecoration: "none",
                          }}
                          onClick={() => {
                            this.setState({
                              activeTab: "setup-new-agent",
                              openManageDevice: false,
                            });
                            this.props.onClick();
                          }}
                          to={`${process.env.REACT_APP_ROUTE_BASENAME}/agents/setup-new-agent`}
                        >
                          Setup New Agent
                        </Link>
                      </Col>
                      <Col
                        md={12}
                        style={{ padding: "10px" }}
                        className="nav-bar-link"
                      >
                        {/* <IosRadioButtonOn fontSize="30px" /> */}
                        <Link
                          className="nav-bar-link"
                          style={{
                            color:
                              this.state.activeTab === "applications"
                                ? process.env
                                    .REACT_APP_NAV_BAR_ACTIVE_ITEM_BACKGROUND_COLOUR
                                : process.env
                                    .REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR,
                            fontSize: this.state.deviceIsPC
                              ? process.env.REACT_APP_MID_FONT_SIZE
                              : process.env.REACT_APP_MID_BIG_FONT_SIZE,
                            fontWeight:
                              this.state.activeTab === "applications"
                                ? "bold"
                                : "normal",
                            paddingLeft: "30%",
                            textDecoration: "none",
                          }}
                          onClick={() => {
                            this.setState({
                              activeTab: "applications",
                              openManageDevice: false,
                            });
                            this.props.onClick();
                          }}
                          to={`${process.env.REACT_APP_ROUTE_BASENAME}/agents/applications`}
                        >
                          Pending Applications
                        </Link>
                      </Col>
                    </>
                  )}
                  <Col
                    md={12}
                    style={{ padding: "10px" }}
                    className="nav-bar-link"
                  >
                    <Link
                      className="nav-bar-link"
                      style={{
                        color:
                          this.state.activeTab === "agent"
                            ? process.env
                                .REACT_APP_NAV_BAR_ACTIVE_ITEM_BACKGROUND_COLOUR
                            : process.env
                                .REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR,
                        fontSize: this.state.deviceIsPC
                          ? process.env.REACT_APP_MID_FONT_SIZE
                          : process.env.REACT_APP_MID_BIG_FONT_SIZE,
                        fontWeight:
                          this.state.activeTab === "agent" ? "bold" : "normal",
                        paddingLeft: "30%",
                        textDecoration: "none",
                      }}
                      onClick={() => {
                        this.setState({
                          activeTab: "agent",
                          openManageDevice: false,
                        });
                        this.props.onClick();
                      }}
                      to={`${process.env.REACT_APP_ROUTE_BASENAME}/agents/my-agents`}
                    >
                      My Agents
                    </Link>
                  </Col>

                  {process.env.REACT_APP_SHOW_PERORMANCE === "true" && (
                    <Col
                      md={12}
                      style={{ padding: "10px" }}
                      className="nav-bar-link"
                    >
                      <Link
                        className="nav-bar-link"
                        style={{
                          color:
                            this.state.activeTab === "performance"
                              ? process.env
                                  .REACT_APP_NAV_BAR_ACTIVE_ITEM_BACKGROUND_COLOUR
                              : process.env
                                  .REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR,
                          fontSize: this.state.deviceIsPC
                            ? process.env.REACT_APP_MID_FONT_SIZE
                            : process.env.REACT_APP_MID_BIG_FONT_SIZE,
                          fontWeight:
                            this.state.activeTab === "performance"
                              ? "bold"
                              : "normal",
                          paddingLeft: "30%",
                          textDecoration: "none",
                        }}
                        onClick={() => {
                          this.setState({
                            activeTab: "performance",
                            openManageDevice: false,
                          });
                          this.props.onClick();
                        }}
                        to={`${process.env.REACT_APP_ROUTE_BASENAME}/agents/performance`}
                      >
                        Performance
                      </Link>
                    </Col>
                  )}
                  <Col md={12} style={{ marginTop: "20px" }}></Col>
                </Row>
              </Collapse>
              {process.env.REACT_APP_SHOW_POS_MANAGEMENT_AGGREGATOR ===
                "true" && (
                <Link
                  // to={
                  //   disabled
                  //     ? null
                  //     : `${process.env.REACT_APP_ROUTE_BASENAME}/my-agents`
                  // }
                  className="nav-bar-link"
                  onClick={() =>
                    disabled
                      ? null
                      : this.setState({
                          openStaffManagement: false,
                          openHelp: false,
                          openMyAccount: false,
                          openAgent: false,
                          openPosMangementAggregator:
                            !this.state.openPosMangementAggregator,
                        })
                  }
                  onClickCapture={() => this.props.onClick()}
                  style={{
                    padding: "10px",
                    paddingLeft: "15pt",
                    fontSize: this.state.deviceIsPC
                      ? process.env.REACT_APP_MID_FONT_SIZE
                      : process.env.REACT_APP_MID_BIG_FONT_SIZE,
                    fontWeight: this.state.deviceIsPC
                      ? "bold"
                      : posManagementAggregatorPages.includes(
                          this.state.activeTab
                        )
                      ? "bold"
                      : "normal",
                    marginTop: "5px",
                    marginBottom: "5px",
                    textDecoration: "none",
                    cursor: disabled ? "not-allowed" : "pointer",
                    borderRadius: "8px",
                    backgroundColor: posManagementAggregatorPages.includes(
                      this.state.activeTab
                    )
                      ? process.env
                          .REACT_APP_NAV_BAR_ACTIVE_ITEM_BACKGROUND_COLOUR
                      : process.env
                          .REACT_APP_NAV_BAR_INACTIVE_ITEM_BACKGROUND_COLOUR,
                    color: posManagementAggregatorPages.includes(
                      this.state.activeTab
                    )
                      ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_COLOUR
                      : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR,
                  }}
                  href="/home"
                >
                  <IosBarcodeOutline
                    color={
                      this.state.activeTab === "pos-management-aggregrator"
                        ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_COLOUR
                        : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR
                    }
                    fontSize="26px"
                    style={{ marginRight: "10pt" }}
                  />
                  POS Management
                </Link>
              )}
              <Collapse
                in={this.state.openPosMangementAggregator}
                style={{ width: "100%" }}
              >
                <Row style={{ margin: 0 }}>
                  {process.env.REACT_APP_SHOW_POS_MANAGEMENT_AGGREGATOR && (
                    <>
                      <Col
                        md={12}
                        style={{ padding: "10px" }}
                        className="nav-bar-link"
                      >
                        <Link
                          className="nav-bar-link"
                          style={{
                            color:
                              this.state.activeTab === "inventory"
                                ? process.env
                                    .REACT_APP_NAV_BAR_ACTIVE_ITEM_BACKGROUND_COLOUR
                                : process.env
                                    .REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR,
                            fontSize: this.state.deviceIsPC
                              ? process.env.REACT_APP_MID_FONT_SIZE
                              : process.env.REACT_APP_MID_BIG_FONT_SIZE,
                            fontWeight:
                              this.state.activeTab === "inventory"
                                ? "bold"
                                : "normal",
                            paddingLeft: "30%",
                            textDecoration: "none",
                          }}
                          onClick={() => {
                            this.setState({
                              activeTab: "inventory",
                            });
                            this.props.onClick();
                          }}
                          to={`${process.env.REACT_APP_ROUTE_BASENAME}/pos-management/aggregator/inventory`}
                        >
                          Inventory
                        </Link>
                      </Col>
                      <Col
                        md={12}
                        style={{ padding: "10px" }}
                        className="nav-bar-link"
                      >
                        {/* <IosRadioButtonOn fontSize="30px" /> */}
                        <Link
                          className="nav-bar-link"
                          style={{
                            color:
                              this.state.activeTab === "my-pos-requests"
                                ? process.env
                                    .REACT_APP_NAV_BAR_ACTIVE_ITEM_BACKGROUND_COLOUR
                                : process.env
                                    .REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR,
                            fontSize: this.state.deviceIsPC
                              ? process.env.REACT_APP_MID_FONT_SIZE
                              : process.env.REACT_APP_MID_BIG_FONT_SIZE,
                            fontWeight:
                              this.state.activeTab === "my-pos-requests"
                                ? "bold"
                                : "normal",
                            paddingLeft: "30%",
                            textDecoration: "none",
                          }}
                          onClick={() => {
                            this.setState({
                              activeTab: "my-pos-requests",
                            });
                            this.props.onClick();
                          }}
                          to={`${process.env.REACT_APP_ROUTE_BASENAME}/pos-management/aggregator/pos-request`}
                        >
                          My POS Requests
                        </Link>
                      </Col>
                    </>
                  )}
                </Row>
              </Collapse>
            </>
          )}

          <Link
            to={
              disabled
                ? null
                : `${process.env.REACT_APP_ROUTE_BASENAME}/my-reports`
            }
            className="nav-bar-link"
            onClick={() =>
              // this.setState({
              //               openStaffManagement: false,
              //               openHelp: false,
              //               openManageDevice: !this.state.openManageDevice,
              //             })

              disabled
                ? null
                : this.setState({
                    activeTab: "my-reports",
                    openManageDevice: false,
                    openMyAccount: false,
                  })
            }
            onClickCapture={() => this.props.onClick()}
            style={{
              padding: "10px",
              paddingLeft: "15pt",
              fontSize: this.state.deviceIsPC
                ? process.env.REACT_APP_MID_FONT_SIZE
                : process.env.REACT_APP_MID_BIG_FONT_SIZE,
              fontWeight: this.state.deviceIsPC
                ? "bold"
                : this.state.activeTab === "my-reports"
                ? "bold"
                : "normal",
              marginTop: "5px",
              marginBottom: "5px",
              textDecoration: "none",
              cursor: disabled ? "not-allowed" : "pointer",
              borderRadius: "8px",
              backgroundColor:
                this.state.activeTab === "my-reports"
                  ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_BACKGROUND_COLOUR
                  : process.env
                      .REACT_APP_NAV_BAR_INACTIVE_ITEM_BACKGROUND_COLOUR,
              color:
                this.state.activeTab === "my-reports"
                  ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_COLOUR
                  : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR,
            }}
            href="/home"
          >
            <MdDocument
              color={
                this.state.activeTab === "my-reports"
                  ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_COLOUR
                  : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR
              }
              fontSize="26px"
              style={{ marginRight: "10pt" }}
            />
            My Reports
          </Link>

          <Link
            to={
              disabled
                ? null
                : `${process.env.REACT_APP_ROUTE_BASENAME}/wallet-journal`
            }
            className="nav-bar-link"
            onClick={() =>
              disabled
                ? null
                : this.setState({
                    activeTab: "wallet-journal",
                    openManageDevice: false,
                  })
            }
            onClickCapture={() => this.props.onClick()}
            style={{
              padding: "10px",
              paddingLeft: "15pt",
              fontSize: this.state.deviceIsPC
                ? process.env.REACT_APP_MID_FONT_SIZE
                : process.env.REACT_APP_MID_BIG_FONT_SIZE,
              fontWeight: this.state.deviceIsPC
                ? "bold"
                : this.state.activeTab === "wallet-journal"
                ? "bold"
                : "normal",
              marginTop: "5px",
              marginBottom: "5px",
              textDecoration: "none",
              cursor: disabled ? "not-allowed" : "pointer",
              borderRadius: "8px",
              backgroundColor:
                this.state.activeTab === "wallet-journal"
                  ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_BACKGROUND_COLOUR
                  : process.env
                      .REACT_APP_NAV_BAR_INACTIVE_ITEM_BACKGROUND_COLOUR,
              color:
                this.state.activeTab === "wallet-journal"
                  ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_COLOUR
                  : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR,
            }}
            href="/home"
          >
            <MdBook
              color={
                this.state.activeTab === "wallet-journal"
                  ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_COLOUR
                  : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR
              }
              fontSize="26px"
              style={{ marginRight: "10pt" }}
            />
            Statement of Account
          </Link>

          <Link
            to={
              disabled
                ? null
                : `${process.env.REACT_APP_ROUTE_BASENAME}/notifications`
            }
            className="nav-bar-link"
            onClick={() =>
              disabled
                ? null
                : this.setState({
                    activeTab: "notifications",
                    openManageDevice: false,
                    openMyAccount: false,
                  })
            }
            onClickCapture={() => this.props.onClick()}
            style={{
              padding: "10px",
              paddingLeft: "15pt",
              fontSize: this.state.deviceIsPC
                ? process.env.REACT_APP_MID_FONT_SIZE
                : process.env.REACT_APP_MID_BIG_FONT_SIZE,
              fontWeight: this.state.deviceIsPC
                ? "bold"
                : this.state.activeTab === "notifications" ||
                  this.state.activeTab === "notifications"
                ? "bold"
                : "normal",
              marginTop: "5px",
              marginBottom: "5px",
              textDecoration: "none",
              cursor: disabled ? "not-allowed" : "pointer",
              borderRadius: "8px",
              backgroundColor:
                this.state.activeTab === "notifications"
                  ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_BACKGROUND_COLOUR
                  : process.env
                      .REACT_APP_NAV_BAR_INACTIVE_ITEM_BACKGROUND_COLOUR,
              color:
                this.state.activeTab === "notifications"
                  ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_COLOUR
                  : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR,
            }}
            href="/home"
            eventkey="link-1"
          >
            <MdNotifications
              color={
                this.state.activeTab === "notifications"
                  ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_COLOUR
                  : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR
              }
              fontSize="26px"
              style={{ marginRight: "10pt" }}
            />
            Notifications
          </Link>

          {JSON.parse(process.env.REACT_APP_NAV_MENU_SHOW_HISTORICAL_DATA) &&
            this.state.user &&
            this.state.user.domainTypeId !== 3 && (
              <Link
                to={
                  disabled
                    ? null
                    : `${process.env.REACT_APP_ROUTE_BASENAME}/historical-transactions`
                }
                className="nav-bar-link"
                onClick={() =>
                  disabled
                    ? null
                    : this.setState({
                        activeTab: "historical-transactions",
                        openManageDevice: false,
                        openMyAccount: false,
                      })
                }
                onClickCapture={() => this.props.onClick()}
                style={{
                  padding: "10px",
                  paddingLeft: "15pt",
                  fontSize: this.state.deviceIsPC
                    ? process.env.REACT_APP_MID_FONT_SIZE
                    : process.env.REACT_APP_MID_BIG_FONT_SIZE,
                  fontWeight: this.state.deviceIsPC
                    ? "bold"
                    : this.state.activeTab === "historical-transactions"
                    ? "bold"
                    : "normal",
                  marginTop: "5px",
                  marginBottom: "5px",
                  textDecoration: "none",
                  cursor: disabled ? "not-allowed" : "pointer",
                  borderRadius: "8px",
                  backgroundColor:
                    this.state.activeTab === "historical-transactions"
                      ? process.env
                          .REACT_APP_NAV_BAR_ACTIVE_ITEM_BACKGROUND_COLOUR
                      : process.env
                          .REACT_APP_NAV_BAR_INACTIVE_ITEM_BACKGROUND_COLOUR,
                  color:
                    this.state.activeTab === "historical-transactions"
                      ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_COLOUR
                      : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR,
                }}
                href="/home"
              >
                <MdListBox
                  color={
                    this.state.activeTab === "historical-transactions"
                      ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_COLOUR
                      : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR
                  }
                  fontSize="26px"
                  style={{ marginRight: "10pt" }}
                />
                Historical Transactions
              </Link>
            )}

          {process.env.REACT_APP_SHOW_BANK_NETWORK == "true" && (
            <Link
              to={
                disabled
                  ? null
                  : `${process.env.REACT_APP_ROUTE_BASENAME}/bank-network`
              }
              className="nav-bar-link"
              onClick={() =>
                disabled
                  ? null
                  : this.setState({
                      activeTab: "bank-network",
                      openManageDevice: false,
                    })
              }
              onClickCapture={() => this.props.onClick()}
              style={{
                padding: "10px",
                paddingLeft: "15pt",
                fontSize: this.state.deviceIsPC
                  ? process.env.REACT_APP_MID_FONT_SIZE
                  : process.env.REACT_APP_MID_BIG_FONT_SIZE,
                fontWeight: this.state.deviceIsPC
                  ? "bold"
                  : this.state.activeTab === "bank-network"
                  ? "bold"
                  : "normal",
                marginTop: "5px",
                marginBottom: "5px",
                textDecoration: "none",
                cursor: disabled ? "not-allowed" : "pointer",
                borderRadius: "8px",
                backgroundColor:
                  this.state.activeTab === "bank-network"
                    ? process.env
                        .REACT_APP_NAV_BAR_ACTIVE_ITEM_BACKGROUND_COLOUR
                    : process.env
                        .REACT_APP_NAV_BAR_INACTIVE_ITEM_BACKGROUND_COLOUR,
                color:
                  this.state.activeTab === "bank-network"
                    ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_COLOUR
                    : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR,
              }}
              href="/home"
            >
              <IosRadioOutline
                color={
                  this.state.activeTab === "bank-network"
                    ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_COLOUR
                    : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR
                }
                fontSize="26px"
                style={{ marginRight: "10pt" }}
              />
              Bank Network
            </Link>
          )}
          {process.env.REACT_APP_NAV_MENU_SHOW_COMPLAINT == "true" && (
            <Link
              to={
                disabled
                  ? null
                  : `${process.env.REACT_APP_ROUTE_BASENAME}/view-issues`
              }
              className="nav-bar-link"
              onClick={() =>
                // this.setState({
                //               openStaffManagement: false,
                //               openHelp: false,
                //               openManageDevice: !this.state.openManageDevice,
                //             })

                disabled
                  ? null
                  : this.setState({
                      activeTab: "view-issues",
                      openManageDevice: false,
                      openMyAccount: false,
                    })
              }
              onClickCapture={() => this.props.onClick()}
              style={{
                padding: "10px",
                paddingLeft: "15pt",
                fontSize: this.state.deviceIsPC
                  ? process.env.REACT_APP_MID_FONT_SIZE
                  : process.env.REACT_APP_MID_BIG_FONT_SIZE,
                fontWeight: this.state.deviceIsPC
                  ? "bold"
                  : this.state.activeTab === "view-issues"
                  ? "bold"
                  : "normal",
                marginTop: "5px",
                marginBottom: "5px",
                textDecoration: "none",
                cursor: disabled ? "not-allowed" : "pointer",
                borderRadius: "8px",
                backgroundColor:
                  this.state.activeTab === "view-issues"
                    ? process.env
                        .REACT_APP_NAV_BAR_ACTIVE_ITEM_BACKGROUND_COLOUR
                    : process.env
                        .REACT_APP_NAV_BAR_INACTIVE_ITEM_BACKGROUND_COLOUR,
                color:
                  this.state.activeTab === "view-issues"
                    ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_COLOUR
                    : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR,
              }}
              href="/home"
            >
              <MdDocument
                color={
                  this.state.activeTab === "view-issues"
                    ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_COLOUR
                    : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR
                }
                fontSize="26px"
                style={{ marginRight: "10pt" }}
              />
              Issue History
            </Link>
          )}
          {/* {process.env.REACT_APP_NAV_MENU_SHOW_MANAGE_STAFF && (
            <Link
              to={null}
              className='nav-bar-link'
              onClick={() =>
                disabled
                  ? null
                  : this.setState({
                      openMyAccount: false,
                      openManageDevice: false,
                      openStaffManagement: !this.state.openStaffManagement,
                    })
              }
              style={{
                padding: '10px',
                paddingLeft: '15pt',
                fontSize: this.state.deviceIsPC
                  ? process.env.REACT_APP_MID_FONT_SIZE
                  : process.env.REACT_APP_MID_BIG_FONT_SIZE,
                fontWeight: this.state.deviceIsPC
                  ? 'bold'
                  : staffManagementPages.includes(this.state.activeTab)
                  ? 'bold'
                  : 'normal',
                marginTop: '5px',
                marginBottom: '5px',
                textDecoration: 'none',
                cursor: disabled ? 'not-allowed' : 'pointer',
                borderRadius: '8px',
                backgroundColor: staffManagementPages.includes(
                  this.state.activeTab
                )
                  ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_BACKGROUND_COLOUR
                  : process.env
                      .REACT_APP_NAV_BAR_INACTIVE_ITEM_BACKGROUND_COLOUR,
                color: staffManagementPages.includes(this.state.activeTab)
                  ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_COLOUR
                  : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR,
              }}
              href='/home'
            >
              <MdContacts
                color={
                  staffManagementPages.includes(this.state.activeTab)
                    ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_COLOUR
                    : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR
                }
                fontSize='26px'
                style={{ marginRight: '10pt' }}
              />
              Issue History
            </Link>
          )} */}
          <Collapse
            in={this.state.openStaffManagement}
            style={{ width: "100%" }}
          >
            <Row style={{ margin: 0 }}>
              <Col md={12} style={{ padding: "10px" }} className="nav-bar-link">
                <Link
                  className="nav-bar-link"
                  style={{
                    color:
                      this.state.activeTab === "roles"
                        ? process.env
                            .REACT_APP_NAV_BAR_ACTIVE_ITEM_BACKGROUND_COLOUR
                        : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR,
                    fontSize: this.state.deviceIsPC
                      ? process.env.REACT_APP_MID_FONT_SIZE
                      : process.env.REACT_APP_MID_BIG_FONT_SIZE,
                    fontWeight:
                      this.state.activeTab === "roles" ? "bold" : "normal",
                    paddingLeft: "30%",
                    textDecoration: "none",
                  }}
                  onClick={() => {
                    this.setState({
                      activeTab: "roles",
                      openManageDevice: false,
                      openMyAccount: false,
                    });
                    this.props.onClick();
                  }}
                  to={`${process.env.REACT_APP_ROUTE_BASENAME}/roles`}
                >
                  Roles
                </Link>
              </Col>
              <Col md={12} style={{ padding: "10px" }} className="nav-bar-link">
                <Link
                  className="nav-bar-link"
                  style={{
                    color:
                      this.state.activeTab === "users"
                        ? process.env
                            .REACT_APP_NAV_BAR_ACTIVE_ITEM_BACKGROUND_COLOUR
                        : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR,
                    fontSize: this.state.deviceIsPC
                      ? process.env.REACT_APP_MID_FONT_SIZE
                      : process.env.REACT_APP_MID_BIG_FONT_SIZE,
                    fontWeight:
                      this.state.activeTab === "users" ? "bold" : "normal",
                    paddingLeft: "30%",
                    textDecoration: "none",
                  }}
                  onClick={() => {
                    this.setState({
                      activeTab: "users",
                      openManageDevice: false,
                      openMyAccount: false,
                    });
                    this.props.onClick();
                  }}
                  to={`${process.env.REACT_APP_ROUTE_BASENAME}/users`}
                >
                  Users
                </Link>
              </Col>
            </Row>
          </Collapse>

          <Link
            className="nav-bar-link"
            onClick={() =>
              disabled
                ? null
                : this.setState({
                    openStaffManagement: false,
                    openHelp: false,
                    openAgent: false,
                    openMyAccount: !this.state.openMyAccount,
                  })
            }
            style={{
              padding: "10px",
              paddingLeft: "15pt",
              fontSize: this.state.deviceIsPC
                ? process.env.REACT_APP_MID_FONT_SIZE
                : process.env.REACT_APP_MID_BIG_FONT_SIZE,
              fontWeight: this.state.deviceIsPC
                ? "bold"
                : myAccountPages.includes(this.state.activeTab)
                ? "bold"
                : "normal",
              marginTop: "5px",
              marginBottom: "5px",
              textDecoration: "none",
              cursor: disabled ? "not-allowed" : "pointer",
              borderRadius: "8px",
              backgroundColor: myAccountPages.includes(this.state.activeTab)
                ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_BACKGROUND_COLOUR
                : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_BACKGROUND_COLOUR,
              color: myAccountPages.includes(this.state.activeTab)
                ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_COLOUR
                : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR,
            }}
            // to="/home"
          >
            <MdSettings
              color={
                myAccountPages.includes(this.state.activeTab)
                  ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_COLOUR
                  : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR
              }
              fontSize="26px"
              style={{ marginRight: "10pt" }}
            />
            My Account
          </Link>

          <Collapse in={this.state.openMyAccount} style={{ width: "100%" }}>
            <Row style={{ margin: 0 }}>
              <Col
                md={12}
                style={{ marginTop: "5px", padding: "10px" }}
                className="nav-bar-link"
              >
                {/* <IosRadioButtonOn fontSize="30px" /> */}

                <Link
                  className="nav-bar-link"
                  style={{
                    color:
                      this.state.activeTab === "manage-profile"
                        ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_COLOUR
                        : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR,

                    fontSize: this.state.deviceIsPC
                      ? process.env.REACT_APP_MID_FONT_SIZE
                      : process.env.REACT_APP_MID_BIG_FONT_SIZE,
                    fontWeight:
                      this.state.activeTab === "manage-profile"
                        ? "bold"
                        : "normal",
                    paddingLeft: "30%",
                    textDecoration: "none",
                  }}
                  onClick={() => {
                    this.setState({
                      activeTab: "manage-profile",
                      openManageDevice: false,
                    });
                    this.props.onClick();
                  }}
                  to={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-profile`}
                >
                  Manage Profile
                </Link>
              </Col>
              {JSON.parse(
                process.env.REACT_APP_NAV_MENU_SHOW_MANAGE_DEVICE
              ) && (
                <Col
                  md={12}
                  style={{ padding: "10px" }}
                  className="nav-bar-link"
                >
                  <Link
                    className="nav-bar-link "
                    style={{
                      fontSize: this.state.deviceIsPC
                        ? process.env.REACT_APP_MID_FONT_SIZE
                        : process.env.REACT_APP_MID_BIG_FONT_SIZE,
                      fontWeight: this.state.deviceIsPC
                        ? "bold"
                        : manageDevicePages.includes(this.state.activeTab)
                        ? "bold"
                        : "normal",
                      marginTop: "5px",
                      marginBottom: "5px",
                      textDecoration: "none",
                      cursor: disabled ? "not-allowed" : "pointer",
                    }}
                    onClick={() =>
                      disabled
                        ? null
                        : this.setState({
                            openStaffManagement: false,
                            openHelp: false,
                            openManageDevice: !this.state.openManageDevice,
                          })
                    }
                  >
                    <div
                      style={{
                        backgroundColor: manageDevicePages.includes(
                          this.state.activeTab
                        )
                          ? process.env
                              .REACT_APP_NAV_BAR_ACTIVE_ITEM_BACKGROUND_COLOUR
                          : process.env
                              .REACT_APP_NAV_BAR_INACTIVE_ITEM_BACKGROUND_COLOUR,
                        color: myAccountPages.includes(this.state.activeTab)
                          ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_COLOUR
                          : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR,
                        borderRadius: "8px",
                      }}
                      className="manage-device"
                    >
                      Manage Devices
                    </div>
                  </Link>
                </Col>
              )}
              <Collapse
                in={this.state.openManageDevice}
                style={{ width: "100%" }}
              >
                <Row style={{ margin: 0 }}>
                  <Col
                    md={12}
                    style={{ marginLeft: "30px", marginBottom: "10px" }}
                    className="nav-bar-link"
                  >
                    <Link
                      className="nav-bar-link"
                      style={{
                        color:
                          this.state.activeTab === "my-devices"
                            ? process.env
                                .REACT_APP_NAV_BAR_ACTIVE_ITEM_BACKGROUND_COLOUR
                            : process.env
                                .REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR,
                        fontSize: this.state.deviceIsPC
                          ? process.env.REACT_APP_MID_FONT_SIZE
                          : process.env.REACT_APP_MID_BIG_FONT_SIZE,
                        fontWeight:
                          this.state.activeTab === "my-devices"
                            ? "bold"
                            : "normal",
                        paddingLeft: "30%",
                        textDecoration: "none",
                        borderRadius: "8px",
                      }}
                      onClick={() => {
                        this.setState({ activeTab: "my-devices" });
                        this.props.onClick();
                      }}
                      to={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-devices/my-device`}
                    >
                      My Devices
                    </Link>
                  </Col>

                  <Col
                    md={12}
                    style={{ marginLeft: "30px", marginBottom: "10px" }}
                    className="nav-bar-link"
                  >
                    <Link
                      className="nav-bar-link"
                      style={{
                        color:
                          this.state.activeTab === "device-audit-trail"
                            ? process.env
                                .REACT_APP_NAV_BAR_ACTIVE_ITEM_BACKGROUND_COLOUR
                            : process.env
                                .REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR,
                        fontSize: this.state.deviceIsPC
                          ? process.env.REACT_APP_MID_FONT_SIZE
                          : process.env.REACT_APP_MID_BIG_FONT_SIZE,
                        fontWeight:
                          this.state.activeTab === "device-audit-trail"
                            ? "bold"
                            : "normal",
                        paddingLeft: "30%",
                        textDecoration: "none",
                        borderRadius: "8px",
                      }}
                      onClick={() => {
                        this.setState({ activeTab: "device-audit-trail" });
                        this.props.onClick();
                      }}
                      to={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-devices/device-audit-trail`}
                    >
                      Device Audit Trail
                    </Link>
                  </Col>
                  {process.env.REACT_APP_SHOW_POS && (
                    <Col
                      md={12}
                      style={{ marginLeft: "30px", marginBottom: "15px" }}
                      className="nav-bar-link"
                    >
                      <Link
                        className="nav-bar-link"
                        style={{
                          color:
                            this.state.activeTab === "pos-management"
                              ? process.env
                                  .REACT_APP_NAV_BAR_ACTIVE_ITEM_BACKGROUND_COLOUR
                              : process.env
                                  .REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR,
                          fontSize: this.state.deviceIsPC
                            ? process.env.REACT_APP_MID_FONT_SIZE
                            : process.env.REACT_APP_MID_BIG_FONT_SIZE,
                          fontWeight:
                            this.state.activeTab === "pos-management"
                              ? "bold"
                              : "normal",
                          paddingLeft: "30%",
                          textDecoration: "none",
                          borderRadius: "8px",
                        }}
                        onClick={() => {
                          this.setState({ activeTab: "pos-management" });
                          this.props.onClick();
                        }}
                        to={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-devices/pos-management`}
                      >
                        Pos Management
                      </Link>
                    </Col>
                  )}
                </Row>
              </Collapse>
              <Col md={12} style={{ padding: "10px" }} className="nav-bar-link">
                <Link
                  className="nav-bar-link"
                  style={{
                    color:
                      this.state.activeTab === "security-settings"
                        ? process.env
                            .REACT_APP_NAV_BAR_ACTIVE_ITEM_BACKGROUND_COLOUR
                        : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR,
                    fontSize: this.state.deviceIsPC
                      ? process.env.REACT_APP_MID_FONT_SIZE
                      : process.env.REACT_APP_MID_BIG_FONT_SIZE,
                    fontWeight:
                      this.state.activeTab === "security-settings"
                        ? "bold"
                        : "normal",
                    paddingLeft: "30%",
                    textDecoration: "none",
                    borderRadius: "8px",
                  }}
                  onClick={() => {
                    this.setState({
                      activeTab: "security-settings",
                      openManageDevice: false,
                    });
                    this.props.onClick();
                  }}
                  to={`${process.env.REACT_APP_ROUTE_BASENAME}/security-settings`}
                >
                  Security Settings
                </Link>
              </Col>

              <Col md={12} style={{ marginTop: "20px" }}></Col>
            </Row>
          </Collapse>

          {this.state.showModal && (
            <VerticallyCenteredModal
              onHide={handleClose}
              show={this.state.showModal}
              title="Contact Us"
              hideFooter
              body={<ContactUsBody />}
            />
          )}
          <Link
            className="nav-bar-link"
            onClick={() => {
              this.setState({
                openStaffManagement: false,
                openMyAccount: false,
                openManageDevice: false,
                openHelp: !this.state.openHelp,
              });
            }}
            style={{
              padding: "10px",
              paddingLeft: "15pt",
              fontSize: this.state.deviceIsPC
                ? process.env.REACT_APP_MID_FONT_SIZE
                : process.env.REACT_APP_MID_BIG_FONT_SIZE,
              fontWeight: this.state.deviceIsPC
                ? "bold"
                : helpPages.includes(this.state.activeTab)
                ? "bold"
                : "normal",
              marginTop: "5px",
              marginBottom: "5px",
              textDecoration: "none",
              cursor: disabled ? "not-allowed" : "pointer",
              borderRadius: "8px",
              backgroundColor: helpPages.includes(this.state.activeTab)
                ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_BACKGROUND_COLOUR
                : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_BACKGROUND_COLOUR,
              color: helpPages.includes(this.state.activeTab)
                ? process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_COLOUR
                : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR,
            }}
            // to='/home'
          >
            <MdHelp
              color="white"
              fontSize="26px"
              style={{ marginRight: "10pt" }}
            />
            <strong>Help</strong>
          </Link>

          <Collapse in={this.state.openHelp} style={{ width: "100%" }}>
            <Row style={{ margin: 0 }}>
              <Col
                md={12}
                style={{ marginTop: "5px", padding: "10px" }}
                className="nav-bar-link"
              >
                <Link
                  className="nav-bar-link"
                  style={{
                    color:
                      this.state.activeTab === "contact-us"
                        ? process.env
                            .REACT_APP_NAV_BAR_ACTIVE_ITEM_BACKGROUND_COLOUR
                        : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR,
                    fontSize: this.state.deviceIsPC
                      ? process.env.REACT_APP_MID_FONT_SIZE
                      : process.env.REACT_APP_MID_BIG_FONT_SIZE,
                    fontWeight:
                      this.state.activeTab === "contact-us" ? "bold" : "normal",
                    paddingLeft: "30%",
                    textDecoration: "none",
                    borderRadius: "8px",
                  }}
                  to=""
                  // href={`${process.env.REACT_APP_ROUTE_BASENAME}/faq`}
                  onClick={() => handleModalOpen()}
                  // target="_blank"
                  // rel="noopener noreferrer"
                >
                  Contact Us
                </Link>
              </Col>

              <Col
                md={12}
                style={{ marginTop: "5px", padding: "10px" }}
                className="nav-bar-link"
              >
                <a
                  className="nav-bar-link"
                  style={{
                    color:
                      this.state.activeTab === "help-portal"
                        ? process.env
                            .REACT_APP_NAV_BAR_ACTIVE_ITEM_BACKGROUND_COLOUR
                        : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR,
                    fontSize: this.state.deviceIsPC
                      ? process.env.REACT_APP_MID_FONT_SIZE
                      : process.env.REACT_APP_MID_BIG_FONT_SIZE,
                    fontWeight:
                      this.state.activeTab === "help-portal"
                        ? "bold"
                        : "normal",
                    paddingLeft: "30%",
                    textDecoration: "none",
                    borderRadius: "8px",
                  }}
                  href={process.env.REACT_APP_HELP_PORTAL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit the help portal
                </a>
              </Col>

              <Col
                md={12}
                style={{ marginTop: "5px", padding: "10px" }}
                className="nav-bar-link"
              >
                <a
                  className="nav-bar-link"
                  style={{
                    color:
                      this.state.activeTab === "faq"
                        ? process.env
                            .REACT_APP_NAV_BAR_ACTIVE_ITEM_BACKGROUND_COLOUR
                        : process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR,
                    fontSize: this.state.deviceIsPC
                      ? process.env.REACT_APP_MID_FONT_SIZE
                      : process.env.REACT_APP_MID_BIG_FONT_SIZE,
                    fontWeight:
                      this.state.activeTab === "faq" ? "bold" : "normal",
                    paddingLeft: "30%",
                    textDecoration: "none",
                  }}
                  href={`${process.env.REACT_APP_ROUTE_BASENAME}/faq`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  See our FAQs
                </a>
              </Col>
            </Row>
          </Collapse>

          <div
            style={{
              display: "flex",
              borderRadius: "10px",
              padding: "20px",
              color: process.env.REACT_APP_NAV_BAR_ACTIVE_ITEM_COLOUR,
              background: process.env.REACT_APP_NAV_BAR_BACKGROUND_COLOUR,
              fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
              fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
              marginTop: "70px",
              maxWidth: "500px",
            }}
          >
            <div style={{ marginRight: "10px" }}>
              <img
                alt="cbn-logo"
                src={CbnLogo}
                style={{ zIndex: 3 }}
                width="90%"
              />
            </div>
            <span style={{ fontWeight: "normal" }}>
              Interswitch Financial Inclusion Services Limited is licensed by
              the Central Bank of Nigeria
            </span>
          </div>

          <a
            className="nav-bar-link"
            href={`${process.env.REACT_APP_ROUTE_BASENAME}/logout`}
            style={{
              // bottom: '0pt',
              top: this.state.deviceIsPC ? "94vh" : "87vh",
              color: process.env.REACT_APP_NAV_BAR_INACTIVE_ITEM_COLOUR,
              fontSize: this.state.deviceIsPC
                ? process.env.REACT_APP_MID_FONT_SIZE
                : process.env.REACT_APP_MID_BIG_FONT_SIZE,
              paddingLeft: "20pt",
              paddingBottom: "15px",
              // position: 'fixed',
              // bottom: '10px',
              marginTop: this.state.openManageDevice ? "40px" : "100px",
              textDecoration: "none",
              // verticalAlign: 'bottom',
              // marginTop: 'auto',
              borderRadius: "8px",
            }}
            onClick={() => {
              this.props.onClick();
            }}
            to={`logout`}
          >
            <MdPower
              color="white"
              fontSize="26px"
              style={{ marginRight: "10pt" }}
            />
            <strong>Logout</strong>
          </a>
        </Nav>
      </aside>
    );
  }
}

function mapStateToProps(state) {
  return {
    // isLoading: state.tunnel.isLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: (pendingUrl) => dispatch(navigateTo(pendingUrl)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
