import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ProductPayment from '../components/product-payment'
import { SELL_AIRTIME } from '../../../../../constants'
import BaseScene from '../../../../base-scene'

export default class SellAirtimeScene extends BaseScene {
  state = {
    
  }

  get service() {
    return {
      'name': 'Airtime Purchase',
    }
  }

  render() {
    const screenSensitiveStyle = this.state.deviceIsMobile ? {
      paddingRight: 0, 
      marginRight: 0
    } : { }

    return <Container fluid className="container">
      <Row style={{display: 'flex', justifyContent: 'center', margin: '0px', padding: '0px'}}>
        <Col xs={12} md={12} lg={12} style={{marginLeft: 0, paddingLeft: 0, ...screenSensitiveStyle}}>
          <ProductPayment 
            category={SELL_AIRTIME}
             service={this.service}
             onCancelProduct={() => this.props.onClose()}
             serviceTypeId={process.env.REACT_APP_AIRTIME_AND_DATA_ID}
          />
        </Col>
      </Row>
    </Container>
  }
}
