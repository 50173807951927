import React, { useState } from 'react';


const Stepper = ({ borderColor = "#D1D5DB" }) => {

    return (
        <div
            style={{
                height: 4,
                borderRadius: '5px',
                border: "none",
                marginTop: 30,
                backgroundColor: borderColor,
                width: "24%",
            }}
        />

    );
};

export default Stepper;




