import React from "react";
import { Button } from "react-bootstrap";

export default ({ value, onClick }) => (
  <Button
    style={{
      backgroundColor: "white",
      color: "black",
    }}
    className="date-input-button"
    onClick={onClick}
  >
    {value}
  </Button>
);
