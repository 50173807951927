import React from "react";
import { Form } from "react-bootstrap";
import MdSync from "react-ionicons/lib/MdSync";

const COLOUR = "grey";
const InputText = ({
  label,
  placeholder,
  value,
  maxLength,
  onChange,
  labelColor,
  disabled = false,
  type = "text",
  border,
  handlePaste,
  handleCopy,
  onBlur,
  flex,
}) => {
  return (
    <div
      style={{
        flex: flex,
      }}
    >
      <Form.Group
        style={{
          position: "relative",
        }}
        className="p-0 m-0"
        controlId="exampleForm.ControlInput1"
      >
        <Form.Label
          style={{
            color: labelColor,
            margin: 0,

            fontSize: "15px",
          }}
        >
          {label}{" "}
        </Form.Label>
        <Form.Control
          style={{
            border: border,
            backgroundColor: "#F3F5F6",
            outline: "none",
          }}
          onBlur={onBlur}
          onCopy={handleCopy}
          onPaste={handlePaste}
          onChange={onChange}
          maxLength={maxLength}
          disabled={disabled}
          value={value}
          required
          autoComplete="off"
          type={type}
          placeholder={placeholder}
        />
      </Form.Group>
    </div>
  );
};

export default InputText;
