import React from 'react';
import { Alert, Col, Container, Row, Button, Form } from 'react-bootstrap';
import 'react-table/react-table.css';
import qs from 'qs';
import { Redirect, Link } from 'react-router-dom';
import BaseScene from '../../../base-scene';
import { SUCCESS_STATUS, HTTP_NOT_FOUND_CODE } from '../../../../constants/api';
import AccountOpening from '../../../../services/api/resources/account-opening';

import Adboard from '../../../../components/adboard';
import FormControl from '../../../../components/form-control';


const MAX_SERIAL_NUMBER_LENGTH = 20;
const MAX_OTP_LENGTH = 6;

export default class CompleteDebitCardLinkScene extends BaseScene {

  location = window.location.href;
  path = '/complete-debit-card-link';
  title = 'Complete Debit Card Linking';

  accountOpening = new AccountOpening();
  constructor(props) {
    super(props);
    
    const requestId = localStorage.getItem("requestId");
    this.state = {
      cardLinkingData: [],
      cardSerialNumber:null,
      checkValidFields: 0,
      otp:null,
      isLoading:false,
      requestId,
      fieldsFocused: [],
      successMessage:null,
      errorMessage:null,
      goBack:false,
      deviceIsMobile: qs.parse(window.location.search)['?mobile'] || window.innerWidth <= 576,
      deviceIsPC: !qs.parse(window.location.search)['?mobile'] && window.innerWidth >= 992,
      deviceIsTab: qs.parse(window.location.search)['?mobile'] || window.innerWidth <= 992,
    };
    this.linkCard = this.linkCard.bind(this)
    this.resendOtp = this.resendOtp.bind(this)
   }

  handleGetAgentsEmptyResponse = (code) => {
    this.setState({
      agents: [],
      isAgentLoading: false
    })
    if (code === HTTP_NOT_FOUND_CODE) {
      this.setState({
        didErrorOccurWhileGettingAgents: false
      })
    } else {
      this.setState({
        didErrorOccurWhileGettingAgents: true
      })
    }
  }

  async linkCard() {
    this.setState({
      isLoading: true,
    });

    let serialNumber = this.state.cardSerialNumber;
    let otp = this.state.otp;

    const { status, response, code } = await this.accountOpening.linkCard({"cardSerialNumber":serialNumber, "otp":otp, "requestId":this.state.requestId})

    const cardLinkingResponse = response;

    if(status === SUCCESS_STATUS) {
      this.setState({
        cardLinkingData: cardLinkingResponse,
        isLoading: false,
        cardSerialNumber:"",
        otp:"",
        successMessage: "Card linked successfully",
        errorMessage: null
      })
    } else {
      this.handleEmptyResponse(cardLinkingResponse.description);
    }
  }

  async resendOtp() {
    this.setState({
      isLoading: true,
    });

    const { status, response, code } = await this.accountOpening.resendOtp(this.state.requestId)

    const cardLinkingResponse = response;

    if(status === SUCCESS_STATUS) {
      this.setState({
        isLoading: false,
        successMessage: "OTP Sent Successfully",
        errorMessage: null
      })
    } else {
      this.handleEmptyResponse(cardLinkingResponse.description);
    }
  }

  handleEmptyResponse = (description) => {
    this.setState({
      cardLinkingData: [],
      pages: 1,
      isLoading: false,
      errorMessage: description,
      successMessage:null
    })
  }

  
  updateCardSerialNumberField (params) {
   
    this.setState({cardSerialNumber:params})
    let count = 0
    if(params.length > 10){
      count = 1
    }
    if(this.state.otp)
    if(this.state.otp.length > 5){
      count += 1
    }
    this.setState({checkValidFields:count});
  }

  updateOtpField (params) {
   
    this.setState({otp:params})
    
    let count = 0
    if(this.state.cardSerialNumber)
    if(this.state.cardSerialNumber.length > 10){
      count = 1
    }
    if(params.length > 5){
      count += 1
    }
    this.setState({checkValidFields:count});
    
  }


  render () {
    if(this.state.goBack) {
      return <Redirect to="/agent/my-services/link-debit-card" />
    }
    return <div className={this.state.deviceIsMobile ? "container container-fluid reports-scene-container-mobile" : "reports-scene-container"}>
      <Row ><Col xs={9} md={9} xl={9}>
        <div className="h1 reports-scene-header">
          Complete Card Linking</div>
        <Container fluid className="regular-container" style={{ padding: '10px' }}>
            <Row id="reports-scene-row">
                <Col xs={12} md={12} xl={12} id="services-report-container" className="card-with-shadow">
                    <Col md={12} className="card-with-shadow" id="transactions-table-container">
                    
      <Form.Group controlId="cardSerialNumber">
        {this.state.errorMessage && <Alert variant="danger">{this.state.errorMessage}</Alert>}
        {this.state.successMessage && <Alert variant="success">{this.state.successMessage}</Alert>}
        <Form.Label style={{color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Card Serial Number: <span className="required-field-tick">*</span></Form.Label>
        <FormControl 
          autoComplete="off"
          value={this.state.cardSerialNumber} 
          maxLength={MAX_SERIAL_NUMBER_LENGTH} 
          type="text" 
          placeholder="Card Serial Number" 
          onBlur={() => this.setState({
            fieldsFocused: [...this.state.fieldsFocused, 'cardSerialNumber']
          })}
          onChange={(event) => {
            const cardSerialNumber = event.target.value
            this.updateCardSerialNumberField(cardSerialNumber)
          }} 
          maxLength={15} 
          style={{backgroundColor: '#FFFFFF', borderRadius: '20px', border: `1px solid '#DFE2E6'`, boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px'}} 
        />
      </Form.Group>

      <Form.Group controlId="otp">
        <Form.Label style={{color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Otp: <span className="required-field-tick">*</span></Form.Label>
        <FormControl 
          autoComplete="off"
          value={this.state.otp} 
          maxLength={MAX_OTP_LENGTH} 
          type="text" 
          placeholder="OTP Number" 
          onBlur={() => this.setState({
            fieldsFocused: [...this.state.fieldsFocused, 'otp']
          })}
          onChange={(event) => {
            const otp = event.target.value
            this.updateOtpField(otp)
          }} 
          maxLength={15} 
          style={{backgroundColor: '#FFFFFF', borderRadius: '20px', border: `1px solid '#DFE2E6'`, boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px'}} 
        />
      </Form.Group>

        <div id="resend-otp-link" style={{color: process.env.REACT_APP_LINK_COLOUR, fontSize: process.env.REACT_APP_MID_FONT_SIZE, margin: '10px 10px 10px 10px'}}>
          Didn't get OTP? <Link onClick={!this.state.isLoading?this.resendOtp:"#"} disabled={this.state.isLoading}>
          {this.state.isLoading ? 'Sending Otp...' : 'Resend OTP'}</Link>
        </div>


        <center style={{marginTop: '10px', marginBottom: '10px'}}>
          <Button outline onClick={() => {
          this.setState({'goBack':true})
        }} className="continue-later-pc-button-right" style={{marginRight: '100px', backgroundColor: '#9999aa'}}>Back</Button>
          <Button className="continue-later-pc-button-right" onClick={this.linkCard} disabled={this.state.checkValidFields <2 || this.state.isLoading}>
            {this.state.isLoading ? 'LOADING...' : 'SUBMIT'}
          </Button>
        </center>
        <br/><br/>




                    </Col>
                </Col>
                
            </Row>
        </Container>
        </Col>
        <Adboard />
        </Row>

    </div>
  }
}