import React, { useEffect } from 'react';

import H1 from '../../../../../../../components/h1';
import SkeletonLoading from '../../../component/skeletonLoading';
import ProfileSetupCTA from '../../../component/profileSetupCTA';
import BackButton from '../../../component/backButton';
import PrimaryButton from '../../../component/button';

const SetupNewAgentScene = ({
  setScreen,
  props,
  application,
  fetchApplication,
  isLoading,
}) => {
  const agentDetails = JSON.parse(localStorage.getItem('agentDetails'));

  const upgradeTo = localStorage.getItem('upgradeTo');

  let personalDetailsForm = JSON.parse(
    localStorage.getItem('personalDetailsFormUpgrade')
  );

  let kycDetailsForm = JSON.parse(
    localStorage.getItem('kycDetailsFormUpgrade')
  );
  let businessDetailsForm = JSON.parse(
    localStorage.getItem('businessDetailsFormUpgrade')
  );

  const handleKYC = () => {
    if (
      personalDetailsForm?.motherMaidenName &&
      businessDetailsForm.accountNumber
    ) {
      setScreen(4);
    }
  };
  const handleBussinessDetails = () => {
    if (personalDetailsForm?.motherMaidenName) {
      setScreen(3);
    }
  };

  useEffect(() => {
    if (agentDetails && !personalDetailsForm) {
      fetchApplication();
    }
  }, []);

  const handleSubmit = () => {
    if (
      personalDetailsForm?.motherMaidenName &&
      businessDetailsForm?.accountNumber
    ) {
      setScreen(4);
    } else if (personalDetailsForm?.motherMaidenName) {
      setScreen(3);
    } else {
      setScreen(2);
    }
  };

  const handleBackButton = () => {
    if (application) {
      props.navigateTo(`/agents/my-agents/upgrade/upgradeClass`);

      localStorage.removeItem('application');
      localStorage.removeItem('businessType');
      localStorage.removeItem('personalDetailsFormUpgrade');
      localStorage.removeItem('kycDetailsFormUpgrade');
      localStorage.removeItem('businessDetailsFormUpgrade');
    } else {
      props.navigateTo(`/agents/my-agents/upgrade/upgradeClass`);
    }
  };

  const Loading = () => {
    return (
      <div
        style={{
          marginBottom: '25px',
        }}
      >
        {' '}
        <SkeletonLoading width={'100%'} height={'15px'} />{' '}
        <SkeletonLoading width={'100%'} height={'15px'} />
      </div>
    );
  };

  return (
    <>
      <BackButton onClick={handleBackButton} />
      <main className='aggregator-container'>
        <div>
          <H1
            style={{
              textAlign: 'left',
              marginBottom: '1rem',
            }}
          >
            Upgrade Agent to {upgradeTo}
          </H1>
          <p
            style={{
              color: '#6B7280',
              fontSize: '0.75rem',
              marginBottom: '3rem',
            }}
          >
            Kindly complete the required fields
          </p>
        </div>

        {isLoading ? (
          <Loading />
        ) : (
          <ProfileSetupCTA
            application={application}
            onClick={() => setScreen(2)}
            actionColor={
              personalDetailsForm
                ? personalDetailsForm?.motherMaidenName
                : false
            }
            title={'Personal Details'}
            checkClickableTile={
              personalDetailsForm ? personalDetailsForm?.motherMaidenName : true
            }
            isLoading={isLoading}
            sub={`Update agent's personal details.`}
          />
        )}

        {isLoading ? (
          <Loading />
        ) : (
          <ProfileSetupCTA
            application={application}
            actionColor={
              businessDetailsForm ? businessDetailsForm.accountNumber : false
            }
            onClick={() => handleBussinessDetails()}
            checkClickableTile={
              personalDetailsForm ? personalDetailsForm?.motherMaidenName : null
            }
            title={'Business Details'}
            isLoading={isLoading}
            sub={`Update agent's business details.`}
          />
        )}

        {isLoading ? (
          <Loading />
        ) : (
          <ProfileSetupCTA
            application={application}
            onClick={() => handleKYC()}
            // onClick={application ? handleKYCApplicationId : handleKYC}
            actionColor={
              kycDetailsForm ? kycDetailsForm.attachedDocuments : false
            }
            title={'KYC Information'}
            checkClickableTile={
              businessDetailsForm ? businessDetailsForm.accountNumber : null
            }
            isLoading={isLoading}
            sub={`Upload neccessary documents.`}
          />
        )}

        <div
          style={{
            margin: '0 auto',
          }}
        >
          <PrimaryButton disabled={isLoading} onClick={handleSubmit}>
            Continue
          </PrimaryButton>
        </div>
      </main>
    </>
  );
};

export default SetupNewAgentScene;
