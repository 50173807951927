import React, { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";
import H1 from "../../../../components/h1";
import BackButton from "../component/backButton";

import { firetoast, firetoastAggregator } from "../../../../components/extras";
import HeaderSignup from "../../../../components/header-signup";
import Text from "../../../../components/text";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../constants/api";
import CountriesStatesLga from "../../../../fixtures/countries_states_lgas.json";
import Onboarding from "../../../../services/api/resources/onboarding";
import PreviewAccordion from "../component/accordion";
import PrimaryButton from "../component/button";
import { connect } from "react-redux";
// import { navigateTo } from '../../../../services/redux/actions/navigation';
import { navigateTo } from "../../../../services/redux/actions/navigation";
import { useHistory } from "react-router-dom";
import { ArrowUpIcon, HandIcon } from "../../../../icons";
import { ArrowDownIcon } from "../../../../icons";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const imgStyle = {
  borderRadius: "5px",
  height: "9rem",
  border: "1px solid grey",
  width: "8rem",
};
const PersonalDetails = ({ setScreen, application, isLoading }) => {
  return (
    <>
      {/* <div
        onClick={() => setScreen(2)}
        style={{
          float: 'right',
          cursor: 'pointer',
          color: '#00425F',
          fontWeight: 600,
        }}
      >
        Preview
      </div> */}
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Phone Number:
        </Text>
        <Text style={{ color: "grey" }} mid>
          {isLoading ? "Loading..." : application?.phoneNumber}
        </Text>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Email Address:
        </Text>
        <Text style={{ color: "grey" }} color="grey" mid>
          {isLoading ? "Loading..." : application?.emailAddress}
        </Text>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Gender:
        </Text>
        <Text style={{ color: "grey" }} color="grey" mid>
          {isLoading ? "Loading..." : application?.gender}
        </Text>
      </div>

      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          BVN:
        </Text>
        <Text style={{ color: "grey" }} mid>
          {isLoading ? "Loading..." : application?.bvn}
        </Text>
      </div>
    </>
  );
};
const KycInformation = ({
  setScreen,
  application,
  isLoading,
  documentsList,
}) => {
  const getDocumentUrl = (documentLink) => {
    let convertedDocument = documentLink.replace(
      "/data",
      process.env.REACT_APP_ONBOARDING_BASE_URL
    );
    return convertedDocument;
  };

  const convertFileName = (fileName) => {
    if (fileName === "UTILITY_BILL") {
      return "Utility Bill";
    } else if (fileName === "PASSPORT_PHOTO") {
      return "Passport Photo";
    } else if (fileName === "ID_CARD") {
      return "Proof of Identity";
    }
  };

  return (
    <>
      <div
        onClick={() => setScreen(3)}
        style={{
          float: "right",
          cursor: "pointer",
          color: "#00425F",
          fontWeight: 600,
        }}
      >
        Edit
      </div>

      {/* <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Means of Identification:
        </Text>
        <Text style={{ color: "grey" }} mid>
          {isLoading ? "Loading..." : application?.identificationType}
        </Text>
      </div> */}

      {/* <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          ID number:
        </Text>
        <Text style={{ color: "grey" }} color="grey" mid>
          {isLoading ? "Loading..." : application?.identificationNumber}
        </Text>
      </div> */}

      {isLoading ? (
        ""
      ) : (
        <div
          style={{
            display: "flex",
            gap: "10px",

            flexWrap: "wrap",
          }}
        >
          {documentsList?.map((item, index) => {
            const {
              documentType,

              documentExtention,
              documentLink,
              documentName,
            } = item;

            return (
              <>
                <div>
                  <Form.Label
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {convertFileName(documentType)}
                  </Form.Label>
                  <div style={imgStyle} key={index}>
                    {documentExtention === "png" ||
                    documentExtention === "jpeg" ||
                    documentExtention === "jpg" ||
                    documentExtention === "svg" ? (
                      <img
                        style={{
                          fontSize: "14px",
                          width: "130px",
                          height: "145px",
                          objectFit: "cover",
                        }}
                        src={getDocumentUrl(documentLink)}
                        title={convertFileName(documentType)}
                        alt={convertFileName(documentType)}
                      />
                    ) : (
                      <embed
                        name="plugin"
                        style={{
                          fontSize: "14px",
                        }}
                        src={getDocumentUrl(documentLink)}
                        title={convertFileName(documentType)}
                        alt={convertFileName(documentType)}
                      />
                    )}
                  </div>
                </div>
              </>
            );
          })}
        </div>
      )}
    </>
  );
};
const BusinessDetails = ({ setScreen, application, isLoading, state, lga }) => {
  return (
    <>
      <div
        onClick={() => setScreen(4)}
        style={{
          float: "right",
          cursor: "pointer",
          color: "#00425F",
          fontWeight: 600,
        }}
      >
        Edit
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Business Name:
        </Text>
        <Text style={{ color: "grey" }} mid>
          {isLoading ? "Loading..." : application?.address}
        </Text>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Business Address:
        </Text>
        <Text style={{ color: "grey" }} color="grey" mid>
          {isLoading ? "Loading..." : application?.businessName}
        </Text>
      </div>

      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          State:
        </Text>
        <Text style={{ color: "grey" }} color="grey" mid>
          {isLoading ? "Loading..." : state}
        </Text>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Local Government Area:
        </Text>
        <Text style={{ color: "grey" }} color="grey" mid>
          {isLoading ? "Loading..." : lga}
        </Text>
      </div>
    </>
  );
};
const NextOfKinDetails = ({ setScreen, application, isLoading }) => {
  return (
    <>
      <div
        onClick={() => setScreen(5)}
        style={{
          float: "right",
          cursor: "pointer",
          color: "#00425F",
          fontWeight: 600,
        }}
      >
        Edit
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Full Name:
        </Text>
        <Text style={{ color: "grey" }} mid>
          {isLoading ? "Loading..." : application?.firstName}
        </Text>
      </div>

      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Phone Number:
        </Text>
        <Text style={{ color: "grey" }} color="grey" mid>
          {isLoading ? "Loading..." : application?.phoneNumber}
        </Text>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Email Address:
        </Text>
        <Text style={{ color: "grey" }} color="grey" mid>
          {isLoading ? "Loading..." : application?.emailAddress}
        </Text>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Home Address:
        </Text>
        <Text style={{ color: "grey" }} color="grey" mid>
          {isLoading ? "Loading..." : application?.address}
        </Text>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Relationship:
        </Text>
        <Text style={{ color: "grey" }} color="grey" mid>
          {isLoading ? "Loading..." : application?.relationship}
        </Text>
      </div>
    </>
  );
};

const ApplicationReviewScene = ({ setScreen, props }) => {
  const history = useHistory();
  const applicationId = localStorage.getItem("applicationId");
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState(false);
  const [state, setState] = useState("");
  const [showCongratsModal, setShowCongratsModal] = useState(false);
  const [LocalGovernmentArea, setLocalGovernmentArea] = useState("");
  // const [arrowIcon, setArrowIcon] = useState(ArrowUpIcon());
  const onboarding = new Onboarding();

  const fetchApplication = async () => {
    setIsLoading(true);
    // applicationId
    const { status, response } = await onboarding.getApplicationAggregator(
      applicationId
    );

    setIsLoading(false);
    if (status === ERROR_STATUS) {
    } else if (status === SUCCESS_STATUS) {
      const stateId = parseInt(response.businessDetails.state);
      setIsLoading(false);

      let state = CountriesStatesLga[0].states.map((item) => {
        return {
          id: item.id,
          name: item.name,
        };
      });
      const getActualStateObj = state.find((item) => item.id === stateId);
      const lga = CountriesStatesLga[0].states.find(
        (state) => state.id === getActualStateObj?.id
      );
      const lgaId = parseInt(response.businessDetails.localGovernmentArea);
      setLocalGovernmentArea(
        lga?.lgas?.find((item) => item.id === lgaId)?.name
      );
      setState(getActualStateObj?.name);
      setApplication(response);
    } else {
      setIsLoading(false);
    }
  };

  const congratsModal = () => {
    setShowCongratsModal(true);
  };

  const navigateToOverview = () => {
    history.push("/login");
    // props.navigateTo('/agents/dashboard');
  };

  const handleSubmit = async () => {
    setLoading(true);
    // applicationId
    const payload = {};
    const { status, response } = await onboarding.saveAggregatorApplication(
      payload,
      applicationId
    );

    setLoading(false);
    if (status === ERROR_STATUS) {
      firetoast("error", `${response.description}`, "");
    } else if (status === SUCCESS_STATUS) {
      congratsModal();
      localStorage.removeItem("applicationId");
      localStorage.removeItem("lga");
      localStorage.removeItem("application");
      localStorage.removeItem("state");
      localStorage.removeItem("passportPhoto");
      localStorage.removeItem("idCard");
      localStorage.removeItem("personalDetailsForm");
      localStorage.removeItem("kycDetailsForm");
      localStorage.removeItem("businessDetailsForm");
      localStorage.removeItem("nextOfKinDetailsForm");
    }
  };

  useEffect(() => {
    fetchApplication();
  }, []);
  const [showAccordion, setAccordion] = useState(true);
  const [showAccordionKYC, setAccordionKYC] = useState(false);
  const [showAccordionBusiness, setAccordionBusiness] = useState(false);
  const [showAccordionNext, setAccordionNext] = useState(false);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          gap: "40px",
        }}
      >
        <HeaderSignup />
        <BackButton onClick={() => setScreen(5)} />
      </div>
      <main className="onboarding-container">
        <Col
          style={{
            marginBottom: "20px",
            margin: "0 auto",
          }}
        >
          <H1
            style={{
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "1rem",
            }}
          >
            Application Review
          </H1>

          <PreviewAccordion
            index="1"
            title={"Personal Details"}
            showAccordion={showAccordion}
            onClick={() => setAccordion(!showAccordion)}
          >
            <div>
              <PersonalDetails
                application={application.applicantDetails}
                isLoading={isLoading}
                setScreen={setScreen}
              />
            </div>
          </PreviewAccordion>
          <PreviewAccordion
            index={"0"}
            title={"KYC Information"}
            showAccordion={showAccordionKYC}
            onClick={() => setAccordionKYC(!showAccordionKYC)}
          >
            <KycInformation
              setScreen={setScreen}
              application={application.applicantDetails}
              documentsList={application.documentsList}
            />
          </PreviewAccordion>
          <PreviewAccordion
            index={"0"}
            title={"Business Details"}
            showAccordion={showAccordionBusiness}
            onClick={() => setAccordionBusiness(!showAccordionBusiness)}
          >
            <BusinessDetails
              application={application.businessDetails}
              isLoading={isLoading}
              setScreen={setScreen}
              state={state}
              lga={LocalGovernmentArea}
            />
          </PreviewAccordion>
          <PreviewAccordion
            index={"0"}
            title={"Next of kin details"}
            showAccordion={showAccordionNext}
            onClick={() => setAccordionNext(!showAccordionNext)}
          >
            <NextOfKinDetails
              setScreen={setScreen}
              application={application.nextOfKin}
            />
          </PreviewAccordion>

          <PrimaryButton disabled={loading || isLoading} onClick={handleSubmit}>
            {loading ? "Processing..." : "Submit"}{" "}
          </PrimaryButton>
        </Col>
      </main>
      <>
        <Modal show={showCongratsModal}>
          <Modal.Body style={{ padding: "1rem" }}>
            <div style={{ padding: "4rem", alignContent: "center" }}>
              <div style={{ display: "grid", placeContent: "center" }}>
                {HandIcon()}
              </div>
              <h1
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  marginBottom: "1rem",
                  fontSize: "1.5rem",
                }}
              >
                Congratulations!
              </h1>
              <p
                style={{
                  color: "grey",
                  fontWeight: "200",
                  marginLeft: "1.5rem",
                }}
              >
                Your account has been successfully setup
              </p>
              <Link to="/login">
                <button
                  className="signupButton2"
                  style={{
                    marginTop: "3rem",
                    textAlign: "center",
                    color: "#479FC8",
                  }}
                >
                  {" "}
                  Continue to Login{" "}
                </button>
              </Link>
            </div>
          </Modal.Body>
        </Modal>
      </>
    </>
  );
};
function mapStateToProps(state) {
  return {
    // isLoading: state.tunnel.isLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: (pendingUrl) => dispatch(navigateTo(pendingUrl)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationReviewScene);
