import { firetoast } from "../../../../../components/extras";

export const isBvnValid = (newForm) => {
  const form = newForm;
  return (
    form.bvn &&
    Boolean(parseInt(form.bvn)) &&
    form.bvn.length === 11 &&
    !isNaN(form.bvn)
  );
};

export const isBusinessNameValid = (newForm) => {
  const form = newForm;

  if (!form.businessName) {
    return false;
  }

  const businessNameSplitted = form.businessName.split(" ");
  return form.businessName.length >= 3;
};
export const isNameValid = (text) => {
  return text.length >= 3;
};

export const isBusinessAddressValid = (newForm) => {
  const form = newForm;
  return form.businessAddress && form.businessAddress.split(" ").length >= 2;
};
export const isBusinessLgaValid = (lga) => {
  return lga && true;
};
export const isBusinessAddressValidNOK = (newForm) => {
  const form = newForm;
  return form.address && form.address.split(" ").length >= 2;
};
export const isIdentificationNumberValid = (newForm) => {
  const form = newForm;
  return (
    form.idNumber && form.idNumber.length >= 7 && !form.idNumber.includes(" ")
  );
};
export function formatDateApplication(inputDate) {
  if (inputDate) {
    const [day, month, year] = inputDate.split("-");

    const dateObject = new Date(inputDate);

    if (isNaN(dateObject.getTime())) {
      throw new Error("Invalid date.");
    }

    const options = { year: "numeric", month: "long", day: "numeric" };

    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      dateObject
    );

    const dayWithSuffix = getDayWithSuffix(dateObject.getDate());

    return formattedDate.replace(/\d+/, dayWithSuffix);
  }
}
function getDayWithSuffix(day) {
  // Determine the suffix for the day
  if (day >= 11 && day <= 13) {
    return day + "th";
  }
  switch (day % 10) {
    case 1:
      return day + "st";
    case 2:
      return day + "nd";
    case 3:
      return day + "rd";
    default:
      return day + "th";
  }
}

export const stripNonNumbers = (str) => {
  return str.replace(/\D/g, "");
};

export const isEmailValid = (newForm) => {
  const form = newForm;
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(form.email).toLowerCase());
};

export const isEmailAddressValid = (newForm) => {
  const form = newForm;
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(form.emailAddress).toLowerCase());
};

export const isEmailValidNOK = (newForm) => {
  const form = newForm;
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(form.emailAddress).toLowerCase());
};

export function isPasswordValid(password) {
  return password && String(password).length >= 4;
}

export function isConfirmPasswordValid(confirmPassword) {
  return confirmPassword && String(confirmPassword).length >= 4;
}

export function isOTPValid(otp) {
  return otp && String(otp).length === 6;
}

export const errorResponse = (code, description) => {
  if (code === "50000") {
    firetoast("error", `Unable to complete this action at this time`, "");
  } else {
    firetoast("error", `${description}`, "");
  }
};
