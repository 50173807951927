import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Onboarding from "../../../../services/api/resources/onboarding";
import Platform from "../../../../services/api/resources/platform";

import CountriesStatesLga from "../../../../fixtures/countries_states_lgas.json";
import PreviewAccordion from "../../onboarding-setup/component/accordion";
import BackButton from "../../onboarding-setup/component/backButton";
import {
  firetoast,
  firetoastAggregator,
  notify,
} from "../../../../components/extras";
import PrimaryButton from "../../onboarding-setup/component/button";
import H1 from "../../../../components/h1";
import Text from "../../../../components/text";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../constants/api";
import { HandIcon } from "../../../../icons";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import DocumentUploader from "../../../home/scenes/dashboard/fragments/onboarding-application-setup/component/customeImage";
import Lightbox from "react-image-lightbox";

const imgStyle = {
  borderRadius: "5px",
  height: "9rem",
  fontSize: "14px",
  border: "1px solid grey",
  width: "8rem",
  margin: "1rem",
};

const PersonalDetails = ({ application, isLoading, setScreen }) => {
  const stateId = application?.homeAddressStateId;
  const lgaId = application?.homeAddressLgaId;

  const selectedStateData = CountriesStatesLga[0].states.find(
    (state) => state.id === Number(stateId)
  );
  const lgaWithId = selectedStateData?.lgas?.find((lga) => lga.id === +lgaId);
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ marginBottom: "10px" }}>
          <Text bold mid>
            Mother's Maiden Name:
          </Text>
          <Text bold mid>
            State:
          </Text>
          <Text bold mid>
            Local Government Area:
          </Text>
          <Text bold mid>
            Residential Address:
          </Text>
        </div>
        <div style={{ marginBottom: "10px", float: "right" }}>
          <Text style={{ color: "grey" }} mid>
            {application?.motherMaidenName}
          </Text>
          <Text style={{ color: "grey" }} mid>
            {isLoading ? "Loading..." : selectedStateData?.name}
          </Text>
          <Text style={{ color: "grey" }} color="grey" mid>
            {isLoading ? "Loading..." : lgaWithId?.name}
          </Text>
          <Text style={{ color: "grey" }} color="grey" mid>
            {isLoading ? "Loading..." : application?.residentialAddress}
          </Text>
        </div>
      </div>
    </>
  );
};

const BusinessDetails = ({ setScreen, application, isLoading }) => {
  const platform = new Platform();
  const [businessTypesSave, setBusinessTypesSave] = useState([]);
  const [businessTypesData, setBusinessTypesData] = useState([]);

  let businessTypeNew;
  const id = application?.businessType;

  const fetchBusinessTypes = async () => {
    const tierId = 2;
    const { response } = await platform.fetchStandardBusinessTypes(tierId);
    businessTypeNew = response?.filter((item) => item.id === +id);
    setBusinessTypesData(businessTypeNew[0]?.business_type);
  };
  useEffect(() => {
    fetchBusinessTypes();
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ marginBottom: "10px" }}>
          <Text bold mid>
            Business Type:
          </Text>
          <Text bold mid>
            Bank:
          </Text>
          <Text bold mid>
            Account Number:
          </Text>
        </div>
        <div style={{ marginBottom: "10px", float: "right" }}>
          <Text style={{ color: "grey" }} mid>
            {businessTypesData}
          </Text>
          <Text style={{ color: "grey" }} mid>
            {isLoading ? "Loading..." : application?.bank}
          </Text>
          <Text style={{ color: "grey" }} color="grey" mid>
            {isLoading ? "Loading..." : application?.accountNumber}
          </Text>
        </div>
      </div>
    </>
  );
};

const KycInformation = ({ setScreen, application, isLoading, documents }) => {
  const [showLightbox, setShowLightbox] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const getDocumentUrl = (name) => {
    return `${process.env.REACT_APP_CDN_BASE_URL}/p/finch/onboarding/${name}`;
  };

  const handleDocumentClick = (name) => {
    setSelectedDocument(name);
    setShowLightbox(true);
  };

  const convertFileNameFetch = (fileName) => {
    if (fileName?.toLowerCase().includes("pass")) {
      return "Passport Photo";
    } else if (
      fileName?.toLowerCase().includes("proof") ||
      fileName?.toLowerCase().includes("id")
    ) {
      return "Means of Identification";
    } else if (fileName?.toLowerCase().includes("util")) {
      return "Utility Bill";
    } else if (fileName?.toLowerCase().includes("char")) {
      return "Character Form";
    }
  };

  // const convertFileName = (fileName) => {
  //     if (fileName === 'UTILITY_BILL') {
  //         return 'Utility Bill';
  //     } else if (fileName === 'PASSPORT_PHOTO') {
  //         return 'Passport Photograph';
  //     } else if (fileName === 'ID_CARD') {
  //         return 'Government Issued ID';
  //     } else if (fileName === 'ADDRESS_VERIFICATION_FORM') {
  //         return 'Address Verification Form';
  //     } else if (fileName === 'CHARACTER_FORM') {
  //         return 'Character Form';
  //     }
  // };
  // const [idCardImg, setIdCardImg] = useState(null)
  // const [utilityBillImg, setUtilityBillImg] = useState(null)

  // useEffect(() => {
  //     const savedImageData = application[0];

  //     if (savedImageData) {
  //         setIdCardImg(savedImageData);
  //     }

  //     const savedPassportImage = application[1];

  //     if (savedPassportImage) {
  //         setUtilityBillImg(savedPassportImage);
  //     }
  // }, []);
  return (
    <>
      <div style={{ marginBottom: "10px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Text bold mid>
            Enter NIN:
          </Text>
          <Text style={{ color: "grey" }} mid>
            {isLoading ? "Loading..." : application?.nin}
          </Text>
        </div>
        {/* <img style={imgStyle} src={idCardImg} alt="NIN Image" />
                <img style={imgStyle} src={utilityBillImg} alt="Utility Image" /> */}

        <Row>
          {application?.documents?.length > 1 && (
            <Col
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              {application?.documents?.map((value, index) => {
                let { name, url, type, extension } = value;

                return (
                  <div onClick={() => handleDocumentClick(url)}>
                    <Form.Label
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      {convertFileNameFetch(type)}
                    </Form.Label>
                    <div style={imgStyle} key={index}>
                      {extension === "png" ||
                      extension === "jpeg" ||
                      extension === "jpg" ||
                      extension === "svg" ? (
                        <img
                          // style={imgStyle}
                          src={getDocumentUrl(url)}
                          title={convertFileNameFetch(type)}
                          alt={convertFileNameFetch(type)}
                        />
                      ) : (
                        <div onClick={() => setShowLightbox(true)}>
                          <embed
                            style={imgStyle}
                            name="plugin"
                            src={getDocumentUrl(url)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
              {showLightbox && selectedDocument && (
                <>
                  <Lightbox
                    mainSrc={getDocumentUrl(selectedDocument)}
                    onCloseRequest={() => setShowLightbox(false)}
                  />
                </>
              )}
            </Col>
          )}
        </Row>
      </div>
    </>
  );
};

const RefereeInfomation = ({ setScreen, application }) => {
  return (
    <>
      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Text bold mid>
          Referee Email Address:
        </Text>
        <Text style={{ color: "grey" }} color="grey" mid>
          {application?.emailAddress}
        </Text>
      </div>
    </>
  );
};

const ApplicationReviewScene = ({ setScreen, props }) => {
  const history = useHistory();

  localStorage.setItem("moveScreen", "false");
  const app = JSON.parse(localStorage.getItem("modifiedAgentDetails"));
  const newApp = app?.content[0];

  const refereeDetailsForm = JSON.parse(
    localStorage.getItem("refereeDetailsForm")
  );
  const platform = new Platform();

  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState([]);
  const [showCongratsModal, setShowCongratsModal] = useState(false);

  const congratsModal = () => {
    setShowCongratsModal(true);
  };

  const navigateToOverview = () => {
    setShowCongratsModal(false);
    history.push("/agent/dashboard");
  };

  // const upgradeClass = () => {
  //     const upgradeToMap = {
  //         prestige: 3,
  //         Standard: 2,
  //     };

  //     return upgradeToMap[upgradeTo] || '';
  // };

  const upgradeTo = localStorage.getItem("upgradeTo");
  let currentAgent = JSON.parse(localStorage.getItem("currentAgent"));

  const handleSubmit = async () => {
    const domain_code = currentAgent?.agentCode;

    setLoading(true);
    const payload = {
      newClassId: 2,

      homeAddressStateId: newApp?.homeAddressStateId,
      homeAddressLgaId: newApp?.homeAddressLgaId,

      motherMaidenName: newApp?.motherMaidenName,
      businessTypeId: newApp?.businessType,
      bank: newApp?.bank,
      accountNumber: newApp?.accountNumber,
      refereeEmail: refereeDetailsForm?.emailAddress,
    };
    const { status, response } = await platform.submitAgentClassUpgrage(
      payload,
      domain_code
    );

    setLoading(false);
    if (status === ERROR_STATUS) {
      firetoast("error", `${response.description}`, "");
    } else if (status === SUCCESS_STATUS) {
      notify(
        `Changes saved, \n Your changes have been successfully saved.`,
        "success"
      );
      congratsModal();
    }
  };

  return (
    <>
      <BackButton onClick={() => setScreen(1)} />
      <main className="aggregator-container">
        <Col
          style={{
            marginBottom: "20px",
            margin: "0 auto",
          }}
        >
          <H1
            style={{
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "1rem",
            }}
          >
            Application Preview
          </H1>
          <PreviewAccordion index="1" title={"Personal Details"}>
            <PersonalDetails
              isLoading={isLoading}
              setScreen={setScreen}
              application={app?.content[0]}
            />
          </PreviewAccordion>
          <PreviewAccordion index={"0"} title={"Business Details"}>
            <BusinessDetails
              isLoading={isLoading}
              setScreen={setScreen}
              application={app?.content[0]}
            />
          </PreviewAccordion>
          <PreviewAccordion index={"0"} title={"KYC Information"}>
            <KycInformation
              setScreen={setScreen}
              // application={application}
              application={app?.content[0]}
            />
          </PreviewAccordion>

          <PreviewAccordion index={"0"} title={"Referee's Information"}>
            <RefereeInfomation
              setScreen={setScreen}
              application={refereeDetailsForm}
            />
          </PreviewAccordion>

          <PrimaryButton disabled={loading} onClick={handleSubmit}>
            {loading ? "Processing..." : "Proceed"}{" "}
          </PrimaryButton>
        </Col>
      </main>
      <>
        <Modal
          show={showCongratsModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body style={{ padding: "2rem", alignSelf: "center" }}>
            <div style={{ alignContent: "center", padding: "3rem" }}>
              <div style={{ display: "grid", placeContent: "center" }}>
                {HandIcon()}
              </div>
              <h1
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  marginBottom: "1rem",
                  fontSize: "1.5rem",
                }}
              >
                Email Sent
              </h1>
              <p
                style={{
                  color: "#5F738C",
                  fontWeight: "300",
                  // whiteSpace: 'no-wrap',
                  wordWrap: "break-word",
                }}
              >
                An Email has been sent to {refereeDetailsForm?.emailAddress}.{" "}
                <br />
                <span style={{ marginLeft: "" }}>
                  {" "}
                  Kindly follow to speed up the request
                </span>
              </p>
              <button
                className="signupButton2"
                style={{
                  marginTop: "2rem",
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "#00425f",
                  marginLeft: "12%",
                  width: "80%",
                }}
                onClick={navigateToOverview}
              >
                {" "}
                Done{" "}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    </>
  );
};

export default ApplicationReviewScene;
