import React from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import ReactPhoneInput from "react-phone-input-2";
import Button from "../../../../../../../components/button";
import FormControl from "../../../../../../../components/form-control";
import FormLabel from "../../../../../../../components/form-label";


export const CashInForm = ({
  props = "",
  customerIdFieldIsEmail,
  customerIdFieldIsPhone,
  isEmailValid,
  isPhoneValid,
  isFormValid,
  updateFormField,
  state = {
    form: {},
    posRequestForm: {},
    requestTypeValues: [],
  },

  setState,
}) => {
  return (
    <Form>
      <Row>
        <Col md={6}>
          <Form.Group>
            <FormLabel
              style={{
                color:
                  (customerIdFieldIsEmail &&
                    state.form.email &&
                    !isEmailValid(state.form.email)) ||
                  (customerIdFieldIsPhone &&
                    state.form.phone &&
                    !isPhoneValid())
                    ? process.env.REACT_APP_RED_COLOUR
                    : "#3A3B3B",
                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                lineHeight: "19px",
              }}
            >
              {props.customerIdField}:{""}
              <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>*</span>
            </FormLabel>

            {customerIdFieldIsPhone ? (
              <ReactPhoneInput
                buttonStyle={{
                  borderTopLeftRadius:
                    process.env.REACT_APP_BORDER_STYLE === "square"
                      ? "4px"
                      : "20px",
                  borderBottomLeftRadius:
                    process.env.REACT_APP_BORDER_STYLE === "square"
                      ? "4px"
                      : "20px",
                }}
                containerStyle={{ margin: 0, padding: 0, width: "100%" }}
                defaultCountry={"ng"}
                disableCountryCode={true}
                inputStyle={{
                  backgroundColor: "#FFFFFF",
                  borderRadius:
                    process.env.REACT_APP_BORDER_STYLE === "square"
                      ? "4px"
                      : "20px",
                  border: `1px solid ${
                    (!state.form.phone && !state.propagateFormErrors) ||
                    isPhoneValid()
                      ? "#DFE2E6"
                      : process.env.REACT_APP_RED_COLOUR
                  }`,
                  boxSizing: "border-box",
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  lineHeight: "17px",
                  width: "100%",
                }}
                value={state.form.phone || ""}
                onlyCountries={["ng", "ke", "ug"]}
                onBlur={() =>
                  setState({
                    fieldsFocused: [...state.fieldsFocused, "phone"],
                  })
                }
                onChange={(value, data) => {
                  let formattedValue = value;
                  const customerId = formattedValue;
                  const fieldsToUpdate = { customerId };

                  if (customerIdFieldIsPhone) {
                    fieldsToUpdate.phone = customerId;
                    fieldsToUpdate.countryShortCode =
                      data.countryCode?.toUpperCase();
                  }

                  updateFormField(fieldsToUpdate);
                }}
                placeholder="08012345678"
              />
            ) : customerIdFieldIsEmail ? (
              <FormControl
                onChange={(event) => {
                  const email = event.target.value;
                  const customerId = email;
                  const fieldsToUpdate = {
                    customerId,
                    email,
                  };

                  updateFormField(fieldsToUpdate);
                }}
                style={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "20px",
                  border: `1px solid ${
                    (!state.form.email && !state.propagateFormErrors) ||
                    isEmailValid(state.form.email)
                      ? "#DFE2E6"
                      : process.env.REACT_APP_RED_COLOUR
                  }`,
                  boxSizing: "border-box",
                  fontSize: "12px",
                  lineHeight: "17px",
                  width: "100%",
                }}
              />
            ) : (
              <FormControl
                onChange={(event) => {
                  const customerId = event.target.value;
                  const fieldsToUpdate = { customerId };

                  updateFormField(fieldsToUpdate);
                }}
                style={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "20px",
                  border: `1px solid #DFE2E6`,
                  boxSizing: "border-box",
                  fontSize: "12px",
                  lineHeight: "17px",
                  width: "100%",
                }}
              />
            )}
          </Form.Group>
        </Col>

        <Col hidden={customerIdFieldIsEmail} md={6}>
          <Form.Group>
            <FormLabel
              style={{
                color: `${
                  (!state.form.email && !state.propagateFormErrors) ||
                  isEmailValid(state.form.email)
                    ? "#3A3B3B"
                    : process.env.REACT_APP_RED_COLOUR
                }`,
                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                lineHeight: "19px",
              }}
            >
              Email Address:
            </FormLabel>
            <FormControl
              onBlur={() =>
                setState({
                  fieldsFocused: [...state.fieldsFocused, "email"],
                })
              }
              onChange={(event) => {
                const email = event.target.value;
                updateFormField({ email });
              }}
              style={{
                backgroundColor: "#FFFFFF",
                borderRadius: "20px",
                border: `1px solid ${
                  (!state.form.email && !state.propagateFormErrors) ||
                  isEmailValid(this.state.form.email)
                    ? "#DFE2E6"
                    : process.env.REACT_APP_RED_COLOUR
                }`,
                boxSizing: "border-box",
                fontSize: "12px",
                lineHeight: "17px",
                width: "100%",
              }}
            />
          </Form.Group>
        </Col>

        <Col hidden={customerIdFieldIsPhone} md={6}>
          <Form.Group>
            <FormLabel
              style={{
                color: `${
                  (!state.form.phone && !state.propagateFormErrors) ||
                  isPhoneValid()
                    ? "#3A3B3B"
                    : process.env.REACT_APP_RED_COLOUR
                }`,
                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                lineHeight: "19px",
              }}
            >
              Phone Number:{" "}
              <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>*</span>
            </FormLabel>
            <ReactPhoneInput
              buttonStyle={{
                borderTopLeftRadius:
                  process.env.REACT_APP_BORDER_STYLE === "square"
                    ? "4px"
                    : "20px",
                borderBottomLeftRadius:
                  process.env.REACT_APP_BORDER_STYLE === "square"
                    ? "4px"
                    : "20px",
              }}
              containerStyle={{ margin: 0, padding: 0, width: "100%" }}
              defaultCountry={"ng"}
              disableCountryCode={true}
              inputStyle={{
                backgroundColor: "#FFFFFF",
                borderRadius:
                  process.env.REACT_APP_BORDER_STYLE === "square"
                    ? "4px"
                    : "20px",
                border: `1px solid ${
                  (!state.form.phone && !state.propagateFormErrors) ||
                  isPhoneValid()
                    ? "#DFE2E6"
                    : process.env.REACT_APP_RED_COLOUR
                }`,
                boxSizing: "border-box",
                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                lineHeight: "17px",
                width: "100%",
              }}
              value={state.form.phone || ""}
              maxLength={11}
              onlyCountries={["ng", "ke", "ug"]}
              onBlur={() =>
                setState({
                  fieldsFocused: [...state.fieldsFocused, "phone"],
                })
              }
              onChange={(value, data) => {
                updateFormField({ phone: value });
              }}
              placeholder="08012345678"
            />
          </Form.Group>
        </Col>

        <Col md={6}>
          <Form.Group controlId="gender">
            <FormLabel
              style={{
                color: "#3A3B3B",
                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                lineHeight: "19px",
              }}
            >
              Gender:{" "}
              <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>*</span>
            </FormLabel>
            <Row>
              <Col md={6}>
                <input
                  type="radio"
                  name="gender"
                  value="Male"
                  id="male"
                  onChange={(event) => {
                    const gender = event.target.value;
                    updateFormField({ gender });
                  }}
                />{" "}
                <label for="male">Male</label>
              </Col>
              <Col md={6}>
                <input
                  type="radio"
                  name="gender"
                  value="Female"
                  id="female"
                  onChange={(event) => {
                    const gender = event.target.value;
                    updateFormField({ gender });
                  }}
                />{" "}
                <label for="female">Female</label>
              </Col>
            </Row>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <Form.Group as={Col} controlId="amount">
            <FormLabel
              style={{
                color: "#3A3B3B",
                fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                lineHeight: "19px",
              }}
            >
              Amount:{" "}
              <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>*</span>
            </FormLabel>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  id="inputGroupPrepend"
                  style={{
                    borderTopLeftRadius:
                      process.env.REACT_APP_BORDER_STYLE === "square"
                        ? "4px"
                        : 20,
                    borderBottomLeftRadius:
                      process.env.REACT_APP_BORDER_STYLE === "square"
                        ? "4px"
                        : 20,
                  }}
                >
                  NGN
                </InputGroup.Text>
              </InputGroup.Prepend>

              <FormControl
                readOnly={props.isAmountFixed}
                type="tel"
                value={state.form.amount}
                onBlur={() =>
                  setState({
                    fieldsFocused: [...state.fieldsFocused, "amount"],
                  })
                }
                onChange={(event) => {
                  const amount = event.target.value;
                  updateFormField({ amount });
                }}
                className="form-control form-control-amount"
              />
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={12} className="proceed-button-col">
          <Button
            className="services-proceed-button"
            disabled={props.isLoading || !isFormValid()}
            onClick={() => props.onCashInProceed(state.form)}
          >
            {props.isLoading ? "Loading..." : "Continue"}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
