import React, { useEffect, useState } from "react";
import qs from "qs";
import H1 from "../../../../../../../components/h1";
import { Form, Modal } from "react-bootstrap";
import InputText from "./inputText";
import PrimaryButton from "./customButton";
import {
  firetoastCustomButton,
  notify,
} from "../../../../../../../components/extras";
import { formatAmount } from "../../../../../../../utils/formatters";
import AnalyticsService from "../../../../../../../services/api/resources/analytics-service";
import ActivityIndicator from "../../../../../../../components/activity-indicator";
import {
  ERROR_STATUS,
  SUCCESS_STATUS,
} from "../../../../../../../constants/api";
import {
  convertNgkToNgn,
  convertNgnToNgk,
} from "../../../../../../../utils/converters/currencies";
import { stripNonNumbers } from "../../../component/extras";
const Xcircle = "/assets/media/images/XCircle.png";

const editStyle = {
  color: "#479FC8",
  cursor: "pointer",
  marginRight: "1rem",
};
const tierLabelStyle = {
  color: "#0275D8",
  position: "relative",
  top: "-10px",
};
const errorIcon = {
  fontSize: "10px",
  marginRight: "3px",
  marginTop: "4px",
  width: "14px",
  height: "12px",
  background: "#ff0000",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
const ModalComponent = ({
  toggleModal,
  showModal,
  isLoading,
  handleSubmit,
  title,
  value,
  currentValue,
  systemBalance,
  onChangeValue,
}) => {
  return (
    <Modal centered show={showModal}>
      <Modal.Body style={{ padding: "1.5rem" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "2rem",
          }}
        >
          <H1>Set {title} Threshold</H1>
          <img
            style={{
              cursor: "pointer",
            }}
            onClick={() => toggleModal()}
            src={Xcircle}
            alt="cancel"
          />
        </div>
        <div
          style={{
            marginBottom: "1rem",
          }}
        >
          <InputText
            onChange={onChangeValue}
            label={title}
            value={`${value}`}
            labelColor={
              value < convertNgkToNgn(systemBalance)
                ? process.env.REACT_APP_RED_COLOUR
                : "#3A3B3B"
            }
            border={`1px solid ${
              value < convertNgkToNgn(systemBalance)
                ? process.env.REACT_APP_RED_COLOUR
                : "#DFE2E6"
            }`}
          />
        </div>
        {value < convertNgkToNgn(systemBalance) ? (
          <div
            style={{
              display: "flex",
              gap: "3px",
              color: "#ff0000",
              position: "relative",
              top: "-10px",
            }}
          >
            <span style={errorIcon}>
              <span style={{ color: "white" }}> !</span>
            </span>
            <p
              style={{
                fontSize: "14px",
              }}
            >
              {`The inputted amount is less than the system threshold, kindly input a higher amount`}
            </p>
          </div>
        ) : (
          <p style={tierLabelStyle}>
            System Threshold: ₦
            {systemBalance
              ? formatAmount(convertNgkToNgn(systemBalance))
              : formatAmount(0)}
          </p>
        )}

        {/* <Form.Check
          defaultChecked={applyToAll}
          type="checkbox"
          onChange={onChangeAppylyAllService}
          style={{
            backgroundColor: "#FFFFFF",
            fontSize: ".9rem",
            color: "#072F40",
            fontWeight: 400,
          }}
          label="Apply to other classes"
        /> */}
        <PrimaryButton
          disabled={
            isLoading || value < convertNgkToNgn(systemBalance) || !value
          }
          children={isLoading ? "Processing..." : "Apply Target"}
          onClick={handleSubmit}
        />
      </Modal.Body>
    </Modal>
  );
};
const Limits = ({ tier, limit }) => {
  return (
    <div
      style={{
        fontWeight: 400,
      }}
    >
      <span
        style={{
          color: "#9CA3AF",
        }}
      >
        {tier}
      </span>
      <p>{limit}</p>
    </div>
  );
};

const BalanceThreshold = () => {
  const analyticsService = new AnalyticsService();

  const deviceIsMobile =
    qs.parse(window.location.search)["?mobile"] || window.innerWidth <= 576;
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [isLoadingGet, setIsLoadingGet] = useState(false);
  const [isLoadingPost, setIsLoadingPost] = useState(false);
  const [balanceThreshold, setBalanceThreshold] = useState({
    basic: null,
    standard: null,
    prestige: null,
  });
  const [systemThreshold, setSystemThreshold] = useState({
    basic: null,
    standard: null,
    prestige: null,
  });

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const convertTransactionTypeToInteger = () => {
    const titleMapping = {
      Basic: 6,
      Standard: 2,
      Prestige: 3,
    };

    return titleMapping[modalData?.title] || null;
  };

  const getBalanceThreshold = async (showloading = true) => {
    showloading && setIsLoadingGet(true);
    const { response, status } = await analyticsService.getBalanceThreshold();

    setIsLoadingGet(false);
    if (status === ERROR_STATUS) {
    } else if (status === SUCCESS_STATUS) {
      const basic = response?.data.filter(
        (agentClass) => agentClass?.agentClassId === 6
      );
      const standard = response?.data.filter(
        (agentClass) => agentClass?.agentClassId === 2
      );
      const prestige = response?.data.filter(
        (agentClass) => agentClass?.agentClassId === 3
      );

      setBalanceThreshold({
        basic: basic[0]?.threshold,
        standard: standard[0]?.threshold,
        prestige: prestige[0]?.threshold,
      });
    }
  };

  const getSystemThreshold = async () => {
    const { response, status } = await analyticsService.getSystemThreshold();

    if (status === ERROR_STATUS) {
    } else if (status === SUCCESS_STATUS) {
      const basic = response?.data.filter(
        (agentClass) => agentClass?.agentClassId === 6
      );
      const standard = response?.data.filter(
        (agentClass) => agentClass?.agentClassId === 2
      );
      const prestige = response?.data.filter(
        (agentClass) => agentClass?.agentClassId === 3
      );

      setSystemThreshold({
        basic: basic[0]?.thresholdLowerBand,
        standard: standard[0]?.thresholdLowerBand,
        prestige: prestige[0]?.thresholdLowerBand,
      });
    }
  };

  const handleSubmit = async () => {
    const payload = {
      agentClassId: convertTransactionTypeToInteger(),
      threshold: convertNgnToNgk(modalData?.value),
      applyToAll: false,
    };

    setIsLoadingPost(true);
    const { response, status } = await analyticsService.updateBalanceThreshold(
      payload
    );
    setIsLoadingPost(false);
    if (status === ERROR_STATUS) {
      firetoastCustomButton("error", "", response.description, "Close");
      setShowModal(!showModal);
    } else if (status === SUCCESS_STATUS) {
      firetoastCustomButton(
        "success",
        "Balance Level Set",
        modalData?.applyToAll
          ? ` Balance Level for all agent classes has been updated to ₦${formatAmount(
              modalData?.value
            )} for all your agents`
          : ` Balance Level has been set to ₦${formatAmount(
              modalData?.value
            )} for your ${modalData.title} agents`,
        "Close"
      );
      getBalanceThreshold(false);
      setShowModal(!showModal);
    }
  };

  useEffect(() => {
    getBalanceThreshold();
    getSystemThreshold();
  }, []);

  return (
    <article className="commission-section-container">
      {deviceIsMobile && (
        <div
          style={{
            marginBottom: "1.5rem",
          }}
        >
          <H1>Balance Threshold</H1>
        </div>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {!deviceIsMobile && (
          <div>
            <H1>Balance Threshold</H1>
          </div>
        )}
        {isLoadingGet ? (
          <div
            style={{
              padding: "52px 0 52px 0",
              position: "relative",
              right: deviceIsMobile ? 0 : "50%",
              left: deviceIsMobile && "45%",
            }}
          >
            <ActivityIndicator
              style={{
                height: "85px",
              }}
            />
          </div>
        ) : (
          <>
            <div
              style={{
                marginLeft: deviceIsMobile ? 0 : "2rem",
              }}
            >
              <Limits
                tier={"Basic"}
                limit={`₦${
                  balanceThreshold.basic
                    ? formatAmount(convertNgkToNgn(balanceThreshold.basic))
                    : formatAmount(0)
                } per day`}
              />
              <Limits
                tier={"Standard"}
                limit={`₦${
                  balanceThreshold.standard
                    ? formatAmount(convertNgkToNgn(balanceThreshold.standard))
                    : formatAmount(0)
                } per day`}
              />
              <Limits
                tier={"Prestige"}
                limit={`₦${
                  balanceThreshold.prestige
                    ? formatAmount(convertNgkToNgn(balanceThreshold.prestige))
                    : formatAmount(0)
                } per day`}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "40px",
              }}
            >
              <div
                style={editStyle}
                onClick={() => {
                  setModalData({
                    title: "Basic",
                    value: convertNgkToNgn(balanceThreshold.basic),
                    currentValue: formatAmount(
                      convertNgkToNgn(balanceThreshold.basic)
                    ),
                    systemBalance: systemThreshold.basic,
                    applyToAll: false,
                  });
                  toggleModal();
                }}
              >
                Edit
              </div>

              <div
                style={editStyle}
                onClick={() => {
                  setModalData({
                    title: "Standard",
                    value: convertNgkToNgn(balanceThreshold.standard),
                    currentValue: formatAmount(
                      convertNgkToNgn(balanceThreshold.standard)
                    ),
                    systemBalance: systemThreshold.standard,
                    applyToAll: false,
                  });
                  toggleModal();
                }}
              >
                Edit
              </div>

              <div
                style={editStyle}
                onClick={() => {
                  setModalData({
                    title: "Prestige",
                    value: convertNgkToNgn(balanceThreshold.prestige),
                    currentValue: formatAmount(
                      convertNgkToNgn(balanceThreshold.prestige)
                    ),
                    systemBalance: systemThreshold.prestige,
                    applyToAll: false,
                  });

                  toggleModal();
                }}
              >
                Edit
              </div>
            </div>
          </>
        )}
      </div>

      <ModalComponent
        title={modalData?.title}
        value={modalData?.value}
        systemBalance={modalData?.systemBalance}
        isLoading={isLoadingPost}
        currentValue={modalData?.currentValue}
        applyToAll={modalData?.applyToAll}
        toggleModal={toggleModal}
        showModal={showModal}
        onChangeValue={(e) => {
          setModalData({
            ...modalData,
            value: stripNonNumbers(e.target.value),
          });
        }}
        onChangeAppylyAllService={(e) =>
          setModalData({
            ...modalData,
            applyToAll: e.target.checked,
          })
        }
        handleSubmit={handleSubmit}
      />
      {/* <Modal centered show={showModal}>
        <Modal.Body style={{ padding: "1.5rem" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "2rem",
            }}
          >
            <H1>Set Balance Threshold</H1>
            <img
              style={{
                cursor: "pointer",
              }}
              onClick={() => toggleModal()}
              src={Xcircle}
              alt="cancel"
            />
          </div>
          <div
            style={{
              marginBottom: "1rem",
            }}
          >
            <InputText
              onChange={(e) =>
                setModalData({
                  ...modalData,
                  basic: e.target.value,
                })
              }
              label={"Basic"}
              value={modalData?.basic}
            />
            <p style={tierLabelStyle}>Current Threshold: ₦50,000.00</p>
          </div>
          <div
            style={{
              marginBottom: "1rem",
            }}
          >
            <InputText
              onChange={(e) =>
                setModalData({
                  ...modalData,
                  standard: e.target.value,
                })
              }
              label={"Standard"}
              value={modalData?.standard}
            />
            <p style={tierLabelStyle}>Current Threshold: ₦60,000.00</p>
          </div>
          <div
            style={{
              marginBottom: "1rem",
            }}
          >
            <InputText
              onChange={(e) =>
                setModalData({
                  ...modalData,
                  prestige: e.target.value,
                })
              }
              label={"Prestige"}
              value={modalData?.prestige}
            />
            <p style={tierLabelStyle}>Current Threshold: ₦50,000.00</p>
          </div>
          <PrimaryButton children={"Set Threshold"} onClick={handleSubmit} />
        </Modal.Body>
      </Modal> */}
    </article>
  );
};

export default BalanceThreshold;
