import React from "react";

import MdArrowBack from "react-ionicons/lib/MdArrowBack";

const BackButton = ({ onClick, disabled }) => {
  return (
    <>
      <div onClick={onClick}>
        <div
          style={{
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
            width: "7.5rem",
            padding: "0.5rem",
            borderRadius: "12px",
            backgroundColor: disabled ? "grey" : "#00425F",
            cursor: disabled ? "not-allowed" : "pointer",
            marginBottom: "3.5rem",
          }}
        >
          <MdArrowBack color="#fff" fontSize="26px" />
          <span
            style={{
              display: "flex",
              justifyContent: "start",
              textDecoration: "none",
            }}
          >
            Back
          </span>
        </div>
      </div>
    </>
  );
};
export default BackButton;
