import qs from "qs";
import React, { useState } from "react";
import { AppProvider } from "./context";
import InitiateScreen from "./initiateScreen";
import ReceiptScreen from "./receiptScreen";
import UssdConfirmationScreen from "./ussdConfirmtionScreen";
const FundViaUSSD = ({ goBack, onCancelConfirm }) => {
  const deviceIsMobile =
    qs.parse(window.location.search)["?mobile"] || window.innerWidth <= 576;
  const [screen, setScreen] = useState(1);

  return (
    <>
      <AppProvider>
        {screen === 1 && (
          <InitiateScreen setScreen={setScreen} goBack={goBack} />
        )}
        {screen === 2 && (
          <UssdConfirmationScreen
            setScreen={setScreen}
            onCancelConfirm={onCancelConfirm}
            deviceIsMobile={deviceIsMobile}
          />
        )}
        {screen === 3 && (
          <ReceiptScreen
            setScreen={setScreen}
            deviceIsMobile={deviceIsMobile}
          />
        )}
      </AppProvider>
    </>
  );
};

export default FundViaUSSD;
