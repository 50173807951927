import React, { useState } from "react";

const AttachmentComponent = ({
  documentLink,
  documentName,
  documentType,
  documentExtention,
}) => {
  const [downloading, setDownloading] = useState(false);

  const handleDownload = async () => {
    try {
      setDownloading(true);

      const response = await fetch(documentLink);
      const blob = await response.blob();

      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", documentName);

      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
      setDownloading(false);
    } catch (error) {
      console.error("Error downloading file:", error);
      setDownloading(false);
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.attachmentInfo}>
        <div style={styles.fileSymbolContainer}>
          <img src="/assets/media/icons/fileSymbol.png" alt="" width={24} height={34} />
          <div style={styles.fileTypeBadge}>{documentExtention}</div>
        </div>
        <div style={styles.fileName}>{documentName}</div>
        <div>
          <a href={documentLink} onClick={handleDownload}>
            <img src="/assets/media/icons/download.png" alt="download" width={26} height={26} />
          </a>
        </div>
      </div>
      <div style={styles.separator} />
    </div>
  );
};

const styles = {
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "7px",
    textAlign: "center",
    fontSize: "16px",
    color: "var(--base-white)",
    fontFamily: "var(--font-inter)",
    cursor: "pointer",
  },
  attachmentInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "8px",
    gap: "8px",
  },
  fileSymbolContainer: {
    position: "relative",
    width: "24px",
    height: "24px",
  },
  fileTypeBadge: {
    position: "absolute",
    top: "0",
    right: "0",
    backgroundColor: "#7F56D9",
    color: "white",
    fontSize: "12px",
    fontWeight: "bold",
    // padding: "2px 6px",
    borderRadius: "4px",
    zIndex: "1",
    boxSizing: "border-box",
  }, 
  fileName: {
    flex: "1",
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "var(--font-averta)",
    color: "#353F50",
    textAlign: "left",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  separator: {
    position: "relative",
    backgroundColor: "#EAECF0",
    border: "1px solid var(--buttons-outline-stroke-button-stroke-enabled)",
    height: "1px",
  },
};


export default AttachmentComponent;
