import React, { useState, useEffect } from "react";
import "./style.css";
import {
  arrowBackIcon,
  busSideIcon,
  ArrowDownIcon,
  timeIcon,
  passengerIcon,
  arrowRightIcon,
  seatIcon,
  clockIcon,
  arrowBlackRightIcon,
} from "../../../../../../icons";
import moment from "moment";
import { Dropdown, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { formatAmount } from "../../../../../../utils/formatters";
import PropTypes from "prop-types";

function formatTime(timeString) {
  const [hours, minutes] = timeString.split(":");
  const date = new Date();
  date.setHours(parseInt(hours, 10));
  date.setMinutes(parseInt(minutes, 10));
  let hours12 = date.getHours() % 12;
  hours12 = hours12 ? hours12 : 12;
  const minutes12 = date.getMinutes();
  const ampm = date.getHours() >= 12 ? "PM" : "AM";

  return `${hours12}:${minutes12.toString().padStart(2, "0")} ${ampm}`;
}

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div className="toolbar-right">
    <button className="action-button" ref={ref} onClick={onClick}>
      {children}
    </button>
  </div>
));

CustomToggle.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

const SearchBar = ({ from, to }) => (
  <div className="search-bar">
    <input type="text" placeholder={from} disabled className="search-input" />
    <span className="bus-icon">{busSideIcon()}</span>
    <input disabled type="text" placeholder={to} className="search-input" />
  </div>
);

SearchBar.propTypes = {
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

const TripMetaInfo = ({ date, adultCount, childCount }) => (
  <div className="view-button" style={{ display: "flex", gap: "20px" }}>
    <p style={{ fontSize: "14px" }}>
      {timeIcon()} {moment(date).format("ll")}
    </p>
    <p style={{ fontSize: "14px" }}>
      {passengerIcon()} {adultCount} Adult, {childCount} Children
    </p>
    <span>{arrowRightIcon()}</span>
  </div>
);

TripMetaInfo.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
    .isRequired,
  adultCount: PropTypes.number.isRequired,
  childCount: PropTypes.number.isRequired,
};

const TabButton = ({ active, onClick, label, disabled = false }) => (
  <button
    className={`view-button ${active ? "active" : ""}`}
    onClick={onClick}
    disabled={disabled}
  >
    {label}
  </button>
);

TabButton.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

const OperatorFilters = ({ operators, filterCriteria, setFilterCriteria }) => (
  <>
    {operators.map((operator, index) => (
      <Form.Check
        key={index}
        type="radio"
        id={`operator-${index}`}
        label={operator}
        name="operator"
        checked={filterCriteria.operator === operator}
        onChange={() => setFilterCriteria({ ...filterCriteria, operator })}
        className="mb-3"
      />
    ))}
  </>
);

OperatorFilters.propTypes = {
  operators: PropTypes.arrayOf(PropTypes.string).isRequired,
  filterCriteria: PropTypes.shape({
    operator: PropTypes.string,
  }).isRequired,
  setFilterCriteria: PropTypes.func.isRequired,
};

const BusFeatureFilters = ({ filterCriteria, setFilterCriteria }) => (
  <>
    <Form.Check
      type="checkbox"
      id="feature-ac"
      label="Air Conditioning"
      name="ac"
      className="mb-3"
      onChange={(e) =>
        setFilterCriteria({
          ...filterCriteria,
          features: { ...filterCriteria.features, ac: e.target.checked },
        })
      }
      checked={filterCriteria.features.ac}
    />
    <Form.Check
      type="checkbox"
      id="feature-legroom"
      label="Extra Leg Room"
      name="legroom"
      className="mb-3"
      onChange={(e) =>
        setFilterCriteria({
          ...filterCriteria,
          features: { ...filterCriteria.features, legRoom: e.target.checked },
        })
      }
      checked={filterCriteria.features.legRoom}
    />
  </>
);

BusFeatureFilters.propTypes = {
  filterCriteria: PropTypes.shape({
    features: PropTypes.shape({
      ac: PropTypes.bool,
      legRoom: PropTypes.bool,
    }),
  }).isRequired,
  setFilterCriteria: PropTypes.func.isRequired,
};

const SortOption = ({ id, label, checked, onChange }) => (
  <Form.Check
    type="radio"
    id={id}
    label={label}
    name="sort"
    className="mb-3"
    checked={checked}
    onChange={onChange}
  />
);

SortOption.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

const RouteInfo = ({ origin, destination }) => (
  <span style={{ marginBottom: "5px", fontSize: "12px", fontWeight: 500 }}>
    {origin} <span style={{ fontWeight: 600 }}>to</span> {destination}
  </span>
);

RouteInfo.propTypes = {
  origin: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
};

const DepartureTime = ({ time }) => (
  <div className="departure-time">
    {clockIcon()} {formatTime(time)}
  </div>
);

DepartureTime.propTypes = {
  time: PropTypes.string.isRequired,
};

const PriceInfo = ({ price, childPrice }) => (
  <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
    <div>
      <div
        style={{ display: "flex", flexDirection: "column", gap: "-2px" }}
        className="price-info"
      >
        <span className="current-price">₦{formatAmount(price)}</span>
        {childPrice?.originalPrice && (
          <ChildPrice price={childPrice.originalPrice} />
        )}
      </div>
    </div>
    {arrowBlackRightIcon()}
  </div>
);

PriceInfo.propTypes = {
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  childPrice: PropTypes.shape({
    originalPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

const ChildPrice = ({ price }) => (
  <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
    <span style={{ fontSize: "10px", marginRight: "5px" }}>Children</span>
    <span className="children-price">₦{formatAmount(price)}</span>
  </div>
);

ChildPrice.propTypes = {
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

const FilterDropdown = ({
  operators,
  filterCriteria,
  setFilterCriteria,
  activeFilterTab,
  setActiveFilterTab,
}) => (
  <Dropdown>
    <Dropdown.Toggle as={CustomToggle} id="filter-dropdown">
      Filter {ArrowDownIcon()}
    </Dropdown.Toggle>
    <Dropdown.Menu className="p-3" style={{ width: "350px" }}>
      <div className="pb-2 border-bottom mb-2">
        <h5>Filter By</h5>
      </div>
      <div className="toolbar">
        <div className="toolbar-left">
          <TabButton
            active={activeFilterTab === "Transport Operators"}
            onClick={() => setActiveFilterTab("Transport Operators")}
            label="Transport Operators"
          />
          <TabButton
            active={activeFilterTab === "Bus Features"}
            onClick={() => setActiveFilterTab("Bus Features")}
            label="Bus Features"
            disabled
          />
        </div>
      </div>

      <div className="tab-content">
        {activeFilterTab === "Transport Operators" && (
          <OperatorFilters
            operators={operators}
            filterCriteria={filterCriteria}
            setFilterCriteria={setFilterCriteria}
          />
        )}

        {activeFilterTab === "Bus Features" && (
          <BusFeatureFilters
            filterCriteria={filterCriteria}
            setFilterCriteria={setFilterCriteria}
          />
        )}
      </div>
    </Dropdown.Menu>
  </Dropdown>
);

FilterDropdown.propTypes = {
  operators: PropTypes.arrayOf(PropTypes.string).isRequired,
  filterCriteria: PropTypes.shape({
    operator: PropTypes.string,
    features: PropTypes.shape({
      ac: PropTypes.bool,
      legRoom: PropTypes.bool,
    }),
  }).isRequired,
  setFilterCriteria: PropTypes.func.isRequired,
  activeFilterTab: PropTypes.string.isRequired,
  setActiveFilterTab: PropTypes.func.isRequired,
};

const SortDropdown = ({ sortOption, setSortOption }) => (
  <Dropdown>
    <Dropdown.Toggle as={CustomToggle} id="sort-dropdown">
      Sort {ArrowDownIcon()}
    </Dropdown.Toggle>
    <Dropdown.Menu className="p-3" style={{ width: "240px" }}>
      <div className="pb-2 mb-2">
        <h5>Sort By</h5>
      </div>
      <SortOption
        id="sort-all"
        label="All"
        checked={sortOption === "All"}
        onChange={() => setSortOption("All")}
      />
      <SortOption
        id="sort-lowest-highest"
        label="Lowest to highest price"
        checked={sortOption === "Lowest to highest price"}
        onChange={() => setSortOption("Lowest to highest price")}
      />
      <SortOption
        id="sort-highest-lowest"
        label="Highest to lowest price"
        checked={sortOption === "Highest to lowest price"}
        onChange={() => setSortOption("Highest to lowest price")}
      />
    </Dropdown.Menu>
  </Dropdown>
);

SortDropdown.propTypes = {
  sortOption: PropTypes.string.isRequired,
  setSortOption: PropTypes.func.isRequired,
};

const TripCard = ({ trip, onClick }) => (
  <div
    className="trip-card"
    tabIndex={0}
    onClick={onClick}
    onKeyDown={(e) => {
      if (e.key === "Enter" || e.key === " ") {
        onClick();
      }
    }}
    role="button"
    aria-label={`Select trip from ${trip.origin} to ${trip.destination}`}
  >
    <div className="trip-info">
      <img alt="Logo" className="company-logo" src={trip?.busImage} />
      <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
        <p className="vehicle-name">{trip?.vehicleName}</p>
        <p className="seats-available">
          {seatIcon()} {trip?.availableSeats} available
        </p>
      </div>
    </div>
    <div style={{ width: "10rem" }}>
      <RouteInfo origin={trip.origin} destination={trip.destination} />
      <DepartureTime time={trip?.departureTime} />
    </div>
    <PriceInfo price={trip?.price} childPrice={trip?.childPrice} />
  </div>
);

TripCard.propTypes = {
  trip: PropTypes.shape({
    origin: PropTypes.string,
    destination: PropTypes.string,
    busImage: PropTypes.string,
    vehicleName: PropTypes.string,
    availableSeats: PropTypes.number,
    departureTime: PropTypes.string,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    childPrice: PropTypes.shape({
      originalPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    operatorName: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

const AvailableTripsScene = ({
  setScreen,
  childCount,
  selectedToTerminal,
  selectedFromTerminal,
  adultCount,
  date,
  trips,
  setSelectedTrip,
}) => {
  const [activeButton, setActiveButton] = useState("one-way");
  const [filteredTrips, setFilteredTrips] = useState(
    trips?.oneWay?.content || []
  );
  const [activeFilterTab, setActiveFilterTab] = useState("Transport Operators");
  const [filterCriteria, setFilterCriteria] = useState({
    operator: "All",
    features: { ac: false, legRoom: false, pickup: false },
  });
  const [sortOption, setSortOption] = useState("All");

  const operators = [
    "All",
    ...new Set(trips?.oneWay?.content?.map((trip) => trip.operatorName) || []),
  ];

  useEffect(() => {
    applyFilters();
  }, [filterCriteria, sortOption, trips]);

  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
  };

  const applyFilters = () => {
    let filtered = [...(trips?.oneWay?.content || [])];

    if (filterCriteria.operator !== "All") {
      filtered = filtered.filter(
        (trip) => trip.operatorName === filterCriteria.operator
      );
    }

    if (sortOption === "Lowest to highest price") {
      filtered.sort((a, b) => {
        const priceA = parseFloat(a.price.replace(/[^0-9.]/g, ""));
        const priceB = parseFloat(b.price.replace(/[^0-9.]/g, ""));
        return priceA - priceB;
      });
    } else if (sortOption === "Highest to lowest price") {
      filtered.sort((a, b) => {
        const priceA = parseFloat(a.price.replace(/[^0-9.]/g, ""));
        const priceB = parseFloat(b.price.replace(/[^0-9.]/g, ""));
        return priceB - priceA;
      });
    }

    setFilteredTrips(filtered);
  };

  const handleSelectTrip = (trip) => {
    setSelectedTrip(trip);
    setScreen(5);
  };

  return (
    <div className="booking-container">
      <button className="back-button" onClick={() => setScreen(3)}>
        {arrowBackIcon()} Back
      </button>

      <h4 className="container-header">Available trips</h4>

      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="toolbar">
          <div className="toolbar-left">
            <TabButton
              active={activeButton === "one-way"}
              onClick={() => handleButtonClick("one-way")}
              label="One-way"
            />
            <TabButton
              active={activeButton === "return"}
              onClick={() => handleButtonClick("return")}
              label="Return"
              disabled
            />
          </div>
        </div>

        <div className="d-flex">
          <FilterDropdown
            operators={operators}
            filterCriteria={filterCriteria}
            setFilterCriteria={setFilterCriteria}
            activeFilterTab={activeFilterTab}
            setActiveFilterTab={setActiveFilterTab}
          />

          <SortDropdown sortOption={sortOption} setSortOption={setSortOption} />
        </div>
      </div>

      <SearchBar
        from={selectedFromTerminal?.value}
        to={selectedToTerminal?.value}
      />

      <TripMetaInfo
        date={date}
        adultCount={adultCount}
        childCount={childCount}
      />

      <p>{filteredTrips?.length} Results</p>

      <div className="">
        {filteredTrips.map((trip) => (
          <TripCard
            key={trip.id}
            trip={trip}
            onClick={() => handleSelectTrip(trip)}
          />
        ))}
      </div>
    </div>
  );
};

// AvailableTripsScene
AvailableTripsScene.propTypes = {
  setScreen: PropTypes.func.isRequired,
  childCount: PropTypes.number.isRequired,
  selectedToTerminal: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
  selectedFromTerminal: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
  adultCount: PropTypes.number.isRequired,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
    .isRequired,
  trips: PropTypes.shape({
    oneWay: PropTypes.shape({
      content: PropTypes.array,
    }),
  }).isRequired,
  setSelectedTrip: PropTypes.func.isRequired,
};

export default AvailableTripsScene;
