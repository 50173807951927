import React, { useState } from 'react';
import { navigateTo } from '../../../../../../services/redux/actions/navigation';
import { connect } from 'react-redux';
import CommisionsLabel from '../components/commisionsLabel';
import IosArrowForward from 'react-ionicons/lib/IosArrowForward';
import H1 from '../../../../../../components/h1';
import InputText from '../../component/input';
import PrimaryButton from '../../component/button';
import { stripNonNumbers } from '../../component/extras';
import { formatAmount } from '../../../../../../utils/formatters';
import {
  firetoast,
  firetoastAggregator,
} from '../../../../../../components/extras';
import { ERROR_STATUS, SUCCESS_STATUS } from '../../../../../../constants/api';
import Transaction from '../../../../../../services/api/resources/transaction';
import {
  convertNgkToNgn,
  convertNgnToNgk,
} from '../../../../../../utils/converters/currencies';
const UnloadBalanceSceen = (props) => {
  const transaction = new Transaction();
  const [amount, setAmount] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const balance = convertNgkToNgn(props.location.state);
  const navigateToOverview = () => {
    props.navigateTo('/dashboard');
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    const { status, response } = await transaction.unloadCommission(
      convertNgnToNgk(amount)
    );
    setIsLoading(false);
    if (status === ERROR_STATUS) {
      firetoast('error', `${response.description}`, '');
    } else if (status === SUCCESS_STATUS) {
      firetoastAggregator(
        'success',
        'Unload successful',
        `Your unloaded commission of ₦${formatAmount(
          amount
        )} are now available in your transaction wallet.`,
        'View Transaction History',
        navigateToOverview
      );
    }
  };

  return (
    <main>
      <CommisionsLabel />
      <section className='aggregator-layout'>
        <div className='unloadContainer'>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
              marginBottom: '10px',
            }}
          >
            <span
              style={{
                fontSize: '0.75rem',
                fontWeight: '400',
                cursor: 'pointer',
              }}
              onClick={() => props.navigateTo('/commissions')}
            >
              Earnings
            </span>
            <IosArrowForward color='#6B7280' fontSize='12px' />
            <span
              style={{
                fontSize: '0.75rem',
                fontWeight: '400',
              }}
            >
              Unload Commission
            </span>
          </div>
          <H1
            style={{
              textAlign: 'start',
              marginBottom: '2.5rem',
            }}
          >
            Unload Commissions
          </H1>
          <div className='unloadBalanceCard'>
            <p className='text1'>Available Balance</p>
            <p className='text2'>₦{formatAmount(balance)}</p>
          </div>

          <InputText
            // disabled={true}
            label={'Amount'}
            placeholder={'0'}
            onChange={(e) => {
              setAmount(stripNonNumbers(e.target.value));
            }}
            value={`₦ ${amount}`}

            // maxLength={MAX_BVN_LENGTH}
          />

          {Number(amount) > Number(balance) && (
            <span
              style={{
                color: 'tomato',
                fontSize: '14px',
                position: 'relative',
                top: '-10px',
              }}
            >
              You cannot enter amount more than available
            </span>
          )}
          <PrimaryButton
            onClick={handleSubmit}
            disabled={!amount || isLoading || Number(amount) > Number(balance)}
          >
            {isLoading ? 'Processing...' : ' Unload Commissions'}
          </PrimaryButton>
        </div>
      </section>
    </main>
  );
};

function mapStateToProps(state) {
  return {
    isLoading: state.tunnel.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: (pendingUrl) => dispatch(navigateTo(pendingUrl)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UnloadBalanceSceen);
