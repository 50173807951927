import React from "react";

import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../constants/api";
import CountriesStatesLga from "../../../../fixtures/countries_states_lgas.json";
import Onboarding from "../../../../services/api/resources/onboarding";
import { AppProvider } from "../context";
import NextOfKinScene from "./nextOfKinScene";
import ResidentialAddressScene from "./residentialAddressScene";
import RefereeDetailsScene from "./refereeDetailsScene";
import TINScene from "./tinScene";
import BusinessDetailsScene from "./businessDetailsScene";

class SetupAgentNew extends React.Component {
    constructor(props) {
        super(props);
        this.myElementRef = React.createRef();
        this.state = {
            screen: 1,
            isLoading: false,
            application: null,
        };

        this.fetchApplication = this.fetchApplication.bind(this);
    }

    setScreen = (newScreen) => {
        this.setState({ screen: newScreen });
    };

    async fetchApplication() {
        const applicationId = localStorage.getItem("applicationId");
        const onboarding = new Onboarding();

        this.setState({ isLoading: true });
        const { status, response } = await onboarding.getApplicationAggregator(
            applicationId
        );

        localStorage.setItem("application", JSON.stringify(response));
        this.setState({ isLoading: false });
        if (status === ERROR_STATUS) {
        } else if (status === SUCCESS_STATUS) {
            this.setState({ isLoading: false });

            const stateArray = CountriesStatesLga[0].states.map((state) => {
                return {
                    name: state.name,
                    id: state.id,
                };
            });

            localStorage.setItem(
                "state",
                response?.businessDetails?.state
                    ? stateArray.find(
                        (value) => value.id === parseInt(response?.businessDetails?.state)
                    ).name
                    : ""
            );
        } else {
            this.setState({ isLoading: false });
        }
    }

    componentDidMount() {
        this.loadApplication();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLoading !== this.props.isLoading) {
            this.loadApplication();
        }
    }

    loadApplication() {
        const application = JSON.parse(localStorage.getItem("application"));
        this.setState({ application });
    }

    render() {
        const { screen } = this.state;
        const application = JSON.parse(localStorage.getItem("currentApplication"));

        return (
            <AppProvider>
                <div className="onboarding-layout">
                    {screen === 1 && (
                        <TINScene
                            application={application}
                            setScreen={this.setScreen}
                            props={this.props}
                            isLoading={this.state.isLoading}
                            fetchApplication={this.fetchApplication}
                        />
                    )}

                    {screen === 2 && (
                        <BusinessDetailsScene
                            application={application}
                            setScreen={this.setScreen}
                            screen={screen}
                        />
                    )}
                    {screen === 3 && (
                        <NextOfKinScene
                            application={application}
                            setScreen={this.setScreen}
                        />
                    )}
                    {screen === 4 && (
                        <ResidentialAddressScene
                            application={application}
                            setScreen={this.setScreen}
                        />
                    )}
                    {screen === 5 && (
                        <RefereeDetailsScene
                            application={application}
                            setScreen={this.setScreen}
                        />
                    )}
                </div>
            </AppProvider>
        );
    }
}

export default SetupAgentNew;
