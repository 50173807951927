import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import BaseScene from "../../../../base-scene";
import Button from "../../../../../components/button";
import FormControl from "../../../../../components/form-control";
import H1 from "../../../../../components/h1";

export default class NextOfKinDetailsForm extends BaseScene {
  state = {};

  render() {
    const { form } = this.props;
    // const screenSensitiveStyle = !this.state.deviceIsMobile ? {
    //   // width: '50%',
    //   // marginLeft: '25%',
    //   // marginTop: '50px'
    // } : { }

    return (
      <Form>
        <div>
          <H1
            ref={this.props.formHeaderRef}
            style={{
              color: "#3A3B3B",
              fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
              fontSize: "24px",
              fontWeight: "400",
              padding: "35px",
              paddingLeft: 0,
              textAlign: "left",
            }}
          >
            Next of Kin Details
          </H1>

          <Row>
            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label
                  style={{
                    color: "#3A3B3B",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  First Name:
                </Form.Label>
                <FormControl
                  type="text"
                  placeholder="First name"
                  onChange={(event) => {
                    const nextOfKinFirstName = event.target.value;
                    this.updateFormField({ nextOfKinFirstName });
                  }}
                  style={{
                    backgroundColor: "#E1E6ED",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.firstName || this.isFirstNameValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                  }}
                  value={form.nextOfKinFirstName}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label
                  style={{
                    color: "#3A3B3B",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  First Name:
                </Form.Label>
                <FormControl
                  type="text"
                  placeholder="Middle Name"
                  onChange={(event) => {
                    const nextOfKinFirstName = event.target.value;
                    this.updateFormField({ nextOfKinFirstName });
                  }}
                  style={{
                    backgroundColor: "#E1E6ED",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.firstName || this.isFirstNameValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                  }}
                  value={form.nextOfKinMiddleName}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label
                  style={{
                    color: "#3A3B3B",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  Surname:
                </Form.Label>
                <FormControl
                  type="text"
                  placeholder="Last Name"
                  onChange={(event) => {
                    const firstName = event.target.value;
                    this.updateFormField({ firstName });
                  }}
                  style={{
                    backgroundColor: "#E1E6ED",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.firstName || this.isFirstNameValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                  }}
                  value={form.nextOfKinLastName}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label
                  style={{
                    color: "#3A3B3B",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  Phone Number:
                </Form.Label>
                <FormControl
                  type="number"
                  placeholder="Phone Number"
                  onChange={(event) => {
                    const nextOfKinMobileNo = event.target.value;
                    this.updateFormField({ nextOfKinMobileNo });
                  }}
                  style={{
                    backgroundColor: "#E1E6ED",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.lastName || this.isLastNameValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                  }}
                  value={form.nextOfKinMobileNo}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label
                  style={{
                    color: "#3A3B3B",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  Email Address:
                </Form.Label>
                <FormControl
                  type="text"
                  placeholder="Email"
                  onChange={(event) => {
                    const nextOfKinMobileNo = event.target.value;
                    this.updateFormField({ nextOfKinMobileNo });
                  }}
                  style={{
                    backgroundColor: "#E1E6ED",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.lastName || this.isLastNameValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                  }}
                  value={form.nextOfKinMobileNo}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label
                  style={{
                    color: "#3A3B3B",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  Home Address:
                </Form.Label>
                <FormControl
                  type="text"
                  placeholder="Home Address"
                  onChange={(event) => {
                    const nextOfKinAddress = event.target.value;
                    this.updateFormField({ nextOfKinAddress });
                  }}
                  style={{
                    backgroundColor: "#E1E6ED",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.lastName || this.isLastNameValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                  }}
                  value={form.nextOfKinAddress}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label
                  style={{
                    color: "#3A3B3B",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  Relationship:
                </Form.Label>
                <FormControl
                  type="text"
                  placeholder="Relationship"
                  onChange={(event) => {
                    const nextOfKinRelationship = event.target.value;
                    this.updateFormField({ nextOfKinRelationship });
                  }}
                  style={{
                    backgroundColor: "#E1E6ED",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.lastName || this.isLastNameValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                  }}
                  value={form.nextOfKinRelationship}
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>
        </div>

        {/* <center style={{marginTop: '10px'}}>
        <Button onClick={() => {
          this.props.onSubmit()
        }}>Next</Button>
      </center> */}
      </Form>
    );
  }
}
