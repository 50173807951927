import React, { useEffect, useState } from "react";
import BackButton from "../../../component/backButton";
import { Col, Form } from "react-bootstrap";
import Title from "./title";
import H1 from "../../../../../../../components/h1";
import InputText from "../../../component/input";
import Select from "react-select";
import { errorResponse, isBusinessLgaValid } from "../../../component/extras";
import { SelectBusinessType } from "../../../component/stateSelect";
import FormLabel from "../../../../../../../components/form-label";
import PrimaryButton from "../../../component/button";
import {
  ERROR_STATUS,
  SUCCESS_STATUS,
} from "../../../../../../../constants/api";
import Transaction from "../../../../../../../services/api/resources/transaction";
import { RegexChecker } from "../../../../../../../utils/regex-checker";
import Platform from "../../../../../../../services/api/resources/platform";
import { firetoast, notify } from "../../../../../../../components/extras";
import IosRefresh from "react-ionicons/lib/IosRefresh";
import CountriesStatesLga from "../../../../../../../fixtures/countries_states_lgas.json";

const MAX_BUSINESS_NAME_LENGTH = 50;
const BusinessDetailsScene = ({ setScreen, application }) => {
  const platform = new Platform();
  const upgradeTo = localStorage.getItem("upgradeTo");
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoadingBusinessType, setIsLoadingBusinessType] = useState(false);
  const [businessTypesData, setBusinessTypesData] = useState([]);
  const [banks, setBanks] = useState([]);

  const businessType = localStorage.getItem("businessType") || "";

  const businessTypeId =
    businessTypesData?.filter((item) => item?.name === businessType)[0]?.id ||
    "";

  const updatedbusinessDetailsForm = JSON.parse(
    localStorage.getItem("businessDetailsFormUpgrade")
  );
  const personalDetailsForm = JSON.parse(
    localStorage.getItem("personalDetailsFormUpgrade")
  );

  let [businessDetailsForm, setBusinessDetailsForm] = useState({
    state: application
      ? application?.businessLocation[0]?.stateId
      : updatedbusinessDetailsForm
      ? updatedbusinessDetailsForm?.state
      : "",
    businessAddress: application
      ? application?.businessLocation[0]?.addressLine1
      : updatedbusinessDetailsForm
      ? updatedbusinessDetailsForm?.businessAddress
      : "",
    businessName: application
      ? application?.businessName
      : updatedbusinessDetailsForm
      ? updatedbusinessDetailsForm?.businessName
      : "",
    localGovernmentArea: application
      ? application?.businessLocation[0]?.lgaId
      : updatedbusinessDetailsForm
      ? updatedbusinessDetailsForm?.localGovernmentArea
      : "",
    businessType: application?.businessType || businessTypeId,

    bank:
      { label: application?.bankName, value: "" } ||
      updatedbusinessDetailsForm?.bank,

    accountNumber:
      application?.accountNo || updatedbusinessDetailsForm?.accountNumber,
  });
  localStorage.setItem(
    "businessDetailsFormUpgrade",
    JSON.stringify(businessDetailsForm)
  );

  const selectedStateData = CountriesStatesLga[0].states.find(
    (state) => state.id === Number(businessDetailsForm?.state)
  );

  const lgaWithId = selectedStateData?.lgas?.find(
    (lga) => lga.id === Number(businessDetailsForm?.localGovernmentArea)
  );

  const updateFormField = (params) => {
    if (params.state) {
      const newForm = {
        ...businessDetailsForm,
        localGovernmentArea: "",
        ...params,
      };
      setBusinessDetailsForm(newForm);
    } else {
      const newForm = {
        ...businessDetailsForm,

        ...params,
      };
      setBusinessDetailsForm(newForm);
    }
  };

  const isFormValid = () => {
    return (
      businessDetailsForm.businessType &&
      businessDetailsForm.bank &&
      businessDetailsForm.accountNumber &&
      isBusinessLgaValid(businessDetailsForm) &&
      isAccountNumberComplete(businessDetailsForm)
    );
  };
  const transaction = new Transaction();

  const getBanks = async () => {
    setLoading(true);

    const { status, response } = await transaction.getUnifiedBanks();
    setLoading(false);

    if (status === ERROR_STATUS) {
    } else if (status === SUCCESS_STATUS) {
      setBanks(response);
    }
  };

  useEffect(() => {
    getBanks();
  }, []);

  const upgradeClass = () => {
    const upgradeToMap = {
      prestige: 3,
      Standard: 2,
    };

    return upgradeToMap[upgradeTo] || "";
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const payload = {
      newClassId: upgradeClass(),

      homeAddressStateId: personalDetailsForm.state
        ? personalDetailsForm.state
        : "",
      homeAddressLgaId: personalDetailsForm.localGovernmentArea
        ? personalDetailsForm.localGovernmentArea
        : "",

      motherMaidenName: personalDetailsForm.motherMaidenName,
      businessTypeId: businessDetailsForm?.businessType?.toString(),
      bank: businessDetailsForm.bank.label,
      accountNumber: businessDetailsForm.accountNumber,
    };

    const { status, response } = await platform.postAggregatorClassUpgrage(
      payload,
      application.agentCode
    );
    setIsLoading(false);
    if (status === ERROR_STATUS) {
      errorResponse(response.code, response.description);
    } else if (status === SUCCESS_STATUS) {
      notify("Saved", "success");
      setScreen(4);
    }
  };

  const customStylesBanks = {
    option: (provided, state) => ({
      ...provided,
      fontSize: 14,
    }),
    control: (base) => ({
      ...base,
      minHeight: 48,
      backgroundColor: "transparent",
    }),
    singleValue: (provided, state) => {
      const fontSize = 14;
      return { ...provided, fontSize };
    },
  };

  const isAccountNumberComplete = () => {
    return RegexChecker.accountNumber.test(businessDetailsForm?.accountNumber);
  };

  const fetchBusinessTypes = async () => {
    //  getBusinessType
    setIsLoadingBusinessType(true);
    const { status, response } = await platform.getBusinessType();
    setIsLoadingBusinessType(false);

    if (status === ERROR_STATUS) {
    } else if (status === SUCCESS_STATUS) {
      setBusinessTypesData(response);
    }
  };

  useEffect(() => {
    fetchBusinessTypes();
  }, []);

  return (
    <>
      <BackButton
        onClick={() => {
          setScreen(1);
          localStorage.removeItem("businessDetailsFormUpgrade");
          localStorage.removeItem("businessType");
        }}
      />
      <main className="aggregator-container">
        <Col
          style={{
            marginBottom: "20px",
            margin: "0 auto",
          }}
        >
          <Title upgradeTo={upgradeTo} />
          <H1
            style={{
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "1rem",
            }}
          >
            Business Details
          </H1>
          <InputText
            label={"Business Name"}
            onChange={(e) => {
              updateFormField({ businessName: e.target.value });
            }}
            disabled={true}
            placeholder={"Enter Business Name"}
            value={businessDetailsForm.businessName}
            maxLength={MAX_BUSINESS_NAME_LENGTH}
          />
          <InputText
            label={"Business Address"}
            placeholder={"Enter Business Address"}
            onChange={(e) => {
              updateFormField({ businessAddress: e.target.value });
            }}
            disabled={true}
            maxLength={200}
            value={businessDetailsForm.businessAddress}
          />
          <InputText
            label={"State"}
            placeholder={"state"}
            disabled={true}
            onChange={(e) => {
              updateFormField({ state: e.target.value });
            }}
            maxLength={200}
            value={selectedStateData?.name}
          />
          <InputText
            disabled={true}
            label={"Local Government Area"}
            placeholder={"LGA"}
            onChange={(e) => {
              updateFormField({ localGovernmentArea: e.target.value });
            }}
            maxLength={200}
            value={lgaWithId?.name}
          />
          <SelectBusinessType
            border={`1px solid ${
              !isBusinessLgaValid(localStorage.getItem("businessType")) &&
              !businessDetailsForm.businessType
                ? process.env.REACT_APP_RED_COLOUR
                : "#DFE2E6"
            }`}
            label={"Business Type"}
            option={businessTypesData}
            value={businessDetailsForm?.businessType}
            getBusinessType={localStorage.getItem("businessType")}
            placeholder={"Business Type"}
            onChange={(e) => {
              localStorage.setItem(
                "businessType",
                businessTypesData.find(
                  (value) => value.business_type === e.target.value
                ).business_type
              );
              updateFormField({
                businessType: businessTypesData.find(
                  (value) => value.business_type === e.target.value
                ).id,
              });
            }}
          />
          {isLoadingBusinessType && (
            <IosRefresh
              style={{
                marginTop: "-52pt",
                marginLeft: "85%",
                width: "20px",
                position: "absolute",
              }}
              fontSize="60px"
              color="grey"
              rotate={true}
            />
          )}

          <Form.Group
            style={{
              padding: 0,
              marginTop: "15px",
            }}
          >
            <FormLabel
              style={{
                color: "#3A3B3B",
                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                lineHeight: "19px",
              }}
            >
              Bank:{" "}
            </FormLabel>
            <Select
              options={banks?.map((bank) => {
                return { label: bank.bankName, value: bank.cbnCode };
              })}
              defaultValue={
                businessDetailsForm?.bank.label && {
                  label: businessDetailsForm?.bank.label,
                }
              }
              isLoading={loading}
              onChange={(event) => {
                const bank = event;

                updateFormField({ bank: bank });
              }}
              styles={customStylesBanks}
              className="select-input-container-banks"
              classNamePrefix="react-select"
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "rgb(178, 212, 255)",
                },
              })}
            />
          </Form.Group>
          <InputText
            label={"Account Number"}
            placeholder={"Enter Account Number"}
            onChange={(e) => {
              const inputValue = e.target.value;
              const numericValue = inputValue.replace(/\D/g, "");

              updateFormField({ accountNumber: numericValue });
            }}
            labelColor={
              businessDetailsForm.accountNumber &&
              isAccountNumberComplete(businessDetailsForm)
                ? "#3A3B3B"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              businessDetailsForm.accountNumber &&
              isAccountNumberComplete(businessDetailsForm)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            maxLength={10}
            value={businessDetailsForm.accountNumber}
          />

          <PrimaryButton
            onClick={handleSubmit}
            disabled={!isFormValid() || isLoading}
          >
            {isLoading ? "Processing..." : "Save & Continue"}
          </PrimaryButton>
        </Col>
      </main>
    </>
  );
};

export default BusinessDetailsScene;
