import React from "react";
import { Col, Row } from "react-bootstrap";
import MdMenu from "react-ionicons/lib/MdMenu";
import { Link } from "react-router-dom";
import BaseScene from "../scenes/base-scene";
import Button from "./button";

export default class Header extends BaseScene {
  state = {};

  constructor() {
    super();
    this.listenScrollEvent = this.listenScrollEvent.bind(this);
  }

  listenScrollEvent(event) {
    this.setState({
      tint: window.scrollY > 10,
    });
  }

  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent);
  }

  get currentApplication() {
    return JSON.parse(localStorage.getItem("currentApplication"));
  }

  renderMobileHeader() {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    return (
      <Row
        className={`header`}
        style={{
          boxShadow: this.state.tint
            ? "10px 5px 10px -8px rgba(0,0,0,0.5)"
            : "none",
          backgroundColor: this.props.transparent ? "transparent" : "white",
          display: "flex",
          justifyContent: "space-between",
          marginRight: 0,
          margin: "0px",
          paddingLeft: this.state.deviceIsMobile ? "5px" : "10px",
          paddingRight: "0px",
          position: "fixed",
          width: "100%",
          zIndex: 50,
          ...this.props.outerDivStyle,
          backgroundColor: this.state.tint
            ? this.props.outerDivStyle
              ? this.props.outerDivStyle.backgroundColor
              : "white"
            : this.props.outerDivStyle
            ? this.props.outerDivStyle.backgroundColor
            : "transparent",
        }}
      >
        {this.props.hasNavBar && (
          <Col xs={1} md={1} className="mobile-header-menu-icon-container">
            <MdMenu
              color={process.env.REACT_APP_BLUE_COLOUR}
              fontSize={32}
              style={{
                alignSelf: "center",
                justifyContent: "center",
                marginRight: "5px",
              }}
              onClick={this.props.showNavBar}
            />
          </Col>
        )}

        <Col xs={5} md={5} className="mobile-header-icon-contianer">
          <Link to="/" onClick={() => (window.location.href = "/")}>
            <img
              src={
                "https://mufasa.interswitchng.com/p/quickteller-paypoint/Assets/header-logo.svg"
              }
              height={"70px"}
              color={"blue"}
              style={{
                alignSelf: "center",
                justifyContent: "center",
                marginLeft: "-10px",
                padding: "10px",
                paddingLeft: "0px",
                paddingTop: "0px",
                paddingBottom: "0px",
              }}
              alt=""
            />
          </Link>
        </Col>

        <Col
          xs={6}
          md={6}
          className="mobile-header-login-signup-buttons-container"
        >
          {this.props.hideLogin ? (
            <React.Fragment>
              {process.env.REACT_APP_SHOW_NEW_SIGNUP === "true" ? (
                <Link to="/signup">
                  <Button transparent className="mobile-header-signup-button">
                    SIGNUP
                  </Button>
                </Link>
              ) : (
                <Link to="/signupOld">
                  <Button transparent className="mobile-header-signup-button">
                    SIGNUP
                  </Button>
                </Link>
              )}
            </React.Fragment>
          ) : this.props.showLoginAndSignup ? (
            <React.Fragment>
              <Link to="/login">
                <Button transparent className="mobile-header-login-button">
                  LOGIN
                </Button>
              </Link>
              {process.env.REACT_APP_SHOW_NEW_SIGNUP === "true" ? (
                <Link to="/signup">
                  <Button transparent className="mobile-header-signup-button">
                    SIGNUP
                  </Button>
                </Link>
              ) : (
                <Link to="/signupOld">
                  <Button transparent className="mobile-header-signup-button">
                    SIGNUP
                  </Button>
                </Link>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {currentUser ? (
                ""
              ) : (
                <Link to="/login">
                  <Button transparent className="mobile-header-login-button">
                    LOGIN
                  </Button>
                </Link>
              )}
            </React.Fragment>
          )}
        </Col>
      </Row>
    );
  }

  renderDesktopHeader() {
    return (
      <div
        className="header"
        style={{
          boxShadow: this.state.tint
            ? "0px 5px 10px -8px rgba(0,0,0,0.5)"
            : "none",
          position: "fixed",
          zIndex: 50,
          width: "100%",
          ...this.props.outerDivStyle,
          backgroundColor: this.state.tint
            ? this.props.outerDivStyle
              ? this.props.outerDivStyle.backgroundColor
              : "white"
            : "transparent",
        }}
      >
        <Row className="desktop-header-container-1">
          <Col className="desktop-header-container-2">
            <div
              className="desktop-header-container-3"
              style={{
                backgroundColor: this.props.transparent
                  ? "transparent"
                  : "white",
              }}
            >
              <Link to="/">
                <img
                  src={
                    "https://mufasa.interswitchng.com/p/quickteller-paypoint/Assets/header-logo.svg"
                  }
                  style={{
                    cursor: "pointer",
                    marginLeft: "-20px",
                    marginTop: "-13px",
                  }}
                  height={"70px"}
                  color={"blue"}
                  alt=""
                />
              </Link>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {
                  this.props.hideLogin ? (
                    <React.Fragment>
                      {process.env.REACT_APP_SHOW_NEW_SIGNUP === "true" ? (
                        <Link to="/signup">
                          <Button
                            className="desktop-signup-button"
                            style={this.props.signupButtonStyle}
                          >
                            SIGNUP
                          </Button>
                        </Link>
                      ) : (
                        <Link to="/signupOld">
                          <Button
                            className="desktop-signup-button"
                            style={this.props.signupButtonStyle}
                          >
                            SIGNUP
                          </Button>
                        </Link>
                      )}
                    </React.Fragment>
                  ) : this.props.showLoginAndSignup ? (
                    <React.Fragment>
                      <Link to="/login">
                        <Button
                          className="desktop-login-button"
                          style={this.props.loginButtonStyle}
                        >
                          LOG IN
                        </Button>
                      </Link>
                      {process.env.REACT_APP_SHOW_NEW_SIGNUP === "true" ? (
                        <Link to="/signup">
                          <Button
                            className="desktop-signup-button"
                            style={this.props.signupButtonStyle}
                          >
                            SIGN UP
                          </Button>
                        </Link>
                      ) : (
                        <Link to="/signupOld">
                          <Button
                            className="desktop-signup-button"
                            style={this.props.signupButtonStyle}
                          >
                            SIGN UP
                          </Button>
                        </Link>
                      )}
                    </React.Fragment>
                  ) : (
                    ""
                  )
                  // localStorage.getItem('currentApplication') === null ?(
                  //   <React.Fragment>
                  //     <Link to="/login">
                  //       <Button
                  //         className="desktop-login-button"
                  //         style={this.props.loginButtonStyle}
                  //       >
                  //         LOGIN
                  //       </Button>
                  //     </Link>
                  //   </React.Fragment>
                  // ):(
                  //   <React.Fragment>
                  //   <Link to="/logout">
                  //     <Button
                  //       className="desktop-login-button"
                  //       style={this.props.loginButtonStyle}
                  //     >
                  //       LOGOUT
                  //     </Button>
                  //   </Link>
                  // </React.Fragment>
                  // )
                }
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.state.deviceIsPC
          ? this.renderDesktopHeader()
          : this.renderMobileHeader()}
      </React.Fragment>
    );
  }
}
