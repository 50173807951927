import React from "react";
import { Carousel, Col, Container, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { stopwatch, userManagementServiceClient } from "../../App";
import Button from "../../components/button";
import Footer from "../../components/footer";
import FormControl from "../../components/form-control";
import FormLabel from "../../components/form-label";
import H1 from "../../components/h1";
import Header from "../../components/header";
import HR from "../../components/hr";
import Text from "../../components/text";
import { NIGERIA_PHONE_PREFIX } from "../../constants";
import { ERROR_STATUS, SELECT_USER_PROFILE } from "../../constants/api";
import {
  dismissErrorMessage,
  showErrorMessage,
  updateLoading
} from "../../services/redux/actions/tunnel";
import { RECOVER_PASSWORD_REDIRECT_URL } from "../../utils/api-properties";
import { formatDomainTypeId, formatEmailOrPhone } from "../../utils/formatters";
import {
  isEmailValid,
  isPhoneValid
} from "../../utils/validators/form-validators";
import BaseScene from "../base-scene";
import "./style.scss";


class ForgottenPassword extends BaseScene {
  location = window.location.href;
  path = "/forgotten-password";
  title = "Forgotten Password";

  constructor() {
    super();

    this.submitForm = this.submitForm.bind(this);
    this.selectProfileToReset = this.selectProfileToReset.bind(this);
  }

  state = {
    form: {},
  };

  async submitForm() {
    const username = this.state.form.selectedProfile.username;
    const resetWith = this.state.form.selectedProfile.authenticatedAs;
    this.props.updateLoading(true, 0);

    setTimeout(
      () => this.props.isLoading && this.props.updateLoading(true, 66),
      200
    );

    const redirectUrl = RECOVER_PASSWORD_REDIRECT_URL;
    const recoverPasswordResponse = await userManagementServiceClient.recoverPassword(
      username,
      redirectUrl,
      resetWith
    );
    const recoverPasswordResponseStatus = recoverPasswordResponse.status;
    const recoverPasswordResponseResponse = recoverPasswordResponse.response;

    this.props.updateLoading(false);

    if (recoverPasswordResponseStatus === ERROR_STATUS) {
      this.props.showErrorMessage(
        JSON.stringify(recoverPasswordResponseResponse)
      );
      setTimeout(this.props.dismissErrorMessage, 3000);

      return;
    }

    if (recoverPasswordResponseResponse.uuid) {
      localStorage.setItem("uuid", recoverPasswordResponseResponse.uuid);

      this.setState({
        redirectPhone: true,
      });

      return;
    }

    localStorage.setItem("pendingEmail", this.state.form.email);

    this.setState({
      redirect: true,
    });
  }

  async selectProfileToReset() {
    const { email } = this.state.form;

    // logEvent(GET_PROF)
    this.props.updateLoading(true, 25);
    stopwatch.start();

    const { response } = await userManagementServiceClient.getUserProfile(
      formatEmailOrPhone(email)
    );

    if (response.code === SELECT_USER_PROFILE) {
      this.setState({
        showUserProfile: true,
        userProfiles: response.data,
      });
      this.props.updateLoading(false);
      return;
    }

    this.props.showErrorMessage(JSON.stringify(response));
    setTimeout(() => {
      this.props.dismissErrorMessage();
    }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
    this.props.updateLoading(false);
  }

  isEmailValid(newForm) {
    const form = newForm || this.state.form;
    var re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(form.email).toLowerCase());
  }

  updateFormField(params) {
    const newForm = {
      ...this.state.form,
      ...params,
    };

    this.setState({
      form: newForm,
      formIsValid:
        isPhoneValid(newForm.email, NIGERIA_PHONE_PREFIX) ||
        isEmailValid(newForm.email),
    });
  }

  backToResetPassword() {
    this.setState({
      showUserProfile: false,
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/forgotten-password-two" />;
    }

    if (this.state.redirectPhone) {
      return <Redirect to="/forgotten-password-three" />;
    }

    const userProfile =
      this.state.userProfiles &&
      this.state.userProfiles.count >= 1 &&
      this.state.userProfiles.userDomainTypes.filter((profile) => {
        return !profile.fipUser;
      });

    const card = (
      <React.Fragment>
        <H1 className="card-heading">Forgot Password?</H1>

        <center>
          <HR style={{ marginTop: "10px" }} />
        </center>

        <Text id="instructions">
          Enter the phone number you've registered with.
          <br />
          We'll send you the instructions there.
        </Text>

        <form onSubmit={() => this.selectProfileToReset()}>
          <Row className="regular-row">
            <Col md={12} lg={12} className="regular-col">
              <Form.Group>
                <FormLabel>Email or Phone:</FormLabel>
                <FormControl
                  className="form-control"
                  defaultValue={localStorage.getItem("pendingEmail")}
                  disabled={this.props.isLoading}
                  type="text"
                  placeholder="Email or Phone"
                  onChange={(event) => {
                    const email = event.target.value;
                    this.updateFormField({ email });
                  }}
                  style={{
                    border: `1px solid ${
                      !this.state.lastName || this.isLastNameValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                  }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row style={{ margin: "5px 0px 0px 0px" }}>
            <Col md={12} lg={12} style={{ margin: 0, padding: 0 }}>
              <Button
                disabled={!this.state.formIsValid || this.props.isLoading}
                onClick={this.selectProfileToReset}
                style={{ fontWeight: "bold", width: "100%" }}
              >
                {this.props.isLoading
                  ? "Loading..."
                  : "Select Profile to Reset"}
              </Button>
            </Col>
          </Row>

          <Row style={{ margin: 0 }}>
            <Col md={12} lg={12} style={{ margin: 0, padding: 0 }}>
              <div
                style={{
                  color: process.env.REACT_APP_LINK_COLOUR,
                  marginTop: "15px",
                }}
              >
                <Link to="/login">Back to Login</Link>
              </div>
            </Col>
          </Row>
        </form>
      </React.Fragment>
    );

    const selectUserProfile = (
      <Form>
        <H1
          style={{ textAlign: "center", margin: 0, textTransform: "uppercase" }}
        >
          {this.state.userProfiles && this.state.userProfiles.count === 0
            ? "No profile to reset"
            : "Select profile to reset"}
        </H1>
        <center>
          <HR style={{ marginTop: "10px" }} />
        </center>

        {/* {this.caseDescriptionLabel}
    {this.message} */}

        <form onSubmit={() => this.submitForm()}>
          {this.state.userProfiles &&
            this.state.userProfiles.count >= 1 &&
            userProfile.map((profile, index) => {
              return (
                <Row
                  key={index}
                  className="regular-row"
                  style={{ paddingBottom: "10px" }}
                >
                  <Col className="regular-col">
                    <div style={{ padding: "10px" }}>
                      <label>
                        <input
                          type="radio"
                          name="profileRadio"
                          value={JSON.stringify(profile)}
                          onChange={(event) => {
                            const selectedProfile = JSON.parse(
                              event.target.value
                            );
                            this.updateFormField({ selectedProfile });
                          }}
                        />
                        <div style={{ float: "right", paddingLeft: "10px" }}>
                          <h6
                            style={{ color: process.env.REACT_APP_BLUE_COLOUR }}
                          >
                            <b>{formatDomainTypeId(profile.domainTypeId)}</b>
                          </h6>
                          <span style={{ fontSize: "12px" }}>
                            {profile.domainName}
                          </span>
                          <br />
                          <span style={{ fontSize: "12px" }}>
                            {profile.roleName}
                          </span>
                        </div>
                      </label>
                    </div>
                  </Col>
                </Row>
              );
            })}

          {!(
            this.state.userProfiles && this.state.userProfiles.count === 0
          ) && (
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Button
                  type="submit"
                  disabled={
                    !this.state.form.selectedProfile || this.props.isLoading
                  }
                  style={{ fontWeight: "normal", width: "100%" }}
                  onClick={() => this.submitForm()}
                >
                  {this.props.isLoading ? "Loading..." : "Continue"}
                </Button>
              </Col>
            </Row>
          )}

          <Row style={{ margin: 0 }}>
            <Col md={12} lg={12} style={{ margin: 0, padding: 0 }}>
              <div
                style={{
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  paddingTop: "10px",
                }}
              >
                Reset password for another account?
                <span
                  style={{ cursor: "pointer", color: "blue" }}
                  onClick={() => this.backToResetPassword()}
                >
                  {" "}
                  Go Back
                </span>
              </div>
            </Col>
          </Row>
        </form>
      </Form>
    );

    const carousel = (
      <Carousel
        activeIndex={this.state.showUserProfile ? 1 : 0}
        controls={false}
        disabled
        indicators={false}
        interval={null}
        style={{
          transition: "all .2s ease-in-out",
        }}
      >
        <Carousel.Item>{card}</Carousel.Item>
        <Carousel.Item>{selectUserProfile}</Carousel.Item>
      </Carousel>
    );
    const desktopContainer = (
      <Container id="desktop-container" fluid>
        <Row style={{ margin: 0, padding: 0 }}>
          <Col
            xs={12}
            md={12}
            lg={6}
            style={{
              margin: 0,
              padding: 0,
              display: "flex",
              justifyContent: this.state.deviceIsPC ? "flex-start" : "center",
              marginLeft: this.state.deviceIsPC ? "10%" : "0%",
            }}
          >
            <div id="card-container">{carousel}</div>
          </Col>
        </Row>
      </Container>
    );

    const mobileContainer = (
      <Container id="mobile-container" fluid>
        {carousel}
      </Container>
    );

    return (
      <div id="login-main-container">
        <div>
          <img
            alt=""
            hidden={!this.state.deviceIsPC}
            src={"https://mufasa.interswitchng.com/p/quickteller/Content/Images/QT_paypoint_bg.png"}
            style={{ position: "absolute", right: "0px", zIndex: 0 }}
            height={"740px"}
            width={"1028px"}
          />
        </div>

        <Header
          transparent={true}
          outerDivStyle={{ backgroundColor: "white" }}
        />

        {this.state.deviceIsMobile ? mobileContainer : desktopContainer}

        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.tunnel.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dismissErrorMessage: () => dispatch(dismissErrorMessage()),
    showErrorMessage: (message) => dispatch(showErrorMessage(message)),
    updateLoading: (isLoading, percentage = null) =>
      dispatch(updateLoading({ isLoading, percentage })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgottenPassword);
