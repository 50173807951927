import React, { useEffect, useState } from "react";
import { BalanceCard } from "../balance/component/balanceCard";
import PrimaryButton from "../settings/components/customButton";
import ActivityIndicator from "../../../../../../components/activity-indicator";
import ReactTable from "react-table";
import { getPaginationRows } from "../../../../../../utils/helpers";
import TopAgents from "./topAgents";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../../../constants/api";
import AnalyticsService from "../../../../../../services/api/resources/analytics-service";
import Spinner from "../../../../../../components/spinner";
import Pagination from "../../../../../../components/pagination";

const Target = ({ setActiveKey }) => {
  const analyticsService = new AnalyticsService();
  const [isLoadingPerformance, setIsLoadingPerformance] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("Underperforming");
  const [performingType, setPerformingType] = useState("Underperforming");

  const [pages, setPages] = useState(1);
  const [performingAgentCount, setPerformingAgentCount] = useState([]);

  const [pageNum, setPageNum] = useState(1);
  const [dataCount, setDataCount] = useState();

  const [performingGrid, setPerformingGrid] = useState([]);
  const [isLoadingGrid, setIsLoadingGrid] = useState(false);

  const [pageSize, setPageSize] = useState(20);

  const filterStyle = (filterName) => {
    return {
      padding: ".5rem 1.5rem",
      backgroundColor: selectedFilter === filterName ? "#00425F" : "white",
      color:
        selectedFilter === filterName
          ? "white"
          : process.env.REACT_APP_BLUE_COLOUR,
      cursor: "pointer",
      border: "1px solid #E1E6ED",
    };
  };
  const handleFilterClick = (filterName) => {
    setSelectedFilter(filterName);

    if (filterName === "Underperforming") {
      setPerformingType("Underperforming");
    } else if (filterName === "Emerging-performing") {
      setPerformingType("Emerging");
    } else if (filterName === "Moderate") {
      setPerformingType("Moderate");
    } else if (filterName === "High-performing") {
      setPerformingType("High Performing");
    } else if (filterName === "Star-agent") {
      setPerformingType("Star Agent");
    } else {
      setPerformingType();
    }
  };

  const getPerformingAgentCount = async () => {
    setIsLoadingPerformance(true);
    const { response, status } =
      await analyticsService.getPerformingAgentCount();
    if (status === ERROR_STATUS) {
      setIsLoadingPerformance(false);
    } else if (status === SUCCESS_STATUS) {
      const Emerging = response?.data.find(
        (data) => data.category === "Emerging"
      )?.count;

      const HighPerforming = response?.data.find(
        (data) => data.category === "High Performing"
      )?.count;
      const StarAgent = response?.data.find(
        (data) => data.category === "Star Agent"
      )?.count;
      const Underperforming = response?.data.find(
        (data) => data.category === "Underperforming"
      )?.count;
      setPerformingAgentCount({
        HighPerforming,
        Emerging,
        StarAgent,
        Underperforming,
      });
      setIsLoadingPerformance(false);
    }
  };

  const getPerformingGrid = async (filterStatus, pageNum_, pageSize_) => {
    setIsLoadingGrid(true);

    const { response, status } = await analyticsService.getPerformanceGrid(
      filterStatus,
      pageNum_,
      pageSize_
    );
    setIsLoadingGrid(false);
    if (status === ERROR_STATUS) {
      setPages(1);
    } else if (status === SUCCESS_STATUS) {
      setDataCount(response.data.count / pageSize);
      setPages(Math.ceil(response.data.count / pageSize));
      setPerformingGrid(response.data.content);
    }
  };
  useEffect(() => {
    getPerformingAgentCount();
  }, []);
  useEffect(() => {
    getPerformingGrid(performingType, pageNum, pageSize);
  }, [performingType]);

  const targetColumns = [
    {
      Header: (props) => <div style={{ textAlign: "left" }}>Agent ID</div>,
      Cell: (props) => (
        <div
          className="wordbreak"
          style={{ textAlign: "left", wordBreak: "break-all" }}
        >
          {props.value}
        </div>
      ),
      accessor: "agentId",
      headerClassName: "table-header",
      className: "table-cell",
      style: { "white-space": "unset" },
      //   width: 200,
    },

    {
      Header: (props) => <div style={{ textAlign: "left" }}>Agent Name</div>,
      accessor: "agentName",
      Cell: (props) => <div style={{ textAlign: "left" }}>{props.value}</div>,
      headerClassName: "table-header",
      className: "table-cell",
      //   width: 300,

      style: { "white-space": "unset" },
    },
    {
      Header: (props) => <div style={{ textAlign: "left" }}>Agent Class</div>,
      accessor: "agentClass",
      Cell: (props) => <div style={{ textAlign: "left" }}>{props.value}</div>,
      headerClassName: "table-header",
      className: "table-cell",
      //   width: 300,

      style: { "white-space": "unset" },
    },
    {
      Header: (props) => <div style={{ textAlign: "left" }}>Category</div>,
      accessor: "category",
      Cell: (props) => <div style={{ textAlign: "left" }}>{props.value}</div>,
      headerClassName: "table-header",
      className: "table-cell",
      //   width: 300,

      style: { "white-space": "unset" },
    },

    {
      Header: (props) => <div style={{ textAlign: "left" }}>Score</div>,
      accessor: "score",
      Cell: (props) => <div style={{ textAlign: "left" }}>{props.value}</div>,
      headerClassName: "table-header",
      className: "table-cell",
      //   width: 200,
      style: { "white-space": "unset" },
    },
  ];
  return (
    <section>
      <article className="agentType-container-card-target">
        <BalanceCard
          loading={isLoadingPerformance}
          title={"Underperforming"}
          balance={performingAgentCount?.Underperforming || 0}
        />
        <BalanceCard
          loading={isLoadingPerformance}
          title={"Upcoming Agent"}
          balance={performingAgentCount?.Emerging || 0}
        />
        <BalanceCard
          loading={isLoadingPerformance}
          title={"Star Agent"}
          balance={performingAgentCount?.StarAgent || 0}
        />
        <BalanceCard
          loading={isLoadingPerformance}
          title={"High Performing"}
          balance={performingAgentCount?.HighPerforming || 0}
        />
      </article>
      <br />
      <article
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          flexWrap: "wrap",
        }}
      >
        <div className="filter-tabs-container">
          <div
            className="rounded-left"
            style={filterStyle("Underperforming")}
            onClick={() => handleFilterClick("Underperforming")}
          >
            Underperforming
          </div>
          <div
            style={filterStyle("Emerging-performing")}
            onClick={() => handleFilterClick("Emerging-performing")}
          >
            Upcoming Agent
          </div>
          {/* <div
            style={filterStyle("Moderate")}
            onClick={() => handleFilterClick("Moderate")}
          >
            Moderate
          </div> */}
          <div
            style={filterStyle("High-performing")}
            onClick={() => handleFilterClick("High-performing")}
          >
            High Performing
          </div>
          <div
            className="rounded-right"
            style={filterStyle("Star-agent")}
            onClick={() => handleFilterClick("Star-agent")}
          >
            Star Agent
          </div>
        </div>
        <div
          style={{
            marginTop: "10px",
          }}
        >
          <PrimaryButton
            height={"50px"}
            backgroundColor={"#0275D8"}
            margin={"0"}
            onClick={() => setActiveKey("Settings")}
            children={"View Target Savings"}
          />
        </div>
      </article>
      <br />
      <article className="commission-section-container-target">
        <ReactTable
          columns={targetColumns}
          data={performingGrid}
          dataCount={dataCount}
          pageSizes={getPaginationRows(dataCount)}
          pages={pages}
          manual
          PaginationComponent={Pagination}
          loading={isLoadingGrid}
          LoadingComponent={Spinner}
          noDataText={isLoadingGrid ? "" : "No records found"}
          minRows={10}
          onFetchData={(state, instance) => {
            getPerformingGrid(performingType, state.page + 1, state.pageSize);
          }}
          getPaginationProps={(state, rowInfo, column, instance) => ({
            style: {
              overflow: "visible",
              backgroundColor: "#ffffff",
            },
          })}
        />
      </article>
      <TopAgents />
    </section>
  );
};

export default Target;
