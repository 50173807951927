import React, { useEffect, useState } from "react";

import qs from "qs";
import { Form } from "react-bootstrap";

import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../../../constants/api";
import Text from "../../../../../../components/text";
import Platform from "../../../../../../services/api/resources/platform";
import PreviewAccordion from "../../component/accordion";
import { formatDateApplication } from "../../component/extras";
import Lightbox from "react-image-lightbox";

const imgStyle = {
  borderRadius: "5px",
  height: "9rem",
  border: "1px solid grey",
  width: "8rem",
  objectFit: "cover",
  cursor: "pointer",
};
const fontSize = {
  fontSize: "16px",
};
const NextOfKinDetails = ({ setScreen, application, isLoading }) => {
  const nextOfKins = application?.nextOfKins?.[0];
  return (
    <section
      style={{
        margin: "0 auto",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div>
        <div style={{ marginBottom: "20px" }}>
          <Text style={{ color: "grey", fontSize: "16px" }}>Name:</Text>
          <Text style={fontSize} bold>
            {isLoading ? "Loading..." : nextOfKins?.firstname}
          </Text>
        </div>
        {/* <div style={{ marginBottom: '20px' }}>
            <Text style={{ color: 'grey', fontSize: '16px' }} mid>
              Middle Name:
            </Text>
            <Text style={fontSize} bold mid>
              {isLoading ? 'Loading...' : application?.middleName}
            </Text>
          </div> */}
        {/* <div style={{ marginBottom: '20px' }}>
            <Text style={{ color: 'grey', fontSize: '16px' }} mid>
              Surname
            </Text>
            <Text style={fontSize} bold mid>
              {isLoading ? 'Loading...' : application?.surname}
            </Text>
          </div> */}
        <div style={{ marginBottom: "20px" }}>
          <Text style={{ color: "grey", fontSize: "16px" }} mid>
            Email Address:
          </Text>
          <Text style={fontSize} bold mid>
            {isLoading ? "Loading..." : nextOfKins?.emailAddress}
          </Text>
        </div>
        <div>
          <Text style={{ color: "grey", fontSize: "16px" }} mid>
            Gender:
          </Text>
          <Text style={fontSize} bold mid>
            {isLoading ? "Loading..." : nextOfKins?.gender}
          </Text>
        </div>
      </div>
      <div>
        <div style={{ marginBottom: "20px" }}>
          <Text style={{ color: "grey", fontSize: "16px" }} mid>
            Phone Number:
          </Text>
          <Text style={fontSize} bold mid>
            {isLoading ? "Loading..." : nextOfKins?.phoneNo}
          </Text>
        </div>

        <div>
          <Text style={{ color: "grey", fontSize: "16px" }} mid>
            Relationship:
          </Text>
          <Text style={fontSize} bold mid>
            {isLoading ? "Loading..." : nextOfKins?.relationship}
          </Text>
        </div>
      </div>
    </section>
  );
};

const PersonalDetails = ({
  setScreen,
  application,
  isLoading,
  documentsList,
}) => {
  const deviceIsPC =
    !qs.parse(window.location.search)["?mobile"] && window.innerWidth >= 992;

  const getDocumentUrl = (documentLink) => {
    let convertedDocument = documentLink.replace(
      "/data",
      process.env.REACT_APP_ONBOARDING_BASE_URL
    );
    return convertedDocument;
  };

  const convertFileName = (fileName) => {
    if (fileName === "UTILITY_BILL") {
      return "Utility Bill";
    } else if (fileName === "PASSPORT_PHOTO") {
      return "Passport Photograph";
    } else if (fileName === "ID_CARD") {
      return "Government Issued ID";
    } else if (fileName === "ADDRESS_VERIFICATION_FORM") {
      return "Address Verification Form";
    } else if (fileName === "CHARACTER_FORM") {
      return "Character  Form";
    }
  };
  const [showLightbox, setShowLightbox] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const handleDocumentClick = (documentName) => {
    setSelectedDocument(documentName);
    setShowLightbox(true);
  };
  return (
    <>
      <section
        style={{
          margin: "0 auto",
          width: deviceIsPC ? "60%" : "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div style={{ marginBottom: "20px" }}>
            <Text style={{ color: "grey", fontSize: "16px" }}>
              Phone Number:
            </Text>
            <Text style={fontSize} bold mid>
              {isLoading ? "Loading..." : application?.agentMobileNo}
            </Text>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <Text style={{ color: "grey", fontSize: "16px" }}>
              Email Address:
            </Text>
            <Text style={fontSize} bold mid>
              {isLoading ? "Loading..." : application?.businessEmail}
            </Text>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <Text style={{ color: "grey", fontSize: "16px" }}>Gender:</Text>
            <Text style={fontSize} bold mid>
              {isLoading ? "Loading..." : application?.businessContact?.gender}
            </Text>
          </div>
          {/* {application?.businessLocation?.[0].state && (
            <div style={{ marginBottom: '20px' }}>
              <Text style={{ color: 'grey', fontSize: '16px' }}>State:</Text>
              <Text style={fontSize} bold mid>
                {isLoading
                  ? 'Loading...'
                  : application?.businessLocation?.[0].state}
              </Text>
            </div>
          )} */}
        </div>
        <div>
          <div style={{ marginBottom: "20px" }}>
            <Text style={{ color: "grey", fontSize: "16px" }}>BVN:</Text>
            <Text style={fontSize} bold mid>
              {isLoading ? "Loading..." : application?.bvn}
            </Text>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <Text style={{ color: "grey", fontSize: "16px" }}>
              Date of Birth:
            </Text>
            <Text style={fontSize} bold mid>
              {isLoading
                ? "Loading..."
                : formatDateApplication(application?.businessContact?.dob)}
            </Text>
          </div>
          {application?.businessContact?.motherMadienName && (
            <div style={{ marginBottom: "20px" }}>
              <Text style={{ color: "grey", fontSize: "16px" }}>
                Mother's Maiden Name:
              </Text>
              <Text style={fontSize} bold mid>
                {isLoading
                  ? "Loading..."
                  : application?.businessContact?.motherMadienName}
              </Text>
            </div>
          )}
          {/* {application?.businessLocation?.[0].lga && (
            <div style={{ marginBottom: '20px' }}>
              <Text style={{ color: 'grey', fontSize: '16px' }}>
                Local Government Area:
              </Text>
              <Text style={fontSize} bold mid>
                {isLoading
                  ? 'Loading...'
                  : application?.businessLocation?.[0].lga}
              </Text>
            </div>
          )} */}
        </div>
      </section>
    </>
  );
};

const KycInformation = ({
  setScreen,
  application,
  isLoading,
  documentsList,
}) => {
  const deviceIsPC =
    !qs.parse(window.location.search)["?mobile"] && window.innerWidth >= 992;

  const getDocumentUrl = (documentLink) => {
    let convertedDocument = documentLink.replace(
      "/data",
      process.env.REACT_APP_ONBOARDING_BASE_URL
    );
    return convertedDocument;
  };

  const convertFileName = (fileName) => {
    if (fileName === "UTILITY_BILL") {
      return "Utility Bill";
    } else if (fileName === "PASSPORT_PHOTO") {
      return "Passport Photograph";
    } else if (fileName === "ID_CARD") {
      return "Government Issued ID";
    } else if (fileName === "ADDRESS_VERIFICATION_FORM") {
      return "Address Verification Form";
    } else if (fileName === "CHARACTER_FORM") {
      return "Character  Form";
    }
  };
  const [showLightbox, setShowLightbox] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const handleDocumentClick = (documentName) => {
    setSelectedDocument(documentName);
    setShowLightbox(true);
  };
  return (
    <section
      style={{
        margin: "0 auto",
        width: "60%",
      }}
    >
      <div>
        <div style={{ marginBottom: "20px" }}>
          <Text
            mid
            style={{
              color: "grey",
              fontSize: "16px",
            }}
          >
            Means of Identification:
          </Text>
          <Text style={fontSize} bold mid>
            {isLoading ? "Loading..." : application?.identificationType}
          </Text>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <Text
            mid
            style={{
              color: "grey",
              fontSize: "16px",
            }}
          >
            ID number:
          </Text>
          <Text style={fontSize} bold mid>
            {isLoading ? "Loading..." : application?.identificationNumber}
          </Text>
        </div>
      </div>
      {isLoading ? (
        ""
      ) : (
        <div
          style={{
            display: "flex",
            gap: "10px",
            marginTop: "20px",
            flexWrap: "wrap",
          }}
        >
          {documentsList?.map((item, index) => {
            const {
              documentType,

              documentExtention,
              documentLink,
              documentName,
            } = item;

            return (
              <>
                <div onClick={() => handleDocumentClick(documentLink)}>
                  <Form.Label
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    {convertFileName(documentType)}
                  </Form.Label>
                  <div style={imgStyle} key={index}>
                    {documentExtention === "png" ||
                    documentExtention === "jpeg" ||
                    documentExtention === "jpg" ||
                    documentExtention === "svg" ? (
                      <img
                        style={imgStyle}
                        src={getDocumentUrl(documentLink)}
                        title={convertFileName(documentType)}
                        alt={convertFileName(documentType)}
                      />
                    ) : (
                      <embed
                        style={imgStyle}
                        name="plugin"
                        src={getDocumentUrl(documentLink)}
                        title={convertFileName(documentType)}
                        alt={convertFileName(documentType)}
                      />
                    )}
                  </div>
                </div>
              </>
            );
          })}
          {showLightbox && selectedDocument && (
            <>
              <Lightbox
                mainSrc={getDocumentUrl(selectedDocument)}
                onCloseRequest={() => setShowLightbox(false)}
              />
            </>
          )}
        </div>
      )}{" "}
    </section>
  );
};

const BusinessDetails = ({ setScreen, application, isLoading, state, lga }) => {
  const contact = application?.businessContact;
  const agentBankAccount = application?.agentBankAccount;
  const location = application?.businessLocation?.[0];

  return (
    <section
      style={{
        margin: "0 auto",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div>
        {/* <div style={{ marginBottom: '10px' }}>
            <Text
              mid
              style={{
                color: 'grey',
              }}
            >
              Phone number:
            </Text>
            <Text bold mid>
              {isLoading ? 'Loading...' : contact?.phoneNo}
            </Text>
          </div> */}
        <div style={{ marginBottom: "20px" }}>
          <Text style={{ color: "grey", fontSize: "16px" }} mid>
            State:
          </Text>
          <Text style={fontSize} bold mid>
            {isLoading ? "Loading..." : location?.state}
          </Text>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <Text style={{ color: "grey", fontSize: "16px" }} mid>
            Business Name:
          </Text>
          <Text style={fontSize} bold mid>
            {isLoading
              ? "Loading..."
              : contact?.firstname + " " + contact?.lastname}
          </Text>
        </div>
        {agentBankAccount?.bankName && (
          <div>
            <Text style={{ color: "grey", fontSize: "16px" }} mid>
              Bank:
            </Text>
            <Text style={fontSize} bold color="grey" mid>
              {isLoading ? "Loading..." : agentBankAccount?.bankName}
            </Text>
          </div>
        )}
      </div>
      <div>
        <div style={{ marginBottom: "20px" }}>
          <Text style={{ color: "grey", fontSize: "16px" }} mid>
            Business Address:
          </Text>
          <Text style={fontSize} bold color="grey" mid>
            {isLoading ? "Loading..." : location?.addressLine1}
          </Text>
        </div>

        <div style={{ marginBottom: "20px" }}>
          <Text style={{ color: "grey", fontSize: "16px" }} mid>
            Local Government Area:
          </Text>
          <Text style={fontSize} bold color="grey" mid>
            {isLoading ? "Loading..." : location?.lga}
          </Text>
        </div>
        {agentBankAccount?.accountNo && (
          <div>
            <Text style={{ color: "grey", fontSize: "16px" }} mid>
              Account Number:
            </Text>
            <Text style={fontSize} bold color="grey" mid>
              {isLoading ? "Loading..." : agentBankAccount?.accountNo}
            </Text>
          </div>
        )}
      </div>
    </section>
  );
};

const Profile = ({ agentDetails }) => {
  const platform = new Platform();
  const [application, setApplication] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const fetchActiveAgentDetails = async () => {
    setIsLoading(true);

    const { status, response } = await platform.getActiveAgentsDetails(
      agentDetails.id
    );
    setIsLoading(false);
    if (status === ERROR_STATUS) {
    } else if (status === SUCCESS_STATUS) {
      setIsLoading(false);
      setApplication(response);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchActiveAgentDetails();
  }, []);
  return (
    <section
      style={{
        marginTop: "2rem",
        width: "70%",
      }}
    >
      <PreviewAccordion application={true} index="1" title={"Personal Details"}>
        <PersonalDetails
          application={application}
          isLoading={isLoading}
          documentsList={application.documents}
        />
      </PreviewAccordion>
      {/* {application?.documents?.length > 1 && ( */}
      <PreviewAccordion
        application={true}
        index={"0"}
        title={"KYC Information"}
      >
        <KycInformation
          application={application}
          documentsList={application.documents}
        />
      </PreviewAccordion>
      {/* )} */}
      {/* {application?.businessDetails?.phoneNumber && ( */}
      <PreviewAccordion
        application={true}
        index={"0"}
        title={"Business Details"}
      >
        <BusinessDetails
          application={application}
          isLoading={isLoading}

          // state={state}
          // lga={localGovernmentArea}
        />
      </PreviewAccordion>
      {/* )} */}
      {/* {application?.nextOfKin?.phoneNumber && ( */}
      <PreviewAccordion
        application={true}
        index={"0"}
        title={"Next of kin details"}
      >
        <NextOfKinDetails application={application} />
      </PreviewAccordion>
      {/* )} */}
    </section>
  );
};

export default Profile;
