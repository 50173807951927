import React from 'react'
import { Col, Form, Row, Table } from 'react-bootstrap'
import BaseScene from '../../../../base-scene'
import Button from '../../../../../components/button'
import FormControl from '../../../../../components/form-control'

export default class UpdateTransactionPin extends BaseScene {
  state = {
    form: {

    },
    hidePin: true,
    hideNewPin: true,
    hideRepeatNewPin: true
  }

  isPinValid (newForm) {
    const form = newForm || this.state.form
    const { pin } = form
    
    return !isNaN(pin) && String(pin).length >= 4
  }

  isNewPinValid(newForm) {
    const form = newForm || this.state.form
    const { newPin } = form
    
    return !isNaN(newPin) && String(newPin).length >= 4
  }

  isRepeatNewPinValid(newForm) {
    const form = newForm || this.state.form
    const { repeatNewPin } = form
    
    return repeatNewPin === form.newPin && !isNaN(repeatNewPin) && String(repeatNewPin).length >= 4
  }

  updateFormField (params) {
    this.setState({
      form: {
        ...this.state.form,
        ...params
      }
    })
  }

  render () {
    return <div style={{
      borderRadius: '6px', 
      display: 'flex', 
      flexWrap: 'wrap', 
      justifyContent: 'flex-start', 
      paddingTop: '20px'}}>
      
      <Form>
        <Row style={{margin: 0, padding: 0}}>
          <Col md={12} style={{margin: 0, padding: 0}}>
            <Form.Group>
              <Form.Label style={{color: `${!this.state.form.pin || this.isPinValid() ?  '#3A3B3B' : process.env.REACT_APP_RED_COLOUR}`, fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Enter current pin:</Form.Label>
              
              <Row style={{margin: 0, padding: 0}}>
                <Col xs={8} md={4} style={{margin: 0, padding: 0}}>
                  <FormControl type={this.state.hidePin ? 'password' : 'text'} placeholder="Pin" onChange={(event) => {
                    const pin = event.target.value
                    this.updateFormField({pin})
                  }} style={{backgroundColor: '#FFFFFF', borderRadius: '20px', border: `1px solid ${!this.state.form.pin || this.isPinValid() ? '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`, boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px'}} />
                  </Col>
              
                <Col xs={4} md={2} style={{display: 'flex', alignItems: 'center'}}>
                  <img src={this.state.hidePin ? '/assets/media/icons/eye.svg' : '/assets/media/icons/eye-hidden.svg'} style={{height: '24px', width: '24px', opacity: 1}} onClick={() => this.setState({hidePin: !this.state.hidePin})} alt="" />
                </Col>
              </Row>
            </Form.Group>
          </Col>

          <Col md={12} style={{margin: 0, padding: 0}}>
            <Form.Group>
              <Form.Label style={{color: `${!this.state.form.newPin || this.isNewPinValid() ?  '#3A3B3B' : process.env.REACT_APP_RED_COLOUR}`, fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Enter a new pin:</Form.Label>
              
              <Row style={{margin: 0, padding: 0}}>
                <Col xs={8} md={4} style={{margin: 0, padding: 0}}>
                  <FormControl type={this.state.hideNewPin ? 'password' : 'text'} placeholder="New pin" onChange={(event) => {
                    const newPin = event.target.value
                    this.updateFormField({newPin})
                  }} style={{backgroundColor: '#FFFFFF', borderRadius: '20px', border: `1px solid ${!this.state.form.newPin || this.isNewPinValid() ? '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`, boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px'}} />
                  </Col>
              
                <Col xs={4} md={2} style={{display: 'flex', alignItems: 'center'}}>
                  <img src={this.state.hideNewPin ? '/assets/media/icons/eye.svg' : '/assets/media/icons/eye-hidden.svg'} style={{height: '24px', width: '24px', opacity: 1}} onClick={() => this.setState({hideNewPin: !this.state.hideNewPin})} alt="" />
                </Col>
              </Row>
            </Form.Group>
          </Col>

          <Col md={12} style={{margin: 0, padding: 0}}>
            <Form.Group>
              <Form.Label style={{color: `${!this.state.form.repeatNewPin || this.isRepeatNewPinValid() ?  '#3A3B3B' : process.env.REACT_APP_RED_COLOUR}`, fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Repeat new pin:</Form.Label>
              
              <Row style={{margin: 0, padding: 0}}>
                <Col xs={8} md={4} style={{margin: 0, padding: 0}}>
                  <FormControl type={this.state.hideRepeatNewPin ? 'password' : 'text'} placeholder="Repeat new pin" onChange={(event) => {
                    const repeatNewPin = event.target.value
                    this.updateFormField({repeatNewPin})
                  }} style={{backgroundColor: '#FFFFFF', borderRadius: '20px', border: `1px solid ${!this.state.form.repeatNewPin || this.isRepeatNewPinValid() ? '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`, boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px'}} />
                </Col>
              
                <Col xs={4} md={2} style={{display: 'flex', alignItems: 'center'}}>
                  <img src={this.state.hideRepeatNewPin ? '/assets/media/icons/eye.svg' : '/assets/media/icons/eye-hidden.svg'} style={{height: '24px', width: '24px', opacity: 1}} onClick={() => this.setState({hideRepeatNewPin: !this.state.hideRepeatNewPin})} alt="" />
                </Col>
              </Row>
            </Form.Group>
          </Col>

          <Col xs={8} md={12} style={{margin: 0, padding: 0}}>    
            <Button style={{marginTop: '5px'}}>Save Changes</Button>
          </Col>

        </Row>
      </Form>

    </div>
  }

}