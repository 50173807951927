import React from "react";
import { Spinner } from "react-bootstrap";

export default class ActivityIndicator extends React.Component {
  render() {
    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: "100vh",
          ...this.props.style,
        }}
      >
        <Spinner animation="border" color={this.props.color} />
      </div>
    );
  }
}
