import React from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import MdMore from "react-ionicons/lib/MdMore";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Pagination from "../../../components/pagination";
import { navigateTo } from "../../../services/redux/actions/navigation";
import { convertNgkToNgn } from "../../../utils/converters/currencies";
import {
  formatAmount,
  formatDate,
  formatDateTime,
  handleCustomerIdFormat,
  handleStatusFormat,
  handleTransactionTypeFormat,
  sentenceCapitalization,
} from "../../../utils/formatters";
import { getPaginationRows } from "../../../utils/helpers";

import ActivityIndicator from "../../../components/activity-indicator";
import Spinner from "../../../components/spinner";
import Text from "../../../components/text";
import VerticallyCenteredModal from "../../../components/vertically-centered-modal";
import { SUCCESS_STATUS } from "../../../constants/api";
import { BILLER_BORNE } from "../../../constants/transactions";
import { transactionExtraSerializerReport } from "../../../serializers/resources/transaction-extras";
import Catalog from "../../../services/api/resources/catalog";
import Quickteller from "../../../services/api/resources/quickteller";
import Transaction from "../../../services/api/resources/transaction";
import TransactionHistory from "../../../services/api/resources/transaction-history";
import "./styles.scss";

class TransactionsReportTable extends React.Component {
  transactionHistory = new TransactionHistory();
  transaction = new Transaction();
  quickteller = new Quickteller();
  catalog = new Catalog();

  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      showReceipt: false,
      transactionDetails: null,
      isLoading: false,
      isRequeryLoading: false,
      reversalDetails: [],
      showReversalSection: false,
      isReversalDetailLoading: false,
    };

    this.searchByTransRef = this.searchByTransRef.bind(this);
  }

  async searchByTransRef(transRef) {
    this.setState({
      isLoading: true,
    });
    const { status, response } =
      await this.transactionHistory.getTransactionsByRef(transRef);
    if (status === SUCCESS_STATUS) {
      this.setState({
        //showModal: true,
        transactionDetails: {
          ...this.state.transactionDetails,
          extraInfoList: response.extraInfoList,
        },
        isLoading: false,
      });
    } else {
      this.setState({
        isLoading: false,
      });
      this.props.showErrorMessage(JSON.stringify(response));
      setTimeout(() => {
        this.props.dismissErrorMessage();
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
    }
  }

  render() {
    const handleOpen = async (props) => {
      this.searchByTransRef(props.original.transactionRef);
      localStorage.setItem("ticketDetailsItem", JSON.stringify(props.original));
      this.props.navigateTo("/ticket");
    };
    const handleTicketDetails = (props) => {
      localStorage.setItem("ticketDetailsItem", JSON.stringify(props.original));
      this.props.navigateTo("/ticket");
    };

    const handleClose = () => {
      this.setState({
        showModal: false,
        showReceipt: false,
      });
    };

    const transactionHistoryColumns = [
      {
        Header: (props) => <div style={{ textAlign: "left" }}>Ticket ID</div>,
        Cell: (props) => (
          <div
            className="wordbreak"
            style={{ textAlign: "left", wordBreak: "break-all" }}
          >
            {props.value}
          </div>
        ),
        accessor: "ticketNumber",
        headerClassName: "table-header",
        className: "table-cell",
        style: { "white-space": "unset", fontSize: 12 },
        width: 220,
      },
      {
        Header: (props) => (
          <div style={{ textAlign: "left" }}>Transaction Ref</div>
        ),
        Cell: (props) => (
          <div
            className="wordbreak"
            style={{ textAlign: "left", wordBreak: "break-all", fontSize: 12 }}
          >
            {props.value}
          </div>
        ),
        accessor: "transactionRef",
        headerClassName: "table-header",
        className: "table-cell",
        style: { "white-space": "unset" },
        width: 280,
      },
      {
        Header: (props) => (
          <div style={{ textAlign: "left" }}>Transaction Type</div>
        ),
        accessor: "transactionType",
        Cell: (props) => (
          <div style={{ textAlign: "left" }}>
            {handleTransactionTypeFormat(props.value)}
          </div>
        ),
        headerClassName: "table-header",
        className: "table-cell",
        style: { "white-space": "unset", fontSize: 12 },
        width: 240,
      },
      {
        Header: (props) => <React.Fragment>Date</React.Fragment>,
        accessor: "dateCreated",
        Cell: (props) => (
          <div style={{ textAlign: "left" }} className="number">
            {formatDateTime(props.value)}
          </div>
        ),
        headerClassName: "table-header",
        className: "table-cell",
        style: { "white-space": "unset", fontSize: 12 },
        width: 180,
      },
      {
        Header: (props) => <div style={{ textAlign: "left" }}>Status</div>,
        accessor: "status",
        Cell: (props) => (
          <div
            className={`${props.value.toLowerCase()}-status`}
            style={{ textAlign: "left", fontSize: 12 }}
          >
            {props.value}
          </div>
        ),
        headerClassName: "table-header",
        className: "table-cell",
        style: { "white-space": "unset" },
        width: 120,
      },
      {
        //   Cell: props => <div style={{ textAlign: "left" }} className='number'>{`₦${formatAmount(convertNgkToNgn(props.value))}`}</div>,
        //   headerClassName: 'table-header',
        //   width: 150,
        // width: 150,
        Header: (props) => <div style={{ textAlign: "left" }}>Action</div>,
        Cell: (props) => (
          <Dropdown>
            <Dropdown.Toggle
              variant="secondary"
              id="dropdown-variants-secondary"
            >
              <MdMore
                fontSize="15px"
                color={process.env.REACT_APP_GREY_COLOUR}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleTicketDetails(props)}>
                View Details
              </Dropdown.Item>
              {props?.original?.status === "Resolved" &&
                !props?.original?.expired && (
                  <Dropdown.Item onClick={() => handleOpen(props)}>
                    Reopen ticket
                  </Dropdown.Item>
                )}
            </Dropdown.Menu>
          </Dropdown>
        ),
        headerClassName: "table-header",
        className: "table-cell",
        style: { "white-space": 'unset, "fontSize":12' },
        width: 80,
      },
    ];

    return (
      <>
        {this.state.showModal && (
          <VerticallyCenteredModal
            onHide={handleClose}
            show={this.state.showModal}
            title="Transaction Details"
            body={
              <TransactionModalBody
                userSerializer={this.props.userSerializer}
                showFee={true}
                showReceiptLogo={false}
                isReversalDetailLoading={this.state.isReversalDetailLoading}
                reversalDetails={this.state.reversalDetails}
                searchByTransRef={this.searchByTransRef}
                details={this.state.transactionDetails}
              />
            }
          />
        )}
        {this.state.isRequeryLoading ? (
          <ActivityIndicator />
        ) : (
          <ReactTable
            PaginationComponent={Pagination}
            columns={transactionHistoryColumns}
            data={this.props.data}
            minRows={0}
            dataCount={this.props.dataCount}
            pageSizes={getPaginationRows(this.props.dataCount)}
            pages={this.props.pages}
            // defaultPageSize={50}
            manual
            onFetchData={(state, instance) => {
              this.props.getAllTransactions(
                this.props.startDate,
                this.props.endDate,
                state.page + 1,
                state.pageSize,
                this.props.selectedOption.value,
                this.props.statusOption.value
              );
            }}
            loading={this.props.loading}
            // minRows={this.props.data.length > 0 ? 3 : 2}
            LoadingComponent={Spinner}
            getPaginationProps={(state, rowInfo, column, instance) => ({
              style: {
                overflow: "visible",
                backgroundColor: "#ffffff",
              },
            })}
          />
        )}
      </>
    );
  }
}

const TransactionModalBody = (props) => {
  const extras = props.details?.extraInfoList
    ? props.details?.extraInfoList
    : [];
  let processedExtra =
    Boolean(extras) && transactionExtraSerializerReport(extras);

  let orderedData = {};

  if (Object.keys(processedExtra).length > 0) {
    Object.keys(processedExtra).map(
      (value) => (orderedData[value] = processedExtra[value])
    );

    if (props.payments?.billsPaymentRequest?.customerType)
      orderedData["Tariff Band"] =
        props.payments.billsPaymentRequest.customerType;
  }

  return (
    <>
      {(props.details?.transactionType === "BILLS" ||
        props.details.transactionType === "RECHARGE" ||
        props.details.transactionType === "PAYPOINT_FUND" ||
        props.details.transactionType === "DISTRIBUTE") && (
        <div id="print-receipt">
          <Row style={{ marginTop: "15px" }}>
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                  fontWeight: "bold",
                }}
              >
                Amount:{" "}
              </Text>
              <Text
                style={{
                  fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                  fontWeight: "bold",
                  textAlign: "right",
                }}
              >{`NGN${formatAmount(
                convertNgkToNgn(props.details.transactionAmount)
              )}`}</Text>
            </Col>
          </Row>

          {props.showFee &&
            props.details.borneBy !== BILLER_BORNE &&
            props.details.fee > 0 && (
              <Row style={{ marginTop: "15px" }}>
                <Col md={12} style={{ display: "flex" }}>
                  <Text
                    style={{
                      paddingLeft: 20,
                      width: 200,
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      fontWeight: "bold",
                    }}
                  >
                    Fee:{" "}
                  </Text>
                  <Text
                    style={{
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      fontWeight: "bold",
                    }}
                  >{`NGN${formatAmount(
                    convertNgkToNgn(props.details.fee)
                  )}`}</Text>
                </Col>
              </Row>
            )}

          <hr />

          <Row
            hidden={!props.details.customerFirstName}
            style={{ marginTop: "15px" }}
          >
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                Customer Name:{" "}
              </Text>
              <Text
                style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
              >{`${props.details.customerFirstName} ${props.details.customerLastName}`}</Text>
            </Col>
          </Row>

          <Row
            hidden={!props.details.accountNumber}
            style={{ marginTop: "15px" }}
          >
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                Account Number:{" "}
              </Text>
              <Text
                style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
              >{`${props.details.accountNumber}`}</Text>
            </Col>
          </Row>

          <Row hidden={!props.details.bankName} style={{ marginTop: "15px" }}>
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                Bank Name:{" "}
              </Text>
              <Text
                style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
              >{`${props.details.bankName}`}</Text>
            </Col>
          </Row>

          <Row
            hidden={props.details.transactionType === "DISTRIBUTE"}
            style={{ marginTop: "15px" }}
          >
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                Phone Number:{" "}
              </Text>
              <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
                {handleCustomerIdFormat(props.details.customerMsisdn)}
              </Text>
            </Col>
          </Row>

          {props.details.transactionType === "DISTRIBUTE" && (
            <Row style={{ marginTop: "15px" }}>
              <Col md={12} style={{ display: "flex" }}>
                <Text
                  style={{
                    paddingLeft: 20,
                    width: 200,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  }}
                >
                  Phone Number:{" "}
                </Text>
                <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
                  {handleCustomerIdFormat(props.details.username)}
                </Text>
              </Col>
            </Row>
          )}

          <hr />

          <Row
            hidden={!props.details.serviceOptionName}
            style={{ marginTop: "15px" }}
          >
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                {"Service Option"}:{" "}
              </Text>
              <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
                {props.details.serviceOptionName}
              </Text>
            </Col>
          </Row>

          <hr
            hidden={
              props.details.transactionType === "PAYPOINT_FUND" ||
              props.details.transactionType === "REVERSAL" ||
              props.details.transactionType === "DISTRIBUTE"
            }
          />

          {Boolean(extras) &&
            Object.entries(transactionExtraSerializerReport(extras)).map(
              ([key, value]) => (
                <Row style={{ marginTop: "15px" }}>
                  <Col md={12} style={{ display: "flex" }}>
                    <Text
                      style={{
                        paddingLeft: 20,
                        width: 200,
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      }}
                    >
                      {key}:{" "}
                    </Text>
                    <Text
                      style={{
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      }}
                    >
                      {value}
                    </Text>
                  </Col>
                </Row>
              )
            )}
          {Boolean(extras.length) && <hr />}

          <Row style={{ marginTop: "15px" }}>
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                Transaction Type:{" "}
              </Text>
              <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
                {handleTransactionTypeFormat(props.details.transactionType)}
              </Text>
            </Col>
          </Row>

          <Row style={{ marginTop: "15px" }}>
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                Transaction Reference:{" "}
              </Text>
              <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
                {props.details.transactionRef}
              </Text>
            </Col>
          </Row>

          <Row style={{ marginTop: "15px" }}>
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                Transaction Date:{" "}
              </Text>
              <Text
                style={{
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  textAlign: "right",
                }}
              >
                {formatDate(props.details.dateCreated)}
              </Text>
            </Col>
          </Row>

          <Row style={{ marginTop: "15px" }}>
            <Col md={12} style={{ display: "flex" }}>
              <Text
                style={{
                  paddingLeft: 20,
                  width: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                Transaction Status:{" "}
              </Text>
              <Text
                style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
                className={handleStatusFormat(props.details.statusCode)}
              >
                {handleStatusFormat(props.details.statusCode)}
              </Text>
            </Col>
          </Row>

          {props.details.parentReference && (
            <Row style={{ marginTop: "15px" }}>
              <Col md={12} style={{ display: "flex" }}>
                <Text
                  style={{
                    paddingLeft: 20,
                    width: 200,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    fontWeight: "bold",
                  }}
                >
                  Parent Reference:{" "}
                </Text>
                <Text
                  style={{
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    fontWeight: "bold",
                  }}
                >
                  {props.details.parentReference}
                </Text>
              </Col>
            </Row>
          )}

          <hr />

          <Row style={{ marginTop: "15px" }}>
            <Col md={12} style={{ display: "flex" }}>
              <Text style={{ paddingLeft: 20, width: 200, fontSize: "15px" }}>
                Business Name:{" "}
              </Text>
              <Text style={{ fontSize: "15px" }}>
                {sentenceCapitalization(props.details.businessName)}
              </Text>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: (pendingUrl) => dispatch(navigateTo(pendingUrl)),
  };
}
export default connect(null, mapDispatchToProps)(TransactionsReportTable);
