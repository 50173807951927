import { connect } from 'react-redux';
import { updateLoading, dismissErrorMessage, showErrorMessage, dismissSuccessMessage, showSuccessMessage } from '../../../../services/redux/actions/tunnel'

import { navigateTo } from '../../../../services/redux/actions/navigation';
import { nullifyUserStatus, startLoadUserData } from '../../../../services/redux/actions/user';

import ProcessingBvnScene from './scene'




function mapStateToProps(state) {
    return {
      isLoading: state.tunnel.isLoading,
      loadUserStatus: state.user.loadUserStatus,
    }
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      dismissErrorMessage: message => dispatch(dismissErrorMessage(message)),
      showErrorMessage: message => dispatch(showErrorMessage(message)),
      dismissSuccessMessage: message => dispatch(dismissSuccessMessage(message)),
      showSuccessMessage: message => dispatch(showSuccessMessage(message)),
      navigateTo: url => dispatch(navigateTo(url)),
      nullifyUserStatus: () => dispatch(nullifyUserStatus()),
      startLoadUserData: () => dispatch(startLoadUserData()),
      updateLoading: isLoading => dispatch(updateLoading(isLoading))
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps) (ProcessingBvnScene);