import React from 'react';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import MdContact from 'react-ionicons/lib/MdContact';
import MdNotifications from 'react-ionicons/lib/MdNotifications';
import Text from '../../../../../components/text';

const COLOUR = 'white';

const mobileApplicationStatusIndicators = props => <React.Fragment>
  <OverlayTrigger
    key={'bottomIndicator1'}
    placement={'bottom'}
    overlay={
      <Tooltip id={`tooltip-${'top'}`}>
        We are reviewing your application, {props.currentApplication.applicantDetails ? props.currentApplication.applicantDetails.firstName : ''}. You will get notified once we are done!
      </Tooltip>
    }>
    <div
      hidden={!(props.toShowAwaitingValidation() && !props.deviceIsPC)}
      style={{
        backgroundColor: process.env.REACT_APP_YELLOW_COLOUR,
        padding: '5px',
      }}
    >
      <Text black bold center>Awaiting Validation</Text>
    </div>
  </OverlayTrigger>

  <OverlayTrigger
    key={'bottomIndicator2'}
    placement={'bottom'}
    overlay={
      <Tooltip id={`tooltip-${'top'}`}>
        We are reviewing your application, {props.currentApplication.applicantDetails ? props.currentApplication.applicantDetails.firstName : ''}. You will get notified once we are done!
      </Tooltip>
    }>
    <div
      hidden={!(props.toShowAwaitingApproval() && !props.deviceIsPC)}
      style={{
        backgroundColor: '#F8E02B',
        padding: '5px'
      }}>
      <Text black bold center>Awaiting Approval</Text>
    </div>
  </OverlayTrigger>

  <OverlayTrigger
    key={'bottomIndicator3'}
    placement={'left'}
    overlay={
      <Tooltip id={`tooltip-${'left'}`}>
        You are currently inactive, {props.currentApplication.applicantDetails ? props.currentApplication.applicantDetails.firstName : ''}.
      </Tooltip>
    }>

    <div hidden={!(props.toShowInactive() && !props.deviceIsPC)} style={{
      backgroundColor: '#FC7717',
      padding: '5px'
    }}>
      <Text black bold center>Inactive</Text>
    </div>
  </OverlayTrigger>

  <OverlayTrigger
    key={'bottomIndicator4'}
    placement={'bottom'}
    overlay={
      <Tooltip id={`tooltip-${'top'}`}>
        We are currently setting up your account. Kindly check back later.
      </Tooltip>
    }>
    <div
      hidden={!(props.toShowSetupInProgress() && !props.deviceIsPC)}
      style={{
        backgroundColor: process.env.REACT_APP_YELLOW_COLOUR,
        padding: '5px',
      }}
    >
      <Text black bold center>Setup in Progress...</Text>
    </div>
  </OverlayTrigger>

  <OverlayTrigger
    key={'bottomIndicator5'}
    placement={'left'}
    overlay={
      <Tooltip id={`tooltip-${'left'}`}>
        You are currently suspended, {props.currentApplication.applicantDetails ? props.currentApplication.applicantDetails.firstName : ''}.
      </Tooltip>
    }>

    <div
      hidden={!(props.toShowSuspended() && !props.deviceIsPC)}
      style={{
        backgroundColor: '#FC7717',
        padding: '5px'
      }}>
      <Text black bold center>Suspended</Text>
    </div>
  </OverlayTrigger>

  <div className="grow" hidden={!props.toShowCompleteApplication()} style={{
    backgroundColor: process.env.REACT_APP_RED_COLOUR,
    borderRadius: '4px',
    fontStyle: process.env.REACT_APP_MID_FONT_SIZE,
    fontWeight: 'normal',
    padding: '10px 15px 10px 15px',
    color: 'white',
    cursor: 'pointer',
    zIndex: '1',
    textAlign: 'center'
  }}
    onClick={() => props.rejectedApplication ? props.navigateTo('/application?applicationRejected=true') : props.navigateTo('/application')}>
    {props.rejectedApplication ? 'Update your Application' : 'Complete Application'}
  </div>

  {/* <div className="grow" hidden={!props.currentUser.isAgent || props.emailVerified} style={{
    backgroundColor: process.env.REACT_APP_RED_COLOUR, 
    borderRadius: '4px', 
    fontStyle: process.env.REACT_APP_MID_FONT_SIZE, 
    fontWeight: 'normal', 
    marginRight: '20pt', 
    padding: '10px 15px 10px 15px', 
    color: 'white', 
    cursor: 'pointer'}} 
    onClick={() => props.resendEmail()}>
    Resend Email
  </div> */}
</React.Fragment>

const desktopApplicationStatusIndicators = props => <>
  <OverlayTrigger
    key={'bottomIndicator6'}
    placement={'left'}
    overlay={
      <Tooltip id={`tooltip-${'top'}`}>
        We are reviewing your application, {props.currentApplication.applicantDetails ? props.currentApplication.applicantDetails.firstName : ''}. You will get notified once we are done!
      </Tooltip>
    }>
    <div hidden={!props.toShowAwaitingApproval()} style={{
      backgroundColor: '#F8E02B',
      borderRadius: '4px',
      color: 'black',
      cursor: 'default',
      fontStyle: process.env.REACT_APP_MID_FONT_SIZE,
      fontWeight: 'bold',
      marginRight: '10px',
      padding: '10px 15px 10px 15px'
    }}>
      Awaiting Approval
    </div>
  </OverlayTrigger>

  <OverlayTrigger
    key={'bottomIndicator7'}
    placement={'left'}
    overlay={
      <Tooltip id={`tooltip-${'left'}`}>
        We are validating your application, {props.currentApplication.applicantDetails ? props.currentApplication.applicantDetails.firstName : ''}. You will get notified once we are done!
      </Tooltip>
    }>

    <div hidden={!props.toShowAwaitingValidation()} style={{
      backgroundColor: process.env.REACT_APP_YELLOW_COLOUR,
      borderRadius: '4px',
      color: 'black',
      cursor: 'default',
      fontStyle: process.env.REACT_APP_MID_FONT_SIZE,
      fontWeight: 'bold',
      marginRight: '10px',
      padding: '10px 15px 10px 15px',
    }}>
      Awaiting Validation
    </div>
  </OverlayTrigger>

  <OverlayTrigger
    key={'bottomIndicator8'}
    placement={'left'}
    overlay={
      <Tooltip id={`tooltip-${'left'}`}>
        You are currently inactive, {props.currentApplication.applicantDetails ? props.currentApplication.applicantDetails.firstName : ''}.
      </Tooltip>
    }>

    <div hidden={!props.toShowInactive()} style={{
      backgroundColor: '#FC7717',
      borderRadius: '4px',
      color: 'black',
      cursor: 'default',
      fontStyle: process.env.REACT_APP_MID_FONT_SIZE,
      fontWeight: 'bold',
      marginRight: '10px',
      padding: '10px 25px 10px 25px',
    }}>
      Inactive
    </div>
  </OverlayTrigger>

  <OverlayTrigger
    key={'bottomIndicator9'}
    placement={'left'}
    overlay={
      <Tooltip id={`tooltip-${'left'}`}>
        You are currently suspended, {props.currentApplication.applicantDetails ? props.currentApplication.applicantDetails.firstName : ''}.
      </Tooltip>
    }>

    <div hidden={!props.toShowSuspended()} style={{
      backgroundColor: '#FC7717',
      borderRadius: '4px',
      color: 'black',
      cursor: 'default',
      fontStyle: process.env.REACT_APP_MID_FONT_SIZE,
      fontWeight: 'bold',
      marginRight: '10px',
      padding: '10px 25px 10px 25px',
    }}>
      Suspended
    </div>
  </OverlayTrigger>

  <OverlayTrigger
    key={'bottomIndicator10'}
    placement={'left'}
    overlay={
      <Tooltip id={`tooltip-${'left'}`}>
        We are currently setting up your account. Kindly check back later.
      </Tooltip>
    }>

    <div hidden={!props.toShowSetupInProgress()} style={{
      backgroundColor: process.env.REACT_APP_YELLOW_COLOUR,
      borderRadius: '4px',
      color: 'black',
      cursor: 'default',
      fontStyle: process.env.REACT_APP_MID_FONT_SIZE,
      fontWeight: 'bold',
      marginRight: '10px',
      padding: '10px 15px 10px 15px',
    }}>
      Setup in Progress...
    </div>
  </OverlayTrigger>

  {/* <div className="grow" hidden={!props.toShowCompleteApplication()} style={{
    backgroundColor: process.env.REACT_APP_RED_COLOUR,
    borderRadius: '4px',
    fontStyle: process.env.REACT_APP_MID_FONT_SIZE,
    fontWeight: 'normal',
    marginRight: '10px',
    padding: '10px 15px 10px 15px',
    color: 'white',
    cursor: 'pointer',
    zIndex: '1'
  }}
    onClick={() => props.rejectedApplication ? props.navigateTo('/application?applicationRejected=true') : props.navigateTo('/application')}>
    {props.rejectedApplication ? 'Update Application' : 'Complete Application'}
  </div> */}

  {/* <div className="grow" hidden={!props.currentUser.isAgent || props.emailVerified} style={{
      backgroundColor: process.env.REACT_APP_RED_COLOUR, 
      borderRadius: '4px', 
      fontStyle: process.env.REACT_APP_MID_FONT_SIZE, 
      fontWeight: 'normal', 
      marginRight: '140px', 
      padding: '10px 15px 10px 15px', 
      color: 'white', 
      cursor: 'pointer'}} 
      onClick={() => props.resendEmail()}>
      Resend Email
    </div> */}

</>

export default props => {
  return props.mobile ? <Row style={{ zIndex: 1, right: '0pt', top: '67pt', width: '100vw', margin: 0, padding: 0 }}>
    <Col style={{ margin: 0, padding: 0 }}>
      {mobileApplicationStatusIndicators(props)}
    </Col>
  </Row> : desktopApplicationStatusIndicators(props)
}
