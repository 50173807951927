import Requester from "../finch-requester";
// import {agentDetails,failedAgentDetails,agentBVNInfo,updateBVNinformation} from '../../../utils/mocks/agentDetails'

const API_BASE_URL = process.env.REACT_APP_PLATFORM_API_BASE_URL;

export default class Platform {
  constructor(props) {
    this.apiRequester = props
      ? props.apiRequester ||
      new Requester({
        apiBaseUrl: API_BASE_URL,
      })
      : new Requester({
        apiBaseUrl: API_BASE_URL,
      });
  }
  getAgentByPhoneNumber(phoneNumber) {
    return this.apiRequester.getIgnore401({
      endpoint: `v2/finch-platform-service/agents/${phoneNumber}`,
    });
  }
  getAgregratorAgentByPhoneNumber(phoneNumber) {
    return this.apiRequester.get({
      endpoint: `v4/finch-platform-service/agents/search?agentMobileNo=${phoneNumber}`,
    });
  }
  getActiveAgentsDetails(agentCode) {
    return this.apiRequester.get({
      endpoint: `v5/finch-platform-service/agents/${agentCode}`,
    });
  }

  getAgentBVNInformation(agentCode) {
    return this.apiRequester.get({
      endpoint: `v2/finch-platform-service/bvn-records/agent/${agentCode}`,
    });
  }
  updateBVNinformation(bvnForm) {
    return this.apiRequester.put({
      endpoint: `v2/finch-platform-service/bvn-records/agent`,
      body: {
        id: bvnForm.id,
        bvnFirstName: bvnForm.bvnFirstName,
        bvnLastName: bvnForm.bvnLastName,
        bvnDateOfBirth: bvnForm.bvnDateOfBirth,
        bvnNumber: bvnForm.bvnNumber,
        bvnPhoneNumber: bvnForm.bvnPhoneNumber,
      },
    });
  }

  getCurrentAgent() {
    return this.apiRequester.getIgnore401({
      endpoint: "v2/finch-platform-service/agents/me",
    });
  }

  validateBVNDetails(bvnForm) {
    return this.apiRequester.post({
      endpoint: `v3/finch-platform-service/bvn-records/validate/bvn`,
      body: {
        agentPhoneNumber: bvnForm.agentPhoneNumber,
        bvnFirstName: bvnForm.bvnFirstName,
        bvnLastName: bvnForm.bvnLastName,
        bvnDateOfBirth: bvnForm.bvnDateOfBirth,
        bvnNumber: bvnForm.bvnNumber,
        bvnPhoneNumber: bvnForm.bvnPhoneNumber,
      },
    });
  }

  getCurrentUser() {
    return this.apiRequester.getIgnore401({
      endpoint: "v2/finch-platform-service/users/me",
    });
  }

  retrieveAgents() {
    return this.apiRequester.getIgnore401({
      endpoint: "v2/finch-platform-service/agents",
    });
  }

  retrieveSuperAgents() {
    return this.apiRequester.get({
      endpoint: "v2/finch-platform-service/super-agents/list",
    });
  }

  retrieveAgent(businessPhone) {
    return this.apiRequester.post({
      endpoint: `v2/finch-platform-service/agents/${businessPhone}`,
    });
  }

  retrieveCountries(pageNumber, pageSize, searchTerm) {
    return this.apiRequester.get({
      endpoint: "v2/finch-platform-service/countries",
      args: {
        pageNum: pageNumber,
        pageSize: pageSize,
        searchText: searchTerm,
      },
    });
  }

  retrieveLgas(stateId) {
    return this.apiRequester.get({
      endpoint: `v2/finch-platform-service/lgas/state/${stateId}`,
    });
  }

  retrieveRegions() {
    return this.apiRequester.get({
      endpoint: "v2/finch-platform-service/regions",
    });
  }

  retrieveStates(countryId) {
    return this.apiRequester.get({
      endpoint: `v2/finch-platform-service/states/country/${countryId}`,
    });
  }

  retrieveWards() {
    return this.apiRequester.get({
      endpoint: "v2/finch-platform-service/wards",
    });
  }

  createRole(payload) {
    return this.apiRequester.post({
      endpoint: "v2/finch-platform-service/users/roles/create",
      body: payload,
    });
  }

  retrieveRoles() {
    return this.apiRequester.get({
      endpoint: "v2/finch-platform-service/users/roles",
    });
  }

  editUserRole(payload) {
    return this.apiRequester.put({
      endpoint: `v2/finch-platform-service/users/roles/edit`,
      body: payload,
    });
  }

  removeRolePermission(name, permissions) {
    return this.apiRequester.put({
      endpoint: "v2/finch-platform-service/users/roles/permission/delete",
      body: {
        name,
        permissions,
      },
    });
  }

  retrieveRolePermissions(roleName) {
    return this.apiRequester.get({
      endpoint: "v2/finch-platform-service/users/role/permissions",
      args: {
        roleName,
      },
    });
  }

  createUser(payload) {
    return this.apiRequester.post({
      endpoint: "v2/finch-platform-service/users",
      body: payload,
    });
  }

  retrieveUser() {
    return this.apiRequester.get({
      endpoint: "v2/finch-platform-service/user",
    });
  }

  retrieveUsers() {
    return this.apiRequester.get({
      endpoint: "v2/finch-platform-service/users",
    });
  }

  getWalletStatus() {
    return this.apiRequester.get({
      endpoint: "v2/finch-platform-service/wallet/status",
    });
  }

  searchAgents(
    pageNum = null,
    pageSize = null,
    status = null,
    noPaging,
    noDetails
  ) {
    return this.apiRequester.get({
      endpoint: "v5/finch-platform-service/agents/search",
      args: {
        pageNum,
        pageSize,
        status,
        ...(noPaging && { noPaging: true }),
        ...(noDetails && { noDetails: true }),
      },
    });
  }

  documentUploadAggregatorClass(agentCode, kycDocType, file) {
    const formData = new FormData();

    formData.append("kycDoc", file);

    return this.apiRequester.post({
      endpoint: `v5/finch-platform-service/agents/${agentCode}/upgrade-requests/documents`,
      headers: {
        kycDocType,
        "content-type": "multipart/form-data",
      },
      body: formData,
    });
  }

  postAggregatorClassUpgrage(payload, agentCode) {
    return this.apiRequester.post({
      endpoint: `v5/finch-platform-service/agents/${agentCode}/upgrade-requests/initialize`,
      body: payload,
    });
  }
  submitAggregatorClassUpgrage(payload, agentCode) {
    return this.apiRequester.put({
      endpoint: `v5/finch-platform-service/agents/${agentCode}/upgrade-requests/complete`,
      body: payload,
    });
  }

  getBusinessType() {
    return this.apiRequester.get({
      endpoint: `v5/finch-platform-service/businesstypes`,
    });
  }

  fetchStandardBusinessTypes(tierId) {
    return this.apiRequester.get({
      endpoint: `v5/finch-platform-service/businesstypes/${tierId}`,
    });
  }

  agentCount() {
    return this.apiRequester.get({
      endpoint: "v2/finch-platform-service/agents/search/count",
      args: {},
    });
  }

  getUpgradeAttestation(id) {
    return this.apiRequester.get({
      endpoint: `v2/finch-platform-service/upgrade/attestation/${id}`,
    });
  }

  postUpgradeAttestation(id) {
    return this.apiRequester.post({
      endpoint: `v2/finch-platform-service/upgrade/attestation/${id}`,
      args: {},
    });
  }

  validateNINDetails(payload, validationType) {
    return this.apiRequester.post({
      endpoint: "v2/finch-platform-service/kyc-records/validate",
      body: payload,
      headers: {
        validationType: validationType,
        forceVerification: true,
      },
    });
  }

  validateBusinessDetails(payload, validationType) {
    return this.apiRequester.post({
      endpoint: "v2/finch-platform-service/kyc-records/validate",
      body: payload,
      headers: {
        validationType: validationType,
        forceVerification: true,
      },
    });
  }

  agentAccountDowngrade(requestBody) {
    return this.apiRequester.put({
      endpoint: `v5/finch-platform-service/agents/downgrade-request`,
      body: requestBody,
    });
  }

  // initializeUpgradeRequest(accessToken, domain_code, payload) {
  //   return this.apiRequester.post({
  //     endpoint: `v5/finch-platform-service/agents/${domain_code}/upgrade-requests/initialize`,
  //     body: payload,
  //     headers: {
  //       Authorization: `Bearer ${accessToken}`,
  //     },
  //   })
  // }

  initializeUpgradeRequest(payload, agentCode, token) {
    return this.apiRequester.post({
      endpoint: `v5/finch-platform-service/agents/${agentCode}/upgrade-requests/initialize`,
      body: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  }

  submitAgentClassUpgrage(payload, domain_code) {
    return this.apiRequester.post({
      endpoint: `v5/finch-platform-service/agents/${domain_code}/upgrade-requests/submit`,
      body: payload,
    });
  }

  getAgentInformationAttest(id) {
    return this.apiRequester.get({
      endpoint: `v5/finch-platform-service/upgrade/attestation`,
      args: { id },
    });
  }

  documentUploadAttestation(id, file) {
    const formData = new FormData();

    formData.append("file", file);
    return this.apiRequester.post({
      endpoint: `v5/finch-platform-service/upgrade/attestation`,
      headers: {
        "content-type": "multipart/form-data",
      },
      args: { id },
      body: formData,
    });
  }

  getModifiedAgentDetails(active) {
    return this.apiRequester.get({
      endpoint: `v5/finch-platform-service/agents/upgrade-requests?active=${active}`,
    });
  }

  postExpireRefereeLink(agentCode) {
    return this.apiRequester.post({
      endpoint: `v5/finch-platform-service/agents/upgrade-requests/attestation/decline?agentCode=${agentCode}`,
    });
  }

  approveAttestation(agentCode) {
    return this.apiRequester.put({
      endpoint: `v5/finch-platform-service/agents/${agentCode}/upgrade-requests/complete`,
    });
  }

  submitInitiateImageCapturing(payload) {
    return this.apiRequester.post({
      endpoint: `v2/finch-platform-service/kyc-records/initiate`,
      headers: payload,
    });
  }

  submitBusinessDetails(payload,id) {
    return this.apiRequester.post({
      endpoint: `v5/finch-platform-service/agents/kyc/${id}/upgrade-requests/initialize`,
      headers: payload,
    });
  }

  submitUploadImageCapturing(payload) {
    return this.apiRequester.post({
      endpoint: `v2/finch-platform-service/kyc-records/smile-id-upload`,
      body: payload,
    });
  }
  submitStatusImageCapturing(jobId) {
    return this.apiRequester.get({
      endpoint: `v2/finch-platform-service/kyc-records/status?jobId=${jobId}`,
    });
  }
  cbnComplianceOtp(tokenId) {
    return this.apiRequester.post({
      endpoint: `v2/finch-platform-service/otp/${tokenId}`,
    });
  }
  cbnComplianceValidateOtp(otp, tokenId, prefix) {
    return this.apiRequester.post({
      endpoint: `v2/finch-platform-service/otp/validate?otp=${prefix}-${otp}`,
      body: { tokenId },
    });
  }
  getBvnInfo() {
    return this.apiRequester.get({
      endpoint: "v2/finch-platform-service/bvn-records/get-record",
    });
  }
  postNextOfKinUpdate(payload, agentCode) {
    return this.apiRequester.post({
      endpoint: `v5/finch-platform-service/agents/kyc/${agentCode}/upgrade-requests/next-of-kin`,
      body: payload,
    });
  }
  postResidentialUpdate(payload, agentCode) {
    return this.apiRequester.post({
      endpoint: `v5/finch-platform-service/agents/kyc/${agentCode}/upgrade-requests/address`,
      body: payload,
    });
  }
  postRefereeAttestationSubmit(refereeEmail, agentCode) {
    return this.apiRequester.post({
      endpoint: `v5/finch-platform-service/agents/kyc/${agentCode}/upgrade-requests/submit?refereeEmail=${refereeEmail}`,
    });
  }
}
