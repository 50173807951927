import React from "react";
import {
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { connect } from "react-redux";
import Button from "../../../../../components/button";
import FormControl from "../../../../../components/form-control";
import H1 from "../../../../../components/h1";
import BaseScene from "../../../../base-scene";
// import DatePicker from 'react-datepicker';
// import DateInputButton from '../../../../../components/date-inputs/button';
import moment from "moment";
import { LeftArrowIcon } from "../../../../../../src/icons/index";
import { platformServiceClient } from "../../../../../App";
import { DatePickerComponent } from "../../../../../components/date-picker";
import Text from "../../../../../components/text";
import { ERROR_STATUS } from "../../../../../constants/api";
import {
  NOT_VERIFIED,
  PENDING_VERIFICATION,
  SUSPENDED,
  VERIFIED,
  VERIFIED_PARTIALLY,
} from "../../../../../constants/bvnVerificationStatus";
import {
  dismissErrorMessage,
  dismissSuccessMessage,
  showErrorMessage,
  showSuccessMessage,
} from "../../../../../services/redux/actions/tunnel";
import { subtractDays } from "../../../../../utils/time";
import {
  isFirstNameValid,
  isLastNameValid,
  isNumberValid,
  isPhoneValid,
  sanitizePhone,
} from "../../../../../utils/validators/form-validators";

class BVNDetailsForm extends BaseScene {
  //  platform = new Platform();

  constructor(props) {
    super(props);
    this.state = {
      bvnForm: {},
      isBvnLoading: false,
      showBvnUpdateResponseMsgModal: false,
      showConfirmBVNFormModal: false,
      disableForm: false,
      showConfirmationFormInModalMode: false,
      errors: {},
    };
    this.loadBVNData = this.loadBVNData.bind(this);
    this.onLoadBVNData = this.onLoadBVNData.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.updateBvnFormField = this.updateBvnFormField.bind(this);
    this.validateBVNFormFields = this.validateBVNFormFields.bind(this);
  }

  componentDidMount() {
    const currentUser = JSON.parse(localStorage.getItem("currentUser") || "{}");
    const agentCode = currentUser.domainCode;
    this.loadBVNData(agentCode).then((agentBVNData) => {
      this.onLoadBVNData(agentBVNData);
    });
    this.setState({
      disableForm:
        this.props.bvnVerificationStatus === VERIFIED ||
        this.props.bvnVerificationStatus === PENDING_VERIFICATION,
    });
  }

  async loadBVNData(agentCode) {
    this.setState({
      didErrorOccurWhileFetching: null,
      isLoading: true,
    });

    const { status, response } =
      await platformServiceClient.getAgentBVNInformation(agentCode);

    this.setState({
      isLoading: false,
    });

    if (status === ERROR_STATUS) {
      this.setState({
        didErrorOccurWhileFetching: true,
      });
      return null;
    }

    return response.data;
  }

  onLoadBVNData(agentBvnData) {
    this.setState({
      bvnForm: {
        id: agentBvnData ? agentBvnData.agentId : "",
        bvnFirstName: agentBvnData ? agentBvnData.bvnFirstName : "",
        bvnLastName: agentBvnData ? agentBvnData.bvnLastName : "",
        bvnNumber: agentBvnData ? agentBvnData.bvnNumber : "",
        bvnPhoneNumber: agentBvnData ? agentBvnData.bvnPhoneNumber : "",
        bvnDateOfBirth: agentBvnData ? agentBvnData.bvnDateOfBirth : "",
        agentCode: agentBvnData ? agentBvnData.agentCode : "",
        bvnVerificationStatus: agentBvnData
          ? agentBvnData.bvnVerificationStatus
          : "",
      },
    });
  }

  updateBvnFormField(params) {
    const newForm = {
      ...this.state.bvnForm,
      ...params,
    };

    this.setState({
      bvnForm: newForm,
    });
  }

  async onSubmit() {
    // if (!isFirstNameValid(this.state.bvnForm.bvnFirstName)) {
    //   // this.props.showErrorMessage("Enter a valid First Name");
    //   // setTimeout(
    //   //   this.props.dismissErrorMessage,
    //   //   parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
    //   // );
    //   this.setState({errorMessage:"Enter a valid First Name"})
    //   return;
    // }

    this.setState({
      isBvnLoading: true,
      showConfirmBVNFormModal: false,
    });

    const phone = sanitizePhone(this.state.bvnForm.bvnPhoneNumber);
    const newForm = {
      ...this.state.bvnForm,
      bvnPhoneNumber: phone,
    };

    const { status, response, code } =
      await platformServiceClient.updateBVNinformation(newForm);

    if (status === ERROR_STATUS) {
      this.setState({
        isBvnLoading: false,
        status: status,
        bvnUpdateResponseMsg: response,
        errorMessage: response?.message,
        // errorMessage: 'errors' in response? response.errors[0].message : response,
        showBvnUpdateResponseMsgModal: true,
      });

      if (this.props.showInstantBVNValidationMessage) {
        if ("code" in response && response.code === "40002") {
          const res = {
            message:
              response.description +
              " (" +
              JSON.stringify(response.errors) +
              ") ",
            validationStatus: "NOT_VERIFIED",
          };
          this.props.showInstantBVNValidationMessage(res);
        } else this.props.showInstantBVNValidationMessage(response);

        this.props.setShowSuspendedAgentModal(true);
        this.props.setBVNNotificationFlag(true);
      }

      setTimeout(() => {
        this.setState({ showBvnUpdateResponseMsgModal: false });
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));

      return null;
    }

    if (this.props.showInstantBVNValidationMessage) {
      this.props.showInstantBVNValidationMessage(response);
      //this.props.showThankyouMessage(true);
      this.props.setShowSuspendedAgentModal(false);
      this.props.setBVNNotificationFlag(false);
    }

    //display successful messages
    this.setState({
      isBvnLoading: false,
      status: status,
      bvnUpdateResponseMsg: response, //"successful",
      showBvnUpdateResponseMsgModal: true,
      disableForm: true,
    });
    setTimeout(() => {
      this.setState({ showBvnUpdateResponseMsgModal: false });
    }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
  }

  isBVNDateOfBirthValid(dob) {
    return dob ? true : false;
  }

  validateBVNFormFields() {
    // const errors = {}
    this.setState({ errors: {} });

    var letters = /^[A-Za-z]+$/;

    if (
      !isFirstNameValid(this.state.bvnForm.bvnFirstName) ||
      !this.state.bvnForm.bvnFirstName.match(letters)
    ) {
      this.setState({ errors: { firstName: "Enter a valid First Name" } });
      return;
    }

    if (
      !isLastNameValid(this.state.bvnForm.bvnLastName) ||
      !this.state.bvnForm.bvnLastName.match(letters)
    ) {
      // errors.lastName =  "Enter a valid Last Name/Surname"
      this.setState({
        errors: { lastName: "Enter a valid Last Name/Surname!" },
      });
      return;
    }

    if (!isPhoneValid(this.state.bvnForm.bvnPhoneNumber, "234")) {
      this.setState({
        errors: { bvnPhoneNumber: "Enter a valid Phone Number!" },
      });

      return;
    }

    if (this.state.bvnForm.bvnNumber === "") {
      //  errors.bvn = "BVN can not be empty"
      this.setState({ errors: { bvn: "BVN can not be empty!" } });
      return;
    }

    if (this.props.bvnFormInModalMode === true)
      this.setState({
        showConfirmationFormInModalMode: true,
      });
    else this.setState({ showConfirmBVNFormModal: true });
  }
  render() {
    const bvnUpdateResponseMsgModal = (
      <Modal
        style={{ borderRadius: "10px", transition: "all .2s ease-in-out" }}
        animation="true"
        keyboard="true"
        size={`${
          this.state?.bvnUpdateResponseMsg?.validationStatus === "NOT_VERIFIED"
            ? "None"
            : "sm"
        }`}
        show={this.state.showBvnUpdateResponseMsgModal}
      >
        <Modal.Body
          className={`${
            this.state?.bvnUpdateResponseMsg?.code === "200" ||
            this.state?.bvnUpdateResponseMsg?.validationStatus === VERIFIED
              ? "successful"
              : this.state?.bvnUpdateResponseMsg?.validationStatus ===
                VERIFIED_PARTIALLY
              ? "partialSuccess"
              : "failed"
          }-popup-style`}
        >
          <center>
            <div style={{ marginBottom: "30px" }}>
              <Text
                style={{
                  fontSize: "14px",
                  lineHeight: process.env.REACT_APP_MID_BIG_LINE_HEIGHT,
                  margin: "10px 0px 20px 0px",
                  color: `${
                    this.state?.bvnUpdateResponseMsg?.validationStatus ===
                    VERIFIED_PARTIALLY
                      ? "#000"
                      : "#fff"
                  }`,
                  fontWeight: "bold",
                }}
              >
                {this.state?.bvnUpdateResponseMsg?.message}.
                {/* {this.state?.errorMessage }. */}
              </Text>
            </div>
          </center>
        </Modal.Body>
      </Modal>
    );

    const confirmBVNFormModal = (
      <Modal
        style={{ borderRadius: "10px", transition: "all .2s ease-in-out" }}
        animation="true"
        keyboard="true"
        size="lg"
        show={this.state.showConfirmBVNFormModal}
        onHide={() => {
          this.setState({ showConfirmBVNFormModal: false });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>BVN Information Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form style={{ height: "flex:1" }} id="bvnConfirmationForm">
            <div>
              <Row style={{ padding: "30px" }}>
                <Col xs={12} md={6} style={{ padding: "20px" }}>
                  <Form.Label
                    style={{
                      color: "#3A3B3B",
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                      marginRight: "10px",
                    }}
                  >
                    First Name:
                  </Form.Label>
                  <Form.Label
                    style={{
                      color: "#3A3B3B",
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                      paddingLeft: "15px",
                    }}
                  >
                    {this.state.bvnForm.bvnFirstName}
                  </Form.Label>
                </Col>
                <Col xs={12} md={6} style={{ padding: "20px" }}>
                  <Form.Label
                    style={{
                      color: "#3A3B3B",
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                      marginRight: "10px",
                    }}
                  >
                    Last Name/Surname:
                  </Form.Label>
                  <Form.Label
                    style={{
                      color: "#3A3B3B",
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                      paddingLeft: "30px",
                    }}
                  >
                    {this.state.bvnForm.bvnLastName}
                  </Form.Label>
                </Col>
                <Col xs={12} md={6} style={{ padding: "20px" }}>
                  <Form.Label
                    style={{
                      color: "#3A3B3B",
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                      marginRight: "10px",
                    }}
                  >
                    BVN Number:
                  </Form.Label>
                  <Form.Label
                    style={{
                      color: "#3A3B3B",
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                    }}
                  >
                    {this.state.bvnForm.bvnNumber}
                  </Form.Label>
                </Col>
                <Col xs={12} md={6} style={{ padding: "20px" }}>
                  <Form.Label
                    style={{
                      color: "#3A3B3B",
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                      marginRight: "10px",
                    }}
                  >
                    Phone Number:
                  </Form.Label>
                  <Form.Label
                    style={{
                      color: "#3A3B3B",
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                    }}
                  >
                    {this.state.bvnForm.bvnPhoneNumber}
                  </Form.Label>
                </Col>
                <Col xs={12} md={6} style={{ padding: "20px" }}>
                  <Form.Label
                    style={{
                      color: "#3A3B3B",
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                      marginTop: "5px",
                      marginRight: "10px",
                    }}
                  >
                    Date Of Birth:
                  </Form.Label>
                  <Form.Label
                    style={{
                      color: "#3A3B3B",
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                      marginTop: "5px",
                    }}
                  >
                    {moment(this.state.bvnForm.bvnDateOfBirth).format(
                      "YYYY-MM-DD"
                    )}
                  </Form.Label>
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>

        <Modal.Footer style={{ justifyContent: "center" }}>
          <Button
            onClick={(e) => {
              e.preventDefault();
              this.onSubmit();
            }}
          >
            proceed
          </Button>
        </Modal.Footer>
      </Modal>
    );

    if (this.state.showConfirmationFormInModalMode === true) {
      return (
        <React.Fragment>
          <Form
            style={{ flex: 1, textAlign: "center" }}
            id="supendedAgentConfirmationForm"
          >
            <Row>
              <Col xs={12} md={12}>
                <OverlayTrigger
                  key={"bottom1"}
                  placement={"right"}
                  overlay={<Tooltip id={`tooltip-${"left"}`}>Back</Tooltip>}
                >
                  <div
                    style={{
                      marginLeft: "0px",
                      width: "10px",
                      height: "30px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ showConfirmationFormInModalMode: false });
                    }}
                  >
                    {LeftArrowIcon()}
                  </div>
                </OverlayTrigger>
              </Col>
            </Row>
            <div>
              <Row>
                <Col xs={12} md={12}>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      marginBottom: "50px",
                    }}
                  >
                    BVN Information confirmation
                  </div>
                </Col>
              </Row>

              <Row className="AddHorizontalSpace">
                <Col xs={12} md={6}>
                  <Form.Label
                    style={{
                      color: "#3A3B3B",
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                      marginRight: "10px",
                    }}
                  >
                    First Name:
                  </Form.Label>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Label
                    style={{
                      color: "#3A3B3B",
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                      paddingLeft: "15px",
                    }}
                  >
                    {this.state.bvnForm.bvnFirstName}
                  </Form.Label>
                </Col>
              </Row>

              <Row className="AddHorizontalSpace">
                <Col xs={12} md={6}>
                  <Form.Label
                    style={{
                      color: "#3A3B3B",
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                      marginLeft: "50px",
                    }}
                  >
                    Last Name/Surname:
                  </Form.Label>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Label
                    style={{
                      color: "#3A3B3B",
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                      paddingLeft: "30px",
                    }}
                  >
                    {this.state.bvnForm.bvnLastName}
                  </Form.Label>
                </Col>
              </Row>

              <Row className="AddHorizontalSpace">
                <Col xs={12} md={6}>
                  <Form.Label
                    style={{
                      color: "#3A3B3B",
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                      marginLeft: "5px",
                    }}
                  >
                    BVN Number:
                  </Form.Label>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Label
                    style={{
                      color: "#3A3B3B",
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                    }}
                  >
                    {this.state.bvnForm.bvnNumber}
                  </Form.Label>
                </Col>
              </Row>

              <Row className="AddHorizontalSpace">
                <Col xs={12} md={6}>
                  <Form.Label
                    style={{
                      color: "#3A3B3B",
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                      marginLeft: "20px",
                    }}
                  >
                    Phone Number:
                  </Form.Label>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Label
                    style={{
                      color: "#3A3B3B",
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                    }}
                  >
                    {this.state.bvnForm.bvnPhoneNumber}
                  </Form.Label>
                </Col>
              </Row>

              <Row className="AddHorizontalSpace">
                <Col xs={12} md={6}>
                  <Form.Label
                    style={{
                      color: "#3A3B3B",
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                      marginTop: "5px",
                      marginLeft: "5px",
                    }}
                  >
                    Date Of Birth:
                  </Form.Label>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Label
                    style={{
                      color: "#3A3B3B",
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                      marginTop: "5px",
                    }}
                  >
                    {moment(this.state.bvnForm.bvnDateOfBirth).format(
                      "YYYY-MM-DD"
                    )}
                  </Form.Label>
                </Col>
              </Row>

              <Row className="AddHorizontalSpace">
                <Col xs={12} md={12} style={{ padding: "20px" }}>
                  <center>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        this.onSubmit();
                      }}
                    >
                      {" "}
                      {this.state.isBvnLoading ? "Loading..." : "Proceed"}
                    </Button>
                  </center>
                </Col>
              </Row>
            </div>
          </Form>
        </React.Fragment>
      );
    } else {
      const logoutLink = <span style={{ color: "#0056b3" }}>Logout</span>;
      //const
      return (
        <React.Fragment>
          <Form>
            <Row>
              <Col xs={12} md={12}>
                {this.props.bvnFormInModalMode &&
                  (this.props.bvnVerificationState === VERIFIED_PARTIALLY ? (
                    <OverlayTrigger
                      key={"bottom1"}
                      placement={"right"}
                      overlay={<Tooltip id={`tooltip-left`}>back</Tooltip>}
                    >
                      <div
                        style={{
                          marginLeft: "0px",
                          height: "30px",
                          width: "10px",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.setShowSuspendedAgentModal(false);
                        }}
                      >
                        {LeftArrowIcon()}
                      </div>
                    </OverlayTrigger>
                  ) : (
                    <div
                      style={{
                        marginLeft: "0px",
                        height: "30px",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        window.location.href = "/logout";
                      }}
                    >
                      {logoutLink}
                    </div>
                  ))}
              </Col>
            </Row>

            <div>
              <H1
                style={{
                  color: "#3A3B3B",
                  fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
                  fontSize: "24px",
                  fontWeight: "400",
                  padding: "35px",
                  paddingLeft: 0,
                  textAlign: "left",
                }}
              >
                <span>BVN Information</span>
                {this.props.bvnVerificationStatus === VERIFIED && (
                  <img
                    style={{
                      height: "24px",
                      width: "24px",
                      opacity: "1",
                      position: "relative",
                      zIndex: "2",
                    }}
                    src={"/assets/media/icons/icons8-ok.svg"}
                    alt=""
                  />
                )}
              </H1>

              <Row>
                <Col xs={12} md={4}>
                  <Form.Group>
                    <Form.Label
                      style={{
                        color: "#3A3B3B",
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        lineHeight: "19px",
                      }}
                    >
                      First Name:
                    </Form.Label>
                    <FormControl
                      type="text"
                      placeholder="Your BVN firstname"
                      onChange={(event) => {
                        this.setState({ errors: {} });
                        const bvnFirstName = event.target.value;
                        this.updateBvnFormField({ bvnFirstName });
                      }}
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "20px",
                        border: `1px solid ${
                          !this.state.bvnForm.firstName ||
                          isFirstNameValid(this.state.bvnForm.firstName)
                            ? "#DFE2E6"
                            : process.env.REACT_APP_RED_COLOUR
                        }`,
                        boxSizing: "border-box",
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        lineHeight: "17px",
                      }}
                      value={this.state.bvnForm.bvnFirstName}
                      disabled={this.state.disableForm}
                      // disabled={this.state.disableForm || this.props.bvnVerificationStatus=== SUSPENDED || this.props.bvnVerificationStatus=== NOT_VERIFIED}
                    />
                    <Form.Label style={{ color: "#ff0000" }}>
                      {this.state.errors && this.state.errors.firstName}
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                  <Form.Group>
                    <Form.Label
                      style={{
                        color: "#3A3B3B",
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        lineHeight: "19px",
                      }}
                    >
                      Last Name/Surname:
                    </Form.Label>
                    <FormControl
                      type="text"
                      placeholder="Your BVN LastName/Surname"
                      onChange={(event) => {
                        this.setState({ errors: {} });
                        const bvnLastName = event.target.value;
                        this.updateBvnFormField({ bvnLastName });
                      }}
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "20px",
                        border: `1px solid ${
                          !this.state.bvnForm.lastName ||
                          isLastNameValid(this.state.bvnForm.lastName)
                            ? "#DFE2E6"
                            : process.env.REACT_APP_RED_COLOUR
                        }`,
                        boxSizing: "border-box",
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        lineHeight: "17px",
                      }}
                      value={this.state.bvnForm.bvnLastName}
                      disabled={this.state.disableForm}
                    />
                    <Form.Label style={{ color: "#ff0000" }}>
                      {this.state.errors && this.state.errors.lastName}
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col xs={12} md={4}>
                  <Form.Group>
                    <Form.Label
                      style={{
                        color: "#3A3B3B",
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        lineHeight: "19px",
                      }}
                    >
                      BVN Number:
                    </Form.Label>
                    <FormControl
                      type="text"
                      placeholder="Your  BVN Number"
                      onChange={(event) => {
                        this.setState({ errors: {} });
                        const bvnNumber = event.target.value;
                        this.updateBvnFormField({ bvnNumber });
                      }}
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "20px",
                        border: `1px solid ${
                          this.state.bvnForm.bvnNumber
                            ? "#DFE2E6"
                            : process.env.REACT_APP_RED_COLOUR
                        }`,
                        boxSizing: "border-box",
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        lineHeight: "17px",
                      }}
                      value={this.state.bvnForm.bvnNumber}
                      // disabled={this.state.disableForm || this.props.bvnVerificationStatus=== VERIFIED_PARTIALLY}
                      disabled={this.state.disableForm}
                    />
                    <Form.Label style={{ color: "#ff0000" }}>
                      {this.state.errors && this.state.errors.bvn}
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col xs={12} md={4}>
                  <Form.Group>
                    <Form.Label
                      style={{
                        color: "#3A3B3B",
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        lineHeight: "19px",
                      }}
                    >
                      Phone Number:
                    </Form.Label>
                    <OverlayTrigger
                      key={"bottom1"}
                      placement={"right"}
                      overlay={
                        <Tooltip id={`tooltip-${"left"}`}>
                          Enter bvn phonenumber not same as Agent login number
                        </Tooltip>
                      }
                    >
                      <FormControl
                        type="text"
                        placeholder="Enter bvn phonenumber not same as Agent login number"
                        onChange={(event) => {
                          this.setState({ errors: {} });
                          const bvnPhoneNumber = event.target.value;
                          this.updateBvnFormField({ bvnPhoneNumber });
                        }}
                        style={{
                          backgroundColor: "#FFFFFF",
                          borderRadius: "20px",
                          border: `1px solid ${
                            !this.state.bvnForm.bvnPhoneNumber ||
                            isNumberValid(this.state.bvnForm.bvnPhoneNumber)
                              ? "#DFE2E6"
                              : process.env.REACT_APP_RED_COLOUR
                          }`,
                          boxSizing: "border-box",
                          fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                          lineHeight: "17px",
                        }}
                        value={this.state.bvnForm.bvnPhoneNumber}
                        disabled={
                          this.state.disableForm ||
                          this.props.bvnVerificationState === SUSPENDED ||
                          this.props.bvnVerificationStatus === NOT_VERIFIED ||
                          this.props.bvnVerificationStatus ===
                            VERIFIED_PARTIALLY
                        }
                      />
                    </OverlayTrigger>
                    <Form.Label style={{ color: "#ff0000" }}>
                      {this.state.errors && this.state.errors.bvnPhoneNumber}
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                  <Form.Group>
                    <Form.Label
                      style={{
                        color: "#3A3B3B",
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        lineHeight: "19px",
                        marginTop: "5px",
                        marginRight: "20px",
                      }}
                    >
                      Date Of Birth:
                    </Form.Label>
                    <div>
                      <DatePickerComponent
                        onChange={(event) => {
                          const bvnDateOfBirth =
                            moment(event).format("YYYY-MM-DD");
                          this.updateBvnFormField({ bvnDateOfBirth });
                        }}
                        size={`150px`}
                        format={`dd-MM-yyyy`}
                        isClearable={true}
                        showTimeInput={true}
                        style={{
                          backgroundColor: "#FFFFFF",
                          borderRadius: "20px",
                          border: `1px solid ${
                            !this.state.bvnForm.bvnDateOfBirth
                              ? "#DFE2E6"
                              : process.env.REACT_APP_RED_COLOUR
                          }`,
                          boxSizing: "border-box",
                          fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                          lineHeight: "17px",
                        }}
                        // style={"0.4rem"}
                        textStyle={"1.0rem"}
                        iconStyle={"0.2rem"}
                        dateType={"startDate"}
                        preloadDate={
                          false /*!JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA) */
                        }
                        minDate={subtractDays(new Date(), 365 * 90)}
                        maxDate={subtractDays(new Date(), 365 * 18)}
                        disabled={
                          this.state.disableForm ||
                          this.props.bvnVerificationStatus === SUSPENDED ||
                          this.props.bvnVerificationStatus === NOT_VERIFIED ||
                          this.props.bvnVerificationStatus ===
                            VERIFIED_PARTIALLY
                        }
                        selected={this.state.bvnForm.bvnDateOfBirth}
                      />
                    </div>
                  </Form.Group>
                </Col>
                {!this.state.disableForm && this.state.bvnForm.id && (
                  <Col md={12} className="proceed-button-col">
                    <Button
                      className="services-proceed-button"
                      onClick={(e) => {
                        e.preventDefault();

                        this.validateBVNFormFields();
                      }}
                    >
                      {this.state.isBvnLoading ? "Loading..." : "Update"}
                    </Button>
                  </Col>
                )}
              </Row>
            </div>
          </Form>

          {this.state.showBvnUpdateResponseMsgModal &&
            bvnUpdateResponseMsgModal}
          {this.state.showConfirmBVNFormModal && confirmBVNFormModal}
        </React.Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    errorMessage: state.tunnel.errorMessage,
    successMessage: state.tunnel.successMessage,
    isLoading: state.tunnel.isLoading,
    loadingPercentage: state.tunnel.loadingPercentage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dismissErrorMessage: (message) => dispatch(dismissErrorMessage(message)),
    showErrorMessage: (message) => dispatch(showErrorMessage(message)),
    showSuccessMessage: (message) => dispatch(showSuccessMessage(message)),
    dismissSuccessMessage: (message) =>
      dispatch(dismissSuccessMessage(message)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BVNDetailsForm);
