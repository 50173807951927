import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Redirect } from "react-router-dom";

import Button from "../../components/button";
import Footer from "../../components/footer";
import Header from "../../components/header";
import BaseScene from "../base-scene";
import "./index.scss";

const IMacIcon = "/assets/media/images/iMac.png";
const IPhoneXIcon = "/assets/media/images/iPhone X.png";
const LandingPageChartIcon = "/assets/media/icons/landing-page-chart-icon.png";
const MacBookIcon = "/assets/media/images/Macbook.png";
const CbnLogo = "/assets/media/images/cbn.png";
const PlayStoreIcon = "/assets/media/icons/play-store.png";
const AppleStoreIcon = "/assets/media/icons/apple-store.png";

class LandingScene extends BaseScene {
  location = window.location.href;
  path = "/";
  title = "Landing";

  isAuthRequired = false;
  requiresEmptySession = true;

  renderDesktopView() {
    if (this.state.redirectToSignup) {
      return <Redirect to="/signup" />;
    }
    return (
      <div className="landing-page-container">
        <Header
          transparent={true}
          showLoginAndSignup={true}
          loginButtonStyle={{
            backgroundColor: process.env.REACT_APP_BLUE_COLOUR,
            color: "white",
          }}
          outerDivStyle={{
            backgroundColor: this.state.deviceIsPC ? "white" : "white",
            position: "fixed",
            zIndex: 100,
            // width: '100%',
            // maxWidth: '1440px'
          }}
        />

        <section className="landing-page-section">
          <svg
            hidden_={!this.state.deviceIsPC}
            style={{
              position: "absolute",
              zIndex: 0,
              bottom: this.state.deviceIsPC ? "-7%" : "-42.5vh",
            }}
            width="1440dp"
            height="622dp"
            viewBox="0 0 1440 622"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 247.82C0 247.82 104.352 388 272.469 374C449.468 359.26 521.768 230.644 680.33 131.552C842.827 30.0001 1039.49 5.75783 1150.41 0.444883C1217.53 -2.76993 1440 72.6646 1440 72.6646V622H0L0 247.82Z"
              fill="rgb(234, 236, 239)"
            />
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="0"
                y1="0.313477"
                x2="0"
                y2="622"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColour="#EAECEF" />
                <stop offset="1" stopColour="white" />
              </linearGradient>
            </defs>
          </svg>

          <svg
            hidden={!this.state.deviceIsPC}
            style={{ position: "absolute", zIndex: 1, right: "0vw" }}
            width="510dp"
            height="700dp"
            viewBox="-150 0 1 770"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M505 670C728.675 670 910 488.675 910 265C910 41.3247 728.675 -140 505 -140C281.325 -140 100 41.3247 100 265C100 488.675 281.325 670 505 670Z"
              stroke="#00425F"
              strokeWidth="200"
            />
          </svg>

          <div className="row landing-page-row">
            <div
              className="col col-xs-12 col-md-12 col-lg-6 landing-page-col"
              id="section-1-text-container"
              style={{
                paddingLeft: this.state.deviceIsPC ? "5%" : "20pt",
                paddingRight: this.state.deviceIsPC ? "0pt" : "20pt",
                zIndex: 1,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "10px",
                  padding: "20px",
                  color: process.env.REACT_APP_BLUE_COLOUR,
                  border: "1px solid #D7F2FE",
                  background: "#F5FCFF",
                  fontFamily: process.env.REACT_APP_FONT_FAMILY_HEADER,
                  marginTop: this.state.deviceIsPC ? "70px" : "50pt",
                  marginBottom: this.state.deviceIsPC ? "25px" : "50pt",
                  maxWidth: "700px",
                }}
              >
                <div>
                  <img
                    alt="cbn-logo"
                    src={CbnLogo}
                    style={{ zIndex: 3 }}
                    width="60%"
                  />
                </div>
                <span style={{ fontWeight: "normal" }}>
                  Interswitch Financial Inclusion Services Limited is licensed
                  by the Central Bank of Nigeria
                </span>
              </div>
              <div
                style={{
                  color: process.env.REACT_APP_BLUE_COLOUR,
                  fontFamily: process.env.REACT_APP_FONT_FAMILY_HEADER,
                  fontSize: this.state.deviceIsMobile ? "38px" : "50px",
                  lineHeight: this.state.deviceIsMobile ? "45px" : "62px",
                  marginTop: this.state.deviceIsPC ? "0px" : "50pt",
                  textAlign: this.state.deviceIsMobile ? "center" : "left",
                }}
              >
                <span style={{ fontWeight: "bold" }}>
                  Open doors to more income
                </span>
                <span style={{ fontWeight: "normal" }}>
                  , become a Quickteller Paypoint Agent
                </span>
              </div>

              <div
                style={{
                  color: "#A2A2A2",
                  fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
                  fontSize: "18px",
                  lineHeight: "25px",
                  paddingTop: "20px",
                  textAlign: this.state.deviceIsMobile ? "center" : "left",
                }}
              >
                Quickteller Paypoint agents earn consistent income offering
                services like Bill Payment, Funds Transfer, Cash Deposits, Cash
                Withdrawals, Insurance, and Airtime Recharge to customers in
                their community.
              </div>

              <div
                style={{
                  justifyContent: this.state.deviceIsMobile
                    ? "center"
                    : "flex-start",
                  display: "flex",
                  marginTop: "30pt",
                }}
              >
                <Button
                  style={{
                    backgroundColor: process.env.REACT_APP_RED_COLOUR,
                    borderColor: "transparent",
                    color: "white",
                    fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
                    fontSize: "20px",
                    fontWeight: "bold",
                    lineHeight: "24px",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    paddingLeft: "50px",
                    paddingRight: "50px",
                    width: this.state.deviceIsMobile ? "100%" : null,
                  }}
                  onClick={() => {
                    this.setState({ redirectToSignup: true });
                  }}
                >
                  TRY FOR FREE
                </Button>
                &nbsp;
                <a
                  href="https://interswitchgroup.com/paypoint-new/agents.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    outline
                    style={{
                      backgroundColor: "#ffffff",
                      color: process.env.REACT_APP_RED_COLOUR,
                      fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
                      fontSize: "14px",
                      fontWeight: "bold",
                      lineHeight: "24px",
                      paddingTop: "20px",
                      paddingBottom: "20px",
                      paddingLeft: "35px",
                      paddingRight: "35px",
                      width: this.state.deviceIsMobile ? "100%" : null,
                    }}
                  >
                    FIND AN AGENT
                  </Button>
                </a>
              </div>
            </div>

            <div
              className="col col-xs-12 col-md-6 landing-page-col"
              hidden={!this.state.deviceIsPC}
              id="section-1-image-container"
            >
              <img alt="" src={MacBookIcon} style={{ zIndex: 3 }} width="90%" />
            </div>
          </div>
        </section>

        <section
          className="landing-page-section"
          style={{
            backgroundColor: "#f2f2f2",
            height: "auto",
          }}
        >
          <div className="row landing-page-row">
            <div className="col col-xs-12 col-md-12 col-lg-6 landing-page-col">
              <img
                src={"/assets/media/images/shutterstock_722577382.png"}
                height={this.state.deviceIsMobile ? "380dp" : "80%"}
                alt=""
                style={{
                  marginTop: "12pt",
                }}
              />
            </div>

            <div
              className="col col-xs-12 col-md-12 col-lg-6 landing-page-col"
              style={{
                alignItems: this.state.deviceIsMobile ? "center" : "flex-start",
                display: "flex",
                flexDirection: "column",
                paddingLeft: this.state.deviceIsMobile ? "5%" : null,
                paddingRight: "5%",
              }}
            >
              <div
                style={{
                  color: process.env.REACT_APP_BLUE_COLOUR,
                  fontFamily: process.env.REACT_APP_FONT_FAMILY_HEADER,
                  fontSize: "42px",
                  lineHeight: "52px",
                  fontWeight: "bold",
                  marginTop: this.state.deviceIsPC ? "10pt" : "10pt",
                }}
              >
                What do you gain by becoming a Quickteller Paypoint agent?
              </div>

              <div
                style={{
                  color: "#A2A2A2",
                  fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
                  fontSize: "18px",
                  lineHeight: "25px",
                  marginTop: "10pt",
                }}
              >
                You opened your shop to make money, becoming a Quickteller
                Paypoint Agent increases your opportunities to make more money
                consistently.
                <br />
                <br />
                <li>Viable Business Opportunity</li>
                <li>Daily Commission & Attractive Incentive</li>
                <li>Additional footfall to your business site</li>
                <li>Increased opportunity to market your business</li>
                <li>Social Relevance to your community</li>
                <li>Visibility for your brand</li>
                <br />
                We start by holding your hands till you become an expert at
                delivering financial services to your community. And we never
                leave you, we stand by you, providing prompt support when you
                need it.
              </div>

              <div style={{ marginTop: "30px" }}>
                <Button
                  style={{
                    backgroundColor: process.env.REACT_APP_RED_COLOUR,
                    borderColor: "transparent",
                    color: "white",
                    fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
                    fontSize: "20px",
                    fontWeight: "bold",
                    lineHeight: "24px",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    paddingLeft: "50px",
                    paddingRight: "50px",
                    width: this.state.deviceIsMobile ? "100%" : null,
                  }}
                  onClick={() => {
                    this.setState({ redirectToSignup: true });
                  }}
                >
                  BECOME AN AGENT
                </Button>
              </div>
            </div>
          </div>
        </section>

        <section
          className="landing-page-section"
          style={{ backgroundColor: "#F2F2F2" }}
        >
          <div className="row landing-page-row">
            {[
              {
                title: "Platform & Content",
                content: [
                  "Process payments for over 8,000 billers & merchants",
                  "Backed by the Industry enabler and Innovator",
                  "Steady supply of relevant content",
                ],
              },
              {
                title: "Guaranteed Successful Entrepreneurs",
                content: [
                  "Substantial commissions and incentives",
                  "Comprehensive service suite",
                  "Reliable round the clock support",
                ],
              },
              {
                title: "Effective Agent Training",
                content: [
                  "Onsite Training",
                  "Regular Visitation",
                  "Periodic Refresher Training",
                ],
              },
              {
                title: "Marketing & Branding",
                content: [
                  "Always-on targeted marketing communications support",
                  "Improved brand association",
                  "Point of Sale promotional items",
                ],
              },
            ].map((item, index) => (
              <div
                key={index}
                className="col col-xs-12 col-md-6 col-lg-3"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "300px",
                  justifyContent: "center", // Center content vertically
                  alignItems: "center", // Center content horizontally
                }}
              >
                <center>
                  <img
                    src={LandingPageChartIcon}
                    style={{ height: "100pt" }}
                    alt=""
                  />
                </center>

                <div
                  style={{
                    color: process.env.REACT_APP_BLUE_COLOUR,
                    fontFamily: process.env.REACT_APP_FONT_FAMILY_HEADER,
                    fontSize: "26pt",
                    fontWeight: "bold",
                    lineHeight: "36px",
                    textAlign: "center",
                  }}
                >
                  {item.title}
                </div>

                <div
                  style={{
                    color: "#A2A2A2",
                    fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "25px",
                    marginTop: "10px",
                    textAlign: "start",
                  }}
                >
                  <ul>
                    {item.content.map((point, pointIndex) => (
                      <li key={pointIndex}>{point}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </section>

        <section
          className="landing-page-section"
          style={{ backgroundColor: "#EAECEF", height: "auto" }}
        >
          <div
            className="row landing-page-row"
            style={{
              display: "flex",
              alignItems: "center",
              padding: this.state.deviceIsPC ? "5%" : "30px",
              backgroundColor: "#EAECEF",
              paddingTop: 0,
            }}
          >
            <div className="col col-xs-12 col-md-6">
              <div style={{ minHeight: "170px" }}>
                <img src={IMacIcon} width="100%" alt="" />
                <img
                  src={IPhoneXIcon}
                  style={{
                    position: "absolute",
                    left: "0",
                    top: "35%",
                  }}
                  width="33%"
                  alt=""
                />
              </div>
            </div>

            <div className="col col-xs-12 col-md-6">
              <div
                style={{
                  color: process.env.REACT_APP_BLUE_COLOUR,
                  fontFamily: process.env.REACT_APP_FONT_FAMILY_HEADER,
                  fontSize: "42px",
                  lineHeight: "60px",
                  fontWeight: "bold",
                  textAlign: "center",
                  marginTop: "15pt",
                }}
              >
                How does this App work?
              </div>

              <ul className="timeline">
                <li>
                  <h4>
                    <strong>#1 Step</strong>
                  </h4>

                  <p>
                    Download the Quickteller Paypoint Mobile App. <br />
                    <br />
                    You’ll find the Quickteller for Agents app in your app
                    store. Download and install it to begin your journey to
                    steady income.
                  </p>
                </li>
                <li>
                  <h4>
                    <strong>#2 Step</strong>
                  </h4>

                  <p>
                    Register as an agent and complete your verification. <br />
                    <br />
                    We’ll complete your registration with a quick verification
                    of your details and set you up to start processing
                    transactions and making money.
                  </p>
                </li>
                <li>
                  <h4>
                    <strong>#3 Step</strong>
                  </h4>
                  <p>
                    Start delivering services and earning steady commission.{" "}
                    <br />
                    <br />
                    Now, you are ready. Your business is now a one stop-shop for
                    bill payments, subscriptions, and other financial services.
                  </p>
                </li>
              </ul>

              {/* TODO: link should be change to a valid playstore/appstore url */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <a
                  href="https://play.google.com/store/apps/details?id=com.interswitchng.ifisagent"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    style={{ width: "170px" }}
                    src={AppleStoreIcon}
                    alt="apple"
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.interswitchng.ifisagent"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    style={{ width: "120px" }}
                    src={PlayStoreIcon}
                    alt="android"
                  />
                </a>
              </div>
            </div>
          </div>
        </section>

        {/* <Footer relative={true} /> */}
      </div>
    );
  }

  renderMobileView() {
    if (this.state.redirectToSignup) {
      return <Redirect to="/signup" />;
    }

    // #F2F4F5

    return (
      <Container
        fluid
        style={{
          fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
          margin: 0,
          padding: 0,
          width: "100%",
          maxWidth: "1440px",
          marginLeft: "auto",
          marginRight: "auto",
          backgroundColor: "#FEFEFE",
        }}
      >
        <div hidden={this.state.deviceIsMobile}>
          <svg
            hidden={!this.state.deviceIsPC}
            style={{ position: "absolute", top: "200px", maxWidth: "1440px" }}
            width="100%"
            height="622"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 247.82C0 247.82 104.352 388 272.469 374C449.468 359.26 521.768 230.644 680.33 131.552C842.827 30.0001 1039.49 5.75783 1150.41 0.444883C1217.53 -2.76993 2500 72.6646 2500 72.6646V622H0L0 247.82Z"
              fill="rgb(244, 246, 249)"
            />
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="0"
                y1="0.313477"
                x2="0"
                y2="622"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColour="#EAECEF" />
                <stop offset="1" stopColour="white" />
              </linearGradient>
            </defs>
          </svg>

          {/* <svg hidden={false} style={{position: 'absolute', left: '67%', top: '-10%', bottom: '81.81%', zIndex: 0, maxWidth: '1440px'}} minWidth="30vw" width="33%" height="770" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M505 670C728.675 670 910 488.675 910 265C910 41.3247 728.675 -140 505 -140C281.325 -140 100 41.3247 100 265C100 488.675 281.325 670 505 670Z" stroke='#00425F' strokeWidth="200"/>
          </svg> */}

          <svg
            hidden={true}
            style={{ position: "absolute", top: "1300px", maxWidth: "1440px" }}
            width="100%"
            height="798"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 213C0 213 243.514 330.378 528 288.297C812.486 246.217 2500 0 2500 0V798H0L0 213Z"
              fill="url(#paint0_linear)"
            />
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="218.61"
                y1="242.293"
                x2="218.61"
                y2="798"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColour="#F7FAFD" />
                <stop offset="1" stopColour="white" />
              </linearGradient>
            </defs>
          </svg>

          {/* Phone */}
          {/* <img src={require('../../assets/media/images/iphone.png')} style={{position: 'absolute', left: '50%', top: '100px'}} width="500px" /> */}
        </div>

        <Header
          transparent={true}
          showLoginAndSignup={true}
          loginButtonStyle={{
            backgroundColor: process.env.REACT_APP_BLUE_COLOUR,
            color: "white",
          }}
          outerDivStyle={{
            backgroundColor: this.state.deviceIsPC ? "white" : "white",
            position: "fixed",
            zIndex: 100,
            width: "100%",
            maxWidth: "1440px",
          }}
        />

        <Container hidden={false} fluid>
          <Row
            style={{
              display: "flex",
              padding: this.state.deviceIsPC ? "80pt" : "10pt",
              paddingLeft: this.state.deviceIsPC ? "80pt" : "10pt",
              margin: 0,
              paddingTop: this.state.deviceIsPC ? "100pt" : "0pt",
              paddingBottom: this.state.deviceIsPC ? "10pt" : "30pt",
            }}
          >
            <Col xs={12} md={12} lg={6} style={{ margin: 0, padding: 0 }}>
              <div
                style={{
                  color: process.env.REACT_APP_BLUE_COLOUR,
                  fontFamily: process.env.REACT_APP_FONT_FAMILY_HEADER,
                  fontSize: this.state.deviceIsMobile ? "38px" : "50pt",
                  lineHeight: this.state.deviceIsMobile ? "45px" : "62pt",
                  marginTop: this.state.deviceIsPC ? "0px" : "60pt",
                }}
              >
                <span style={{ fontWeight: "bold" }}>
                  Open doors to more income
                </span>
                <span style={{ fontWeight: "normal" }}>
                  , become a Quickteller Paypoint Agent
                </span>
              </div>

              <div
                style={{
                  color: "#A2A2A2",
                  fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
                  fontSize: "18px",
                  lineHeight: "25px",
                  paddingTop: "20px",
                }}
              >
                Quickteller Paypoint agents earn consistent income offering
                services like Bill Payment, Funds Transfer, Cash Deposits, Cash
                Withdrawals, Insurance, and Airtime Recharge to customers in
                their community.
              </div>

              <div style={{ marginTop: "30pt" }}>
                <Button
                  style={{
                    backgroundColor: process.env.REACT_APP_RED_COLOUR,
                    borderColor: "transparent",
                    color: "white",
                    fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
                    fontSize: "20px",
                    fontWeight: "bold",
                    lineHeight: "24px",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    paddingLeft: "50px",
                    paddingRight: "50px",
                    width: this.state.deviceIsMobile ? "100%" : null,
                  }}
                  onClick={() => this.setState({ redirectToSignup: true })}
                >
                  BECOME AN AGENT
                </Button>
              </div>
            </Col>

            <Col xs={12} md={6}>
              <svg
                hidden={!this.state.deviceIsPC}
                style={{
                  position: "absolute",
                  left: "23%",
                  top: "-235px",
                  maxWidth: "1560px",
                  zIndex: 0,
                }}
                minWidth="32vw"
                width="100%"
                height="770"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M505 670C728.675 670 910 488.675 910 265C910 41.3247 728.675 -140 505 -140C281.325 -140 100 41.3247 100 265C100 488.675 281.325 670 505 670Z"
                  stroke="#00425F"
                  strokeWidth="200"
                />
              </svg>
              <img
                alt=""
                hidden={!this.state.deviceIsPC}
                src={MacBookIcon}
                style={{ position: "absolute", left: "15%", top: "0px" }}
                width="100%"
              />
            </Col>
          </Row>

          <Row
            style={{
              backgroundColor: "#EAECEF",
              padding: this.state.deviceIsPC ? "165pt" : "10px",
              marginTop: this.state.deviceIsPC ? "100pt" : "0pt",
              paddingTop: this.state.deviceIsPC ? "130pt" : "30pt",
              paddingLeft: this.state.deviceIsPC ? "0px" : "0px",
              paddingBottom: this.state.deviceIsPC ? "10pt" : "30pt",
            }}
          >
            <Col
              xs={12}
              md={6}
              style={{ margin: 0, padding: 0, paddingLeft: 0 }}
            >
              <img
                src={"/assets/media/images/shutterstock_722577382.png"}
                width="100%"
                alt=""
                style={{}}
              />
            </Col>

            <Col
              xs={12}
              md={6}
              style={{
                margin: 0,
                padding: 0,
                paddingLeft: this.deviceIsPC ? "0px" : "30pt",
                paddingRight: this.deviceIsPC ? "0px" : "30px",
              }}
            >
              <div
                style={{
                  color: process.env.REACT_APP_BLUE_COLOUR,
                  fontFamily: process.env.REACT_APP_FONT_FAMILY_HEADER,
                  fontSize: "42px",
                  lineHeight: "52px",
                  fontWeight: "bold",
                  marginTop: this.state.deviceIsPC ? "75pt" : "15pt",
                }}
              >
                What do you gain by becoming a Quickteller Paypoint agent?
              </div>

              <div
                style={{
                  color: "#A2A2A2",
                  fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
                  fontSize: "18px",
                  lineHeight: "25px",
                }}
              >
                You opened your shop to make money, becoming a Quickteller
                Paypoint Agent increases your opportunities to make more money
                consistently.
                <br />
                <br />
                <li>Viable Business Opportunity</li>
                <li>Daily Commission & Attractive Incentive</li>
                <li>Additional footfall to your business site</li>
                <li>Increased opportunity to market your business</li>
                <li>Social Relevance to your community</li>
                <li>Visibility for your brand</li>
                <br /> <br />
                We start by holding your hands till you become an expert at
                delivering financial services to your community. And we never
                leave you, we stand by you, providing prompt support when you
                need it.
              </div>

              <div style={{ marginTop: "30px" }}>
                <Button
                  style={{
                    backgroundColor: process.env.REACT_APP_RED_COLOUR,
                    borderColor: "transparent",
                    color: "white",
                    fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
                    fontSize: "20px",
                    fontWeight: "bold",
                    lineHeight: "24px",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    paddingLeft: "50px",
                    paddingRight: "50px",
                  }}
                  onClick={() => this.setState({ redirectToSignup: true })}
                >
                  BECOME AN AGENT
                </Button>
              </div>
            </Col>
          </Row>

          <Row
            style={{
              display: "flex",
              position: "absolute",
              top: 0,
              width: "100%",
              maxWidth: "1440px",
            }}
          >
            <svg
              hidden={this.state.deviceIsMobile}
              width="92"
              height="132"
              viewBox="0 0 92 132"
              xlink="http://www.w3.org/1999/xlink"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                right: "0px",
                position: "absolute",
                top: "1600px",
              }}
            >
              <desc>Created with Lunacy</desc>
              <path
                d="M66 115C93.062 115 115 93.062 115 66C115 38.938 93.062 17 66 17C38.938 17 17 38.938 17 66C17 93.062 38.938 115 66 115Z"
                fill="none"
                stroke="#00425F"
                strokeWidth="33"
              />
            </svg>

            <svg
              hidden={this.state.deviceIsMobile}
              width="71"
              height="214"
              version="1.1"
              viewBox="0 0 71 214"
              xlink="http://www.w3.org/1999/xlink"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginleft: "0px",
                position: "absolute",
                top: "2070px",
              }}
            >
              <desc>Created with Lunacy</desc>
              <path
                d="M-36 197C13.7056 197 54 156.706 54 107C54 57.2944 13.7056 17 -36 17C-85.7056 17 -126 57.2944 -126 107C-126 156.706 -85.7056 197 -36 197Z"
                fill="none"
                stroke="#00B8DE"
                strokeWidth="33"
              />
            </svg>

            <svg
              hidden={this.state.deviceIsMobile}
              width="133"
              height="214"
              viewBox="0 0 133 214"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                right: "0px",
                position: "absolute",
                top: "2780px",
              }}
            >
              <path
                d="M107 197C156.706 197 197 156.706 197 107C197 57.2944 156.706 17 107 17C57.2944 17 17 57.2944 17 107C17 156.706 57.2944 197 107 197Z"
                stroke="#00425F"
                strokeWidth="33"
              />
            </svg>
          </Row>

          <Row
            style={{
              background: "linear-gradient(#EAECEF, #FFFFFF)",
              padding: this.state.deviceIsPC ? "100px" : "10px",
              paddingLeft: this.state.deviceIsPC ? "100px" : "10px",
            }}
          >
            <Col
              xs={12}
              md={6}
              lg={3}
              style={{
                height: "350px",
                marginTop: "10px",
                padding: "15px",
                paddingTop: "0px",
              }}
            >
              <img
                src={LandingPageChartIcon}
                style={{ height: "160px" }}
                alt=""
              />

              <div
                style={{
                  color: process.env.REACT_APP_BLUE_COLOUR,
                  fontFamily: process.env.REACT_APP_FONT_FAMILY_HEADER,
                  fontSize: "30px",
                  fontWeight: "bold",
                  lineHeight: "36px",
                }}
              >
                Platform & Content
              </div>
              <div
                style={{
                  color: "#A2A2A2",
                  fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  lineHeight: "25px",
                  marginTop: "10px",
                }}
              >
                <li>Process payments for over 8,000 billers & merchants</li>
                <li>Backed by the Industry enabler and Innovator</li>
                <li>Steady supply of relevant content</li>
              </div>
            </Col>
            <Col
              xs={12}
              md={6}
              lg={3}
              style={{
                height: "350px",
                marginTop: "10px",
                padding: "15px",
                paddingTop: "0px",
              }}
            >
              <img
                src={LandingPageChartIcon}
                style={{ height: "160px" }}
                alt=""
              />

              <div
                style={{
                  color: process.env.REACT_APP_BLUE_COLOUR,
                  fontFamily: process.env.REACT_APP_FONT_FAMILY_HEADER,
                  fontSize: "30px",
                  fontWeight: "bold",
                  lineHeight: "36px",
                }}
              >
                Guaranteed Successful Entrepreneurs
              </div>
              <div
                style={{
                  color: "#A2A2A2",
                  fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  lineHeight: "25px",
                  marginTop: "10px",
                }}
              >
                <li>Substantial commissions and incentives</li>
                <li>Comprehensive service suite</li>
                <li>Reliable round the clock support</li>
              </div>
            </Col>
            <Col
              xs={12}
              md={6}
              lg={3}
              style={{
                height: "350px",
                marginTop: "10px",
                padding: "15px",
                paddingTop: "0px",
              }}
            >
              <img
                src={LandingPageChartIcon}
                style={{ height: "160px" }}
                alt=""
              />

              <div
                style={{
                  color: process.env.REACT_APP_BLUE_COLOUR,
                  fontFamily: process.env.REACT_APP_FONT_FAMILY_HEADER,
                  fontSize: "30px",
                  fontWeight: "bold",
                  lineHeight: "36px",
                }}
              >
                Effective Agent Training
              </div>
              <div
                style={{
                  color: "#A2A2A2",
                  fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  lineHeight: "25px",
                  marginTop: "10px",
                }}
              >
                <li>Onsite Training</li>
                <li>Regular Visitation</li>
                <li>Periodic Refresher Training</li>
              </div>
            </Col>
            <Col
              xs={12}
              md={6}
              lg={3}
              style={{ height: "350px", padding: "15px", paddingTop: "0px" }}
            >
              <img
                src={LandingPageChartIcon}
                style={{ height: "160px" }}
                alt=""
              />

              <div
                style={{
                  color: process.env.REACT_APP_BLUE_COLOUR,
                  fontFamily: process.env.REACT_APP_FONT_FAMILY_HEADER,
                  fontSize: "30px",
                  fontWeight: "bold",
                  lineHeight: "36px",
                }}
              >
                Marketing & Branding
              </div>
              <div
                style={{
                  color: "#A2A2A2",
                  fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  lineHeight: "25px",
                  marginTop: "10px",
                }}
              >
                <li>Always-on targeted marketing communications support</li>
                <li>Improved brand association</li>
                <li>Point of Sale promotional items</li>
              </div>
            </Col>
          </Row>

          <Row
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "100px",
            }}
          >
            <iframe
              title="Quickteller Onboarding Video"
              width={this.state.deviceIsMobile ? "100%" : "560"}
              height="315"
              src="https://www.youtube.com/embed/RtDbvEPehGY"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{
                margin: this.state.deviceIsMobile ? "20px" : "0px",
                zIndex: 40,
              }}
            ></iframe>
          </Row>

          <Row
            style={{
              display: "flex",
              alignItems: "center",
              padding: this.state.deviceIsPC ? "100px" : "30px",
              backgroundColor: "#EAECEF",
            }}
          >
            <Col xs={12} md={6}>
              <div style={{ minHeight: "385px" }}>
                <img src={IMacIcon} width="100%" alt="" />
                <img
                  src={IPhoneXIcon}
                  style={{
                    position: "absolute",
                    left: "0",
                    top: "35%",
                  }}
                  width="33%"
                  alt=""
                />
              </div>
            </Col>

            <Col xs={12} md={6}>
              <div
                style={{
                  color: process.env.REACT_APP_BLUE_COLOUR,
                  fontFamily: process.env.REACT_APP_FONT_FAMILY_HEADER,
                  fontSize: "42px",
                  lineHeight: "60px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                How does this App work?
              </div>

              <ul className="timeline">
                <li>
                  <h4>
                    <strong>#1 Step</strong>
                  </h4>

                  <p>
                    Download the Quickteller Paypoint Mobile App. <br />
                    <br />
                    You’ll find the Quickteller for Agents app in your app
                    store. Download and install it to begin your journey to
                    steady income.
                  </p>
                </li>
                <li>
                  <h4>
                    <strong>#2 Step</strong>
                  </h4>

                  <p>
                    Register as an agent and complete your verification. <br />
                    <br />
                    We’ll complete your registration with a quick verification
                    of your details and set you up to start processing
                    transactions and making money.
                  </p>
                </li>
                <li>
                  <h4>
                    <strong>#3 Step</strong>
                  </h4>
                  <p>
                    Start delivering services and earning steady commission.{" "}
                    <br />
                    <br />
                    Now, you are ready. Your business is now a one stop-shop for
                    bill payments, subscriptions, and other financial services.
                  </p>
                </li>
              </ul>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <a
                  href="https://play.google.com/store/apps/details?id=com.interswitchng.ifisagent"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    style={{ width: "170px" }}
                    src={AppleStoreIcon}
                    alt="apple"
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.interswitchng.ifisagent"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    style={{ width: "120px" }}
                    src={PlayStoreIcon}
                    alt="android"
                  />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
        <Footer relative={true} />
      </Container>
    );
  }

  render() {
    return this.state.deviceIsPC
      ? this.renderDesktopView()
      : this.renderMobileView();
  }
}

export default LandingScene;
