import ApiGateway from '../services/api/resources/api-gateway';
import Liquidity from '../services/api/resources/liquidity';
import Notifications from '../services/api/resources/notifications.js';
import Onboarding from '../services/api/resources/onboarding';
import Passport from '../services/api/resources/passport';
import Platform from '../services/api/resources/platform';
import Quickteller from '../services/api/resources/quickteller';
import Settlement from '../services/api/resources/settlement';
import TransactionHistory from '../services/api/resources/transaction-history';
import Transaction from '../services/api/resources/transaction';
import UserManagement from '../services/api/resources/user-management';
import Audit from '../services/api/resources/audit-trail';
import HistoricalData from '../services/api/resources/historical-data';


export const apiGatewayService = new ApiGateway();
export const auditTrailServiceClient = new Audit();
export const liquidityServiceClient = new Liquidity();
export const notifications = new Notifications();
export const onboardingServiceClient = new Onboarding();
export const passportServiceClient = new Passport();
export const platformServiceClient = new Platform();
export const quicktellerServiceClient = new Quickteller();
export const settlementServiceClient = new Settlement();
export const transactionHistoryServiceClient = new TransactionHistory();
export const transactionServiceClient = new Transaction();
export const userManagementServiceClient = new UserManagement();
export const historicalData = new HistoricalData();
