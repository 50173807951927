import React, { Component } from 'react';

import moment from 'moment';
import { Col, Container, Dropdown, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import ReactTable from 'react-table';

import IosOptions from 'react-ionicons/lib/IosOptions';
import MdMore from 'react-ionicons/lib/MdMore';
import {
  platformServiceClient,
  userManagementServiceClient,
} from '../../../../App';
import Button from '../../../../components/button';
import CustomMenu from '../../../../components/custom-menu';
import CustomToggle from '../../../../components/custom-toggle';
import FormControl from '../../../../components/form-control';
import FormLabel from '../../../../components/form-label';
import H1 from '../../../../components/h1';
import HR from '../../../../components/hr';
import Spinner from '../../../../components/spinner';
import Text from '../../../../components/text';
import VerticallyCenteredModal from '../../../../components/vertically-centered-modal';
import { CURRENT_AGENT } from '../../../../constants';
import { ERROR_STATUS } from '../../../../constants/api';
import UserManagement from '../../../../services/api/resources/user-management';
import {
  dismissErrorMessage,
  showErrorMessage,
  updateLoading,
} from '../../../../services/redux/actions/tunnel';
import { retrieveAuthToken } from '../../../../utils/auth';
import { saveData } from '../../../../utils/storage';
import { isOtpValid } from '../../../../utils/validators/form-validators';
import BaseScene from '../../../base-scene';
import Layout from './layout';

const COLOUR_GREEN = '#2A9D8F';
const COLOUR_GREY = '#a5a5a6';

class Device extends Component {
  userManagement = new UserManagement();

  async activateDevice() {
    this.props.updateLoading(true, 66);

    const { status, response } = await this.userManagement.activateDevice(
      retrieveAuthToken(),
      this.props.deviceid
    );

    this.props.updateLoading(false);

    if (status === ERROR_STATUS) {
      this.props.showErrorMessage(JSON.stringify(response));

      setTimeout(
        () => this.props.dismissErrorMessage(),
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );

      return;
    }

    this.props.onActivateDevice(this.props.deviceid);
  }

  async deactivateDevice() {
    this.props.updateLoading(true, 66);

    const { status, response } = await this.userManagement.deactivateDevice(
      retrieveAuthToken(),
      this.props.deviceid
    );

    this.props.updateLoading(false);

    if (status === ERROR_STATUS) {
      this.props.showErrorMessage(JSON.stringify(response));

      setTimeout(
        () => this.props.dismissErrorMessage(),
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );

      return;
    }

    this.props.onDeactivateDevice(this.props.deviceid);
  }

  async deleteDevice() {
    this.props.updateLoading(true, 66);

    const { status, response } = await this.userManagement.deleteDevice(
      retrieveAuthToken(),
      this.props.deviceid
    );

    this.props.updateLoading(false);

    if (status === ERROR_STATUS) {
      this.props.showErrorMessage(JSON.stringify(response));

      setTimeout(
        () => this.props.dismissErrorMessage(),
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );

      return;
    }

    this.props.onDeleteDevice(this.props.deviceid);
  }

  get activeLabel() {
    return (
      <Text
        style={{
          color: '#00de03',
          lineHeight: '18px',
          marginTop: '5px',
          marginBottom: '5px',
        }}
      >
        Activated
      </Text>
    );
  }

  get inactiveLabel() {
    return (
      <Text
        style={{
          color: process.env.REACT_APP_RED_COLOUR,
          lineHeight: '18px',
          marginTop: '5px',
          marginBottom: '5px',
        }}
      >
        Deactivated
      </Text>
    );
  }

  render() {
    return (
      <div
        className='grow'
        style={{
          borderRadius: '6px',
          height: '270px',
          margin: '10px',
          width: '185px',
          elevation: '60deg',
          boxShadow: '0px 16px 29px rgba(35,36,49,0.06)',
        }}
      >
        <Row>
          <Col md={10}></Col>
          <Col md={2}>
            <Dropdown
              style={{ position: 'absolute', right: '25px', top: '10px' }}
            >
              <Dropdown.Toggle
                as={CustomToggle}
                id='dropdown-custom-components'
              >
                <IosOptions color='#a0aeb4' />
              </Dropdown.Toggle>

              <Dropdown.Menu as={CustomMenu}>
                <Dropdown.Item
                  eventKey='1'
                  onClick={() => this.activateDevice()}
                >
                  Activate
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey='2'
                  onClick={() => this.deactivateDevice()}
                >
                  Deactivate
                </Dropdown.Item>
                <Dropdown.Item eventKey='3' onClick={() => this.deleteDevice()}>
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>

        <div
          style={{
            borderRadius: '6px',
            borderBottomLeftRadius: '0px',
            borderBottomRightRadius: '0px',
            backgroundColor: '#eff3f5',
            height: '45%',
            width: '100%',
          }}
        >
          <center>
            <svg
              width='200px'
              height='100px'
              style={{ marginLeft: '35px', marginTop: '17px' }}
              version='1.1'
              xlink='http://www.w3.org/1999/xlink'
              xmlns='http://www.w3.org/2000/svg'
            >
              <desc>Created with Lunacy</desc>
              <g id='Group'>
                <path
                  d='M111.103 0L2.89707 0C1.29683 0 0 1.30162 0 2.90777L0 78.4165C0 80.0226 1.29683 81.3242 2.89707 81.3242L46.9529 81.3242L46.9529 92.0221L35.5939 92.0221C34.7746 92.0221 34.1104 92.6887 34.1104 93.511C34.1104 94.3334 34.7746 95 35.5939 95L78.4061 95C79.2254 95 79.8896 94.3334 79.8896 93.511C79.8896 92.6887 79.2254 92.0221 78.4061 92.0221L67.0464 92.0221L67.0464 81.3242L111.103 81.3242C112.703 81.3242 114 80.0226 114 78.4165L114 2.90777C114 1.30162 112.703 0 111.103 0'
                  id='Fill-4'
                  fill='#A0AEB4'
                  stroke='none'
                />
                <path
                  d='M0 65L106 65L106 0L0 0L0 65Z'
                  transform='translate(4 3)'
                  id='Fill-6'
                  fill='#FFFFFE'
                  stroke='none'
                />
              </g>
            </svg>
          </center>
        </div>

        <div
          style={{
            color: '#777779',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            padding: '10px',
          }}
        >
          <H1
            style={{
              color: '#1b2125',
              fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
              fontWeight: 'normal',
              height: '45px',
              lineHeight: '23px',
            }}
          >
            {this.props.deviceName}
          </H1>

          <Text style={{ lineHeight: '18px', marginTop: '5px' }}>
            {this.props.description}
          </Text>
          {this.props.active ? this.activeLabel : this.inactiveLabel}
          {/* <HR style={{margin: '0px', borderColor: '#eff3f5', padding:'0px', width: '100%'}} /> */}
          {/* <Text style={{fontSize: process.env.REACT_APP_SMALL_FONT_SIZE, paddingTop: '0px'}}>LAST MODIFIED: 06/07/2019</Text> */}
        </div>
      </div>
    );
  }
}

class ManageDevicesTabGrid extends BaseScene {
  userManagement = new UserManagement();

  constructor(props) {
    super(props);

    this.state = {
      devices: [],
    };

    this.onActivateDevice = this.onActivateDevice.bind(this);
    this.onDeactivateDevice = this.onDeactivateDevice.bind(this);
    this.onDeleteDevice = this.onDeleteDevice.bind(this);
  }

  componentDidMount() {
    this.loadUserDevices();
  }

  async loadUserDevices() {
    const getDeviceListResponse = await this.userManagement.getDeviceList(
      retrieveAuthToken()
    );
    const { status, response } = getDeviceListResponse;

    if (status === ERROR_STATUS) {
      this.props.showErrorMessage(JSON.stringify(response));
      setTimeout(
        this.props.dismissErrorMessage,
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );
      return;
    }

    this.setState({
      devices: response.data,
    });
  }

  onActivateDevice(deviceId) {
    this.setState({
      devices: this.state.devices.map((value) => {
        if (value.deviceid === deviceId) {
          value.active = true;
        }

        return value;
      }),
    });
  }

  onDeactivateDevice(deviceId) {
    this.setState({
      devices: this.state.devices.map((value) => {
        if (value.deviceid === deviceId) {
          value.active = false;
        }

        return value;
      }),
    });
  }

  onDeleteDevice(deviceId) {
    this.setState({
      devices: this.state.devices.filter(
        (value) => value.deviceid !== deviceId
      ),
    });
  }

  render() {
    return (
      <Container
        fluid
        style={{
          borderRadius: '6px',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          padding: '20px 20px 20px 20px',
        }}
      >
        {this.state.devices.map((value) => (
          <Device
            {...value}
            dismissErrorMessage={this.props.dismissErrorMessage}
            showErrorMessage={this.props.showErrorMessage}
            updateLoading={this.props.updateLoading}
            onActivateDevice={this.onActivateDevice}
            onDeactivateDevice={this.onDeactivateDevice}
            onDeleteDevice={this.onDeleteDevice}
          />
        ))}
      </Container>
    );
  }
}

class ManageDevicesTab extends BaseScene {
  userManagement = new UserManagement();

  constructor(props) {
    super(props);

    this.state = {
      devices: [],
      isLoading: true,

      otpForm: {},
    };

    this.onActivateDevice = this.onActivateDevice.bind(this);
    this.onDeactivateDevice = this.onDeactivateDevice.bind(this);
    this.onDeleteDevice = this.onDeleteDevice.bind(this);

    this.activateDevice = this.activateDevice.bind(this);
    this.deactivateDevice = this.deactivateDevice.bind(this);
    this.deleteDevice = this.deleteDevice.bind(this);
  }

  componentDidMount() {
    // this.loadUserDevices();
    this.loadData();
  }

  async loadData() {
    await this.loadAgentData();
    await this.loadDevices();
    // await this.loadAuditTrail();

    this.setState({
      isLoading: false,
    });
  }

  onErrorLoadingData() {
    return;
  }

  async loadAgentData() {
    const { response, status } = await platformServiceClient.getCurrentAgent();
    if (status === ERROR_STATUS) {
      this.onErrorLoadingData();
      return;
    }

    const currentAgent = response;
    saveData(CURRENT_AGENT, JSON.stringify(currentAgent));

    this.setState({
      currentAgent: currentAgent,
    });
  }

  async loadDevices() {
    const { response, status } =
      await userManagementServiceClient.getDeviceList();
    if (status === ERROR_STATUS) {
      this.onErrorLoadingData();
      return;
    }

    this.setState({
      devices: response.data,
      isLoading: false,
    });
  }
  updateFormField(params) {
    const otp = {
      ...this.state.otpForm,
      ...params,
    };
    this.setState({
      otpForm: {
        ...otp,
      },
    });
  }

  async loadUserDevices() {
    const getDeviceListResponse = await this.userManagement.getDeviceList(
      retrieveAuthToken()
    );
    this.setState({
      isLoading: false,
    });
    const { status, response } = getDeviceListResponse;

    if (status === ERROR_STATUS) {
      this.props.showErrorMessage(JSON.stringify(response));
      setTimeout(
        this.props.dismissErrorMessage,
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );
      return;
    }

    this.setState({
      devices: response.data,
    });
  }

  async activateDevice(verifyOtp) {
    this.props.updateLoading(true, 66);

    this.setState({
      onActivateClick: false,
    });

    const { deviceInView } = this.state;

    const { status, response } = await this.userManagement.activateDevice(
      // retrieveAuthToken(),
      deviceInView.deviceid,
      this.state.otpForm.otp,
      verifyOtp
    );

    this.props.updateLoading(false);

    if (status === ERROR_STATUS) {
      this.setState({
        onActivateClick: false,
      });
      this.props.showErrorMessage(JSON.stringify(response));

      setTimeout(
        () => this.props.dismissErrorMessage(),
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );

      return;
    }

    if (response.code === '20200') {
      this.setState({
        showOtpModal: true,
        onActivateClick: true,
      });
      return;
    }

    if (response.code === '20000') {
      this.setState({
        showModal: false,
        showOtpModal: false,
      });
    }

    this.onActivateDevice();
  }

  async deactivateDevice(verifyOtp) {
    this.props.updateLoading(true, 66);

    this.setState({
      onDeactivateClick: false,
    });

    const { deviceInView } = this.state;

    const { status, response } = await this.userManagement.deactivateDevice(
      // retrieveAuthToken(),
      deviceInView.deviceid,
      this.state.otpForm.otp,
      verifyOtp
    );

    this.props.updateLoading(false);

    if (status === ERROR_STATUS) {
      this.setState({
        onDeactivateClick: false,
      });
      this.props.showErrorMessage(JSON.stringify(response));

      setTimeout(
        () => this.props.dismissErrorMessage(),
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );

      return;
    }

    if (response.code === '20200') {
      this.setState({
        showOtpModal: true,
        onDeactivateClick: true,
      });
      return;
    }

    if (response.code === '20000') {
      this.setState({
        showModal: false,
        showOtpModal: false,
      });
    }

    this.onDeactivateDevice();
  }

  async deleteDevice(verifyOtp) {
    this.props.updateLoading(true, 66);
    this.setState({
      onDeleteClick: false,
    });

    const { deviceInView } = this.state;

    const { status, response } = await this.userManagement.deleteDevice(
      // retrieveAuthToken(),
      deviceInView.deviceid,
      this.state.otpForm.otp,
      verifyOtp
    );

    this.props.updateLoading(false);

    if (status === ERROR_STATUS) {
      this.setState({
        onDeleteClick: false,
      });
      this.props.showErrorMessage(JSON.stringify(response));

      setTimeout(
        () => this.props.dismissErrorMessage(),
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );

      return;
    }

    if (response.code === '20200') {
      this.setState({
        showOtpModal: true,
        onDeleteClick: true,
      });
      return;
    }

    if (response.code === '20000') {
      this.setState({
        showModal: false,
        showOtpModal: false,
      });
    }

    this.onDeleteDevice();
  }

  onActivateDevice() {
    const { deviceid } = this.state.deviceInView;

    this.setState({
      devices: this.state.devices.map((value) => {
        if (value.deviceid === deviceid) {
          value.status = 'Active';
        }

        return value;
      }),
      showModal: false,
    });
  }

  onDeactivateDevice() {
    const { deviceid } = this.state.deviceInView;

    const devices = this.state.devices.map((value) => {
      if (value.deviceid === deviceid) {
        value.status = 'Not-Active';
      }

      return value;
    });

    this.setState({
      devices,
      showModal: false,
    });
  }

  onDeleteDevice() {
    const { deviceid } = this.state.deviceInView;

    this.setState({
      devices: this.state.devices.filter(
        (value) => value.deviceid !== deviceid
      ),
      showModal: false,
    });
  }

  get deviceModalPreview() {
    const { status } = this.state.deviceInView;
    const statusColor = {
      Active: COLOUR_GREEN,
      'Not-Active': COLOUR_GREY,
    }[status];

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <svg
          width='115px'
          height='100px'
          style={{ arginTop: '17px' }}
          version='1.1'
          xlink='http://www.w3.org/1999/xlink'
          xmlns='http://www.w3.org/2000/svg'
        >
          <desc>Created with Lunacy</desc>
          <g id='Group'>
            <path
              d='M111.103 0L2.89707 0C1.29683 0 0 1.30162 0 2.90777L0 78.4165C0 80.0226 1.29683 81.3242 2.89707 81.3242L46.9529 81.3242L46.9529 92.0221L35.5939 92.0221C34.7746 92.0221 34.1104 92.6887 34.1104 93.511C34.1104 94.3334 34.7746 95 35.5939 95L78.4061 95C79.2254 95 79.8896 94.3334 79.8896 93.511C79.8896 92.6887 79.2254 92.0221 78.4061 92.0221L67.0464 92.0221L67.0464 81.3242L111.103 81.3242C112.703 81.3242 114 80.0226 114 78.4165L114 2.90777C114 1.30162 112.703 0 111.103 0'
              id='Fill-4'
              fill='#A0AEB4'
              stroke='none'
            />
            <path
              d='M0 65L106 65L106 0L0 0L0 65Z'
              transform='translate(4 3)'
              id='Fill-6'
              fill='#FFFFFE'
              stroke='none'
            />
          </g>
        </svg>
        <Text bold big center thin>
          {this.state.deviceInView.deviceName}
        </Text>
        <Text grey>
          Status:{' '}
          <span style={{ color: statusColor, fontWeight: 'bold' }}>
            {this.state.deviceInView.status}
          </span>
        </Text>
        <div
          style={{
            display: 'grid',
            gridGap: '8px',
            gridTemplateColumns: 'auto auto auto',
            marginTop: '24px',
          }}
        >
          <Button
            onClick={() => this.activateDevice(false)}
            outline
            style={{
              border: 'none',
              color: process.env.REACT_APP_BLUE_COLOUR,
            }}
          >
            ACTIVATE
          </Button>
          <Button
            onClick={() => this.deactivateDevice(false)}
            outline
            style={{
              border: 'none',
              color: process.env.REACT_APP_BLUE_COLOUR,
            }}
          >
            DEACTIVATE
          </Button>
          <Button
            onClick={() => this.deleteDevice(false)}
            outline
            style={{
              border: 'none',
              color: process.env.REACT_APP_BLUE_COLOUR,
            }}
          >
            DELETE
          </Button>
        </div>
      </div>
    );
  }
  //TODO refactor this guy
  get otpModalPreview() {
    return (
      <Form>
        <H1
          style={{ textAlign: 'center', margin: 0, textTransform: 'uppercase' }}
        >
          VERIFY YOUR PHONE NUMBER
        </H1>

        <center>
          <HR style={{ marginTop: '10px' }} />
        </center>

        <Row className='regular-row'>
          <Col className='regular-col'>
            <div
              style={{
                color: process.env.REACT_APP_BLACK_COLOUR,
                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                marginBottom: '15px',
                textAlign: 'center',
              }}
            >
              Enter the OTP that was sent to your registered phone number.
            </div>
          </Col>
        </Row>

        <Row
          className='regular-row'
          style={{
            marginBottom: '0px',
            paddingBottom: '0px',
          }}
        >
          <Col
            className='regular-col'
            style={{
              marginBottom: '0px',
              paddingBottom: '0px',
            }}
          >
            <Form.Group
              style={{
                marginBottom: '0px',
                paddingBottom: '0px',
              }}
            >
              <FormLabel
                style={{
                  color: `${!this.state.otpForm.otp || isOtpValid(this.state.otpForm)
                    ? '#3A3B3B'
                    : process.env.REACT_APP_RED_COLOUR
                    }`,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  lineHeight: '19px',
                }}
              >
                OTP:
              </FormLabel>
              <FormControl
                autoComplete='new-password'
                id='otp-input'
                type='text'
                placeholder='OTP'
                onChange={(event) => {
                  const otp = event.target.value;
                  this.updateFormField({ otp });
                }}
                style={{
                  border: `1px solid ${!this.state.otpForm.otp || isOtpValid(this.state.otpForm)
                    ? '#DFE2E6'
                    : process.env.REACT_APP_RED_COLOUR
                    }`,
                }}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row id='continue-button-row'>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className='regular-col'>
            <Button
              disabled={!isOtpValid(this.state.otpForm) || this.state.isLoading}
              style={{ fontWeight: 'normal', width: '100%' }}
              onClick={
                this.state.onActivateClick
                  ? () => this.activateDevice(true)
                  : this.state.onDeactivateClick
                    ? () => this.deactivateDevice(true)
                    : () => this.deleteDevice(true)
              }
            >
              {this.state.isLoading ? 'Loading...' : 'CONTINUE'}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }

  render() {
    const handleOpen = (props) => {
      this.setState({
        showModal: true,
      });

      this.setState({
        deviceInView: props.original,
      });
    };

    const handleClose = () => {
      this.setState({
        showModal: false,
      });
    };

    const handleOtpModalClose = () => {
      this.setState({
        showOtpModal: false,
      });
    };

    const deviceManagementColumns = [
      {
        Header: (props) => <React.Fragment>Device Name</React.Fragment>,
        Cell: (props) => <div style={{ textAlign: 'left' }}>{props.value}</div>,
        accessor: 'deviceName',
        headerClassName: 'table-header',
        className: 'table-cell',
        style: { 'white-space': 'unset' },
      },
      {
        Header: (props) => <React.Fragment>Terminal ID</React.Fragment>,
        Cell: (props) => <div style={{ textAlign: 'left' }}>{props.value}</div>,
        accessor: 'terminalId',
        headerClassName: 'table-header',
        className: 'table-cell',
        style: { 'white-space': 'unset' },
      },
      {
        Header: (props) => <React.Fragment>Status</React.Fragment>,
        Cell: (props) => {
          const statusColor = {
            Active: COLOUR_GREEN,
            'Not-Active': COLOUR_GREY,
          }[props.value];

          return (
            <div
              style={{
                color: statusColor,
                fontWeight: 'bold',
                textAlign: 'left',
              }}
            >
              {props.value}
            </div>
          );
        },
        accessor: 'status',
        headerClassName: 'table-header',
        className: 'table-cell',
      },
      {
        Header: (props) => <React.Fragment>Date Created</React.Fragment>,
        Cell: (props) => (
          <div style={{ textAlign: 'left' }}>
            {moment(props.value).format('DD-MM-YYYY HH:mm a')}
          </div>
        ),
        accessor: 'created_date',
        headerClassName: 'table-header',
        className: 'table-cell',
        style: { 'white-space': 'unset' },
      },
      {
        Header: (props) => <React.Fragment>Action</React.Fragment>,
        Cell: (props) => (
          <div
            className='selectable'
            onClick={() => handleOpen(props)}
            style={{
              textDecoration: 'underline',
              textAlign: 'left',
            }}
          >
            <MdMore color={process.env.REACT_APP_GREY_COLOUR} />
          </div>
        ),
        headerClassName: 'table-header',
        className: 'table-cell',
        width: 60,
      },
    ];

    return (
      <React.Fragment>
        <Layout title={'My Devices'}>
          {this.state.showModal && (
            <VerticallyCenteredModal
              hideFooter
              onHide={handleClose}
              show={this.state.showModal}
              title='Manage Device'
              body={this.deviceModalPreview}
              style={{
                display: 'flex',
                justifyContent: 'center',
                maxWidth: '100%',
              }}
            />
          )}
          {this.state.showOtpModal && (
            <VerticallyCenteredModal
              hideFooter
              onHide={handleOtpModalClose}
              show={this.state.showOtpModal}
              title='Verify Phone Number'
              body={this.otpModalPreview}
              style={{
                display: 'flex',
                justifyContent: 'center',
                maxWidth: '100%',
              }}
            />
          )}
          <ReactTable
            columns={deviceManagementColumns}
            data={this.state.devices}
            LoadingComponent={Spinner}
            loading={this.state.isLoading}
            // pages={this.props.pages}
            // onFetchData={(state, instance) => (
            //   this.props.startDate === this.props.endDate
            //     ? this.props.getAllTransactions(state.page + 1, state.pageSize)
            //     : this.props.getTransactionsByDateRange(this.props.startDate, this.props.endDate, this.props.selectedOption.value, this.props.statusOption.value, state.pageSize, state.page + 1)
            // )}
            manual
            minRows={10}
          />
        </Layout>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.tunnel.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dismissErrorMessage: () => dispatch(dismissErrorMessage()),
    showErrorMessage: (message) => dispatch(showErrorMessage(message)),
    updateLoading: (isLoading, percentage = null) =>
      dispatch(updateLoading({ isLoading, percentage })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageDevicesTab);
