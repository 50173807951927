import { CRM_SERVICES_API_BASE_URL } from "../../../utils/api-properties";
import Requester from "../finch-requester";

const API_BASE_URL = CRM_SERVICES_API_BASE_URL;
export default class CRMService {
  constructor(props) {
    this.apiRequester = props
      ? props.apiRequester ||
        new Requester({
          apiBaseUrl: API_BASE_URL,
        })
      : new Requester({
          apiBaseUrl: API_BASE_URL,
        });
  }

  getIssueHistory(startDate, endDate, pageNum, pageSize, transactionType, status, transRef, ticketNumber,) {
    return this.apiRequester.get({
      endpoint: "v1/finch-crm/issue-history",
      args: {
        startDate: startDate,
        endDate: endDate,
        pageNumber: pageNum,
        pageSize: pageSize,
        transactionType: transactionType,
        status: status,
        transRef: transRef,
        ticketNumber: ticketNumber,
      },
    });
  }

  getTicketNumber(ticketNumber) {
    return this.apiRequester.get({
      endpoint: `v1/finch-crm/tickets/${ticketNumber}`,
    });
  }

  getTransactionTicket(transRef) {
    return this.apiRequester.get({
      endpoint: `v1/finch-crm/transactions/${transRef}/tickets`,
    });
  }

  submitComment(ticketNumber, form) {
    const formData = new FormData();
    if (form.attachment?.file) {
      formData.append("attachment", form.attachment.file);
    }
    formData.append("noteBody", form.description);
  
    const headers = {
      "content-type": "multipart/form-data",
    };
    if (form.attachment?.filename) {
      headers.documentType = form.attachment.filename;
    }
  
    return this.apiRequester.post({
      endpoint: `v1/finch-crm/tickets/${ticketNumber}/comments`,
      headers: headers,
      body: formData,
    });
  }
  
  reOpenComplaint(ticketNumber, form) {
    const formData = new FormData();
    if (form.attachment?.file) {
      formData.append("attachment", form.attachment.file);
    }
    formData.append("reason", form.description);
  
    const headers = {
      "content-type": "multipart/form-data",
    };
    if (form.attachment?.filename) {
      headers.documentType = form.attachment.filename;
    }
  
    return this.apiRequester.post({
      endpoint: `v1/finch-crm/tickets/${ticketNumber}/reopen`,
      headers: headers,
      body: formData,
    });
  }

  submitComplaint(form) {
    const formData = new FormData();
  
    if (form.attachment?.file) {
      formData.append("attachment", form.attachment.file);
    }
  
    formData.append("amount", form.amount);
    formData.append("transactionRef", form.transactionRef);
    formData.append("transactionType", form.transactionType);
    formData.append("description", form.description);
    formData.append("transactionDate", form.transactionDate);
    formData.append("maskedPan", form.maskedPan);
    formData.append("rrn", form.rrn);
    formData.append("beneficiaryAccountNo", form.beneficiaryAccountNo);
    formData.append("agentPhoneNo", form.agentPhoneNo);
    formData.append("transactionStatus", form.transactionStatus);
    formData.append(" beneficiaryPhoneNo", form.beneficiaryPhoneNo);
    formData.append("terminalId", form.terminalId);
    formData.append("customerId", form.customerId);
    formData.append("customerPhoneNo", form.customerPhoneNo);

    if (!form.attachedDocument?.file) {
      return this.apiRequester.post({
        endpoint: "v1/finch-crm/tickets",
        headers: {
          "content-type": "multipart/form-data",
        },
        body: formData,
      });
    }
  
    return this.apiRequester.post({
      endpoint: "v1/finch-crm/tickets",
      headers: {
        documentType: form.attachedDocument.filename,
        "content-type": "multipart/form-data",
      },
      body: form,
    });
  }
  
}
