import React, { useEffect } from "react";

import { Col } from "react-bootstrap";
import ProfileSetupCTA from "../component/profileSetupCTA";

import PrimaryButton from "../component/button";

import H1 from "../../../../../../../components/h1";
import { getOnboardingProgress } from "../../../../../../../utils/metrics";
import SkeletonLoading from "../component/skeletonLoading";

const SetupOnboardingAgentScene = ({
  setScreen,
  props,
  fetchApplication,
  isLoading,
}) => {
  const onboardingLevel = getOnboardingProgress();

  const application1 = JSON.parse(localStorage.getItem("application"));
  const application = application1
    ? application1
    : JSON.parse(localStorage.getItem("currentApplication"));
  let passportPhoto = localStorage.getItem("passportPhoto");

  let personalDetailsForm = JSON.parse(
    localStorage.getItem("personalDetailsForm")
  );
  let nextOfKinDetailsForm = JSON.parse(
    localStorage.getItem("nextOfKinDetailsForm")
  );
  let kycDetailsForm = JSON.parse(localStorage.getItem("kycDetailsForm"));
  let businessDetailsForm = JSON.parse(
    localStorage.getItem("businessDetailsForm")
  );

  const handleKYC = () => {
    if (personalDetailsForm?.phoneNumber) {
      setScreen(3);
    }
  };
  const handleBussinessDetails = () => {
    if (personalDetailsForm?.phoneNumber && passportPhoto) {
      setScreen(4);
    }
  };
  const handleNOK = () => {
    if (personalDetailsForm?.phoneNumber && businessDetailsForm?.state) {
      setScreen(5);
    }
  };
  const handleKYCApplicationId = () => {
    if (application?.applicantDetails?.phoneNumber) {
      setScreen(3);
    }
  };
  const handleBussinessDetailsApplicationId = () => {
    if (
      application?.applicantDetails?.phoneNumber &&
      application?.documentsList?.length === 1
    ) {
      setScreen(4);
    }
  };
  const handleNOKApplicationId = () => {
    if (
      application?.applicantDetails?.phoneNumber &&
      application?.documentsList?.length === 1 &&
      application?.businessDetails?.state
    ) {
      setScreen(5);
    }
  };

  useEffect(() => {
    if (application?.applicationId && !personalDetailsForm) {
      fetchApplication();
    }
  }, []);

  const handleSubmit = () => {
    if (
      personalDetailsForm?.phoneNumber &&
      businessDetailsForm?.state &&
      nextOfKinDetailsForm?.phoneNumber
    ) {
      setScreen(6);
    } else if (
      personalDetailsForm?.phoneNumber &&
      passportPhoto &&
      businessDetailsForm?.state
    ) {
      setScreen(5);
    } else if (personalDetailsForm?.phoneNumber && passportPhoto) {
      setScreen(4);
    } else if (personalDetailsForm?.phoneNumber) {
      setScreen(3);
    } else {
      setScreen(2);
    }
  };
  const handleSubmitApplicationId = () => {
    if (
      application?.applicantDetails?.phoneNumber &&
      application?.documentsList?.length === 1 &&
      application?.businessDetails?.state &&
      application?.applicantDetails?.nextOfKin?.phoneNumber
    ) {
      setScreen(6);
    } else if (
      application?.applicantDetails?.phoneNumber &&
      application?.documentsList?.length === 1 &&
      application?.businessDetails?.state
    ) {
      setScreen(5);
    } else if (
      application?.applicantDetails?.phoneNumber &&
      application?.documentsList?.length === 1
    ) {
      setScreen(4);
    } else if (application?.applicantDetails?.phoneNumber) {
      setScreen(3);
    } else {
      setScreen(2);
    }
  };

  const handleBackButton = () => {
    if (application.applicationId) {
      props.navigateTo("/agents/applications");
      localStorage.removeItem("applicationId");
      localStorage.removeItem("lga");
      localStorage.removeItem("application");
      localStorage.removeItem("state");
      localStorage.removeItem("personalDetailsForm");
      localStorage.removeItem("kycDetailsForm");
      localStorage.removeItem("businessDetailsForm");
      localStorage.removeItem("nextOfKinDetailsForm");
    } else {
      props.navigateTo("/dashboard");
    }
  };

  const Loading = () => {
    return (
      <div
        style={{
          marginBottom: "25px",
        }}
      >
        {" "}
        <SkeletonLoading width={"100%"} height={"15px"} />{" "}
        <SkeletonLoading width={"100%"} height={"15px"} />
      </div>
    );
  };

  const checkKycDetailsUploads = () => {
    if (
      application?.documentsList?.length === 1
      // application?.applicantDetails?.identificationNumber &&
      // application?.applicantDetails?.identificationType
    ) {
      return true;
    } else {
      return null;
    }
  };

  return (
    <>
      <main className="onboarding-resume-container">
        <Col
          style={{
            display: "grid",
            placeContent: "center",
          }}
        >
          <Col>
            <H1
              style={{
                textAlign: "left",
                marginBottom: "1rem",
              }}
            >
              Application Resumption
            </H1>
            <p
              style={{
                color: "#6B7280",
                fontSize: "0.75rem",
                marginBottom:
                  application?.approvalStatus === "REJECTED" ? "1rem" : "3rem",
              }}
            >
              Complete your application to start carrying out transactions on
              Quickteller Paypoint .{" "}
            </p>
          </Col>
          {application?.approvalStatus === "REJECTED" && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "3rem",
                gap: "10px",
                padding: "0 15px",
              }}
            >
              <div
                style={{
                  color: "black",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                Decline Reason:
              </div>
              <div
                style={{
                  color: process.env.REACT_APP_RED_COLOUR,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  lineHeight: "19px",
                }}
              >
                {application.declineReason}
              </div>
            </div>
          )}
          {/* <div style={{ flexGrow: '1' }}>
            {!onboardingLevel.requirements[2].completed
              ? <MdMail fontSize='40px' />
              : <MdCheckmarkCircle color='green' fontSize='40px' />
            }
          </div> */}
        </Col>

        {isLoading ? (
          <Loading />
        ) : (
          <ProfileSetupCTA
            application={application}
            // onClick={() => setScreen(2)}
            actionColor={
              application
                ? application?.applicantDetails?.phoneNumber
                : personalDetailsForm?.phoneNumber
            }
            title={"Personal Details"}
            checkClickableTile={
              application ? application?.applicantDetails?.phoneNumber : false
            }
            isLoading={isLoading}
            sub={`Provide us the agent's personal details.`}
          />
        )}

        {isLoading ? (
          <Loading />
        ) : (
          <ProfileSetupCTA
            declineReason={application?.approvalStatus === "REJECTED"}
            application={application}
            onClick={application ? handleKYCApplicationId : handleKYC}
            actionColor={
              application
                ? application?.documentsList?.length === 1
                : // application?.applicantDetails?.identificationNumber &&
                  // application?.applicantDetails?.identificationType
                  kycDetailsForm
            }
            title={"KYC Information"}
            checkClickableTile={
              application ? checkKycDetailsUploads : kycDetailsForm
            }
            isLoading={isLoading}
            sub={`Agent's identification information for KYC purposes.`}
          />
        )}

        {isLoading ? (
          <Loading />
        ) : (
          <ProfileSetupCTA
            application={application}
            actionColor={
              application
                ? application?.businessDetails?.state
                : businessDetailsForm?.state
            }
            onClick={
              application
                ? handleBussinessDetailsApplicationId
                : handleBussinessDetails
            }
            checkClickableTile={
              application && application?.businessDetails?.state
                ? true
                : businessDetailsForm
            }
            title={"Business Details"}
            isLoading={isLoading}
            sub={"Tell us who owns this business"}
          />
        )}

        {isLoading ? (
          <Loading />
        ) : (
          <ProfileSetupCTA
            application={application}
            actionColor={
              application
                ? application?.nextOfKin?.phoneNumber
                : nextOfKinDetailsForm?.phoneNumber
            }
            onClick={application ? handleNOKApplicationId : handleNOK}
            checkClickableTile={
              application && application?.businessDetails?.state
                ? true
                : nextOfKinDetailsForm
            }
            title={"Next of Kin Details"}
            isLoading={isLoading}
            sub={`Provide us with the contact information of the agent's next of kin.`}
          />
        )}
        <div
          style={{
            margin: "0 auto",
          }}
        >
          <PrimaryButton
            disabled={isLoading}
            onClick={application ? handleSubmitApplicationId : handleSubmit}
          >
            Continue
          </PrimaryButton>
        </div>
      </main>
    </>
  );
};

export default SetupOnboardingAgentScene;
