import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Text from "../../../components/text";
import VerticallyCenteredModal from "../../../components/vertically-centered-modal";
import VerticallyCenteredModalTerms from "../../../components/vertically-centered-modal-terms";
import {
  dismissErrorMessage,
  dismissSuccessMessage,
  showErrorMessage,
  showSuccessMessage,
  updateLoading,
} from "../../../services/redux/actions/tunnel";
import PrivacyPolicy from "../../privacy-policy";
import TermsAndConditions from "../../terms-and-conditions";
import "./styles.scss";
import BVNModal from "../onboarding-setup/component/bvnModal";
import AccountTypeModal from "../onboarding-setup/component/accountTypeModal";


class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      color: "red",
      showTermsAndConditions: false,
      showPrivacyPolicy: false,
      showBVNModal: false,
      showAccountTypeModal: false,
    };

    this.showTermsAndConditions = this.showTermsAndConditions.bind(this);
    this.showPrivacyPolicy = this.showPrivacyPolicy.bind(this);
    this.showBVNModal = this.showBVNModal.bind(this);
    this.showAccountTypeModal = this.showAccountTypeModal.bind(this);
    this.handleCloseBVNModal = this.handleCloseBVNModal.bind(this);
    this.handleCloseAccountTypeModal = this.handleCloseAccountTypeModal.bind(this);
  }

  showTermsAndConditions(event) {
    event.preventDefault();

    this.setState({
      showTermsAndConditions: true,
    });
  }

  showPrivacyPolicy(event) {
    event.preventDefault();

    this.setState({
      showPrivacyPolicy: true,
    });
  }

  showBVNModal(event) {
    event.preventDefault();

    this.setState({
      showBVNModal: true,
    });
  }

  showAccountTypeModal(event) {
    event.preventDefault();

    this.setState({
      showAccountTypeModal: true,
    });
  }

  handleCloseBVNModal() {
    this.setState({
      showBVNModal: false,
    });
  }

  handleCloseAccountTypeModal() {
    this.setState({
      showAccountTypeModal: false,
    });
  }

  componentDidMount() {
    localStorage.removeItem("personalDetailsForm");
    localStorage.removeItem("application");
  }

  render() {
    return (
      <div>
        <div
          id="signup-modal"
          style={{
            backgroundColor: "white",
            position: "absolute",
            top: "45%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "467px",
            height: "499px",
            padding: "50px",
            border: "1px solid black",
            border: "none",
            marginTop: "20px",
          }}
        >
          <form>
            <Text
              big
              style={{
                fontWeight: "bold",
                color: "#1F2937",
                textAlign: "center",
              }}
            >
              Sign Up
            </Text>

            <center>
              <p
                mid
                style={{
                  marginTop: "15px",
                }}
              >
                Welcome to QuickTeller PayPoint. Choose which <br /> best
                describes you
              </p>
            </center>

            <div>
              {process.env.REACT_APP_NAV_MENU_SHOW_KYC_UPDATE === "true" ? (
                <Link to="/signup/onboarding-new-agent">
                  <button
                    className="signupButton1"
                    style={{
                      paddingBottom: "20px",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      Sign up as an Agent
                    </span>
                    <br />
                    <span style={{ color: "#353F50" }}>
                      {" "}
                      Sole proprietor, SMEs
                    </span>
                  </button>
                </Link>
              ) : (
                <button
                  className="signupButton1"
                  onClick={this.showAccountTypeModal}
                  style={{
                    paddingBottom: "20px",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>
                    Sign up as an Agent
                  </span>
                  <br />
                  <span style={{ color: "#353F50" }}>
                    {" "}
                    Sole proprietor, SMEs
                  </span>
                </button>
              )}
              <button
                className="signupButton2"
                style={{ cursor: "not-allowed" }}
                disabled={true}
              >
                <span style={{ fontWeight: "bold" }}>
                  Sign up as a Corporate/Aggregator
                </span>
                <br />
                <span style={{ color: "#353F50" }}> Sole proprietor, SMEs</span>
              </button>
            </div>

            <div>
              <center>
                <Text
                  mid
                  style={{
                    marginTop: "6%",
                    color: " #848F9F",
                  }}
                >
                  Have an account already?{" "}
                  <span style={{ color: "#00B8DE" }}>
                    {localStorage.getItem("currentApplication") ? (
                      <Link to="/logout">Logout </Link>
                    ) : (
                      <Link to="/login">Sign In</Link>
                    )}
                  </span>
                </Text>
              </center>
              <center>
                <Text
                  mid
                  style={{
                    marginTop: "40px",
                    color: " #848F9F",
                  }}
                >
                  <label htmlFor="i-agree-checkbox">
                    By signing up, you agree to our
                  </label>{" "}
                  <a
                    onClick={this.showTermsAndConditions}
                    href="/"
                    target="_blank"
                  >
                    Terms of Use
                  </a>{" "}
                  <br /> and{" "}
                  <a onClick={this.showPrivacyPolicy} href="/" target="_blank">
                    Privacy Policy
                  </a>
                </Text>
              </center>

              <VerticallyCenteredModalTerms
                onHide={() =>
                  this.setState({
                    showTermsAndConditions: false,
                  })
                }
                title="Quickteller Paypoint Terms and Conditions"
                body={<TermsAndConditions />}
                show={this.state.showTermsAndConditions}
              />

              <VerticallyCenteredModal
                onHide={() =>
                  this.setState({
                    showPrivacyPolicy: false,
                  })
                }
                title="Privacy Policy"
                body={<PrivacyPolicy />}
                show={this.state.showPrivacyPolicy}
              />
            </div>
          </form>
        </div>

        <AccountTypeModal  
          show={this.state.showAccountTypeModal}
          handleClose={this.handleCloseAccountTypeModal}
        />

        <BVNModal
          show={this.state.showBVNModal}
          handleClose={this.handleCloseBVNModal}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.tunnel.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dismissErrorMessage: () => dispatch(dismissErrorMessage()),
    showErrorMessage: (message) => dispatch(showErrorMessage(message)),
    dismissSuccessMessage: () => dispatch(dismissSuccessMessage()),
    showSuccessMessage: (message) => dispatch(showSuccessMessage(message)),
    updateLoading: (isLoading, percentage = null) =>
      dispatch(updateLoading({ isLoading, percentage })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
