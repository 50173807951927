import React from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import Select from 'react-select';
import Button from '../../../../../../../components/button';
import FormControl from '../../../../../../../components/form-control';
import FormLabel from '../../../../../../../components/form-label';
import Text from '../../../../../../../components/text';
import { convertNgkToNgn } from '../../../../../../../utils/converters/currencies';
import { formatAmount } from '../../../../../../../utils/formatters';

// export class PosRequestForm extends React.Component {
//   render() {
//     console.log(this.state, "this");
export const PosRequestForm = ({
  props = '',
  handlePosTypeSelection,
  state = {
    form: {},
    posRequestForm: {},
    requestTypeValues: [],
  },
  handlePosRequestType,
  setState,
  handlePosModelSelection,

  updatePosRequestFormField,
  handleChangeImagePhoto,
}) => {
  const handleQuantityChange = (params) => {
    const quantity = params.quantity;
    if (quantity < 0) {
      props.showErrorMessage('Quantity can not be negative');
      setTimeout(
        props.dismissErrorMessage,
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );
      return;
    }
    //  this.props.setPosTotalPrice(quantity) ;
    const amt =
      quantity === undefined || quantity < 1
        ? props.posUnitPrice
        : quantity * props.posUnitPrice;
    setState({
      posRequestForm: { ...state.posRequestForm, quantity: quantity },
    });
    props.setPosTotalPrice(amt);
  };

  const isPosRequestFormValid = () => {
    return (
      state.posRequestForm.posType &&
      state.posRequestForm.posModel &&
      state.posRequestForm.quantity > 0 &&
      state.posRequestForm.termsCondition
    );
    // && this.state.posRequestForm.file
  };
  const clearPOSFormFields = () => {
    setState({
      selectedPosModel: null,
      requestTypeValues: state.requestTypeValues,
      posRequestForm: {
        posModel: '',
        posType: '',
        quantity: 1,
        requestType: '',
        amount: 0,
        posUnitPrice: 0,
        file: null,
        termsCondition: false,
        fileInput: Date.now(),
      },
    });

    props.setPosTotalPrice(0);
    props.setPosUnitPrice(0);
  };

  return (
    <div>
      <Form>
        <Row>
          <Col md={6}>
            <Form.Group>
              <FormLabel
                style={{
                  color: '#3A3B3B',
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  lineHeight: '19px',
                }}
              >
                POS Type:{' '}
                <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                  *
                </span>
              </FormLabel>
              <Select
                options={props.posTypes}
                onChange={(event) => {
                  // const posType = event.value;

                  handlePosTypeSelection(event);
                }}
                className='select-input-container-airtime'
                classNamePrefix='react-select'
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: 'rgb(178, 212, 255)',
                  },
                })}
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group>
              <FormLabel
                style={{
                  color: '#3A3B3B',
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  lineHeight: '19px',
                }}
              >
                POS Model:{' '}
                <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                  *
                </span>
              </FormLabel>
              <Select
                value={state.selectedPosModel}
                options={props.posModels}
                onChange={(event) => {
                  const posModel = event.value;
                  handlePosModelSelection(event);
                }}
                className='select-input-container-airtime'
                classNamePrefix='react-select'
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: 'rgb(178, 212, 255)',
                  },
                })}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group>
              <FormLabel
                style={{
                  color: '#3A3B3B',
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  lineHeight: '19px',
                }}
              >
                Request Type:{' '}
                <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                  *
                </span>
              </FormLabel>
              <Select
                options={state.requestTypeValues}
                value={state.posRequestForm.requestType}
                onChange={(event) => {
                  // const requestType= event.value;
                  handlePosRequestType(event.value);
                  //  this.updatePosRequestFormField({requestType})
                }}
                className='select-input-container-airtime'
                classNamePrefix='react-select'
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: 'rgb(178, 212, 255)',
                  },
                })}
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group>
              <FormLabel
                style={{
                  color: '#3A3B3B',
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  lineHeight: '19px',
                }}
              >
                Quantity:{' '}
                <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                  *
                </span>
              </FormLabel>
              <InputGroup>
                <FormControl
                  type='number'
                  value={state.posRequestForm.quantity}
                  onBlur={() =>
                    setState({
                      fieldsFocused: [...state.fieldsFocused, 'quantity'],
                    })
                  }
                  onChange={(event) => {
                    const quantity = event.target.value;
                    handleQuantityChange({ quantity });
                  }}
                  className='form-control form-control-amount'
                  min='1'
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>

        {!state.isFreePos && (
          <Row>
            <Col md={6}>
              <Form.Group>
                <FormLabel
                  style={{
                    color: '#3A3B3B',
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: '19px',
                  }}
                >
                  Unit Price:{' '}
                </FormLabel>

                <FormControl
                  type='tel'
                  value={`₦${formatAmount(
                    convertNgkToNgn(props.posUnitPrice)
                  )}`}
                  readOnly='true'
                  style={{
                    backgroundColor: '#FFFFFF',
                    border: `1px solid #DFE2E6`,
                    borderTopRightRadius: 20,
                    borderBottomRightRadius: 20,
                    boxSizing: 'border-box',
                    fontSize: '12px',
                    lineHeight: '17px',
                    padding: '20px',
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <FormLabel
                  style={{
                    color: '#3A3B3B',
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: '19px',
                  }}
                >
                  Total Price:{' '}
                </FormLabel>
                <InputGroup style={{ borderRadius: 10 }}>
                  <FormControl
                    type='tel'
                    value={`₦${formatAmount(
                      convertNgkToNgn(props.posTotalPrice)
                    )}`}
                    readOnly='true'
                    onChange={(event) => {
                      const amount = event.target.value;
                      updatePosRequestFormField({ amount });
                    }}
                    style={{
                      backgroundColor: '#FFFFFF',
                      border: `1px solid #DFE2E6`,
                      borderTopRightRadius: 20,
                      borderBottomRightRadius: 20,
                      boxSizing: 'border-box',
                      fontSize: '12px',
                      lineHeight: '17px',
                      padding: '20px',
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        )}

        <Row>
          <Col md={12}>
            <Form.Group as={Col} md='12' controlId='amount'>
              <FormLabel
                style={{
                  color: '#3A3B3B',
                  fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                  lineHeight: '19px',
                }}
              >
                Upload Signature{' '}
                <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                  *
                </span>
                <Text mid>( JPG, JPEG, PNG, PDF, CSV file formats only )</Text>
              </FormLabel>

              <FormControl
                type='file'
                onBlur={() =>
                  setState({
                    fieldsFocused: [...state.fieldsFocused, 'signature'],
                  })
                }
                key={state.posRequestForm.fileInput}
                onChange={handleChangeImagePhoto}
                encType='multipart/form-data'
                style={{
                  backgroundColor: '#FFFFFF',
                  borderRadius: '20px',
                  border: `1px solid '#DFE2E6'`,
                  boxSizing: 'border-box',
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  lineHeight: '17px',
                }}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row style={{ color: '#8C8D8F' }}>
          <Col
            xs={12}
            lg={12}
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            <div className='unstyled-checkbox'>
              <Form.Check
                id='i-agree-checkbox'
                name='termsCondition'
                type='checkbox'
                checked={state.posRequestForm.termsCondition}
                onChange={(event) => {
                  //alert(event.target.checked)
                  setState({
                    // form:{termsCondition:event.target.checked},
                    posRequestForm: {
                      ...state.posRequestForm,
                      termsCondition: event.target.checked,
                    },
                  });
                }}
              />
            </div>

            <Text mid>
              I agree to the Quickteller Paypoint{' '}
              <a
                onClick={(event) => {
                  event.preventDefault();
                  setState({
                    showTermsAndConditions: true,
                  });
                }}
                href='0#'
              >
                Terms and Conditions
              </a>
            </Text>
          </Col>
        </Row>
        <Row>
          <Col md={12} className='proceed-button-col'>
            <Button
              className='services-proceed-button'
              disabled={props.isLoading || !isPosRequestFormValid()}
              onClick={(e) => {
                e.preventDefault();
                props.onPosRequestProceed(state.posRequestForm);
                clearPOSFormFields();
              }}
            >
              {props.isLoading ? 'Loading...' : 'Continue'}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
