import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import BackButton from "../../../component/backButton";
import { Spacer, Status, copybox } from "../../components/extras";
import MdCopy from "react-ionicons/lib/MdCopy";
import processing from "../../../../../../../icons/processing.png";
import delivered from "../../../../../../../icons/delievered.png";
import terminalId from "../../../../../../../icons/terminalId.png";
import shipped from "../../../../../../../icons/shipped.png";

const TextContainer = ({ text1, text2, customText }) => {
  return (
    <div>
      <p style={{ color: "#9CA3AF", fontWeight: 400, margin: 0 }}>{text1}</p>
      {customText ? customText : <p style={{ fontWeight: 600 }}>{text2}</p>}
    </div>
  );
};
const TrackShipmentScreen = () => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const handleCopyDeliveryCode = (code) => {
    setShow(true);
    navigator.clipboard.writeText(code);

    setTimeout(() => {
      setShow(false);
    }, 1500);
  };

  const shippingStatuses = [
    {
      id: 1,
      status: "Processing",
      message: "Your shipment is being sorted.",
      date: "May 20, 2023, 3:30pm",
      icon: processing,
    },
    {
      id: 2,
      status: "Shipped",
      message: "Your shipment is on it's way to you.",
      date: "May 14, 2023, 9:00am",
      icon: shipped,
    },
    {
      id: 3,
      status: "Provide Terminal ID",
      message: "Terminal ID: 675473 should be given to the IFIS Admin",
      date: "Jun 30, 2023, 6:16pm",
      icon: terminalId,
    },
    {
      id: 4,
      status: "Delivered",
      message: "Your shipment has been delivered.",
      date: "Aug 15, 2023, 4:45pm",
      icon: delivered,
    },
  ];
  const TimelineItem = ({ status, message, date }) => (
    <div className="timeline-item">
      <div className="timeline-item-status">{status}</div>
      <div className="timeline-item-message">{message}</div>
      <div className="timeline-item-date">{date}</div>
    </div>
  );
  return (
    <main className="main-container">
      <div className="inventoryLabelContainer">
        <div>
          <h1 className="inventoryLabelText">Track Shipment</h1>
          <Spacer height={"20px"} />
          <BackButton marginBottom={0} onClick={() => history.goBack()} />
        </div>
      </div>
      <section className="shipment-section1-container">
        <div>
          <TextContainer text1={"Request ID"} text2={"1023456"} />
          <TextContainer
            text1={" Delivery Code"}
            customText={
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                <span style={{ fontWeight: 600 }}>1023456</span>
                <div
                  onClick={() => handleCopyDeliveryCode("1023456")}
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <MdCopy
                    fontSize="18px"
                    color="#0275D8"

                    //   onClick={() =>
                    //     this.handleCopyAccountNumber(item.agentId)
                    //   }
                  />
                  <div style={{ color: "#0275D8", fontSize: "13px" }}>
                    {show ? (
                      <span style={{ color: "black" }}>Copied!</span>
                    ) : (
                      <span>Copy</span>
                    )}
                  </div>
                </div>
              </div>
            }
          />
        </div>
        <div className="text-space" />
        <div>
          <TextContainer text1={"No of Pos"} text2={"1023456"} />
          <TextContainer
            text1={"Approval Status"}
            customText={<Status value={"processing"} />}
          />
        </div>
        <div className="text-space" />
        <div>
          <TextContainer
            text1={"Date Requested"}
            text2={"12 Aug, 2024 12:15:30"}
          />
        </div>
        {/* <div>
          <TextContainer text1={"State coordinator"} text2={"Oladejo Israel"} />
        </div> */}
      </section>
      <section className="shipment-section2-container">
        <article className="shipment-article1-container">
          <div className="timeline">
            {shippingStatuses.map((status) => {
              return (
                <div style={{ display: "flex" }}>
                  <div className="timeline-icon-container">
                    <div className="timeline-icon">
                      <img src={status.icon} alt={`icon ${status.id}`} />
                    </div>
                  </div>
                  <TimelineItem key={status.id} {...status} />
                </div>
              );
            })}
          </div>
        </article>
        <article className="shipment-article2-container">
          <p style={{ fontWeight: 700 }}>Model Requested</p>
          <Spacer height={20} />
          <TextContainer text1={"Model 141"} text2={"181 Units"} />
          <Spacer height={10} />
          <TextContainer text1={"Model 141"} text2={"181 Units"} />
          <Spacer height={10} />
          <TextContainer text1={"Model 141"} text2={"181 Units"} />
          <Spacer height={10} />
          <TextContainer text1={"Model 141"} text2={"181 Units"} />
        </article>
      </section>
    </main>
  );
};

export default TrackShipmentScreen;
