import React from 'react';
import MdCheckmarkCircle from 'react-ionicons/lib/MdCheckmarkCircle'
import MdMail from 'react-ionicons/lib/MdMail';
import MdPerson from 'react-ionicons/lib/MdPerson';
import MdCall from 'react-ionicons/lib/MdCall';
import Button from '../../../../../components/button';
import H1 from '../../../../../components/h1';
import Text from '../../../../../components/text';
import { getOnboardingProgress } from '../../../../../utils/metrics';

export default props => {
  const onboardingLevel = getOnboardingProgress();
  return <div style={{alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', marginTop: '50px', padding: '5px', fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY}}>
    <H1 style={{fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY, fontSize: process.env.REACT_APP_BIG_FONT_SIZE, fontWeight: '600', marginBottom: '15px', marginTop: '40pt'}}>{props.rejectedApplication ? 'Your Application has been Declined!' : 'Incomplete Application!'}</H1>
    {props.rejectedApplication ? <Text style={{color: '#808593', fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE, lineHeight: process.env.REACT_APP_MID_BIG_LINE_HEIGHT, textAlign: 'center', maxWidth: '80%', width: '450pt'}}>
      {props.declineReason}
    </Text> : <Text style={{color: '#808593', fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE, lineHeight: process.env.REACT_APP_MID_BIG_LINE_HEIGHT, textAlign: 'center', maxWidth: '80%', width: '450pt', marginBottom: '20px'}}>
      Complete your application to start carrying out transactions on Quickteller Paypoint
    </Text>}
    {props.rejectedApplication ? '' : 
    <>
      <div hidden={!props.email} className="first-column" style={{display: 'flex', padding: '20px', justifyContent: 'space-between', flexDirection: 'row'}}>
        <div style={{flexGrow: '1'}}>
          {!onboardingLevel.requirements[2].completed
          ? <MdMail fontSize='40px'/>
          : <MdCheckmarkCircle color='green' fontSize='40px'/>
          }
        </div>
        <div style={{fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY, flexGrow: '9'}}>
          <h5 
            style={{
              textAlign: props.deviceIsMobile ? 'center' : 'left', 
              color: process.env.REACT_APP_BLUE_COLOUR, 
              fontFamily: 'Source Sans Pro, sans-serif',
              fontWeight: '700',
              lineHeight: '31px',
              fontSize: props.deviceIsMobile ? '14px' : '26px'
            }}
          >Email Address Verification</h5>
          {!props.deviceIsMobile && <p style={{color: 'rgb(128, 133, 147)'}}>Click on the verification link we sent to your email address. You can click resend email if you didn’t get it.</p>}
        </div>
        <div>
          {!onboardingLevel.requirements[2].completed ? 
            <div
              className="grow"
              style={{
                backgroundColor: process.env.REACT_APP_RED_COLOUR, 
                borderRadius: '4px', 
                fontStyle: process.env.REACT_APP_MID_FONT_SIZE, 
                fontWeight: 'normal', 
                marginRight: '10px', 
                padding: '10px 15px 10px 15px', 
                color: 'white', 
                cursor: 'pointer',
                zIndex: '1'
              }}
              onClick={()=> props.resendEmail()}>{props.isLoading ? 'Loading...' : 'Resend'}</div> : ''}
        </div>
      </div>
      <div onClick={() => window.location.href = `/application`} className="first-column email" style={{display: 'flex', padding: '20px', justifyContent: 'space-between', flexDirection: 'row', cursor: 'pointer'}}>
        <div style={{flexGrow: '1'}}>
          {!onboardingLevel.requirements[0].completed
          ? <MdPerson fontSize='40px'/> 
          :<MdCheckmarkCircle color='green' fontSize='40px'/>
          }
        </div>
        <div style={{fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY, flexGrow: '9'}}>
          <h5 
            style={{
              textAlign: props.deviceIsMobile ? 'center' : 'left', 
              color: process.env.REACT_APP_BLUE_COLOUR, 
              fontFamily: 'Source Sans Pro, sans-serif',
              fontWeight: '700',
              lineHeight: '31px',
              fontSize: props.deviceIsMobile ? '14px' : '26px',
              paddingTop: props.deviceIsMobile && '6px'
            }}
            >Agent's Information</h5>
          {!props.deviceIsMobile && <p style={{color: 'rgb(128, 133, 147)'}}>Please provide some details to help us verify your identity and provide your business registration documents.</p>}
        </div>
        <H1 style={{fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY, flexGrow: '1', paddingTop: props.deviceIsMobile && '6px'}}>{`${parseInt(onboardingLevel.levelPercentage)}%`}</H1>        
      </div>
      <div className="first-column" style={{display: 'flex', padding: '20px', justifyContent: 'space-between', flexDirection: 'row'}}>
        <div style={{flexGrow: '0.3'}}>
          {!onboardingLevel.requirements[1].completed
          ? <MdCall fontSize='40px'/>
          : <MdCheckmarkCircle color='green' fontSize='40px'/>
          }
          </div>
        <div style={{fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY, flexGrow: '9'}}>
          <h5 
            style={{
              textAlign: props.deviceIsMobile ? 'center' : 'left', 
              color: process.env.REACT_APP_BLUE_COLOUR, 
              fontFamily: 'Source Sans Pro, sans-serif',
              fontWeight: '700',
              lineHeight: '31px',
              fontSize: props.deviceIsMobile ? '14px' : '26px',
              paddingTop: props.deviceIsMobile && '6px'
            }}
          >Phone Number Verification</h5>
          {!props.deviceIsMobile && <p style={{color: 'rgb(128, 133, 147)'}}>Please ensure that your phone number is valid and accessible by you.</p>}
        </div>
        <H1 style={{fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY, flexGrow: '1', paddingTop: props.deviceIsMobile && '6px'}}>{onboardingLevel.requirements[1].completed ? '100%' : '0%'}</H1>                
      </div>
    </>
    }
    <Button
      onClick={() => props.rejectedApplication ? window.location.href = `/application?applicationRejected=true` : window.location.href = `/application`}
      style={{fontWeight: 'normal', marginTop: '25px', paddingVertical: '2px'}}>{props.rejectedApplication ? 'Update' : 'Complete' } Your Application</Button>
  </div>
}
