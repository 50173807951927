import React from 'react';
import H1 from '../../../../../../components/h1';
import SkeletonLoading from '../../component/skeletonLoading';

const LoadingComponent = () => {
  return (
    <div
      style={{
        marginTop: '25px',
      }}
    >
      {' '}
      <SkeletonLoading width={'100%'} height={'20px'} />{' '}
      <SkeletonLoading width={'100%'} height={'20px'} />
      <SkeletonLoading width={'100%'} height={'20px'} />
      <SkeletonLoading width={'100%'} height={'20px'} />
    </div>
  );
};
export const BalanceCard = ({ title, balance, loading, buttonContent }) => {
  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : (
        <div className='balance-card-commission'>
          <H1
            style={{
              fontSize: '1.25rem',
              fontWeight: 400,
              // color: '#4B5563',
              color: '#4B5563',
              textAlign: 'start',
            }}
          >
            {title}
          </H1>
          <h3
            style={{
              fontSize: '1.75rem',
              marginTop: '20px',
              fontWeight: '600',
              color: process.env.REACT_APP_BLUE_COLOUR,
            }}
          >
            {balance}
          </h3>

          <div className='mt-3'>{buttonContent}</div>
        </div>
      )}
    </>
  );
};
