import { connect } from 'react-redux';
import { updateLoading } from '../../../../../../services/redux/actions/tunnel'
import RolesScene from './scene'

function mapStateToProps(state) {
  return {
    isLoading: state.tunnel.isLoading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateLoading: isLoading => dispatch(updateLoading(isLoading))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RolesScene);
