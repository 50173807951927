import React, { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import Onboarding from "../../../../services/api/resources/onboarding";
import Platform from "../../../../services/api/resources/platform";

import qs from "qs";
// import { formatDateApplication } from '../../../component/extras';
import PreviewAccordion from "../../onboarding-setup/component/accordion";
import BackButton from "../../onboarding-setup/component/backButton";
import {
  firetoast,
  firetoastAggregator,
  notify,
} from "../../../../components/extras";
import PrimaryButton from "../../onboarding-setup/component/button";
import H1 from "../../../../components/h1";
import Text from "../../../../components/text";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../constants/api";
import { HandIcon } from "../../../../icons";
import Modal from "react-bootstrap/Modal";
import application from "../../../application";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const imgStyle = {
  borderRadius: "5px",
  height: "9rem",
  fontSize: "14px",
  border: "1px solid grey",
  width: "8rem",
  margin: "1rem",
};

const PersonalDetails = ({ setScreen, application, isLoading }) => {
  const state = localStorage.getItem("state");
  const lga = localStorage.getItem("lga");

  return (
    <>
      <div
        onClick={() => {
          localStorage.setItem("moveScreen", "true");
          setScreen(2);
        }}
        style={{
          float: "right",
          cursor: "pointer",
          color: "#00425F",
          fontWeight: 600,
        }}
      >
        Edit
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Mother's Maiden Name:
        </Text>
        <Text style={{ color: "grey" }} mid>
          {application?.motherMaidenName}
        </Text>
        <div style={{ marginBottom: "10px" }}>
          <Text bold mid>
            State:
          </Text>
          <Text style={{ color: "grey" }} mid>
            {isLoading ? "Loading..." : state}
          </Text>
        </div>
        <div style={{ marginBottom: "10px" }}>
          <Text bold mid>
            Local Government Area:
          </Text>
          <Text style={{ color: "grey" }} color="grey" mid>
            {isLoading ? "Loading..." : lga}
          </Text>
        </div>
        <div style={{ marginBottom: "10px" }}>
          <Text bold mid>
            Residential Address:
          </Text>
          <Text style={{ color: "grey" }} color="grey" mid>
            {isLoading ? "Loading..." : application?.residentialAddress}
          </Text>
        </div>
      </div>
      {/* {localStorage.getItem('upgradeTo') === 'prestige' && (
        <>
          <div style={{ marginBottom: '10px' }}>
            <Text bold mid>
              State:
            </Text>
            <Text style={{ color: 'grey' }} mid>
              {state}
            </Text>
          </div>
          <div style={{ marginBottom: '10px' }}>
            <Text bold mid>
              Local Government Area:
            </Text>
            <Text style={{ color: 'grey' }} mid>
              {lga}
            </Text>
          </div>
        </>
      )} */}
    </>
  );
};

const BusinessDetails = ({ setScreen, application, isLoading }) => {
  const businessType = localStorage.getItem("businessType");
  return (
    <>
      <div
        onClick={() => {
          localStorage.setItem("moveScreen", "true");
          setScreen(3);
        }}
        style={{
          float: "right",
          cursor: "pointer",
          color: "#00425F",
          fontWeight: 600,
        }}
      >
        Edit
      </div>
      {/* <div style={{ marginBottom: '10px' }}>
        <Text bold mid>
          Business Name:
        </Text>
        <Text style={{ color: 'grey' }} mid>
          {isLoading
            ? 'Loading...'
            : contact?.firstname + ' ' + contact?.lastname}
        </Text>
      </div>
      <div style={{ marginBottom: '10px' }}>
        <Text bold mid>
          Business Address:
        </Text>
        <Text style={{ color: 'grey' }} color='grey' mid>
          {isLoading ? 'Loading...' : location?.addressLine1}
        </Text>
      </div>

      <div style={{ marginBottom: '10px' }}>
        <Text bold mid>
          State:
        </Text>
        <Text style={{ color: 'grey' }} color='grey' mid>
          {isLoading ? 'Loading...' : location?.state}
        </Text>
      </div>
      <div style={{ marginBottom: '10px' }}>
        <Text bold mid>
          Local Government Area:
        </Text>
        <Text style={{ color: 'grey' }} color='grey' mid>
          {isLoading ? 'Loading...' : location?.lga}
        </Text>
      </div> */}

      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Business Type:
        </Text>
        <Text style={{ color: "grey" }} color="grey" mid>
          {isLoading ? "Loading..." : businessType}
        </Text>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Bank:
        </Text>
        <Text style={{ color: "grey" }} color="grey" mid>
          {application?.bank?.label}
        </Text>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Account Number:
        </Text>
        <Text style={{ color: "grey" }} color="grey" mid>
          {application?.accountNumber}
        </Text>
      </div>
    </>
  );
};

const KycInformation = ({ setScreen, application, isLoading, documents }) => {
  const getDocumentUrl = (documentName) => {
    return `${process.env.REACT_APP_CDN_BASE_URL}/p/finch/onboarding/${documentName}`;
  };

  const convertFileName = (fileName) => {
    if (fileName === "UTILITY_BILL") {
      return "Utility Bill";
    } else if (fileName === "PASSPORT_PHOTO") {
      return "Passport Photograph";
    } else if (fileName === "ID_CARD") {
      return "Government Issued ID";
    } else if (fileName === "ADDRESS_VERIFICATION_FORM") {
      return "Address Verification Form";
    } else if (fileName === "CHARACTER_FORM") {
      return "Character Form";
    }
  };
  const [idCardImg, setIdCardImg] = useState(null);
  const [utilityBillImg, setUtilityBillImg] = useState(null);

  useEffect(() => {
    const savedImageData = localStorage.getItem("idCard");

    if (savedImageData) {
      setIdCardImg(savedImageData);
    }

    const savedPassportImage = localStorage.getItem("passportPhoto");

    if (savedPassportImage) {
      setUtilityBillImg(savedPassportImage);
    }
  }, []);
  return (
    <>
      <div
        onClick={() => {
          localStorage.setItem("moveScreen", "true");
          setScreen(4);
        }}
        style={{
          float: "right",
          cursor: "pointer",
          color: "#00425F",
          fontWeight: 600,
        }}
      >
        Edit
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Enter NIN:
        </Text>
        <Text style={{ color: "grey" }} mid>
          {isLoading ? "Loading..." : application?.nin}
        </Text>
        <img style={imgStyle} src={idCardImg} alt="NIN Image" />
        <img style={imgStyle} src={utilityBillImg} alt="Utility Image" />
      </div>
    </>
  );
};

const RefereeInfomation = ({ setScreen, application }) => {
  return (
    <>
      <div
        onClick={() => {
          localStorage.setItem("moveScreen", "true");
          setScreen(5);
        }}
        style={{
          float: "right",
          cursor: "pointer",
          color: "#00425F",
          fontWeight: 600,
        }}
      >
        Edit
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Referee Email Address:
        </Text>
        <Text style={{ color: "grey" }} color="grey" mid>
          {application?.emailAddress}
        </Text>
      </div>
    </>
  );
};

const ApplicationReviewScene = ({ setScreen, applicationData, props }) => {
  const history = useHistory();
  localStorage.setItem("moveScreen", "false");
  const personalDetailsFormUpgrade = JSON.parse(
    localStorage.getItem("personalDetailsFormUpgrade")
  );
  const businessDetailsFormUpgrade = JSON.parse(
    localStorage.getItem("businessDetailsFormUpgrade")
  );
  const kycDetailsFormUpgrade = JSON.parse(
    localStorage.getItem("kycDetailsFormUpgrade")
  );
  const refereeDetailsForm = JSON.parse(
    localStorage.getItem("refereeDetailsForm")
  );
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const platform = new Platform();

  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState([]);
  const [showCongratsModal, setShowCongratsModal] = useState(false);

  const congratsModal = () => {
    setShowCongratsModal(true);
  };

  const navigateToOverview = () => {
    setShowCongratsModal(false);
    history.push("/agent/dashboard");
    // window.location.reload();
  };

  const upgradeClass = () => {
    const upgradeToMap = {
      prestige: 3,
      Standard: 2,
    };

    return upgradeToMap[upgradeTo] || "";
  };

  const upgradeTo = localStorage.getItem("upgradeTo");

  const handleSubmit = async () => {
    const domain_code = currentUser?.domainCode;
    setLoading(true);
    const payload = {
      newClassId: upgradeClass(),

      homeAddressStateId: personalDetailsFormUpgrade.state
        ? personalDetailsFormUpgrade.state
        : "",
      homeAddressLgaId: personalDetailsFormUpgrade.localGovernmentArea
        ? personalDetailsFormUpgrade.localGovernmentArea
        : "",

      motherMaidenName: personalDetailsFormUpgrade.motherMaidenName,
      businessTypeId: businessDetailsFormUpgrade?.businessType,
      bank: businessDetailsFormUpgrade?.bank?.label,
      accountNumber: businessDetailsFormUpgrade?.accountNumber,
      refereeEmail: refereeDetailsForm?.emailAddress,
    };
    const { status, response } = await platform.submitAgentClassUpgrage(
      payload,
      domain_code
    );
    setLoading(false);
    if (status === ERROR_STATUS) {
      firetoast("error", `${response.description}`, "");
    } else if (status === SUCCESS_STATUS) {
      congratsModal();
      localStorage.removeItem("applicationId");
      localStorage.removeItem("lga");
      localStorage.removeItem("application");
      localStorage.removeItem("businessType");
      localStorage.removeItem("idCard");
      localStorage.removeItem("passportPhoto");
      localStorage.removeItem("personalDetailsFormUpgrade");
      localStorage.removeItem("businessDetailsFormUpgrade");
      localStorage.removeItem("kycDetailsFormUpgrade");
      localStorage.removeItem("upgradeTo");
      localStorage.removeItem("currentUser");
    }
  };

  return (
    <>
      <BackButton onClick={() => setScreen(1)} />
      <main className="aggregator-container">
        <Col
          style={{
            marginBottom: "20px",
            margin: "0 auto",
          }}
        >
          <H1
            style={{
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "1rem",
            }}
          >
            Application Preview
          </H1>
          <PreviewAccordion index="1" title={"Personal Details"}>
            <PersonalDetails
              isLoading={isLoading}
              setScreen={setScreen}
              application={personalDetailsFormUpgrade}
            />
          </PreviewAccordion>
          <PreviewAccordion index={"0"} title={"Business Details"}>
            <BusinessDetails
              isLoading={isLoading}
              setScreen={setScreen}
              application={businessDetailsFormUpgrade}
            />
          </PreviewAccordion>
          <PreviewAccordion index={"0"} title={"KYC Information"}>
            <KycInformation
              setScreen={setScreen}
              application={kycDetailsFormUpgrade}
              documents={application?.documents}
            />
          </PreviewAccordion>

          <PreviewAccordion index={"0"} title={"Referee's Information"}>
            <RefereeInfomation
              setScreen={setScreen}
              application={refereeDetailsForm}
            />
          </PreviewAccordion>

          <PrimaryButton disabled={loading} onClick={handleSubmit}>
            {loading ? "Processing..." : "Proceed"}{" "}
          </PrimaryButton>
        </Col>
      </main>
      <>
        <Modal
          show={showCongratsModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body style={{ padding: "2rem", alignSelf: "center" }}>
            <div style={{ alignContent: "center", padding: "3rem" }}>
              <div style={{ display: "grid", placeContent: "center" }}>
                {HandIcon()}
              </div>
              <h1
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  marginBottom: "1rem",
                  fontSize: "1.5rem",
                }}
              >
                Email Sent
              </h1>
              <p
                style={{
                  color: "#5F738C",
                  fontWeight: "300",
                  // whiteSpace: 'no-wrap',
                  wordWrap: "break-word",
                }}
              >
                An Email has been sent to {refereeDetailsForm?.emailAddress}.{" "}
                <br />
                <span style={{ marginLeft: "" }}>
                  Kindly follow up to speed up the request
                </span>
              </p>
              <button
                className="signupButton2"
                style={{
                  marginTop: "2rem",
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "#00425f",
                  marginLeft: "12%",
                  width: "80%",
                }}
                onClick={navigateToOverview}
              >
                {" "}
                Done{" "}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    </>
  );
};

export default ApplicationReviewScene;
