import React, { useEffect, useState } from "react";
import {
  Col,
  Form,
  FormControl,
  FormLabel,
  InputGroup,
  Row,
} from "react-bootstrap";
import MdArrowBack from "react-ionicons/lib/MdArrowBack";
import ReactPhoneInput from "react-phone-input-2";
import Select from "react-select";
import { stopwatch } from "../../../../../../../App";
import Button from "../../../../../../../components/button";
import { notify } from "../../../../../../../components/extras";
import H1 from "../../../../../../../components/h1";
import HR from "../../../../../../../components/hr";
import { TRANSFER_TO_AGENT } from "../../../../../../../constants";
import { TRANSACTION_INITIATE_CLICK } from "../../../../../../../constants/analytics";
import {
  ERROR_STATUS,
  SUCCESS_STATUS,
} from "../../../../../../../constants/api";
import { logEvent } from "../../../../../../../core/logger";
import Liquidity from "../../../../../../../services/api/resources/liquidity";
import Transaction from "../../../../../../../services/api/resources/transaction";
import { convertNgnToNgk } from "../../../../../../../utils/converters/currencies";
import { formatPhoneNumber } from "../../../../../../../utils/formatters";
import { generateChecksum } from "../../../../../../../utils/helpers";
import phoneValidators from "../../../../../../../utils/validators/phone-validators";
import { useFundUssdContext } from "./context";

import "./fund-ussd.css";

const InitiateScreen = ({ goBack, setScreen }) => {
  const { setResponseData, form, setForm } = useFundUssdContext();
  const customStylesBanks = {
    option: (provided, state) => ({
      ...provided,
      fontSize: 14,
    }),
    control: (base) => ({
      ...base,
      minHeight: 48,
    }),
    singleValue: (provided, state) => {
      const fontSize = 14;
      return { ...provided, fontSize };
    },
  };
  const liquidity = new Liquidity();
  const [isLoading, setIsloading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(true);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isGetUssdCashoutBankLoading, setIsGetUssdCashoutBankLoading] =
    useState(false);
  const [errorDescription, setErrorDescription] = useState("");
  const [propagateFormErrors, setPropagateFormErrors] = useState(false);
  const [validationLoading, setValidationLoading] = useState(false);
  const [ussdCashoutBanks, setUssdCashoutBanks] = useState([]);
  const [fieldsFocused, setFieldsFocused] = useState([]);

  const updateFormField = (params) => {
    const newForm = {
      ...form,
      ...params,
    };

    setForm(newForm);
  };
  const getUssdCashoutBanks = async () => {
    setIsGetUssdCashoutBankLoading(true);

    const { status, response } = await liquidity.fetchUssdCashoutBanks();

    if (status === ERROR_STATUS) {
      setErrorLoading(false);
      setIsGetUssdCashoutBankLoading(false);

      return;
    }
    const transformUssdBanks = (ussdBanks) => {
      const newUssdBanks = ussdBanks.map(
        ({ name: label, name: value, name, ...rest }) => ({
          label,
          value,
          name,
          ...rest,
        })
      );
      return newUssdBanks;
    };
    setIsGetUssdCashoutBankLoading(false);
    setUssdCashoutBanks(transformUssdBanks(response));
  };
  const validateAccountDetails = async (bank) => {
    setValidationLoading(true);
    const bankValue = bank ? bank : form.bank;

    const nameInquiry = await Transaction.nameInquiry(
      form.accountNumber,
      bankValue.value
    );
    const nameInquiryStatus = nameInquiry.status;
    const nameInquiryResponse = nameInquiry.response;
    setUpdateLoading(false);
    setIsDisabled(false);

    if (nameInquiryStatus === ERROR_STATUS) {
      const beneficiaryName = "";
      setForm({
        ...form,
        beneficiaryName,
      });
      setErrorDescription(
        "Account name confirmation failed. Enter beneficiary name or click retry"
      );
      setValidationLoading(false);

      return;
    }
    const beneficiaryName = nameInquiryResponse.accountName;
    setValidationLoading(false);
    setErrorDescription("");
    setIsDisabled(true);
    setForm({
      ...form,
      beneficiaryName,
    });
  };

  const isSenderPhoneValid = (newForm) => {
    const Form = newForm || form;
    const countryValidator = phoneValidators[form.phonePrefix];
    return (
      Form.senderPhone &&
      (countryValidator ? countryValidator(Form.senderPhone) : true)
    );
  };

  const isBeneficiaryPhoneValid = (newForm) => {
    const Form = newForm || form;
    const countryValidator = phoneValidators[form.phonePrefix];

    return (
      Form.beneficiaryPhone &&
      (countryValidator ? countryValidator(Form.beneficiaryPhone) : true)
    );
  };

  const isUssdWithdrawalFormValid = () => {
    return form.senderPhone && form.amount && form.bank && isSenderPhoneValid();
  };

  const onUssdWithdrawalProceed = async (formData, e) => {
    e.preventDefault();

    setIsloading(true);

    const paymentItemCode = "FUND_VIA_USSD";
    const amount = convertNgnToNgk(formData.amount);
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const deviceId = localStorage.getItem("deviceUid");
    const httpMethod = "POST";
    const username = currentUser.username;

    logEvent(TRANSACTION_INITIATE_CLICK, {
      transaction_type: TRANSFER_TO_AGENT,
    });

    stopwatch.start();
    const { response, status } = await liquidity.initiateFundWithUssdRequest(
      {
        amount: `${amount}`,
        customerId: formatPhoneNumber(formData.senderPhone, "wallet"),
        paymentItemCode: paymentItemCode,
        bankCode: formData.bank.cbnCode,
        checksum: generateChecksum(
          `${username}${httpMethod}${amount}${httpMethod}${deviceId}`
        ),
      },
      deviceId
    );

    if (status === ERROR_STATUS) {
      setIsloading(false);
      notify(response.description, "error");
    } else if (status === SUCCESS_STATUS) {
      setResponseData(response);
      setIsloading(false);
      setScreen(2);
    }
  };

  useEffect(() => {
    getUssdCashoutBanks();
  }, []);
  return (
    <section>
      <H1
        style={{
          textAlign: "left",
          marginTop: "0px",
          paddingTop: "0px",
          display: "flex",
          alignItems: "center",
        }}
        onClick={() => goBack()}
      >
        <MdArrowBack
          fontSize="26px"
          style={{
            cursor: "pointer",
            color: "#00425F",
            marginRight: "10px",
          }}
        />
        <span>Fund via USSD</span>
      </H1>
      <HR style={{ marginTop: "10px", width: "100%" }} />
      <article className="fund-by-ussd-section">
        <Form>
          <>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group>
                  <FormLabel
                    style={{
                      color: `${
                        !form.senderPhone || isSenderPhoneValid()
                          ? "#3A3B3B"
                          : process.env.REACT_APP_RED_COLOUR
                      }`,
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                    }}
                  >
                    Phone:{" "}
                    <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                      *
                    </span>
                  </FormLabel>
                  <ReactPhoneInput
                    buttonStyle={{
                      // padding: '23px 0',
                      borderTopLeftRadius:
                        process.env.REACT_APP_BORDER_STYLE === "square"
                          ? "4px"
                          : "20px",
                      borderBottomLeftRadius:
                        process.env.REACT_APP_BORDER_STYLE === "square"
                          ? "4px"
                          : "20px",
                    }}
                    containerStyle={{
                      margin: 0,
                      padding: 0,
                      width: "100%",
                    }}
                    defaultCountry={"ng"}
                    disableCountryCode={true}
                    inputStyle={{
                      backgroundColor: "#FFFFFF",
                      borderRadius:
                        process.env.REACT_APP_BORDER_STYLE === "square"
                          ? "4px"
                          : "20px",
                      border: `1px solid ${
                        (!form.beneficiaryPhone && !propagateFormErrors) ||
                        isBeneficiaryPhoneValid()
                          ? "#DFE2E6"
                          : process.env.REACT_APP_RED_COLOUR
                      }`,
                      boxSizing: "border-box",
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "17px",
                      width: "100%",
                      padding: "23px 0 23px 50px",
                    }}
                    value={form.senderPhone || ""}
                    onlyCountries={["ng", "ke", "ug"]}
                    onBlur={() =>
                      setFieldsFocused([...fieldsFocused, "beneficiaryPhone"])
                    }
                    onChange={(value) => {
                      updateFormField({ senderPhone: value });
                    }}
                    placeholder="08012345678"
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <FormLabel
                    style={{
                      color: "#3A3B3B",
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                    }}
                  >
                    Bank:{" "}
                    <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                      *
                    </span>
                  </FormLabel>
                  <Select
                    options={ussdCashoutBanks && ussdCashoutBanks}
                    onChange={(event) => {
                      const bank = event;
                      updateFormField({ bank });
                      form.accountNumber && validateAccountDetails(bank);
                    }}
                    styles={customStylesBanks}
                    className="select-input-container-banks"
                    classNamePrefix="react-select"
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "rgb(178, 212, 255)",
                      },
                    })}
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group as={Col} md="12" controlId="amount">
                  <FormLabel
                    style={{
                      color: "#3A3B3B",
                      fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                      lineHeight: "19px",
                    }}
                  >
                    Amount:{" "}
                    <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                      *
                    </span>
                  </FormLabel>
                  <InputGroup style={{ borderRadius: 10 }}>
                    <InputGroup.Prepend>
                      <InputGroup.Text
                        id="inputGroupPrepend"
                        style={{
                          borderTopLeftRadius:
                            process.env.REACT_APP_BORDER_STYLE === "square"
                              ? "4px"
                              : "20px",
                          borderBottomLeftRadius:
                            process.env.REACT_APP_BORDER_STYLE === "square"
                              ? "4px"
                              : "20px",
                        }}
                      >
                        NGN
                      </InputGroup.Text>
                    </InputGroup.Prepend>

                    <FormControl
                      type="number"
                      value={form.amount}
                      onChange={(event) => {
                        const amount = event.target.value;
                        updateFormField({ amount });
                      }}
                      style={{
                        backgroundColor: "#FFFFFF",
                        border: `1px solid #DFE2E6`,
                        // borderTopRightRadius: 20,
                        // borderBottomRightRadius: 20,
                        boxSizing: "border-box",
                        fontSize: "12px",
                        lineHeight: "17px",
                        padding: "20px",
                      }}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col xs={6} md={6}>
                <Form.Group controlId="gender">
                  <FormLabel
                    style={{
                      color: "#3A3B3B",
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                    }}
                  >
                    Gender:
                  </FormLabel>
                  <Row>
                    <Col xs={6} md={6}>
                      <input
                        type="radio"
                        name="gender"
                        value="Male"
                        id="male"
                        onChange={(event) => {
                          const gender = event.target.value;
                          updateFormField({ gender });
                        }}
                      />{" "}
                      <label for="male">Male</label>
                    </Col>
                    <Col xs={6} md={6}>
                      <input
                        type="radio"
                        name="gender"
                        id="female"
                        value="Female"
                        onChange={(event) => {
                          const gender = event.target.value;
                          updateFormField({ gender });
                        }}
                      />{" "}
                      <label for="female">Female</label>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={12} className="proceed-button-col">
                <Button
                  disabled={isLoading || !isUssdWithdrawalFormValid()}
                  className="services-proceed-button"
                  onClick={(e) => onUssdWithdrawalProceed(form, e)}
                >
                  {isLoading ? "Loading..." : "Continue"}
                </Button>
              </Col>
            </Row>
          </>
        </Form>
      </article>
    </section>
  );
};

export default InitiateScreen;
