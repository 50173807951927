import moment from "moment";
import qs from "qs";
import React, { useState } from "react";
import {
  Col,
  Form,
  FormControl,
  FormLabel,
  InputGroup,
  Row,
} from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import H1 from "../components/h1";
import Thumbnail2 from "../components/thumbnail-2";
import { PERSONAL_DOC_SUFFIX, THREE_MB } from "../constants";
import { ERROR_STATUS, SUCCESS_STATUS } from "../constants/api";
import CRMService from "../services/api/resources/crm-service";
import { formatAmount, formatDateTime } from "../utils/formatters";
import { useHistory } from "react-router-dom";

export const TicketDetails = (props) => {
  const crmService = new CRMService();
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ticketDetails, setTicketDetails] = useState({});
  const [ticketId, setTicketId] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [attachedDocument, setAttachedDocument] = useState("");
  const [updatesHaveBeenMade, setUpdatesHaveBeenMade] = useState(false);
  const [form, setForm] = useState("");
  const [formIsValid, setFormIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [comments, setComments] = useState("");
  const [error, setError] = useState("");
  const [commentsArray, setCommentsArray] = useState([]);

  const handleGoBack = () => {
    history.push("/agent/view-issues");
  };
  const deviceIsMobile =
    qs.parse(window.location.search)["?mobile"] || window.innerWidth <= 576;
  const deviceIsPC =
    qs.parse(window.location.search)["?mobile"] && window.innerWidth >= 992;
  const deviceIsTab =
    qs.parse(window.location.search)["?mobile"] || window.innerWidth <= 992;

  React.useEffect(() => {
    getTicketDetails();
  }, []);
  const handleOpen = () => {
    if (ticketDetails?.status !== "Resolved" || !ticketDetails?.expired) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  };

  const notify = (params) => {
    if (params === "success") {
      toast.success("Comment Added!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (params === "error") {
      toast.error("Oops! An error occurred, try again.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (params === "uploading-error") {
      toast.error("File size must not exceed 3MB.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
  };

  const onFileSelect = (event, filename) => {
    const name = event.target.files[0].name;
    const file = event.target.files[0];

    if (file.size > THREE_MB) {
      setError(true);
      notify("uploading-error");
      return;
    } else {
      setAttachedDocument(file);
      setError(false);
      displayUploadedFile(name, filename, file);
      return;
    }
  };

  const onFileUnselect = () => {
    setAttachedDocument("");
    updateFormField({ attachedDocument: {} });
  };

  const handleAddComment = async (e) => {
    e.preventDefault();
    const { comments, attachedDocument } = form;
    setIsLoading(true);

    const payload = {
      description: comments,
      attachment: attachedDocument,
    };

    if (ticketDetails?.status === "Active") {
      try {
        const { status, code, data } = await crmService.submitComment(
          ticketId,
          payload
        );

        if (status === SUCCESS_STATUS) {
          await getComments();

          handleClose();
          notify("success");
          setAttachedDocument("");
          setForm({ attachedDocument: "" });
          setIsLoading(false);
          window.scrollTo(0, 0);
          window.location.reload();
        }

        if (status === ERROR_STATUS) {
          notify("error");
          setIsLoading(false);
          return;
        }
      } catch (error) {
        setIsLoading(false);
        notify("error");
      }
    } else {
      try {
        const { status, code, data } = await crmService.reOpenComplaint(
          ticketId,
          payload
        );

        if (status === SUCCESS_STATUS) {
          await getComments();

          handleClose();
          notify("success");
          setAttachedDocument("");
          setForm({ attachedDocument: "" });
          setIsLoading(false);
          window.scrollTo(0, 0);
          window.location.reload();
        }

        if (status === ERROR_STATUS) {
          notify("error");
          setIsLoading(false);
          return;
        }
      } catch (error) {
        setIsLoading(false);
        notify("error");
      }
    }
  };

  const handleClose = () => {
    setIsModalOpen(!isModalOpen);
  };

  const getDocumentForThumbnail = (attachedDocument) => {
    return attachedDocument;
  };

  const displayUploadedFile = (name, filename, file) => {
    const newAttachedDocument = {
      name: `${name}${PERSONAL_DOC_SUFFIX}`,
      filename: `${filename}${PERSONAL_DOC_SUFFIX}`,
      file: file,
      url: URL.createObjectURL(file),
    };

    setAttachedDocument(newAttachedDocument);
    setError(false);
    setIsLoading(false);
    updateFormField({ attachedDocument: newAttachedDocument });
  };

  const updateFormField = (params) => {
    const newForm = {
      ...form,
      ...params,
    };

    setForm(newForm);
    setFormIsValid(false);
  };

  const getComments = async () => {
    try {
      const res = await crmService.getTicketNumber(ticketId);
      const { data } = res.response;
      setCommentsArray(data?.comments);
    } catch (err) {
      return false;
    }
  };

  async function getTicketDetails() {
    setLoading(true);
    const ticket = localStorage.getItem("ticketDetailsItem");
    const isssueTicket = JSON.parse(ticket);
    setTicketId(isssueTicket.ticketNumber);

    try {
      const res = await crmService.getTicketNumber(isssueTicket.ticketNumber);
      const { data } = res.response;

      setTicketDetails(data);
      setCommentsArray(data.comments);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  const handleChangeComments = (e) => {
    const inputValue = e.target.value;
    const comments = inputValue;
    if (comments.length <= 75) {
      updateFormField({ comments });
    } else {
      alert("Exceeded the maximum word limit of 75 words.");
    }
  };

  const containerStyle = {
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    width: deviceIsMobile ? "80%" : "471px",
    paddingTop: "20px",
    paddingBottom: "50px",
    height: "max-height",
    position: "relative",
    overflow: "hidden",
    marginLeft: "50px",
  };

  const frameStyle = {
    background: "var(--surface-primary, #ffffff)",
    borderRadius: "12px",
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    alignSelf: "stretch",
    position: "relative",
  };

  const rowStyle = {
    display: "flex",
    flexDirection: "row",
    gap: "24px",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    alignSelf: "stretch",
    position: "relative",
  };

  const rowStyles = {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    alignSelf: "stretch",
    position: "relative",
  };

  const textStyle = {
    color: "var(--gray-600, #4b5563)",
  };
  const textStyles = {
    color: "var(--gray-600, #4b5563)",
    alignItems: "center",
    justifyContent: "flex-start",
    flex: 1,
  };

  return (
    <>
      <div
        className="h1 "
        style={{
          fontSize: 30,
          fontWeight: "bold",
          paddingLeft: 30,
          marginTop: 20,
        }}
      >
        Ticket Details
      </div>

      <button
        onClick={handleGoBack}
        style={{
          backgroundColor: "#00425F",
          color: "white",
          padding: "6px 10px",
          borderRadius: "8px",
          border: "none",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          marginLeft: "50px",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          style={{ marginRight: "5px" }}
        >
          <polyline points="15 18 9 12 15 6"></polyline>
        </svg>
        Back
      </button>
      <div style={containerStyle}>
        <div style={frameStyle}>
          {loading ? (
            <div style={{ textAlign: "center", alignSelf: "center" }}>
              Loading...
            </div>
          ) : (
            <>
              <div style={rowStyles}>
                <div style={textStyles}>Ticket ID</div>
                <div style={textStyles}>{ticketDetails?.ticketNumber}</div>
              </div>
              <div style={rowStyles}>
                <div style={{ ...textStyles }}>Transaction Date & Time</div>
                <div style={textStyles}>
                  {" "}
                  {ticketDetails?.transactionDate &&
                    moment(ticketDetails.transactionDate).format(
                      "YYYY-MM-DD HH:mm:ss A"
                    )}
                </div>
              </div>
              <div style={rowStyles}>
                <div style={textStyles}>Transaction Reference</div>
                <div style={textStyles}>{ticketDetails?.transactionRef}</div>
              </div>
              <div style={rowStyles}>
                <div style={textStyles}>Transaction Type</div>
                <div style={textStyles}>{ticketDetails?.transactionType}</div>
              </div>
              <div style={rowStyles}>
                <div style={textStyles}>Amount</div>
                <div style={textStyles}>
                  {formatAmount(ticketDetails?.amount) && "N "}
                  {formatAmount(ticketDetails?.amount)}
                </div>
              </div>
              <div style={rowStyles}>
                <div style={textStyles}>Status</div>
                <div style={{ ...textStyle, flex: 1, textAlign: "justify" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        background: "var(--active-blue-100, #ebf8fe)",
                        borderRadius: "8px",
                        border: "1px solid var(--active-blue-200, #a8d6ef)",
                        padding: "4px 8px",
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                        flexShrink: 0,
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          flexShrink: "0",
                          width: "9px",
                          height: "9px",
                          position: "relative",
                        }}
                      >
                        <svg
                          className="circle"
                          width="9"
                          height="10"
                          viewBox="0 0 9 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            position: "absolute",
                            left: "0px",
                            top: "0px",
                            overflow: "visible",
                          }}
                        >
                          <path
                            d="M4.5 8.65625C6.51929 8.65625 8.15625 7.01929 8.15625 5C8.15625 2.98071 6.51929 1.34375 4.5 1.34375C2.48071 1.34375 0.84375 2.98071 0.84375 5C0.84375 7.01929 2.48071 8.65625 4.5 8.65625Z"
                            fill="#1A4983"
                          />
                        </svg>
                      </div>
                      <div
                        style={{
                          color: "var(--active-blue-500, #1a4983)",
                          textAlign: "left",
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        {ticketDetails?.status}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        {!loading && (
          <div
            style={{
              background: "var(--surface-primary, #ffffff)",
              borderRadius: "12px",
              padding: "16px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              alignSelf: "stretch",
              position: "relative",
            }}
          >
            <div
              style={{
                ...textStyle,
                color: "#1F2937",
                fontSize: "20px",
                fontWeight: 700,
              }}
            >
              Comments
            </div>
            {commentsArray
              ?.filter((value) => value.comment !== null)
              .map((value, idx) => {
                return (
                  <div style={{ ...rowStyle }} key={idx}>
                    <div style={frameStyle}>
                      <div style={textStyle}>
                        <span
                          style={{ width: "130px", display: "inline-block" }}
                        >
                          {value?.author}:
                        </span>
                      </div>
                      <div style={textStyle}>
                        {formatDateTime(value?.dateCreated)}
                      </div>
                    </div>
                    <p
                      style={{
                        ...rowStyle,
                        marginTop: "20px",
                        paddingRight: "10px",
                        // wordWrap: 'break-word',
                        // whiteSpace: 'pre-wrap',
                        overflow: "auto",
                      }}
                    >
                      {value?.comment}
                    </p>
                  </div>
                );
              })}
          </div>
        )}

        {!loading && (
          <div
            style={{
              backgroundColor:
                ticketDetails?.expired !== true ? "#00425F" : "#AEDDF1",
              borderRadius: "8px",
              padding: "16px 48px",
              display: "flex",
              flexDirection: "row",
              gap: "8px",
              alignItems: "center",
              justifyContent: "center",
              height: "48px",
              position: "relative",
              overflow: "hidden",
              cursor: "pointer",
            }}
            onClick={handleOpen}
          >
            {ticketDetails?.status === "Active" ||
            ticketDetails?.expired === "true" ? (
              <div
                style={{
                  color: "var(--white, #ffffff)",
                  background: "var(--primary-blue, #00425f)",
                  textAlign: "left",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                {ticketDetails?.expired === true
                  ? "Reopen Ticket"
                  : "Add comment"}
              </div>
            ) : ticketDetails?.status === "Resolved" ? (
              <div
                style={{
                  color: "var(--white, #ffffff)",
                  backgroundColor:
                    ticketDetails?.expired === true ? "#AEDDF1" : "#00425F",
                  textAlign: "left",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                {ticketDetails?.expired === true ? "Closed" : "Reopen Ticket"}
              </div>
            ) : null}
          </div>
        )}
      </div>

      {isModalOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              background: "#ffffff",
              justifyContent: "center",
              padding: "16px",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <H1>
                {ticketDetails?.status === "Active"
                  ? " Add comment"
                  : "Reopen Ticket"}
              </H1>
              <button
                style={{ border: "none" }}
                onClick={() => setIsModalOpen(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="52"
                  height="52"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M20 12L12 20"
                    stroke="#353F50"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M20 20L12 12"
                    stroke="#353F50"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>

            <Form>
              <div style={{ backgroundColor: "white" }}>
                <Row
                  style={{
                    justifyContent: "center",
                    paddingBottom: "50px",
                  }}
                >
                  <Col xs={12} md={0} style={{ paddingBottom: "15px" }}>
                    <Form.Group style={{ width: "100px" }}>
                      <FormLabel className={`form-label-style`}>
                        Comments:
                        <span className="asterisk-style">*</span>
                      </FormLabel>
                      <InputGroup style={{ height: "10px", width: "300px" }}>
                        <FormControl
                          className="form-control-text-input"
                          as="textArea"
                          value={comments}
                          placeholder="Add comments"
                          maxLength={75}
                          onChange={handleChangeComments}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>

                <Row style={{ justifyContent: "center" }}>
                  <Col xs={12} md={0} style={{ paddingBottom: "15px" }}>
                    <span> Add Attachment:</span>
                    <div
                      style={{
                        marginBottom: 5,
                        fontSize: 10,
                        paddingLeft: 10,
                        paddingRight: 10,
                      }}
                    >
                      Upload file (image or document) PNG, JPEG, JPG, DOC, DOCX,
                      PDF up to 3MB
                    </div>
                    <Thumbnail2
                      style={{ marginBottom: "5px", width: "100%" }}
                      attachedDocument={getDocumentForThumbnail(
                        form.attachedDocument,
                        "attachment"
                      )}
                      filename={"attachment"}
                      onFileSelect={onFileSelect}
                      onFileUnselect={onFileUnselect}
                      isLoading={isLoading}
                    />
                  </Col>
                </Row>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 20,
                    paddingBottom: 20,
                    paddingTop: 15,
                  }}
                >
                  <button
                    disabled={!form?.comments}
                    style={{
                      paddingLeft: "50px",
                      paddingRight: "50px",
                      backgroundColor: "#00425F",
                      color: "white",
                      fontSize: "14px",
                      borderRadius: "8px",
                      padding: 10,
                      border: "none",
                    }}
                    onClick={handleAddComment}
                  >
                    {isLoading
                      ? "Loading..."
                      : ticketDetails?.status === "Active"
                      ? "Add comment"
                      : "Reopen Ticket"}
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      )}
    </>
  );
};
