import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import InputText from "../component/input";
import { connect } from "react-redux";

import { toast } from "react-toastify";
import BackButton from "../component/backButton";
import InputWithSelectText from "../component/inputWithSelect";
import MdCheckmarkCircle from "react-ionicons/lib/MdCheckmarkCircle";
import Platform from "../../../../services/api/resources/platform";
import HeaderSignup from "../../../../components/header-signup";
import { navigateTo } from "../../../../services/redux/actions/navigation";

const TinScene = ({ setScreen, application }) => {
  const history = useHistory();
  const platform = new Platform();
  const [loading, setLoading] = useState(false);
  const [businessSceneForm, setBusinessSceneForm] = useState({
    tin: application?.applicantDetails?.businessScene?.tin || "",
    cacNumber: application?.applicantDetails?.businessScene?.cacNumber || "",
  });
  const [tinValidated, setTinValidated] = useState(false);
  const [selectedValue, setSelectedValue] = useState("BN");
  const [tinSubmitted, setTinSubmitted] = useState(false);

  useEffect(() => {
    const updateBusinessForm = JSON.parse(localStorage.getItem("businessForm"));
    if (updateBusinessForm) {
      setBusinessSceneForm({
        tin: updateBusinessForm.tin || businessSceneForm.tin,
        cacNumber: updateBusinessForm.cacNumber || businessSceneForm.cacNumber,
      });
    }
  }, [application]);

  useEffect(() => {
    localStorage.setItem(
      "businessSceneForm",
      JSON.stringify(businessSceneForm)
    );
  }, [businessSceneForm]);

  const isTinValid = (tin) => {
    const tinPattern = /^[A-Za-z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{9,14}$/;
    return tinPattern.test(tin);
  };

  const notify = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
  
    const payload = tinValidated
      ? {
          identificationNumber: businessSceneForm.cacNumber,
          prefix: selectedValue,
        }
      : { identificationNumber: businessSceneForm.tin };
  
    const validationType = tinValidated ? "CAC" : "TIN";
  
    try {
      const response = await platform.validateBusinessDetails(payload, validationType);
      if (response.response.code === "20100") {
        setScreen(2);
      } else if (response.code !== "00") {
        notify(response.description, "error");
      } else if (response.data.validationStatus === "VERIFIED") {
        notify(response.data.message, "success");
  
        if (!tinValidated) {
          setTinValidated(true);
          setTinSubmitted(true);
          updateFormField({ cacNumber: response.data.cacRegNo });
        } else {
          setScreen(2);
        }
      } else {
        notify(response.data.message, "error");
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      notify(
        "An error occurred while validating your details, please try again.",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };
  
  const updateFormField = (params) => {
    setBusinessSceneForm((prevState) => ({
      ...prevState,
      ...params,
    }));
  };

  const isFormValid = () => {
    return isTinValid(businessSceneForm.tin);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          gap: "40px",
          marginTop: "3rem",
          marginLeft: "3rem",
        }}
      >
        <HeaderSignup />
        <BackButton
          marginBottom={0}
          onClick={() => history.push("/dashboard")}
        />
      </div>

      <main className="onboarding-container">
        <div style={{ alignContent: "center", padding: "1rem" }}>
          <h1
            style={{
              fontWeight: "bold",
              marginBottom: "2rem",
              marginTop: "2rem",
              fontSize: "1.5rem",
            }}
          >
            Enter your Identification Number
          </h1>

          <div style={{ position: "relative" }}>
            <InputText
              label={"Enter your Tax Identification Number (TIN)"}
              labelColor={
                !businessSceneForm.tin || isTinValid(businessSceneForm.tin)
                  ? "black"
                  : process.env.REACT_APP_RED_COLOUR
              }
              border={`1px solid ${!businessSceneForm.tin || isTinValid(businessSceneForm.tin)
                  ? "#DFE2E6"
                  : process.env.REACT_APP_RED_COLOUR
                }`}
              placeholder={"Enter TIN"}
              onChange={(e) => {
                updateFormField({ tin: e.target.value.trim() });
              }}
              maxLength={14}
              value={businessSceneForm.tin}
              disabled={tinValidated}
            />
            {tinValidated && (
              <MdCheckmarkCircle
                style={{
                  position: "absolute",
                  top: "70%",
                  right: "10px",
                  transform: "translateY(-50%)",
                  width: "30px",
                  height: "30px",
                }}
                color="green"
                fontSize="40px"
              />
            )}
          </div>

          {tinValidated && (
            <div style={{}}>
              <InputWithSelectText
                label={"Select your prefix"}
                placeholder={"Enter CAC Number"}
                value={businessSceneForm.cacNumber}
                maxLength={8}
                selectOptions={[
                  { label: "BN", value: "BN" },
                  { label: "LP", value: "LP" },
                  { label: "RC", value: "RC" },
                  { label: "LLP", value: "LLP" },
                  { label: "IT", value: "IT" },
                ]}
                selectValue={selectedValue}
                onSelectChange={handleSelectChange}
                disabled={true}
              />
            </div>
          )}

          <button
            onClick={handleSubmit}
            className="signupButton2"
            style={{
              marginTop: "1rem",
              textAlign: "center",
              color: "white",
              backgroundColor: "#00425f",
              width: "100%",
            }}
            disabled={!isFormValid() || loading}
          >
            {loading ? "Processing..." : tinSubmitted ? "Submit" : "Next"}
          </button>
          <button
            className="signupButton2"
            style={{
              marginTop: "1rem",
              textAlign: "center",
              color: "black",
              alignContent: "center",
            }}
            onClick={() => {
              history.push("/dashboard");
            }}
          >
            Continue Later
          </button>

          {process.env.REACT_APP_CAC_REGISTRATION === "true" && (
            <center
              style={{
                fontWeight: "bold",
                marginBottom: "3rem",
                marginTop: "1.5rem",
                fontSize: "0.9rem",
              }}
            >
              Not registered with a CAC before?
              <br />
              <span
                style={{
                  color: process.env.REACT_APP_LINK_COLOUR,
                  cursor: "pointer",
                }}
                onClick={() => {
                  localStorage.removeItem("cacAssistedService");
                  localStorage.removeItem("personalDetailsForm");
                  localStorage.removeItem("businessSceneForm");
                  localStorage.removeItem("businessDetailsForm");
                  localStorage.removeItem("bvnInfo");
                  localStorage.removeItem("companyState");
                  localStorage.removeItem("cacRegistrationForm");
                  localStorage.removeItem("kycDetailsForm");
                  localStorage.removeItem("documentPassportPhoto");
                  localStorage.removeItem("passportPhotoBase64");
                  localStorage.removeItem("state");
                  localStorage.removeItem("lga");
                  history.push("/cac-registration");
                }}
              >
                Click here
              </span>
            </center>
          )}
        </div>
      </main>
    </>
  );
};

const mapStateToProps = ({ onboarding, application }) => ({
  onboarding,
  application,
});

const mapDispatchToProps = (dispatch) => ({
  // setScreen: (screen) => dispatch(navigateTo(screen)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TinScene);
