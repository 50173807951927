import React, { useEffect, useState } from 'react';
import ActivityIndicator from '../../../../../../components/activity-indicator';

import { Line } from 'react-chartjs-2';
import TransactionHistory from '../../../../../../services/api/resources/transaction-history';
import { specialDateRangeFormatter_StartDate } from '../../../../../../utils/formatters';
import { ERROR_STATUS, SUCCESS_STATUS } from '../../../../../../constants/api';
const CommissionPerfomance = ({
  startDate,
  endDate,
  dateIntervalType,
  selectedFilter,
}) => {
  const transactionHistory = new TransactionHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [getPerformance, setGetPerformance] = useState([]);

  const periods = [];
  const values = [];

  const getEarningPerformance = async () => {
    setIsLoading(true);
    setError(false);
    setGetPerformance([]);
    // const payload = {
    //   from: specialDateRangeFormatter_StartDate(startDate),
    //   to: specialDateRangeFormatter_StartDate(endDate),
    //   dateIntervalType: dateIntervalType,
    // };

    let payload = {};
    if (dateIntervalType) {
      payload = {
        dateIntervalType: dateIntervalType,
      };
    } else {
      payload = {
        from: specialDateRangeFormatter_StartDate(startDate),
        to: specialDateRangeFormatter_StartDate(endDate),
      };
    }
    const { response, status } = await transactionHistory.getEarningPerformance(
      payload
    );

    setIsLoading(false);
    if (status === ERROR_STATUS) {
      setError(true);
    } else if (status === SUCCESS_STATUS) {
      response.data.forEach((item) => {
        periods.push(item.period);
        values.push(item.value);
      });

      const transformedData = {
        period: periods,
        value: values,
      };

      setGetPerformance(transformedData);
    }
  };

  useEffect(() => {
    getEarningPerformance();
  }, [endDate, dateIntervalType]);

  const data = {
    labels: getPerformance?.period,
    datasets: [
      {
        label: 'Value',
        fill: true,
        lineTension: 0.1,
        backgroundColor: '#acf6dd74',
        borderColor: '#00E396',
        borderCapStyle: 'butt',

        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: getPerformance?.value,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'month',
          displayFormats: {
            month: 'YYYY/MM',
          },
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 2,
          callback: (value) => value + '₦',
        },
        grid: {
          borderDash: [5, 5],
        },
      },
    },

    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  return (
    <div>
      {error && (
        <p
          style={{
            textAlign: 'center',
            color: process.env.REACT_APP_BLUE_COLOUR,
            fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
            padding: '85px 0 85px 0',
          }}
        >
          {`No records found for ${selectedFilter}`}
        </p>
      )}

      {isLoading && (
        <div
          style={{
            padding: '85px 0 85px 0',
          }}
        >
          <ActivityIndicator
            style={{
              height: '85px',
            }}
          />
        </div>
      )}
      {getPerformance?.period?.length > 0 && (
        <div style={{ height: '423px' }}>
          <Line data={data} options={options} />
        </div>
      )}
    </div>
  );
};

export default CommissionPerfomance;
