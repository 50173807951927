import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Stepper from "../../scenes/signup/onboarding-setup/component/stepper";
import {
  isBvnValid,
  stripNonNumbers,
} from "../../scenes/signup/onboarding-setup/component/extras";
import InputText from "../../scenes/signup/onboarding-setup/component/input";
import Text from "../text";
import { greenCheckIcon } from "../../icons";
import HeaderSignup from "../header-signup";
import BackButton from "../../scenes/signup/onboarding-setup/component/backButton";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../constants/api";
import Platform from "../../services/api/resources/platform";
import { loadAgent } from "./mockApi";

const NINVerificationScene = ({ application }) => {
  const history = useHistory();
  const platform = new Platform();
  const location = useLocation();
  const data = location.state;

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ninVerificationForm, setNinVerificationForm] = useState({
    nin: application ? application?.applicantDetails?.ninVerification?.nin : "",
  });

  useEffect(() => {
    const updatedNINVerificationForm = JSON.parse(
      localStorage.getItem("ninVerification")
    );

    if (updatedNINVerificationForm) {
      setNinVerificationForm({
        nin:
          application?.applicantDetails?.ninVerification?.nin ||
          updatedNINVerificationForm.nin ||
          "",
      });
    }
  }, [application]);

  useEffect(() => {
    localStorage.setItem(
      "ninVerificationForm",
      JSON.stringify(ninVerificationForm)
    );
  }, [ninVerificationForm]);

  const updateFormField = (params) => {
    const newForm = {
      ...ninVerificationForm,
      ...params,
    };

    setNinVerificationForm(newForm);
  };

  const navigateToNINVerification = () => {
    history.goBack();
  };

  const notify = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const payload = {
      identificationNumber: ninVerificationForm.nin,
    };

    const { status, response } = await platform.validateNINDetails(
      payload,
      "NIN"
    );

    if (status === ERROR_STATUS) {
      notify(response.description, "error");
      setLoading(false);
    } else if (status === SUCCESS_STATUS) {
      // await loadAgent();

      if (response.data.validationStatus === "VERIFIED") {
        notify(response.data.message, "success");
        history.push("/business-cbn");
      } else if (response.data.validationStatus === "NOT_VERIFIED") {
        notify(response.data.message, "error");
        setLoading(false);
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const isNinValid = (nin) => {
    const ninPattern = /^\d{11}$/;
    return ninPattern.test(nin);
  };

  const isFormValid = () => {
    return ninVerificationForm.nin && isNinValid(ninVerificationForm.nin);
  };

  const checkAuthentication = () => {
    const isAuthenticated = localStorage.getItem("auth_token") === null;

    if (isAuthenticated) {
      history.push("/login");
      localStorage.clear();
    }
  };

  useEffect(() => {
    checkAuthentication();
  }, []);

  // let kycCheckListDetails = JSON.parse(localStorage.getItem("currentAgent"));
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          gap: "40px",
          margin: "2rem 0 0 2rem",
        }}
      >
        <HeaderSignup />
        {/* {kycCheckListDetails?.kycCheckList?.livelinessCheck === false && (
          <BackButton
            disabled={data?.disableBackButton}
            onClick={
              data?.disableBackButton ? null : () => navigateToNINVerification()
            }
          />
        )} */}
      </div>
      <main className="onboarding-container">
        <div style={{ alignContent: "center", padding: "2rem" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              gap: "10px",
              marginBottom: "30px",
            }}
          >
            <Stepper borderColor="#00425F" />
            <Stepper borderColor="#00425F" />
            <Stepper borderColor="#00425F" />
            <Stepper />
            <Stepper />
          </div>
          <h1
            style={{
              fontWeight: "bold",
              marginBottom: "1rem",
              marginTop: "3rem",
              fontSize: "1.5rem",
            }}
          >
            Verify your NIN
          </h1>

          <InputText
            label={"National Identification Number (NIN)"}
            labelColor={
              !ninVerificationForm.nin || isNinValid(ninVerificationForm.nin)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !ninVerificationForm.nin || isNinValid(ninVerificationForm.nin)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            placeholder={"Enter your NIN"}
            onChange={(e) => {
              updateFormField({ nin: stripNonNumbers(e.target.value.trim()) });
            }}
            maxLength={11}
            value={ninVerificationForm.nin}
          />

          <button
            onClick={handleSubmit}
            className="signupButton2"
            style={{
              marginTop: "1rem",
              textAlign: "center",
              color: "white",
              backgroundColor: "#00425f",
              width: "100%",
            }}
            disabled={!isFormValid() || loading}
          >
            {loading ? "Processing..." : "Next"}
          </button>
          <button
            className="signupButton2"
            style={{
              marginTop: "1rem",
              textAlign: "center",
              color: "black",
              alignContent: "center",
            }}
            onClick={() => {
              history.push("/dashboard");
            }}
          >
            Continue Later
          </button>
        </div>
      </main>
    </>
  );
};

export default NINVerificationScene;
