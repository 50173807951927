import { connect } from 'react-redux';
import { updateLoading, showErrorMessage, dismissErrorMessage, } from '../../../../services/redux/actions/tunnel'
import ServicesScene from './scene'

function mapStateToProps(state) {
  return {
    isLoading: state.tunnel.isLoading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateLoading: isLoading => dispatch(updateLoading(isLoading)),
    dismissErrorMessage: () => dispatch(dismissErrorMessage()),
    showErrorMessage: message => dispatch(showErrorMessage(message)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServicesScene);
