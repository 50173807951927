export const ServiceType = [
  {
    label: "Select Transaction Type",
    value: "0",
  },
  {
    label: "Bills",
    value: "1",
  },
  {
    label: "Transfer",
    value: "2",
  },
  {
    label: "NIP Transfer",
    value: "16",
  },
  {
    label: "POS Purchase",
    value: "17",
  },
  {
    label: "Recharge",
    value: "3",
  },
  {
    label: "Fund",
    value: "4",
  },
  {
    label: "Cash Out",
    value: "5",
  },
  {
    label: "Wallet",
    value: "6",
  },
  {
    label: "Cash In",
    value: "19",
  },
  {
    label: "Commission Unload",
    value: "8",
  },
  {
    label: "Paypoint Fund",
    value: "9",
  },
  {
    label: "Reversal",
    value: "10",
  },
  {
    label: "Refund",
    value: "11",
  },
  {
    label: "Retrieval",
    value: "12",
  },
  {
    label: "Account Opening",
    value: "13",
  },
  {
    label: "Distribute",
    value: "14",
  },
];

export const StatusCode = [
  {
    label: "Select Transaction Status",
    value: "0",
  },
  // {
  //   label: 'Initiated',
  //   value: '1'
  // },
  {
    label: "Pending",
    value: "2",
  },
  {
    label: "Successful",
    value: "3",
  },
  {
    label: "Failed",
    value: "4",
  },
];

export const posReportTableOptions = [
  {
    label: "POS Requests",
    value: 1,
  },
  {
    label: "POS Remap Requests",
    value: 2,
  },
];

export const Event = [
  {
    label: "All",
    value: null,
  },
  {
    label: "Credit",
    value: "Credit",
  },
  {
    label: "Debit",
    value: "Debit",
  },
];

export const FinchOneServiceType = [
  {
    label: "Select Service Type",
    value: "0",
  },
  {
    value: "AIRTIME_TOPUP_CASH",
    label: "AirTime Topup",
  },
  // {
  //   value: "AIRTIME_TOPUP_TOKEN",
  //   label: "AirTime Topup by Token"
  // },
  {
    value: "BILL_PAYMENT_CASH",
    label: "Bill Payment",
  },
  // {
  //   value: "BILL_PAYMENT_TOKEN",
  //   label: "Bill Payment by Token"
  // },
  {
    value: "CASH_IN_CASH_INTER_SP",
    label: "Cash in Inter SP",
  },
  // {
  //   value: "CASH_IN_CASH_INTRA_SP",
  //   label: "Cash in"
  // },
  {
    value: "CASH_IN_OTHER_CASH_INTER_SP",
    label: "Cash in other Inter SP",
  },
  // {
  //   value: "CASH_IN_OTHER_CASH_INTRA_SP",
  //   label: "Cash in other"
  // },
  // {
  //   value: "CASH_IN_OTHER_TOKEN_INTER_SP",
  //   label: "Cash in other by Token Inter SP"
  // },
  // {
  //   value: "CASH_IN_OTHER_TOKEN_INTRA_SP",
  //   label: "Cash in other by Token"
  // },
  // {
  //   value: "CASH_IN_TOKEN_INTER_SP",
  //   label: "Cash in by Token Inter SP"
  // },
  // {
  //   value: "CASH_IN_TOKEN_INTRA_SP",
  //   label: "Cash in by Token"
  // },
  // {
  //   value: "CASH_OUT_CASH_INTER_SP",
  //   label: "Cash out Inter SP"
  // },
  // {
  //   value: "CASH_OUT_CASH_INTRA_SP",
  //   label: "Cash out"
  // },
  // {
  //   value: "CASH_OUT_TOKEN_INTER_SP",
  //   label: "Cash out by Token Inter SP"
  // },
  // {
  //   value: "CASH_OUT_TOKEN_INTRA_SP",
  //   label: "Cash out by Token"
  // },
  {
    value: "DISTRIBUTE",
    label: "Distribute",
  },
  // {
  //   value: "GET_AGENTS_BALANCE",
  //   label: "Get Agents Balance"
  // },
  // {
  //   value: "GET_AGENTS_LAST_TRANSACTION",
  //   label: "Get Agents Last Transaction"
  // },
  // {
  //   value: "INTERNATIONAL_REMITTANCE",
  //   label: "International Remittance"
  // },
  // {
  //   value: "LIQUIDITY_TRANSFER",
  //   label: "Liquidity Transfer"
  // },
  // {
  //   value: "LOAN_APPLICATION",
  //   label: "Loan Application"
  // },
  {
    value: "MONEY_TRANSFER_CASH_INTER_SP",
    label: "Money Transfer Inter SP",
  },
  // {
  //   value: "MONEY_TRANSFER_CASH_INTRA_SP",
  //   label: "Money Transfer"
  // },
  // {
  //   value: "MONEY_TRANSFER_TOKEN_INTER_SP",
  //   label: "Money Transfer by Token Inter SP"
  // },
  // {
  //   value: "MONEY_TRANSFER_TOKEN_INTRA_SP",
  //   label: "Money Transfer by Token"
  // },
  {
    value: "REMOTE_BANK_ACCOUNT_OPENING",
    label: "Remote Bank Account Opening",
  },
  // {
  //   value: "REMOTE_MMO_ACCOUNT_OPENING",
  //   label: "Remote MMO Account Opening"
  // },
  // {
  //   value: "SET_SVA_BALANCE",
  //   label: "Set SVA Balance"
  // },
  {
    value: "UNLOAD_COMMISION",
    label: "Unload Commission",
  },
];

export const WalletJournalServiceType = [
  // {
  //   label: "Select Service Type",
  //   value: "1"
  // },
  {
    label: "Transactions",
    value: "1",
  },
  {
    label: "Commissions",
    value: "2",
  },
];

export const AuditActions = [
  { label: "Create", value: "CREATE" },
  { label: "Update", value: "UPDATE" },
  { label: "Delete", value: "DELETE" },
  { label: "Activate", value: "ACTIVATE" },
  { label: "Deactivate", value: "DEACTIVATE" },
  { label: "Login", value: "LOGIN" },
  { label: "Reset Password", value: "RESET_PASSWORD" },
  { label: "Reset Password / Send Email", value: "RESET_PASSWORD_SEND_EMAIL" },
  { label: "Logout", value: "LOGOUT" },
  // { label: 'Retire', value: 'RETIRE'},
  { label: "Change Password", value: "CHANGE_PASSWORD" },
];

export const AgentStatuses = [
  { label: "Agent Status", value: 0 },
  { label: "New", value: 1, class: "primary" },
  { label: "Active", value: 2, class: "success" },
  { label: "Suspended", value: 3, class: "warning" },
  { label: "Retired", value: 4, class: "danger" },
  { label: "Dormant", value: 5, class: "dark" },
];
