import React from 'react'
import { connect } from 'react-redux'
import Text from './text'
import TourCard from './tour-card'
import { navigateTo } from '../services/redux/actions/navigation';

class TourGuide extends React.Component {
  constructor() {
    super() 

    this.state = {
      hide: localStorage.getItem('tour_guide') === 'hidden',
      level : parseInt(localStorage.getItem('tour_guide_level')) || 0
    }

    this.cancel = this.cancel.bind(this)
    this.next = this.next.bind(this)
    this.skipTour = this.skipTour.bind(this)
  }
  
  cancel () {
    this.setState({
      hide: true
    })
  }

  next () {
    const urls = ['/dashboard', '/my-services', '/my-reports', '/manage-devices']

    this.setState({
      level: this.state.level + 1
    })
    this.props.navigateTo(urls[this.state.level + 1])
    localStorage.setItem('tour_guide_level', JSON.stringify(this.state.level + 1))
  }

  skipTour () {
    localStorage.setItem('tour_guide', 'hidden')
    this.setState({
      hide: true
    })
  }

  get tourCard1 () {
    return <TourCard 
      centerComponent={<Text style={{
          color: 'white',
          fontSize: process.env.REACT_APP_BIG_FONT_SIZE,
          lineHeight: process.env.REACT_APP_BIG_LINE_HEIGHT,
          textAlign: 'center'
        }}>
        Hi, <b>{ this.currentAgent.businessContact ? this.currentAgent.businessContact.firstname : ''} {this.currentAgent.businessContact ? this.currentAgent.businessContact.lastname : ''}</b>!<br/>
        Welcome to your new dashboard
      </Text>}
      nextButtonText="NEXT"
      style={{
        top: 80,
        right: 40,
        position: 'absolute',
        zIndex: 100
      }}
      onCancelClick={this.cancel}
      onNextClick={this.next}
      onSkipTourClick={this.skipTour}
    />
  }

  get tourCard2 () {
    return <TourCard 
      centerComponent={<Text style={{
          color: 'white',
          fontSize: process.env.REACT_APP_BIG_FONT_SIZE,
          lineHeight: process.env.REACT_APP_BIG_LINE_HEIGHT,
          textAlign: 'center'
        }}>
        <b>PAYPOINT SERVICES</b><br/>
        <span style={{fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE, fontWeight: 'normal', lineHeight: process.env.REACT_APP_MID_BIG_LINE_HEIGHT}}>You can Pay Bills, Send Money, Buy Airtime using our services.</span>
      </Text>}
      leftComponent={<Text style={{
        color: 'white',
        fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
        lineHeight: process.env.REACT_APP_MID_BIG_LINE_HEIGHT,
        marginRight: '40px',
        textAlign: 'center',
        textAlignVertical: 'center'
      }}>
        1/3
      </Text>}
      nextButtonText="IT'S CLEAR"
      style={{
        top: 242,
        left: 100,
        position: 'absolute',
        zIndex: 100
      }}
      onCancelClick={this.cancel}
      onNextClick={this.next}
      onSkipTourClick={this.skipTour}
    />
  }

  get tourCard3 () {
    return <TourCard 
        centerComponent={<Text style={{
          color: 'white',
          fontSize: process.env.REACT_APP_BIG_FONT_SIZE,
          lineHeight: process.env.REACT_APP_BIG_LINE_HEIGHT,
          textAlign: 'center'
        }}>
        <b>PAYPOINT REPORTS</b><br/>
        <span style={{fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE, fontWeight: 'normal', lineHeight: process.env.REACT_APP_MID_BIG_LINE_HEIGHT}}>You could see real time reports and analytics.</span>
      </Text>}
      leftComponent={<Text style={{
        color: 'white',
        fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
        lineHeight: process.env.REACT_APP_MID_BIG_LINE_HEIGHT,
        marginRight: '40px',
        textAlign: 'center',
        textAlignVertical: 'center'
      }}>
        2/3
      </Text>}
      nextButtonText="IT'S CLEAR"
      style={{
        top: 298,
        left: 100,
        position: 'absolute',
        zIndex: 100
      }}
      onCancelClick={this.cancel}
      onNextClick={this.next}
      onSkipTourClick={this.skipTour}
    />
  }

  get tourCard4 () {
    return <TourCard 
      centerComponent={<Text style={{
          color: 'white',
          fontSize: process.env.REACT_APP_BIG_FONT_SIZE,
          lineHeight: process.env.REACT_APP_BIG_LINE_HEIGHT,
          textAlign: 'center'
        }}>
        <b>MANAGE DEVICES</b><br/>
        <span style={{fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE, fontWeight: 'normal', lineHeight: process.env.REACT_APP_MID_BIG_LINE_HEIGHT}}>You could manage your devices in<br/>real-time.</span>
      </Text>}
      leftComponent={<Text style={{
        color: 'white',
        fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
        lineHeight: process.env.REACT_APP_MID_BIG_LINE_HEIGHT,
        marginRight: '40px',
        textAlign: 'center',
        textAlignVertical: 'center'
      }}>
        3/3
      </Text>}
      nextButtonText="IT'S CLEAR"
      style={{
        top: 453,
        left: 100,
        position: 'absolute',
        zIndex: 100
      }}
      onCancelClick={this.cancel}
      onNextClick={this.skipTour}
      onSkipTourClick={this.skipTour}
    />
  }

  savePageTourCard(page) {
    const seenPages = JSON.parse(localStorage.getItem('seenPages') || '[]')
    seenPages.push(page)
    localStorage.setItem('seenPages', JSON.stringify(seenPages))
  }

  get currentAgent() {
    return JSON.parse(localStorage.getItem('currentAgent'))
  }

  userHasSeenPageTourCard(page) {
    const seenPages = JSON.parse(localStorage.getItem('seenPages') || '[]')
    return seenPages.includes(page)
  }

  render() {
    const cards = [this.tourCard1, this.tourCard2, this.tourCard3, this.tourCard4]
    const urlCards = {
      'dashboard': this.tourCard1,
      'my-services': this.tourCard2,
      'my-reports': this.tourCard3,
      'manage-devices': this.tourCard4
    }
    const page = window.location.pathname.split('/')[2]

    if (this.state.hide) {
      return <React.Fragment>

      </React.Fragment>
    }

    return <div style={{
      height: '100%',
      maxWidth: '1440px',
      width: '100%'
    }}>{cards[this.state.level]}</div>
  }

}

function mapStateToProps () {
  return
}

function mapDispatchToProps (dispatch) {
  return {
    navigateTo: (pendingUrl) => dispatch(navigateTo(pendingUrl))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TourGuide)
