import React from 'react';
import { Form } from 'react-bootstrap';

const SelectInput = ({
  label,
  disabled = true,
  option,
  onChange,
  placeholder,
  flex,
  value,
}) => {
  return (
    <div
      style={{
        // marginTop: '1rem',
        // marginBottom: '1rem',
        flex: flex,
      }}
    >
      <Form.Label
        style={{
          color: 'black',

          fontSize: '14px',
          fontWeight: 'bold',
        }}
      >
        {label}
      </Form.Label>
      <Form.Control placeholder={placeholder} onChange={onChange} disabled={disabled} as='select'>
        <option value={value} disabled selected>
          {value ? value : ` Select ${placeholder}...`}
        </option>
        {option.map((item) => {
          return (
            <>
              <option key={item} value={item}>
                {item}
              </option>
            </>
          );
        })}
      </Form.Control>
    </div>
  );
};

export default SelectInput;
