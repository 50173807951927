import { connect } from 'react-redux';
import { updateLoading, dismissErrorMessage, showErrorMessage } from '../../../../services/redux/actions/tunnel'
import CompleteDebitCardLinkScene from './scene';

function mapStateToProps(state) {
  return {
    isLoading: state.tunnel.isLoading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateLoading: isLoading => dispatch(updateLoading(isLoading)),
    dismissErrorMessage: () => dispatch(dismissErrorMessage()),
    showErrorMessage: data => dispatch(showErrorMessage(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompleteDebitCardLinkScene);