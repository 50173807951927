import qs from "qs";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MdSync from "react-ionicons/lib/MdSync";
import Select from "react-select";
import "react-table/react-table.css";

import ActivityIndicator from "../../../../components/activity-indicator";
import DateInputButton from "../../../../components/date-inputs/button";
import Text from "../../../../components/text";
import { HTTP_NOT_FOUND_CODE, SUCCESS_STATUS } from "../../../../constants/api";
import { WalletJournalServiceType } from "../../../../fixtures/report_filter_options";
import UserSerializer from "../../../../serializers/resources/user";
import Platform from "../../../../services/api/resources/platform";
import TransactionHistory from "../../../../services/api/resources/transaction-history";
import { convertNgkToNgn } from "../../../../utils/converters/currencies";
import {
  formatAmount,
  formatDate,
  formatDateForDateRange,
  handleLongNumber,
} from "../../../../utils/formatters";
import BaseScene from "../../../base-scene";
import WalletJournalTable from "./tables/wallet-journal-table";

export default class WalletJournalScene extends BaseScene {
  location = window.location.href;
  path = "/wallet-journal";
  title = "Statement of Account";

  transactionHistory = new TransactionHistory();
  platform = new Platform();

  TodaysDate = new Date();
  OneMonthsBefore = new Date(
    this.TodaysDate.getFullYear(),
    this.TodaysDate.getMonth() - 1
  );

  constructor(props) {
    super(props);

    this.state = {
      historicalData: [],
      filteredHistoricalData: [],
      endDate: this.TodaysDate,
      startDate: this.OneMonthsBefore,
      selectedOption: { label: "Transactions", value: "1" },
      agentList: { label: "Select Agent", value: "0" },
      didErrorOccurWhileFetchingAllTransactions: false,
      pageNum: 1,
      pageSize: 50,
      sortDesc: false,
      deviceIsMobile:
        qs.parse(window.location.search)["?mobile"] || window.innerWidth <= 576,
      deviceIsPC:
        !qs.parse(window.location.search)["?mobile"] &&
        window.innerWidth >= 992,
      deviceIsTab:
        qs.parse(window.location.search)["?mobile"] || window.innerWidth <= 992,
    };
    this.getWalletJournal = this.getWalletJournal.bind(this);
    this.filterTransactionsByType = this.filterTransactionsByType.bind(this);
    this.filterTransactionsByAgent = this.filterTransactionsByAgent.bind(this);
    this.downloadReport = this.downloadReport.bind(this);
    this.handleSortOrder = this.handleSortOrder.bind(this);
  }

  componentDidMount() {
    const currentUser = JSON.parse(localStorage.getItem("currentUser") || "{}");
    const userSerializer = new UserSerializer(currentUser);

    userSerializer && userSerializer.isAggregator && this.getAgents();

    this.setState({
      userSerializer: userSerializer,
    });
  }

  componentWillMount() {
    this.getWalletJournal(
      this.state.pageNum,
      this.state.pageSize,
      this.state.selectedOption.value,
      this.state.sortDesc,
      this.state.startDate,
      this.state.endDate,
      this.state.domainCode
    );
  }

  handleGetAgentsEmptyResponse = (code) => {
    this.setState({
      agents: [],
      isAgentLoading: false,
    });
    if (code === HTTP_NOT_FOUND_CODE) {
      this.setState({
        didErrorOccurWhileGettingAgents: false,
      });
    } else {
      this.setState({
        didErrorOccurWhileGettingAgents: true,
      });
    }
  };

  async getAgents() {
    //noPaging & noDetails
    const { status, response, code } = await this.platform.searchAgents(
      null,
      null,
      null,
      true,
      true
    );
    const agentSample = response.content;

    if (status === SUCCESS_STATUS) {
      const transformAgent = (agentSample) => {
        const newAgentArray = agentSample.map(
          ({ businessName: label, businessName: value, ...rest }) => ({
            label,
            value,
            ...rest,
          })
        );
        const selfObject = {
          agentCode: this.state.userSerializer.domainCode,
          value: this.state.userSerializer.domainCode,
          label: "Self",
        };
        const modifiedAgentArray = [...newAgentArray, selfObject];
        return modifiedAgentArray;
      };
      this.setState({
        agents: transformAgent(agentSample),
        isAgentLoading: false,
        didErrorOccurWhileGettingAgents: false,
      });
    } else {
      this.handleGetAgentsEmptyResponse(code);
    }
  }

  async getWalletJournal(
    pageNum,
    pageSize,
    serviceType_,
    sortDesc_,
    startDate_,
    endDate_,
    domainCode
  ) {
    this.setState({
      isLoading: true,
      pageNum,
      pageSize,
      sortDesc: sortDesc_,
    });

    domainCode =
      this.state.userSerializer &&
      this.state.userSerializer.isAggregator &&
      domainCode !== "0"
        ? domainCode
        : null;

    const serviceType = serviceType_;
    const startDate = startDate_ || this.state.startDate;
    const endDate = endDate_ || this.state.endDate;

    const formattedStartDate = formatDateForDateRange(startDate);
    const formattedEndDate = formatDateForDateRange(endDate);
    const { status, response, code } =
      await this.transactionHistory.getWalletJournal(
        formattedStartDate,
        formattedEndDate,
        serviceType,
        sortDesc_,
        pageSize,
        pageNum,
        domainCode
      );

    const walletJournalResponse = response?.content;

    if (status === SUCCESS_STATUS) {
      this.setState({
        historicalData: walletJournalResponse,
        filteredHistoricalData: walletJournalResponse,
        isLoading: false,
        pages: Math.ceil(response.count / pageSize),
        pageSize: pageSize,
        didErrorOccurWhileFetchingAllTransactions: false,
      });
    } else {
      this.handleEmptyResponse(code);
    }
  }

  handleEmptyResponse = (code) => {
    this.setState({
      historicalData: [],
      filteredHistoricalData: [],
      pages: 1,
      isLoading: false,
    });
    if (code === HTTP_NOT_FOUND_CODE) {
      this.setState({
        didErrorOccurWhileFetchingAllTransactions: false,
      });
    } else {
      this.setState({
        didErrorOccurWhileFetchingAllTransactions: true,
      });
    }
  };

  filterTransactionsByAgent(event) {
    const domainCode = event.agentCode;
    this.setState({
      domainCode,
      agentList: event,
    });

    this.getWalletJournal(
      this.state.pageNum,
      this.state.pageSize,
      this.state.selectedOption.value,
      this.state.sortDesc,
      this.state.startDate,
      this.state.endDate,
      domainCode
    );
  }

  setStartDate(value) {
    this.setState({
      startDate: value,
    });

    const domainCode =
      this.state.userSerializer &&
      this.state.userSerializer.isAggregator &&
      this.state.domainCode !== "0"
        ? this.state.domainCode
        : null;

    this.getWalletJournal(
      this.state.pageNum,
      this.state.pageSize,
      null,
      this.state.sortDesc,
      value,
      null,
      domainCode
    );
  }

  setEndDate(value) {
    this.setState({
      endDate: value,
    });

    const domainCode =
      this.state.userSerializer &&
      this.state.userSerializer.isAggregator &&
      this.state.domainCode !== "0"
        ? this.state.domainCode
        : null;

    this.getWalletJournal(
      this.state.pageNum,
      this.state.pageSize,
      null,
      this.state.sortDesc,
      null,
      value,
      domainCode
    );
  }

  filterTransactionsByType(selectedOption) {
    this.setState({
      selectedOption,
    });

    const domainCode =
      this.state.userSerializer &&
      this.state.userSerializer.isAggregator &&
      this.state.domainCode !== "0"
        ? this.state.domainCode
        : null;

    selectedOption.value === "0"
      ? this.getWalletJournal(this.state.pageNum, this.state.pageSize)
      : this.getWalletJournal(
          this.state.pageNum,
          this.state.pageSize,
          selectedOption.value,
          this.state.sortDesc,
          null,
          null,
          domainCode
        );
  }

  renderRefreshTransactionMessage() {
    const { startDate, endDate, selectedOption, pageNum, pageSize } =
      this.state;
    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "top",
          marginTop: "24px",
          paddingBottom: "30px",
        }}
      >
        <Text blue bold small>
          Oops!
        </Text>
        <Text mid>Something went wrong. Please, try again.</Text>
        <div
          style={{
            alignItems: "center",
            border: `1pt solid ${process.env.REACT_APP_BLUE_COLOUR}`,
            borderRadius: "4px",
            color: process.env.REACT_APP_BLUE_COLOUR,
            cursor: "pointer",
            display: "flex",
            fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            fontStyle: process.env.REACT_APP_MID_FONT_SIZE,
            fontWeight: "bold",
            height: "40px",
            justifyContent: "center",
            marginTop: "16px",
            width: "160px",
          }}
          onClick={() =>
            this.getWalletJournal(
              pageNum,
              pageSize,
              selectedOption.value,
              startDate,
              endDate
            )
          }
        >
          <MdSync
            rotate={this.state.isLoading}
            color={process.env.REACT_APP_BLUE_COLOUR}
            fontSize={"24px"}
            style={{ marginRight: "10px" }}
          />
          {this.state.isLoading ? "Loading..." : "RETRY"}
        </div>
      </div>
    );
  }

  async downloadReport() {
    let { startDate, endDate, selectedOption, pageSize, pageNum, domainCode } =
      this.state;
    this.setState({
      isLoading: true,
    });

    domainCode =
      this.state.userSerializer &&
      this.state.userSerializer.isAggregator &&
      domainCode !== "0"
        ? domainCode
        : null;

    const formattedStartDate = formatDateForDateRange(startDate);
    const formattedEndDate = formatDateForDateRange(endDate);

    const { status, response, code } =
      await this.transactionHistory.downloadWalletJournal(
        formattedStartDate,
        formattedEndDate,
        selectedOption.value,
        pageSize,
        pageNum,
        domainCode
      );

    if (status === SUCCESS_STATUS) {
      const blob = new Blob([response], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("download", "Statement of Account Report.xls");
      a.setAttribute("href", window.URL.createObjectURL(blob));
      a.click();
      this.setState({
        isLoading: false,
      });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  handleSortOrder = (order) => {
    const domainCode =
      this.state.userSerializer &&
      this.state.userSerializer.isAggregator &&
      this.state.domainCode !== "0"
        ? this.state.domainCode
        : null;
    this.getWalletJournal(
      this.state.pageNum,
      this.state.pageSize,
      null,
      !order,
      null,
      null,
      domainCode
    );
  };

  render() {
    const { userSerializer, agentList } = this.state;
    const csvFilteredTransaction = this.state.historicalData?.map((trans) => ({
      uniqueReference:
        trans.uniqueReference && handleLongNumber(trans.uniqueReference),
      journalDescription: trans.journalDescription && trans.journalDescription,
      debitedAmount:
        trans.debitAmount &&
        `₦${formatAmount(convertNgkToNgn(trans.debitAmount))}`,
      creditedAmount:
        trans.creditAmount &&
        `₦${formatAmount(convertNgkToNgn(trans.creditAmount))}`,
      balance:
        trans.balance && `₦${formatAmount(convertNgkToNgn(trans.balance))}`,
      journalEntryDate:
        trans.journalEntryDate && formatDate(trans.journalEntryDate),
    }));

    const csvHeader = [
      { label: "Transaction Reference", key: "uniqueReference" },
      { label: "Description", key: "journalDescription" },
      { label: "Debited Amount", key: "debitedAmount" },
      { label: "Credited Amount", key: "creditedAmount" },
      { label: "Balance", key: "balance" },
      { label: "Entry Date", key: "journalEntryDate" },
    ];

    return (
      <div
        className={
          this.state.deviceIsMobile
            ? "container container-fluid reports-scene-container-mobile"
            : "reports-scene-container"
        }
      >
        <div className="h1 reports-scene-header">Statement of Account</div>
        <Container
          fluid
          className="regular-container"
          style={{ padding: "10px", }}
        >
          <Row id="reports-scene-row">
            <Col
              xs={12}
              md={12}
              xl={12}
              id="services-report-container"
              className="card-with-shadow"
            >
              <Col
                md={12}
                className="card-with-shadow"
                id="transactions-table-container"
              >
                <div className="transactions-table-header">
                  {userSerializer && userSerializer.isAggregator && (
                    <Select
                      options={this.state.agents && this.state.agents}
                      onChange={(event) => {
                        this.filterTransactionsByAgent(event);
                      }}
                      value={agentList}
                      className="select-input-container"
                      classNamePrefix="react-select"
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "rgb(178, 212, 255)",
                        },
                      })}
                    />
                  )}
                  <Select
                    options={WalletJournalServiceType}
                    onChange={this.filterTransactionsByType}
                    value={this.state.selectedOption}
                    className="select-input-container"
                    classNamePrefix="react-select"
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "rgb(178, 212, 255)",
                      },
                    })}
                  />
                  <div className="reports-scene-date-picker-container">
                    <DatePicker
                      selected={this.state.startDate}
                      onChange={(date) => this.setStartDate(date)}
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      dateFormat={`dd/MM/yyyy`}
                      showYearDropdown
                      customInput={<DateInputButton />}
                      maxDate={this.TodaysDate}
                      // showTimeSelect
                    />
                    <Text
                      className="date-input-separator"
                      style={{ fontSize: "24px" }}
                    >
                      -
                    </Text>
                    <DatePicker
                      selected={this.state.endDate}
                      onChange={(date) => this.setEndDate(date)}
                      selectsEnd
                      endDate={this.state.endDate}
                      minDate={this.state.startDate}
                      maxDate={this.TodaysDate}
                      dateFormat={`dd/MM/yyyy`}
                      showYearDropdown
                      customInput={<DateInputButton />}
                      // showTimeSelect
                    />
                  </div>
                  {/* <div>
                              <Button className="download-link-button" onClick={() => this.handleSortOrder(this.state.sortDesc)}>
                                {this.state.sortDesc ? 'Order: Descending' : 'Order: Ascending'}
                              </Button>
                            </div> */}
                  <div>
                    <Button
                      className="download-link-button"
                      onClick={this.downloadReport}
                    >
                      Download
                    </Button>
                  </div>
                </div>
                <div className="transactions-table-container">
                  {this.state.didErrorOccurWhileFetchingAllTransactions ? (
                    this.renderRefreshTransactionMessage()
                  ) : this.state.filteredHistoricalData?.length > 0 ? (
                    <WalletJournalTable
                      data={this.state.filteredHistoricalData}
                      getAllTransactions={this.getWalletJournal}
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      pages={this.state.pages}
                      pageSize={this.state.pageSize}
                      pageNum={this.state.pageNum}
                      sortDesc={this.state.sortDesc}
                      loading={this.state.isLoading}
                      selectedOption={this.state.selectedOption}
                      dismissErrorMessage={this.props.dismissErrorMessage}
                      showErrorMessage={this.props.showErrorMessage}
                      domainCode={this.state.domainCode}
                    />
                  ) : this.state.isLoading ? (
                    <p
                      style={{
                        textAlign: "center",
                        cursor: "pointer",
                        padding: "20px 0 20px 0",
                      }}
                    >
                      <ActivityIndicator />
                    </p>
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        color: process.env.REACT_APP_BLUE_COLOUR,
                        fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                        padding: "85px 0 85px 0",
                      }}
                    >
                      {"No record found, change filter"}
                    </p>
                  )}
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
