import React from 'react';
import {
  Route, 
  Switch 
} from 'react-router-dom';
import ViewAllRolesScene from './scenes/view-all-roles';
import CreateRoleScene from './scenes/create-role';
import ViewAndEditRoleScene from './scenes/view-and-edit-role';

export default class RoleScene extends React.Component {
  render() {

    const switchComponent = <Switch>
      {/* <Route path={`${process.env.REACT_APP_ROUTE_BASENAME}/dashboard`} component={() => <DashboardScene currentApplication={this.state.currentApplication} currentAgent={this.state.currentAgent} disabled={this.state.disabled} loadUser={this.loadUser} getWallet={this.getWallet} />} /> */}
      <Route exact path={`${process.env.REACT_APP_ROUTE_BASENAME}/roles`} component={ViewAllRolesScene} />
      <Route exact path={`${process.env.REACT_APP_ROUTE_BASENAME}/roles/new`} component={CreateRoleScene} />
      <Route exact path={`${process.env.REACT_APP_ROUTE_BASENAME}/roles/:roleName`} component={ViewAndEditRoleScene} />
    </Switch>

    return switchComponent
  }
}
