import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import MdArrowBack from 'react-ionicons/lib/MdArrowBack';
import Services from '../../../../../fixtures/services';
import { CARDLESS_CASHOUT_TABS_TO_HIDE } from '../../../../../utils/api-properties';
import BaseScene from '../../../../base-scene';
import ListItem from '../components/list-item';
import ProductPayment from '../components/product-payment';

const WITHDRAWAL = 'cardless-cashout'

class ServiceTypesTab extends BaseScene {
  constructor(props) {
    super(props);

    this.onBackButtonClick = this.onBackButtonClick.bind(this);
  }

  updateFormField(params) {

  }

  onBackButtonClick() {
    this.setState({selectedServiceType: null});
    this.props.onClose ? this.props.onClose() : this.props.goBack();
  }

  render() {
    return <section style={{backgroundColor: 'white', height: '100vh', width: this.state.deviceIsMobile ? '100vw' : '100%'}}>
      <div>
        {!this.state.deviceIsMobile && <div style={{height: '90px'}} />}
        {(this.props.selectedServiceType || this.state.deviceIsMobile) && <div>
          {<div style={{alignItems: 'center', display: 'flex', marginLeft: '10px', paddingTop: '10px', marginBottom: '10px'}}>
            <MdArrowBack className="grow" fontSize="26px" onClick={this.onBackButtonClick} />
          </div>}
        </div>}
      </div>
      <div className="services-type-tab" style={{height: 'auto'}}>
        {Services[WITHDRAWAL].filter(value => 
          !process.env.REACT_APP_CARDLESS_CASHOUT_TABS_TO_HIDE.includes(value.id))
        .map(value => <ListItem 
          value={value} 
          onSelect={(value) => {
            this.props.onSelect(value)
          }}
          active={this.props.selectedServiceType === value} 
          display={CARDLESS_CASHOUT_TABS_TO_HIDE.includes(value.id)}
          />
        )}
      </div>
    </section>
  }
}

export default class withdrawalScene extends BaseScene {
  state = {
    
  }

  constructor() {
    super();

    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    this.setState({
      selectedServiceType: null
    })
    this.props.showServiceCategories();
  }

  render() {   
    const screenSensitiveStyle = this.state.deviceIsMobile ? {
      paddingRight: 0, 
      marginRight: 0
    } : { }

    const desktopContainer = <Row style={{margin: '0px', padding: '0px'}}>

      {<Col md={this.state.selectedServiceType ? 4 : 5} style={{margin: '0px', padding: '0px'}}>
        <ServiceTypesTab 
          goBack={this.goBack}
          onSelect={(value) => {
            this.props.hideServiceCategories();
            this.setState({selectedServiceType: value})
          }}
          selectedServiceType={this.state.selectedServiceType} />
      </Col>}

      {this.state.selectedServiceType && <Col md={8} style={{marginLeft: 0, paddingLeft: 0}}>
        <ProductPayment 
          category={WITHDRAWAL}
          onCancelProduct={() => this.setState({selectedServiceType: null})}
          service={this.state.selectedServiceType} />
      </Col>}
    </Row> 
    
    const mobileContainer = <Row style={{margin: '0px', padding: '0px'}}>
      {!this.state.selectedServiceType && <Col xs={12} md={this.state.selectedServiceType ? 4 : 5} style={{marginLeft: '0px', paddingLeft: '0px'}}>
        <ServiceTypesTab 
          onClose={this.props.onClose}
          goBack={this.goBack}
          onBack={() => {
            this.props.showServiceCategories();
            this.setState({selectedServiceType: null})
          }}
          onSelect={(value) => {
            this.props.hideServiceCategories();
            this.setState({selectedServiceType: value})
          }}
          selectedServiceType={this.state.selectedServiceType} />
      </Col>} 

      {this.state.selectedServiceType && <Col xs={12} md={12} style={{marginLeft: 0, paddingLeft: 0, ...screenSensitiveStyle}}>
        <ProductPayment 
          category={WITHDRAWAL}
          onCancelProduct={() => {
            this.setState({selectedServiceType: null})
          }}
          service={this.state.selectedServiceType} 
        />
      </Col>}
    </Row>

    return <Container fluid style={{padding: 0, margin: 0}}>
      {this.state.deviceIsPC ? desktopContainer : mobileContainer}
    </Container>
  }
}
