import React from 'react';
import Select from 'react-select';
import { Alert, Col, Container, Row, Button, Form } from 'react-bootstrap';
import 'react-table/react-table.css';
import qs from 'qs';

import DatePicker from 'react-datepicker';
import BaseScene from '../../../base-scene';
import { SUCCESS_STATUS, HTTP_NOT_FOUND_CODE } from '../../../../constants/api';
import AccountOpening from '../../../../services/api/resources/account-opening';
import FormControl from '../../../../components/form-control';
import { Redirect } from 'react-router-dom';
import { NIGERIA} from '../../../../constants';
import CountriesStatesLga from '../../../../fixtures/countries_states_lgas';
import { subtractDays } from '../../../../utils/time';
import { getDeviceInformation } from '../../../../utils/helpers';

const customStylesBanks = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 14,
  }),
  control: (base) => ({
    ...base,
    minHeight: 48,
  }),
  singleValue: (provided, state) => {
    const fontSize = 14;
    return { ...provided, fontSize }
  }
}

class AccountOpeningScene extends BaseScene {

  location = window.location.href;
  path = '/create-account-form';
  title = 'Create Account Opening Form';

  
  accountOpening = new AccountOpening();
  constructor(props) {
    super(props);
    
    this.state = {
      accountOpeningData: {
        firstName:null, middleName:null, lastName:null, 
        gender:null, dateOfBirth:null, houseNumber:null, streetName:null, 
        city:null,bankCode:null,
        lgaCode:null, 
        emailAddress:null,
        phoneNumber:null,
      },      
      countries: [],
      states: [],
      lgas: [],
      bankData: [{sanefBankCode:"loading",bankName:"loading..."}],
      bankName:"loading...",
      sanefBankCode:"loading",
      errorMessage:null,
      checkValidFields: 0,
      isLoading:false,
      isLoadingBanks:false,
      fieldsFocused: [],
      redirectToCompleteCardLink: false,
      didErrorOccurWhileFetchingAllBanks: false,
      deviceIsMobile: qs.parse(window.location.search)['?mobile'] || window.innerWidth <= 576,
      deviceIsPC: !qs.parse(window.location.search)['?mobile'] && window.innerWidth >= 992,
      deviceIsTab: qs.parse(window.location.search)['?mobile'] || window.innerWidth <= 992,
    };
    this.getBankList = this.getBankList.bind(this)
    this.createForm = this.createForm.bind(this)
    
    this.onLgaSelect = this.onLgaSelect.bind(this);
    this.onNationalitySelect = this.onNationalitySelect.bind(this)
    this.onStateSelect = this.onStateSelect.bind(this)
    localStorage.removeItem("transactionRef")
    localStorage.removeItem("accountName")
   }

  componentDidMount () {
    this.getBankList()
    
    this.fetchAllCountries();
  }

  handleGetAgentsEmptyResponse = (code) => {
    this.setState({
      agents: [],
      isAgentLoading: false
    })
    if (code === HTTP_NOT_FOUND_CODE) {
      this.setState({
        didErrorOccurWhileGettingAgents: false
      })
    } else {
      this.setState({
        didErrorOccurWhileGettingAgents: true
      })
    }
  }

  async fetchAllCountries() {        
    const countries = CountriesStatesLga;
    this.setState({
      countries
    });

    const defaultNationality = countries.find(value => value.name === NIGERIA)
    this.onNationalitySelect(defaultNationality.id)
    this.updateFormField({nationality: JSON.stringify(defaultNationality.id)})
    this.setState({
      form: {
        ...this.state.form,
        nationality: defaultNationality.id
      }
    })
  }

  async fetchAllLgas(stateId) {
    const country = CountriesStatesLga.find(value => parseInt(value.id) === parseInt(this.state.form.nationality))
    const state = country.states ? country.states.find(value => parseInt(value.id) === parseInt(stateId)) : null

    this.setState({
      lgas: state ? state.lgas : []
    })
  }

  async fetchAllStates(countryId) {
    const country = CountriesStatesLga.find(value => parseInt(value.id) === parseInt(countryId))

    this.setState({
      states: country ? country.states : []
    })
  }

  onLgaSelect(stateId) {
    return
  }
  
  onNationalitySelect(countryId) {
    this.setState({
      states: [],
      lgas: [],
      form: {
        ...this.state.form,
        state: null,
        lga: null
      }
    })
    this.fetchAllStates(countryId)
  }

  onStateSelect(stateId) {
    this.setState({
      lgas: [],
      form: {
        ...this.state.form,
        lga: null
      }
    })
    this.fetchAllLgas(stateId)
  }

  async getBankList() {
    this.setState({
      isLoadingBanks: true,
    });
    const deviceInformation = getDeviceInformation()
    const { status, response, code } = await this.accountOpening.registerAgent(deviceInformation.deviceUuid)
    
    const accountOpeningResponse = "";
    try{
      accountOpeningResponse = response.data.bankList;
    } catch(e){}
    
    if(status === SUCCESS_STATUS) {
      this.setState({
        bankData: accountOpeningResponse,
        isLoadingBanks: false,
        didErrorOccurWhileFetchingAllBanks: false
      })
    } else {
      this.handleEmptyResponse(code);
    }
  }

  async createForm() {
    this.setState({
      isLoading: true,
    });

    const param = {...this.state.accountOpeningData}
    
    if(this.validateForm(param)){
      var dd = param.dateOfBirth.getDate();
      var mm = param.dateOfBirth.getMonth(); //January is 0!
      var yyyy = param.dateOfBirth.getFullYear();

      const ddd = dd < 10?'0'+dd:dd
      const month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      const month = month_names_short[mm]

      const dateOfBirth = yyyy+'-'+month+'-'+ddd
      const deviceInformation = getDeviceInformation()
      const { status, response } = await this.accountOpening.createForm(
        deviceInformation.deviceUuid,{
        "FirstName":param.firstName, "MiddleName":param.middleName, "LastName":param.lastName, 
          "Gender":param.gender, "DateOfBirth":dateOfBirth, "HouseNumber":param.houseNumber, "StreetName":param.streetName, 
          "City":param.city,"BankCode":param.bankCode,
          "LgaCode":param.lgaCode, 
          "EmailAddress":param.emailAddress,
          "PhoneNumber":param.phoneNumber
      })
    
      const createFormResponse = response.data;

      if(status === SUCCESS_STATUS) {
        localStorage.setItem("transactionRef", createFormResponse.transactionRef)
        localStorage.setItem("accountName", param.firstName+ " "+ param.lastName)
        this.setState({
          accountOpeningData: createFormResponse,
          isLoading: false,
          didErrorOccurWhileFetchingAllBanks: false,
          redirectToCompleteCardLink: true
        })
      } else {
        this.handleEmptyResponse(response.description);
        alert(response.description);
      }
    }
  }

  handleEmptyResponse = (description) => {
    this.setState({
      isLoading: false,
      errorMessage: description
    })
  }

  updateFormField (params) {
    const newAccountOpeningData = {
      ...this.state.accountOpeningData,
      ...params,
    }

    this.setState({
      accountOpeningData: newAccountOpeningData,
    })
  }


  handleValidationErrorResponse = (description) => {
    alert(description)
    this.setState({
      isLoading: false,
      errorMessage: description
    })
  }

  validateForm = (param) => {
    let formResponse = false;
    if(!param.firstName){
      this.handleValidationErrorResponse("First name is required");
    }
    else if(!param.lastName){
      this.handleValidationErrorResponse("Last name is required");
    }
    else if(!param.gender){
      this.handleValidationErrorResponse("Gender is required");
    }
    else if(!param.dateOfBirth){
      this.handleValidationErrorResponse("Date of Birth is required");
    }
    else if(!param.houseNumber){
      this.handleValidationErrorResponse("House number is required");
    }
    else if(!param.streetName){
      this.handleValidationErrorResponse("Street name is required");
    }
    else if(!param.city){
      this.handleValidationErrorResponse("City is required");
    }
    else if(!param.nationality){
      this.handleValidationErrorResponse("Nationality is required");
    }
    else if(!param.state){
      this.handleValidationErrorResponse("Kindly select State of residence");
    }
    else if(!param.lgaCode){
      this.handleValidationErrorResponse("Kindly select LGA of residence");
    }
    else if(!param.bankCode){
      this.handleValidationErrorResponse("Kindly select Bank");
    }
    else if(!param.emailAddress){
      this.handleValidationErrorResponse("Email address is required");
    }
    else if(!param.phoneNumber){
      this.handleValidationErrorResponse("Phone number is required");
    }
    else if(param.phoneNumber.length < 11){
      this.handleValidationErrorResponse("Phone number must not be less than 11");
    }
    else if(param.phoneNumber.length > 15){
      this.handleValidationErrorResponse("Phone number must not be greater than 15");
    }
    else{formResponse = true}
    return formResponse
  }
  
  updateAccountNumberField (params) {
   
    this.setState({accountNumber:params})

    let count = 0
    if(params.length > 9){
      count = 1
    }
    if(this.state.bankCode)
    if(this.state.bankCode.length >1){
      count += 1
    }
    this.setState({checkValidFields:count});
  }

  updateBankCodeField (params) {
   
    this.setState({bankCode:params})
    
    let count = 0
    if(params.length > 1) count =1
    if(this.state.accountNumber){
      if(this.state.accountNumber.length > 9){
        count += 1
      }
    }
    this.setState({checkValidFields:count});
  }


  render () {
    
    if (this.state.redirectToCompleteCardLink) {
      return <Redirect to="/agent/account-opening-documents" />
    }

    return <div className={this.state.deviceIsMobile ? "container container-fluid reports-scene-container-mobile" : "reports-scene-container"} style={{'overflow':'scroll', 'height':'95vh'}}>
      <div className="h1 reports-scene-header">Account Opening Form</div>
        <Container fluid className="regular-container" style={{ padding: '10px'}}>
            <Row id="reports-scene-row">
                <Col xs={6} md={6} xl={6} id="services-report-container" className="card-with-shadow">
                    <Col md={12} className="card-with-shadow" id="transactions-table-container">
                    
      <Form.Group>
        {this.state.errorMessage && <Alert variant="danger">{this.state.errorMessage}</Alert>}
        <Form.Label style={{color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>First Name: <span className="required-field-tick">*</span></Form.Label>
        <FormControl 
          autoComplete="off"
          defaultValue={this.state.accountOpeningData.firstName} 
          maxLength={20} 
          type="text" 
          placeholder="Your first name" 
          onBlur={() => this.setState({
            fieldsFocused: [...this.state.fieldsFocused, 'firstName']
          })}
          onChange={(event) => {
            const firstName = event.target.value
            this.updateFormField({firstName})
          }}
          style={{backgroundColor: '#FFFFFF', borderRadius: '20px', border: `1px solid #DFE2E6`, boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px'}} 
        /> 
        
        <Form.Label style={{color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Middle Name: </Form.Label>
        <FormControl 
          autoComplete="off"
          defaultValue={this.state.accountOpeningData.middleName} 
          maxLength={20} 
          type="text" 
          placeholder="Your middle name" 
          onBlur={() => this.setState({
            fieldsFocused: [...this.state.fieldsFocused, 'middleName']
          })}
          onChange={(event) => {
            const middleName = event.target.value
            this.updateFormField({middleName})
          }}
          style={{backgroundColor: '#FFFFFF', borderRadius: '20px', border: `1px solid #DFE2E6`, boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px'}} 
        />        
        
        <Form.Label style={{color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Last Name: <span className="required-field-tick">*</span></Form.Label>
        <FormControl 
          autoComplete="off"
          defaultValue={this.state.accountOpeningData.lastName} 
          maxLength={20} 
          type="text" 
          placeholder="Your last name" 
          onBlur={() => this.setState({
            fieldsFocused: [...this.state.fieldsFocused, 'lastName']
          })}
          onChange={(event) => {
            const lastName = event.target.value
            this.updateFormField({lastName})
          }}
          style={{backgroundColor: '#FFFFFF', borderRadius: '20px', border: `1px solid #DFE2E6`, boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px'}} 
        />        
        
        <Form.Label style={{color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Gender: <span className="required-field-tick">*</span></Form.Label>
        <select autocomplete="off" class="form-control" disabled={this.props.disabled ? this.props.disabled : ''} data-live-search="true" onChange={(event) => {
            const gender = event.target.value
            this.updateFormField({gender})
          }}>
            <option selected disabled>Select</option>
            {[{"gender":"Male"}, {"gender":"Female"}].map((value, index) => <option value={value.gender} >{value.gender}</option>)}
        </select>
        
        <Form.Label style={{color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Date of Birth: <span className="required-field-tick">*</span></Form.Label>
        <br/>
        <DatePicker
            customInput={<FormControl 
              maxLength={20} 
              placeholder="DD/MM/YYYY" 
              value={this.state.accountOpeningData.dateOfBirth}
              onBlur={() => this.setState({
                fieldsFocused: [...this.state.fieldsFocused, 'dateOfBirth']
              })} 
              style={{backgroundColor: '#FFFFFF', borderRadius: '20px', border: `1px solid '#DFE2E6'`, boxSizing: 'border-box', fontSize: '14px', lineHeight: '17px'}} 
            />}
            dateFormat="dd/MM/yyyy"
            showYearDropdown
            minDate={subtractDays(new Date(), 365 * 70)}
            maxDate={subtractDays(new Date(), 365 * 18)}
            selected={this.state.accountOpeningData.dateOfBirth}
            onBlur={() => this.setState({
              fieldsFocused: [...this.state.fieldsFocused, 'dateOfBirth']
            })}
            onChange={value => {
              this.updateFormField({dateOfBirth: value })
            }}
            placeholderText="DD/MM/YYYY"
            className="date-picker"
            style={{
              border: `1px solid #DFE2E6`,
              borderRadius: '20px',
              padding: 10
            }} /><br/>
        <Form.Label style={{color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>House Number: <span className="required-field-tick">*</span></Form.Label>
        <FormControl 
          autoComplete="off"
          defaultValue={this.state.accountOpeningData.houseNumber} 
          maxLength={20} 
          type="text" 
          placeholder="Your House Number" 
          onBlur={() => this.setState({
            fieldsFocused: [...this.state.fieldsFocused, 'houseNumber']
          })}
          onChange={(event) => {
            const houseNumber = event.target.value
            this.updateFormField({houseNumber})
          }}
          style={{backgroundColor: '#FFFFFF', borderRadius: '20px', border: `1px solid #DFE2E6`, boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px'}} 
        />        
        
        <Form.Label style={{color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Street Name: <span className="required-field-tick">*</span></Form.Label>
        <FormControl 
          autoComplete="off"
          defaultValue={this.state.accountOpeningData.streetName} 
          maxLength={20} 
          type="text" 
          placeholder="Your Street Name" 
          onBlur={() => this.setState({
            fieldsFocused: [...this.state.fieldsFocused, 'streetName']
          })}
          onChange={(event) => {
            const streetName = event.target.value
            this.updateFormField({streetName})
          }}
          style={{backgroundColor: '#FFFFFF', borderRadius: '20px', border: `1px solid #DFE2E6`, boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px'}} 
        />        
        
        <Form.Label style={{color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>City: <span className="required-field-tick">*</span></Form.Label>
        <FormControl 
          autoComplete="off"
          defaultValue={this.state.accountOpeningData.city} 
          maxLength={20} 
          type="text" 
          placeholder="Your City" 
          onBlur={() => this.setState({
            fieldsFocused: [...this.state.fieldsFocused, 'city']
          })}
          onChange={(event) => {
            const city = event.target.value
            this.updateFormField({city})
          }}
          style={{backgroundColor: '#FFFFFF', borderRadius: '20px', border: `1px solid #DFE2E6`, boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px'}} 
        />        
        
          <Form.Label style={{color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Nationality: <span className="required-field-tick">*</span></Form.Label>
            <FormControl
              onBlur={() => this.setState({
                fieldsFocused: [...this.state.fieldsFocused, 'nationality']
              })}
              onChange={(event) => {
                const nationality = event.target.value
                this.onNationalitySelect(nationality)
                this.updateFormField({nationality, state: null, lga: null})
              }}
              style={{
                backgroundColor: '#FFFFFF', 
                borderRadius: '20px', 
                border: '1px solid #DFE2E6', 
                boxSizing: 'border-box', 
                fontSize: process.env.REACT_APP_MID_FONT_SIZE, 
                lineHeight: '17px', 
                width: '100%'
              }} as="select">
              <option value={0}>Select Nationality</option>
              {this.state.countries.map(value => <option selected={value.name === NIGERIA} value={JSON.stringify(value.id)}>{value.name}</option>)}
            </FormControl>
            
          <Form.Label style={{color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>State of residence: <span className="required-field-tick">*</span></Form.Label>
            <FormControl 
              onBlur={() => this.setState({
                fieldsFocused: [...this.state.fieldsFocused, 'state']
              })}
              onChange={(event) => {
                const state = event.target.value
                this.onStateSelect(state)
                this.updateFormField({state, lga: null})
              }} style={{backgroundColor: '#FFFFFF', borderRadius: '20px', border: '1px solid #DFE2E6', boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px', width: '100%'}} as="select">  
              <option value={0}>Select State</option>
              {this.state.states.map(value => <option value={JSON.stringify(value.id)}>{value.name}</option>)}
            </FormControl>
          
            <Form.Label style={{color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Lga of residence: <span className="required-field-tick">*</span></Form.Label>
            <FormControl 
              onBlur={() => this.setState({
                fieldsFocused: [...this.state.fieldsFocused, 'lga']
              })}
              onChange={(event) => {
                const lgaCode = event.target.value
                this.onLgaSelect(lgaCode)
                this.updateFormField({lgaCode})
              }} style={{backgroundColor: '#FFFFFF', borderRadius: '20px', border: '1px solid #DFE2E6', boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px', width: '100%'}} as="select">
              <option value={0}>Select LGA</option>
              {this.state.lgas.map(value => <option value={value.code}>{value.name}</option>)}
            </FormControl>
          
        
          <Form.Group controlId="bankCode">
            <Form.Label style={{color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Banks: <span className="required-field-tick">*</span></Form.Label>
      
          <Select 
              options={this.state.bankData.map(value => ({"value":value.sanefBankCode, "label":value.bankName}))}
              onChange={(event) => {
                const bankCode = event.value
                this.updateFormField({bankCode})
              }}
              styles={customStylesBanks}
              className='select-input-container-banks'
              classNamePrefix="react-select"
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: 'rgb(178, 212, 255)',
                }
              })}
            />
            </Form.Group>
            
        <Form.Label style={{color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Email Address: <span className="required-field-tick">*</span></Form.Label>
        <FormControl 
          autoComplete="off"
          defaultValue={this.state.accountOpeningData.emailAddress} 
          maxLength={40} 
          type="email" 
          placeholder="Your email address" 
          onBlur={() => this.setState({
            fieldsFocused: [...this.state.fieldsFocused, 'emailAddress']
          })}
          onChange={(event) => {
            const emailAddress = event.target.value
            this.updateFormField({emailAddress})
          }}
          style={{backgroundColor: '#FFFFFF', borderRadius: '20px', border: `1px solid #DFE2E6`, boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px'}} 
        />        
        
        <Form.Label style={{color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Phone Number: <span className="required-field-tick">*</span></Form.Label>
        <FormControl 
          autoComplete="off"
          defaultValue={this.state.accountOpeningData.phoneNumber} 
          maxLength={15} 
          type="text" 
          placeholder="Your phone number" 
          onBlur={() => this.setState({
            fieldsFocused: [...this.state.fieldsFocused, 'phoneNumber']
          })}
          onChange={(event) => {
            const phoneNumber = event.target.value
            this.updateFormField({phoneNumber})
          }}
          style={{backgroundColor: '#FFFFFF', borderRadius: '20px', border: `1px solid #DFE2E6`, boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px'}} 
        />        
      </Form.Group>


        <div style={{marginTop: '10px', marginBottom: '10px'}}>
          <Button className="continue-later-pc-button-right" onClick={this.createForm} disabled={this.state.isLoading}>
          {this.state.isLoading ? 'LOADING...' : 'CONTINUE'}
          </Button>
        </div>
        <br/><br/>

                    </Col>
                </Col>
            </Row>
        </Container>
    </div>
  }
}

export default AccountOpeningScene;
