import React from 'react';
import { Col, Row } from 'react-bootstrap';
import BaseScene from '../scenes/base-scene';

export default class Footer extends BaseScene {
  state = {

  }

  render () {
    const style = !this.props.relative ? {
      position: 'absolute', 
      bottom: '0px', 
      width: '100%'
    } : {
      width: '100vw'
    }

    const currentYear = new Date().getFullYear();

    return <section style={style}>
      <hr style={{ backgroundColor: 'rgba(79, 109, 245, 0.212013)', border: '1px solid rgba(79, 109, 245, 0.212013)', margin: 'auto 70px 0px 70px', marginBottom: '0px' }} />

      <Row hidden style={{ 
        backgroundColor: '#e1e2e6', 
        marginLeft: 0, 
        marginRight: 0, 
        paddingLeft: this.state.deviceIsMobile ? '10px' : '165px', 
        paddingRight: this.state.deviceIsMobile ? '10px' : '165px'}}>
        <Col xs={6} md={3} style={{marginTop: '20px'}}>
          <div style={{
            color: '#A2A2A2',
            // fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
            fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            lineHeight: '25px',
            marginTop: 0, 
            marginRight: '70px'}}>
            Imo State Branch, Plot 5009, New Owerri, PMB 1556, Owerri, Tel: 08037190940
          </div>
        </Col>

        <Col xs={6} md={3} style={{marginTop: '20px'}}>
          <Row>
            <div style={{
              color: '#3A3B3B',
              // fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
              lineHeight: '25px',
              marginBottom: '20px',
              textTransform: 'uppercase'
            }}>Company</div></Row>
          <Row>
            <div style={{
              color: '#A2A2A2',
              // fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
              lineHeight: '25px',
              marginBottom: '20px'
            }}>Page link</div>
          </Row>
          <Row>
            <div style={{
              color: '#A2A2A2',
              // fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
              lineHeight: '25px',
              marginBottom: '20px'
            }}>Page link</div>
          </Row>
          <Row>
            <div style={{
              color: '#A2A2A2',
              // fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
              lineHeight: '25px',
              marginBottom: '20px'
            }}>Page link</div>
          </Row>
          <Row>
            <div style={{
              color: '#A2A2A2',
              // fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
              lineHeight: '25px',
              marginBottom: '20px'
            }}>Page link</div>
          </Row>
        </Col>
              
        <Col xs={6} md={3} style={{marginTop: '20px'}}>
          <Row>
            <div style={{
              color: '#3A3B3B',
              // fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
              lineHeight: '25px',
              marginBottom: '20px',
              textTransform: 'uppercase'
            }}>Product</div></Row>
          <Row>
            <div style={{
              color: '#A2A2A2',
              // fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
              lineHeight: '25px',
              marginBottom: '20px'
            }}>Page link</div>
          </Row>
          <Row>
            <div style={{
              color: '#A2A2A2',
              // fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
              lineHeight: '25px',
              marginBottom: '20px'
            }}>Page link</div>
          </Row>
          <Row>
            <div style={{
              color: '#A2A2A2',
              // fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
              lineHeight: '25px',
              marginBottom: '20px'
            }}>Page link</div>
          </Row>
          <Row>
            <div style={{
              color: '#A2A2A2',
              // fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
              lineHeight: '25px',
              marginBottom: '20px'
            }}>Page link</div>
          </Row>
        </Col>
              
        <Col xs={6} md={3} style={{marginTop: '20px'}}>
          <Row>
            <div style={{
              color: '#3A3B3B',
             // fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
              lineHeight: '25px',
              marginBottom: '20px',
              textTransform: 'uppercase'
            }}>Support</div></Row>
          <Row>
            <div style={{
              color: '#A2A2A2',
             // fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
              lineHeight: '25px',
              marginBottom: '20px'
            }}>Page link</div>
          </Row>
          <Row>
            <div style={{
              color: '#A2A2A2',
             // fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
              lineHeight: '25px',
              marginBottom: '20px'
            }}>Page link</div>
          </Row>
          <Row>
            <div style={{
              color: '#A2A2A2',
             // fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
              lineHeight: '25px',
              marginBottom: '20px'
            }}>Page link</div>
          </Row>
          <Row>
            <div style={{
              color: '#A2A2A2',
             // fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
              lineHeight: '25px',
              marginBottom: '20px'
            }}>Page link</div>
          </Row>
        </Col>
      </Row>

      <Row style={{
        backgroundColor: '#EEEFF3',
        color: '#3A3B3B',
        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
        fontWeight: '500',
        padding: '10px',
        paddingLeft: this.state.deviceIsMobile ? '10px' : '165px',
        paddingRight: this.state.deviceIsMobile ? '10px' : '165px',
        margin: 0
      }}>
        <Col xs={12} md={10}>
        © 2020 - {currentYear} - Quickteller Paypoint - All rights reserved.
        </Col>
        <Col xs={6} md={1}>
          <a href="/">Terms</a>
        </Col>
        <Col xs={6} md={1}>
          <a href="/">Privacy</a>
        </Col>
      </Row>
    </section>
  }
}
