import React from "react";
import { Accordion, Card, Row, Tab } from "react-bootstrap";
import MdSync from "react-ionicons/lib/MdSync";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  platformServiceClient,
  userManagementServiceClient,
} from "../../../../App";
import H1 from "../../../../components/h1";
import Text from "../../../../components/text";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../constants/api";
import IdentificationTypes from "../../../../fixtures/identification_types.json";
import UserManagement from "../../../../services/api/resources/user-management";
import BaseScene from "../../../base-scene";
import Layout from "./layout";
import RemapRequest from "./remap-pos/remap-request";
import { ResponseModal } from "./remap-pos/response";
import TerminalInformation from "./remap-pos/terminal-information";

export default class RemapScene extends BaseScene {
  location = window.location.href;
  path = "/pos-management";
  title = "Pos Management";

  constructor(props) {
    super(props);

    this.state = {
      activeTab: "pos-information",
      didErrorOccurWhileFetching: null,
      form: {
        identificationtypes: IdentificationTypes,
      },
      isLoading: false,
      loading: false,
      agentNumber: "",
      show: false,
      toggleTab: false,
      showTerminal: false,
      agentDetails: [],
      agentToMap: [],
    };

    this.userManagement = new UserManagement();
    this.handleClose = this.handleClose.bind(this);

    this.handleShow = this.handleShow.bind(this);
    this.handleCloseTerminal = this.handleCloseTerminal.bind(this);
    this.handleShowTerminal = this.handleShowTerminal.bind(this);
    this.notify = this.notify.bind(this);
    this.fetchAgentByTerminalId = this.fetchAgentByTerminalId.bind(this);
    this.fetchAgentByPhoneNumber = this.fetchAgentByPhoneNumber.bind(this);
    this.handleSubmitPosRequest = this.handleSubmitPosRequest.bind(this);
  }

  handleClose = () => this.setState({ show: false });
  handleShow = () => {
    this.setState({ show: true });
  };
  handleCloseTerminal = () => this.setState({ showTerminal: false });
  handleShowTerminal = () => {
    this.setState({ showTerminal: true });
  };

  notify = (message, state) => {
    state === "warning" && toast.warning(message);
    state === "success" && toast.success(message);
    state === "error" && toast.error(message);
  };

  fetchAgentByTerminalId = async (terminalId) => {
    if (terminalId) {
      this.setState({ toggleTab: false, agentToMap: [], loading: true });
      const { status, response } =
        await userManagementServiceClient.getAgregratorAgentByTerminalId(
          terminalId
        );
      this.setState({ loading: false });
      if (status === ERROR_STATUS) {
        this.notify(response.description, "error");
      } else if (status === SUCCESS_STATUS) {
        this.notify(response.description, "success");
        this.setState({ agentDetails: [response.data], toggleTab: true });
      }
    } else {
      this.notify("Please Enter a Terminal ID", "warning");
    }
  };

  /////////////// FETCH AGENT BY PHONE NUMBER ///////////
  fetchAgentByPhoneNumber = async (agentNumber) => {
    if (agentNumber) {
      this.setState({ isLoading: true });
      const { status, response } =
        await platformServiceClient.getAgregratorAgentByPhoneNumber(
          agentNumber
        );

      this.setState({ isLoading: false });
      if (status === ERROR_STATUS) {
        this.notify(response.description, "error");
      } else if (status === SUCCESS_STATUS) {
        this.notify(response.description, "success");
      } else {
        this.notify("An error occur, please try again later", "error");
      }
      this.setState({ agentToMap: response });
    } else {
      this.notify("Please Enter a Phone Number", "warning");
    }
  };

  //////////////POS REMAP REQUEST //////////////////
  handleSubmitPosRequest = async () => {
    const payload = {
      currentTerminalOwner: this.state.agentDetails[0].username,
      deviceId: this.state.agentDetails[0].deviceid,
      nextOwnerBusinessAddress:
        this.state.agentToMap.content[0].businessLocation.addressLine1,
      nextOwnerState: this.state.agentToMap.content[0].businessLocation.state,
      nextTerminalOwner: this.state.agentToMap.content[0].agentMobileNo,
      remapRequestType: "free",
      terminalName: this.state.agentDetails[0].deviceName,
      reasonForRemaping: "NA",
      unitPrice: 0,
    };

    this.setState({ loading: true });
    const { status, response } =
      await userManagementServiceClient.postAgregratorPosRequest(payload);
    this.setState({ loading: false });
    if (status === ERROR_STATUS) {
      this.notify(response.description, "error");
    } else if (status === SUCCESS_STATUS) {
      this.notify(response.description, "success");
      this.handleCloseTerminal();
      this.setState({ toggleTab: false, agentToMap: [] });
    } else {
      this.notify("An error occur, please try again later", "error");
    }
  };

  renderRefreshTransactionMessage() {
    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
          marginTop: "24px",
          width: "83vw",
        }}
      >
        <Text blue bold small>
          Oops!
        </Text>
        <Text mid>Something went wrong. Please, try again.</Text>
        <div
          style={{
            alignItems: "center",
            border: `1pt solid ${process.env.REACT_APP_BLUE_COLOUR}`,
            borderRadius: "4px",
            color: process.env.REACT_APP_BLUE_COLOUR,
            cursor: "pointer",
            display: "flex",
            fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            fontStyle: process.env.REACT_APP_MID_FONT_SIZE,
            fontWeight: "bold",
            height: "40px",
            justifyContent: "center",
            marginTop: "16px",
            // padding: '7.5px 10px 7.5px 10px',
            width: "160px",
          }}
          // onClick={() => props.disabled ? null : window.open(FUND_WALLET_URL, '_blank')}
          onClick={this.loadData}
        >
          <MdSync
            rotate={this.state.isLoading}
            color={process.env.REACT_APP_BLUE_COLOUR}
            fontSize={"24px"}
            style={{ marginRight: "10px" }}
          />
          {this.state.isLoading ? "Loading..." : "RETRY"}
        </div>
      </div>
    );
  }

  render() {
    const { didErrorOccurWhileFetching, isLoading, loading, showTerminal } =
      this.state;

    if (didErrorOccurWhileFetching) {
      return this.renderRefreshTransactionMessage();
    }
    const tabsContainer = (
      <Layout>
        <div className="postabcontainer">
          <Tab.Container
            id="left-tabs-example"
            activeKey={this.props.activeTab}
            onSelect={() => {}}
          >
            <Row xs={4} lg={4} style={{ padding: "0 0 10px 15px" }}>
              <H1>Remap POS</H1>
            </Row>

            <Accordion defaultActiveKey="0">
              <div>
                <Accordion.Toggle
                  style={{ cursor: "pointer", fontWeight: 700 }}
                  as={Card.Header}
                  eventKey="0"
                >
                  Device Information
                </Accordion.Toggle>

                <Accordion.Collapse eventKey="0">
                  <div>
                    {" "}
                    <TerminalInformation
                      handleShow={this.handleShow}
                      loading={loading}
                      notify={this.notify}
                      agentDetails={this.state.agentDetails}
                      handleToggleTab={this.handleToggleTab}
                      fetchAgentByTerminalId={this.fetchAgentByTerminalId}
                      getAgentByRequestDetails={this.getAgentByRequestDetails}
                    />
                  </div>
                </Accordion.Collapse>
              </div>

              <div>
                <Accordion.Toggle
                  style={{ cursor: "pointer", fontWeight: 700 }}
                  as={Card.Header}
                  eventKey="1"
                >
                  {this.state.toggleTab ? `Agent Information` : ""}
                </Accordion.Toggle>

                <Accordion.Collapse eventKey={this.state.toggleTab ? "0" : "1"}>
                  <div>
                    {this.state.toggleTab ? (
                      <RemapRequest
                        fetchAgentByPhoneNumber={this.fetchAgentByPhoneNumber}
                        agentToMap={this.state.agentToMap}
                        handleShowTerminal={this.handleShowTerminal}
                        isLoading={isLoading}
                      />
                    ) : null}
                  </div>
                </Accordion.Collapse>
              </div>
            </Accordion>
          </Tab.Container>
        </div>
      </Layout>
    );

    return (
      <div>
        {tabsContainer}
        <ResponseModal
          title={"Remap Request"}
          paragraph={"You are about to submit a request for a POS remap"}
          handleClose={this.handleCloseTerminal}
          show={showTerminal}
          loading={this.state.loading}
          notify={this.notify}
          handleSubmit={this.handleSubmitPosRequest}
        />
      </div>
    );
  }
}
