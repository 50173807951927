import React from 'react';

import { Button } from 'react-bootstrap';
const PrimaryButton = ({ children, onClick, disabled }) => {
  return (
    <Button
      disabled={disabled}
      style={{
        fontSize: '14px',
        borderRadius: '8px',
        width: '100%',
        height: '48px',
        margin: '30px 0',
        backgroundColor: '#00425f',
        color: 'white',
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default PrimaryButton;
