import React from 'react';
import ads from '../fixtures/side_banners';

const AD_DURATION = 10000;
let currentIndex = 0;

export default class Adboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ad: undefined,
      isRunning: true,
    };

    this.changeAd = this.changeAd.bind(this);
    
    this.changeAd();
  }

  componentDidMount() {
    this.setState({
      isRunning: true,
    });
    this.changeAd();
  }

  componentWillUnmount() {
    this.setState({
      isRunning: false,
    });
  }

  changeAd() {
    currentIndex += 1;
    this.setState({
      ad: ads[Math.ceil((currentIndex/2) % ads.length)],
    });

    if (this.state.isRunning) {
      setTimeout(this.changeAd, AD_DURATION);
    }
  }

  render() {
    if (!this.state.ad) {
      return <div />
    }

    return <aside style={{position: 'absolute', right: '0px', width: '15vw', height: '100%'}}>
      <a href={this.state.ad.url} target="_blank" rel="noopener noreferrer">
        <img src={this.state.ad.imageUrl} style={{width: '15vw', height: '100%'}} alt=''/>
      </a>
    </aside>
  }
}
