import { connect } from 'react-redux';
import { navigateTo } from '../../../../../../services/redux/actions/navigation'
import { updateLoading, dismissSuccessMessage, showSuccessMessage, dismissErrorMessage, showErrorMessage  } from '../../../../../../services/redux/actions/tunnel'
import UnloadCommissionScene from './scene'

function mapStateToProps(state) {
  return {
    pendingUrl: state.navigation.pendingUrl,
    isLoading: state.tunnel.isLoading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: pendingUrl => dispatch(navigateTo(pendingUrl)),
    dismissErrorMessage: () => dispatch(dismissErrorMessage()),
    showErrorMessage: message => dispatch(showErrorMessage(message)),
    dismissSuccessMessage: () => dispatch(dismissSuccessMessage()),
    showSuccessMessage: message => dispatch(showSuccessMessage(message)),
    updateLoading: isLoading => dispatch(updateLoading(isLoading))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnloadCommissionScene);
