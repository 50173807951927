import Requester from '../finch-requester';

const API_BASE_URL = process.env.REACT_APP_FINCH_MESSAGING_SERVICE_BASE_URL;

export default class MessagingService {
  constructor(props) {
    this.apiRequester = props ? props.apiRequester || new Requester({
      apiBaseUrl: API_BASE_URL
    }) : new Requester({
      apiBaseUrl: API_BASE_URL
    })
  }

  subscribe(phone, data) {
    return this.apiRequester.post({
      endpoint: `messaging/fcm/subscribe/${phone}`,
      body: data
    })
  }

  subscribeToPushNotification(phone, token) {
    return this.apiRequester.get({
      endpoint: `messaging/subscribe/${phone}`,
      args: {
        token
      }
    })
  }

  getAllMessages() {
    return this.apiRequester.get({
      endpoint: `notifications`
    })
  }

  getMessageById(id) {
    return this.apiRequester.get({
      endpoint: `notifications/${id}`
    })
  }

  markMessageAsRead(data) {
    return this.apiRequester.post({
      endpoint: `notifications/markAsRead`,
      body: data
    })
  }
  
  searchMessages(data) {
    const modifiedData = {
      pageNumber: data.pageNumber,
      pageSize: data.pageSize,
      filter: {
        ...(data.queryName != null ? { name: data.queryName} : {})
      },
      range: {
        ...(data.fromDate != null ? { start: data.fromDate, end: data.toDate} : {})
      },
      sortFeld: 'date',
      sortOrder: 'DESC'
    }
    return this.apiRequester.post({
      endpoint: `notifications/query`,
      body: modifiedData
    })
  }

  getMessagesByPhone(data, phone) {
    return this.apiRequester.get({
      endpoint: `notifications/query/${phone}`,
      args: {
        // data,
        pageNumber: data.pageNumber,
        pageSize: data.pageSize,
        sortField: 'date',
        sortOrder: 'DESC'
      }
    })
  }

  deleteMessage(data) {
    return this.apiRequester.post({
      endpoint: `notifications/clear`,
      body: data
    })
  }

}