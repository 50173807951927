import React, { useState } from "react";
import { Button, Form, FormControl } from "react-bootstrap";

const TerminalInformation = ({
  loading,
  agentDetails,
  fetchAgentByTerminalId,
}) => {
  const ptag = { color: "#111827", fontWeight: 400 };
  const spanTag = {
    fontSize: "14px",
    fontWeight: 400,
    color: "#6B7280",
  };

  const [terminalId, setTerminalId] = useState("");

  return (
    <div>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div style={{ display: "flex" }}>
          <div>
            <Form.Group style={{ padding: 0 }} controlId="Terminal-id">
              <Form.Label>POS Terminal ID</Form.Label>
              <FormControl
                type="text"
                placeholder="Enter Terminal ID"
                onChange={(e) => setTerminalId(e.target.value)}
                className="posInput"
              />
            </Form.Group>
          </div>
          <div>
            <Button
              className="posButton"
              onClick={() => fetchAgentByTerminalId(terminalId)}
            >
              {loading ? "Loading..." : "SUBMIT"}
            </Button>
          </div>
        </div>
      </Form>
      {agentDetails && agentDetails.length > 0 && (
        <>
          <h2
            style={{
              fontSize: "16px",
              fontWeight: "600",
              marginBottom: "15px",
              marginTop: "20px",
            }}
          >
            Agent Information
          </h2>

          <section className="posSection">
            <div className="posArticle">
              <div>
                <span style={spanTag}>Name</span>
                <p style={ptag}>
                  {agentDetails[0].firstName} {agentDetails[0].lastName}
                </p>
              </div>
              <div>
                <span style={spanTag}>Email Address</span>
                <p style={ptag}>{agentDetails[0].email}</p>
              </div>
              <div>
                <span style={spanTag}>Username</span>
                <p style={ptag}>{agentDetails[0].username}</p>
              </div>
            </div>
            <div className="posArticle">
              <div>
                <span style={spanTag}>Status</span>
                <p style={ptag}>{agentDetails[0].status}</p>
              </div>
              <div>
                <span style={spanTag}>Business Mobile</span>
                <p style={ptag}>{agentDetails[0].businessMobile}</p>
              </div>
              <div>
                <span style={spanTag}>Business Name</span>
                <p style={ptag}>{agentDetails[0].businessName}</p>
              </div>
            </div>
          </section>
          <h2
            style={{
              fontSize: "16px",
              fontWeight: "600",
              marginBottom: "15px",
              marginTop: "20px",
            }}
          >
            Teminal Information
          </h2>

          <section className="posSection">
            <div className="posArticle">
              <div>
                <span style={spanTag}>Device Name</span>
                <p style={ptag}>{agentDetails[0].deviceName}</p>
              </div>
              <div>
                <span style={spanTag}>Device Os</span>
                <p style={ptag}>{agentDetails[0].deviceOs}</p>
              </div>
            </div>
            <div className="posArticle">
              <div>
                <span style={spanTag}>Channel</span>
                <p style={ptag}>{agentDetails[0].channel}</p>
              </div>
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export default TerminalInformation;
