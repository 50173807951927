const ACTIVE = 'ACTIVE';
const DORMANT = 'DORMANT';
const INACTIVE = 'INACTIVE';
const NEW = 'NEW';
const RETIRED = 'RETIRED';
const SUSPENDED = 'SUSPENDED';

const DISABLED_STATUSES = [
  INACTIVE,
  RETIRED,
  DORMANT
];

const STATUSES = [
  null,
  ACTIVE,
  INACTIVE,
  RETIRED,
  DORMANT,
];

const DOMAIN_STATUSES = [
  null,
  ACTIVE,
  SUSPENDED,
  RETIRED,
  DORMANT,
  NEW
];


export default class UserSerializer {
  constructor(props) {
    Object.assign(this, props)

    this.isApplicant = props.domainCode === 'APP';
    this.isAgent = props.domainCode && props.domainCode.startsWith('AG');
    this.isAggregator = props.domainTypeId && props.domainTypeId === 3;
    
    this.canAccessDashboard = this.isApplicant || this.isAgent || this.isAggregator;
  }

  get isDomainDisabled() {
    return DISABLED_STATUSES.includes(this.domainStatusStr) || this.isApplicant;
  }

  get businessName() {
    return this.domainName;
  }

  get domainStatusStr() {
    return DOMAIN_STATUSES[this.domainStatus];
  }

  get isDisabled() {
    return this.isApplicant || !this.isEmailVerified || !this.isPhoneVerified;
  }

  get isDomainNew() {
    return this.domainStatusStr === NEW;
  }

  get statusStr() {
    return STATUSES[this.status]
  }

  asJson() {
    return {
      ...this.props,
    }
  }
}
