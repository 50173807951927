import React, { useEffect, useRef, useState } from "react";
import { BalanceCard } from "../components/balanceCard";
import {
  formatAmount,
  formatDate,
  formatDate2,
  formatWalletDate,
} from "../../../../../../utils/formatters";
import ArrowUp from "../../../../../../icons/arrowUp.png";
import ArrowDown from "../../../../../../icons/arrowDown.png";
import H1 from "../../../../../../components/h1";
import TopAgents from "./topAgents";
import CommissionPerfomance from "./commissionPerfomance";
import { navigateTo } from "../../../../../../services/redux/actions/navigation";
import {
  dismissErrorMessage,
  showErrorMessage,
  updateLoading,
} from "../../../../../../services/redux/actions/tunnel";
import Numeral from "numeral";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Overlay, Tooltip } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../../../constants/api";

import Transaction from "../../../../../../services/api/resources/transaction";
import CustomError from "../../../../../../components/error-component";
import { RenderScale } from "../../component/renderScale";
import { convertNgkToNgn } from "../../../../../../utils/converters/currencies";
import TransactionHistory from "../../../../../../services/api/resources/transaction-history";
const EarningsScreen = ({
  fetchEarningBalance,
  getEarningBalance,
  loading,
}) => {
  const transaction = new Transaction();

  const [selectedFilter, setSelectedFilter] = useState("This week");
  const [getBalance, setGetBalance] = useState();

  const [dateIntervalType, setDateIntervalType] = useState(2);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [error, setError] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [show, setShow] = useState(false);
  const target = useRef(null);

  const handleFilterClick = (filterName) => {
    setSelectedFilter(filterName);
    if (filterName === "This week") {
      setDateIntervalType(2);
    } else if (filterName === "This Month") {
      setDateIntervalType(3);
    } else if (filterName === "This Year") {
      setDateIntervalType(4);
    } else {
      setDateIntervalType();
    }
  };
  const filterStyle = (filterName) => {
    return {
      padding: ".5rem 1.5rem",
      backgroundColor: selectedFilter === filterName ? "#00425F" : "white",
      color:
        selectedFilter === filterName
          ? "white"
          : process.env.REACT_APP_BLUE_COLOUR,
      cursor: "pointer",
      border: "1px solid #E1E6ED",
    };
  };

  const getWalletBalance = async () => {
    setIsLoading(true);

    const { status, response } = await transaction.getWalletBalance();

    setIsLoading(false);
    if (status === ERROR_STATUS) {
      setError(true);
    } else if (status === SUCCESS_STATUS) {
      setError(false);
      setIsLoading(false);

      setGetBalance(response);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getWalletBalance();
  }, []);
  useEffect(() => {
    fetchEarningBalance(dateIntervalType, startDate, endDate);
  }, [endDate, dateIntervalType]);

  const history = useHistory();

  return (
    <section>
      <article className="filter-tabs-container">
        <div
          className="rounded-left"
          style={filterStyle("This week")}
          onClick={() => handleFilterClick("This week")}
        >
          This week
        </div>
        <div
          style={filterStyle("This Month")}
          onClick={() => handleFilterClick("This Month")}
        >
          This Month
        </div>
        <div
          style={filterStyle("This Year")}
          onClick={() => handleFilterClick("This Year")}
        >
          This Year
        </div>
        {/* <div
          style={filterStyle('All Time')}
          onClick={() => handleFilterClick('All Time')}
        >
          All Time
        </div> */}

        <div
          className="rounded-right"
          style={filterStyle("Custom Range")}
          ref={target}
          onClick={() => {
            handleFilterClick("Custom Range");
            setShow(!show);
          }}
        >
          Custom Range
        </div>
      </article>

      <Overlay
        rootClose={true}
        onHide={() => setShow(false)}
        target={target.current}
        show={show}
        placement="bottom"
      >
        {({
          placement: _placement,
          arrowProps: _arrowProps,
          show: _show,
          popper: _popper,

          hasDoneInitialMeasure: _hasDoneInitialMeasure,
          ...props
        }) => (
          <div
            {...props}
            style={{
              // position: 'absolute',
              marginTop: "10px",
              backgroundColor: "white",
              padding: "10px",
              borderRadius: "10px",
              boxShadow:
                "0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)",
              border: "1px solid var(#E1E6ED, #E1E6ED)",

              ...props.style,
            }}
          >
            <div
              style={{
                margin: "30px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <div>
                  <span
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    Start Date
                  </span>
                </div>
                <div>
                  <span
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    End Date
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "20px",
                  marginTop: "10px",
                }}
              >
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  inline
                  showYearDropdown
                />
                <DatePicker
                  selected={endDate}
                  onChange={(date) => {
                    setEndDate(date);
                    setShow(false);
                  }}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  inline
                  showYearDropdown
                />
              </div>
            </div>
          </div>
        )}
      </Overlay>
      <article className="agentType-container-card-commission">
        <BalanceCard
          loading={loading}
          title={"Earnings"}
          balance={`₦${formatAmount(
            convertNgkToNgn(getEarningBalance?.currentEarningValue)
          )}`}
          buttonContent={
            <RenderScale
              percentage={getEarningBalance?.earningVariancePercent || 0}
            />
          }
        />
        {/* <BalanceCard
          loading={isLoading}
          title={"Ledger Balance"}
          balance={`₦${formatAmount(
            convertNgkToNgn(
              +getBalance?.commissionWalletBalance +
                +getBalance?.unsettledWalletBalance
            )
          )}`}
        /> */}
        <BalanceCard
          loading={isLoading}
          title={"Total Earnings"}
          balance={`₦${formatAmount(
            convertNgkToNgn(getBalance?.commissionWalletBalance)
          )}`}
          buttonContent={
            <button
              title="Unload Commision"
              className="balance-card-button-container"
              onClick={() => {
                history.push({
                  pathname: "/agent/commissions/unload-commission",
                  state: getBalance?.commissionWalletBalance,
                });
              }}
            >
              Unload Commissions
            </button>
          }
        />
      </article>
      <article className="commission-section-container">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <H1
            style={{
              textAlign: "start",
            }}
          >
            Commission Performance
          </H1>
          <button
            style={{
              border: "1px solid #E1E6ED",
              borderRadius: "10px",
              padding: "10px 12px",
              background: "transparent",
              color: "grey",
            }}
            navigationFromCommission
            onClick={() => {
              localStorage.setItem("navigationFromCommission", true);
              history.push("/agent/dashboard");
            }}
            aria-label="view all Commisions"
            title="view all Commisions"
          >
            View all Commisions
          </button>
        </div>
        <CommissionPerfomance
          startDate={startDate}
          endDate={endDate}
          dateIntervalType={dateIntervalType}
          selectedFilter={selectedFilter.toLowerCase()}
        />
      </article>
      <article className="commission-section-container">
        <H1
          style={{
            textAlign: "start",
          }}
        >
          Top 5 Agents
        </H1>

        <TopAgents
          startDate={startDate}
          endDate={endDate}
          dateIntervalType={dateIntervalType}
        />
      </article>
    </section>
  );
};

export default EarningsScreen;
