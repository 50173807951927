import React from 'react'
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import BaseScene from '../../../base-scene';
import UpdatePasswordTab from './tabs/update-password';
import UpdateTransactionPinTab from './tabs/update-transaction-pin';
import UserManagement from '../../../../services/api/resources/user-management';
import { SUCCESS_STATUS } from '../../../../constants/api';

export default class SecuritySettingsScene extends BaseScene {

  location = window.location.href;
  path = '/security-settings';
  title = 'Security Settings';

  userMangement = new UserManagement();

  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'update-password',
      form: {
  
      }
    }
    this.changePassword = this.changePassword.bind(this);
  }
  

  async changePassword (form) {
    const accessToken = localStorage.getItem('auth_token');

    this.setState({
      isLoading: true
    });
    const changePasswordResponse = await this.userMangement.changePassword(form.password, form.newPassword, form.repeatNewPassword, accessToken);
    const {status, code, response} = changePasswordResponse;
    
    if(status === SUCCESS_STATUS) {
      this.setState({
        isLoading: false
      });
      this.props.showSuccessMessage("Password Changed Successfully");
      setTimeout(() => {
        this.props.dismissSuccessMessage()
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
      return;
    }

    this.setState({
      isLoading: false
    });
    this.props.showErrorMessage(JSON.stringify(response));
      setTimeout(() => {
        this.props.dismissErrorMessage()
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
      return;
  }

  updateFormField(params) {
    this.setState({
      form: {
        ...this.state.form,
        ...params
      }
    })
  }
  
  render () {
    const screenSensitiveStyle = this.state.deviceIsPC ? {
      padding: '30px'
    } : { paddingTop: '20px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '20px'}

    const tabContentScreenSensitiveStyle = this.state.deviceIsPC ? {
      padding: '15px',
      paddingTop: '0px',
      paddingBottom: '0px'
    } : {}

    const tabsContainer = <Tab.Container activeKey={this.state.activeTab}>
      <Row style={{margin: 0, padding: 0}}>
        {/* <Col xs={6} md={6} style={{margin: 0, padding: 0}}>
        <Nav.Item style={{borderBottom: `0.6px solid ${this.state.activeTab === 'update-transaction-pin' ? process.env.REACT_APP_BLUE_COLOUR : '#D2D2D3'}`, height: '70px', paddingTop: '15px', paddingBottom: '10px'}}>
            <Nav.Link eventKey="update-transaction-pin" onSelect={() => this.setState({'activeTab': 'update-transaction-pin'})} 
              style={{
                color: this.state.activeTab === 'update-transaction-pin' ? process.env.REACT_APP_BLUE_COLOUR : '#929293', 
                fontSize: process.env.REACT_APP_MID_FONT_SIZE, 
                fontWeight: 'normal',
                textAlign: 'center', 
                textTransform: 'uppercase'}}>
                {`01 Update Transaction Pin`}
            </Nav.Link>
          </Nav.Item>
        </Col> */}
        <Col xs={12} md={12} style={{margin: 0, padding: 0}}>
          <Nav.Item style={{borderBottom: `0.6px solid ${this.state.activeTab === 'update-password' ? process.env.REACT_APP_BLUE_COLOUR : '#D2D2D3'}`, height: '70px', paddingTop: '15px', paddingBottom: '10px'}}>
            <Nav.Link eventKey="update-password" onSelect={() => this.setState({'activeTab': 'update-password'})} 
              style={{
                color: this.state.activeTab === 'update-password' ? process.env.REACT_APP_BLUE_COLOUR : '#929293', 
                fontSize: process.env.REACT_APP_MID_FONT_SIZE, 
                fontWeight: 'normal',
                textAlign: 'center', 
                textTransform: 'uppercase'}}>{`01 Update Password`}</Nav.Link>
          </Nav.Item>
        </Col>
      </Row>

      <Row style={{margin: 0, padding: 0}}>
        <Tab.Content style={{width: '100%'}}>
          <Tab.Pane eventKey='update-password' style={{...tabContentScreenSensitiveStyle}}>
            <UpdatePasswordTab
              onSubmit={this.changePassword} 
              isLoading={this.state.isLoading}
            />
          </Tab.Pane>
          {/* <Tab.Pane eventKey='update-transaction-pin' style={{...tabContentScreenSensitiveStyle}}>
            <UpdateTransactionPinTab 
              onSubmit={() => {}} />
          </Tab.Pane> */}
        </Tab.Content>
      </Row>
    </Tab.Container>

    return <div style={{backgroundColor: 'white', borderRadius: '4px', margin: this.state.deviceIsPC ? '40px' : '10px', padding: '0px', paddingTop: 0, marginBottom: '40px', marginTop: '40px', width: this.state.deviceIsPC && '79vw', ...screenSensitiveStyle}}>
      {tabsContainer}
    </div>
  }
}