import React, { useState } from "react";
import "./style.css";
import { arrowBackIcon, passengerIcon } from "../../../../../../icons";

const PassengerDetails = ({ setScreen }) => {
  const [passengerData, setPassengerData] = useState({
    firstName: "",
    lastName: "",
    gender: "Female",
    phoneNumber: "",
    nextOfKin: {
      usePrevious: true,
      firstName: "David",
      lastName: "Ashun",
      phoneNumber: "090 2343 4567",
    },
  });


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPassengerData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleNextOfKinChange = (e) => {
    const { name, value } = e.target;
    setPassengerData((prev) => ({
      ...prev,
      nextOfKin: {
        ...prev.nextOfKin,
        [name]: value,
      },
    }));
  };

  // Handle checkbox toggle
  const handleUsePreviousToggle = (e) => {
    const isChecked = e.target?.checked; // Ensure e.target exists before accessing properties

    setPassengerData((prev) => ({
      ...prev,
      nextOfKin: {
        ...prev.nextOfKin,
        usePrevious: isChecked,
        firstName: isChecked ? "David" : "",
        lastName: isChecked ? "Ashun" : "",
        phoneNumber: isChecked ? "090 2343 4567" : "",
      },
    }));
  };

  return (
    <div className=" mx-auto rounded-lg shadow-md container">
      <button className="flex items-center text-blue-600 mb-4 font-medium back-button">
        {arrowBackIcon()} Back
      </button>

      <div className="container-header"> <p>Passenger Details</p>
        <p className="container-header--inner">
          Who will you be traveling with?
        </p>
        <span className="title">Enter passenger details</span>
      </div>

      <div className="container--inner">
        <div className="passenger-details">
          <div className="">
            <div className="grid gap-4">
              {[
                { name: "Onyekachi Abraham", number: "0818000456" },
                { name: "Folashade Adeyanju", number: "0818000456" },
                { name: "Ifeoma Abraham", number: "0818000456" },
              ].map((passenger, index) => (
                <div
                  key={index}
                  className="passenger-details-info">
                  <div>
                    <span className="text-blue-500 text-xl">{passengerIcon()}</span>
                  </div>
                  <div>
                    <p className="text-gray-500 text-sm">Passenger 2</p>
                    <p className="text-gray-500 text-sm">Adult</p>
                  </div>
                  <div>
                    <p className="font-semibold text-base">{passenger.name}</p>
                    <p className="text-gray-500 text-sm">{passenger.number}</p>
                  </div>
                  <button className="view-button">View</button>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className=" p-6 shadow-md passenger-form">
          <p className={{ color: "#353F50", fontSize: "16px", fontWeight: "600" }}>Passenger (4 of 4)</p>
          <form>
            <div className="passenger-form--inner">
              <div className="mb-4">
                <label className="title">First Name</label>
                <input
                  type="text"
                  className="input"
                  name="firstName"
                  value={passengerData.firstName}
                  onChange={handleInputChange}
                  placeholder="First Name"
                />
              </div>
              <div className="mb-4">
                <label className="title">Last Name</label>
                <input
                  className="input"
                  type="text"
                  name="lastName"
                  value={passengerData.lastName}
                  onChange={handleInputChange}
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="title">Gender</label>
              <div className="flex space-between gap-4">
                <label className="flex items-center gap-2">
                  <input type="radio"
                    name="gender"
                    value="Male"
                    checked={passengerData.gender === "Male"}
                    onChange={handleInputChange} />
                  Male
                </label>
                <label className="flex items-center gap-2">
                  <input type="radio" name="gender" value="Female"
                    checked={passengerData.gender === "Female"}
                    onChange={handleInputChange} />
                  Female
                </label>
              </div>
            </div>
            <div className="mb-4">
              <label className="title">Phone Number</label>
              <input
                type="tel"
                className="phone-input"
                name="phoneNumber"
                value={passengerData.phoneNumber}
                onChange={handleInputChange}
                placeholder="Phone Number"
              />
            </div>
            <div className="mb-4">
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={passengerData.nextOfKin.usePrevious}
                  onChange={handleUsePreviousToggle}
                />
                <span>Add Next of Kin from previous info</span>
              </label>
            </div>

            {/* {!passengerData.nextOfKin.usePrevious && ( */}
            <div className="mb-4">
              <div className="passenger-form--inner">
                <div className="space-y-2">
                  <label className="title">Next of Kin First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    value={passengerData.nextOfKin.firstName}
                    onChange={handleNextOfKinChange}
                    className="input"
                    disabled={passengerData.nextOfKin.usePrevious}
                  />
                </div>

                <div className="mb-4">
                  <label className="title">Next of Kin Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    value={passengerData.nextOfKin.lastName}
                    onChange={handleNextOfKinChange}
                    className="input"
                    disabled={passengerData.nextOfKin.usePrevious}
                  />
                </div>
              </div>

              <div className="mb-4">
                <label className="title">Next of Kin Phone Number</label>
                <input
                  type="tel"
                  name="phoneNumber"
                  value={passengerData.nextOfKin.phoneNumber}
                  onChange={handleNextOfKinChange}
                  className="phone-input"
                  disabled={passengerData.nextOfKin.usePrevious}
                />
              </div>
            </div>
            {/* )} */}
            <button className="addButton" onClick={() => setScreen(3)} >
              Add
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PassengerDetails;
