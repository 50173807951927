import React from 'react';
import { Bar } from 'react-chartjs-2';

export default props => <Bar 
  height="500px" 
  style={{
    fontWeight: 'normal', 
    width: '100px'
  }} 
  options={{
    aspectRatio: 1,
    bar: {
      borderColor: 'red',
      borderWidth: 0
    },
    showLines: true,
    spanGaps: true,
    maintainAspectRatio: false,
    legend: {
      align: 'start',
      fullWidth: true,
      labels: {
        boxWidth: 12,
        fontSize: 12,
        padding: 12,
      },
      position: 'top',
    },
    responsive: true,
    scales: {
      xAxes: [{
        gridLines: {
          display: false
        }
      }],
      yAxes: [{
        ticks: {
          callback: function(value) {
            return `₦${value}`
          } 
        },
        gridLines: {
          lineWidth: 0.6
        }
      }]
    }
  }}
  data={props.data} 
  />
