import React, { useState } from 'react';
import { Button, Col, Form, FormControl, Nav, Row, Tab } from 'react-bootstrap';
import MdCheckmark from 'react-ionicons/lib/MdCheckmark';
import qs from 'qs';
import MdSync from 'react-ionicons/lib/MdSync';
import { ToastContainer } from 'react-toastify';
import { userManagementServiceClient } from '../../../../App';
import { notify } from '../../../../components/extras';
import { ERROR_STATUS, SUCCESS_STATUS } from '../../../../constants/api';
import Layout from './layout';

const HandleSubmitComponent = ({
  fetchPosRequestConfirmation,
  isChecked,
  isLoading,
  handleOnChange,
  currentId,
  id,
  requestDetails,
}) => {
  return (
    <>
      {/* {requestDetails.workflowType === 'pos_request' && ( */}
      <>
        {requestDetails.deliveryStatus === 'delivered' || (
          <div
            onClick={fetchPosRequestConfirmation}
            style={{
              display: 'inline',
              backgroundColor: '#EEF2F4',
              padding: '8px',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
            title='Confirm'
          >
            {isLoading && id === currentId ? (
              <MdSync color={'grey'} fontSize='20pt' rotate={isLoading} />
            ) : (
              <MdCheckmark color={'green'} fontSize='20pt' />
            )}
          </div>
        )}
      </>
      {/* )} */}
    </>
  );
};

const DelieveryNote = () => {
  const [activeTab, setActiveTab] = useState('Request Confirmation');
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [value, setValue] = useState('');
  const [requestDetails, setRequestDetails] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [currentId, setCurrentId] = useState('');

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  // /////////FETCH REQUEST DETAILS ////////
  const fetchRequestDetailsByRequestId = async (
    requestId,
    checkErrorMsg = true
  ) => {
    setRequestDetails(null);
    if (requestId) {
      setLoading(true);
      const { status, response } =
        await userManagementServiceClient.getPosRequestDetails(requestId);
      setLoading(false);
      if (status === ERROR_STATUS) {
        checkErrorMsg && notify(response.description, 'error');
      } else if (status === SUCCESS_STATUS) {
        setRequestDetails(response.data);
      }
    }
  };

  ///Confirm All Request////
  // const confirmPosRequest = async (value) => {
  //   if (value) {
  //     if (requestDetails[0].workflowType === 'pos_request') {
  //       setConfirmLoading(true);
  //       const { status, response } =
  //         await userManagementServiceClient.putConfirmPosRequest(value);
  //       setConfirmLoading(false);
  //       if (status === ERROR_STATUS) {
  //         notify(response.description, 'error');
  //       } else if (status === SUCCESS_STATUS) {
  //         notify(response.description, 'success');
  //         setRequestDetails(null);
  //         setValue('');
  //       }
  //     }
  //   }
  // };

  ////////Confirm individual request///////
  const fetchPosRequestConfirmation = async (posRequestStockId) => {
    setCurrentId(posRequestStockId);
    if (value) {
      if (requestDetails.workflowType === 'pos_remap_request') {
        setIsLoading(true);

        const { status, response } =
          await userManagementServiceClient.putPosRemapRequestConfirmation(
            value
          );
        setIsLoading(false);
        if (status === ERROR_STATUS) {
          notify(response.description, 'error');
        } else if (status === SUCCESS_STATUS) {
          notify(response.description, 'success');
        }
      }
      //  else if (requestDetails[0].workflowType === 'pos_request') {
      //   setIsLoading(true);
      //   const { status, response } =
      //     await userManagementServiceClient.putPosRequestConfirmation(
      //       posRequestStockId
      //     );
      //   setIsLoading(false);
      //   if (status === ERROR_STATUS) {
      //     notify(response.description, 'error');
      //   } else if (status === SUCCESS_STATUS) {
      //     notify(response.description, 'success');
      //     fetchRequestDetailsByRequestId(value, false);
      //   }
      // }
    } else {
      notify('Please Enter a Request ID', 'warning');
    }
  };

  return (
    <Layout>
      <div className='reciept_confirmation'>
        <h3
          style={{
            color: '#111827',
            fontSize: '22px',
            fontWeight: 'bold',
            marginBottom: '25px',
          }}
        >
          POS Remap Confirmation
        </h3>

        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div style={{ display: 'flex', width: '100%' }}>
            <div>
              <Form.Group
                style={{
                  padding: 0,
                }}
                controlId='Terminal-id'
              >
                <Form.Label> POS Request ID</Form.Label>

                <FormControl
                  type='text'
                  value={value}
                  className='posInput'
                  placeholder='Enter Request ID'
                  onChange={(event) => {
                    setValue(event.target.value.trim());
                  }}
                />
              </Form.Group>
            </div>
            <div>
              <Button
                className='posButton'
                disabled={!value}
                style={{
                  backgroundColor: '#00B8DE',

                  marginLeft: '15px',

                  alignItems: 'center',
                }}
                onClick={() => fetchRequestDetailsByRequestId(value)}
              >
                {loading ? 'Loading...' : 'SEARCH'}
              </Button>
            </div>
          </div>
        </Form>

        {requestDetails &&
          requestDetails.workflowType === 'pos_remap_request' && (
            <>
              <h2
                style={{
                  fontSize: '16px',
                  fontWeight: '600',
                  paddingTop: '25px',
                  paddingBottom: '25px',
                }}
              >
                Terminal Information
              </h2>

              <section className='pos-receipt-section'>
                <div className='posArticle'>
                  <div>
                    <span>Name</span>
                    <p>{requestDetails.nextOwnerName}</p>
                  </div>
                  <div>
                    <span>Phone Number</span>
                    <p>{requestDetails.nextTerminalOwner}</p>
                  </div>
                </div>
                <div className='posArticle'>
                  <div>
                    <span>Terminal Name</span>
                    <p>{requestDetails.terminalName}</p>
                  </div>
                  <HandleSubmitComponent
                    fetchPosRequestConfirmation={fetchPosRequestConfirmation}
                    isChecked={isChecked}
                    isLoading={isLoading}
                    requestDetails={requestDetails}
                    handleOnChange={handleOnChange}
                  />
                </div>
              </section>
            </>
          )}
        {/* {requestDetails && requestDetails[0].workflowType === 'pos_request' && (
          <>
            <h2
              style={{
                fontSize: '16px',
                fontWeight: '600',
                paddingTop: '25px',
                paddingBottom: '25px',
              }}
            >
              Request Information
            </h2>
            {requestDetails.map((item, index) => {
              const { terminalModel, agentUsername, initiatorUsername } = item;
              return (
                <>
                  <section
                    key={index}
                    style={{ display: 'flex' }}
                    className='pos-receipt-section'
                  >
                    <div className='posArticle'>
                      <div>
                        <span style={{ fontSize: '14px', color: 'grey' }}>
                          Terminal Model
                        </span>
                        <p>{terminalModel}</p>
                      </div>

                      <div>
                        <span style={{ fontSize: '14px', color: 'grey' }}>
                          Agent Username
                        </span>
                        <p>{agentUsername}</p>
                      </div>
                    </div>
                    <div className='posArticle'>
                      <div>
                        <span style={{ fontSize: '14px', color: 'grey' }}>
                          Initiator Username
                        </span>
                        <p>{initiatorUsername}</p>
                        <HandleSubmitComponent
                          id={item.posRequestStockId}
                          fetchPosRequestConfirmation={() =>
                            fetchPosRequestConfirmation(item.posRequestStockId)
                          }
                          currentId={currentId}
                          isChecked={isChecked}
                          isLoading={isLoading}
                          handleOnChange={handleOnChange}
                          requestDetails={requestDetails}
                        />
                      </div>
                    </div>
                  </section>
                  <hr />
                </>
              );
            })}

            {requestDetails &&
              requestDetails[0].workflowType === 'pos_request' &&
              requestDetails.length > 1 && (
                <>
                  <div title='Confirm all the request'>
                    <Button
                      className='posButton'
                      disabled={!value}
                      style={{
                        backgroundColor: '#00B8DE',
                        width: '220px',
                        alignItems: 'center',
                      }}
                      onClick={() => confirmPosRequest(value)}
                    >
                      {confirmLoading ? 'Loading...' : 'Confirm All'}
                    </Button>
                  </div>
                </>
              )}
          </>
        )} */}
        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='light'
        />
      </div>
    </Layout>
  );
};

export default DelieveryNote;
