import React from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import Text from "./text";
import CountriesStatesLgas from "../fixtures/countries_states_lgas";
import IdentificationTypes from "../fixtures/identification_types";
import Months from "../fixtures/months";
// import Thumbnail from './thumbnail';
// import Thumbnail2 from './thumbnail-2';

function getCountry(countryId) { 
  return CountriesStatesLgas.find((value) => value.id === countryId) || {};
}

function getIdentificationType(identificationTypeId) {
  return IdentificationTypes.find((value) => value.id === identificationTypeId);
}

function getState(country, stateId) {
  return  country.states.find((value) => value.id  === parseInt(stateId)) || {};
}

function getLga(state, lgaId) {
  return state.lgas ? state.lgas.find((value) => value.id === parseInt(lgaId)) : {};
}

export default (props) => {
  let { personalDetailsForm, businessDetailsForm, nextOfKinDetailsForm } =
    props;

  return (
    <Accordion
      defaultActiveKey="0"
      style={{
        height: "65vh",
        overflowY: "auto",
      }}
    >
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Row} variant="link" eventKey="0">
            <Text
              bold
              style={{
                fontSize: process.env.REACT_APP_BIG_FONT_SIZE,
                padding: "10px",
              }}
            >
              Personal Details
            </Text>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <Row>
              <Col
                xs={12}
                md={12}
                style={{ margin: 0, padding: 0, marginTop: "10px" }}
              >
                <Text
                  bold
                  style={{
                    fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                    padding: "10px",
                  }}
                >
                  Contact Information
                </Text>
              </Col>
              <Col xs={12} md={6} style={{ marginBottom: 10 }}>
                <Text bold mid>
                  First Name:
                </Text>
                <Text mid>{personalDetailsForm.firstName}</Text>
              </Col>
              <Col xs={12} md={6} style={{ marginBottom: 10 }}>
                <Text bold mid>
                  Middle Name:
                </Text>
                <Text mid>{personalDetailsForm.middleName}</Text>
              </Col>
              <Col xs={12} md={6} style={{ marginBottom: 10 }}>
                <Text bold mid>
                  Last Name:
                </Text>
                <Text mid>{personalDetailsForm.lastName}</Text>
              </Col>
              <Col xs={12} md={6} style={{ marginBottom: 10 }}>
                <Text bold mid>
                  Phone Number:
                </Text>
                <Text mid>{personalDetailsForm.phone}</Text>
              </Col>
              <Col xs={12} md={6} style={{ marginBottom: 10 }}>
                <Text bold mid>
                  Email Address:
                </Text>
                <Text mid>{personalDetailsForm.email}</Text>
              </Col>
              <Col hidden xs={12} md={6} style={{ marginBottom: 10 }}>
                <Text bold mid>
                  Super Agent:
                </Text>
                <Text mid>{personalDetailsForm.referralCode}</Text>
              </Col>
            </Row>

            <Row>
              <Col
                xs={12}
                md={12}
                style={{ margin: 0, padding: 0, marginTop: "10px" }}
              >
                <Text
                  bold
                  style={{
                    fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                    padding: "10px",
                  }}
                >
                  Residential Information
                </Text>
              </Col>
              <Col xs={12} md={6} style={{ marginBottom: "10px" }}>
                <Text bold mid>
                  Country:
                </Text>
                <Text mid>
                  {getCountry(personalDetailsForm.nationality).name}
                </Text>
              </Col>
              <Col xs={12} md={6} style={{ marginBottom: "10px" }}>
                <Text bold mid>
                  State:
                </Text>
                <Text mid>
                  {
                    getState(
                      getCountry(personalDetailsForm.nationality),
                      personalDetailsForm.state
                    ).name
                  }
                </Text>
              </Col>
              <Col xs={12} md={6} style={{ marginBottom: "10px" }}>
                <Text bold mid>
                  LGA:
                </Text>
                <Text mid>
                  {
                    getLga(
                      getState(
                        getCountry(personalDetailsForm.nationality),
                        personalDetailsForm.state
                      ),
                      personalDetailsForm.lga
                    ).name
                  }
                </Text>
              </Col>
              <Col xs={12} md={6} style={{ marginBottom: "10px" }}>
                <Text bold mid>
                  Closest Landmark:
                </Text>
                <Text mid>{personalDetailsForm.closestLandmark}</Text>
              </Col>
              <Col xs={12} md={6} style={{ marginBottom: "10px" }}>
                <Text bold mid>
                  Address:
                </Text>
                <Text mid>{personalDetailsForm.address}</Text>
              </Col>
            </Row>

            <Row>
              <Col
                xs={12}
                md={12}
                style={{ margin: 0, padding: 0, marginTop: "10px" }}
              >
                <Text
                  bold
                  style={{
                    fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                    padding: "10px",
                  }}
                >
                  Personal Information
                </Text>
              </Col>
              <Col xs={12} md={6} style={{ marginBottom: "10px" }}>
                <Text bold mid>
                  Date of Birth:
                </Text>
                <Text mid>{`${personalDetailsForm.dateOfBirth.getDate()} ${
                  Months[personalDetailsForm.dateOfBirth.getMonth()]
                } ${personalDetailsForm.dateOfBirth.getFullYear()}`}</Text>
              </Col>
              <Col xs={12} md={6} style={{ marginBottom: "10px" }}>
                <Text bold mid>
                  Gender:
                </Text>
                <Text mid>{personalDetailsForm.gender}</Text>
              </Col>
              <Col xs={12} md={6} style={{ marginBottom: "10px" }}>
                <Text bold mid>
                  Place of Birth:
                </Text>
                <Text mid>{personalDetailsForm.placeOfBirth}</Text>
              </Col>
              <Col xs={12} md={6} style={{ marginBottom: "10px" }}>
                <Text bold mid>
                  ID Type:
                </Text>
                <Text mid>
                  {
                    getIdentificationType(
                      personalDetailsForm.identificationType
                    ).name
                  }
                </Text>
              </Col>
              <Col xs={12} md={6} style={{ marginBottom: "10px" }}>
                <Text bold mid>
                  ID Number:
                </Text>
                <Text mid>{personalDetailsForm.identificationNumber}</Text>
              </Col>
              <Col xs={12} md={6} style={{ marginBottom: "10px" }}>
                <Text bold mid>
                  Nationality:
                </Text>
                <Text mid>
                  {getCountry(personalDetailsForm.nationality).name}
                </Text>
              </Col>
              <Col xs={12} md={6} style={{ marginBottom: "10px" }}>
                <Text bold mid>
                  BVN:
                </Text>
                <Text mid>{personalDetailsForm.bvn}</Text>
              </Col>
              <Col xs={12} md={6} style={{ marginBottom: "10px" }}>
                <Text bold mid>
                  Mother's Maiden Name:
                </Text>
                <Text mid>{personalDetailsForm.mothersMaidenName}</Text>
              </Col>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Row} variant="link" eventKey="1">
            <Text
              bold
              style={{
                fontSize: process.env.REACT_APP_BIG_FONT_SIZE,
                padding: "10px",
              }}
            >
              Business Details
            </Text>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            <Row>
              <Col
                xs={12}
                md={12}
                style={{ margin: 0, padding: 0, marginTop: "10px" }}
              >
                <Text
                  bold
                  style={{
                    fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                    padding: "10px",
                  }}
                >
                  Business Information
                </Text>
              </Col>
              <Col xs={12} md={6} style={{ marginBottom: "10px" }}>
                <Text bold mid>
                  Business Name:
                </Text>
                <Text mid>{businessDetailsForm.businessName}</Text>
              </Col>
              <Col xs={12} md={6} style={{ marginBottom: "10px" }}>
                <Text bold mid>
                  Company Registration No:
                </Text>
                <Text mid>{businessDetailsForm.companyRegistrationNumber}</Text>
              </Col>
              <Col xs={12} md={6} style={{ marginBottom: "10px" }}>
                <Text bold mid>
                  Phone Number:
                </Text>
                <Text mid>{businessDetailsForm.phone}</Text>
              </Col>
              <Col xs={12} md={6} style={{ marginBottom: "10px" }}>
                <Text bold mid>
                  Business Type:
                </Text>
                <Text mid>{businessDetailsForm.businessType}</Text>
              </Col>
              <Col xs={12} md={6} style={{ marginBottom: "10px" }}>
                <Text bold mid>
                  Bank Name:
                </Text>
                <Text mid>{businessDetailsForm.bankName}</Text>
              </Col>
              <Col xs={12} md={6} style={{ marginBottom: "10px" }}>
                <Text bold mid>
                  Account Number:
                </Text>
                <Text mid>{businessDetailsForm.accountNumber}</Text>
              </Col>
              <Col xs={12} md={6} style={{ marginBottom: "10px" }}>
                <Text bold mid>
                  State:
                </Text>
                <Text mid>
                  {
                    getState(CountriesStatesLgas[0], businessDetailsForm.state)
                      .name
                  }
                </Text>
              </Col>
              <Col xs={12} md={6} style={{ marginBottom: "10px" }}>
                <Text bold mid>
                  LGA:
                </Text>
                <Text mid>
                  {
                    getLga(
                      getState(
                        CountriesStatesLgas[0],
                        businessDetailsForm.state
                      ),
                      businessDetailsForm.lga
                    ).name
                  }
                </Text>
              </Col>
              <Col xs={12} md={6} style={{ marginBottom: "10px" }}>
                <Text bold mid>
                  Business Address:
                </Text>
                <Text mid>{businessDetailsForm.businessAddress}</Text>
              </Col>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Row} variant="link" eventKey="2">
            <Text
              bold
              style={{
                fontSize: process.env.REACT_APP_BIG_FONT_SIZE,
                padding: "10px",
              }}
            >
              Next of Kin Details
            </Text>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="2">
          <Card.Body>
            <Row>
              <Col
                xs={12}
                md={12}
                style={{ margin: 0, padding: 0, marginTop: "10px" }}
              >
                <Text
                  bold
                  style={{
                    fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                    padding: "10px",
                  }}
                >
                  Personal Information
                </Text>
              </Col>
              <Col xs={12} md={6} style={{ marginBottom: "10px" }}>
                <Text bold mid>
                  First Name:
                </Text>
                <Text mid>{nextOfKinDetailsForm.firstName}</Text>
              </Col>
              <Col xs={12} md={6} style={{ marginBottom: "10px" }}>
                <Text bold mid>
                  Last Name:
                </Text>
                <Text mid>{nextOfKinDetailsForm.lastName}</Text>
              </Col>
              <Col xs={12} md={6} style={{ marginBottom: "10px" }}>
                <Text bold mid>
                  Phone Number:
                </Text>
                <Text mid>{nextOfKinDetailsForm.phone}</Text>
              </Col>
              <Col xs={12} md={6} style={{ marginBottom: "10px" }}>
                <Text bold mid>
                  Gender:
                </Text>
                <Text mid>{nextOfKinDetailsForm.gender}</Text>
              </Col>
              <Col xs={12} md={6} style={{ marginBottom: "10px" }}>
                <Text bold mid>
                  Relationship:
                </Text>
                <Text mid>{nextOfKinDetailsForm.relationship}</Text>
              </Col>
              <Col xs={12} md={6} style={{ marginBottom: "10px" }}>
                <Text bold mid>
                  Address:
                </Text>
                <Text mid>{nextOfKinDetailsForm.address}</Text>
              </Col>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );

  // return <section>
  //   <div>
  //     <H1>Personal Details</H1>
  //     <Row>
  //       <Col xs={12} md={6}>
  //       <Text>First Name:</Text>
  //       <Text>Tomisin</Text>
  //       </Col>
  //       <Col xs={12} md={6}>

  //       </Col>
  //     </Row>
  //   </div>
  //   <div>
  //     <H1>Business Details</H1>
  //   </div>
  //   <div>
  //     <H1>Next of Kin Details</H1>
  //   </div>
  // </section>
};
