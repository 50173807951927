import React from "react";
import { Doughnut } from "react-chartjs-2";
import Button from "../../../../../components/button";

export default class ServicesReportGraph extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Doughnut
          height="350px"
          style={{ marginBottom: "200px" }}
          options={{
            tooltips: {
              callbacks: {
                label: function (tooltipItem, data) {
                  return (
                    data["labels"][tooltipItem["index"]] +
                    ": " +
                    data["datasets"][0]["data"][tooltipItem["index"]] +
                    "%"
                  );
                },
              },
            },
          }}
          data={this.props.data}
        />

        <center>
          <Button
            outline="true"
            style={{
              fontSize: "12px",
              borderColor: "#3A6D84",
              color: "#3A6D84",
              marginTop: "10px",
            }}
            onClick={() => this.props.navigateTo("/my-reports")}
          >
            View all reports
          </Button>
        </center>
      </React.Fragment>
    );
  }
}
