import Requester from '../finch-requester';

const API_BASE_URL = process.env.REACT_APP_TRANSACTION_HISTORY_API_BASE_URL;

export default class TransactionHistory {
  constructor(props) {
    this.apiRequester = props
      ? props.apiRequester ||
        new Requester({
          apiBaseUrl: API_BASE_URL,
        })
      : new Requester({
          apiBaseUrl: API_BASE_URL,
        });
  }

  getCommissionHistory() {
    // pageNum,
    // pageSize,
    // startDate,
    // endDate,
    // transactionTypeInt,
    // event,
    // agentId
    return this.apiRequester.getIgnore401({
      endpoint: 'v2/finch-transaction-history-service/commissions/',
      // args: {
      //   pageNum,
      //   pageSize,
      //   start: startDate,
      //   end: endDate,
      //   transactionTypeInt,
      //   event,
      //   agentId,
      // },
    });
  }

  getTransactionsByRef(transactionRef) {
    return this.apiRequester.get({
      endpoint: `v2/finch-transaction-history-service/transactions/${transactionRef}`,
    });
  }

  getInflowOutflow(payload) {
    return this.apiRequester.get({
      endpoint:
        'v3/finch-transaction-history-service/transactions/agentStatistics',
      args: payload,
    });
  }
  getTopPerformingAgent(payload) {
    return this.apiRequester.get({
      endpoint:
        'v3/finch-transaction-history-service/commissions/earnings/top-contributors',
      args: payload,
    });
  }
  getWithdrawalReport(payload) {
    return this.apiRequester.get({
      endpoint: 'v3/finch-transaction-history-service/commissions/withdrawals',
      args: payload,
    });
  }
  getEarningPerformance(payload) {
    return this.apiRequester.get({
      endpoint:
        'v3/finch-transaction-history-service/commissions/earnings/performance',
      args: payload,
    });
  }
  getEarnings(payload) {
    return this.apiRequester.get({
      endpoint: 'v3/finch-transaction-history-service/commissions/stats',
      args: payload,
    });
  }
  getWithdrawalsAggregator(payload) {
    return this.apiRequester.get({
      endpoint:
        'v3/finch-transaction-history-service/commissions/withdrawals/stats',
      args: payload,
    });
  }

  getTransactions(domainCode, pageNum, pageSize) {
    return this.apiRequester.getIgnore401({
      endpoint: 'v2/finch-transaction-history-service/transactions/search',
      args: {
        domainCode,
        pageNum: pageNum,
        pageSize: pageSize,
      },
    });
  }
  getTransactionsForSearch(payload) {
    return this.apiRequester.get({
      endpoint: 'v3/finch-transaction-history-service/transactions/search',
      args: payload,
    });
  }

  getTransactionsByStatus(statusCodeInt, pageSize) {
    return this.apiRequester.get({
      endpoint: 'v2/finch-transaction-history-service/transactions/search',
      args: {
        statusCodeInt: statusCodeInt,
        pageSize: pageSize,
      },
    });
  }

  getTableTransactionsByStatus(
    startDate,
    endDate,
    statusCodeInt,
    transactionTypeInt,
    pageSize,
    pageNum,
    domainCode
  ) {
    return this.apiRequester.get({
      endpoint: 'v2/finch-transaction-history-service/transactions/search',
      args: {
        startDate: startDate,
        endDate: endDate,
        statusCodeInt: statusCodeInt,
        transactionTypeInt: transactionTypeInt,
        pageSize: pageSize,
        pageNum: pageNum,
        domainCode,
      },
    });
  }

  getTransactionsByType(transactionTypeInt, pageSize) {
    return this.apiRequester.get({
      endpoint: 'v2/finch-transaction-history-service/transactions/search',
      args: {
        transactionTypeInt: transactionTypeInt,
        pageSize: pageSize,
      },
    });
  }

  getTableTransactionsByType(
    startDate,
    endDate,
    transactionTypeInt,
    statusCodeInt,
    pageSize,
    pageNum,
    domainCode
  ) {
    return this.apiRequester.get({
      endpoint: 'v2/finch-transaction-history-service/transactions/search',
      args: {
        startDate: startDate,
        endDate: endDate,
        transactionTypeInt: transactionTypeInt,
        statusCodeInt: statusCodeInt,
        pageSize: pageSize,
        pageNum: pageNum,
        domainCode,
      },
    });
  }

  getTransactionsByDateRange(startDate, endDate, pageSize, pageNum) {
    return this.apiRequester.get({
      endpoint: 'v2/finch-transaction-history-service/transactions/search',
      args: {
        startDate: startDate,
        endDate: endDate,
        pageSize: pageSize,
        pageNum: pageNum,
      },
    });
  }

  getTableTransactionsByDateRange(
    startDate,
    endDate,
    transactionTypeInt,
    statusCodeInt,
    pageSize,
    pageNum,
    domainCode
  ) {
    return this.apiRequester.get({
      endpoint: 'v2/finch-transaction-history-service/transactions/search',
      args: {
        startDate: startDate,
        endDate: endDate,
        transactionTypeInt: transactionTypeInt,
        statusCodeInt: statusCodeInt,
        pageSize: pageSize,
        pageNum: pageNum,
        domainCode,
      },
    });
  }

  getGraphTransactions(
    startDate,
    endDate,
    transactionTypeInt,
    statusCodeInt,
    domainCode
  ) {
    return this.apiRequester.getIgnore401({
      endpoint:
        'v2/finch-transaction-history-service/transactions/chart/date-range',
      args: {
        startDate: startDate,
        endDate: endDate,
        transactionTypeInt: transactionTypeInt,
        statusCodeInt: statusCodeInt,
        domainCode,
      },
    });
  }

  requeryTransaction(transRef) {
    return this.apiRequester.get({
      endpoint: `v2/finch-transaction-history-service/resolutions/${transRef}/status`,
    });
  }

  getWalletStatus() {
    return this.apiRequester.get({
      endpoint: 'v2/finch-transaction-history-service/wallet/status',
    });
  }

  getWalletJournal(
    startDate,
    endDate,
    walletJournalType,
    sortDesc,
    pageSize,
    pageNum,
    domainCode
  ) {
    return this.apiRequester.getIgnore401({
      endpoint: 'v2/finch-transaction-history-service/journal/mini-statement',
      args: {
        startDate: startDate,
        endDate: endDate,
        pageSize: pageSize,
        pageNum: pageNum,
        walletJournalType: walletJournalType,
        agentCode: domainCode,
        sortDesc,
      },
    });
  }

  downloadWalletJournal(
    startDate,
    endDate,
    walletJournalType,
    pageSize,
    pageNum,
    domainCode
  ) {
    return this.apiRequester.get({
      endpoint:
        'v2/finch-transaction-history-service/journal/mini-statement/download',
      args: {
        startDate: startDate,
        endDate: endDate,
        pageSize: pageSize,
        pageNum: pageNum,
        walletJournalType: walletJournalType,
        agentCode: domainCode,
      },
      headers: {
        download: true,
      },
    });
  }

  // commissionsHistory(pageNum, pageSize, startDate, endDate, transactionTypeInt, event) {
  //   return this.apiRequester.get({
  //     endpoint: `commissions/`,
  //     args: {
  //       pageNum: pageNum,
  //       pageSize: pageSize,
  //       start: startDate,
  //       end: endDate,
  //       transactionTypeInt,
  //       event
  //     }
  //   })
  // }
}
