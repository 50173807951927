import React from "react";
import { Carousel, Col, Row } from "react-bootstrap";
import Lottie from "react-lottie";
import Button from "../../../../../../components/button";
import FormControl from "../../../../../../components/form-control";
import H1 from "../../../../../../components/h1";
import HR from "../../../../../../components/hr";
import BaseScene from "../../../../../base-scene";
import Text from "../../../../../../components/text";
import Transaction from "../../../../../../services/api/resources/transaction";
import { ERROR_STATUS } from "../../../../../../constants/api";
import animationData from "../../../../../../animations/checked-done-2";
import { RegexChecker } from "../../../../../../utils/regex-checker";
import { convertNgnToNgk } from "../../../../../../utils/converters/currencies";

export default class UnloadCommissionScene extends BaseScene {
  state = {
    activeTab: "confirmation",
    manualIsLoading: false,
    form: {},
  };

  constructor() {
    super();

    this.onCancelConfirmation = this.onCancelConfirmation.bind(this);
    this.onCancelDetails = this.onCancelDetails.bind(this);
    this.updateFormField = this.updateFormField.bind(this);
    this.onGetPaid = this.onGetPaid.bind(this);
  }

  getCurrentCarouselIndex(tabs) {
    return tabs.indexOf(this.state.activeTab);
  }

  onCancelConfirmation() {
    this.props.onCancel();
  }

  isAmountValid(value) {
    return RegexChecker.amountChecker.test(value) && value > 0;
  }

  async onGetPaid() {
    this.setState({
      manualIsLoading: true,
    });
    const transaction = new Transaction();
    const amountToUnload = convertNgnToNgk(this.state.form.amount);
    const unloadCommission = await transaction.unloadCommission(amountToUnload);
    if (unloadCommission.status === ERROR_STATUS) {
      this.props.showErrorMessage(JSON.stringify(unloadCommission.response));
      setTimeout(
        this.props.dismissErrorMessage,
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );
      this.setState({
        manualIsLoading: false,
      });
    } else {
      this.setState({
        displayAnimation: true,
      });
      setTimeout(() => this.props.onCancel(), 2000);
      setTimeout(
        () =>
          this.setState({
            displayAnimation: false,
          }),
        4000
      );
      this.setState({
        manualIsLoading: false,
      });
    }
  }

  onCancelDetails() {
    this.setState({ activeTab: "confirmation" });
    this.props.onCancel();
  }

  updateFormField(payload) {
    this.setState({
      form: {
        ...this.state.form,
        ...payload,
      },
    });
  }

  render() {
    const unloadAbleCommission = isNaN(this.props.wallet.commissions_earned)
      ? "0.0"
      : this.props.wallet.commissions_earned;

    const confirmationTab = (
      <React.Fragment>
        <Row
          style={{
            margin: "5px",
            textAlign: "center",
          }}
        >
          <Col>
            <Text
              style={{
                fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
              }}
            >
              Are you sure you want to unload commissions?
            </Text>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15px",
              }}
            >
              <Button
                style={{ marginRight: "5px" }}
                onClick={() => this.setState({ activeTab: "details" })}
              >
                YES
              </Button>
              <Button
                outline
                style={{ backgroundColor: "transparent" }}
                onClick={this.onCancelConfirmation}
              >
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );

    const detailsTab = this.state.displayAnimation ? (
      <Animation />
    ) : (
      <React.Fragment>
        <Row
          style={{ color: process.env.REACT_APP_BLACK_COLOUR, margin: "5px" }}
        >
          <Col>
            <Row style={{ marginBottom: "20px" }}>
              <Col md={12}>Available Commission:</Col>
              <Col md={12}>₦{unloadAbleCommission}</Col>
            </Row>
            <Row style={{ marginBottom: "20px" }}>
              <Col md={12}>Amount:</Col>
              <Col md={12}>
                <input
                  type="radio"
                  name="gender"
                  value={unloadAbleCommission}
                  onChange={(e) =>
                    this.setState({
                      form: {
                        ...this.state.form,
                        amount: e.target.value,
                        amountIsFixed: true,
                      },
                    })
                  }
                />
                ₦{unloadAbleCommission}
                <br />
              </Col>
              <Col md={12}>
                <input
                  type="radio"
                  name="gender"
                  value={this.state.form.amount}
                  onChange={() =>
                    this.setState({
                      form: { ...this.state.form, amountIsFixed: false },
                    })
                  }
                />{" "}
                Other Amount
                <br />
              </Col>
              <Col md={6}>
                <FormControl
                  type="text"
                  placeholder="Enter your amount"
                  onChange={(event) => {
                    const amount = event.target.value;
                    this.updateFormField({ amount });
                  }}
                  disabled={this.state.form.amountIsFixed}
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.amount
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                  }}
                />
              </Col>
            </Row>
            <Row
              style={{
                fontSize: process.env.REACT_APP_BIG_FONT_SIZE,
                marginBottom: "5px",
              }}
            >
              <Col md={6}>Total Amount:</Col>
              <Col md={6}>₦{this.state.form.amount}</Col>
            </Row>
            <HR style={{ marginTop: "5px", width: "100%" }} />
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <Button
                disabled={
                  !this.state.form.amount ||
                  !this.isAmountValid(this.state.form.amount)
                }
                style={{
                  paddingLeft: "50px",
                  paddingRight: "50px",
                  marginRight: "5px",
                }}
                onClick={this.onGetPaid}
              >
                {this.state.manualIsLoading ? "Loading..." : "Get Paid"}
              </Button>
              <Button
                outline
                style={{ backgroundColor: "transparent" }}
                onClick={this.onCancelDetails}
              >
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );

    const tabs = {
      confirmation: {
        component: confirmationTab,
      },
      details: {
        component: detailsTab,
      },
    };

    return (
      <div
        id={this.props.id}
        style={{
          color: process.env.REACT_APP_BLACK_COLOUR,
          marginTop: !this.state.deviceIsMobile ? "50px" : "75pt",
          padding: "10px",
          paddingTop: "0px",
        }}
      >
        <H1 style={{ textAlign: "left", marginTop: "0px", paddingTop: "0px" }}>
          UNLOAD COMMISSION
        </H1>
        <HR style={{ marginTop: "10px", width: "100%" }} />

        <Carousel
          activeIndex={this.getCurrentCarouselIndex(Object.keys(tabs))}
          controls={false}
          indicators={false}
          interval={null}
          style={{
            display: "flex",
            justifyContent: "center",
            margin: 0,
            marginTop: "10px",
            padding: 0,
          }}
        >
          {Object.values(tabs).map((value, idx) => {
            return <Carousel.Item key={idx}>{value.component}</Carousel.Item>;
          })}
        </Carousel>
      </div>
    );
  }
}

class Animation extends React.Component {
  render() {
    const defaultOptions = {
      autoplay: true,
      loop: false,
      animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return <Lottie height={400} options={defaultOptions} width={400} />;
  }
}
