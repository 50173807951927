import Requester from "../finch-requester";

const API_BASE_URL = process.env.REACT_APP_ONBOARDING_API_BASE_URL;

export default class Onboarding {
  constructor(props) {
    this.apiRequester = props
      ? props.apiRequester ||
      new Requester({
        apiBaseUrl: API_BASE_URL,
      })
      : new Requester({
        apiBaseUrl: API_BASE_URL,
      });
  }

  ping() {
    return this.apiRequester.get({
      endpoint: "v2/finch-onboarding/draft",
    });
  }

  signUp(data) {
    return this.apiRequester.post({
      endpoint: "v2/finch-onboarding/signup",
      body: data,
    });
  }

  getApplicationByEmailOrPhoneOrId(applicationId) {
    return this.apiRequester.get({
      endpoint: `v2/finch-onboarding/applications/${applicationId}`,
    });
  }
  putApplication(application, applicationId) {
    return this.apiRequester.put({
      endpoint: `v3/finch-onboarding/applications/${applicationId}`,
      body: application,
      args: {
        draft: true,
      },
    });
  }
  saveAggregatorApplication(application, applicationId) {
    return this.apiRequester.put({
      endpoint: `v3/finch-onboarding/applications/${applicationId}`,
      body: application,
      args: {
        draft: false,
      },
    });
  }

  getAggregatorApplication(
    status,
    searchText,
    pageSize = null,
    pageNum = null,
    mobile
  ) {
    return this.apiRequester.get({
      endpoint: `v3/finch-onboarding/applications/search`,
      args: {
        status,
        searchText,
        pageSize,
        pageNum,
        mobile,
      },
    });
  }

  // getApplicationByEmailOrPhone (emailOrPhone) {
  //   return this.apiRequester.get({
  //     endpoint: 'application',
  //     args: {
  //       identifier: emailOrPhone
  //     }
  //   })
  // }

  // saveAsDraft (application) {
  //   return this.apiRequester.put({
  //     endpoint: 'draft',
  //     body: application
  //   })
  // }

  createApplication(application) {
    return this.apiRequester.post({
      endpoint: "v2/finch-onboarding/applications",
      body: application,
      args: {
        draft: true,
      },
    });
  }
  createPersonalDetailsAggregator(application) {
    return this.apiRequester.post({
      endpoint: "v3/finch-onboarding/applications",
      body: application,
      args: {
        draft: true,
      },
    });
  }

  validateBVNDetails(bvnForm) {
    return this.apiRequester.post({
      endpoint: `v3/finch-onboarding/bvn/agent/validate`,
      body: {
        bvnDateOfBirth: bvnForm.bvnDateOfBirth,
        bvnNumber: bvnForm.bvnNumber,
      },
    });
  }
  validateBVNDetailsAggregator(bvnForm) {
    return this.apiRequester.post({
      endpoint: `v3/finch-onboarding/bvn/validate`,
      body: {
        bvnDateOfBirth: bvnForm.bvnDateOfBirth,
        bvnNumber: bvnForm.bvnNumber,
      },
    });
  }

  saveAsDraft(application) {
    return this.apiRequester.put({
      endpoint: "v2/finch-onboarding/applications",
      body: application,
      args: {
        draft: true,
      },
    });
  }

  submit(application) {
    return this.apiRequester.put({
      endpoint: "v2/finch-onboarding/applications",
      body: application,
      args: {
        draft: false,
      },
    });
  }

  documentUpload(applicationId, documentType, file) {
    const formData = new FormData();
    formData.append("file", file);

    return this.apiRequester.post({
      endpoint: "v2/finch-onboarding/document",
      headers: {
        applicationId,
        documentType,
        "content-type": "multipart/form-data",
      },
      body: formData,
    });
  }
  documentUploadAggregator(applicationId, kycDocType, file) {
    const formData = new FormData();

    formData.append("kycDoc", file);

    return this.apiRequester.post({
      endpoint: `v3/finch-onboarding/applications/${applicationId}/documents`,
      headers: {
        kycDocType,
        "content-type": "multipart/form-data",
      },
      body: formData,
    });
  }

  getDocumentsByApplicationAggregator(applicationId) {
    return this.apiRequester.get({
      endpoint: `v3/finch-onboarding/applications/${applicationId}/documents`,
    });
  }
  deleteDocumentsByApplicationAggregator(id) {
    return this.apiRequester.delete({
      endpoint: `v3/finch-onboarding/documents/${id}`,
    });
  }
  getApplicationAggregator(applicationId) {
    return this.apiRequester.get({
      endpoint: `v3/finch-onboarding/applications/${applicationId}`,
    });
  }

  getDocumentsByApplicationId(applicationId) {
    return this.apiRequester.get({
      endpoint: `v2/finch-onboarding/documents/${applicationId}`,
    });
  }

  getDocumentsByDocumentId(documentId) {
    return this.apiRequester.get({
      endpoint: `v2/finch-onboarding/document/${documentId}`,
    });
  }

  getApplicationsByApprovalStatus(status) {
    return this.apiRequester.get({
      endpoint: "v2/finch-onboarding/applications",
      params: {
        status,
      },
    });
  }

  validateApplication(application) {
    return this.apiRequester.put({
      endpoint: "v2/finch-onboarding/approval",
      body: {
        applicationID: application.applicationId,
        approvalStatus: "1",
      },
    });
  }

  approveApplication(application) {
    return this.apiRequester.put({
      endpoint: "v2/finch-onboarding/approval",
      body: {
        applicationID: application.applicationId,
        approvalStatus: "2",
      },
    });
  }

  declineApplication(application) {
    return this.apiRequester.put({
      endpoint: "v2/finch-onboarding/approval",
      body: {
        applicationID: application.applicationId,
        approvalStatus: "3",
      },
    });
  }

  submitUploadImageCapturing(payload) {
    return this.apiRequester.post({
      endpoint: `v2/finch-onboarding/kyc-records/smile-id-upload`,
      body: payload,
    });
  }

  bvnConfirmation(bvn) {
    return this.apiRequester.post({
      endpoint: `v3/finch-onboarding/bvn/confirmation`,
      body: {
        bvnNumber: bvn,
      },
    });
  }

  bvnValidate(bvn) {
    return this.apiRequester.post({
      endpoint: `v5/finch-onboarding/kyc-records/validate`,
      body: {
        identificationNumber: bvn,
      },
      headers: {
        validationType: 'BVN'
      }
    });
  }

  otpValidate(payload) {
    return this.apiRequester.post({
      endpoint: `v5/finch-onboarding/kyc-records/validate`,
      body: payload,
      headers: {
        validationType: 'BVN'
      }
    });
  }

  bvnNumberValidate(kycId, payload) {
    return this.apiRequester.post({
      endpoint: `v5/finch-onboarding/kyc-records/secure-wallet-number/initiate/${kycId}`,
      body: payload,
    });
  }

  secureWalletOtpValidate(payload) {
    const { otp, ...rest } = payload;
    const queryParams = new URLSearchParams({ otp }).toString();
    return this.apiRequester.post({
      endpoint: `v5/finch-onboarding/kyc-records/secure-wallet-number/validate?${queryParams}`,
      body: rest,
    });
  }

  createApplicationOnboarding(payload, authorization) {
    return this.apiRequester.post({
      endpoint: "v5/finch-onboarding/applications",
      body: payload,
      headers: {
        authorization
      }
    });
  }

  ninValidate(payload) {
    return this.apiRequester.post({
      endpoint: `v5/finch-onboarding/kyc-records/validate`,
      body: payload,
      headers: {
        validationType: 'NIN'
      }
    });
  }

  personalDetailsSubmit(payload) {
    return this.apiRequester.post({
      endpoint: `v5/finch-onboarding/applications/personal-details`,
      body: payload
    });
  }
  
  getApplicationDetails(authorization) {
    return this.apiRequester.get({
      endpoint: `v5/finch-onboarding/applications/fetch-application`,
      headers: {
        authorization
      }
    });
  }
  
  submitOnboarding(applicationId){
    return this.apiRequester.post({
      endpoint: `v5/finch-onboarding/applications/submit/${applicationId}`,
    });
  }

  submitInitiateImageCapturing(kycId) {
    return this.apiRequester.post({
      endpoint: `v5/finch-onboarding/kyc-records/initiate/${kycId}`,
      
    });
  }

  submitStatusImageCapturing(payload) {
    return this.apiRequester.post({
      endpoint: `v5/finch-onboarding/kyc-records/status`,
      body: payload
    });
  }
}
