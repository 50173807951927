import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";

import { DEFAULT_DEVICE_NAME } from "../constants";

export function generateChecksum(rawValue) {
  return CryptoJS.MD5(rawValue).toString();
}

export function generateMacValue(rawValue) {
  return CryptoJS.SHA512(rawValue).toString();
}

export function getDeviceInformation() {
  let deviceUuid = localStorage.getItem("deviceUid");
  if (deviceUuid == null) {
    deviceUuid = uuidv4();
    localStorage.setItem("deviceUid", deviceUuid);
  }

  return {
    deviceUuid: deviceUuid,
    deviceName: DEFAULT_DEVICE_NAME,
    deviceModel: "",
    channel: "Web",
  };
}

export function getUserPermissions() {
  const user = JSON.parse(localStorage.getItem("currentUser"));

  return user.permissions;
}

export function checkFieldsValidation(fieldNames, validationState) {
  let formFieldsValid = true;
  let focusedField;

  for (let i = 0; i < fieldNames.length; i++) {
    const fieldName = fieldNames[i];
    const fieldValidationState = validationState[fieldName];

    const fieldSelector = fieldValidationState.selector;
    const fieldContainerEl = window.document.querySelector(fieldSelector);

    if (fieldValidationState.valid === false) {
      formFieldsValid = false;

      fieldContainerEl.style.borderBottomColor = "red";
      continue;
    }

    if (focusedField === fieldName) {
      fieldContainerEl.style.borderBottomColor = "#a0c8e2";
    } else {
      fieldContainerEl.style.borderBottomColor = "#e4e4e4";
    }
  }

  return formFieldsValid;
}

export function setActivePage(pageName) {
  let pages = window.document.querySelectorAll(".form-page");

  for (let i = 0; i < pages.length; i++) {
    let page = pages[i];
    page.classList.remove("show");
  }

  let activePage = window.document.querySelector(".form-page." + pageName);
  activePage.classList.add("show");
}

export function showNotification(message, isError) {
  let notificationBoxEl = window.document.querySelector(".notification-box");
  notificationBoxEl.classList.add("show");

  let contentEl = notificationBoxEl.querySelector(".content");
  contentEl.innerHTML = message;

  contentEl.classList.remove("error");
  contentEl.classList.remove("success");

  contentEl.classList.add(isError ? "error" : "success");

  setTimeout(function () {
    notificationBoxEl.classList.remove("show");
  }, 3000);
}

export function getDateByType(type = null) {
  let fromDate = new Date();
  let toDate = new Date();
  fromDate.setHours(0, 0, 0, 0);
  toDate.setHours(23, 59, 59, 59);
  return type === "startDate"
    ? fromDate
    : type === "endDate"
    ? toDate
    : new Date();
}


export function getPaginationRows(count) {
  let rows = [10, 20, 25, 50, 100];
  return rows;
}