import React from "react";
import {
  Carousel,
  Col,
  Container,
  Modal,
  Nav,
  Row,
  Tab,
} from "react-bootstrap";
import { Redirect } from "react-router-dom";
import BaseScene from "../base-scene";
import Button from "../../components/button";
import Footer from "../../components/footer";
import H1 from "../../components/h1";
import Header from "../../components/header";
import Banks from "../../fixtures/banks";
import IdentificationTypes from "../../fixtures/identification_types";
import CountriesStatesLgas from "../../fixtures/countries_states_lgas";
import PersonalDetailsFormSerializer from "../../serializers/forms/personal-details";
import BusinessDetailsFormSerializer from "../../serializers/forms/business-details";
import NextOfKinDetailsFormSerializer from "../../serializers/forms/next-of-kin-details";
import Onboarding from "../../services/api/resources/onboarding";
import PersonalDetailsForm from "./tabs/personal-details-form";
import BusinessDetailsForm from "./tabs/business-details-form";
import NextOfKinDetailsForm from "./tabs/next-of-kin-details-form";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../constants/api";
import ApplicationPreview from "../../components/application-preview";
import ServiceLevelAgreement from "../../components/service-level-agreement";
import "./styles.scss";
import { AGENT_TYPE_ID } from "../../constants";

class MyVerticallyCenteredModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      iAgree: false,
      index: 0,
      showAgreedButton: false,
    };

    this.onProceed = this.onProceed.bind(this);
  }

  onProceed() {
    if (this.state.index === 0) {
      this.setState({
        index: 1,
        iAgree: true,
        showAgreedButton: true,
      });

      return;
    }

    this.props.onHide();
    this.props.onProceed();
  }

  get toDisableProceed() {
    if (this.state.index === 0) {
      return false;
    }

    return !this.state.iAgree || this.props.isLoading;
  }

  render() {
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* <Modal.Header closeButton>
        </Modal.Header> */}
        <Modal.Body>
          <Carousel
            activeIndex={this.state.index}
            controls={false}
            disabled
            indicators={false}
            interval={null}
          >
            <Carousel.Item>
              <H1 style={{ marginBottom: "10px" }}>Application Preview</H1>
              <ApplicationPreview
                banks={this.props.banks}
                identificationTypes={this.props.identificationTypes}
                countriesStatesLgas={this.props.countriesStatesLgas}
                {...this.props}
              />
            </Carousel.Item>
            <Carousel.Item>
              <H1 style={{ marginBottom: "10px" }}>
                IFIS AGENT SERVICE LEVEL AGREEMENT
              </H1>
              <ServiceLevelAgreement
                {...this.props}
                defaultChecked={this.state.iAgree}
                onAgree={() =>
                  this.setState({
                    iAgree: !this.state.iAgree,
                  })
                }
              />
            </Carousel.Item>
          </Carousel>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={this.toDisableProceed} onClick={this.onProceed}>
            {this.state.index === 0 ? "PROCEED" : "I AGREE"}
          </Button>
          <Button
            outline
            style={{
              backgroundColor: "transparent",
              marginTop: this.props.deviceIsMobile ? "10pt" : null,
              padding: "8pt 37pt",
              marginRight: "10px",
            }}
            onClick={this.props.onHide}
          >
            {this.state.showAgreedButton ? `I DISAGREE` : `Cancel`}
          </Button>
          {/* {this.state.showAgreedButton && <Button outline onClick={this.props.onHide}>I DISAGREE</Button>} */}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default class Application extends BaseScene {
  location = window.location.href;
  path = "/application";
  title = "Onboarding";

  isAuthRequired = true;
  onboarding = new Onboarding();

  businessDetailsFormHeaderRef = React.createRef(null);
  nextOfKinDetailsFormHeaderRef = React.createRef(null);
  personalDetailsFormHeaderRef = React.createRef(null);

  constructor(props) {
    super(props);

    this.state = {
      activeTab: "personal-details",
      firstName: null,
      lastName: null,
      phone: null,
      email: null,
      password: null,
      repeatPassword: null,
      howYouHeardAboutUs: null,
      iAgree: null,
      formIsValid: false,
      hidePassword: true,
      hideRepeatPassword: true,

      personalDetailsForm: {},
      businessDetailsForm: {},
      nextOfKinDetailsForm: {},

      showModal: false,

      banks: Banks,
      countriesStatesLgas: CountriesStatesLgas,
      identificationTypes: IdentificationTypes,
    };

    this.onContinueLaterClick = this.onContinueLaterClick.bind(this);
    this.saveApplication = this.saveApplication.bind(this);
    this.submitApplication = this.submitApplication.bind(this);
  }

  componentDidMount() {
    const currentApplication = JSON.parse(
      localStorage.getItem("currentApplication")
    );
    this.onboarding
      .getApplicationByEmailOrPhoneOrId(currentApplication.applicationId)
      .then((responseObj) => {
        const { status, response } = responseObj;
        if (status === SUCCESS_STATUS) {
          localStorage.setItem("currentApplication", JSON.stringify(response));
        }
      });

    if (window.location.search) {
      const queryStringObject = new URLSearchParams(window.location.search);
      const isApplicationRejected = JSON.parse(
        queryStringObject.get("applicationRejected")
      );

      if (isApplicationRejected) {
        this.setState({
          activeTab: "personal-details",
        });
      }

      const currentUrl = window.location.href.split("?");
      const urlObj = { Title: "application", Url: currentUrl[0] };
      window.history.replaceState(urlObj, urlObj.Title, urlObj.Url);
    }
  }

  isEmailValid(newState) {
    const state = newState || this.state;
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(state.email).toLowerCase());
  }

  isFirstNameValid(newState) {
    const state = newState || this.state;
    return state.firstName && state.firstName.length > 2;
  }

  isLastNameValid(newState) {
    const state = newState || this.state;
    return state.lastName && state.lastName.length > 2;
  }

  isPasswordValid(newState) {
    const state = newState || this.state;
    const { password } = state;

    var re = /[a-z]/;
    var re2 = /[A-Z]/;
    var re3 = /[0-9]/;

    return (
      re.test(String(password)) &&
      re2.test(String(password)) &&
      re3.test(String(password)) &&
      String(password).length >= 8
    );
  }

  isPhoneValid(newState) {
    const state = newState || this.state;
    return state.phone && state.phone.length === 11;
  }

  passwordsMatch(newState) {
    const state = newState || this.state;
    return state.password === state.repeatPassword;
  }

  userAgrees(newState) {
    const state = newState || this.state;
    return state.iAgree;
  }

  updateFormField(params) {
    const newState = {
      ...this.state,
      ...params,
    };
    const allParamsAreValid =
      this.isFirstNameValid(newState) &&
      this.isLastNameValid(newState) &&
      this.isPhoneValid(newState) &&
      this.isEmailValid(newState) &&
      this.isPasswordValid(newState) &&
      this.passwordsMatch(newState) &&
      this.userAgrees(newState);

    this.setState({
      ...params,
      canSubmit: this.canSubmit(),
      formIsValid: allParamsAreValid,
    });
  }

  canSubmit() {
    const personalDetailsFormSerializer = new PersonalDetailsFormSerializer(
      this.state.personalDetailsForm
    );
    const businessDetailsFormSerializer = new BusinessDetailsFormSerializer(
      this.state.businessDetailsForm
    );
    const nextOfKinDetailsFormSerializer = new NextOfKinDetailsFormSerializer(
      this.state.nextOfKinDetailsForm
    );

    return (
      personalDetailsFormSerializer.formIsComplete() &&
      businessDetailsFormSerializer.formIsComplete() &&
      nextOfKinDetailsFormSerializer.formIsComplete()
    );
  }

  async saveApplication() {
    this.props.updateLoading(true);

    const currentApplication = JSON.parse(
      localStorage.getItem("currentApplication")
    );

    const personalDetailsFormSerializer = new PersonalDetailsFormSerializer({
      ...this.state.personalDetailsForm,
      bvnVerificationStatus:
        currentApplication.applicantDetails.bvnVerificationStatus,
    });
    const businessDetailsFormSerializer = new BusinessDetailsFormSerializer(
      this.state.businessDetailsForm
    );
    const nextOfKinDetailsFormSerializer = new NextOfKinDetailsFormSerializer(
      this.state.nextOfKinDetailsForm
    );

    // const newApplicantDetails = personalDetailsFormSerializer.asJson()

    const newApplicationData = {
      ...currentApplication,
      applicantDetails: personalDetailsFormSerializer.asJson(),
      businessDetails: businessDetailsFormSerializer.asJson(),
    };

    newApplicationData.referralCode =
      personalDetailsFormSerializer.referralCode;
    delete personalDetailsFormSerializer.referralCode;

    newApplicationData.agentTypeId = AGENT_TYPE_ID;

    newApplicationData.applicantDetails.nextOfKin =
      nextOfKinDetailsFormSerializer.asJson();
    if (!nextOfKinDetailsFormSerializer.formIsValid()) {
      delete newApplicationData.applicantDetails.nextOfKin;
    }

    if (!businessDetailsFormSerializer.formIsValid()) {
      delete newApplicationData.businessDetails;
    }

    // localStorage.setItem("currentApplication: .....", JSON.stringify(newApplicationData));

    const responseObj = await this.onboarding.saveAsDraft(newApplicationData);

    const { status, response, code } = responseObj;
    if (status === "SUCCESS") {
      localStorage.setItem("currentApplication", JSON.stringify(response));
    } else {
      code === 400
        ? this.props.showErrorMessage(
            "Something is wrong with the data submitted."
          )
        : this.props.showErrorMessage(JSON.stringify(response));

      setTimeout(
        () => this.props.dismissErrorMessage(),
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );
    }

    this.props.updateLoading(false);

    return responseObj;
  }

  async submitApplication() {
    this.props.updateLoading(true, 33);

    const saveApplicationResponseObj = await this.saveApplication();
    const saveApplicationResponse = saveApplicationResponseObj.response;
    const saveApplicationResponseStatus = saveApplicationResponseObj.status;
    const currentApplication = saveApplicationResponse;

    if (saveApplicationResponseStatus === ERROR_STATUS) {
      this.props.updateLoading(false);
      this.props.showErrorMessage(JSON.stringify(saveApplicationResponse));
      setTimeout(
        () => this.props.dismissErrorMessage(),
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );
      return;
    }

    this.props.updateLoading(true, 66);

    const submitResponseObj = await this.onboarding.submit(currentApplication);
    const { status, response } = submitResponseObj;
    if (status === "SUCCESS") {
      localStorage.setItem("currentApplication", JSON.stringify(response));
      this.setState({
        redirectToDashboard: true,
      });
    } else {
      this.props.showErrorMessage(JSON.stringify(response));
      setTimeout(
        () => this.props.dismissErrorMessage(),
        parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT)
      );
    }

    this.props.updateLoading(false);

    return submitResponseObj;
  }

  async onContinueLaterClick() {
    const response = await this.saveApplication();
    if (response.status === "SUCCESS") {
      this.setState({ redirectToDashboard: true });
    }
  }

  get previewModal() {
    return (
      <MyVerticallyCenteredModal
        banks={this.state.banks}
        identificationTypes={this.state.identificationTypes}
        lgas={this.state.lgas}
        states={this.state.states}
        personalDetailsForm={this.state.personalDetailsForm}
        businessDetailsForm={this.state.businessDetailsForm}
        nextOfKinDetailsForm={this.state.nextOfKinDetailsForm}
        show={this.state.showModal}
        onHide={() =>
          this.setState({
            showModal: false,
          })
        }
        onProceed={this.submitApplication}
      />
    );
  }

  get decorativeHeader() {
    return (
      <div className="decorative-header">
        <center
          style={{ fontFamily: process.env.REACT_APP_FONT_FAMILY_HEADER }}
        >
          COMPLETE YOUR APPLICATION <br />
          TO JOIN QUICKTELLER PAYPOINT
          <button
            className="continue-later-pc-button"
            hidden={this.state.deviceIsMobile || this.state.canSubmit}
            onClick={this.onContinueLaterClick}
          >
            CONTINUE LATER
          </button>
        </center>
      </div>
    );
  }

  render() {
    if (this.state.redirectToDashboard) {
      return <Redirect to="/dashboard" />;
    }

    const screenSensitiveStyle = !this.state.deviceIsMobile
      ? {
          maxWidth: "1080px",
          width: "1080px",
          marginTop: "50px",
        }
      : {};

    return (
      <div className="main-container">
        {this.previewModal}

        <Header />

        <Container fluid className="main-container-2">
          {this.decorativeHeader}

          <div
            hidden={!this.state.deviceIsMobile || this.state.canSubmit}
            id="continue-later-mobile-button"
            onClick={this.onContinueLaterClick}
          >
            Continue later
          </div>

          <div className="main-container-3">
            <div className="main-container-4" style={screenSensitiveStyle}>
              <div className="toggle-previously-saved-fields-container">
                <Button
                  outline
                  className="toggle-previously-saved-fields"
                  style={{
                    backgroundColor: this.state.showPreviouslySavedFields
                      ? process.env.REACT_APP_BLUE_COLOUR
                      : "transparent",
                    borderColor: this.state.showPreviouslySavedFields
                      ? "transparent"
                      : "black",
                    color: this.state.showPreviouslySavedFields
                      ? "white"
                      : process.env.REACT_APP_BLUE_COLOUR,
                    padding: "8pt 37pt",
                  }}
                  onClick={() =>
                    this.setState({
                      showPreviouslySavedFields:
                        !this.state.showPreviouslySavedFields,
                    })
                  }
                >
                  {this.state.showPreviouslySavedFields ? "Hide" : "Show"}{" "}
                  previously saved fields
                </Button>
              </div>
              <Tab.Container
                id="left-tabs-example"
                activeKey={this.state.activeTab}
                onSelect={() => {}}
              >
                <Row hidden>
                  <Col xs={6} md={4} className="regular-col">
                    <Nav.Item
                      className="application-scene-nav-item"
                      style={{
                        borderBottom: `1px solid ${
                          this.state.activeTab === "personal-details"
                            ? process.env.REACT_APP_BLUE_COLOUR
                            : "#D2D2D3"
                        }`,
                      }}
                    >
                      <Nav.Link
                        className="application-scene-nav-link"
                        eventKey="personal-details"
                        onSelectA={() =>
                          this.setState({ activeTab: "personal-details" })
                        }
                        style={{
                          color:
                            this.state.activeTab === "personal-details"
                              ? process.env.REACT_APP_BLUE_COLOUR
                              : "#929293",
                        }}
                      >
                        <b>01</b> Personal Details
                      </Nav.Link>
                    </Nav.Item>
                  </Col>
                  <Col xs={6} md={4} className="regular-col">
                    <Nav.Item
                      className="application-scene-nav-item"
                      style={{
                        borderBottom: `1px solid ${
                          this.state.activeTab === "business-details"
                            ? process.env.REACT_APP_BLUE_COLOUR
                            : "#D2D2D3"
                        }`,
                      }}
                    >
                      <Nav.Link
                        className="application-scene-nav-link"
                        eventKey="business-details"
                        onSelectA={() =>
                          this.setState({ activeTab: "business-details" })
                        }
                        style={{
                          color:
                            this.state.activeTab === "business-details"
                              ? process.env.REACT_APP_BLUE_COLOUR
                              : "#929293",
                        }}
                      >
                        <b>02</b> Business Details
                      </Nav.Link>
                    </Nav.Item>
                  </Col>
                  <Col xs={6} md={4} className="regular-col">
                    <Nav.Item
                      className="application-scene-nav-item"
                      style={{
                        borderBottom: `1px solid ${
                          this.state.activeTab === "next-of-kin-details"
                            ? process.env.REACT_APP_BLUE_COLOUR
                            : "#D2D2D3"
                        }`,
                      }}
                    >
                      <Nav.Link
                        className="application-scene-nav-link"
                        eventKey="next-of-kin-details"
                        onSelectA={() =>
                          this.setState({ activeTab: "next-of-kin-details" })
                        }
                        style={{
                          color:
                            this.state.activeTab === "next-of-kin-details"
                              ? process.env.REACT_APP_BLUE_COLOUR
                              : "#929293",
                        }}
                      >
                        <b>03</b> Next Of Kin Details
                      </Nav.Link>
                    </Nav.Item>
                  </Col>
                </Row>
                <Row>
                  <Tab.Content id="tab-content">
                    <Tab.Pane eventKey="personal-details">
                      <PersonalDetailsForm
                        formHeaderRef={this.personalDetailsFormHeaderRef}
                        onUpdateFormField={(formData) =>
                          this.setState({ personalDetailsForm: formData })
                        }
                        onSubmit={(formData) => {
                          this.setState({
                            personalDetailsForm: formData,
                            activeTab: "business-details",
                            canSubmit: this.canSubmit(),
                          });
                          this.businessDetailsFormHeaderRef.current &&
                            window.scrollTo(
                              0,
                              this.businessDetailsFormHeaderRef.current
                                .offsetTop
                            );
                        }}
                        showPreviouslySavedFields={
                          this.state.showPreviouslySavedFields
                        }
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="business-details">
                      <BusinessDetailsForm
                        showPreviouslySavedFields={
                          this.state.showPreviouslySavedFields
                        }
                        formHeaderRef={this.businessDetailsFormHeaderRef}
                        goBack={() =>
                          this.setState({
                            activeTab: "personal-details",
                          })
                        }
                        onUpdateFormField={(formData) =>
                          this.setState({
                            businessDetailsForm: formData,
                            canSubmit: this.canSubmit(),
                          })
                        }
                        onSubmit={(formData) => {
                          this.setState({
                            businessDetailsForm: formData,
                            activeTab: "next-of-kin-details",
                            canSubmit: this.canSubmit(),
                          });
                          this.nextOfKinDetailsFormHeaderRef.current &&
                            window.scrollTo(
                              0,
                              this.nextOfKinDetailsFormHeaderRef.current
                                .offsetTop
                            );
                        }}
                        sameAddress={this.state.personalDetailsForm.address}
                        sameState={this.state.personalDetailsForm.state}
                        sameLga={this.state.personalDetailsForm.lga}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="next-of-kin-details">
                      <NextOfKinDetailsForm
                        showPreviouslySavedFields={
                          this.state.showPreviouslySavedFields
                        }
                        canSubmit={this.state.canSubmit}
                        formHeaderRef={this.nextOfKinDetailsFormHeaderRef}
                        goBack={() =>
                          this.setState({
                            activeTab: "business-details",
                          })
                        }
                        onUpdateFormField={(formData) => {
                          this.state.nextOfKinDetailsForm = formData;
                          this.setState({
                            canSubmit: this.canSubmit(),
                          });
                        }}
                        onSubmit={(formData) => {
                          this.setState({
                            nextOfKinDetailsForm: formData,
                            activeTab: "next-of-kin-details",
                            showModal: true,
                          });
                          // this.submitApplication()
                        }}
                        sameAddress={this.state.personalDetailsForm.address}
                        sameState={this.state.personalDetailsForm.state}
                        sameLga={this.state.personalDetailsForm.lga}
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </Container>

        <Footer relative={true} />
      </div>
    );
  }
}
