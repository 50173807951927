import React, { useContext, useState } from 'react';
import { NIGERIA_PHONE_PREFIX } from '../../../../../../../constants';

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [responseData, setResponseData] = useState({});
  const [code, setCode] = useState('');
  const [form, setForm] = useState({
    amount: '',
    bankCode: '',
    customerId: '',
    checksum: '',
    phonePrefix: NIGERIA_PHONE_PREFIX,
    paymentItemCode: '',
  });

  return (
    <AppContext.Provider
      value={{
        responseData,
        setResponseData,
        form,
        code,
        setCode,
        setForm,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
// make sure use
export const useFundUssdContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
