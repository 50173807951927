import React, { useEffect, useState } from "react";
import InputText from "../component/input";
import H1 from "../../../../../components/h1";
import { Col } from "react-bootstrap";
import { SelectInputState } from "../../../../signup/onboarding-setup/component/stateSelect";
import SelectInput from "../component/select";
import BackButton from "../component/backButton";
import PrimaryButton from "../component/button";
import CountriesStatesLga from "../../../../../fixtures/countries_states_lgas.json";

const BusinessDetailsScene = ({ setScreen, application }) => {
  const [states, setStates] = useState([]);

  const currentAgent = JSON.parse(localStorage.getItem("currentAgent"));

  const stateId = currentAgent?.businessLocation[0]?.stateId;
  const lgaId = currentAgent?.businessLocation[0]?.lgaId;

  const selectedStateData = CountriesStatesLga[0].states.find(
    (state) => state.id === Number(stateId)
  );

  const lgaWithId = selectedStateData?.lgas?.find(
    (lga) => lga.id === Number(lgaId)
  );

  return (
    <>
      <BackButton onClick={() => setScreen(3)} />
      <main className="aggregator-container">
        <Col
          style={{
            marginBottom: "20px",
            width: "80%",
            margin: "0 auto",
          }}
        >
          <H1
            style={{
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "1rem",
              fontSize: "2rem",
              color: "#1F2937",
            }}
          >
            Business Details
          </H1>
          {/* <InputText
            label={'Business Name'}
            onChange={(e) => {
              updateFormField({ businessName: e.target.value });
            }}
            labelColor={
              !businessDetailsForm.businessName ||
                isBusinessNameValid(businessDetailsForm)
                ? 'black'
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${!businessDetailsForm.businessName ||
              isBusinessNameValid(businessDetailsForm)
              ? '#DFE2E6'
              : process.env.REACT_APP_RED_COLOUR
              }`}
            placeholder={'Enter your Business Name'}
            value={businessDetailsForm.businessName}
            maxLength={MAX_BUSINESS_NAME_LENGTH}
          // disabled={application.businessDetails?.address
          // }
          /> */}
          <InputText
            disabled={true}
            value={currentAgent?.businessName}
            label={"Business Name"}
          />
          <InputText
            disabled={true}
            value={currentAgent?.businessLocation[0].addressLine1}
            label={"Shop Address"}
          />
          {/* <InputText
            label={'Shop Address'}
            labelColor={
              !businessDetailsForm.businessAddress ||
                isBusinessAddressValid(businessDetailsForm)
                ? 'black'
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${!businessDetailsForm.businessAddress ||
              isBusinessAddressValid(businessDetailsForm)
              ? '#DFE2E6'
              : process.env.REACT_APP_RED_COLOUR
              }`}
            placeholder={'Enter your Business Address'}
            onChange={(e) => {
              updateFormField({ businessAddress: e.target.value });
            }}
            maxLength={200}
            value={businessDetailsForm.businessAddress}
          // disabled={application.businessDetails?.address}
          /> */}

          {/* <SelectInputState
            label={'State'}
            option={states}
            getState={localStorage.getItem('state')}
            value={businessDetailsForm.state}
            placeholder={'state'}
            onChange={(e) => {
              localStorage.setItem(
                'state',
                states.find((value) => value.name === e.target.value).name
              );
              setBusinessDetailsForm({ ...businessDetailsForm, lga: '' });
              localStorage.removeItem('lga');
              setSelectedState(
                states.find((value) => value.name === e.target.value).id
              );
              updateFormField({
                state: states.find((value) => value.name === e.target.value).id,
              });

            }}

          // disabled={application.applicationId}
          /> */}

          <SelectInput
            value={selectedStateData.name}
            option={states}
            label={"State"}
            disabled={true}
          />

          <SelectInput
            // value={lgaWithId.name}
            option={[]}
            label={"Local Government Area"}
            disabled={true}
          />
          <PrimaryButton onClick={() => setScreen(5)}>Next</PrimaryButton>
        </Col>
      </main>
    </>
  );
};

export default BusinessDetailsScene;
