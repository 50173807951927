import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ActivityIndicator from "../../../../../components/activity-indicator";
import Spinner from "../../../../../components/spinner";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Redirect } from "react-router-dom";
import {
  Row,
  Col,
  Dropdown,
  Modal,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import MdMore from "react-ionicons/lib/MdMore";
import { FUND_WALLET_URL } from "../../../../../utils/api-properties";
import { formatAmount } from "../../../../../utils/formatters";
import { convertNgkToNgn } from "../../../../../utils/converters/currencies";
import VerticallyCenteredModal from "../../../../../components/vertically-centered-modal";
import Text from "../../../../../components/text";
import PosRequest from "../../../../../services/api/resources/pos-request-automation";
import { SUCCESS_STATUS } from "../../../../../constants/api";
import {
  showErrorMessage,
  showSuccessMessage,
  dismissErrorMessage,
  dismissSuccessMessage,
  updateLoading,
} from "../../../../../services/redux/actions/tunnel";
import H1 from "../../../../../components/h1";
import Button from "../../../../../components/button";
import HR from "../../../../../components/hr";

//import './styles.scss';

class PosRequestTable extends React.Component {
  posRequest = new PosRequest();

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      showModal: false,

      transactionDetails: [],
      showConfirmationModal: false,
      isRequeryLoading: false,
    };

    this.capitalizeFirstLetter = this.capitalizeFirstLetter.bind(this);
    this.processValuesWithUnderscore =
      this.processValuesWithUnderscore.bind(this);
    this.handleRedirectToQT = this.handleRedirectToQT.bind(this);

    this.searchByPosRequestId = this.searchByPosRequestId.bind(this);
    this.handleConfirmDelivery = this.handleConfirmDelivery.bind(this);
    this.confirmPosDelivered = this.confirmPosDelivered.bind(this);
    this.handleYesConfirmationModal =
      this.handleYesConfirmationModal.bind(this);
    this.handleNoConfirmationModal = this.handleNoConfirmationModal.bind(this);
  }
  capitalizeFirstLetter(word) {
    if (word === "n/a") return "N/A";
    return word.replace(word.charAt(0), word.charAt(0).toUpperCase());
  }

  processValuesWithUnderscore = (value) => {
    const words = value.split("_");

    const result =
      this.capitalizeFirstLetter(words[0]) +
      " " +
      this.capitalizeFirstLetter(words[1]);

    return result.indexOf("Pos") !== -1 ? result.replace("Pos", "POS") : result;
  };

  handleRedirectToQT = () => {
    // const envQTURL = process.env.REACT_APP_QUICKTELLER_URL;
    window.open(FUND_WALLET_URL);
    // <Redirect to={envQTURL }/>
  };

  handleConfirmDelivery = (id) => {
    this.setState({
      showConfirmationModal: true,
      selectedPosRequestStockId: id,
    });
  };

  async handleYesConfirmationModal() {
    this.setState({
      showConfirmationModal: false,
    });
    await this.confirmPosDelivered(this.state.selectedPosRequestStockId);
    this.searchByPosRequestId(this.state.selectedPosRequestId);
  }

  handleNoConfirmationModal() {
    this.setState({
      showConfirmationModal: false,
    });
  }

  async confirmPosDelivered(id) {
    this.setState({
      isLoading: true,
    });
    const { status, code, response } =
      await this.posRequest.confirmPosDelivered(id);

    if (status === SUCCESS_STATUS) {
      this.setState({
        isLoading: false,
        showConfirmationResponseMsgModal: true,
        confirmationResponseMsg: "successful", //response.description
      });

      setTimeout(() => {
        this.setState({ showConfirmationResponseMsgModal: false });
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
    } else {
      this.setState({
        isLoading: false,
        showConfirmationResponseMsgModal: true,
        confirmationResponseMsg: "failed", //response.description
      });

      setTimeout(() => {
        this.setState({ showConfirmationResponseMsgModal: false });
      }, process.env.REACT_APP_TOAST_DURATION_SHORT);
    }
  }

  async searchByPosRequestId(id) {
    const { status, code, response } = await this.posRequest.getPosRequestByRef(
      id
    );

    if (status === SUCCESS_STATUS) {
      this.setState({
        transactionDetails: response.data,
        isLoading: false,
      });
    } else if (code === 404) {
      this.setState({
        isLoading: false,
      });
    } else {
      this.setState({
        isLoading: false,
      });
      this.props.showErrorMessage(JSON.stringify(response));
      setTimeout(() => {
        this.props.dismissErrorMessage();
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
    }
  }

  render() {
    const confirmationResponseMsgModal = (
      <Modal
        style={{ borderRadius: "10px", transition: "all .2s ease-in-out" }}
        animation="true"
        keyboard="true"
        size="sm"
        show={this.state.showConfirmationResponseMsgModal}
        centered
      >
        <Modal.Body
          className={`${this.state.confirmationResponseMsg}-popup-style`}
        >
          <center>
            <Text
              style={{
                fontSize: process.env.REACT_APP_BIG_FONT_SIZE,
                lineHeight: process.env.REACT_APP_MID_BIG_LINE_HEIGHT,
                margin: "10px",
                color: "#fff",
              }}
            >
              {this.state.confirmationResponseMsg === "successful" ? (
                <span>Successful</span>
              ) : (
                <span>Please Retry</span>
              )}
            </Text>
          </center>
        </Modal.Body>
      </Modal>
    );

    const confirmationModal = (
      <Modal
        animation="true"
        style={{ borderRadius: "10px", transition: "all .2s ease-in-out" }}
        size="sm"
        show={this.state.showConfirmationModal}
        centered
      >
        <Modal.Body style={{ margin: "10px", padding: "15px" }}>
          <H1
            style={{
              textAlign: "center",
              textTransform: "uppercase",
              margin: "0px",
              padding: "0px",
            }}
          >
            CONFIRM POS DELIVERY
          </H1>

          <center>
            <HR style={{ marginTop: "10px", width: "20%" }} />

            <Text
              style={{
                fontSize: "15px",
                lineHeight: process.env.REACT_APP_MID_BIG_LINE_HEIGHT,
                margin: "10px",
              }}
            >
              Click Yes If You Have Received POS
            </Text>
          </center>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <Button onClick={this.handleYesConfirmationModal}>Yes</Button>
          <Button onClick={this.handleNoConfirmationModal}>No</Button>
        </Modal.Footer>
      </Modal>
    );

    const handleOpen = (props) => {
      this.setState({
        showModal: true,
        isLoading: true,

        selectedPosRequestId: props.original.id,
        transactionDetails: [],
      });

      this.searchByPosRequestId(props.original.id);
    };

    const handleClose = () => {
      this.setState({
        showModal: false,
      });
    };

    const eachPosColumns = [
      {
        Header: (props) => <div style={{ textAlign: "left" }}>S/N</div>,
        Cell: (props) => (
          <div style={{ textAlign: "left" }}>{props.index + 1}</div>
        ),
        accessor: "index",
        headerClassName: "table-header",
        className: "table-cell",
        style: { "white-space": "unset" },
        width: 40,
      },

      {
        Header: (props) => (
          <div style={{ textAlign: "left" }}>Terminal Type</div>
        ),
        Cell: (props) => (
          <div style={{ textAlign: "left", fontWeight: "normal" }}>
            {props.original.terminalType !== undefined
              ? this.capitalizeFirstLetter(props.original.terminalType)
              : props.original.terminalType}
          </div>
        ),
        accessor: "terminalType",
        headerClassName: "table-header",
        width: 100,
      },
      {
        Header: (props) => (
          <div style={{ textAlign: "left" }}>Request Type</div>
        ),
        Cell: (props) => (
          <div style={{ textAlign: "left" }}>
            {props.original.requestType !== undefined
              ? this.capitalizeFirstLetter(props.original.requestType)
              : props.original.requestType}
          </div>
        ),
        accessor: "requestType",
        headerClassName: "table-header",
        className: "table-cell",
        style: { "white-space": "unset" },
        width: 100,
      },
      {
        Header: (props) => (
          <div style={{ textAlign: "left" }}>Terminal Model</div>
        ),
        Cell: (props) => (
          <div style={{ textAlign: "left" }}>
            {props.original.terminalModel !== undefined
              ? this.capitalizeFirstLetter(props.original.terminalModel)
              : props.original.terminalModel}
          </div>
        ),
        accessor: "terminalModel",
        headerClassName: "table-header",
        className: "table-cell",
        style: { "white-space": "unset", fontWeight: "normal" },
        width: 150,
      },
      {
        Header: (props) => (
          <div style={{ textAlign: "left" }}>Workflow Status</div>
        ),
        Cell: (props) => (
          <div
            className={`${
              props.value === undefined ? (
                <div>N/A</div>
              ) : (
                props.original.workflowStatus.toLowerCase()
              )
            }-status`}
            style={{ textAlign: "left" }}
          >
            {props.value === undefined
              ? ""
              : props.original.workflowStatus.indexOf("_") !== -1
              ? this.processValuesWithUnderscore(props.original.workflowStatus)
              : this.capitalizeFirstLetter(props.original.workflowStatus)}
          </div>
        ),
        accessor: "workflowStatus",
        headerClassName: "table-header",
        className: "table-cell",
        style: { "white-space": "unset" },
        width: 200,
      },
      {
        Header: (props) => (
          <div style={{ textAlign: "left" }}>Workflow Type</div>
        ),
        Cell: (props) => (
          <div style={{ textAlign: "left" }}>
            {props.original.workflowType.indexOf("_") !== -1
              ? this.processValuesWithUnderscore(props.original.workflowType)
              : this.capitalizeFirstLetter(props.original.workflowType)}
          </div>
        ),
        accessor: "workflowType",
        headerClassName: "table-header",
        className: "table-cell",
        style: { "white-space": "unset" },
        width: 200,
      },

      {
        Header: (props) => (
          <div style={{ textAlign: "left" }}>POS Unit Price</div>
        ),
        Cell: (props) => (
          <div style={{ textAlign: "left" }}>
            {" "}
            ₦{`${formatAmount(convertNgkToNgn(props.original.posUnitPrice))}`}
          </div>
        ),
        accessor: "posUnitPrice",
        headerClassName: "table-header",
        className: "table-cell",
        style: { "white-space": "unset" },
        width: 200,
      },

      // {
      //   Header: (props) => <div style={{ textAlign: 'left' }}>Action</div>,
      //   accessor: '',
      //   Cell: (props) =>
      //     props.original.deliveryStatus === 'awaiting_delivery' && (
      //       <Button
      //         className='table-button'
      //         onClick={() =>
      //           this.handleConfirmDelivery(props.original.posRequestStockId)
      //         }
      //       >
      //         Confirm
      //       </Button>
      //     ),
      //   headerClassName: 'table-header',
      //   className: 'table-cell',
      //   style: { 'white-space': 'unset' },
      //   width: 200,
      // },
    ];

    const PosRequestColumns = [
      {
        Header: (props) => <div style={{ textAlign: "left" }}>S/N</div>,
        Cell: (props) => (
          <div style={{ textAlign: "left" }}>{props.index + 1}</div>
        ),
        accessor: "index",
        headerClassName: "table-header",
        className: "table-cell",
        style: { "white-space": "unset" },
        width: 40,
      },
      {
        Header: (props) => <div style={{ textAlign: "left" }}>Request ID</div>,
        accessor: "requestId",
        Cell: (props) => <div style={{ textAlign: "left" }}>{props.value}</div>,
        headerClassName: "table-header",
        className: "table-cell",
        style: { "white-space": "unset" },
      },
      {
        Header: (props) => (
          <div style={{ textAlign: "left" }}>Approval Status</div>
        ),
        accessor: "approvalStatus",
        Cell: (props) => (
          <div
            className={`${
              props.value === undefined ? (
                <div style={{ textAlign: "left" }}>N/A</div>
              ) : (
                props.value.toLowerCase()
              )
            }-status`}
            style={{ textAlign: "left" }}
          >
            {props.value === undefined
              ? ""
              : props.value.indexOf("_") !== -1
              ? this.processValuesWithUnderscore(props.value)
              : this.capitalizeFirstLetter(props.value)}
          </div>
        ),
        headerClassName: "table-header",
        className: "table-cell",
        width: 125,
        style: { "white-space": "unset" },
      },
      {
        Header: (props) => (
          <div style={{ textAlign: "left" }}>Request Type</div>
        ),
        accessor: "requestType",
        Cell: (props) => (
          <div style={{ textAlign: "left" }}>
            {props.value !== undefined
              ? this.capitalizeFirstLetter(props.value)
              : props.value}
          </div>
        ),
        headerClassName: "table-header",
        className: "table-cell",
        style: { "white-space": "unset" },
      },

      {
        Header: (props) => (
          <div style={{ textAlign: "left" }}>Terminal Model</div>
        ),
        accessor: "terminalModel",
        Cell: (props) => (
          <div style={{ textAlign: "left" }}>
            {props.value !== undefined
              ? this.capitalizeFirstLetter(props.value)
              : props.value}
          </div>
        ),
        headerClassName: "table-header",
        className: "table-cell",
        width: 110,
        style: { "white-space": "unset" },
      },
      {
        Header: (props) => (
          <div style={{ textAlign: "left" }}>Terminal Type</div>
        ),
        accessor: "terminalType",
        Cell: (props) => (
          <div style={{ textAlign: "left" }}>
            {props.value !== undefined
              ? this.capitalizeFirstLetter(props.value)
              : props.value}
          </div>
        ),
        headerClassName: "table-header",
        className: "table-cell",
        style: { "white-space": "unset" },
      },
      {
        Header: (props) => <div style={{ textAlign: "left" }}>Quantity</div>,
        accessor: "quantity",
        Cell: (props) => <div style={{ textAlign: "left" }}>{props.value}</div>,
        headerClassName: "table-header",
        className: "table-cell",
        width: 80,
        style: { "white-space": "unset" },
      },
      {
        Header: (props) => (
          <div style={{ textAlign: "left" }}>POS Unit Price</div>
        ),
        accessor: "posUnitPrice",
        Cell: (props) => (
          <div style={{ textAlign: "left" }}>
            ₦{`${formatAmount(convertNgkToNgn(props.value))}`}
          </div>
        ),
        headerClassName: "table-header",
        className: "table-cell",
        style: { "white-space": "unset" },
      },
      {
        Header: (props) => <div style={{ textAlign: "left" }}>Total Price</div>,
        accessor: "totalPrice",
        Cell: (props) => (
          <div style={{ textAlign: "left" }}>
            ₦{`${formatAmount(convertNgkToNgn(props.value))}`}
          </div>
        ),
        headerClassName: "table-header",
        className: "table-cell",
        style: { "white-space": "unset" },
      },

      {
        Header: (props) => (
          <div style={{ textAlign: "left" }}>Date Initiated</div>
        ),
        accessor: "dateInitiated",
        Cell: (props) => <div style={{ textAlign: "left" }}>{props.value}</div>,
        headerClassName: "table-header",
        className: "table-cell",
        style: { "white-space": "unset" },
      },
      {
        Header: (props) => <div style={{ textAlign: "left" }}>Action</div>,
        accessor: "paymentStatus",
        Cell: (props) =>
          props.original.approvalStatus != "awaiting_approval" &&
          props.original.approvalStatus != "declined" && (
            <Dropdown>
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-variants-secondary"
              >
                <MdMore
                  fontSize="15px"
                  color={process.env.REACT_APP_GREY_COLOUR}
                />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleOpen(props)}>
                  View
                </Dropdown.Item>
                {process.env.REACT_APP_SHOW_POS && (
                  <Dropdown.Item
                    onClick={() => {
                      localStorage.setItem(
                        "requestId",
                        props.original.requestId
                      );
                      window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/manage-devices/pos-management/pos-receipt-confirmation`;
                    }}
                  >
                    POS Received
                  </Dropdown.Item>
                )}

                {props.value === "pending_payment" && (
                  <Dropdown.Item onClick={() => this.handleRedirectToQT()}>
                    Pay
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          ),
        headerClassName: "table-header",
        className: "table-cell",
        style: { "white-space": "unset" },
        width: 60,
      },
    ];

    return (
      <React.Fragment>
        {this.state.isRequeryLoading ? (
          <ActivityIndicator />
        ) : (
          <ReactTable
            columns={PosRequestColumns}
            data={this.props.data}
            pages={this.props.pages}
            defaultPageSize={50}
            manual
            onFetchData={(state, instance) => {
              this.props.getPosRequestData(
                state.pageSize,
                state.page + 1,
                this.props.domainCode
              );
            }}
            loading={this.state.isLoading}
            minRows={this.props.data.length > 0 ? 3 : 2}
            LoadingComponent={Spinner}
          />
        )}

        {this.state.showModal && (
          <VerticallyCenteredModal
            onHide={handleClose}
            show={this.state.showModal}
            title={`POS Request Detail: `}
            size="sm"
            keyboard="true"
            body={
              <ReactTable
                style={{ color: "#808593" }}
                columns={eachPosColumns}
                data={this.state.transactionDetails}
                onFetchData={() => {
                  this.searchByPosRequestId(this.state.selectedPosRequestId);
                }}
                pages={this.props.pages}
                defaultPageSize={10}
                manual
                loading={this.state.isLoading}
                //minRows={(this.props.data).length > 0 ? 3 : 2}
                LoadingComponent={Spinner}
              />
            }
          />
        )}

        {this.state.showConfirmationModal && confirmationModal}

        {this.state.showConfirmationResponseMsgModal &&
          confirmationResponseMsgModal}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.tunnel.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dismissErrorMessage: () => dispatch(dismissErrorMessage()),
    dismissSuccessMessage: () => dispatch(dismissSuccessMessage()),
    showErrorMessage: (message, keywords) =>
      dispatch(showErrorMessage(message, keywords)),
    showSuccessMessage: (message) => dispatch(showSuccessMessage(message)),
    updateLoading: (isLoading, percentage = null) =>
      dispatch(updateLoading({ isLoading, percentage })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PosRequestTable);
