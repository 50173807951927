import React from "react";
import BackButton from "../../../../../signup/onboarding-setup/component/backButton";
import HeaderSignup from "../../../../../../components/header-signup";
import PrimaryButton from "../../../../../signup/onboarding-setup/component/button";

const AddPassengerScene = ({ setScreen, application }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          gap: "40px",
        }}
      >
        <HeaderSignup />
        <BackButton onClick={() => setScreen(3)} />
      </div>
      <p>AddPassengerScene</p>
      <PrimaryButton
      // disabled={!isFormValid() || isLoading}
      >
        {"Next"}
      </PrimaryButton>
    </>
  );
};

export default AddPassengerScene;