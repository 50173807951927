import React from 'react'

function Tab(props) {
  const { label, activeTab, onCustomClick } = props;

  const onClick = () => {
    onCustomClick(label)
  };

  let tabStyle = 'tab-list-item'

  if (activeTab === label) {
    tabStyle += ' tab-list-active'
  }
  return (
    <li className={tabStyle} onClick={onClick}>
      {label}
    </li>
  )
}

export default Tab
