import React from "react";
import MdArrowDropright from "react-ionicons/lib/MdArrowDropright";

export default class ListItem extends React.Component {
  render() {
    return (
      <div
        className={`service-sub-category${this.props.active ? "-active" : ""}`}
        style={{
          alignItems: "center",
          borderBottom: "0.6px solid #ccc",
          display: !this.props.display ? "flex" : "none",
          fontSize: process.env.REACT_APP_MID_FONT_SIZE,
          justifyContent: "space-between",
          lineHeight: process.env.REACT_APP_MID_LINE_HEIGHT,
          height: "45px",
          width: "100%",
          marginTop: "0px",
          marginBottom: "0px",
          marginLeft: "10px",
          marginRight: "10px",
          paddingBottom: "10px",
          paddingTop: "10px",
          paddingLeft: "15px",
          paddingRight: "0px",
          borderRadius: process.env.REACT_APP_BORDER_STYLE ? "4px" : "24px",
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
        }}
        onClick={() => {
          this.props.onSelect(this.props.value);
        }}
      >
        {this.props.value.name}
        &nbsp;{this.props.value.alias ? `${`(${this.props.value.alias})`}` : ""}
        <MdArrowDropright
          color={
            this.props.active
              ? process.env.REACT_APP_BLUE_COLOUR
              : process.env.REACT_APP_LIGHT_GREY_COLOUR
          }
          fontSize={24}
          style={{
            alignSelf: "center",
            justifyContent: "center",
            marginRight: "5px",
          }}
          onClick={this.props.showNavBar}
        />
      </div>
    );
  }
}
