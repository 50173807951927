import React from "react";
import { Row, Col } from "react-bootstrap";
import { savePDF } from "@progress/kendo-react-pdf";
import Button from "../../../../../components/button";
import H1 from "../../../../../components/h1";
import {
  CashoutlineIcon,
  DownloadIcon,
  QtppIcon,
  successfulIcon,
} from "../../../../../icons";
import { convertNgkToNgn } from "../../../../../utils/converters/currencies";
import {
  formatAmount,
  handleTransactionStatus,
} from "../../../../../utils/formatters";
import "./cashoutModalContent.scss";
import Text from "../../../../../components/text";

const refTrans = React.createRef();

const savePage = (name) => {
  savePDF(refTrans.current, {
    paperSize: "A4",
    fileName: `cashout-receipt.pdf`,
    margin: 20,
    author: "Interswitch",
  });
};

const printPage = () => {
  let mywindow = window.open("", "PRINT", "height=400,width=600");

  mywindow.document.write(`<html><head><title> </title>`);
  mywindow.document.write(`</head><body >`);
  mywindow.document.write(document.getElementById("cashout-receipt").innerHTML);
  mywindow.document.write(`</body></html>`);

  mywindow.document.close();
  mywindow.focus();

  mywindow.print();

  return true;
};

export const CashoutModalContent = (props) => {
  return (
    <>
      <Row>
        <Col xs={12} md={12} className="cashout-column">
          <p>
            <span className="cashout-header-text">Cash out amount </span>
            <br />
            <span className="cashout-sub-text">
              {props.payment &&
                `₦${formatAmount(convertNgkToNgn(props.payment.amount))}`}
            </span>
          </p>
          <p style={{ textAlign: "right" }}>
            <span className="cashout-header-text">Paycode </span>
            <br />
            <span className="cashout-sub-text">
              {props.payment && props.payment.payCode}
            </span>
          </p>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={12} className="cashout-column">
          <p>
            <span className="cashout-header-text">Transaction Reference</span>
            <br />
            <span className="cashout-sub-text">
              {props.payment && props.payment.transactionRef}
            </span>
          </p>
        </Col>
      </Row>

      <Row className="header-cashout-row">
        <Col xs={12} md={12} className="about-cashout">
          <p>{`Your Quickteller wallet will be credited with ₦${formatAmount(
            convertNgkToNgn(props.payment.amount)
          )} after the transaction is complete.`}</p>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={12} className="proceed-button-col">
          <Button
            className="services-proceed-button"
            onClick={(e) => {
              e.preventDefault();
              props.onPaycodeCashoutConfirmation();
            }}
            disabled={props.isLoading}
          >
            {props.payment &&
              `Cash out ₦${formatAmount(
                convertNgkToNgn(props.payment.amount)
              )}`}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export const UsedCashoutModal = (props) => {
  return (
    <>
      <Row>
        <Col xs={12} md={12} className="cashout-column">
          <h6>{props.errorMessage}</h6>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12} className="cashout-column">
          <p>
            <span className="cashout-header-text">Paycode </span>
            <br />
            <span className="cashout-sub-text">{props.paycode}</span>
          </p>
        </Col>
      </Row>

      <Row className="custom-modal-button">
        <Col xs={12} md={12} className="proceed-button-col">
          <Button
            outline
            className="services-proceed-button modified-outline"
            onClick={() => props.cancelClick()}
          >
            Cancel
          </Button>
        </Col>
        <Col xs={12} md={12} className="proceed-button-col"></Col>
      </Row>
    </>
  );
};

export const CashoutSuccessfullModal = () => {
  return (
    <>
      <Row>
        <Col xs={12} md={12} className="cashout-successful-column">
          {successfulIcon()}
          <p className="message">Cash Out Successful</p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12} className="cashout-successful-column">
          <p>
            Your Quickteller wallet will be credited <br />
            with ₦20,000. Cash out the same
            <br /> amount to the customer.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12} className="proceed-button-col">
          <Button className="services-proceed-button">Done</Button>
        </Col>
      </Row>
    </>
  );
};

export const CashoutReceipt = (props) => {
  return (
    <div id="cashout-receipt">
      <Row>
        <Col md={4}>
          <Row className="receipt-logo-column">
            <Col>{QtppIcon()}</Col>
            <Col>
              <p className="cash">{CashoutlineIcon()}</p>
              {/* {props.payment && `₦${formatAmount(convertNgkToNgn(props.payment.amount))}`} */}
              <p>
                Your{" "}
                {props.payment &&
                  `₦${formatAmount(
                    convertNgkToNgn(props.payment.amount)
                  )}`}{" "}
                cash out was {handleTransactionStatus(props.code)}
              </p>
            </Col>
            <Col onClick={printPage}>
              {DownloadIcon()}
              <p>Download Receipt</p>
            </Col>
          </Row>
        </Col>
        <Col md={8} style={{ borderLeft: "1px solid #F3F5F6" }}>
          <Row>
            <Col md={12} className="receipt-column">
              <p className="receipt-header-text">Customer Details</p>
            </Col>
          </Row>
          {/* <Row>
            <Col md={12} className="receipt-column">
              <p className="cashout-header-text">Customer Name</p>
              <p className="cashout-sub-text">Ahmed Uche</p>
            </Col>
          </Row> */}
          <Row>
            <Col md={12} className="receipt-column">
              <p className="cashout-header-text">Phone Number</p>
              <p className="cashout-sub-text">
                {props.payment && props.payment.customerPhoneNo}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="receipt-column">
              <p className="cashout-header-text">Customer ID</p>
              <p className="cashout-sub-text">
                {props.payment && props.payment.customerPhoneNo}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="receipt-column">
              <p className="receipt-header-text">Transaction Details</p>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="receipt-column">
              <p className="cashout-header-text">Transaction Type</p>
              <p className="cashout-sub-text">Cash Out</p>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="receipt-column">
              <p className="cashout-header-text">Amount</p>
              <p className="cashout-sub-text">
                {props.payment &&
                  `₦${formatAmount(convertNgkToNgn(props.payment.amount))}`}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="receipt-column">
              <p className="cashout-header-text">Paycode</p>
              <p className="cashout-sub-text">
                {props.payment && props.payment.payCode}
              </p>
            </Col>
          </Row>
          {/* <Row>
            <Col md={12} className="receipt-column">
              <p className="cashout-header-text">Phone Number</p>
              <p className="cashout-sub-text">Ahmed Uche</p>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="receipt-column">
              <p className="cashout-header-text">Customer Name</p>
              <p className="cashout-sub-text">08097353535</p>
            </Col>
          </Row> */}
          <Row>
            <Col md={12} className="receipt-column">
              <p className="cashout-header-text">Transaction Reference</p>
              <p className="cashout-sub-text">
                {props.payment && props.payment.transactionRef}
              </p>
            </Col>
          </Row>
          {/* <Row>
            <Col md={12} className="receipt-column">
              <p className="cashout-header-text">Payment Method</p>
              <p className="cashout-sub-text">Wallet</p>
            </Col>
          </Row> */}
          <Row>
            <Col md={12} className="receipt-column">
              <p className="cashout-header-text">Transaction Status</p>
              <p className="cashout-sub-text">
                {handleTransactionStatus(props.code)}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="receipt-column">
              <p className="cashout-header-text">Business Name</p>
              <p className="cashout-sub-text">
                {props.currentUser && `${props.currentUser.domainName}`}
              </p>
            </Col>
          </Row>
          {/* <Row style={{marginTop: '15px'}}>
            <Col md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
              <Text style={{fontSize: '15px'}}>Business Name: </Text>{props.currentUser && `${props.currentUser.domainName}`}
              <Text style={{fontSize: '15px', textTransform: 'capitalize'}}></Text>
            </Col>
          </Row>       */}
        </Col>
      </Row>
    </div>
  );
};

export const NewCashoutReceipt = (props) => {
  return (
    <div
      className={`print-receipt ${
        props.category === "cashless-cashout"
          ? "airtime-bill-height"
          : "send-money-height"
      } ${props.deviceIsMobile ? ".mobile-width" : "pc-tab-width"}`}
    >
      <div className="cashout-receipt" id="cashout-receipt" ref={refTrans}>
        <div>
          <img
            alt=""
            style={{
              top: "40px",
              left: "500px",
              width: "100px",
              float: "right",
            }}
            src="https://i.ibb.co/Qbsjn5b/isw.png"
          />
          <div
            style={{
              color: process.env.REACT_APP_BLUE_COLOUR,
              paddingTop: "5px",
            }}
          >
            <h6>
              <b>Thank you for using Quickteller Paypoint!</b>
            </h6>
            <span>Here is your transaction receipt.</span>
            <span>See payment details below.</span>
          </div>
          <H1 style={{ textAlign: "center" }}>Transaction Receipt</H1>

          <Row style={{ marginTop: "15px" }}>
            <Col
              md={12}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Text
                style={{
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  fontWeight: "bold",
                }}
              >
                Phone Number:{" "}
              </Text>
              <Text
                style={{
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  fontWeight: "bold",
                }}
              >
                {props.payment && props.payment.customerPhoneNo}
              </Text>
            </Col>
          </Row>

          <Row style={{ marginTop: "15px" }}>
            <Col
              md={12}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Text
                style={{
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  fontWeight: "bold",
                }}
              >
                Customer ID:{" "}
              </Text>
              <Text
                style={{
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  fontWeight: "bold",
                }}
              >
                {props.payment && props.payment.customerPhoneNo}
              </Text>
            </Col>
          </Row>

          <hr />

          <Row style={{ marginTop: "15px" }}>
            <Col
              md={12}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Text
                style={{
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  fontWeight: "bold",
                }}
              >
                Transaction Type:{" "}
              </Text>
              <Text
                style={{
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  fontWeight: "bold",
                }}
              >
                Cash Out
              </Text>
            </Col>
          </Row>

          <Row style={{ marginTop: "15px" }}>
            <Col
              md={12}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Text
                style={{
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  fontWeight: "bold",
                }}
              >
                Amount:{" "}
              </Text>
              <Text
                style={{
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  fontWeight: "bold",
                }}
              >
                {props.payment
                  ? `NGN${formatAmount(convertNgkToNgn(props.payment.amount))}`
                  : ""}
              </Text>
            </Col>
          </Row>

          <Row style={{ marginTop: "15px" }}>
            <Col
              md={12}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Text
                style={{
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  fontWeight: "bold",
                }}
              >
                Paycode:{" "}
              </Text>
              <Text
                style={{
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  fontWeight: "bold",
                }}
              >
                {props.payment && props.payment.payCode}
              </Text>
            </Col>
          </Row>

          <Row style={{ marginTop: "15px" }}>
            <Col
              md={12}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Text
                style={{
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  fontWeight: "bold",
                }}
              >
                Transaction Reference:{" "}
              </Text>
              <Text
                style={{
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  fontWeight: "bold",
                }}
              >
                {props.payment && props.payment.transactionRef}
              </Text>
            </Col>
          </Row>

          <Row style={{ marginTop: "15px" }}>
            <Col
              md={12}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Text
                style={{
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  fontWeight: "bold",
                }}
              >
                Transaction Status:{" "}
              </Text>
              <Text
                style={{
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  fontWeight: "bold",
                }}
                className={handleTransactionStatus(props.code)}
              >
                {handleTransactionStatus(props.code)}
              </Text>
            </Col>
          </Row>

          <Row style={{ marginTop: "15px" }}>
            <Col
              md={12}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Text
                style={{
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  fontWeight: "bold",
                }}
              >
                Business Name:{" "}
              </Text>
              <Text
                style={{
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  fontWeight: "bold",
                }}
              >
                {props.currentUser && `${props.currentUser.domainName}`}
              </Text>
            </Col>
          </Row>
        </div>
      </div>

      <Row
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "20pt",
        }}
      >
        <Col
          xs={12}
          md={6}
          style={{ marginBottom: `${props.deviceIsMobile && "5px"}` }}
        >
          <Button
            onClick={printPage}
            style={{
              width: "100%",
            }}
          >
            Print
          </Button>
        </Col>
        <Col
          xs={12}
          md={6}
          style={{ marginBottom: `${props.deviceIsMobile && "5px"}` }}
        >
          <Button
            onClick={printPage}
            style={{
              width: "100%",
            }}
          >
            Save
          </Button>
        </Col>
        {/* <Col xs={12} md={4}>
        <Button 
          outline 
          style={{
            width: '100%',
            padding: '9pt 37pt'
          }}
          onClick={props.onCancelProduct}
        >
          Dismiss</Button>
      </Col> */}
      </Row>
    </div>
  );
};
