import React from "react";
import { Form } from "react-bootstrap";
import MdSync from "react-ionicons/lib/MdSync";

const COLOUR = "grey";

const InputWithSelectText = ({
  label,
  placeholder,
  value,
  maxLength,
  onChange,
  labelColor,
  disabled = false,
  type = "text",
  border,
  loading,
  handlePaste,
  handleCopy,
  onBlur,
  flex,
  selectOptions,
  selectValue,
  onSelectChange,
  selectDisabled = false,
}) => {
  return (
    <div style={{ flex: flex }}>
      <Form.Group style={{ position: "relative" }} className="p-0">
        <Form.Label
          style={{
            color: labelColor,
            fontSize: "14px",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
          }}
        >
          {label}
        </Form.Label>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Form.Control
            as="select"
            style={{ border: border, marginRight: "10px", maxWidth: "75px" }}
            value={selectValue}
            onChange={onSelectChange}
            disabled={selectDisabled}
          >
            {selectOptions.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </Form.Control>
          <Form.Control
            style={{
              flex: "auto",
              border: border,
              minWidth: "0", 
             }}
            onBlur={onBlur}
            onCopy={handleCopy}
            onPaste={handlePaste}
            onChange={onChange}
            maxLength={maxLength}
            disabled={disabled}
            value={value}
            required
            autoComplete="off"
            type={type}
            placeholder={placeholder}
          />
          {loading && (
            <div
              style={{
                position: "absolute",
                bottom: "10px",
                right: "10px",
              }}
            >
              <MdSync
                backgroundColor={COLOUR}
                fontSize="20pt"
                rotate={true}
                style={{ marginRight: "5pt" }}
              />
            </div>
          )}
        </div>
      </Form.Group>
    </div>
  );
};

export default InputWithSelectText;
