import React from 'react';
import { Row } from 'react-bootstrap';
import MdArrowBack from 'react-ionicons/lib/MdArrowBack';
import H1 from '../../../../../components/h1';
import { usePosRequestContext } from '../context/pos-Request-context';

const BackArrow = ({ screen, setScreen }) => {
  const { setValue, setAmount } = usePosRequestContext();
  return (
    <Row
      xs={4}
      lg={4}
      style={{
        padding: '0 0 30px 15px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {screen === 1 || screen === 4 || (
        <MdArrowBack
          fontSize='26px'
          style={{ marginRight: '30px', cursor: 'pointer' }}
          onClick={() => {
            if (screen === 2) {
              setValue(null);
            }
            setAmount(1);
            setScreen(screen - 1);
          }}
        />
      )}
      {screen === 4 ? '' : <H1>Request POS</H1>}
    </Row>
  );
};

export default BackArrow;
