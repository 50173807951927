import React, { useEffect } from 'react';

import BackButton from '../../onboarding-setup/component/backButton';
import PrimaryButton from '../../onboarding-setup/component/button';
import ProfileUpgradeCTA from '../../onboarding-setup/component/profileUpgradeCTA';
import SkeletonLoading from '../../onboarding-setup/component/skeletonLoading';
import H1 from '../../../../components/h1';
import { BadgeIcon } from '../../../../icons';

const SetupNewAgentScene = ({
  setScreen,
  props,
  application,
  fetchApplication,
  isLoading,
}) => {
  const agentDetails = JSON.parse(localStorage.getItem('agentDetails'));

  const upgradeTo = localStorage.getItem('upgradeTo');

  let personalDetailsForm = JSON.parse(
    localStorage.getItem('personalDetailsFormUpgrade')
  );

  let kycDetailsForm = JSON.parse(
    localStorage.getItem('kycDetailsFormUpgrade')
  );
  let businessDetailsForm = JSON.parse(
    localStorage.getItem('businessDetailsFormUpgrade')
  );
  let refereeDetailsForm = JSON.parse(
    localStorage.getItem('refereeDetailsFormUpgrade')
  );

  const handleKYC = () => {
    if (
      personalDetailsForm?.motherMaidenName &&
      businessDetailsForm.accountNumber
    ) {
      setScreen(4);
    }
  };
  const handleBussinessDetails = () => {
    if (personalDetailsForm?.motherMaidenName) {
      setScreen(3);
    }
  };

  const handlerefereeDetails = () => {
    if (
      personalDetailsForm?.motherMaidenName &&
      businessDetailsForm.accountNumber
      // kycDetailsForm.idNumber
    ) {
      setScreen(5);
    }
  };

  useEffect(() => {
    if (agentDetails && !personalDetailsForm) {
      fetchApplication();
    }
  }, []);

  const handleSubmit = () => {
    if (
      personalDetailsForm?.motherMaidenName &&
      businessDetailsForm?.accountNumber
    ) {
      setScreen(4);
    } else if (personalDetailsForm?.motherMaidenName) {
      setScreen(3);
    } else {
      setScreen(2);
    }
  };

  const handleBackButton = () => {
    if (application) {
      // props.navigateTo(`/agents/my-agents/upgrade/upgradeClass`);
      props.navigateTo(`/manage-profile/upgrade-list`);

      localStorage.removeItem('application');
      localStorage.removeItem('businessType');
      localStorage.removeItem('personalDetailsFormUpgrade');
      localStorage.removeItem('kycDetailsFormUpgrade');
      localStorage.removeItem('businessDetailsFormUpgrade');
    } else {
      // props.navigateTo(`/agents/my-agents/upgrade/upgradeClass`);
      props.navigateTo(`/manage-profile/upgrade-list`);
    }
  };

  const Loading = () => {
    return (
      <div
        style={{
          marginBottom: '25px',
        }}
      >
        {' '}
        <SkeletonLoading width={'100%'} height={'15px'} />{' '}
        <SkeletonLoading width={'100%'} height={'15px'} />
      </div>
    );
  };

  return (
    <>
      <BackButton onClick={handleBackButton} />
      <main className='aggregator-container'>
        <div>
          <H1
            style={{
              textAlign: 'left',
              marginBottom: '1rem',
            }}
          >
            Upgrade to {upgradeTo}
          </H1>
          <p
            style={{
              color: '#6B7280',
              fontSize: '0.75rem',
              marginBottom: '3rem',
            }}
          >
            The following details below are required to upgrade your account.
          </p>
        </div>

        <div style={{
          padding: '1rem 1.5rem',
          borderRadius: '8px',
          border: `1px solid #A8D6EF`,
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: '#EBF8FE'
        }}>
          <div>
            <p
              style={{
                fontSize: '0.875rem',
                margin: 0,
                fontWeight: 600,
                color: '#1F2126',
                marginBottom: '10px',
              }}
            >
              {upgradeTo} Account
            </p>
            <p
              style={{
                fontSize: '0.775rem',
                margin: 0,
                color: '#353F50',
              }}
            >
              Please fill the information below to upgrade to a standard account.
            </p>
          </div>
          <div>
            {BadgeIcon()}
          </div>

        </div>

        {isLoading ? (
          <Loading />
        ) : (
          <ProfileUpgradeCTA
            application={application}
            onClick={() => setScreen(2)}
            actionColor={
              personalDetailsForm
                ? personalDetailsForm?.motherMaidenName
                : false
            }
            title={'Personal Details'}
            checkClickableTile={
              personalDetailsForm ? personalDetailsForm?.motherMaidenName : true
            }
            isLoading={isLoading}
            sub={`Provide your residential address, maiden name, state and LGA`}
          />
        )}

        {isLoading ? (
          <Loading />
        ) : (
          <ProfileUpgradeCTA
            application={application}
            actionColor={
              businessDetailsForm ? businessDetailsForm.accountNumber : false
            }
            onClick={() => handleBussinessDetails()}
            checkClickableTile={
              personalDetailsForm ? personalDetailsForm?.motherMaidenName : null
            }
            title={'Business Details'}
            isLoading={isLoading}
            sub={`Provide your business type and bank information.`}
          />
        )}

        {isLoading ? (
          <Loading />
        ) : (
          <ProfileUpgradeCTA
            application={application}
            onClick={() => handleKYC()}
            // onClick={application ? handleKYCApplicationId : handleKYC}
            actionColor={
              kycDetailsForm ? kycDetailsForm.attachedDocuments : false
            }
            title={'KYC Information'}
            checkClickableTile={
              businessDetailsForm ? businessDetailsForm.accountNumber : null
            }
            isLoading={isLoading}
            sub={`Provide your ID information and utility bill.`}
          />
        )}

        {isLoading ? (
          <Loading />
        ) : (
          <ProfileUpgradeCTA
            application={application}
            actionColor={
              refereeDetailsForm ? refereeDetailsForm.phoneNumber : false
            }
            onClick={() => handlerefereeDetails()}
            checkClickableTile={
              businessDetailsForm ? businessDetailsForm.accountNumber : null
            }
            title={'Referee Information'}
            isLoading={isLoading}
            sub={`Provide your referee details here.`}
          />
        )}

        <div
          style={{
            margin: '0 auto',
          }}
        >
          <PrimaryButton disabled={isLoading} onClick={handleSubmit}>
            Submit
          </PrimaryButton>
        </div>
      </main>
    </>
  );
};

export default SetupNewAgentScene;
