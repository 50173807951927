import React, { useEffect, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import { userManagementServiceClient } from '../../../../../App';
import { firetoast } from '../../../../../components/extras';
import Text from '../../../../../components/text';
import { ERROR_STATUS, SUCCESS_STATUS } from '../../../../../constants/api';
import POSIMAGE from '../../../../../icons/pos-image.png';
import { usePosRequestContext } from '../context/pos-Request-context';
import qs from 'qs';

const SelectRequestType = ({ setScreen }) => {
  const { value, setValue, setRequestId, amount, setAmount } =
    usePosRequestContext();
  const deviceIsTab =
    qs.parse(window.location.search)['?mobile'] || window.innerWidth <= 992;

  const [agree, setAgree] = useState(false);
  const [agentDetails, setAgentDetails] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const agentDetails = JSON.parse(localStorage.getItem('currentAgent'));
    setAgentDetails(agentDetails);
  }, []);

  const options = [
    { value: 'Free', label: 'Free' },
    { value: 'Purchase', label: 'Purchase' },
  ];
  const [requestType, setRequestType] = useState(options[0]);
  const increase = () => {
    setAmount((oldAmount) => {
      let tempAmount = oldAmount + 1;

      return tempAmount;
    });
  };
  const decrease = () => {
    setAmount((oldAmount) => {
      let tempAmount = oldAmount - 1;
      if (tempAmount < 1) {
        tempAmount = 1;
      }
      return tempAmount;
    });
  };

  const handleRequest = async () => {
    const payload = {
      posModel: value.label,
      posType: value.terminalType,
      quantity: parseInt(amount),
      requestDeviceChannel: 'WEB',
      requestType: requestType.value === 'Free' ? 'free' : 'paid',
      termsCondition: true,
    };
    setLoading(true);
    const { status, response } =
      await userManagementServiceClient.validatePosRequest(payload);
    if (status === ERROR_STATUS) {
      firetoast('error', 'Request Failed', `${response.description}`);
      setLoading(false);
    } else if (status === SUCCESS_STATUS) {
      setRequestId(response.data);
      setLoading(false);
      setScreen(3);
      setAmount(amount);
    }
  };

  return (
    <>
      <div className='pos-request-label'>POS Model: *</div>{' '}
      <Select
        isDisabled={true}
        defaultValue={value}
        className='select-pos-request-component sm:w-100'
        classNamePrefix='react-select'
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: 'rgb(178, 212, 255)',
          },
        })}
      />
      <section className='pos-request-section-container'>
        <article className='pos-request-article1'>
          <img
            style={{
              width: '100%',
              height: '27rem',
              objectFit: 'cover',
              borderRadius: '10px',
            }}
            src={value.terminalImage ? value.terminalImage : POSIMAGE}
            alt='pos terminal'
          />
        </article>
        <div className='page-two-section'>
          <div>
            <div
              style={{
                marginBottom: '20px',
                marginTop: deviceIsTab ? '30px' : '0',
              }}
            >
              <div className='pos-request-label'>Request Type: *</div>{' '}
              <Select
                options={options}
                onChange={(event) => {
                  setRequestType(event);
                }}
                defaultValue={options[0]}
                className='w-100'
                classNamePrefix='react-select'
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: 'rgb(178, 212, 255)',
                  },
                })}
              />
            </div>
            <div>
              <div className='pos-request-label'>Quantity: *</div>{' '}
              <div className='amount-btn-container'>
                <button type='button' className='amount-btn' onClick={decrease}>
                  -
                </button>
                <h2 className='amount-pos'>{amount}</h2>
                <button type='button' className='amount-btn' onClick={increase}>
                  +
                </button>
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: deviceIsTab ? '70px' : '180px',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                gap: deviceIsTab ? '5px' : '10px',
                marginBottom: deviceIsTab ? '0' : '10px',
              }}
            >
              <Form.Check
                type='checkbox'
                onChange={(event) => {
                  setAgree(!agree);
                }}
              />

              <Text mid style={{ fontSize: '16px' }}>
                I agree to Quickteller Paypoint{' '}
                <a
                  href={`${process.env.REACT_APP_PUBLIC_URL}/documents/POS Agreement Template Outright Payment.pdf`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Terms and Conditions
                </a>
              </Text>
            </div>

            <button
              onClick={handleRequest}
              className='request-pos-article2-button'
              disabled={!agree || loading}
            >
              {loading ? 'Loading...' : 'Submit Request'}
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default SelectRequestType;
