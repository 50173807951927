import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ProductPayment from "../../components/product-payment";
import { DISTRIBUTE } from "../../../../../../constants";
import BaseScene from "../../../../../base-scene";

import EntryPoint from "./entrypoint/entryPoint";

export default class DistributeScene extends BaseScene {
  state = {};

  get service() {
    return {
      name: "Distribute",
    };
  }

  render() {
    const screenSensitiveStyle = this.state.deviceIsMobile
      ? {
          paddingRight: 0,
          marginRight: 0,
        }
      : {};

    return (
      <Container fluid className="container">
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "0px",
            padding: "0px",
          }}
        >
          <Col
            xs={12}
            md={12}
            lg={12}
            style={{ marginLeft: 0, paddingLeft: 0, ...screenSensitiveStyle }}
          >
            {process.env.REACT_APP_SHOW_PERORMANCE === "true" ? (
              <EntryPoint />
            ) : (
              <ProductPayment
                category={DISTRIBUTE}
                service={this.service}
                onCancelProduct={() => this.props.onClose()}
                serviceTypeId={process.env.REACT_APP_AIRTIME_AND_DATA_ID}
              />
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}
