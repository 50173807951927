import React from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import BaseScene from '../scenes/base-scene';

const HeaderSignup = () => {
  const history = useHistory();
  const landingPage = () => {
    history.push('/')
    localStorage.removeItem('personalDetailsForm');
    localStorage.removeItem('application');

  }

  return (
    // <div onClick={landingPage}>

    <Link to="/dashboard">
      <img
        src={
          'https://mufasa.interswitchng.com/p/quickteller-paypoint/Assets/header-logo.svg'
        }
        style={{
          cursor: 'pointer',
          marginLeft: '-20px',
          marginTop: '-13px',
        }}
        height={'70px'}
        color={'blue'}
        alt=''
      />
    </Link>
  );
}

export default HeaderSignup;
