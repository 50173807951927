export default class ApplicationSerializers {
  requiredFields = {
    'applicantDetails': [
      'firstName',
      'surname',
      'phoneNumber',
      'emailAddress',
      'nationality',
      'address',
      'state',
      'localGovernmentArea',
      'closestLandMark',
      'dob',
      'gender',
      'placeOfBirth',
      'identificationType',
      'identificationNumber',
      'bvn',
      'mothersMaidenName',
      'howYouHeardAboutUs',
    ],
    'businessDetails': [
      'businessName',
      'address',
      'companyRegistrationNumber',
      'phoneNumber',
      'businessType',
      'bankName',
      'accountNumber',
      'state',
      'localGovernmentArea'
    ],
    'nextOfKinDetails': [
      'firstName',
      'surname',
      'phoneNumber',
      'gender',
      'relationship',
      'address'
    ]
  }
  
  constructor(props) {
    Object.assign(this, props);

    this.isApproved = props.approvalStatus === 'APPROVED';
    this.isAwaitingApproval = props.approvalStatus === 'AWAITING_APPROVAL';
    this.isAwaitingValidation = props.approvalStatus === 'AWAITING_VALIDATION';
    this.isDraftApplication = props.applicationType === 'DRAFT';
    this.isRejected = props.approvalStatus === 'REJECTED';
    this.isApplicationValid = !this.isApproved && !this.isAwaitingApproval && !this.isAwaitingValidation && !this.isDraftApplication && !this.isRejected;
  }

  get numberOfRequiredFields() {
    return (
      this.requiredFields.applicantDetails.length + 
      this.requiredFields.businessDetails.length + 
      this.requiredFields.nextOfKinDetails.length
    );
  }

  get missingFields() {
    const missingApplicantDetails = this.requiredFields.applicantDetails.filter(
      (value) => this.applicantDetails[value] === undefined
    );
    const missingBusinessDetails = this.businessDetails ? this.requiredFields.businessDetails.filter(
      (value) => this.businessDetails[value] === undefined
    ) : this.requiredFields.businessDetails;
    const missingNextOfKinDetails = this.applicantDetails.nextOfKin ? this.requiredFields.nextOfKinDetails.filter(
      (value) => this.applicantDetails.nextOfKin[value] === undefined
    ) : this.requiredFields.nextOfKinDetails;

    return [
      ...missingApplicantDetails,
      ...missingBusinessDetails,
      ...missingNextOfKinDetails,
    ];
  }
}
