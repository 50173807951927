import { debounce } from "lodash";
import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import MdSearch from "react-ionicons/lib/MdSearch";

import MdArrowBack from "react-ionicons/lib/MdArrowBack";
import ActivityIndicator from "../../../../../../components/activity-indicator";
import FormControl from "../../../../../../components/form-control";
import H1 from "../../../../../../components/h1";
import { PAY_A_BILL } from "../../../../../../constants";
import {
  ERROR_STATUS,
  HTTP_NOT_FOUND_CODE,
  SUCCESS_STATUS,
} from "../../../../../../constants/api";
import Services from "../../../../../../fixtures/services";
import ListItem from "../../components/list-item";
import ProductPayment from "../../components/product-payment";
import ProductThumbnail from "../../components/product-thumbnail";
// import Quickteller from "../../../../../../services/api/resources/quickteller";
import Button from "../../../../../../components/button";
import Catalog from "../../../../../../services/api/resources/catalog";
import Quickteller from "../../../../../../services/api/resources/quickteller";
import { QUICKTELLER_TERMINAL_ID } from "../../../../../../utils/api-properties";
import BaseScene from "../../../../../base-scene";
import SearchServicesTab from "../../components/search-services-tab";
import QTPTransport from "../qt-transport/qtTransport";

const payBillServices = Services[PAY_A_BILL];
class ServicesTab extends BaseScene {
  state = {
    isLoading: true,
    services: [],
  };

  quickteller = new Quickteller();
  catalog = new Catalog();

  constructor(props) {
    super(props);

    this.onBackButtonClick = this.onBackButtonClick.bind(this);
  }

  componentDidMount() {
    this.getServices();
    // setTimeout(() => {this.setState({isLoading: false})}, 2000)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.serviceType.id !== this.props.serviceType.id) {
      this.refresh();
    }
  }

  async getServices() {
    const getServicesResponseObj =
      process.env.REACT_APP_SHOW_QUICKTELLER_SERVICE_URL === "true"
        ? await this.catalog.getServices2(
            this.props.serviceType.id,
            QUICKTELLER_TERMINAL_ID
          )
        : await this.catalog.getServices(
            this.props.serviceType.id,
            QUICKTELLER_TERMINAL_ID
          );
    const { status, response } = getServicesResponseObj;

    if (status === ERROR_STATUS) {
      this.props.showErrorMessage("Unable to Fetch Service, Try again");
      setTimeout(() => {
        this.props.dismissErrorMessage();
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
      this.setState({
        isLoading: false,
        erroroccurred: true,
      });

      return;
    }

    this.setState({
      isLoading: false,
      services: response.services,
    });
  }

  onBackButtonClick() {
    this.props.onBackButtonClick();
  }

  refresh() {
    this.setState({
      isLoading: true,
      services: [],
    });

    this.getServices();
  }

  render() {
    return (
      <section
        className={`services-tab${this.props.deviceIsMobile ? "-mobile" : ""}`}
      >
        <H1
          style={{
            marginBottom: "10px",
            marginTop: "10px",
            textAlign: "left",
            width: "100vw",
            display: "flex",
            alignItems: "center",
          }}
        >
          {this.state.deviceIsMobile && (
            <div
              style={{
                alignItems: "center",
                display: "flex",
                marginRight: "10px",
              }}
            >
              <MdArrowBack fontSize="26px" onClick={this.onBackButtonClick} />
            </div>
          )}
          {this.props.serviceType.name}
        </H1>
        {this.props.serviceType.name === "QTP Transport" &&
        process.env.REACT_APP_SHOW_QTP_TRANSPORTATION === "true" ? (
          <QTPTransport />
        ) : (
          <>
            {this.state.isLoading || !this.state.services ? (
              <ActivityIndicator />
            ) : (
              this.state.services.map((value) => (
                <>
                  <ProductThumbnail
                    onSelect={this.props.onSelect}
                    product={value}
                  />
                </>
              ))
            )}
          </>
        )}
      </section>
    );
  }
}

class ServiceTypesTab extends BaseScene {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: "",
      selectedServiceType: null,
      samplePayBillServices: payBillServices,
      filteredPayBillServices: payBillServices,
    };

    this.onBackButtonClick = this.onBackButtonClick.bind(this);
    this.handleUniversalSearch = this.handleUniversalSearch.bind(this);
  }

  onBackButtonClick() {
    if (this.state.selectedServiceType === null && this.state.deviceIsMobile) {
      this.props.onClose();
      return;
    }

    this.setState({ selectedServiceType: null });
    this.props.onSelect(null);
  }

  handleUniversalSearch = debounce((searchTerm) => {
    this.props.serviceUniversalSearch(searchTerm);
  }, 1000);

  get searchButton() {
    const { isSearchLoading, searchTerm } = this.state;

    return (
      <Button
        disabled={searchTerm === "" || isSearchLoading}
        id="select-service-type-search-button"
        onClick={() => {
          this.setState({
            isSearchLoading: true,
          });
          this.props.onSelect();
          // this.props.hideServiceCategories();
          this.props.serviceUniversalSearch(searchTerm).then(() =>
            this.setState({
              isSearchLoading: false,
            })
          );
        }}
      >
        {isSearchLoading ? (
          <ActivityIndicator style={{ height: "auto" }} />
        ) : (
          <MdSearch color={"white"} />
        )}
      </Button>
    );
  }

  render() {
    const { filteredPayBillServices } = this.state;

    return (
      <section style={{ backgroundColor: "white" }}>
        <div>
          <div>
            <Form.Group
              controlId="searchServicesTypes"
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "10px",
                paddingTop: "20px",
              }}
            >
              <FormControl
                type="text"
                placeholder="Find a biller (eg. DStv)"
                onChange={(e) => {
                  const searchTerm = e.target.value;
                  this.props.onSelect();
                  this.handleUniversalSearch(searchTerm);
                  this.setState({
                    searchTerm,
                  });
                }}
                style={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "20px",
                  border: "1px solid white",
                  boxSizing: "border-box",
                  elevation: "60deg",
                  boxShadow: "0px 4px 12px rgba(107,107,107,0.2)",
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  lineHeight: "17px",
                  padding: "20px",
                }}
              />
              {/* {this.searchButton} */}
            </Form.Group>
            {(this.props.selectedServiceType ||
              this.state.deviceIsMobile ||
              this.props.services ||
              this.props.slug) && (
              <div
                style={{ cursor: "pointer", width: "100vw" }}
                onClick={this.onBackButtonClick}
              >
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    marginLeft: "10px",
                  }}
                >
                  <MdArrowBack
                    fontSize="26px"
                    onClick={
                      this.props.services
                        ? this.props.onBackButtonClick
                        : this.onBackButtonClick
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="services-type-tab">
          <div className="services-type-tab">
            {filteredPayBillServices
              .filter(
                (service) =>
                  !(
                    service.id === 80 &&
                    process.env.REACT_APP_SHOW_QTP_TRANSPORTATION === "true"
                  )
              )
              .map((value, idx) => (
                <ListItem
                  key={idx}
                  value={value}
                  onSelect={(value) => {
                    this.props.onSelect(value);
                  }}
                  active={this.props.selectedServiceType === value}
                />
              ))}
          </div>
        </div>
      </section>
    );
  }
}

export default class PayABillScene extends BaseScene {
  // quickteller = new Quickteller();
  catalog = new Catalog();
  quickteller = new Quickteller();

  constructor(props) {
    super(props);

    this.state = {};
    this.serviceUniversalSearch = this.serviceUniversalSearch.bind(this);
  }

  onBackButtonClick = () => {
    this.props.hideServiceCategories();
    this.setState({
      searchServices: null,
    });
  };

  componentDidMount() {
    this.props.slug && this.getOptions(this.props.slug);
  }

  async getOptions(urlName) {
    this.setState({
      isSearchLoading: true,
    });
    const { status, code, response } = await this.catalog.getOptions(
      urlName,
      QUICKTELLER_TERMINAL_ID
    );
    // const getOptionsResponseStatus = getOptionsResponse.status;
    // const getOptionsResponseObj = getOptionsResponse.response;
    // const getOptionsResponseCode =

    if (status === ERROR_STATUS) {
      if (code === HTTP_NOT_FOUND_CODE) {
        return this.serviceUniversalSearch(urlName);
      }
      this.props.showErrorMessage("Unable to Fetch Service, Try again");
      setTimeout(() => {
        this.props.dismissErrorMessage();
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
      this.setState({
        errorLoading: false,
        isSearchLoading: false,
      });
      return;
    }

    this.setState({
      isSearchLoading: false,
      selectedService: response,
    });
  }

  async serviceUniversalSearch(searchTerm) {
    if (searchTerm === "") {
      return;
    }
    this.setState({
      isSearchLoading: true,
      searchServices: null,
    });
    const { status, code, response } = await this.catalog.search(searchTerm);

    if (status === SUCCESS_STATUS) {
      const filteredResponse = response.filter((value) => {
        return (
          value.name.toLowerCase().indexOf("mtn") === -1 &&
          value.name.toLowerCase().indexOf("9mobile") === -1 &&
          value.name.toLowerCase().indexOf("airtel") === -1
        );
      });
      this.setState({
        searchServices: filteredResponse,
        isSearchLoading: false,
      });
    } else {
      this.props.showErrorMessage("Unable to Fetch Service, Try again");
      setTimeout(() => {
        this.props.dismissErrorMessage();
      }, parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
      this.setState({
        isSearchLoading: false,
      });
    }
  }

  render() {
    const desktopContainer = (
      <Row style={{ margin: "0px", padding: "0px" }}>
        <Col md={4} style={{ margin: "0px", padding: "0px" }}>
          <ServiceTypesTab
            dismissErrorMessage={this.props.dismissErrorMessage}
            hideServiceCategories={this.props.hideServiceCategories}
            onBackButtonClick={this.onBackButtonClick}
            onSelect={(value) => {
              this.setState({
                selectedServiceType: value,
                selectedService: null,
              });
              this.props.onSelect(value);
            }}
            selectedServiceType={this.state.selectedServiceType}
            showErrorMessage={this.props.showErrorMessage}
            serviceUniversalSearch={this.serviceUniversalSearch}
            services={this.state.searchServices}
            slug={this.props.slug}
          />
        </Col>
        {this.state.isSearchLoading && (
          <Col md={7}>
            <ActivityIndicator />
          </Col>
        )}
        {this.state.searchServices &&
          !this.state.selectedServiceType &&
          !this.state.selectedService && (
            <Col md={7}>
              <SearchServicesTab
                isLoading={this.state.isSearchLoading}
                services={this.state.searchServices}
                onSelect={(value) => {
                  this.setState({ selectedService: value });
                  this.props.onSelect(value);
                }}
                onBackButtonClick={this.onBackButtonClick}
                serviceType={this.state.selectedServiceType}
                searchType="pay-a-bill"
              />
            </Col>
          )}

        {this.state.selectedServiceType && !this.state.selectedService && (
          <Col md={7}>
            <ServicesTab
              onSelect={(value) => {
                this.setState({ selectedService: value });
                this.props.onSelect(value);
              }}
              serviceType={this.state.selectedServiceType}
              showErrorMessage={this.props.showErrorMessage}
              dismissErrorMessage={this.props.dismissErrorMessage}
            />
          </Col>
        )}
        {this.state.selectedService && (
          <Col md={8} style={{ marginLeft: "0px", paddingLeft: "0px" }}>
            <ProductPayment
              category={PAY_A_BILL}
              service={this.state.selectedService}
              onCancelProduct={() => this.setState({ selectedService: null })}
            />
          </Col>
        )}
      </Row>
    );

    const mobileContainer = (
      <Row style={{ margin: "0px", padding: "0px" }}>
        {!this.state.selectedServiceType && !this.state.selectedService && (
          <>
            <Col
              md={this.state.selectedServiceType ? 4 : 5}
              hidden={this.state.searchServices}
              style={{
                margin: "0px",
                padding: "0px",
              }}
            >
              {/* {console.log(this.state.selectedServiceType, "this is a selected service")} */}
              {!this.state.isSearchLoading ? (
                <ServiceTypesTab
                  {...this.props}
                  onSelect={(value) => {
                    this.setState({ selectedServiceType: value });
                    this.props.onSelect(value);
                  }}
                  selectedServiceType={this.state.selectedServiceType}
                  showErrorMessage={this.props.showErrorMessage}
                  dismissErrorMessage={this.props.dismissErrorMessage}
                  serviceUniversalSearch={this.serviceUniversalSearch}
                  services={this.state.searchServices}
                  onBackButtonClick={this.onBackButtonClick}
                  slug={this.props.slug}
                />
              ) : (
                <ActivityIndicator />
              )}
            </Col>

            {this.state.searchServices &&
              !this.state.selectedServiceType &&
              !this.state.selectedService && (
                <Col md={7}>
                  <SearchServicesTab
                    deviceIsMobile
                    isLoading={this.state.isSearchLoading}
                    services={this.state.searchServices}
                    onSelect={(value) => {
                      this.setState({ selectedService: value });
                      this.props.onSelect(value);
                    }}
                    onBackButtonClick={this.onBackButtonClick}
                    serviceType={this.state.selectedServiceType}
                    searchType="pay-a-bill"
                  />
                </Col>
              )}
          </>
        )}

        {this.state.selectedServiceType && !this.state.selectedService && (
          <Col md={12} style={{ margin: 0, padding: 0 }}>
            <ServicesTab
              {...this.props}
              deviceIsMobile
              onSelect={(value) => {
                this.setState({ selectedService: value });
                this.props.onSelect(value);
              }}
              onBackButtonClick={() => {
                this.setState({ selectedServiceType: null });
              }}
              serviceType={this.state.selectedServiceType}
              showErrorMessage={this.props.showErrorMessage}
              dismissErrorMessage={this.props.dismissErrorMessage}
            />
          </Col>
        )}

        {this.state.selectedService && (
          <Col md={12} style={{ margin: 0, padding: 0 }}>
            <ProductPayment
              category={PAY_A_BILL}
              service={this.state.selectedService}
              onCancelProduct={() => this.setState({ selectedService: null })}
            />
          </Col>
        )}
      </Row>
    );

    return (
      <Container fluid className="container">
        {this.state.deviceIsPC ? desktopContainer : mobileContainer}
      </Container>
    );
  }
}
