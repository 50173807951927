import { connect } from "react-redux";
import {
  dismissErrorMessage,
  showErrorMessage,
  updateLoading,
} from "../../../../services/redux/actions/tunnel";
import AccountDowngradeScene from "./scene";

function mapStateToProps(state) {
  return {
    isLoading: state.tunnel.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateLoading: (isLoading) => dispatch(updateLoading(isLoading)),
    dismissErrorMessage: () => dispatch(dismissErrorMessage()),
    showErrorMessage: (data) => dispatch(showErrorMessage(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountDowngradeScene);
