// src/components/ImageUploader.js

import React from "react";
import Lightbox from "react-image-lightbox";
import IosRefresh from "react-ionicons/lib/IosRefresh";

const IMAGE_FORMATS = ["png", "jpg", "jpeg", "bimp"];
const DocumentUploader = ({
  showLightbox,
  state,
  setShowLightbox,
  fileInputRef,
  onChange,
  handleDocument,
  handleCancel,
  fileFormat,
  loading,
  label,
  loadingCancelDoc,
}) => {
  return (
    <div>
      <label>{label}</label>
      <div
        style={{
          alignItems: "center",
          backgroundColor: "#dcdcdc6d",
          borderRadius: "4px",
          border: "1px solid   #D3D3D3",
          cursor: "pointer",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100px",
          marginRight: "10px",
          width: "200px",
        }}
        className="position-relative"
      >
        {loading ? (
          <IosRefresh
            style={{ width: "24px" }}
            fontSize="100px"
            color="grey"
            rotate={true}
          />
        ) : (
          <>
            {state ? (
              <>
                {fileFormat &&
                IMAGE_FORMATS.includes(fileFormat.toLowerCase()) ? (
                  <img
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                    src={state}
                    alt="Uploaded"
                    onClick={() => setShowLightbox(true)}
                  />
                ) : (
                  <embed
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                    onClick={() => setShowLightbox(true)}
                    name="plugin"
                    src={state}
                  />
                )}
              </>
            ) : (
              <div
                style={{
                  width: "80%",
                  textAlign: "center",
                  fontSize: "14px",
                }}
                onClick={handleDocument}
              >
                Upload a file or drag and drop DOCX, DOC, PDF, PNG, JPG upto
                10MB"
              </div>
            )}
          </>
        )}
        <input
          ref={fileInputRef}
          type="file"
          accept=".pdf, .png, .jpg, .jpeg"
          onChange={onChange}
          style={{ display: "none" }}
        />
      </div>
      {showLightbox && (
        <>
          <Lightbox
            mainSrc={state}
            onCloseRequest={() => setShowLightbox(false)}
          />
        </>
      )}
      {state && !loading && (
        <>
          {loadingCancelDoc ? (
            <IosRefresh
              style={{ width: "24px" }}
              fontSize="25px"
              color="grey"
              rotate={true}
            />
          ) : (
            <span
              style={{
                cursor: "pointer",
                fontSize: "14px",
              }}
              className=" text-danger cursor-pointer"
              onClick={handleCancel}
            >
              Remove
            </span>
          )}
        </>
      )}
    </div>
  );
};

export default DocumentUploader;
