import React, { useEffect, useState } from "react";

import { Col, Form, Row } from "react-bootstrap";

import Lightbox from "react-image-lightbox";
import {
  ERROR_STATUS,
  SUCCESS_STATUS,
} from "../../../../../constants/api";
import {
  ADDRESS_VERIFICATION_FORM,
  BUSINESS_DOC_SUFFIX,
  CHARACTER_ATTESTATION,
  THREE_MB,
  UTILITY_BILL,
} from "../../../../../constants";

import Title from "../component/title";
import { MAX_IDENTIFICATION_NUMBER_LENGTH } from "../../../../../constants/form-fields";
import Thumbnail5 from "../../../../../components/thumbnail-5";
import Platform from "../../../../../services/api/resources/platform";
import { firetoastAggregator } from "../../../../../components/extras";
import PrimaryButton from "../component/button";
import { isIdentificationNumberValid, errorResponse } from "../component/extras";
import InputText from "../component/input";
import SelectInput from "../component/select";
import H1 from "../../../../../components/h1";
import BackButton from '../component/backButton';


const meansOfId = [
  "Passport",
  "Drivers License",
  "International Passport",
  "Voter's Card",
  "National Identification Number",
];
const KycDocumentScene = ({ setScreen, props }) => {
  const application = JSON.parse(
    localStorage.getItem("currentAgent")
  );

  const [showLightbox, setShowLightbox] = useState(false);

  const imgStyle = {
    borderRadius: "5px",
    height: "7rem",
    border: "1px solid grey",
    width: "7rem",
    cursor: "pointer",
    objectFit: "cover",
  };

  const getDocumentUrl = (documentLink) => {
    let convertedDocument = documentLink.replace(
      "/data",
      process.env.REACT_APP_ONBOARDING_BASE_URL
    );
    return convertedDocument;
  };
  const convertFileNameFetch = (fileName) => {
    if (fileName.toLowerCase().includes("pass")) {
      return "Passport Photo";
    } else if (
      fileName.toLowerCase().includes("proof") ||
      fileName.toLowerCase().includes("id")
    ) {
      return "Means of Identification";
    } else if (fileName.toLowerCase().includes("util")) {
      return "Utility Bill";
    } else if (fileName.toLowerCase().includes("char")) {
      return "Character Form";
    }
  };

  const [selectedDocument, setSelectedDocument] = useState(null);
  const handleDocumentClick = (documentName) => {
    setSelectedDocument(documentName);
    setShowLightbox(true);
  };

  return (
    <>
      <BackButton onClick={() => setScreen(2)} />
      <main className="aggregator-container">
        <Col
          style={{
            margin: "20px auto",
          }}
        >
          <H1
            style={{
              textAlign: "left",
              fontWeight: 600,
              marginBottom: ".2rem",
            }}
          >
            KYC Information
          </H1>
          <p
            style={{
              color: "#6B7280",
              fontSize: "0.75rem",
              marginBottom: "2rem",
            }}
          >
            Kindly upload required documents
          </p>
          <SelectInput
            value={application?.identificationType}
            option={meansOfId}
            label={"Means of Identification"}
            disabled={true}
          />
          <br />
          <InputText
            disabled={true}
            value={application?.identificationNumber}
            label={'ID Number'}
          />
          <Row>
            {application?.documents.length > 1 && (
              <Col
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  marginTop: "10px",
                }}
              >
                {application?.documents?.map((value, index) => {
                  let {
                    documentName,
                    documentLink,
                    documentType,
                    documentExtention,
                  } = value;

                  return (
                    <div onClick={() => handleDocumentClick(documentLink)}>
                      <Form.Label
                        style={{
                          fontSize: "14px",
                        }}
                      >
                        {convertFileNameFetch(documentType)}
                      </Form.Label>
                      <div style={imgStyle} key={index}>
                        {documentExtention === "png" ||
                          documentExtention === "jpeg" ||
                          documentExtention === "jpg" ||
                          documentExtention === "svg" ? (
                          <img
                            style={imgStyle}
                            src={getDocumentUrl(documentLink)}
                            title={convertFileNameFetch(documentType)}
                            alt={convertFileNameFetch(documentType)}
                          />
                        ) : (
                          <div onClick={() => setShowLightbox(true)}>
                            <embed
                              style={imgStyle}
                              name="plugin"
                              src={getDocumentUrl(documentLink)}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
                {showLightbox && selectedDocument && (
                  <>
                    <Lightbox
                      mainSrc={getDocumentUrl(selectedDocument)}
                      onCloseRequest={() => setShowLightbox(false)}
                    />
                  </>
                )}
              </Col>
            )}
          </Row>

          <PrimaryButton
            onClick={() => setScreen(4)}
          >
            Next
          </PrimaryButton>
        </Col>
      </main>
    </>
  );
};

export default KycDocumentScene;
