import React from 'react';
import { Col, Container, Form, FormControl, FormLabel, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { userManagementServiceClient } from '../App';
import Button from '../components/button';
import Footer from '../components/footer';
import H1 from '../components/h1';
import Header from '../components/header';
import { ERROR_STATUS } from '../constants/api';
import { dismissErrorMessage, showErrorMessage, updateLoading } from '../services/redux/actions/tunnel';
import BaseScene from './base-scene';

class ForgottenPasswordThree extends BaseScene {
  isAuthRequired = false
  

  constructor(props) {
    super(props)

    this.state = {
      hideConfirmPassword: true,
      hidePassword: true,
      form: {
        otp: '',
        password: '',
        confirmPassword: ''
      }
    }

    this.onSubmit = this.onSubmit.bind(this)
  }
  

  updateFormField (params) {
    const newFormData = {
      ...this.state.form,
      ...params,
    }

    this.setState({
      form: newFormData,
    })
  }

  isOtpValid (otp) {
    return otp.length === 6
  }

  isPasswordValid(password) {
    return password.length >= 4
  }

  isPasswordSame(password) {
    return password === this.state.form.password
  }

  isFormValid () {
    const {form} = this.state
    return this.isOtpValid(form.otp) && this.isPasswordValid(form.password) && this.isPasswordSame(form.confirmPassword)
  }

  async onSubmit () {
    const {form} = this.state;
    this.props.updateLoading(true, 66);

    const userUuid = localStorage.getItem('uuid');

    const resetPasswordResponse = await userManagementServiceClient.resetPasswordPhone(
      form.password,
      form.confirmPassword,
      userUuid,
      form.otp
    )

    const resetPasswordResponseStatus = resetPasswordResponse.status;
    this.props.updateLoading(false);

    if (resetPasswordResponseStatus === ERROR_STATUS) {
      this.props.showErrorMessage(JSON.stringify(resetPasswordResponse.response));
      setTimeout(() => this.props.dismissErrorMessage(), 3000);
      return
    }

    this.setState({
      redirectToLogin: true
    })
  }

  render() {
    if (this.state.redirectToLogin) {
      return <Redirect to="/login" />
    }

    const card = <React.Fragment>
      <H1>RESET PASSWORD</H1>
      <br/>
      <Form>

        <Row className="regular-row" style={{ marginBottom: '0px', paddingBottom: '0px'}}>
          <Col className="regular-col" style={{ marginBottom: '0px', paddingBottom: '0px'}}>
            <Form.Group style={{ marginBottom: '20px', paddingBottom: '0px'}}>
              <FormLabel style={{color: `${!this.state.form.otp || this.isOtpValid(this.state.form.otp) ?  '#3A3B3B' : process.env.REACT_APP_RED_COLOUR}`, fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Otp:</FormLabel>
              <FormControl 
                id="otp-input"
                type="text" 
                placeholder="Enter Otp"
                onChange={(event) => {
                  const otp = event.target.value
                  this.updateFormField({otp})
                }} style={{
                  border: `1px solid ${!this.state.form.otp || this.isOtpValid(this.state.form.otp) ?  '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`
                }} />
            </Form.Group>
          </Col>
        </Row>

        <Row className="regular-row" style={{ marginBottom: '0px', paddingBottom: '0px'}}>
          <Col className="regular-col" style={{ marginBottom: '0px', paddingBottom: '0px'}}>
            <Form.Group style={{ marginBottom: '20px', paddingBottom: '0px'}}>
              <FormLabel style={{color: `${!this.state.form.password || this.isPasswordValid(this.state.form.password) ?  '#3A3B3B' : process.env.REACT_APP_RED_COLOUR}`, fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Enter New Password:</FormLabel>
              <FormControl 
                id="password-input"
                type={this.state.hidePassword ? 'password' : 'text'} 
                placeholder="Enter new password"
                onChange={(event) => {
                  const password = event.target.value
                  this.updateFormField({password})
                }}
                style={{
                  background: "url(https://img.icons8.com/material/24/C1C1C2/key.png) no-repeat scroll 8px 8px",
                  paddingLeft: "50px",
                  backgroundColor: '#FFFFFF', 
                  // borderRadius: '20px', 
                  border: `1px solid ${!this.state.form.password || this.isPasswordValid(this.state.form.password) ?  '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`,
                  boxSizing: 'border-box', 
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  lineHeight: '17px'
                }}
                />
                <img 
                  src={this.state.hidePassword ? '/assets/media/icons/eye.svg' : '/assets/media/icons/eye-hidden.svg'}
                  style={{
                    height: '24px', 
                    width: '24px', 
                    opacity: 1,
                    marginLeft: this.state.deviceIsPC ? '310px' : '85%',
                    marginTop: '-70px',
                    position: 'relative',
                    zIndex: 2
                  }}
                  onClick={() => this.setState({hidePassword: !this.state.hidePassword})}
                  alt=""
                />
            </Form.Group>
          </Col>
        </Row>

        <Row className="regular-row" style={{ marginBottom: '0px', paddingBottom: '0px'}}>
          <Col className="regular-col" style={{ marginBottom: '0px', paddingBottom: '0px'}}>
            <Form.Group style={{ marginBottom: '0px', paddingBottom: '0px'}}>
              <FormLabel style={{color: `${!this.state.form.confirmPassword || this.isPasswordSame(this.state.form.confirmPassword) ?  '#3A3B3B' : process.env.REACT_APP_RED_COLOUR}`, fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Confirm Password:</FormLabel>
              <FormControl 
                id="confirm-password-input"
                type={this.state.hideConfirmPassword ? 'password' : 'text'} 
                placeholder="Repeat your new password"
                onChange={(event) => {
                  const confirmPassword = event.target.value
                  this.updateFormField({confirmPassword})
                }}
                style={{
                  background: "url(https://img.icons8.com/material/24/C1C1C2/key.png) no-repeat scroll 8px 8px",
                  paddingLeft: "50px",
                  backgroundColor: '#FFFFFF', 
                  // borderRadius: '20px',
                  border: `1px solid ${!this.state.form.confirmPassword || this.isPasswordSame(this.state.form.confirmPassword) ?  '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`,
                  boxSizing: 'border-box', 
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  lineHeight: '17px'
                }}
                />
                <img
                  src={this.state.hideConfirmPassword ? '/assets/media/icons/eye.svg' : '/assets/media/icons/eye-hidden.svg'}
                  style={{
                    height: '24px', 
                    width: '24px', 
                    opacity: 1,
                    marginLeft: this.state.deviceIsPC ? '310px' : '85%',
                    marginTop: '-70px',
                    position: 'relative',
                    zIndex: 2
                  }}
                  onClick={() => this.setState({hideConfirmPassword: !this.state.hideConfirmPassword})}
                  alt=""
                />
            </Form.Group>
          </Col>
        </Row>

        <Row id="continue-button-row" hidden={this.state.isUserGuest}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="regular-col">
            <Button disabled={!this.isFormValid() || this.props.isLoading} style={{fontWeight: 'normal', width: '100%'}} onClick={() => this.onSubmit()}>
              {this.props.isLoading ? 'Loading...' : 'RESET PASSWORD AND LOGIN'}
            </Button>
          </Col>
        </Row>

        <Row style={{margin: 0}}>
          <Col style={{margin: 0, padding: 0}}>
            <div style={{color: process.env.REACT_APP_LINK_COLOUR, marginTop: '15px'}}><Link to="/login">Back to Login</Link></div>
          </Col>
        </Row>

      </Form>
    </React.Fragment>

    const desktopContainer = <Container fluid style={{
      margin: 0,
      marginRight: '165px',
      marginTop: '70px',
      paddingLeft: '0px',
      paddingRight: '0px',
      paddingTop: '90px',
      paddingBottom: '100px'
    }}>
      <Row style={{margin: 0, padding: 0}}>
        <Col xs={12} md={12} lg={6} style={{margin: 0, padding: 0, display: 'flex', justifyContent: this.state.deviceIsPC ? 'flex-start' : 'center',
        marginLeft: this.state.deviceIsPC ? '10%' : '0%'}}>
          <div style={{
            border: '2px', 
            borderRadius: '8px',
            boxShadow: '0 1px 5px 4px rgba(0,0,0,0.12)', 
            padding: '50px',
            paddingTop: '50px',
            paddingBottom: '35px',
            backgroundColor: 'white',
            width: '450px'}}>
            {card}
          </div>
        </Col>
      </Row>
    </Container>

    const mobileContainer = <Container fluid style={{ padding: 0, margin: 0, marginTop: '90px' }}>
      {card}
    </Container>

    return <div style={{
      backgroundColor: 'white',
      minHeight: '100vh',
      height: '100%',
      width: '100%',
      maxWidth: '1440px',
      overflow: 'hidden',
      display: 'block',
      flexDirection: 'column',
      marginLeft: 'auto',
      marginRight: 'auto',
      position: 'relative',
      paddingBottom: '100px'
    }}>
      <div>
        <img
          hidden={!this.state.deviceIsPC}
          src={'/assets/media/images/StyleGuide_Interswitch_2015_V02.png'}
          style={{position: 'absolute', right: '0px', zIndex: 0}}
          height={'740px'}
          width={'1028px'}
          alt=""
        />
      </div>
      <Header transparent={true} outerDivStyle={{backgroundColor: 'white', maxWidth: '1440px'}} />

      {this.state.deviceIsMobile ? mobileContainer : desktopContainer}

      <Footer />

    </div>
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.tunnel.isLoading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dismissErrorMessage: () => dispatch(dismissErrorMessage()),
    showErrorMessage: message => dispatch(showErrorMessage(message)),
    updateLoading: (isLoading, percentage=null) => dispatch(updateLoading({isLoading, percentage}))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgottenPasswordThree);