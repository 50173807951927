import React from "react";
import Button from "../../../../../components/button";
import H1 from "../../../../../components/h1";
import Text from "../../../../../components/text";
import { FUND_WALLET_URL } from "../../../../../utils/api-properties";

export default (props) => {
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        marginTop: "10px",
        padding: "5px",
      }}
    >
      <img
        alt=""
        src={"/assets/media/images/Group 2 (2).png"}
        style={{ marginBottom: "20px", width: "200px" }}
      />
      <H1
        style={{
          fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
          fontSize: process.env.REACT_APP_BIG_FONT_SIZE,
          fontWeight: "600",
          marginBottom: "15px",
        }}
      >
        Welcome to Quickteller Paypoint
      </H1>
      {/* <Text style={{color: '#808593', fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE, lineHeight: process.env.REACT_APP_MID_BIG_LINE_HEIGHT, textAlign: 'center', maxWidth: '80%', width: '450pt'}}>You have no transactions yet. In order to start working with the application, fund your wallet.</Text> */}
      <Text
        style={{
          color: "#808593",
          fontSize: process.env.REACT_APP_MID_FONT_SIZE,
          lineHeight: process.env.REACT_APP_MID_BIG_LINE_HEIGHT,
          textAlign: "center",
          maxWidth: "50%",
          width: "450pt",
          marginBottom: "15px",
        }}
      >
        You have no transactions yet. To start transacting, fund your wallet via
        any of the channels below:
      </Text>
      <ul
        style={{
          listStyleType: "square",
          color: "#808593",
          fontSize: process.env.REACT_APP_MID_FONT_SIZE,
          lineHeight: process.env.REACT_APP_BIG_LINE_HEIGHT,
          textAlign: "left",
          maxWidth: "55%",
          width: "395pt",
        }}
      >
        <li>
          Via{" "}
          <a target="_blank" rel="noopener noreferrer" href={FUND_WALLET_URL}>
            Quickteller.com
          </a>
        </li>
        <li>Via Paydirect at any Bank Branch nationwide,</li>
        <li>
          By clicking the “Fund Wallet” button displayed below and on your
          Dashboard
        </li>
        {/* <li>Using Quickteller within your bank’s Internet Banking Platform</li> */}
      </ul>
      <Button
        onClick={props.fundWallet}
        style={{
          fontWeight: "normal",
          marginTop: "25px",
          paddingVertical: "2px",
        }}
      >
        Fund Wallet
      </Button>
    </div>
  );
};
