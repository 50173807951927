import React from "react";
import Button from "../../../../../components/button";
import H1 from "../../../../../components/h1";
import { WITHDRAWAL } from "../../../../../constants";
import { getConfirmationPage } from "../components/product-payment";

export const ConfirmationTab = (props) => {
  return (
    <div
      className={`product-payment-container ${
        props.category === "sell-airtime-and-data" ||
        props.category === "request-pos" ||
        props.category === "pay-a-bill"
          ? "airtime-bill-height"
          : "send-money-height"
      } ${props.deviceIsMobile ? ".mobile-width" : "pc-tab-width"}`}
    >
      {props.category === WITHDRAWAL && props.service.id === 1 ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
          }}
        >
          <Button
            outline
            style={{
              backgroundColor: "transparent",
              borderColor: "transparent",
              color: process.env.REACT_APP_LINK_COLOUR,
              fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
              fontWeight: "normal",
              padding: 0,
            }}
            onClick={() => props.onBack()}
          >
            Back
          </Button>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            // justifyContent: "right",
          }}
        >
          <H1
            style={{
              fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
              color: process.env.REACT_APP_BLACK_COLOUR,
              fontWeight: "normal",
            }}
          >
            Confirmation
          </H1>
          <Button
            outline
            style={{
              backgroundColor: "transparent",
              borderColor: "transparent",
              color: process.env.REACT_APP_LINK_COLOUR,
              fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
              fontWeight: "normal",
              padding: 0,
            }}
            onClick={() => props.onBack()}
          >
            Back
          </Button>
        </div>
      )}
      {getConfirmationPage(props)}
    </div>
  );
};
