import React, { useState } from "react";
import "./style.css";
import { arrowBackIcon, busSideIcon, busIcon } from "../../../../../../icons";

const AvailableTripsScene = ({ setScreen, application }) => {
  const trips = [
    {
      id: 1,
      // logo: {busIcon()},
      vehicle: "Toyota Hiace X",
      seats: "9 Seats available",
      departureTime: "6:14 AM",
      price: "₦14,000.00",
      originalPrice: "₦19,600.00",
      features: ["AC", "Leg room", "Pick-up +2"],
      childrenPrice: "Children - ₦9,000.00",
    },
  ];
  const [activeButton, setActiveButton] = useState("one-way");

  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
  };

  return (
    <div className="p-6 max-w-7xl mx-auto">
      <button className="flex items-center text-blue-600 mb-4 font-medium back-button">
        {arrowBackIcon()} Back
      </button>

      <h4 className="text-md font-bold mb-4">Available trips</h4>

      <div className="toolbar">
        <div className="toolbar-left">
          <button
            className={`view-button ${activeButton === "one-way" ? "active" : ""}`}
            onClick={() => handleButtonClick("one-way")}
          >
            One-way
          </button>
          <button
            className={`view-button ${activeButton === "return" ? "active" : ""}`}
            onClick={() => handleButtonClick("return")}
          >
            Return
          </button>
        </div>

        <div className="toolbar-right">
          <button className="action-button">Filter</button>
          <button className="action-button">Sort</button>
        </div>
      </div>

      <div className="flex items-center gap-4 mb-6">
        <input
          type="text"
          placeholder="Lagos (Ajah)"
          className="px-4 py-2 border rounded-md"
        />
        <div className="flex items-center gap-4">
          {busSideIcon()}
        </div>
        <input
          type="text"
          placeholder="Abuja (Utako)"
          className="px-4 py-2 border rounded-md"
        />
      </div>

      <div className="grid gap-6">
        {trips.map((trip) => (
          <div
            key={trip.id}
            className="flex items-center justify-between p-4 border border-gray-200 rounded-lg bg-white shadow-sm"
          >
            <div className="flex items-center gap-4">
              <img src={trip.logo} alt="Logo" className="w-20 h-10" />
              <div>
                <p className="text-gray-800 font-semibold">{trip.vehicle}</p>
                <p className="text-gray-500 text-sm">{trip.seats}</p>
              </div>
            </div>

            <div className="text-gray-500">
              <p>{trip.departureTime}</p>
            </div>

            <div className="text-right">
              <p className="text-blue-500 font-bold">{trip.price}</p>
              <p className="line-through text-gray-400 text-sm">
                {trip.originalPrice}
              </p>
              <p className="text-sm text-gray-500">{trip.childrenPrice}</p>
            </div>

            <div className="flex items-center gap-2">
              {trip.features.map((feature, index) => (
                <span
                  key={index}
                  className="px-2 py-1 text-xs bg-gray-100 border rounded-md"
                >
                  {feature}
                </span>
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className="flex justify-between items-center mt-6">
        <p className="text-gray-500">Showing 3 of 33 Results</p>
        <div className="flex items-center gap-2">
          {[1, 2, 3, 4, 5].map((page) => (
            <button
              key={page}
              className={`px-4 py-2 ${page === 1
                ? "bg-blue-500 text-white"
                : "border text-gray-500 hover:bg-gray-100"
                } rounded-md`}
            >
              {page}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AvailableTripsScene;