import React, { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import PrimaryButton from "../../aggregator-module/performance/settings/components/customButton";
import CAC from "../../../../../services/api/resources/cac-service";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../../constants/api";
import { ErrorBanner, firetoast, notify } from "../../../../../components/extras";
import CountriesStatesLga from "../../../../../fixtures/countries_states_lgas.json";
import Text from "../../../../../components/text";

export const ModalComponent = ({
  handleOpenView,

  modalData,
  getApplication,
  pageSize,
  pageNum,
}) => {
  const cac = new CAC();
  const currentAgent = JSON.parse(localStorage.getItem("currentAgent"));
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [payloadLoading, setPayloadLoading] = useState(false);
  const [errorByRef, setErrorByRef] = useState(false);
  const [formData, setFormData] = useState([]);
  const [queriedFieldMap, setQueriedFieldMap] = useState([]);

  const [imageErrors, setImageErrors] = useState({});

  const [initialFormData, setInitialFormData] = useState({});
  const [hasChanges, setHasChanges] = useState(false);

  const getRequestPayload = async () => {
    setPayloadLoading(true);

    const { response, status } = await cac.getCacRegistrationReportByReference(
      modalData?.original.reference,
      currentAgent.agentCode
    );

    setPayloadLoading(false);
    if (status === ERROR_STATUS) {
      setErrorByRef(true);
    } else if (status === SUCCESS_STATUS) {
      setFormData(response?.data?.content[0]?.registrationRequestPayload || []);
      setInitialFormData(
        response?.data?.content[0]?.registrationRequestPayload
      );
      setQueriedFieldMap(response?.data?.content[0]?.queriedFieldMap);
    }
  };

  const imageFields = ["passport", "supportingDoc", "signature", "meansOfId"];
  const hiddenFields = [
    "businessCommencementDate",
    "transactionRef",
    "proprietorDob",
    "proprietorGender",
  ];
  const handleInputChange = (e, field) => {
    setFormData({
      ...formData,
      [field]: e.target.value,
    });
    setQueriedFieldMap((prevMap) =>
      prevMap.filter((item) => item?.reason !== field)
    );
  };

  const isFormValid = () => {
    return (
      formData.lineOfBusiness &&
      formData.proprietorCity &&
      formData.companyCity &&
      formData.companyState &&
      formData.proprietorNationality &&
      formData.proprietorState &&
      formData.proprietorFirstname &&
      formData.proprietorSurname &&
      formData.proprietorEmail &&
      formData.proprietorPhonenumber &&
      formData.proposedOption1 &&
      formData.proprietorStreetNumber &&
      formData.proprietorServiceAddress &&
      formData.companyEmail &&
      formData.companyStreetNumber &&
      formData.companyAddress &&
      formData.proprietorPostcode &&
      formData.proprietorLga &&
      formData.signature &&
      formData.meansOfId
    );
  };

  const isFieldQueried = (fieldName) => {
    const result = queriedFieldMap?.some((item) => item?.reason === fieldName);
    return result;
  };

  const queriedFields = queriedFieldMap.filter((field) => field.comment);

  const fieldsToShow = Object.keys(formData)?.filter(
    (field) => !hiddenFields.includes(field)
  );

  const groupedFields = [];
  for (let i = 0; i < fieldsToShow.length; i += 2) {
    groupedFields.push(fieldsToShow.slice(i, i + 2));
  }

  const formatFieldName = (fieldName) => {
    return fieldName
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const handleImageChange = (e, field) => {
    const file = e.target.files[0];

    if (file.size > 1024 * 1024) {
      setImageErrors({
        ...imageErrors,
        [field]: "File size should not exceed 1MB",
      });
      return;
    }

    setImageErrors({
      ...imageErrors,
      [field]: "",
    });

    const reader = new FileReader();
    reader.onloadend = () => {
      setFormData({
        ...formData,
        [field]: reader.result,
      });

      setQueriedFieldMap((prevMap) =>
        prevMap.filter((item) => item.reason !== field)
      );
    };

    reader.readAsDataURL(file);
  };


  const handleSubmitEdit = async () => {
    setIsLoading(true);
    const { response, status } = await cac.updateCacRegistrationReport(
      formData
    );

    setIsLoading(false);
    if (status === ERROR_STATUS) {
      notify(response.description, "error");
    } else if (status === SUCCESS_STATUS) {
      notify(response.description, "success");
      handleOpenView();
      getApplication(pageSize, pageNum);
    } else {
      notify("An error occur, please try again", "error");
    }
  };
  const handleSubmitRequery = async () => {
    setLoading(true);
    const { response } = await cac.requeryCacRegistrationReport(
      modalData.original.reference
    );
    setLoading(false);
    if (response.code === "00") {
      firetoast("success", "", "Request successful");
      handleOpenView();
      getApplication(pageSize, pageNum);
    } else if (response.code === "02") {
      firetoast("warning", "", "Your request is being reviewed");
      handleOpenView();
    } else {
      firetoast("error", `${response.description}`, "");
    }
  };

  const businessLine = [
    "General Merchandise",
    "Trading",
    "Fashion design/tailoring",
    "ICT Service",
    "Data Analysis",
    "Poultry/Livestock Farming",
    "Crop production farming/Agro allied service",
    "Hair stylist/salon",
    "Solar Panel installation",
    "Digital Marketing",
    "Graphic Design",
    "Content Creation",
    "Web Design",
    "POS Agent",
  ];

  useEffect(() => {
    getRequestPayload();
  }, []);

  useEffect(() => {
    if (formData.proprietorState) {
      const selectedState = CountriesStatesLga[0].states.find(
        (state) => state.name === formData.proprietorState
      );
      if (selectedState && !formData.proprietorLga) {
        setFormData({
          ...formData,
          proprietorLga: selectedState.lgas[0]?.name || "",
        });
      }
    }
  }, [formData.companyState]);

  useEffect(() => {
    setInitialFormData({ ...formData });
  }, []);

  useEffect(() => {
    const formDataChanged =
      JSON.stringify(formData) !== JSON.stringify(initialFormData);
    setHasChanges(formDataChanged);
  }, [formData, initialFormData]);

  if (payloadLoading) {
    return (
      <div
        style={{
          display: "grid",
          placeContent: "center",
          padding: "1.5rem",
        }}
      >
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }
  if (formData.length === 0) {
    return (
      <div
        style={{
          display: "grid",
          placeContent: "center",
          padding: "1.5rem",
          textAlign: "center",
        }}
      >
        <span
          style={{
            color: "grey",
          }}
        >
          No record found
        </span>
      </div>
    );
  }
  if (errorByRef) {
    return (
      <div
        style={{
          display: "grid",
          placeContent: "center",
          padding: "2rem",
          textAlign: "center",
        }}
      >
        <Text blue bold small>
          Oops!
        </Text>
        <Text mid>Something went wrong. Please, try again.</Text>
      </div>
    );
  }

  return (
    <form>
      {modalData?.original?.status === "QUERIED" && (
        <div style={{ marginBottom: "20px", color: "red" }}>
          <strong>Please update the information highlighted below:</strong>
        </div>
      )}
      {modalData?.original?.status === "PROCESSING" && (
        <div style={{ marginBottom: "20px", color: "red" }}>
          <strong>Will you like to requery this registration?</strong>
        </div>
      )}

      <div
        style={{
          maxHeight: "400px",
          overflowY: "scroll",
        }}
      >
        {modalData?.original?.status === "QUERIED" ?
          <div>
            <ErrorBanner queriedFields={queriedFields} />
          </div> : ""
        }
        {groupedFields.map((fieldGroup, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginTop: "15px",
            }}
          >
            {fieldGroup.map((field) => (
              <div key={field} style={{ width: "48%", marginBottom: "10px" }}>
                {imageFields.includes(field) ? (
                  <>
                    {formData[field] ? (
                      <div
                        style={{
                          marginTop: "5px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <label
                          style={{
                            fontSize: "14px",
                            fontWeight: 700,
                            color: isFieldQueried(field) ? "red" : "black",
                          }}
                          htmlFor={field}
                        >
                          {formatFieldName(field)}
                        </label>
                        <div
                          style={{
                            maxWidth: "100%",
                            height: "150px",
                          }}
                        >
                          <img
                            src={formData[field]}
                            alt={field}
                            style={{
                              borderRadius: "10px",
                              border: `1px solid ${isFieldQueried(field) ? "red" : "#D3D3D3"
                                }`,
                              objectFit: "cover",
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <label
                          style={{
                            fontSize: "14px",
                            fontWeight: 700,
                            color: isFieldQueried(field) ? "red" : "black",
                          }}
                          htmlFor={field}
                        >
                          {formatFieldName(field)}
                        </label>
                        <div
                          style={{
                            maxWidth: "100%",
                            height: "100px",
                            border: `1px solid #D3D3D3`,
                            marginBottom: "10px",
                            display: "grid",
                            font: "12px",
                            placeContent: "center",
                            borderRadius: "10px",
                          }}
                        >
                          No image found
                        </div>
                      </>
                    )}
                    {imageErrors[field] ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "14px",
                          marginTop: "-10px",
                        }}
                      >
                        {imageErrors[field]}
                      </p>
                    ) : (
                      ""
                    )}
                    <input
                      type="file"
                      style={{
                        marginTop: "-10px",
                        fontSize: "13px",
                      }}
                      accept="image/*"
                      onChange={(e) => handleImageChange(e, field)}
                      disabled={modalData?.original?.status !== "QUERIED"} />
                  </>
                ) : field === "proprietorState" ? (
                  <>
                    <Form.Label
                      style={{
                        fontSize: "14px",
                        fontWeight: 700,
                        color: isFieldQueried(field) ? "red" : "black",
                      }}
                    >
                      {formatFieldName(field)}
                    </Form.Label>
                    <Form.Control
                      style={{
                        width: "100%",
                        padding: "8px",
                        outline: "none",
                        borderColor: isFieldQueried(field) ? "red" : "#ced4da",
                        borderRadius: "4px",
                      }}
                      as="select"
                      value={formData[field]}
                      onChange={(e) => {
                        const selectedState = CountriesStatesLga[0].states.find(
                          (state) => state.name === e.target.value
                        );
                        if (selectedState) {
                          setFormData({
                            ...formData,
                            proprietorState: e.target.value,
                            proprietorLga: selectedState.lgas[0]?.name || "",
                          });
                        } else {
                          setFormData({
                            ...formData,
                            proprietorState: "",
                            proprietorLga: "",
                          });
                        }
                      }}
                      disabled={modalData?.original?.status !== "QUERIED"}
                    >
                      <option value="">{formData[field]}</option>
                      {CountriesStatesLga[0].states.map((state) => (
                        <option key={state.id} value={state.name}>
                          {state.name}
                        </option>
                      ))}
                    </Form.Control>
                    {isFieldQueried(field) && (
                      ""
                    )}
                  </>
                ) : field === "proprietorLga" ? (
                  <>
                    <Form.Label
                      style={{
                        fontSize: "14px",
                        fontWeight: 700,
                        color: isFieldQueried(field) ? "red" : "black",
                      }}
                    >
                      {formatFieldName(field)}
                    </Form.Label>
                    <Form.Control
                      style={{
                        width: "100%",
                        padding: "8px",
                        borderColor: isFieldQueried(field) ? "red" : "#ced4da",
                        borderRadius: "4px",
                        outline: "none",
                      }}
                      as="select"
                      value={formData.proprietorLga || ""}
                      onChange={(e) => handleInputChange(e, field)}
                      disabled={modalData?.original?.status !== "QUERIED"}
                    >
                      <option value="">{formData[field]}</option>
                      {CountriesStatesLga[0].states
                        .find((state) => state.name === formData.proprietorState)
                        ?.lgas.map((city) => (
                          <option key={city.id} value={city.name}>
                            {city.name}
                          </option>
                        ))}
                    </Form.Control>
                  </>
                ) : field === "lineOfBusiness" ? (
                  <>
                    <Form.Label
                      style={{
                        fontSize: "14px",
                        fontWeight: 700,
                        color: isFieldQueried(field) ? "red" : "black",
                      }}
                    >
                      {formatFieldName(field)}
                    </Form.Label>
                    <Form.Control
                      style={{
                        width: "100%",
                        padding: "8px",
                        borderColor: isFieldQueried(field) ? "red" : "#ced4da",
                        borderRadius: "4px",
                        outline: "none",
                      }}
                      as="select"
                      value={formData[field]}
                      onChange={(e) => handleInputChange(e, field)}
                      disabled={modalData?.original?.status !== "QUERIED"}
                    >
                      <option value="">{formData[field]}</option>
                      {businessLine.map((business) => (
                        <option key={business} value={business}>
                          {business}
                        </option>
                      ))}
                    </Form.Control>
                  </>
                ) : field === "companyState" ? (
                  <>
                    <Form.Label
                      style={{
                        fontSize: "14px",
                        fontWeight: 700,
                        color: isFieldQueried(field) ? "red" : "black",
                      }}
                    >
                      {formatFieldName(field)}
                    </Form.Label>
                    <Form.Control
                      style={{
                        width: "100%",
                        padding: "8px",
                        borderColor: isFieldQueried(field) ? "red" : "#ced4da",
                        borderRadius: "4px",
                        outline: "none",
                      }}
                      as="select"
                      value={formData[field]}
                      onChange={(e) => handleInputChange(e, field)}
                      disabled={modalData?.original?.status !== "QUERIED"}
                    >
                      <option value="">{formData[field]}</option>
                      {CountriesStatesLga[0].states.map((state) => (
                        <option key={state.id} value={state.name}>
                          {state.name}
                        </option>
                      ))}
                    </Form.Control>
                  </>
                ) : (
                  <>
                    <Form.Label
                      style={{
                        fontSize: "14px",
                        fontWeight: 700,
                        color: isFieldQueried(field) ? "red" : "black",
                      }}
                    >
                      {formatFieldName(field)}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      style={{
                        width: "100%",
                        padding: "8px",
                        borderColor: isFieldQueried(field) ? "red" : "#ced4da",
                        borderRadius: "4px",
                        outline: "none",
                      }}
                      value={formData[field]}
                      onChange={(e) => handleInputChange(e, field)}
                      disabled={modalData?.original?.status !== "QUERIED"}
                    />
                  </>
                )}
              </div>
            ))}
          </div>

        ))}{" "}
      </div>

      <div
        style={{
          float: "right",
          margin: "1rem 1rem 0 0",
        }}
      >
        {modalData?.original?.status === "QUERIED" && (
          <PrimaryButton
            margin={0}
            disabled={isLoading || !isFormValid() || !hasChanges}
            children={isLoading ? "Processing..." : "Update"}
            onClick={handleSubmitEdit}
          />
        )}
        {modalData?.original?.status === "PROCESSING" && (
          <PrimaryButton
            margin={0}
            disabled={loading}
            children={loading ? "Processing..." : "Requery"}
            onClick={handleSubmitRequery}
          />
        )}
      </div>
    </form>
  );
};
