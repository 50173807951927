import PropTypes from 'prop-types';
import React from 'react';
import '../../../../App.css';

const SkeletonLoading = ({ width, height }) => {
  const skeletonStyle = {
    width: width,
    height: height,
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
      }}
    >
      <div className='skeleton' style={skeletonStyle}></div>
      <div className='skeleton' style={skeletonStyle}></div>
    </div>
  );
};

SkeletonLoading.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default SkeletonLoading;
