import React from 'react';
import qs from 'qs';

import { navigateTo } from '../../../../../../services/redux/actions/navigation';
import { updateLoading } from '../../../../../../services/redux/actions/tunnel';
import { connect } from 'react-redux';

import MdArrowForward from 'react-ionicons/lib/MdArrowForward';
import IosArrowBack from 'react-ionicons/lib/IosArrowBack';
import IosArrowForward from 'react-ionicons/lib/IosArrowForward';
import MdColorWand from 'react-ionicons/lib/MdColorWand';
import { Dropdown, Nav, Row, Tab } from 'react-bootstrap';
import Activity from './activity';
import Profile from './profile';
import H1 from '../../../../../../components/h1';

const ManageAgentScene = (props) => {
  const deviceIsPC =
    !qs.parse(window.location.search)['?mobile'] && window.innerWidth >= 992;

  const agentDetails = JSON.parse(localStorage.getItem('agentDetails'));

  const getInitials = () => {
    if (agentDetails) {
      const initials = agentDetails.accountName
        .split(' ')
        .map((word) => word[0])
        .join('');

      return initials;
    }
  };

  const handleOpenUpgrade = () => {
    props.navigateTo(`/agents/my-agents/upgrade/${agentDetails.id}`);
  };

  return (
    <>
      <div className='aggregator-layout'>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            marginBottom: '30px',
            color: '#6B7280',
          }}
        >
          <div
            style={{
              borderRadius: '0.25rem',
              padding: '0.25rem',
              border: '1px solid #E5E7EB',
              cursor: 'pointer',
            }}
            title='Go back'
            onClick={() => props.navigateTo('/agents/my-agents')}
          >
            <IosArrowBack color='#6B7280' fontSize='20px' />
          </div>
          <span
            style={{
              fontWeight: 400,
            }}
          >
            My Agents
          </span>
          <IosArrowForward color='#6B7280' fontSize='12px' />
          <span
            style={{
              fontWeight: 600,
            }}
          >
            {agentDetails.accountName}
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              textAlign: 'left',
              gap: '20px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {deviceIsPC && (
              <div
                style={{
                  width: '65px',
                  height: '65px',
                  fontWeight: 700,
                  fontSize: '18px',
                  display: 'grid',
                  placeContent: 'center',
                  borderRadius: '100%',
                  background: '#EBF8FE',
                  color: '#00425F',
                  textTransform: 'uppercase',
                }}
              >
                {getInitials()}
              </div>
            )}
            {deviceIsPC && (
              <div>
                <H1
                  style={{
                    fontSize: '1.5rem',
                    marginBottom: '7px',
                    textAlign: 'start',
                  }}
                >
                  {agentDetails.accountName}
                </H1>
                <div
                  style={{
                    color: '#0275D8',
                    borderRadius: '5px',
                    backgroundColor: '#EBF8FE',
                    padding: '5px',
                    display: 'inline',
                  }}
                >
                  {agentDetails.agentClass}
                </div>
              </div>
            )}
          </div>
          <div>
            <Dropdown>
              <Dropdown.Toggle
                style={{
                  cursor: 'pointer',
                  gap: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '20px',
                  backgroundColor: '#00425F',
                  borderRadius: '0.375rem',
                  color: 'white',
                  height: '2.5rem',
                }}
                as={'span'}
                variant='secondary'
                id='dropdown-variants-secondary'
              >
                <div>Action</div>
                <MdArrowForward color='white' fontSize='20px' />
              </Dropdown.Toggle>

              <Dropdown.Menu
                variant='secondary'
                id='dropdown-variants-secondary'
              >
                <Dropdown.Item onClick={handleOpenUpgrade}>
                  <Row
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <MdColorWand
                      fontSize='15px'
                      color={process.env.REACT_APP_GREY_COLOUR}
                    />

                    <div>Upgrade</div>
                  </Row>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <Tab.Container id='left-tabs-example' defaultActiveKey='first'>
          <div
            style={{
              marginTop: '2.5rem',
            }}
          >
            <div>
              <Nav
                variant='pills'
                style={{
                  borderBottom: '1px solid #E5E7EB',
                }}
                className='flex-row'
              >
                <Nav.Item>
                  <Nav.Link
                    style={{ color: process.env.REACT_APP_BLUE_COLOUR }}
                    eventKey='first'
                  >
                    Activity
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    style={{ color: process.env.REACT_APP_BLUE_COLOUR }}
                    eventKey='second'
                  >
                    Profile
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div>
              <Tab.Content>
                <Tab.Pane eventKey='first'>
                  <Activity agentDetails={agentDetails} />
                </Tab.Pane>
                <Tab.Pane eventKey='second'>
                  <Profile agentDetails={agentDetails} />
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    isLoading: state.tunnel.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateLoading: (isLoading) => dispatch(updateLoading(isLoading)),
    navigateTo: (pendingUrl) => dispatch(navigateTo(pendingUrl)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageAgentScene);
