import React, { useEffect, useState, useRef } from "react";
import { Col, Form, Row } from "react-bootstrap";
import MdCheckmarkCircle from "react-ionicons/lib/MdCheckmarkCircle";
import Lightbox from "react-image-lightbox";
import {
  ERROR_STATUS,
  SUCCESS_STATUS,
} from "../../../../constants/api";
import {
  ADDRESS_VERIFICATION_FORM,
  BUSINESS_DOC_SUFFIX,
  CHARACTER_ATTESTATION,
  TEN_MB,
  THREE_MB,
  UTILITY_BILL,
} from "../../../../constants";
import Title from "./title";
import Platform from "../../../../services/api/resources/platform";
import PrimaryButton from "../../onboarding-setup/component/button";
import { isNINNumberValid } from "../../onboarding-setup/component/extras";
import InputText2 from "../../onboarding-setup/component/input2";
import SelectInput from "../../onboarding-setup/component/select";
import { firetoast, firetoastAggregator, notify } from "../../../../components/extras";
import H1 from "../../../../components/h1";
import BackButton from "../../onboarding-setup/component/backButton";
import DocumentUploader from "../../../home/scenes/dashboard/fragments/onboarding-application-setup/component/customImage3"
import Onboarding from "../../../../services/api/resources/onboarding";
import FormLabel from "../../../../components/form-label";

const meansOfId = [
  "Passport",
  "Drivers License",
  "International Passport",
  "Voter's Card",
  "National Identification Number",
];
const KycDocumentScene = ({ setScreen, application }) => {
  const moveScreen = localStorage.getItem('moveScreen');
  const onboarding = new Onboarding();
  const platform = new Platform();

  const currentAgent = JSON.parse(localStorage.getItem('currentAgent'));
  const upgradeTo = localStorage.getItem('upgradeTo');

  const [showLightboxPassport, setShowLightboxPassport] = useState(false);
  const [showLightboxId, setShowLightboxId] = useState(false);
  const [passportPhoto, setPassportPhoto] = useState(null);
  const [utilityBill, setUtilityBill] = useState(null);
  const [idCard, setIdCard] = useState(null);

  const [loadingPassportCancel, setLoadingPassportCancel] = useState(false);
  const [loadingIdCardCancel, setLoadingIdCardCancel] = useState(false);
  const [isLoadingDoc, setIsLoadingDoc] = useState(false);
  const [isLoadingPassport, setIsLoadingPassport] = useState(false);
  const [isLoadingUtilityBillCancel, setLoadingUtilityBillCancel] = useState(false);
  const [isLoadingIdCard, setIsLoadingIdCard] = useState(false);
  const [fileFormatPassport, setFileFormatPassport] = useState(null);
  const [fileFormatIdCard, setFileFormatIdCard] = useState(null);
  const [ninData, setNINData] = useState();
  const [ninDataValid, setNINDataValid] = useState(false);
  const [ninResponse, setNINResponse] = useState({
    success: '',
    error: '',
  });
  const [idCardImg, setIdCardImg] = useState(null)
  const [utilityBillImg, setUtilityBillImg] = useState(null)

  const updatedKycDetailsForm = JSON.parse(
    localStorage.getItem('kycDetailsFormUpgrade')
  );

  const [kycDetailsForm, setKycDetailsForm] = useState({
    nin: application
      ? application?.applicantDetails?.nin
      || updatedKycDetailsForm?.nin : '',
    idCard: "",
    utilityBill: "",
  });

  localStorage.setItem(
    'kycDetailsFormUpgrade',
    JSON.stringify(kycDetailsForm)
  );

  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const updateFormField = (params) => {
    const newForm = {
      ...kycDetailsForm,
      ...params,
    };

    setKycDetailsForm(newForm);
  };

  const handleSubmit = async () => {
    moveScreen ? setScreen(6) : setScreen(5);
  };

  const [uploadError, setUploadError] = useState(false);
  const [error, setError] = useState(false);

  const getDocumentUrl = (document) => {
    let convertedDocument = document?.documentLink?.replace(
      "/data",
      process.env.REACT_APP_ONBOARDING_BASE_URL
    );
    return convertedDocument;
  };

  const getDocumentTypePassport = (file) => {
    const srcSplitted = file?.name
      ? file?.name?.split(".")
      : file?.documentName?.split(".");

    const slittedType = file
      ? srcSplitted[srcSplitted.length - 1].split("-")[0]
      : null;
    setFileFormatPassport(slittedType);
  };
  const getDocumentTypeIdCard = (file) => {
    const srcSplitted = file?.name
      ? file?.name?.split(".")
      : file?.documentName?.split(".");

    const slittedType = file
      ? srcSplitted[srcSplitted.length - 1].split("-")[0]
      : null;
    setFileFormatIdCard(slittedType);
  };

  const fileInputRefPassportPhoto = useRef(null);
  const fileInputRefIdCard = useRef(null);

  const validateNIN = async () => {
    setNINResponse({
      success: "",
      error: "",
    });
    const payload = {
      dateOfBirth: currentAgent?.businessContact?.dob,
      identificationNumber: kycDetailsForm?.nin,
      firstName: currentAgent?.businessContact?.firstname,
      agentPhoneNumber: currentAgent?.agentMobileNo,
      lastName: currentAgent?.businessContact?.lastname,
      phoneNumber: currentAgent?.businessContact?.phoneNo,
    };

    const { status, response } = await platform.validateNINDetails(
      payload,
      'NIN',
    )
    setNINData(response);

    setLoading(false);
    if (status === ERROR_STATUS) {
      setNINResponse({ error: response?.data?.validationStatus });
    } else if (status === SUCCESS_STATUS) {
      setNINResponse({ success: response?.data?.validationStatus });
      if (ninResponse?.success === 'VERIFIED') {
        setNINDataValid(true)
      } else {
        setNINDataValid(false)
      }
    }

  };

  const handleClickPassportPhoto = () => {
    fileInputRefPassportPhoto.current.click();
  };
  const handleClickIdCardPhoto = () => {
    fileInputRefIdCard.current.click();
  };
  const handlePassportChange = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    if (file.size > TEN_MB) {
      setError(true);

      return;
    } else {
      setIsLoadingPassport(true);
      const uploadDoc = await platform.documentUploadAggregatorClass(
        currentAgent.agentCode,
        // `${convertFileName(filename)}`,
        "UTILITY_BILL",
        file
      );
      setIsLoadingPassport(false);
      if (uploadDoc.status === ERROR_STATUS) {
        setError(false);
        notify(" Failed to Upload.Try again", "error");
      } else {
        setError(false);

        getDocumentTypePassport(file);
        reader.onload = (e) => {
          const imageDataUrl = e.target.result;
          setPassportPhoto(imageDataUrl);

          localStorage.setItem("passportPhoto", imageDataUrl);
        };
        reader.readAsDataURL(file);
        notify("Document uploaded", "success");
        // fetchApplicationDocument(false);
      }
    }
  };


  const handleIdCardChange = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    const applicationId = application.id
    if (file?.size > TEN_MB) {
      setError(true);

      return;
    } else {
      setIsLoadingIdCard(true);

      const uploadDoc = await platform.documentUploadAggregatorClass(
        currentAgent.agentCode,
        // `${convertFileName(filename)}`,
        "ID_CARD",
        file
      );
      setIsLoadingIdCard(false);
      if (uploadDoc.status === ERROR_STATUS) {
        setError(false);
        notify(" Failed to Upload.Try again", "error");
      } else {
        setError(false);

        getDocumentTypeIdCard(file);
        reader.onload = (e) => {
          const imageDataUrl = e.target.result;
          setIdCard(imageDataUrl);

          localStorage.setItem("idCard", imageDataUrl);
        };
        reader.readAsDataURL(file);
        notify("Document uploaded", "success");
        // fetchApplicationDocument(false);
      }
    }
  };

  const handleCancelIdCard = async () => {
    setLoadingIdCardCancel(true);
    // fetchApplicationDocument(false);
    notify("Document removed", "success");
    setIdCard(null);
    localStorage.removeItem("idCard");
    setLoadingIdCardCancel(false);

  };

  const handleCancelIdCard2 = async () => {
    setLoadingIdCardCancel(true);
    // fetchApplicationDocument(false);
    notify("Document removed", "success");
    setIdCardImg(null);
    localStorage.removeItem("idCard");
    setLoadingIdCardCancel(false);

  };

  const handleCancelPassport = async () => {
    // const passportPhoto = localStorage.getItem("passportPhoto");
    setLoadingUtilityBillCancel(true);
    // fetchApplicationDocument(false);
    notify("Document removed", "success");
    setPassportPhoto(null);
    localStorage.removeItem("passportPhoto");
    setLoadingUtilityBillCancel(false);

  };

  const handleCancelPassport2 = async () => {
    setLoadingUtilityBillCancel(true);
    // fetchApplicationDocument(false);
    notify("Document removed", "success");
    setUtilityBillImg(null);
    localStorage.removeItem("passportPhoto");
    setLoadingUtilityBillCancel(false);

  };

  const confirmImage = () => {
    if (idCardImg && utilityBillImg)
      return;

  }

  const isFormValid = () => {
    return (
      kycDetailsForm.nin &&
      isNINNumberValid(kycDetailsForm) &&
      // passportPhoto &&
      // idCard &&
      ninDataValid
    );
  };

  useEffect(() => {
    const savedImageData = localStorage.getItem('idCard');

    if (savedImageData) {
      setIdCardImg(savedImageData);
    } else {
      setIdCardImg(null);
    }

    const savedPassportImage = localStorage.getItem('passportPhoto');

    if (savedPassportImage) {
      setUtilityBillImg(savedPassportImage);
    }
  }, []);

  useEffect(() => {
    if (kycDetailsForm?.nin?.length === 11) {
      validateNIN();
    }
  }, [kycDetailsForm.nin]);

  return (
    <>
      <BackButton
        onClick={() => {
          setScreen(3);
        }}
      />
      <main className="onboarding-container">
        <Col
          style={{
            margin: "20px auto",
          }}
        >
          <Title upgradeTo={upgradeTo} />
          <H1
            style={{
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "2rem",
            }}
          >
            KYC Information
          </H1>
          {ninResponse?.error === "Invalid NIN" && (
            <div
              style={{
                color: process.env.REACT_APP_RED_COLOUR,
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,

                position: "relative",
                top: "-10px",
              }}
            >
              {ninResponse.error}
            </div>
          )}
          <InputText2
            onBlur={() => {
              isNINNumberValid(kycDetailsForm) &&
                validateNIN(kycDetailsForm.nin);
            }}
            loading={loading}
            labelColor={
              !kycDetailsForm.nin ||
                isNINNumberValid(kycDetailsForm)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${!kycDetailsForm.nin ||
              isNINNumberValid(kycDetailsForm)
              ? "#DFE2E6"
              : process.env.REACT_APP_RED_COLOUR
              }`}
            onChange={(e) => {
              updateFormField({
                nin: e.target.value.trim().replace(/[^0-9]/g, ""),
              });
            }}
            value={kycDetailsForm.nin}
            maxLength={11}
            label={"Enter NIN"}
            placeholder={"Enter your NIN"}
          />
          {ninResponse.error && (
            <FormLabel
              style={{
                color: process.env.REACT_APP_RED_COLOUR,
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                lineHeight: "19px",
                position: "relative",
                top: "-15px",

                width: "100%",
              }}
            >
              {ninResponse.error}
            </FormLabel>
          )}
          {ninResponse?.success === 'NOT_VERIFIED' ?
            <div
              style={{
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                color: "red",

                position: "relative",
                top: "-10px",
              }}
            >
              {ninResponse.success}
            </div>
            :
            <div
              style={{
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                color: "#32de84",

                position: "relative",
                top: "-10px",
              }}
            >
              {ninResponse.success}
            </div>
          }

          {/* {!ninResponse?.error === "Invalid NIN" && (
            <div
              style={{
                color: process.env.REACT_APP_RED_COLOUR,
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,

                position: "relative",
                top: "-10px",
              }}
            >
              {ninResponse.error}
            </div>
          )} */}
          {idCardImg ?
            <DocumentUploader
              label={"Upload NIN Image"}
              loading={isLoadingDoc || isLoadingIdCard}
              loadingCancelDoc={loadingIdCardCancel}
              showLightbox={showLightboxId}
              fileFormat={fileFormatIdCard}
              state={idCardImg}
              setShowLightbox={setShowLightboxId}
              onChange={handleIdCardChange}
              handleDocument={handleClickIdCardPhoto}
              fileInputRef={fileInputRefIdCard}
              handleCancel={handleCancelIdCard2}
            />
            :
            <DocumentUploader
              label={"Upload NIN Image"}
              loading={isLoadingDoc || isLoadingIdCard}
              loadingCancelDoc={loadingIdCardCancel}
              showLightbox={showLightboxId}
              fileFormat={fileFormatIdCard}
              state={idCard?.documentLink ? idCard?.documentLink : idCard}
              setShowLightbox={setShowLightboxId}
              onChange={handleIdCardChange}
              handleDocument={handleClickIdCardPhoto}
              fileInputRef={fileInputRefIdCard}
              handleCancel={handleCancelIdCard}
            />
          }
          {utilityBillImg ?
            <DocumentUploader
              label={"Utility Bill"}
              loading={isLoadingDoc || isLoadingPassport}
              loadingCancelDoc={loadingPassportCancel}
              showLightbox={showLightboxPassport}
              fileFormat={fileFormatPassport}
              state={utilityBillImg}
              setShowLightbox={setShowLightboxPassport}
              onChange={handlePassportChange}
              handleDocument={handleClickPassportPhoto}
              fileInputRef={fileInputRefPassportPhoto}
              handleCancel={handleCancelPassport2}
            />
            :
            <DocumentUploader
              label={"Utility Bill"}
              loading={isLoadingDoc || isLoadingPassport}
              loadingCancelDoc={loadingPassportCancel}
              showLightbox={showLightboxPassport}
              fileFormat={fileFormatPassport}
              state={
                passportPhoto?.documentLink
                  ? passportPhoto?.documentLink
                  : passportPhoto
              }
              setShowLightbox={setShowLightboxPassport}
              onChange={handlePassportChange}
              handleDocument={handleClickPassportPhoto}
              fileInputRef={fileInputRefPassportPhoto}
              handleCancel={handleCancelPassport}
            />
          }
          <br />
          {error && (
            <Col lg={12}>
              <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                File size is above 10MB
              </span>
            </Col>
          )}
          {uploadError && (
            <Col lg={12}>
              <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                Failed to Upload.Try again
              </span>
            </Col>
          )}

          <PrimaryButton
            disabled={!isFormValid() || isLoading}
            onClick={handleSubmit}
          >
            {isLoading ? "Processing..." : "Proceed"}
          </PrimaryButton>
        </Col>
      </main>
    </>
  );
};

export default KycDocumentScene;
