import React from 'react';
import { Accordion, Card, Col, Container, Row, Table } from 'react-bootstrap';
import MdMail from 'react-ionicons/lib/MdMail';
import MdCall from 'react-ionicons/lib/IosCall'
import BaseScene from '../../base-scene';
import Text from '../../../components/text'
import Button from '../../../components/button';

// class FaqHead extends React.Component {
//   render() {
//     return <div>
//       {this.props.children}
//     </div>
//   }
// }

export default class FAQ extends BaseScene {
  state = {

  }

  render () {
    const screenSensitiveStyle = !this.state.deviceIsMobile ? {
      width: '70%',
      marginLeft: '20%', 
      marginTop: '50px',
      // padding: '50px 270px 0px 270px'
    } : { }

    const answerPointStyle = {
      color: process.env.REACT_APP_LIGHT_GREY_COLOUR,
      fontSize: this.state.deviceIsMobile ? '40px' : '55px'
    }

    const questionPointStyle = {
      color: process.env.REACT_APP_BLUE_COLOUR,
      fontSize: this.state.deviceIsMobile ? '40px' : '55px'
    }

    const answerTextStyle = {
      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
      marginTop: this.state.deviceIsMobile ? '0px' : '30px',
      // marginLeft: '20px'
    }

    const questionTextStyle = {
      fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
      marginTop: this.state.deviceIsMobile ? '0px' : '30px',
      // marginLeft: '20px'
    }

    return <Container fluid style={{fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY, backgroundColor: '#F3F5F9', margin: 0, padding: 0, width: '100%'}}>

      <div style={{
        backgroundColor: process.env.REACT_APP_BLUE_COLOUR,
        color: 'white',
        fontSize: '26px',
        fontWeight: 'bold',
        lineHeight: '31px',
        padding: '50px'
      }}>
        
        <svg hidden style={{position: 'absolute', left: '-27.46%', top: '220px'}} width="100%" height="92" viewBox="0 0 214 92" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.103399" d="M107 197C156.706 197 197 156.706 197 107C197 57.2944 156.706 17 107 17C57.2944 17 17 57.2944 17 107C17 156.706 57.2944 197 107 197Z" stroke="white" stroke-width="33"/>
        </svg>

        <svg hidden style={{position: 'absolute', left: '950px', top: '173px'}} width="80" height="82" viewBox="0 0 132 82" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.103399" d="M66 65C93.062 65 115 43.062 115 16C115 -11.062 93.062 -33 66 -33C38.938 -33 17 -11.062 17 16C17 43.062 38.938 65 66 65Z" stroke="white" stroke-width="33"/>
        </svg>

        <center style={{fontFamily: process.env.REACT_APP_FONT_FAMILY_HEADER}}>FREQUENTLY ASKED QUESTIONS (FAQs)</center>
      </div>

      <Accordion style={{marginBottom: '45px', ...screenSensitiveStyle}}>
        
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0">
            <Row style={{margin: 0, padding: 0, border: `5px solid ${process.env.REACT_APP_BLUE_COLOUR}`, borderTopWidth: '0px', borderRightWidth: '0px', borderBottomWidth: '0px'}}>
              <Col md={2} xs={2} style={questionPointStyle}>Q</Col>
              <Col md={10} xs={10} style={questionTextStyle}><strong>Who is a Quickteller Paypoint Agent?</strong></Col>
            </Row>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body style={{margin: '20px', padding: '20px', border: `5px solid ${process.env.REACT_APP_LIGHT_GREY_COLOUR}`, borderTopWidth: '0px', borderRightWidth: '0px', borderBottomWidth: '0px'}}>
              <Row style={{display: 'flex', flexDirection: 'row'}}>
                <Col md={2} xs={2} style={answerPointStyle}>A</Col>
                <Col md={10} xs={10} style={answerTextStyle}>
                  <Text style={answerTextStyle}>Any person, corporate or institution appointed by IFIS to provide Agency Banking & Financial Inclusion services on its behalf.
                    <br/>
                    Examples of potential agents are:
                    <li><strong>Individuals</strong> - Unregistered MSMEs</li>
                    <li><strong>Registered SMEs</strong> - salons, chemist shops, grocery stores, cybercafes, restaurants & bars, business center, recharge card/pay, TV center, health centers, hospitals etc.</li>
                    <li><strong>Corporates & Retail Chains</strong> - filling stations, MFBs, MDAs, fast food chains, pharmacies etc. </li>
                  </Text>
                </Col>
              </Row>

            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="1">
            <Row style={{margin: 0, padding: 0, border: `5px solid ${process.env.REACT_APP_BLUE_COLOUR}`, borderTopWidth: '0px', borderRightWidth: '0px', borderBottomWidth: '0px'}}>
              <Col md={2} xs={2} style={questionPointStyle}>Q</Col>
              <Col md={10} xs={10} style={questionTextStyle}><strong>What are the requirements for becoming an Agent?</strong></Col>
            </Row>
          </Accordion.Toggle>
          <Accordion.Collapse as={Row} eventKey="1">
            <Card.Body style={{margin: '20px', padding: '20px', border: `5px solid ${process.env.REACT_APP_LIGHT_GREY_COLOUR}`, borderTopWidth: '0px', borderRightWidth: '0px', borderBottomWidth: '0px'}}>
              <Row>
                <Col md={2} xs={2} style={answerPointStyle}>A</Col>
                <Col md={10} xs={10} style={answerTextStyle}>
                  <Text style={answerTextStyle}>Interested parties must ensure they meet the following criteria and requirements:<br /><br />

                    1)    Must be able to read and write<br /><br />

                    2)    Must have a functional android enabled mobile phone, laptop or PC<br /><br />

                    3)    Must have an existing physical shop/ business outlet<br /><br />

                    4)    A completed Agent application and agreement form<br /><br />

                    5)    A valid ID or equivalent (Driver’s license, National ID card, Int’l passport, Voter’s Card)<br /><br />

                    6)    Must provide his/her Bank Verification Number (BVN)<br /><br />
                    
                    7)    A Passport-sized photograph<br /><br />

                    8)    Utility Bill/ Proof of Address (PHCN, LAWMA, DStv, GOtv, StarTimes, Land Use Charge Bill etc.)<br /><br />

                    9)    Reference/ Guarantor<br/><br/>
                    
                    10)   Items 1-7 above are required to be a Classic or Standard IFIS Agent. However, business registration documents are required to be a Prestige IFIS Agent.<br /><br />

                  </Text>
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>  
        </Card>

        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="2">
            <Row style={{margin: 0, padding: 0, border: `5px solid ${process.env.REACT_APP_BLUE_COLOUR}`, borderTopWidth: '0px', borderRightWidth: '0px', borderBottomWidth: '0px'}}>
              <Col md={2} xs={2} style={questionPointStyle}>Q</Col>
              <Col md={10} xs={10} style={questionTextStyle}><strong>What are the benefits of being a Quickteller Paypoint Agent?</strong></Col>
            </Row>
          </Accordion.Toggle>
          <Accordion.Collapse as={Row} eventKey="2">
            <Card.Body style={{margin: '20px', padding: '20px', border: `5px solid ${process.env.REACT_APP_LIGHT_GREY_COLOUR}`, borderTopWidth: '0px', borderRightWidth: '0px', borderBottomWidth: '0px'}}>
              <Row>
                <Col md={2} xs={2} style={answerPointStyle}>A</Col>
                <Col md={10} xs={10} style={answerTextStyle}>
                  <Text style={answerTextStyle}>

                    <li>Earn additional income line to your business by performing any of the Quickteller Paypoint services & earn commission</li>
                    <li>Guaranteed volume of transactions due to a rich bouquet of products and services to offer to customers</li>
                    <li>Opportunity to become socially responsible within immediate community </li>
                    <li>Visibility of location with branding and marketing support provided to the business </li>
                    <li>Prominence of agent’s location within the financial systems ecosystem</li>
                    <li>Opportunity for local/national & international awareness of business premises </li>
                    <li>Over 8,000 existing billers & merchants services available for vending thereby increasing footfall and sales</li>
                    <li>Trusted Interswitch brand associated with a growing IFIS agent network </li>
                    <li>Guaranteed training and dedicated field support </li>

                  </Text>
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="3">
            <Row style={{margin: 0, padding: 0, border: `5px solid ${process.env.REACT_APP_BLUE_COLOUR}`, borderTopWidth: '0px', borderRightWidth: '0px', borderBottomWidth: '0px'}}>
              <Col m md={2} xs={2} style={questionPointStyle}>Q</Col>
              <Col md={10} xs={10} style={questionTextStyle}><strong>What is the Agent Registration/Setup Process?</strong></Col>
            </Row>
          </Accordion.Toggle>
          <Accordion.Collapse as={Row} eventKey="3">
            <Card.Body style={{margin: '20px', padding: '20px', border: `5px solid ${process.env.REACT_APP_LIGHT_GREY_COLOUR}`, borderTopWidth: '0px', borderRightWidth: '0px', borderBottomWidth: '0px'}}>
              <Row>
                <Col md={2} xs={2} style={answerPointStyle}>A</Col>
                <Col md={10} xs={10} style={answerTextStyle}>
                  <Text style={answerTextStyle}>
                    <strong>1)  Via QTPP field staff</strong><br/><br/>
                    <li>Provide the required documents listed in the registration document received from the field staff </li>
                    <li>Application is reviewed and approved once validated as okay</li>
                    <li>Receive your login information</li>
                    <li>Fund your wallet</li>
                    <li>Start business operations and transactions</li>
                    <li>Participate in a training session</li>
                    <li>Receive branding materials </li>

                    <br/>
                    <strong>2)  Self Service</strong><br/><br/>
                    <li>Visit  <a href="https://www.paypoint.quickteller.com" target='_blank' rel="noopener noreferrer">www.paypoint.quickteller.com</a> or <a href="https://www.ifis.quickteller.com" target='_blank' rel="noopener noreferrer">ifis.quickteller.com</a> to Sign Up and complete the online agent registration forms</li>
                    <li>It is important to have all the requisite documents listed above handy whilst completing the registration </li>
                  </Text>
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="4">
            <Row style={{margin: 0, padding: 0, border: `5px solid ${process.env.REACT_APP_BLUE_COLOUR}`, borderTopWidth: '0px', borderRightWidth: '0px', borderBottomWidth: '0px'}}>
              <Col m md={2} xs={2} style={questionPointStyle}>Q</Col>
              <Col md={10} xs={10} style={questionTextStyle}><strong>How do I get a POS terminal to carry out Transactions especially Cash Out?</strong></Col>
            </Row>
          </Accordion.Toggle>
          <Accordion.Collapse as={Row} eventKey="4">
            <Card.Body style={{margin: '20px', padding: '20px', border: `5px solid ${process.env.REACT_APP_LIGHT_GREY_COLOUR}`, borderTopWidth: '0px', borderRightWidth: '0px', borderBottomWidth: '0px'}}>
              <Row>
                <Col md={2} xs={2} style={answerPointStyle}>A</Col>
                <Col md={10} xs={10} style={answerTextStyle}>
                  <Text style={answerTextStyle}>
                    You may obtain an Andriod POS terminals from IFIS by committing to a minimum of 300 transactions per month on the POS and applying through our field support staff in your state OR by outright purchase on  <a href="https://www.quickteller.com/quicktellerpaypoint" target='_blank' rel="noopener noreferrer">www.quickteller.com/quicktellerpaypoint</a> Once payment is confirmed, 
                    the POS terminal will be dispatched to your location within 5-10 working days.
                  </Text>
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="5">
            <Row style={{margin: 0, padding: 0, border: `5px solid ${process.env.REACT_APP_BLUE_COLOUR}`, borderTopWidth: '0px', borderRightWidth: '0px', borderBottomWidth: '0px'}}>
              <Col m md={2} xs={2} style={questionPointStyle}>Q</Col>
              <Col md={10} xs={10} style={questionTextStyle}><strong>How do I contact your business for any issues, enquires or information?</strong></Col>
            </Row>
          </Accordion.Toggle>
          <Accordion.Collapse as={Row} eventKey="5">
            <Card.Body style={{margin: '20px', padding: '20px', border: `5px solid ${process.env.REACT_APP_LIGHT_GREY_COLOUR}`, borderTopWidth: '0px', borderRightWidth: '0px', borderBottomWidth: '0px'}}>
              <Row>
                <Col md={2} xs={2} style={answerPointStyle}>A</Col>
                <Col md={10} xs={10} style={answerTextStyle}>
                  <Text style={answerTextStyle}>
                    You can reach us through any of the following medium: <br/>
                    <li><strong>By phone:</strong> <a href='tel:07009065000'>07009065000</a></li>
                    <li><strong>By email:</strong> <a href='mailto:ifiscustomercare@interswitchgroup.com'>ifiscustomercare@interswitchgroup.com</a></li>
                    <li><strong>Online help portal:</strong> <a href="https://help.interswitchgroup.com" target='_blank' rel="noopener noreferrer">https://help.interswitchgroup.com</a> </li>
                    <li><strong>By staff:</strong> Financial Inclusion Partners (FIPs) in your respective states </li>
                  </Text>
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="6">
            <Row style={{margin: 0, padding: 0, border: `5px solid ${process.env.REACT_APP_BLUE_COLOUR}`, borderTopWidth: '0px', borderRightWidth: '0px', borderBottomWidth: '0px'}}>
              <Col m md={2} xs={2} style={questionPointStyle}>Q</Col>
              <Col md={10} xs={10} style={questionTextStyle}><strong>How do we reach you to inform you of any updates, new services or general matters?</strong></Col>
            </Row>
          </Accordion.Toggle>
          <Accordion.Collapse as={Row} eventKey="6">
            <Card.Body style={{margin: '20px', padding: '20px', border: `5px solid ${process.env.REACT_APP_LIGHT_GREY_COLOUR}`, borderTopWidth: '0px', borderRightWidth: '0px', borderBottomWidth: '0px'}}>
              <Row>
                <Col md={2} xs={2} style={answerPointStyle}>A</Col>
                <Col md={10} xs={10} style={answerTextStyle}>
                  <Text style={answerTextStyle}>
                    IFIS would typically reach out to our esteemed agents via any of the following medium:  <br/>
                    <li><strong>Our staff:</strong> The financial inclusion partner (field support staff) in your respective states OR appointed 3rd parties who will identify themselves to you </li>
                    <li><strong>Via SMS:</strong> Periodic SMS messages to your registered mobile phone number with IFIS</li>
                    <li><strong>Via Newsletter:</strong> Look out for QUICK UPDATES email, sent from Paypoint@quickteller.com on a weekly basis to your registered email address with IFIS  </li>
                    <li><strong>Via Instagram:</strong> <a href="http://www.instagram.com/QuicktellerPaypoint" target='_blank' rel="noopener noreferrer">Quickteller Paypoint</a> </li>
                    <li><strong>Via Facebook:</strong> <a href="http://www.facebook.com/QuicktellerPaypoint" target='_blank' rel="noopener noreferrer">Quickteller Paypoint</a></li> 
                    <li><strong>Via In-app messages:</strong> Look out for messages directly on the agent app when you log in to transact </li> 
                  </Text>
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="7">
            <Row style={{margin: 0, padding: 0, border: `5px solid ${process.env.REACT_APP_BLUE_COLOUR}`, borderTopWidth: '0px', borderRightWidth: '0px', borderBottomWidth: '0px'}}>
              <Col m md={2} xs={2} style={questionPointStyle}>Q</Col>
              <Col md={10} xs={10} style={questionTextStyle}><strong>How do I get branding materials, do I pay for it?</strong></Col>
            </Row>
          </Accordion.Toggle>
          <Accordion.Collapse as={Row} eventKey="7">
            <Card.Body style={{margin: '20px', padding: '20px', border: `5px solid ${process.env.REACT_APP_LIGHT_GREY_COLOUR}`, borderTopWidth: '0px', borderRightWidth: '0px', borderBottomWidth: '0px'}}>
              <Row>
                <Col md={2} xs={2} style={answerPointStyle}>A</Col>
                <Col md={10} xs={10} style={answerTextStyle}>
                  <Text style={answerTextStyle}>
                    Branding materials (T-shirts, banners, stickers, signages etc.) are given free of charge to you upon registration & commencement of transactions as a Quickteller Paypoint agent.
                    Kindly contact the designated Financial Inclusion Partner (FIP) in your state/location to request for these items. 
                  </Text>
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>



        {/* <Card>
          <Accordion.Toggle as={Card.Header} eventKey="4">
            <Row style={{margin: 0, padding: 0, border: `5px solid ${process.env.REACT_APP_BLUE_COLOUR}`, borderTopWidth: '0px', borderRightWidth: '0px', borderBottomWidth: '0px'}}>
              <Col md={2} xs={2} style={questionPointStyle}>Q</Col>
              <Col md={10} xs={10} style={questionTextStyle}><strong>Classes of Agents</strong></Col>
            </Row>
          </Accordion.Toggle>
          <Accordion.Collapse as={Row} eventKey="4">
            <Card.Body style={{margin: '20px', padding: '20px', border: `5px solid ${process.env.REACT_APP_LIGHT_GREY_COLOUR}`, borderTopWidth: '0px', borderRightWidth: '0px', borderBottomWidth: '0px'}}>
              <Row>
                <Col md={2} xs={2} style={answerPointStyle}>A</Col>
                <Col md={10} xs={10} style={answerTextStyle}>
                
                <Table bordered responsive hover style={{color: '#3a3a3a', margin: 0, fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>
                  <thead style={{fontWeight: 'normal'}}>
                    <tr>
                      <th>AGENT</th>
                      <th>TRANSACTION THRESHOLD</th>
                      <th>BUSINESS CAPITAL REQ</th>
                      <th>BUSINESS DOCUMENTATION</th>
                      <th>LOCATION ADEQUACY</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><strong>PRESTIGE AGENT</strong></td>
                      <td>No debit balance allowed</td>
                      <td style={{border: 0}}>N5,000 (Minimum)</td>
                      <td><li>CAC business registered</li></td>
                      <td><li>Functional business outlet</li></td>
                    </tr>
                    <tr>
                      <td><strong>(Retail and high Volume outlets)</strong></td>
                      <td>The minimum amount allowed per transaction <strong>50</strong></td>
                      <td style={{border: 0}}></td>
                      <td><li>Form C07,</li></td>
                      <td><li>Space capacity to occupy a minimum of 5 customers</li></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>The maximum amount allowed per transaction <strong>100,000</strong></td>
                      <td style={{border: 0}}></td>
                      <td><li>Shareholders Information,</li></td>
                      <td><li>Storage capacity for POS/ merchandising materials</li></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Maximum spending limit per day <strong>500,000</strong></td>
                      <td style={{border: 0}}></td>
                      <td><li>Business Operational Proposal</li></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Maximum spending limit per week <strong>5,000,000</strong></td>
                      <td style={{border: 0}}></td>
                      <td>Provide proof of a corporate account</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Maximum spending limit per month <strong>20,000,000</strong></td>
                      <td style={{border: 0}}></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Allowed number of transactions per day <strong>300</strong></td>
                      <td style={{border: 0}}></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Allowed number of transactions per week <strong>1,500</strong></td>
                      <td style={{border: 0}}></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Allowed number of transactions per month <strong>6,000</strong></td>
                      <td style={{borderTop: 0}}></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>
                
                <Table bordered responsive hover style={{color: '#3a3a3a', margin: 0, marginTop: 50, fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>
                  <thead style={{fontWeight: 'normal'}}>
                    <tr>
                      <th>AGENT</th>
                      <th>TRANSACTION THRESHOLD</th>
                      <th>BUSINESS CAPITAL REQ</th>
                      <th>BUSINESS DOCUMENTATION</th>
                      <th>LOCATION ADEQUACY</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><strong>STANDARD AGENT</strong></td>
                      <td>No debit balance allowed</td>
                      <td style={{border: 0}}>N5,000 (Minimum)</td>
                      <td><li>Business Ownership</li></td>
                      <td><li>Functional business outlet</li></td>
                    </tr>
                    <tr>
                      <td><strong>(Mom & Pop Shops)</strong></td>
                      <td>The minimum amount allowed per transaction <strong>50</strong></td>
                      <td style={{border: 0}}></td>
                      <td>
                        <li>Provide proof of account availability</li>
                        <li>Provide utility bill</li>
                      </td>
                      <td><li>Space capacity to occupy a minimum of 2 customers</li></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>The maximum amount allowed per transaction <strong>50,000</strong></td>
                      <td style={{border: 0}}></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Maximum spending limit per day <strong>500,000</strong></td>
                      <td style={{border: 0}}></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Maximum spending limit per week <strong>2,500,000</strong></td>
                      <td style={{border: 0}}></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Maximum spending limit per month <strong>10,000,000</strong></td>
                      <td style={{border: 0}}></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Allowed number of transactions per day <strong>200</strong></td>
                      <td style={{border: 0}}></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Allowed number of transactions per week <strong>1,000</strong></td>
                      <td style={{border: 0}}></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Allowed number of transactions per month <strong>4,000</strong></td>
                      <td style={{borderTop: 0}}></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                  
                </Table>
                
                <Table bordered responsive hover style={{color: '#3a3a3a', margin: 0, marginTop: 50, fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>
                  <thead style={{fontWeight: 'normal'}}>
                    <tr>
                      <th>AGENT</th>
                      <th>TRANSACTION THRESHOLD</th>
                      <th>BUSINESS CAPITAL REQ</th>
                      <th>BUSINESS DOCUMENTATION</th>
                      <th>LOCATION ADEQUACY</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><strong>CLASSIC AGENT</strong></td>
                      <td>No debit balance allowed</td>
                      <td style={{border: 0}}>N5,000 (Minimum)</td>
                      <td><li>Business Ownership</li></td>
                      <td><li>Functional business outlet</li></td>
                    </tr>
                    <tr>
                      <td><strong><li>Umbrella Points</li></strong></td>
                      <td>The minimum amount allowed per transaction <strong>50</strong></td>
                      <td style={{border: 0}}></td>
                      <td><li>Provide proof of account availability</li></td>
                      <td><li>Space capacity to occupy a minimum of 2 customers</li></td>
                    </tr>
                    <tr>
                      <td><strong><li>Kiosks</li></strong></td>
                      <td>The maximum amount allowed per transaction <strong>30,000</strong></td>
                      <td style={{border: 0}}></td>
                      <td><li>Provide utility bill</li></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Maximum spending limit per day <strong>200,000</strong></td>
                      <td style={{border: 0}}></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Maximum spending limit per week <strong>1,000,000</strong></td>
                      <td style={{border: 0}}></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Maximum spending limit per month <strong>4,000,000</strong></td>
                      <td style={{border: 0}}></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Allowed number of transactions per day <strong>200</strong></td>
                      <td style={{border: 0}}></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Allowed number of transactions per week <strong>1,000</strong></td>
                      <td style={{border: 0}}></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Allowed number of transactions per month <strong>4,000</strong></td>
                      <td style={{borderTop: 0}}></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                  
                </Table>
                  
              </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card> */}
      </Accordion>

      <div style={{
        backgroundColor: process.env.REACT_APP_BLUE_COLOUR,
        color: 'white',
        fontSize: '26px',
        fontWeight: 'bold',
        lineHeight: '31px',
        padding: '75px',
        height: '270px',
      }}>
        
        <svg style={{position: 'absolute', left: '-27.46%', bottom: '-13%'}} width="100%" height="92" viewBox="0 0 214 92" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.103399" d="M107 197C156.706 197 197 156.706 197 107C197 57.2944 156.706 17 107 17C57.2944 17 17 57.2944 17 107C17 156.706 57.2944 197 107 197Z" stroke="white" stroke-width="33"/>
        </svg>

        <svg style={{position: 'absolute', left: '65%', bottom: '70px'}} width="80" height="82" viewBox="0 0 132 82" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.103399" d="M66 65C93.062 65 115 43.062 115 16C115 -11.062 93.062 -33 66 -33C38.938 -33 17 -11.062 17 16C17 43.062 38.938 65 66 65Z" stroke="white" stroke-width="33"/>
        </svg>

        <center>
          Can't find what you're looking for?<br></br>
          <a href='https://help.interswitchgroup.com/' target='_blank' rel="noopener noreferrer" style={{color: 'white', fontSize: process.env.REACT_APP_MID_FONT_SIZE, textAlign: 'center'}}>
            <Button style={{backgroundColor: 'white', color: process.env.REACT_APP_BLUE_COLOUR, margin: '25px'}}>Contact Us</Button>
          </a>

          {/* {this.props.moveToTab 
            ? <Button onClick={() => this.props.moveToTab('contact-us')} style={{backgroundColor: 'white', color: process.env.REACT_APP_BLUE_COLOUR, margin: '25px'}}>Contact Us</Button>
            : <div>
                <ul style={{listStyle: "none", marginTop: '10px'}}>
                  <li><MdMail color="white" fontSize="26px"/> <h6>IFISCustomercare@interswitchgroup.com</h6></li>
                  <li><MdCall color="white" fontSize="26px"/> <h6>0700-9065000</h6></li>
                </ul>
              </div>
          } */}
        </center>
      </div>

    </Container>
  }
}