import React from 'react';
import { statusColor } from './request-details';

const TrackPosDelivery = ({ requestDetails }) => {
  let arr = [];
  arr.push(
    { processing: 'Processing' },
    { paymentStatus: requestDetails.requestType },
    { approval: requestDetails.approvalStatus },
    { delivery: requestDetails.deliveryStatus }
  );

  const handleTrackingStatus = (
    processing,
    paymentStatus,
    approval,
    delivery
  ) => {
    if (processing === 'Processing') {
      return 'order-steps-completed';
    } else if (paymentStatus === 'paid' || paymentStatus === 'Paid') {
      return 'order-steps-completed';
    } else if (approval === 'declined' || approval === 'awaiting_approval') {
      return 'order-steps-processing';
    } else if (approval === 'approved') {
      return 'order-steps-completed';
    } else if (delivery === 'awaiting_delivery' || delivery === 'delivered') {
      return 'order-steps-processing';
    } else if (paymentStatus === 'free') {
      return 'order-steps-turnoff';
    } else if (!delivery) {
      return 'order-steps-turnoff';
    } else return '';
  };

  return (
    <>
      <div>
        <div className='order-steps'>
          {arr.map((arrayOfOrders, index) => {
            const { processing, approval, delivery, paymentStatus } =
              arrayOfOrders;

            return (
              <div
                key={index}
                className={handleTrackingStatus(
                  processing,
                  paymentStatus,
                  approval,
                  delivery
                )}
              >
                <h6 style={{ color: '#abb3c2', textTransform: 'capitalize' }}>
                  {processing}
                </h6>

                <h6
                  style={{
                    textTransform: 'capitalize',
                    color:
                      (paymentStatus === 'paid' || paymentStatus === 'Paid') &&
                      '#abb3c2',
                  }}
                  className={paymentStatus === 'free' && 'track-delivery-blur'}
                >
                  {paymentStatus === 'paid' || paymentStatus === 'Paid'
                    ? 'Paid'
                    : ''}
                </h6>

                <h6
                  style={{
                    color: statusColor(approval),
                    textTransform: 'capitalize',
                  }}
                >
                  {approval === 'awaiting_approval'
                    ? 'Awaiting Approval'
                    : approval}
                </h6>
                <h6 style={{ textTransform: 'capitalize' }}>
                  {delivery === 'awaiting_delivery'
                    ? 'Awaiting Delivery'
                    : delivery}
                </h6>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default TrackPosDelivery;
