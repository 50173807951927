import React, { useEffect, useState } from "react";

import { connect } from "react-redux";

import "../../app.css";
import InventoryLabel from "../../components/inventoryLabel";
import { updateLoading } from "../../../../../../../services/redux/actions/tunnel";
import { navigateTo } from "../../../../../../../services/redux/actions/navigation";
import SelectPos from "./selectPos";
import PreviewPos from "./previewPos";
import { userManagementServiceClient } from "../../../../../../../App";
import {
  ERROR_STATUS,
  SUCCESS_STATUS,
} from "../../../../../../../constants/api";
import { firetoast, notify } from "../../../../../../../components/extras";
import { useHistory } from "react-router-dom";

const RequestPosScreen = (props) => {
  const history = useHistory();
  const [requestDetails, setRequestDetails] = useState({
    quantity: 1,
    requestType: "paid",
    address: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [model, setModel] = useState([]);
  const [error, setError] = useState("");
  // const [requestId, setRequestId] = useState("");
  const [value, setValue] = useState(null);
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setRequestDetails((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const isFormValid = () => {
    return (
      requestDetails.requestType &&
      requestDetails.quantity &&
      requestDetails.address &&
      requestDetails.address.length > 2 &&
      value.label
    );
  };

  const handleValidate = async () => {
    setIsLoading(true);
    const payload = {
      posDetails: [
        {
          posModel: value.label,
          posType: value.terminalType,
          quantity: +requestDetails.quantity,
        },
      ],
      requestDeviceChannel: "Web",
      requestType: requestDetails.requestType,
      address: requestDetails.address,
      termsCondition: true,
    };

    const { status, response } =
      await userManagementServiceClient.validateAggregatorPosRequest(payload);
    if (status === ERROR_STATUS) {
      setIsLoading(false);
      firetoast("error", "Request Failed", `${response.description}`);
    } else if (status === SUCCESS_STATUS) {
      handleSubmit(response.data);
    }
  };

  const handleSubmit = async (requestId) => {
    setIsLoading(true);

    const payload = {
      posRequestId: requestId.posRequestId,
    };
    const { status, response } =
      await userManagementServiceClient.submitAggregatorPosRequest(payload);
    setIsLoading(false);
    if (status === ERROR_STATUS) {
      firetoast("error", "Request Failed", `${response.description}`);
    } else if (status === SUCCESS_STATUS) {
      notify(
        "POS Terminal request has been successful, currently pending approval",
        "success"
      );
      history.push("/agent/pos-management/aggregator/inventory");
    }
  };

  const fetchPosModel = async () => {
    const { status, response } =
      await userManagementServiceClient.getPosModel();
    if (status === ERROR_STATUS) {
      setError("Failed fetching Pos Model");
    } else if (status === SUCCESS_STATUS) {
      setModel(response.data);
    }
  };
  useEffect(() => {
    fetchPosModel();
  }, []);
  return (
    <main className="main-container">
      <InventoryLabel disableButton={true} />
      <div className="RequestPosContainerAggregator">
        <article className="select-pos-container">
          <SelectPos
            handleInputChange={handleInputChange}
            model={model}
            requestDetails={requestDetails}
            error={error}
            value={value}
            isLoading={isLoading}
            setValue={setValue}
            handleSubmit={handleValidate}
            isFormValid={isFormValid}
          />
        </article>
        <article className="preview-pos-container">
          <PreviewPos value={value} setValue={setValue} />
        </article>
      </div>
    </main>
  );
};

function mapStateToProps(state) {
  return {
    isLoading: state.tunnel.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateLoading: (isLoading) => dispatch(updateLoading(isLoading)),
    navigateTo: (pendingUrl) => dispatch(navigateTo(pendingUrl)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestPosScreen);
