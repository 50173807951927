export default class PersonalDetailsFormSerializer {
  constructor(props) {
    const { businessName, businessType, businessAddress, phone, phonePrefix, state, lga, companyRegistrationNumber,
    bankName, accountNumber} = props

    this.businessName = businessName
    this.businessType = businessType
    this.businessAddress = businessAddress
    this.phone = phone
    this.state = state
    this.localGovernmentArea = lga
    this.companyRegistrationNumber = companyRegistrationNumber
    this.cacNumber = companyRegistrationNumber
    this.bankName = bankName
    this.accountNumber = accountNumber
    
    if (phone) {  
      this.phoneNumber = phone.startsWith(phonePrefix) ? `${phone}` : `${phonePrefix}${phone}`
    }
  }

  formIsValid () {
    return this.businessName || this.businessType || this.businessAddress || this.emailAddress || this.phone
      || this.localGovernmentArea || this.state || this.companyRegistrationNumber || this.cacNumber || this.bankName || this.accountNumber
  }

  formIsComplete () {
    return Boolean(this.businessName) && Boolean(this.businessType) && Boolean(this.businessAddress)
      && Boolean(this.localGovernmentArea) && Boolean(this.state) && Boolean(this.bankName) && Boolean(this.accountNumber)
  }

  asJson () {
    return {
      businessName: this.businessName,
      businessType: this.businessType,
      address: this.businessAddress,
      emailAddress: this.emailAddress,
      phoneNumber: this.phoneNumber,
      state: this.state,
      localGovernmentArea: this.localGovernmentArea,
      companyRegistrationNumber: this.companyRegistrationNumber,
      cacNumber: this.cacNumber,
      bankName: this.bankName,
      accountNumber: this.accountNumber
    }
  }
}
