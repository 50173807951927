import React from 'react';
import ActivityIndicator from '../../../../../components/activity-indicator';
import Spinner from '../../../../../components/spinner';
import ReactTable from 'react-table';
import "react-table/react-table.css";
import { Redirect } from 'react-router-dom'
import { Row, Col, Dropdown } from 'react-bootstrap';
import MdMore from 'react-ionicons/lib/MdMore';
import { FUND_WALLET_URL } from '../../../../../utils/api-properties';
import {formatAmount}  from     '../../../../../utils/formatters';
import { convertNgkToNgn } from '../../../../../utils/converters/currencies';
//import './styles.scss';

export default class PosRemapTable extends React.Component {

    constructor(props) {
        super(props)
    
        this.state = {
            isLoading: false,
        }

        this.capitalizeFirstLetter =  this.capitalizeFirstLetter.bind(this);
        this.processValuesWithUnderscore= this.processValuesWithUnderscore.bind(this);
        this.handleRedirectToQT = this.handleRedirectToQT.bind(this);
    }

    capitalizeFirstLetter(word){
         return word.replace( word.charAt(0) , word.charAt(0).toUpperCase())
    }


    processValuesWithUnderscore=(value) =>{
        const words=    value.split("_");

          return   this.capitalizeFirstLetter(words[0]) + " " + this.capitalizeFirstLetter(words[1]);
    }

    handleRedirectToQT=()=>{
       // const envQTURL = process.env.REACT_APP_QUICKTELLER_URL;
        window.open(FUND_WALLET_URL);
       // <Redirect to={envQTURL }/>
    }



    render() {
 
        const transactionHistoryColumns = [
            {
                            Header: props => <div style={{ textAlign: "left" }}>S/N</div>,
                            Cell: (props) => (
                            <div style={{ textAlign: "left" }}>
                                {props.index+1}
                            </div>
                            ),
                            accessor: 'index',
                            headerClassName: 'table-header',
                            className: 'table-cell',
                            style: {'white-space': 'unset'},
                            width: 40
             }, 
      
           {
                        Header: props => <div style={{ textAlign: "left" }}>Initiator Username</div>,
                        accessor: 'remapInitiatorUsername',
                        Cell: props => <div style={{ textAlign: "left" }}>{props.value}</div>,
                        headerClassName: 'table-header',
                        className: 'table-cell',
                        width: 200,
                        style: { 'white-space': 'unset' }
        }, 
          {
                        Header: props => <div style={{ textAlign: "left" }}>Terminal Model</div>,
                        accessor: 'terminalModel',
                        Cell: props => <div style={{ textAlign: "left" }}>{props.value.indexOf("_") !== -1 ? this.processValuesWithUnderscore(props.value) : this.capitalizeFirstLetter( props.value)}</div>,
                        headerClassName: 'table-header',
                        className: 'table-cell',
                        width: 200,
                        style: { 'white-space': 'unset' }
          }, 
          {
                        Header: props => <div style={{ textAlign: "left" }}>Next Terminal Owner</div>,
                        accessor: 'nextTerminalOwner',
                        Cell: props => <div style={{ textAlign: "left" }}>{props.value}</div>,
                        headerClassName: 'table-header',
                        className: 'table-cell',
                        width: 200,
                        style: {'white-space': 'unset'},
          },
          {
                        Header: props => <div style={{ textAlign: "left" }}> Request Type</div>,
                        accessor: 'remapRequestType',
                        Cell: props => <div style={{ textAlign: "left" }}>{ props.value !== undefined ?this.capitalizeFirstLetter(props.value):props.value}</div>,
                        headerClassName: 'table-header',
                        className: 'table-cell',
                        style: {'white-space': 'unset'},
                        width: 100,
          },      
          {
                        Header: props => <div style={{ textAlign: "left" }}>Request Status</div>,
                        accessor: 'requestStatus',
                        Cell: props => <div className={`${props.value.toLowerCase()}-status`} style={{ textAlign: "left" }}>{ props.value === undefined?'':(props.value.indexOf("_") !== -1 ? this.processValuesWithUnderscore(props.value) : this.capitalizeFirstLetter(props.value))}</div>,
                        headerClassName: 'table-header',
                        className: 'table-cell',
                        width: 200,
                        style: {'white-space': 'unset'},
          },
              {
                        Header: props => <div style={{ textAlign: "left" }}>Unit Price</div>,
                        accessor: 'unitPrice',
                        Cell: props => <div style={{ textAlign: "left" }}> ₦{`${formatAmount(convertNgkToNgn(props.value))}`}</div>,
                        headerClassName: 'table-header',
                        className: 'table-cell',
                        style: {'white-space': 'unset'},
               },

               {
                        Header: props => <div style={{ textAlign: "left" }}>Action</div>,
                        accessor:'approvalStatus',
                        Cell: (props) => (
                            (props.value === 'pending_payment') &&     <Dropdown>
                                                <Dropdown.Toggle variant="secondary" id="dropdown-variants-secondary">
                                                    <MdMore fontSize='15px' color={process.env.REACT_APP_GREY_COLOUR} /> 
                                                </Dropdown.Toggle>
                                        
                                                <Dropdown.Menu>
                                                    { <Dropdown.Item onClick={()=>this.handleRedirectToQT()}>Make Payment</Dropdown.Item>}
                                                        
                                                </Dropdown.Menu>
                                                </Dropdown>
                        
                        ),
                        headerClassName: 'table-header',
                        className: 'table-cell',
                        style: {'white-space': 'unset'},
                        width: 60,
                 }
        ]

     return(
        <React.Fragment>
                {this.state.isRequeryLoading 
                    ? <ActivityIndicator />
                    : <ReactTable
                        columns={transactionHistoryColumns}
                        data={this.props.data}
                        pages={this.props.pages}
                        defaultPageSize={50}
                        manual
                        onFetchData={(state, instance) => {
                        this.props.getPosRemapReport(state.pageSize, state.page + 1, this.props.domainCode)
                        }}
                        loading={this.props.loading}
                        minRows={(this.props.data).length > 0 ? 3 : 2}
                        LoadingComponent={Spinner}
                    />
                }

           </React.Fragment>
           )
    }

}