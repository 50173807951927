import React from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import BaseScene from '../../../../base-scene';
import FormControl from '../../../../../components/form-control';
import H1 from '../../../../../components/h1';
import Thumbnail2 from '../../../../../components/thumbnail-2';
import { UTILITY_BILL, CHARACTER_ATTESTATION } from '../../../../../constants';


export default class BusinessDetailsForm extends BaseScene {

  constructor(props) {
    super(props);
    this.state = {
      form: {}
    }
  }


  updateFormField () {

  }

  get documentsToAttach() {
    return [
      UTILITY_BILL,
      CHARACTER_ATTESTATION,
    ]
  }

  getDocumentForThumbnail(attachedDocuments, filename) {
    return attachedDocuments.find(value => {
      const name = value.filename ? value.filename : value.documentType;
      return name.includes(filename);
    });
  }
  
  render () {
    const { form } = this.props;

    return <Form>
      <div>
        <H1 ref={this.props.formHeaderRef} style={{color: '#3A3B3B', fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY, fontSize: '24px', fontWeight: '400', padding: '35px', paddingLeft: 0, textAlign: 'left'}}>Business Information</H1>
        
        <Row>

        <Col xs={12} md={4}>
            <Form.Group>
              <Form.Label style={{color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Business Type:</Form.Label>
              <FormControl
                onChange={(event) => {
                const businessType = event.target.value
                this.updateFormField({businessType})
                }}
                style={{backgroundColor: '#E1E6ED', borderRadius: '20px', border: `1px solid ${!this.state.lastName || this.isLastNameValid() ? '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`, boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px'}}
                value={form.businessType}
                disabled 
              />
            </Form.Group>
          </Col>

          <Col xs={12} md={4}>
            <Form.Group>
              <Form.Label style={{color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Business Name:</Form.Label>
              <FormControl
                type="text"
                placeholder="Your business name"
                onChange={(event) => {
                const businessName = event.target.value
                this.updateFormField({businessName})
                }}
                style={{backgroundColor: '#E1E6ED', borderRadius: '20px', border: `1px solid ${!this.state.firstName || this.isFirstNameValid() ? '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`, boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px'}}
                value={form.businessName}
                disabled
                />
            </Form.Group>
          </Col>


          <Col xs={12} md={4}>
            <Form.Group>
              <Form.Label style={{color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>State:</Form.Label>
              <FormControl
                type="text"
                placeholder="State"
                onChange={(event) => {
                const businessName = event.target.value
                this.updateFormField({businessName})
                }}
                style={{backgroundColor: '#E1E6ED', borderRadius: '20px', border: `1px solid ${!this.state.firstName || this.isFirstNameValid() ? '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`, boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px'}}
                value={form.state}
                disabled
                />
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group>
              <Form.Label style={{color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>LGA:</Form.Label>
              <FormControl
                type="text"
                placeholder="LGA"
                onChange={(event) => {
                const businessName = event.target.value
                this.updateFormField({businessName})
                }}
                style={{backgroundColor: '#E1E6ED', borderRadius: '20px', border: `1px solid ${!this.state.firstName || this.isFirstNameValid() ? '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`, boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px'}}
                value={form.lga}
                disabled
                />
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group>
              <Form.Label style={{color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Business Address:</Form.Label>
              <FormControl
                type="text"
                placeholder="Your business address"
                onChange={(event) => {
                const businessAddress = event.target.value
                this.updateFormField({businessAddress})
                }}
                style={{backgroundColor: '#E1E6ED', borderRadius: '20px', border: `1px solid ${!this.state.lastName || this.isLastNameValid() ? '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`, boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px'}}
                value={form.businessAddress}
                disabled
                />
            </Form.Group>
          </Col>
          
          <Col xs={12} md={4}>
            <Form.Group>
              <Form.Label style={{color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Company Registration No:</Form.Label>
              <FormControl
                type="text"
                placeholder="Your company registration number"
                onChange={(event) => {
                const companyRegNo = event.target.value
                this.updateFormField({companyRegNo})
                }}
                style={{backgroundColor: '#E1E6ED', borderRadius: '20px', border: `1px solid ${!this.state.firstName || this.isFirstNameValid() ? '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`, boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px'}}
                value={form.companyRegNo}
                disabled
                />
            </Form.Group>
          </Col>          

          <Col xs={12} md={4}>
            <Form.Group>
              <Form.Label style={{color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Bank Name:</Form.Label>
              <FormControl
                type="text"
                placeholder="Bank Name"
                onChange={(event) => {
                const bankName = event.target.value
                this.updateFormField({bankName})
                }}
                style={{backgroundColor: '#E1E6ED', borderRadius: '20px', border: `1px solid ${!this.state.lastName || this.isLastNameValid() ? '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`, boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px'}}
                value={form.bankName}
                disabled
                />
            </Form.Group>
          </Col>


          <Col xs={12} md={4}>
            <Form.Group>
              <Form.Label style={{color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Account Number:</Form.Label>
              <FormControl
                type="text"
                placeholder="Account number"
                onChange={(event) => {
                const accountNumber = event.target.value
                this.updateFormField({accountNumber})
                }}
                style={{backgroundColor: '#E1E6ED', borderRadius: '20px', border: `1px solid ${!this.state.lastName || this.isLastNameValid() ? '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`, boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px'}}
                value={form.accountNumber}
                disabled
                />
            </Form.Group>
          </Col>

          <Col xs={12} md={4}>
            <Form.Group>
              <Form.Label style={{color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Account Name:</Form.Label>
              <FormControl
                type="text"
                placeholder="Account name"
                onChange={(event) => {
                const accountName = event.target.value
                this.updateFormField({accountName})
                }}
                style={{backgroundColor: '#E1E6ED', borderRadius: '20px', border: `1px solid ${!this.state.lastName || this.isLastNameValid() ? '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`, boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px'}}
                value={form.accountName}
                disabled
                />
            </Form.Group>
          </Col>

        </Row>

      </div>


      {/* <center style={{marginTop: '10px'}}>
        <Button onClick={() => {
          this.props.onSubmit()
        }}>Next</Button>
      </center> */}

    </Form>
  }
}

