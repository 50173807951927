import React, { useEffect, useRef, useState } from "react";
import { Col, Form } from "react-bootstrap";
import H1 from "../../../../../components/h1";
import FormLabel from "../../../../../components/form-label";
import PrimaryButton from "../component/button";
import InputText from "../component/input";
import CountriesStatesLga from "../../../../../fixtures/countries_states_lgas.json";

import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../../constants/api";

import { SelectInputLga, SelectInputState } from "../component/stateSelect";
import BackButton from "../component/backButton";
import Platform from "../../../../../services/api/resources/platform";
import Title from "../component/titleRevamp";
import { isHomeAddressValid } from "../../../../signup/onboarding-setup/component/extras";
import { isBusinessLgaValid, isNameValid } from "../component/extras";
import { notify } from "../../../../../components/extras";
import SelectInput from "../component/select";

const MAX_BUSINESS_NAME_LENGTH = 50;
const ResidentialAddressScene = ({ setScreen, application }) => {
  const platform = new Platform();

  const [isLoading, setIsLoading] = useState(false);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState(null);

  const [residentialAddressForm, setResidentialAddressForm] = useState({
    nationality: "",
    state: "",
    lga: "",
    address: "",
    landmark: "",
  });

  useEffect(() => {
    const updatedResidentialAddressForm = JSON.parse(
      localStorage.getItem("residentialAddressForm")
    );

    if (updatedResidentialAddressForm) {
      setResidentialAddressForm({
        nationality:
          application?.residentialAddressForm?.nationality ||
          updatedResidentialAddressForm.nationality ||
          "",
        state:
          application?.residentialAddressForm?.state ||
          updatedResidentialAddressForm.state ||
          "",
        lga:
          application?.residentialAddressForm?.lga ||
          updatedResidentialAddressForm.lga ||
          "",
        address:
          application?.residentialAddressForm?.address ||
          updatedResidentialAddressForm.address ||
          "",
        landmark:
          application?.residentialAddressForm?.landmark ||
          updatedResidentialAddressForm.landmark ||
          "",
      });
    }
  }, [application]);

  useEffect(() => {
    localStorage.setItem(
      "residentialAddressForm",
      JSON.stringify(residentialAddressForm)
    );
  }, [residentialAddressForm]);

  const updateFormField = (params) => {
    const newForm = {
      ...residentialAddressForm,
      ...params,
    };

    setResidentialAddressForm(newForm);
  };

  const fetchAllStates = async (countryId) => {
    const stateArray = CountriesStatesLga[0].states.map((state) => {
      return {
        name: state.name,
        id: state.id,
      };
    });

    setStates(stateArray);
  };

  const selectedStateData = CountriesStatesLga[0].states.find(
    (state) => state.id === Number(residentialAddressForm?.state)
  );

  const nationality = ["Nigeria"];

  const isFormValid = () => {
    return (
      residentialAddressForm.nationality &&
      residentialAddressForm.state &&
      residentialAddressForm.lga &&
      residentialAddressForm.address &&
      residentialAddressForm.landmark &&
      isHomeAddressValid(residentialAddressForm.address) &&
      isNameValid(residentialAddressForm.landmark)
    );
  };

  const handleSubmit = async () => {
    // setIsLoading(true);
    // const selectedState = CountriesStatesLga[0].states.find(
    //   (state) => state.id === Number(residentialAddressForm.state)
    // );

    // const payload = {
    //   nationality: residentialAddressForm.nationality,
    //   state: selectedState?.name,
    //   lga: residentialAddressForm.lga,
    //   address: residentialAddressForm.address,
    //   landMark: residentialAddressForm.landmark,
    // };
    // const currentAgent = JSON.parse(localStorage.getItem("currentAgent"));
    // const authToken = JSON.parse(localStorage.getItem("auth_token"));
    // const agentCode = currentAgent.domainCode;
    // const { status, response } = await platform.postResidentialUpdate(
    //   payload,
    //   agentCode,
    //   `Bearer ${authToken}`
    // );
    // setIsLoading(false);
    // if (status === ERROR_STATUS) {
    //   notify(response.description, "error");
    // } else if (status === SUCCESS_STATUS) {
    //   notify("Saved", "success");
    //   setScreen(5);
    // }
    setScreen(8);
  };

  useEffect(() => {
    fetchAllStates();
  }, []);

  useEffect(() => {
    const storedState = localStorage.getItem("state");
    const storedLga = localStorage.getItem("lga");

    if (storedState && states.length > 0) {
      const selectedStateData = states.find(
        (state) => state.name === storedState
      );
      if (selectedStateData) {
        setSelectedState(selectedStateData.id);
        setResidentialAddressForm((prevForm) => ({
          ...prevForm,
          state: selectedStateData.id,
          lga: storedLga || prevForm.lga || "",
        }));
      }
    }
  }, [states]);

  useEffect(() => {
    const savedForm = JSON.stringify(residentialAddressForm);
    if (localStorage.getItem("residentialAddressForm") !== savedForm) {
      localStorage.setItem("residentialAddressForm", savedForm);
    }
  }, [residentialAddressForm]);

  return (
    <>
      <BackButton
        onClick={() => {
          localStorage.removeItem("businessDetailsForm");
          setScreen(6);
        }}
      />

      <main className="onboarding-container">
        <Col
          style={{
            margin: "20px auto",
          }}
        >
          <Title pageNum={"6s"} />
          <H1
            style={{
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "1rem",
            }}
          >
            Residential Address
          </H1>

          <SelectInput
            onChange={(e) => {
              updateFormField({ nationality: e.target.value });
            }}
            placeholder={"nationality"}
            value={residentialAddressForm.nationality}
            option={nationality}
            label={"Nationality"}
            disabled={false}
          />

          <SelectInputState
            label={"State"}
            option={states}
            getState={localStorage.getItem("state")}
            value={residentialAddressForm.state}
            placeholder={"State"}
            onChange={(e) => {
              const selectedState = states.find(
                (value) => value.name === e.target.value
              );
              if (
                selectedState &&
                residentialAddressForm.state !== selectedState.id
              ) {
                localStorage.setItem("state", selectedState.name);
                setResidentialAddressForm({
                  ...residentialAddressForm,
                  state: selectedState.id,
                  lga: "",
                });
                localStorage.removeItem("lga");
                setSelectedState(selectedState.id);
                updateFormField({ state: selectedState.id });
              }
            }}
            default={false}
          />

          <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <label
              style={{
                color: "black",
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              LGA
            </label>
            <select
              style={{
                border: `1px solid ${
                  !isBusinessLgaValid(residentialAddressForm.lga) &&
                  residentialAddressForm.state
                    ? process.env.REACT_APP_RED_COLOUR
                    : "#DFE2E6"
                }`,
                width: "100%",
                padding: "0.5rem",
                fontSize: "14px",
                borderRadius: "4px",
                height: "50px",
                outline: "none",
              }}
              value={residentialAddressForm.lga}
              onChange={(e) => {
                const selectedLga = selectedStateData?.lgas.find(
                  (value) => value.name === e.target.value
                );

                if (selectedLga) {
                  localStorage.setItem("lga", selectedLga.name);
                  setResidentialAddressForm((prevForm) => ({
                    ...prevForm,
                    lga: selectedLga.id,
                  }));
                  updateFormField({
                    lga: selectedLga.id,
                  });
                }
              }}
            >
              <option value="" disabled>
                Select LGA
              </option>
              {selectedStateData?.lgas.map((lga) => (
                <option key={lga.id} value={lga.name}>
                  {lga.name}
                </option>
              ))}
            </select>
          </div>

          {!isBusinessLgaValid(localStorage.getItem("lga")) &&
            residentialAddressForm.state && (
              <FormLabel
                style={{
                  color: process.env.REACT_APP_RED_COLOUR,
                  fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                  lineHeight: "19px",
                  position: "relative",
                  top: "-15px",
                  width: "100%",
                }}
              >
                Select Local Government Area
              </FormLabel>
            )}

          <InputText
            label={"Address"}
            labelColor={
              !residentialAddressForm.address ||
              isHomeAddressValid(residentialAddressForm.address)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !residentialAddressForm.address ||
              isHomeAddressValid(residentialAddressForm.address)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            placeholder={"Enter home address"}
            onChange={(e) => {
              updateFormField({ address: e.target.value });
            }}
            maxLength={MAX_BUSINESS_NAME_LENGTH}
            value={residentialAddressForm.address}
          />

          <InputText
            label={"Closest Landmark"}
            labelColor={
              !residentialAddressForm.landmark ||
              isNameValid(residentialAddressForm.landmark)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !residentialAddressForm.landmark ||
              isNameValid(residentialAddressForm.landmark)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            placeholder={"Enter your house address"}
            onChange={(e) => {
              updateFormField({ landmark: e.target.value });
            }}
            maxLength={200}
            value={residentialAddressForm.landmark}
          />

          <PrimaryButton
            // disabled={!isFormValid() || isLoading}
            onClick={handleSubmit}
          >
            {isLoading ? "Processing..." : "Next"}
          </PrimaryButton>
        </Col>
      </main>
    </>
  );
};

export default ResidentialAddressScene;
