export default class SignupFormSerializer {
  constructor(props) {
    let { firstName, lastName, email, phone, phonePrefix, username, password, repeatPassword, howYouHeardAboutUs, state, lga, nationality, referralCode, otp,bvnNumber,bvnDateOfBirth } = props

    firstName = firstName.replace(/ /g, '')
    lastName = lastName.replace(/ /g, '')

    this.firstname = firstName
    this.lastname = lastName
    this.email = email
    this.phone = phone.startsWith(phonePrefix) ? `${phone}` : `${phonePrefix}${phone.slice(1,)}`
    this.username = username
    this.uniquePwd = password
    this.confirmPwd = repeatPassword
    this.howYouHeardAboutUs = howYouHeardAboutUs
    this.state = state
    this.localGovernmentArea = lga
    this.nationality = nationality
    this.referralCode = referralCode
    this.otp = otp
    this.bvnNumber = bvnNumber
    this.bvnDateOfBirth = bvnDateOfBirth
  }

  asJson () {
    return {
      firstName: this.firstname,
      lastName: this.lastname,
      email: this.email,
      mobileNo: this.phone,
      password: this.uniquePwd,
      confirmPassword: this.confirmPwd,
      otp: this.otp,
      howYouHeardAboutUs: this.howYouHeardAboutUs,
      // state: this.state,
      // localGovernmentArea: this.localGovernmentArea,
      // nationality: this.nationality,
      // referralCode: this.referralCode,
      domainCode: 'app',
      bvnNumber :this.bvnNumber,
      bvnDateOfBirth:this.bvnDateOfBirth
    }
  }
}
