import ApplicationSerializer from '../serializers/resources/application';
import UserSerializer from '../serializers/resources/user';

export function getOnboardingProgress() {
  const currentApplication = localStorage.getItem('currentApplication');
  const currentUser = localStorage.getItem('currentUser')
  const application = new ApplicationSerializer(JSON.parse(currentApplication));
  const user = new UserSerializer(JSON.parse(currentUser));

  const onboardingCompletion = (
    1 - (
      application.missingFields.length / application.numberOfRequiredFields
    )
  ) * 100;
  const phoneVerificationCompletion = user.mobileVerified ? 100 : 0;
  const emailVerificationCompletion = user.emailVerified ? 100 : 0;

  // const percentageCompletion = (
  //   onboardingCompletion * .3334 + phoneVerificationCompletion * .3334 + emailVerificationCompletion * .3334
  // );

  const progress = {
    levelPercentage: onboardingCompletion,
    requirements: [
      {
        'completed': application.missingFields.length === 0,
        'description': 'Complete your application',
        'name': 'Self Onboarding',
      },
      {
        'completed': user.mobileVerified,
        'description': 'Verify your phone number',
        'name': 'Phone Verification',
      },
      {
        'completed': user.emailVerified,
        'description': 'Verify your email',
        'name': 'Email Verification',
      },
    ]
  }

  return progress;
  
}
