export default class FacebookSerializer {

  serializeLoginResponse(payload) {
    const data = {}

    data.access_token = payload.accessToken;
    data.access_token_expiry = payload.accessTokenExpiry;
    data.avatar_url = payload.picture.data.url;
    data.email = payload.email;
    data.first_name = payload.name.split(' ')[0];
    data.last_name = payload.name.split(' ')[1];
    data.name = payload.name;
    data.user_uid_platform = payload.id;

    return data;
  }

}
