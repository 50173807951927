import React from "react";
import { Col, Row } from "react-bootstrap";

import Text from "../../../../../components/text";
import { convertNgkToNgn } from "../../../../../utils/converters/currencies";
import { formatAmount } from "../../../../../utils/formatters";
import Button from "../../../../../components/button";

export const UssdConfirmationTab = (props) => {
  //props.payment?.withdrawalRequest?.agentCommission === undefined
  return (
    <>
      <Row style={{ marginTop: "15px", height: "55vh", paddingTop: "120px" }}>
        <Col md={12} style={{}}>
          <Text
            style={{
              textAlign: "center",
              color: "grey",
              //fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            Commission Due :{" "}
            {props.payment
              ? `NGN${formatAmount(
                  convertNgkToNgn(
                    props.payment?.withdrawalRequest?.agentCommissionDue
                  )
                )}`
              : "0.0"}
          </Text>
          <Text
            style={{
              textAlign: "center",
              color: "grey",
              fontWeight: "bold",
              fontSize: "20px",
              marginBottom: "20px",
            }}
          >
            Customer should dial this code:{" "}
          </Text>

          <Text
            style={{
              fontWeight: "bold",
              textAlign: "center",
              fontSize: "50px",
              color: "grey",
            }}
          >
            {props.payment ? `${props.payment.ussdCode}` : ""}
          </Text>

          <Text
            style={{
              marginTop: "2em",
              marginBottom: "15px",
              fontSize: "12px",
              textAlign: "center",
              color: "grey",
            }}
          >
            Once your transaction is complete, click on "PROCEED" to complete
            your transaction.
          </Text>
        </Col>
      </Row>

      {/* <Row>
          <Col xs={12} md={5}>
            <Text
              style={{
                marginTop: "2em",
                marginBottom: "10px",
                fontSize: "11px",
              }}
            >
              Once your transaction is complete, click on "PROCEED" to complete
              your transaction.
            </Text>
          </Col>
        </Row> */}
      <Row style={{ display: "flex", justifyContent: "space-between" }}>
        <Col
          xs={12}
          md={5}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            disabled={props.isLoading}
            style={{ width: "100%", marginBottom: "10px" }}
            onClick={props.onUssdWithdrawalConfirmation}
          >
            {props.isLoading ? "Processing..." : "Proceed"}
          </Button>
        </Col>
        <Col xs={12} md={5}>
          <Button
            outline
            style={{
              backgroundColor: "transparent",
              marginTop: props.deviceIsMobile ? "10pt" : null,
              width: "100%",
              padding: "9pt 37pt",
              marginBottom: "10px",
            }}
            onClick={props.onCancelProduct}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </>
  );
};
