import React, { useState } from 'react';
import CommisionsLabel from '../components/commisionsLabel';
import IosArrowForward from 'react-ionicons/lib/IosArrowForward';
import H1 from '../../../../../../components/h1';
import PrimaryButton from '../../component/button';

import { useHistory } from 'react-router-dom';
import { formatAmount } from '../../../../../../utils/formatters';
import { StatusComponent } from '../../../../../../components/status';
import { ERROR_STATUS, SUCCESS_STATUS } from '../../../../../../constants/api';
import {
  firetoast,
  firetoastAggregator,
} from '../../../../../../components/extras';
import Transaction from '../../../../../../services/api/resources/transaction';
import {
  convertNgkToNgn,
  convertNgnToNgk,
} from '../../../../../../utils/converters/currencies';
const WithdrawalDetailsSceen = (props) => {
  const history = useHistory();
  const { state } = props.location;

  const labelStyle = {
    fontSize: '0.875rem',
    marginBottom: '20px',
    fontWeight: 400,
    lineHeight: '1.25rem',
  };
  const textStyle = {
    fontSize: '0.875rem',
    marginBottom: '20px',
    fontWeight: 600,
    lineHeight: '1.25rem',
  };
  const stateArray = [state];

  const transaction = new Transaction();

  const [isLoading, setIsLoading] = useState(false);

  const navigateToOverview = () => {
    history.push('/agent/dashboard');
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    const { status, response } = await transaction.unloadCommission(
      convertNgnToNgk(state.amount)
    );
    setIsLoading(false);
    if (status === ERROR_STATUS) {
      firetoast('error', `${response.description}`, '');
    } else if (status === SUCCESS_STATUS) {
      firetoastAggregator(
        'success',
        'Unload successful',
        `Your unloaded commission of ₦${formatAmount(
          state.amount
        )} are now available in your transaction wallet.`,
        'View Transaction History',
        navigateToOverview
      );
    }
  };

  return (
    <main>
      <CommisionsLabel />
      <section className='aggregator-layout'>
        <div className='unloadContainer'>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
              marginBottom: '10px',
            }}
          >
            <span
              style={{
                fontSize: '0.75rem',
                fontWeight: '400',
                cursor: 'pointer',
                color: '#353F50',
              }}
              onClick={() =>
                history.push({
                  pathname: '/agent/commissions',
                  state: 'withdrawal',
                })
              }
            >
              Withdrawals
            </span>
            <IosArrowForward color='#6B7280' fontSize='12px' />
            <span
              style={{
                fontSize: '1rem',
                fontWeight: '400',
                color: '#353F50',
              }}
            >
              {state.paymentReference}
            </span>
          </div>
          <H1
            style={{
              textAlign: 'start',
              marginBottom: '2.5rem',
            }}
          >
            Withdrawal Details
          </H1>
          <article
            style={{
              display: 'flex',
              width: '100%',
              gap: '4rem',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <span style={labelStyle}>Withdrawal Reference</span>
              <span style={labelStyle}>Narration</span>
              <span style={labelStyle}>Amount</span>
              <span style={labelStyle}>Status</span>
            </div>
            <div>
              {stateArray.map((item) => {
                const { paymentReference, narration, debitAmount, status } =
                  item;
                return (
                  <div key={paymentReference}>
                    <div style={textStyle}>{paymentReference}</div>
                    <div style={textStyle}>{narration}</div>
                    <div style={textStyle}>
                      ₦{formatAmount(convertNgkToNgn(debitAmount))}
                    </div>
                    <StatusComponent value={status.toLowerCase()} />
                  </div>
                );
              })}
            </div>
          </article>
          <div
            style={{
              display: 'flex',
              gap: '20px',
            }}
          >
            {state.status === 'failed' && (
              <PrimaryButton onClick={handleSubmit} disabled={isLoading}>
                {isLoading ? 'Processing...' : 'Try again'}
              </PrimaryButton>
            )}
            <PrimaryButton
              backgroundColor='transparent'
              color='#00425f'
              onClick={() =>
                history.push({
                  pathname: '/agent/commissions',
                  state: 'withdrawal',
                })
              }
            >
              Close
            </PrimaryButton>
          </div>
        </div>
      </section>
    </main>
  );
};

export default WithdrawalDetailsSceen;
