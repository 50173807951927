import React from "react";
import { Col, Dropdown, Form, Row } from "react-bootstrap";
import ReactPhoneInput from "react-phone-input-2";
import MdArrowDropleft from "react-ionicons/lib/MdArrowDropleft";
import MdArrowDropright from "react-ionicons/lib/MdArrowDropright";
import BaseScene from "../../base-scene";
import Button from "../../../components/button";
import CountriesStatesLga from "../../../fixtures/countries_states_lgas.json";
import FormControl from "../../../components/form-control";
import H1 from "../../../components/h1";
import Thumbnail from "../../../components/thumbnail";
import Thumbnail2 from "../../../components/thumbnail-2";
import Banks from "../../../fixtures/banks";
import BusinessTypes from "../../../fixtures/business_types";
import Onboarding from "../../../services/api/resources/onboarding";
import Platform from "../../../services/api/resources/platform";
import phoneValidators from "../../../utils/validators/phone-validators";
import { SUCCESS_STATUS, ERROR_STATUS } from "../../../constants/api";
import {
  THREE_MB,
  UTILITY_BILL,
  CERTIFICATE_OF_INCORPORATION,
  CHARACTER_ATTESTATION,
  BUSINESS_DOC_SUFFIX,
} from "../../../constants";
import Text from "../../../components/text";

const DEFAULT_BUSINESS_TYPES = ["Pharmacy", "Super Market", "Business Center"];
const MAX_ACCOUNT_NUMBER_LENGTH = 10;
const MAX_BUSINESS_NAME_LENGTH = 50;
const MAX_COMPANY_REGISTRATION_NUMBER_LENGTH = 8;
const MIN_DOCUMENTS_REQUIRED = 2;
const NIGERIA = "Nigeria";
const NIGERIA_ID = 1;
const NIGERIA_PHONE_PREFIX = "234";
const NIGERIA_SHORT_CODE = "ng";

class CustomToggle extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();

    this.props.onClick(e);
  }

  render() {
    return (
      <a href="" onClick={this.handleClick}>
        {this.props.children}
      </a>
    );
  }
}

class CustomMenu extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleChange = this.handleChange.bind(this);

    this.state = { value: "" };
  }

  handleChange(e) {
    this.setState({ value: e.target.value.toLowerCase().trim() });
  }

  render() {
    const {
      children,
      style,
      className,
      "aria-labelledby": labeledBy,
    } = this.props;

    const { value } = this.state;

    return (
      <div
        style={{
          // display: 'flex',
          // flexDirection: 'column',
          height: "170px",
          overflowY: "auto",
          ...style,
        }}
        className={className}
        aria-labelledby={labeledBy}
      >
        <FormControl
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={this.handleChange}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
}

export default class BusinessDetailsForm extends BaseScene {
  platform = new Platform();
  onboarding = new Onboarding();

  state = {
    attachedDocuments: [],
    banks: Banks,
    businessTypes: BusinessTypes,
    countries: [],
    countryShortCode: NIGERIA_SHORT_CODE,
    fieldsFocused: [],
    form: {
      businessName: null,
      phonePrefix: NIGERIA_PHONE_PREFIX,
      attachedDocuments: [],
      sameAsPersonalAddress: false,
    },
    formIsValid: null,
    lgas: [],
    previouslySavedFields: {},
    states: [],
    originalAttachedDocuments: [],
    propagateFormErrors: false,
  };

  constructor(props) {
    super(props);

    this.onFileSelect = this.onFileSelect.bind(this);
    this.onFileUnselect = this.onFileUnselect.bind(this);
  }

  componentDidMount() {
    const currentApplication = JSON.parse(
      localStorage.getItem("currentApplication")
    );

    const businessDetails = currentApplication.businessDetails || {};

    const newForm = {
      businessName: businessDetails.businessName,
      businessType: businessDetails.businessType,
      businessAddress: businessDetails.address,
      phone: businessDetails.phoneNumber,
      phonePrefix: NIGERIA_PHONE_PREFIX,
      email: businessDetails.emailAddress,
      state: businessDetails.state,
      lga: businessDetails.localGovernmentArea,
      companyRegistrationNumber: businessDetails.companyRegistrationNumber,
      cacNumber: businessDetails.cacRegistrationNumber,
      bankName: businessDetails.bankName,
      accountNumber: businessDetails.accountNumber,
    };

    this.props.onUpdateFormField(newForm);

    // this.fetchAllBusinessTypes()
    this.fetchAllCountries();
    businessDetails.state &&
      this.fetchAllLgas(NIGERIA_ID, businessDetails.state);

    const formIsValid =
      this.isBusinessNameValid(newForm) &&
      this.isCompanyRegistrationNumberValid(newForm) &&
      this.isPhoneValid(newForm) &&
      this.isBuinessTypeValid(newForm) &&
      this.isBusinessAddressValid(newForm) &&
      this.isBankValid(newForm) &&
      this.isAccountNumberValid(newForm) &&
      this.isStateValid(newForm) &&
      this.isLgaValid(newForm) &&
      currentApplication.documentsList.length >= MIN_DOCUMENTS_REQUIRED;

    if (formIsValid) {
      this.props.onSubmit(newForm);
    }

    const businessDetailsDocuments = currentApplication.documentsList
      ? currentApplication.documentsList.filter((value) =>
        value.documentType.endsWith(BUSINESS_DOC_SUFFIX)
      )
      : [];

    this.setState({
      form: newForm,
      formIsValid,
      previouslySavedFields: newForm,
      originalAttachedDocuments: businessDetailsDocuments || [],
      attachedDocuments: businessDetailsDocuments || [],
    });
  }

  componentDidUpdate(prevProps, nextState) {
    if (this.props.sameState !== prevProps.sameState) {
      this.fetchAllLgas(this.state.country, this.props.sameState);
    }
  }

  async fetchAllBusinessTypes() {
    let pageNumber = 1;
    let hasNext = true;

    while (hasNext) {
      const businessTypesResponseObj =
        await this.platform.retrieveBusinessTypes(pageNumber, 20);
      const businessTypesResponseStatus = businessTypesResponseObj.status;
      const businessTypesResponse = businessTypesResponseObj.response;

      if (businessTypesResponseStatus === SUCCESS_STATUS) {
        if (businessTypesResponse.content.length === 0) {
          hasNext = false;
        }

        const { businessTypes } = this.state;
        const newBusinessTypes = [
          ...businessTypes,
          ...businessTypesResponse.content,
        ];

        this.setState({
          businessTypes: newBusinessTypes,
        });

        pageNumber += 1;
      }
    }
  }

  async fetchAllCountries() {
    // let pageNumber = 1
    // let hasNext = true
    // let failedCount = 0

    // while (hasNext && failedCount < 3) {
    //   const countriesResponseObj = await this.platform.retrieveCountries(
    //     pageNumber,
    //     20
    //   )
    //   const countriesResponseStatus = countriesResponseObj.status
    //   const countriesResponse = countriesResponseObj.response

    //   if (countriesResponseStatus === SUCCESS_STATUS) {
    //     if (countriesResponse.content.length === 0) {
    //       hasNext = false
    //     }

    //     const { countries } = this.state
    //     const newCountries = [
    //       ...countries,
    //       ...countriesResponse.content
    //     ]

    //     this.setState({
    //       countries: newCountries
    //     })

    //     pageNumber += 1
    //   }

    //   else {
    //     failedCount += 1
    //   }

    // }

    // if (failedCount === 3) {
    //   return
    // }

    const countries = CountriesStatesLga;
    this.setState({
      countries,
    });

    const defaultNationality = countries.find(
      (value) => value.name === NIGERIA
    );
    this.setState({
      form: {
        ...this.state.form,
        nationality: defaultNationality.id,
      },
    });

    this.onFetchAllCountries(countries);
  }

  async fetchAllLgas(nationality, stateId) {
    const country = CountriesStatesLga.find((value) => {
      return parseInt(value.id) === parseInt(nationality);
    });

    const state = country.states.find(
      (value) => parseInt(value.id) === parseInt(stateId)
    );

    if (!state) {
      return;
    }

    this.setState({
      lgas: state.lgas,
    });

    // const lgasResponseObj = await this.platform.retrieveLgas(stateId)
    // const lgasResponseStatus = lgasResponseObj.status
    // const lgasResponse = lgasResponseObj.response

    // if (lgasResponseStatus === SUCCESS_STATUS) {
    //   this.setState({
    //     lgas: lgasResponse
    //   })
    // }
  }

  async fetchAllStates(countryId) {
    const country = CountriesStatesLga.find(
      (value) => parseInt(value.id) === parseInt(countryId)
    );

    this.setState({
      states: country.states,
    });
    // const statesResponseObj = await this.platform.retrieveStates(countryId)
    // const statesResponseStatus = statesResponseObj.status
    // const statesResponse = statesResponseObj.response

    // if (statesResponseStatus === SUCCESS_STATUS) {
    //   this.setState({
    //     states: statesResponse
    //   })
    // }
  }

  get documentsToAttach() {
    return [
      UTILITY_BILL,
      // CERTIFICATE_OF_INCORPORATION,
      CHARACTER_ATTESTATION,
    ];
  }

  extraDocuments(attachedDocuments) {
    return attachedDocuments.filter((value) => {
      const filename = value.filename ? value.filename : value.documentType;

      return !this.documentsToAttach.find((value) => {
        return filename.includes(value);
      });
    });
    // state.attachedDocuments
  }

  getDocumentForThumbnail(attachedDocuments, filename) {
    return attachedDocuments.find((value) => {
      const name = value.filename ? value.filename : value.documentType;
      return name.includes(filename);
    });
  }

  onFetchAllCountries(countries) {
    const nigeria = countries.find((value) => value.name === NIGERIA);
    this.setState({
      country: nigeria.id,
    });

    this.fetchAllStates(nigeria.id);
  }

  onStateSelect(stateId) {
    this.fetchAllLgas(this.state.country, stateId);
  }

  isAttachedDocumentsValid(newForm) {
    const form = newForm || this.state.form;
    return (
      this.state.attachedDocuments.length >= MIN_DOCUMENTS_REQUIRED ||
      (form.attachedDocuments &&
        form.attachedDocuments.length >= MIN_DOCUMENTS_REQUIRED)
    );
  }

  isBankValid(newForm) {
    const form = newForm || this.state.form;
    return form.bankName && form.bankName !== "0";
  }

  isBusinessNameValid(newForm) {
    const form = newForm || this.state.form;

    if (!form.businessName) {
      return false;
    }

    const businessNameSplitted = form.businessName.split(" ");
    return form.businessName.length >= 3;
  }

  isBusinessAddressValid(newForm) {
    const form = newForm || this.state.form;
    return form.businessAddress && form.businessAddress.split(" ").length >= 2;
  }

  isBuinessTypeValid(newForm) {
    const form = newForm || this.state.form;
    return form.businessType && form.businessType !== "0";
  }

  isCompanyRegistrationNumberValid(newForm) {
    const form = newForm || this.state.form;

    if (!form.companyRegistrationNumber) {
      return true;
    }

    return (
      form.companyRegistrationNumber &&
      form.companyRegistrationNumber.length >= 8 &&
      !form.companyRegistrationNumber.includes(" ")
    );
  }

  isStateValid(newForm) {
    const form = newForm || this.state.form;
    return form.state && form.state !== "0";
  }

  isLgaValid(newForm) {
    const form = newForm || this.state.form;
    return form.lga && form.lga !== "0";
  }

  isPhoneValid(newForm) {
    const form = newForm || this.state.form;

    // if (!form.phone) {
    //   return true
    // }

    const countryValidator = phoneValidators[this.state.form.phonePrefix];

    return (
      form.phone && (countryValidator ? countryValidator(form.phone) : true)
    );
  }

  isAccountNumberValid(newForm) {
    const form = newForm || this.state.form;
    return (
      form.accountNumber &&
      form.accountNumber.length === 10 &&
      !isNaN(form.accountNumber)
    );
  }

  async onFileSelect(event, filename) {
    const currentApplication = JSON.parse(
      localStorage.getItem("currentApplication")
    );
    const name = event.target.files[0].name;
    const file = event.target.files[0];

    if (file.size > THREE_MB) {
      this.setState({
        error: true,
        uploadError: false,
      });
      return;
    } else {
      this.setState({
        isLoading:
          filename === UTILITY_BILL
            ? true
            : filename === CERTIFICATE_OF_INCORPORATION
              ? true
              : filename === CHARACTER_ATTESTATION
                ? true
                : false,
      });
      const uploadDoc = await this.onboarding.documentUpload(
        currentApplication.applicationId,
        `${filename}${BUSINESS_DOC_SUFFIX}`,
        file
      );
      if (uploadDoc.status === ERROR_STATUS) {
        this.setState({
          error: false,
          uploadError: true,
          isLoading:
            filename === UTILITY_BILL
              ? false
              : filename === CERTIFICATE_OF_INCORPORATION
                ? false
                : filename === CHARACTER_ATTESTATION
                  ? false
                  : true,
        });
      } else {
        const newAttachedDocument = {
          name: `${name}${BUSINESS_DOC_SUFFIX}`,
          filename: `${filename}${BUSINESS_DOC_SUFFIX}`,
          file: file,
          url: URL.createObjectURL(file),
        };

        const newAttachedDocuments = [
          ...this.state.attachedDocuments,
          newAttachedDocument,
        ];

        this.setState({
          attachedDocuments: newAttachedDocuments,
          error: false,
          uploadError: false,
          isLoading: false,
        });

        this.updateFormField({ attachedDocuments: newAttachedDocuments });
      }
    }
  }

  onFileUnselect(file) {
    const { attachedDocuments } = this.state;
    const newAttachedDocuments = attachedDocuments.filter(
      (item) => item !== file
    );

    this.setState({
      attachedDocuments: newAttachedDocuments,
    });
  }

  propagateFormErrors() {
    this.setState({
      propagateFormErrors: true,
    });
  }

  stripNonNumbers(str) {
    return str.replace(/\D/g, "");
  }

  stripSpacesFromString(str) {
    return str.replace(/ /g, "");
  }

  toHideAttachments() {
    const businessDetailsDocuments =
      this.state.originalAttachedDocuments.filter((value) =>
        value.documentType.endsWith(BUSINESS_DOC_SUFFIX)
      );

    return (
      !this.props.showPreviouslySavedFields &&
      businessDetailsDocuments.length > 0
    );
  }

  toHideBusinessInformationHeader() {
    return (
      !this.props.showPreviouslySavedFields &&
      this.state.previouslySavedFields.businessName &&
      this.state.previouslySavedFields.businessType &&
      this.state.previouslySavedFields.businessAddress &&
      this.state.previouslySavedFields.phone &&
      this.state.previouslySavedFields.state &&
      this.state.previouslySavedFields.lga &&
      this.state.previouslySavedFields.companyRegistrationNumber
    );
  }

  toHideBankInformationHeader() {
    return (
      !this.props.showPreviouslySavedFields &&
      this.state.previouslySavedFields.bankName &&
      this.state.previouslySavedFields.accountNumber &&
      this.state.previouslySavedFields.businessAddress &&
      this.state.previouslySavedFields.phone &&
      this.state.previouslySavedFields.state &&
      this.state.previouslySavedFields.lga &&
      this.state.previouslySavedFields.bankName &&
      this.state.previouslySavedFields.accountNumber
    );
  }

  updateFormField(params) {
    const newForm = {
      ...this.state.form,
      ...params,
    };

    this.setState({
      form: newForm,
      formIsValid:
        this.isBusinessNameValid(newForm) &&
        this.isCompanyRegistrationNumberValid(newForm) &&
        this.isPhoneValid(newForm) &&
        this.isBuinessTypeValid(newForm) &&
        this.isBusinessAddressValid(newForm) &&
        this.isBankValid(newForm) &&
        this.isAccountNumberValid(newForm) &&
        this.isStateValid(newForm) &&
        this.isLgaValid(newForm) &&
        this.isAttachedDocumentsValid(newForm),
    });

    this.props.onUpdateFormField(newForm);
  }

  render() {
    const screenSensitiveStyle = !this.state.deviceIsMobile
      ? {
        padding: "0px 25px 10px 25px",
      }
      : {};

    return (
      <Form style={screenSensitiveStyle}>
        <div>
          <H1 style={{ marginTop: 10 }}>
            <MdArrowDropleft
              color={process.env.REACT_APP_BLUE_COLOUR}
              className="grow"
              onClick={this.props.goBack}
              style={{
                marginRight: "15pt",
              }}
            />
            Business Details
            <MdArrowDropright
              color={process.env.REACT_APP_BLUE_COLOUR}
              className="grow"
              onClick={() => {
                if (this.state.formIsValid === true) {
                  this.props.onSubmit(this.state.form);
                }

                this.propagateFormErrors();
                return;
              }}
              style={{
                marginLeft: "15pt",
              }}
            />
          </H1>

          <H1
            hidden={this.toHideBusinessInformationHeader()}
            ref={this.props.formHeaderRef}
            style={{
              color: "#3A3B3B",
              fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
              fontSize: "24px",
              fontWeight: "400",
              padding: "35px",
              paddingLeft: 0,
              textAlign: "left",
            }}
          >
            Business Information
          </H1>

          <Row>
            <Col
              xs={12}
              md={6}
              lg={4}
              hidden={
                !this.props.showPreviouslySavedFields &&
                this.state.previouslySavedFields.businessName
              }
            >
              <Form.Group controlId="businessName">
                <Form.Label
                  style={{
                    color: `${(!this.state.form.businessName &&
                      !this.state.fieldsFocused.includes("businessName") &&
                      !this.state.propagateFormErrors) ||
                      this.isBusinessNameValid()
                      ? "#3A3B3B"
                      : process.env.REACT_APP_RED_COLOUR
                      }`,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  Business Name: <span className="required-field-tick">*</span>
                </Form.Label>
                <FormControl
                  defaultValue={this.state.form.businessName}
                  maxLength={MAX_BUSINESS_NAME_LENGTH}
                  type="text"
                  placeholder="Your business name"
                  onBlur={() =>
                    this.setState({
                      fieldsFocused: [
                        ...this.state.fieldsFocused,
                        "businessName",
                      ],
                    })
                  }
                  onChange={(event) => {
                    const businessName = event.target.value;
                    this.updateFormField({ businessName });
                  }}
                  // maxLength={70}
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "20px",
                    border: `1px solid ${!this.state.form.businessName ||
                      this.isBusinessNameValid()
                      ? "#DFE2E6"
                      : process.env.REACT_APP_RED_COLOUR
                      }`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                  }}
                />
                {(this.state.propagateFormErrors ||
                  this.state.fieldsFocused.includes("businessName")) &&
                  !this.state.form.businessName && (
                    <Form.Label
                      style={{
                        color: process.env.REACT_APP_RED_COLOUR,
                        fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                        lineHeight: "19px",
                        position: "absolute",
                        width: "100%",
                        left: 25,
                      }}
                    >
                      Business name is required.
                    </Form.Label>
                  )}
                {!this.state.form.businessName || this.isBusinessNameValid() ? (
                  <React.Fragment />
                ) : (
                  <Form.Label
                    style={{
                      color: process.env.REACT_APP_RED_COLOUR,
                      fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                      lineHeight: "19px",
                      position: "absolute",
                      width: "100%",
                      left: 25,
                    }}
                  >
                    Business name is invalid.
                  </Form.Label>
                )}
              </Form.Group>
            </Col>

            <Col
              xs={12}
              md={6}
              lg={4}
              hidden={
                !this.props.showPreviouslySavedFields &&
                this.state.previouslySavedFields.companyRegistrationNumber
              }
            >
              <Form.Group controlId="companyRegistrationNumber">
                <Form.Label
                  style={{
                    color: `${(!this.state.form.companyRegistrationNumber &&
                      !this.state.fieldsFocused.includes(
                        "companyRegistrationNumber"
                      ) &&
                      !this.state.propagateFormErrors) ||
                      this.isCompanyRegistrationNumberValid()
                      ? "#3A3B3B"
                      : process.env.REACT_APP_RED_COLOUR
                      }`,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  Company Registration Number:
                </Form.Label>
                <FormControl
                  value={this.state.form.companyRegistrationNumber}
                  maxLength={MAX_COMPANY_REGISTRATION_NUMBER_LENGTH}
                  type="text"
                  placeholder="Your company registration number"
                  onBlur={() =>
                    this.setState({
                      fieldsFocused: [
                        ...this.state.fieldsFocused,
                        "companyRegistrationNumber",
                      ],
                    })
                  }
                  onChange={(event) => {
                    const companyRegistrationNumber =
                      this.stripSpacesFromString(event.target.value);
                    this.updateFormField({ companyRegistrationNumber });
                  }}
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "20px",
                    border: `1px solid ${!this.state.form.companyRegistrationNumber ||
                      this.isCompanyRegistrationNumberValid()
                      ? "#DFE2E6"
                      : process.env.REACT_APP_RED_COLOUR
                      }`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                  }}
                />
                {!this.state.form.companyRegistrationNumber ||
                  this.isCompanyRegistrationNumberValid() ? (
                  <React.Fragment />
                ) : (
                  <Form.Label
                    style={{
                      color: process.env.REACT_APP_RED_COLOUR,
                      fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                      lineHeight: "19px",
                      position: "absolute",
                      width: "100%",
                      left: 25,
                    }}
                  >
                    Company Registration Number is not valid.
                  </Form.Label>
                )}
              </Form.Group>
            </Col>

            <Col
              xs={12}
              md={6}
              lg={4}
              hidden={
                !this.props.showPreviouslySavedFields &&
                this.state.previouslySavedFields.phone
              }
            >
              <Form.Group controlId="phone">
                <Form.Label
                  style={{
                    color: `${(!this.state.form.phone &&
                      !this.state.fieldsFocused.includes("phone") &&
                      !this.state.propagateFormErrors) ||
                      this.isPhoneValid()
                      ? "#3A3B3B"
                      : process.env.REACT_APP_RED_COLOUR
                      }`,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  Business Mobile Number:{" "}
                  <span className="required-field-tick">*</span>
                </Form.Label>
                <ReactPhoneInput
                  buttonStyle={{
                    borderTopLeftRadius:
                      process.env.REACT_APP_BORDER_STYLE === "square"
                        ? "4px"
                        : "20px",
                    borderBottomLeftRadius:
                      process.env.REACT_APP_BORDER_STYLE === "square"
                        ? "4px"
                        : "20px",
                  }}
                  containerStyle={{ margin: 0, padding: 0, width: "100%" }}
                  defaultCountry={"ng"}
                  disableCountryCode={true}
                  inputStyle={{
                    backgroundColor: "#FFFFFF",
                    borderRadius:
                      process.env.REACT_APP_BORDER_STYLE === "square"
                        ? "4px"
                        : "20px",
                    border: `1px solid ${(!this.state.form.phone &&
                      !this.state.propagateFormErrors) ||
                      this.isPhoneValid()
                      ? "#DFE2E6"
                      : process.env.REACT_APP_RED_COLOUR
                      }`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                    width: "100%",
                  }}
                  value={this.state.form.phone || ""}
                  maxLength={11}
                  onlyCountries={["ng", "ke", "ug"]}
                  onBlur={() =>
                    this.setState({
                      fieldsFocused: [...this.state.fieldsFocused, "phone"],
                    })
                  }
                  onChange={(value, data) => {
                    this.updateFormField({ phone: value });
                  }}
                  placeholder="08012345678"
                />
                {(this.state.propagateFormErrors ||
                  this.state.fieldsFocused.includes("phone")) &&
                  !this.state.form.phone && (
                    <Form.Label
                      style={{
                        color: process.env.REACT_APP_RED_COLOUR,
                        fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                        lineHeight: "19px",
                        position: "absolute",
                        width: "100%",
                        left: 25,
                      }}
                    >
                      Phone number is required.
                    </Form.Label>
                  )}
                {!this.state.form.phone || this.isPhoneValid() ? (
                  <React.Fragment />
                ) : (
                  <Form.Label
                    style={{
                      color: process.env.REACT_APP_RED_COLOUR,
                      fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                      lineHeight: "19px",
                      position: "absolute",
                      width: "100%",
                      left: 25,
                    }}
                  >
                    Phone number is invalid.
                  </Form.Label>
                )}
              </Form.Group>
            </Col>
            <Col
              xs={12}
              md={6}
              lg={4}
              hidden={
                !this.props.showPreviouslySavedFields &&
                this.state.previouslySavedFields.businessType
              }
            >
              <Form.Group controlId="businessType">
                <Form.Label
                  style={{
                    color:
                      (!this.state.form.businessType &&
                        !this.state.fieldsFocused.includes("businessType") &&
                        !this.state.propagateFormErrors) ||
                        this.isBuinessTypeValid()
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  Business Type: <span className="required-field-tick">*</span>
                </Form.Label>
                <FormControl
                  defaultValue={this.state.form.businessType}
                  as="select"
                  onBlur={() =>
                    this.setState({
                      fieldsFocused: [
                        ...this.state.fieldsFocused,
                        "businessType",
                      ],
                    })
                  }
                  onChange={(event) => {
                    const businessType = event.target.value;
                    this.updateFormField({ businessType });
                  }}
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "20px",
                    border: `1px solid #DFE2E6`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                  }}
                >
                  <option value={"0"}>Select Business Type</option>
                  {this.state.businessTypes.map((value) => (
                    <option
                      selected={
                        value.name === this.state.form.businessType
                          ? "selected"
                          : false
                      }
                      value={value.name}
                    >
                      {value.name}
                    </option>
                  ))}
                  <option
                    selected={
                      !DEFAULT_BUSINESS_TYPES.includes(
                        this.state.form.businessType
                      )
                        ? "selected"
                        : false
                    }
                    value="Others"
                  >
                    Others
                  </option>
                </FormControl>
                <FormControl
                  placeholder="Specify business type"
                  hidden={
                    this.state.form.businessType === "0" ||
                    DEFAULT_BUSINESS_TYPES.includes(
                      this.state.form.businessType
                    )
                  }
                  onChange={(event) => {
                    const businessType = event.target.value;
                    this.updateFormField({ businessType });
                  }}
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "20px",
                    border: `1px solid #DFE2E6`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                    marginTop: "5px",
                  }}
                />
                {!this.state.form.businessType &&
                  (this.state.propagateFormErrors ||
                    this.state.fieldsFocused.includes("businessType")) && (
                    <Form.Label
                      style={{
                        color: process.env.REACT_APP_RED_COLOUR,
                        fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                        lineHeight: "19px",
                        position: "absolute",
                        width: "100%",
                        left: 25,
                      }}
                    >
                      Business type is required.
                    </Form.Label>
                  )}
                {!this.state.form.businessType || this.isBuinessTypeValid() ? (
                  <React.Fragment />
                ) : (
                  <Form.Label
                    style={{
                      color: process.env.REACT_APP_RED_COLOUR,
                      fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                      lineHeight: "19px",
                      position: "absolute",
                      width: "100%",
                      left: 25,
                    }}
                  >
                    Business type is invalid.
                  </Form.Label>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col
              xs={12}
              md={6}
              lg={8}
              hidden={
                !this.props.showPreviouslySavedFields &&
                this.state.previouslySavedFields.businessAddress
              }
            >
              <Form.Group controlId="businessAddress">
                <Form.Label
                  style={{
                    color: `${(!this.state.form.businessAddress &&
                      !this.state.fieldsFocused.includes("businessAddress") &&
                      !this.state.propagateFormErrors) ||
                      this.isBusinessAddressValid()
                      ? "#3A3B3B"
                      : process.env.REACT_APP_RED_COLOUR
                      }`,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  Business Address:{" "}
                  <span className="required-field-tick">*</span>
                </Form.Label>
                <Form.Check
                  inline
                  onChange={(event) => {
                    this.updateFormField({
                      sameAsPersonalAddress:
                        !this.state.form.sameAsPersonalAddress,
                      businessAddress: !this.state.form.sameAsPersonalAddress
                        ? this.props.sameAddress
                        : "",
                      state: !this.state.form.sameAsPersonalAddress
                        ? this.props.sameState
                        : "",
                      lga: !this.state.form.sameAsPersonalAddress
                        ? this.props.sameLga
                        : "",
                    });
                  }}
                />
                <Text
                  className="tool-tip"
                  style={{ paddingLeft: "10px", paddingBottom: "3px" }}
                >
                  Same as Personal Address
                  <span className="tool-tip-text">
                    Tick this box if your business address is same as personal
                    address
                  </span>
                </Text>
                <FormControl
                  as="textarea"
                  value={
                    this.state.form.sameAsPersonalAddress
                      ? this.props.sameAddress
                      : this.state.form.businessAddress
                  }
                  placeholder={
                    this.state.form.businessAddress || "Your business address"
                  }
                  type="text"
                  onBlur={() =>
                    this.setState({
                      fieldsFocused: [
                        ...this.state.fieldsFocused,
                        "businessAddress",
                      ],
                    })
                  }
                  onChange={(event) => {
                    const businessAddress = event.target.value;
                    this.updateFormField({ businessAddress });
                  }}
                  maxLength={200}
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "10px",
                    border: `1px solid ${!this.state.form.businessAddress ||
                      this.isBusinessAddressValid()
                      ? "#DFE2E6"
                      : process.env.REACT_APP_RED_COLOUR
                      }`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                  }}
                  disabled={this.state.form.sameAsPersonalAddress}
                />
                {!this.state.form.businessAddress &&
                  (this.state.propagateFormErrors ||
                    this.state.fieldsFocused.includes("businessAddress")) && (
                    <Form.Label
                      style={{
                        color: process.env.REACT_APP_RED_COLOUR,
                        fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                        lineHeight: "19px",
                        position: "absolute",
                        width: "100%",
                        left: 25,
                      }}
                    >
                      Business address is required.
                    </Form.Label>
                  )}
                {!this.state.form.businessAddress ||
                  this.isBusinessAddressValid() ? (
                  <React.Fragment />
                ) : (
                  <Form.Label
                    style={{
                      color: process.env.REACT_APP_RED_COLOUR,
                      fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                      lineHeight: "19px",
                      position: "absolute",
                      width: "100%",
                      left: 25,
                    }}
                  >
                    Business address is invalid.
                  </Form.Label>
                )}
              </Form.Group>
            </Col>
            <Col
              xs={12}
              md={6}
              lg={4}
              hidden={
                !this.props.showPreviouslySavedFields &&
                this.state.previouslySavedFields.state
              }
            >
              <Form.Group controlId="state">
                <Form.Label
                  style={{
                    color: `${(!this.state.form.state &&
                      !this.state.fieldsFocused.includes("state") &&
                      !this.state.propagateFormErrors) ||
                      this.isStateValid()
                      ? "#3A3B3B"
                      : process.env.REACT_APP_RED_COLOUR
                      }`,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  Business Location (State):{" "}
                  <span className="required-field-tick">*</span>
                </Form.Label>
                <FormControl
                  value={
                    this.state.form.sameAsPersonalAddress
                      ? this.props.sameState
                      : this.state.form.state
                  }
                  onBlur={() =>
                    this.setState({
                      fieldsFocused: [...this.state.fieldsFocused, "state"],
                    })
                  }
                  onChange={(event) => {
                    const state = event.target.value;
                    this.updateFormField({ state });
                    this.onStateSelect(state);
                  }}
                  disabled={this.state.form.sameAsPersonalAddress}
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "20px",
                    border: `1px solid #DFE2E6`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                    width: "100%",
                  }}
                  as="select"
                >
                  <option value={"0"}>Select State</option>
                  {this.state.states.map((value) => (
                    <option
                      selected={
                        parseInt(value.id) === parseInt(this.state.form.state)
                          ? "selected"
                          : false
                      }
                      value={value.id}
                    >
                      {value.name}
                    </option>
                  ))}
                </FormControl>
                {!this.isStateValid() &&
                  (this.state.propagateFormErrors ||
                    this.state.fieldsFocused.includes("state")) && (
                    <Form.Label
                      style={{
                        color: process.env.REACT_APP_RED_COLOUR,
                        fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                        lineHeight: "19px",
                        position: "absolute",
                        width: "100%",
                        left: 25,
                      }}
                    >
                      State is required.
                    </Form.Label>
                  )}
              </Form.Group>
            </Col>
            <Col
              xs={12}
              md={6}
              lg={4}
              hidden={
                !this.props.showPreviouslySavedFields &&
                this.state.previouslySavedFields.lga
              }
            >
              <Form.Group controlId="lga">
                <Form.Label
                  style={{
                    color:
                      (!this.state.form.lga &&
                        !this.state.fieldsFocused.includes("lga") &&
                        !this.state.propagateFormErrors) ||
                        this.isLgaValid()
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  Business Location (LGA):{" "}
                  <span className="required-field-tick">*</span>
                </Form.Label>
                <FormControl
                  value={
                    this.state.form.sameAsPersonalAddress
                      ? this.props.sameLga
                      : this.state.form.lga
                  }
                  onBlur={() =>
                    this.setState({
                      fieldsFocused: [...this.state.fieldsFocused, "lga"],
                    })
                  }
                  onChange={(event) => {
                    const lga = event.target.value;
                    this.updateFormField({ lga });
                  }}
                  disabled={this.state.form.sameAsPersonalAddress}
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "20px",
                    border: `1px solid #DFE2E6`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                    width: "100%",
                  }}
                  as="select"
                >
                  <option value={"0"}>Select LGA</option>
                  {this.state.lgas.map((value) => (
                    <option
                      selected={
                        parseInt(value.id) === parseInt(this.state.form.lga)
                          ? "selected"
                          : false
                      }
                      value={value.id}
                    >
                      {value.name}
                    </option>
                  ))}
                </FormControl>
                {!this.isLgaValid() &&
                  (this.state.propagateFormErrors ||
                    this.state.fieldsFocused.includes("lga")) && (
                    <Form.Label
                      style={{
                        color: process.env.REACT_APP_RED_COLOUR,
                        fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                        lineHeight: "19px",
                        position: "absolute",
                        width: "100%",
                        left: 25,
                      }}
                    >
                      Local Government Area is required.
                    </Form.Label>
                  )}
              </Form.Group>
            </Col>
          </Row>

          <H1
            hidden={this.toHideBankInformationHeader()}
            ref={this.props.formHeaderRef}
            style={{
              color: "#3A3B3B",
              fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
              fontSize: "24px",
              fontWeight: "400",
              padding: "35px",
              paddingLeft: 0,
              textAlign: "left",
            }}
          >
            Bank Information
          </H1>
          <Row hidden={this.toHideBankInformationHeader()}>
            <Col
              xs={12}
              md={6}
              lg={4}
              hidden={
                !this.props.showPreviouslySavedFields &&
                this.state.previouslySavedFields.bankName
              }
            >
              {/* <Form.Group>
              <Form.Label style={{color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Bank Name:</Form.Label>
              <FormControl defaultValue={this.state.form.bankName} type="text" placeholder="Bank name" onChange={(event) => {
                const bankName = event.target.value
                this.updateFormField({bankName})
              }} style={{backgroundColor: '#FFFFFF', borderRadius: '20px', border: `1px solid #DFE2E6`, boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px'}} />
            </Form.Group> */}

              <Form.Group controlId="bankName">
                <Form.Label
                  style={{
                    color:
                      (!this.state.form.bankName &&
                        !this.state.fieldsFocused.includes("bankName") &&
                        !this.state.propagateFormErrors) ||
                        this.isBankValid()
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  Bank Name: <span className="required-field-tick">*</span>
                </Form.Label>

                <Dropdown>
                  <Dropdown.Toggle
                    as={CustomToggle}
                    id="dropdown-custom-components"
                  >
                    <FormControl
                      placeholder="Select Bank"
                      value={
                        this.state.form.bankName
                          ? (
                            this.state.banks.find(
                              (value) =>
                                value.name === this.state.form.bankName
                            ) || {}
                          ).name
                          : ""
                      }
                      readOnly
                      onBlur={() =>
                        this.setState({
                          fieldsFocused: [
                            ...this.state.fieldsFocused,
                            "referralCode",
                          ],
                        })
                      }
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "20px",
                        border: "1px solid #DFE2E6",
                        boxSizing: "border-box",
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        lineHeight: "17px",
                        width: "100%",
                      }}
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu as={CustomMenu}>
                    {this.state.banks.map((value) => (
                      <Dropdown.Item
                        onClick={() =>
                          this.updateFormField({ bankName: value.name })
                        }
                        eventKey={value.name}
                      >
                        {value.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                {!this.isBankValid() &&
                  (this.state.propagateFormErrors ||
                    this.state.fieldsFocused.includes("bankName")) && (
                    <Form.Label
                      style={{
                        color: process.env.REACT_APP_RED_COLOUR,
                        fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                        lineHeight: "19px",
                        position: "absolute",
                        width: "100%",
                        left: 25,
                      }}
                    >
                      Bank is required.
                    </Form.Label>
                  )}
              </Form.Group>
            </Col>
            <Col
              xs={12}
              md={6}
              lg={4}
              hidden={
                !this.props.showPreviouslySavedFields &&
                this.state.previouslySavedFields.accountNumber
              }
            >
              <Form.Group>
                <Form.Label
                  style={{
                    color: `${(!this.state.form.accountNumber &&
                      !this.state.fieldsFocused.includes("accountNumber") &&
                      !this.state.propagateFormErrors) ||
                      this.isAccountNumberValid()
                      ? "#3A3B3B"
                      : process.env.REACT_APP_RED_COLOUR
                      }`,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  Account Number: <span className="required-field-tick">*</span>
                </Form.Label>
                <FormControl
                  maxLength={MAX_ACCOUNT_NUMBER_LENGTH}
                  value={this.state.form.accountNumber}
                  type="text"
                  placeholder="Account number"
                  onBlur={() =>
                    this.setState({
                      fieldsFocused: [
                        ...this.state.fieldsFocused,
                        "accountNumber",
                      ],
                    })
                  }
                  onChange={(event) => {
                    const accountNumber = this.stripNonNumbers(
                      event.target.value
                    );
                    this.updateFormField({ accountNumber });
                  }}
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "20px",
                    border: `1px solid ${!this.state.form.accountNumber ||
                      this.isAccountNumberValid()
                      ? "#DFE2E6"
                      : process.env.REACT_APP_RED_COLOUR
                      }`,
                    boxSizing: "border-box",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "17px",
                  }}
                />
                {!this.state.form.accountNumber &&
                  (this.state.propagateFormErrors ||
                    this.state.fieldsFocused.includes("accountNumber")) && (
                    <Form.Label
                      style={{
                        color: process.env.REACT_APP_RED_COLOUR,
                        fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                        lineHeight: "19px",
                        position: "absolute",
                        width: "100%",
                        left: 25,
                      }}
                    >
                      Account number is required.
                    </Form.Label>
                  )}
                {!this.state.form.accountNumber ||
                  this.isAccountNumberValid() ? (
                  <React.Fragment />
                ) : (
                  <Form.Label
                    style={{
                      color: process.env.REACT_APP_RED_COLOUR,
                      fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                      lineHeight: "19px",
                      position: "absolute",
                      width: "100%",
                      left: 25,
                    }}
                  >
                    Account number is invalid.
                  </Form.Label>
                )}
              </Form.Group>
            </Col>
          </Row>

          <H1
            hidden={this.toHideAttachments()}
            ref={this.props.formHeaderRef}
            style={{
              color: "#3A3B3B",
              fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
              fontSize: "24px",
              fontWeight: "400",
              padding: "35px",
              paddingLeft: 0,
              textAlign: "left",
            }}
          >
            Attachments
          </H1>
          <Row hidden={this.toHideAttachments()}>
            <Col lg={12}>
              <span
                style={{
                  color:
                    (!this.state.form.attachedDocuments &&
                      !this.state.propagateFormErrors) ||
                      this.isAttachedDocumentsValid()
                      ? "#3A3B3B"
                      : process.env.REACT_APP_RED_COLOUR,
                }}
              >
                Attach necessary documents:
              </span>{" "}
              <span className="required-field-tick">*</span>
              <br />
              <span
                style={{
                  color:
                    (!this.state.form.attachedDocuments &&
                      !this.state.propagateFormErrors) ||
                      this.isAttachedDocumentsValid()
                      ? "#3A3B3B"
                      : process.env.REACT_APP_RED_COLOUR,
                  fontSize: "12px",
                }}
              >
                Upload required documents (PDF, PNG, JPG, JPEG) not more than
                3MB in size.
              </span>
              <br />
              <a
                href={`${process.env.REACT_APP_PUBLIC_URL}/documents/CHARACTER CONFIRMATION FORM.pdf`}
                style={{
                  fontSize: "8pt",
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download the Character Attestation form
              </a>
            </Col>

            <Col
              style={{
                display: "flex",
                flexWrap: "nowrap",
                marginTop: "10px",
                overflowX: "auto",
                overflowY: "auto",
              }}
            >
              {this.documentsToAttach.map((value) => {
                let filename = value;
                return (
                  <Thumbnail2
                    attachedDocument={this.getDocumentForThumbnail(
                      this.state.attachedDocuments,
                      filename
                    )}
                    filename={filename}
                    onFileSelect={this.onFileSelect}
                    onFileUnselect={this.onFileUnselect}
                    isLoading={this.state.isLoading}
                  />
                );
              })}
              {this.extraDocuments(this.state.attachedDocuments).map(
                (value) => {
                  const name = value.documentType
                    ? value.documentType
                    : value.name;
                  if (!name.endsWith(BUSINESS_DOC_SUFFIX)) {
                    return <React.Fragment />;
                  }
                  return (
                    <Thumbnail
                      suffix={BUSINESS_DOC_SUFFIX}
                      filename={name}
                      style={{
                        width: "100px",
                        height: "100px",
                        marginRight: "10px",
                      }}
                      src={value.url}
                      location={value.documentLink}
                      onRemove={() => this.removeDocument(value)}
                    />
                  );
                }
              )}

              <div
                className="upload-btn-container"
                style={{ cursor: "pointer", height: "100px", width: "100px" }}
              >
                <button class="btn" style={{ height: "100px", width: "100px" }}>
                  +
                </button>
                <input
                  class="custom-file-input"
                  style={{ color: "transparent" }}
                  type="file"
                  accept=".pdf,.png,.jpg,.jpeg"
                  onChange={this.onFileSelect}
                />
              </div>
            </Col>
            <br />
            {this.state.error && (
              <Col lg={12}>
                <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                  File size is above 3MB
                </span>
              </Col>
            )}
            {this.state.uploadError && (
              <Col lg={12}>
                <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                  Failed to Upload.Try again
                </span>
              </Col>
            )}
          </Row>
        </div>

        <div
          style={{
            color: process.env.REACT_APP_GREY_COLOUR,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80px",
          }}
          hidden={
            !(
              this.toHideAttachments() && this.toHideBusinessInformationHeader()
            )
          }
        >
          You are done filling your business details!
        </div>

        <center style={{ marginTop: "10px" }}>
          <Button
            outline
            onClick={(e) => {
              e.preventDefault();
              this.props.goBack();
            }}
            style={{
              backgroundColor: "transparent",
              marginTop: this.props.deviceIsMobile ? "10pt" : null,
              padding: "8pt 37pt",
              marginRight: "10px",
            }}
          >
            Back
          </Button>

          <Button
            onClick={(e) => {
              e.preventDefault();
              if (this.state.formIsValid === true) {
                this.props.onSubmit(this.state.form);
              }

              this.propagateFormErrors();
              return;
            }}
          >
            Next
          </Button>
        </center>
      </Form>
    );
  }
}
