import React from "react";

export default class CountryFlagDropdownToggle extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();

    this.props.onClick(e);
  }

  render() {
    return (
      <a
        href=""
        onClick={this.handleClick}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "5px",
          marginRight: "10px",
          padding: "2px",
        }}
      >
        {this.props.children}
        <img
          src="https://img.icons8.com/ios-filled/32/ccccc/expand-arrow.png"
          alt=""
          style={{ marginLeft: "10px", height: "15px", width: "15px" }}
        />
      </a>
    );
  }
}
