import React, { Component } from 'react'

export default class H1 extends Component {
  render() {
    return <div {...this.props} style={{
      color: process.env.REACT_APP_BLUE_COLOUR,
      fontFamily: process.env.REACT_APP_FONT_FAMILY_HEADER,
      fontSize: process.env.REACT_APP_BIG_FONT_SIZE,
      fontWeight: this.props.normal ? 'normal' : '700',
      lineHeight: process.env.REACT_APP_BIG_LINE_HEIGHT,
      textAlign: this.props.leftAlign ? 'left' : 'center',
      borderBottom: this.props.underline ? '1px solid #A2A2A2' : '',
      marginBottom: this.props.underline ? '10px' : '',
      paddingBottom: this.props.underline ? '10px' : '',
      ...this.props.style
    }}>
      {this.props.children}
    </div>
  }
}
