import React, { useState } from "react";
import {
  Button,
  Card,
  Form,
  FormControl,
  Modal,
  Accordion,
} from "react-bootstrap";

import MdCloseCircle from "react-ionicons/lib/MdCloseCircle";
import { notify } from "../../../../../components/extras";
import { userManagementServiceClient } from "../../../../../App";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../../constants/api";
import qs from "qs";
import { useHistory } from "react-router-dom";

import MdArrowBack from "react-ionicons/lib/MdArrowBack";

const Layout = ({ children }) => {
  let history = useHistory();
  return (
    <>
      <div onClick={history.goBack}>
        <div
          style={{
            margin: "30px 0 0 30px",
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
          }}
        >
          <MdArrowBack fontSize="26px" />
          <span
            style={{
              display: "flex",
              justifyContent: "start",
              textDecoration: "none",
              color: "black",
            }}
          >
            Go Back
          </span>
        </div>
      </div>
      <div className="pos-layout">{children}</div>
    </>
  );
};

const ConcurDelivery = () => {
  const deviceIsTab =
    qs.parse(window.location.search)["?mobile"] || window.innerWidth <= 992;
  const requestId = localStorage.getItem("requestId");
  let history = useHistory();

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    history.goBack();
  };
  const handleShow = () => setShow(true);

  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [inputValue, setInputValue] = useState("");
  const [responseData, setResponseData] = useState();
  const [error, setError] = useState("");
  const [values, setValues] = useState([]);

  function handleInputChange(event) {
    setError("");
    setInputValue(event.target.value.trim());
  }

  const handleAddInput = async () => {
    if (inputValue !== "") {
      setLoading(true);
      const { status, response } =
        await userManagementServiceClient.validatePosTerminal(inputValue);
      setLoading(false);

      if (status === ERROR_STATUS) {
        setError(response.description);
      } else if (status === SUCCESS_STATUS) {
        setValues([...values, inputValue]);

        setInputValue("");
      }
      setLoading(false);
    }
  };

  function handleCancelInput(index) {
    const newValues = [...values];
    newValues.splice(index, 1);
    setValues(newValues);
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (values.length > 0) {
      setIsLoading(true);

      const { status, response } =
        await userManagementServiceClient.postConcurDelivery(requestId, values);
      setIsLoading(false);

      if (status === ERROR_STATUS) {
        notify(response.description, "error");
      } else if (status === SUCCESS_STATUS) {
        setResponseData(response.data);
        setValues([]);

        if (
          response.data?.successful.length > 0 &&
          response.data?.failed.length === 0
        ) {
          notify("Request Successful", "success");
        } else {
          handleShow();
        }
      }
    }
  };

  return (
    <Layout>
      <div style={{ padding: "10px" }}>
        <h3
          style={{
            color: "#111827",
            fontSize: "22px",
            fontWeight: "bold",
            marginBottom: "40px",
          }}
        >
          POS Receipt Confirmation
        </h3>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div style={{ width: "100%" }}>
            <div>
              <Form.Group
                style={{
                  padding: 0,
                }}
                controlId="Terminal-id"
              >
                <Form.Label> Terminal ID</Form.Label>

                <div
                  style={{
                    display: "flex",

                    gap: "20px",
                  }}
                >
                  <div>
                    <FormControl
                      type="text"
                      style={{
                        height: deviceIsTab ? "37px" : "50px",
                        backgroundColor: "#ffffff",
                        border: "1px solid #dfe2e6",
                        width: deviceIsTab ? "40vw" : "20vw",

                        boxSizing: "borderBox",

                        lineHeight: "17px",
                      }}
                      placeholder={
                        values.length >= 1
                          ? "Enter Another Terminal ID"
                          : "Enter Terminal ID"
                      }
                      value={inputValue}
                      onChange={handleInputChange}
                    />
                    <div
                      style={{
                        color: "tomato",
                        fontSize: "14px",
                        width: deviceIsTab ? "40vw" : "20vw",
                      }}
                    >
                      {error}
                    </div>
                  </div>

                  <Button
                    style={{
                      backgroundColor: "#00B8DE",

                      display: "flex",
                      fontSize: "16px",
                      borderRadius: "5px",
                      width: deviceIsTab ? "100%" : "9rem",
                      height: deviceIsTab ? "37px" : "50px",
                      padding: 0,
                      color: "white",
                      justifyContent: "center",
                      gap: "10px",
                      alignItems: "center",
                    }}
                    onClick={handleAddInput}
                  >
                    {loading ? (
                      "Loading..."
                    ) : (
                      <>
                        <div
                          style={{
                            fontSize: "16px",
                            color: "white",
                          }}
                        >
                          {values.length >= 1 ? "Add Another  ID" : "Add ID"}
                        </div>
                      </>
                    )}
                  </Button>
                </div>
              </Form.Group>
            </div>

            {values.length > 0 && (
              <div>
                {values.map((id, index) => {
                  return (
                    <div
                      style={{
                        width: deviceIsTab ? "100%" : "20vw",
                        padding: "10px",
                        border: "1px solid #e0e0e0",
                        borderRadius: "5px",
                        marginBottom: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        key={index}
                      >
                        <div>{id}</div>{" "}
                        <div
                          onClick={() => handleCancelInput(index)}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <MdCloseCircle color={"#FF6347"} fontSize="20px" />
                        </div>{" "}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <br />
            <br />

            <div>
              <Button
                className="posButton"
                disabled={values.length === 0 || isLoading}
                style={{
                  backgroundColor: "#00425F",
                  width: deviceIsTab ? "100%" : "10vw",

                  alignItems: "center",
                }}
                onClick={handleFormSubmit}
              >
                {isLoading ? "Loading..." : "SUBMIT"}
              </Button>
            </div>
          </div>
        </Form>
      </div>

      <Modal show={show}>
        <Modal.Header>
          <Modal.Title>Requests</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Accordion defaultActiveKey="0">
            {responseData?.successful.length > 0 && (
              <>
                <Card>
                  <Accordion.Toggle
                    style={{ cursor: "pointer" }}
                    as={Card.Header}
                    eventKey="0"
                  >
                    Status: &nbsp;
                    <span
                      style={{
                        fontWeight: "600",
                        color: "green",
                      }}
                    >
                      Successful
                    </span>{" "}
                  </Accordion.Toggle>

                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      {responseData?.successful.map((data, index) => {
                        return (
                          <div
                            style={{
                              margin: "10px",
                            }}
                            key={index}
                          >
                            <div>
                              TerminalId:{" "}
                              <span
                                style={{
                                  fontWeight: "600",
                                }}
                              >
                                {data.terminalId}
                              </span>
                            </div>
                            <div>
                              Message:{" "}
                              <span
                                style={{
                                  fontWeight: "600",
                                }}
                              >
                                {data.message}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </>
            )}
            {responseData?.failed.length > 0 && (
              <>
                <Card>
                  <Accordion.Toggle
                    style={{ cursor: "pointer" }}
                    as={Card.Header}
                    eventKey="1"
                  >
                    Status: &nbsp;
                    <span
                      style={{
                        fontWeight: 600,
                        color: "red",
                      }}
                    >
                      Failed
                    </span>{" "}
                  </Accordion.Toggle>

                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      {responseData.failed.map((data, index) => {
                        return (
                          <div
                            style={{
                              margin: "10px",
                            }}
                            key={index}
                          >
                            <div>
                              TerminalId:{" "}
                              <span
                                style={{
                                  fontWeight: "600",
                                }}
                              >
                                {data.terminalId}
                              </span>
                            </div>
                            <div>
                              Message:{" "}
                              <span
                                style={{
                                  fontWeight: "600",
                                }}
                              >
                                {data.message}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </>
            )}
          </Accordion>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              background: "red",
              color: "white",
            }}
            className="posButton"
            variant="danger"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

export default ConcurDelivery;
