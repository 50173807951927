export function deleteData(key) {
  return localStorage.removeItem(key);
}


export function saveData(key, value) {
  return localStorage.setItem(key, value);
}


export function loadData(key) {
  return localStorage.getItem(key);
}
