import React, { useEffect, useState } from "react";
import Text from "../../../../../../../components/text";
import { Col, Form } from "react-bootstrap";
import Onboarding from "../../../../../../../services/api/resources/onboarding";
import {
  ERROR_STATUS,
  SUCCESS_STATUS,
} from "../../../../../../../constants/api";

import Platform from "../../../../../../../services/api/resources/platform";
import qs from "qs";
import {
  errorResponse,
  formatDateApplication,
} from "../../../component/extras";
import {
  firetoast,
  firetoastAggregator,
} from "../../../../../../../components/extras";
import BackButton from "../../../component/backButton";
import PreviewAccordion from "../../../component/accordion";
import H1 from "../../../../../../../components/h1";
import PrimaryButton from "../../../component/button";

const imgStyle = {
  borderRadius: "5px",
  height: "9rem",
  fontSize: "14px",
  border: "1px solid grey",
  width: "8rem",
  objectFit: "cover",
};
const PersonalDetails = ({ setScreen, application, isLoading }) => {
  const personalDetails = JSON.parse(
    localStorage.getItem("personalDetailsForm")
  );
  const lga = localStorage.getItem("lga");
  const state = localStorage.getItem("state");
  return (
    <>
      <div
        onClick={() => setScreen(2)}
        style={{
          float: "right",
          cursor: "pointer",
          color: "#00425F",
          fontWeight: 600,
        }}
      >
        Edit
      </div>
      {/* <div style={{ marginBottom: '10px' }}>
        <Text bold mid>
          Phone Number:
        </Text>
        <Text style={{ color: 'grey' }} mid>
          {isLoading ? 'Loading...' : application?.agentMobileNo}
        </Text>
      </div>
      <div style={{ marginBottom: '10px' }}>
        <Text bold mid>
          Email Address:
        </Text>
        <Text style={{ color: 'grey' }} color='grey' mid>
          {isLoading ? 'Loading...' : application?.businessEmail}
        </Text>
      </div>
      <div style={{ marginBottom: '10px' }}>
        <Text bold mid>
          Gender:
        </Text>
        <Text style={{ color: 'grey' }} color='grey' mid>
          {isLoading ? 'Loading...' : application?.businessContact?.gender}
        </Text>
      </div>

      <div style={{ marginBottom: '10px' }}>
        <Text bold mid>
          BVN:
        </Text>
        <Text style={{ color: 'grey' }} mid>
          {isLoading ? 'Loading...' : application?.bvn}
        </Text>
      </div> */}
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Mother's Maiden Name:
        </Text>
        <Text style={{ color: "grey" }} mid>
          {personalDetails.motherMaidenName}
        </Text>
      </div>
      {localStorage.getItem("upgradeTo") === "prestige" && (
        <>
          <div style={{ marginBottom: "10px" }}>
            <Text bold mid>
              State:
            </Text>
            <Text style={{ color: "grey" }} mid>
              {state}
            </Text>
          </div>
          <div style={{ marginBottom: "10px" }}>
            <Text bold mid>
              Local Government Area:
            </Text>
            <Text style={{ color: "grey" }} mid>
              {lga}
            </Text>
          </div>
        </>
      )}
    </>
  );
};

const KycInformation = ({ setScreen, application, isLoading, documents }) => {
  const convertFileName = (fileName) => {
    if (fileName === "UTILITY_BILL") {
      return "Utility Bill";
    } else if (fileName === "PASSPORT_PHOTO") {
      return "Passport Photograph";
    } else if (fileName === "ID_CARD") {
      return "Government Issued ID";
    } else if (fileName === "ADDRESS_VERIFICATION_FORM") {
      return "Address Verification Form";
    } else if (fileName === "CHARACTER_FORM") {
      return "Character Form";
    }
  };

  const kycDetailsForm = JSON.parse(localStorage.getItem("kycDetailsForm"));

  return (
    <>
      <div
        onClick={() => setScreen(4)}
        style={{
          float: "right",
          cursor: "pointer",
          color: "#00425F",
          fontWeight: 600,
        }}
      >
        Edit
      </div>
      {/* <div style={{ marginBottom: '10px' }}>
        <Text bold mid>
          Means of Identification:
        </Text>
        <Text style={{ color: 'grey' }} mid>
          {isLoading ? 'Loading...' : application?.identificationType}
        </Text>
      </div>
      <div style={{ marginBottom: '10px' }}>
        <Text bold mid>
          ID number:
        </Text>
        <Text style={{ color: 'grey' }} color='grey' mid>
          {isLoading ? 'Loading...' : application?.identificationNumber}
        </Text>
      </div> */}

      <div
        style={{
          display: "flex",
          gap: "10px",

          flexWrap: "wrap",
        }}
      >
        {kycDetailsForm?.attachedDocuments?.map((item, index) => {
          const { file, filename, name, url } = item;

          return (
            <>
              <div>
                <Form.Label
                  style={{
                    fontSize: "14px",
                  }}
                >
                  {filename.slice(0, -3)}
                </Form.Label>
                <div style={imgStyle} key={index}>
                  {name.includes("png") ||
                  name.includes("jpeg") ||
                  name.includes("jpg") ||
                  name.includes("svg") ? (
                    <img
                      style={imgStyle}
                      src={url}
                      title={filename.slice(0, -3)}
                      alt={filename.slice(0, -3)}
                    />
                  ) : (
                    <embed
                      name="plugin"
                      style={imgStyle}
                      src={url}
                      title={filename.slice(0, -3)}
                      alt={filename.slice(0, -3)}
                    />
                  )}
                </div>
              </div>
            </>
          );
        })}
      </div>
      {/* {isLoading ? (
        ''
      ) : (
        <div
          style={{
            display: 'flex',
            gap: '10px',

            flexWrap: 'wrap',
          }}
        >
          {documents?.map((item, index) => {
            const {
              documentType,

              documentExtention,

              documentName,
            } = item;

            return (
              <>
                <div>
                  <Form.Label
                    style={{
                      fontSize: '14px',
                    }}
                  >
                    {convertFileName(documentType)}
                  </Form.Label>
                  <div style={imgStyle} key={index}>
                    {documentExtention === 'png' ||
                    documentExtention === 'jpeg' ||
                    documentExtention === 'jpg' ||
                    documentExtention === 'svg' ? (
                      <img
                        style={{
                          fontSize: '14px',
                        }}
                        src={getDocumentUrl(documentName)}
                        title={convertFileName(documentType)}
                        alt={convertFileName(documentType)}
                      />
                    ) : (
                      <embed
                        name='plugin'
                        style={{
                          fontSize: '14px',
                        }}
                        src={getDocumentUrl(documentName)}
                        title={convertFileName(documentType)}
                        alt={convertFileName(documentType)}
                      />
                    )}
                  </div>
                </div>
              </>
            );
          })}
        </div>
      )} */}
    </>
  );
};
const BusinessDetails = ({ setScreen, application, isLoading }) => {
  const contact = application?.businessContact;
  const location = application?.businessLocation?.[0];
  const businessType = localStorage.getItem("businessType");
  const businessDetailsForm = JSON.parse(
    localStorage.getItem("businessDetailsForm")
  );
  return (
    <>
      <div
        onClick={() => setScreen(3)}
        style={{
          float: "right",
          cursor: "pointer",
          color: "#00425F",
          fontWeight: 600,
        }}
      >
        Edit
      </div>
      {/* <div style={{ marginBottom: '10px' }}>
        <Text bold mid>
          Business Name:
        </Text>
        <Text style={{ color: 'grey' }} mid>
          {isLoading
            ? 'Loading...'
            : contact?.firstname + ' ' + contact?.lastname}
        </Text>
      </div>
      <div style={{ marginBottom: '10px' }}>
        <Text bold mid>
          Business Address:
        </Text>
        <Text style={{ color: 'grey' }} color='grey' mid>
          {isLoading ? 'Loading...' : location?.addressLine1}
        </Text>
      </div>

      <div style={{ marginBottom: '10px' }}>
        <Text bold mid>
          State:
        </Text>
        <Text style={{ color: 'grey' }} color='grey' mid>
          {isLoading ? 'Loading...' : location?.state}
        </Text>
      </div>
      <div style={{ marginBottom: '10px' }}>
        <Text bold mid>
          Local Government Area:
        </Text>
        <Text style={{ color: 'grey' }} color='grey' mid>
          {isLoading ? 'Loading...' : location?.lga}
        </Text>
      </div> */}
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Business Type:
        </Text>
        <Text style={{ color: "grey" }} color="grey" mid>
          {businessType}
          {/* {isLoading ? 'Loading...' : location?.businessType} */}
        </Text>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Bank:
        </Text>
        <Text style={{ color: "grey" }} color="grey" mid>
          {businessDetailsForm.bank.label}
        </Text>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Account Number:
        </Text>
        <Text style={{ color: "grey" }} color="grey" mid>
          {businessDetailsForm.accountNumber}
        </Text>
      </div>
    </>
  );
};
// const NextOfKinDetails = ({ setScreen, application, isLoading }) => {
//   return (
//     <>
//       <div
//         onClick={() => setScreen(5)}
//         style={{
//           float: 'right',
//           cursor: 'pointer',
//           color: '#00425F',
//           fontWeight: 600,
//         }}
//       >
//         Edit
//       </div>
//       <div style={{ marginBottom: '10px' }}>
//         <Text bold mid>
//           Name:
//         </Text>
//         <Text style={{ color: 'grey' }} mid>
//           {isLoading ? 'Loading...' : application?.firstName}
//         </Text>
//       </div>
//       {/* <div style={{ marginBottom: '10px' }}>
//         <Text bold mid>
//           Middle Name:
//         </Text>
//         <Text style={{ color: 'grey' }} color='grey' mid>
//           {isLoading ? 'Loading...' : application?.middleName}
//         </Text>
//       </div> */}
//       {/* <div style={{ marginBottom: '10px' }}>
//         <Text bold mid>
//           Surname
//         </Text>
//         <Text style={{ color: 'grey' }} color='grey' mid>
//           {isLoading ? 'Loading...' : application?.surname}
//         </Text>
//       </div> */}
//       <div style={{ marginBottom: '10px' }}>
//         <Text bold mid>
//           Home Address:
//         </Text>
//         <Text style={{ color: 'grey' }} color='grey' mid>
//           {isLoading ? 'Loading...' : application?.address}
//         </Text>
//       </div>
//       <div style={{ marginBottom: '10px' }}>
//         <Text bold mid>
//           Phone Number:
//         </Text>
//         <Text style={{ color: 'grey' }} color='grey' mid>
//           {isLoading ? 'Loading...' : application?.phoneNumber}
//         </Text>
//       </div>
//       {/* <div style={{ marginBottom: '10px' }}>
//         <Text bold mid>
//           Email Address:
//         </Text>
//         <Text style={{ color: 'grey' }} color='grey' mid>
//           {isLoading ? 'Loading...' : application?.emailAddress}
//         </Text>
//       </div> */}
//       <div style={{ marginBottom: '10px' }}>
//         <Text bold mid>
//           Gender:
//         </Text>
//         <Text style={{ color: 'grey' }} color='grey' mid>
//           {isLoading ? 'Loading...' : application?.gender}
//         </Text>
//       </div>
//     </>
//   );
// };

const ApplicationReviewScene = ({ setScreen, applicationData, props }) => {
  const platform = new Platform();

  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState([]);

  // const fetchApplication = async () => {
  //   setIsLoading(true);
  //   // applicationId
  //   const { status, response } = await platform.getActiveAgentsDetails(
  //     applicationData?.id
  //   );

  //   setIsLoading(false);
  //   if (status === ERROR_STATUS) {
  //   } else if (status === SUCCESS_STATUS) {
  //     setIsLoading(false);
  //     console.log(response, 'response');

  //     setApplication(response);
  //   } else {
  //     setIsLoading(false);
  //   }
  // };

  const navigateToOverview = () => {
    props.navigateTo("/agents/applications");
  };

  const handleSubmit = async () => {
    // submitAggregatorClassUpgrage

    setLoading(true);
    // applicationId
    const payload = {};
    const { status, response } = await platform.submitAggregatorClassUpgrage(
      payload,
      applicationData.agentCode
    );
    setLoading(false);
    if (status === ERROR_STATUS) {
      errorResponse(response.code, response.description);
    } else if (status === SUCCESS_STATUS) {
      firetoastAggregator(
        "success",
        "Agent application has been successfully submitted",
        `Activation email has been sent to ${applicationData?.businessEmail}`,
        "View Application",
        navigateToOverview
      );
      localStorage.removeItem("applicationId");
      localStorage.removeItem("lga");
      localStorage.removeItem("application");
      localStorage.removeItem("businessType");
      localStorage.removeItem("state");
      localStorage.removeItem("passportPhoto");
      localStorage.removeItem("idCard");
      localStorage.removeItem("personalDetailsForm");
      localStorage.removeItem("kycDetailsForm");
      localStorage.removeItem("businessDetailsForm");
      localStorage.removeItem("nextOfKinDetailsForm");
    }
  };

  // useEffect(() => {
  //   if (applicationData) {
  //     fetchApplication();
  //   }
  // }, [applicationData]);

  return (
    <>
      <BackButton onClick={() => setScreen(1)} />
      <main className="aggregator-container">
        <Col
          style={{
            marginBottom: "20px",
            margin: "0 auto",
          }}
        >
          <H1
            style={{
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "1rem",
            }}
          >
            Application Review
          </H1>
          <PreviewAccordion index="1" title={"Personal Details"}>
            <PersonalDetails
              isLoading={isLoading}
              setScreen={setScreen}
              application={application}
            />
          </PreviewAccordion>
          <PreviewAccordion index={"0"} title={"Business Details"}>
            <BusinessDetails
              isLoading={isLoading}
              setScreen={setScreen}
              application={application}
            />
          </PreviewAccordion>
          <PreviewAccordion index={"0"} title={"KYC Information"}>
            <KycInformation
              setScreen={setScreen}
              application={application}
              documents={application?.documents}
            />
          </PreviewAccordion>

          {/* <PreviewAccordion index={'0'} title={'Next of kin details'}>
            <NextOfKinDetails
              setScreen={setScreen}
              application={application.nextOfKin}
            />
          </PreviewAccordion> */}

          <PrimaryButton disabled={loading} onClick={handleSubmit}>
            {loading ? "Processing..." : "Submit"}{" "}
          </PrimaryButton>
        </Col>
      </main>
    </>
  );
};

export default ApplicationReviewScene;
