import React, { useEffect } from "react";
import Select from "react-select";
import "../app.css";
import InputText from "../../../../aggregator-module/component/input";
import { stripNonNumbers } from "../../../../aggregator-module/component/extras";
import PrimaryButton from "../../../../aggregator-module/component/button";
import ActivityIndicator from "../../../../../../../components/activity-indicator";

const Spacer = ({ height }) => {
  return (
    <div
      style={{
        height: height,
      }}
    />
  );
};
const DistributeForm = ({
  createDistributeRequest,
  isLoading,
  loading,
  agent,

  setSelectedAgent,
  setAmount,
  amount,
  selectedAgent,
}) => {
  const styles = {
    input: (styles) => ({
      ...styles,
      height: "38px",
    }),
  };
  function isLessThanTwoDigits() {
    return String(amount).length < 2;
  }

  if (isLoading) {
    return (
      <div
        style={{
          display: "grid",
          placeContent: "center",
          height: "300px",
        }}
      >
        <ActivityIndicator />
      </div>
    );
  }

  return (
    <>
      <label style={{ fontSize: "14px" }}>Select agent</label>
      <Select
        options={agent?.map((item, i) => ({
          label: item.label,
          value: item.value,
          beneficiaryAccountNo: item.accountNo,
          beneficiaryAgentCode: item.agentCode,
          beneficiaryEmail: item.businessEmail,
          beneficiaryFirstName: item.agentBankAccount.accountName,
          beneficiaryLastName: item.agentBankAccount.accountName,
          beneficiaryName: item.agentBankAccount.accountName,
          beneficiaryPhone: item.agentMobileNo,
          businessName: item.businessName,
        }))}
        value={selectedAgent}
        onChange={(event) => {
          setSelectedAgent(event);
        }}
        isLoading={isLoading}
        styles={styles}
        classNamePrefix="react-select"
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "rgb(178, 212, 255)",
          },
        })}
      />

      <Spacer height={20} />
      <InputText
        // disabled={true}
        label={"Amount"}
        placeholder={"0"}
        onChange={(e) => {
          setAmount(stripNonNumbers(e.target.value));
        }}
        value={`₦ ${amount}`}

        // maxLength={MAX_BVN_LENGTH}
      />
      <PrimaryButton
        onClick={createDistributeRequest}
        disabled={isLessThanTwoDigits() || loading || selectedAgent === null}
      >
        {loading ? "Processing..." : " Top up Agent"}
      </PrimaryButton>
    </>
  );
};

export default DistributeForm;
