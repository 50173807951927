import Requester from "../finch-requester";

const API_BASE_URL = process.env.REACT_APP_API_ANALYTICS_SERVICE_API_BASE_URL;
export default class AnalyticsService {
  constructor(props) {
    this.apiRequester = props
      ? props.apiRequester
      : new Requester({
          apiBaseUrl: API_BASE_URL,
        });
  }

  getBalanceThreshold() {
    return this.apiRequester.get({
      endpoint: "v1/finch-analytics-service/balance-kpis",
    });
  }
  getSystemThreshold() {
    return this.apiRequester.get({
      endpoint: "v1/finch-analytics-service/balance-kpis/system",
    });
  }
  getBalance() {
    return this.apiRequester.get({
      endpoint: "v1/finch-analytics-service/agent-balance/statuses/stats",
    });
  }
  getPerformingAgentCount() {
    return this.apiRequester.get({
      endpoint: "v1/finch-analytics-service/transactions/performance/stats",
    });
  }
  getTopPerformingAgentAgent() {
    return this.apiRequester.get({
      endpoint:
        "v1/finch-analytics-service/transactions/performance/top-agents",
    });
  }
  getBalanceGrid(status = "", pageNum = null, pageSize = null) {
    return this.apiRequester.get({
      endpoint: "v1/finch-analytics-service/agent-balance/statuses",
      args: {
        status,
        pageNum,
        pageSize,
      },
    });
  }
  getPerformanceGrid(category = "", pageNum = null, pageSize = null) {
    return this.apiRequester.get({
      endpoint: "v1/finch-analytics-service/transactions/performance",
      args: {
        category,
        pageNum,
        pageSize,
      },
    });
  }
  getTransactionTarget() {
    return this.apiRequester.get({
      endpoint: "v1/finch-analytics-service/transaction-kpis",
    });
  }
  updateBalanceThreshold(data) {
    return this.apiRequester.post({
      endpoint: "v1/finch-analytics-service/balance-kpis/addOrUpdate",
      body: data,
    });
  }
  updateTransactionKpi(data) {
    return this.apiRequester.post({
      endpoint: "v1/finch-analytics-service/transaction-kpis/addOrUpdate",
      body: data,
    });
  }
}
