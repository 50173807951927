import React from "react";
import H1 from "../../../../../components/h1";
import Text from "../../../../../components/text";
import { Link } from "react-router-dom";
import { UpgradeArrowIconWhite, OvalIcon } from "../../../../../icons";


export default (props) => {
    let modifiedAgentDetails = JSON.parse(
        localStorage.getItem('modifiedAgentDetails')
    );
    const updateDashboardBackground = '/assets/media/images/upgradeDashboard-background.png';
    const handleUpgrade = () => {
        this.props.navigateTo('/manage-profile')
    };

    return (
        <>
            {modifiedAgentDetails && modifiedAgentDetails?.content[0]?.status === 0 || modifiedAgentDetails?.content[0]?.status === 1 ?
                <div style={{ width: '100%', backgroundColor: '#1F2937', borderRadius: '10px', padding: '1px' }}>
                    <span style={{ position: 'absolute', zIndex: '1', top: '140px', right: '-40px' }}>{OvalIcon()}</span>
                    <span style={{ position: 'absolute', zIndex: '1', top: '280px', right: '150px' }}>{OvalIcon()}</span>

                    <div style={{ display: 'flex', gap: '15px', marginLeft: '10px', marginTop: '8px' }}>
                        <H1 style={{ fontWeight: '700', fontSize: '16px', LineHeight: '119.2px', color: '#F3F5F6', marginBottom: '10px' }}>Upgrade your account </H1>
                        <Link to='/agent/manage-profile'><span style={{ color: 'white', marginTop: '3px' }}>{UpgradeArrowIconWhite()}</span></Link>
                    </div>
                    <p style={{ fontWeight: '200', fontSize: '12px', LineHeight: '16px', color: '#F3F5F6', marginLeft: '10px' }}>Unlock more services and features when you <br />setup your account completely.</p>
                </div > : ''
            }
        </>
    );
};
