import React, { useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from '../../../../../components/button';
import H1 from '../../../../../components/h1';
import Text from '../../../../../components/text';
import { convertNgkToNgn } from '../../../../../utils/converters/currencies';
import { formatAmount, formatDate } from '../../../../../utils/formatters';
import { usePosRequestContext } from '../context/pos-Request-context';

function PosRequestReceipt(props) {
  const statusColor = (color) => {
    if (color === 'Successful') {
      return '#74C965';
    } else if (color === 'Failed') {
      return 'red';
    } else {
      return '#EC9B40';
    }
  };

  return (
    <>
      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            Business Name:{' '}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            {props.data?.businessName}{' '}
          </Text>
        </Col>
      </Row>

      <hr />
      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            Transaction Date:{' '}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            {formatDate(props.data?.dateInitiated)}{' '}
          </Text>
        </Col>
      </Row>

      <hr />
      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            Terminal Model:{' '}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            {props.data?.terminalModel}
          </Text>
        </Col>
      </Row>
      <hr />
      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            Transaction Reference:{' '}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            {props.data?.transactionReference}
          </Text>
        </Col>
      </Row>
      <hr />
      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            Request Type
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
              textTransform: 'capitalize',
            }}
          >
            {props.data?.requestType}
          </Text>
        </Col>
      </Row>
      <hr />
      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            Narration
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
              textTransform: 'capitalize',
            }}
          >
            {props.data?.requestType === 'free'
              ? 'Pos Deployment Fee'
              : 'Pos Purchase Transaction'}
          </Text>
        </Col>
      </Row>
      <hr />
      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            Transaction status
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
              color: statusColor(props.description),
              textTransform: 'capitalize',
            }}
          >
            {props.description}
          </Text>
        </Col>
      </Row>
      <hr />
      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            Amount:{' '}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            {`NGN${formatAmount(
              convertNgkToNgn(
                props.data?.requestType === 'free'
                  ? props.data?.posDeploymentFee
                  : props.data?.totalPrice
              )
            )}`}
          </Text>
        </Col>
      </Row>
      <hr />
      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            Quantity:{' '}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            {props.data?.quantity}
          </Text>
        </Col>
      </Row>
      <hr />
    </>
  );
}

const ReceiptScreen = ({ deviceIsMobile, setScreen }) => {
  const { setValue, setAmount, receiptResponse } = usePosRequestContext();

  const refTrans = useRef();
  const printPage = () => {
    let mywindow = window.open('', 'PRINT', 'height=400,width=600');

    mywindow.document.write(`<html><head><title> </title>`);
    mywindow.document.write(`</head><body >`);
    mywindow.document.write(document.getElementById('print-receipt').innerHTML);
    mywindow.document.write(`</body></html>`);

    mywindow.document.close();
    mywindow.focus();

    mywindow.print();
    //mywindow.download();

    return true;
  };
  const onCancelProduct = () => {
    setValue('');
    setAmount(1);
    setScreen(1);
  };

  return (
    <article className='pos-receipt-screen'>
      <div
        className={`print-receipt airtime-bill-height  ${
          deviceIsMobile ? '.mobile-width' : 'pc-tab-width'
        }`}
      >
        <div className='print-receipt' id='print-receipt' ref={refTrans}>
          <div>
            <img
              alt=''
              style={{
                top: '20px',
                width: '40px',
              }}
              src='https://i.ibb.co/Qbsjn5b/isw.png'
            />

            <img
              alt=''
              style={{
                top: '40px',
                left: '500px',
                width: '100px',
                float: 'right',
              }}
              src='https://i.ibb.co/Qbsjn5b/isw.png'
            />
            <div
              style={{
                color: process.env.REACT_APP_BLUE_COLOUR,
                paddingTop: '5px',
              }}
            >
              <h6>
                <b>Thank you for using Quickteller Paypoint!</b>
              </h6>
              <span>Here is your transaction receipt.</span>
              <span>See payment details below.</span>

              <p style={{ textAlign: 'right' }}>
                <Text
                  style={{
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    color: process.env.REACT_APP_GREY_COLOUR,
                  }}
                >
                  ***ORIGINAL COPY***
                </Text>
              </p>
            </div>
            <H1 style={{ textAlign: 'center' }}>Transaction Receipt</H1>

            {PosRequestReceipt(receiptResponse)}
          </div>
        </div>

        <Row
          style={{ display: 'flex', alignItems: 'center', marginTop: '20pt' }}
        >
          <Col
            xs={12}
            md={4}
            style={{ marginBottom: `${deviceIsMobile && '5px'}` }}
          >
            <Button
              onClick={printPage}
              style={{
                width: '100%',
                marginBottom: '10px',
              }}
            >
              Print
            </Button>
          </Col>

          <Col xs={12} md={4}>
            <Button
              outline
              style={{
                width: '100%',
                padding: '9pt 37pt',
                marginBottom: '10px',
              }}
              onClick={onCancelProduct}
            >
              Dismiss
            </Button>
          </Col>
        </Row>
      </div>
    </article>
  );
};

export default ReceiptScreen;
