import React from "react";
import ReactTable from "react-table";
import {
  formatAmount,
  formatDate,
  settlementFormatter,
  handleTransactionTypeFormat,
} from "../../../../../utils/formatters";
import { convertNgkToNgn } from "../../../../../utils/converters/currencies";
import Spinner from "../../../../../components/spinner";
import ActivityIndicator from "../../../../../components/activity-indicator";

const transactionHistoryColumns = [
  {
    Header: (props) => <React.Fragment>Transaction Reference</React.Fragment>,
    accessor: "transactionRef",
    Cell: (props) => <div style={{ textAlign: "left" }}>{props.value}</div>,
    headerClassName: "table-header",
    className: "table-cell",
    width: 300,
    style: { "white-space": "unset" },
  },
  {
    Header: (props) => <React.Fragment>Transaction Type</React.Fragment>,
    accessor: "transactionType",
    Cell: (props) => (
      <div style={{ textAlign: "left" }}>
        {handleTransactionTypeFormat(props.value)}
      </div>
    ),
    headerClassName: "table-header",
    className: "table-cell",
    style: { "white-space": "unset" },
  },
  {
    Header: (props) => <React.Fragment>Transaction Amount</React.Fragment>,
    accessor: "amount",
    Cell: (props) => (
      <div style={{ textAlign: "left" }} className="number">{`₦${formatAmount(
        convertNgkToNgn(props.value)
      )}`}</div>
    ),
    headerClassName: "table-header",
    className: "table-cell",
    style: { "white-space": "unset" },
  },
  {
    Header: (props) => <React.Fragment>Commission Earned</React.Fragment>,
    accessor: "fee",
    Cell: (props) => (
      <div style={{ textAlign: "left" }} className="number">{`₦${formatAmount(
        convertNgkToNgn(props.value)
      )}`}</div>
    ),
    headerClassName: "table-header",
    className: "table-cell",
    style: { "white-space": "unset" },
  },
  {
    Header: (props) => <React.Fragment>Status</React.Fragment>,
    accessor: "status",
    Cell: (props) => (
      <div style={{ textAlign: "left" }} className={`${props.value}-status`}>
        {props.value}
      </div>
    ),
    headerClassName: "table-header",
    className: "table-cell",
    style: { "white-space": "unset" },
  },
  {
    Header: (props) => (
      <React.Fragment>Commission Balance After</React.Fragment>
    ),
    accessor: "availableBalance",
    Cell: (props) => (
      <div style={{ textAlign: "left" }} className="number">
        {props.original.status !== "Failed" &&
          `₦${formatAmount(convertNgkToNgn(props.value))}`}
      </div>
    ),
    headerClassName: "table-header",
    className: "table-cell",
    style: { "white-space": "unset" },
  },
  {
    Header: (props) => <React.Fragment>Event</React.Fragment>,
    accessor: "event",
    Cell: (props) => (
      <div style={{ textAlign: "left" }} className={`${props.value}-status`}>
        {props.value}
      </div>
    ),
    headerClassName: "table-header",
    className: "table-cell",
    style: { "white-space": "unset" },
  },
  {
    Header: (props) => <React.Fragment>Date</React.Fragment>,
    accessor: "dateCreated",
    Cell: (props) => (
      <div style={{ textAlign: "left" }} className="number">
        {formatDate(props.value)}
      </div>
    ),
    headerClassName: "table-header",
    className: "table-cell",
    style: { "white-space": "unset" },
  },
];

export default (props) => {
  return (
    <ReactTable
      columns={transactionHistoryColumns}
      data={props.data}
      pages={props.pages}
      defaultPageSize={50}
      manual
      onFetchData={(state, instance) =>
        props.getCommissionEarnedHistory(
          state.page + 1,
          state.pageSize,
          props.startDate,
          props.endDate,
          props.transactionType,
          props.transactionEvent,
          props.domainCode
        )
      }
      minRows={props.data.length > 0 ? 3 : 2}
      loading={props.loading}
      LoadingComponent={Spinner}
    />
  );
};
