import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Col, Form } from 'react-bootstrap';
import Select from 'react-select';
import Platform from '../../../../services/api/resources/platform';
import IosRefresh from 'react-ionicons/lib/IosRefresh';
import BackButton from '../../onboarding-setup/component/backButton';
import PrimaryButton from '../../onboarding-setup/component/button';
import { isBusinessLgaValid, isEmailAddressValid } from '../../onboarding-setup/component/extras';
import InputText2 from '../../onboarding-setup/component/input2';
import { firetoast, firetoastAggregator, notify } from '../../../../components/extras';
import FormLabel from '../../../../components/form-label';
import H1 from '../../../../components/h1';
import { ERROR_STATUS, SUCCESS_STATUS } from '../../../../constants/api';
import Transaction from '../../../../services/api/resources/transaction';
import { RegexChecker } from '../../../../utils/regex-checker';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Title from '../upgradeAgentClass/title';


const RefereeInfoScene = ({ setScreen, application, props }) => {
    const platform = new Platform();
    const history = useHistory();
    const location = useLocation();

    const upgradeTo = localStorage.getItem('upgradeTo');
    const currentAgent = JSON.parse(localStorage.getItem('currentAgent'));
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    const updatedRefereeDetailsForm = JSON.parse(
        localStorage.getItem('refereeDetailsForm')
    );

    const [refereeDetailsForm, setRefereeDetailsForm] = useState({
        emailAddress: application ? application?.emailAddress || updatedRefereeDetailsForm?.emailAddress : '',
    });


    useEffect(() => {
        localStorage.setItem('refereeDetailsForm', JSON.stringify(refereeDetailsForm));
    }, [refereeDetailsForm]);

    const updateFormField = (params) => {
        if (params.state) {
            const newForm = {
                ...refereeDetailsForm,

                ...params,
            };
            setRefereeDetailsForm(newForm);
        } else {
            const newForm = {
                ...refereeDetailsForm,

                ...params,
            };
            setRefereeDetailsForm(newForm);
        }
    };

    const stripSpacesFromString = (str) => {
        return str.replace(/ /g, "");
    }

    const isFormValid = () => {
        return (
            refereeDetailsForm.emailAddress &&
            isEmailAddressValid(refereeDetailsForm)
        );
    };


    return (
        <>
            <main className='aggregator-container'>
                <Col
                    style={{
                        marginBottom: '20px',
                        margin: '0 auto',
                    }}
                >
                    <Title upgradeTo={upgradeTo} />
                    <H1
                        style={{
                            textAlign: 'left',
                            fontWeight: 600,
                            marginBottom: '1rem',
                            marginBottom: '2em',
                        }}
                    >
                        Referee's Information
                    </H1>
                    <InputText2
                        onChange={(e) => {
                            updateFormField({ emailAddress: stripSpacesFromString(e.target.value.trim()) });
                        }}
                        labelColor={
                            !refereeDetailsForm.emailAddress || isEmailAddressValid(refereeDetailsForm)
                                ? '#3A3B3B'
                                : process.env.REACT_APP_RED_COLOUR
                        }
                        border={`1px solid ${!refereeDetailsForm.emailAddress || isEmailAddressValid(refereeDetailsForm)
                            ? '#DFE2E6'
                            : process.env.REACT_APP_RED_COLOUR
                            }`}
                        type={'email'}
                        value={refereeDetailsForm.emailAddress}
                        label={'Referee Email Address'}
                        placeholder={'Enter email adress'}
                    />


                    <PrimaryButton
                        disabled={!isFormValid() || isLoading}
                        onClick={() => setScreen(6)}
                    >
                        {isLoading ? 'Processing...' : 'Next'}
                    </PrimaryButton>
                </Col>
            </main>
        </>
    );
};

export default RefereeInfoScene;
