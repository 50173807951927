import React, { useEffect, useState } from "react";

import ReactTable from "react-table";
import Spinner from "../../../../../../components/spinner";
import { convertNgkToNgn } from "../../../../../../utils/converters/currencies";
import {
  formatAmount,
  specialDateRangeFormatter_StartDate,
} from "../../../../../../utils/formatters";
import { Dropdown, Row } from "react-bootstrap";
import MdMore from "react-ionicons/lib/MdMore";
import MdEye from "react-ionicons/lib/MdEye";
import { useHistory } from "react-router-dom";

import TransactionHistory from "../../../../../../services/api/resources/transaction-history";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../../../constants/api";
import { getPaginationRows } from "../../../../../../utils/helpers";
import ActivityIndicator from "../../../../../../components/activity-indicator";
import { StatusComponent } from "../../../../../../components/status";
const WithdrawalGridSceen = ({ startDate, endDate, dateIntervalType }) => {
  const history = useHistory();
  const transactionHistory = new TransactionHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState(1);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [dataCount, setDataCount] = useState();

  const [error, setError] = useState(false);
  const [getWithdrawalReportList, setGetWithdrawalReportList] = useState([]);
  const handleOpenView = (props) => {
    history.push({
      pathname: `/agent/commissions/withdrawal-details/${props.original.paymentReference}`,
      state: props.original,
    });
  };

  const getWithdrawalReport = async (pageSize_, pageNum_) => {
    setGetWithdrawalReportList([]);
    setIsLoading(true);
    setError(false);
    // const payload = {
    //   pageSize: pageSize_,
    //   pageNum: pageNum_,
    //   from: specialDateRangeFormatter_StartDate(startDate),
    //   to: specialDateRangeFormatter_StartDate(endDate),
    //   dateIntervalType: dateIntervalType,
    // };
    let payload = {};
    if (dateIntervalType) {
      payload = {
        pageSize: pageSize_,
        pageNum: pageNum_,
        dateIntervalType: dateIntervalType,
      };
    } else {
      payload = {
        pageSize: pageSize_,
        pageNum: pageNum_,
        from: specialDateRangeFormatter_StartDate(startDate),
        to: specialDateRangeFormatter_StartDate(endDate),
      };
    }
    const { response, status } = await transactionHistory.getWithdrawalReport(
      payload
    );

    setIsLoading(false);
    if (status === ERROR_STATUS) {
      setError(true);
      // notify(response.description, 'error');
    } else if (status === SUCCESS_STATUS) {
      setDataCount(response.count / pageSize);
      setPages(Math.ceil(response.count / pageSize));
      setGetWithdrawalReportList(response.data.content);
    }
  };

  useEffect(() => {
    getWithdrawalReport(pageSize, pageNum);
  }, [endDate, dateIntervalType]);

  const Columns = [
    {
      Header: (props) => <div style={{ textAlign: "left" }}>Withdrawal ID</div>,
      Cell: (props) => (
        <div
          className="wordbreak"
          style={{ textAlign: "left", wordBreak: "break-all" }}
        >
          {props.value}
        </div>
      ),
      accessor: "paymentReference",
      headerClassName: "table-header",
      className: "table-cell",
      style: { "white-space": "unset" },
      //   width: 200,
    },

    {
      Header: (props) => <div style={{ textAlign: "left" }}>Narration</div>,
      accessor: "narration",
      Cell: (props) => <div style={{ textAlign: "left" }}>{props.value}</div>,
      headerClassName: "table-header",
      className: "table-cell",
      //   width: 300,

      style: { "white-space": "unset" },
    },
    {
      Header: (props) => <div style={{ textAlign: "left" }}>Amount</div>,
      accessor: "debitAmount",
      Cell: (props) => (
        <div className="number" style={{ textAlign: "left" }}>{`₦${formatAmount(
          convertNgkToNgn(props.value)
        )}`}</div>
      ),
      headerClassName: "table-header",
      className: "table-cell",
      //   width: 200,
      style: { "white-space": "unset" },
    },
    {
      Header: (props) => <div style={{ textAlign: "left" }}>Status</div>,
      accessor: "status",
      Cell: (props) => {
        return <StatusComponent value={props.value.toLowerCase()} />;
      },
      headerClassName: "table-header",
      className: "table-cell",
      //   width: 300,

      style: { "white-space": "unset" },
    },
    {
      Header: (props) => (
        <div style={{ textAlign: "left" }}>Withdrawal Date</div>
      ),
      accessor: "journalEntryDate",
      Cell: (props) => <div style={{ textAlign: "left" }}>{props.value}</div>,
      headerClassName: "table-header",
      className: "table-cell",
      //   width: 300,

      style: { "white-space": "unset" },
    },
    {
      Header: (props) => <div style={{ textAlign: "left" }}>Action</div>,

      Cell: (props, original) => (
        <Dropdown>
          <Dropdown.Toggle
            style={{
              cursor: "pointer",
            }}
            as={"span"}
            variant="secondary"
            id="dropdown-variants-secondary"
          >
            <MdMore color={process.env.REACT_APP_GREY_COLOUR} />
          </Dropdown.Toggle>

          <Dropdown.Menu variant="secondary" id="dropdown-variants-secondary">
            <Dropdown.Item onClick={() => handleOpenView(props)}>
              <Row
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <MdEye
                  fontSize="15px"
                  color={process.env.REACT_APP_GREY_COLOUR}
                />

                <div>View</div>
              </Row>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
      headerClassName: "table-header",
      className: "table-cell",
      //   width: 300,

      style: { "white-space": "unset" },
    },
  ];

  return (
    <div className="mt-4">
      <ReactTable
        columns={Columns}
        data={getWithdrawalReportList}
        dataCount={dataCount}
        pageSizes={getPaginationRows(dataCount)}
        pages={pages}
        defaultPageSize={10}
        onFetchData={(state, instance) => {
          getWithdrawalReport(state.pageSize, state.page + 1);
        }}
        noDataText={isLoading ? "" : "No records found"}
        loading={isLoading}
        LoadingComponent={Spinner}
      />
    </div>
  );
};

export default WithdrawalGridSceen;
