import React from "react";
import { Col, Row } from "react-bootstrap";
import Button from "../../../../../components/button";
import HR from "../../../../../components/hr";
import Text from "../../../../../components/text";
import { BILLER_BORNE } from "../../../../../constants/transactions";
import { convertNgkToNgn } from "../../../../../utils/converters/currencies";
import { formatAmount } from "../../../../../utils/formatters";

export const CashInConfirmationTab = (props) => {
  //const extras = TransactionExtras;
  return (
    <>
      <HR style={{ marginTop: "5px", width: "100%" }} />

      <Row style={{ marginTop: "15px" }}>
        <Col
          md={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {props.form.billerOption ? props.form.billerOption.serviceName : ""}
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {props.form.billerOption ? props.form.billerOption.name : ""}
          </Text>
        </Col>
      </Row>

      <Row
        hidden={props.form.billerOption === null}
        style={{ marginTop: "15px" }}
      >
        <Col
          md={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {props.form.billerOption
              ? props.form.billerOption.customerIdField
              : ""}
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {props.payment ? props.payment.billsPaymentRequest.customerId : ""}
          </Text>
        </Col>
      </Row>

      <Row style={{ marginTop: "15px" }}>
        <Col
          md={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            Biller Name
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {props.payment ? props.service.name : ""}
          </Text>
        </Col>
      </Row>

      <Row style={{ marginTop: "15px" }}>
        <Col
          md={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            Phone Number
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {props.payment ? props.form.phone : ""}
          </Text>
        </Col>
      </Row>

      <Row style={{ marginTop: "15px" }}>
        <Col
          md={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            Gender
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {props.payment ? props.form.gender : ""}
          </Text>
        </Col>
      </Row>

      <Row
        hidden={
          props.form.billerOption === null ||
          props.form.billerOption.customerIdField === "Customer Name"
        }
        style={{ marginTop: "15px" }}
      >
        <Col
          md={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            Customer Name
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {props.payment
              ? props.payment.billsPaymentRequest.customerName
              : ""}
          </Text>
        </Col>
      </Row>

      <Row style={{ marginTop: "15px" }}>
        <Col
          md={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
              fontWeight: "bold",
            }}
          >
            Amount
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
              fontWeight: "bold",
            }}
          >{`NGN${formatAmount(props.form.amount)}`}</Text>
        </Col>
      </Row>

      {props.payment &&
        props.payment.billsPaymentRequest.borneBy !== BILLER_BORNE && (
          <Row style={{ marginTop: "15px" }}>
            <Col
              md={12}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Text
                style={{
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  fontWeight: "bold",
                }}
              >
                Fee
              </Text>
              <Text
                style={{
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  fontWeight: "bold",
                }}
              >
                {props.payment
                  ? `NGN${formatAmount(
                      convertNgkToNgn(props.payment.billsPaymentRequest.fee)
                    )}`
                  : ""}
              </Text>
            </Col>
          </Row>
        )}

      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "30pt",
        }}
      >
        <Col
          xs={12}
          md={5}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            disabled={props.isLoading}
            style={{ width: "100%" }}
            onClick={props.onCashInConfirmation}
          >
            {props.isLoading ? "Processing..." : "Pay"}
          </Button>
        </Col>
        <Col xs={12} md={5}>
          <Button
            outline
            style={{
              backgroundColor: "transparent",
              marginTop: props.deviceIsMobile ? "10pt" : null,
              width: "100%",
              padding: "9pt 37pt",
            }}
            onClick={props.onCancelProduct}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </>
  );
};
