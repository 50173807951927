import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import MdArrowBack from 'react-ionicons/lib/MdArrowBack';
import ReactPhoneInput from 'react-phone-input-2';
import phoneValidators from '../../../../../../../utils/validators/phone-validators';
import BaseScene from '../../../../../../base-scene';
import Button from '../../../../../../../components/button';
import { ERROR_STATUS } from '../../../../../../../constants/api';
import FormControl from '../../../../../../../components/form-control';
import H1 from '../../../../../../../components/h1';
import Platform from '../../../../../../../services/api/resources/platform';
import UserManagement from '../../../../../../../services/api/resources/user-management';
import { showErrorMessage, showSuccessMessage, dismissErrorMessage, dismissSuccessMessage, updateLoading } from '../../../../../../../services/redux/actions/tunnel';
import { NIGERIA_PHONE_PREFIX } from '../../../../../../../constants';


class CreateUserScene extends BaseScene {
  platform = new Platform();
  userManagement = new UserManagement();

  constructor(props) {
    super(props)

    this.state = {
      faultyFields: [],
      fetchingRoles: true,
      fieldsFocused: [],
      form: {
        phonePrefix: NIGERIA_PHONE_PREFIX,
        name: null,
        email: null,
        permissions: []
      },
      permissions: [],
      roles: [],
    };

    this.createUser = this.createUser.bind(this);
    this.fetchAllRoles = this.fetchAllRoles.bind(this);
    this.onBackButtonClick = this.onBackButtonClick.bind(this);
    this.onRemovePermission = this.onRemovePermission.bind(this);
    this.onSelectPermission = this.onSelectPermission.bind(this);
    this.updateFormField = this.updateFormField.bind(this);
  }

  componentDidMount() {
    this.fetchAllRoles();
  }

  serializeForm(form) {
    return {
      ...form,
      mobileNo: `+${form.phonePrefix}${form.phone}`,
      roleName: form.role,
    }
  }

  async createUser() {
    this.props.updateLoading(true, 66);

    const createUserResponse = await this.platform.createUser(this.serializeForm(this.state.form));
    const createUserResponseStatus = createUserResponse.status;
    const createUserResponseObj = createUserResponse.response;

    this.props.updateLoading(false);

    if (createUserResponseStatus === ERROR_STATUS) {
      this.props.showErrorMessage(JSON.stringify(createUserResponseObj));
      setTimeout(() => this.props.dismissErrorMessage(), parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
      return {};
    }

    this.props.showSuccessMessage("User created successfully.")
    setTimeout(() => this.props.dismissSuccessMessage(), parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT))

    return createUserResponseObj
  }

  async fetchAllRoles() {
    this.props.updateLoading(true, 66);

    const getPermissionsResponse = await this.platform.retrieveRoles();

    this.props.updateLoading(false);

    const getPermissionsResponseStatus = getPermissionsResponse.status;
    const getPermissionsResponseObj = getPermissionsResponse.response;

    if (getPermissionsResponseStatus === ERROR_STATUS) {
      this.props.showErrorMessage(JSON.stringify(getPermissionsResponseObj))
      return
    }

    this.setState({
      roles: getPermissionsResponseObj,
      fetchingRoles: false
    })

  }

  async fetchPermissions(pageNum, pageSize) {
    // this.props.updateLoading(true, 66);

    const getPermissionsResponse = await this.userManagement.getPermissions(pageNum, pageSize);
    const getPermissionsResponseStatus = getPermissionsResponse.status;
    const getPermissionsResponseObj = getPermissionsResponse.response;

    // this.props.updateLoading(false);

    if (getPermissionsResponseStatus === ERROR_STATUS) {
      return {}
    }

    return getPermissionsResponseObj
  }

  isDescriptionValid (newForm) {
    const form = newForm || this.state.form
    return form.email && form.email.length >= 6;
  }

  isEmailValid (newForm) {
    const form = newForm || this.state.form
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(form.email).toLowerCase());
  }

  isFirstNameValid (newForm) {
    const form = newForm || this.state.form
    return form.firstName && form.firstName.length >= 3;
  }

  isLastNameValid (newForm) {
    const form = newForm || this.state.form
    return form.lastName && form.lastName.length >= 3;
  }

  isPhoneValid (newForm) {
    const form = newForm || this.state.form
    const countryValidator = phoneValidators[this.state.form.phonePrefix]

    return !this.state.faultyFields.includes('phoneNumber') && form.phone && form.phone.length > 0 && countryValidator ? countryValidator(form.phone) : false
  }

  isRoleValid (newForm) {
    const form = newForm || this.state.form
    return form.role && form.role.length >= 1;
  }

  onBackButtonClick() {
    return this.props.history.goBack();
  }

  onRemovePermission(optionList, removedPermission) {
    const { permissions } = this.state.form;

    this.updateFormField({
      permissions: permissions.filter(value => value !== removedPermission),
    });
  }

  onSelectPermission(optionList, selectedPermission) {
    const { permissions } = this.state.form;
    permissions.push(selectedPermission);

    this.updateFormField(permissions);
  }

  updateFormField (params) {
    const newFormData = {
      ...this.state.form,
      ...params
    }

    const allParamsAreValid = this.isFirstNameValid(newFormData) && this.isLastNameValid(newFormData) && this.isPhoneValid(newFormData) && this.isRoleValid(newFormData)

    this.setState({
      form: newFormData,
      formIsValid: allParamsAreValid
    })
  }

  result(params) {
  }

  render() {
    if (this.state.fetchingRoles) {
      return <React.Fragment />
    }

    return <section className={`form-container${this.state.deviceIsMobile ? '-mobile' : ''}`}>
      <H1 hidden={this.state.deviceIsMobile} style={{display: 'flex', alignItems: 'center', textAlign: 'left'}}>
        <MdArrowBack className="grow" color={process.env.REACT_APP_BLUE_COLOUR} fontSize="26px" onClick={this.onBackButtonClick} style={{marginRight: '20pt'}} />
        Create User
      </H1>
      <form style={{marginTop: !this.state.deviceIsMobile ? '10pt' : '0pt'}}>
        <Row style={{margin: 0, padding: 0}}>
          <Col xs={12} md={4}>
            <Form.Group controlId="create-user-first-name">
              <Form.Label style={{color: `${!this.state.form.firstName && !this.state.fieldsFocused.includes('firstName') && !this.state.propagateFormErrors || this.isFirstNameValid() ? '#3A3B3B' : process.env.REACT_APP_RED_COLOUR}`, fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>First Name: <span className="required-marker">*</span></Form.Label>
              <FormControl 
                value={this.state.form.firstName} 
                placeholder="First Name" 
                onBlur={() => this.setState({
                  fieldsFocused: [...this.state.fieldsFocused, 'firstName']
                })}
                onChange={(event) => {
                  const firstName = event.target.value
                  this.updateFormField({firstName})
                }} style={{backgroundColor: '#FFFFFF', borderRadius: '20px', border: `1px solid ${!this.state.form.firstName || this.isFirstNameValid() ? '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`, boxSizing: 'border-box', fontSize: '12px', lineHeight: process.env.REACT_APP_MID_LINE_HEIGHT}} />
            </Form.Group>
          </Col>

          <Col xs={12} md={4}>
            <Form.Group controlId="create-user-last-name">
              <Form.Label style={{color: `${!this.state.form.lastName && !this.state.fieldsFocused.includes('lastName') && !this.state.propagateFormErrors || this.isLastNameValid() ? '#3A3B3B' : process.env.REACT_APP_RED_COLOUR}`, fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Last Name: <span className="required-marker">*</span></Form.Label>
              <FormControl 
                value={this.state.form.lastName} 
                placeholder="Last Name" 
                onBlur={() => this.setState({
                  fieldsFocused: [...this.state.fieldsFocused, 'lastName']
                })}
                onChange={(event) => {
                  const lastName = event.target.value
                  this.updateFormField({lastName})
                }} 
                style={{backgroundColor: '#FFFFFF', borderRadius: '20px', border: `1px solid ${!this.state.form.lastName || this.isLastNameValid() ? '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`, boxSizing: 'border-box', fontSize: '12px', lineHeight: process.env.REACT_APP_MID_LINE_HEIGHT}} />
            </Form.Group>
          </Col>

          <Col xs={12} md={4}>
            <Form.Group controlId="create-user-email">
              <Form.Label style={{color: `${!this.state.form.email && !this.state.fieldsFocused.includes('email') && !this.state.propagateFormErrors || this.isEmailValid() ? '#3A3B3B' : process.env.REACT_APP_RED_COLOUR}`, fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Email:</Form.Label>
              <FormControl 
                value={this.state.form.email} 
                placeholder="Email (ex. john@example.com)" 
                onBlur={() => this.setState({
                  fieldsFocused: [...this.state.fieldsFocused, 'email']
                })}
                onChange={(event) => {
                  const email = event.target.value
                  this.updateFormField({email})
                }} style={{backgroundColor: '#FFFFFF', borderRadius: '20px', border: `1px solid ${!this.state.form.email || this.isEmailValid() ? '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`, boxSizing: 'border-box', fontSize: '12px', lineHeight: process.env.REACT_APP_MID_LINE_HEIGHT}} />
            </Form.Group>
          </Col>

          <Col xs={12} md={4}>
            <Form.Group controlId="create-user-phone">
              <Form.Label style={{color: `${!this.state.form.phone && !this.state.fieldsFocused.includes('phone') && !this.state.propagateFormErrors || this.isPhoneValid() ? '#3A3B3B' : process.env.REACT_APP_RED_COLOUR}`, fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Phone: <span className="required-marker">*</span></Form.Label>
              <ReactPhoneInput
                buttonStyle={{borderTopLeftRadius: process.env.REACT_APP_BORDER_STYLE === 'square' ? '4px' : '20px', borderBottomLeftRadius: process.env.REACT_APP_BORDER_STYLE === 'square' ? '4px' : '20px'}}
                containerStyle={{margin: 0, padding: 0, width: '100%'}}
                defaultCountry={'ng'}
                disableCountryCode={true}
                inputStyle={{backgroundColor: '#FFFFFF', borderRadius: process.env.REACT_APP_BORDER_STYLE === 'square' ? '4px' : '20px', border: `1px solid ${!this.state.form.phone || this.isPhoneValid() ? '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`, boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px', width: '100%'}}
                value={this.state.form.phone || ''} 
                onlyCountries={['ng', 'ke', 'ug']}
                onBlur={() => this.setState({
                  fieldsFocused: [...this.state.fieldsFocused, 'phone']
                })}
                onChange={(value, data) => {
                  // this.onPhoneUpdate();
                  this.updateFormField({phone: value})
                }}
                placeholder="08012345678" />  
            </Form.Group>
          </Col>
        </Row>
        
        <Row style={{margin: 0, padding: 0}}>
          <Col xs={12} md={4}>
            <Form.Group controlId="create-user-role">
              <Form.Label style={{color: `${!this.state.form.role && !this.state.fieldsFocused.includes('role') && !this.state.propagateFormErrors || this.isRoleValid() ? '#3A3B3B' : process.env.REACT_APP_RED_COLOUR}`, fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Role: <span className="required-marker">*</span></Form.Label>
              <FormControl 
                as="select" 
                placeholder="Role" 
                onBlur={() => this.setState({
                  fieldsFocused: [...this.state.fieldsFocused, 'role']
                })}
                onChange={(event) => {
                  const role = event.target.value
                  this.updateFormField({role})
                }} 
                style={{backgroundColor: '#FFFFFF', borderRadius: '20px', border: `1px solid ${!this.state.firstName || this.isFirstNameValid() ? '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`, boxSizing: 'border-box', fontSize: '12px', lineHeight: process.env.REACT_APP_MID_LINE_HEIGHT}}>
                <option>Select a Role</option>
                {this.state.roles.map(value => <option>{value.name}</option>)}
              </FormControl>
            </Form.Group>
          </Col>
        </Row>
        
        <Row style={{margin: 0, padding: 0}}>
          <Col xs={12} md={4}>
            <Button disabled={this.props.isLoading || !this.state.formIsValid} style={{marginTop: '10pt', width: '100%'}} onClick={this.createUser}>{this.props.isLoading ? 'Loading...' : 'SAVE'}</Button>
          </Col>
        </Row>
      </form>
    </section>
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.tunnel.isLoading,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dismissErrorMessage: () => dispatch(dismissErrorMessage()),
    dismissSuccessMessage: () => dispatch(dismissSuccessMessage()),
    showErrorMessage: message => dispatch(showErrorMessage(message)),
    showSuccessMessage: message => dispatch(showSuccessMessage(message)),
    updateLoading: (isLoading, percentage=null) => dispatch(updateLoading({isLoading, percentage}))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserScene);
