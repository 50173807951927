import React from "react";
import "../styles.scss";

export default (props) => {
  if (props.mobile) {
    return (
      <div
        className="dashboard-page-header"
        style={{
          backgroundImage: "linear-gradient(120deg, #002636, #002636)",
          position: "absolute",
          right: "0px",
          zIndex: "0",
          width: "100vw",
          ...props.style,
        }}
      >
        <svg
          width="133px"
          height="133px"
          viewBox="0 0 133 133"
          version="1.1"
          style={{ position: "absolute", left: "-8%", top: "10%" }}
          xlink="http://www.w3.org/1999/xlink"
          xmlns="http://www.w3.org/2000/svg"
        >
          <desc>Created with Lunacy</desc>
          <path
            d="M56.5 113C87.7041 113 113 87.7041 113 56.5C113 25.2959 87.7041 0 56.5 0C25.2959 0 0 25.2959 0 56.5C0 87.7041 25.2959 113 56.5 113Z"
            transform="translate(10 10)"
            id="Oval-Copy"
            fill="none"
            stroke="#F3F5F9"
            strokeOpacity="0.08"
            strokeWidth="20"
          />
        </svg>
        <svg
          width="161px"
          height="161px"
          viewBox="0 0 161 161"
          version="1.1"
          style={{ position: "absolute", right: "-10%", top: "35%" }}
          xlink="http://www.w3.org/1999/xlink"
          xmlns="http://www.w3.org/2000/svg"
        >
          <desc>Created with Lunacy</desc>
          <path
            d="M62.5 125C97.0178 125 125 97.0178 125 62.5C125 27.9822 97.0178 0 62.5 0C27.9822 0 0 27.9822 0 62.5C0 97.0178 27.9822 125 62.5 125Z"
            transform="translate(18 18)"
            id="Oval-Copy-5"
            fill="none"
            stroke="#F3F5F9"
            strokeOpacity="0.08"
            strokeWidth="36"
          />
        </svg>
      </div>
    );
  }

  return (
    <div
      className="dashboard-page-header"
      style={{
        backgroundImage: "linear-gradient(120deg, #002636, #003646)",
        borderBottomLeftRadius: props.deviceIsMobile ? "50px" : "75px",
        position: "absolute",
        right: "0px",
        zIndex: "0",
        width: "83vw",
        ...props.style,
      }}
    >
      <svg
        width="142px"
        height="142px"
        style={{ position: "absolute", left: "20%", top: "-12%" }}
        viewBox="0 0 142 142"
        version="1.1"
        xlink="http://www.w3.org/1999/xlink"
        xmlns="http://www.w3.org/2000/svg"
      >
        <desc>Created with Lunacy</desc>
        <path
          d="M53 106C82.2711 106 106 82.2711 106 53C106 23.7289 82.2711 0 53 0C23.7289 0 0 23.7289 0 53C0 82.2711 23.7289 106 53 106Z"
          transform="translate(18 18)"
          id="Oval-Copy-6"
          fill="none"
          stroke="#F3F5F9"
          strokeOpacity="0.02"
          strokeWidth="36"
        />
      </svg>
      <svg
        width="280px"
        height="280px"
        viewBox="0 0 280 280"
        version="1.1"
        style={{ position: "absolute", left: "-8%", top: "40%" }}
        xlink="http://www.w3.org/1999/xlink"
        xmlns="http://www.w3.org/2000/svg"
      >
        <desc>Created with Lunacy</desc>
        <path
          d="M90 180C139.706 180 180 139.706 180 90C180 40.2944 139.706 0 90 0C40.2944 0 0 40.2944 0 90C0 139.706 40.2944 180 90 180Z"
          transform="translate(50 50)"
          id="Oval-Copy-2"
          fill="none"
          stroke="#F3F5F9"
          strokeOpacity="0.02"
          strokeWidth="100"
        />
      </svg>
      <svg
        width="133px"
        height="133px"
        viewBox="0 0 133 133"
        version="1.1"
        style={{ position: "absolute", left: "-8%", top: "10%" }}
        xlink="http://www.w3.org/1999/xlink"
        xmlns="http://www.w3.org/2000/svg"
      >
        <desc>Created with Lunacy</desc>
        <path
          d="M56.5 113C87.7041 113 113 87.7041 113 56.5C113 25.2959 87.7041 0 56.5 0C25.2959 0 0 25.2959 0 56.5C0 87.7041 25.2959 113 56.5 113Z"
          transform="translate(10 10)"
          id="Oval-Copy"
          fill="none"
          stroke="#F3F5F9"
          strokeOpacity="0.02"
          strokeWidth="20"
        />
      </svg>
      <svg
        width="425px"
        height="425px"
        viewBox="0 0 425 425"
        version="1.1"
        style={{ position: "absolute", right: "-10%", top: "-100%" }}
        xlink="http://www.w3.org/1999/xlink"
        xmlns="http://www.w3.org/2000/svg"
      >
        <desc>Created with Lunacy</desc>
        <path
          d="M162.5 325C252.246 325 325 252.246 325 162.5C325 72.7537 252.246 0 162.5 0C72.7537 0 0 72.7537 0 162.5C0 252.246 72.7537 325 162.5 325Z"
          transform="translate(50 50)"
          id="Oval-Copy-3"
          fill="none"
          stroke="#F3F5F9"
          strokeOpacity="0.02"
          strokeWidth="100"
        />
      </svg>
      <svg
        width="225px"
        height="225px"
        viewBox="0 0 425 425"
        version="1.1"
        style={{ position: "absolute", right: "50%", top: "0%" }}
        xlink="http://www.w3.org/1999/xlink"
        xmlns="http://www.w3.org/2000/svg"
      >
        <desc>Created with Lunacy</desc>
        <path
          d="M162.5 325C252.246 325 325 252.246 325 162.5C325 72.7537 252.246 0 162.5 0C72.7537 0 0 72.7537 0 162.5C0 252.246 72.7537 325 162.5 325Z"
          transform="translate(50 50)"
          id="Oval-Copy-3"
          fill="none"
          stroke="#F3F5F9"
          strokeOpacity="0.02"
          strokeWidth="100"
        />
      </svg>
      <svg
        width="161px"
        height="161px"
        viewBox="0 0 161 161"
        version="1.1"
        style={{ position: "absolute", right: "-10%", top: "35%" }}
        xlink="http://www.w3.org/1999/xlink"
        xmlns="http://www.w3.org/2000/svg"
      >
        <desc>Created with Lunacy</desc>
        <path
          d="M62.5 125C97.0178 125 125 97.0178 125 62.5C125 27.9822 97.0178 0 62.5 0C27.9822 0 0 27.9822 0 62.5C0 97.0178 27.9822 125 62.5 125Z"
          transform="translate(18 18)"
          id="Oval-Copy-5"
          fill="none"
          stroke="#F3F5F9"
          strokeOpacity="0.02"
          strokeWidth="36"
        />
      </svg>
      <svg
        width="161px"
        height="161px"
        viewBox="0 0 161 161"
        version="1.1"
        style={{ position: "absolute", left: "60%", top: "82%" }}
        xlink="http://www.w3.org/1999/xlink"
        xmlns="http://www.w3.org/2000/svg"
      >
        <desc>Created with Lunacy</desc>
        <path
          d="M62.5 125C97.0178 125 125 97.0178 125 62.5C125 27.9822 97.0178 0 62.5 0C27.9822 0 0 27.9822 0 62.5C0 97.0178 27.9822 125 62.5 125Z"
          transform="translate(18 18)"
          id="Oval-Copy-4"
          fill="none"
          stroke="#F3F5F9"
          strokeOpacity="0.02"
          strokeWidth="36"
        />
      </svg>
    </div>
  );
};
