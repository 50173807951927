import React from "react";

export const PayCodeConfirmationTab = (props) => {
  //const extras = TransactionExtras;
  return (
    <>
      {/* <VerticallyCenteredModal 
          onHide={handleClose}
          show={show}
          title={<><p>Transaction Details</p><span>Please confirm the cash out details below</span></>}
          body={<CashoutModalContent/>}
          payment
          hideFooter
          deviceIsMobile={this.props.deviceIsMobile}
        /> */}
    </>
  );
};
