import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Multiselect } from 'multiselect-react-dropdown';
import BaseScene from '../../../../../../base-scene';
import Button from '../../../../../../../components/button';
import { ERROR_STATUS } from '../../../../../../../constants/api';
import FormControl from '../../../../../../../components/form-control';
import H1 from '../../../../../../../components/h1';
import Platform from '../../../../../../../services/api/resources/platform';
import UserManagement from '../../../../../../../services/api/resources/user-management';
import { showErrorMessage, showSuccessMessage, dismissErrorMessage, dismissSuccessMessage, updateLoading } from '../../../../../../../services/redux/actions/tunnel';

// const data = [
//   {
//     name: 'one',
//     value: 'one'
//   },
//   {
//     name: 'two',
//     value: 'two'
//   },
//   {
//     name: 'three',
//     value: 'three'
//   },
//   {
//     name: 'four',
//     value: 'four'
//   },
//   {
//     name: 'five',
//     value: 'five'
//   },
//   {
//     name: 'six',
//     value: 'six'
//   }
// ];

// class EditRoleForm extends React.Component {
//   render() {
//     return <React.Fragment />
//   }
// }

class ViewAndEditRoleScene extends BaseScene {
  platform = new Platform();
  userManagement = new UserManagement();

  constructor(props) {
    super(props)

    this.state = {
      form: {
        name: null,
        description: null,
        permissions: []
      },
      permissions: [],
      fetchingPermissions: true,
    };

    this.editRole = this.editRole.bind(this);
    this.fetchAllPermissions = this.fetchAllPermissions.bind(this);
    this.onRemovePermission = this.onRemovePermission.bind(this);
    this.onSelectPermission = this.onSelectPermission.bind(this);
    this.updateFormField = this.updateFormField.bind(this);
  }

  componentDidMount() {
    this.fetchRole();
    this.fetchAllPermissions();
  }

  async fetchRole() {
    const { roleName } = this.props.match.params

    this.props.updateLoading(true, 66);

    const retrieveRoleResponse = await this.platform.retrieveRolePermissions(roleName);
    const retrieveRoleResponseStatus = retrieveRoleResponse.status;
    const retrieveRoleResponseObj = retrieveRoleResponse.response;

    this.props.updateLoading(false);

    if (retrieveRoleResponseStatus === ERROR_STATUS) {
      this.props.showErrorMessage(JSON.stringify(retrieveRoleResponseObj))
      setTimeout(() => this.props.dismissErrorMessage(), parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
    }

    this.setState({
      form: {
        ...this.state.form,
        name: roleName,
        permissions: [
          ...this.state.form.permissions,
          ...retrieveRoleResponseObj
        ],
        selectedPermissions: retrieveRoleResponseObj,
      }
    })

    return retrieveRoleResponseObj;
  }

  async editRole() {
    this.props.updateLoading(true, 66);

    const editRoleResponse = await this.platform.editRole(this.state.form);
    const editRoleResponseStatus = editRoleResponse.status;
    const editRoleResponseObj = editRoleResponse.response;

    this.props.updateLoading(false);

    if (editRoleResponseStatus === ERROR_STATUS) {
      return {}
    }

    this.setState({
      form: {
        name: null,
        description: null,
        permissions: []
      }
    })
    this.props.showSuccessMessage("Role edited successfully.")
    setTimeout(() => this.props.dismissSuccessMessage(), parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT))

    return editRoleResponseObj
  }

  async fetchAllPermissions() {
    let pageNum = 1;
    const pageSize = 20;

    while (true) {
      const getPermissionsResponseObj = await this.fetchPermissions(pageNum, pageSize);
      const newPermissions = [
        ...this.state.permissions,
        ...getPermissionsResponseObj.content
      ];

      this.setState({
        permissions: newPermissions
      })

      if (newPermissions.length >= getPermissionsResponseObj.count) {
        this.setState({
          fetchingPermissions: false
        })
        this.props.updateLoading(false);
        break;
      }

      pageNum += 1;
    }
  }

  async fetchPermissions(pageNum, pageSize) {
    // this.props.updateLoading(true, 66);

    const getPermissionsResponse = await this.userManagement.getPermissions(pageNum, pageSize);
    const getPermissionsResponseStatus = getPermissionsResponse.status;
    const getPermissionsResponseObj = getPermissionsResponse.response;

    // this.props.updateLoading(false);

    if (getPermissionsResponseStatus === ERROR_STATUS) {
      return {}
    }

    return getPermissionsResponseObj
  }

  isDescriptionValid (newForm) {
    const form = newForm || this.state.form
    return form.description && form.description.length >= 6;
  }

  isNameValid (newForm) {
    const form = newForm || this.state.form
    return form.name && form.name.length >= 4;
  }

  isPermissionsValid (newForm) {

    const form = newForm || this.state.form
    return form.permissions && form.permissions.length >= 1;
  }

  onRemovePermission(optionList, removedPermission) {
    const { permissions } = this.state.form;
    

    this.updateFormField({
      permissions: permissions.filter(value => value !== removedPermission),
    });
  }

  onSelectPermission(optionList, selectedPermission) {
    const { permissions } = this.state.form;
    permissions.push(selectedPermission);

    this.updateFormField(permissions);
  }

  updateFormField (params) {
    const newFormData = {
      ...this.state.form,
      ...params
    }

    const allParamsAreValid = this.isNameValid(newFormData) && this.isPermissionsValid(newFormData)

    this.setState({
      form: newFormData,
      formIsValid: allParamsAreValid
    })
  }

  result(params) {
  }

  render() {
    if (this.state.fetchingPermissions) {
      return <React.Fragment />
    }

    return <section className={`form-container${this.state.deviceIsMobile ? '-mobile' : ''}`}>
      <H1 hidden={this.state.deviceIsMobile} style={{textAlign: 'left'}}>Edit Role</H1>
      <form style={{marginTop: !this.state.deviceIsMobile ? '10pt' : '0pt'}}>
        <Row style={{margin: 0, padding: 0}}>
          <Col xs={12} md={4}>
            <Form.Group controlId="create-role-role-name">
              <Form.Label style={{color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Name: <span className="required-marker">*</span></Form.Label>
              <FormControl disabled readonly value={this.state.form.name} placeholder="Name" onChange={(event) => {
                const name = event.target.value
                this.updateFormField({name})
              }} style={{backgroundColor: '#FFFFFF', borderRadius: '20px', border: `1px solid ${!this.state.form.name || this.isNameValid() ? '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`, boxSizing: 'border-box', fontSize: '12px', lineHeight: process.env.REACT_APP_MID_LINE_HEIGHT}} />
            </Form.Group>
          </Col>

          <Col xs={12} md={4}>
            <Form.Group controlId="create-role-role-description">
              <Form.Label style={{color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Description:</Form.Label>
              <FormControl value={this.state.form.description} placeholder="Description" onChange={(event) => {
                const description = event.target.value
                this.updateFormField({description})
              }} style={{backgroundColor: '#FFFFFF', borderRadius: '20px', border: `1px solid ${!this.state.form.description || this.isDescriptionValid() ? '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`, boxSizing: 'border-box', fontSize: '12px', lineHeight: process.env.REACT_APP_MID_LINE_HEIGHT}} />
            </Form.Group>
          </Col>
        </Row>
        
        <Row style={{margin: 0, padding: 0}}>
          <Col xs={12} md={4}>
            <Form.Group controlId="create-role-role-name">
              <Form.Label style={{color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Permissions: <span className="required-marker">*</span></Form.Label>
              <Multiselect
                options={this.state.permissions} // Options to display in the dropdown
                selectedvalues={[{"id":6,"name":"NAME_INQUIRY"}]} // Preselected value to persist in dropdown
                onRemove={this.onRemovePermission} // Function will trigger on remove event
                onSelect={this.onSelectPermission} // Function will trigger on select event
                displayValue="name" // Property name to display in the dropdown options
              />
              {/* <FormControl type="tel" placeholder="Name" onChange={(event) => {
                const name = event.target.value
                this.updateFormField({name})
              }} style={{backgroundColor: '#FFFFFF', borderRadius: '20px', border: `1px solid ${!this.state.firstName || this.isFirstNameValid() ? '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`, boxSizing: 'border-box', fontSize: '12px', lineHeight: process.env.REACT_APP_MID_LINE_HEIGHT}} /> */}
            </Form.Group>
          </Col>
        </Row>
        
        <Row style={{margin: 0, padding: 0}}>
          <Col xs={12} md={4}>
            <Button disabled={this.props.isLoading || !this.state.formIsValid} style={{marginTop: '10pt'}} onClick={this.editRole}>{this.props.isLoading ? 'Loading...' : 'SAVE'}</Button>
          </Col>
        </Row>
      </form>
    </section>
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.tunnel.isLoading,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dismissErrorMessage: () => dispatch(dismissErrorMessage()),
    dismissSuccessMessage: () => dispatch(dismissSuccessMessage()),
    showErrorMessage: message => dispatch(showErrorMessage(message)),
    showSuccessMessage: message => dispatch(showSuccessMessage(message)),
    updateLoading: (isLoading, percentage=null) => dispatch(updateLoading({isLoading, percentage}))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewAndEditRoleScene);
