import moment from 'moment';

import React, { useEffect, useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import ReactTable from 'react-table';
import { userManagementServiceClient } from '../../../../../App';
import Button from '../../../../../components/button';
import H1 from '../../../../../components/h1';
import Spinner from '../../../../../components/spinner';
import Layout from '../layout';
import { checkScreenSize } from '../remap-pos/response';
import AgentReport from './agent-report';

const COLOUR_GREEN = '#2A9D8F';
const COLOUR_GREY = '#a5a5a6';

const PosReport = () => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [posRequest, setPosRequest] = useState([]);
  const [restOfTheData, setRestOfTheData] = useState('');
  // const [pageSize, setPageSize] = useState("");
  // const [pageNum, setPageNum] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = (props) => {
    setRestOfTheData(props.original);
    setShow(true);
  };
  // const getAgents = (pageNum, pageSize) => {
  //   setPageSize(1);
  //   setPageNum(20);
  // };
  const getAllPosRemapReqest = async () => {
    const { response } =
      await userManagementServiceClient.getAllPosRemapReqest();
    setPosRequest(response.data);
    setLoading(false);
  };

  useEffect(() => {
    getAllPosRemapReqest();
  }, []);

  const reportColumns = [
    {
      Header: (props) => <React.Fragment>Request ID</React.Fragment>,
      Cell: (props) => <div style={{ textAlign: 'left' }}>{props.value}</div>,
      accessor: 'requestId',
      headerClassName: 'table-header',
      className: 'table-cell',
      style: { 'white-space': 'unset' },
    },
    {
      Header: (props) => <React.Fragment>Serial Number</React.Fragment>,
      Cell: (props) => {
        const statusColor = {
          Active: COLOUR_GREEN,
          'Not-Active': COLOUR_GREY,
        }[props.value];

        return (
          <div
            style={{
              textAlign: checkScreenSize() ? 'center' : 'left',
            }}
          >
            {props.value}
          </div>
        );
      },
      accessor: 'serialNo',
      headerClassName: 'table-header',
      className: 'table-cell',
    },
    {
      Header: (props) => <React.Fragment>Date</React.Fragment>,
      Cell: (props) => (
        <div style={{ textAlign: 'left' }}>
          {moment(props.value).format('DD-MM-YYYY HH:mm a')}
        </div>
      ),
      accessor: 'dateInitiated',
      headerClassName: 'table-header',
      className: 'table-cell',
      style: { 'white-space': 'unset' },
    },
    {
      Header: (props) => <React.Fragment>Status</React.Fragment>,
      Cell: (props) => {
        const statusColor = () => {
          if (props.value === 'approved') {
            return '#74C965';
          } else if (props.value === 'declined') {
            return 'red';
          } else {
            return '#EC9B40';
          }
        };
        // const statusColor = props.value === "approved" ? "#74C965" : "#EC9B40";
        return (
          <div
            style={{
              color: statusColor(),
              textAlign: 'left',
              textTransform: 'capitalize',
            }}
          >
            {props.value}
          </div>
        );
      },

      accessor: 'approvalStatus',
      headerClassName: 'table-header',
      className: 'table-cell',
      style: { 'white-space': 'unset' },
    },
    {
      Header: (props) => <React.Fragment>Action</React.Fragment>,
      Cell: (props) => (
        <div
          className='selectable'
          onClick={() => handleShow(props)}
          style={{
            textAlign: checkScreenSize() ? 'center' : 'left',
            color: '#0275D8',
          }}
        >
          view
          {/* <MdMore color={process.env.REACT_APP_GREY_COLOUR} /> */}
        </div>
      ),
      accessor: 'action',
      headerClassName: 'table-header',
      className: 'table-cell',
      // width: 40,
    },
  ];

  return (
    <Layout>
      <div>
        <Row xs={4} lg={4} style={{ padding: '0 0 10px 15px' }}>
          <H1>POS Remap Report (View)</H1>
        </Row>

        <ReactTable
          defaultPageSize={20}
          columns={reportColumns}
          data={posRequest}
          loading={loading}
          LoadingComponent={Spinner}
          // pageSize={20}
        />

        <Modal
          show={show}
          size='xl'
          onHide={handleClose}
          backdrop='static'
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Agent Details</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ background: '#F5F5F5' }}>
            {' '}
            <div
              style={{
                display: 'flex',
                gap: '40px',
                fontSize: '16px',
              }}
            >
              <p>
                <span style={{ fontWeight: 'bold' }}>Request ID</span>:{' '}
                {restOfTheData.requestId}
              </p>
              <p>
                <span
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  Request Status
                </span>
                :{' '}
                <span
                  style={{
                    textTransform: 'capitalize',
                    color:
                      restOfTheData.requestStatus === 'closed'
                        ? ' #74C965'
                        : '#EC9B40',
                  }}
                >
                  {restOfTheData.requestStatus}
                </span>
              </p>
            </div>
            <AgentReport restOfTheData={restOfTheData} />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Layout>
  );
};

export default PosReport;
