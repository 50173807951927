import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { Col, Form, Modal } from "react-bootstrap";
import H1 from "../../../../components/h1";
import PrimaryButton from "../component/button";
import InputText from "../component/input";
import { isEmailAddressValid } from "../component/extras";
import { HandIcon } from "../../../../icons";
import BackButton from "../component/backButton";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../constants/api";
import { notify } from "../../../../components/extras";
import Platform from "../../../../services/api/resources/platform";

const RefereeDetailsScene = ({ setScreen, application }) => {
  const history = useHistory();
  const platform = new Platform();

  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [refereeDetailsForm, setRefereeDetailsForm] = useState({
    emailAddress: "",
  });

  useEffect(() => {
    const updatedRefereeDetailsForm = JSON.parse(
      localStorage.getItem("refereeDetailsForm")
    );

    if (updatedRefereeDetailsForm) {
      setRefereeDetailsForm({
        emailAddress:
          application?.refereeDetailsForm?.emailAddress ||
          updatedRefereeDetailsForm.emailAddress ||
          "",
      });
    }
  }, [application]);

  useEffect(() => {
    localStorage.setItem(
      "refereeDetailsForm",
      JSON.stringify(refereeDetailsForm)
    );
  }, [refereeDetailsForm]);

  const handleSubmit = async () => {
    setIsLoading(true);
    const currentAgent = JSON.parse(localStorage.getItem("currentAgent"));
    const authToken = JSON.parse(localStorage.getItem("auth_token"));

    const agentCode = currentAgent.domainCode;
    const refereeEmail = refereeDetailsForm.emailAddress;
    const { status, response } = await platform.postRefereeAttestationSubmit(
      refereeEmail,
      agentCode,
      `Bearer ${authToken}`
    );
    setIsLoading(false);
    if (status === ERROR_STATUS) {
      notify(response.description, "error");
    } else if (status === SUCCESS_STATUS) {
      notify("Saved", "success");
      congratsModal();
    }
  };

  const updateFormField = (params) => {
    const newForm = {
      ...refereeDetailsForm,
      ...params,
    };

    setRefereeDetailsForm(newForm);
  };

  const isFormValid = () => {
    return (
      refereeDetailsForm.emailAddress && isEmailAddressValid(refereeDetailsForm)
    );
  };

  const congratsModal = () => {
    setShowModal(true);
  };

  return (
    <>
      <BackButton onClick={() => setScreen(4)} />

      <main className="onboarding-container">
        <Col
          style={{
            margin: "20px auto",
          }}
        >
          <H1
            style={{
              textAlign: "left",
              fontWeight: 600,
              marginBottom: ".2rem",
            }}
          >
            KYC Information
          </H1>

          <InputText
            label={"Email Address"}
            labelColor={
              !refereeDetailsForm.emailAddress ||
              isEmailAddressValid(refereeDetailsForm)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !refereeDetailsForm.emailAddress ||
              isEmailAddressValid(refereeDetailsForm)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            placeholder={"Enter your email address"}
            onChange={(e) => {
              updateFormField({ emailAddress: e.target.value });
            }}
            maxLength={200}
            value={refereeDetailsForm.emailAddress}
          />

          <PrimaryButton
            disabled={!isFormValid() || isLoading}
            onClick={handleSubmit}
          >
            {isLoading ? "Processing..." : "Next"}
          </PrimaryButton>
        </Col>
      </main>
      <Modal
        show={showModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body style={{ padding: "1rem" }}>
          <div style={{ padding: "4rem", alignContent: "center" }}>
            <div style={{ display: "grid", placeContent: "center" }}>
              {HandIcon()}
            </div>
            <h1
              style={{
                textAlign: "center",
                fontWeight: "bold",
                marginBottom: "1rem",
                fontSize: "1.5rem",
              }}
            >
              Congratulations !!!
            </h1>
            <p
              style={{
                color: "black",
                fontWeight: "400",
                fontSize: "13px",
                textAlign: "center",
              }}
            >
              An email has been sent to your referee. Kindly follow up with your
              referee for the prompt approval.
            </p>
            <button
              className="signupButton2"
              style={{
                marginTop: "3rem",
                textAlign: "center",
                color: "white",
                backgroundColor: "#00425f",
                width: "100%",
              }}
              onClick={() => {
                setShowModal(false);
                history.push("/dashboard");
              }}
            >
              Okay
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RefereeDetailsScene;
