import React, { useEffect, useRef, useState } from "react";
import { Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import H1 from "../../../../components/h1";
import CountriesStatesLga from "../../../../fixtures/countries_states_lgas.json";
import BackButton from "../component/backButton";
import InputText from "../component/input";
import { SelectInputState } from "../component/stateSelect";
import HeaderSignup from "../../../../components/header-signup";
import {
  isBusinessAddressValid,
  isEmailValid,
  isNameValid,
  isStreetNumber,
  isPhoneNumberValid,
  isCityValid,
} from "../component/extras";
import Stepper from "../component/stepper";
import { DatePickerComponent } from "../component/datePickerComponent";
import Transaction from "../../../../services/api/resources/transaction";
import { notify } from "../../../../components/extras";
import PrimaryButton from "../component/button";

const MAX_BUSINESS_NAME_LENGTH = 50;

const parseDate = (dateStr) => {
  const date = new Date(dateStr);
  return isNaN(date.getTime()) ? null : date;
};

const BusinessDetailsScene = ({ setScreen, application }) => {
  const transaction = new Transaction();

  const [states, setStates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedState, setSelectedState] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [cacInitiate, setCacInitiate] = useState(null);
  const [personalDetailsForm, setPersonalDetailsForm] = useState(null);
  const [kycDetailsForm, setKycDetailsForm] = useState(null);
  const [bvnInfo, setBvnInfo] = useState(null);
  const [lga, setLga] = useState("");
  const [state, setState] = useState("");
  const [passportPhotoBase64, setPassportPhotoBase64] = useState("");
  const [cacRegistrationForm, setCacRegistrationForm] = useState("");
  const [documentPassportPhoto, setDocumentPassportPhoto] = useState("");

  useEffect(() => {
    const storedCacInitiate = JSON.parse(localStorage.getItem("cacInitiate"));
    const storedPersonalDetailsForm = JSON.parse(
      localStorage.getItem("personalDetailsForm")
    );
    const storedKycDetailsForm = JSON.parse(
      localStorage.getItem("kycDetailsForm")
    );
    const storedBvnInfo = JSON.parse(localStorage.getItem("bvnInfo"));
    const storedLga = localStorage.getItem("lga");
    const storedState = localStorage.getItem("state");
    const storedPassportPhotoBase64 = localStorage.getItem(
      "passportPhotoBase64"
    );
    const storedDocumentPassportPhotoBase64 = localStorage.getItem(
      "documentPassportPhoto"
    );
    const storedCacRegistrationForm = JSON.parse(
      localStorage.getItem("cacRegistrationForm")
    );

    // Set form data
    setCacInitiate(storedCacInitiate);
    setPersonalDetailsForm(storedPersonalDetailsForm);
    setKycDetailsForm(storedKycDetailsForm);
    setBvnInfo(storedBvnInfo);
    setLga(storedLga);
    setState(storedState);
    setCacRegistrationForm(storedCacRegistrationForm);

    // Determine which passport photo to use
    if (storedPassportPhotoBase64) {
      setPassportPhotoBase64(storedPassportPhotoBase64);
    } else if (storedDocumentPassportPhotoBase64) {
      setDocumentPassportPhoto(storedDocumentPassportPhotoBase64);
      setPassportPhotoBase64(storedDocumentPassportPhotoBase64);
    }
  }, []);

  const modifiedAmount = (
    parseInt(cacInitiate?.cacInitiateRequest?.amount) / 100
  ).toString();

  const initialFormState = {
    email: application ? application?.businessDetails?.email : "",
    businessLine: application ? application?.businessDetails?.businessLine : "",
    businessDate: application
      ? parseDate(application?.businessDetails?.businessDate)
      : "",
    companyState: application ? application?.businessDetails?.companyState : "",
    city: application ? application?.businessDetails?.city : "",
    street: application ? application?.businessDetails?.street : "",
    address: application ? application?.businessDetails?.address : "",
  };

  const [businessDetailsForm, setBusinessDetailsForm] = useState(() => {
    const savedForm = JSON.parse(localStorage.getItem("businessDetailsForm"));
    if (savedForm) {
      return {
        ...initialFormState,
        ...savedForm,
        businessDate: savedForm.businessDate
          ? parseDate(savedForm.businessDate)
          : "",
      };
    }
    return initialFormState;
  });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      localStorage.setItem(
        "businessDetailsForm",
        JSON.stringify({
          ...businessDetailsForm,
          businessDate: businessDetailsForm.businessDate
            ? businessDetailsForm.businessDate.toISOString()
            : "",
        })
      );
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [businessDetailsForm]);

  const updateFormField = (params) => {
    const newForm = {
      ...businessDetailsForm,
      ...params,
    };
    setBusinessDetailsForm(newForm);
  };

  const fetchAllStates = async () => {
    const stateArray = CountriesStatesLga[0].states.map((state) => ({
      name: state.name,
      id: state.id,
    }));
    setStates(stateArray);
  };

  const submitCacPaymentRequest = async () => {
    const deviceId = localStorage.getItem("deviceUid");
    const paymentItemCode = "IFISCAC";
    const amount = cacInitiate?.cacInitiateRequest?.amount;
    const borneBy = cacInitiate?.cacInitiateRequest?.borneBy;
    const businessName = cacRegistrationForm.proposedName;
    const customerMsisdn = bvnInfo?.data?.bvnPhoneNumber;
    const narration = cacInitiate?.cacInitiateRequest?.narration;
    const proprietorCity = personalDetailsForm.city;
    const companyCity = businessDetailsForm.city;
    const businnessPhone = bvnInfo?.data?.bvnPhoneNumber;
    const fee = 0;
    const lineOfBusiness = cacRegistrationForm.businessLine;
    const proprietorPhonenumber = bvnInfo?.data?.bvnPhoneNumber;
    const businessCommencementDate = businessDetailsForm.businessDate
      ?.toISOString()
      .split("T")[0];
    const companyState = localStorage.getItem("companyState");
    const proprietorNationality = personalDetailsForm.nationality;
    const proprietorState = state;
    const proprietorDob = bvnInfo?.data?.bvnDateOfBirth;
    const proprietorFirstname = bvnInfo?.data?.bvnFirstName;
    const proprietorOthername = bvnInfo?.data?.bvnMiddleName
      ? bvnInfo?.data?.bvnMiddleName
      : "";
    const proprietorSurname = bvnInfo?.data?.bvnLastName;
    const proprietorGender = personalDetailsForm.gender.toUpperCase();
    const proprietorStreetNumber = personalDetailsForm.streetNumber;
    const proprietorServiceAddress = personalDetailsForm.houseAddress;
    const companyEmail = businessDetailsForm.email;
    const companyStreetNumber = businessDetailsForm.street;
    const proprietorEmail = bvnInfo?.data?.businessEmail;
    const companyAddress = businessDetailsForm.businessAddress;
    const proprietorPostcode = personalDetailsForm.postalCode;
    const proprietorLga = lga;
    const transactionRef = cacInitiate?.transactionReference;
    const supportingDoc = kycDetailsForm.businessLocation;
    const signature = kycDetailsForm.signature;
    const meansOfId = kycDetailsForm.ninImage;
    const passport = passportPhotoBase64 || documentPassportPhoto;

    const payload = {
      amount,
      borneBy,
      businessName,
      customerMsisdn,
      fee,
      lineOfBusiness,
      narration,
      paymentInstrumentType: "CASH",
      paymentItemCode,
      reference: transactionRef,
      registrationPayload: {
        proprietorCity,
        companyCity,
        proprietorPhonenumber,
        businessCommencementDate,
        companyState,
        proprietorNationality,
        proprietorState,
        proprietorDob,
        proprietorFirstname,
        proprietorOthername,
        proprietorSurname,
        proprietorGender,
        proprietorStreetNumber,
        proprietorServiceAddress,
        companyEmail,
        companyStreetNumber,
        proprietorEmail,
        companyAddress,
        proprietorPostcode,
        proprietorLga,
        transactionRef,
        supportingDoc,
        signature,
        meansOfId,
        passport,
      },
    };

    try {
      setLoading(true);
      const { status, response } = await transaction.submitCacRegPayment(
        payload,
        businnessPhone,
        transactionRef,
        deviceId
      );
      setLoading(false);
      if (response.code === "50300") {
        notify(response.description, "error");
        // setScreen(1)
      } else if (response.code === "40006") {
        // notify(response.description, "error");
        setScreen(6);
      } else if (response.code === "01") {
        notify(response.description, "error");
        // setScreen(1)
      } else if (response.code === "00") {
        // notify(response.description, "success");
        setScreen(5);
      }
    } catch (error) {
      notify(
        "An unexpected error occurred. Please check your connection or try again later.",
        "error"
      );
      setScreen(1);
    }
  };

  const congratsModal = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setIsLoading(false);
    setShowModal(false);
  };

  const isFormValid = () => {
    return (
      businessDetailsForm.city &&
      businessDetailsForm.email &&
      isEmailValid(businessDetailsForm) &&
      businessDetailsForm.businessDate &&
      businessDetailsForm.street &&
      isStreetNumber(businessDetailsForm.street) &&
      businessDetailsForm.businessAddress &&
      isBusinessAddressValid(businessDetailsForm.businessAddress)
    );
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setTimeout(() => {
      congratsModal();
    }, 4000);
  };

  useEffect(() => {
    fetchAllStates();
  }, []);

  return (
    <>
      <div
        style={{ display: "flex", justifyContent: "flex-start", gap: "40px" }}
      >
        <HeaderSignup />
        <BackButton onClick={() => setScreen(3)} />
      </div>
      <main className="onboarding-container">
        <Col style={{ marginBottom: "20px", width: "80%", margin: "0 auto" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              gap: "10px",
              marginBottom: "30px",
            }}
          >
            <Stepper borderColor="#00425F" />
            <Stepper borderColor="#00425F" />
            <Stepper borderColor="#00425F" />
          </div>
          <H1
            style={{ textAlign: "left", fontWeight: 600, marginBottom: "1rem" }}
          >
            Business Details
          </H1>
          <InputText
            label={"Company Email Address"}
            onChange={(e) => updateFormField({ email: e.target.value })}
            labelColor={
              !businessDetailsForm.email || isEmailValid(businessDetailsForm)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !businessDetailsForm.email || isEmailValid(businessDetailsForm)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            placeholder={"Enter your Company email address"}
            value={businessDetailsForm.email}
            maxLength={MAX_BUSINESS_NAME_LENGTH}
            disabled={false}
          />

          <InputText
            disabled={true}
            label={"Line of Business"}
            placeholder={"Enter line of business"}
            onChange={(e) => updateFormField({ businessLine: e.target.value })}
            maxLength={200}
            value={cacRegistrationForm?.businessLine}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "1rem 0",
            }}
          >
            <DatePickerComponent
              onChange={(date) => updateFormField({ businessDate: date })}
              selected={businessDetailsForm.businessDate}
              label={"Business Commencement Date"}
              placeholder="DD-MM-YYYY"
              size={`100%`}
              format={`dd-MM-yyyy`}
              isClearable={true}
              showTimeInput={true}
              textStyle={"1.0rem"}
              iconStyle={"0.2rem"}
              dateType={"startDate"}
              preloadDate={false}
            />
          </div>
          <SelectInputState
            label={"Company State"}
            option={states}
            getState={localStorage.getItem("companyState")}
            value={businessDetailsForm.state}
            placeholder={"state"}
            onChange={(e) => {
              const selectedState = states.find(
                (value) => value.name === e.target.value
              );
              localStorage.setItem("companyState", selectedState.name);
              setSelectedState(selectedState.id);
              updateFormField({ companyState: selectedState.id });
            }}
          />
          <InputText
            label={"Company City"}
            labelColor={
              !businessDetailsForm.city || isCityValid(businessDetailsForm.city)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !businessDetailsForm.city || isCityValid(businessDetailsForm.city)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            placeholder={"Enter your Company city"}
            onChange={(e) => updateFormField({ city: e.target.value })}
            maxLength={200}
            value={businessDetailsForm.city}
          />

          <InputText
            label={"Company Street Number"}
            onChange={(e) => {
              const value = e.target.value;
              if (/^[a-zA-Z0-9 ]*$/.test(value)) {
                updateFormField({ street: value });
              }
            }}
            labelColor={
              !businessDetailsForm.street ||
              /^[a-zA-Z0-9 ]*$/.test(businessDetailsForm.street)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !businessDetailsForm.street ||
              /^[a-zA-Z0-9 ]*$/.test(businessDetailsForm.street)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            placeholder={"Enter your company street number"}
            value={businessDetailsForm.street}
            disabled={false}
          />

          <InputText
            label={"Company Address"}
            labelColor={
              !businessDetailsForm.businessAddress ||
              isBusinessAddressValid(businessDetailsForm.businessAddress)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !businessDetailsForm.businessAddress ||
              isBusinessAddressValid(businessDetailsForm.businessAddress)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            placeholder={"Enter your Company address"}
            onChange={(e) =>
              updateFormField({ businessAddress: e.target.value })
            }
            maxLength={200}
            value={businessDetailsForm.businessAddress}
          />

          <PrimaryButton
            onClick={handleSubmit}
            // onClick={onPayCacPaymentRequest}
            disabled={!isFormValid() || isLoading}
          >
            {isLoading ? "Processing..." : "Proceed to Payment"}
          </PrimaryButton>
        </Col>
      </main>
      <Modal
        show={showModal}
        onHide={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        centered
      >
        <Modal.Body style={{ padding: "1rem" }}>
          <div style={{ padding: "4rem", alignContent: "center" }}>
            <h1
              style={{
                textAlign: "center",
                fontWeight: "bold",
                marginBottom: "1rem",
                fontSize: "1.5rem",
              }}
            >
              Payment Details
            </h1>
            <InputText
              disabled={true}
              label={"Amount"}
              placeholder={"amount"}
              maxLength={200}
              value={modifiedAmount}
            />
            <InputText
              disabled={true}
              label={"Reason"}
              placeholder={"reason"}
              maxLength={200}
              value={"CAC Registration"}
            />
            <button
              className="signupButton2"
              style={{
                marginTop: "3rem",
                textAlign: "center",
                color: "white",
                backgroundColor: "#00425f",
                width: "100%",
              }}
              onClick={() => submitCacPaymentRequest()}
              disabled={loading}
            >
              {loading ? "Processing..." : "Proceed"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BusinessDetailsScene;
