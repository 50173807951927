import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";

import MdCheckmarkCircle from "react-ionicons/lib/MdCheckmarkCircle";
import { toast } from "react-toastify";
import { notify } from "../../../../../components/extras";
import Title from "../component/titleRevamp.js";
import { Col } from "react-bootstrap";
import H1 from "../../../../../components/h1.js";
import Platform from "../../../../../services/api/resources/platform.js";
import BackButton from "../../../../signup/onboarding-setup/component/backButton.js";
import InputText from "../../../../signup/onboarding-setup/component/input.js";
import InputWithSelectText from "../../../../signup/onboarding-setup/component/inputWithSelect.js";
import Onboarding from "../../../../../services/api/resources/onboarding.js";
import { ERROR_STATUS } from "../../../../../constants/api.js";

const BusinessDetailsVerfication = ({ application, setScreen, setKycId }) => {
  const history = useHistory();
  const onboarding = new Onboarding();

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tinValidated, setTinValidated] = useState(false);
  const [businessType, setBusinessType] = useState("BN");
  const [tinSubmitted, setTinSubmitted] = useState(false);
  const [businessSceneForm, setBusinessSceneForm] = useState({
    tin: application?.applicantDetails?.businessScene?.tin || "",
    cacNumber: application?.applicantDetails?.businessScene?.cacNumber || "",
    kycId: application?.applicantDetails?.businessScene?.kycId || "",
  });
  const [selectedValue, setSelectedValue] = useState("BN");

  useEffect(() => {
    const updateBusinessForm = JSON.parse(localStorage.getItem("businessForm"));
    if (updateBusinessForm) {
      setBusinessSceneForm({
        tin: updateBusinessForm.tin || businessSceneForm.tin,
        cacNumber: updateBusinessForm.cacNumber || businessSceneForm.cacNumber,
        kycId: updateBusinessForm.kycId || businessSceneForm.kycId,
      });
    }
  }, [application]);

  useEffect(() => {
    localStorage.setItem(
      "businessSceneForm",
      JSON.stringify(businessSceneForm)
    );
  }, [businessSceneForm]);

  const updateFormField = (params) => {
    setBusinessSceneForm((prevState) => ({
      ...prevState,
      ...params,
    }));
  };

  const congratsModal = () => {
    setShowModal(true);
  };

  const handleSubmit = async () => {
    setLoading(true);

    const payload = getPayload();
    const validationType = tinValidated ? "CAC" : "TIN";

    const { status, response } = await onboarding.validateBusinessDetails(
      payload,
      validationType
    );

    setLoading(false);

    if (status === ERROR_STATUS) {
      return handleErrorResponse(response);
    }

    return handleSuccessfulResponse(response);
  };

  const getPayload = () => {
    return tinValidated
      ? {
          kycId: businessSceneForm?.kycId,
          identificationNumber: businessSceneForm.cacNumber,
          prefix: selectedValue,
        }
      : { identificationNumber: businessSceneForm.tin };
  };

  const handleErrorResponse = (response) => {
    notify(response.description, "error");
  };

  const handleSuccessfulResponse = (response) => {
    const { kycData } = response;
    const {
      tinVerificationResponse,
      cacVerificationResponse,
      kycNextStage,
      kycId,
    } = kycData;

    if (tinVerificationResponse) {
      return handleTinVerificationResponse(tinVerificationResponse, kycId);
    }

    if (cacVerificationResponse) {
      return handleCacVerificationResponse(cacVerificationResponse, kycId);
    }

    if (kycNextStage) {
      return handleKycNextStage(kycNextStage, kycId);
    }

    notify("An error occurred", "error");
  };

  const handleTinVerificationResponse = (tinVerificationResponse, kycId) => {
    const { validationStatus, message, cacRegNo } = tinVerificationResponse;

    if (validationStatus === "VERIFIED") {
      if (!tinValidated) {
        setTinValidated(true);
        setTinSubmitted(true);
        updateFormField({
          cacNumber: cacRegNo,
          kycId,
        });
        setKycId(kycId);
      } else {
        congratsModal();
      }
    } else if (validationStatus === "NOT_VERIFIED") {
      notify(message, "error");
    }
  };

  const handleCacVerificationResponse = (cacVerificationResponse, kycId) => {
    const { validationStatus, message } = cacVerificationResponse;

    if (validationStatus === "VERIFIED") {
      notify(message, "success");
      setScreen(3);
      setKycId(kycId);
    } else if (validationStatus === "NOT_VERIFIED") {
      notify(message, "error");
    }
  };

  const handleKycNextStage = (kycNextStage, kycId) => {
    if (kycNextStage === "BVN" || kycNextStage === "BVN_VERIFY_MOBILE") {
      setScreen(3);
      notify("TIN Verified", "success");
      setKycId(kycId);
    } else if (kycNextStage === "LIVELINESS") {
      setKycId(kycId);
      notify("TIN Verified", "success");
      setScreen(10);
    } else if (kycNextStage === "PERSONAL_DETAIL") {
      setKycId(kycId);
      notify("TIN Verified", "success");
      setScreen(5);
    } else if (kycNextStage === "NIN") {
      setKycId(kycId);
      notify("TIN Verified", "success");
      setScreen(4);
    }
  };

  const isTinValid = (tin) => {
    const tinPattern =
      /^[A-Za-z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{13,13}$/;
    return tinPattern.test(tin);
  };

  const isCacNumberValid = (cacNumber) => {
    const cacNumberPattern =
      /^[A-Za-z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8}$/;
    return cacNumberPattern.test(cacNumber);
  };

  const isFormValid = () => {
    return isTinValid(businessSceneForm.tin);
    // &&
    // (tinValidated ? isCacNumberValid(businessSceneForm.cacNumber) : true)
  };

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  // let kycCheckListDetails = JSON.parse(localStorage.getItem("currentAgent"));
  return (
    <>
      <BackButton
        onClick={() => {
          localStorage.removeItem("businessDetailsForm");
          setScreen(1);
        }}
      />
      <main className="aggregator-container">
        <Col
          style={{
            marginBottom: "20px",
            margin: "0 auto",
          }}
        >
          <Title pageNum={"1"} />
          <H1
            style={{
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "1rem",
            }}
          >
            Business details
          </H1>

          <div style={{ position: "relative" }}>
            <div>
              <InputText
                label={"Enter your Tax Identification Number (TIN)"}
                labelColor={
                  !businessSceneForm.tin || isTinValid(businessSceneForm.tin)
                    ? "black"
                    : process.env.REACT_APP_RED_COLOUR
                }
                border={`1px solid ${
                  !businessSceneForm.tin || isTinValid(businessSceneForm.tin)
                    ? "#DFE2E6"
                    : process.env.REACT_APP_RED_COLOUR
                }`}
                placeholder={"Enter TIN"}
                onChange={(e) => {
                  updateFormField({ tin: e.target.value.trim() });
                }}
                maxLength={13}
                value={businessSceneForm.tin}
                disabled={tinValidated}
              />
              {tinValidated && (
                <MdCheckmarkCircle
                  style={{
                    position: "absolute",
                    top: "52px",
                    right: "10px",
                    transform: "translateY(-50%)",
                    width: "20px",
                    height: "20px",
                  }}
                  color="green"
                  fontSize="10px"
                />
              )}
            </div>
            {tinValidated && (
              <div style={{}}>
                <InputWithSelectText
                  label={"Select your Prefix"}
                  // labelColor={
                  //   !businessSceneForm.cacNumber ||
                  //   isCacNumberValid(businessSceneForm.cacNumber)
                  //     ? "black"
                  //     : process.env.REACT_APP_RED_COLOUR
                  // }
                  placeholder={"Enter number"}
                  value={businessSceneForm.cacNumber}
                  maxLength={8}
                  // border={`1px solid ${
                  //   !businessSceneForm.cacNumber ||
                  //   isCacNumberValid(businessSceneForm.cacNumber)
                  //     ? "#DFE2E6"
                  //     : process.env.REACT_APP_RED_COLOUR
                  // }`}
                  loading={loading}
                  selectOptions={[
                    { label: "BN", value: "BN" },
                    { label: "LP", value: "LP" },
                    { label: "RC", value: "RC" },
                    { label: "LLP", value: "LLP" },
                    { label: "IT", value: "IT" },
                  ]}
                  selectValue={selectedValue}
                  onSelectChange={handleSelectChange}
                  disabled={true}
                />
              </div>
            )}
            <div
              style={{
                fontSize: "14px",
                marginBottom: "1.5rem",
                marginTop: "-10px",
              }}
            >
              Are you a Government Biller?{" "}
              <span
                style={{
                  color: process.env.REACT_APP_LINK_COLOUR,
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
                onClick={() => {
                  setScreen(9);
                }}
              >
                Click here
              </span>
            </div>
          </div>

          <button
            onClick={handleSubmit}
            className="signupButton2"
            style={{
              marginTop: "1rem",
              textAlign: "center",
              color: "white",
              backgroundColor: "#00425f",
              width: "100%",
            }}
            disabled={!isFormValid() || loading}
          >
            {loading ? "Processing..." : tinSubmitted ? "Submit" : "Next"}
          </button>
        </Col>
      </main>
    </>
  );
};

export default BusinessDetailsVerfication;
