import React, { useEffect } from "react";

import { useHistory } from "react-router-dom";

import ProfileSetupCTA from "./component/profileSetupCTA";
import HeaderSignup from "../../../../components/header-signup";
import {
  AgentBanner,
  PendingApprovalBanner,
  PendingAttestationBanner,
  PendingSubmissionBanner,
} from "./component/bannerHeader";

const ManageProfileScene = ({
  setScreen,
  props,
  fetchApplication,
  isLoading,
}) => {
  const history = useHistory();
  const applicationId = localStorage.getItem("applicationId");

  let application = JSON.parse(localStorage.getItem("currentAgent"));

  let modifiedAgentDetails = JSON.parse(
    localStorage.getItem("modifiedAgentDetails")
  );

  let currentUser = JSON.parse(localStorage.getItem("currentUser"));

  let personalDetailsForm = JSON.parse(
    localStorage.getItem("personalDetailsForm")
  );
  let nextOfKinDetailsForm = JSON.parse(
    localStorage.getItem("nextOfKinDetailsForm")
  );
  let kycDetailsForm = JSON.parse(localStorage.getItem("kycDetailsForm"));
  let businessDetailsForm = JSON.parse(
    localStorage.getItem("businessDetailsForm")
  );
  const navigateToOverview = () => {
    history.push("/agent/dashboard");
  };

  const handleKYC = () => {
    if (personalDetailsForm?.phoneNumber) {
      setScreen(3);
    }
  };
  const handleBussinessDetails = () => {
    if (personalDetailsForm?.phoneNumber && kycDetailsForm.idNumber) {
      setScreen(4);
    }
  };
  const handleNOK = () => {
    if (
      personalDetailsForm?.phoneNumber &&
      kycDetailsForm?.idNumber &&
      businessDetailsForm?.state
    ) {
      setScreen(5);
    }
  };
  const handleKYCApplicationId = () => {
    if (application?.applicantDetails?.phoneNumber) {
      setScreen(3);
    }
  };
  const handleBussinessDetailsApplicationId = () => {
    if (
      application?.applicantDetails?.phoneNumber &&
      application?.documentsList?.length > 1
    ) {
      setScreen(4);
    }
  };
  const handleNOKApplicationId = () => {
    if (
      application?.applicantDetails?.phoneNumber &&
      application?.documentsList?.length > 1 &&
      application?.businessDetails?.state
    ) {
      setScreen(5);
    }
  };

  useEffect(() => {
    if (applicationId && !personalDetailsForm) {
      fetchApplication();
    }
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          gap: "40px",
        }}
      >
        <HeaderSignup />
      </div>

      <main className="aggregator-container">
        <div>
          {(process.env.REACT_APP_UPGRADE_STANDARD === "true" &&
            application?.agentClass === "BASIC" &&
            modifiedAgentDetails?.count === 0) ||
          modifiedAgentDetails?.content[0]?.status === 0 ||
          modifiedAgentDetails?.content[0]?.status === 1 ? (
            <div>{<AgentBanner />}</div>
          ) : modifiedAgentDetails?.content[0]?.status === 5 ? (
            <div>{<PendingAttestationBanner />}</div>
          ) : modifiedAgentDetails?.content[0]?.status === 2 ? (
            <div>{<PendingApprovalBanner />}</div>
          ) : modifiedAgentDetails?.content[0]?.status === 6 ? (
            <div
              style={{
                zIndex: 10,
              }}
            >
              {<PendingSubmissionBanner />}
            </div>
          ) : null}
        </div>
        <div>
          <ProfileSetupCTA
            application={application}
            // onClick={() => setScreen(2)}
            onClick={() =>
              (window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/manage-AgentProfile?activeTab=personal`)
            }
            title={"Personal Details"}
            isLoading={isLoading}
            sub={`View your personal details.`}
          />

          <ProfileSetupCTA
            application={application}
            // onClick={application ? handleKYCApplicationId : handleKYC}
            // onClick={() => setScreen(3)}
            onClick={() =>
              (window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/manage-AgentProfile?activeTab=kyc`)
            }
            title={"KYC Information"}
            isLoading={isLoading}
            sub={`Get an overview of your KYC information.`}
          />

          <ProfileSetupCTA
            application={application}
            // onClick={application ? handleKYCApplicationId : handleKYC}
            // onClick={() => setScreen(4)}
            onClick={() =>
              (window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/manage-AgentProfile?activeTab=business`)
            }
            title={"Business Information"}
            isLoading={isLoading}
            sub={`Get an overview of your Business information.`}
          />

          <ProfileSetupCTA
            application={application}
            // onClick={() => setScreen(5)}
            onClick={() =>
              (window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/manage-AgentProfile?activeTab=nextOfKin`)
            }
            title={"Next of Kin Information"}
            isLoading={isLoading}
            sub={`Get an overview of your Next of Kin Information.`}
          />
        </div>
      </main>
    </>
  );
};

export default ManageProfileScene;
