import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import ReactPhoneInput from 'react-phone-input-2';
import BaseScene from '../../../../../../base-scene';
import Button from '../../../../../../../components/button';
import { ERROR_STATUS } from '../../../../../../../constants/api';
import FormControl from '../../../../../../../components/form-control';
import FormLabel from '../../../../../../../components/form-label';
import H1 from '../../../../../../../components/h1';
import Platform from '../../../../../../../services/api/resources/platform';
import UserManagement from '../../../../../../../services/api/resources/user-management';
import { showErrorMessage, showSuccessMessage, dismissErrorMessage, dismissSuccessMessage, updateLoading } from '../../../../../../../services/redux/actions/tunnel';

class ViewAndEditUserScene extends BaseScene {
  platform = new Platform();
  userManagement = new UserManagement();

  constructor(props) {
    super(props)

    this.state = {
      form: {
        name: null,
        description: null,
        permissions: []
      },
      roles: [],
      fetchingRoles: true,
    };

    this.editUser = this.editUser.bind(this);
    this.fetchUser = this.fetchUser.bind(this);
    this.fetchRoles = this.fetchRoles.bind(this);
    this.onRemovePermission = this.onRemovePermission.bind(this);
    this.onSelectPermission = this.onSelectPermission.bind(this);
    this.updateFormField = this.updateFormField.bind(this);
  }

  componentDidMount() {
    this.fetchRoles();
    this.fetchUser();
  }

  async fetchRoles() {
    const getRolesResponse = await this.platform.retrieveRoles();
    const getRoleResponseStatus = getRolesResponse.status;
    const getRoleResponseObj = getRolesResponse.response;

    this.props.updateLoading(false);

    if (getRoleResponseStatus === ERROR_STATUS) {
      return
    }

    this.setState({
      fetchingRoles: false,
      roles: getRoleResponseObj
    });
  }

  async fetchUser() {
    const { username } = this.props.match.params

    this.props.updateLoading(true, 66);

    const retrieveUserResponse = await this.userManagement.getUser(username);
    const retrieveUserResponseStatus = retrieveUserResponse.status;
    const retrieveUserResponseObj = retrieveUserResponse.response;

    this.props.updateLoading(false);

    if (retrieveUserResponseStatus === ERROR_STATUS) {
      this.props.showErrorMessage(JSON.stringify(retrieveUserResponseObj))
      setTimeout(() => this.props.dismissErrorMessage(), parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT));
      return
    }

    const userData = retrieveUserResponseObj.data;

    this.setState({
      form: {
        ...this.state.form,
        ...userData,
        phone: userData.mobileNo,
        role: userData.roleName
      }
    })

    return retrieveUserResponseObj;
  }

  async editUser() {
    this.props.updateLoading(true, 66);

    const editRoleResponse = await this.platform.editRole(this.state.form);
    const editRoleResponseStatus = editRoleResponse.status;
    const editRoleResponseObj = editRoleResponse.response;

    this.props.updateLoading(false);

    if (editRoleResponseStatus === ERROR_STATUS) {
      return {}
    }

    this.setState({
      form: {
        name: null,
        description: null,
        permissions: []
      }
    })
    this.props.showSuccessMessage("Role edited successfully.")
    setTimeout(() => this.props.dismissSuccessMessage(), parseInt(process.env.REACT_APP_TOAST_DURATION_SHORT))

    return editRoleResponseObj
  }

  async fetchAllPermissions() {
    let pageNum = 1;
    const pageSize = 20;

    while (true) {
      const getPermissionsResponseObj = await this.fetchPermissions(pageNum, pageSize);
      const newPermissions = [
        ...this.state.permissions,
        ...getPermissionsResponseObj.content
      ];

      this.setState({
        permissions: newPermissions
      })

      if (newPermissions.length >= getPermissionsResponseObj.count) {
        this.setState({
          fetchingPermissions: false
        })
        this.props.updateLoading(false);
        break;
      }

      pageNum += 1;
    }
  }

  async fetchPermissions(pageNum, pageSize) {
    const getPermissionsResponse = await this.userManagement.getPermissions(pageNum, pageSize);
    const getPermissionsResponseStatus = getPermissionsResponse.status;
    const getPermissionsResponseObj = getPermissionsResponse.response;

    if (getPermissionsResponseStatus === ERROR_STATUS) {
      return {}
    }

    return getPermissionsResponseObj
  }

  isDescriptionValid (newForm) {
    const form = newForm || this.state.form
    return form.description && form.description.length >= 6;
  }

  isNameValid (newForm) {
    const form = newForm || this.state.form
    return form.name && form.name.length >= 4;
  }

  isPhoneValid (newForm) {
    const form = newForm || this.state.form
    return form.phone && form.phone.length === 11
  }

  isPermissionsValid (newForm) {

    const form = newForm || this.state.form
    return form.permissions && form.permissions.length >= 1;
  }

  onRemovePermission(optionList, removedPermission) {
    const { permissions } = this.state.form;
    

    this.updateFormField({
      permissions: permissions.filter(value => value !== removedPermission),
    });
  }

  onSelectPermission(optionList, selectedPermission) {
    const { permissions } = this.state.form;
    permissions.push(selectedPermission);

    this.updateFormField(permissions);
  }

  updateFormField (params) {
    const newFormData = {
      ...this.state.form,
      ...params
    }

    const allParamsAreValid = this.isNameValid(newFormData) && this.isPermissionsValid(newFormData)

    this.setState({
      form: newFormData,
      formIsValid: allParamsAreValid
    })
  }

  result(params) {
  }

  render() {
    if (this.state.fetchingRoles) {
      return <React.Fragment />
    }

    return <section className={`form-container${this.state.deviceIsMobile ? '-mobile' : ''}`}>
      <H1 hidden={this.state.deviceIsMobile} style={{textAlign: 'left'}}>Edit User</H1>
      <form style={{marginTop: !this.state.deviceIsMobile ? '10pt' : '0pt'}}>
        <Row style={{margin: 0, padding: 0}}>
          <Col xs={12} md={4}>
            <Form.Group controlId="create-role-role-name">
              <Form.Label 
                style={{color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>First Name: <span className="required-field-tick">*</span></Form.Label>
              <FormControl 
                value={this.state.form.firstName} 
                placeholder="First Name" 
                onChange={(event) => {
                  const firstName = event.target.value
                  this.updateFormField({firstName})
                }} 
                style={{backgroundColor: '#FFFFFF', borderRadius: '20px', border: `1px solid ${!this.state.form.name || this.isNameValid() ? '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`, boxSizing: 'border-box', fontSize: '12px', lineHeight: process.env.REACT_APP_MID_LINE_HEIGHT}} />
            </Form.Group>
          </Col>

          <Col xs={12} md={4}>
            <Form.Group controlId="create-role-role-description">
              <Form.Label 
                style={{color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}
                >Last Name: <span className="required-field-tick">*</span>
              </Form.Label>
              <FormControl 
                value={this.state.form.lastName} 
                placeholder="Last Name" 
                onChange={(event) => {
                  const lastName = event.target.value
                  this.updateFormField({lastName})
                }} 
                style={{backgroundColor: '#FFFFFF', borderRadius: '20px', border: `1px solid ${!this.state.form.description || this.isDescriptionValid() ? '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`, boxSizing: 'border-box', fontSize: '12px', lineHeight: process.env.REACT_APP_MID_LINE_HEIGHT}} />
            </Form.Group>
          </Col>

          <Col xs={12} md={4}>
            <Form.Group controlId="create-role-role-description">
              <Form.Label 
                style={{color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>
                Email: <span className="required-field-tick">*</span>
              </Form.Label>
              <FormControl 
                value={this.state.form.email} 
                placeholder="Email" 
                onChange={(event) => {
                  const email = event.target.value
                  this.updateFormField({email})
                }} 
                style={{backgroundColor: '#FFFFFF', borderRadius: '20px', border: `1px solid ${!this.state.form.description || this.isDescriptionValid() ? '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`, boxSizing: 'border-box', fontSize: '12px', lineHeight: process.env.REACT_APP_MID_LINE_HEIGHT}} 
              />
            </Form.Group>
          </Col>
        </Row>

        <Row style={{margin: 0, padding: 0}}>
          <Col xs={12} md={4}>
            <Form.Group controlId="create-role-role-description">
              <Form.Label 
                style={{color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>
                Phone: <span className="required-field-tick">*</span>
              </Form.Label>

              <ReactPhoneInput 
                buttonStyle={{borderTopLeftRadius: process.env.REACT_APP_BORDER_STYLE === 'square' ? '4px' : '20px', borderBottomLeftRadius: process.env.REACT_APP_BORDER_STYLE === 'square' ? '4px' : '20px'}}
                containerStyle={{margin: 0, padding: 0, width: '100%'}}
                defaultCountry={'ng'}
                disableCountryCode={true}
                inputStyle={{backgroundColor: '#FFFFFF', borderRadius: process.env.REACT_APP_BORDER_STYLE === 'square' ? '4px' : '20px', border: `1px solid ${!this.state.form.phone || this.isPhoneValid() ? '#DFE2E6' : process.env.REACT_APP_RED_COLOUR}`, boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px', width: '100%'}}
                value={this.state.form.phone || ''} 
                onlyCountries={['ng', 'ke', 'ug']}
                onBlur={() => this.setState({
                  fieldsFocused: [...this.state.fieldsFocused, 'phone']
                })}
                onChange={(value, data) => {
                  this.updateFormField({phone: value})
                }}
                placeholder="08012345678" />
            </Form.Group>
          </Col>

          <Col xs={12} md={4}>
            <Form.Group controlId="edit-user" className="form-group">
              <FormLabel style={{color: '#3A3B3B', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>Role: <span className="required-field-tick">*</span></FormLabel>
              <FormControl 
                onBlur={() => this.setState({
                  fieldsFocused: [...this.state.fieldsFocused, 'howYouHeardAboutUs']
                })}
                onChange={(event) => {
                  const howYouHeardAboutUs = event.target.value
                  this.updateFormField({howYouHeardAboutUs})
                }} style={{backgroundColor: '#FFFFFF', borderRadius: '20px', border: '1px solid #DFE2E6', boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px', width: '100%'}} as="select">
                <option value={"0"}>Select an option</option>
                {this.state.roles.map(value => <option selected={value.name === this.state.form.role} value={value.id}>{value.name}</option>)}
              </FormControl>
            </Form.Group>
          </Col>
        </Row>
        
        <Row style={{margin: 0, padding: 0}}>
          <Col xs={12} md={12} style={{margin: 10}}>
            <Button disabled={this.props.isLoading || !this.state.formIsValid} style={{marginTop: '10pt'}} onClick={this.editRole}>{this.props.isLoading ? 'Loading...' : 'SAVE'}</Button>
          </Col>
        </Row>
      </form>
    </section>
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.tunnel.isLoading,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dismissErrorMessage: () => dispatch(dismissErrorMessage()),
    dismissSuccessMessage: () => dispatch(dismissSuccessMessage()),
    showErrorMessage: message => dispatch(showErrorMessage(message)),
    showSuccessMessage: message => dispatch(showSuccessMessage(message)),
    updateLoading: (isLoading, percentage=null) => dispatch(updateLoading({isLoading, percentage}))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewAndEditUserScene);
