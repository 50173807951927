import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import SelectInput from "../../../component/select";
import InputText from "../../../component/input";
import { Spacer } from "../../components/extras";
import PrimaryButton from "../../../component/button";
import { useHistory } from "react-router-dom";
import Text from "../../../../../../../components/text";
import { convertNgkToNgn } from "../../../../../../../utils/converters/currencies";
import { formatAmount } from "../../../../../../../utils/formatters";
import { userManagementServiceClient } from "../../../../../../../App";
import {
  ERROR_STATUS,
  SUCCESS_STATUS,
} from "../../../../../../../constants/api";

function getPosImage(link) {
  return link && `${process.env.REACT_APP_POS_IMAGE}` + "/" + link.slice(6);
}
const SelectPos = ({
  model,
  error,
  handleInputChange,
  value,
  setValue,
  requestDetails,
  handleSubmit,
  isFormValid,
  isLoading,
}) => {
  const history = useHistory();
  const [fee, setFee] = useState(0);

  const totalAmountIn =
    convertNgkToNgn(value?.price) +
    convertNgkToNgn(parseInt(fee)) * parseInt(requestDetails.quantity);

  const limit = ["10m Monthly Transaction", "Must be used 5 times a week"];

  const getDeploymentFee = async () => {
    const { status, response } =
      await userManagementServiceClient.getDeploymentFee();
    if (status === ERROR_STATUS) {
    } else if (status === SUCCESS_STATUS) {
      setFee(response.data);
    }
  };

  useEffect(() => {
    if (requestDetails.requestType === "free") {
      getDeploymentFee();
    }
  }, [requestDetails.requestType]);

  return (
    <>
      <p
        style={{
          fontSize: "14px",
          fontWeight: 700,
        }}
      >
        Request Type
      </p>

      <Form.Check
        label="I am paying for this POS"
        name="requestType"
        value="paid"
        defaultChecked="paid"
        onChange={handleInputChange}
        style={{
          fontSize: "14px",
        }}
        type={"radio"}
      />
      <Spacer height={"10px"} />
      <Form.Check
        label="I want a free POS"
        name="requestType"
        value="free"
        onChange={handleInputChange}
        style={{
          fontSize: "14px",
        }}
        type={"radio"}
      />

      {requestDetails.requestType === "free" && (
        <div
          style={{
            color: "grey",
            border: "2px solid  #E1E6ED",
            borderRadius: "5px",
            backgroundColor: "#F9FBFC",
            padding: "10px",
            marginTop: "10px",
          }}
        >
          {limit?.map((text) => {
            return (
              <div
                style={{
                  display: "flex",
                  fontSize: "14px",

                  alignItems: "center",
                  gap: "2px",
                }}
              >
                <Form.Check type={"checkbox"} checked id={`default-checkbox`} />
                <span>{text}</span>
              </div>
            );
          })}
        </div>
      )}
      <Spacer height={"20px"} />
      <Select
        name="colors"
        options={model.map((model, i) => ({
          label: model.terminalModel,
          value: model.terminalModel,
          fee: model.fee,
          price: model.price,
          terminalType: model.terminalType,
          terminalImage: getPosImage(model.terminalImage),
          terminalDescription: model.terminalDescription,
        }))}
        onChange={(event) => {
          setValue(event);
        }}
        className="basic-multi-select"
        classNamePrefix="select"
      />
      <Text style={{ color: "tomato" }}>{error}</Text>
      <Spacer height={"20px"} />
      {value && (
        <div
          style={{
            display: "flex",
            gap: "10px",
          }}
        >
          <div style={{ width: "62%" }}>
            <InputText
              label="Terminal Type"
              value={value?.terminalType}
              disabled={true}
            />
          </div>
          <div style={{ flexGrow: 1 }}>
            <SelectInput
              placeholder=""
              name="quantity"
              value={requestDetails.quantity}
              onChange={handleInputChange}
              option={[1, 2, 3, 4, 5, 6, 7, 8]}
              label="Quantity to Deliver"
            />
          </div>
        </div>
      )}
      <Spacer height={"5px"} />
      <InputText
        name="address"
        value={requestDetails.address}
        onChange={handleInputChange}
        label="Shipping Address"
        placeholder="Block 95, Charity Estate, Lagos"
      />
      <Spacer height={"5px"} />
      {fee !== 0 && requestDetails.requestType === "free" && (
        <p style={{ color: "tomato", fontSize: "14px", margin: 0 }}>
          Deployment fee of{" "}
          <span style={{ fontWeight: "700" }}>{`₦${formatAmount(
            convertNgkToNgn(fee)
          )}`}</span>{" "}
          applies.
        </p>
      )}

      <div
        style={{
          background: "#EAECF0",
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        <p
          style={{
            fontSize: "13px",
            margin: 0,
          }}
        >
          Total POS cost
        </p>
        <p
          style={{
            fontWeight: 600,
            margin: 0,
          }}
        >
          {totalAmountIn
            ? `₦${formatAmount(totalAmountIn)}`
            : `₦${formatAmount(0)}`}
        </p>
      </div>
      <Spacer height={"20px"} />
      <PrimaryButton
        disabled={!isFormValid() || isLoading}
        onClick={handleSubmit}
        margin="0"
      >
        {isLoading ? "Processing..." : "Proceed to Payment"}
      </PrimaryButton>
      <Spacer height={"10px"} />
      <PrimaryButton
        onClick={() => history.goBack()}
        backgroundColor={"transparent"}
        border={"1px solid #EAECF0"}
        margin="0"
        color="black"
      >
        Close
      </PrimaryButton>
    </>
  );
};

export default SelectPos;
