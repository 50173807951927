import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { savePDF } from '@progress/kendo-react-pdf';
import { transactionExtraSerializer } from '../../serializers/resources/transaction-extras';
import { convertNgkToNgn } from '../../utils/converters/currencies';
import {
  basicAddition,
  formatAmount,
  formatDate,
  handleCustomerIdFormat,
  handleTransactionStatus,
} from '../../utils/formatters';
import Button from '../button';
import H1 from '../h1';
import Text from '../text';

import { DISTRIBUTE, TRANSFER_CASH_IN } from '../../constants';
import './index.scss';

const refTrans = React.createRef();
const ikedcCodes = process.env.REACT_APP_DISTRIBUTE_PAYMENT_IKEDC_CODES;
// const ikedcCodes = "051727101 051722602 04393801 04393701";

const today = new Date();

const printPage = () => {
  let mywindow = window.open('', 'PRINT', 'height=400,width=600');

  mywindow.document.write(`<html><head><title> </title>`);
  mywindow.document.write(`</head><body >`);
  mywindow.document.write(document.getElementById('print-receipt').innerHTML);
  mywindow.document.write(`</body></html>`);

  mywindow.document.close();
  mywindow.focus();

  mywindow.print();
  //mywindow.close();

  return true;
};

const savePage = (name) => {
  savePDF(refTrans.current, {
    paperSize: 'A4',
    fileName: `${name}-receipt.pdf`,
    margin: 20,
    author: 'Interswitch',
  });
};

function processPins(pins, paymentCode) {
  if (ikedcCodes.includes(paymentCode)) {
    if (pins?.length > 25) {
      let splitPins = pins.split(';');
      let fields = {
        'Activation Token 1:': splitPins[0],
        'Activation Token 2:': splitPins[1],
        'Credit Token:': splitPins[2],
      };

      return fields;
    } else {
      return { Token: pins };
    }
  } else {
    return { PIN: pins };
  }
}

function PayBillOrBuyAirtimeReceipt(props) {
  const { pinExtras, extraInfoList } = props.payments ? props.payments : [];
  const extraData = pinExtras ? pinExtras.extras : extraInfoList;

  let orderedData = null;
  //formData.paymentCode
  if (
    ikedcCodes?.includes(props?.form?.billerOption?.paymentCode) &&
    extraData
  ) {
    try {
      orderedData = {};
      let processedExtra = extraData && transactionExtraSerializer(extraData);

      if (Object.keys(processedExtra).length > 0) {
        Object.keys(processedExtra).map(
          (value) => (orderedData[value] = processedExtra[value])
        );

        // if (processedExtra["Vat"]) orderedData["Vat"] = processedExtra["Vat"];
        // if (processedExtra["Deduction"])
        //   orderedData["Deduction"] = processedExtra["Deduction"];
        // if (processedExtra["Credit Val"])
        //   orderedData["Credit Val"] = processedExtra["Credit Val"];

        if (props.payments?.billsPaymentRequest?.customerType)
          orderedData['Tariff Band'] =
            props.payments.billsPaymentRequest.customerType;
        // if (processedExtra["Tariff"])
        //   orderedData["Tariff"] = processedExtra["Tariff"];
        // if (processedExtra["Units Purchased"])
        //   orderedData["Units Purchased"] = processedExtra["Units Purchased"];
        // if (processedExtra["Receipt No"])
        //   orderedData["Receipt No"] = processedExtra["Receipt No"];
        // if (processedExtra["Client ID"])
        //   orderedData["Client ID"] = processedExtra["Client ID"];
        // if (processedExtra["Terminal ID"])
        //   orderedData["Terminal ID"] = processedExtra["Terminal ID"];
      }
    } catch (e) {}
  }
  return (
    <>
      {props.category === 'sell-airtime-and-data' && (
        <Row style={{ marginTop: '15px' }}>
          <Col md={12} style={{ display: 'flex' }}>
            <Text
              style={{
                paddingLeft: 20,
                minWidth: 200,
                fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                fontWeight: 'bold',
              }}
            >
              Amount:{' '}
            </Text>
            <Text
              style={{
                fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                fontWeight: 'bold',
              }}
            >
              {props.payment
                ? `NGN${formatAmount(
                    convertNgkToNgn(props.payment.rechargeRequest.amount)
                  )}`
                : ''}
            </Text>
          </Col>
        </Row>
      )}

      <hr />

      {props.category === 'pay-a-bill' && (
        <Row
          hidden={
            props.form.billerOption === null ||
            props.form.billerOption.customerIdField === 'Customer Name'
          }
          style={{ marginTop: '15px' }}
        >
          <Col md={12} style={{ display: 'flex' }}>
            <Text
              style={{
                paddingLeft: 20,
                minWidth: 200,
                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              }}
            >
              Customer Name
            </Text>
            <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
              {props.payment
                ? props.payment.billsPaymentRequest.customerName
                : ''}
            </Text>
          </Col>
        </Row>
      )}

      {props.category === 'pay-a-bill' && (
        <Row
          hidden={props.form.billerOption === null}
          style={{ marginTop: '15px' }}
        >
          <Col md={12} style={{ display: 'flex' }}>
            <Text
              style={{
                paddingLeft: 20,
                minWidth: 200,
                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              }}
            >
              Phone Number
            </Text>
            <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
              {props.payment
                ? handleCustomerIdFormat(
                    props.payment.billsPaymentRequest.customerMsisdn
                  )
                : ''}
            </Text>
          </Col>
        </Row>
      )}

      {props.category === 'sell-airtime-and-data' && (
        <Row style={{ marginTop: '15px' }}>
          <Col md={12} style={{ display: 'flex' }}>
            <Text
              style={{
                paddingLeft: 20,
                minWidth: 200,
                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                fontWeight: 'bold',
              }}
            >
              Phone Number:{' '}
            </Text>
            <Text
              style={{
                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                fontWeight: 'bold',
              }}
            >
              {props.payment
                ? handleCustomerIdFormat(
                    props.payment.rechargeRequest.customerMsisdn
                  )
                : ''}
            </Text>
          </Col>
        </Row>
      )}

      {props.category === 'pay-a-bill' && (
        <Row
          hidden={
            props.payment && props.payment.billsPaymentRequest.address === ''
          }
          style={{ marginTop: '15px' }}
        >
          <Col md={12} style={{ display: 'flex' }}>
            <Text
              style={{
                paddingLeft: 20,
                minWidth: 200,
                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              }}
            >
              Address:{' '}
            </Text>
          { process.env.REACT_APP_SHOW_ADDRESS_FIELD_ODIRS ? <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
              {props.form.address? props.form.address:
              <>
              {props.payment ? props.payment.billsPaymentRequest.address : ''}
              </>
              }
            </Text>
            :
            <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
              {props.payment ? props.payment.billsPaymentRequest.address : ''}
            </Text>
          }
          </Col>
        </Row>
      )}

      {props.category === 'pay-a-bill' || props.category === 'cash-in' ? (
        <Row
          hidden={props.form.billerOption === null}
          style={{ marginTop: '15px' }}
        >
          <Col md={12} style={{ display: 'flex' }}>
            <Text
              style={{
                paddingLeft: 20,
                minWidth: 200,
                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              }}
            >
              {props.form.billerOption
                ? props.form.billerOption.customerIdField
                : ''}
            </Text>
            <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
              {props.payment
                ? props.payment.billsPaymentRequest.customerId
                : ''}
            </Text>
          </Col>
        </Row>
      ) : (
        ''
      )}

      {props.category === 'pay-a-bill' || props.category === 'cash-in' ? (
        <Row
          hidden={
            !(
              props.payment &&
              props.payment.billsPaymentRequest.parentCustomerId &&
              props.payment.billsPaymentRequest.parentCustomerId !==
                props.payment.billsPaymentRequest.customerId
            )
          }
          style={{ marginTop: '15px' }}
        >
          <Col md={12} style={{ display: 'flex' }}>
            <Text
              style={{
                paddingLeft: 20,
                minWidth: 200,
                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              }}
            >
              Sub Account:
            </Text>
            <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
              {props.payment
                ? props.payment.billsPaymentRequest.parentCustomerId
                : ''}
            </Text>
          </Col>
        </Row>
      ) : (
        ''
      )}

      <hr />

      {props.pin &&
        Object.entries(
          processPins(props.pin, props.form.billerOption?.paymentCode)
        ).map(([key, value]) => (
          <Row style={{ marginTop: '15px' }}>
            <Col md={12} style={{ display: 'flex' }}>
              <Text
                style={{
                  paddingLeft: 20,
                  minWidth: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                {key}{' '}
              </Text>
              <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
                {value}
              </Text>
            </Col>
          </Row>
        ))}

      {props.category === 'sell-airtime-and-data' && (
        <Row style={{ marginTop: '15px' }}>
          <Col md={12} style={{ display: 'flex' }}>
            <Text
              style={{
                paddingLeft: 20,
                minWidth: 200,
                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              }}
            >
              Mobile Network:{' '}
            </Text>
            <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
              {props.payment ? props.payment.rechargeRequest.narration : ''}
            </Text>
          </Col>
        </Row>
      )}

      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            }}
          >
            Biller Name:{' '}
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {props.service ? props.service.name : ''}
          </Text>
        </Col>
      </Row>

      {props.form.billerOption && props.form.billerOption.name ? (
        <Row
          hidden={props.form.billerOption === null}
          style={{ marginTop: '15px' }}
        >
          <Col md={12} style={{ display: 'flex' }}>
            <Text
              style={{
                paddingLeft: 20,
                minWidth: 200,
                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              }}
            >
              Service Option
            </Text>
            <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
              {props.form.billerOption ? props.form.billerOption.name : ''}
            </Text>
          </Col>
        </Row>
      ) : (
        ''
      )}

      {props.category === 'sell-airtime-and-data' && (
        <Row style={{ marginTop: '15px' }}>
          <Col md={12} style={{ display: 'flex' }}>
            <Text
              style={{
                paddingLeft: 20,
                minWidth: 200,
                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              }}
            >
              Service Option{' '}
            </Text>
            <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
              {props.payment ? props.payment.rechargeRequest.narration : ''}
            </Text>
          </Col>
        </Row>
      )}

      <hr />

      {props.category === 'pay-a-bill' || props.category === 'cash-in' ? (
        <Row style={{ marginTop: '15px' }}>
          <Col md={12} style={{ display: 'flex' }}>
            <Text
              style={{
                paddingLeft: 20,
                minWidth: 200,
                fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                fontWeight: 'bold',
              }}
            >
              Amount:{' '}
            </Text>
            <Text
              style={{
                fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                fontWeight: 'bold',
              }}
            >
              {props.payment
                ? `NGN${formatAmount(
                    convertNgkToNgn(props.payment.billsPaymentRequest.amount)
                  )}`
                : ''}
            </Text>
          </Col>
        </Row>
      ) : (
        ''
      )}

      {orderedData &&
        Object.entries(orderedData).map(([key, value]) => (
          <Row style={{ marginTop: '15px' }}>
            <Col md={12} style={{ display: 'flex' }}>
              <Text
                style={{
                  paddingLeft: 20,
                  minWidth: 200,
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                }}
              >
                {key}{' '}
              </Text>
              <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
                {value}
              </Text>
            </Col>
          </Row>
        ))}
      {Boolean(extraData) &&
        !orderedData &&
        Object.entries(transactionExtraSerializer(extraData)).map(
          ([key, value]) => (
            <Row style={{ marginTop: '15px' }}>
              <Col md={12} style={{ display: 'flex' }}>
                <Text
                  style={{
                    paddingLeft: 20,
                    minWidth: 200,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  }}
                >
                  {key}{' '}
                </Text>
                <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
                  {value}
                </Text>
              </Col>
            </Row>
          )
        )}
      {Boolean(extraData) && <hr />}

      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            }}
          >
            Transaction Type:{' '}
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {props.payment ? props.payment.transactionType : ''}
          </Text>
        </Col>
      </Row>

      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            }}
          >
            Transaction Reference:{' '}
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {props.payment ? props.payment.transactionReference : ''}
          </Text>
        </Col>
      </Row>

      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            }}
          >
            Transaction Date:{' '}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              textAlign: 'right',
            }}
          >
            {formatDate(today)}
          </Text>
        </Col>
      </Row>

      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            }}
          >
            Transaction Status:{' '}
          </Text>
          <Text
            style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
            className={handleTransactionStatus(props.code)}
          >
            {props.payment ? handleTransactionStatus(props.code) : ''}
          </Text>
        </Col>
      </Row>

      <hr />

      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text style={{ paddingLeft: 20, minWidth: 200, fontSize: '15px' }}>
            Business Name:{' '}
          </Text>
          <Text style={{ fontSize: '15px', textTransform: 'capitalize' }}>
            {props.currentUser && `${props.currentUser.domainName}`}
          </Text>
        </Col>
      </Row>
    </>
  );
}

function TransferReceipt(props) {
  const { pinExtras, extraInfoList } = props.payments ? props.payments : [];
  const extraData = pinExtras ? pinExtras.extras : extraInfoList;
  return (
    <>
      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            Amount:{' '}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            {props.payment
              ? `NGN${formatAmount(
                  convertNgkToNgn(
                    props.payment?.transferRequest
                      ? props.payment.transferRequest.amount
                      : props.payment?.nipTransferRequest?.amount
                  )
                )}`
              : ''}
          </Text>
        </Col>
      </Row>

      <hr />

      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            }}
          >
            Beneficiary Name:{' '}
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {props.form ? props.form.beneficiaryName : ''}
          </Text>
        </Col>
      </Row>

      {props.form && props.form.beneficiaryPhone && (
        <Row style={{ marginTop: '15px' }}>
          <Col md={12} style={{ display: 'flex' }}>
            <Text
              style={{
                paddingLeft: 20,
                minWidth: 200,
                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              }}
            >
              Beneficiary Number:{' '}
            </Text>
            <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
              {props.form ? props.form.beneficiaryPhone : ''}
            </Text>
          </Col>
        </Row>
      )}

      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            }}
          >
            Bank:{' '}
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {props.form.bank
              ? props.form.bank.name || props.form.bank.label
              : ''}
          </Text>
        </Col>
      </Row>

      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            }}
          >
            Account Number:{' '}
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {props.form ? props.form.accountNumber : ''}
          </Text>
        </Col>
      </Row>

      {/* <Row style={{ marginTop: "15px" }}>
        <Col md={12} style={{ display: "flex" }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            }}
          >
            Sender Name:{" "}
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {props.form ? props.form.senderName : ""}
          </Text>
        </Col>
      </Row> */}

      {/* <Row style={{ marginTop: "15px" }}>
        <Col md={12} style={{ display: "flex" }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            }}
          >
            Sender Number:{" "}
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {props.form ? props.form.senderPhone : ""}
          </Text>
        </Col>
      </Row> */}

      <hr />
      {Boolean(extraData) &&
        Object.entries(transactionExtraSerializer(extraData)).map(
          ([key, value]) => (
            <Row style={{ marginTop: '15px' }}>
              <Col md={12} style={{ display: 'flex' }}>
                <Text
                  style={{
                    paddingLeft: 20,
                    minWidth: 200,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  }}
                >
                  {key}:{' '}
                </Text>
                <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
                  {value}
                </Text>
              </Col>
            </Row>
          )
        )}
      {Boolean(extraData) && <hr />}

      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            }}
          >
            Transaction Type:{' '}
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {props.payment
              ? props.payment.transactionType === 'NIP'
                ? `${props.payment.transactionType} Transfer`
                : props.payment.transactionType === TRANSFER_CASH_IN
                ? `CASH IN`
                : props.payment.transactionType
              : ''}
          </Text>
        </Col>
      </Row>

      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            }}
          >
            Transaction Reference:{' '}
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {props.payment ? props.payment.transactionReference : ''}
          </Text>
        </Col>
      </Row>

      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            }}
          >
            Transaction Date:{' '}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              textAlign: 'right',
            }}
          >
            {formatDate(today)}
          </Text>
        </Col>
      </Row>

      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            }}
          >
            Transaction Status:{' '}
          </Text>
          <Text
            style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
            className={handleTransactionStatus(props.code)}
          >
            {props.payment ? handleTransactionStatus(props.code) : ''}
          </Text>
        </Col>
      </Row>

      <hr />

      {props.form && (props.form.remark || props.form.narration) && (
        <Row style={{ marginTop: '15px' }}>
          <Col md={12} style={{ display: 'flex' }}>
            <Text
              style={{
                paddingLeft: 20,
                minWidth: 200,
                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              }}
            >
              Remark:{' '}
            </Text>
            <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
              {props.form ? props.form.remark || props.form.narration : ''}
            </Text>
          </Col>
        </Row>
      )}

      <hr />

      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text style={{ paddingLeft: 20, minWidth: 200, fontSize: '15px' }}>
            Business Name:{' '}
          </Text>
          <Text style={{ fontSize: '15px', textTransform: 'capitalize' }}>
            {props.currentUser && `${props.currentUser.domainName}`}
          </Text>
        </Col>
      </Row>
    </>
  );
}

function WalletToWalletReceipt(props) {
  const { pinExtras, extraInfoList } = props.payments ? props.payments : [];
  const extraData = pinExtras ? pinExtras.extras : extraInfoList;
  return (
    <>
      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            Amount:{' '}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            {props.payment
              ? `NGN${formatAmount(convertNgkToNgn(props.payment.amount))}`
              : ''}
          </Text>
        </Col>
      </Row>
      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            Fee:{' '}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            {props.payment
              ? `NGN${formatAmount(convertNgkToNgn(props.payment.fee))}`
              : ''}
          </Text>
        </Col>
      </Row>

      <hr />

      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            }}
          >
            Beneficiary's Name:{' '}
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {props.payment
              ? `${props.payment.beneficiaryFirstName} ${props.payment.beneficiaryLastName}`
              : ''}
          </Text>
        </Col>
      </Row>

      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            }}
          >
            Agent's Number:{' '}
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {props.payment ? props.payment.customerMsisdn : ''}
          </Text>
        </Col>
      </Row>

      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            }}
          >
            Service Option{' '}
          </Text>
          <Text
            style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
          >{`Transfer To Agent`}</Text>
        </Col>
      </Row>

      <hr />
      {Boolean(extraData) &&
        Object.entries(transactionExtraSerializer(extraData)).map(
          ([key, value]) => (
            <Row style={{ marginTop: '15px' }}>
              <Col md={12} style={{ display: 'flex' }}>
                <Text
                  style={{
                    paddingLeft: 20,
                    minWidth: 200,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  }}
                >
                  {key}:{' '}
                </Text>
                <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
                  {value}
                </Text>
              </Col>
            </Row>
          )
        )}
      {Boolean(extraData) && <hr />}

      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            }}
          >
            Transaction Type:{' '}
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {props.payment ? props.payment.narration : ''}
          </Text>
        </Col>
      </Row>

      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            }}
          >
            Transaction Reference:{' '}
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {props.payment ? props.payment.transactionRef : ''}
          </Text>
        </Col>
      </Row>

      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            }}
          >
            Transaction Date:{' '}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              textAlign: 'right',
            }}
          >
            {formatDate(today)}
          </Text>
        </Col>
      </Row>

      <Row style={{ marginTop: '15px' }} hidden={!props.code}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            }}
          >
            Transaction Status:{' '}
          </Text>
          <Text
            style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
            className={handleTransactionStatus(props.code)}
          >
            {props.payment ? handleTransactionStatus(props.code) : ''}
          </Text>
        </Col>
      </Row>

      <hr />

      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text style={{ paddingLeft: 20, minWidth: 200, fontSize: '15px' }}>
            Business Name:{' '}
          </Text>
          <Text style={{ fontSize: '15px', textTransform: 'capitalize' }}>
            {props.currentUser && `${props.currentUser.domainName}`}
          </Text>
        </Col>
      </Row>
    </>
  );
}

function DistributeReceipt(props) {
  const { pinExtras, extraInfoList } = props.payments ? props.payments : [];
  const extraData = pinExtras ? pinExtras.extras : extraInfoList;
  return (
    <>
      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            Amount:{' '}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            {props.payment
              ? `NGN${formatAmount(
                  convertNgkToNgn(
                    basicAddition(props.payment.totalAmount, props.payment.fee)
                  )
                )}`
              : ''}
          </Text>
        </Col>
      </Row>

      <hr />

      {props.payment && props.payment.w2WRequestList && (
        <Row style={{ marginTop: '15px' }}>
          <Col md={12} style={{ display: 'flex' }}>
            <Text
              style={{
                paddingLeft: 20,
                minWidth: 200,
                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              }}
            >{`Beneficiary Name`}</Text>
            <Text
              style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
            >{`Multiple Recipients (${props.payment.w2WRequestList.length})`}</Text>
            {/* <Text style={{fontSize: process.env.REACT_APP_MID_FONT_SIZE}}>{props.payment ? `NGN${formatAmount(convertNgkToNgn(basicAddition(props.payment.totalAmount, props.payment.fee)))}` : ''}</Text> */}
          </Col>
        </Row>
      )}

      {props.payment && props.payment.w2WRequestList && (
        <Row style={{ marginTop: '15px' }}>
          <Col md={12} style={{ display: 'flex' }}>
            <Text
              style={{
                paddingLeft: 20,
                minWidth: 200,
                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              }}
            >{`Phone Number`}</Text>
            <Text
              style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
            >{`Multiple Recipients (${props.payment.w2WRequestList.length})`}</Text>
          </Col>
        </Row>
      )}

      <hr />
      {Boolean(extraData) &&
        Object.entries(transactionExtraSerializer(extraData)).map(
          ([key, value]) => (
            <Row style={{ marginTop: '15px' }}>
              <Col md={12} style={{ display: 'flex' }}>
                <Text
                  style={{
                    paddingLeft: 20,
                    minWidth: 200,
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  }}
                >
                  {key}:{' '}
                </Text>
                <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
                  {value}
                </Text>
              </Col>
            </Row>
          )
        )}
      {Boolean(extraData) && <hr />}

      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            }}
          >
            Transaction Type:{' '}
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {props.payment ? props.payment.narration : ''}
          </Text>
        </Col>
      </Row>

      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            }}
          >
            Transaction Reference:{' '}
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {props.payment ? props.payment.transactionRef : ''}
          </Text>
        </Col>
      </Row>

      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            }}
          >
            Transaction Date:{' '}
          </Text>
          <Text style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}>
            {formatDate(today)}
          </Text>
        </Col>
      </Row>

      {/* TODO: sync with backend */}
      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            }}
          >
            Transaction Status:{' '}
          </Text>
          <Text
            style={{ fontSize: process.env.REACT_APP_MID_FONT_SIZE }}
            className={handleTransactionStatus(props.code)}
          >
            {props.payment ? handleTransactionStatus(props.code) : ''}
          </Text>
        </Col>
      </Row>

      <hr />

      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text style={{ paddingLeft: 20, minWidth: 200, fontSize: '15px' }}>
            Business Name:{' '}
          </Text>
          <Text style={{ fontSize: '15px', textTransform: 'capitalize' }}>
            {props.currentUser && `${props.currentUser.domainName}`}
          </Text>
        </Col>
      </Row>
    </>
  );
}

function CardlessCashoutReceipt(props) {
  return (
    <>
      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            Phone Number:{' '}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            {props.form.senderPhone}{' '}
          </Text>
        </Col>
      </Row>

      <hr />
      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            Bank Name:{' '}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            {props.form.bank
              ? props.form.bank.name || props.form.bank.label
              : ''}
          </Text>
        </Col>
      </Row>
      <hr />
      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            Transaction Date:{' '}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            {formatDate(today)}{' '}
          </Text>
        </Col>
      </Row>
      <hr />
      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            Transaction Type:{' '}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            Cash Out
          </Text>
        </Col>
      </Row>
      <hr />
      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            Transaction Reference:{' '}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            {props.payment && props.payment.transactionRef}
          </Text>
        </Col>
      </Row>
      <hr />
      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            Transaction Status:{' '}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
            className={handleTransactionStatus(props.code)}
          >
            {handleTransactionStatus(props.code)}
          </Text>
        </Col>
      </Row>
      <hr />
      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            Amount:{' '}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            {props.payment
              ? `NGN${formatAmount(convertNgkToNgn(props.payment.amount))}`
              : ''}
          </Text>
        </Col>
      </Row>
      <hr />
      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            Business Name:{' '}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            {props.currentUser && `${props.currentUser.domainName}`}{' '}
          </Text>
        </Col>
      </Row>
      <hr />
    </>
  );
}

function ReceiptPage(props) {
  return (
    <div
      className={`print-receipt ${
        props.category === 'sell-airtime-and-data' ||
        props.category === 'pay-a-bill' ||
        props.category === 'cash-in'
          ? 'airtime-bill-height'
          : 'send-money-height'
      } ${props.deviceIsMobile ? '.mobile-width' : 'pc-tab-width'}`}
    >
      <div className='print-receipt' id='print-receipt' ref={refTrans}>
        <div>
          <img
            alt=''
            style={{
              top: '20px',
              width: '40px',
            }}
            src={props.service ? props.service.imageUrl : ''}
          />

          <img
            alt=''
            style={{
              top: '40px',
              left: '500px',
              width: '100px',
              float: 'right',
            }}
            src='https://i.ibb.co/Qbsjn5b/isw.png'
          />
          <div
            style={{
              color: process.env.REACT_APP_BLUE_COLOUR,
              paddingTop: '5px',
            }}
          >
            <h6>
              <b>Thank you for using Quickteller Paypoint!</b>
            </h6>
            <span>Here is your transaction receipt.</span>
            <span>See payment details below.</span>

            <p style={{ textAlign: 'right' }}>
              <Text
                style={{
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  color: process.env.REACT_APP_GREY_COLOUR,
                }}
              >
                ***ORIGINAL COPY***
              </Text>
            </p>
          </div>
          <H1 style={{ textAlign: 'center' }}>Transaction Receipt</H1>
          {props.category === 'sell-airtime-and-data' &&
            PayBillOrBuyAirtimeReceipt(props)}
          {props.category === 'send-money' &&
            props.service.id === 1 &&
            TransferReceipt(props)}
          {props.category === 'send-money' &&
            (props.service.id === 7 || props.service.id === 19) &&
            TransferReceipt(props)}
          {props.category === 'send-money' &&
            props.service.id === 4 &&
            TransferReceipt(props)}
          {props.category === 'send-money' &&
            props.service.id === 2 &&
            WalletToWalletReceipt(props)}
          {props.category === 'pay-a-bill' && PayBillOrBuyAirtimeReceipt(props)}
          {props.category === 'cash-in' && TransferReceipt(props)}
          {props.category === 'cardless-cashout' &&
            CardlessCashoutReceipt(props)}
          {/* {props.category === 'cash-in' && PayBillOrBuyAirtimeReceipt(props)} */}
          {props.category === DISTRIBUTE && DistributeReceipt(props)}
        </div>
      </div>

      <Row style={{ display: 'flex', alignItems: 'center', marginTop: '20pt' }}>
        <Col
          xs={12}
          md={4}
          style={{ marginBottom: `${props.deviceIsMobile && '5px'}` }}
        >
          <Button
            onClick={printPage}
            style={{
              width: '100%',
              marginBottom: '10px',
            }}
          >
            Print
          </Button>
        </Col>
        <Col
          xs={12}
          md={4}
          style={{ marginBottom: `${props.deviceIsMobile && '5px'}` }}
        >
          <Button
            onClick={() => savePage(props.category)}
            style={{
              width: '100%',
              marginBottom: '10px',
            }}
          >
            Save
          </Button>
        </Col>
        <Col xs={12} md={4}>
          <Button
            outline
            style={{
              width: '100%',
              padding: '9pt 37pt',
              marginBottom: '10px',
            }}
            onClick={props.onCancelProduct}
          >
            Dismiss
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default ReceiptPage;
