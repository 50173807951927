import React from "react";
import { expiredIcon } from "../../../../icons";
import HeaderSignup from "../../../../components/header-signup";
import H1 from "../../../../components/h1";
import MdSync from "react-ionicons/lib/MdSync";
import MdRefreshCircle from "react-ionicons/lib/MdRefreshCircle";
import Text from "../../../../components/text";

const headerStyle = {
  display: "flex",
  justifyContent: "flex-start",
  gap: "40px",
  margin: "20px",
};
export const ExpiredLinkScene = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          gap: "40px",
          margin: "20px",
        }}
      >
        <HeaderSignup />
      </div>
      <main className="aggregator-container">
        <div
          style={{
            height: "200px",
            position: "relative",
          }}
        >
          <div
            style={{
              margin: "0",
              position: "absolute",
              top: "50%",
              left: "30%",
              // right: "25%",
              // msTransform: 'translateY(-50%)',
              transform: "translateY(-50%)",
              justifyContent: "center",
            }}
          >
            {expiredIcon()}
          </div>
        </div>
        <H1>Link Expired</H1>
        <p
          style={{
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "20px",
            textAlign: "center",
            color: "#5F738C",
            marginTop: "10px",
          }}
        >
          This link is no longer valid. Please contact the agent to <br />
          resend link
        </p>
      </main>
    </>
  );
};
export const Loading = ({ isLoadingInformation }) => {
  return (
    <div className="attestationErrorContainer">
      <div style={headerStyle}>
        <HeaderSignup />
      </div>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <MdRefreshCircle
          rotate={isLoadingInformation}
          color={process.env.REACT_APP_BLUE_COLOUR}
          fontSize={"80px"}
        />
        <p
          style={{
            textAlign: "center",
            color: process.env.REACT_APP_BLUE_COLOUR,
          }}
        >
          Loading...
        </p>
      </div>
    </div>
  );
};
export const Error = ({ isLoadingInformation, fetchAgentInformation }) => {
  return (
    <div className="attestationErrorContainer">
      <div style={headerStyle}>
        <HeaderSignup />
      </div>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          gap: "10px",
          justifyContent: "center",
        }}
      >
        <Text blue bold bigger>
          Oops!
        </Text>
        <Text mid>
          An error occured while fetching attestation form, Please try again.
        </Text>
        <div
          style={{
            alignItems: "center",
            backgroundColor: process.env.REACT_APP_BLUE_COLOUR,

            color: "white",
            cursor: "pointer",
            display: "flex",
            fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            fontStyle: process.env.REACT_APP_MID_FONT_SIZE,
            fontWeight: "bold",
            height: "40px",
            justifyContent: "center",
            borderRadius: "50px",
            width: "130px",
          }}
          onClick={() => fetchAgentInformation()}
        >
          <MdSync
            rotate={isLoadingInformation}
            color={"white"}
            fontSize={"24px"}
            style={{ marginRight: "10px" }}
          />
          {isLoadingInformation ? "Loading..." : "RETRY"}
        </div>
      </div>
    </div>
  );
};
