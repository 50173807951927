export function getDateByType(type = null) {
  let fromDate = new Date();
  let toDate = new Date();
  fromDate.setHours(0, 0, 0, 0);
  toDate.setHours(23, 59, 59, 59);
  return type == 'startDate'
    ? fromDate
    : type == 'endDate'
    ? toDate
    : new Date();
}
