import React, { useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from '../../../../../../../components/button';
import H1 from '../../../../../../../components/h1';
import Text from '../../../../../../../components/text';
import { convertNgkToNgn } from '../../../../../../../utils/converters/currencies';
import {
  formatAmount,
  formatDate,
  handleTransactionStatus,
} from '../../../../../../../utils/formatters';
import { useFundUssdContext } from './context';

function Receipt(props, responseData, code) {
  let today = new Date();
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));

  return (
    <>
      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            Phone Number:{' '}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            {props.senderPhone}{' '}
          </Text>
        </Col>
      </Row>

      <hr />
      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            Bank Name:{' '}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            {props.bank ? props.bank.name || props.bank.label : ''}
          </Text>
        </Col>
      </Row>
      <hr />
      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            Transaction Date:{' '}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            {formatDate(today)}{' '}
          </Text>
        </Col>
      </Row>
      <hr />
      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            Transaction Type:{' '}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            Fund Via USSD
          </Text>
        </Col>
      </Row>
      <hr />
      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            Transaction Reference:{' '}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            {responseData && responseData.transactionRef}
          </Text>
        </Col>
      </Row>
      <hr />
      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            Transaction Status:{' '}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
            className={handleTransactionStatus(code)}
          >
            {handleTransactionStatus(code)}
          </Text>
        </Col>
      </Row>
      <hr />
      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            Amount:{' '}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            {responseData
              ? `NGN${formatAmount(convertNgkToNgn(responseData.amount))}`
              : ''}
          </Text>
        </Col>
      </Row>
      <hr />
      <Row style={{ marginTop: '15px' }}>
        <Col md={12} style={{ display: 'flex' }}>
          <Text
            style={{
              paddingLeft: 20,
              minWidth: 200,
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            Business Name:{' '}
          </Text>
          <Text
            style={{
              fontSize: process.env.REACT_APP_MID_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            {currentUser && `${currentUser.domainName}`}{' '}
          </Text>
        </Col>
      </Row>
      <hr />
    </>
  );
}

const ReceiptScreen = ({ deviceIsMobile, setScreen }) => {
  const refTrans = useRef();
  const { form, setForm, responseData, code } = useFundUssdContext();
  const printPage = () => {
    let mywindow = window.open('', 'PRINT', 'height=400,width=600');

    mywindow.document.write(`<html><head><title> </title>`);
    mywindow.document.write(`</head><body >`);
    mywindow.document.write(document.getElementById('print-receipt').innerHTML);
    mywindow.document.write(`</body></html>`);

    mywindow.document.close();
    mywindow.focus();

    mywindow.print();
    //mywindow.close();

    return true;
  };

  const onCancelProduct = () => {
    window.location.href = '/agent/dashboard';
  };

  return (
    <article className='fund-by-ussd-section'>
      <div
        className={`print-receipt airtime-bill-height  ${
          deviceIsMobile ? '.mobile-width' : 'pc-tab-width'
        }`}
      >
        <div className='print-receipt' id='print-receipt' ref={refTrans}>
          <div>
            <img
              alt=''
              style={{
                top: '20px',
                width: '40px',
              }}
              src='https://i.ibb.co/Qbsjn5b/isw.png'
              // src={service ? service.imageUrl : ''}
            />

            <img
              alt=''
              style={{
                top: '40px',
                left: '500px',
                width: '100px',
                float: 'right',
              }}
              src='https://i.ibb.co/Qbsjn5b/isw.png'
            />
            <div
              style={{
                color: process.env.REACT_APP_BLUE_COLOUR,
                paddingTop: '5px',
              }}
            >
              <h6>
                <b>Thank you for using Quickteller Paypoint!</b>
              </h6>
              <span>Here is your transaction receipt.</span>
              <span>See payment details below.</span>

              <p style={{ textAlign: 'right' }}>
                <Text
                  style={{
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    color: process.env.REACT_APP_GREY_COLOUR,
                  }}
                >
                  ***ORIGINAL COPY***
                </Text>
              </p>
            </div>
            <H1 style={{ textAlign: 'center' }}>Transaction Receipt</H1>

            {Receipt(form, responseData, code)}
          </div>
        </div>

        <Row
          style={{ display: 'flex', alignItems: 'center', marginTop: '20pt' }}
        >
          <Col
            xs={12}
            md={4}
            style={{ marginBottom: `${deviceIsMobile && '5px'}` }}
          >
            <Button
              onClick={printPage}
              style={{
                width: '100%',
                marginBottom: '10px',
              }}
            >
              Print
            </Button>
          </Col>

          <Col xs={12} md={4}>
            <Button
              outline
              style={{
                width: '100%',
                padding: '9pt 37pt',
                marginBottom: '10px',
              }}
              onClick={onCancelProduct}
            >
              Dismiss
            </Button>
          </Col>
        </Row>
      </div>
    </article>
  );
};

export default ReceiptScreen;
