import React from 'react';
import { Redirect } from 'react-router-dom';
import qs from 'querystring';
import { Facebook } from '../../services/api/resources/social';
// import { ERROR_STATUS } from '../../constants/api';
// import { FACEBOOK_LOWERCASE } from '../../constants';
// import { saveAuthToken } from '../../utils/auth';
import FacebookSerializer from '../../serializers/resources/facebook';
import Users from '../../services/api/resources/users';

export class FacebookLoginCallback extends React.Component {

  state = {

  };

  facebook = new Facebook();
  facebookSerializer = new FacebookSerializer();
  users = new Users();

  componentDidMount() {
    const code = qs.parse(this.props.location.search)['?code'];
    if (code) {
      this.facebook.getFacebookAccessToken(code).then(
        response => this.responseFacebook(response.data)
      );
    }
  }

  responseFacebook = async (response) => {
    const { access_token, expires_in } = response;

    const getFacebookUserResponse = await this.facebook.getFacebookUser(access_token);
    const getFacebookUserResponseData = getFacebookUserResponse.data;

    const compiledResponse = {
      accessToken: access_token,
      accessTokenExpiry: new Date( new Date().valueOf() / 1000 + expires_in ),
      ...getFacebookUserResponseData
    };
    const serializedResponse = this.facebookSerializer.serializeLoginResponse(compiledResponse);

    this.setState({
      redirectToSignup: true,
      loginResponse: serializedResponse,
    })
  }

  render() {
    if (this.state.redirectToSignup) {
      return <Redirect to={`/signup?first_name=${this.state.loginResponse.first_name}&last_name=${this.state.loginResponse.last_name}&email=${this.state.loginResponse.email}&social=Facebook`} />
    }

    return <React.Fragment>

    </React.Fragment>
  }

}

export class FacebookLoginConfirmationCallback extends React.Component {

}
