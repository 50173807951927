import React from "react";

export const TotalStockColumns = [
  {
    Header: (props) => <div style={{ textAlign: "left" }}>Device ID</div>,
    Cell: (props) => (
      <div
        className="wordbreak"
        style={{ textAlign: "left", wordBreak: "break-all" }}
      >
        {props.value}
      </div>
    ),
    accessor: "deviceId",
    headerClassName: "table-header",
    className: "table-cell",
    style: { "white-space": "unset" },
    width: 500,
  },

  {
    Header: (props) => <div style={{ textAlign: "left" }}>POS Model</div>,
    accessor: "model",
    Cell: (props) => <div style={{ textAlign: "left" }}>{props.value}</div>,
    headerClassName: "table-header",
    className: "table-cell",
    //   width: 300,

    style: { "white-space": "unset" },
  },
];
