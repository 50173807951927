import React, { useState } from "react";

import { Col, Form, Row } from "react-bootstrap";

import Lightbox from "react-image-lightbox";
import {
  firetoastAggregator
} from "../../../../../../../components/extras";
import {
  ADDRESS_VERIFICATION_FORM,
  BUSINESS_DOC_SUFFIX,
  CHARACTER_ATTESTATION,
  THREE_MB,
  UTILITY_BILL,
} from "../../../../../../../constants";
import {
  ERROR_STATUS,
  SUCCESS_STATUS,
} from "../../../../../../../constants/api";
import {
  errorResponse,
  isIdentificationNumberValid,
} from "../../../component/extras";

import H1 from "../../../../../../../components/h1";
import Thumbnail3 from "../../../../../../../components/thumbnail-3";
import { MAX_IDENTIFICATION_NUMBER_LENGTH } from "../../../../../../../constants/form-fields";
import Platform from "../../../../../../../services/api/resources/platform";
import PrimaryButton from "../../../component/button";
import InputText from "../../../component/input";
import SelectInput from "../../../component/select";
import Title from "./title";

const meansOfId = [
  "Passport",
  "Drivers License",
  "International Passport",
  "Voter's Card",
  "National Identification Number",
];
const KycDocumentScene = ({ setScreen, application, props }) => {
  const upgradeTo = localStorage.getItem("upgradeTo");

  const platform = new Platform();

  const [showLightbox, setShowLightbox] = useState(false);

  const updatedkycDetailsForm = JSON.parse(
    localStorage.getItem("kycDetailsFormUpgrade")
  );

  const [kycDetailsForm, setKycDetailsForm] = useState({
    attachedDocuments: updatedkycDetailsForm?.attachedDocuments || [],

    meansOfId: application
      ? application?.identificationType
      : updatedkycDetailsForm
      ? updatedkycDetailsForm.meansOfId
      : "",
    idNumber: application
      ? application?.identificationNumber
      : updatedkycDetailsForm
      ? updatedkycDetailsForm.idNumber
      : "",
  });

  localStorage.setItem("kycDetailsFormUpgrade", JSON.stringify(kycDetailsForm));

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [
  //   filteredDocumentsToAttachPrestige,
  //   setFilteredDocumentsToAttachPrestige,
  // ] = useState([]);

  const updateFormField = (params) => {
    const newForm = {
      ...kycDetailsForm,
      ...params,
    };

    setKycDetailsForm(newForm);
  };

  const navigateToOverview = () => {
    props.navigateTo("/agents/my-agents");
  };

  const handleSubmit = async () => {
    // submitAggregatorClassUpgrage

    setIsLoadingSubmit(true);

    const payload = {};
    const { status, response } = await platform.submitAggregatorClassUpgrage(
      payload,
      application.agentCode
    );
    setIsLoadingSubmit(false);
    if (status === ERROR_STATUS) {
      errorResponse(response.code, response.description);
    } else if (status === SUCCESS_STATUS) {
      firetoastAggregator(
        "success",
        "Request successfully submitted",
        `Please expect feedback within 24 hours`,
        // `Your upgrade request has been submitted`,
        // `Your upgrade request has been submitted`,
        "View Agent",
        navigateToOverview
      );

      localStorage.removeItem("lga");
      localStorage.removeItem("application");
      localStorage.removeItem("businessType");
      localStorage.removeItem("state");
      localStorage.removeItem("personalDetailsFormUpgrade");
      localStorage.removeItem("kycDetailsFormUpgrade");
      localStorage.removeItem("businessDetailsFormUpgrade");
    }
  };

  const imgStyle = {
    borderRadius: "5px",
    height: "7rem",
    border: "1px solid grey",
    width: "7rem",
    cursor: "pointer",
    objectFit: "cover",
  };

  const [attachedDocuments, setAttachedDocuments] = useState(
    kycDetailsForm.attachedDocuments
  );
  const [fileNameDetails, setFileNameDetails] = useState(false);

  const [uploadError, setUploadError] = useState(false);
  const [error, setError] = useState(false);

  const getDocumentForThumbnail = (attachedDocuments, filename) => {
    return attachedDocuments.find((value) => {
      const name = value.filename ? value.filename : value.documentType;

      return name.includes(filename);
    });
  };

  const convertFileName = (fileName) => {
    if (fileName === "Utility Bill") {
      return "UTILITY_BILL";
    } else if (fileName === "Address Verification Form") {
      return "ADDRESS_VERIFICATION_FORM ";
    } else if (fileName === "Character Attestation Form") {
      return "CHARACTER_FORM";
    }
  };

  const checkDoc = () => {
    const documentsToAttachStandard = [UTILITY_BILL, CHARACTER_ATTESTATION];
    const documentsToAttachPrestige = [
      UTILITY_BILL,
      CHARACTER_ATTESTATION,
      ADDRESS_VERIFICATION_FORM,
    ];

    const filteredDocumentsToAttachPrestige = documentsToAttachPrestige.filter(
      (docType) => {
        const hasMatch = application?.documents?.some((item) => {
          return (
            item.documentType.substring(0, 3).toLowerCase() ===
            docType.substring(0, 3).toLowerCase()
          );
        });

        return !hasMatch;
      }
    );

    if (upgradeTo === "Standard") {
      return documentsToAttachStandard;
    } else if (upgradeTo === "prestige") {
      return filteredDocumentsToAttachPrestige;
    }
  };

  const checkClassDocumentValidation = () => {
    if (upgradeTo === "prestige") {
      return checkDoc().length === kycDetailsForm.attachedDocuments.length;
    } else if (upgradeTo === "Standard") {
      return kycDetailsForm.attachedDocuments.length > 1;
    }
  };

  const isFormValid = () => {
    return (
      kycDetailsForm.meansOfId &&
      kycDetailsForm.idNumber &&
      checkClassDocumentValidation()
    );
  };

  const onFileSelect = async (event, filename) => {
    const name = event.target.files[0].name;
    const file = event.target.files[0];

    if (file.size > THREE_MB) {
      setError(true);

      setUploadError(false);
      return;
    } else {
      setLoading(true);

      const uploadDoc = await platform.documentUploadAggregatorClass(
        application.agentCode,
        `${convertFileName(filename)}`,
        file
      );

      if (uploadDoc.status === ERROR_STATUS) {
        setError(false);

        setUploadError(false);
        setLoading(false);
      } else {
        const newAttachedDocument = {
          name: `${name}${BUSINESS_DOC_SUFFIX}`,
          filename: `${filename}${BUSINESS_DOC_SUFFIX}`,
          file: file,
          url: URL.createObjectURL(file),
        };

        const newAttachedDocuments = [
          ...attachedDocuments,
          newAttachedDocument,
        ];

        setError(false);
        setAttachedDocuments(newAttachedDocuments);
        setUploadError(false);
        setLoading(false);

        updateFormField({ attachedDocuments: newAttachedDocuments });
      }
    }
  };

  function onFileUnselect(file) {
    const newAttachedDocuments = attachedDocuments.filter(
      (item) => item !== file
    );

    setAttachedDocuments(newAttachedDocuments);
  }

  const getDocumentUrl = (documentLink) => {
    let convertedDocument = documentLink.replace(
      "/data",
      process.env.REACT_APP_ONBOARDING_BASE_URL
    );
    return convertedDocument;
  };
  const convertFileNameFetch = (fileName) => {
    if (fileName.toLowerCase().includes("pass")) {
      return "Passport Photo";
    } else if (
      fileName.toLowerCase().includes("proof") ||
      fileName.toLowerCase().includes("id")
    ) {
      return "Means of Identification";
    } else if (fileName.toLowerCase().includes("util")) {
      return "Utility Bill";
    } else if (fileName.toLowerCase().includes("char")) {
      return "Character Form";
    }
  };

  const [selectedDocument, setSelectedDocument] = useState(null);
  const handleDocumentClick = (documentName) => {
    setSelectedDocument(documentName);
    setShowLightbox(true);
  };

  return (
    <>
      <main className="aggregator-container">
        <Col
          style={{
            margin: "20px auto",
          }}
        >
          <Title upgradeTo={localStorage.getItem("upgradeTo")} />
          <H1
            style={{
              textAlign: "left",
              fontWeight: 600,
              marginBottom: ".2rem",
            }}
          >
            KYC Information
          </H1>
          <p
            style={{
              color: "#6B7280",
              fontSize: "0.75rem",
              marginBottom: "2rem",
            }}
          >
            Kindly upload required documents
          </p>
          <SelectInput
            disabled={true}
            label={"Means of Identification"}
            onChange={(e) => {
              updateFormField({ meansOfId: e.target.value });
            }}
            placeholder={"means of identification"}
            value={kycDetailsForm.meansOfId}
            option={meansOfId}
          />
          <br />
          <InputText
            disabled={true}
            labelColor={
              !kycDetailsForm.idNumber ||
              isIdentificationNumberValid(kycDetailsForm)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !kycDetailsForm.idNumber ||
              isIdentificationNumberValid(kycDetailsForm)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            onChange={(e) => {
              updateFormField({
                idNumber: e.target.value.trim().replace(/[^a-zA-Z0-9]/g, ""),
              });
            }}
            value={kycDetailsForm.idNumber}
            maxLength={MAX_IDENTIFICATION_NUMBER_LENGTH}
            label={"ID number"}
            placeholder={"Enter  Id number"}
          />

          <Row>
            {application?.documents.length > 1 && (
              <Col
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  marginTop: "10px",
                }}
              >
                {application?.documents.map((value, index) => {
                  let {
                    documentName,
                    documentLink,
                    documentType,
                    documentExtention,
                  } = value;

                  return (
                    <div onClick={() => handleDocumentClick(documentLink)}>
                      <Form.Label
                        style={{
                          fontSize: "14px",
                        }}
                      >
                        {convertFileNameFetch(documentType)}
                      </Form.Label>
                      <div style={imgStyle} key={index}>
                        {documentExtention === "png" ||
                        documentExtention === "jpeg" ||
                        documentExtention === "jpg" ||
                        documentExtention === "svg" ? (
                          <img
                            style={imgStyle}
                            src={getDocumentUrl(documentLink)}
                            title={convertFileNameFetch(documentType)}
                            alt={convertFileNameFetch(documentType)}
                          />
                        ) : (
                          <div onClick={() => setShowLightbox(true)}>
                            <embed
                              style={imgStyle}
                              name="plugin"
                              src={getDocumentUrl(documentLink)}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
                {showLightbox && selectedDocument && (
                  <>
                    <Lightbox
                      mainSrc={getDocumentUrl(selectedDocument)}
                      onCloseRequest={() => setShowLightbox(false)}
                    />
                  </>
                )}
              </Col>
            )}
          </Row>

          <>
            <div
              style={{
                marginTop: "10px",
                flexWrap: "nowrap",
                overflowX: "auto",
                overflowY: "auto",
              }}
            >
              {checkDoc().map((value) => {
                let filename = value;

                return (
                  <div
                    style={{
                      marginBottom: "15px",
                    }}
                    onClick={() => setFileNameDetails(value)}
                  >
                    <Form.Label
                      style={{
                        color: "black",
                        fontSize: "14px",
                      }}
                    >
                      {filename}
                    </Form.Label>
                    <Thumbnail3
                      attachedDocument={getDocumentForThumbnail(
                        attachedDocuments,
                        filename
                      )}
                      text={
                        "Upload a file or drag and drop DOCX, DOC, PDF, upto 3MB"
                      }
                      filename={filename}
                      onFileSelect={onFileSelect}
                      onFileUnselect={onFileUnselect}
                      isLoading={value === fileNameDetails && loading}
                    />
                  </div>
                );
              })}
            </div>
          </>
          <br />
          {error && (
            <Col lg={12}>
              <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                File size is above 10MB
              </span>
            </Col>
          )}
          {uploadError && (
            <Col lg={12}>
              <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                Failed to Upload.Try again
              </span>
            </Col>
          )}

          <PrimaryButton
            disabled={!isFormValid() || isLoadingSubmit}
            onClick={handleSubmit}
          >
            {isLoadingSubmit ? "Processing..." : "Save & Continue"}
          </PrimaryButton>
        </Col>
      </main>
    </>
  );
};

export default KycDocumentScene;
