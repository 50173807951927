import ErrorTemplates from "../fixtures/error_templates";

export class ApiErrorHandler {
  _findTemplateForErrorResponse(parsedErrorResponse) {
    // TODO refactor this to cater for `mode` and other parameters
    return (
      ErrorTemplates.find(
        (value) => value.message_from_api === parsedErrorResponse.message
      ) || process.env.REACT_APP_DEFAULT_ERROR_MESSAGE
    );
  }

  _parseApiErrorResponse(rawApiResponse) {
    const errorObject = {
      code: null,
      message: null,
      message_to_user: null,
    };

    if (!rawApiResponse) {
      return errorObject;
    }

    errorObject.code = rawApiResponse.code;

    errorObject.message =
      rawApiResponse.description ||
      rawApiResponse.errorMessage ||
      rawApiResponse.message;
    if (rawApiResponse.errors && rawApiResponse.errors.length > 0) {
      errorObject.message =
        rawApiResponse.errors[0].errorMessage ||
        rawApiResponse.errors[0].message;
    } else if (
      rawApiResponse.responseData &&
      rawApiResponse.responseData.description
    ) {
      errorObject.message = rawApiResponse.responseData.description;
    }

    if (JSON.parse(process.env.REACT_APP_VERBOSE_ERRORS)) {
      errorObject.message_to_user = errorObject.message;
    }
    return errorObject;
  }

  handleApiErrorResponse(rawApiResponse, errorMessageKeywords) {
    const parsedErrorResponse = this._parseApiErrorResponse(rawApiResponse);
    let errorMessage =
      this._findTemplateForErrorResponse(parsedErrorResponse).message_to_user ||
      parsedErrorResponse.message;

    if (errorMessageKeywords) {
      let refinedErrorMessage = errorMessage;

      Object.keys(errorMessageKeywords).map(keyword => {
        refinedErrorMessage = refinedErrorMessage.replace(`{${keyword}}`, errorMessageKeywords[keyword]);
        return refinedErrorMessage
      })

      return refinedErrorMessage;
    }

    if (
      (!rawApiResponse.code.includes("400") &&
        !rawApiResponse.code.includes("401")) ||
      errorMessage.length > 80
    ) {
      errorMessage = process.env.REACT_APP_DEFAULT_ERROR_MESSAGE;
    }
    return errorMessage;
  }
}
