import React from 'react';
import ArrowUp from '../../../../../icons/arrowUp.png';
import ArrowDown from '../../../../../icons/arrowDown.png';
export const RenderScale = ({ percentage }) => {
  const isPositive = percentage >= 40;
  const isZero = percentage === 0;
  const backgroundColor = isZero
    ? '#FBE9E9'
    : isPositive
    ? '#F1FEF1'
    : '#FBE9E9';
  const textColor = isZero ? '#DC4437' : isPositive ? '#519E47' : '#DC4437';
  const arrowIcon = isZero ? (
    <img alt='arrowDown' src={ArrowDown} />
  ) : isPositive ? (
    <img alt='arrowUp' src={ArrowUp} />
  ) : (
    <img alt='arrowDown' src={ArrowDown} />
  );
  const sign = isZero ? '' : isPositive ? '+' : '-';

  return (
    <div
      style={{
        backgroundColor,
        color: textColor,
        fontWeight: '600',
      }}
      className='balance-card-scale-container'
    >
      {arrowIcon}
      <span>{`${percentage}%`}</span>
    </div>
  );
};
