import React from 'react';

const AgentReport = (restOfTheData) => {
  const ptag = { color: '#111827', fontWeight: 400 };
  const spanTag = {
    fontSize: '14px',
    fontWeight: 400,
    color: '#6B7280',
  };

  return (
    <div className='view-report-screen'>
      <div className='view-report-section'>
        <div>
          <h2
            style={{
              fontSize: '16px',
              fontWeight: '600',
              marginBottom: '15px',
              marginTop: '20px',
            }}
          >
            Agent Information
          </h2>
          <section className='posSection'>
            <div className='posAgent'>
              <div>
                <span style={spanTag}>Next Owner Business Address</span>
                <p style={ptag}>
                  {restOfTheData.restOfTheData.nextOwnerBusinessAddress}
                </p>
              </div>
              <div>
                <span style={spanTag}>Next Owner State</span>
                <p style={ptag}>{restOfTheData.restOfTheData.nextOwnerState}</p>
              </div>
              <div>
                <span style={spanTag}>Next Owner Name</span>
                <p style={ptag}>{restOfTheData.restOfTheData.nextOwnerName}</p>
              </div>
            </div>
            <div className='posAgent'>
              <div>
                <span style={spanTag}>Remap To</span>
                <p style={ptag}>
                  {restOfTheData.restOfTheData.nextTerminalOwner}
                </p>
              </div>
              <div>
                <span style={spanTag}>Remap From</span>
                <p style={ptag}>
                  {restOfTheData.restOfTheData.currentOwnerName}
                </p>
              </div>
            </div>
          </section>
          <hr />
          <h2
            style={{
              fontSize: '16px',
              fontWeight: '600',
              marginBottom: '15px',
              marginTop: '40px',
            }}
          >
            Terminal Information
          </h2>
          <section className='posSection'>
            <div className='posAgent'>
              <div>
                <span style={spanTag}>Device Name</span>
                <p style={ptag}>{restOfTheData.restOfTheData.terminalName}</p>
              </div>
              <div>
                <span style={spanTag}>Terminal ID</span>
                <p style={ptag}>{restOfTheData.restOfTheData.terminalId}</p>
              </div>
            </div>
            <div className='posAgent'>
              <div>
                <span style={spanTag}>Terminal Model</span>
                <p style={ptag}>{restOfTheData.restOfTheData.terminalModel}</p>
              </div>
              <div>
                <span style={spanTag}>Serial Number</span>
                <p style={ptag}>{restOfTheData.restOfTheData.serialNo}</p>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className='view-report-section'>
        <div>
          <h2
            style={{
              fontSize: '16px',
              fontWeight: '600',
              marginBottom: '15px',
              marginTop: '20px',
            }}
          >
            Other Information
          </h2>
          <section className='posSection'>
            <div className='posAgent'>
              <div>
                <span style={spanTag}>Initiator Username</span>
                <p style={ptag}>
                  {restOfTheData.restOfTheData.remapInitiatorUsername}
                </p>
              </div>
              <div>
                <span style={spanTag}>Reason For Remaping</span>
                <p style={ptag}>
                  {restOfTheData.restOfTheData.reasonForRemaping}
                </p>
              </div>
              <div>
                <span style={spanTag}>Remap request Type</span>
                <p style={ptag}>
                  {restOfTheData.restOfTheData.remapRequestType}
                </p>
              </div>
            </div>
            <div className='posAgent'>
              <div>
                <span style={spanTag}>Request Id</span>
                <p style={ptag}>{restOfTheData.restOfTheData.requestId}</p>
              </div>
              {restOfTheData.restOfTheData.approvalStatus === 'declined' && (
                <div>
                  <span style={spanTag}>Reason For Decline</span>
                  <p style={ptag}>
                    {restOfTheData.restOfTheData.reasonForDecline}
                  </p>
                </div>
              )}
            </div>
          </section>
          <hr />
        </div>
      </div>
    </div>
  );
};

export default AgentReport;
