import React from 'react'

export default class NotFoudScene extends React.Component {

  render() {
    return <div style={{
      alignItems: 'center', 
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      justifyContent: 'center',
      textAlign: 'center'
    }}>
      Page not found.
    </div>
  }

}
