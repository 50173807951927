import React, { useEffect, useRef, useState } from "react";
import {
  formatAmount,
  specialDateRangeFormatter_StartDate,
} from "../../../../../../utils/formatters";

import { BalanceCard } from "../components/balanceCard";
import WithdrawalGridSceen from "./withdrawalGrid";
import SkeletonLoading from "../../component/skeletonLoading";
import TransactionHistory from "../../../../../../services/api/resources/transaction-history";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../../../constants/api";
import CustomError from "../../../../../../components/error-component";
import { Overlay } from "react-bootstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { RenderScale } from "../../component/renderScale";
import { convertNgkToNgn } from "../../../../../../utils/converters/currencies";
const WithdrawalsSceen = (
  {
    // fetchEarningBalance,
    // getEarningBalance,
    // loading,
  }
) => {
  const currentAgent = JSON.parse(localStorage.getItem("currentAgent"));
  const transactionHistory = new TransactionHistory();
  const [selectedFilter, setSelectedFilter] = useState("This week");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [show, setShow] = useState(false);
  const [withdrawal, setWithdrawal] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [dateIntervalType, setDateIntervalType] = useState(2);
  const [pageNum, setPageNum] = useState(1);
  const [getEarningBalance, setGetEarningBalance] = useState();

  const [loading, setLoading] = useState(true);
  const target = useRef(null);

  const [pageSize, setPageSize] = useState(20);

  const fetchEarningBalance = async () => {
    setGetEarningBalance([]);
    setLoading(true);
    // const payload = {
    //   dateIntervalType: dateIntervalType,
    // };

    let payload = {};
    if (dateIntervalType) {
      payload = {
        dateIntervalType: dateIntervalType,
      };
    } else {
      payload = {
        from: specialDateRangeFormatter_StartDate(startDate),
        to: specialDateRangeFormatter_StartDate(endDate),
      };
    }
    const { status, response } = await transactionHistory.getEarnings(payload);

    setLoading(false);
    if (status === ERROR_STATUS) {
      // setError(true);
    } else if (status === SUCCESS_STATUS) {
      setLoading(false);

      setGetEarningBalance(response.data);
    } else {
      setLoading(false);
    }
  };

  const handleFilterClick = (filterName) => {
    setSelectedFilter(filterName);
    if (filterName === "This week") {
      setDateIntervalType(2);
    } else if (filterName === "This Month") {
      setDateIntervalType(3);
    } else if (filterName === "This Year") {
      setDateIntervalType(4);
    } else {
      setDateIntervalType();
    }
  };

  const filterStyle = (filterName) => {
    return {
      padding: ".5rem 1.5rem",
      backgroundColor: selectedFilter === filterName ? "#00425F" : "white",
      color:
        selectedFilter === filterName
          ? "white"
          : process.env.REACT_APP_BLUE_COLOUR,
      cursor: "pointer",
      border: "1px solid #E1E6ED",
    };
  };

  useEffect(() => {
    fetchEarningBalance();
  }, [dateIntervalType, endDate]);

  //   if (percentage >= 40) {
  //     return (
  //       <div
  //         style={{
  //           backgroundColor: '#F1FEF1',
  //           color: '#519E47',
  //           fontWeight: '600',
  //         }}
  //         className='balance-card-scale-container'
  //       >
  //         <img alt='arrowUp' src={ArrowUp} />
  //         <span>+{percentage}%</span>
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div
  //         style={{
  //           backgroundColor: '#FBE9E9',
  //           color: '#DC4437',
  //           fontWeight: '600',
  //         }}
  //         className='balance-card-scale-container'
  //       >
  //         <img alt='arrowDown' src={ArrowDown} />
  //         <span>-{percentage}%</span>
  //       </div>
  //     );
  //   }
  // };

  return (
    <section>
      <article className="filter-tabs-container">
        <div
          className="rounded-left"
          style={filterStyle("This week")}
          onClick={() => handleFilterClick("This week")}
        >
          This week
        </div>
        <div
          style={filterStyle("This Month")}
          onClick={() => handleFilterClick("This Month")}
        >
          This Month
        </div>
        <div
          style={filterStyle("This Year")}
          onClick={() => handleFilterClick("This Year")}
        >
          This Year
        </div>
        {/* <div
          style={filterStyle('All Time')}
          onClick={() => handleFilterClick('All Time')}
        >
          All Time
        </div> */}
        <div
          className="rounded-right"
          ref={target}
          style={filterStyle("Custom Range")}
          onClick={() => {
            setShow(!show);
            handleFilterClick("Custom Range");
          }}
        >
          Custom Range
        </div>
      </article>
      <Overlay
        rootClose={true}
        onHide={() => setShow(false)}
        target={target.current}
        show={show}
        placement="bottom"
      >
        {({
          placement: _placement,
          arrowProps: _arrowProps,
          show: _show,
          popper: _popper,

          hasDoneInitialMeasure: _hasDoneInitialMeasure,
          ...props
        }) => (
          <div
            {...props}
            style={{
              // position: 'absolute',

              backgroundColor: "white",
              padding: "10px",
              borderRadius: "10px",
              boxShadow:
                "0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)",
              border: "1px solid var(#E1E6ED, #E1E6ED)",
              marginTop: "10px",
              ...props.style,
            }}
          >
            <div
              style={{
                margin: "30px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <div>
                  <span
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    Start Date
                  </span>
                </div>
                <div>
                  <span
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    End Date
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "20px",
                  marginTop: "10px",
                }}
              >
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  inline
                  showYearDropdown
                />
                <DatePicker
                  selected={endDate}
                  onChange={(date) => {
                    setEndDate(date);
                    setShow(false);
                  }}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  inline
                  showYearDropdown
                />
              </div>
            </div>
          </div>
        )}
      </Overlay>
      <article className="agentType-container-card-commission">
        <div className="commission-withdrawal-card">
          <BalanceCard
            loading={loading}
            title={`Total Withdrawals`}
            balance={`₦${formatAmount(
              convertNgkToNgn(getEarningBalance?.currentWithdrawalValue)
            )}`}
            buttonContent={
              <RenderScale
                percentage={
                  getEarningBalance?.withdrawalVariancePercent
                    ? getEarningBalance?.withdrawalVariancePercent
                    : 0
                }
              />
            }
          />
        </div>
      </article>
      <WithdrawalGridSceen
        dateIntervalType={dateIntervalType}
        endDate={endDate}
        startDate={startDate}
      />
    </section>
  );
};

export default WithdrawalsSceen;
