import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Text from "../../../../../components/text";

export default class ProductThumbnail extends React.Component {
  render() {
    return (
      <OverlayTrigger
        key={"bottomThumbnail"}
        placement={"bottom"}
        overlay={
          <Tooltip id={`tooltip-${"top"}`}>
            {this.props.product.description}
          </Tooltip>
        }
      >
        <div
          className="grow"
          style={{
            backgroundColor: "white",
            borderRadius: "8px",
            cursor: "pointer",
            height: "110px",
            width: "110px",
            margin: "12px",
          }}
          onClick={() => this.props.onSelect(this.props.product)}
        >
          <img
            src={this.props.product.imageUrl}
            alt={this.props.product.name}
            style={{ padding: "5px" }}
            height="80%"
            width="100%"
          />
          <Text
            style={{
              textAlign: "center",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              paddingLeft: "5px",
              paddingRight: "5px",
            }}
          >
            {this.props.product.name}
          </Text>
        </div>
      </OverlayTrigger>
    );
  }
}
