import React from "react";
import { Col, Dropdown, Form, FormLabel, Row } from "react-bootstrap";
import ReactCountryFlag from "react-country-flag";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MdArrowDropright from "react-ionicons/lib/MdArrowDropright";
import Button from "../components/button";
import CountryFlagDropdownMenu from "../components/country-flag-dropdown-menu";
import CountryFlagDropdownToggle from "../components/country-flag-dropdown-toggle";
import FormControl from "../components/form-control";
import H1 from "../components/h1";
import Thumbnail2 from "../components/thumbnail-2";
import {
  PASSPORT_PHOTO,
  PERSONAL_DOC_SUFFIX,
  PROOF_OF_ID,
  THREE_MB,
} from "../constants";
import {
  ERROR_STATUS,
  HTTP_NOT_FOUND_CODE,
  SUCCESS_CODE,
  SUCCESS_STATUS,
} from "../constants/api";
import {
  MAX_BVN_LENGTH,
  MAX_DESCRIPTION_LENGTH,
  MAX_IDENTIFICATION_NUMBER_LENGTH,
  MAX_MOTHERS_MAIDEN_NAME_LENGTH,
  MAX_NAME_LENGTH,
  MAX_PLACE_OF_BIRTH_LENGTH,
  MIN_DOCUMENTS_REQUIRED,
} from "../constants/form-fields";
import CountriesStatesLga from "../fixtures/countries_states_lgas";
import IdentificationTypes from "../fixtures/identification_types";
import Onboarding from "../services/api/resources/onboarding";
import Platform from "../services/api/resources/platform";
import UserManagement from "../services/api/resources/user-management";
import { subtractDays } from "../utils/time";
import { isEmailValid } from "../utils/validators/form-validators";
import phoneValidators from "../utils/validators/phone-validators";
import BaseScene from "../scenes/base-scene";

const NIGERIA_COUNTRY_SHORT_CODE = "ng";
const NIGERIA_PHONE_PREFIX = "234";

const NEW_EMAIL = "email is new";
const USED_EMAIL = "email not new";

class CustomToggle extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();

    this.props.onClick(e);
  }

  render() {
    return (
      <a href="" onClick={this.handleClick}>
        {this.props.children}
      </a>
    );
  }
}

class CustomMenu extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleChange = this.handleChange.bind(this);

    this.state = { value: "" };
  }

  handleChange(e) {
    this.setState({ value: e.target.value.toLowerCase().trim() });
  }

  render() {
    const {
      children,
      style,
      className,
      "aria-labelledby": labeledBy,
    } = this.props;

    const { value } = this.state;

    return (
      <div
        style={{
          // display: 'flex',
          // flexDirection: 'column',
          height: "150px",
          overflowY: "auto",
          ...style,
        }}
        className={className}
        aria-labelledby={labeledBy}
      >
        <FormControl
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={this.handleChange}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
}
export default class ManageComplaint extends BaseScene {
  platform = new Platform();
  userManagement = new UserManagement();

  constructor(props) {
    super(props);

    this.onboarding = new Onboarding();

    this.state = {
      attachedDocuments: [],
      countries: [],
      countryShortCode: NIGERIA_COUNTRY_SHORT_CODE,
      fieldsFocused: [],
      form: {},
      formIsValid: null,
      identificationTypes: IdentificationTypes,
      lgas: [],
      originalAttachedDocuments: [],
      previouslySavedFields: {},
      states: [],
      superAgents: [],
      updatesHaveBeenMade: false,
      propagateFormErrors: false,
    };

    this.onLgaSelect = this.onLgaSelect.bind(this);
    this.onNationalitySelect = this.onNationalitySelect.bind(this);
    this.onStateSelect = this.onStateSelect.bind(this);
    this.stripNotAllowedCharactersFromString =
      this.stripNotAllowedCharactersFromString.bind(this);
    this.onFileSelect = this.onFileSelect.bind(this);
    this.onFileUnselect = this.onFileUnselect.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.onEmailUpdate = this.onEmailUpdate.bind(this);
  }

  componentDidMount() {
    const currentApplication = JSON.parse(
      localStorage.getItem("currentApplication")
    );
    const newForm = {
      firstName: currentApplication.applicantDetails.firstName,
      middleName: currentApplication.applicantDetails.middleName,
      lastName: currentApplication.applicantDetails.surname,
      phone: currentApplication.applicantDetails.phoneNumber,
      phonePrefix: NIGERIA_PHONE_PREFIX,
      email: currentApplication.applicantDetails.emailAddress,
      state: currentApplication.applicantDetails.state,
      lga: currentApplication.applicantDetails.localGovernmentArea,
      nationality: currentApplication.applicantDetails.nationality,
      address: currentApplication.applicantDetails.address,
      closestLandmark: currentApplication.applicantDetails.closestLandMark,
      dateOfBirth: currentApplication.applicantDetails.dob
        ? new Date(currentApplication.applicantDetails.dob)
        : currentApplication.applicantDetails.dob,
      placeOfBirth: currentApplication.applicantDetails.placeOfBirth,
      gender: currentApplication.applicantDetails.gender,
      identificationNumber:
        currentApplication.applicantDetails.identificationNumber,
      identificationType:
        currentApplication.applicantDetails.identificationType,
      mothersMaidenName: currentApplication.applicantDetails.mothersMaidenName,
      bvn: currentApplication.applicantDetails.bvn,
      referralCode: currentApplication.referralCode,
    };

    this.props.onUpdateFormField(newForm);

    this.fetchAllCountries();
    this.fetchAllSuperAgents();
    currentApplication.applicantDetails.nationality &&
      this.fetchAllStates(currentApplication.applicantDetails.nationality);
    currentApplication.applicantDetails.state &&
      this.fetchAllLgas(
        currentApplication.applicantDetails.nationality,
        currentApplication.applicantDetails.state
      );

    const formIsValid =
      this.isMiddleNameValid(newForm) &&
      this.isClosestLandmarkValid(newForm) &&
      this.isAddressValid(newForm) &&
      this.isDateOfBirthValid(newForm) &&
      newForm.gender &&
      this.isPlaceOfBirthValid(newForm) &&
      this.isIdentificationTypeValid(newForm) &&
      this.isIdentificationNumberValid(newForm) &&
      this.isBvnValid(newForm) &&
      this.isMothersMaidenNameValid(newForm) &&
      currentApplication.documentsList.length >= MIN_DOCUMENTS_REQUIRED;

    if (formIsValid) {
      this.props.onSubmit(newForm);
    }

    const personalDetailsDocuments = currentApplication.documentsList
      ? currentApplication.documentsList.filter((value) =>
          value.documentType.endsWith(PERSONAL_DOC_SUFFIX)
        )
      : [];

    this.setState({
      form: newForm,
      previouslySavedFields: newForm,
      formIsValid,
      originalAttachedDocuments: personalDetailsDocuments || [],
      attachedDocuments: personalDetailsDocuments || [],
    });
  }

  async fetchAllCountries() {
    const countries = CountriesStatesLga;
    this.setState({
      countries,
    });

    // const defaultNationality = countries.find(value => value.name === NIGERIA)
    // this.onNationalitySelect(defaultNationality.id)
  }

  async fetchAllLgas(nationality, stateId) {
    const country = CountriesStatesLga.find(
      (value) => parseInt(value.id) === parseInt(nationality)
    );
    const state = country.states.find(
      (value) => parseInt(value.id) === parseInt(stateId)
    );

    this.setState({
      lgas: state ? state.lgas : [],
    });
  }

  async fetchAllStates(countryId) {
    const country = CountriesStatesLga.find(
      (value) => parseInt(value.id) === parseInt(countryId)
    );

    this.setState({
      states: country ? country.states : [],
    });
  }

  async fetchAllSuperAgents() {
    let failedCount = 0;

    while (failedCount < 3) {
      const superAgentsResponseObj = await this.platform.retrieveSuperAgents();
      const superAgentsResponseStatus = superAgentsResponseObj.status;
      const superAgentsResponse = superAgentsResponseObj.response;

      if (superAgentsResponseStatus === SUCCESS_STATUS) {
        this.setState({
          superAgents: superAgentsResponse,
        });
        return;
      } else {
        failedCount += 1;
      }
    }
  }

  isAddressValid(newForm) {
    const form = newForm || this.state.form;
    return form.address && form.address.length > 2;
  }

  isAttachedDocumentsValid(newForm) {
    const form = newForm || this.state.form;
    return (
      this.state.attachedDocuments.length >= MIN_DOCUMENTS_REQUIRED ||
      (form.attachedDocuments &&
        form.attachedDocuments.length >= MIN_DOCUMENTS_REQUIRED)
    );
  }

  isEmailValid(newForm) {
    const form = newForm || this.state.form;
    var re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(form.email).toLowerCase());
  }

  isEmailValidAndNew() {
    return (
      isEmailValid(this.state.form.email) &&
      (this.state.isEmailExisting === NEW_EMAIL ||
        this.state.isEmailExisting === "")
    );
  }

  isFirstNameValid(newForm) {
    const form = newForm || this.state.form;
    return form.firstName && form.firstName.length > 2;
  }

  isMiddleNameValid(newForm) {
    const form = newForm || this.state.form;

    if (!form.middleName) {
      return true;
    }

    return form.middleName && form.middleName.length > 2;
  }

  isHowYouHeardAboutUsValid(newForm) {
    const form = newForm || this.state.form;
    return Boolean(form.howYouHeardAboutUs);
  }

  isLastNameValid(newForm) {
    const form = newForm || this.state.form;
    return form.lastName && form.lastName.length > 2;
  }

  isLgaValid(newForm) {
    const form = newForm || this.state.form;
    return form.lga && Boolean(parseInt(form.lga));
  }

  isTownValid(newForm) {
    const form = newForm || this.state.form;
    return form.town && form.town.length > 2;
  }

  isNationalityValid(newForm) {
    const form = newForm || this.state.form;
    return form.nationality && Boolean(parseInt(form.nationality));
  }

  isPostalCodeValid(newForm) {
    const form = newForm || this.state.form;
    return (
      form.postalCode &&
      Boolean(parseInt(form.postalCode)) &&
      form.postalCode.length >= 6
    );
  }

  isReferralCodeValid(newForm) {
    const form = newForm || this.state.form;
    return form.referralCode && form.referralCode.length >= 3;
  }

  isDateOfBirthValid(newForm) {
    const form = newForm || this.state.form;

    return form.dateOfBirth;
  }

  isClosestLandmarkValid(newForm) {
    const form = newForm || this.state.form;
    return form.closestLandmark && form.closestLandmark.length > 2;
  }

  isPasswordValid(newForm) {
    const form = newForm || this.state.form;
    const { password } = form;

    var re = /[a-z]/;
    var re2 = /[A-Z]/;
    var re3 = /[0-9]/;

    return (
      re.test(String(password)) &&
      re2.test(String(password)) &&
      re3.test(String(password)) &&
      String(password).length >= 8
    );
  }

  isPhoneValid(newForm) {
    const form = newForm || this.state.form;
    const countryValidator = phoneValidators[this.state.form.phonePrefix];

    return form.phone && countryValidator ? countryValidator(form.phone) : true;
  }

  isIdentificationNumberValid(newForm) {
    const form = newForm || this.state.form;
    return (
      form.identificationNumber &&
      form.identificationNumber.length >= 7 &&
      !form.identificationNumber.includes(" ")
    );
  }

  isIdentificationTypeValid(newForm) {
    const form = newForm || this.state.form;
    return !isNaN(form.identificationType);
  }

  isBvnValid(newForm) {
    const form = newForm || this.state.form;
    return (
      form.bvn &&
      Boolean(parseInt(form.bvn)) &&
      form.bvn.length === 11 &&
      !isNaN(form.bvn)
    );
  }

  isMothersMaidenNameValid(newForm) {
    const form = newForm || this.state.form;
    return form.mothersMaidenName && form.mothersMaidenName.length > 2;
  }

  isPlaceOfBirthValid(newForm) {
    const form = newForm || this.state.form;
    return form.placeOfBirth && form.placeOfBirth.length > 2;
  }

  isStateValid(newForm) {
    const form = newForm || this.state.form;
    return form.state && Boolean(parseInt(form.state));
  }

  isUsernameValid(newForm) {
    const form = newForm || this.state.form;
    return form.username && form.username.length > 2;
  }

  async onFileSelect(event, filename) {
    const currentApplication = JSON.parse(
      localStorage.getItem("currentApplication")
    );
    const name = event.target.files[0].name;
    const file = event.target.files[0];

    if (file.size > THREE_MB) {
      this.setState({
        error: true,
        uploadError: false,
      });
      return;
    } else {
      this.setState({
        isLoading:
          filename === PROOF_OF_ID
            ? true
            : filename === PASSPORT_PHOTO
            ? true
            : false,
      });
      const uploadDoc = await this.onboarding.documentUpload(
        currentApplication.applicationId,
        `${filename}${PERSONAL_DOC_SUFFIX}`,
        file
      );
      if (uploadDoc.status === ERROR_STATUS) {
        this.setState({
          error: false,
          uploadError: true,
          isLoading:
            filename === PROOF_OF_ID
              ? false
              : filename === PASSPORT_PHOTO
              ? false
              : true,
        });
      } else {
        const newAttachedDocument = {
          name: `${name}${PERSONAL_DOC_SUFFIX}`,
          filename: `${filename}${PERSONAL_DOC_SUFFIX}`,
          file: file,
          url: URL.createObjectURL(file),
        };

        const newAttachedDocuments = [
          ...this.state.attachedDocuments,
          newAttachedDocument,
        ];

        this.setState({
          attachedDocuments: newAttachedDocuments,
          error: false,
          uploadError: false,
          isLoading: false,
        });

        this.updateFormField({ attachedDocuments: newAttachedDocuments });
      }
    }
  }

  onFileUnselect(unselectedAttachment, filename) {
    const newAttachedDocuments = this.state.attachedDocuments.filter(
      (value) => value.filename !== unselectedAttachment.filename
    );

    this.setState({
      attachedDocuments: newAttachedDocuments,
    });

    this.updateFormField({ attachedDocuments: newAttachedDocuments });
  }

  onLgaSelect(stateId) {
    //this.fetchAllLgas(this.state.form.nationality, stateId)
  }

  onNationalitySelect(countryId) {
    this.fetchAllStates(countryId);
  }

  onStateSelect(stateId) {
    this.fetchAllLgas(this.state.form.nationality, stateId);
  }

  propagateFormErrors() {
    this.setState({
      propagateFormErrors: true,
    });
  }

  stripNonNumbers(str) {
    return str.replace(/\D/g, "");
  }

  stripNotAllowedCharactersFromString(str) {
    return str.replace(/[!@#$%^&*(),.?":{}|_`¬<>+=£\\/;[\]]~/g, "");
  }

  stripNumbersFromString(str) {
    return str.replace(/[0-9]/g, "");
  }

  stripSpacesFromString(str) {
    return str.replace(/ /g, "");
  }

  toHideContactInformationHeader() {
    return (
      !this.props.showPreviouslySavedFields &&
      this.state.previouslySavedFields.firstName &&
      this.state.previouslySavedFields.middleName &&
      this.state.previouslySavedFields.referralCode &&
      this.state.previouslySavedFields.lastName &&
      this.state.previouslySavedFields.phone &&
      this.state.previouslySavedFields.email
    );
  }

  toHidePersonalInformationHeader() {
    return (
      !this.props.showPreviouslySavedFields &&
      this.state.previouslySavedFields.dateOfBirth &&
      this.state.previouslySavedFields.gender &&
      this.state.previouslySavedFields.placeOfBirth &&
      this.state.previouslySavedFields.identificationNumber &&
      this.state.previouslySavedFields.identificationType &&
      this.state.previouslySavedFields.bvn &&
      this.state.previouslySavedFields.mothersMaidenName
    );
  }

  toHideResidentialAddressHeader() {
    return (
      !this.props.showPreviouslySavedFields &&
      this.state.previouslySavedFields.nationality &&
      this.state.previouslySavedFields.address &&
      this.state.previouslySavedFields.state &&
      this.state.previouslySavedFields.lga &&
      this.state.previouslySavedFields.closestLandmark
    );
  }

  toHideAttachments() {
    const personalDetailsDocuments =
      this.state.originalAttachedDocuments.filter((value) =>
        value.documentType.endsWith(PERSONAL_DOC_SUFFIX)
      );

    return (
      !this.props.showPreviouslySavedFields &&
      personalDetailsDocuments.length > 0
    );
  }

  updateFormField(params) {
    if (!this.state.updatesHaveBeenMade) {
      this.setState({
        updatesHaveBeenMade: !this.state.updatesHaveBeenMade,
      });
    }

    const newForm = {
      ...this.state.form,
      ...params,
    };

    this.setState({
      form: newForm,
      formIsValid:
        this.isMiddleNameValid(newForm) &&
        this.isClosestLandmarkValid(newForm) &&
        this.isAddressValid(newForm) &&
        this.isDateOfBirthValid(newForm) &&
        newForm.gender &&
        this.isPlaceOfBirthValid(newForm) &&
        this.isIdentificationTypeValid(newForm) &&
        this.isIdentificationNumberValid(newForm) &&
        this.isBvnValid(newForm) &&
        this.isMothersMaidenNameValid(newForm) &&
        this.isAttachedDocumentsValid(newForm),
    });

    this.props.onUpdateFormField(newForm);
  }

  get documentsToAttach() {
    return [PROOF_OF_ID, PASSPORT_PHOTO];
  }

  getDocumentForThumbnail(attachedDocuments, filename) {
    return attachedDocuments.find((value) => {
      const name = value.filename ? value.filename : value.documentType;
      return name.includes(filename);
    });
  }

  onEmailUpdate() {
    this.setState({
      isEmailExisting: "",
      emailError: false,
    });
  }

  async validateEmail(email) {
    if (!email) {
      return;
    }
    this.setState({
      isEmailLoading: true,
    });
    const validateUserObj = await this.userManagement.validateUserOnPassport(
      email
    );
    const { code } = validateUserObj;
    if (code === SUCCESS_CODE) {
      this.setState({
        isEmailExisting: USED_EMAIL,
        isEmailLoading: false,
        emailError: false,
      });
    } else if (code === HTTP_NOT_FOUND_CODE) {
      this.setState({
        isEmailExisting: NEW_EMAIL,
        isEmailLoading: false,
        emailError: false,
      });
    } else {
      this.setState({
        emailError: true,
        isEmailLoading: false,
      });
    }
  }

  render() {
    const currentUser = localStorage.getItem("currentUser");
    const currentApplication = JSON.parse(
      localStorage.getItem("currentApplication")
    );

    const screenSensitiveStyle = !this.state.deviceIsMobile
      ? {
          padding: "0px 25px 10px 25px",
        }
      : {};

    return (
      <Form style={screenSensitiveStyle}>
        <div>
          <H1 style={{ marginTop: 10 }}>
            Personal Details
            <MdArrowDropright
              color={process.env.REACT_APP_BLUE_COLOUR}
              className="grow"
              onClick={() => {
                if (this.state.formIsValid === true) {
                  this.props.onSubmit(this.state.form);
                }

                this.propagateFormErrors();
                return;
              }}
              style={{
                height: "30px",
                marginLeft: "15pt",
              }}
            />
          </H1>

          <H1
            hidden={this.toHideContactInformationHeader()}
            ref={this.props.formHeaderRef}
            style={{
              color: "#3A3B3B",
              fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
              fontSize: "24px",
              fontWeight: "400",
              padding: "35px",
              paddingLeft: 0,
              textAlign: "left",
            }}
          >
            Contact Information
          </H1>

          <Row>
            <Col
              xs={12}
              md={6}
              lg={4}
              hidden={!this.props.showPreviouslySavedFields}
            >
              <Form.Group controlId="personal-details-form.firstName">
                <Form.Label
                  style={{
                    color: process.env.REACT_APP_LIGHT_GREY_COLOUR,
                    fontSize: "16px",
                    lineHeight: "19px",
                  }}
                >
                  First Name: <span className="required-field-tick">*</span>
                </Form.Label>
                <FormControl
                  disabled
                  defaultValue={this.state.form.firstName}
                  type="text"
                  placeholder="Your first name"
                  onChange={(event) => {
                    const firstName = event.target.value;
                    this.updateFormField({ firstName });
                  }}
                  style={{
                    color: process.env.REACT_APP_LIGHT_GREY_COLOUR,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.form.firstName || this.isFirstNameValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: "14px",
                    lineHeight: "17px",
                  }}
                />
                {!this.state.form.firstName || this.isFirstNameValid() ? (
                  <React.Fragment />
                ) : (
                  <Form.Label
                    style={{
                      color: process.env.REACT_APP_RED_COLOUR,
                      fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                      lineHeight: "19px",
                      position: "absolute",
                      width: "100%",
                      left: 25,
                    }}
                  >
                    First name is not valid.
                  </Form.Label>
                )}
              </Form.Group>
            </Col>
            <Col
              xs={12}
              md={6}
              lg={4}
              hidden={
                !this.props.showPreviouslySavedFields &&
                this.state.previouslySavedFields.middleName
              }
            >
              <Form.Group controlId="personal-details-form.middleName">
                <Form.Label
                  style={{
                    color: `${
                      !this.state.form.middleName || this.isMiddleNameValid()
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    fontSize: "16px",
                    lineHeight: "19px",
                  }}
                >
                  Middle Name:{" "}
                  <span className="optional-field-tick">(Optional)</span>
                </Form.Label>
                <FormControl
                  value={this.state.form.middleName}
                  maxLength={MAX_NAME_LENGTH}
                  type="text"
                  placeholder="Your middle name"
                  onChange={(event) => {
                    const middleName = this.stripNotAllowedCharactersFromString(
                      this.stripNumbersFromString(event.target.value)
                    );
                    this.updateFormField({ middleName });
                  }}
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.form.middleName || this.isMiddleNameValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: "14px",
                    lineHeight: "17px",
                  }}
                />
                {!this.state.form.middleName || this.isMiddleNameValid() ? (
                  <React.Fragment />
                ) : (
                  <Form.Label
                    style={{
                      color: process.env.REACT_APP_RED_COLOUR,
                      fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                      lineHeight: "19px",
                      position: "absolute",
                      width: "100%",
                      left: 25,
                    }}
                  >
                    Middle name is not valid.
                  </Form.Label>
                )}
              </Form.Group>
            </Col>

            <Col
              xs={12}
              md={6}
              lg={4}
              hidden={!this.props.showPreviouslySavedFields}
            >
              <Form.Group controlId="personal-details-form.lastName">
                <Form.Label
                  style={{
                    color: process.env.REACT_APP_LIGHT_GREY_COLOUR,
                    fontSize: "16px",
                    lineHeight: "19px",
                  }}
                >
                  Last name: <span className="required-field-tick">*</span>
                </Form.Label>
                <FormControl
                  disabled
                  defaultValue={this.state.form.lastName}
                  type="text"
                  placeholder="Your Last name"
                  onChange={(event) => {
                    const lastName = event.target.value;
                    this.updateFormField({ lastName });
                  }}
                  style={{
                    color: process.env.REACT_APP_LIGHT_GREY_COLOUR,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.form.lastName || this.isLastNameValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: "14px",
                    lineHeight: "17px",
                  }}
                />
                {!this.state.form.lastName || this.isLastNameValid() ? (
                  <React.Fragment />
                ) : (
                  <Form.Label
                    style={{
                      color: process.env.REACT_APP_RED_COLOUR,
                      fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                      lineHeight: "19px",
                      position: "absolute",
                      width: "100%",
                      left: 25,
                    }}
                  >
                    Last name is not valid.
                  </Form.Label>
                )}
              </Form.Group>
            </Col>
            <Col
              xs={12}
              md={6}
              lg={4}
              hidden={!this.props.showPreviouslySavedFields}
            >
              <Form.Group controlId="personal-details-form.phone">
                <Form.Label
                  style={{
                    color: process.env.REACT_APP_LIGHT_GREY_COLOUR,
                    fontSize: "16px",
                    lineHeight: "19px",
                  }}
                >
                  Phone Number: <span className="required-field-tick">*</span>
                </Form.Label>
                <Row style={{ margin: 0, padding: 0 }}>
                  <Col xs={4} md={4} style={{ margin: 0, padding: 0 }}>
                    <Dropdown disabled>
                      <Dropdown.Toggle as={CountryFlagDropdownToggle} disabled>
                        <ReactCountryFlag
                          code={this.state.countryShortCode}
                          styleProps={{ height: "30px", width: "30px" }}
                          svg
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu as={CountryFlagDropdownMenu}>
                        {this.state.countries.map((country) => {
                          return (
                            <Dropdown.Item
                              onSelect={() => {
                                this.updateFormField({
                                  phonePrefix: country.dialCode,
                                });
                                this.setState({
                                  countryShortCode: country.alphabeticCode2,
                                  countryShortCodeSelected: true,
                                });
                              }}
                            >
                              {`${country.dialCode} ${country.name}`}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                  <Col xs={8} md={8} style={{ margin: 0, padding: 0 }}>
                    <FormControl
                      disabled
                      defaultValue={this.state.form.phone}
                      type="tel"
                      placeholder="08012345678"
                      onChange={(event) => {
                        const phone = event.target.value;
                        this.updateFormField({ phone });
                      }}
                      style={{
                        color: process.env.REACT_APP_LIGHT_GREY_COLOUR,
                        backgroundColor: "#FFFFFF",
                        borderRadius: "20px",
                        border: `1px solid ${
                          !this.state.form.phone || this.isPhoneValid()
                            ? "#DFE2E6"
                            : process.env.REACT_APP_RED_COLOUR
                        }`,
                        boxSizing: "border-box",
                        fontSize: "14px",
                        lineHeight: "17px",
                      }}
                    />
                  </Col>
                </Row>
                {!this.state.form.phone || this.isPhoneValid() ? (
                  <React.Fragment />
                ) : (
                  <Form.Label
                    style={{
                      color: process.env.REACT_APP_RED_COLOUR,
                      fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                      lineHeight: "19px",
                      position: "absolute",
                      width: "100%",
                      left: 25,
                    }}
                  >
                    Phone number is not valid.
                  </Form.Label>
                )}
              </Form.Group>
            </Col>
            <Col
              xs={12}
              md={6}
              lg={4}
              hidden={
                !this.props.showPreviouslySavedFields &&
                this.state.previouslySavedFields.email
              }
            >
              <Form.Group controlId="personal-details-form.email">
                <Form.Label
                  style={{
                    color: `${
                      !this.state.form.email || this.isEmailValid()
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    fontSize: "16px",
                    lineHeight: "19px",
                  }}
                >
                  Email Address:
                </Form.Label>
                <FormControl
                  defaultValue={this.state.form.email}
                  type="email"
                  placeholder="john@gmail.com"
                  onBlur={(e) => {
                    isEmailValid(e.target.value) &&
                      this.validateEmail(e.target.value);
                  }}
                  onChange={(event) => {
                    this.onEmailUpdate();
                    const email = event.target.value;
                    this.updateFormField({ email });
                  }}
                  style={{
                    color: process.env.REACT_APP_LIGHT_GREY_COLOUR,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.form.email || this.isEmailValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: "14px",
                    lineHeight: "17px",
                  }}
                />
                <img
                  style={{
                    height: "24px",
                    width: "24px",
                    opacity: "1",
                    marginLeft: "93%",
                    marginTop: "-51pt",
                    position: "relative",
                    zIndex: "2",
                  }}
                  src={
                    this.state.isEmailLoading
                      ? "/assets/media/icons/spinner.svg"
                      : ""
                  }
                  alt=""
                />
                {this.state.isEmailExisting === USED_EMAIL &&
                  this.state.form.email && (
                    <FormLabel
                      style={{
                        color: process.env.REACT_APP_RED_COLOUR,
                        fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                        lineHeight: "19px",
                        position: "absolute",
                        width: "100%",
                        left: 25,
                      }}
                    >
                      Email already registered on Quickteller Paypoint. Try
                      another email!
                    </FormLabel>
                  )}
              </Form.Group>
            </Col>
            <Col
              xs={12}
              md={6}
              lg={4}
              hidden={
                !this.props.showPreviouslySavedFields &&
                this.state.previouslySavedFields.referralCode
              }
            >
              <Form.Group controlId="personal-details-form.super-agent">
                <Form.Label
                  style={{
                    color: `${
                      !this.state.form.referralCode ||
                      this.isReferralCodeValid()
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    fontSize: "16px",
                    lineHeight: "19px",
                  }}
                >
                  Super Agent:{" "}
                  <span className="optional-field-tick">(Optional)</span>
                </Form.Label>

                <Dropdown>
                  <Dropdown.Toggle
                    as={CustomToggle}
                    id="dropdown-custom-components"
                  >
                    <FormControl
                      placeholder="Select Super Agent"
                      value={
                        this.state.form.referralCode
                          ? (
                              this.state.superAgents.find(
                                (value) =>
                                  value.referralCode ===
                                  this.state.form.referralCode
                              ) || {}
                            ).businessName
                          : ""
                      }
                      readOnly
                      onBlur={() =>
                        this.setState({
                          fieldsFocused: [
                            ...this.state.fieldsFocused,
                            "referralCode",
                          ],
                        })
                      }
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "20px",
                        border: "1px solid #DFE2E6",
                        boxSizing: "border-box",
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        lineHeight: "17px",
                        width: "100%",
                      }}
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu as={CustomMenu}>
                    {this.state.superAgents.map((value) => (
                      <Dropdown.Item
                        onClick={() =>
                          this.updateFormField({
                            referralCode: value.referralCode,
                          })
                        }
                        eventKey={value.referralCode}
                      >
                        {value.businessName}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
            </Col>
            {/* <Col xs={12} md={6} lg={4} hidden={!this.props.showPreviouslySavedFields}>
          <Form.Group controlId="how-you-heard-about-us" className="form-group">
            <Form.Label style={{color: `${!form.howYouHeardAboutUs && !this.state.fieldsFocused.includes('howYouHeardAboutUs') && !this.state.propagateFormErrors || form.howYouHeardAboutUs ? '#3A3B3B' : process.env.REACT_APP_RED_COLOUR}`, fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '19px'}}>How did you hear about us? <span className="required-field-tick">*</span></Form.Label>
            <FormControl 
              onBlur={() => this.setState({
                fieldsFocused: [...this.state.fieldsFocused, 'howYouHeardAboutUs']
              })}
              onChange={(event) => {
                const howYouHeardAboutUs = event.target.value
                this.updateFormField({howYouHeardAboutUs})
              }} style={{backgroundColor: '#FFFFFF', borderRadius: '20px', border: '1px solid #DFE2E6', boxSizing: 'border-box', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: '17px', width: '100%'}} as="select">
              <option value={"0"}>Select an option</option>
              <option value="TV/Radio">TV/Radio</option>
              <option value="Social Media">Social Media</option>
              <option value="Market Storm">Market Storm</option>
              <option value="From a friend">From a friend</option>
              <option value="Referred by an Agent">Referred by an Agent</option>
              <option value="Other">Other</option>
            </FormControl>
            {(this.state.propagateFormErrors || this.state.fieldsFocused.includes('howYouHeardAboutUs')) && !form.howYouHeardAboutUs && <FormLabel style={{color: process.env.REACT_APP_RED_COLOUR, fontSize: process.env.REACT_APP_SMALL_FONT_SIZE, lineHeight: '19px', position: 'absolute', width: '100%', left: 25}}>Field is required.</FormLabel>}
          </Form.Group>
        </Col> */}
          </Row>
        </div>

        <div>
          <H1
            hidden={this.toHideResidentialAddressHeader()}
            style={{
              color: "#3A3B3B",
              fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
              fontSize: "24px",
              fontWeight: "400",
              padding: "35px",
              paddingLeft: 0,
              textAlign: "left",
            }}
          >
            Residential Address
          </H1>

          <Row>
            <Col
              xs={12}
              md={6}
              lg={4}
              hidden={
                !this.props.showPreviouslySavedFields &&
                this.state.previouslySavedFields.nationality
              }
            >
              <Form.Group controlId="personal-details-form.country-select">
                <Form.Label
                  style={{
                    color: "#3A3B3B",
                    fontSize: "16px",
                    lineHeight: "19px",
                  }}
                >
                  Country: <span className="required-field-tick">*</span>
                </Form.Label>
                <FormControl
                  defaultValue={this.state.form.nationality}
                  as="select"
                  onChange={(event) => {
                    const country = event.target.value;

                    this.updateFormField({ country, nationality: country });
                    this.onNationalitySelect(country);
                  }}
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.lastName || this.isLastNameValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: "14px",
                    lineHeight: "17px",
                  }}
                >
                  <option value={"0"}>Select an option</option>
                  {this.state.countries.map((value) => (
                    <option
                      selected={
                        parseInt(value.id) ===
                        parseInt(this.state.form.nationality)
                          ? "selected"
                          : false
                      }
                      value={value.id}
                    >
                      {value.name}
                    </option>
                  ))}
                </FormControl>
              </Form.Group>
            </Col>

            <Col xs={12} md={6} lg={4} hidden={true}>
              <Form.Group>
                <Form.Label
                  style={{
                    color: `${
                      !this.state.form.town || this.isTownValid()
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    fontSize: "16px",
                    lineHeight: "19px",
                  }}
                >
                  Town:
                </Form.Label>
                <FormControl
                  defaultValue={this.state.form.town}
                  type="text"
                  placeholder="Town"
                  onChange={(event) => {
                    const town = event.target.value;
                    this.updateFormField({ town });
                  }}
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.form.town || this.isTownValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: "14px",
                    lineHeight: "17px",
                  }}
                />
              </Form.Group>
            </Col>
            <Col
              xs={12}
              md={6}
              lg={4}
              hidden={
                !this.props.showPreviouslySavedFields &&
                this.state.previouslySavedFields.state
              }
            >
              <Form.Group controlId="personal-details-form.state-select">
                <Form.Label
                  style={{
                    color: `${
                      (!this.state.form.state &&
                        !this.state.fieldsFocused.includes("state") &&
                        !this.state.propagateFormErrors) ||
                      this.isStateValid()
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    fontSize: "16px",
                    lineHeight: "19px",
                  }}
                >
                  State: <span className="required-field-tick">*</span>
                </Form.Label>
                <FormControl
                  defaultValue={this.state.form.state}
                  as="select"
                  onChange={(event) => {
                    const state = event.target.value;

                    this.updateFormField({ state });
                    this.onStateSelect(state);
                  }}
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.form.state || this.isStateValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: "14px",
                    lineHeight: "17px",
                  }}
                >
                  <option value={"0"}>Select an option</option>
                  {this.state.states.map((value) => (
                    <option
                      selected={
                        parseInt(value.id) === parseInt(this.state.form.state)
                          ? "selected"
                          : false
                      }
                      value={value.id}
                    >
                      {value.name}
                    </option>
                  ))}
                </FormControl>
                {!this.isStateValid() &&
                  (this.state.propagateFormErrors ||
                    this.state.fieldsFocused.includes("state")) && (
                    <Form.Label
                      style={{
                        color: process.env.REACT_APP_RED_COLOUR,
                        fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                        lineHeight: "19px",
                        position: "absolute",
                        width: "100%",
                        left: 25,
                      }}
                    >
                      State is required.
                    </Form.Label>
                  )}
              </Form.Group>
            </Col>
            <Col
              xs={12}
              md={6}
              lg={4}
              hidden={
                !this.props.showPreviouslySavedFields &&
                this.state.previouslySavedFields.lga
              }
            >
              <Form.Group controlId="personal-details-form.lga-select">
                <Form.Label
                  style={{
                    color: `${
                      (!this.state.form.lga &&
                        !this.state.fieldsFocused.includes("lga") &&
                        !this.state.propagateFormErrors) ||
                      this.isLgaValid()
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    fontSize: "16px",
                    lineHeight: "19px",
                  }}
                >
                  LGA: <span className="required-field-tick">*</span>
                </Form.Label>
                <FormControl
                  defaultValue={this.state.form.lga}
                  as="select"
                  onChange={(event) => {
                    const lga = event.target.value;
                    this.updateFormField({ lga });
                    this.onLgaSelect(lga);
                  }}
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.form.lga || this.isLgaValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: "14px",
                    lineHeight: "17px",
                  }}
                >
                  <option value={"0"}>Select an option</option>
                  {this.state.lgas.map((value) => (
                    <option
                      selected={
                        parseInt(value.id) === parseInt(this.state.form.lga)
                          ? "selected"
                          : false
                      }
                      value={value.id}
                    >
                      {value.name}
                    </option>
                  ))}
                </FormControl>
                {!this.isLgaValid() &&
                  (this.state.propagateFormErrors ||
                    this.state.fieldsFocused.includes("lga")) && (
                    <Form.Label
                      style={{
                        color: process.env.REACT_APP_RED_COLOUR,
                        fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                        lineHeight: "19px",
                        position: "absolute",
                        width: "100%",
                        left: 25,
                      }}
                    >
                      LGA is required.
                    </Form.Label>
                  )}
              </Form.Group>
            </Col>

            <Col xs={12} md={6} lg={4} hidden={true}>
              <Form.Group>
                <Form.Label
                  style={{
                    color: `${
                      !this.state.form.postalCode || this.isPostalCodeValid()
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    fontSize: "16px",
                    lineHeight: "19px",
                  }}
                >
                  Postal code:
                </Form.Label>
                <FormControl
                  defaultValue={this.state.form.postalCode}
                  type="tel"
                  placeholder="Postal code"
                  onChange={(event) => {
                    const postalCode = event.target.value;
                    this.updateFormField({ postalCode });
                  }}
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.form.postalCode || this.isPostalCodeValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: "14px",
                    lineHeight: "17px",
                  }}
                />
              </Form.Group>
            </Col>

            <Col
              xs={12}
              md={6}
              lg={8}
              hidden={
                !this.props.showPreviouslySavedFields &&
                this.state.previouslySavedFields.address
              }
            >
              <Form.Group>
                <Form.Label
                  style={{
                    color: `${
                      (!this.state.form.address &&
                        !this.state.fieldsFocused.includes("address") &&
                        !this.state.propagateFormErrors) ||
                      this.isAddressValid()
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    fontSize: "16px",
                    lineHeight: "19px",
                  }}
                >
                  Address: <span className="required-field-tick">*</span>
                </Form.Label>
                <FormControl
                  as="textarea"
                  value={this.state.form.address}
                  maxLength={MAX_DESCRIPTION_LENGTH}
                  type="text"
                  placeholder={this.state.form.address || "Address"}
                  onBlur={() =>
                    this.setState({
                      fieldsFocused: [...this.state.fieldsFocused, "address"],
                    })
                  }
                  onChange={(event) => {
                    const address = this.stripNotAllowedCharactersFromString(
                      event.target.value
                    );
                    this.updateFormField({ address });
                  }}
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "10px",
                    border: `1px solid ${
                      !this.state.form.address || this.isAddressValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: "14px",
                    lineHeight: "17px",
                  }}
                >
                  {this.state.form.address}
                </FormControl>
                {(this.state.propagateFormErrors ||
                  this.state.fieldsFocused.includes("address")) &&
                  !this.state.form.address && (
                    <Form.Label
                      style={{
                        color: process.env.REACT_APP_RED_COLOUR,
                        fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                        lineHeight: "19px",
                        position: "absolute",
                        width: "100%",
                        left: 25,
                      }}
                    >
                      Address is required.
                    </Form.Label>
                  )}
                {!this.state.form.address || this.isAddressValid() ? (
                  <React.Fragment />
                ) : (
                  <Form.Label
                    style={{
                      color: process.env.REACT_APP_RED_COLOUR,
                      fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                      lineHeight: "19px",
                      position: "absolute",
                      width: "100%",
                      left: 25,
                    }}
                  >
                    Address is invalid.
                  </Form.Label>
                )}
              </Form.Group>
            </Col>
            <Col
              xs={12}
              md={6}
              lg={4}
              hidden={
                !this.props.showPreviouslySavedFields &&
                this.state.previouslySavedFields.closestLandmark
              }
            >
              <Form.Group>
                <Form.Label
                  style={{
                    color: `${
                      (!this.state.form.closestLandmark &&
                        !this.state.fieldsFocused.includes("closestLandmark") &&
                        !this.state.propagateFormErrors) ||
                      this.isClosestLandmarkValid()
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    fontSize: "16px",
                    lineHeight: "19px",
                  }}
                >
                  Closest Landmark:{" "}
                  <span className="required-field-tick">*</span>
                </Form.Label>
                <FormControl
                  value={this.state.form.closestLandmark}
                  maxLength={MAX_DESCRIPTION_LENGTH}
                  placeholder="Closest landmark"
                  onChange={(event) => {
                    const closestLandmark =
                      this.stripNotAllowedCharactersFromString(
                        event.target.value
                      );
                    this.updateFormField({ closestLandmark });
                  }}
                  onBlur={() =>
                    this.setState({
                      fieldsFocused: [
                        ...this.state.fieldsFocused,
                        "closestLandmark",
                      ],
                    })
                  }
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.firstName || this.isClosestLandmarkValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: "14px",
                    lineHeight: "17px",
                  }}
                />
                {(this.state.propagateFormErrors ||
                  this.state.fieldsFocused.includes("closestLandmark")) &&
                  !this.state.form.closestLandmark && (
                    <Form.Label
                      style={{
                        color: process.env.REACT_APP_RED_COLOUR,
                        fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                        lineHeight: "19px",
                        position: "absolute",
                        width: "100%",
                        left: 25,
                      }}
                    >
                      Closest landmark is required.
                    </Form.Label>
                  )}
                {!this.state.form.closestLandmark ||
                this.isClosestLandmarkValid() ? (
                  <React.Fragment />
                ) : (
                  <Form.Label
                    style={{
                      color: process.env.REACT_APP_RED_COLOUR,
                      fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                      lineHeight: "19px",
                      position: "absolute",
                      width: "100%",
                      left: 25,
                    }}
                  >
                    Closest landmark is invalid.
                  </Form.Label>
                )}
              </Form.Group>
            </Col>
          </Row>
        </div>

        <div>
          <H1
            hidden={this.toHidePersonalInformationHeader()}
            style={{
              color: "#3A3B3B",
              fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
              fontSize: "24px",
              fontWeight: "400",
              padding: "35px",
              paddingLeft: 0,
              textAlign: "left",
            }}
          >
            Personal Information
          </H1>

          <Row>
            <Col
              xs={12}
              md={6}
              lg={4}
              hidden={
                !this.props.showPreviouslySavedFields &&
                this.state.previouslySavedFields.dateOfBirth
              }
            >
              <Form.Group>
                <Form.Label
                  style={{
                    color: `${
                      (!this.state.form.dateOfBirth &&
                        !this.state.fieldsFocused.includes("dateOfBirth") &&
                        !this.state.propagateFormErrors) ||
                      this.isDateOfBirthValid()
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    fontSize: "16px",
                    lineHeight: "19px",
                  }}
                >
                  Date of Birth: <span className="required-field-tick">*</span>
                </Form.Label>
                <Col xs={12}>
                  <DatePicker
                    customInput={
                      <FormControl
                        maxLength={MAX_DESCRIPTION_LENGTH}
                        placeholder="DD-MM-YYYY"
                        value={this.state.form.dateOfBirth}
                        onBlur={() =>
                          this.setState({
                            fieldsFocused: [
                              ...this.state.fieldsFocused,
                              "dateOfBirth",
                            ],
                          })
                        }
                        style={{
                          backgroundColor: "#FFFFFF",
                          borderRadius: "20px",
                          border: `1px solid ${
                            !this.state.firstName ||
                            this.isClosestLandmarkValid()
                              ? "#DFE2E6"
                              : process.env.REACT_APP_RED_COLOUR
                          }`,
                          boxSizing: "border-box",
                          fontSize: "14px",
                          lineHeight: "17px",
                        }}
                      />
                    }
                    dateFormat="dd-MM-yyyy"
                    showYearDropdown
                    minDate={subtractDays(new Date(), 365 * 70)}
                    maxDate={subtractDays(new Date(), 365 * 18)}
                    selected={this.state.form.dateOfBirth}
                    onBlur={() =>
                      this.setState({
                        fieldsFocused: [
                          ...this.state.fieldsFocused,
                          "dateOfBirth",
                        ],
                      })
                    }
                    onChange={(value) => {
                      this.updateFormField({ dateOfBirth: value });
                    }}
                    placeholderText="DD-MM-YYYY"
                    className="date-picker"
                    stylea={{
                      border: `1px solid ${
                        (!this.state.form.dateOfBirth &&
                          !this.state.propagateFormErrors) ||
                        this.isDateOfBirthValid()
                          ? "#DFE2E6"
                          : process.env.REACT_APP_RED_COLOUR
                      }`,
                      borderRadius: "20px",
                      padding: 10,
                    }}
                    disabled={true}
                  />
                </Col>
                {(this.state.propagateFormErrors ||
                  this.state.fieldsFocused.includes("dateOfBirth")) &&
                  !this.state.form.dateOfBirth && (
                    <Form.Label
                      style={{
                        color: process.env.REACT_APP_RED_COLOUR,
                        fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                        lineHeight: "19px",
                        position: "absolute",
                        width: "100%",
                        left: 25,
                      }}
                    >
                      Date of Birth is required.
                    </Form.Label>
                  )}
                {!this.state.form.dateOfBirth || this.isDateOfBirthValid() ? (
                  <React.Fragment />
                ) : (
                  <Form.Label
                    style={{
                      color: process.env.REACT_APP_RED_COLOUR,
                      fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                      lineHeight: "19px",
                      position: "absolute",
                      width: "100%",
                      left: 25,
                    }}
                  >
                    Date of Birth is invalid.
                  </Form.Label>
                )}
              </Form.Group>
            </Col>

            <Col
              xs={12}
              md={6}
              lg={4}
              hidden={
                !this.props.showPreviouslySavedFields &&
                this.state.previouslySavedFields.gender
              }
            >
              <Form.Group controlId="gender">
                <Form.Label
                  style={{
                    color: `${
                      this.state.form.gender || !this.state.propagateFormErrors
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    fontSize: "16px",
                    lineHeight: "19px",
                  }}
                >
                  Gender: <span className="required-field-tick">*</span>
                </Form.Label>
                <Row>
                  <Col>
                    <Form.Check
                      checked={this.state.form.gender === "MALE"}
                      inline
                      type="radio"
                      onChange={(event) => {
                        this.updateFormField({ gender: "MALE" });
                      }}
                      style={{
                        backgroundColor: "#FFFFFF",
                        fontSize: "14px",
                        lineHeight: "17px",
                        width: "100%",
                      }}
                      id="male-gender"
                      label={`Male`}
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      checked={this.state.form.gender === "FEMALE"}
                      inline
                      type="radio"
                      onChange={(event) => {
                        this.updateFormField({ gender: "FEMALE" });
                      }}
                      style={{
                        backgroundColor: "#FFFFFF",
                        fontSize: "14px",
                        lineHeight: "17px",
                        width: "100%",
                      }}
                      id="female-gender"
                      label={`Female`}
                    />
                  </Col>
                  <Col />
                </Row>
              </Form.Group>
            </Col>

            <Col
              xs={12}
              md={6}
              lg={4}
              hidden={
                !this.props.showPreviouslySavedFields &&
                this.state.previouslySavedFields.placeOfBirth
              }
            >
              <Form.Group>
                <Form.Label
                  style={{
                    color: `${
                      (!this.state.form.placeOfBirth &&
                        !this.state.fieldsFocused.includes("placeOfBirth") &&
                        !this.state.propagateFormErrors) ||
                      this.isPlaceOfBirthValid()
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    fontSize: "16px",
                    lineHeight: "19px",
                  }}
                >
                  Place of Birth: <span className="required-field-tick">*</span>
                </Form.Label>
                <FormControl
                  defaultValue={this.state.form.placeOfBirth}
                  maxLength={MAX_PLACE_OF_BIRTH_LENGTH}
                  type="text"
                  placeholder="Place of Birth"
                  onBlur={() =>
                    this.setState({
                      fieldsFocused: [
                        ...this.state.fieldsFocused,
                        "placeOfBirth",
                      ],
                    })
                  }
                  onChange={(event) => {
                    const placeOfBirth =
                      this.stripNotAllowedCharactersFromString(
                        event.target.value
                      );
                    this.updateFormField({ placeOfBirth });
                  }}
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.form.placeOfBirth ||
                      this.isPlaceOfBirthValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: "14px",
                    lineHeight: "17px",
                  }}
                />
                {(this.state.propagateFormErrors ||
                  this.state.fieldsFocused.includes("placeOfBirth")) &&
                  !this.state.form.placeOfBirth && (
                    <Form.Label
                      style={{
                        color: process.env.REACT_APP_RED_COLOUR,
                        fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                        lineHeight: "19px",
                        position: "absolute",
                        width: "100%",
                        left: 25,
                      }}
                    >
                      Place of birth is required.
                    </Form.Label>
                  )}
                {!this.state.form.placeOfBirth || this.isPlaceOfBirthValid() ? (
                  <React.Fragment />
                ) : (
                  <Form.Label
                    style={{
                      color: process.env.REACT_APP_RED_COLOUR,
                      fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                      lineHeight: "19px",
                      position: "absolute",
                      width: "100%",
                      left: 25,
                    }}
                  >
                    Place of birth is invalid.
                  </Form.Label>
                )}
              </Form.Group>
            </Col>

            <Col xs={12} md={6} lg={4} hidden={true}>
              <Form.Group controlId="exampleFormControlSelect2">
                <Form.Label>State of origin:</Form.Label>
                <FormControl
                  defaultValue={this.state.form.stateOfOrigin}
                  as="select"
                  onChange={(event) => {
                    const stateOfOrigin = event.target.value;
                    this.updateFormField({ stateOfOrigin });
                  }}
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.lastName || this.isLastNameValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: "14px",
                    lineHeight: "17px",
                  }}
                >
                  <option>Select State</option>
                  {this.state.states.map((value) => (
                    <option
                      selected={
                        parseInt(value.id) === parseInt(this.state.form.state)
                          ? "selected"
                          : false
                      }
                      value={value.id}
                    >
                      {value.name}
                    </option>
                  ))}
                </FormControl>
              </Form.Group>
            </Col>

            <Col
              xs={12}
              md={6}
              lg={4}
              hidden={
                !this.props.showPreviouslySavedFields &&
                this.state.previouslySavedFields.identificationType
              }
            >
              <Form.Group controlId="exampleFormControlSelect2">
                <Form.Label
                  style={{
                    color: `${
                      (!this.state.form.identificationType &&
                        !this.state.fieldsFocused.includes(
                          "identificationType"
                        ) &&
                        !this.state.propagateFormErrors) ||
                      this.isIdentificationTypeValid()
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    fontSize: "16px",
                    lineHeight: "19px",
                  }}
                >
                  ID Type: <span className="required-field-tick">*</span>
                </Form.Label>
                <FormControl
                  defaultValue={this.state.form.identificationType}
                  as="select"
                  onBlur={() =>
                    this.setState({
                      fieldsFocused: [
                        ...this.state.fieldsFocused,
                        "identificationType",
                      ],
                    })
                  }
                  onChange={(event) => {
                    const identificationType = event.target.value;
                    this.updateFormField({ identificationType });
                  }}
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.form.identificationType ||
                      this.isIdentificationTypeValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: "14px",
                    lineHeight: "17px",
                  }}
                >
                  <option>Select ID Type</option>
                  {this.state.identificationTypes.map(({ id, name }) => (
                    <option
                      key={id}
                      selected={
                        parseInt(id) ===
                        parseInt(this.state.form.identificationType)
                          ? "selected"
                          : null
                      }
                      value={id}
                    >
                      {name}
                    </option>
                  ))}
                </FormControl>
                {!this.isIdentificationTypeValid() &&
                  (this.state.propagateFormErrors ||
                    this.state.fieldsFocused.includes(
                      "identificationType"
                    )) && (
                    <Form.Label
                      style={{
                        color: process.env.REACT_APP_RED_COLOUR,
                        fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                        lineHeight: "19px",
                        position: "absolute",
                        width: "100%",
                        left: 25,
                      }}
                    >
                      ID Type is required.
                    </Form.Label>
                  )}
              </Form.Group>
            </Col>

            <Col
              xs={12}
              md={6}
              lg={4}
              hidden={
                !this.props.showPreviouslySavedFields &&
                this.state.previouslySavedFields.identificationNumber
              }
            >
              <Form.Group controlId="idNumber">
                <Form.Label
                  style={{
                    color: `${
                      (!this.state.form.identificationNumber &&
                        !this.state.fieldsFocused.includes(
                          "identificationNumber"
                        ) &&
                        !this.state.propagateFormErrors) ||
                      this.isIdentificationNumberValid()
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                  }}
                >
                  ID Number: <span className="required-field-tick">*</span>
                </Form.Label>
                <FormControl
                  value={this.state.form.identificationNumber}
                  maxLength={MAX_IDENTIFICATION_NUMBER_LENGTH}
                  type="text"
                  placeholder="ID Number"
                  onBlur={() =>
                    this.setState({
                      fieldsFocused: [
                        ...this.state.fieldsFocused,
                        "identificationNumber",
                      ],
                    })
                  }
                  onChange={(event) => {
                    const identificationNumber =
                      this.stripNotAllowedCharactersFromString(
                        this.stripSpacesFromString(event.target.value)
                      );
                    this.updateFormField({ identificationNumber });
                  }}
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.form.identificationNumber ||
                      this.isIdentificationNumberValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: "14px",
                    lineHeight: "17px",
                  }}
                />
                {(this.state.propagateFormErrors ||
                  this.state.fieldsFocused.includes("identificationNumber")) &&
                  !this.state.form.identificationNumber && (
                    <Form.Label
                      style={{
                        color: process.env.REACT_APP_RED_COLOUR,
                        fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                        lineHeight: "19px",
                        position: "absolute",
                        width: "100%",
                        left: 25,
                      }}
                    >
                      Identification number is required.
                    </Form.Label>
                  )}
                {!this.state.form.identificationNumber ||
                this.isIdentificationNumberValid() ? (
                  <React.Fragment />
                ) : (
                  <Form.Label
                    style={{
                      color: process.env.REACT_APP_RED_COLOUR,
                      fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                      lineHeight: "19px",
                      position: "absolute",
                      width: "100%",
                      left: 25,
                    }}
                  >
                    Identification number is invalid.
                  </Form.Label>
                )}
              </Form.Group>
            </Col>

            <Col
              xs={12}
              md={6}
              lg={4}
              hidden={
                !this.props.showPreviouslySavedFields &&
                this.state.previouslySavedFields.nationality
              }
            >
              <Form.Group controlId="exampleFormControlSelect2">
                <Form.Label>Nationality:</Form.Label>
                <FormControl
                  defaultValue={this.state.form.nationality}
                  as="select"
                  onChange={(event) => {
                    const nationality = event.target.value;
                    this.updateFormField({ nationality });
                  }}
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "20px",
                    border: "1px solid #DFE2E6",
                    boxSizing: "border-box",
                    fontSize: "14px",
                    lineHeight: "17px",
                  }}
                >
                  <option>Select Country</option>
                  {this.state.countries.map((value) => (
                    <option
                      selected={
                        parseInt(value.id) ===
                        parseInt(this.state.form.nationality)
                          ? "selected"
                          : false
                      }
                      value={value.id}
                    >
                      {value.name}
                    </option>
                  ))}
                </FormControl>
              </Form.Group>
            </Col>

            <Col
              xs={12}
              md={6}
              lg={4}
              hidden={
                !this.props.showPreviouslySavedFields &&
                this.state.previouslySavedFields.bvn
              }
            >
              <Form.Group>
                <Form.Label
                  style={{
                    color: `${
                      (!this.state.form.bvn &&
                        !this.state.fieldsFocused.includes("bvn") &&
                        !this.state.propagateFormErrors) ||
                      this.isBvnValid(this.state.form)
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    fontSize: "16px",
                    lineHeight: "19px",
                  }}
                >
                  BVN: <span className="required-field-tick">*</span>
                </Form.Label>
                <FormControl
                  maxLength={MAX_BVN_LENGTH}
                  placeholder="12345678910"
                  onBlur={() =>
                    this.setState({
                      fieldsFocused: [...this.state.fieldsFocused, "bvn"],
                    })
                  }
                  onChange={(event) => {
                    const bvn = this.stripNonNumbers(
                      this.stripSpacesFromString(
                        currentApplication.applicantDetails.bvn
                      )
                    ); /* */
                    this.updateFormField({ bvn });
                  }}
                  value={currentApplication.applicantDetails.bvn}
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.form.bvn || this.isBvnValid(this.state.form)
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: "14px",
                    lineHeight: "17px",
                  }}
                  type="text"
                  disabled={true}
                />
                {(this.state.propagateFormErrors ||
                  this.state.fieldsFocused.includes("bvn")) &&
                  !this.state.form.bvn && (
                    <Form.Label
                      style={{
                        color: process.env.REACT_APP_RED_COLOUR,
                        fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                        lineHeight: "19px",
                        position: "absolute",
                        width: "100%",
                        left: 25,
                      }}
                    >
                      BVN is required.
                    </Form.Label>
                  )}
                {!this.state.form.bvn || this.isBvnValid(this.state.form) ? (
                  <React.Fragment />
                ) : (
                  <Form.Label
                    style={{
                      color: process.env.REACT_APP_RED_COLOUR,
                      fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                      lineHeight: "19px",
                      position: "absolute",
                      width: "100%",
                      left: 25,
                    }}
                  >
                    BVN is invalid.
                  </Form.Label>
                )}
              </Form.Group>
            </Col>

            <Col
              xs={12}
              md={6}
              lg={4}
              hidden={
                !this.props.showPreviouslySavedFields &&
                this.state.previouslySavedFields.mothersMaidenName
              }
            >
              <Form.Group>
                <Form.Label
                  style={{
                    color: `${
                      (!this.state.form.mothersMaidenName &&
                        !this.state.fieldsFocused.includes(
                          "mothersMaidenName"
                        ) &&
                        !this.state.propagateFormErrors) ||
                      this.isMothersMaidenNameValid()
                        ? "#3A3B3B"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    fontSize: "16px",
                    lineHeight: "19px",
                  }}
                >
                  Mother's Maiden Name:{" "}
                  <span className="required-field-tick">*</span>
                </Form.Label>
                <FormControl
                  value={this.state.form.mothersMaidenName}
                  maxLength={MAX_MOTHERS_MAIDEN_NAME_LENGTH}
                  type="text"
                  placeholder="Your mother's maiden name"
                  onChange={(event) => {
                    const mothersMaidenName =
                      this.stripNotAllowedCharactersFromString(
                        this.stripNumbersFromString(event.target.value)
                      );
                    this.updateFormField({ mothersMaidenName });
                  }}
                  onBlur={() =>
                    this.setState({
                      fieldsFocused: [
                        ...this.state.fieldsFocused,
                        "mothersMaidenName",
                      ],
                    })
                  }
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "20px",
                    border: `1px solid ${
                      !this.state.mothersMaidenName ||
                      this.isMothersMaidenNameValid()
                        ? "#DFE2E6"
                        : process.env.REACT_APP_RED_COLOUR
                    }`,
                    boxSizing: "border-box",
                    fontSize: "14px",
                    lineHeight: "17px",
                  }}
                />
                {(this.state.propagateFormErrors ||
                  this.state.fieldsFocused.includes("mothersMaidenName")) &&
                  !this.state.form.mothersMaidenName && (
                    <Form.Label
                      style={{
                        color: process.env.REACT_APP_RED_COLOUR,
                        fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                        lineHeight: "19px",
                        position: "absolute",
                        width: "100%",
                        left: 25,
                      }}
                    >
                      Mother's maiden name is required.
                    </Form.Label>
                  )}
                {!this.state.form.mothersMaidenName ||
                this.isMothersMaidenNameValid() ? (
                  <React.Fragment />
                ) : (
                  <Form.Label
                    style={{
                      color: process.env.REACT_APP_RED_COLOUR,
                      fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                      lineHeight: "19px",
                      position: "absolute",
                      width: "100%",
                      left: 25,
                    }}
                  >
                    Mother's maiden name is invalid.
                  </Form.Label>
                )}
              </Form.Group>
            </Col>
          </Row>

          <H1
            hidden={this.toHideAttachments()}
            ref={this.props.formHeaderRef}
            style={{
              color: "#3A3B3B",
              fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
              fontSize: "24px",
              fontWeight: "400",
              padding: "35px",
              paddingLeft: 0,
              textAlign: "left",
            }}
          >
            Attachments
          </H1>
          <Row hidden={this.toHideAttachments()}>
            <Col lg={12}>
              <span
                style={{
                  color:
                    (!this.state.form.attachedDocuments &&
                      !this.state.propagateFormErrors) ||
                    this.isAttachedDocumentsValid()
                      ? "#3A3B3B"
                      : process.env.REACT_APP_RED_COLOUR,
                }}
              >
                Attach necessary documents:
              </span>{" "}
              <span className="required-field-tick">*</span>
              <br />
              <span
                style={{
                  color:
                    (!this.state.form.attachedDocuments &&
                      !this.state.propagateFormErrors) ||
                    this.isAttachedDocumentsValid()
                      ? "#3A3B3B"
                      : process.env.REACT_APP_RED_COLOUR,
                  fontSize: "12px",
                }}
              >
                Upload necessary documents (PDF, PNG, JPG, JPEG) not more than
                3MB in size.
              </span>
            </Col>
            <Col
              style={{
                display: "flex",
                flexWrap: "nowrap",
                marginTop: "10px",
                overflowX: "auto",
                overflowY: "auto",
              }}
            >
              {this.documentsToAttach.map((value) => {
                let filename = value;
                return (
                  <Thumbnail2
                    attachedDocument={this.getDocumentForThumbnail(
                      this.state.attachedDocuments,
                      filename
                    )}
                    filename={filename}
                    onFileSelect={this.onFileSelect}
                    onFileUnselect={this.onFileUnselect}
                    isLoading={this.state.isLoading}
                  />
                );
              })}
            </Col>
            <br />
            {this.state.error && (
              <Col lg={12}>
                <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                  File size is above 3MB
                </span>
              </Col>
            )}
            {this.state.uploadError && (
              <Col lg={12}>
                <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                  Failed to Upload.Try again
                </span>
              </Col>
            )}

            {/* <Col style={{display: 'flex', flexWrap: 'nowrap', marginTop: '10px', overflowX: 'auto', overflowY: 'auto'}}>
            {this.state.attachedDocuments.map(value => {
              const name = value.documentType ? value.documentType : value.name
              if (!name.endsWith(PERSONAL_DOC_SUFFIX)) {
                return <React.Fragment />
              }
              return <Thumbnail suffix={PERSONAL_DOC_SUFFIX} filename={name} style={{width: '100px', height: '100px', marginRight: '10px'}} src={value.url} location={value.documentLink} onRemove={() => this.removeDocument(value)} />
            })}

            <div className="upload-btn-container" style={{cursor: 'pointer', height: '100px', width: '100px'}}>
              <button class="btn" style={{height: '100px', width: '100px'}}>+</button>
              <input class="custom-file-input" style={{color: 'transparent'}} type="file" accept=".pdf,.png,.jpg,.jpeg" onChange={this.onFileSelect} />
            </div>
          </Col> */}
          </Row>
        </div>

        <div
          style={{
            color: process.env.REACT_APP_GREY_COLOUR,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80px",
          }}
          hidden={
            !(
              this.toHideContactInformationHeader() &&
              this.toHideContactInformationHeader() &&
              this.toHideResidentialAddressHeader() &&
              this.toHideAttachments()
            )
          }
        >
          You are done filling your personal details!
        </div>

        <center style={{ marginTop: "10px" }}>
          <Button
            onClick={(e) => {
              e.preventDefault();
              if (this.state.formIsValid === true) {
                this.props.onSubmit(this.state.form);
              }

              this.propagateFormErrors();
              return;
            }}
          >
            Next
          </Button>
        </center>
      </Form>
    );
  }
}
