import React from "react";
import { Accordion, Card } from "react-bootstrap";

const PreviewAccordion = ({ children, title, index, application = false }) => {
  return (
    <div>
      <Accordion defaultActiveKey={index}>
        <div
          style={{
            borderRadius: application ? "0" : "10px",
            border: application ? "none" : "1px solid #01010117",

            marginBottom: "1rem",
          }}
        >
          <Accordion.Toggle
            style={{
              cursor: "pointer",
              color: process.env.REACT_APP_BLUE_COLOUR,
              fontWeight: "600",
              padding: "10px",
              borderRadius: application ? "0" : "10px",
              backgroundColor: application ? "white" : "#F5F5F5",
              borderBottom: application ? "2px solid #01010117" : "",
              outlineBottom: application ? "2px solid #01010117" : "",
            }}
            as={"div"}
            eventKey="1"
          >
            {title}
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="1">
            <div
              style={{
                padding: "20px",
                borderRadius: "10px",
                border: application ? "none" : "1px solid #eef2f4ab",
              }}
            >
              {children}
            </div>
          </Accordion.Collapse>
        </div>
      </Accordion>
    </div>
  );
};

export default PreviewAccordion;
