import React, { useState } from 'react';

import SetupNewAgentScene from './setupNewAgentScene';
// import KycDocumentScene from './kycDocumentScene';

// import PersonalDetailsScene from './personalDetailsScene';
// import CountriesStatesLga from '../../../../../fixtures/countries_states_lgas.json';
// import BusinessDetailsScene from './businessDetailsScene';
// import NextOfKinScene from './nextOfKinScene';
// import ApplicationReviewScene from './applicationReviewScene';

import Platform from '../../../../../../../services/api/resources/platform';
import {
  ERROR_STATUS,
  SUCCESS_STATUS,
} from '../../../../../../../constants/api';
import { AppProvider } from '../../../context';
import PersonalDetailsScene from './personalDetailsScene';
import BusinessDetailsScene from './businessDetailsScene';
import KycDocumentScene from './kycDocumentScene';
import ApplicationReviewScene from './applicationReviewScene';

class SetupAgent extends React.Component {
  constructor(props) {
    super(props);
    this.myElementRef = React.createRef();
    this.state = {
      screen: 1,
      agentDetails: JSON.parse(localStorage.getItem('agentDetails')),
      isLoading: false,
      application: null,
    };

    this.fetchApplication = this.fetchApplication.bind(this);
  }

  setScreen = (newScreen) => {
    this.setState({ screen: newScreen });
  };

  async fetchApplication() {
    const platform = new Platform();

    this.setState({ isLoading: true });
    const { status, response } = await platform.getActiveAgentsDetails(
      this.state.agentDetails.id
    );

    localStorage.setItem('agentInformation', JSON.stringify(response));
    this.setState({ isLoading: false });
    if (status === ERROR_STATUS) {
    } else if (status === SUCCESS_STATUS) {
      this.setState({ isLoading: false });
    } else {
      this.setState({ isLoading: false });
    }
  }

  componentDidMount() {
    this.loadApplication();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoading !== this.props.isLoading) {
      this.loadApplication();
    }
  }

  loadApplication() {
    const agentInformation = JSON.parse(
      localStorage.getItem('agentInformation')
    );
    this.setState({ agentInformation });
  }

  render() {
    const { screen } = this.state;
    const agentInformation = JSON.parse(
      localStorage.getItem('agentInformation')
    );
    return (
      <AppProvider>
        <div className='aggregator-layout'>
          {/* <KycDocumentScene
            application={agentInformation}
            setScreen={this.setScreen}
            props={this.props}
          /> */}
          {screen === 1 && (
            <SetupNewAgentScene
              application={agentInformation}
              setScreen={this.setScreen}
              props={this.props}
              isLoading={this.state.isLoading}
              fetchApplication={this.fetchApplication}
            />
          )}
          {screen === 2 && (
            <PersonalDetailsScene
              application={agentInformation}
              setScreen={this.setScreen}
              screen={screen}
            />
          )}

          {screen === 3 && (
            <BusinessDetailsScene
              application={agentInformation}
              setScreen={this.setScreen}
              screen={screen}
            />
          )}
          {screen === 4 && (
            <KycDocumentScene
              application={agentInformation}
              setScreen={this.setScreen}
              props={this.props}
            />
          )}
          {/* {screen === 5 && (
            <ApplicationReviewScene
              applicationData={agentInformation}
              setScreen={this.setScreen}
              screen={screen}
              isLoading={this.state.isLoading}
              props={this.props}
            />
          )} */}
        </div>
      </AppProvider>
    );
  }
}

export default SetupAgent;
