import React, { Component } from 'react';

export default class Text extends Component {
  render () {
    const predefinedStyle = {}

    if (this.props.big) {
      predefinedStyle.fontSize = process.env.REACT_APP_BIG_FONT_SIZE
      predefinedStyle.lineHeight = process.env.REACT_APP_BIG_LINE_HEIGHT
    }

    if (this.props.bigger) {
      predefinedStyle.fontSize = process.env.REACT_APP_BIGGER_FONT_SIZE
      predefinedStyle.lineHeight = process.env.REACT_APP_BIG_LINE_HEIGHT
    }

    if (this.props.biggest) {
      predefinedStyle.fontSize = process.env.REACT_APP_BIGGEST_FONT_SIZE
      predefinedStyle.lineHeight = process.env.REACT_APP_BIG_LINE_HEIGHT
    }

    if (this.props.black) {
      predefinedStyle.color = process.env.REACT_APP_BLACK_COLOUR
    }

    if (this.props.blue) {
      predefinedStyle.color = process.env.REACT_APP_BLUE_COLOUR
    }

    if (this.props.bold) {
      predefinedStyle.fontWeight = 'bold'
    }

    if (this.props.center) {
      predefinedStyle.textAlign = 'center'
    }

    if (this.props.grey) {
      predefinedStyle.color = process.env.REACT_APP_GREY_COLOUR
    }

    if (this.props.mid) {
      predefinedStyle.fontSize = process.env.REACT_APP_MID_FONT_SIZE
      predefinedStyle.lineHeight = process.env.REACT_APP_MID_LINE_HEIGHT
    }

    if (this.props.small) {
      predefinedStyle.fontSize = process.env.REACT_APP_SMALL_FONT_SIZE
      predefinedStyle.lineHeight = process.env.REACT_APP_SMALL_LINE_HEIGHT
    }

    if (this.props.thin) {
      predefinedStyle.fontWeight = '200'
    }

    if (this.props.white) {
      predefinedStyle.color = 'white'
    }

    return <div 
      {...this.props}
      style={{
        color: '#3A3A3B',
        fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
        fontSize: '12px',
        lineHeight: '23px',
        textOverflow: 'ellipsis',
        ...predefinedStyle,
        ...this.props.style
      }}>
      {this.props.children}
    </div>
  }
}
