import React, { useEffect, useRef, useState } from "react";
import { Col } from "react-bootstrap";
import H1 from "../../../../components/h1";
import PrimaryButton from "../component/button";
import InputText from "../component/input";
import Onboarding from "../../../../services/api/resources/onboarding";
import BackButton from "../component/backButton";
import HeaderSignup from "../../../../components/header-signup";
import DocumentUploader from "../../../home/scenes/dashboard/fragments/onboarding-application-setup/component/customeImage4";
import SelectInput from "../component/select";
import { ONE_MB } from "../../../../constants";
import Platform from "../../../../services/api/resources/platform";

const BusinessDetailsScene = ({ setScreen, application }) => {
  const onboarding = new Onboarding();
  const platform = new Platform();

  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [fileFormatBusinessLocation, setFileFormatBusinessLocation] =
    useState(null);
  const [fileFormatUtilityBill, setFileFormatUtilityBill] = useState(null);
  const [uploadError, setUploadError] = useState(false);
  const [showLightboxBusinessLocation, setShowLightboxBusinessLocation] =
    useState(false);
  const [showLightBoxUtilityBill, setShowLightBoxUtilityBill] = useState(false);
  const [loadingBusinessLocationCancel, setLoadingBusinessLocationCancel] =
    useState(false);
  const [isLoadingBusinessLocation, setIsLoadingBusinessLocation] =
    useState(false);
  const [errorBusinessLocation, setErrorBusinessLocation] = useState(false);
  const [loadingUtilityBillCancel, setLoadingUtilityBillCancel] =
    useState(false);
  const [isLoadingUtilityBill, setIsLoadingUtilityBill] = useState(false);
  const [errorUtilityBill, setErrorUtilityBill] = useState(false);
  const [isLoadingDoc, setIsLoadingDoc] = useState(false);
  const [isFormTouched, setIsFormTouched] = useState(false);

  const [errors, setErrors] = useState({
    businessName: "",
    businessAddress: "",
    businessType: "",
    bankname: "",
    accountNumber: "",
    accountName: "",
  });

  const [businessDetailsForm, setBusinessDetailsForm] = useState({
    bankname: "",
    businessAddress: "",
    businessType: "",
    businessName: "",
    businessLocation: "",
    utilityBill: "",
    accountName: "",
    accountNumber: "",
  });

  useEffect(() => {
    const currentAgent = localStorage.getItem("currentAgent");
    if (currentAgent) {
      const agentData = JSON.parse(currentAgent);
      setBusinessDetailsForm((prevForm) => ({
        ...prevForm,
        businessName: agentData.businessName || "",
        businessAddress: agentData.businessAddress || "",
        businessType: agentData.businessType || "",
      }));
    }
  }, []);

  const fileInputRefUtilityBill = useRef(null);
  const fileInputRefBusinessLocation = useRef(null);

  const bankname = ["First Bank of Nigeria"];

  const handleCancelBusinessLocation = async () => {
    setLoadingBusinessLocationCancel(true);
    setBusinessDetailsForm((prevForm) => {
      const updatedForm = { ...prevForm, businessLocation: "" };
      localStorage.setItem("businessDetailsForm", JSON.stringify(updatedForm));
      return updatedForm;
    });
  };

  const handleCancelUtilityBill = async () => {
    setLoadingUtilityBillCancel(true);
    setBusinessDetailsForm((prevForm) => {
      const updatedForm = { ...prevForm, utilityBill: "" };
      localStorage.setItem("businessDetailsForm", JSON.stringify(updatedForm));
      return updatedForm;
    });
  };

  const updateFormField = (params) => {
    setIsFormTouched(true);
    const newForm = { ...businessDetailsForm, ...params };

    Object.keys(params).forEach((key) => {
      validateField(key, params[key]);
    });

    setBusinessDetailsForm(newForm);
  };

  const validateField = (fieldName, value) => {
    let errorMessage = "";

    if (!value) {
      errorMessage = `${fieldName} is required.`;
    } else if (fieldName === "accountNumber" && value.length < 10) {
      errorMessage = "Account number must be at least 10 digits.";
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: errorMessage,
    }));
  };

  const handleClickUtilityBill = () => {
    fileInputRefUtilityBill.current.click();
  };

  const handleClickBusinessLocation = () => {
    fileInputRefBusinessLocation.current.click();
  };

  const handleBusinessLocationChange = async (event) => {
    setLoadingBusinessLocationCancel(false);
    const file = event.target.files[0];

    if (file) {
      if (file.size > ONE_MB) {
        setErrorBusinessLocation(true);
        return;
      } else {
        setIsLoadingBusinessLocation(true);
        setErrorBusinessLocation(false);
        const reader = new FileReader();
        reader.onloadend = () => {
          const result = reader.result;
          setBusinessDetailsForm((prevForm) => ({
            ...prevForm,
            businessLocation: result,
          }));
          setIsLoadingBusinessLocation(false);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleUtilityBillChange = async (event) => {
    setLoadingUtilityBillCancel(false);
    const file = event.target.files[0];

    if (file) {
      if (file.size > ONE_MB) {
        setErrorUtilityBill(true);
        return;
      } else {
        setIsLoadingUtilityBill(true);
        setErrorUtilityBill(false);
        const reader = new FileReader();
        reader.onloadend = () => {
          const result = reader.result;
          setBusinessDetailsForm((prevForm) => ({
            ...prevForm,
            utilityBill: result,
          }));
          setIsLoadingUtilityBill(false);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleSubmit = async () => {
    if (isFormValid()) {
      setIsLoading(true);

      try {
        const { response } = await platform.submitBusinessDetails({
          bankName: businessDetailsForm.bankname,
          accountName: businessDetailsForm.accountName,
          accountNumber: businessDetailsForm.accountNumber,
          businessAddress: businessDetailsForm.businessAddress,
          businessName: businessDetailsForm.businessName,
        });

        setScreen(3);
        localStorage.setItem(
          "businessDetailsForm",
          JSON.stringify(businessDetailsForm)
        );
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    } else {
    }
  };
  
  const isFormValid = () => {
    return (
      businessDetailsForm.accountName &&
      businessDetailsForm.businessLocation &&
      businessDetailsForm.accountNumber &&
      businessDetailsForm.bankname &&
      businessDetailsForm.businessType &&
      businessDetailsForm.utilityBill &&
      businessDetailsForm.businessAddress &&
      businessDetailsForm.businessName &&
      Object.values(errors).every((error) => !error)
    );
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          gap: "40px",
          marginTop: "3rem",
          marginLeft: "3rem",
        }}
      >
        <HeaderSignup />
        <BackButton marginBottom={0} onClick={() => setScreen(1)} />
      </div>

      <main className="onboarding-container">
        <Col
          style={{
            margin: "20px auto",
          }}
        >
          <H1
            style={{
              textAlign: "left",
              fontWeight: 600,
              marginBottom: ".2rem",
            }}
          >
            Enter your Business Details
          </H1>

          <InputText
            label="Business Name"
            labelColor={
              errors.businessName ? process.env.REACT_APP_RED_COLOUR : "black"
            }
            border={`1px solid ${
              errors.businessName ? process.env.REACT_APP_RED_COLOUR : "#DFE2E6"
            }`}
            placeholder="Enter your Business Name"
            onChange={(e) => updateFormField({ businessName: e.target.value })}
            maxLength={200}
            value={businessDetailsForm.businessName}
          />

          <InputText
            label="Business Address"
            labelColor={
              errors.businessAddress
                ? process.env.REACT_APP_RED_COLOUR
                : "black"
            }
            border={`1px solid ${
              errors.businessAddress
                ? process.env.REACT_APP_RED_COLOUR
                : "#DFE2E6"
            }`}
            placeholder="Enter your Business Address"
            onChange={(e) =>
              updateFormField({ businessAddress: e.target.value })
            }
            maxLength={200}
            value={businessDetailsForm.businessAddress}
          />

          <InputText
            label="Business Type"
            labelColor={
              errors.businessType ? process.env.REACT_APP_RED_COLOUR : "black"
            }
            border={`1px solid ${
              errors.businessType ? process.env.REACT_APP_RED_COLOUR : "#DFE2E6"
            }`}
            placeholder="Enter your Business Type"
            onChange={(e) => updateFormField({ businessType: e.target.value })}
            maxLength={200}
            value={businessDetailsForm.businessType}
          />

          <SelectInput
            onChange={(e) => updateFormField({ bankname: e.target.value })}
            placeholder="Your Bank Name"
            value={businessDetailsForm.bankname}
            option={bankname}
            label="Bank Name"
            disabled={false}
          />

          <InputText
            label="Account Number"
            labelColor={
              errors.accountNumber ? process.env.REACT_APP_RED_COLOUR : "black"
            }
            border={`1px solid ${
              errors.accountNumber
                ? process.env.REACT_APP_RED_COLOUR
                : "#DFE2E6"
            }`}
            placeholder="Enter your Account Number"
            onChange={(e) => updateFormField({ accountNumber: e.target.value })}
            maxLength={10}
            value={businessDetailsForm.accountNumber}
          />

          <InputText
            label="Account Name"
            labelColor={
              errors.accountName ? process.env.REACT_APP_RED_COLOUR : "black"
            }
            border={`1px solid ${
              errors.accountName ? process.env.REACT_APP_RED_COLOUR : "#DFE2E6"
            }`}
            placeholder="Enter your Account Name"
            onChange={(e) => updateFormField({ accountName: e.target.value })}
            maxLength={200}
            value={businessDetailsForm.accountName}
          />

          <DocumentUploader
            label={"Image of Business Location"}
            loading={isLoadingDoc || isLoadingBusinessLocation}
            loadingCancelDoc={loadingBusinessLocationCancel}
            showLightbox={showLightboxBusinessLocation}
            fileFormat={fileFormatBusinessLocation}
            state={
              businessDetailsForm?.businessLocation
                ? businessDetailsForm?.businessLocation
                : ""
            }
            setShowLightbox={setShowLightboxBusinessLocation}
            onChange={handleBusinessLocationChange}
            handleDocument={handleClickBusinessLocation}
            fileInputRef={fileInputRefBusinessLocation}
            handleCancel={handleCancelBusinessLocation}
            error={errorBusinessLocation}
          />

          <DocumentUploader
            label={"Utility Bill"}
            loading={isLoadingDoc || isLoadingUtilityBill}
            loadingCancelDoc={loadingUtilityBillCancel}
            showLightbox={showLightBoxUtilityBill}
            fileFormat={fileFormatUtilityBill}
            state={
              businessDetailsForm?.utilityBill
                ? businessDetailsForm?.utilityBill
                : ""
            }
            setShowLightbox={setShowLightBoxUtilityBill}
            onChange={handleUtilityBillChange}
            handleDocument={handleClickUtilityBill}
            fileInputRef={fileInputRefUtilityBill}
            handleCancel={handleCancelUtilityBill}
            error={errorUtilityBill}
          />
          {uploadError && (
            <Col lg={12}>
              <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                Failed to Upload.Try again
              </span>
            </Col>
          )}

          <PrimaryButton
            disabled={!isFormValid() || isLoading || isLoadingDoc}
            onClick={handleSubmit}
          >
            {isLoading ? "Processing..." : "Next"}
          </PrimaryButton>
        </Col>
      </main>
    </>
  );
};

export default BusinessDetailsScene;
