import React from "react";
//import Button from '../../../../../components/button';
import H1 from "../../../../../components/h1";
import Text from "../../../../../components/text";

export default (props) => {
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        marginTop: "50px",
        padding: "5px",
      }}
    >
      <H1
        style={{
          fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
          fontSize: process.env.REACT_APP_BIG_FONT_SIZE,
          fontWeight: "600",
          marginBottom: "15px",
          marginTop: "40pt",
        }}
      >
        Your application is currently being reviewed
      </H1>
      <Text
        style={{
          color: "#808593",
          fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
          lineHeight: process.env.REACT_APP_MID_BIG_LINE_HEIGHT,
          textAlign: "center",
          maxWidth: "50%",
          width: "450pt",
          marginBottom: "15px",
        }}
      >
        An email notification will be sent to you once approved and your status
        would be updated on the dashboard.
      </Text>
      <Text
        style={{
          color: "#808593",
          fontSize: process.env.REACT_APP_MID_FONT_SIZE,
          lineHeight: process.env.REACT_APP_MID_BIG_LINE_HEIGHT,
          textAlign: "center",
          maxWidth: "80%",
          width: "450pt",
        }}
      >
        For questions or feedback, reach out to us on any of the channels below:
      </Text>
      <ul
        style={{
          listStyleType: "none",
          color: "#808593",
          fontSize: process.env.REACT_APP_MID_FONT_SIZE,
          lineHeight: process.env.REACT_APP_BIG_LINE_HEIGHT,
          textAlign: "left",
          maxWidth: "55%",
          width: "395pt",
        }}
      >
        <li>
          Email -{" "}
          <a href="mailto:ifiscustomercare@interswitchgroup.com">
            ifiscustomercare@interswitchgroup.com
          </a>
        </li>
        <li>
          Help Portal -{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://help.interswitchgroup.com/"
          >
            https://help.interswitchgroup.com
          </a>
        </li>
        <li>
          Twitter -{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.twitter.com/QTPaypoint"
          >
            @QuicktellerPaypoint
          </a>
        </li>
        <li>
          Facebook -{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/QuicktellerPaypoint/"
          >
            Quickteller Paypoint
          </a>
        </li>
        <li>
          Contact Centre - <a href="tel:0700-9065000">0700-9065000</a>
        </li>
      </ul>
      {/* <Text style={{color: '#808593', fontSize: process.env.REACT_APP_MID_FONT_SIZE, lineHeight: process.env.REACT_APP_MID_LINE_HEIGHT, textAlign: 'center', maxWidth: '80%', width: '450pt'}}>
      Do reach out for any feedback or clarification, if required.
    </Text> */}
    </div>
  );
};
