import { verifyPhoneNumber } from "nigerian-phone-number-validator"

function nigerianPhoneNumber (phone) {
  return verifyPhoneNumber(phone)
  // return (phone.length === 11 && phone.startsWith('0')) || (phone.length === 10 && !phone.startsWith('0') && !phone.startsWith('234')) || (phone.length === 13 && phone.startsWith('234'))
}

export default {
  '234': (phone) => nigerianPhoneNumber(phone)
}
