import React, { Component } from "react";
import { AppProvider } from "../context";
import H1 from "../../../../../components/h1";
import ApplicationGrid from "./applicationsGrid";
import { Form, InputGroup } from "react-bootstrap";
import MdSearch from "react-ionicons/lib/MdSearch";
import qs from "qs";

class ApplicationsScene extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterStatus: "",
      searchText: "",
      deviceIsPC:
        !qs.parse(window.location.search)["?mobile"] &&
        window.innerWidth >= 992,
    };
    this.handleChange = this.handleChange.bind(this);

    this.timeoutId = null;
  }

  handleChange(event) {
    this.setState({ searchText: event.target.value });
  }

  componentDidMount() {
    localStorage.removeItem("applicationId");
    localStorage.removeItem("passportPhoto");
    localStorage.removeItem("idCard");
    localStorage.removeItem("lga");
    localStorage.removeItem("application");
    localStorage.removeItem("businessType");
    localStorage.removeItem("state");
    localStorage.removeItem("personalDetailsForm");
    localStorage.removeItem("kycDetailsForm");
    localStorage.removeItem("businessDetailsForm");
    localStorage.removeItem("nextOfKinDetailsForm");
    localStorage.removeItem("jumbScreen");
  }

  render() {
    return (
      <AppProvider>
        <div className="aggregator-layout">
          <H1
            style={{
              textAlign: "start",
              marginBottom: "35px",
            }}
          >
            My Applications
          </H1>
          <div
            style={{
              marginBottom: 25,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <InputGroup
              col
              lg={3}
              style={{
                background: "#F3F5F6",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
                borderRadius: 5,
                width: this.state.deviceIsPC ? "25vw" : "40vw",
                padding: "0 10px",
              }}
            >
              <div
                style={{
                  fontSize: 30,
                  textAlign: "center",
                  color: "#848F9F",
                  cursor: "pointer",
                }}
                onClick={() => this.handleSearch()}
              >
                <MdSearch color="grey" />
              </div>
              <Form.Control
                onChange={this.handleChange}
                value={this.state.searchText}
                title="Search by business name or phone number"
                style={{
                  border: "none",
                  background: "#F3F5F6",
                  height: 40,
                  borderRadius: 5,

                  boxShadow: "none",
                }}
                placeholder="Search by business name or phone number"
              />
            </InputGroup>
            <Form.Control
              style={{
                width: this.state.deviceIsPC ? "10vw" : "25vw",
                borderRadius: 5,
                height: 40,
                cursor: "pointer",
              }}
              as="select"
              onChange={(e) => this.setState({ filterStatus: e.target.value })}
            >
              <option value={this.state.filterStatus} disabled selected>
                {this.state.deviceIsPC ? "Agent status" : "Status"}
              </option>
              <option>Approved</option>
              <option>Rejected</option>
              <option>Cancelled</option>
              <option>Awaiting approval</option>
              <option>Awaiting validation</option>
              <option>Draft</option>
              <option>All</option>
            </Form.Control>
          </div>
          <section>
            <ApplicationGrid
              navigateTo={this.props.navigateTo}
              searchText={this.state.searchText}
              filterStatus={this.state.filterStatus}
            />
          </section>
        </div>
      </AppProvider>
    );
  }
}

export default ApplicationsScene;
